import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faStoreAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonRedButton } from 'components/styledComponents';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { apiStatusConstants, vendorDealerStatusTypes } from '../../../../app-constants';
import { formatMMDDYY } from '../../../../utils/dateTimeUtils';
import { sortInvitationHistory } from '../../../../utils/sortUtils';
import { CircularSpinner } from '../../../common';
import FormModal from '../../../modals/FormModal';
import { formatPhoneNumber } from 'utils/stringUtils';
import { vendorDealerActions } from '../../../../store/vendorStore';

const selectedDealerSelector = createSelector(
  (state) => state.vendors.isLoading,
  (state) => state.vendors.vendorDealer,
  (isLoading, vendorDealer) => ({
    isLoading: isLoading,
    vendorDealer: vendorDealer.data,
    fetchStatus: vendorDealer.fetchStatus
  })
);

const DealershipDetails = ({ vendorId, closeDrawer }) => {
  const { vendorDealer, fetchStatus, isLoading } = useSelector(selectedDealerSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(undefined);
  const [sortedInvitationHistory, setSortedInvitationHistory] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading === false) {
      setIsModalOpen(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (vendorDealer?.invitationHistory) {
      const sortedArray = sortInvitationHistory(vendorDealer.invitationHistory);
      setSortedInvitationHistory(sortedArray);
    }
  }, [vendorDealer]);

  const handleSave = (values) => {
    const invite = {
      vendorId: vendorId,
      dealerId: vendorDealer.dealer.id,
      status: modalType === 'decline' ? 'DECLINED' : 'ACTIVE',
      message: modalType === 'decline' ? values.message : '',
      dealerNote: vendorDealer.dealerNotes
    };
    const errorMessage =
      modalType === 'decline'
        ? 'An error occurred while declining the invite'
        : 'An error occurred while accepting the invite';
    const successMessage =
      modalType === 'decline'
        ? `${vendorDealer.dealer.name}'s invite has been declined`
        : `${vendorDealer.dealer.name}'s invite has been accepted`;
    dispatch(
      vendorDealerActions.updateVendorDealerInvite(
        vendorId,
        vendorDealer.dealer.id,
        invite,
        successMessage,
        errorMessage
      )
    );
  };

  const isFetching = fetchStatus === apiStatusConstants.IS_FETCHING;

  const getButtonText = () => {
    if (isLoading) {
      if (modalType === 'accept') {
        return 'Accepting';
      } else {
        return 'Declining';
      }
    } else {
      if (modalType === 'accept') {
        return 'Accept Invite';
      } else {
        return 'Decline Invite';
      }
    }
  };

  return (
    <>
      {!isFetching ? (
        <StyledVendorDetailWrapper>
          <StyledSectionWrapper style={{ paddingTop: 24 }}>
            <StyledSpacedRow>
              <StyledHeading>Contact Information</StyledHeading>
              <StyledText style={{ fontSize: 14 }}>
                {vendorDealerStatusTypes[vendorDealer?.status]?.Name}: {formatMMDDYY(vendorDealer?.statusUpdatedOn)}
              </StyledText>
            </StyledSpacedRow>
            <StyledRow>
              <div className="iconDiv">
                <FontAwesomeIcon icon={faStoreAlt} />
              </div>
              <StyledCol>
                <span>{vendorDealer?.dealer?.name}</span>
                <span>
                  {vendorDealer?.dealer?.address1}, {vendorDealer?.dealer?.city}, {vendorDealer?.dealer?.state}{' '}
                  {vendorDealer?.dealer?.postalCode}
                </span>
                {vendorDealer?.dealer?.phone && <span>{formatPhoneNumber(vendorDealer?.dealer?.phone)}</span>}
                <span>{vendorDealer?.dealer?.email}</span>
              </StyledCol>
            </StyledRow>
          </StyledSectionWrapper>
          <StyledSectionWrapper>
            <StyledRow>
              <StyledHeading>Added By</StyledHeading>
            </StyledRow>
            <StyledRow>
              <div className="iconDiv">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <span>
                {vendorDealer?.createdByFirstName} {vendorDealer?.createdByLastName}
              </span>
            </StyledRow>
          </StyledSectionWrapper>
          <StyledSectionWrapper>
            <StyledHeading>Invitation Messages</StyledHeading>
            {sortedInvitationHistory
              .filter((item) => item?.message)
              .map((item) => {
                return (
                  <div className="inviteMessage" key={item.createdOn}>
                    <StyledText>
                      {item.status === 'DECLINED' && 'Decline '}Message from{' '}
                      <StyledSpan>
                        {item.createdByFirstName} {item.createdByLastName}
                      </StyledSpan>{' '}
                      on <StyledSpan>{formatMMDDYY(item.createdOn)}</StyledSpan>.
                    </StyledText>
                    <StyledText>You cannot respond to this message.</StyledText>
                    <StyledMessage>{item.message}</StyledMessage>
                  </div>
                );
              })}
          </StyledSectionWrapper>
        </StyledVendorDetailWrapper>
      ) : (
        <StyledCenter>
          <CircularSpinner tip="Loading..." size="2.5rem" />
        </StyledCenter>
      )}
      <StyledButtonContainer>
        {vendorDealer.status === 'PENDING' ? (
          <>
            <CommonRedButton
              className="ant-btn-background-ghost"
              disabled={isFetching}
              onClick={(e) => {
                e.target.blur();
                setIsModalOpen(true);
                setModalType('decline');
              }}
            >
              Decline
            </CommonRedButton>
            <CommonRedButton
              disabled={isFetching}
              onClick={(e) => {
                e.target.blur();
                setIsModalOpen(true);
                setModalType('accept');
              }}
            >
              Accept
            </CommonRedButton>
          </>
        ) : (
          <CommonRedButton
            onClick={() => {
              closeDrawer();
            }}
          >
            Close
          </CommonRedButton>
        )}
      </StyledButtonContainer>
      <FormModal
        visible={isModalOpen}
        handleSave={handleSave}
        closeModal={() => setIsModalOpen(false)}
        title={modalType === 'accept' ? 'Accept Invite' : 'Decline Invite'}
        icon={faQuestionCircle}
        buttonText={getButtonText()}
        message={
          modalType === 'decline' && {
            label: 'Decline Note',
            placeholder: 'Explain to the dealer why you are denying their request',
            maxLength: 120
          }
        }
        isSaving={isLoading}
      >
        Are you sure you want to {modalType} the invite from{' '}
        <StyledVendorName>{vendorDealer?.dealer?.name}</StyledVendorName>? They will be notified of this action.
      </FormModal>
    </>
  );
};

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  margin-top: calc(50% - 2.5rem);
  display: flex;
  align-items: center;
`;

const StyledVendorDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  div.iconDiv {
    margin-right: 12px;
    width: 17.5px;
  }
`;

const StyledSectionWrapper = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #cacfd9;
  span + span {
    padding-top: 4px;
  }
  .inviteMessage {
    padding-bottom: 16px;
  }
  .inviteMessage + .inviteMessage {
    padding-top: 16px;
    border-top: ${({ theme }) => theme.borders.grayLineItemBorder};
  }
  .inviteMessage:last-child {
    padding-bottom: 0px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledSpacedRow = styled(StyledRow)`
  justify-content: space-between;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.lg};
  margin-bottom: 8px;
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledMessage = styled(StyledText)`
  margin-top: 8px;
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

const StyledButtonContainer = styled(StyledRow)`
  padding: 20px 24px;
  justify-content: end;
  * + * {
    margin-left: 8px;
  }
  border-top: 1px solid #cacfd9;
  position: absolute;
  bottom: 0;
  background-color: white;
`;

const StyledVendorName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export default DealershipDetails;
