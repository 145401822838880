import React, { useState, useEffect, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from '@reach/router';
import { debounce } from 'lodash';
import { ResponsivePie } from '@nivo/pie';
import { Stack } from 'components';
import { GridTemplate, GridArea } from 'components/styledComponents';
import { Typography } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { generateDealershipsLink } from '../commonFunctions';

const { Text } = Typography;

const InventoryOverview = ({ activeMetrics, isFetching, theme, flags }) => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const [summaryCount, setSummaryCount] = useState(null);
  const [summaryText, setSummaryText] = useState(null);
  const [summarySubText, setSummarySubText] = useState(null);
  const [percData, setPercData] = useState([]);
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const { items } = useCommonDropdownContext('dealerships');
  const mappedDealershipFilters = (items ?? []).filter((item) => item.checked).map((item) => item.name);
  const noDealershipFiltersSelectedHQ = flags.reconDealerFilter && isHQ && mappedDealershipFilters.length === 0;

  function dashboardMetrics(activeMetrics) {
    return [
      {
        id: 'No Plan',
        label: 'No Plan',
        value: activeMetrics.inventoryNoPlan
      },
      {
        id: 'Plan On Target',
        label: 'Plan On Target',
        value: activeMetrics.inventoryPlanOnTarget
      },
      {
        id: 'Plan At Risk',
        label: 'Plan At Risk',
        value: activeMetrics.inventoryPlanAtRisk
      },
      {
        id: 'Plan Complete',
        label: 'Plan Complete',
        value: activeMetrics.inventoryCompletedPlan
      },
      {
        id: 'Excluded',
        label: 'Excluded',
        value: activeMetrics.inventoryExempt
      }
    ];
  }

  useEffect(() => {
    if (Object.keys(activeMetrics).length > 0) {
      setColors(defaultColors);
      setChartData(dashboardMetrics(activeMetrics));
    }
  }, [activeMetrics]);

  useEffect(() => {
    calcPercentage();
  }, [chartData]);

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  // DE181404: Inventory Overview graph displaying incorrect %
  const calcPercentage = () => {
    if (activeMetrics.inventoryTotalCount === 0) {
      setPercData([0, 0, 0, 0, 0, 0]);
    } else if (chartData.length > 0) {
      let noPlanPerc = (chartData[0].value / activeMetrics.inventoryTotalCount) * 100;
      let inProgressPerc = ((chartData[1].value + chartData[2].value) / activeMetrics.inventoryTotalCount) * 100;
      let planCompletePerc = (chartData[3].value / activeMetrics.inventoryTotalCount) * 100;

      let planOnTargetPerc = 0;
      if (inProgressPerc > 0) {
        planOnTargetPerc = (chartData[1].value / (chartData[1].value + chartData[2].value)) * 100;
      }

      let noPlanPercCutted = valueCutter(noPlanPerc);
      let inProgressPercCutted = valueCutter(inProgressPerc);

      let planOnTargetPercCutted = '0';
      let planAtRiskPercCutted = '0';

      if (inProgressPerc > 0) {
        planOnTargetPercCutted = valueCutter(planOnTargetPerc);
        planAtRiskPercCutted = valueCutter(Number.parseFloat(100 - Number(planOnTargetPercCutted)).toPrecision(4));
      }

      let planCompletePercCutted = valueCutter(planCompletePerc);

      let excludedPercCutted =
        (chartData[4].value ?? 0) === 0
          ? 0
          : valueCutter(
              Number.parseFloat(
                100 - Number(noPlanPercCutted) - Number(inProgressPercCutted) - Number(planCompletePercCutted)
              ).toPrecision(4)
            );

      let percDataset = [
        noPlanPercCutted,
        inProgressPercCutted,
        planOnTargetPercCutted,
        planAtRiskPercCutted,
        planCompletePercCutted,
        excludedPercCutted
      ].map((number) => formatter.format(Number(number)));

      setPercData(handleSpecialCase(percDataset));
    }
  };

  const valueCutter = (value) => {
    return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  };

  const handleSpecialCase = (percDataset) => {
    for (var index in percDataset) {
      if (Number(percDataset[index]) > 0 && Number(percDataset[index]) < 1) {
        percDataset[index] = '<1'; // If percentage is > 0 and < 1, display <1 (don't display decimals or round down to 0). Library auto-appends the '%' symbol
      } else if (Number(percDataset[index]) > 99) {
        percDataset[index] = '>99'; // If percentage is between 99 and 100, display >99 (don't round up to 100 because then the math will look wrong). Library auto-appends the '%' symbol
      }
    }
    return percDataset;
  };

  const handleNavigate = (type, reconHiddenFilter) => {
    const dealershipIds = generateDealershipsLink(
      flags.reconDealerFilter && isHQ ? (items ?? []).filter((item) => item.checked).map((item) => item.name) : null
    );
    switch (type) {
      case 'No Plan':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=No Plan&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'In Progress':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=In Progress&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      /**
       * ToDo: "On Track", "Past Goal", "Overdue" should be removed once the launch toggle plan-status-filter is removed
       * because "Plan On Target" and "Plan At Risk" will replace these filters.
       */
      case 'On Track':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=In Progress&taskProgress=On Track&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'Past Goal':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=In Progress&taskProgress=Past Goal&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'Overdue':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=In Progress&taskProgress=Overdue&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'Plan On Target':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=In Progress&planStatus=On Target&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'Plan At Risk':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=In Progress&planStatus=At Risk&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'Plan Complete':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=Plan Complete&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      case 'Excluded':
        navigate(
          `/inventory?vehicleStatus=Active&planStatus=Excluded&fromDashboard=1${dealershipIds}${
            reconHiddenFilter ? '&hideDeletedInProvision=1' : ''
          }`
        );
        break;
      default:
        devLogger.error(new Error(`Unexpected inventory type: ${type}`));
        break;
    }
  };

  const defaultColors = [
    theme.colors.purple, // No Plan
    theme.colors.green, // Plan On Target
    theme.colors.red, // Plan At Risk
    theme.colors.blue, // Plan Complete
    theme.colors.navy // Excluded
  ];

  const categories = ['No Plan', 'Plan On Target', 'Plan At Risk', 'Plan Complete', 'Excluded'];

  const [colors, setColors] = useState(defaultColors);

  const setColorFocus = (index) => {
    const newColors = [
      theme.colors.backgroundGray,
      theme.colors.backgroundGray,
      theme.colors.backgroundGray,
      theme.colors.backgroundGray,
      theme.colors.backgroundGray,
      theme.colors.backgroundGray
    ];
    newColors[index] = defaultColors[index];
    setColors(newColors);
  };

  const clearSummaryAndResetColors = () => {
    setSummaryCount(null);
    setSummaryText(null);
    setSummarySubText(null);
    setColors(defaultColors);
  };

  const setSummary = (label, count) => {
    setSummaryCount(count);
    setSummaryText(label);
    setSummarySubText(`${activeMetrics?.inventoryTotalCount?.toLocaleString() || 0} Vehicles in Inventory`);
  };

  const onSliceHover = debounce((type, data) => {
    // Adding this debounce so that when you mouse over the chart slices, that it doesn't act so schizophrenic when moving from one slice to another
    if (type === 'mouseEnter') {
      const index = categories.findIndex((x) => x === data.id);
      setSummary(data.label, chartData[index].value.toLocaleString());
      setColorFocus(index);
    } else {
      clearSummaryAndResetColors();
    }
  }, 100);

  return (
    <StyledContainer>
      <StyledTitle>
        <Text className="medium-font dark-gray">Inventory Overview</Text>
      </StyledTitle>

      {isFetching ? (
        <StyledChartContainer style={{ display: 'grid', placeContent: 'center' }}>
          <Skeleton circle height={200} width={200} />
        </StyledChartContainer>
      ) : (
        <StyledChartContainer>
          <ResponsivePie
            data={chartData}
            startAngle={-90}
            endAngle={90}
            innerRadius={0.94}
            padAngle={1}
            cornerRadius={3}
            fit={true}
            colors={colors}
            enableRadialLabels={false}
            enableSlicesLabels={false}
            isInteractive={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            onClick={(a) => handleNavigate(a.id, flags.reconHiddenFilter)}
            onMouseEnter={(data) => onSliceHover('mouseEnter', data)}
            onMouseLeave={(data) => onSliceHover('mouseLeave', data)}
          />
          <StyledChartSummary>
            <div>
              <Text className="styled-count">
                {summaryCount
                  ? summaryCount
                  : activeMetrics
                  ? activeMetrics.inventoryTotalCount?.toLocaleString()
                  : null}
              </Text>
              <Text className="strong">{summaryText ? summaryText : 'Vehicles in Inventory'}</Text>
              <Text className="dark-gray">{summarySubText ? summarySubText : ''}</Text>
            </div>
          </StyledChartSummary>
        </StyledChartContainer>
      )}

      <StyledLegendContainer>
        {isFetching ? (
          <div style={{ fontSize: 20 }}>
            <Skeleton count={7} />
          </div>
        ) : (
          <>
            {chartData.length > 0 && (
              <>
                <Legend
                  label="No Plan"
                  dotColor={legendColors['No Plan']}
                  count={chartData[0].value == null ? '-' : chartData[0].value.toLocaleString()}
                  percentage={percData[0]}
                  handleClick={() =>
                    noDealershipFiltersSelectedHQ ? () => {} : handleNavigate('No Plan', flags.reconHiddenFilter)
                  }
                  onMouseEnter={() => {
                    setSummary('No Plan', chartData[0].value.toLocaleString());
                    setColorFocus(0);
                  }}
                  onMouseLeave={() => clearSummaryAndResetColors()}
                  noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
                />
                <StyledInProgressLegendBucket>
                  <Legend
                    className="styled-in-progress"
                    label="In Progress"
                    dotColor={legendColors['In Progress']}
                    count={activeMetrics.inventoryOpenPlan?.toLocaleString() || 0}
                    percentage={percData[1]}
                    handleClick={() =>
                      noDealershipFiltersSelectedHQ ? () => {} : handleNavigate('In Progress', flags.reconHiddenFilter)
                    }
                    onMouseEnter={() => {
                      setSummary('In Progress', activeMetrics.inventoryOpenPlan.toLocaleString());
                      setColors([
                        theme.colors.backgroundGray,
                        theme.colors.green,
                        theme.colors.red,
                        theme.colors.backgroundGray,
                        theme.colors.backgroundGray
                      ]);
                    }}
                    onMouseLeave={() => clearSummaryAndResetColors()}
                    noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
                  />
                  <div className="styled-vertical-bar" />
                  <Legend
                    className="styled-plan-on-target"
                    label="Plan On Target"
                    dotColor={legendColors['Plan On Target']}
                    count={activeMetrics.inventoryPlanOnTarget?.toLocaleString() || 0}
                    percentage={percData[2]}
                    handleClick={() =>
                      noDealershipFiltersSelectedHQ
                        ? () => {}
                        : handleNavigate('Plan On Target', flags.reconHiddenFilter)
                    }
                    /**
                     * When you hover over a specific status it shows the corresponding chart value (ex: 20 No Plan Vehicles)
                     * When your mouse leaves the hover, it resets the summary title and resets the colors of the pie chart that are displayed
                     */
                    onMouseEnter={() => {
                      setSummary('Plan On Target', chartData[1].value.toLocaleString());
                      setColorFocus(1);
                    }}
                    onMouseLeave={() => clearSummaryAndResetColors()}
                    noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
                  />
                  <Legend
                    className="styled-plan-at-risk"
                    label="Plan At Risk"
                    dotColor={legendColors['Plan At Risk']}
                    count={activeMetrics.inventoryPlanAtRisk?.toLocaleString() || 0}
                    percentage={percData[3]}
                    handleClick={() =>
                      noDealershipFiltersSelectedHQ ? () => {} : handleNavigate('Plan At Risk', flags.reconHiddenFilter)
                    }
                    onMouseEnter={() => {
                      setSummary('Plan At Risk', chartData[2].value.toLocaleString());
                      setColorFocus(2);
                    }}
                    onMouseLeave={() => clearSummaryAndResetColors()}
                    noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
                  />
                </StyledInProgressLegendBucket>
                <Legend
                  label="Plan Complete"
                  dotColor={legendColors['Plan Complete']}
                  count={chartData[3].value.toLocaleString()}
                  percentage={percData[4]}
                  handleClick={() =>
                    noDealershipFiltersSelectedHQ ? () => {} : handleNavigate('Plan Complete', flags.reconHiddenFilter)
                  }
                  onMouseEnter={() => {
                    setSummary('Plan Complete', chartData[3].value.toLocaleString());
                    setColorFocus(3);
                  }}
                  onMouseLeave={() => clearSummaryAndResetColors()}
                  noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
                />
                <Legend
                  label="Excluded"
                  dotColor={legendColors['Excluded']}
                  count={chartData[4].value.toLocaleString()}
                  percentage={percData[5]}
                  handleClick={() =>
                    noDealershipFiltersSelectedHQ ? () => {} : handleNavigate('Excluded', flags.reconHiddenFilter)
                  }
                  onMouseEnter={() => {
                    setSummary('Excluded', chartData[4].value.toLocaleString());
                    setColorFocus(4);
                  }}
                  onMouseLeave={() => clearSummaryAndResetColors()}
                  noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
                />
              </>
            )}
          </>
        )}
      </StyledLegendContainer>
    </StyledContainer>
  );
};

const Legend = withLDConsumer()(
  ({
    className,
    dotColor,
    label,
    count,
    percentage,
    handleClick,
    onMouseEnter,
    onMouseLeave,
    flags,
    noDealershipFiltersSelectedHQ = false
  }) => {
    const reconDealerFilterFlag = flags?.reconDealerFilter;
    const defaultPercent = reconDealerFilterFlag ? '0' : '—';
    const percent = Number(count) === 0 ? defaultPercent : percentage + '%';

    return (
      <StyledLegend
        className={className}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
      >
        <Stack horizontal divStyle={{ minHeight: '33px', alignItems: 'center' }}>
          <StyledDot dotColor={dotColor} />
          <Text className="strong">{label}</Text>
          <Text className="styled-count-label extra-small-font dark-gray">{`${count} (${percent})`}</Text>
        </Stack>
      </StyledLegend>
    );
  }
);

const legendColors = {
  'No Plan': 'purple', //'#A900F2',
  'In Progress': 'borderGray', //'#E5E5E5',
  'Plan On Target': 'green', //'#00F2AB',
  'Plan At Risk': 'red', //'#FE203A',
  //ToDo: Remove "On Track", "Past Goal", "Overdue" once plan-status-filter feature flag is removed
  //START Old Plan Status Filters
  'On Track': 'green', //'#00F2AB',
  'Past Goal': 'orange', //'#FE7520',
  Overdue: 'red', //'#FE203A',
  //END Old Plan Satus Filters
  'Plan Complete': 'blue', //'#1CC9FD',
  Excluded: 'navy' //'#002950'
};

//#endregion Styled Components
const StyledContainer = styled(GridTemplate(`'title' 'chart' 'legend'`, 'auto 200px 260px'))`
  flex: 2 2 0;
  width: 302px; // 350px - 24px padding left/right
`;
const StyledTitle = styled(GridArea('title', 1, 1))`
  margin: 0 0 5px 4px;
`;
const StyledChartContainer = styled(GridArea('chart', 2, 1))`
  position: relative;
  padding: 20px 4px 20px;
  height: 220px;
  width: 100%;
`;
const StyledChartSummary = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 110px;
    margin-right: 15px;

    .styled-count {
      font-size: ${({ theme }) => theme.fontSizes.largeStat};
      font-weight: ${({ theme }) => theme.fontWeights.light};
      line-height: 47px;
    }
  }
`;

const StyledLegendContainer = styled(GridArea('legend', 3, 1))`
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;

const StyledInProgressLegendBucket = styled.div`
  position: relative;
  .styled-vertical-bar {
    position: absolute;
    border: ${({ theme }) => theme.borders.thinSolidGray};
    height: 40px;
    width: 1px;
    top: 41px;
    left: 19px;
  }
  .styled-in-progress {
    height: 98px;
  }
  .styled-plan-on-target {
    position: absolute;
    top: 29px;
    left: 20px;
    width: 200px;
  }
  .styled-plan-at-risk {
    position: absolute;
    top: 59px;
    left: 20px;
    width: 200px;
  }
`;
const StyledLegend = styled.div`
  ${({ noDealershipFiltersSelectedHQ }) => (noDealershipFiltersSelectedHQ ? '' : 'cursor: pointer;')}
  width: 220px;
  .styled-count-label {
    margin-left: 6px;
  }
  :hover {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.backgroundGray};
  }
`;
const StyledDot = styled.span`
  margin: 0 8px;
  height: 9px;
  min-width: 9px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors[props['dotColor']]};
`;
//#endregion

export default withLDConsumer()(withTheme(InventoryOverview));
