import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    letter-spacing: 0.25px;

    .general-setting-tooltip {
      .ant-tooltip-arrow {
        border-bottom-color: ${({ theme }) => theme.colors.white};
      }
  
      .ant-tooltip-arrow::before {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }

    .ant-modal-wrap {
      overflow: hidden !important;
    }
  }
  
  #root {
    width: 100%;
    height: 100%;
  }

  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navy,
  .ant-typography.navy {
    color: ${({ theme }) => theme.colors.navy};
  }

  .red,
  .ant-typography.red {
    color: ${({ theme }) => theme.colors.red};
  }

  .orange,
  .ant-typography.orange {
    color: ${({ theme }) => theme.colors.orange}
  }

  .gray,
  .ant-typography.gray {
    color: ${({ theme }) => theme.colors.gray};
  }

  .dark-gray,
  .ant-typography.dark-gray {
    color: ${({ theme }) => theme.colors.darkGray};
  }

  .border-gray {
    color: ${({ theme }) => theme.colors.borderGray};
  }

  .extra-small-font {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }

  .medium-font {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }

  .large-font {
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }

  .strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .smallBorder {
    border: ${({ theme }) => theme.borders.mediumSolidGray};
  }

  .mediumBorder {
    border: ${({ theme }) => theme.borders.mediumSecondaryGrey}
  }

  .big-border {
    border: 4px solid #E5E5E5;
  }

  .box-shadow {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15);
  }

  .selected-background-color {
    background-color: #D6EAF8;
  }

  .ant-input:focus, .ant-input-number:focus, .ant-select:focus,
  .ant-dropdown-open, .ant-input-number-focused,
  .ant-select-open .ant-select-selection,
  .ant-calendar-picker:focus {
    border-color: ${({ theme }) => theme.colors.navy} !important;
  }

  .ant-select:not(.ant-select-open) .ant-select-selection:focus  {
    // This removes the focus highlight after the user has chosen something from the dropdown and it's closed itself
    border-color: ${({ theme }) => theme.borderColors.gray} !important;
  }

  .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
  .ant-checkbox-wrapper:hover:not(.ant-checkbox-wrapper-disabled),
  .ant-radio-wrapper:hover:not(.ant-radio-wrapper-disabled) {
    background-color: ${({ theme }) => theme.backgroundColors.gray};
  }
  
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.red};
    border: unset;  
  }
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.lightRed};
    border: unset;  
  }
  .ant-checkbox-inner {
    border-radius: 4px;   
  }
  .ant-checkbox-checked::after {
    border: unset;
  }

  // Overwrite default behavior when menu item is disabled
  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
    background-color: initial !important;
    font-weight: initial !important;
  }

  label.ant-radio-wrapper {
    display: block;
    padding: 0px 16px;
    margin: 0;
    height: 32px;
    line-height: 32px;
    font-size: ${({ theme }) => theme.fontSizes.md};

    &.ant-radio-wrapper-disabled {
      cursor: not-allowed;
    }

    &:hover > .ant-radio,
    .ant-radio:hover > .ant-radio-inner,
    .ant-radio-input:focus.ant-radio-inner {
      border-color: unset;
    }
    .ant-radio.ant-radio-checked:not(.ant-radio-disabled) > .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.red};
      &::after {
        background-color: ${({ theme }) => theme.colors.red};
      }
    }
  }

  .dashboardRangePicker {
    .ant-calendar-range .ant-calendar-in-range-cell:before,
    .ant-calendar-in-range-cell,
    .ant-calendar-in-range-cell .ant-calendar-date,
    .ant-calendar-date:hover {
      background-color: #E5E5E5;
    }

    .ant-calendar-date {
      transition: none;
    }

    .ant-calendar-disabled-cell .ant-calendar-date {
      background-color: transparent;
    }

    .ant-calendar-today .ant-calendar-date {
      color: ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red};
    }

    .ant-calendar-range .ant-calendar-selected-start-date,
    .ant-calendar-range .ant-calendar-selected-end-date {
      &, & .ant-calendar-date, & .ant-calendar-date:hover {
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 4px;
      }
    }
    .ant-calendar-my-select .ant-calendar-month-select,
    .ant-calendar-my-select .ant-calendar-year-select {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }

  /* Custom scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border: 1px solid #ddd */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa;
    /* border-radius: 4px; */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  /* Popover styles */
  .footerGetHelpPopover {
    &.ant-popover {
      z-index: 100;
    }

    .ant-popover-content {
      border-radius: 4px;
    }

    .ant-avatar {
      background-color: #D8D8D8;
    }

    .ant-popover-title {
      padding: 0;
      height: 60px;
      border-radius: 4px 4px 0 0;
      background-color: ${({ theme }) => theme.colors.navy};
      color: ${({ theme }) => theme.colors.white};
      font-size: ${({ theme }) => theme.fontSizes.md};
      position: relative;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-tabs {
      .ant-tabs-bar {
        color: ${({ theme }) => theme.colors.navy};
        .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
          background-color: ${({ theme }) => theme.colors.red};
          color: ${({ theme }) => theme.colors.navy};
        }
      }
    }
  }
  .time-tracking-reassignment {
    .ant-popover-inner-content {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
    /* Tooltip styles */
  .create-recon-plan-from-template-tooltip,
  .recon-plan-need-assignee-tooltip,
  .appraisal-info-no-data-tooltip,
  .passthrough-toggle,
  .task-pending-task-card-toggle,
  .task-pending-tdp-toggle {
    .ant-tooltip-arrow:before,
    .ant-tooltip-inner {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.red};
    }
  }

  .statistic-tooltip,
  .current-recon-task-type-name-tooltip,
  .vehicle-card-communication-tag-tooltip,
  .copy-tooltip,
  .communication-tooltip,
  .avg-time-in-task-tooltip,
  .prev-avg-time-in-task-tooltip,
  .leader-info-tooltip,
  .statistic-footer-tooltip {
    .ant-tooltip-arrow:before,
    .ant-tooltip-inner {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.navy};
    }

    .ant-tooltip-inner {
      padding-right: 16px;
      padding-left: 16px;
      p {
        margin-bottom: 4px;
      }
    }
  }

  .enterprise-summary-tooltip {
    .ant-tooltip-inner {
      padding: 8px 16px;
      color: ${({ theme }) => theme.colors.navy};
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 4px;
    }

    .ant-tooltip-content {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }

    .ant-tooltip-arrow {
      border-bottom-color: ${({ theme }) => theme.colors.white};
    }

    .ant-tooltip-arrow::before,
    .ant-tooltip-arrow-content::before {
      background: ${({ theme }) => theme.colors.white};
    }
  }

  .leader-info-tooltip {
    .ant-tooltip-inner {
      width: 170px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }

  .copy-tooltip {
    .ant-tooltip-inner {
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }
  
  .communication-tooltip.ant-tooltip {
    z-index: 900;
    .ant-tooltip-inner {
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }

  .attach-tooltip.ant-tooltip {
    .ant-tooltip-inner {
      width: 220px;
      text-align:center;
      padding: 0;

      & > div.attach-photo-title {
        padding: 7px 16px;
      }
    }
  }

  /* Switch styles */
  .ant-switch.ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.navy};
  }

  .select-recon-plan-button .ant-affix {
    pointer-events: none;

    div {
      pointer-events: auto;
    }
  }

  .create-plan-drawer-cta .ant-affix {
    text-align: center;
  }

  /* The bridge bar CSS puts an overlay over the whole page that prevents the links from being clickable */
  .bridge-bar-menu-overlay {
    display: none !important;
  }

  .ant-select-dropdown-menu {
    overflow: initial !important;
  }
  
  .ant-select-dropdown-menu-item-group-title {
    color: ${({ theme }) => theme.colors.navy} !important;
    font-size: ${({ theme }) => theme.fontSizes.sm} !important;
    font-weight: bold;
  }

  .btn-dangerous {
    color: ${({ theme }) => theme.colors.red} !important;
    border-color: ${({ theme }) => theme.colors.red} !important;
    font-weight: ${({ theme }) => theme.fontWeights.medium} !important;

    :hover {
      color: ${({ theme }) => theme.borderColors.error} !important;
      border-color: ${({ theme }) => theme.borderColors.error} !important;
    }
  }

  .modal-with-thin-button {
    .ant-btn {
      padding: 0 8px !important;
    }
  }

  .thin-button.ant-btn {
    padding: 0 8px !important;
  }

  .ant-modal-confirm-btns {
    .ant-btn:focus-visible {
      outline: 1px solid ${({ theme }) => theme.colors.navy};
      outline-offset: 2px;
      transition: none;
    }
  }

  .mention-editor {
    box-sizing: border-box;
    cursor: text;
    width: 100%;

    .public-DraftEditorPlaceholder-root {
      position: absolute;
    }
  }

  .mentions-border {
    border: ${(props) => props.theme.borders.thickSolidLightNavy} !important;
    border-right-width: 2px !important;
    border-radius: 4px;
  }

  .ant-drawer-content {
    overflow: hidden !important;
  }
  
  [id^="mentions-list"] {
    max-height: 310px;
    overflow-y: scroll;
  }

  .unassigned-text-style {
    color: ${({ theme }) => theme.colors.red};
  }

  .unassigned-text-style-italic {
    color: ${({ theme }) => theme.colors.red};
    font-style: italic;
  }

  .white-space-pre {
    .ant-typography& {
      white-space: pre !important;
    }
  }

  .d-none {
    display: none !important;
  }

  .table-row-declined {
    color: ${({ theme }) => theme.colors.transparentNavy};
    background: ${({ theme }) => theme.colors.backgroundGrayOut};
  }
  
  .table-row-normal {
    color: ${({ theme }) => theme.colors.navy};
    background: ${({ theme }) => theme.colors.white};
  }

  .table-cell-pending {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-style: italic;
    color: ${({ theme }) => theme.colors.navy};
    margin-bottom: 0em;
  }

  .table-cell-failed-to-send{
    width: 89px;
    height: 20px;
    margin-bottom: 0em;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    
    /* iRecon/iRecon Red */
    
    color: #EB002E;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .ant-notification {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: fixed;
    z-index: 1010;
    max-width: calc(100vw - 32px);
    margin-right: 24px;
  }

  .ant-notification-topLeft,
  .ant-notification-bottomLeft {
    margin-right: 0;
    margin-left: 24px;
  }
  .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationLeftFadeIn;
            animation-name: NotificationLeftFadeIn;
  }
  .ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .ant-notification-notice {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 24px;
    overflow: hidden;
    line-height: 1.5;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .ant-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .ant-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none;
  }
  .ant-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: '';
  }
  .ant-notification-notice-description {
    font-size: 14px;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
  }
  .ant-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  .anticon.ant-notification-notice-icon-success {
    color: #52c41a;
  }
  .anticon.ant-notification-notice-icon-info {
    color: #1890ff;
  }
  .anticon.ant-notification-notice-icon-warning {
    color: #faad14;
  }
  .anticon.ant-notification-notice-icon-error {
    color: #f5222d;
  }
  .ant-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
  }
  .ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67);
  }
  .ant-notification-notice-btn {
    float: right;
    margin-top: 16px;
  }
  .ant-notification .notification-fade-effect {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .ant-notification-fade-enter,
  .ant-notification-fade-appear {
    opacity: 0;
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ant-notification-fade-leave {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationFadeIn;
            animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ant-notification-fade-leave.ant-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
            animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  @-webkit-keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 16px 24px;
      padding-bottom: 16px 24px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 16px 24px;
      padding-bottom: 16px 24px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  .custom-ant-notification-notice {
    padding: 0px;

    .ant-notification-notice-message {
      padding: 10px 8px 10px 0px;
      margin-bottom: 0px;
      color: ${({ theme }) => theme.colors.navy};
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
      min-width: 100px;
    }

    .ant-notification-notice-icon {
      height: 100%;
      width: 40px;
      margin: 0px;
      font-size: ${({ theme }) => theme.fontSizes.md};
      .svg-inline--fa {
        height: 100%;
      }
      .anticon {
        height: 100%;
      }
      .anticon svg {
        height: 100%;
      }
    }

    .ant-notification-notice-icon-info {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.backgroundColors.lightBlue};
    }
  }

  .custom-ant-notification-notice-success {
    .ant-notification-notice-icon {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.backgroundColors.krypton};
      text-align: center;
    }
  }

  .custom-ant-notification-notice-success {
    .ant-notification-notice-icon {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.backgroundColors.krypton};
      text-align: center;
    }
  }

  .custom-ant-notification-notice-error {
    .ant-notification-notice-icon {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.backgroundColors.red};
      text-align: center;
    }
  }

  .custom-ant-notification-notice-info {
    .ant-notification-notice-icon {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.backgroundColors.lightBlue};
      font-size: ${({ theme }) => theme.fontSizes.lg};
      text-align: center;
    }
  }

  .custom-ant-notification-notice-with-close-icon {
    padding-right: 24px;

    .ant-notification-notice-close {
      top: calc((100% - 22px)/2);
      right: 8px;
      font-size: 16px;
    }
  }

  .seperate-item:after {
    content: '';
    position: absolute;
    left: 12px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 24px);
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
  }
  .seperate-item-top:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 0;
    height: 1px;
    width: calc(100% - 24px);
    border-top: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
  }
  .hard-disabled {
    position: relative;
  }

  .hard-disabled:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    content: '';
    background: ${({ theme }) => theme.colors.backgroundGrayOut};
    opacity: 0.4;
    cursor: not-allowed;
  }

  .ant-table-column-sorters {
    display: inline-flex  !important;
  }

  .ant-table-column-title {
    padding-right: 5px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent !important;
  }
`;
