import { takeLatest, put } from 'redux-saga/effects';
import { getWithToken, deleteWithToken, putWithToken, postWithToken } from 'api';
import { isEmpty } from 'lodash';
import { createRequestTypes, makeActionCreator, sortLineItemCatalog } from 'utils';
import { apiStatusConstants } from 'app-constants';
import { messagesActions } from 'store/messagesStore';

//#region Actions
export const LINE_ITEM_CATALOG_TYPES = createRequestTypes('LINE_ITEM_CATALOG_TYPES', [
  'GET_LINE_ITEM_CATALOG',
  'SET_LINE_ITEM_CATALOG',
  'SET_FETCH_LINE_ITEM_CATALOG_STATUS',
  'DELETE_LINE_ITEM_CATALOG',
  'DELETE_LOCAL_LINE_ITEM_CATALOG',
  'UPDATE_LINE_ITEM_CATALOG',
  'UPDATE_LOCAL_LINE_ITEM_CATALOG',
  'ADD_NEW_LINE_ITEM_CATALOG',
  'ADD_NEW_LOCAL_LINE_ITEM_CATALOG',
  'SET_IS_EDITING_OR_ADDING',
  'SET_SORTED_INFO',
  'SET_SEARCH_STRING'
]);

export const lineItemCatalogActions = {
  getLineItemCatalog: makeActionCreator(LINE_ITEM_CATALOG_TYPES.GET_LINE_ITEM_CATALOG, 'dealerId'),
  setLineItemCatalog: makeActionCreator(LINE_ITEM_CATALOG_TYPES.SET_LINE_ITEM_CATALOG, 'lineItemCatalog'),
  setFetchLineItemCatalogStatus: makeActionCreator(
    LINE_ITEM_CATALOG_TYPES.SET_FETCH_LINE_ITEM_CATALOG_STATUS,
    'fetchLineItemCatalogStatus'
  ),
  deleteLineItemCatalog: makeActionCreator(
    LINE_ITEM_CATALOG_TYPES.DELETE_LINE_ITEM_CATALOG,
    'lineItem',
    'lineItemCatalog'
  ),
  deleteLocalLineItemCatalog: makeActionCreator(LINE_ITEM_CATALOG_TYPES.DELETE_LOCAL_LINE_ITEM_CATALOG, 'lineItem'),
  updateLineItemCatalog: makeActionCreator(
    LINE_ITEM_CATALOG_TYPES.UPDATE_LINE_ITEM_CATALOG,
    'lineItem',
    'lineItemCatalog',
    'sortedInfo',
    'clearLineItemInput'
  ),
  updateLocalLineItemCatalog: makeActionCreator(LINE_ITEM_CATALOG_TYPES.UPDATE_LOCAL_LINE_ITEM_CATALOG, 'lineItem'),
  addNewLineItemCatalog: makeActionCreator(
    LINE_ITEM_CATALOG_TYPES.ADD_NEW_LINE_ITEM_CATALOG,
    'lineItem',
    'lineItemCatalog',
    'sortedInfo',
    'clearLineItemInput'
  ),
  addNewLocalLineItemCatalog: makeActionCreator(LINE_ITEM_CATALOG_TYPES.ADD_NEW_LOCAL_LINE_ITEM_CATALOG, 'lineItem'),
  setIsEditingOrAdding: makeActionCreator(LINE_ITEM_CATALOG_TYPES.SET_IS_EDITING_OR_ADDING, 'isEditingOrAdding'),
  setSortedInfo: makeActionCreator(LINE_ITEM_CATALOG_TYPES.SET_SORTED_INFO, 'sortedInfo'),
  setSearchString: makeActionCreator(LINE_ITEM_CATALOG_TYPES.SET_SEARCH_STRING, 'searchString')
};
//#endregion

//#region Reducer
const initialState = {
  lineItemCatalog: [],
  fetchLineItemCatalogStatus: apiStatusConstants.IS_FETCHING,
  isEditingOrAdding: false,
  sortedInfo: {
    field: 'description',
    order: 'ascend'
  },
  searchString: ''
};

export const lineItemCatalogReducer = (state = initialState, action) => {
  let lineItemCatalogTemp = [...state.lineItemCatalog];
  switch (action.type) {
    case LINE_ITEM_CATALOG_TYPES.SET_LINE_ITEM_CATALOG:
      if (isEmpty(action.lineItemCatalog)) {
        return {
          ...state,
          lineItemCatalog: []
        };
      }
      return {
        ...state,
        lineItemCatalog: action.lineItemCatalog
      };
    case LINE_ITEM_CATALOG_TYPES.UPDATE_LOCAL_LINE_ITEM_CATALOG:
      const indexUpdate = lineItemCatalogTemp.findIndex((item) => item.id === action.lineItem.id);
      if (indexUpdate > -1) {
        lineItemCatalogTemp[indexUpdate] = action.lineItem;
        return {
          ...state,
          lineItemCatalog: lineItemCatalogTemp
        };
      } else {
        break;
      }
    case LINE_ITEM_CATALOG_TYPES.DELETE_LOCAL_LINE_ITEM_CATALOG:
      const indexDelete = lineItemCatalogTemp.findIndex((item) => item.id === action.lineItem.id);
      if (indexDelete > -1) {
        lineItemCatalogTemp.splice(indexDelete, 1);
        return {
          ...state,
          lineItemCatalog: lineItemCatalogTemp
        };
      } else {
        break;
      }
    case LINE_ITEM_CATALOG_TYPES.ADD_NEW_LOCAL_LINE_ITEM_CATALOG:
      return {
        ...state,
        lineItemCatalog: [action.lineItem, ...lineItemCatalogTemp]
      };
    case LINE_ITEM_CATALOG_TYPES.SET_FETCH_LINE_ITEM_CATALOG_STATUS:
      return {
        ...state,
        fetchLineItemCatalogStatus: action.fetchLineItemCatalogStatus
      };
    case LINE_ITEM_CATALOG_TYPES.SET_IS_EDITING_OR_ADDING:
      return {
        ...state,
        isEditingOrAdding: action.isEditingOrAdding
      };
    case LINE_ITEM_CATALOG_TYPES.SET_SORTED_INFO:
      return {
        ...state,
        sortedInfo: action.sortedInfo,
        lineItemCatalog: sortLineItemCatalog(lineItemCatalogTemp, action.sortedInfo)
      };
    case LINE_ITEM_CATALOG_TYPES.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
//#endregion

//#region Sagas
export function* getLineItemCatalogSaga() {
  yield takeLatest(LINE_ITEM_CATALOG_TYPES.GET_LINE_ITEM_CATALOG, function* ({ dealerId }) {
    try {
      yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.IS_FETCHING));
      const lineItemCatalogData = yield getWithToken(`/api/LineItemCatalog/dealer/${dealerId}`);
      yield put(lineItemCatalogActions.setLineItemCatalog(lineItemCatalogData.items));
      yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getLineItemCatalogSaga', error);
    }
  });
}
export function* deleteLineItemCatalogSaga() {
  yield takeLatest(LINE_ITEM_CATALOG_TYPES.DELETE_LINE_ITEM_CATALOG, function* ({ lineItem, lineItemCatalog }) {
    try {
      yield put(
        messagesActions.notify('loading', 'Deleting...', {
          duration: 0.05
        })
      );
      yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.IS_FETCHING));
      yield deleteWithToken(`/api/LineItemCatalog/id/${lineItem.id}`);
      const indexDelete = lineItemCatalog.findIndex((item) => item.id === lineItem.id);
      if (indexDelete > -1) {
        lineItemCatalog.splice(indexDelete, 1);
        yield put(lineItemCatalogActions.setLineItemCatalog([...lineItemCatalog]));
        yield put(
          messagesActions.notify('success', 'Successfully deleted line item!', {
            duration: 2
          })
        );
        yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.SUCCEEDED));
      }
    } catch (error) {
      yield put(
        messagesActions.notify('error', 'Failed to delete line item!', {
          duration: 2
        })
      );
      yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.FAILED));
      devLogger.log('error in deleteLineItemCatalogSaga', error);
    }
  });
}
export function* updateLineItemCatalogSaga() {
  yield takeLatest(
    LINE_ITEM_CATALOG_TYPES.UPDATE_LINE_ITEM_CATALOG,
    function* ({ lineItem, lineItemCatalog, sortedInfo, clearLineItemInput }) {
      try {
        yield put(
          messagesActions.notify('loading', 'Updating...', {
            duration: 0.05
          })
        );
        yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.IS_FETCHING));
        const updatedLineItem = yield putWithToken(`/api/LineItemCatalog/id/${lineItem.id}`, lineItem);
        const indexUpdate = lineItemCatalog.findIndex((item) => item.id === lineItem.id);
        if (indexUpdate > -1) {
          lineItemCatalog[indexUpdate] = updatedLineItem;
          yield put(lineItemCatalogActions.setLineItemCatalog(sortLineItemCatalog(lineItemCatalog, sortedInfo)));
          clearLineItemInput();
          yield put(
            messagesActions.notify('success', 'Successfully updated line item!', {
              duration: 2
            })
          );
          yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.SUCCEEDED));
        }
      } catch (error) {
        yield put(
          messagesActions.notify('error', 'Failed to update line item!', {
            duration: 2
          })
        );
        yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.FAILED));
        devLogger.log('error in updateLineItemCatalogSaga', error);
      }
    }
  );
}

export function* addNewLineItemCatalogSaga() {
  yield takeLatest(
    LINE_ITEM_CATALOG_TYPES.ADD_NEW_LINE_ITEM_CATALOG,
    function* ({ lineItem, lineItemCatalog, sortedInfo, clearLineItemInput }) {
      try {
        yield put(
          messagesActions.notify('loading', 'Adding...', {
            duration: 0.05
          })
        );
        yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.IS_FETCHING));
        let newLineItem = yield postWithToken(`/api/LineItemCatalog`, lineItem);
        const indexAdd = lineItemCatalog.findIndex((item) => item.isAdding);
        if (indexAdd > -1) {
          lineItemCatalog[indexAdd] = newLineItem;
          yield put(lineItemCatalogActions.setLineItemCatalog(sortLineItemCatalog(lineItemCatalog, sortedInfo)));
          clearLineItemInput();
          yield put(
            messagesActions.notify('success', 'Successfully added line item!', {
              duration: 2
            })
          );
          yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.SUCCEEDED));
        }
      } catch (error) {
        yield put(
          messagesActions.notify('error', 'Failed to add line item!', {
            duration: 2
          })
        );
        yield put(lineItemCatalogActions.setFetchLineItemCatalogStatus(apiStatusConstants.FAILED));
        devLogger.log('error in addNewLineItemCatalogSaga', error);
      }
    }
  );
}
//#endregion
