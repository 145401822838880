import { Location, navigate, Redirect, Router } from '@reach/router';
import { debounce } from 'lodash';
import { setApiBaseUrl } from 'api';
import {
  contactInfo,
  features,
  IS_NEW_SIGNIN,
  VENDOR_USER_INFO,
  onboardingStatusTypes,
  RECON_ALERTS,
  profitTimeNonProd,
  profitTimeProd,
  ewsEntityTypes,
  apiStatusConstants,
  tempLogoutUrlNewDomain
} from 'app-constants';
import { AppMessages } from 'components';
import loading_mask from 'assets/images/loading-mask.svg';
import { Authorize, Login, LoginCallback, Logout } from 'components/auth';
import ConfigureIRecon from 'components/layout/configureIRecon';
import { Dashboard } from 'components/layout/dashboard';
import { Vehicles } from 'components/layout/inventory';
import { Footer, Home, Navbar, PageNotFound } from 'components/layout/nav';
import BridgeBar from 'components/layout/nav/BridgeBar';
import { Notifications } from 'components/layout/settings';
import { Tasks } from 'components/layout/tasks';
import { GridArea, GridTemplate } from 'components/styledComponents';
import { useFeatures, useLocalStorage } from 'hooks';
import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_FOUND } from 'redux-oidc';
import { createSelector } from 'reselect';
import { authSelector, oidcSelector } from 'store/authStore';
import { currentDealerActions, rootEntitySwitcherSelector } from 'store/dealersStore';
import { userAppStateActions } from 'store/userAppStateStore';
import { userSettingsActions } from 'store/userSettingsStore';
import styled from 'styled-components';
import { checkIf3rdPartyCookiesAreBlocked, getCookie, setDealerCookie } from 'utils/cookieUtils';
import { userManagerNewSignin, userManagerNewDomain } from 'utils/userManager';
import VendorWelcome from './layout/VendorWelcome';
import CoBrowseScreenShare from './common/CoBrowseScreenShare';
import GeneralSetting from './layout/GeneralSetting';
import BusinessProfile from './layout/BusinessProfile';
import { GlobalAlert } from './alerts';
import useVendorRole from 'hooks/useVendorRole';
import { HQAndProfitTimeContext } from 'utils/contexts';
import {
  analyticsEntityContextSelector,
  analyticsUserContextSelector,
  pushTagManagerUser,
  pushTagManagerReady,
  pushTagManagerContext
} from '../google-analytics';
import { useQueryParam } from 'use-query-params';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';

const appPropsSelector = createSelector(
  oidcSelector,
  authSelector,
  (state) => state.dealers.current,
  (state) => state.dealers.accessible.totalAccessibleCount,
  (oidcState, authStore, currentDealerState, totalAccessibleCount) => ({
    oidcState,
    isBridgeUser: authStore?.isBridgeUser,
    currentDealer: currentDealerState.data,
    currentDealerFetchStatus: currentDealerState.fetchStatus,
    totalAccessibleCount
  })
);

export const GlobalAlertContext = React.createContext();

const App = ({ flags }) => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const [redirectCount, setRedirectCount] = useLocalStorage('redirectCount', 0);
  const { oidcState, isBridgeUser, currentDealer, currentDealerFetchStatus, totalAccessibleCount } = useSelector(
    appPropsSelector
  );

  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const [hasRecon] = useFeatures(features.RECON);
  const scrollcontainer = useRef();
  const authWatcher = useRef();
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  //Used to inform the Inventory and Tasks pages that they need to set the query using their params held in local storage. This would typically happen when those
  //components mount (Loading last used filters), but a page will not remount if you are already on that page when you click the tab, and the saved query would not be set.
  const [forceLoadPageQueryFromStorage, setForceLoadPageQueryFromStorage] = useState(false);

  const [selectedNav, setSelectedNav] = useState();
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  const vendorUserInfoData = localStorage.getItem(VENDOR_USER_INFO);
  const vendorUserInfo = vendorUserInfoData ? JSON.parse(vendorUserInfoData) : {};
  const vendor = useSelector((state) => state.vendors.vendor);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [hasGlobalAlert, setHasGlobalAlert] = useState(true);
  // Save state show/hidden global alert by user
  const [hasClosedGlobalAlert, setClosedGlobalAlert] = useLocalStorage(RECON_ALERTS, false);
  // Only Dealer/Admin and ReconManager has this permission
  const [hasInventoryEditPermission] = useFeatures(features.INVENTORY_EDIT);
  const isProd = process.env.REACT_APP_API_BASE_URL === 'https://recon-api.irecon.vauto.com';
  const { isVendor } = useVendorRole(flags.reconVendorManagement);
  const hasReconProfitTime = (flags?.reconProfittime && !isVendor) || (isRootUser && vendorShipSelected);
  const { isAnalyticsEntityContextAvailable, analyticsEntity } = useSelector(analyticsEntityContextSelector);
  const { isAnalyticsUserContextAvailable, analyticsUser } = useSelector(analyticsUserContextSelector);
  const [ldClientInitializationState, setLdClientInitializationState] = useState(apiStatusConstants.PENDING);
  const debouncedSetLdClientState = debounce((clientState) => {
    setLdClientInitializationState(clientState);
  }, 500);
  const [scriptsAdded, setScriptsAdded] = useState(false);
  const [userId] = useQueryParam('user_id');

  const isHQ = useMemo(
    () =>
      flags?.ireconHqView &&
      (currentDealer?.entityType === ewsEntityTypes.ENTERPRISE ||
        currentDealer?.entityType === ewsEntityTypes.CONTAINER),
    [flags?.ireconHqView, currentDealer?.entityType]
  );

  useEffect(() => {
    if (hasReconProfitTime && !scriptsAdded) {
      const esmScript = document.createElement('script');
      const fontAwesomeLink = document.createElement('link');

      esmScript.src = `${
        isProd ? profitTimeProd : profitTimeNonProd
      }ProfitTimeVDPHeader/profit-time-vdp-header/profit-time-vdp-header.esm.js`;
      esmScript.async = true;
      esmScript.type = 'module';
      document.head.appendChild(esmScript);

      fontAwesomeLink.href = 'https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.14.0/css/all.min.css';
      fontAwesomeLink.rel = 'stylesheet';
      //This stylesheet should not be included for now as it is only needed for the refresh icon inside the profit time metal component.
      //We are not currently diplaying the component in that state.
      //This stylesheet also has numerous conficts with our existing fontawesome setup.
      //document.head.appendChild(fontAwesomeLink);
      setScriptsAdded(true);

      return () => {
        document.head.removeChild(esmScript);
        setScriptsAdded(false);
      };
    }
  }, [flags?.reconProfittime, isRootUser, vendorShipSelected]);

  useEffect(() => {
    if (flags?.reconAlerts) {
      const { screens, platforms, message } = flags?.reconAlerts;
      const isShowGlobalAlert = screens?.includes(selectedNav?.toUpperCase());
      const isShowGlobalAlertInWeb = platforms?.includes('WEB');
      if (hasClosedGlobalAlert) {
        setHasGlobalAlert(false);
      } else {
        setHasGlobalAlert(isShowGlobalAlert && isShowGlobalAlertInWeb && hasInventoryEditPermission && message);
      }
    }
  }, [flags?.reconAlerts, selectedNav, hasInventoryEditPermission]);

  useEffect(() => {
    if (redirectCount !== 0) {
      setRedirectCount(0); //  see comments in LoginCallback to see why the reset is done here
    }
  }, [redirectCount]);

  useEffect(() => {
    if (vendorUserInfo.vendorOnboardingStatus !== onboardingStatusTypes.VERIFIED && isNewSignin) {
      setIsOnboarded(false);
    } else {
      setIsOnboarded(true);
    }
  }, [vendorUserInfo.vendorOnboardingStatus, isNewSignin]);

  useEffect(() => {
    if (vendor?.onboardingStatus && vendor.onboardingStatus === onboardingStatusTypes.VERIFIED && isNewSignin) {
      setIsOnboarded(true);
    }
  }, [vendor?.onboardingStatus, isNewSignin]);

  const vauto3rdPartyNoticeAvailable = flags?.vauto3RdPartyNoticeAvailable;
  const vauto3rdPartyNoticeStorage = flags?.vauto3RdPartyNoticeStorage;
  const vauto3rdPartyNoticeStorageKey = 'vauto-3rd-party-notice';

  useEffect(() => {
    if (
      oidcState.user &&
      !oidcState.user.expired &&
      ldClientInitializationState === apiStatusConstants.SUCCEEDED &&
      (currentDealerFetchStatus === apiStatusConstants.SUCCEEDED || isNewSignin) &&
      !['/vendor-welcome', '/', '/callback', '/login', '/logout'].includes(window.location.pathname)
    ) {
      checkIf3rdPartyCookiesAreBlocked({
        vauto3rdPartyNoticeAvailable,
        vauto3rdPartyNoticeStorage
      }).then((areBlocked) => {
        if (areBlocked) {
          const storage = vauto3rdPartyNoticeStorage ? sessionStorage : localStorage;
          Modal.confirm({
            title: 'Third-party Cookies',
            content: (
              <>
                Changes to how your browser handles third-party cookies are impacting our ability to provide a good
                experience.
                <br />
                Click <b>Learn More</b> to learn how to resolve this issue.
              </>
            ),
            cancelText: 'Cancel',
            okText: 'Learn More',
            okType: 'danger',
            autoFocusButton: null,
            zIndex: 9999,
            onOk() {
              const marketingUrl = 'https://www.vauto.com/third-party-cookies-update/';
              window.open(marketingUrl, '_blank', 'noopener');
              storage.setItem(vauto3rdPartyNoticeStorageKey, 'true');
            },
            icon: <StyledExclamationCircleIcon icon={faExclamationCircle} className="anticon" />
          });
        }
      });
    }
  }, [oidcState.user, oidcState.user?.expired, ldClientInitializationState, window.location.pathname]);

  useEffect(() => {
    if (!oidcState.isLoadingUser) {
      authWatcher.current = setInterval(async () => {
        const currentUser = isNewSignin ? await userManagerNewSignin.getUser() : await userManagerNewDomain.getUser();
        const vAutoAuthCookie = getCookie('vAutoAuth');
        if (
          currentUser &&
          oidcState.user &&
          currentUser.profile?.recon_user_id !== oidcState.user?.profile?.recon_user_id
        ) {
          dispatch({ type: USER_FOUND, payload: currentUser });
          dispatch(currentDealerActions.switch()); // hijacking this action to clear out all data when user switches
        } else if (
          process.env.NODE_ENV !== 'development' &&
          window.location.pathname &&
          !['/vendor-welcome', '/vendor-welcome-alternate', '/login', '/callback', '/logout'].includes(
            window.location.pathname
          ) &&
          !isNewSignin
        ) {
          // All users should have a vAutoAuth cookie if they are logged in via SSO
          // but in development (local) environment, we can't read the cookie because
          // its domain is set to .vauto.com
          // So, DE145370 can't be fixed in the local environment
          if (!vAutoAuthCookie) {
            navigate(tempLogoutUrlNewDomain);
          } else if (!vAutoAuthCookie.startsWith(currentUser?.profile?.sub)) {
            // Handling the case where we still have the oidc token for the previously logged in user, but
            // someone else has logged into vAuto on this same browser and has a vAutoAuth cookie,
            // and the two users don't match. We don't want to remove the vAutoAuth cookie, just the oidc user
            // So we should just go to our normal "/logout" endpoint
            // This will trigger us to logout the oidc user and relogin as the vAuto user automatically
            localStorage.removeItem(VENDOR_USER_INFO);
            navigate('/logout');
          }
        }
        if (
          isNewSignin &&
          !currentUser &&
          !['/vendor-welcome', '/vendor-welcome-alternate', '/login', '/callback', '/logout'].includes(
            window.location.pathname
          )
        ) {
          navigate('/logout');
        }
      }, 5000); // checking the current authenticated user in localstorage every 5 secs

      // If the user is authenticated we will get the user settings to do the actions related
      if (oidcState.user?.profile?.recon_user_id && dealerId) {
        dispatch(userSettingsActions.get(oidcState.user.profile.recon_user_id, dealerId));
        dispatch(userAppStateActions.getData(oidcState.user.profile.recon_user_id, dealerId));
      }

      if (isNewSignin && vendorUserInfo && !!vendorUserInfo.id) {
        dispatch(userSettingsActions.get(vendorUserInfo.id, dealerId));
        dispatch(userAppStateActions.getData(vendorUserInfo.id, dealerId));
      }
    }

    return () => {
      if (authWatcher.current) clearInterval(authWatcher.current);
    };
  }, [oidcState, dealerId, vendorUserInfo.id]);

  const oidcStateValid = oidcState && oidcState.user && !oidcState.expired;
  const vAutoAuthCookie = getCookie('vAutoAuth');

  // All users should have a vAutoAuth cookie if they are logged in via SSO
  // but in development (local) environment, we can't read the cookie because
  // its domain is set to .vauto.com
  // So, DE145370 can't be fixed in the local environment
  const isAuthenticated =
    !oidcState.isLoadingUser &&
    oidcStateValid &&
    (process.env.NODE_ENV === 'development' || !!vAutoAuthCookie || isNewSignin);
  const reconAndVAutoUsersMatch =
    process.env.NODE_ENV === 'development' ||
    (vAutoAuthCookie && vAutoAuthCookie.startsWith(oidcState.user?.profile?.sub)); // Default to true if LD flag is not on so we go into first "if" block below

  useEffect(() => {
    if (vendorShipSelected) {
      setLdClientInitializationState(apiStatusConstants.SUCCEEDED);
      return;
    }
    if (currentDealerFetchStatus !== apiStatusConstants.SUCCEEDED) {
      // LD initialization couldn't possibly have started if the "current dealer" hasn't even been fetched
      // This helps show the "spinner" while a user switches entities using the entity switcher
      setLdClientInitializationState(apiStatusConstants.PENDING);
    }
  }, [currentDealer, currentDealerFetchStatus, vendorShipSelected]);

  useEffect(() => {
    if (currentDealer?.features && !!currentDealer.features.length) {
      if (window.location.pathname.includes('dashboard') && !currentDealer.features.includes(features.TASKS_EDIT)) {
        if (
          currentDealer.features.includes(features.INTERNAL_TECH_ONLY) ||
          currentDealer.features.includes(features.EXTERNAL_TECH_ONLY)
        ) {
          navigate('/tasks');
        } else {
          if (currentDealer.features.includes(features.INVENTORY_VIEW)) {
            navigate('/inventory');
          } else if (currentDealer.features.includes(features.TASKS_VIEW_OWN)) {
            navigate('/tasks');
          }
        }
      }
    }
  }, [currentDealer?.features]);

  useEffect(() => {
    if (ldClientInitializationState === apiStatusConstants.SUCCEEDED && !vendorShipSelected && !isNewSignin) {
      // set shared vAuto cookie
      setDealerCookie(currentDealer.vautoId, flags?.vautoRedomainingCookiePartition);
    }
  }, [flags?.vautoRedomainingCookiePartition, ldClientInitializationState]);

  useEffect(() => {
    const identifyLDUser = () => {
      if (
        isAuthenticated &&
        ((reconAndVAutoUsersMatch && currentDealerFetchStatus === apiStatusConstants.SUCCEEDED) || isNewSignin)
      ) {
        const hasDealerInformation = !!currentDealer.logicalId && !vendorShipSelected;
        //for dealers, only invoke this clause if current dealer is fetched (useUpdateEffect is not needed if we verify this)
        //not necessary for vendors (for vendors, this useEffect is only invoked once as currentDealer is always {})
        if (hasDealerInformation || isNewSignin || (isRootUser && vendorShipSelected && !currentDealer.logicalId)) {
          ldClient
            .identify({
              // The default values are a hack to get LaunchDarkly to work for vendors.
              // These constants do not correlate with any other values in the system. Do not change them
              key: hasDealerInformation ? currentDealer.logicalId : 'VND-ALL',
              name: hasDealerInformation ? currentDealer.name : 'All Vendors',
              //Keep capitalization of custom keys consistent between API, UI, and mobile
              custom: {
                ReleaseGroup: hasDealerInformation ? currentDealer.releaseGroup : 1,
                EnterpriseReleaseGroup: hasDealerInformation ? currentDealer.enterpriseReleaseGroup : 1,
                Country: currentDealer.country,
                PostalCode: currentDealer?.postalCode
              }
            })
            .then(() => {
              // This needs to be debounced so that immediate changes in dealership information don't cause
              //  a flash of content before the "spinner" reappears while LD client re-initializes
              debouncedSetLdClientState(apiStatusConstants.SUCCEEDED);
            });
        }
      }
    };

    // Set initialization status here, not within the function just before calling `ldClient.identify(...)`
    // If this method was invoked, something has already changed that should cause us to show the spinner till LD initialization is complete
    setLdClientInitializationState(apiStatusConstants.IS_FETCHING);
    identifyLDUser();
  }, [
    currentDealerFetchStatus,
    isAuthenticated,
    reconAndVAutoUsersMatch,
    isNewSignin,
    currentDealer?.logicalId,
    vendorShipSelected
  ]);

  useEffect(() => {
    // Set up google analytics data layer

    // application context
    // user context
    // entity context
    if (isAnalyticsEntityContextAvailable) {
      pushTagManagerContext(analyticsEntity);
    }

    if (isAnalyticsUserContextAvailable) {
      pushTagManagerUser(analyticsUser);
    }

    if (!isAnalyticsEntityContextAvailable || !isAnalyticsUserContextAvailable) {
      return;
    }

    pushTagManagerReady();
  }, [isAnalyticsEntityContextAvailable, analyticsEntity, isAnalyticsUserContextAvailable, analyticsUser]);

  if (oidcState.isLoadingUser) {
    return null;
  }

  const onCloseGlobalAlert = () => {
    setClosedGlobalAlert(true);
    setHasGlobalAlert(false);
  };

  if (isAuthenticated && (reconAndVAutoUsersMatch || isNewSignin)) {
    const hasOtherDealers = totalAccessibleCount !== 0;
    const forbiddenMessage = `You don't have access to iRecon${hasOtherDealers ? ' at this dealership' : ''}. Please ${
      hasOtherDealers ? 'select a different dealer, or ' : ''
    }contact Support at ${contactInfo.SUPPORT_PHONE}, if you believe this message is in error.`;
    // if LaunchDarkly recon-web-bffs flag is on then Recon-Web call api through BFF Service, otherwise Recon-Web call directly to API Recon
    setApiBaseUrl(flags.reconWebBff);

    const calculatedHeightMenu = () => {
      if (isBridgeUser) {
        if (flags?.reconAlerts && hasGlobalAlert) {
          return '149px 1fr';
        }
        return '107px 1fr';
      } else {
        if (flags?.reconAlerts && hasGlobalAlert) {
          return '114px 1fr';
        }
        return null;
      }
    };

    return (
      <HQAndProfitTimeContext.Provider value={{ isHQ, hasReconProfitTime }}>
        <AppMessages isBridgeUser={isBridgeUser} />
        {flags.supportCobrowseRecon && <CoBrowseScreenShare />}
        <StyledContainer
          hasGlobalAlert={hasGlobalAlert}
          global-alert={flags?.reconAlerts}
          grid-auto-rows={calculatedHeightMenu()}
        >
          <Navigation
            setForceLoadPageQueryFromStorage={setForceLoadPageQueryFromStorage}
            selectedNav={selectedNav}
            setSelectedNav={setSelectedNav}
            globalAlert={flags?.reconAlerts}
            onCloseGlobalAlert={onCloseGlobalAlert}
            hasGlobalAlert={hasGlobalAlert}
          />
          <GlobalAlertContext.Provider value={hasGlobalAlert ?? false}>
            <StyledContentAndFooterContainer
              has-global-alert={hasGlobalAlert && flags?.reconAlerts}
              ref={scrollcontainer}
              is-bridge-user={isBridgeUser}
            >
              <StyledContentContainer>
                {ldClientInitializationState !== apiStatusConstants.SUCCEEDED ? (
                  <>
                    <StyledLoadingDiv>
                      <img src={loading_mask} alt="Loading..." />
                    </StyledLoadingDiv>
                    {/* These routes are required to support functionality while a user isn't logged in */}
                    <Router>
                      <LoginCallback path="callback" />
                    </Router>
                  </>
                ) : (
                  <>
                    {hasRecon === false && !isRootUser && !isNewSignin && (
                      <StyledForbiddenText>{forbiddenMessage}</StyledForbiddenText>
                    )}
                    {hasRecon || isRootUser || isNewSignin ? (
                      <AuthorizedRoutes
                        scrollcontainer={scrollcontainer}
                        forceLoadPageQueryFromStorage={forceLoadPageQueryFromStorage}
                        setForceLoadPageQueryFromStorage={setForceLoadPageQueryFromStorage}
                        selectedNav={selectedNav}
                        isOnboarded={isOnboarded}
                        isHQ={isHQ}
                        userIdMatched={!!userId && vendorUserInfo?.id === userId}
                      />
                    ) : (
                      <Router>
                        <Notifications path="settings/notifications" flags={flags} />
                        <ConfigureIRecon path="configure/*" flags={flags} />

                        <Redirect from="vendor-welcome" to="/" noThrow />
                        <Login path="login" />
                        <LoginCallback path="callback" />
                        <Logout path="logout" />
                      </Router>
                    )}
                  </>
                )}
              </StyledContentContainer>
              {(!flags?.reconFooter ||
                (!window.location.pathname?.startsWith('/inventory') &&
                  !window.location.pathname?.startsWith('/tasks'))) && (
                <StyledFooterContainer>
                  <Footer />
                </StyledFooterContainer>
              )}
            </StyledContentAndFooterContainer>
          </GlobalAlertContext.Provider>
        </StyledContainer>
      </HQAndProfitTimeContext.Provider>
    );
  } else if (
    window.location.pathname &&
    ['/logout', '/login', '/callback', '/vendor-welcome', '/vendor-welcome-alternate'].indexOf(
      window.location.pathname
    ) === -1 &&
    process.env.NODE_ENV !== 'development'
  ) {
    if (!isNewSignin) {
      if (!reconAndVAutoUsersMatch) {
        // Handling the case where we still have the oidc token for the previously logged in user, but
        // EITHER:
        //     1. Someone else has logged into vAuto on this same browser and has a vAutoAuth cookie, and the two users don't match
        //     2. Or we just don't have a vAutoAuth cookie at all
        // We don't want to remove the vAutoAuth cookie if we have one, just the oidc user
        // So we should just go to our normal "/logout" endpoint
        // This will trigger us to logout the oidc user and
        //     1. Relogin as the vAuto user automatically (if we DO have a vAutoAuth cookie)
        //     2. Redirect to the login page (if we DON'T have a vAutoAuth cookie)
        navigate(`/logout?redirect_url=${encodeURIComponent(window.location.href)}`);
        // Have to return null or application breaks because nothing was explicitly returned
        return null;
      } else {
        // Clean up both the vAutoAuth cookie and the oidc token
        // Because either we're missing an oidc token, or the oidc token has expired
        localStorage.removeItem(RECON_ALERTS);
        // Encoding twice since `redirect_url` is a URL parameter within a URL parameter.
        // ie. vAutoLogoutUrl is a concatenation of URLs (ie) https://<vAuto logout url>?...&returnUrl=<iRecon url>
        // The line below adds a redirect_url to <iRecon url> which is already a querystring parameter appended to <vAuto logout url>.
        navigate(
          `${tempLogoutUrlNewDomain}?redirect_url=${encodeURIComponent(encodeURIComponent(window.location.href))}`
        );
        return null;
      }
    } else {
      navigate('/login');
      return null;
    }
  } else {
    return (
      <Router style={{ height: '100%' }}>
        <VendorWelcome path="vendor-welcome" />
        <VendorWelcome path="vendor-welcome-alternate" alternateView={true} />
        <Login path="login" default />
        <Logout path="logout" />
        <LoginCallback path="callback" />
      </Router>
    );
  }
};

const AuthorizedRoutes = withLDConsumer()(
  ({
    scrollcontainer,
    forceLoadPageQueryFromStorage,
    setForceLoadPageQueryFromStorage,
    selectedNav,
    flags,
    isOnboarded,
    isHQ,
    userIdMatched
  }) =>
    isOnboarded ? (
      <Router>
        <Home path="/" />
        {isHQ && <Redirect from="configure/*" to="/" noThrow />}
        <Authorize path="dashboard" feature={features.TASKS_EDIT}>
          <Dashboard path="/*" />
        </Authorize>
        <Authorize path="inventory" feature={features.INVENTORY_VIEW}>
          <Vehicles
            path="/*selectedVehicleId"
            scrollcontainer={scrollcontainer}
            forceLoadPageQueryFromStorage={forceLoadPageQueryFromStorage}
            setForceLoadPageQueryFromStorage={setForceLoadPageQueryFromStorage}
            selectedNav={selectedNav}
          />
        </Authorize>
        <Authorize path="tasks" feature={features.TASKS_VIEW_OWN}>
          <Tasks
            path="/*selectedTaskId"
            scrollcontainer={scrollcontainer}
            forceLoadPageQueryFromStorage={forceLoadPageQueryFromStorage}
            setForceLoadPageQueryFromStorage={setForceLoadPageQueryFromStorage}
            selectedNav={selectedNav}
          />
        </Authorize>
        <Notifications path="settings/notifications" flags={flags} />
        <Authorize path="configure" feature={features.DEALER_SETTINGS}>
          <ConfigureIRecon path="/*" flags={flags} />
        </Authorize>
        <Authorize path="business-profile">
          <BusinessProfile path="/" />
        </Authorize>
        <Login path="login" />
        <LoginCallback path="callback" />
        <Logout path="logout" />
        <PageNotFound default />
        <Redirect from="vendor-welcome" to={userIdMatched ? '/configure/dealerships' : '/'} noThrow />
      </Router>
    ) : (
      <Router>
        <GeneralSetting path="/general-setting" />
        <GeneralSetting default />
        <Login path="login" />
        <LoginCallback path="callback" />
        <Logout path="logout" />
      </Router>
    )
);

const Navigation = ({
  setForceLoadPageQueryFromStorage,
  selectedNav,
  setSelectedNav,
  globalAlert,
  onCloseGlobalAlert,
  hasGlobalAlert
}) => (
  <StyledNavbarContainer>
    <BridgeBar />
    <Location>
      {({ location }) => (
        <Navbar
          location={location}
          setForceLoadPageQueryFromStorage={setForceLoadPageQueryFromStorage}
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
        />
      )}
    </Location>
    {globalAlert && hasGlobalAlert && <GlobalAlert flagValue={globalAlert} onCloseGlobalAlert={onCloseGlobalAlert} />}
  </StyledNavbarContainer>
);

//#region Styled Components
const StyledContainer = styled(GridTemplate(`'navbar' 'contentAndFooter'`, '72px 1fr', '1fr'))`
  overflow: hidden;
`;

const calculatedContentHeight = (isBridgeUser, hasGlobalAlert) => {
  let height = 72;
  if (isBridgeUser) {
    height += 35;
  }
  if (hasGlobalAlert) {
    height += 42;
  }
  return `height: calc(100vh - ${height}px);`;
};

const StyledNavbarContainer = styled(GridArea('navbar', 1, 1))`
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
`;
const StyledContentAndFooterContainer = styled(
  GridArea(
    'contentAndFooter',
    2,
    1,
    GridTemplate(
      `'content' 
     'footer'`,
      '1fr 85px'
    )
  )
)`
  ${(props) => calculatedContentHeight(props['is-bridge-user'], props['has-global-alert'])}
  background-color: #f2f4f5;
  overflow-y: auto;
  overflow-x: hidden;
`;
const StyledContentContainer = GridArea('content', 1, 1);
const StyledFooterContainer = GridArea('footer', 2, 1);
const StyledForbiddenText = styled.h1`
  margin: 15px 48px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;

const StyledLoadingDiv = styled.div`
  height: min(90vh, 50rem);
  width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.titanium300};

  > img {
    height: 32px;
    width: 32px;
  }
`;

const StyledExclamationCircleIcon = styled(FontAwesomeIcon)`
  color: ${themeStyles.colors.red} !important;
`;
//#endregion

export default withLDConsumer()(App);
