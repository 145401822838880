import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Badge, Drawer } from 'antd';
import { IN_APP } from 'app-constants/notificationChannelsTypes.js';
import { DrawerContainer } from 'components';
import { getNewSince, Notifications } from 'components/layout/notifications';
import { CommonHeaderLabel } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import { journalActions } from 'store/journalStore';
import { userAppStateActions, userAppStateSelector } from 'store/userAppStateStore';
import { userNotificationChannelsSelector, userNotificationEventsSelector } from 'store/userSettingsStore';
import { vdpActions } from 'store/vdpStore';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';
import useDeepCompareEffect from 'use-deep-compare-effect';
import journalEventTypes from 'app-constants/journalEventTypes';
import useVendorRole from 'hooks/useVendorRole';

const notificationHistoryPropsSelector = createSelector(
  authSelector,
  userAppStateSelector,
  (authState, userAppState) => ({
    userId: authState.user.id,
    token: authState.token,
    userAppState
  })
);
const NotificationHistory = ({ flags }) => {
  const { userId, token, userAppState } = useSelector(notificationHistoryPropsSelector);
  const dispatch = useDispatch();
  const { isVendor } = useVendorRole();
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [iconHover, setIconHover] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const userNotificationChannels = useSelector(userNotificationChannelsSelector);
  const userNotificationEvents = useSelector(userNotificationEventsSelector);
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  const connection = useRef();
  const [userNotificationEventsWithFlags, setUserNotificationEventsWithFlags] = useState([]);
  const [isShowNotifications, setIsShowNotifications] = useState(false);

  useDeepCompareEffect(() => {
    let newUserNotificationEventsWithFlags = userNotificationEvents
      .filter(
        (event) =>
          event.isActive &&
          (flags.reconVendorManagement || event.notificationMessageType !== journalEventTypes.VENDOR_CONFIRMATION)
      )
      .map((e) => e.notificationMessageType);
    if (isVendor) {
      //if vendor login, always includes this notification type
      newUserNotificationEventsWithFlags.push(journalEventTypes.VENDOR_INVITATION_RECIEVED);
    }
    //When reconVendorManagement flag is off, do not include VENDOR_CONFIRMATION event type
    setUserNotificationEventsWithFlags(newUserNotificationEventsWithFlags);
  }, [flags.reconVendorManagement, userNotificationEvents]);

  useEffect(() => {
    setIsShowNotifications((prevIsShowNotification) => {
      let upComingIsShowNotificationValue =
        userNotificationChannels.includes(IN_APP) && userNotificationEventsWithFlags.length > 0;
      // if IsShowNotification change, update notificationCount
      if (prevIsShowNotification !== upComingIsShowNotificationValue) {
        setNotificationCount(0);
      }
      return upComingIsShowNotificationValue;
    });
  }, [JSON.stringify(userNotificationChannels), JSON.stringify(userNotificationEventsWithFlags)]);

  useEffect(() => {
    if (isShowNotifications) {
      setNotificationCount(getNewSince(userAppState));
      //Stop current signalR connection and then open a new connection in case refreshing auth token
      if (connection.current) connection.current.stop();
      const newConnection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_BASE_URL + '/notificationhub', {
          accessTokenFactory: () => token,
          transport: HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling | HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build();

      newConnection.on('ReceiveNotificationCount', (data) => {
        if (flags.reconNotificationCountOptimization) {
          refreshNotifications();
        } else {
          setNotificationCount(getNewSince({ ...userAppState, uncheckedNotificationCount: data }));
        }
      });
      connection.current = newConnection;
      async function start() {
        try {
          await newConnection.start();
        } catch (err) {
          console.log(err);
          setTimeout(start, 5000);
        }
      }

      start();
      fetchPage();
    } else {
      setNotificationCount(0);
    }
  }, [isShowNotifications, token]);

  useEffect(() => {
    if (isShowNotifications) {
      setNotificationCount(getNewSince(userAppState));
    }
  }, [JSON.stringify(userAppState)]);

  useEffect(() => {
    if (!isShowNotifications) {
      setNotificationCount(0);
    }
  }, [userId, isShowNotifications]);

  const fetchPage = () => {
    // Must get the app state first to avoid the badge rendering with the total
    // message count then change to unSeen Message count
    dispatch(journalActions.getUserData(userId, 1, 100, dealerId, userNotificationEventsWithFlags));
  };

  const refreshNotifications = () => {
    dispatch(journalActions.getUserData(userId, 1, 100, dealerId, userNotificationEventsWithFlags));
    dispatch(userAppStateActions.getData(userId, dealerId));
    setNotificationCount(getNewSince(userAppState));
  };

  const openDrawer = () => {
    // Detect when user open VDP from notification or not
    dispatch(vdpActions.setNotification(true));
    // Drawer has been clicked, retrieve most recent notifications from API
    if (isShowNotifications) {
      refreshNotifications();
    }

    // Once notifications have been received, update the date the user last checked their notifications are filtered by dealerId
    dispatch(userAppStateActions.setJournalHistoryChecked(userId, dealerId));

    // Information is in a state where we can show the notification drawer
    setShowNotificationDrawer(true);
  };

  const closeDrawer = () => {
    setShowNotificationDrawer(false);
  };

  return (
    <>
      <StyledNotificationHighlightContainer
        onClick={() => openDrawer()}
        onMouseEnter={() => setIconHover(true)}
        onMouseLeave={() => setIconHover(false)}
        data-selected={iconHover}
        reconDealerFilterFlag={flags?.reconDealerFilter}
      >
        <StyledNotificationContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
          {isShowNotifications ? (
            <Badge
              count={notificationCount}
              overflowCount={9}
              offset={notificationCount < 10 ? [2, -6] : [7, -6]}
              style={{ cursor: 'pointer', fontSize: '14px', backgroundColor: themeStyles.colors.red }}
            >
              <StyledBellIcon icon={faBell} data-selected={iconHover} />
            </Badge>
          ) : (
            <StyledBellIcon icon={faBell} data-selected={iconHover} />
          )}
        </StyledNotificationContainer>
      </StyledNotificationHighlightContainer>

      <Drawer
        closable={false}
        destroyOnClose={true}
        placement="right"
        width={580}
        visible={showNotificationDrawer}
        onClose={() => {
          closeDrawer();
          setIconHover(false);
        }}
        bodyStyle={{ padding: '0' }}
      >
        <DrawerContainer
          headerLabel={<CommonHeaderLabel>Notifications</CommonHeaderLabel>}
          onClose={() => closeDrawer()}
        >
          <Notifications handleCloseDrawer={() => closeDrawer()} />
        </DrawerContainer>
      </Drawer>
    </>
  );
};

//#region Styled Components
const StyledNotificationHighlightContainer = styled.div`
  display: flex;
  padding: 20px 15px 20px 0;
  background-color: ${(props) => (props['data-selected'] ? props.theme.colors.backgroundGray : 'inherit')};
  :hover {
    cursor: pointer;
  }
  ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 'height: 100%;' : '')}
`;
const StyledNotificationContainer = styled.div`
  border-left: ${({ reconDealerFilterFlag, theme }) =>
    reconDealerFilterFlag ? theme.borders.mediumSolidGray : '1px solid #eee'};
  display: flex;
  align-items: center;
  padding: 0 15px;
`;
const StyledBellIcon = styled(FontAwesomeIcon)`
  color: ${(props) => (props['data-selected'] ? 'inherit' : props.theme.colors.darkGray)};
  cursor: pointer;
  height: 20px;
`;
//#endregion

export default withLDConsumer()(NotificationHistory);
