import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown, Menu, Checkbox, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sort } from 'utils/arrayUtils';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { dashboardPreferencesTypes, statisticTagTypes } from 'app-constants';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useDebouncedEffect } from 'hooks';
import { analyticsTagConstants, pushTagManagerEvent } from 'google-analytics';

const propsSelector = createSelector(
  (state) => state.taskCategories.categoriesAll,
  (taskCategoriesContainer) => ({
    taskCategories: taskCategoriesContainer.data
  })
);

const ShowExcludedTaskCategoriesFilter = ({ metricsType = 'activeMetrics', useLocalStorageObject }) => {
  const filter =
    metricsType === statisticTagTypes.ACTIVE_METRICS
      ? dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS
      : dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS;

  const { taskCategories } = useSelector(propsSelector);
  const [value, setData, getData] = useLocalStorageObject;
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  useEffect(() => {
    if (dropdownIsOpen) {
      pushTagManagerEvent(analyticsTagConstants.filterOpened.TASK_CATEGORY_FILTER_OPENED);
    }
  }, [dropdownIsOpen]);

  //We need to get a fresh copy of what is in the localStorage
  const excludedTaskCategories = value?.[filter]?.excludedTaskCategories || [];
  const [items, setItems] = useState([...excludedTaskCategories]);

  const taskCategoriesSorted = useMemo(() => {
    const list = [...taskCategories];
    sort(list, 'name');
    return list;
  });

  const onSelectedAll = (checked) => {
    const ids = checked ? [] : taskCategories.map((item) => item.id);
    setItems(ids);
  };

  const handleOnChange = (checked, option) => {
    if (checked) {
      const index = items.indexOf(option.id);
      if (index > -1) {
        items.splice(index, 1);
      }
    } else {
      items.push(option.id);
    }
    setItems([...items]);
    pushTagManagerEvent(analyticsTagConstants.filterSet.TASK_CATEGORY_FILTER_SET);
  };

  useEffect(() => {
    setItems(value?.[filter]?.excludedTaskCategories || []);
  }, [JSON.stringify(value)]);

  //Triggers after 1 sec after the items were modifided
  useDebouncedEffect(
    () => {
      const dashboardPreferences = getData();
      if (dashboardPreferences.hasOwnProperty(filter)) {
        dashboardPreferences[filter]['excludedTaskCategories'] = [...items];
      } else {
        dashboardPreferences[filter] = {
          excludedTaskCategories: [...items]
        };
      }
      setData(dashboardPreferences);
    },
    850,
    [JSON.stringify(items)]
  );

  const totalTaskCategories = taskCategoriesSorted.length;
  const selectedCount = excludedTaskCategories.length;
  const categoryLabel =
    selectedCount === 0
      ? 'All (' + totalTaskCategories + ')'
      : totalTaskCategories - selectedCount + ' (of ' + totalTaskCategories + ')';

  const createMenu = useMemo(() => {
    const itemsCount = items.length;
    const allChecked = itemsCount === totalTaskCategories;
    const indeterminate = itemsCount >= 1 && itemsCount < totalTaskCategories;

    return (
      <CommonCheckboxMenu>
        <Menu.Item key="All">
          <CustomSelecedAll
            id="Selected-All"
            onChange={(e) => onSelectedAll(e.target.checked)}
            checked={!allChecked}
            indeterminate={indeterminate}
          >
            All
          </CustomSelecedAll>
        </Menu.Item>
        <Menu.Divider />
        {taskCategoriesSorted?.map((item) => (
          <Menu.Item key={item.id}>
            <Checkbox
              id={item.id}
              checked={!items.includes(item.id)}
              onChange={(e) => handleOnChange(e.target.checked, item)}
            >
              {item.name}
            </Checkbox>
          </Menu.Item>
        ))}
      </CommonCheckboxMenu>
    );
  }, [JSON.stringify(items)]);

  return (
    <FilterAndLabelContainer>
      <StyledLabel>Categories:</StyledLabel>
      <CustomDropdown
        trigger={['click']}
        overlay={createMenu}
        visible={dropdownIsOpen}
        onVisibleChange={setDropdownIsOpen}
      >
        <div>{categoryLabel}</div>
      </CustomDropdown>
      {selectedCount === totalTaskCategories ? (
        <Popover
          overlayStyle={{
            width: '300px'
          }}
          placement="right"
          content='Use the "Categories" menu to select one or more task categories.'
          trigger="hover"
        >
          <IconOverride icon={faInfoCircle} />
        </Popover>
      ) : (
        <></>
      )}
    </FilterAndLabelContainer>
  );
};

const IconOverride = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.red};
`;

const CustomSelecedAll = styled(Checkbox)`
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.red}!important;
    border-radius: 4px;
    border: unset;
    :after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.white};
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: ' ';
    }
  :after {
    border: unset;
  }
`;

const CustomDropdown = styled(Dropdown)`
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.infoPrimary};

  border: 1px solid
    ${(props) => (props['visible'] ? props.theme.colors.infoPrimary : props.theme.colors.transparent)}!important;
  :hover {
    text-decoration: underline;
  }
  background-color: transparent !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  height: 32px;
  padding: 6px 8px;
  gap: 4px;
  padding-bottom: 9px;
  padding-top: 8px;
`;

const CommonCheckboxMenu = styled(Menu)`
  .ant-dropdown-menu& {
    padding: 8px 0;
    border-radius: unset;
    max-height: min(488px, 100vh - 139px);
    overflow-y: auto;
    width: 106%;
    .ant-dropdown-menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      display: flex;
      align-items: center;
      padding: 0;
      height: 32px;
      font-size: ${({ theme }) => theme.fontSizes.md};
      transition: none;
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        font-size: ${({ theme }) => theme.fontSizes.md};
      }
      :hover {
        background-color: ${({ theme }) => theme.colors.backgroundGray};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
    }
  }
`;

const StyledLabel = styled.div`
  margin-left: 12px;
  padding-bottom: 1px;
`;

const FilterAndLabelContainer = styled.div.attrs({
  className: 'strong center-content'
})`
  display: flex;
`;

// default export
export default ShowExcludedTaskCategoriesFilter;
