import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Alert, Card, Checkbox, Col, List, Modal, Row, Switch, Typography } from 'antd';
import { apiStatusConstants, arraysAreEquivalent, dispositionTypes, features } from 'app-constants';
import { CommonInputNumber, CommonRedButton, StyledConfirmationModalIcon } from 'components/styledComponents';
import { useFeatures } from 'hooks';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import { currentDealerActions, currentDealerSelector } from 'store/dealersStore';
import { vehiclesActions } from 'store/vehiclesStore';
import styled, { css } from 'styled-components';
import { GlobalAlertContext } from '../../App';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../google-analytics';
import { numberThousandsSepFormatter } from 'utils/numberUtils';
import { numberOnlyParser } from 'utils/regexUtils';

const { Text, Paragraph } = Typography;

const InventorySettingsSelector = createSelector(
  authSelector,
  currentDealerSelector,
  (state) => state.vehicles,
  (authStore, currentDealersStore, vehiclesStore) => ({
    userId: authStore.user.id,
    dealer: currentDealersStore.data,
    dealerFetchStatus: currentDealersStore.fetchStatus,
    updateStatus: currentDealersStore.updateStatus,
    inProgressCountByDisposition: vehiclesStore.inProgressCountByDisposition,
    vehiclesFetchStatus: vehiclesStore.fetchStatus
  })
);
const InventorySettings = ({ flags }) => {
  const dispatch = useDispatch();
  const { isBridgeUser } = useSelector((state) => state.auth);
  const { userId, dealer, dealerFetchStatus, updateStatus, inProgressCountByDisposition, vehiclesFetchStatus } =
    useSelector(InventorySettingsSelector);
  const [hasSystemManage] = useFeatures(features.SYSTEM_MANAGE);
  const [hasAdminGeneral] = useFeatures(features.ADMINGENERAL);
  const [fetchStatus, setFetchStatus] = useState(apiStatusConstants.IS_FETCHING);
  const [originalDispositionFilterSettings, setOriginalDispositionFilterSettings] = useState(
    dealer.vehicleDispositionFilter || Object.keys(dispositionTypes)
  ); // when a dealer is setup, they won't have any dispositions selected, so we're going to set the default to all selected
  const [transientDispositionSettings, setTransientDispositionSettings] = useState(
    dealer.vehicleDispositionFilter || Object.keys(dispositionTypes)
  );
  const [originalExclusionAge, setOriginalExclusionAge] = useState(null);
  const [transientExclusionAge, setTransientExclusionAge] = useState(null);
  const [fetchStarted, setFetchStarted] = useState(null); //  used for capturing page load time for google analytics, considering the page as done loading when the taskTypesActions.getData call has completed
  const hasGlobalAlert = useContext(GlobalAlertContext);
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.GENERAL_SETTINGS_VIEWED);

  useEffect(() => {
    if (dealer?.id) {
      setFetchStarted(true);
      // get all vehicles in_progress from all dispositions so that we can figure out if there are active plans per disposition, we are not going to allow the user to uncheck a disposition if there are still active plans
      dispatch(vehiclesActions.getInProgressCountByDisposition(dealer.id));
    }
  }, [dealer?.id, userId]);

  useEffect(() => {
    let status = apiStatusConstants.IS_FETCHING;
    if (dealerFetchStatus === apiStatusConstants.SUCCEEDED && vehiclesFetchStatus === apiStatusConstants.SUCCEEDED) {
      status = apiStatusConstants.SUCCEEDED;
      const dispos = dealer.vehicleDispositionFilter || Object.keys(dispositionTypes);
      setOriginalDispositionFilterSettings(dispos); // these need to be re-set when dealer switches
      setTransientDispositionSettings(dispos);

      if (fetchStarted) {
        setFetchStarted(false);
      }
    } else if (dealerFetchStatus === apiStatusConstants.FAILED || vehiclesFetchStatus === apiStatusConstants.FAILED) {
      status = apiStatusConstants.FAILED;
    }
    setFetchStatus(status);
  }, [dealerFetchStatus, vehiclesFetchStatus]);

  const onSaveDispositions = () => {
    const newDealer = {
      ...dealer,
      vehicleDispositionFilter: transientDispositionSettings
    };
    dispatch(currentDealerActions.updateData(newDealer));
    setOriginalDispositionFilterSettings(transientDispositionSettings);
  };

  const onApplyExclude = () => {
    const data = {
      dealerId: dealer.id,
      exclusionAge: transientExclusionAge
    };
    dispatch(currentDealerActions.applyExcludeFilter(data));
    setOriginalExclusionAge(transientExclusionAge);
  };

  // Disable if we haven't made local changes or if the local state is equivalent to the master state
  const dispositionSaveButtonDisabled = arraysAreEquivalent(
    transientDispositionSettings,
    originalDispositionFilterSettings
  );
  const excludeApplyButtonDisabled =
    transientExclusionAge === originalExclusionAge || transientExclusionAge < 1 || transientExclusionAge > 10000;

  // Load if update status is pending and the button is not disabled
  const saveButtonLoading =
    (!dispositionSaveButtonDisabled || !excludeApplyButtonDisabled) && updateStatus === apiStatusConstants.PENDING;

  const onChangeAppraisalToggle = (e) => {
    const newDealer = {
      ...dealer,
      enableAppraisalToggle: e
    };
    dispatch(currentDealerActions.updateData(newDealer));
  };

  const onLoadInventory = () => {
    dispatch(currentDealerActions.loadInventory(dealer.id));
  };

  const onPullInventory = (dealer) => {
    Modal.confirm({
      title: `Import ${dealer.name}'s Inventory?`,
      content: `Are you sure you want to import ${dealer.name}'s inventory from vAuto? If you don't see the inventory within 10 minutes, please try again or contact support.`,
      okText: 'Initiate Import',
      okType: 'danger',
      cancelText: 'Cancel',
      cancelButtonProps: { className: 'btn-dangerous' },
      className: 'modal-with-thin-button',
      onOk() {
        onLoadInventory();
      },
      icon: <StyledConfirmationModalIcon icon={faQuestionCircle} className="anticon" />
    });
  };

  return (
    <>
      <StyledList
        itemLayout="vertical"
        size="large"
        has-global-alert={hasGlobalAlert ? 1 : 0}
        is-bridge-user={isBridgeUser ? 1 : 0}
      >
        <Row type="flex" justify="center">
          <Col xl={16} md={24}>
            {
              {
                [apiStatusConstants.SUCCEEDED]: (
                  <>
                    <DispositionFilterSettingsCard
                      loading={false}
                      filterSettings={transientDispositionSettings}
                      originalDispositionFilterSettings={originalDispositionFilterSettings}
                      onChange={setTransientDispositionSettings}
                      inProgressCountByDisposition={inProgressCountByDisposition}
                      saveButtonLoading={saveButtonLoading}
                      dispositionSaveButtonDisabled={dispositionSaveButtonDisabled}
                      onSaveDispositions={onSaveDispositions}
                    />
                    {hasSystemManage && (
                      <>
                        <ExcludeVehiclesSettingsCard
                          loading={false}
                          filterSettings={transientExclusionAge}
                          onChange={setTransientExclusionAge}
                          saveButtonLoading={saveButtonLoading}
                          excludeApplyButtonDisabled={excludeApplyButtonDisabled}
                          onApplyExclude={onApplyExclude}
                        />
                      </>
                    )}
                    {hasSystemManage && (
                      <>
                        <EnableAppraisalSettingCard
                          enableAppraisalSetting={dealer?.enableAppraisalToggle ?? false}
                          onChange={onChangeAppraisalToggle}
                        />
                      </>
                    )}
                    {hasAdminGeneral && (
                      <>
                        <InventoryLoaderSettingCard onPullInventory={onPullInventory} dealer={dealer} />
                      </>
                    )}
                  </>
                ),
                [apiStatusConstants.IS_FETCHING]: <DispositionFilterSettingsCard loading={true} />,
                [apiStatusConstants.FAILED]: (
                  <Alert
                    message="Error"
                    description="There was an issue retrieving your settings."
                    type="error"
                    showIcon
                  />
                )
              }[fetchStatus]
            }
          </Col>
        </Row>
      </StyledList>
    </>
  );
};

const DispositionFilterSettingsCard = ({
  filterSettings,
  originalDispositionFilterSettings,
  loading,
  onChange,
  inProgressCountByDisposition,
  saveButtonLoading,
  dispositionSaveButtonDisabled,
  onSaveDispositions
}) => {
  return (
    <>
      <List.Item>
        <StyledCard loading={loading} title={'Global Disposition'}>
          <Paragraph>Limit the inventory that users can see to focus them on the inventory that matters.</Paragraph>
          {!loading && (
            <Checkbox.Group onChange={onChange} value={filterSettings}>
              {Object.keys(dispositionTypes).map((key) => {
                const disabled =
                  originalDispositionFilterSettings &&
                  originalDispositionFilterSettings.includes(key) &&
                  inProgressCountByDisposition[key] > 0;
                return (
                  <Row key={key}>
                    <>
                      <StyledCheckbox disabled={disabled} value={key}>
                        {dispositionTypes[key]}
                      </StyledCheckbox>
                      {disabled && (
                        <StyledText>{`This disposition cannot be unchecked until all vehicles (${inProgressCountByDisposition[key]}) have completed their current plan`}</StyledText>
                      )}
                    </>
                  </Row>
                );
              })}
            </Checkbox.Group>
          )}
          <div style={{ textAlign: 'right' }}>
            <CommonRedButton
              loading={saveButtonLoading}
              disabled={dispositionSaveButtonDisabled}
              onClick={onSaveDispositions}
            >
              Save
            </CommonRedButton>
          </div>
        </StyledCard>
      </List.Item>
    </>
  );
};

const ExcludeVehiclesSettingsCard = ({
  filterSettings,
  loading,
  onChange,
  saveButtonLoading,
  excludeApplyButtonDisabled,
  onApplyExclude
}) => {
  return (
    <List.Item>
      <StyledCard loading={loading} title={'Exclude Vehicles'}>
        <Paragraph>
          Exclude vehicles from reconditioning in bulk below. Vehicles with active recon plans cannot be excluded.
        </Paragraph>
        {!loading && (
          <>
            <StyledLabel>Exclude Vehicles with an age greater than&nbsp;</StyledLabel>
            <CommonInputNumber
              min={1}
              max={10000}
              onChange={onChange}
              value={filterSettings}
              formatter={numberThousandsSepFormatter}
              parser={numberOnlyParser}
            />
            <StyledLabel>&nbsp;days</StyledLabel>
          </>
        )}
        <div style={{ textAlign: 'right' }}>
          <CommonRedButton loading={saveButtonLoading} disabled={excludeApplyButtonDisabled} onClick={onApplyExclude}>
            Apply
          </CommonRedButton>
        </div>
      </StyledCard>
    </List.Item>
  );
};

const EnableAppraisalSettingCard = ({ enableAppraisalSetting, onChange }) => {
  return (
    <>
      <List.Item>
        <StyledCard
          title={'Enable Appraisal Toggle'}
          extra={<Switch checked={enableAppraisalSetting} onChange={onChange} />}
        >
          <Text>
            Enabling this setting will make vAuto appraisal data available in the appraisal toggle when creating or
            editing a vehicle's reconditioning plan
          </Text>
        </StyledCard>
      </List.Item>
    </>
  );
};

const InventoryLoaderSettingCard = ({ onPullInventory, dealer }) => {
  return (
    <>
      <List.Item>
        <StyledCard title={'Inventory Loader'}>
          <Paragraph>Synchronize {dealer.name}'s iRecon vehicle inventory to match Provision/ProfitTime.</Paragraph>
          <div style={{ textAlign: 'right' }}>
            <CommonRedButton onClick={() => onPullInventory(dealer)}>Sync Inventory</CommonRedButton>
          </div>
        </StyledCard>
      </List.Item>
    </>
  );
};

//#region Styled Components
const StyledCard = styled(Card)`
  .ant-card-head {
    padding: 0 16px;
  }
  .ant-card-body {
    padding: 16px;
    > div.ant-typography {
      margin-bottom: 10px;
    }
    > .ant-checkbox-group {
      margin-left: 8px;
    }
    .ant-checkbox-disabled + span {
      color: inherit;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`;

const StyledList = styled(List).attrs({ split: false })`
  .ant-list& {
    display: flex;
    ${(props) =>
      css`
        overflow: auto;
        padding-right: 24px;
      `}
      .ant-spin-nested-loading {
        flex: 1;
      }
      .ant-list-item {
        padding: 0;
        margin-bottom: 8px;
      }
    }
  }
`;
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.titanium};
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.infoPrimary};
  }
  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;
const StyledText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-left: 24px;
  color: ${({ theme }) => theme.colors.darkGray};
`;
const StyledLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.sm};
`;
//#endregion

export default InventorySettings;
