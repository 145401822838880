import * as yup from 'yup';
import { regExp } from 'app-constants/regExp';

export const countries = ['United States', 'Canada'];

export const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const validationSchema = yup.object({
  name: yup.string().trim().required('Business name is required.'),
  phone: yup
    .string()
    .trim()
    .matches(regExp.PHONE, 'Valid phone number is required.')
    .required('Valid phone number is required.'),
  address: yup.string().trim().required('Street name and number are required.'),
  address2: yup.string().trim(),
  email: yup.string().trim().email('Valid email is required.').required('Valid email is required.'),
  state: yup.string().trim().required('Required.'),
  city: yup.string().trim().required('City is required.'),
  postalCode: yup
    .string()
    .trim()

    .test('validate-postal-code', 'Invalid.', (value, context) => {
      let country = context.parent.country;

      if (country && value) {
        if (country === 'Canada') {
          return regExp.CANADA_POSTAL_CODE.test(value);
        } else if (country === 'United States') {
          return regExp.US_POSTAL_CODE.test(value);
        }
      }

      return false;
    })
    .required('Required.')
});

export const forms = [
  {
    title: 'General Details',
    showTitle: true,
    key: 'generalDetails',
    data: [
      {
        key: 'forms:1',
        label: 'Business Name',
        name: 'name',
        placeholder: '',
        required: true,
        maxLength: 50
      },
      {
        key: 'forms:2',
        label: 'Business Phone',
        name: 'phone',
        placeholder: '###-###-####',
        required: true
      },
      {
        key: 'forms:3',
        label: 'Business Email',
        name: 'email',
        placeholder: 'Email@example.com',
        required: true
      }
    ]
  },
  {
    title: 'Business Address',
    key: 'businessAddress',
    showTitle: true,
    data: [
      {
        key: 'forms-1:0',
        label: 'Country',
        name: 'country',
        placeholder: 'United States',
        required: true,
        dropdown: true,
        data: countries
      },
      {
        key: 'forms-1:1',
        label: 'Street Address',
        name: 'address',
        placeholder: '',
        required: true,
        maxLength: 50
      },
      {
        key: 'forms-1:2',
        label: 'Suite/Bldg/Room/Other',
        name: 'address2',
        placeholder: '',
        required: false,
        maxLength: 50
      },
      {
        key: 'forms-1:3',
        label: 'City',
        name: 'city',
        placeholder: '',
        required: true,
        size: 16,
        sizeSM: 12,
        sizeSmall: 24,
        maxLength: 50
      },
      {
        key: 'forms-1:4',
        label: 'State',
        labelCanada: 'Province',
        name: 'state',
        placeholder: '',
        required: true,
        size: 3,
        sizeSM: 5,
        sizeSmall: 14,
        dropdown: true,
        data: {
          US: states,
          CA: provinces
        }
      },
      {
        key: 'forms-1:5',
        label: 'ZIP Code',
        labelCanada: 'Postal Code',
        name: 'postalCode',
        placeholder: '',
        required: true,
        size: 5,
        sizeSM: 7,
        sizeSmall: 10
      }
    ]
  }
];
