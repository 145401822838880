// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyles from 'styles/globalStyles';
import { userManagerNewSignin, userManagerNewDomain } from 'utils/userManager';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from 'store/configureStore';
import { globalHistory } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import { App } from 'components';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/appTheme';
import { OidcProvider } from 'redux-oidc';
import { devLogger } from 'utils/logging';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { IS_NEW_SIGNIN, VENDOR_USER_INFO } from 'app-constants';
import { initTagManager } from './google-analytics';
//global functions that can be used in any file
global.devLogger = devLogger;

//only load the webapp over https
//todo: it seems like this should be a concern of the ELB or ingress controller...
if (window.location.hostname !== 'localhost' && window.location.protocol === 'http:') {
  window.location.protocol = 'https:';
} else {
  initTagManager();
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';

  if (isNewSignin) {
    userManagerNewSignin.events.addAccessTokenExpiring(() => {
      userManagerNewSignin.signinSilent({ prompt: 'login' }).catch((err) => {
        userManagerNewSignin.events._raiseSilentRenewError(err);
        localStorage.removeItem(VENDOR_USER_INFO);
      });
    });
  }

  const store = configureStore();

  (async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LD_KEY
    });

    ReactDOM.render(
      <Provider store={store}>
        <OidcProvider store={store} userManager={isNewSignin ? userManagerNewSignin : userManagerNewDomain}>
          <ThemeProvider theme={theme}>
            <QueryParamProvider {...{ path: '/' }} reachHistory={globalHistory}>
              <GlobalStyles />
              <LDProvider>
                <App />
              </LDProvider>
            </QueryParamProvider>
          </ThemeProvider>
        </OidcProvider>
      </Provider>,
      document.getElementById('root')
    );
  })();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
