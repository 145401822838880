import { delay, call } from 'redux-saga/effects';

/** 
 * This helper function allows you to set a minimum time you want your function generator code to take to execute
 * @param {function*} codeToExecute function generator code to execute
 * @param {number} minimumExecutionTime the minimum time the code should take to execute
 */
export function* executeWithMinimumTime(codeToExecute, minimumExecutionTime) {
  const startTime = new Date();
  const result = yield call(codeToExecute);
  const endTime = new Date();
  const elapsedTime = endTime - startTime;
  const delayAdditionallyBy = minimumExecutionTime - elapsedTime;
  if (minimumExecutionTime !== null && delayAdditionallyBy > 0) {
    yield delay(delayAdditionallyBy)
  } 

  return result;
}