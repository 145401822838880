import { Table as AntDesignTable } from 'antd4';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const Table = ({
  bordered = false,
  columnsDef,
  dataSource,
  scroll,
  rowClassName,
  showInnerBorders,
  isFetching,
  fetchFailed
}) => {
  const columns = columnsDef.map((col) => {
    return {
      ...col
    };
  });

  const [tableBodyHasVerticalScrollbar, setTableBodyHasVerticalScrollbar] = useState(false);
  const antTableDiv = document.getElementsByClassName('ant-table-body')
    ? document.getElementsByClassName('ant-table-body')[0]
    : null;

  const checkIfTableHasVerticalScrollBar = () => {
    const antTableBodyDiv = document.getElementsByClassName('ant-table-body')
      ? document.getElementsByClassName('ant-table-body')[0]
      : null;
    if (antTableBodyDiv) {
      let hasVerticalScrollbar = !!antTableBodyDiv?.scrollTop;
      if (!hasVerticalScrollbar) {
        antTableBodyDiv.scrollTop = 1;
        hasVerticalScrollbar = !!antTableBodyDiv.scrollTop;
        antTableBodyDiv.scrollTop = 0;
      }
      setTableBodyHasVerticalScrollbar(hasVerticalScrollbar);
    }
  };

  useEffect(() => {
    checkIfTableHasVerticalScrollBar();
  }, [antTableDiv]);

  useEffect(() => {
    window.addEventListener('resize', checkIfTableHasVerticalScrollBar);
    // cleanup func
    return () => window.removeEventListener('resize', checkIfTableHasVerticalScrollBar);
  }, []);

  return !isFetching ? (
    <>
      <StyledTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record?.id}
        pagination={false}
        bordered={bordered}
        align="right"
        showSorterTooltip={false}
        scroll={scroll}
        rowClassName={rowClassName}
        showInnerBorders={showInnerBorders}
        fetchFailed={fetchFailed}
        tableBodyHasVerticalScrollbar={tableBodyHasVerticalScrollbar}
      />
    </>
  ) : (
    <SkeletonContainer>
      {Array.from(Array(11)).map((_, i) => (
        <SkeletonRowContainer key={uuidv4()}>
          <Skeleton width="100%" height="32px" />
        </SkeletonRowContainer>
      ))}
    </SkeletonContainer>
  );
};

const StyledTable = styled(AntDesignTable).attrs((props) => ({
  className: 'mediumBorder',
  locale: {
    emptyText: props.fetchFailed ? 'Data failure. Please refresh the page to reload this data.' : 'No results found'
  }
}))`
  .ant-table {
    table-layout: auto;
    width: 100%;
  }

  // must override inline style set by Ant Design
  .ant-table-body {
    overflow: overlay !important;
  }

  // Fix bug where scrollbar on header is displayed when table body has no scrollbar
  // This leads to misaligned headers and columns
  ${({ tableBodyHasVerticalScrollbar }) =>
    tableBodyHasVerticalScrollbar ? '' : '.ant-table-header { colgroup col:last-child { width: 0px !important;}}'};

  .ant-table-thead {
    & > tr > th {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.tableHeaderFontColor};
      cursor: ${(props) => (props.isEditing ? 'not-allowed !important' : 'pointer')};
      background: ${({ theme }) => theme.colors.titanium300};
      .ant-table-column-title {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.navy};
        font-size: ${({ theme }) => theme.fontSizes.sm};
        line-height: ${({ theme }) => theme.lineHeights.ss};
        margin-top: 3px;
      }
      border-bottom: ${({ theme }) => theme.borders.mediumSecondaryGrey};
    }

    th {
      white-space: nowrap;
      table-layout: auto;
    }

    th:first-child {
      border-right: ${(props) => props.showInnerBorders && (({ theme }) => theme.borders.mediumSecondaryGrey)};
      border-bottom: ${({ theme }) => theme.borders.mediumSecondaryGrey};
    }

    .ant-table-cell-fix-right-first {
      border-left: ${(props) => props.showInnerBorders && (({ theme }) => theme.borders.mediumSecondaryGrey)};
      right: 0px !important;
    }

    // Fix bug where headers and columns are misaligned as the right CSS value is not correct
    ${({ tableBodyHasVerticalScrollbar }) =>
      tableBodyHasVerticalScrollbar ? '.vendor-operation-col-header { right: 8px !important; }' : ''};

    th:first-child,
    th:nth-last-child(1),
    th:nth-last-child(2) {
      z-index: 3;
    }

    .ant-table-column-sorters {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: start;
    }

    .ant-table-column-title {
      position: relative;
      z-index: 1;
    }

    .ant-table-column-sorter-inner {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: ${({ theme }) => theme.borderColors.blue};
    }
  }

  .ant-table-tbody {
    tr {
      line-height: 3;
      flex: none;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
    }

    tr > td.ant-table-column-sort {
      background: inherit;
    }

    td {
      position: static;
      border-left: none;
      border-right: none;
      padding: 4px 16px;
      border-bottom: ${({ theme }) => theme.borders.mediumSecondaryGrey};
      border-top: none;
      color: ${(props) => props.fetchFailed && (({ theme }) => theme.colors.red)};
      font-weight: ${(props) => props.fetchFailed && (({ theme }) => theme.fontWeights.medium)};
    }

    .ant-table-cell-row-hover {
      background: ${({ theme }) => theme.colors.titanium300} !important;
    }

    td:first-child,
    td:last-child {
      background: inherit;
      z-index: 3;
      border-left: none;
      border-right: ${(props) => props.showInnerBorders && (({ theme }) => theme.borders.mediumSecondaryGrey)};
      border-radius: 0;
      white-space: nowrap;
      flex: 1 0 auto;
    }

    td:first-child {
      border-right: ${(props) => props.showInnerBorders && (({ theme }) => theme.borders.mediumSecondaryGrey)};
      border-left: none;
    }

    td:last-child {
      border-left: ${(props) => props.showInnerBorders && (({ theme }) => theme.borders.mediumSecondaryGrey)};
      border-right: none;
    }

    > tr.ant-table-row-level-1.ant-table-row,
    > tr.ant-table-row-level-1.ant-table-row td {
      border-bottom: none;
    }
    > tr.ant-table-row-level-1.ant-table-row td:nth-child(1) {
      padding-left: 40px;
    }
    > tr.ant-table-row-level-1.lastUserRow.ant-table-row,
    > tr.ant-table-row-level-1.lastUserRow.ant-table-row td {
      border-bottom: ${({ theme }) => theme.borders.tableRowBorder};
    }

    .input-error {
      border-color: #eb002e !important;
    }

    // override default opacity set by antd
    td.ant-table-column-sort {
      background: none;
    }

    .ant-table-expanded-row-fixed {
      display: inline;
    }
  }

  .ant-table-placeholder {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
    line-height: 17px;
  }

  .ant-table-cell-fix-right {
    right: 0px !important;
  }
`;

const SkeletonContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const SkeletonRowContainer = styled.div`
  margin-bottom: 8px;
`;

export default Table;
