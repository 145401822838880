import React, { useEffect, useState } from 'react';
import { Form, Input, Radio } from 'antd';
import { vendorUserRoles } from 'app-constants/vendorUserConstants';
import { Stack } from 'components';
import { StyledForm, StyledFormItem } from 'components/layout/settings/common/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { vendorActions } from 'store/vendorStore';
import styled from 'styled-components';
import { formatPhoneNumber } from '../../../../utils/numberUtils';
import { CommonRedButton } from '../../../styledComponents';
import InputErrorMessage from '../../../common/InputErrorMessage';
import { apiStatusConstants } from '../../../../app-constants';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const MAX_MESSAGE_LENGTH = 120;
const MAX_NAME_LENGTH = 50;

const AddUsersForm = ({ form, closeDrawer, vendor, vendorUsers, postStatus }) => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(postStatus === apiStatusConstants.PENDING);
  const { getFieldDecorator, getFieldsError, getFieldsValue, validateFields, resetFields } = form;
  const defaultRole = vendorUserRoles.find((role) => role.isDefaultRole === true);
  const { isRootUser, vendorShipSelected, currentId } = useSelector(rootEntitySwitcherSelector);

  useEffect(() => {
    if (postStatus === apiStatusConstants.PENDING) {
      setIsSaving(true);
    } else if (postStatus === apiStatusConstants.SUCCEEDED) {
      resetFields();
      closeDrawer();
      setIsSaving(false);
      dispatch(vendorActions.setPostStatus(''));
    } else {
      setIsSaving(false);
      dispatch(vendorActions.setPostStatus(''));
    }
  }, [postStatus]);

  const hasFormErrors = () => {
    const formErrors = getFieldsError();
    return Object.keys(formErrors).some((field) => formErrors[field]);
  };

  const hasUndefinedValues = () => {
    const formValues = getFieldsValue();
    return Object.entries(formValues).some((field) => field[1] === undefined);
  };

  const isEmailExists = (email) => {
    return vendorUsers.find((t) => t.email.toLowerCase() === email.toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        saveUser(values);
      }
    });
  };

  const saveUser = (values) => {
    const roleObj = vendorUserRoles.find((role) => role.name === values.role);
    const inviteMessage = values.inviteMessage.trim();
    const { vendorId } = vendor;

    let vendorIdToUse;
    if (isRootUser && vendorShipSelected && currentId) {
      vendorIdToUse = currentId;
    } else {
      vendorIdToUse = vendorId;
    }

    if (vendorIdToUse) {
      const newUser = {
        vendorId: vendorIdToUse,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        role: { id: roleObj.id, name: roleObj.label },
        phone: formatPhoneNumber(values.phone.trim()),
        email: values.email.trim(),
        userName: values.email.trim(),
        preferredContactMethodType: 'EMAIL',
        isPrimaryContact: false
      };

      let successMessage = `${newUser.firstName} ${newUser.lastName} was invited to be one of your Users`;
      let errorMessage = 'An error occurred while adding the user';
      dispatch(vendorActions.addVendorUser(vendorIdToUse, inviteMessage, newUser, successMessage, errorMessage));
    }
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit}>
        <StyledFormItemsContainer>
          <Stack horizontal>
            <StyledFormItem label="First Name" style={{ width: '100%', marginRight: 4, marginBottom: 8 }}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value.trim()) {
                        rule.message = <InputErrorMessage>First Name is required</InputErrorMessage>;
                      }
                      if (value.trim().length > MAX_NAME_LENGTH) {
                        rule.message = (
                          <InputErrorMessage>First Name cannot exceed {MAX_NAME_LENGTH} characters</InputErrorMessage>
                        );
                      }
                      if (rule.message) {
                        return Promise.reject(rule.message);
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              })(
                <Input
                  size="large"
                  placeholder="New user first name"
                  style={{ fontSize: '14px' }}
                  maxLength={MAX_NAME_LENGTH + 1}
                />
              )}
            </StyledFormItem>
            <StyledFormItem label="Last Name" style={{ width: '100%', marginLeft: 4, marginBottom: 8 }}>
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value.trim()) {
                        rule.message = <InputErrorMessage>Last Name is required</InputErrorMessage>;
                      }
                      if (value.trim().length > MAX_NAME_LENGTH) {
                        rule.message = (
                          <InputErrorMessage>Last Name cannot exceed {MAX_NAME_LENGTH} characters</InputErrorMessage>
                        );
                      }
                      if (rule.message) {
                        return Promise.reject(rule.message);
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              })(
                <Input
                  size="large"
                  placeholder="New user last name"
                  style={{ fontSize: '14px' }}
                  maxLength={MAX_NAME_LENGTH + 1}
                />
              )}
            </StyledFormItem>
          </Stack>
          <StyledFormItem label="User Role" style={{ marginBottom: 8 }}>
            {getFieldDecorator('role', {
              initialValue: defaultRole.name,
              rules: [
                {
                  required: true
                }
              ]
            })(
              <Radio.Group>
                <StyledRow>
                  {vendorUserRoles.map((role) => (
                    <Radio value={role.name} key={role.id} style={{ fontSize: 16 }}>
                      {role.label}
                    </Radio>
                  ))}
                </StyledRow>
              </Radio.Group>
            )}
          </StyledFormItem>
          <StyledFormItem label="Phone Number" style={{ marginBottom: 8 }}>
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    const globalRegex = /^\(?([2-9][0-8]\d)\)?[-. ]?([2-9]\d{2})[-. ]?(\d{4})$/;

                    if (!globalRegex.test(value)) {
                      rule.message = <InputErrorMessage>Valid phone number is required</InputErrorMessage>;
                    }

                    if (!value) {
                      rule.message = <InputErrorMessage>Phone Number is required</InputErrorMessage>;
                    }

                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                }
              ]
            })(<Input placeholder="555-555-5555" size="large" style={{ fontSize: '14px' }} />)}
          </StyledFormItem>
          <StyledFormItem label="Email" style={{ marginBottom: 8 }}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      rule.message = <InputErrorMessage>Email is required</InputErrorMessage>;
                    }
                    if (isEmailExists(value)) {
                      rule.message = (
                        <InputErrorMessage>
                          This user already exists. Please enter a different email address.
                        </InputErrorMessage>
                      );
                    }
                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                },
                {
                  type: 'email',
                  message: <InputErrorMessage>Valid email address is required</InputErrorMessage>
                }
              ]
            })(<Input placeholder="New user email address" size="large" style={{ fontSize: '14px' }} />)}
          </StyledFormItem>
          <StyledFormItem label="Message to User" style={{ marginBottom: 8 }}>
            {getFieldDecorator('inviteMessage', {
              initialValue: '',
              rules: [
                {
                  required: false,
                  validator: (rule, value) => {
                    if (value.trim().length > MAX_MESSAGE_LENGTH) {
                      rule.message = (
                        <InputErrorMessage>Message cannot exceed {MAX_MESSAGE_LENGTH} characters</InputErrorMessage>
                      );
                    }
                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                }
              ]
            })(
              <Input.TextArea
                placeholder="Include a message that will appear in the invitation email"
                rows={6}
                maxLength={MAX_MESSAGE_LENGTH + 1}
              />
            )}
          </StyledFormItem>
        </StyledFormItemsContainer>
        <StyledButtonContainer>
          <CommonRedButton onClick={closeDrawer} className="ant-btn-background-ghost" disabled={isSaving}>
            Cancel
          </CommonRedButton>
          <StyledSubmitButton disabled={hasUndefinedValues() || hasFormErrors()} loading={isSaving}>
            {isSaving ? 'Sending' : 'Send Invite'}
          </StyledSubmitButton>
        </StyledButtonContainer>
      </StyledForm>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* add margin below inputs to match text area */
  .ant-input {
    margin-bottom: 4px;
  }
  /* move asterisks to right */
  .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    float: left;

    &::before {
      margin-left: 4px;
    }
  }
  /* radio styling */
  label.ant-radio-wrapper {
    padding: 0px;
  }
  .ant-radio-wrapper:hover:not(.ant-radio-wrapper-disabled) {
    background-color: transparent;
  }
  label.ant-radio-wrapper + label.ant-radio-wrapper {
    margin-left: 24px;
  }
  label.ant-radio-wrapper span {
    padding-right: 0px;
  }
`;
const StyledFormItemsContainer = styled.div`
  padding: 24px;
  margin-bottom: 50px;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const StyledButtonContainer = styled(StyledRow)`
  padding: 20px 24px;
  justify-content: end;
  * + * {
    margin-left: 8px;
  }
  border-top: 1px solid lightgrey;
  position: absolute;
  bottom: 0;
  background-color: white;
`;
const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})``;

export default Form.create()(AddUsersForm);
