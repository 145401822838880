import React, { useContext } from 'react';
import styled from 'styled-components';
import { TaskContext } from '../helpers/context';
import { RECON_PLAN } from 'app-constants/planTypes';
import { Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { useGetMenuDropdownData } from '../TaskMenu/helpers/hooks';
import { getOnClickHandler } from '../TaskMenu/helpers/functions';
import { CommonLinkButton } from 'components/styledComponents';
import { theme } from 'styles/appTheme';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { messagesByTaskIdSelector } from 'store/vdpStore';

const MessageCountSegment = ({ countMessagesTask, flags }) => {
  const { planSource } = useContext(TaskContext);
  return planSource === RECON_PLAN ? (
    <MessageCountForReconTask countMessagesTask={countMessagesTask} flags={flags} />
  ) : null;
};

const MessageCountForReconTask = ({ countMessagesTask, flags }) => {
  /**
   * If messageCount >= 1 then message count indicator is displayed, otherwise not displayed
   **/
  const { planSource, reconPlanFeatures, task, ...taskAccessoryInfo } = useGetMenuDropdownData(flags);
  const hasEditTasks = reconPlanFeatures?.[0] && !countMessagesTask;
  const onClickHandler = getOnClickHandler('message', task, planSource, taskAccessoryInfo);
  const messages = useSelector(messagesByTaskIdSelector(task.id));
  return messages && messages.length > 0 ? (
    <MessageCountIndicator
      hasEditTasks={hasEditTasks}
      onClickHandler={onClickHandler}
      messages={messages}
      reconTaskCollapseFlag={flags.reconTaskCollapse}
    />
  ) : null;
};

const MessageCountIndicator = ({ hasEditTasks, onClickHandler, messages, reconTaskCollapseFlag }) =>
  reconTaskCollapseFlag ? (
    <CommonLinkButton
      style={{
        width: '32px',
        height: '32px',
        color: theme.colors.darkGray,
        fontSize: theme.fontSizes.xs
      }}
      onClick={hasEditTasks ? onClickHandler : () => false}
    >
      <Badge
        style={{ backgroundColor: ({ theme }) => theme.backgroundColors.red, border: theme.borderColors.white }}
        dot={false}
      >
        <StyledCommentIcon recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0} icon={faCommentAlt} />
      </Badge>
    </CommonLinkButton>
  ) : (
    <StyledMessageCountIndicator>
      <CommonLinkButton
        style={{
          height: '16px',
          color: theme.colors.darkGray,
          fontWeight: theme.fontWeights.medium,
          fontSize: theme.fontSizes.xs,
          cursor: hasEditTasks ? 'pointer' : 'default'
        }}
        onClick={hasEditTasks ? onClickHandler : () => false}
      >
        <StyledCommentIcon recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0} icon={faCommentAlt} />
        <span style={{ verticalAlign: 'top' }}>
          {messages.length} {messages.length > 1 ? 'messages' : 'message'}
        </span>
      </CommonLinkButton>
    </StyledMessageCountIndicator>
  );

//#region Styled Components
const StyledMessageCountIndicator = styled.div`
  margin: 8px 0px 6px 0px;
  line-height: 1;
`;
const StyledCommentIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => (props['recon-task-collapse-flag'] ? props.theme.fontSizes.xs : props.theme.fontSizes.md)};
  margin-right: ${(props) => (props['recon-task-collapse-flag'] ? '' : '6px')};
`;
//#endregion

export default withLDConsumer()(MessageCountSegment);
