import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { omit } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { journalActions, getTaskHistoryLogSelector } from 'store/journalStore';
import { apiStatusConstants } from 'app-constants';
import { LoadingCenter } from 'components';
import TaskHistoryDetail from './TaskHistoryDetail';

const TaskHistory = ({ task, documents }) => {
  const dispatch = useDispatch();
  const { taskHistoryData, taskHistoryFetchStatus } = useSelector(getTaskHistoryLogSelector());

  useDeepCompareEffect(() => {
    dispatch(journalActions.getTaskHistoryData(task.id, 1, 1000));
  }, [documents?.length, omit(task, ['needsApproval', 'completedCost'])]);

  return taskHistoryFetchStatus === apiStatusConstants.IS_FETCHING ? (
    <LoadingCenter />
  ) : (
    <StyledList
      locale={{ emptyText: 'No history to display' }}
      dataSource={taskHistoryData}
      renderItem={(taskHistoryEvent) => <TaskHistoryDetail taskHistoryEvent={taskHistoryEvent} />}
    />
  );
};

const StyledList = styled(List).attrs({
  size: 'small'
})`
  margin-right: 15px;
  li:first-child {
    margin-top: 0;
  }
  li.ant-list-item {
    padding-top: 0;
    padding-bottom: 12px;
  }
`;

export default withLDConsumer()(TaskHistory);
