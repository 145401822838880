import { oidcSelector } from 'store/authStore';
import { useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { userManagerNewSignin, userManagerNewDomain } from 'utils/userManager';
import { navigate } from '@reach/router';
import { IS_NEW_SIGNIN, VENDOR_USER_INFO, RECON_ALERTS } from 'app-constants';
import React, { useEffect } from 'react';
import { notification } from 'antd4';

const Logout = ({ flags }) => {
  const oidcState = useSelector(oidcSelector);
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';

  useEffect(() => {
    if (isNewSignin) {
      localStorage.removeItem(VENDOR_USER_INFO);
      userManagerNewSignin.removeUser();
    } else {
      localStorage.removeItem(RECON_ALERTS);
      userManagerNewDomain.removeUser();
      userManagerNewSignin.getUser().then((user) => {
        if (user) {
          userManagerNewSignin.removeUser();
        }
      });
    }
  }, [isNewSignin]);

  useEffect(() => {
    if (!oidcState.user || oidcState.user.expired) {
      const urlParameters = new URLSearchParams(window.location.search);
      const redirectUrl = urlParameters.get('redirect_url') || undefined;

      devLogger.log(`Logged out`, redirectUrl);
      localStorage.removeItem(RECON_ALERTS);

      if (flags.reconVendorDisambiguationPage) {
        if (redirectUrl) {
          navigate(`/login?redirect_url=${encodeURIComponent(redirectUrl)}`);
        } else {
          navigate('/login');
        }
      } else {
        localStorage.setItem(IS_NEW_SIGNIN, false);
        userManagerNewDomain.signinRedirect({ state: { redirect_url: redirectUrl } });
      }
    }
  }, [oidcState.user, oidcState.user?.expired]);
  notification.destroy();
  return <>Logging out...</>;
};

export default withLDConsumer()(Logout);
