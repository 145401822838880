import { Divider, Typography } from 'antd';
import { communicationContexts, detailContents, features, formatVehicleTitle } from 'app-constants';
import { EXEMPT, NEW } from 'app-constants/reconPlanStatusTypes';
import { IN_PROGRESS, PENDING } from 'app-constants/taskStatusTypes';
import { VehicleImage } from 'components';
import { CommunicationsSnippet } from 'components/communications';
import { CommonCardContainer, CommonRedButton } from 'components/styledComponents';
import VehicleInfo from 'components/common/VehicleInfo';
import { useFeatures } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'store/usersStore';
import { vdpActions } from 'store/vdpStore';
import styled from 'styled-components';
import { VehicleCardOverflowMenu, VehicleCardReconPlan, VehicleCardTask } from '.';
import { DECLINED } from 'app-constants/taskStatusTypes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import VehicleTag from 'components/common/VehicleTag';
import VehicleTagInfoProfitContainer from './VehicleTagInfoProfitContainer';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { vdpSelector } from '../../../../store/vdpStore';

const { Text } = Typography;

const selectVehicleById = (state, vehicleId) => {
  return state?.items?.find((vehicle) => vehicle.id === vehicleId);
};

const setIsSelected = (modelId, vehicleId) => {
  return modelId === vehicleId;
};

const VehicleCard = React.memo(
  ({ isLoading = false, vehicleId, setTaskGroups, onTemplatePlanChosen, flags, setFormProps }) => {
    const dispatch = useDispatch();
    const dealerId = useSelector((state) => state.dealers.current.data.id);
    const vehicle = useSelector((state) => selectVehicleById(state.vehicles.data, vehicleId)) || {};
    const { errorId, message } = useSelector(vdpSelector);
    const isSelected = useSelector((state) => setIsSelected(state.vdp.model, vehicleId));
    const vehicleTitle = formatVehicleTitle(vehicle);
    const [anyNeedsApprovalTasks, setAnyNeedsApprovalTasks] = useState(false);
    const [showApprDeclineBtn, setShowApprDeclineBtn] = useState(false);
    const [hasInventoryEdit] = useFeatures(features.INVENTORY_EDIT);
    const [hasApproverRole] = useFeatures(features.TASKS_APPROVE_LINE_ITEMS);
    const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);
    const showDealerName = isHQ;

    const openVdpAndGetData = () => {
      dispatch(vdpActions.getCommentsForVehicle(vehicle.id));
      dispatch(usersActions.getData(dealerId, false)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
      dispatch(vdpActions.setModel(vehicle.id));
      dispatch(vdpActions.setContentType(detailContents.VEHICLE_DETAILS));

      // refetch assignee for newest workload when opening the drawer
      dispatch(usersActions.getAssigneeData(dealerId));
    };

    const handleMainDescriptionClick = (e) => {
      e.preventDefault();
      if (!isLoading) {
        openVdpAndGetData();
      }
    };

    const handleCurrentTaskAndPlanClick = (e) => {
      e.preventDefault();
      let vehicleReconStatus = vehicle.reconStatus;
      if ((vehicle.reconPlan?.reconTaskGroups?.count || 0) === 0) vehicleReconStatus = NEW;
      if (!isLoading && vehicleReconStatus !== NEW && vehicle.reconStatus !== EXEMPT) {
        dispatch(vdpActions.getCommentsForVehicle(vehicle.id));
        dispatch(usersActions.getData(dealerId)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
        dispatch(vdpActions.setModel(vehicle.id));
        dispatch(vdpActions.setContentType('reconPlan'));

        // refetch assignee for newest workload when opening the drawer
        dispatch(usersActions.getAssigneeData(dealerId));
      }
    };

    const isTaskInProgOrPendingAndNeedsApprAndNotDeclined = (task) =>
      task.needsApproval && (task.status === IN_PROGRESS || task.status === PENDING) && !task.declined;

    useEffect(() => {
      if (vehicle.id) {
        setAnyNeedsApprovalTasks(
          vehicle?.tasks?.items.some(
            (task) => task.needsApproval && task.status !== DECLINED && !task.declined && !task.declinedOn
          )
        );
        setShowApprDeclineBtn(
          vehicle?.tasks?.items.some((task) => isTaskInProgOrPendingAndNeedsApprAndNotDeclined(task))
        );
      }
    }, [JSON.stringify(vehicle)]);

    useEffect(() => {
      if (flags.reconToastMessageUpdate && message && message.messageType !== 'error') {
        dispatch(vdpActions.setError(''));
      }
    }, [message]);
    return (
      <CommonCardContainer data-isselected={isSelected} data-is-error={errorId === vehicleId}>
        <StyledFlexContainer>
          <VehicleImage
            isLoading={isLoading}
            src={vehicle?.heroImageUrl}
            nonMarketingSrc={vehicle?.nonMarketingHeroImageUrl}
            fallbackSrc={vehicle?.appraisalHeroImageUrl}
            isHeroOrAppraisal
            imageQualityWidth={490} // This determines the image quality we pull back from the image api, setting it to twice the size of the box
            imageQualityHeight={hasReconProfitTime ? (showDealerName ? 426 : 386) : showDealerName ? 360 : 320}
            imageStyle={{
              width: '245px',
              height: hasReconProfitTime ? (showDealerName ? '213px' : '193px') : showDealerName ? '180px' : '160px'
            }}
            noImageStyle={{
              width: '245px',
              minWidth: '245px',
              height: isLoading
                ? '160px'
                : hasReconProfitTime
                ? showDealerName
                  ? '213px'
                  : '193px'
                : showDealerName
                ? '180px'
                : '160px'
            }}
          />
          <StyledCardInfoContainer>
            <StyledDescriptionContainer
              isLoading={isLoading}
              reconUpdatedListViews={flags?.reconUpdatedListViews}
              reconProfitTime={hasReconProfitTime}
              showDealerName={showDealerName}
            >
              <StyledMainDescriptionContainer
                reconUpdatedListViews={flags?.reconUpdatedListViews}
                onClick={flags?.reconUpdatedListViews ? () => {} : handleMainDescriptionClick}
              >
                {isLoading ? (
                  <StyledLoadingContainer>
                    <StyledSkeletonContainer>
                      <div>
                        <Skeleton count={2} width="100%" />
                      </div>
                    </StyledSkeletonContainer>
                    <IconWrapper>
                      <StyledMoreIcon icon={faEllipsisH} />
                    </IconWrapper>
                  </StyledLoadingContainer>
                ) : (
                  <>
                    <StyledVehicleTitleContainer reconProfitTime={hasReconProfitTime}>
                      <StyledTitleTagContaier>
                        <StyledVehicleTitle
                          reconProfitTime={hasReconProfitTime}
                          reconUpdatedListViews={flags?.reconUpdatedListViews}
                          onClick={flags?.reconUpdatedListViews ? handleMainDescriptionClick : () => {}}
                        >
                          {vehicleTitle}
                        </StyledVehicleTitle>
                        {!hasReconProfitTime && (
                          <VehicleTag flags={flags} anyNeedsApprovalTasks={anyNeedsApprovalTasks} vehicle={vehicle} />
                        )}
                      </StyledTitleTagContaier>
                      <StyledButtonContainer>
                        {flags.reconApproveButton && hasApproverRole && showApprDeclineBtn && !isLoading && !isHQ && (
                          <StyledRedButton
                            onClick={() => {
                              if (!isLoading) {
                                openVdpAndGetData();
                                const selectedTask = vehicle?.tasks?.items.find((task) =>
                                  isTaskInProgOrPendingAndNeedsApprAndNotDeclined(task)
                                );
                                const taskGroupOfSelectedTask = vehicle.reconPlan?.reconTaskGroups?.items?.find(
                                  (taskGroup) => {
                                    const reconTasks = taskGroup?.reconTasks?.items;
                                    const foundTask = reconTasks.find((reconTask) => reconTask.id === selectedTask.id);
                                    return foundTask;
                                  }
                                );
                                const taskToUpdate = { ...selectedTask };
                                if (taskGroupOfSelectedTask.reconTasks?.items?.length < 2) {
                                  taskToUpdate.group = { ...taskGroupOfSelectedTask };
                                }
                                // set form to line items page
                                setFormProps({
                                  vehicleId: vehicleId,
                                  isLineItemsTask: true,
                                  taskToUpdate
                                });
                              }
                            }}
                          >
                            Approve/Decline
                          </StyledRedButton>
                        )}
                        <VehicleCardOverflowMenu
                          isLoading={isLoading}
                          vehicle={vehicle}
                          hasInventoryEdit={hasInventoryEdit}
                          flags={flags}
                        />
                      </StyledButtonContainer>
                    </StyledVehicleTitleContainer>
                    {hasReconProfitTime ? (
                      <VehicleTagInfoProfitContainer
                        flags={flags}
                        anyNeedsApprovalTasks={anyNeedsApprovalTasks}
                        vehicle={vehicle}
                        showDealerName={showDealerName}
                      />
                    ) : (
                      <VehicleInfo vehicle={vehicle} showDealerName={showDealerName} />
                    )}
                  </>
                )}
              </StyledMainDescriptionContainer>
            </StyledDescriptionContainer>
            <StyledCurrentTaskAndPlanContainer
              isLoading={isLoading}
              reconStatus={vehicle?.reconStatus}
              reconUpdatedListViews={flags?.reconUpdatedListViews}
              onClick={flags?.reconUpdatedListViews ? () => {} : handleCurrentTaskAndPlanClick}
            >
              <StyledCurrentTaskContainer>
                {/* current task */}
                <VehicleCardTask
                  isLoading={isLoading}
                  dealerId={dealerId}
                  vehicle={vehicle}
                  isSelected={isSelected}
                  setTaskGroups={setTaskGroups}
                  onTemplatePlanChosen={onTemplatePlanChosen}
                />
              </StyledCurrentTaskContainer>
              {/* if vehicle doesn't have a recon plan vehicle.reconStatus === NEW, don't show the following... otherwise, do show it */}
              {![NEW, EXEMPT].includes(vehicle?.reconStatus) ? (
                <>
                  <Divider type="vertical" style={{ height: '68px' }} />
                  <VehicleCardReconPlan isLoading={isLoading} vehicle={vehicle} />
                </>
              ) : null}
            </StyledCurrentTaskAndPlanContainer>
          </StyledCardInfoContainer>
          <StyledCommunicationsContainer>
            <CommunicationsSnippet
              isLoading={isLoading}
              vehicle={vehicle}
              isSelected={isSelected}
              isVDP={true}
              location={communicationContexts.VEHICLE_CARD}
            />
          </StyledCommunicationsContainer>
        </StyledFlexContainer>
      </CommonCardContainer>
    );
  }
);

//#region StyledComponents
const StyledFlexContainer = styled.div`
  display: flex;
`;
const StyledCardInfoContainer = styled.div`
  min-width: 550px;
  flex: 2 2 0px;
  display: flex;
  flex-direction: column;
  border-width: 0 1px 0 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borderGray};
`;
const StyledDescriptionContainer = styled(({ isLoading, reconUpdatedListViews, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  height: ${(props) =>
    props['isLoading']
      ? '75px'
      : props['reconProfitTime']
      ? props['showDealerName']
        ? '128px'
        : '108px'
      : props['showDealerName']
      ? '95px'
      : '75px'};
  border-width: 0 0 0.5px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borderGray};
  :hover {
    cursor: ${(props) => (!props['isLoading'] ? 'pointer' : 'inherit')};
    background-color: ${(props) =>
      !props['isLoading'] && !props['reconUpdatedListViews'] ? props.theme.colors.backgroundGray : 'inherit'};
  }
`;

const StyledMainDescriptionContainer = styled(({ reconUpdatedListViews, ...rest }) => <div {...rest} />)`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  cursor: ${({ reconUpdatedListViews }) => (reconUpdatedListViews ? 'default' : 'inherit')};
`;

const StyledSkeletonContainer = styled.div`
  display: flex;
  flex: 1 1 0px;
  align-items: center;
  height: 100%;
  div {
    width: 100%;
    max-width: 460px;
    margin: 0 25px;
  }
`;
const StyledCurrentTaskAndPlanContainer = styled.div`
  padding: 9px 15px 8px 0px;
  display: flex;
  flex: 1 1 0px;
  :hover {
    cursor: ${(props) =>
      !props['isLoading'] &&
      props['reconStatus'] !== 'NEW' &&
      props['reconStatus'] !== 'EXEMPT' &&
      !props['reconUpdatedListViews']
        ? 'pointer'
        : 'inherit'};
    background-color: ${(props) =>
      !props['isLoading'] &&
      props['reconStatus'] !== 'NEW' &&
      props['reconStatus'] !== 'EXEMPT' &&
      !props['reconUpdatedListViews']
        ? props.theme.colors.backgroundGray
        : 'inherit'};
  }
`;
const StyledCurrentTaskContainer = styled.div`
  width: auto;
`;
const StyledCommunicationsContainer = styled.div`
  flex: 1 1 0px;
  min-width: 300px;
  margin: 18px 16px 7px 16px;
`;
const StyledVehicleTitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 12px 15px ${(props) => (props['reconProfitTime'] ? '8px' : '2px')} 15px;
  ${(props) => (props['reconProfitTime'] ? 'justify-content: space-between;' : '')}
`;
const StyledVehicleTitle = styled(({ reconUpdatedListViews, ...rest }) => <Text {...rest} />).attrs({
  ellipsis: true,
  className: 'large-font'
})`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  cursor: ${({ reconUpdatedListViews }) => (reconUpdatedListViews ? 'pointer' : 'inherit')};
  color: ${({ reconUpdatedListViews, theme }) => reconUpdatedListViews && theme.colors.infoPrimary} !important;
  &:hover {
    text-decoration: ${({ reconUpdatedListViews }) => (reconUpdatedListViews ? 'underline' : 'inherit')};
  }
`;

const StyledRedButton = styled(CommonRedButton)`
  line-height: ${({ theme }) => theme.lineHeights.smd} !important;
  height: 24px !important;
  padding: 0px 8px !important;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledTitleTagContaier = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1 1 0px;
  align-items: center;
`;
const StyledLoadingContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1 1;
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.lightGray};
  height: 24px;
`;
const IconWrapper = styled.div`
  display: flex;
  cursor: default;
  margin-right: 15px;
  margin-top: 12px;
  width: 22px;
  height: 24px;
`;
//#endregion

export default withLDConsumer()(VehicleCard);
