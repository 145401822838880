import { regExp } from '../app-constants/regExp';

const emDash = String.fromCharCode(8212);

//takes a decimal value and returns a formatted string in % form
export const formattedPercentCalculation = (rawPercentValue, reconDealerFilterFlag = false) => {
  if (
    rawPercentValue === Infinity ||
    rawPercentValue === 0 ||
    rawPercentValue === Number.NEGATIVE_INFINITY ||
    isNaN(rawPercentValue)
  ) {
    if (rawPercentValue === 0) {
      return '0';
    }
    return '--';
  }
  return `${rawPercentValue > 0 ? '+' : ''}${Math.round(rawPercentValue * 100).toLocaleString()}%`;
};

export const formatPrice = (rawPrice) => {
  return (rawPrice ?? emDash).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace('.00', '');
};

export const formatNumberTwoNumberAfterComma = (number) => {
  let numberTemp = typeof number === 'string' ? Number(number) : number;
  return (Math.round(numberTemp * 100) / 100).toFixed(2);
};

export const convertNumberNoCharAfterComma = (number) => {
  if ((number * 100) % 100 === 0) {
    return (number * 100) / 100;
  }
  return number;
};

export const validateNumberTwoNumberAfterComma = (rule, value) => {
  let temp = value.split('.');
  if (temp[1] && (temp[1].length === 0 || temp[1].length > 2)) {
    // rule.message = '.xx';
    rule.message = ' ';
  }
  if (rule.message) {
    return Promise.reject(rule.message);
  }
  return Promise.resolve();
};

export const formatterNumberUSD = (number, minimumFractionDigits = 0) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: minimumFractionDigits || (Number.isInteger(number) ? 0 : 2)
  }).format(number);
};

// Formats a valid 10-digit phone number with hyphens
export const formatPhoneNumber = (phoneNumber) => {
  // return empty string if undefined or empty string
  if (typeof phoneNumber === 'undefined' || phoneNumber === '') {
    return '';
  }

  // check for correct format
  const correctPhoneNumber = phoneNumber.match(regExp.PHONE);

  if (correctPhoneNumber) {
    return correctPhoneNumber[1] + '-' + correctPhoneNumber[2] + '-' + correctPhoneNumber[3];
  } else {
    // display raw text if invalid phone number
    return phoneNumber;
  }
};

const ROUNDING_PRECISION = 0.0001;
export const round = (number, precision) => {
  precision = precision || ROUNDING_PRECISION;
  if (!precision) return number;
  return Math.round(number / precision) * precision;
};

export const numberThousandsSepFormatter = (value) => {
  const stringValue = value ? `${value}` : '';
  if (stringValue.includes('.')) {
    const group = stringValue.split('.')[0];
    const decimal = stringValue.split('.')[1];
    const formattedGroup = parseInt(group).toLocaleString();
    if (group) {
      return decimal ? `${formattedGroup}.${decimal}` : `${formattedGroup}.`;
    } else {
      return '';
    }
  } else {
    return value ? parseInt(value).toLocaleString() : '';
  }
};
