import { Form, Input } from 'antd';
import { Stack } from 'components';
import { InputErrorMessage } from 'components/common';
import { StyledForm, StyledFormItem } from 'components/layout/settings/common/Styles';
import { CommonFooterContainer, CommonLinkButton, CommonRedButton } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { vendorActions } from 'store/vendorStore';
import styled from 'styled-components';

const { TextArea } = Input;

const vendorPropsSelector = createSelector(
  (state) => state.vendors,
  (vendorStore) => ({
    errorMessageEmail: vendorStore.errorMessageEmail
  })
);

const VendorForm = ({
  form,
  vendor,
  formValues,
  isNameTaken,
  isEmailExists,
  isNameAndEmailMatch,
  onChange,
  isSaving,
  formIsValidated,
  onSave,
  showDiscardButton,
  closeDrawer,
  handleCanClose,
  setIsSaving,
  isReinvite,
  flags
}) => {
  const dispatch = useDispatch();
  const { getFieldDecorator } = form;
  const { errorMessageEmail } = useSelector(vendorPropsSelector);

  useEffect(() => {
    if (vendor?.id) {
      form.setFieldsValue({
        name: vendor.name,
        contactFirstName: isReinvite ? vendor?.primaryContact?.firstName : vendor.contactFirstName ?? '',
        contactLastName: isReinvite ? vendor?.primaryContact?.lastName : vendor.contactLastName ?? '',
        email: vendor.email,
        phone: vendor.phone,
        inviteMessage: vendor.inviteMessage
      });
    }
  }, [vendor?.id]);

  useEffect(() => {
    if (errorMessageEmail) {
      setIsSaving(false);
    }
  }, [errorMessageEmail]);

  const getSaveButtonLabel = () => {
    if (vendor && vendor.id && !isReinvite) {
      return isSaving ? 'Saving' : 'Save';
    } else {
      return isSaving ? 'Creating' : 'Send Invite';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err) => {
      if (!err) {
        onSave();
      }
    });
  };

  const isFormError = () => {
    const formErrors = form.getFieldsError(['name', 'contactFirstName', 'contactLastName', 'email', 'phone']);
    return Object.keys(formErrors).some((key) => !!formErrors[key]) || !!errorMessageEmail.length;
  };

  const onChaneEmail = (e) => {
    onChange('email')(e.target.value);
    if (!!errorMessageEmail.length) {
      dispatch(vendorActions.setErrorMsgEmailValidate(''));
    }
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit} hideRequiredMark>
        <StyledFormItemsContainer>
          <StyleVendorTitle>Business Details</StyleVendorTitle>
          <StyledFormItem
            label={
              <>
                Vendor Business Name <span className="required-char">*</span>
              </>
            }
          >
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    if (flags.reconVendorWorkflow2023) {
                      return Promise.resolve();
                    }
                    if (!value.trim()) {
                      rule.message = <InputErrorMessage>Name is required</InputErrorMessage>;
                    }
                    if (
                      isNameTaken(value, vendor?.id) ||
                      isNameAndEmailMatch({ name: value, email: formValues.email }, vendor?.id)
                    ) {
                      rule.message = (
                        <InputErrorMessage>
                          Vendor name already exists. Press Cancel to search for this vendor.
                        </InputErrorMessage>
                      );
                    }
                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                }
              ]
            })(
              <Input
                maxLength={50}
                size="large"
                style={{ fontSize: '14px' }}
                onChange={(e) => onChange('name')(e.target.value)}
                disabled={!!isReinvite}
              />
            )}
          </StyledFormItem>
          <StyledFormItem
            label={
              <>
                Business Phone Number <span className="required-char">*</span>
              </>
            }
            style={{ marginTop: 8 }}
          >
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    const globalRegex = /^\(?([2-9][0-8]\d)\)?[-. ]?([2-9]\d{2})[-. ]?(\d{4})$/;

                    if (!globalRegex.test(value)) {
                      rule.message = <InputErrorMessage>Valid Phone is required</InputErrorMessage>;
                    }

                    if (!value) {
                      rule.message = <InputErrorMessage>Business Phone Number is required</InputErrorMessage>;
                    }

                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                }
              ]
            })(
              <Input
                placeholder="###-###-####"
                maxLength={30}
                size="large"
                style={{ fontSize: '14px' }}
                onChange={(e) => onChange('phone')(e.target.value)}
                disabled={!!isReinvite}
              />
            )}
          </StyledFormItem>

          <StyleVendorTitle style={{ marginTop: 16, marginBottom: 8 }}>Primary Contact Details</StyleVendorTitle>
          <StyleContactDetails>
            The below individual should be the vendor manager at this vendorship
          </StyleContactDetails>

          <div style={{ marginTop: 16 }}>
            <Stack horizontal>
              <StyledFormItem
                label={
                  <>
                    First Name <span className="required-char">*</span>
                  </>
                }
                style={{ flex: 1 }}
              >
                {getFieldDecorator('contactFirstName', {
                  rules: [
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (!value.trim()) {
                          rule.message = <InputErrorMessage>First Name is required</InputErrorMessage>;
                        }
                        if (rule.message) {
                          return Promise.reject(rule.message);
                        }
                        return Promise.resolve();
                      }
                    }
                  ]
                })(
                  <Input
                    size="large"
                    maxLength={50}
                    placeholder="Primary contact first name"
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onChange('contactFirstName')(e.target.value)}
                    disabled={!!isReinvite}
                  />
                )}
              </StyledFormItem>
              <StyledFormItem
                label={
                  <>
                    Last Name <span className="required-char">*</span>
                  </>
                }
                style={{ marginLeft: 8, flex: 1 }}
              >
                {getFieldDecorator('contactLastName', {
                  rules: [
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (!value.trim()) {
                          rule.message = <InputErrorMessage>Last Name is required</InputErrorMessage>;
                        }
                        if (rule.message) {
                          return Promise.reject(rule.message);
                        }
                        return Promise.resolve();
                      }
                    }
                  ]
                })(
                  <Input
                    size="large"
                    maxLength={50}
                    placeholder="Primary contact last name"
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onChange('contactLastName')(e.target.value)}
                    disabled={!!isReinvite}
                  />
                )}
              </StyledFormItem>
            </Stack>
          </div>

          <StyledFormItem
            label={
              <>
                Email <span className="required-char">*</span>
              </>
            }
            style={{ marginTop: 9 }}
            {...(errorMessageEmail && {
              help: errorMessageEmail ? <InputErrorMessage>{errorMessageEmail}</InputErrorMessage> : null,
              validateStatus: errorMessageEmail ? 'error' : 'success'
            })}
          >
            {getFieldDecorator('email', {
              validateFirst: true,
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    if (flags.reconVendorWorkflow2023) {
                      return Promise.resolve();
                    }
                    if (!value.trim()) {
                      rule.message = <InputErrorMessage>Email is required</InputErrorMessage>;
                    }
                    if (isNameAndEmailMatch({ name: formValues.name, email: value }, vendor?.id)) {
                      rule.message = <InputErrorMessage>I already have an account</InputErrorMessage>;
                    } else if (!isEmpty(isEmailExists(value, vendor?.id))) {
                      const vendorItem = isEmailExists(value, vendor?.id);
                      rule.message = (
                        <InputErrorMessage>
                          Email already exists for {vendorItem.name}. Press Cancel to search for this vendor.
                        </InputErrorMessage>
                      );
                    }
                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                },
                {
                  type: 'email',
                  message: <InputErrorMessage>Valid email is required</InputErrorMessage>
                }
              ]
            })(
              <Input
                placeholder="Primary contact email address"
                size="large"
                style={{ fontSize: '14px' }}
                onChange={onChaneEmail}
              />
            )}
          </StyledFormItem>

          <StyledFormItem style={{ marginTop: 8 }} label="Message to Vendor">
            {getFieldDecorator('inviteMessage')(
              <TextArea
                placeholder="Include a message in the invite email"
                autoSize={{ minRows: 7, maxRows: 14 }}
                onChange={(e) => onChange('inviteMessage')(e.target.value)}
              />
            )}
          </StyledFormItem>
        </StyledFormItemsContainer>

        <StyledFooterContainer>
          {showDiscardButton ? (
            <CommonRedButton
              disabled={isSaving}
              onClick={(e) => closeDrawer()}
              style={{ marginLeft: 'auto', marginRight: '24px' }}
            >
              Discard Changes
            </CommonRedButton>
          ) : (
            <CommonLinkButton
              disabled={isSaving}
              onClick={handleCanClose}
              style={{ marginLeft: 'auto', marginRight: '24px' }}
            >
              Cancel
            </CommonLinkButton>
          )}

          <StyledSubmitButton
            loading={isSaving}
            disabled={(!formIsValidated && !isReinvite) || isFormError()}
            style={{ marginRight: '24px' }}
          >
            {getSaveButtonLabel()}
          </StyledSubmitButton>
        </StyledFooterContainer>
      </StyledForm>
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  .has-error .ant-form-explain {
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;
const StyleVendorTitle = styled.div`
  font-family: Roboto;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.subTitle};
  color: ${({ theme }) => theme.colors.navy};
  text-align: center;
  margin-bottom: 16px;
`;

const StyleContactDetails = styled.div`
  font-family: Roboto;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
`;

export const StyledFormItemsContainer = styled.div`
  padding: 24px 24px 0 24px;
  width: 100%;
  margin-bottom: 73px;
  span {
    &.required-char {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;
const StyledFooterContainer = styled(CommonFooterContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})``;

//#endregion

export default withLDConsumer()(Form.create()(VendorForm));
