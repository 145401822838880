import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EditorState, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import styled from 'styled-components';
import { oidcSelector, authSelector } from 'store/authStore';
import { createSelector } from 'reselect';

const support = (function () {
  if (!window.DOMParser) return false;
  let parser = new DOMParser();
  try {
    parser.parseFromString('x', 'text/html');
  } catch (err) {
    return false;
  }
  return true;
})();

const textToHTML = function (str) {
  // check for DOMParser support
  if (support) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body.innerHTML;
  }

  // Otherwise, create div and append HTML
  let dom = document.createElement('div');
  dom.innerHTML = str;
  return dom;
};

const propsSelector = createSelector(oidcSelector, authSelector, (oidcState, authStore) => ({
  userId: oidcState.user.profile?.recon_user_id || authStore.user.id
}));

const CustomMentionEditor = ({ showEllipsis, comment }) => {
  const convertedData = convertFromRaw(comment.contentData);
  const editorValue = EditorState.createWithContent(convertedData);
  const { userId } = useSelector(propsSelector);

  const [editorState, setEditorState] = useState(editorValue);

  const { plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(props) {
        return (
          <>
            {userId === props.mention.id ? (
              <StyledTagForOwn>{props.children}</StyledTagForOwn>
            ) : (
              <StyledTagForOther>{props.children}</StyledTagForOther>
            )}
          </>
        );
      }
    });

    const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
    const plugins = [mentionPlugin, linkifyPlugin];
    return { plugins };
  }, []);

  const onChange = useCallback((_editorState) => {
    setEditorState(_editorState);
  }, []);

  return (
    <StyledEditor show-ellipsis={showEllipsis}>
      <Editor editorKey={'editor'} editorState={editorState} readOnly={true} onChange={onChange} plugins={plugins} />
    </StyledEditor>
  );
};

export const CreateStringWithURLClickable = ({ content, showEllipsis, comment }) => {
  const URL_REGEX = /(https?|chrome):\/\/[^\s$.?#].[^\s]*/gi;
  let result = '';
  if (!comment?.contentData) {
    result = content.replace(URL_REGEX, (url) => {
      let urlTemp = url;
      try {
        decodeURI(urlTemp);
      } catch (e) {
        urlTemp = encodeURI(url);
      }
      let urlEncode =
        decodeURI(urlTemp) !== decodeURIComponent(urlTemp) ? (url.includes('"') ? encodeURI(url) : url) : urlTemp;
      return `<a href="${urlEncode}" target="_blank">${url}</a>`;
    });
  }

  return (
    <>
      {!comment?.contentData ? (
        <SpanComment
          dangerouslySetInnerHTML={{
            __html: textToHTML(result)
          }}
        ></SpanComment>
      ) : (
        <CustomMentionEditor showEllipsis={showEllipsis} comment={comment}></CustomMentionEditor>
      )}
    </>
  );
};

const SpanComment = styled.span`
  word-break: break-word;
  a {
    color: ${({ theme }) => theme.colors.lightBlueLinkColor} !important;
    &:hover {
      text-decoration: underline !important;
      color: ${({ theme }) => theme.colors.lightBlueLinkColor} !important;
    }
    word-break: break-word;
  }
`;

const StyledEditor = styled.span`
  .public-DraftEditor-content {
    white-space: ${({ 'show-ellipsis': showEllipsis }) => (showEllipsis ? 'unset' : 'pre-wrap')} !important;

    > div {
      display: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && 'inline-block'};
      width: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && '100%'};

      > div {
        display: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && 'inline-block'};
        width: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && '100%'};
      }
    }
  }
  .public-DraftStyleDefault-block {
    overflow: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && 'hidden'};
    white-space: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && 'nowrap'};
    text-overflow: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && 'ellipsis'};
    word-break: ${({ 'show-ellipsis': showEllipsis }) => (showEllipsis ? 'break-all' : 'break-word')};
    display: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && 'inline-block'};
    width: ${({ 'show-ellipsis': showEllipsis }) => showEllipsis && '100%'};
  }

  a {
    color: #2b6bdd !important;
    &:hover {
      text-decoration: underline;
    }
  }

  div:last-child {
    display: ${({ 'show-ellipsis': showEllipsis }) => !showEllipsis && 'inline'};
  }
`;

const StyledTagForOwn = styled.span`
  color: #2b6bdd !important;
  font-weight: 500;
  background-color: #e3edff;
  text-decoration: none !important;
  cursor: pointer;
  padding: 3px;
  border-radius: 4px;
  line-height: 25px;
`;

const StyledTagForOther = styled.span`
  font-weight: 500;
  text-decoration: none !important;
  cursor: pointer;
`;
