import { useEffect } from 'react';
import { pushTagManagerEvent } from './utils';

/**
 * Custom hook to emit GTM events when a component is mounted on to DOM.
 * This hook may not work for components that are always mounted to DOM and whose visibility is controlled using CSS instead
 * The hook also may also display unexpected behavior when using components from the AntDesign library that are created on first render and then not destroyed for performance reasons
 */
export const useComponentViewedGoogleTag = (eventName) => {
  useEffect(() => {
    pushTagManagerEvent(eventName);
  }, []);
};
