import { vehiclesActions } from 'store/vehiclesStore';
import { tasksActions } from 'store/tasksStore';
import { messagesActions } from 'store/messagesStore';
import { createRequestTypes, makeActionCreator } from 'utils';
import { takeLatest, put } from 'redux-saga/effects';
import { getWithToken, patchWithToken, putWithToken } from 'api';
import { detailContents, apiStatusConstants, lineItemStatusTypes } from 'app-constants';
import {
  OPERATION_LINE_ITEM,
  NAME_OF_LIST_LINE_ITEMS,
  LOCATION_MESSAGE_LINE_ITEM
} from 'app-constants/lineItemConstants';
import { isEmpty } from 'lodash';
import { updateStatusForLineItemTemplate } from 'utils/lineItemsTemplateUtils';
import { planTemplatesActions } from './planTemplatesStore';

//#region Actions
export const LINE_ITEMS_TYPES = createRequestTypes('LINE_ITEMS_TYPES', [
  'ADD_NEED_APPROVAL',
  // 'ADD_LINE_ITEMS_STORE',
  'UPDATE_NEED_APPROVAL',
  'UPDATE_LINE_ITEM',
  'DELETE_NEED_APPROVAL',
  'DELETE_LINE_ITEM',
  'HIDE_ADD_NEW_LINE_ITEM',
  'SHOW_ADD_NEW_LINE_ITEM',
  'TOGGLE_SHOW_LINE_ITEM',
  'RESET_LINE_ITEM',
  'MOVE_LINE_ITEM',
  'APPROVED_ALL',
  'GET_LINE_ITEMS',
  'SET_FETCH_LINE_ITEMS_STATUS',
  'SET_PATCH_LINE_ITEMS_STATUS',
  'SET_LINE_ITEMS',
  'PATCH_LINE_ITEMS',
  'PATCH_LINE_ITEMS_TDP',
  'PATCH_LINE_ITEMS_PLAN_TEMPLATE',
  'PATCH_LINE_ITEMS_TASK_TEMPLATE',
  'UPDATE_LINE_ITEM_FOR_BODY_SAVE_REQUEST',
  'RESET_LINE_ITEM_FOR_BODY_SAVE_REQUEST',
  'SET_DISCARD_LINE_ITEM',
  'SET_EDITING_LINE_ITEM',
  'SET_ORIGINAL_LINE_ITEMS',
  'SET_ERROR_ITEM',
  'RESET_ERROR',
  'SET_NEED_APPROVAL_ERROR_MESSAGE',
  'RESET_ERROR_MESSAGE_BY_LIST',
  'UPDATE_RECON_TASK',
  'GET_LINE_ITEMS_RECON_PLAN_TEMPLATE_TASK',
  'GET_LINE_ITEMS_TASK_TEMPLATE'
]);
export const lineItemsActions = {
  addNeedApproval: makeActionCreator(LINE_ITEMS_TYPES.ADD_NEED_APPROVAL, 'item'),
  updateLineItem: makeActionCreator(LINE_ITEMS_TYPES.UPDATE_LINE_ITEM, 'item', 'listName'),
  deleteNeedApproval: makeActionCreator(LINE_ITEMS_TYPES.DELETE_NEED_APPROVAL, 'itemId'),
  deleteLineItem: makeActionCreator(LINE_ITEMS_TYPES.DELETE_LINE_ITEM, 'itemId', 'listName'),
  hideAddNewLineItem: makeActionCreator(LINE_ITEMS_TYPES.HIDE_ADD_NEW_LINE_ITEM),
  showAddNewLineItem: makeActionCreator(LINE_ITEMS_TYPES.SHOW_ADD_NEW_LINE_ITEM),
  toggleShowNoLineItems: makeActionCreator(LINE_ITEMS_TYPES.TOGGLE_SHOW_LINE_ITEM, 'isShowLineItem'),
  resetLineItem: makeActionCreator(LINE_ITEMS_TYPES.RESET_LINE_ITEM),
  moveItem: makeActionCreator(LINE_ITEMS_TYPES.MOVE_LINE_ITEM, 'list1', 'list1Name', 'list2', 'list2Name', 'item'),
  approvedAll: makeActionCreator(LINE_ITEMS_TYPES.APPROVED_ALL),
  getLineItems: makeActionCreator(LINE_ITEMS_TYPES.GET_LINE_ITEMS, 'task'),
  setFetchLineItemsStatus: makeActionCreator(LINE_ITEMS_TYPES.SET_FETCH_LINE_ITEMS_STATUS, 'fetchLineItemsStatus'),
  setPatchLineItemsStatus: makeActionCreator(LINE_ITEMS_TYPES.SET_PATCH_LINE_ITEMS_STATUS, 'patchLineItemsStatus'),
  setLineItems: makeActionCreator(LINE_ITEMS_TYPES.SET_LINE_ITEMS, 'items'),
  setDiscardLineItem: makeActionCreator(LINE_ITEMS_TYPES.SET_DISCARD_LINE_ITEM, 'showDiscardLineItem'),
  setEditingLineItem: makeActionCreator(LINE_ITEMS_TYPES.SET_EDITING_LINE_ITEM, 'editingLineItem'),
  patchLineItems: makeActionCreator(
    LINE_ITEMS_TYPES.PATCH_LINE_ITEMS,
    'listLineItemToUpdate',
    'taskToUpdate',
    'isVDP',
    'localTasksLineItems',
    'closeForm',
    'vehicle',
    'reconTimestampFilterFlag'
  ),
  patchLineItemsTDP: makeActionCreator(
    LINE_ITEMS_TYPES.PATCH_LINE_ITEMS_TDP,
    'listLineItemToUpdate',
    'taskToUpdate',
    'contentType',
    'isUpdating'
  ),
  patchLineItemsPlanTemplate: makeActionCreator(
    LINE_ITEMS_TYPES.PATCH_LINE_ITEMS_PLAN_TEMPLATE,
    'listLineItemToUpdate',
    'taskToUpdate',
    'closeForm'
  ),
  patchLineItemsTaskTemplate: makeActionCreator(
    LINE_ITEMS_TYPES.PATCH_LINE_ITEMS_TASK_TEMPLATE,
    'listLineItemToUpdate',
    'closeForm'
  ),
  updateLineItemForBodySaveRequest: makeActionCreator(LINE_ITEMS_TYPES.UPDATE_LINE_ITEM_FOR_BODY_SAVE_REQUEST, 'item'),
  resetLineItemForBodySaveRequest: makeActionCreator(LINE_ITEMS_TYPES.RESET_LINE_ITEM_FOR_BODY_SAVE_REQUEST),
  setOriginalLineItems: makeActionCreator(LINE_ITEMS_TYPES.SET_ORIGINAL_LINE_ITEMS, 'items'),
  setErrorItem: makeActionCreator(LINE_ITEMS_TYPES.SET_ERROR_ITEM, 'listName', 'item', 'message'),
  resetError: makeActionCreator(LINE_ITEMS_TYPES.RESET_ERROR),
  setNeedApprovalErrorMessage: makeActionCreator(LINE_ITEMS_TYPES.SET_NEED_APPROVAL_ERROR_MESSAGE, 'message'),
  resetErrorMessageByList: makeActionCreator(LINE_ITEMS_TYPES.RESET_ERROR_MESSAGE_BY_LIST, 'listName'),
  updateReconTask: makeActionCreator(LINE_ITEMS_TYPES.UPDATE_RECON_TASK, 'taskToUpdate'),
  getLineItemsReconPlanTemplateTask: makeActionCreator(
    LINE_ITEMS_TYPES.GET_LINE_ITEMS_RECON_PLAN_TEMPLATE_TASK,
    'reconPlanTemplateId',
    'reconPlanTemplateTaskId'
  ),
  getLineItemsTaskTemplate: makeActionCreator(LINE_ITEMS_TYPES.GET_LINE_ITEMS_TASK_TEMPLATE, 'reconTaskTypeId')
};
//#endregion

//#region Reducer
const initialState = {
  needApproval: [],
  approved: [],
  declined: [],
  lineItemForBodySaveRequest: [],
  lineItemsList: [],
  isShowAddLineItem: true,
  showNoLineItems: true,
  showDiscardLineItem: false,
  editingLineItem: false,
  fetchLineItemsStatus: apiStatusConstants.IS_FETCHING,
  patchLineItemsStatus: apiStatusConstants.IS_FETCHING,
  originalLineItemsList: [],
  needApprovalErrorMessage: ''
};

export const lineItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LINE_ITEMS_TYPES.ADD_NEED_APPROVAL:
      return {
        ...state,
        needApproval: [...state.needApproval, action.item]
      };
    case LINE_ITEMS_TYPES.UPDATE_LINE_ITEM:
      return {
        ...state,
        [action.listName]: state[action.listName].map((item) => {
          if (item.idTemp === action.item.idTemp) {
            return {
              ...action.item,
              error: ''
            };
          } else {
            return item;
          }
        })
      };
    case LINE_ITEMS_TYPES.SET_ERROR_ITEM:
      return {
        ...state,
        [action.listName]: state[action.listName].map((item) => {
          if (item.idTemp === action.item.idTemp) {
            return {
              ...item,
              error: action.message
            };
          } else {
            return item;
          }
        })
      };
    case LINE_ITEMS_TYPES.RESET_ERROR:
      return {
        ...state,
        needApproval: state.needApproval.map((item) => ({
          ...item,
          error: ''
        })),
        approved: state.approved.map((item) => ({
          ...item,
          error: ''
        })),
        declined: state.declined.map((item) => ({
          ...item,
          error: ''
        })),
        needApprovalErrorMessage: ''
      };
    case LINE_ITEMS_TYPES.RESET_ERROR_MESSAGE_BY_LIST:
      return {
        ...state,
        [action.listName]: state[action.listName].map((item) => ({
          ...item,
          error: ''
        }))
      };
    case LINE_ITEMS_TYPES.SET_NEED_APPROVAL_ERROR_MESSAGE:
      return {
        ...state,
        needApprovalErrorMessage: action.message
      };
    case LINE_ITEMS_TYPES.UPDATE_LINE_ITEM_FOR_BODY_SAVE_REQUEST:
      const lineItemForBodySaveRequestTemp = updateItemForBodySaveRequest(action.item, [
        ...state.lineItemForBodySaveRequest
      ]);
      return {
        ...state,
        lineItemForBodySaveRequest: lineItemForBodySaveRequestTemp.lineItemForBodySaveRequest
      };
    case LINE_ITEMS_TYPES.RESET_LINE_ITEM_FOR_BODY_SAVE_REQUEST:
      return {
        ...state,
        lineItemForBodySaveRequest: []
      };
    case LINE_ITEMS_TYPES.DELETE_NEED_APPROVAL:
      return {
        ...state,
        needApproval: state.needApproval.filter((item) => item.idTemp !== action.itemId)
      };
    case LINE_ITEMS_TYPES.DELETE_LINE_ITEM:
      return {
        ...state,
        [action.listName]: state[action.listName].filter((item) => item.idTemp !== action.itemId)
      };
    case LINE_ITEMS_TYPES.SHOW_ADD_NEW_LINE_ITEM:
      return {
        ...state,
        isShowAddLineItem: true
      };
    case LINE_ITEMS_TYPES.HIDE_ADD_NEW_LINE_ITEM:
      return {
        ...state,
        isShowAddLineItem: false
      };
    case LINE_ITEMS_TYPES.TOGGLE_SHOW_LINE_ITEM:
      return {
        ...state,
        showNoLineItems: action.isShowLineItem
      };
    case LINE_ITEMS_TYPES.MOVE_LINE_ITEM:
      const result = moveItem(action.list1, action.list1Name, action.list2, action.list2Name, action.item);
      return {
        ...state,
        ...result
      };
    case LINE_ITEMS_TYPES.APPROVED_ALL:
      return {
        ...state,
        needApproval: setStatus(
          'needApproval',
          state.needApproval.filter((item) => !item?.totalCost?.toString())
        ),
        approved: setStatus('approved', [
          ...state.approved,
          ...state.needApproval.filter((item) => item?.totalCost?.toString())
        ])
      };
    case LINE_ITEMS_TYPES.RESET_LINE_ITEM:
      return {
        ...state,
        ...initialState
      };
    case LINE_ITEMS_TYPES.SET_LINE_ITEMS:
      if (isEmpty(action.items)) {
        return {
          ...state,
          lineItemsList: []
        };
      }
      return {
        ...state,
        lineItemsList: action.items,
        needApproval: mapLineItemsAfterFetch(
          action.items.filter((item) =>
            [lineItemStatusTypes.PENDING, lineItemStatusTypes.ESTIMATE_REQUESTED].includes(item.status)
          )
        ),
        approved: mapLineItemsAfterFetch(action.items.filter((item) => item.status === lineItemStatusTypes.APPROVED)),
        declined: mapLineItemsAfterFetch(action.items.filter((item) => item.status === lineItemStatusTypes.DECLINED))
      };
    case LINE_ITEMS_TYPES.SET_FETCH_LINE_ITEMS_STATUS:
      return {
        ...state,
        fetchLineItemsStatus: action.fetchLineItemsStatus
      };
    case LINE_ITEMS_TYPES.SET_PATCH_LINE_ITEMS_STATUS:
      return {
        ...state,
        patchLineItemsStatus: action.patchLineItemsStatus
      };
    case LINE_ITEMS_TYPES.SET_DISCARD_LINE_ITEM:
      return {
        ...state,
        showDiscardLineItem: action.showDiscardLineItem
      };
    case LINE_ITEMS_TYPES.SET_EDITING_LINE_ITEM:
      return {
        ...state,
        editingLineItem: action.editingLineItem
      };
    case LINE_ITEMS_TYPES.SET_ORIGINAL_LINE_ITEMS:
      if (isEmpty(action.items)) {
        return {
          ...state,
          originalLineItemsList: []
        };
      }
      return {
        ...state,
        originalLineItemsList: action.items
      };
    default:
      return state;
  }
};

const moveItem = (list1, list1Name, list2, list2Name, item) => {
  return {
    [list1Name]: setStatus(
      list1Name,
      list1.filter((item1) => item1.idTemp !== item.idTemp)
    ),
    [list2Name]: setStatus(list2Name, [...list2, { ...item, error: '' }])
  };
};

const setStatus = (listName, array) => {
  let status = '';
  if (listName === NAME_OF_LIST_LINE_ITEMS.approved) {
    status = lineItemStatusTypes.APPROVED;
  }
  if (listName === NAME_OF_LIST_LINE_ITEMS.needApproval) {
    status = lineItemStatusTypes.PENDING;
  }
  if (listName === NAME_OF_LIST_LINE_ITEMS.declined) {
    status = lineItemStatusTypes.DECLINED;
  }
  return array.map((item) => {
    if (!item?.totalCost?.toString()) {
      item.status = lineItemStatusTypes.ESTIMATE_REQUESTED;
    } else {
      item.status = status;
    }
    return item;
  });
};

const updateItemForBodySaveRequest = (incomingItem, array) => {
  const oldItemIndex = array.findIndex((item) => item.idTemp === incomingItem.idTemp);
  if (oldItemIndex < 0) {
    array.push(incomingItem);
  } else {
    if (incomingItem.op === OPERATION_LINE_ITEM.REMOVE && array[oldItemIndex].op === OPERATION_LINE_ITEM.ADD) {
      array.splice(oldItemIndex, 1);
    } else {
      array.splice(oldItemIndex, 1, incomingItem);
    }
  }
  return {
    lineItemForBodySaveRequest: [...array]
  };
};

const mapLineItemsAfterFetch = (listLineItems) => {
  return listLineItems.map((item) => {
    return {
      ...item,
      idTemp: item.id || item.idTemp
    };
  });
};

export const getShowTagNeedApprovel = (lineItemsList) => {
  return lineItemsList.some((lineItems) => lineItems.status === lineItemStatusTypes.PENDING);
};
//#endregion

//#region Selectors
//#endregion

//#region Sagas
export function* getLineItemsSaga() {
  yield takeLatest(LINE_ITEMS_TYPES.GET_LINE_ITEMS, function* ({ task }) {
    try {
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
      const lineItemsData = yield getWithToken(`/api/LineItems`, {
        entityId: task.id,
        entityTypeId: 'TASK'
      });
      yield put(lineItemsActions.setLineItems(lineItemsData.items));

      const updatedTask = { ...task };
      updatedTask.lineItems = { ...lineItemsData };
      yield put(tasksActions.setTasksLineItemStore(updatedTask));

      yield put(lineItemsActions.setOriginalLineItems(lineItemsData.items));
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
      yield put(lineItemsActions.toggleShowNoLineItems(!lineItemsData.count));
    } catch (error) {
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getLineItemsSaga', error);
    }
  });
}

export function* patchLineItemsSaga() {
  yield takeLatest(
    [LINE_ITEMS_TYPES.PATCH_LINE_ITEMS],
    function* ({
      listLineItemToUpdate,
      taskToUpdate,
      isVDP,
      localTasksLineItems,
      closeForm,
      vehicle,
      reconTimestampFilterFlag
    }) {
      try {
        const requestBody = listLineItemToUpdate.map((item) => {
          return {
            op: item.op,
            path: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
            value: {
              entityId: item.entityId,
              entityType: item.entityType,
              status: item.status,
              id: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
              description: item.description,
              laborRate: item.laborRate || 0,
              laborTime: item.laborTime || 0,
              laborCost: item.laborCost,
              partsCost: item.partsCost,
              totalCost: item.totalCost
            }
          };
        });
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
        yield put(
          messagesActions.notify('loading', 'Saving...', {
            duration: 0.05,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield patchWithToken(`/api/LineItems`, requestBody);
        // reset line items
        yield put(lineItemsActions.resetLineItemForBodySaveRequest());
        const lineItemsData = yield getWithToken(`/api/LineItems`, {
          entityId: taskToUpdate.id,
          entityTypeId: 'TASK'
        });

        // updateTaskNeedsApprovalStatus to show tag
        let updatedVehicle = {
          ...vehicle
        };
        let showTagNeedApprovel = getShowTagNeedApprovel(lineItemsData.items);

        let taskUpdatedIndex = updatedVehicle.tasks.items.findIndex((item) => item.id === taskToUpdate.id);
        updatedVehicle.reconPlan.reconTaskGroups.items.forEach((taskGroup) => {
          taskGroup.reconTasks.items.forEach((task) => {
            if (task.id === taskToUpdate.id) {
              task.needsApproval = showTagNeedApprovel;
              task.completedCost = taskToUpdate.completedCost;
            }
          });
        });
        if (updatedVehicle.tasks.items[taskUpdatedIndex]) {
          updatedVehicle.tasks.items[taskUpdatedIndex].needsApproval = showTagNeedApprovel;
          updatedVehicle.tasks.items[taskUpdatedIndex].completedCost = taskToUpdate.completedCost;
        }

        // only update final cost when task completed
        if (taskToUpdate.completedOn) {
          yield putWithToken(`/api/ReconTasks/id/${taskToUpdate.id}/completedCost`, {
            completedCost: taskToUpdate.completedCost
          });
        }
        yield put(vehiclesActions.setVehicleById(updatedVehicle.id, updatedVehicle));

        yield put(lineItemsActions.setLineItems(lineItemsData.items));

        // update line items for vehicle
        if (isVDP) {
          yield put(
            tasksActions.getTasksLineItemByVehicleId(
              taskToUpdate.vehicleId,
              { ...localTasksLineItems },
              reconTimestampFilterFlag
            )
          );
        }

        yield put(
          messagesActions.notify('success', 'Successfully updated line items!', {
            duration: 2,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield put(messagesActions.clear());
        if (closeForm) {
          closeForm();
        }

        if (isVDP) yield put(lineItemsActions.resetLineItem());
        if (!isVDP || isEmpty(lineItemsData.items)) {
          yield put(lineItemsActions.resetLineItem());
        }
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        yield put(
          messagesActions.notify('error', 'Failed to update line items!', {
            duration: 2,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield put(messagesActions.clear());
        if (closeForm) {
          closeForm();
        }
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
        if (isVDP) yield put(lineItemsActions.resetLineItem());
        devLogger.log('error in patchLineItemsSaga', error);
      }
    }
  );
}

export function* patchLineItemsTDPSaga() {
  yield takeLatest(
    [LINE_ITEMS_TYPES.PATCH_LINE_ITEMS_TDP],
    function* ({ listLineItemToUpdate, taskToUpdate, contentType, isUpdating }) {
      try {
        const requestBody = listLineItemToUpdate.map((item) => {
          return {
            op: item.op,
            path: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
            value: {
              entityId: item.entityId,
              entityType: item.entityType,
              status: item.status,
              id: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
              description: item.description,
              laborRate: item.laborRate || 0,
              laborTime: item.laborTime || 0,
              laborCost: item.laborCost,
              partsCost: item.partsCost,
              totalCost: item.totalCost
            }
          };
        });
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
        yield put(lineItemsActions.setPatchLineItemsStatus(apiStatusConstants.IS_FETCHING));
        yield put(
          messagesActions.notify('loading', 'Saving...', {
            duration: 0.05,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield patchWithToken(`/api/LineItems`, requestBody);
        // reset line items
        yield put(lineItemsActions.resetLineItemForBodySaveRequest());
        const lineItemsData = yield getWithToken(`/api/LineItems`, {
          entityId: taskToUpdate.id,
          entityTypeId: 'TASK'
        });

        //if call api not on completeTask set lineitem
        if (contentType === detailContents.TASK_DETAILS && !isUpdating) {
          yield put(lineItemsActions.setLineItems(lineItemsData.items));
          yield put(tasksActions.setRefreshTasks(taskToUpdate.id, lineItemsData.items));

          const updatedTask = { ...taskToUpdate };
          updatedTask.lineItems = { ...lineItemsData };
          yield put(tasksActions.setTasksLineItemStore(updatedTask));
        }

        yield put(
          messagesActions.notify('success', 'Successfully updated line items!', {
            duration: 2,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield put(messagesActions.clear());
        if (isEmpty(lineItemsData.items)) {
          yield put(lineItemsActions.resetLineItem());
        }
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
        yield put(lineItemsActions.setPatchLineItemsStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        yield put(
          messagesActions.notify('error', 'Failed to update line items!', {
            duration: 2,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield put(messagesActions.clear());
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
        yield put(lineItemsActions.setPatchLineItemsStatus(apiStatusConstants.FAILED));
        devLogger.log('error in patchLineItemsTDPSaga', error);
      }
    }
  );
}

export function* patchLineItemsPlanTemplateSaga() {
  yield takeLatest(
    [LINE_ITEMS_TYPES.PATCH_LINE_ITEMS_PLAN_TEMPLATE],
    function* ({ listLineItemToUpdate, taskToUpdate, closeForm }) {
      try {
        const requestBody = listLineItemToUpdate.map((item) => {
          return {
            op: item.op,
            path: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
            value: {
              reconPlanTemplateTaskId: item.reconPlanTemplateTaskId,
              preApprove: lineItemStatusTypes.APPROVED === item.status,
              id: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
              description: item.description,
              laborRate: item.laborRate || 0,
              laborTime: item.laborTime || 0,
              laborCost: item.laborCost,
              partsCost: item.partsCost,
              totalCost: item.totalCost
            }
          };
        });
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
        yield put(
          messagesActions.notify('loading', 'Saving...', {
            duration: 0.05,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield patchWithToken(`/api/LineItemTemplates`, requestBody);
        // reset line items
        yield put(lineItemsActions.resetLineItemForBodySaveRequest());
        yield put(lineItemsActions.resetLineItem());

        yield put(
          messagesActions.notify('success', 'Successfully updated line items!', {
            duration: 2,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield put(messagesActions.clear());
        if (closeForm) {
          closeForm();
        }

        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        yield put(
          messagesActions.notify('error', 'Failed to update line items!', {
            duration: 2,
            location: LOCATION_MESSAGE_LINE_ITEM
          })
        );
        yield put(messagesActions.clear());
        if (closeForm) {
          closeForm();
        }
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
        yield put(lineItemsActions.resetLineItem());
        devLogger.log('error in patchLineItemsPlanTemplateSaga', error);
      }
    }
  );
}

export function* getLineItemsReconPlanTemplateTaskSaga() {
  yield takeLatest(
    LINE_ITEMS_TYPES.GET_LINE_ITEMS_RECON_PLAN_TEMPLATE_TASK,
    function* ({ reconPlanTemplateId, reconPlanTemplateTaskId }) {
      try {
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
        const lineItemsData = yield getWithToken(`/api/LineItemTemplates`, {
          reconPlanTemplateTaskId
        });
        yield put(
          planTemplatesActions.updatelineItemTemplatesTask(
            reconPlanTemplateId,
            reconPlanTemplateTaskId,
            lineItemsData.items.map((lineItem) => updateStatusForLineItemTemplate(lineItem))
          )
        );
        yield put(
          lineItemsActions.setLineItems(
            lineItemsData.items.map((lineItem) => updateStatusForLineItemTemplate(lineItem))
          )
        );
        yield put(
          lineItemsActions.setOriginalLineItems(
            lineItemsData.items.map((lineItem) => updateStatusForLineItemTemplate(lineItem))
          )
        );
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
        yield put(lineItemsActions.toggleShowNoLineItems(!lineItemsData.count));
      } catch (error) {
        yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getLineItemsReconPlanTemplateTaskSaga', error);
      }
    }
  );
}

// update total cost with tash status is completed when edit line items
export function* updateReconTaskSaga() {
  yield takeLatest([LINE_ITEMS_TYPES.UPDATE_RECON_TASK], function* ({ taskToUpdate }) {
    try {
      const taskBody = { ...taskToUpdate };
      delete taskBody.lineItems;
      yield putWithToken(`/api/ReconTasks/id/${taskToUpdate.id}`, taskBody);
    } catch (error) {
      yield put(
        messagesActions.notify('error', 'Failed to update line items!', {
          duration: 2,
          location: LOCATION_MESSAGE_LINE_ITEM
        })
      );
      yield put(messagesActions.clear());
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
      devLogger.log('error in updateReconTaskSaga', error);
    }
  });
}

export function* patchLineItemsTaskTemplateSaga() {
  yield takeLatest([LINE_ITEMS_TYPES.PATCH_LINE_ITEMS_TASK_TEMPLATE], function* ({ listLineItemToUpdate, closeForm }) {
    try {
      const requestBody = listLineItemToUpdate.map((item) => {
        return {
          op: item.op,
          path: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
          value: {
            reconTaskTypeId: item.reconTaskTypeId,
            preApprove: lineItemStatusTypes.APPROVED === item.status,
            id: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
            description: item.description,
            laborRate: item.laborRate || 0,
            laborTime: item.laborTime || 0,
            laborCost: item.laborCost,
            partsCost: item.partsCost,
            totalCost: item.totalCost
          }
        };
      });
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
      yield put(
        messagesActions.notify('loading', 'Saving...', {
          duration: 0.05,
          location: LOCATION_MESSAGE_LINE_ITEM
        })
      );
      yield patchWithToken(`/api/LineItemTemplateTemplates`, requestBody);
      // reset line items
      yield put(lineItemsActions.resetLineItemForBodySaveRequest());
      yield put(lineItemsActions.resetLineItem());

      yield put(
        messagesActions.notify('success', 'Successfully updated line items!', {
          duration: 2,
          location: LOCATION_MESSAGE_LINE_ITEM
        })
      );
      yield put(messagesActions.clear());
      if (closeForm) {
        closeForm();
      }

      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(
        messagesActions.notify('error', 'Failed to update line items!', {
          duration: 2,
          location: LOCATION_MESSAGE_LINE_ITEM
        })
      );
      yield put(messagesActions.clear());
      if (closeForm) {
        closeForm();
      }
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
      yield put(lineItemsActions.resetLineItem());
      devLogger.log('error in patchLineItemsTaskTemplateSaga', error);
    }
  });
}

export function* getLineItemsTaskTemplateSaga() {
  yield takeLatest(LINE_ITEMS_TYPES.GET_LINE_ITEMS_TASK_TEMPLATE, function* ({ reconTaskTypeId }) {
    try {
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.IS_FETCHING));
      const lineItemsData = yield getWithToken(`/api/LineItemTemplateTemplates`, {
        reconTaskTypeId
      });
      yield put(
        lineItemsActions.setLineItems(lineItemsData.items.map((lineItem) => updateStatusForLineItemTemplate(lineItem)))
      );
      yield put(
        lineItemsActions.setOriginalLineItems(
          lineItemsData.items.map((lineItem) => updateStatusForLineItemTemplate(lineItem))
        )
      );
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
      yield put(lineItemsActions.toggleShowNoLineItems(!lineItemsData.count));
    } catch (error) {
      yield put(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getLineItemsTaskTemplateSaga', error);
    }
  });
}
//#endregion
