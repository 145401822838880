import React, { useContext } from 'react';
import { Button, Divider, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { vehiclesActions } from 'store/vehiclesStore';
import { tasksActions } from 'store/tasksStore';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { theme as themeStyles } from 'styles/appTheme';
import { metricsActions } from 'store/metricsStore';
import { enterpriseMetricsActions } from 'store/enterpriseMetricsStore';
import { apiStatusConstants, dashboardPreferencesTypes, statisticTagTypes } from 'app-constants';
import { createSelector } from 'reselect';
import { useLocalStorage } from 'hooks';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { useCommonDropdownContext } from './CommonDropdown';

//custom selector
const exportFetchStatusesSelector = createSelector(
  (state) => state.tasks.tasksExportFetchStatus,
  (state) => state.vehicles.vehiclesExportFetchStatus,
  (state) => state.metrics.exportHistoricalFetchStatus,
  (state) => state.metrics.exportInventoryFetchStatus,
  (state) => state.enterpriseMetrics.needsAttention.exportNeedAttentionFetchStatus,
  (state) => state.enterpriseMetrics.historical.exportHistoricalFetchStatus,
  (state) => state.enterpriseMetrics.inventory.exportEnterprisePerformanceMetricsFetchStatus,
  (
    tasksExportFetchStatus,
    vehiclesExportFetchStatus,
    exportHistoricalFetchStatus,
    exportInventoryFetchStatus,
    exportNeedAttentionFetchStatus,
    exportHistoricalEnterpriseFetchStatus,
    exportEnterprisePerformanceMetricsFetchStatus
  ) => ({
    tasksExportFetchStatus,
    vehiclesExportFetchStatus,
    exportHistoricalFetchStatus,
    exportInventoryFetchStatus,
    exportNeedAttentionFetchStatus,
    exportHistoricalEnterpriseFetchStatus,
    exportEnterprisePerformanceMetricsFetchStatus
  })
);

// Override Icon
const IconOverride = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.lightBlueLinkColor};
  height: 20px;
  width: 14px;
  font-family: 'Font Awesome 5 Free';
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 3px;
`;

// Make Export Link Look Like A Button
const LinkButton = styled(Button)`
  &.ant-btn-link {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.lightBlueLinkColor} !important;
    height: 26.25px;
    .ant-typography {
      color: ${({ theme }) => theme.colors.lightBlueLinkColor};
      display: inline-block;
      line-height: 26.25px;
    }

    &:focus {
      box-shadow: ${({ theme }) => theme.borders.inactiveNavBorder};
      outline: 0;
    }

    ${({ shouldShowDealershipFilter }) =>
      shouldShowDealershipFilter &&
      `
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row-wrap;
    `}
  }

  &.ant-btn-link[disabled] {
    opacity: 0.35;
    :hover {
      text-decoration: none !important;
      cursor: not-allowed;
    }
  }

  &.ant-btn:hover {
    text-decoration: underline !important;
    outline: 0;
    cursor: pointer;
  }

  &.ant-btn-loading {
    &::before {
      background-color: ${({ theme, loadingMask }) => loadingMask ?? theme.colors.appBackgroundColor};
    }
  }

  &.ant-btn.ant-btn-loading {
    margin-left: 22px !important;
  }
`;

const ExportFunctionality = ({
  filters,
  sort,
  exportType,
  currentEntityId,
  flags,
  dividerUndisplay,
  taskBreakdownSort,
  enterpriseView,
  doesExportHiddenTasks
}) => {
  const dispatch = useDispatch();
  const [, , getData] = useLocalStorage('dashboardPreferences', {});
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const { items } = useCommonDropdownContext('dealerships');

  const {
    tasksExportFetchStatus,
    vehiclesExportFetchStatus,
    exportHistoricalFetchStatus,
    exportInventoryFetchStatus,
    exportNeedAttentionFetchStatus,
    exportHistoricalEnterpriseFetchStatus,
    exportEnterprisePerformanceMetricsFetchStatus
  } = useSelector(exportFetchStatusesSelector);

  const getLoadingPerExportType = () => {
    let fetchStatusToCheck;
    switch (exportType) {
      case 'Vehicle':
        fetchStatusToCheck = vehiclesExportFetchStatus;
        break;
      case 'Task':
        fetchStatusToCheck = tasksExportFetchStatus;
        break;
      case 'Historical':
        fetchStatusToCheck = exportHistoricalFetchStatus;
        break;
      case 'Inventory':
        fetchStatusToCheck = exportInventoryFetchStatus;
        break;
      case 'EnterpriseNeedAttention':
        fetchStatusToCheck = exportNeedAttentionFetchStatus;
        break;
      case 'EnterpriseHistorical':
        fetchStatusToCheck = exportHistoricalEnterpriseFetchStatus;
        break;
      case 'EnterpriseInventory':
        fetchStatusToCheck = exportEnterprisePerformanceMetricsFetchStatus;
        break;
      default:
        break;
    }
    return fetchStatusToCheck === apiStatusConstants.IS_FETCHING;
  };

  const getExcludedTaskCategories = (filter) => {
    if (flags.reconExcludeTaskCategories) {
      const dashboardPreferences = getData();
      return dashboardPreferences?.[filter]?.excludedTaskCategories || [];
    }
    return [];
  };

  const getDealershipIds = () => {
    const mappedItems = (items ?? []).filter((item) => item.checked).map((item) => item.key);
    return flags?.reconDealerFilter && isHQ ? mappedItems.join(',') : undefined;
  };

  const fetchExport = (filters, sort, exportType, currentEntityId, doesExportHiddenTasks) => {
    if (filters?.assignedToGroupFilter) {
      let assignedToGroups = JSON.parse(filters?.assignedToGroupFilter);
      if (assignedToGroups.length > 0) {
        let assignedToGroupFilterExcludeEmpty = Object.values(
          assignedToGroups.filter((f) => f.assignedToGroup !== null)
        );
        filters.assignedToGroupFilter = JSON.stringify(assignedToGroupFilterExcludeEmpty);
      }
    }

    switch (exportType) {
      case 'Vehicle':
        dispatch(
          vehiclesActions.getExportData(
            currentEntityId,
            filters,
            sort,
            ['Tasks', 'Comments'],
            0,
            flags?.reconTimestampFilter
          )
        );
        break;
      case 'Task':
        dispatch(tasksActions.getExportData(currentEntityId, 1, filters, sort, 0, doesExportHiddenTasks));
        break;
      case 'Historical':
        dispatch(
          metricsActions.getExportHistoricalMetrics(
            currentEntityId,
            { ...filters, dealershipIds: getDealershipIds() },
            taskBreakdownSort[statisticTagTypes.HISTORICAL_METRICS],
            flags?.reconTimestampFilter
          )
        );
        break;
      case 'Inventory':
        const excludedTaskCategoriesInventory = getExcludedTaskCategories(
          dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS
        );
        dispatch(
          metricsActions.getExportInventoryMetrics(
            currentEntityId,
            {
              inventoryTypes: ['NEW', 'USED'],
              excludedTaskCategories: excludedTaskCategoriesInventory,
              dealershipIds: getDealershipIds()
            },
            taskBreakdownSort[statisticTagTypes.ACTIVE_METRICS],
            flags?.reconTimestampFilter
          )
        );
        break;
      case 'EnterpriseNeedAttention':
        dispatch(enterpriseMetricsActions.getExportNeedAttentionMetrics(currentEntityId, sort, getDealershipIds()));
        break;
      case 'EnterpriseHistorical':
        dispatch(
          enterpriseMetricsActions.getExportHistoricalMetrics(
            currentEntityId,
            { ...filters, dealershipIds: getDealershipIds() },
            flags?.reconTimestampFilter,
            sort
          )
        );
        break;
      case 'EnterpriseInventory':
        dispatch(
          enterpriseMetricsActions.getExportEnterprisePerformanceMetrics(
            currentEntityId,
            flags?.reconTimestampFilter,
            { ...filters, dealershipIds: getDealershipIds() },
            sort
          )
        );
        break;
      default:
        break;
    }
  };

  const shouldShowDealershipFilter = isHQ && flags.reconDealerFilter;
  const shouldDisableButton = shouldShowDealershipFilter && (!items || !items.filter((item) => item.checked).length);

  if (dividerUndisplay) {
    return (
      <div>
        <LinkButton
          className="label"
          style={{
            marginLeft: shouldShowDealershipFilter ? 0 : 10,
            fontWeight: 500,
            padding: shouldShowDealershipFilter ? '0px 0px 0px 2.5px' : '1px 6px'
          }}
          onClick={() => fetchExport(filters, sort, exportType, currentEntityId)}
          type="link"
          loading={getLoadingPerExportType()}
          size="small"
          loadingMask={themeStyles.colors.appBackgroundColor}
          disabled={shouldDisableButton}
          shouldShowDealershipFilter={shouldShowDealershipFilter}
        >
          {shouldShowDealershipFilter ? (
            <FontAwesomeIcon icon={faFileExcel} style={{ width: '14px', height: '14px', paddingLeft: '5px' }} />
          ) : (
            <IconOverride icon={faFileExcel} style={{ fontSize: 18, paddingTop: 5 }} />
          )}
          <Typography style={{ paddingLeft: shouldShowDealershipFilter ? '5px' : 0 }}>
            {flags.reconDealerFilter ? 'Export' : 'Export All'}
          </Typography>
        </LinkButton>
      </div>
    );
  }

  if (enterpriseView) {
    return (
      <div>
        <LinkButton
          className="label"
          style={{
            padding: shouldShowDealershipFilter ? '0px 0px 0px 2.5px' : '0px 7px'
          }}
          onClick={() => fetchExport(filters, sort, exportType, currentEntityId)}
          type="link"
          loading={getLoadingPerExportType()}
          size="small"
          loadingMask={themeStyles.colors.white}
          disabled={shouldDisableButton}
          shouldShowDealershipFilter={shouldShowDealershipFilter}
        >
          {shouldShowDealershipFilter ? (
            <FontAwesomeIcon icon={faFileExcel} style={{ width: '14px', height: '14px', paddingLeft: '5px' }} />
          ) : (
            <IconOverride icon={faFileExcel} style={{ fontSize: 18, paddingTop: 5 }} />
          )}
          <Typography style={{ paddingLeft: shouldShowDealershipFilter ? '5px' : 0 }}>
            {flags.reconDealerFilter ? 'Export' : 'Export All'}
          </Typography>
        </LinkButton>
      </div>
    );
  }

  return (
    <div>
      <Divider
        type="vertical"
        style={{ height: 16, width: 1, boxSizing: 'border-box', backgroundColor: themeStyles.colors.darkGray }}
      />
      <LinkButton
        className="label"
        onClick={() => fetchExport(filters, sort, exportType, currentEntityId, doesExportHiddenTasks)}
        type="link"
        loading={getLoadingPerExportType()}
        size="small"
        loadingMask={themeStyles.colors.appBackgroundColor}
        style={{ padding: '1px 6px' }}
      >
        <IconOverride icon={faDownload} />
        <Typography>Export</Typography>
      </LinkButton>
    </div>
  );
};
export default withLDConsumer()(ExportFunctionality);
