import React from 'react';
import Statistics from 'components/layout/dashboard/sharedComponents/Statistics';
import { createOverviewDataForPresentation } from 'store/helperFunctions/metricsStore';
import {
  EnterpriseStatisticsSkeleton,
  StyledContainer,
  StyledEnterpriseSummaryTitle,
  StyledSummaryContainer,
  StyledSummarySkeletonHeader,
  SummaryItemCard,
  StyledEnterpriseSummaryContainer
} from '../common/styles';
import { useScreenSize } from 'hooks';
import { apiStatusConstants, statisticTagTypes } from 'app-constants';
import {
  generateDealershipsLink,
  getStatisticLink,
  getTaskCategoriesParamsFromExcludedIds
} from '../../commonFunctions';
import ExportFunctionality from '../../../../common/ExportFunctionality';
import { ShowExcludedTaskCategoriesFilter, ExcludeTaskCategoriesDivider } from '../../taskCategoryFilter';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import CommonDropdown, { useCommonDropdownContext } from 'components/common/CommonDropdown';
import { Row } from 'antd';
import { useSelector } from 'react-redux';

const SummaryHeader = ({
  flags,
  data,
  unfilteredData,
  filters,
  fetchStatus,
  createdOn,
  currentEntity,
  useLocalStorageObject,
  sort
}) => {
  const screenSize = useScreenSize();
  let presentationData = [];
  const headerType = 'enterprise';
  const reconDealerFilterFlag = flags?.reconDealerFilter;
  const { items } = useCommonDropdownContext('dealerships');
  const mappedItems = (items ?? []).filter((item) => item.checked).map((item) => item.name);

  const taskCategoriesData = useSelector((state) => state.taskCategories.categoriesAll);
  const [, , getData] = useLocalStorageObject;

  const getExcludedTaskCategoryIds = () => {
    const filterType = 'inventorySnapshotFilters';
    const dashboardPreference = getData();
    return dashboardPreference?.[filterType]?.excludedTaskCategories || [];
  };

  if (fetchStatus === apiStatusConstants.SUCCEEDED) {
    const headerData = {
      latestPeriodVehiclesSummary: flags?.reconExcludeTaskCategories
        ? data.currentPeriodExcludedCategoryMetrics
        : data.currentPeriodMetrics,
      previousPeriodVehiclesSummary: flags?.reconExcludeTaskCategories
        ? data.previousPeriodExcludedCategoryMetrics
        : data.previousPeriodMetrics,
      earliestDateAvailable: createdOn,
      startDateChosen: filters.startTime,
      endDateChosen: filters.endTime,
      daysAgo: filters.daysAgo,
      taskBreakdowns: data.dealershipLevelMetrics
    };
    const [, ...snapshotOverviewData] = createOverviewDataForPresentation(headerData, reconDealerFilterFlag);
    presentationData = snapshotOverviewData;
  }
  const disableLinkForNonSpecialColumns =
    flags.reconDealerFilter && taskCategoriesData?.data?.length === getExcludedTaskCategoryIds()?.length;
  const noDealershipFiltersSelectedHQ = flags.reconDealerFilter && mappedItems.length === 0;

  return (
    <>
      <StyledContainer>
        <StyledEnterpriseSummaryContainer>
          <StyledEnterpriseSummaryTitle>Performance Metrics</StyledEnterpriseSummaryTitle>
          {flags.reconExcludeTaskCategories ? (
            <>
              <ExcludeTaskCategoriesDivider />
              <ShowExcludedTaskCategoriesFilter
                metricsType={statisticTagTypes.ACTIVE_METRICS}
                useLocalStorageObject={useLocalStorageObject}
                enterpriseDrawer
              />
            </>
          ) : (
            <></>
          )}
        </StyledEnterpriseSummaryContainer>
        <Row type="flex" align="middle" justify="center">
          {flags.reconDealerFilter && (
            <CommonDropdown
              title="Dealerships: "
              identifier="dealerships"
              tooltipMessage="Use the “Dealerships” menu to select one or more dealerships."
              additionalStyle={{ marginLeft: '12px' }}
            />
          )}
          <ExportFunctionality
            exportType={'EnterpriseInventory'}
            currentEntityId={currentEntity.id}
            enterpriseView={true}
            sort={sort}
            filters={filters}
          />
        </Row>
      </StyledContainer>
      <StyledSummaryContainer screenSize={screenSize} statisticsCount={4}>
        {fetchStatus !== apiStatusConstants.SUCCEEDED ? (
          <StyledSummarySkeletonHeader>
            <EnterpriseStatisticsSkeleton statisticsCount={4} />
          </StyledSummarySkeletonHeader>
        ) : (
          <>
            {presentationData.map((statistics, index) => (
              <SummaryItemCard size="small" summaryItemCard="default" key={statistics.title}>
                <Statistics
                  key={statistics.title}
                  fetchStatus={data.fetchStatus}
                  statistics={statistics}
                  screenSize={screenSize}
                  headerType={headerType}
                  statisticLink={getStatisticLink(
                    false,
                    statistics.title,
                    null,
                    null,
                    statistics.title === 'Avg Time Before Plan'
                      ? [generateDealershipsLink(reconDealerFilterFlag ? mappedItems : null)]
                      : [
                          ...getTaskCategoriesParamsFromExcludedIds(
                            getExcludedTaskCategoryIds(),
                            taskCategoriesData.dict
                          ),
                          generateDealershipsLink(reconDealerFilterFlag ? mappedItems : null)
                        ],
                    disableLinkForNonSpecialColumns,
                    noDealershipFiltersSelectedHQ,
                    false,
                    flags.reconHiddenFilter
                  )}
                  comparativeStatisticLink={getStatisticLink(
                    false,
                    statistics.title,
                    null,
                    null,
                    statistics.title === 'Avg Time Before Plan'
                      ? [generateDealershipsLink(reconDealerFilterFlag ? mappedItems : null)]
                      : [
                          ...getTaskCategoriesParamsFromExcludedIds(
                            getExcludedTaskCategoryIds(),
                            taskCategoriesData.dict
                          ),
                          generateDealershipsLink(reconDealerFilterFlag ? mappedItems : null)
                        ],
                    disableLinkForNonSpecialColumns,
                    noDealershipFiltersSelectedHQ,
                    true,
                    flags.reconHiddenFilter
                  )}
                  unfilteredStats={unfilteredData ? unfilteredData[index] : null}
                />
              </SummaryItemCard>
            ))}
          </>
        )}
      </StyledSummaryContainer>
    </>
  );
};

export default withLDConsumer()(SummaryHeader);
