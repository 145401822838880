import React from 'react';
import styled from 'styled-components';
import { CommonLinkButton } from 'components/styledComponents';
import { communicationContexts } from 'app-constants';

const SeeMoreReply = ({ count, taskId, setShowSeeMoreReply, setScrollTo }) => {
  const handleShowMoreReply = () => {
    setScrollTo(communicationContexts.NO_SCROLL_ID);
    setShowSeeMoreReply(taskId, true);
  };
  return (
    <StyledContainer>
      <CommonLinkButton onClick={handleShowMoreReply}>
        See {count} more {count > 1 ? 'replies' : 'reply'}
      </CommonLinkButton>
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  display: flex;
  margin-left: 32px;
  margin-bottom: 0;
  .ant-btn {
    height: 20px;
  }
`;
//#endregion

export default SeeMoreReply;
