import React, { useState, useRef, useCallback, useMemo, useEffect, useContext } from 'react';
import { EditorState, convertToRaw, convertFromRaw, ContentState, Modifier, SelectionState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import mentionsStyles from './MentionsStyles.module.css';
import styled from 'styled-components';
import {
  taggingTypes,
  WIDTH_MESSAGE_TAGGING_LIST,
  MAXIMUM_TAGGING_TEXT_COUNT,
  MINIMUM_VENDOR_TEXT_COUNT
} from 'app-constants';
import { useSelector } from 'react-redux';
import { getWithToken } from 'api';
import { emojiRegExp } from 'utils';
import { RootContext, HQAndProfitTimeContext } from 'utils/contexts';
const notHandled = 'not-handled';
export const MentionsSuggestion = ({
  transientCommunication = '',
  setTransientCommunication = null,
  setEnterSubmitContent = null,
  setContentData = null,
  taggableUsers = [],
  taskId = null,
  placeholder = null,
  isReply = false,
  isEdit = false,
  isCreateANote = false,
  disabled,
  flags
}) => {
  let editorRef = null;
  let editorInit;
  const mentionRef = useRef(null);
  const taggableUsersRef = useRef();
  const LINE_HEIGHT = 20;
  const { id: currentDealerId } = useSelector((state) => state.dealers.current.data);
  const { id: currentVendorId } = useSelector((state) => state.vendors.vendor);

  const contextValue = useContext(RootContext);

  if (transientCommunication && isEdit && !editorInit) {
    if (typeof transientCommunication === 'string') {
      editorInit = EditorState.createWithContent(ContentState.createFromText(transientCommunication));
    } else {
      editorInit = EditorState.createWithContent(convertFromRaw(transientCommunication));
    }
    //move focus to the end.
    // editorInit = EditorState.moveFocusToEnd(editorInit);
  } else {
    editorInit = EditorState.createEmpty();
  }

  const [editorState, setEditorState] = useState(editorInit);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(taggableUsers);
  const [currentRows, setCurrentRows] = useState(1);
  const [hasFocus, setHasFocus] = useState(false);
  const [isPastedText, setIsPastedText] = useState(false);
  const { isHQ } = useContext(HQAndProfitTimeContext);

  useEffect(() => {
    if (transientCommunication) {
      let newCurrentRows = Math.floor(mentionRef?.current?.firstChild?.clientHeight / LINE_HEIGHT);
      setCurrentRows(newCurrentRows);

      if (setContentData && typeof transientCommunication !== 'string') {
        setContentData(transientCommunication);
      }
    }

    if (editorRef?.editor && isEdit) {
      setTimeout(editorRef.editor.focus, 0);
    }
  }, []);

  useEffect(() => {
    if (transientCommunication === '' && disabled) {
      setEditorState(EditorState.createEmpty());
      setContentData('');
      setIsPastedText(false);
      setHasFocus(false);
    }
  }, [disabled]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '@',
      supportWhitespace: true,
      positionSuggestions: (settings) => {
        const clientHeight = mentionRef?.current?.firstChild?.clientHeight;
        const percentage = settings.popover.offsetHeight + clientHeight;
        const offsetWidth = settings.popover.offsetWidth;
        // 555 is distance with top
        const translate = settings.decoratorRect.top < 555 ? 0 : `-${percentage}`;
        return {
          width: `${offsetWidth}px`,
          transform: `scale(1) translateY(${translate}px)`,
          position: 'absolute',
          transformOrigin: '1em 0% 0px',
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)'
        };
      }
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
    if (setEnterSubmitContent) {
      setEnterSubmitContent(_open);
    }
  }, []);

  const customSuggestionsFilter = (searchValue, suggestions) => {
    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    return suggestions.filter((suggestion) => !value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1);
  };

  const onSearchChange = useCallback(({ value }) => {
    if ((currentDealerId || currentVendorId || contextValue?.rootDealerId) && taskId) {
      if (!taggableUsersRef?.current) {
        getWithToken(`/api/Users/TaggableUsers`, {
          dealerId: contextValue?.rootDealerId ?? currentDealerId ?? currentVendorId,
          taggingType: taggingTypes.TASK_MESSAGE,
          taskId
        })
          .then((response) => {
            const users = response.map((r) => {
              return {
                id: r.id,
                name: checkItemDisplaying(
                  r?.entityName ? `${r?.entityName} (${r.fullName})` : r.fullName,
                  flags.reconVendorManagement
                )
              };
            });
            taggableUsersRef.current = users;
            setSuggestions(customSuggestionsFilter(value, users));
          })
          .catch(() => {
            taggableUsersRef.current = [];
            setSuggestions(customSuggestionsFilter(value, []));
          });
      } else {
        setSuggestions(customSuggestionsFilter(value, taggableUsersRef.current));
      }
    } else {
      setSuggestions(customSuggestionsFilter(value, taggableUsers));
    }
  }, []);

  const checkItemDisplaying = (fullDisplayedName, vendorManagementFlag) => {
    const { minVendorShipLength, maxLengthViewing } = getMinAndMaxLengthViewing();
    if (fullDisplayedName.length <= maxLengthViewing || !vendorManagementFlag) {
      return fullDisplayedName;
    } else {
      const indexParentheses = fullDisplayedName.indexOf('(');
      if (indexParentheses > 0) {
        //Name of Vendor User or Internal Group within parentheses
        let nameInParentheses = fullDisplayedName.substring(indexParentheses);
        if (nameInParentheses.length >= maxLengthViewing) {
          const remaingOfFullNameViewing = maxLengthViewing - minVendorShipLength - 3; //minus 3 last characters (...)
          nameInParentheses = `${nameInParentheses.slice(0, remaingOfFullNameViewing)}...`;
        }
        //Name of Vendor Ship or User
        const remaningLengthDisplaying = maxLengthViewing - nameInParentheses.length - 3; //minus 3 last characters (...)
        const truncateName = `${fullDisplayedName.slice(0, remaningLengthDisplaying)}...`;
        //Return names that be displayed on the selection
        return `${truncateName} ${nameInParentheses}`;
      } else {
        return `${fullDisplayedName.slice(0, maxLengthViewing - 3)}...`;
      }
    }
  };

  const getMinAndMaxLengthViewing = () => {
    const result = {
      minVendorShipLength: MINIMUM_VENDOR_TEXT_COUNT,
      maxLengthViewing: MAXIMUM_TAGGING_TEXT_COUNT
    };
    const currentDrawerWidth = mentionRef?.current?.firstChild?.clientWidth;
    const percentageWidthWithDefault = currentDrawerWidth / WIDTH_MESSAGE_TAGGING_LIST;
    result.maxLengthViewing = Math.round(percentageWidthWithDefault * MAXIMUM_TAGGING_TEXT_COUNT);
    if (result.maxLengthViewing <= MINIMUM_VENDOR_TEXT_COUNT) {
      result.minVendorShipLength = Math.round(percentageWidthWithDefault * MINIMUM_VENDOR_TEXT_COUNT);
    }
    return result;
  };

  const trimEditorState = (currentEditorState) => {
    const currentContent = currentEditorState.getCurrentContent();
    const newContent = currentContent.getBlockMap().reduce((accumulator, block) => {
      const key = block.getKey();
      const text = block.getText();
      const trimmedLeft = text.trimLeft().replace(/\r+/gm, '');
      const trimmedRight = text.trimRight().replace(/\r+/gm, '');
      const offset = text.length - trimmedLeft.length;

      const textToReplaceLeft = new SelectionState({
        anchorKey: key,
        focusKey: key,
        anchorOffset: 0,
        focusOffset: offset
      });

      const leftTrimmedContent = Modifier.replaceText(accumulator, textToReplaceLeft, '');

      const textToReplacRight = new SelectionState({
        anchorKey: key,
        focusKey: key,
        anchorOffset: trimmedRight.length - offset,
        focusOffset: text.length - offset
      });

      return Modifier.replaceText(leftTrimmedContent, textToReplacRight, '');
    }, currentContent);

    return EditorState.push(currentEditorState, newContent, 'remove-range');
  };

  //Handle backspace key command to delete emoji if existed
  const handleKeyCommand = (command) => {
    if (command !== 'backspace') {
      return notHandled;
    }

    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      return notHandled;
    }

    const startOffset = selection.getStartOffset();

    if (startOffset < 0) {
      return notHandled;
    }

    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(selection.getStartKey());
    const blockText = block.getText();

    if ((blockText.codePointAt(startOffset - 1) || 0) <= 127) {
      return notHandled;
    }

    const lastChars = blockText.slice(0, startOffset);
    const match = lastChars.match(emojiRegExp);
    if (match) {
      const anchorOffset = startOffset - match[match.length - 1].length;
      const emojiSelection = new SelectionState({
        anchorOffset,
        anchorKey: block.getKey(),
        focusOffset: startOffset,
        focusKey: block.getKey(),
        isBackward: false,
        hasFocus: selection.getHasFocus()
      });

      const style = block.getInlineStyleAt(anchorOffset);
      const entity = block.getEntityAt(anchorOffset);

      setEditorState(
        EditorState.push(editorState, Modifier.replaceText(content, emojiSelection, '', style, entity), 'remove-range')
      );
      return 'handled';
    }

    return notHandled;
  };

  const onEditorChange = useCallback((state) => {
    let newCurrentRows = Math.floor(mentionRef?.current?.firstChild?.clientHeight / LINE_HEIGHT);

    setEditorState(state);
    const value = state.getCurrentContent().getPlainText().trim();
    // set scroll when row >= 7
    if (value === '' && newCurrentRows) {
      setIsPastedText(false);
    }
    if (newCurrentRows) setCurrentRows(newCurrentRows);

    if (setTransientCommunication) {
      setTransientCommunication(value);
      const newEditorState = trimEditorState(state);
      const contentData = value ? convertToRaw(newEditorState.getCurrentContent()) : '';
      setContentData(contentData);
    }
  }, []);

  const focus = () => {
    editorRef.focus();
  };

  const onFocusEditor = () => {
    setHasFocus(true);
  };

  const onBlurEditor = () => {
    setHasFocus(false);
  };

  const handlePastedText = () => {
    setIsPastedText(true);
  };

  return (
    <StyledMentions
      className={`mention-editor ${hasFocus ? 'mentions-border' : ''}`}
      currentRows={currentRows}
      isPastedText={isPastedText}
      bg-disabled={disabled}
      ref={mentionRef}
    >
      <StyledEditor
        onClick={focus}
        isEdit={isEdit}
        isReply={isReply}
        currentRows={currentRows}
        isCreateANote={isCreateANote}
        isPastedText={isPastedText}
      >
        <Editor
          placeholder={placeholder}
          editorKey={'editor'}
          editorState={editorState}
          onChange={onEditorChange}
          onFocus={onFocusEditor}
          handleKeyCommand={handleKeyCommand}
          onBlur={onBlurEditor}
          handlePastedText={handlePastedText}
          plugins={plugins}
          readOnly={disabled}
          ref={(element) => {
            editorRef = element;
          }}
          spellCheck
          stripPastedStyles
        />
      </StyledEditor>

      <div
        style={{
          visibility: suggestions.length ? 'visible' : 'hidden',
          height: 0
        }}
      >
        {isHQ ? null : (
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            onAddMention={() => {
              // get the mention object selected
            }}
            flags={flags}
          />
        )}
      </div>
    </StyledMentions>
  );
};

const StyledEditor = styled.div`
  ${(props) =>
    props['currentRows'] > 1 && props['isReply'] && !props['isPastedText']
      ? 'margin-bottom: 30px'
      : 'margin-bottom: 0px'}
  overflow-y: ${(props) =>
    (props['isEdit'] && props['currentRows'] >= 4) || props['currentRows'] >= 7 || props['isCreateANote']
      ? 'auto'
      : 'hidden'};
  max-height: ${(props) => {
    if (props['isReply']) {
      return '145px';
    } else {
      if (props['isEdit']) {
        return '90px';
      } else {
        return props['isCreateANote'] ? '72px' : 'unset';
      }
    }
  }};
  padding: 5px 12px;
  background: ${(props) => props['bg-disabled'] && '#f5f5f5'};
  color: ${(props) => props['bg-disabled'] && '#90939D'};
`;

const StyledMentions = styled.div`
  :active,
  :focus,
  :hover {
    border: ${(props) => !props['bg-disabled'] && props.theme.borders.thickSolidLightNavy} !important;
    border-right-width: 2px !important;
    border-radius: 4px;
  }

  background: ${(props) => props['bg-disabled'] && '#f5f5f5'};
  color: ${(props) => props['bg-disabled'] && '#90939D !important'};
`;
