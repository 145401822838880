import * as yup from 'yup';
import { regExp } from 'app-constants/regExp';
import { countries, provinces, states } from '../BusinessProfile/data';

export const data = [
  {
    title: 'Your Information',
    showTitle: false,
    tooltip:
      'Please enter your first name, last name and preferred phone number. You can update the email later in "My Profile".',
    data: [
      {
        key: 'forms:1',
        label: 'Your First Name',
        name: 'firstName',
        placeholder: '',
        required: true,
        disabled: false,
        maxLength: 50
      },
      {
        key: 'forms:2',
        label: 'Your Last Name',
        name: 'lastName',
        placeholder: '',
        required: true,
        disabled: false,
        maxLength: 50
      },
      {
        key: 'forms:3',
        label: 'Your Phone',
        name: 'phone',
        placeholder: '###-###-####',
        required: true,
        disabled: false
      },
      {
        key: 'forms:4',
        label: 'Your Email',
        name: 'email',
        placeholder: 'Email@example.com',
        required: true,
        disabled: true
      }
    ]
  },
  {
    title: 'General Business Details',
    showTitle: false,
    tooltip:
      'Please enter the name of your business, its primary phone number and its email address. This information will be visible to dealers.',
    data: [
      {
        key: 'forms-2:1',
        label: 'Business Name',
        name: 'businessName',
        placeholder: '',
        required: true,
        disabled: false,
        maxLength: 50
      },
      {
        key: 'forms-2:2',
        label: 'Business Phone',
        name: 'businessPhone',
        placeholder: '###-###-####',

        required: true,
        disabled: false
      },
      {
        key: 'forms-2:3',
        label: 'Business Email',
        name: 'businessEmail',
        placeholder: 'Email@example.com',

        required: true,
        disabled: false
      }
    ]
  },
  {
    title: 'Business Address',
    showTitle: false,
    tooltip:
      'Please enter the preferred address for your business. This will make it easier for nearby dealers to work with you.',
    data: [
      {
        key: 'forms-1:0',
        label: 'Country',
        name: 'country',
        placeholder: 'United States',
        required: true,
        dropdown: true,
        data: countries
      },
      {
        key: 'forms-1:1',
        label: 'Street Address',
        name: 'address',
        placeholder: '',
        required: true,
        maxLength: 50
      },
      {
        key: 'forms-1:2',
        label: 'Suite/Bldg/Room/Other',
        name: 'address2',
        placeholder: '',
        required: false,
        maxLength: 50
      },
      {
        key: 'forms-1:3',
        label: 'City',
        name: 'city',
        placeholder: '',
        required: true,
        size: 13,
        sizeSmall: 24,
        maxLength: 50
      },
      {
        key: 'forms-1:4',
        label: 'State',
        labelCanada: 'Province',
        name: 'state',
        placeholder: '',
        required: true,
        size: 5,
        sizeSmall: 14,
        dropdown: true,
        data: {
          US: states,
          CA: provinces
        }
      },
      {
        key: 'forms-1:5',
        label: 'ZIP Code',
        labelCanada: 'Postal Code',
        name: 'postalCode',
        placeholder: '',
        required: true,
        size: 6,
        sizeSmall: 10
      }
    ]
  }
];

export const informationValidationSchema = yup.object({
  firstName: yup.string().trim().required('First name is required.'),
  lastName: yup.string().trim().required('Last name is required.'),
  email: yup.string().trim().email('Valid email is required.').required('Valid email is required.'),
  phone: yup
    .string()
    .trim()
    .matches(regExp.PHONE, 'Valid phone number is required.')
    .required('Valid phone number is required.')
});

export const businessDetailsValidationSchema = yup.object({
  businessName: yup.string().trim().required('Business name is required.'),
  businessPhone: yup
    .string()
    .trim()
    .matches(regExp.PHONE, 'Valid phone number is required.')
    .required('Valid phone number is required.'),
  businessEmail: yup.string().trim().email('Valid email is required.').required('Valid email is required.')
});

export const businessAddressValidationSchema = yup.object({
  address: yup.string().trim().required('Street name and number are required.'),
  address2: yup.string().trim(),
  state: yup.string().trim().required('Required.'),
  city: yup.string().trim().required('City is required.'),
  postalCode: yup
    .string()
    .trim()
    .test('validate-postal-code', 'Invalid.', (value, context) => {
      let country = context.parent.country;
      if (country && value) {
        if (country === 'Canada') {
          return regExp.CANADA_POSTAL_CODE.test(value);
        } else if (country === 'United States') {
          return regExp.US_POSTAL_CODE.test(value);
        }
      }
      return false;
    })
    .required('Required.')
});
