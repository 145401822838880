import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from '@reach/router';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { currentDealerSelector } from 'store/dealersStore';
import { ShowMoreHqNeedAttentionSummary } from '../enterprise';
import { getNeedAttentionLinks } from '../commonFunctions';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';
import { useConditionalUpdateEffect } from 'hooks/useUpdateEffect';
const { Text } = Typography;

const needAttentionPropsSelector = createSelector(currentDealerSelector, (currentDealersStore) => ({
  currentDealer: currentDealersStore.data
}));

const NeedAttention = ({ flags, activeMetrics, isFetching }) => {
  const { currentDealer } = useSelector(needAttentionPropsSelector);
  const { items } = useCommonDropdownContext('dealerships');
  const [links, setLinks] = useState({});
  const navigate = useNavigate();
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const onMetricClick = (metric) => {
    const url = links[metric];
    navigate(url);
  };
  const mappedDealershipFilters = (items ?? []).filter((item) => item.checked).map((item) => item.name);
  const noDealershipFiltersSelectedHQ = flags.reconDealerFilter && isHQ && mappedDealershipFilters.length === 0;

  useConditionalUpdateEffect(
    () => {
      if (flags.reconDealerFilter && isHQ) {
        setLinks(
          getNeedAttentionLinks(
            currentDealer,
            mappedDealershipFilters,
            noDealershipFiltersSelectedHQ,
            flags.reconHiddenFilter
          )
        );
      } else {
        setLinks(getNeedAttentionLinks(currentDealer, null, false, flags.reconHiddenFilter));
      }
    },
    flags?.reconDealerFilter && isHQ,
    [items, isHQ, currentDealer?.id, flags?.reconDealerFilter]
  );

  return (
    <StyledContainer>
      <Text className="styled-title medium-font dark-gray">Need Attention</Text>
      <StyledRow onClick={() => onMetricClick('No Plan')} noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}>
        <StyledCountBox>{!isFetching && activeMetrics.inventoryNoPlan}</StyledCountBox>
        {isFetching ? (
          <StyledSkeletonContainer>
            <Skeleton />
          </StyledSkeletonContainer>
        ) : (
          <StyledLabel>No Plan</StyledLabel>
        )}
      </StyledRow>
      {
        <StyledRow
          onClick={() => onMetricClick('Pending Approval')}
          noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
        >
          <StyledCountBox>{!isFetching && (activeMetrics.inventoryNeedsApprovalCount || 0)}</StyledCountBox>
          {isFetching ? (
            <StyledSkeletonContainer>
              <Skeleton />
            </StyledSkeletonContainer>
          ) : (
            <StyledLabel>{flags.reconApproveButton ? 'Pending Approval' : 'Needs Approval'}</StyledLabel>
          )}
        </StyledRow>
      }
      <StyledRow
        onClick={() => onMetricClick('Declined Tasks')}
        noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
      >
        <StyledCountBox>{!isFetching && activeMetrics.inventoryDeclinedCount}</StyledCountBox>
        {isFetching ? (
          <StyledSkeletonContainer>
            <Skeleton />
          </StyledSkeletonContainer>
        ) : (
          <StyledLabel>Declined Tasks</StyledLabel>
        )}
      </StyledRow>
      <StyledRow onClick={() => onMetricClick('Overdue')} noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}>
        <StyledCountBox>{!isFetching && activeMetrics.inventoryOverdueCount}</StyledCountBox>
        {isFetching ? (
          <StyledSkeletonContainer>
            <Skeleton />
          </StyledSkeletonContainer>
        ) : (
          <StyledLabel>Overdue</StyledLabel>
        )}
      </StyledRow>
      <StyledRow onClick={() => onMetricClick('Recall')} noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}>
        <StyledCountBox>{!isFetching && activeMetrics.inventoryRecall}</StyledCountBox>
        {isFetching ? (
          <StyledSkeletonContainer>
            <Skeleton />
          </StyledSkeletonContainer>
        ) : (
          <StyledLabel>Recall</StyledLabel>
        )}
      </StyledRow>
      <StyledRow onClick={() => onMetricClick('Sold')} noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}>
        <StyledCountBox>{!isFetching && activeMetrics.inventorySold}</StyledCountBox>
        {isFetching ? (
          <StyledSkeletonContainer>
            <Skeleton />
          </StyledSkeletonContainer>
        ) : (
          <StyledLabel>Sold</StyledLabel>
        )}
      </StyledRow>
      {isHQ && flags.ireconHqViewEnterprise && !isFetching ? <ShowMoreHqNeedAttentionSummary /> : ''}
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  width: 302px; // 350px - 24px padding left/right
  .styled-title {
    margin: 0 0 17px 8px;
  }
`;
const StyledRow = styled.div`
  display: flex;
  ${({ noDealershipFiltersSelectedHQ }) => (noDealershipFiltersSelectedHQ ? '' : 'cursor: pointer;')};
  padding: 8px;
  max-width: 180px;
  border-radius: 4px;
  :hover {
    background-color: ${({ theme }) => theme.colors.backgroundGray};
  }
`;
const StyledCountBox = styled.div.attrs({
  className: 'center-content strong'
})`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background-color: rgba(0, 41, 80, 0.1);
`;
const StyledSkeletonContainer = styled.div`
  margin: 1px 0 0 10px;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  width: 100%;
  max-width: 250px;
`;
const StyledLabel = styled.div.attrs({
  className: 'strong center-content'
})`
  margin-left: 10px;
`;
//#endregion

export default NeedAttention;
