import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import { FolderFilled, CarFilled, SearchOutlined } from '@ant-design/icons';
import { Pagination, Menu, Input, Dropdown, Typography, Radio } from 'antd';
import { accessibleDealersActions, currentDealerActions, rootEntitySwitcherSelector } from 'store/dealersStore';
import { usersActions } from 'store/usersStore';
import { getDealerCookie, CookieWatcher } from 'utils/cookieUtils';
import { useUpdateEffect } from 'hooks';
import {
  apiStatusConstants,
  IS_NEW_SIGNIN,
  VENDOR_USER_INFO,
  onboardingStatusTypes,
  VENDORS,
  DEALERS,
  ROOT_ENTITY_PICKER
} from 'app-constants';
import { useQueryParam } from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { elementRotate } from 'styles/mixins';
import { debounce } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { vendorActions } from 'store/vendorStore';
import { navigate } from '@reach/router';
import { useFeatures } from '../../../../hooks/useFeatures';
import { features } from '../../../../app-constants';
import { isVendorIdValid } from 'utils/stringUtils';

const { Text } = Typography;
const { Search } = Input;

const UsernameAndDealerPickerPropsSelector = createSelector(
  authSelector,
  (state) => state.dealers,
  (state) => state.users.currentUserInfo,
  (authStates, dealerStates, currentUserInfo) => ({
    user: authStates.user,
    currentUserInfo: currentUserInfo,
    defaultDealerId: authStates.defaultDealerId,
    current: dealerStates.current,
    currentDealerIsAccessible: dealerStates.currentDealerIsAccessible,
    vendorShipSelected: dealerStates.vendorShipSelected,
    accessible: dealerStates.accessible
  })
);
const UsernameAndDealerPicker = ({ flags }) => {
  const { isRootUser } = useSelector(rootEntitySwitcherSelector);
  const vendors = useSelector((state) => state.vendors);
  const vendorFetchStatus = useSelector((state) => state.vendors.fetchStatus);
  const vendor = vendors.vendor;

  const dispatch = useDispatch();
  const {
    user,
    currentUserInfo,
    defaultDealerId,
    current,
    currentDealerIsAccessible,
    vendorShipSelected,
    accessible
  } = useSelector(UsernameAndDealerPickerPropsSelector);

  const { pager, filters } = accessible;
  const [queryDealerId] = useQueryParam('dealerId');
  const [entityWhenSwitchingId] = useQueryParam('entity');
  const [vendorEntityWhenSwitchingId] = useQueryParam('vendorEntity');
  const [dealers, setDealers] = useState([]);
  const [vendorship, setVendorship] = useState('Dealers');
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [totalAccessibleCount, setTotalAccessibleCount] = useState(0);
  const [pickerIsEnabled, setPickerIsEnabled] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isExternalTechnicianRole, isAdminGeneralRole] = useFeatures(
    features.EXTERNAL_TECH_ONLY,
    features.ADMINGENERAL
  );
  const isExternalTech = isExternalTechnicianRole && !isAdminGeneralRole;
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
  const parsedVendorUserInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
  const vendorUserFirstName =
    parsedVendorUserInfo && parsedVendorUserInfo.firstName ? parsedVendorUserInfo.firstName : '';
  const vendorUserLastName = parsedVendorUserInfo && parsedVendorUserInfo.lastName ? parsedVendorUserInfo.lastName : '';
  const userFirstName = isNewSignin ? vendorUserFirstName : currentUserInfo?.firstName;
  const userLastName = isNewSignin ? vendorUserLastName : currentUserInfo?.lastName;
  // dealer cookie
  const [localDealerCookie, setLocalDealerCookie] = useState(getDealerCookie());
  // configure a cookie watcher to monitor changes
  CookieWatcher('CurrentEntity', setLocalDealerCookie);

  useEffect(() => {
    updateCurrentDealer(true);
  }, []);

  useEffect(() => {
    if (!currentUserInfo) {
      dispatch(usersActions.getCurrentUserInfo());
    }
  }, [user.id, selectedDealer]);

  useUpdateEffect(() => {
    updateCurrentDealer(true);
  }, [user.id]);

  //honor the dealerId query param whenever it's used
  useUpdateEffect(() => {
    if (queryDealerId && queryDealerId !== selectedDealer?.id) {
      updateCurrentDealer();
    }
  }, [queryDealerId]);

  useUpdateEffect(() => {
    if (entityWhenSwitchingId && entityWhenSwitchingId !== selectedDealer?.id) {
      updateCurrentDealer();
    }
  }, [entityWhenSwitchingId]);

  useUpdateEffect(() => {
    if (isRootUser && isVendorIdValid(vendorEntityWhenSwitchingId)) {
      updateCurrentDealer();
    }
  }, [vendorEntityWhenSwitchingId, isRootUser]);

  // Update if the dealer cookie changed and we don't have a queryDealerId
  useUpdateEffect(() => {
    if (localDealerCookie && !queryDealerId && localDealerCookie !== current.data?.vautoId) {
      updateCurrentDealer();
    }
  }, [localDealerCookie]);

  // This is needed because the component loads before the feature flags return. This allows us to
  // update our component after the flags are set. When this feature flag is removed, we should
  // update the default pager and remove this useEffect.
  useEffect(() => {
    const vendorId = localStorage.getItem(ROOT_ENTITY_PICKER);

    if (!flags.ireconHqView || vendorId) return;

    const newPager = {
      ...pager,
      limit: flags.ireconHqView ? 5 : 10
    };

    dispatch(accessibleDealersActions.setPager(newPager));
    dispatch(accessibleDealersActions.getData(newPager));
  }, [flags.ireconHqView]);

  const updateCurrentDealer = (updateTotalAccessibleCount = false) => {
    let vendorId = isVendorIdValid(vendorEntityWhenSwitchingId)
      ? vendorEntityWhenSwitchingId
      : localStorage.getItem(ROOT_ENTITY_PICKER);
    const dealerId = entityWhenSwitchingId || queryDealerId || localDealerCookie || defaultDealerId;

    if (!isNewSignin) {
      if (vendorId && isRootUser && !queryDealerId && !entityWhenSwitchingId) {
        dispatch(currentDealerActions.setVendorShipSelected(VENDORS));
        dispatch(currentDealerActions.getData(vendorId, flags?.vautoRedomainingCookiePartition));
        dispatch(accessibleDealersActions.getDataVendors(pager, {}, updateTotalAccessibleCount));
        setVendorship(VENDORS);
      } else {
        dispatch(currentDealerActions.setVendorShipSelected(DEALERS));
        dispatch(
          currentDealerActions.getData(
            dealerId,
            flags?.vautoRedomainingCookiePartition,
            selectedDealer ? undefined : defaultDealerId
          )
        );
        dispatch(accessibleDealersActions.getData(pager, {}, updateTotalAccessibleCount));
        setVendorship(DEALERS);
        if (entityWhenSwitchingId) {
          localStorage.removeItem(ROOT_ENTITY_PICKER);
        }
      }
    }
  };

  // if we have a current dealer, but haven't loaded the accessible list yet, use a list with just the current in it
  useEffect(() => {
    if (accessible.fetchStatus === apiStatusConstants.SUCCEEDED) {
      setDealers(accessible.data.items.sort((a, b) => (a.name > b.name ? 1 : -1))); // sort in alphabetical order
      setTotalAccessibleCount(accessible.totalAccessibleCount || 0);
    } else if (
      current.fetchStatus === apiStatusConstants.SUCCEEDED &&
      (accessible.fetchStatus === apiStatusConstants.FAILED || (accessible?.data?.items?.length || 0) === 0)
    ) {
      setDealers([current.data]);
    }
  }, [accessible]);

  useEffect(() => {
    // it's possible for the current dealer fetch to fail, example when you impersonate a different user and that user doesn't have access to the current entity set in the vAuto cookie from the previous user, when this occurs, we need to recheck the accessibility to update whether the picker can be enabled or not
    if ([apiStatusConstants.SUCCEEDED, apiStatusConstants.FAILED].includes(current.fetchStatus)) {
      if (vendorShipSelected !== VENDORS) {
        dispatch(currentDealerActions.checkAccessibility(current.data?.logicalId));
      }
      setSelectedDealer(current.data);
    }
  }, [current.fetchStatus]);

  useEffect(() => {
    if (vendorFetchStatus === apiStatusConstants.SUCCEEDED && isRootUser && vendorShipSelected === VENDORS) {
      //case update vendor's business profile as root users
      setSelectedDealer(current.data);
    }
  }, [vendorFetchStatus]);

  // This is a little hacky, but it manages the Mouse Over/Active State of the Username and dealerPicker area, it will be active anytime the whole region is moused over or if the dropdown is open
  const [dealerPickerDropdownIsVisible, setDealerPickerDropdownIsVisible] = useState(false);
  const [dealerPickerIsHovering, setDealerPickerIsHovering] = useState(false);
  const [dealerPickerIsActive, setDealerPickerIsActive] = useState(false);
  const [searchRef, setSearchRef] = useState(null);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    setTitle(`${userFirstName} ${userLastName}`);
  }, [userFirstName, userLastName]);

  useEffect(() => {
    let newSubtitle = '';

    if (isNewSignin) {
      //login as a vendor
      if (!!Object.keys(vendor)) {
        newSubtitle = vendor.name;
      }
    } else {
      //login as a dealer
      if (vendorShipSelected === VENDORS) {
        if (selectedDealer) {
          newSubtitle = `${selectedDealer.name ?? ''}`;
        }
      } else {
        if (selectedDealer) {
          const logicalIdTiTle = selectedDealer.logicalId ? `- ${selectedDealer.logicalId}` : '';
          const dealerTitle = `${selectedDealer.name} ${logicalIdTiTle}`;
          newSubtitle = selectedDealer.name ? dealerTitle : '';
        }
      }
    }

    setSubtitle(newSubtitle);
  }, [isNewSignin, vendorShipSelected, selectedDealer?.name, selectedDealer?.logicalId, vendor.name]);

  useEffect(() => {
    if (
      isNewSignin &&
      !!vendor.contactFirstName &&
      !!vendor.contactLastName &&
      vendor.primaryContactId === parsedVendorUserInfo.id
    ) {
      setTitle(`${vendor.contactFirstName} ${vendor.contactLastName}`);
    }
  }, [isNewSignin, vendor.contactFirstName, vendor.contactLastName, vendor.primaryContactId, parsedVendorUserInfo.id]);

  useEffect(() => {
    if (dealerPickerDropdownIsVisible) {
      setDealerPickerIsActive(true);
    } else {
      setDealerPickerIsActive(dealerPickerIsHovering);
      if (!dealerPickerIsActive && searchRef?.input?.state?.value !== undefined) {
        // clear search when no longer active
        if (searchRef?.input?.setValue) {
          searchRef.input.setValue(undefined);
        } else if (searchRef?.setValue) {
          searchRef.setValue(undefined);
        }
        onChangeSearch('');
      }
    }
  }, [dealerPickerDropdownIsVisible, dealerPickerIsHovering]);

  const onChangePage = (pageNum, _pageSize) => {
    pageNum = pageNum || 1;
    const newPager = { ...pager, start: (pageNum - 1) * pager.limit + 1 };
    dispatch(accessibleDealersActions.setPager(newPager));
    if (vendorship === VENDORS) {
      dispatch(accessibleDealersActions.getDataVendors(newPager, filters));
    } else {
      dispatch(accessibleDealersActions.getData(newPager, filters));
    }
  };

  const onChangeSearch = (search) => {
    setSearchValue(search);
    debouncedSearch(search);
  };

  const debouncedSearch = debounce((search) => {
    const newFilters = { ...filters, search };
    const newPager = { ...pager, start: 1 };
    dispatch(accessibleDealersActions.setPager(newPager)); // on new search, the pager should be reset to the first page, ie if you are on the 2nd page, and there are only 2 results, you won't see those 2 results since each page holds 10 items
    dispatch(accessibleDealersActions.setFilters(newFilters));

    if (vendorship === VENDORS) {
      dispatch(accessibleDealersActions.getDataVendors(newPager, newFilters));
    } else {
      dispatch(accessibleDealersActions.getData(newPager, newFilters));
    }
  }, 400);

  const switchDealer = (dealerId) => {
    if (selectedDealer?.id === dealerId) {
      setDealerPickerDropdownIsVisible(false);
      return;
    }
    dispatch(currentDealerActions.switch());
    dispatch(currentDealerActions.setVendorShipSelected(vendorship));
    if (vendorship === VENDORS) {
      dispatch(currentDealerActions.getData(dealerId, flags?.vautoRedomainingCookiePartition));
      if (!window.location.pathname.includes('/tasks')) {
        navigate('/tasks');
      }
    } else {
      dispatch(currentDealerActions.getData(dealerId, flags?.vautoRedomainingCookiePartition));
    }
    setSelectedDealer(dealers.find((x) => x.id === dealerId));
    setDealerPickerDropdownIsVisible(false);
  };

  const switcherVendorShip = (e) => {
    const switcherValue = e.target.value;
    setVendorship(switcherValue);
    if (vendorship === VENDORS) {
      dispatch(accessibleDealersActions.getData(pager, {}, false));
    } else {
      dispatch(accessibleDealersActions.getDataVendors(pager, {}, false));
    }
    const newPager = { ...pager, start: 1 };
    const newFilters = { ...filters, search: '' };
    if (searchRef?.input?.setValue) {
      searchRef.input.setValue(undefined);
    } else if (searchRef?.setValue) {
      searchRef.setValue(undefined);
    }
    dispatch(accessibleDealersActions.setPager(newPager));
    dispatch(accessibleDealersActions.setFilters(newFilters));
  };

  const renderEntityTypeIcon = (entityType) => {
    switch (entityType) {
      case 'Enterprise':
        return <StyledEnterpriseIcon />;

      case 'Container':
        return <StyledContainerIcon />;

      case 'Dealer':
        return <StyledDealerIcon />;

      default:
        return;
    }
  };

  const renderDealerMenu = () => {
    const plainOptions = [DEALERS, VENDORS];

    const setNoPadding = flags.ireconHqView ? {} : { padding: 0 };
    const normalPlaceholder = vendorship === VENDORS ? 'Search Vendors' : 'Search your dealerships';
    const HQPlaceholder = vendorship === VENDORS ? 'Search Vendors' : 'Search Dealers';

    const searchComponent = flags.ireconHqView ? (
      <StyleInputDealer
        ref={(s) => {
          if (s) {
            setSearchRef(s);
            s.focus();
          }
        }}
        prefix={<SearchOutlined />}
        reconVendorManagement={flags.reconVendorManagement && isRootUser}
        loading={accessible.fetchStatus === apiStatusConstants.PENDING}
        placeholder={normalPlaceholder}
        value={searchValue}
        onChange={(e) => onChangeSearch(e.target.value)}
      />
    ) : (
      <StyleSearchDealer
        ref={(s) => {
          if (s) {
            setSearchRef(s);
            s.focus();
          }
        }}
        reconVendorManagement={flags.reconVendorManagement && isRootUser}
        loading={accessible.fetchStatus === apiStatusConstants.PENDING}
        placeholder={HQPlaceholder}
        value={searchValue}
        onChange={(e) => onChangeSearch(e.target.value)}
      />
    );

    return (
      <Menu>
        {flags.reconVendorManagement && isRootUser && (
          <Menu.Item key="__switcher" style={setNoPadding}>
            <StyleSwitcherVendorShip options={plainOptions} onChange={switcherVendorShip} value={vendorship} />
          </Menu.Item>
        )}

        {flags.ireconHqView && <StyledMenuTitle>Select an entity</StyledMenuTitle>}

        <Menu.Item key="__search" style={setNoPadding}>
          {searchComponent}
        </Menu.Item>

        {/* Using onClick on the Menu Item rather than on the main Menu is because the search box becomes clickable if used in the main Menu and it causes the dropdown to close when clicked into the search box */}
        {dealers.map((d, index) => (
          <StyledMenuItem
            key={d.id + '_StyledMenuItem'}
            onClick={() => switchDealer(d.id)}
            data-isselected={selectedDealer && selectedDealer.id === d.id}
            ireconHqView={flags.ireconHqView}
          >
            {flags.ireconHqView && renderEntityTypeIcon(d.entityType)}
            <Text>
              {d.name} {d.logicalId ? ` - ${d.logicalId}` : ''}
            </Text>
          </StyledMenuItem>
        ))}
        <StyledPager
          total={accessible.data && accessible.data.count}
          pageSize={pager.limit}
          current={Math.floor(pager.start / pager.limit) + 1}
          onChange={onChangePage}
        />
      </Menu>
    );
  };

  useEffect(() => {
    /* When the user doesn't have more than one accessible dealer (including the currentDealer), disable the dropdown
     * This is real confusing, so creating this matrix
     * accessibleCount    currentIsAccessible   shouldEnableDropdown
     * 0	                true	                = false       // this shouldn't happen, if current is accessible, then it should be included the accessible list
     * 0    		          false                 = false
     * 1                  true 		              = false
     * 1    		          false          	  	  = true
     * 2 or more          false          	  	  = true
     * 2 or more          true                  = true
     */
    if (!isNewSignin) {
      let isEnabled = true;
      if (totalAccessibleCount === 0) {
        isEnabled = false;
      } else if (totalAccessibleCount === 1 && currentDealerIsAccessible) {
        isEnabled = false;
      }

      setPickerIsEnabled(isEnabled);
    }
  }, [isNewSignin, currentDealerIsAccessible, totalAccessibleCount]);

  useEffect(() => {
    if (isNewSignin) {
      setPickerIsEnabled(false);
    }
  }, [isNewSignin]);

  useEffect(() => {
    if (
      !!parsedVendorUserInfo.vendorId &&
      isNewSignin &&
      parsedVendorUserInfo.vendorOnboardingStatus === onboardingStatusTypes.VERIFIED
    ) {
      //this is vendor signin
      dispatch(vendorActions.getVendor(parsedVendorUserInfo.vendorId));
    }
  }, [isNewSignin, parsedVendorUserInfo.vendorId, parsedVendorUserInfo.vendorOnboardingStatus]);

  return (
    (currentUserInfo || isNewSignin) && (
      <Dropdown
        disabled={!pickerIsEnabled}
        trigger={['click']}
        placement="bottomRight"
        overlay={renderDealerMenu()}
        visible={dealerPickerDropdownIsVisible}
        onVisibleChange={(e) => setDealerPickerDropdownIsVisible(e)}
      >
        <StyledNameAndDealerPickerContainer
          dealerPickerIsDisabled={!pickerIsEnabled}
          dealerPickerIsActive={dealerPickerIsActive}
          dealerPickerDropdownIsVisible={dealerPickerDropdownIsVisible}
          onMouseEnter={() => setDealerPickerIsHovering(true)}
          onMouseLeave={() => setDealerPickerIsHovering(false)}
          reconDealerFilterFlag={flags?.reconDealerFilter}
        >
          <div>
            <div>
              <div>
                <Text>
                  {title} {flags.reconDisableExtechsPhase3 && isExternalTech ? '(Disabled)' : ''}
                </Text>
              </div>

              <div>
                <Text>{subtitle}</Text>
              </div>
            </div>
            {!flags?.reconDealerFilter && <div>{pickerIsEnabled && <FontAwesomeIcon icon={faChevronDown} />}</div>}
          </div>
        </StyledNameAndDealerPickerContainer>
      </Dropdown>
    )
  );
};

//#region Styled Components
const StyledNameAndDealerPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (!props['dealerPickerIsDisabled'] ? 'pointer' : 'inherit')};
  background-color: ${(props) =>
    props['dealerPickerIsActive'] && !props['dealerPickerIsDisabled'] ? props.theme.backgroundColors.gray : 'inherit'};
  color: ${(props) =>
    props['dealerPickerIsActive'] && !props['dealerPickerIsDisabled'] ? 'inherit' : props.theme.colors.darkGray};
  ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 'height: 100%;' : '')}

  > div {
    display: flex;
    height: 100%;

    div:first-child {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      div:first-child {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        padding: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? '2px 17px 0 17px' : '2px 0 0 15px')};

        > span {
          white-space: nowrap;
          font-size: ${({ theme }) => theme.fontSizes.md};
          line-height: ${({ reconDealerFilterFlag, theme }) => (reconDealerFilterFlag ? theme.lineHeights.lg : '19px')};
          color: inherit;
          ${({ reconDealerFilterFlag, theme }) =>
            reconDealerFilterFlag ? `font-weight: ${theme.fontWeights.normal};` : ''}
        }
      }

      div:nth-child(2) {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        padding: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? '2px 17px 0 17px' : '2px 0 0 15px')};

        > span {
          white-space: nowrap;
          color: ${({ reconDealerFilterFlag, theme }) =>
            reconDealerFilterFlag ? theme.colors.infoPrimary : 'inherit'};
          ${({ reconDealerFilterFlag, theme }) =>
            reconDealerFilterFlag
              ? `font-size: ${theme.fontSizes.xs};
              font-weight: ${theme.fontWeights.normal};
              line-height: ${theme.lineHeights.xs};`
              : ''}
        }
      }
    }

    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 'end' : 'center')};
      padding: 0 8px 0 6px;

      ${elementRotate('fa-chevron-down', 'dealerPickerDropdownIsVisible', 180, 0.3)}
    }
  }
`;

const StyledMenuTitle = styled.span`
  padding: 8px 14px;
  font-weight: bold;
  font-size: 16px;
`;

const StyledMenuItem = styled(Menu.Item)`
  ${(props) => (props['ireconHqView'] ? 'padding: 8px 16px !important;' : 'padding: 4px 8px !important;')};

  :not(:hover) {
    background-color: ${(props) => (props['data-isselected'] ? props.theme.colors.backgroundGray : 'inherit')};
  }
  > span {
    font-weight: ${(props) => (props['data-isselected'] ? props.theme.fontWeights.medium : 'inherit')};
  }
`;

const StyledPager = styled(Pagination).attrs({ simple: true, size: 'small' })`
  .ant-pagination& {
    padding-top: 5px;
    margin: 5px 0;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    border-top: ${({ theme }) => theme.borders.thinSolidGray};
  }
`;

const StyleSwitcherVendorShip = styled(Radio.Group)`
  display: flex !important;
  background: #ffffff;

  .ant-radio-wrapper span {
    vertical-align: middle;
  }
  .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
  .ant-checkbox-wrapper:hover:not(.ant-checkbox-wrapper-disabled),
  .ant-radio-wrapper:hover:not(.ant-radio-wrapper-disabled) {
    background: #ffffff;
  }
`;

const StyleInputDealer = styled(Input)`
  background: #ffffff;
  border-radius: 4px;
  padding: ${(props) => props['reconVendorManagement'] && '0 8px !important'};
  font-size: 16px;

  .ant-input {
    border: 1px solid #0b294d;
  }
`;

const StyleSearchDealer = styled(Search)`
  background: #ffffff;
  border-radius: 4px;
  padding: ${(props) => props['reconVendorManagement'] && '0 8px !important'};

  .ant-input {
    border: 1px solid #0b294d;
  }
`;

const StyledEnterpriseIcon = styled(FolderFilled).attrs({ style: { fontSize: '16px' } })`
  &.anticon {
    color: ${({ theme }) => theme.colors.iconBlue};
    padding: 0px 8px;
  }
`;

const StyledContainerIcon = styled(FolderFilled).attrs({ style: { fontSize: '16px' } })`
  &.anticon {
    color: ${({ theme }) => theme.colors.iconYellow};
    padding: 0px 8px;
  }
`;

const StyledDealerIcon = styled(CarFilled).attrs({ style: { fontSize: '16px' } })`
  &.anticon {
    color: ${({ theme }) => theme.colors.iconBlue};
    padding: 0px 8px;
  }
`;
//#endregion

export default withLDConsumer()(UsernameAndDealerPicker);
