import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, DatePicker } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

//our components
import Dropdown from './Dropdown';

//dateTime utility function
import { generateDateRangeFromDaysAgo, generateDateRangeFromMoments } from 'utils/dateTimeUtils';

//custom hooks
import useSetDisplayedFilters from './hooks/useSetDisplayedFilters';
import { useScreenSize, useSetAvailableDateRange } from 'hooks';

//extracting component from Ant Design Select and Typography export
const { Text } = Typography;
const { RangePicker } = DatePicker;

const Filter = ({
  vehicleDispositionFilter,
  setFilters = () => {},
  filters = {},
  createdOn = '',
  currentDealer = {},
  flags
}) => {
  const [displayedDispositionFilters, timeSpanFilter] = useSetDisplayedFilters(vehicleDispositionFilter, setFilters);
  const screenSize = useScreenSize();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const availableDateRange = useSetAvailableDateRange(createdOn, currentDealer);
  useEffect(() => {
    if (filters.customDateRange !== undefined) {
      setShowDatePicker(filters.customDateRange);
    }
  }, []); //only want the setFilters function to run on mount - needs to refresh from previously
  //create changeFilters functions for each of the different types of filters
  //disposition type

  useEffect(() => {
    setShowDatePicker(filters.customDateRange);
  }, [JSON.stringify(filters)]);
  const changeFiltersDisposition = (newFilter) =>
    setFilters((previousFilters) => ({ ...previousFilters, dispositions: newFilter }));
  //historical date range
  const changeFiltersDateRange = (chosenValue) => {
    setShowDatePicker(chosenValue === 'customDateRange'); //show DatePicker?
    chosenValue !== 'customDateRange' &&
      setFilters((previousFilters) => ({
        ...previousFilters,
        ...generateDateRangeFromDaysAgo(chosenValue),
        customDateRange: false
      })); //if not custom date range, set start and end date - this will also trigger re-fetching data as new date range has been selected
  };
  //range picker onchange method
  const onChangeRangePicker = (momentsArray) => {
    if (momentsArray.length === 0) {
      //this occurs when you clear the selected dates
      changeFiltersDateRange('30'); //reset date range dropdown to show 'last 30 days' data
    } else {
      setFilters((previousFilters) => ({
        ...previousFilters,
        ...generateDateRangeFromMoments(momentsArray),
        customDateRange: true
      }));
    }
  };

  const getInitialDispositionDropdownValue = () => {
    if (typeof filters['dispositions'] === 'string') {
      return filters['dispositions'];
    }
    return 'ANY';
  };

  const getInitialDaysDropdownValue = () => {
    if (filters.customDateRange) {
      return 'customDateRange';
    } else if (filters['daysAgo'] !== undefined) {
      return filters['daysAgo'].toString();
    }

    return '30';
  };

  const getInitialDateRangeDropdownValue = () => {
    if (filters.customDateRange) {
      return [moment(filters.startTime, 'MM-DD-YYYY'), moment(filters.endTime, 'MM-DD-YYYY')];
    }
    return null;
  };

  return (
    <StyledContainer>
      <Text strong>View By</Text>
      <Dropdown
        options={displayedDispositionFilters}
        defaultValue={getInitialDispositionDropdownValue()}
        changeFilters={changeFiltersDisposition}
      />
      <Dropdown
        options={timeSpanFilter}
        defaultValue={getInitialDaysDropdownValue()}
        changeFilters={changeFiltersDateRange}
      />
      {showDatePicker && (
        <StyledRangePicker
          placeholder="Select start date"
          disabledDate={(current) => current && (current < availableDateRange[0] || current > availableDateRange[1])}
          onChange={onChangeRangePicker}
          screenSize={screenSize}
          dropdownClassName="dashboardRangePicker"
          separator="&#x2192;"
          format="MM-DD-YYYY"
          defaultPickerValue={[moment().add(-1, 'month'), moment()]}
          defaultValue={getInitialDateRangeDropdownValue()}
        />
      )}
    </StyledContainer>
  );
};

Filter.propTypes = {
  vehicleDispositionFilter: PropTypes.arrayOf(PropTypes.string),
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  createdOn: PropTypes.string
};

const StyledRangePicker = styled(RangePicker)`
  margin-left: ${({ screenSize }) => (screenSize === '<= 960px' ? '4px' : '10px')} !important;
  width: ${({ screenSize }) => (screenSize === '<= 960px' ? '225px' : '250px')};
  color: ${({ theme }) => theme.colors.navy};
  .ant-calendar-input {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .anticon-close-circle,
  .ant-calendar-range-picker-input::placeholder {
    color: ${({ theme }) => theme.colors.navy};
  }
`;

const StyledContainer = styled.div`
  float: right;
`;
//other styles, for the range picker, are in the global styles sheet. the dropdown renders outside of the react root node, so have to apply these styles globally
//the styles in the globalstyles sheet are wrapped by the dropdownclassname that is set on the rangepicker component above, "dashboardRangePicker"
export default withLDConsumer()(Filter);
