import React, { useState } from 'react';
import { DrawerContainer } from 'components';
import { CommonHeaderLabel, CommonLinkButton } from '../../../styledComponents';
import { EnterpriseSummaryDrawerContent, EnterpriseSummaryDrawer, StyledDetailsContainer } from './common/styles';
import { SummaryBody } from './inventorySummary';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';
import { useUpdateEffect } from 'hooks';

// main component
const ShowMoreHqInventorySummary = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { setInDrawer } = useCommonDropdownContext('dealerships');

  const toggleDrawer = () => {
    setIsVisible(!isVisible);
  };

  useUpdateEffect(() => {
    setInDrawer(isVisible);
  }, [isVisible]);

  return (
    <StyledDetailsContainer>
      <CommonLinkButton onClick={toggleDrawer}>Show Details</CommonLinkButton>
      <EnterpriseInventorySummaryDrawer
        visible={isVisible}
        onClose={() => {
          if (typeof onClose === 'function') {
            onClose();
          }
          toggleDrawer();
        }}
      />
    </StyledDetailsContainer>
  );
};

// internal components
const EnterpriseInventorySummaryDrawer = ({ visible, onClose }) => {
  return (
    <EnterpriseSummaryDrawer
      visible={visible}
      onClose={onClose} // How to close drawer using "default methods" (backdrop clicked, "ESC" key etc.)
    >
      <DrawerContainer headerLabel={<CommonHeaderLabel>Enterprise Summary</CommonHeaderLabel>} onClose={onClose}>
        <EnterpriseSummaryDrawerContent>
          <SummaryBody />
        </EnterpriseSummaryDrawerContent>
      </DrawerContainer>
    </EnterpriseSummaryDrawer>
  );
};

// default export
export default ShowMoreHqInventorySummary;
