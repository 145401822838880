import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Typography, Upload } from 'antd';
import { apiStatusConstants, features, documentationContexts } from 'app-constants';
import { CircularSpinner } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { useFeatures } from 'hooks';
import styled from 'styled-components';
import { DocumentsDetail, MessageUpload } from '.';
import { LoadingRedButton } from 'components/common';
import { theme as themeStyles } from 'styles/appTheme';
import { CustomizedAlert } from 'components/alerts';
import { updateVisibleStateError } from 'utils/arrayUtils';
import { convertBytetoMB } from '../../utils/sizeUtils';
import { useTaskReadOnlyStatus } from '../../hooks/useTaskReadOnlyStatus';
import { useSelector } from 'react-redux';
import { NEW } from '../../app-constants/reconPlanStatusTypes';
import uuidv4 from 'uuid/v4';

const { Text } = Typography;

const DocumentUpload = ({
  documents,
  vehicleId,
  stockNumber,
  taskId,
  documentsFetchStatus,
  documentsUploadStatus,
  documentsDeleteStatus,
  getDocuments,
  flags,
  deleteDocument,
  downloadDocument,
  setPaddingBottom,
  uploadDocument
}) => {
  const [hasInventoryEditPermission] = useFeatures(features.INVENTORY_EDIT);
  const { heightRef } = useRef(null);
  // this variable use for prevent error message to show up many time.
  let checkInvalid = false;
  const [fileListUpload, setFileListUpload] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorList, setErrorList] = useState([
    { messageContent: documentationContexts.MESSAGE_INCORRECT_FILE_TYPE, visibleState: false },
    { messageContent: documentationContexts.MESSAGE_MAXIMUM_DOCUMENT_SIZE, visibleState: false },
    { messageContent: documentationContexts.MESSAGE_MINIMUM_DOCUMENT_SIZE, visibleState: false },
    { messageContent: documentationContexts.MESSAGE_EXCEEDS_10_FILES_LIMIT, visibleState: false }
  ]);
  const { data } = useSelector((state) => state.vehicles);
  const [numberAlert, setNumberAlert] = useState(0);
  const isReadonlyTask = useTaskReadOnlyStatus(taskId, flags.reconVendorManagement);
  const isNewVehicle = data?.items && data.items.length === 1 && data.items[0].inventoryType === NEW;

  useEffect(() => {
    if (numberAlert > 0) setPaddingBottom(errorList.filter((err) => err.visibleState).length * 46 + 32 + 60);
    else if (!isLoaded) {
      getDocuments(vehicleId || taskId);
      setIsLoaded(true);
    }
  }, [numberAlert, errorList]);

  const uploadAction = () => {
    const messageConvertFunc = (amount, stockNumber) => <MessageUpload amount={amount} stockNumber={stockNumber} />;
    uploadDocument(vehicleId || taskId, fileListUpload, stockNumber, messageConvertFunc, false);
  };

  const checkInValidFileType = (file) => {
    const acceptedFileType = documentationContexts.ACCEPTED_FILE_TYPES;
    const arr = file.name.split('.');
    return !acceptedFileType.includes(arr[arr.length - 1]);
  };

  const handleValidate = (_, fileList) => {
    if (checkInvalid) {
      return false;
    }
    const isInvalidAmount = fileList.length > documentationContexts.NUMBERS_DOCUMENTS_UPLOADED;
    const isInvalidSize = !!fileList.find(
      (file) => convertBytetoMB(file.size) > documentationContexts.MAXIMUM_DOCUMENT_SIZE
    );
    const isInvalidSizeMinimum = !!fileList.find(
      (file) => convertBytetoMB(file.size) <= documentationContexts.MINIMUM_DOCUMENT_SIZE
    );
    const isInvalidFileType = !!fileList.find((file) => checkInValidFileType(file));

    //Check invalid
    setNumberAlert([isInvalidAmount, isInvalidFileType, isInvalidSize, isInvalidSizeMinimum].filter(Boolean).length);
    //Displaying Error Handle for each
    setErrorList(
      updateVisibleStateError(errorList, documentationContexts.MESSAGE_EXCEEDS_10_FILES_LIMIT, isInvalidAmount)
    );
    setErrorList(
      updateVisibleStateError(errorList, documentationContexts.MESSAGE_INCORRECT_FILE_TYPE, isInvalidFileType)
    );
    setErrorList(
      updateVisibleStateError(errorList, documentationContexts.MESSAGE_MAXIMUM_DOCUMENT_SIZE, isInvalidSize)
    );
    setErrorList(
      updateVisibleStateError(errorList, documentationContexts.MESSAGE_MINIMUM_DOCUMENT_SIZE, isInvalidSizeMinimum)
    );

    if (isInvalidAmount || isInvalidFileType || isInvalidSize || isInvalidSizeMinimum) {
      checkInvalid = true;
      return false;
    }

    setFileListUpload(fileList); // we will upload all the array fileList
    checkInvalid = true; // this make sure handleValidate() function only check for the first time.
    return true;
  };

  const renderDocuments = (documents) => {
    if (documents && documents.length > 0) {
      return (
        <StyledList
          dataSource={documents}
          renderItem={(document) => (
            <DocumentsDetail
              document={document}
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              hasInventoryEditPermission={hasInventoryEditPermission}
              isLoading={
                documentsUploadStatus === apiStatusConstants.PENDING ||
                documentsDeleteStatus === apiStatusConstants.PENDING
              }
            />
          )}
        />
      );
    } else {
      return (
        <>
          <NoDocumentsText style={{ fontSize: 24 }}>
            <EmptyIcon icon={faFolder} size="2x" />
          </NoDocumentsText>
          <NoDocumentsText>No Documents</NoDocumentsText>
          {!isNewVehicle && (
            <NoDocumentsDescriptionText>{documentationContexts.EMPTY_DOCUMENT_MESSAGE}</NoDocumentsDescriptionText>
          )}
        </>
      );
    }
  };

  const resetErrorState = (contentMessage) => {
    switch (contentMessage) {
      case documentationContexts.MESSAGE_INCORRECT_FILE_TYPE:
        setErrorList(updateVisibleStateError(errorList, documentationContexts.MESSAGE_INCORRECT_FILE_TYPE, false));
        break;
      case documentationContexts.MESSAGE_MAXIMUM_DOCUMENT_SIZE:
        setErrorList(updateVisibleStateError(errorList, documentationContexts.MESSAGE_MAXIMUM_DOCUMENT_SIZE, false));
        break;
      case documentationContexts.MESSAGE_MINIMUM_DOCUMENT_SIZE:
        setErrorList(updateVisibleStateError(errorList, documentationContexts.MESSAGE_MINIMUM_DOCUMENT_SIZE, false));
        break;
      case documentationContexts.MESSAGE_EXCEEDS_10_FILES_LIMIT:
        setErrorList(updateVisibleStateError(errorList, documentationContexts.MESSAGE_EXCEEDS_10_FILES_LIMIT, false));
        break;
      default:
        //Reset all visible state
        setErrorList(
          errorList.map((err) => {
            err.visibleState = false;
            return err;
          })
        );
        break;
    }
  };

  const invalidCondition = () => {
    return errorList.some((err) => err.visibleState);
  };

  const checkInvalidCondition = invalidCondition();

  return (
    <>
      {
        {
          [apiStatusConstants.IS_FETCHING]: (
            <StyledCenter>
              <CircularSpinner tip="Loading..." size="2.5rem" />
            </StyledCenter>
          ),
          [apiStatusConstants.SUCCEEDED]: renderDocuments(documents),
          [apiStatusConstants.FAILED]: (
            <StyledCenter>
              <Typography.Title type="danger" level={4}>
                Oops, something went wrong
              </Typography.Title>
            </StyledCenter>
          )
        }[documentsFetchStatus]
      }
      {!isNewVehicle && (
        <StyledButtonsContainer ref={heightRef}>
          {checkInvalidCondition && (
            <StyledAlertContainer className="alert-container" check-invalid={checkInvalidCondition}>
              {errorList.map((error, index) => {
                return (
                  <CustomizedAlert
                    key={uuidv4()}
                    type="error"
                    onClose={(e) => resetErrorState(error.messageContent)}
                    message={error.messageContent}
                    visible={error.visibleState}
                    closable
                    showIcon
                  />
                );
              })}
            </StyledAlertContainer>
          )}

          <Upload
            multiple={true}
            beforeUpload={handleValidate}
            showUploadList={false}
            action={uploadAction}
            accept={documentationContexts.ACCEPTED_FILE_TYPES.map((type) => '.' + type).join(',')}
            className={isReadonlyTask ? 'hard-disabled' : ''}
            customRequest={() => {}}
          >
            <LoadingRedButton
              onClick={() => {
                checkInvalid = false;
                resetErrorState();
                setFileListUpload([]);
              }}
              ghost={true}
              theme={themeStyles}
              disabled={
                documentsUploadStatus === apiStatusConstants.PENDING ||
                documentsDeleteStatus === apiStatusConstants.PENDING ||
                documentsFetchStatus === apiStatusConstants.IS_FETCHING
              }
              type="submit"
              style={{ margin: '0 auto' }}
              loadingIcon={documentsUploadStatus === apiStatusConstants.PENDING}
            >
              Attach Files
            </LoadingRedButton>
          </Upload>
        </StyledButtonsContainer>
      )}
    </>
  );
};

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
  background-color: ${({ theme }) => theme.colors.white};
  height: 73px;
  position: absolute;
  margin: 0px 20px;
  bottom: 0;
`;
const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: calc(50vh);
`;
const StyledList = styled(List).attrs({
  size: 'small'
})`
  margin-right: 15px;
  overflow-y: auto;
  max-height: 100%;
`;
const NoDocumentsText = styled(Text)`
  .ant-typography& {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    display: block;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
const NoDocumentsDescriptionText = styled(Text)`
  .ant-typography& {
    text-align: center;
    display: block;
    max-width: 380px;
    margin: 8px auto;
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
const EmptyIcon = styled(FontAwesomeIcon)`
  padding-top: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`;
const StyledAlertContainer = styled.div`
  & {
    width: 100%;
    padding: ${(props) => (props['check-invalid'] ? '8px 0 16px 0' : 'unset')};
    position: absolute;
    background-color: white;
    bottom: 73px;

    & .ant-alert {
      margin: 4px 3px !important;
    }
  }
`;

export default DocumentUpload;
