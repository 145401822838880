import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { authActions, authSelector } from 'store/authStore';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  IS_NEW_SIGNIN,
  VENDOR_USER_INFO,
  RECON_ALERTS,
  iReconLogoutUrl,
  vAutoLogoutUrlNewDomain
} from 'app-constants';
import { navigate } from '@reach/router';
import { getCookie } from 'utils/cookieUtils';

const bridgeBarSelector = createSelector(authSelector, (authStore) => ({
  userId: authStore.user.id
}));

const BridgeBar = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector(bridgeBarSelector);

  const [scriptAddedToPage, setScriptAddedToPage] = useState(false);
  const [isBridgeUser, setIsBridgeUser] = useState(false);
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  const isProd = process.env.REACT_APP_API_BASE_URL === 'https://recon-api.irecon.vauto.com';

  useEffect(() => {
    if (!scriptAddedToPage && userId) {
      //owner: vAuto Auth. domain: .vauto.com
      //httpOnly: false
      const caPlatformUserId = getCookie('caPlatformUserId');
      if (isNewSignin || caPlatformUserId) {
        dispatch(authActions.setBridgeUser(true));
        const script = document.createElement('script');
        script.src = 'https://assets.bridge.coxautoservices.com/bridge-bar.js';
        script.async = true;
        document.body.appendChild(script);
        setScriptAddedToPage(true);
        var bbEl = document.querySelector('bridge-bar');
        bbEl.addEventListener('initialized', function () {
          bbEl.bb
            .render({
              solutionGroupCode: isNewSignin ? 'CAI' : 'VAT',
              sessionId: `${userId}-${Date.now()}`,
              env: isNewSignin ? (isProd ? 'prod' : 'dev') : 'prod'
            })
            .onStateDetermined(function (state) {
              if (state.visible) {
                setIsBridgeUser(true);
                let bbLogoutBtn;
                const intervalToCheckHTML = setInterval(() => {
                  if (bbLogoutBtn) {
                    clearInterval(intervalToCheckHTML);
                  } else {
                    bbLogoutBtn = document.querySelector('.bb-logout');
                    if (bbLogoutBtn)
                      bbLogoutBtn.href += `&next=${
                        isNewSignin ? iReconLogoutUrl : encodeURIComponent(vAutoLogoutUrlNewDomain)
                      }`;
                  }
                }, 500);
              } else if (state.error) {
                localStorage.removeItem(VENDOR_USER_INFO);
                navigate('/logout');
              }
            });
        });
      }
    }
  }, [userId]);
  // Because the logout mechanism is performed on vAuto if a user is a bridge user and the component logout input from external app iRecon
  const handleOnClickOnBrigeBar = (e) => {
    const btnLogoutClass = e.target.className || '';
    if (btnLogoutClass === 'bb-logout') {
      // The delete local storage of global alert.
      localStorage.removeItem(RECON_ALERTS);
    }
  };
  return <bridge-bar onClick={handleOnClickOnBrigeBar} style={{ display: isBridgeUser ? 'flex' : 'none' }} />;
};

export default withLDConsumer()(BridgeBar);
