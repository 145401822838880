import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useScreenSize } from 'hooks';
import styled from 'styled-components';
import { TaskContext } from '../helpers/context';
import { detailContents } from 'app-constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PassThroughIndicator from './PassThroughIndicator';
import { ContentMetaAndOptionsForTDP } from './ContentMetaAndOptions';
import PendingApprovalTime from './PendingApprovalTime';
import { getPendingApprovalTimeByTaskIdSelector } from '../../../../store/tasksStore';

/**
 * ContentSegmentTDP renders everything but the step number/icon for the task card TDP
 */
const ContentSegmentTDP = ({ contentType, showPendingApprovalTime = true, flags }) => {
  const screenSize = useScreenSize();
  const { hidePassthroughIndicator, task } = useContext(TaskContext);
  const timeInApproval = useSelector(getPendingApprovalTimeByTaskIdSelector(task.id));
  return (
    <StyledContentContainer screenSize={screenSize} contentType={contentType === detailContents.TASK_DETAILS}>
      <ContentMetaAndOptionsForTDP contentType={contentType} />
      {contentType !== detailContents.TASK_DETAILS &&
        task.passthrough &&
        !hidePassthroughIndicator && <PassThroughIndicator reconTaskCollapse={flags.reconTaskCollapse} />}
      {showPendingApprovalTime && <PendingApprovalTime timeInApproval={timeInApproval} />}
    </StyledContentContainer>
  );
};
//#region Styled Components
const StyledContentContainer = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  width: 100%;
  min-width: 0px;
`;
//#endregion

export default withLDConsumer()(ContentSegmentTDP);
