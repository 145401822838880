import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { vehiclesActions } from 'store/vehiclesStore';
import { CircularSpinner } from 'components';
import { apiStatusConstants } from 'app-constants';
import VehiclePhotosDetail from './VehiclePhotosDetail';

const Loading = () => (
  <StyledCenter>
    <CircularSpinner tip="Loading..." size="2.5rem" />
  </StyledCenter>
);
const Error = () => (
  <StyledCenter>
    <Typography.Title type="danger" level={4}>
      Oops, something went wrong
    </Typography.Title>
  </StyledCenter>
);

const VehiclePhotos = ({ vehicle , flags }) => {
  const { imagesFetchStatus } = vehicle;
  const { isShowLoadingSendCommunication } = useSelector((state) => state.vehicles);
  const dispatch = useDispatch();
  const dealerId = useSelector((state) => state.dealers.current.data.id);
  useEffect(() => {
    if (!isShowLoadingSendCommunication) {
      dispatch(vehiclesActions.getPhotosForVehicleEntity(vehicle.id, dealerId));
    }
  }, [isShowLoadingSendCommunication]);

  return (
    <StylePhotosContainer recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0} >
      {
        {
          [apiStatusConstants.IS_FETCHING]: <Loading></Loading>,
          [apiStatusConstants.SUCCEEDED]: <VehiclePhotosDetail vehicle={vehicle}></VehiclePhotosDetail>,
          [apiStatusConstants.FAILED]: <Error></Error>
        }[imagesFetchStatus]
      }
    </StylePhotosContainer>
  );
};

const StylePhotosContainer = styled.div`
  height: calc(100vh - ${(props) => (props['recon-breadcrumb-update-flag'] ? '285px' : '275px')});  
  padding-bottom: 30px;
  padding-top: 24px;
  overflow-y: auto;
`;
const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: calc(50vh);
`;
export default withLDConsumer()(VehiclePhotos);
