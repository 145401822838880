import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { StyledAntIcon, StyledRequirmentText, StyledSpan } from './style';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Col } from 'antd';
import { theme } from 'styles/appTheme';
import uuidv4 from 'uuid/v4';

const rules = [
  {
    check: true,
    text: 'Must contain at least one letter and one number'
  },
  {
    check: true,
    text: 'Must be 8 to 72 characters in length'
  },
  {
    check: false,
    text: 'Should not contain your first or last name, username, or a Cox brand'
  },
  {
    check: false,
    text: 'Should not consist of a common word or phrase'
  },
  {
    check: false,
    text: 'Should not contain repeated (AAA) or sequential (123) patterns'
  }
];

const RuleList = () => {
  return (
    <Col md={22} xs={24}>
      {rules.map((rule, index) => (
        <div key={uuidv4()} style={{ display: 'table' }}>
          <div style={{ display: 'table-cell' }}>
            {rule.check ? (
              <StyledSpan>
                <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.krypton} />
              </StyledSpan>
            ) : (
              <StyledAntIcon />
            )}
          </div>

          <div style={{ display: 'table-cell' }}>
            <StyledRequirmentText style={{ paddingLeft: '4px', verticalAlign: 'top' }}>
              {rule.text}
            </StyledRequirmentText>
          </div>
        </div>
      ))}
    </Col>
  );
};

export default React.memo(RuleList);
