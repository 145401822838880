import React from 'react';
import styled from 'styled-components';
import { getAvatarColor, communicationContexts } from 'app-constants';
import { Avatar, Typography, Tag, Tooltip, Popover, Row, Col } from 'antd';
import { truncateString, determineCharLimit } from 'utils/stringUtils';
import { useScreenSize } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { formatDateWithYear, formatTime } from 'utils/dateTimeUtils';
const { Text } = Typography;

const CommunicationBase = ({
  id,
  indent = false,
  authorId,
  authorName,
  authorInitials,
  showAvatar = true,
  avatarTopOffset = 0,
  label = null,
  datetime,
  hoverDateTime,
  headerExtra,
  content,
  action,
  style,
  location,
  flags
}) => {
  const screenSize = useScreenSize();

  const determineStringTruncation = (label, charLimit, location) => {
    const result = {
      presentationLabel: label,
      needTooltip: false
    };
    if (location === communicationContexts.VEHICLE_CARD && charLimit > 0) {
      result.presentationLabel = truncateString(label, charLimit);
      result.needTooltip = label?.length > charLimit;
    }
    return result;
  };

  const { presentationLabel, needTooltip } = determineStringTruncation(
    label,
    determineCharLimit(screenSize, 15, 9, 5, 3, 2),
    location
  );
  const hoverTooltipContent = (date) => (
    <div style={{ width: '135px' }}>
      <Row type="flex" justify="center">
        <Col>
          <Text>{formatDateWithYear(date)}</Text>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Text>at {formatTime(date)}</Text>
        </Col>
      </Row>
    </div>
  );
  return (
    <StyledContainer id={id} style={style} indent={indent}>
      {showAvatar && (
        <StyledAvatarContainer avatarTopOffset={avatarTopOffset}>
          <Avatar
            size="small"
            alt={authorName}
            style={{ backgroundColor: getAvatarColor(authorId), lineHeight: '25px' }}
          >
            {authorInitials}
          </Avatar>
        </StyledAvatarContainer>
      )}
      <StyledMainContainer>
        <StyledHeader>
          <StyledAuthorAndLabelContainer>
            <StyledAuthor>{authorName}</StyledAuthor>
            {label &&
              (needTooltip ? (
                <Tooltip
                  placement="left"
                  overlayClassName="vehicle-card-communication-tag-tooltip"
                  title={<Text>{label}</Text>}
                >
                  <StyledTag>{presentationLabel}</StyledTag>
                </Tooltip>
              ) : (
                <StyledTag>{presentationLabel}</StyledTag>
              ))}
          </StyledAuthorAndLabelContainer>
          <Popover content={hoverTooltipContent(hoverDateTime)} trigger="hover">
            <StyledDatetime>{datetime}</StyledDatetime>
          </Popover>
          <StyledOverFlow>{headerExtra}</StyledOverFlow>
        </StyledHeader>
        <StyledContent>{content}</StyledContent>
        <StyledActionContainer>{action}</StyledActionContainer>
      </StyledMainContainer>
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  display: flex;
  margin-left: ${(props) => (props.indent ? '30px' : '0')};
  margin-bottom: 0px;
  margin-top: ${(props) => (props.indent ? '8px' : '0')};
  padding-top: ${(props) => (props.indent ? '6px' : '0')};
`;
const StyledOverFlow = styled.div`
  cursor: pointer;
`;
const StyledAvatarContainer = styled.div`
  margin-top: ${(props) => props.avatarTopOffset};
  margin-right: 8px;
`;
const StyledMainContainer = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
`;
const StyledHeader = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAuthorAndLabelContainer = styled.div`
  flex: 1 1 0px;
  -ms-flex: none;
  display: flex;
  flex-flow: nowrap;
`;
const StyledAuthor = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-right: 8px;
`;
const StyledTag = styled(Tag).attrs(({ theme }) => ({
  color: theme.colors.white
}))`
  .ant-tag& {
    margin-left: 8px;
    margin-right: 5px;
    border: ${({ theme }) => theme.borders.mediumSolidGray};
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-transform: uppercase;
    max-height: 20px;
  }
`;
const StyledDatetime = styled(Text).attrs({
  ellipsis: true
})`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.darkGray};
    white-space: nowrap;
    -ms-flex: 1 1 0px;
    text-align: right;
  }
`;
const StyledContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
`;
const StyledActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
`;
//#endregion

export default withLDConsumer()(CommunicationBase);
