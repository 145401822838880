import { createRequestTypes, makeActionCreator } from 'utils';
import { takeLatest, put, select } from 'redux-saga/effects';
import { getWithToken, postWithToken } from 'api';
import { apiStatusConstants } from 'app-constants';
import { createSelector } from 'reselect';
import { rootEntitySwitcherSelector } from './dealersStore';

//#region Actions
export const JOURNAL_EVENTS = createRequestTypes('JOURNAL_EVENTS', [
  'GET_USER_DATA',
  'SET_USER_DATA',
  'SET_USER_DATA_FETCH_STATUS',
  'SET_USER_SEEN',
  'GET_VEHICLE_HISTORY_DATA',
  'SET_VEHICLE_HISTORY_DATA',
  'SET_VEHICLE_HISTORY_FETCH_STATUS',
  'GET_TASK_HISTORY_DATA',
  'SET_TASK_HISTORY_DATA',
  'SET_TASK_HISTORY_FETCH_STATUS'
]);

export const journalActions = {
  getUserData: makeActionCreator(
    JOURNAL_EVENTS.GET_USER_DATA,
    'userId',
    'start',
    'limit',
    'dealerId',
    'displayNotificationMessageTypes'
  ),
  setUserData: makeActionCreator(JOURNAL_EVENTS.SET_USER_DATA, 'userData'),
  setUserDataFetchStatus: makeActionCreator(JOURNAL_EVENTS.SET_USER_DATA_FETCH_STATUS, 'userDataFetchStatus'),
  setSeen: makeActionCreator(JOURNAL_EVENTS.SET_USER_SEEN, 'notificationId'),
  getVehicleHistoryData: makeActionCreator(JOURNAL_EVENTS.GET_VEHICLE_HISTORY_DATA, 'vehicleId', 'start', 'limit'),
  setVehicleHistoryData: makeActionCreator(JOURNAL_EVENTS.SET_VEHICLE_HISTORY_DATA, 'vehicleHistoryData'),
  setVehicleHistoryFetchStatus: makeActionCreator(
    JOURNAL_EVENTS.SET_VEHICLE_HISTORY_FETCH_STATUS,
    'vehicleHistoryFetchStatus'
  ),
  getTaskHistoryData: makeActionCreator(JOURNAL_EVENTS.GET_TASK_HISTORY_DATA, 'taskId', 'start', 'limit'),
  setTaskHistoryData: makeActionCreator(JOURNAL_EVENTS.SET_TASK_HISTORY_DATA, 'taskHistoryData'),
  setTaskHistoryFetchStatus: makeActionCreator(JOURNAL_EVENTS.SET_TASK_HISTORY_FETCH_STATUS, 'taskHistoryFetchStatus')
};
//#endregion

//#region Reducer
const initialState = {
  userData: {},
  userDataFetchStatus: apiStatusConstants.PENDING,
  vehicleHistoryData: null,
  vehicleHistoryFetchStatus: apiStatusConstants.IS_FETCHING,
  taskHistoryData: null,
  taskHistoryFetchStatus: apiStatusConstants.IS_FETCHING
};

export const journalReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOURNAL_EVENTS.SET_USER_DATA:
      return {
        ...state,
        userData: { ...action.userData }
      };
    case JOURNAL_EVENTS.SET_USER_DATA_FETCH_STATUS:
      return {
        ...state,
        userDataFetchStatus: action.userDataFetchStatus
      };
    case JOURNAL_EVENTS.SET_VEHICLE_HISTORY_DATA:
      return {
        ...state,
        vehicleHistoryData: { ...action.vehicleHistoryData }
      };
    case JOURNAL_EVENTS.SET_VEHICLE_HISTORY_FETCH_STATUS:
      return {
        ...state,
        vehicleHistoryFetchStatus: action.vehicleHistoryFetchStatus
      };
    case JOURNAL_EVENTS.SET_TASK_HISTORY_DATA:
      return {
        ...state,
        taskHistoryData: { ...action.taskHistoryData }
      };
    case JOURNAL_EVENTS.SET_TASK_HISTORY_FETCH_STATUS:
      return {
        ...state,
        taskHistoryFetchStatus: action.taskHistoryFetchStatus
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const journalSelector = createSelector(
  (state) => state.journal,
  (journal) => journal.userData
);

export const vehicleHistorySelector = createSelector(
  (state) => state.journal,
  (journal) => journal.vehicleHistoryData
);

export const taskHistorySelector = createSelector(
  (state) => state.journal,
  (journal) => journal.taskHistoryData
);
//#endregion

//#region Sagas
export function* getUserJournalSaga() {
  yield takeLatest(
    JOURNAL_EVENTS.GET_USER_DATA,
    function* ({ userId, start, limit, displayNotificationMessageTypes, dealerId }) {
      try {
        yield put(journalActions.setUserDataFetchStatus(apiStatusConstants.IS_FETCHING));
        const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
        const journalEventsData = yield getWithToken(`/api/ReconJournal/userid/${userId}`, {
          start,
          limit,
          displayNotificationMessageTypes,
          dealerId: isRootUser && vendorShipSelected ? undefined : dealerId,
          isRootUserSwitchToVendor: isRootUser && vendorShipSelected ? true : false
        });

        yield put(journalActions.setUserData(journalEventsData));
        yield put(journalActions.setUserDataFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        // TODO: handle error
        yield put(journalActions.setUserDataFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getJournalSaga', error);
      }
    }
  );
}

export function* getVehicleHistoryJournalSaga() {
  yield takeLatest(JOURNAL_EVENTS.GET_VEHICLE_HISTORY_DATA, function* ({ vehicleId, start, limit }) {
    try {
      yield put(journalActions.setVehicleHistoryFetchStatus(apiStatusConstants.IS_FETCHING));
      const vehicleHistoryData = yield getWithToken(`/api/ReconJournal/vehicleId/${vehicleId}`, { start, limit });

      yield put(journalActions.setVehicleHistoryData(vehicleHistoryData));
      yield put(journalActions.setVehicleHistoryFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(journalActions.setVehicleHistoryFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getVehicleHistoryJournalSaga', error);
    }
  });
}

export function* getTaskHistoryJournalSaga() {
  yield takeLatest(JOURNAL_EVENTS.GET_TASK_HISTORY_DATA, function* ({ taskId, start, limit }) {
    try {
      yield put(journalActions.setTaskHistoryFetchStatus(apiStatusConstants.IS_FETCHING));
      const taskHistoryData = yield getWithToken(`/api/ReconJournal/taskId/${taskId}`, { start, limit });

      yield put(journalActions.setTaskHistoryData(taskHistoryData));
      yield put(journalActions.setTaskHistoryFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(journalActions.setTaskHistoryFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTaskHistoryJournalSaga', error);
    }
  });
}

export function* setUserJournalSeenSaga() {
  yield takeLatest(JOURNAL_EVENTS.SET_USER_SEEN, function* ({ notificationId }) {
    try {
      // set notification complete
      const notificationResult = yield postWithToken(`/api/ReconJournal/notificationId/${notificationId}/setSeen`, '');

      // get the current set of notifications in the store
      const data = { ...(yield select(journalSelector)) };

      // reset seen on with new value from api return value
      const itemIndex = data.items.findIndex((x) => x.notificationId === notificationId);
      data.items[itemIndex].seenOn = notificationResult.seenOn;

      yield put(journalActions.setUserData(data));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in setJournalSeenSaga', error);
    }
  });
}

export const getTaskHistoryLogSelector = () => {
  return createSelector(
    (state) => state.journal.taskHistoryData,
    (state) => state.journal.taskHistoryFetchStatus,
    (taskHistoryData, taskHistoryFetchStatus) => {
      const originHistory = taskHistoryData?.items ?? [];
      const reversedHistory = [...originHistory].sort(
        (leftDate, rightDate) => new Date(rightDate.journalCreatedOn) - new Date(leftDate.journalCreatedOn)
      );
      return {
        taskHistoryData: reversedHistory.filter((h) => h.reconJournalEventType !== 'LINE_ITEM_REMOVED'),
        taskHistoryFetchStatus
      };
    }
  );
};

//#endregion
