//#region immutatable array functions
export const insertItem = (array, action) => {
  return [...array.slice(0, action.index), action.item, ...array.slice(action.index)];
};

export const removeItem = (array, action) => {
  return [...array.slice(0, action.index), ...array.slice(action.index + 1)];
};

export const pushItemToEndOfArray = (array, action) => {
  return removeItem([...array, array[action.index]], action);
};

export const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item
    };
  });
};

// All items of array a that are not in array b
export const difference = (a, b) => {
  return [...a].filter((item) => !b.includes(item));
};
//#endregion

//#region mutable array functions
export const sort = (collectionRef, fieldName, ascending = true) => {
  collectionRef.sort((a, b) => (a[fieldName] > b[fieldName] ? (ascending ? 1 : -1) : ascending ? -1 : 1));
};
//#endregion

//#region add values from objects in object array based on property key
//array needs to be an array of objects which all have the same property keys
//whatever you are taking the sum of across object elements needs to be of type number
export const addObjectArrayValues = (array, propertyKey) =>
  array?.reduce((sum, { [propertyKey]: key }) => sum + key, 0) || 0;

//some work could be done to this to make the map function more powerful
export const createNumberArray = (
  length = 0, //length of array
  valueAdded = 0, //can start the array from a different value than 0
  valuesStoredAsIntegers = true, //whether or not we want to return an number or a string,
  functionToDetermineSequence = (x, y) => x + y //how should the startingValue and index be combined to create the array
) =>
  Array.from(
    //creates an array from an array-like or iterable object
    { length }, //{length} is an array-like - all arrays have a length property and are objects, so this mimics an array enough that Array.from creates an array from it - the array that is created is an empty array with length length
    (_, index) => {
      //Array.from map function - works exactly like the Array.map function works - here we are taking the empty array and working with the index values to create a sequential integer array
      let returnedValue = functionToDetermineSequence(index, valueAdded);
      return valuesStoredAsIntegers ? returnedValue : returnedValue.toString();
    }
  );

export const createSequentialIntegerArray = (length, valueAdded, valueStoredAsIntegers) =>
  createNumberArray(length, valueAdded, valueStoredAsIntegers);
//#endregion

export const sortSubArrays = (array, keyOfPropToSort, sorterMethod) => {
  if (!Array.isArray(array)) return devLogger.error('value passed in as paramter array is not an array') || array;
  if (array.length === 0) return devLogger.error('value passed in as parameter array is an empty array') || array;
  if (typeof keyOfPropToSort !== 'string')
    return devLogger.error('value passed in as parameter keyOfPropToSort is not a string') || array;
  if (Array.isArray(array) && typeof keyOfPropToSort === 'string' && array[0][keyOfPropToSort] === undefined)
    return devLogger.error(`${keyOfPropToSort} is not a field in the elements of the array`) || array;
  if (Array.isArray(array) && typeof keyOfPropToSort === 'string' && !Array.isArray(array[0][keyOfPropToSort]))
    return devLogger.error(`${keyOfPropToSort} is not an array field in the elements of the array`) || array;
  if (typeof sorterMethod !== 'function')
    return devLogger.error('value passed as parameter sorterMethod is not a function') || array;
  //after checks
  const copyOfArray = JSON.parse(JSON.stringify(array)); //note that this function should not be used if there are functions in the array - this will not keep the functions

  return copyOfArray.reduce(
    (transformedArray, { [keyOfPropToSort]: dataToSort, ...restOfData }) => [
      ...transformedArray,
      {
        [keyOfPropToSort]: dataToSort.sort(sorterMethod),
        ...restOfData
      }
    ],
    []
  );
};

//#region Looking for the item in list then update the visible state
export const updateVisibleStateError = (list, messageContent, visibleState) => {
  return list?.map((err) => {
    if (err.messageContent === messageContent) {
      err.visibleState = visibleState;
    }
    return err;
  });
};
//#endregion

//stripReferences should be used for arrays of objects or arrays of arrays
//main point of this function is to remove references to objects, arrays that would stick around when creating new arrays from arrays using filter, etc
//you pass in the resulting array after a manipulation has been performed
export const stripReferences = (array) => JSON.parse(JSON.stringify(array));

export const sortLineItemCatalog = (array, sortedInfo) => [
  ...array.sort((a, b) => {
    const { field, order } = sortedInfo;
    if (field !== 'description' && order) {
      return order === 'ascend' ? compareCost(a, b, field) : -compareCost(a, b, field);
    } else {
      if (!order) {
        return a.description.localeCompare(b.description);
      }
      const compare = a[field].localeCompare(b[field]);
      return order === 'ascend' ? compare : -compare;
    }
  })
];

export const compareCost = (a, b, columnKey) =>
  isNaN(a[columnKey]) || a[columnKey] === ''
    ? isNaN(b[columnKey]) || b[columnKey] === ''
      ? 0
      : -1
    : isNaN(b[columnKey]) || b[columnKey] === ''
    ? 1
    : a[columnKey] - b[columnKey];

export const searchLineItemCatalog = (array, searchString) => {
  const lowerCaseSearchStr = searchString.toLowerCase();
  const lowerCaseSearchStrNoSeparator = lowerCaseSearchStr.replace(',', '');
  return [
    ...array.filter((item) => {
      if (!item.isAdding && searchString) {
        if (isNaN(lowerCaseSearchStrNoSeparator)) {
          return item.description?.trim().toLowerCase().includes(lowerCaseSearchStr);
        } else {
          return (
            item.description?.trim().toLowerCase().includes(lowerCaseSearchStr) ||
            item.partsCost?.toFixed(2).toString().includes(lowerCaseSearchStr) ||
            item.laborCost?.toFixed(2).toString().includes(lowerCaseSearchStr) ||
            item.totalCost?.toFixed(2).toString().includes(lowerCaseSearchStr) ||
            item.partsCost?.toFixed(2).toString().includes(lowerCaseSearchStrNoSeparator) ||
            item.laborCost?.toFixed(2).toString().includes(lowerCaseSearchStrNoSeparator) ||
            item.totalCost?.toFixed(2).toString().includes(lowerCaseSearchStrNoSeparator)
          );
        }
      } else {
        return item;
      }
    })
  ];
};

export const trimLineBreakBlocks = (arr) => {
  let blockArr = [...arr];
  const firstIndex = blockArr.findIndex((block) => !!block.text);
  blockArr.reverse();
  const lastIndex = blockArr.length - 1 - blockArr.findIndex((block) => !!block.text);
  return arr.slice(firstIndex, lastIndex + 1);
};
