import { analyticsTagConstants } from './index';

/**
 * Pushes "static" data to the data layer
 */
export const initTagManager = () => {
  window.dataLayer.push({
    common: {
      dataLayerVersion: 2,
      application: {
        name: 'iRecon',
        version: process.env.REACT_APP_VERSION || 'local',
        environment: process.env.REACT_APP_APPLICATION_ENVIRONMENT,
        isProduction: process.env.REACT_APP_APPLICATION_ENVIRONMENT === 'production'
      }
    }
  });
};

/*
 * Indicates that the data layer is fully initialized. Allows events to start being pushed to analytics services.
 *
 * Would it be better to track what the data layer has set and have this be automatically called?
 * One potential issue with this is that a dealer user has a dealer context but a vendor user does not.
 */
export const pushTagManagerReady = () => {
  window.dataLayer.push({
    event: 'dataLayerReady',
    dataLayerReady: true
  });
};

/*
 * Pushes user data to the data layer.
 */
export const pushTagManagerUser = (user) => {
  window.dataLayer.push({
    common: {
      user: {
        ...user
      }
    }
  });
};

/**
 * Sets up common data layer with current dealer or vendor details
 */
export const pushTagManagerContext = (entityContext) => {
  const logicalId = entityContext.isVendor ? analyticsTagConstants.ALL_VENDORS_LOGICAL_ID : entityContext.logicalId;
  const entityType = entityContext.isVendor ? analyticsTagConstants.ENTITY_TYPE_VENDOR : entityContext.entityType;
  const entityName = entityContext.name || 'Unknown';

  window.dataLayer.push({
    common: {
      context: {
        dealershipId: logicalId,
        dealershipName: entityName.substring(0, 280) // Truncate user properties to 280 character to avoid 414 response codes
      }
    },
    dealership: {
      entityType: entityType,
      groupName: logicalId
    }
  });
};

/**
 * Pushes a userEvent to the data layer. Look at docs/GoogleAnalytics.md for more information on the fields.
 * With the new `productEvent` shape, we can only send 2 parameters, `eventName`, and an optional `eventProperties`
 * @param {string} eventName : Required field indicating event name displayed in analytics dashboards
 * @param {object} eventProperties : optional parameter to include any custom properties specific to this event
 */
export const pushTagManagerEvent = (eventName, eventProperties = undefined) => {
  if (!!eventName === false) {
    console.trace('GA tag event : Event name is required');
    return;
  }

  const _productEvent = {
    event: 'productEvent', // this must always be 'productEvent'
    eventName: eventName // custom event name
  };

  if (eventProperties) {
    _productEvent.eventProperties = eventProperties;
  }

  window.dataLayer.push(_productEvent);
};
