import { takeLatest, put, select } from 'redux-saga/effects';
import { createRequestTypes, makeActionCreator } from 'utils';
import { getWithToken } from 'api';
import { apiStatusConstants } from 'app-constants';
import { createSelector } from 'reselect';
import { pushItemToEndOfArray } from 'utils/arrayUtils';

//#region Actions
export const TASK_CATEGORIES = createRequestTypes('TASK_CATEGORIES', [
  'GET_DATA',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'GET_DATA_FROM_TASK_TYPE',
  'SET_DATA_FROM_TASK_TYPE',
  'SET_FETCH_STATUS_FROM_TASK_TYPE'
]);
export const taskCategoriesActions = {
  getData: makeActionCreator(TASK_CATEGORIES.GET_DATA),
  setData: makeActionCreator(TASK_CATEGORIES.SET_DATA, 'categories'),
  setFetchStatus: makeActionCreator(TASK_CATEGORIES.SET_FETCH_STATUS, 'fetchStatus'),

  getDataFromTaskType: makeActionCreator(TASK_CATEGORIES.GET_DATA_FROM_TASK_TYPE, 'dealerId'),
  setDataFromTaskTypes: makeActionCreator(TASK_CATEGORIES.SET_DATA_FROM_TASK_TYPE, 'categories'),
  setFetchStatusFromTaskTypes: makeActionCreator(TASK_CATEGORIES.SET_FETCH_STATUS_FROM_TASK_TYPE, 'fetchStatus')
};
//#endregion

//#region Reducer
const initialState = {
  categoriesAll: {
    data: [],
    dict: {},
    fetchStatus: apiStatusConstants.PENDING
  },
  categoriesFromTaskTypes: {
    data: [],
    fetchStatus: apiStatusConstants.PENDING
  },
  data: []
};

export const taskCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TASK_CATEGORIES.SET_DATA:
      return {
        ...state,
        categoriesAll: {
          ...state.categoriesAll,
          data: action.categories.data,
          dict: action.categories.dict
        }
      };
    case TASK_CATEGORIES.SET_FETCH_STATUS:
      return {
        ...state,
        categoriesAll: {
          ...state.categoriesAll,
          fetchStatus: action.fetchStatus
        }
      };

    case TASK_CATEGORIES.SET_DATA_FROM_TASK_TYPE:
      return {
        ...state,
        categoriesFromTaskTypes: {
          ...state.categoriesFromTaskTypes,
          data: action.categories
        }
      };
    case TASK_CATEGORIES.SET_FETCH_STATUS_FROM_TASK_TYPE:
      return {
        ...state,
        categoriesFromTaskTypes: {
          ...state.categoriesFromTaskTypes,
          fetchStatus: action.fetchStatus
        }
      };
    default:
      return state;
  }
};
//#endregion

//#region selectors
export const taskCategoriesSelector = createSelector(
  (state) => state.taskCategories,
  (state) => state,
  (taskCategories) => {
    return {
      categoriesAll: taskCategories.categoriesAll,
      categoriesFromTaskTypes: taskCategories.categoriesFromTaskTypes
    };
  }
);
//#endregion

//#region Sagas
export function* getTaskCategoriesSaga() {
  yield takeLatest(TASK_CATEGORIES.GET_DATA, function* () {
    try {
      const categoriesState = yield select(taskCategoriesSelector);
      const fetchStatus = categoriesState.categoriesAll.fetchStatus;
      if (fetchStatus === apiStatusConstants.SUCCEEDED) {
        return;
      }

      yield put(taskCategoriesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));

      let taskCategories = yield getWithToken(`/api/TaskCategories`);

      // Move miscellaneous to end of array for displaying in task type edit/create dropdown lists
      const miscIndex = taskCategories.findIndex((taskCategory) => taskCategory.name === 'Miscellaneous');

      // This should always be true
      if (miscIndex !== -1) {
        taskCategories = pushItemToEndOfArray(taskCategories, { index: miscIndex });
      }

      // Create id/name dictionary for task categories
      let taskCategoriesDict = {};
      taskCategories.forEach((taskCategory) => (taskCategoriesDict[taskCategory.id] = taskCategory.name));

      yield put(taskCategoriesActions.setData({ data: taskCategories, dict: taskCategoriesDict }));
      yield put(taskCategoriesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(taskCategoriesActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTaskCategoriesSaga', error);
    }
  });
}

export function* getTaskCategoriesFromTaskTypeSaga() {
  yield takeLatest(TASK_CATEGORIES.GET_DATA_FROM_TASK_TYPE, function* ({ dealerId }) {
    try {
      yield put(taskCategoriesActions.setFetchStatusFromTaskTypes(apiStatusConstants.IS_FETCHING));

      let taskCategories = yield getWithToken(`/api/TaskCategories/FromTaskTypes?dealerId=${dealerId}`);

      yield put(taskCategoriesActions.setDataFromTaskTypes(taskCategories));
      yield put(taskCategoriesActions.setFetchStatusFromTaskTypes(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(taskCategoriesActions.setFetchStatusFromTaskTypes(apiStatusConstants.FAILED));
      devLogger.log('error in getTaskCategoriesSaga', error);
    }
  });
}
//#endregion
