import { Row, Col } from 'antd';
import React, { useState } from 'react';
import { useDeviceOSDetect } from 'hooks/useDeviceDetect';
import { ButtonText, StyledButton, StyledInput } from './style';
import { unicodeToChar } from './utils';

const mask = unicodeToChar('\u2022');
const searchRegex = /[^\u2022]/g;

const CustomInput = ({ title, placeholder, updateValue, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [masked, setMasked] = useState('');
  const [normalValue, setNormalValue] = useState('');
  const isIOS = useDeviceOSDetect();

  const sliceText = (value, startIndex, endIndex) => {
    return value.slice(0, startIndex) + value.slice(endIndex);
  };

  const insertText = (value, newValue, startIndex, endIndex) => {
    return [value.slice(0, startIndex), newValue, value.slice(endIndex)].join('');
  };

  const onChange = (e) => {
    let value = e.target.value;
    let unmasked = '';
    const removeDistance = masked.length - value.length;
    if (showPassword) {
      unmasked = value;
    } else {
      const changedIndex = value.search(searchRegex);
      if (changedIndex !== -1) {
        //addding
        unmasked =
          removeDistance >= 0
            ? value[changedIndex]
            : insertText(normalValue, value[changedIndex], changedIndex, changedIndex);
      } else {
        //removing
        unmasked = sliceText(normalValue, e.target.selectionStart, e.target.selectionStart + removeDistance);
      }
    }
    setNormalValue(unmasked);
    updateValue(unmasked);
    setMasked(mask.repeat(value.length));
  };

  const onCopy = (e) => {
    if (showPassword) {
      e.clipboardData.setData('text/plain', normalValue.slice(e.target.selectionStart, e.target.selectionEnd));
    }
    e.preventDefault();
  };

  const onPaste = (e) => {
    const value = e.clipboardData.getData('text/plain');
    const newValue = insertText(normalValue, value, e.target.selectionStart, e.target.selectionEnd);
    setNormalValue(newValue);
    updateValue(newValue);
    setMasked(mask.repeat(newValue.length));
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Row type="flex" justify="space-between" align="middle" gutter={[3, 3]}>
        <Col>
          <ButtonText>{title}</ButtonText>
        </Col>

        <Col>
          <StyledButton type="link" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? 'Hide Password' : 'Show Password'}
          </StyledButton>
        </Col>

        <Col xs={24}>
          <StyledInput
            type="text"
            placeholder={placeholder}
            value={showPassword ? normalValue : masked}
            onChange={onChange}
            error={error}
            size={isIOS ? 'large' : 'middle'}
            onCopy={onCopy}
            onPaste={onPaste}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CustomInput;
