import axios from 'axios';
import qs from 'qs';

let baseUrl = localStorage.getItem('apiBaseUrl') || process.env.REACT_APP_API_BASE_URL;

const unauthorizedAxios = axios.create();

export const setApiBaseUrl = (bffFlag) => {
  baseUrl =
    bffFlag && process.env.REACT_APP_BFF_BASE_URL
      ? process.env.REACT_APP_BFF_BASE_URL
      : process.env.REACT_APP_API_BASE_URL;
};

export const getWithToken = (url, params) => {
  return axios
    .get(`${baseUrl}${url}`, {
      params,
      paramsSerializer: (QueryParams) => qs.stringify(QueryParams, { allowDots: true })
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const newError = new Error(error);
      newError.statusCode = error.response?.status;
      throw newError;
    });
};

export const getFileWithToken = (url, params) => {
  return axios
    .get(`${baseUrl}${url}`, {
      params,
      paramsSerializer: (QueryParams) => qs.stringify(QueryParams, { allowDots: true }),
      responseType: 'blob'
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getWithTokenTestWithError = (url, params) => {
  return axios
    .get(`${baseUrl}${url}`, {
      params
    })
    .then((_response) => {
      throw new Error('testing...');
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const postFileWithToken = (url, file) => {
  let data = new FormData();
  data.append('file', file?.originFileObj);
  return axios
    .post(`${baseUrl}${url}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const postWithoutToken = (url, body) => {
  return unauthorizedAxios
    .post(`${baseUrl}${url}`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let message = error.response.data?.message;
      throw message ?? error;
    });
};

export const postWithToken = (url, body) => {
  return axios
    .post(`${baseUrl}${url}`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const putWithToken = (url, body, params = {}) => {
  return axios
    .put(`${baseUrl}${url}`, body, {
      headers: {
        'Content-Type': 'application/json'
      },
      params,
      paramsSerializer: (QueryParams) => qs.stringify(QueryParams, { allowDots: true })
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const patchWithToken = (url, body) => {
  return axios
    .patch(`${baseUrl}${url}`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const deleteWithToken = (url) => {
  return axios
    .delete(`${baseUrl}${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
