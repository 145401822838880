import React, { useContext } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BodySmall } from 'components/styledComponents';
import styled from 'styled-components';
import { Typography } from 'antd';

import { taskProgressLabels, detailContents } from 'app-constants';
import { TaskContext } from '../helpers/context';
import { IN_PROGRESS } from 'app-constants/taskStatusTypes';

const AdditionalTaskNotes = withLDConsumer()(({ contentType = null, flags }) => {
  return <AdditionalTaskNotesForReconTask contentType={contentType} reconTaskCollapseFlag={flags.reconTaskCollapse} />;
});

/**
 * AdditionalTaskNotesForReconTask is the part of the Recon Task that shows one of two things
 * if the task progress is not on track, information about Past Goal or Overdue progress is shown
 * if the task progress is in progress, it shows the task description which is set when the task is saved/added/edited
 **/
const AdditionalTaskNotesForReconTask = ({ contentType, reconTaskCollapseFlag }) => {
  /**
   * TaskContext is used at the top level of the Task component
   * task is the actual task dto
   * taskProgress is declared and initialized in the getReconData function in the Task component file
   **/
  const { task, editable, taskProgress, hideTaskProgressInfo } = useContext(TaskContext);
  if (reconTaskCollapseFlag) {
    return (
      <StyledAdditionalTaskNotes>
        <BodySmall>
          <StyledParagraph completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
            Description: {task.description || String.fromCharCode(8212)}
          </StyledParagraph>
        </BodySmall>
        {task.declinedOn && (
          <BodySmall>
            <StyledParagraph completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
              Decline Note: {task.declinedReason || String.fromCharCode(8212)}
            </StyledParagraph>
          </BodySmall>
        )}
        {task.completedOn && !editable && (
          <BodySmall>
            <StyledParagraph completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
              Completion Note: {task.completionNote || String.fromCharCode(8212)}
            </StyledParagraph>
          </BodySmall>
        )}
      </StyledAdditionalTaskNotes>
    );
  } else {
    if (taskProgress.status !== taskProgressLabels.OnTrack || task.completedOn) {
      return hideTaskProgressInfo ? (
        <></>
      ) : (
        <>
          {taskProgress.status !== taskProgressLabels.OnTrack && (
            <StyledTaskProgress progress={taskProgress.status}>{taskProgress.info}</StyledTaskProgress>
          )}
          {contentType === detailContents.TASK_DETAILS &&
            [taskProgressLabels.PastGoal, taskProgressLabels.Overdue].includes(taskProgress.status) && (
              <StyledParagraph>Description: {task.description || String.fromCharCode(8212)}</StyledParagraph>
            )}
          {task.completedOn && task.completionNote && (
            <StyledParagraph>Completion Note: {task.completionNote || String.fromCharCode(8212)}</StyledParagraph>
          )}
        </>
      );
    } else if (task.status === IN_PROGRESS) {
      return <StyledParagraph>Description: {task.description || String.fromCharCode(8212)}</StyledParagraph>;
    }
  }

  return null;
};

//#region Styled Components
const StyledTaskProgress = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: ${(props) => {
    switch (props['progress']) {
      case taskProgressLabels.Overdue:
        return props.theme.colors.red;
      case taskProgressLabels.PastGoal:
        return props.theme.colors.orange;
      default:
        return 'inherit';
    }
  }};
`;
const StyledParagraph = styled(Typography.Paragraph).attrs((props) => ({
  ellipsis: {
    rows: props['recon-task-collapse-flag'] ? 4 : 1,
    expandable: true
  }
}))`
  line-height: ${(props) => !props['recon-task-collapse-flag'] && '1.8'};
  margin-right: ${(props) => !props['recon-task-collapse-flag'] && '18px'};
  word-break: break-word;
  .ant-typography& {
    margin-bottom: 0;
    color: ${(props) => props['recon-task-collapse-flag'] && props.theme.colors.darkGray};
  }
`;
const StyledAdditionalTaskNotes = styled.div``;
//#endregion

export default AdditionalTaskNotes;
