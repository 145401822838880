export default [
  {
    id: 'e08a0590-0e9a-48b6-9d62-deb6a030973f',
    name: 'VendorDentRepait526239818',
    assignees: [
      {
        reconTaskTypeId: 'e08a0590-0e9a-48b6-9d62-deb6a030973f',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 0,
    isDefault: false
  },
  {
    id: 'ac6eaefb-a743-48c5-b9b2-ac099a529720',
    name: 'VendorDentRepait713502872',
    assignees: [
      {
        reconTaskTypeId: 'ac6eaefb-a743-48c5-b9b2-ac099a529720',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 0,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: 'ac6eaefb-a743-48c5-b9b2-ac099a529720',
          preApprove: false,
          createdOn: '2022-06-08T03:25:19.824804Z',
          description: 'BlueWheels',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10.5,
          partsCost: 40,
          totalCost: 50.5,
          id: '22054093-d7d2-4665-b5c2-8d6e06ea6d3f'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '47465714-647e-43ed-92ea-35ce7620c45c',
    name: 'VendorDentRepait134732555',
    assignees: [
      {
        reconTaskTypeId: '47465714-647e-43ed-92ea-35ce7620c45c',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 0,
    isDefault: false
  },
  {
    id: 'd3b2f2a4-615e-492c-9548-135e00b2add0',
    name: 'VendorDentRepait1454126982',
    assignees: [
      {
        reconTaskTypeId: 'd3b2f2a4-615e-492c-9548-135e00b2add0',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 0,
    isDefault: false
  },
  {
    id: '112b88d8-bbc0-4088-aec0-cd72ca7621b6',
    name: 'Keys',
    assignees: [
      {
        reconTaskTypeId: '112b88d8-bbc0-4088-aec0-cd72ca7621b6',
        userId: '2d7b3b14-b88b-4ba6-804f-949417a6982f',
        userName: 'aglovermeade',
        userFullName: 'Adam Glover-Meade',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 1,
    isDefault: true
  },
  {
    id: '1cbee6fb-c8c8-4a42-b68b-b1f1a64fa64b',
    name: 'VendorDentRepait120412154',
    assignees: [
      {
        reconTaskTypeId: '1cbee6fb-c8c8-4a42-b68b-b1f1a64fa64b',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 3,
    isDefault: false
  },
  {
    id: 'a4b0cac4-3625-43ee-8d75-f3c4e379d8da',
    name: 'VendorDentRepait339884246',
    assignees: [
      {
        reconTaskTypeId: 'a4b0cac4-3625-43ee-8d75-f3c4e379d8da',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 4,
    isDefault: false
  },
  {
    id: 'aa7ec524-da12-4ab8-808a-9e224fefeccf',
    name: 'Service',
    assignees: [
      {
        reconTaskTypeId: 'aa7ec524-da12-4ab8-808a-9e224fefeccf',
        userId: '1ddcce4c-dd91-421e-9291-9d85b8ff0237',
        userName: 'kelleighthomas',
        userFullName: 'Kelleigh Thomas',
        priority: 1
      }
    ],
    cost: 700,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT2H',
    maxThreshold: 'PT3H',
    sequence: 6,
    isDefault: true,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: 'aa7ec524-da12-4ab8-808a-9e224fefeccf',
          preApprove: false,
          createdOn: '2021-09-10T16:49:44.543528Z',
          description: 'Oil Change',
          laborRate: 0,
          laborTime: 0,
          laborCost: 45,
          totalCost: 45,
          id: '0bc36977-3bf1-4396-a5f3-072907b97f46'
        },
        {
          reconTaskTypeId: 'aa7ec524-da12-4ab8-808a-9e224fefeccf',
          preApprove: true,
          createdOn: '2021-09-10T16:49:44.54363Z',
          description: 'Check Brakes',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10,
          totalCost: 10,
          id: 'c02d8c0f-44e9-4831-9694-21d088d61883'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'ff67da16-c3d5-4af0-8f4d-eb04759579db',
    name: 'VendorDentRepait1663614917',
    assignees: [
      {
        reconTaskTypeId: 'ff67da16-c3d5-4af0-8f4d-eb04759579db',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 7,
    isDefault: false
  },
  {
    id: '2fa9d7bc-f2d8-4009-9cdc-370a47407427',
    name: 'VendorDentRepait963372650',
    assignees: [
      {
        reconTaskTypeId: '2fa9d7bc-f2d8-4009-9cdc-370a47407427',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 200000,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 8,
    isDefault: false
  },
  {
    id: '21b548f8-b45a-4a25-843f-0eccfb572042',
    name: 'VendorDentRepait951086621',
    assignees: [
      {
        reconTaskTypeId: '21b548f8-b45a-4a25-843f-0eccfb572042',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 9,
    isDefault: false
  },
  {
    id: 'bc3ed669-7344-4aa8-9240-6d9cda65dde5',
    name: 'ServiceTest',
    assignees: [
      {
        reconTaskTypeId: 'bc3ed669-7344-4aa8-9240-6d9cda65dde5',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 200000,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P15DT19H',
    maxThreshold: 'P15DT20H',
    sequence: 10,
    isDefault: false
  },
  {
    id: '64cc243e-eab7-4796-b015-2a0494e87935',
    name: 'VendorDentRepait171543586',
    assignees: [
      {
        reconTaskTypeId: '64cc243e-eab7-4796-b015-2a0494e87935',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 11,
    isDefault: false
  },
  {
    id: 'ce734aa1-9035-49fc-b809-6c91b17b0401',
    name: 'VendorDentRepait1442488366',
    assignees: [
      {
        reconTaskTypeId: 'ce734aa1-9035-49fc-b809-6c91b17b0401',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 12,
    isDefault: false
  },
  {
    id: 'a03601ab-775e-4b54-a57b-239f4394e982',
    name: 'VendorDentRepait143703559',
    assignees: [
      {
        reconTaskTypeId: 'a03601ab-775e-4b54-a57b-239f4394e982',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 13,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: 'a03601ab-775e-4b54-a57b-239f4394e982',
          preApprove: true,
          preApprovedOn: '2021-12-20T10:30:09.912252Z',
          createdOn: '2021-10-19T09:41:10.5104Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 123,
          partsCost: 123,
          totalCost: 246,
          id: '53f422ba-a0e4-41c7-9bf0-4f80cae84e93'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'f9d4b625-2390-4fe3-94f5-25275328a129',
    name: 'VendorDentRepait1062868672',
    assignees: [
      {
        reconTaskTypeId: 'f9d4b625-2390-4fe3-94f5-25275328a129',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 14,
    isDefault: false
  },
  {
    id: '0e53e51e-2f19-4a4c-84d3-a015ed07121b',
    name: 'VendorDentRepait518157014',
    assignees: [
      {
        reconTaskTypeId: '0e53e51e-2f19-4a4c-84d3-a015ed07121b',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 15,
    isDefault: false
  },
  {
    id: 'ff955d55-a028-4435-bff8-4afd77bc58ce',
    name: 'VendorDentRepait461022625',
    assignees: [
      {
        reconTaskTypeId: 'ff955d55-a028-4435-bff8-4afd77bc58ce',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 16,
    isDefault: false
  },
  {
    id: 'ef3d6ce2-5868-4cbc-881b-eabb8ab1bcf2',
    name: 'VendorDentRepait1538369210',
    assignees: [
      {
        reconTaskTypeId: 'ef3d6ce2-5868-4cbc-881b-eabb8ab1bcf2',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 17,
    isDefault: false
  },
  {
    id: '223b3e0a-f26d-4863-a62b-c328edc11c00',
    name: 'VendorDentRepait1906135465',
    assignees: [
      {
        reconTaskTypeId: '223b3e0a-f26d-4863-a62b-c328edc11c00',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 18,
    isDefault: false
  },
  {
    id: '688f5910-8c3d-4b1c-9631-ee5fe4de94f9',
    name: 'VendorDentRepait1631100916',
    assignees: [
      {
        reconTaskTypeId: '688f5910-8c3d-4b1c-9631-ee5fe4de94f9',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 19,
    isDefault: false
  },
  {
    id: '896fb332-cee1-4d31-9d83-63e7b248f660',
    name: 'VendorDentRepait862495019e',
    assignees: [
      {
        reconTaskTypeId: '896fb332-cee1-4d31-9d83-63e7b248f660',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 20,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: '896fb332-cee1-4d31-9d83-63e7b248f660',
          preApprove: false,
          createdOn: '2021-09-29T08:46:08.592326Z',
          description: 'Line item 1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 23,
          totalCost: 23,
          id: '9128ceaf-3b56-4a4b-86b4-b6b2346f169b'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'afe495e5-56fd-41ca-bcb3-64b61aa9cefc',
    name: 'VendorDentRepait638538658',
    assignees: [
      {
        reconTaskTypeId: 'afe495e5-56fd-41ca-bcb3-64b61aa9cefc',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 21,
    isDefault: false
  },
  {
    id: '216286d5-08b6-4363-a2dc-2a0cdde71ffe',
    name: 'VendorDentRepait1983280838',
    assignees: [
      {
        reconTaskTypeId: '216286d5-08b6-4363-a2dc-2a0cdde71ffe',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 22,
    isDefault: false
  },
  {
    id: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
    name: 'Vendor Dent Repair',
    assignees: [
      {
        reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 23,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 10,
      items: [
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          createdOn: '2021-09-06T10:33:53.527114Z',
          description: 'line 1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 234,
          partsCost: 23,
          totalCost: 257,
          id: '35c592f8-fa37-448e-90bf-dbb0f49c605f'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          createdOn: '2021-09-06T10:33:53.527251Z',
          description: '2',
          laborRate: 0,
          laborTime: 0,
          partsCost: 2,
          totalCost: 2,
          id: '84baee98-b640-4f1f-a8d8-af41ff5d57fe'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          createdOn: '2021-09-06T10:33:53.527306Z',
          description: '3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 23434,
          partsCost: 3432,
          totalCost: 26866,
          id: '6f4ee398-dc04-4584-a82e-656b80a8d70e'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          createdOn: '2021-09-06T10:33:53.52734Z',
          description: 'line 4',
          laborRate: 0,
          laborTime: 0,
          laborCost: 100000,
          totalCost: 100000,
          id: 'a6b9dbe3-6668-4a78-b642-a7b7e9ed07e5'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          createdOn: '2021-09-06T10:33:53.527366Z',
          description: 'line item 5 - line item 6',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          totalCost: 0,
          id: '1785aaa5-06c3-4759-9533-97c66ef84eb6'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          createdOn: '2021-09-06T10:33:53.527395Z',
          description: 'line item 7 for testing . 242',
          laborRate: 0,
          laborTime: 0,
          totalCost: 200,
          id: '8e531191-bb81-43f8-acd8-cbafde5b99e6'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          preApprovedOn: '2021-09-30T08:47:13.736032Z',
          createdOn: '2021-09-30T08:35:51.065067Z',
          description: 'line item task type 2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 20,
          totalCost: 20,
          id: '7171abbe-0c5e-484d-be62-fae0df21ac23'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          preApprovedOn: '2021-09-30T08:36:10.938988Z',
          createdOn: '2021-09-30T08:36:10.938986Z',
          description: 'line item task type 1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 20,
          totalCost: 20,
          id: 'aa571e0f-7fce-41f3-980f-e010acf81ff3'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: true,
          preApprovedOn: '2021-09-30T08:37:30.768091Z',
          createdOn: '2021-09-30T08:37:30.76809Z',
          description: 'LI test 1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10,
          partsCost: 0,
          totalCost: 10,
          id: '9895fe5e-c4ed-48f9-9ff3-dfa8587d7896'
        },
        {
          reconTaskTypeId: 'b2e32bdb-9d75-4e61-ae7d-8ce3a0d9e9d1',
          preApprove: false,
          createdOn: '2021-09-30T08:37:45.281023Z',
          description: 'LI test 1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10,
          partsCost: 0,
          totalCost: 10,
          id: '1f745599-88c3-49b1-b9dd-d8f3d5724af3'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'e43123a9-23a6-4e87-9d28-161efce3ea5f',
    name: 'Manheim - Detail',
    assignees: [
      {
        reconTaskTypeId: 'e43123a9-23a6-4e87-9d28-161efce3ea5f',
        userId: '95d6b9c1-4fe5-4302-9b99-734478242eb4',
        userName: 'dwinchester',
        userFullName: 'Dan Winchester',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P2D',
    maxThreshold: 'P2DT10H',
    sequence: 24,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: 'e43123a9-23a6-4e87-9d28-161efce3ea5f',
          preApprove: true,
          createdOn: '2021-08-19T20:18:12.446532Z',
          description: 'UpdateTT',
          laborRate: 45,
          laborTime: 0,
          laborCost: 0,
          partsCost: 50,
          totalCost: 95,
          id: '055e7476-7b93-413b-9a05-111703d998f8'
        },
        {
          reconTaskTypeId: 'e43123a9-23a6-4e87-9d28-161efce3ea5f',
          preApprove: true,
          createdOn: '2021-09-10T03:21:33.028972Z',
          description: 'DemoKeys',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 450,
          totalCost: 450,
          id: 'c0b260bf-719b-43ae-ab4e-e0887e10cf2d'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '66743a5c-85d6-4307-8757-7ab13eacc484',
    name: 'VendorDentRepait860166086',
    assignees: [
      {
        reconTaskTypeId: '66743a5c-85d6-4307-8757-7ab13eacc484',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 25,
    isDefault: false
  },
  {
    id: '87da9e23-4fd5-4759-a6ef-935fef8b0b12',
    name: 'Manheim - Reconditioning',
    assignees: [
      {
        reconTaskTypeId: '87da9e23-4fd5-4759-a6ef-935fef8b0b12',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 26,
    isDefault: false
  },
  {
    id: 'b66a26e3-0d7d-46e0-b6b8-fdf7847bf7dd',
    name: 'Manheim - Imaging',
    assignees: [
      {
        reconTaskTypeId: 'b66a26e3-0d7d-46e0-b6b8-fdf7847bf7dd',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 27,
    isDefault: false
  },
  {
    id: '173e204a-e987-447f-8dce-b37b1eb2f24a',
    name: 'Manheim - Quality Control 1',
    assignees: [
      {
        reconTaskTypeId: '173e204a-e987-447f-8dce-b37b1eb2f24a',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 200000,
    description: 'Test345678',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 28,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: '173e204a-e987-447f-8dce-b37b1eb2f24a',
          preApprove: false,
          createdOn: '2021-09-01T06:30:25.151798Z',
          description: 'T2',
          laborRate: 0,
          laborTime: 0,
          id: 'b1f8bafd-b02d-4dc2-a231-604356450d20'
        },
        {
          reconTaskTypeId: '173e204a-e987-447f-8dce-b37b1eb2f24a',
          preApprove: true,
          createdOn: '2021-09-01T06:28:00.665472Z',
          description: 'T1',
          laborRate: 0,
          laborTime: 0,
          totalCost: 10,
          id: 'aeddf1b0-e6e6-4702-8064-459216bcaec4'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'eed1eb71-3d7c-4c98-b37d-ce27ab35e8f6',
    name: 'Manheim - Audit 1',
    assignees: [
      {
        reconTaskTypeId: 'eed1eb71-3d7c-4c98-b37d-ce27ab35e8f6',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 80,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 29,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: 'eed1eb71-3d7c-4c98-b37d-ce27ab35e8f6',
          preApprove: false,
          createdOn: '2021-09-29T09:15:08.368384Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 10,
          totalCost: 10,
          id: 'dca636f4-0989-4a1f-90f5-b2a58e4cfbdc'
        },
        {
          reconTaskTypeId: 'eed1eb71-3d7c-4c98-b37d-ce27ab35e8f6',
          preApprove: true,
          preApprovedOn: '2021-09-29T09:15:08.36854Z',
          createdOn: '2021-09-29T09:15:08.368538Z',
          description: 'L2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 20,
          totalCost: 20,
          id: 'ee688a52-b81a-441b-b42d-92b3cd4a6834'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '5eccd630-6a70-4594-9097-c868f1dae3ea',
    name: 'RegressionTestTask',
    assignees: [
      {
        reconTaskTypeId: '5eccd630-6a70-4594-9097-c868f1dae3ea',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    cost: 99,
    description: 'For Release Testing',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1DT2H',
    maxThreshold: 'P5DT5H',
    sequence: 30,
    isDefault: false
  },
  {
    id: '3421675d-ecfa-4549-be29-d4a663790249',
    name: 'tungtt31 test 2',
    assignees: [
      {
        reconTaskTypeId: '3421675d-ecfa-4549-be29-d4a663790249',
        userId: '2d7b3b14-b88b-4ba6-804f-949417a6982f',
        userName: 'aglovermeade',
        userFullName: 'Adam Glover-Meade',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 31,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 4,
      items: [
        {
          reconTaskTypeId: '3421675d-ecfa-4549-be29-d4a663790249',
          preApprove: true,
          preApprovedOn: '2021-11-15T07:15:02.768155Z',
          createdOn: '2021-09-01T07:34:23.545147Z',
          description: '12',
          laborRate: 0,
          laborTime: 0,
          partsCost: 12,
          totalCost: 12,
          id: '16e46334-bce9-43a5-9a81-3d18a1334159'
        },
        {
          reconTaskTypeId: '3421675d-ecfa-4549-be29-d4a663790249',
          preApprove: false,
          createdOn: '2021-09-01T07:34:23.544958Z',
          description: 'ưe',
          laborRate: 0,
          laborTime: 0,
          laborCost: 100000,
          partsCost: 12,
          totalCost: 100012,
          id: '4b071452-3339-4dea-a1dd-3a3cc4f3bc5c'
        },
        {
          reconTaskTypeId: '3421675d-ecfa-4549-be29-d4a663790249',
          preApprove: true,
          preApprovedOn: '2021-11-15T07:15:02.76811Z',
          createdOn: '2021-09-01T07:34:23.545099Z',
          description: '212',
          laborRate: 0,
          laborTime: 0,
          laborCost: 221,
          partsCost: 100000,
          totalCost: 100221,
          id: '38a10f3b-7ca7-4a46-8bd5-abe374e8167d'
        },
        {
          reconTaskTypeId: '3421675d-ecfa-4549-be29-d4a663790249',
          preApprove: false,
          createdOn: '2021-11-15T07:15:02.763328Z',
          description: 'Alignment',
          laborRate: 0,
          laborTime: 0,
          laborCost: 1800,
          totalCost: 1800,
          id: 'a4a54f5c-e52f-4036-85b1-1ecc1b6fffa3'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'fd76535b-a78b-4fbe-b3c4-e7d856fa008f',
    name: 'CPO Service Inspection',
    assignees: [
      {
        reconTaskTypeId: 'fd76535b-a78b-4fbe-b3c4-e7d856fa008f',
        userId: 'ecdbecee-4f5e-4706-8da5-ae2edb6478bc',
        userName: 'mboyd0',
        userFullName: 'Michael Boyd',
        priority: 1
      }
    ],
    cost: 700,
    description: 'Used Vehicle Inspection',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P2D',
    maxThreshold: 'P3D',
    sequence: 32,
    isDefault: false
  },
  {
    id: '4eca0205-a3e7-4761-bf21-a29cee0eb453',
    name: "HN's Task",
    assignees: [
      {
        reconTaskTypeId: '4eca0205-a3e7-4761-bf21-a29cee0eb453',
        userId: '36cbb005-5009-48c7-9ea1-e4b101c06eac',
        userName: 'rkumarkotikala',
        userFullName: 'Ravi KumarKotikala',
        priority: 1
      }
    ],
    cost: 200000,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 33,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: '4eca0205-a3e7-4761-bf21-a29cee0eb453',
          preApprove: false,
          createdOn: '2021-09-06T07:37:21.734862Z',
          description: 'T1 test',
          laborRate: 0,
          laborTime: 0,
          totalCost: 100,
          id: '71149d66-93ba-4953-800c-9cfa62d2d836'
        },
        {
          reconTaskTypeId: '4eca0205-a3e7-4761-bf21-a29cee0eb453',
          preApprove: true,
          preApprovedOn: '2022-03-11T02:46:56.128555Z',
          createdOn: '2021-10-18T13:48:25.914349Z',
          description: 'T2 test ',
          laborRate: 0,
          laborTime: 0,
          totalCost: 200,
          id: '31aea740-e3bd-4d89-acab-a5d5f7227fe6'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '33b8bff4-23c3-4e9f-8fbb-5dc98335f2d9',
    name: "HD's Task Temp v2",
    assignees: [
      {
        reconTaskTypeId: '33b8bff4-23c3-4e9f-8fbb-5dc98335f2d9',
        userId: '68292f3d-633a-4ee9-aa8d-d9be37220135',
        userName: 'extech',
        userFullName: 'External Tech',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 34,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 4,
      items: [
        {
          reconTaskTypeId: '33b8bff4-23c3-4e9f-8fbb-5dc98335f2d9',
          preApprove: false,
          createdOn: '2021-09-13T02:48:40.213287Z',
          description: 'line item 3',
          laborRate: 0,
          laborTime: 0,
          id: '1024d3f6-cb66-4114-93f7-4c6dbdebf8bb'
        },
        {
          reconTaskTypeId: '33b8bff4-23c3-4e9f-8fbb-5dc98335f2d9',
          preApprove: true,
          createdOn: '2021-09-13T02:48:40.213256Z',
          description: 'line item 2',
          laborRate: 0,
          laborTime: 0,
          totalCost: 200000,
          id: '049c246d-7dd9-44cd-ad6c-6d46cfb26f95'
        },
        {
          reconTaskTypeId: '33b8bff4-23c3-4e9f-8fbb-5dc98335f2d9',
          preApprove: true,
          createdOn: '2021-09-13T02:48:40.213312Z',
          description: 'line item 4',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          totalCost: 0,
          id: '20db380d-5c39-42df-ae6e-c36de7db0c3f'
        },
        {
          reconTaskTypeId: '33b8bff4-23c3-4e9f-8fbb-5dc98335f2d9',
          preApprove: false,
          createdOn: '2021-09-13T02:48:40.213154Z',
          description: 'line item 1 - Line Item 1 .',
          laborRate: 0,
          laborTime: 0,
          partsCost: 242,
          totalCost: 242,
          id: 'd0909691-69b5-4773-a34f-a19ed30e93bb'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
    name: "HD's Task Temp v1",
    assignees: [
      {
        reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 35,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 6,
      items: [
        {
          reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
          preApprove: true,
          createdOn: '2021-09-10T06:39:13.607192Z',
          description: 'Line item 1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 234,
          totalCost: 234,
          id: '34f7b54c-eff8-4140-908a-ae1dd9f215b8'
        },
        {
          reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
          preApprove: true,
          createdOn: '2021-09-10T06:39:13.607292Z',
          description: 'Line item 2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 100000,
          partsCost: 2342,
          totalCost: 102342,
          id: '61211556-6444-49e0-abb8-a53038daafd7'
        },
        {
          reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
          preApprove: true,
          createdOn: '2021-09-10T06:39:13.607338Z',
          description: 'line item 3',
          laborRate: 0,
          laborTime: 0,
          totalCost: 0,
          id: 'a8af76b3-cfb3-4f9d-a49c-3a71625e905c'
        },
        {
          reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
          preApprove: false,
          createdOn: '2021-09-12T05:52:51.920493Z',
          description: 'line item 6',
          laborRate: 0,
          laborTime: 0,
          id: 'efe208d3-8e6d-4dc5-964b-67cbf85bba8d'
        },
        {
          reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
          preApprove: true,
          createdOn: '2021-09-12T05:52:51.920326Z',
          description: 'line item 4 ',
          laborRate: 0,
          laborTime: 0,
          laborCost: 345,
          partsCost: 3,
          totalCost: 348,
          id: '26144782-689c-4de7-8409-08fd08e94192'
        },
        {
          reconTaskTypeId: '1b3cebb1-d3f2-4b34-bd27-e20d02f8b37e',
          preApprove: true,
          createdOn: '2021-09-12T05:52:51.920462Z',
          description: 'line item 5',
          laborRate: 0,
          laborTime: 0,
          laborCost: 7,
          partsCost: 1000,
          totalCost: 1007,
          id: 'edbc81f3-2282-4817-88ee-4b809ab680a9'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
    name: "HD's Task Template",
    assignees: [
      {
        reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
        userId: 'ebb9c3fa-8d3e-4316-9c89-451d2461b5e0',
        userName: 'dahy',
        userFullName: 'Huyen Dao',
        priority: 1
      }
    ],
    cost: 10000,
    description: 'Create a single Task ',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P2DT10H',
    maxThreshold: 'P10D',
    sequence: 36,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 7,
      items: [
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: false,
          createdOn: '2021-09-01T06:43:04.394891Z',
          description: 'line item 2',
          laborRate: 0,
          laborTime: 0,
          id: '8b866dd1-3929-4489-87a0-7ceeab174692'
        },
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: true,
          createdOn: '2021-09-01T06:44:18.701931Z',
          description: 'line item 3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10000,
          partsCost: 234,
          totalCost: 10234,
          id: '99b2745d-8aee-4713-b198-621f71d33e72'
        },
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: true,
          createdOn: '2021-09-01T06:44:18.702034Z',
          description: 'line item 4',
          laborRate: 0,
          laborTime: 0,
          laborCost: 100000,
          totalCost: 100000,
          id: '276d4a8b-5005-435c-b61b-c509626e1310'
        },
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: true,
          createdOn: '2021-09-01T06:44:18.702078Z',
          description: 'line item 5',
          laborRate: 0,
          laborTime: 0,
          partsCost: 0,
          totalCost: 0,
          id: '95f77f71-5484-46cc-8a63-a66f1d10de5d'
        },
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: true,
          createdOn: '2021-09-01T06:44:18.702118Z',
          description: 'line item 6',
          laborRate: 0,
          laborTime: 0,
          laborCost: 33333,
          totalCost: 33333,
          id: '5262b72d-d1b4-44db-9ac2-cd589287ee5f'
        },
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: true,
          createdOn: '2021-09-01T06:44:18.702156Z',
          description: 'line item 7',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10000,
          partsCost: 3333,
          totalCost: 13333,
          id: '6b007991-f315-45ea-9521-a25f333cad46'
        },
        {
          reconTaskTypeId: 'a3da2c51-6ad1-459a-be97-3f5980c6923c',
          preApprove: false,
          createdOn: '2021-09-01T06:42:19.100156Z',
          description: 'line item 1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 234,
          partsCost: 234,
          totalCost: 468,
          id: '489d1f32-465c-4f77-b641-1f77fd850cc1'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '96683022-2c81-4a39-a48e-620c19537f80',
    name: "Brian Serrato's looking task",
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P2D',
    maxThreshold: 'P3D',
    sequence: 37,
    isDefault: false
  },
  {
    id: 'e90da42f-3df7-4459-bc29-8200b00b45ac',
    name: 'Wheel Repair',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 38,
    isDefault: true
  },
  {
    id: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
    name: 'Paint123',
    assignees: [
      {
        reconTaskTypeId: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
        userId: '18f499a1-f647-4cf6-bfb7-b70b7612e504',
        userName: 'bglover2',
        userFullName: 'Brandon Glover',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P3D',
    maxThreshold: 'P5D',
    sequence: 39,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 5,
      items: [
        {
          reconTaskTypeId: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
          preApprove: false,
          createdOn: '2021-10-04T09:04:22.311524Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 10000,
          totalCost: 10000,
          id: 'ac5925d2-9042-4162-9b09-4d9f53f2ead5'
        },
        {
          reconTaskTypeId: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
          preApprove: false,
          createdOn: '2021-10-04T09:04:22.311628Z',
          description: 'L2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 12300,
          totalCost: 12300,
          id: '0d1f8160-211d-4c39-b5e3-861563d5abda'
        },
        {
          reconTaskTypeId: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
          preApprove: false,
          createdOn: '2021-10-04T09:04:22.311659Z',
          description: 'L4',
          laborRate: 0,
          laborTime: 0,
          laborCost: 50000,
          partsCost: 12500,
          totalCost: 62500,
          id: '16cc340b-19e7-4f2e-927b-6fb923c0e151'
        },
        {
          reconTaskTypeId: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
          preApprove: false,
          createdOn: '2021-10-04T09:04:22.311685Z',
          description: 'L5',
          laborRate: 0,
          laborTime: 0,
          totalCost: 34444,
          id: 'd008b96e-9953-4f55-b5c1-c6a3a85de14e'
        },
        {
          reconTaskTypeId: '9eb30b28-fe10-4adc-b551-2630fbd98dc6',
          preApprove: false,
          createdOn: '2021-10-04T09:04:22.311712Z',
          description: 'L6',
          laborRate: 0,
          laborTime: 0,
          laborCost: 99000,
          totalCost: 99000,
          id: '1f6dc920-7533-4fd7-b95b-ad2ba720b504'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'cc695378-9a07-410b-8944-d53c67652f2f',
    name: 'In Transit',
    assignees: [
      {
        reconTaskTypeId: 'cc695378-9a07-410b-8944-d53c67652f2f',
        userId: '18f499a1-f647-4cf6-bfb7-b70b7612e504',
        userName: 'bglover2',
        userFullName: 'Brandon Glover',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P2D',
    maxThreshold: 'P4D',
    sequence: 40,
    isDefault: false
  },
  {
    id: '61565b9b-2e9c-4436-b670-f79c31106051',
    name: 'Final Walkaround',
    assignees: [
      {
        reconTaskTypeId: '61565b9b-2e9c-4436-b670-f79c31106051',
        userId: '18f499a1-f647-4cf6-bfb7-b70b7612e504',
        userName: 'bglover2',
        userFullName: 'Brandon Glover',
        priority: 1
      }
    ],
    description: 'Final inspection - review all steps were performed',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT2H',
    maxThreshold: 'PT2H',
    sequence: 41,
    isDefault: false
  },
  {
    id: 'fbfc4af0-7c95-423e-9f28-9c69ab618357',
    name: 'BonettPaint',
    assignees: [
      {
        reconTaskTypeId: 'fbfc4af0-7c95-423e-9f28-9c69ab618357',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 42,
    isDefault: false
  },
  {
    id: '591c0d0a-5a9c-40b4-952b-eb555fce49e9',
    name: 'Odor Removal',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 43,
    isDefault: true
  },
  {
    id: '3b7c0467-a0a2-4405-9ead-9c2d4f2b2ea4',
    name: 'Paint121',
    assignees: [],
    cost: 11,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 44,
    isDefault: false
  },
  {
    id: 'f4b47c36-a650-4b8f-a219-2ae4cc6b2eb6',
    name: 'Inspection',
    assignees: [
      {
        reconTaskTypeId: 'f4b47c36-a650-4b8f-a219-2ae4cc6b2eb6',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P1D',
    sequence: 45,
    isDefault: true
  },
  {
    id: 'e95f72d7-85d6-4c35-9046-53c0d3894f2e',
    name: 'Touch Up',
    assignees: [
      {
        reconTaskTypeId: 'e95f72d7-85d6-4c35-9046-53c0d3894f2e',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 295,
    description: 'THIS IS A COMMON TASK, THEN PLEASE DO NOT CHANGE IT NAME.',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT1H',
    sequence: 46,
    isDefault: true
  },
  {
    id: '5864a3f2-4d3a-418f-bc8e-01d07a71d96d',
    name: 'Parts',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'P5D',
    sequence: 47,
    isDefault: true
  },
  {
    id: '3d83605f-d9fa-4a39-af98-644e759818f9',
    name: 'Aftermarket',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT3H',
    maxThreshold: 'P7D',
    sequence: 48,
    isDefault: true
  },
  {
    id: '540bd6fb-8e42-448b-8d92-e9e19579ffe6',
    name: 'Photos',
    assignees: [
      {
        reconTaskTypeId: '540bd6fb-8e42-448b-8d92-e9e19579ffe6',
        assignedToGroupId: 'd476cb8d-5b06-4479-bfa2-e44368f151a9',
        assignedToGroupName: 'DoReMi Group Test 2',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT3H',
    maxThreshold: 'P3D',
    sequence: 49,
    isDefault: true
  },
  {
    id: 'd6424ea1-766b-4092-9731-88d8f71d15d5',
    name: 'Pictures-Window Stickers',
    assignees: [
      {
        reconTaskTypeId: 'd6424ea1-766b-4092-9731-88d8f71d15d5',
        userId: '18f499a1-f647-4cf6-bfb7-b70b7612e504',
        userName: 'bglover2',
        userFullName: 'Brandon Glover',
        priority: 1
      }
    ],
    cost: 36,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P1D',
    sequence: 50,
    isDefault: false
  },
  {
    id: '9ab3c5cb-2943-4ead-98d2-bff69e7cb544',
    name: 'Recall',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 51,
    isDefault: true
  },
  {
    id: 'f68a2afa-2f4e-475f-864c-555ed2febda2',
    name: 'Detail',
    assignees: [
      {
        reconTaskTypeId: 'f68a2afa-2f4e-475f-864c-555ed2febda2',
        userId: '3f10794a-386b-4329-9b72-cd82fe3ebec3',
        userName: 'ekelley2',
        userFullName: 'Erin Kelley',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P2DT16H',
    maxThreshold: 'P5DT20H',
    sequence: 52,
    isDefault: true,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: 'f68a2afa-2f4e-475f-864c-555ed2febda2',
          preApprove: false,
          createdOn: '2021-09-07T15:42:34.603935Z',
          description: 'Test Detail LIne Item',
          laborRate: 0,
          laborTime: 0,
          partsCost: 50,
          totalCost: 50,
          id: 'e58f8534-dc2f-4e57-b7fa-27963c4ed259'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '79858eef-ca28-431e-893d-e38a8f601f57',
    name: 'Carpet Repair',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 53,
    isDefault: true
  },
  {
    id: '0d00b0c1-2e25-4af0-8a4e-3b7228f9394b',
    name: 'Paint Repair',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 54,
    isDefault: true
  },
  {
    id: '6ad9c7a9-7444-4414-96da-29a5ad8e274a',
    name: 'Windshield Repair/Replace',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 55,
    isDefault: true
  },
  {
    id: 'd80689f2-3d7b-4053-bf54-b21e67afb766',
    name: 'Ding Removal',
    assignees: [
      {
        reconTaskTypeId: 'd80689f2-3d7b-4053-bf54-b21e67afb766',
        userId: 'c4bf2b99-2ede-4561-a99e-58ce86ca26bd',
        userName: 'dbhattacharjee',
        userFullName: 'Dipankar Bhattacharjee',
        priority: 1
      }
    ],
    cost: 70,
    description: 'Let me test you',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P2D',
    sequence: 56,
    isDefault: true
  },
  {
    id: '98ce1850-5fba-419d-8c26-2f05add9264b',
    name: 'Lot Tech',
    assignees: [
      {
        reconTaskTypeId: '98ce1850-5fba-419d-8c26-2f05add9264b',
        userId: '3f10794a-386b-4329-9b72-cd82fe3ebec3',
        userName: 'ekelley2',
        userFullName: 'Erin Kelley',
        priority: 1
      }
    ],
    cost: 100,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P1D',
    sequence: 57,
    isDefault: true
  },
  {
    id: 'ef6aa7f3-a3ad-41a7-aa1c-071d9d1a258f',
    name: 'Burnells Delivery Clean',
    assignees: [
      {
        reconTaskTypeId: 'ef6aa7f3-a3ad-41a7-aa1c-071d9d1a258f',
        userId: 'b63b5ddc-467d-4bbc-98dd-a76dbb59cde4',
        userName: 'Brandon Test',
        userFullName: 'Brandon Test',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT23H',
    maxThreshold: 'P10DT2H',
    sequence: 58,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 3,
      items: [
        {
          reconTaskTypeId: 'ef6aa7f3-a3ad-41a7-aa1c-071d9d1a258f',
          preApprove: true,
          createdOn: '2021-08-23T15:41:34.58398Z',
          description: 'TestPatchLTT2Add',
          laborRate: 45,
          laborTime: 0,
          laborCost: 0,
          partsCost: 0,
          totalCost: 40,
          id: 'ba8d5dc5-8df4-4811-9491-23d55ca536f6'
        },
        {
          reconTaskTypeId: 'ef6aa7f3-a3ad-41a7-aa1c-071d9d1a258f',
          preApprove: true,
          createdOn: '2021-08-26T15:09:06.567785Z',
          description: 'dENTPAINT',
          laborRate: 50,
          laborTime: 0,
          laborCost: 0,
          partsCost: 0,
          totalCost: 50,
          id: '351fa018-9d6d-43b0-ba70-b138f33c4fab'
        },
        {
          reconTaskTypeId: 'ef6aa7f3-a3ad-41a7-aa1c-071d9d1a258f',
          preApprove: true,
          preApprovedOn: '2021-10-06T18:11:49.775204Z',
          createdOn: '2021-10-06T18:11:49.774835Z',
          description: 'paint',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 0,
          totalCost: 0,
          id: '568e70b4-c406-49ff-9575-63fa1dc0c2a4'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '9316dae1-8d23-4700-ab8a-d7e9300a73b5',
    name: "Yen's Photo Task",
    assignees: [
      {
        reconTaskTypeId: '9316dae1-8d23-4700-ab8a-d7e9300a73b5',
        userId: '1ec854cb-4c78-499a-9fa2-085a4f839353',
        userName: 'yhainguyen',
        userFullName: 'Yen HaiNguyen',
        priority: 1
      }
    ],
    cost: 111,
    description: 'testing task by Yen Nguyen',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT2H',
    maxThreshold: 'P1D',
    sequence: 59,
    isDefault: false
  },
  {
    id: 'e29eb83f-2e08-4867-a5ed-f9156794a37e',
    name: 'Interior Repair',
    assignees: [
      {
        reconTaskTypeId: 'e29eb83f-2e08-4867-a5ed-f9156794a37e',
        userId: '18f499a1-f647-4cf6-bfb7-b70b7612e504',
        userName: 'bglover2',
        userFullName: 'Brandon Glover',
        priority: 1
      }
    ],
    cost: 50,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'P7D',
    sequence: 60,
    isDefault: true
  },
  {
    id: '16ebbef6-a904-49fc-bcbb-b8970a28051a',
    name: 'UCI Detail',
    assignees: [
      {
        reconTaskTypeId: '16ebbef6-a904-49fc-bcbb-b8970a28051a',
        userId: 'eaa81dfd-424b-49e2-bcd4-d9583f08867d',
        userName: 'adamirecon1',
        userFullName: 'Adam iRecon',
        priority: 1
      }
    ],
    cost: 125,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P1DT12H',
    sequence: 61,
    isDefault: false
  },
  {
    id: '2f568090-d63e-4521-81f8-8588426218ed',
    name: 'Photos/Window Stickers',
    assignees: [
      {
        reconTaskTypeId: '2f568090-d63e-4521-81f8-8588426218ed',
        userId: 'eaa81dfd-424b-49e2-bcd4-d9583f08867d',
        userName: 'adamirecon1',
        userFullName: 'Adam iRecon',
        priority: 1
      }
    ],
    cost: 35,
    description: 'FULL shoot (24 pics) and Window Stickers',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT3H',
    maxThreshold: 'PT4H',
    sequence: 62,
    isDefault: false
  },
  {
    id: 'f6d5705e-6515-407d-9cf4-ca1b1d22872c',
    name: 'Vijay Test Task1',
    assignees: [
      {
        reconTaskTypeId: 'f6d5705e-6515-407d-9cf4-ca1b1d22872c',
        userId: 'e3992013-79dc-4798-b749-1f4f1cf054d3',
        userName: 'vayyadurai',
        userFullName: 'Vijayakumar Ayyadurai',
        priority: 1
      }
    ],
    cost: 25,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 63,
    isDefault: false
  },
  {
    id: '11b32d93-87bc-423d-a815-b57bc6e1b65a',
    name: 'Vijay Test Task2',
    assignees: [
      {
        reconTaskTypeId: '11b32d93-87bc-423d-a815-b57bc6e1b65a',
        userId: 'e3992013-79dc-4798-b749-1f4f1cf054d3',
        userName: 'vayyadurai',
        userFullName: 'Vijayakumar Ayyadurai',
        priority: 1
      }
    ],
    cost: 30,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 64,
    isDefault: false
  },
  {
    id: '31e95a40-1462-4b50-aa2c-32b9d5b7d8f6',
    name: 'Task has 4 LIs',
    assignees: [
      {
        reconTaskTypeId: '31e95a40-1462-4b50-aa2c-32b9d5b7d8f6',
        userId: '87ae74bb-33d3-4e23-b87d-31703b0bc80c',
        userName: 'reconautomanager',
        userFullName: 'reconauto manager',
        priority: 1
      }
    ],
    cost: 1,
    description: 'Demo with LI',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 65,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 4,
      items: [
        {
          reconTaskTypeId: '31e95a40-1462-4b50-aa2c-32b9d5b7d8f6',
          preApprove: false,
          createdOn: '2021-09-13T10:45:48.93707Z',
          description: 'Demo with LI 1',
          laborRate: 0,
          laborTime: 0,
          totalCost: 1,
          id: '0d36022b-6bbb-4d25-8a7c-0029716901bc'
        },
        {
          reconTaskTypeId: '31e95a40-1462-4b50-aa2c-32b9d5b7d8f6',
          preApprove: false,
          createdOn: '2021-09-13T10:45:48.93717Z',
          description: 'Demo with LI 2',
          laborRate: 0,
          laborTime: 0,
          totalCost: 2,
          id: 'ed00fc6b-e397-4018-8ff7-d01086d484f7'
        },
        {
          reconTaskTypeId: '31e95a40-1462-4b50-aa2c-32b9d5b7d8f6',
          preApprove: true,
          createdOn: '2021-09-13T10:45:48.9372Z',
          description: 'Demo with LI 3',
          laborRate: 0,
          laborTime: 0,
          totalCost: 3,
          id: '4d0f7db0-0eae-46b8-b544-c4c77ee17d98'
        },
        {
          reconTaskTypeId: '31e95a40-1462-4b50-aa2c-32b9d5b7d8f6',
          preApprove: true,
          createdOn: '2021-09-13T10:45:48.937225Z',
          description: 'Demo with LI 4',
          laborRate: 0,
          laborTime: 0,
          totalCost: 4,
          id: '8795a911-fdc8-40fc-a3d4-30805019d47f'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'e26dd415-0767-45fe-9091-d096d309afaa',
    name: 'Nathan Task',
    assignees: [
      {
        reconTaskTypeId: 'e26dd415-0767-45fe-9091-d096d309afaa',
        assignedToGroupId: '4b46671b-502d-4c40-afea-e928ea7033ba',
        assignedToGroupName: 'Nathan Group',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT2H',
    sequence: 66,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 3,
      items: [
        {
          reconTaskTypeId: 'e26dd415-0767-45fe-9091-d096d309afaa',
          preApprove: false,
          createdOn: '2021-09-20T20:14:30.352927Z',
          description: 'Line Item 1',
          laborRate: 0,
          laborTime: 0,
          id: '670f75c5-b1ea-445a-a6f8-94a8553e78f3'
        },
        {
          reconTaskTypeId: 'e26dd415-0767-45fe-9091-d096d309afaa',
          preApprove: false,
          createdOn: '2021-09-20T20:14:30.353075Z',
          description: 'Line Item 2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 10,
          partsCost: 10,
          totalCost: 20,
          id: 'ef47e9d2-a864-4b8e-9d40-ce953ac8d954'
        },
        {
          reconTaskTypeId: 'e26dd415-0767-45fe-9091-d096d309afaa',
          preApprove: true,
          createdOn: '2021-09-20T20:14:30.353137Z',
          description: 'Line Item 3',
          laborRate: 0,
          laborTime: 0,
          totalCost: 50,
          id: '89acbff3-8a9c-48e4-87e6-1d96071c0a9b'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '7a2e86bb-d429-47af-8868-4bd26a94b309',
    name: 'task test template',
    assignees: [
      {
        reconTaskTypeId: '7a2e86bb-d429-47af-8868-4bd26a94b309',
        userId: 'ed94f627-391b-4c0c-a7a9-24e59e188bb7',
        userName: 'vvando',
        userFullName: 'Viet Van Do',
        priority: 1
      }
    ],
    cost: 40,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 67,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 3,
      items: [
        {
          reconTaskTypeId: '7a2e86bb-d429-47af-8868-4bd26a94b309',
          preApprove: false,
          createdOn: '2021-09-21T03:25:45.798885Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 1,
          partsCost: 1,
          totalCost: 2,
          id: 'c7b18c14-ec94-4c38-9764-1e676e64d3b1'
        },
        {
          reconTaskTypeId: '7a2e86bb-d429-47af-8868-4bd26a94b309',
          preApprove: true,
          preApprovedOn: '2021-09-29T09:39:31.502423Z',
          createdOn: '2021-09-29T09:35:26.372768Z',
          description: 'L3',
          laborRate: 0,
          laborTime: 0,
          partsCost: 20,
          totalCost: 20,
          id: '8921abed-8cd2-4bb5-b0dd-787dbfe7c5bb'
        },
        {
          reconTaskTypeId: '7a2e86bb-d429-47af-8868-4bd26a94b309',
          preApprove: false,
          createdOn: '2021-09-21T03:25:45.798988Z',
          description: 'L2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 20,
          partsCost: 2,
          totalCost: 22,
          id: '4c9974f6-69be-40bb-8882-133dc4194609'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'd37c674f-bb57-4d8e-8185-12bf47b22489',
    name: 'task test template 2',
    assignees: [
      {
        reconTaskTypeId: 'd37c674f-bb57-4d8e-8185-12bf47b22489',
        userId: '7a27a8b9-f09b-43c1-b66e-a60838f6c840',
        userName: 'sdinhtran',
        userFullName: 'Sang DinhTran',
        priority: 1
      }
    ],
    cost: 99,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 68,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 4,
      items: [
        {
          reconTaskTypeId: 'd37c674f-bb57-4d8e-8185-12bf47b22489',
          preApprove: false,
          createdOn: '2021-09-21T03:26:45.294238Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 20,
          totalCost: 20,
          id: '6ff4d29a-2a8a-4ffe-a9e2-d6e7635a4ab5'
        },
        {
          reconTaskTypeId: 'd37c674f-bb57-4d8e-8185-12bf47b22489',
          preApprove: false,
          createdOn: '2021-09-21T03:26:45.294338Z',
          description: 'L2',
          laborRate: 0,
          laborTime: 0,
          partsCost: 10,
          totalCost: 10,
          id: '7c98fee6-f88c-473f-bc7d-13ed75f41a00'
        },
        {
          reconTaskTypeId: 'd37c674f-bb57-4d8e-8185-12bf47b22489',
          preApprove: true,
          createdOn: '2021-09-21T03:26:45.294382Z',
          description: 'L3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 22,
          partsCost: 1,
          totalCost: 23,
          id: '449cd2d5-cf0c-425f-9de1-d70b86d23bb6'
        },
        {
          reconTaskTypeId: 'd37c674f-bb57-4d8e-8185-12bf47b22489',
          preApprove: true,
          createdOn: '2021-09-21T03:26:45.294422Z',
          description: 'L4',
          laborRate: 0,
          laborTime: 0,
          laborCost: 100,
          partsCost: 3,
          totalCost: 103,
          id: 'c6f343ce-7088-429b-9727-0f13db35e10b'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'b88f1dc6-a0dd-4173-9d19-8a84cb885957',
    name: 'Task template add line item',
    assignees: [
      {
        reconTaskTypeId: 'b88f1dc6-a0dd-4173-9d19-8a84cb885957',
        userId: '94ed9c09-6e73-4b1c-a58e-d744dcdccf81',
        userName: 'hductran',
        userFullName: 'Hiep Duc Tran',
        priority: 1
      }
    ],
    cost: 908,
    description: 'test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P2D',
    sequence: 69,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 3,
      items: [
        {
          reconTaskTypeId: 'b88f1dc6-a0dd-4173-9d19-8a84cb885957',
          preApprove: false,
          createdOn: '2021-09-29T08:47:44.381199Z',
          description: 'Line item 1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 12,
          totalCost: 12,
          id: '804ccd39-cb50-4525-b8c0-6bdcd08a4635'
        },
        {
          reconTaskTypeId: 'b88f1dc6-a0dd-4173-9d19-8a84cb885957',
          preApprove: false,
          createdOn: '2021-09-29T08:49:43.309677Z',
          description: 'line item 3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 10,
          totalCost: 10,
          id: '1d5eb6c8-d05c-4193-a52c-4e1026627a82'
        },
        {
          reconTaskTypeId: 'b88f1dc6-a0dd-4173-9d19-8a84cb885957',
          preApprove: false,
          createdOn: '2021-09-29T08:49:45.732516Z',
          description: 'line item 3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 10,
          totalCost: 10,
          id: '488320d8-8722-4505-99de-018622994d09'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'b0fac5da-8d93-44f4-8fa8-f49b8cc86e67',
    name: "HD's Task Temp v3",
    assignees: [
      {
        reconTaskTypeId: 'b0fac5da-8d93-44f4-8fa8-f49b8cc86e67',
        userId: '2780273c-2978-4705-88db-a1c6a412f518',
        userName: 'atuanho',
        userFullName: 'Anh TuaHo',
        priority: 1
      }
    ],
    cost: 12,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 70,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 4,
      items: [
        {
          reconTaskTypeId: 'b0fac5da-8d93-44f4-8fa8-f49b8cc86e67',
          preApprove: false,
          createdOn: '2021-09-29T07:25:18.048826Z',
          description: 'L2',
          laborRate: 0,
          laborTime: 0,
          totalCost: 0,
          id: '1e7ae9f2-cfe1-4b54-a8f8-dda7b40caf3d'
        },
        {
          reconTaskTypeId: 'b0fac5da-8d93-44f4-8fa8-f49b8cc86e67',
          preApprove: false,
          createdOn: '2021-09-29T07:25:18.033289Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          id: '6ece729d-55be-464d-9dd8-94949a5ff8d3'
        },
        {
          reconTaskTypeId: 'b0fac5da-8d93-44f4-8fa8-f49b8cc86e67',
          preApprove: true,
          preApprovedOn: '2021-09-29T07:25:18.048931Z',
          createdOn: '2021-09-29T07:25:18.048929Z',
          description: 'L3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 100000,
          totalCost: 100000,
          id: '21c88186-005e-4f1c-9fb3-1050e98a52e6'
        },
        {
          reconTaskTypeId: 'b0fac5da-8d93-44f4-8fa8-f49b8cc86e67',
          preApprove: false,
          createdOn: '2021-11-01T08:51:26.193291Z',
          description: 'L4',
          laborRate: 0,
          laborTime: 0,
          laborCost: 60000,
          totalCost: 60000,
          id: '40127e53-da15-4c77-9093-f628cd276cf0'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '53138fbf-949f-421a-83b3-a1fa64561b65',
    name: 'Task template Demo',
    assignees: [
      {
        reconTaskTypeId: '53138fbf-949f-421a-83b3-a1fa64561b65',
        userId: '85099009-a88a-4e11-bd51-89c8b9d6452a',
        userName: 'archerirecon',
        userFullName: 'Archer Irecon',
        priority: 1
      }
    ],
    cost: 234,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 71,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 5,
      items: [
        {
          reconTaskTypeId: '53138fbf-949f-421a-83b3-a1fa64561b65',
          preApprove: true,
          preApprovedOn: '2021-10-05T03:17:05.817429Z',
          createdOn: '2021-10-05T03:17:05.817429Z',
          description: 'Line item approved',
          laborRate: 0,
          laborTime: 0,
          partsCost: 890,
          totalCost: 890,
          id: '8e6ce4fc-6847-44be-afc0-4d639e3ca827'
        },
        {
          reconTaskTypeId: '53138fbf-949f-421a-83b3-a1fa64561b65',
          preApprove: true,
          preApprovedOn: '2021-10-19T09:41:20.572662Z',
          createdOn: '2021-10-05T03:17:05.8174Z',
          description: 'Item 2',
          laborRate: 0,
          laborTime: 0,
          laborCost: 46,
          partsCost: 45,
          totalCost: 91,
          id: '0d3df8dc-b71c-4ebc-a248-6e012062c243'
        },
        {
          reconTaskTypeId: '53138fbf-949f-421a-83b3-a1fa64561b65',
          preApprove: true,
          preApprovedOn: '2021-10-19T09:41:20.572524Z',
          createdOn: '2021-10-05T03:17:05.817303Z',
          description: 'Item 1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 167,
          partsCost: 14,
          totalCost: 181,
          id: '343e6f46-c921-4470-9526-e340f10b1e55'
        },
        {
          reconTaskTypeId: '53138fbf-949f-421a-83b3-a1fa64561b65',
          preApprove: false,
          createdOn: '2021-10-19T09:41:39.025058Z',
          description: 'L1',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 123,
          totalCost: 123,
          id: 'e28ac6b7-736a-4da7-8fcf-bb9444a6cd6c'
        },
        {
          reconTaskTypeId: '53138fbf-949f-421a-83b3-a1fa64561b65',
          preApprove: false,
          createdOn: '2021-10-19T09:41:39.025172Z',
          description: 'L2',
          laborRate: 0,
          laborTime: 0,
          id: 'da3eaee6-e3f9-487b-840e-be5cdb0d5bb9'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '78895fe9-bada-47f0-b544-26fba7e99b58',
    name: 'test task template',
    assignees: [
      {
        reconTaskTypeId: '78895fe9-bada-47f0-b544-26fba7e99b58',
        userId: 'ed94f627-391b-4c0c-a7a9-24e59e188bb7',
        userName: 'vvando',
        userFullName: 'Viet Van Do',
        assignedToGroupId: '58e69f44-074e-4cbd-b4a8-88944b447826',
        assignedToGroupName: 'NguNT group test',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    cost: 23,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 72,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: '78895fe9-bada-47f0-b544-26fba7e99b58',
          preApprove: true,
          preApprovedOn: '2021-12-20T10:30:42.454473Z',
          createdOn: '2021-12-20T10:30:42.454471Z',
          description: 'Battery Replacement',
          laborRate: 0,
          laborTime: 0,
          laborCost: 1,
          partsCost: 100000,
          totalCost: 100001,
          id: '10d6cd9c-ea59-49fc-a37d-21cefe71a488'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '3145a945-231f-438c-bbe5-3b607e228c68',
    name: 'Advisors',
    assignees: [
      {
        reconTaskTypeId: '3145a945-231f-438c-bbe5-3b607e228c68',
        userId: 'd92b4cc0-6f33-4a7e-aaf4-40bdf5bf13a1',
        userName: 'ahassan3',
        userFullName: 'Ahmed Hassan',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 73,
    isDefault: false
  },
  {
    id: '6b1a022f-ac6d-4dcb-a2ba-2c6f45cebfdf',
    name: 'Approval',
    assignees: [
      {
        reconTaskTypeId: '6b1a022f-ac6d-4dcb-a2ba-2c6f45cebfdf',
        userId: '78a3c0cd-d553-e495-e053-34d6e50a23e6',
        userName: 'huyendao',
        userFullName: 'Huyen Dao',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 74,
    isDefault: false
  },
  {
    id: '21813198-0668-4462-92a7-6eb0bc495296',
    name: 'Repair',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 75,
    isDefault: false
  },
  {
    id: '642729e1-deda-4888-815c-465f21c03520',
    name: 'Task demo',
    assignees: [
      {
        reconTaskTypeId: '642729e1-deda-4888-815c-465f21c03520',
        userId: 'eaa81dfd-424b-49e2-bcd4-d9583f08867d',
        userName: 'adamirecon1',
        userFullName: 'Adam iRecon',
        priority: 1
      }
    ],
    cost: 211,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 76,
    isDefault: false
  },
  {
    id: '8768ea9b-3794-42af-8ac0-abb11a22bd55',
    name: 'Windshield Repair',
    assignees: [
      {
        reconTaskTypeId: '8768ea9b-3794-42af-8ac0-abb11a22bd55',
        userId: 'a8c8f283-3117-43ac-be75-cc231f8cb10b',
        userName: 'tonydinicola',
        userFullName: 'Tony DiNicola',
        priority: 1
      }
    ],
    cost: 75,
    description: 'Up to 2 stars.\nNo cracks.',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT2H',
    sequence: 77,
    isDefault: false
  },
  {
    id: '1ab5ab8a-9085-45d3-bd30-5e0bb807a7ac',
    name: 'Vasken Template',
    assignees: [
      {
        reconTaskTypeId: '1ab5ab8a-9085-45d3-bd30-5e0bb807a7ac',
        userId: '0d117541-ea1d-4fc0-add0-b91be18bbf70',
        userName: 'vsayer',
        userFullName: 'Vasken Sayer',
        assignedToGroupId: '56e2c5d5-b178-41b4-83f2-59f4f044d284',
        assignedToGroupName: 'Vasken Test Group',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    cost: 100,
    description: 'Vasken description',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 78,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: '1ab5ab8a-9085-45d3-bd30-5e0bb807a7ac',
          preApprove: false,
          createdOn: '2022-02-17T22:20:24.540881Z',
          description: 'Fix the thing',
          laborRate: 0,
          laborTime: 0,
          totalCost: 50,
          id: '80e404b3-fdca-42a8-93bb-fd0095ba8491'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '944a89cf-f6f3-4fb6-bb50-c652d6b58934',
    name: 'Test TD 021722',
    assignees: [
      {
        reconTaskTypeId: '944a89cf-f6f3-4fb6-bb50-c652d6b58934',
        userId: 'a8c8f283-3117-43ac-be75-cc231f8cb10b',
        userName: 'tonydinicola',
        userFullName: 'Tony DiNicola',
        priority: 1
      }
    ],
    description: 'Test TD 021722',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT1H',
    sequence: 79,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: '944a89cf-f6f3-4fb6-bb50-c652d6b58934',
          preApprove: false,
          createdOn: '2022-02-17T23:36:30.167137Z',
          description: 'line item Test TD 021722',
          laborRate: 0,
          laborTime: 0,
          laborCost: 50,
          totalCost: 50,
          id: 'b32d3ce8-b456-4caf-a4b8-8ae546c00786'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '7602948a-1c95-4d1e-b3a3-0792e109df24',
    name: 'Archer Group',
    assignees: [
      {
        reconTaskTypeId: '7602948a-1c95-4d1e-b3a3-0792e109df24',
        userId: '85099009-a88a-4e11-bd51-89c8b9d6452a',
        userName: 'archerirecon',
        userFullName: 'Archer Irecon',
        assignedToGroupId: '39c75c09-92d3-438c-9357-21340364041e',
        assignedToGroupName: 'Archer Group 2',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 80,
    isDefault: false
  },
  {
    id: '50e888b8-ad85-449e-9dd5-161842f52d9e',
    name: 'Task Template',
    assignees: [
      {
        reconTaskTypeId: '50e888b8-ad85-449e-9dd5-161842f52d9e',
        userId: 'ebb9c3fa-8d3e-4316-9c89-451d2461b5e0',
        userName: 'dahy',
        userFullName: 'Huyen Dao',
        priority: 1
      }
    ],
    cost: 12345,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P1D',
    maxThreshold: 'P7D',
    sequence: 81,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: '50e888b8-ad85-449e-9dd5-161842f52d9e',
          preApprove: false,
          createdOn: '2022-02-22T13:44:10.407419Z',
          description: 'Diagnostic - Check Engine Light',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 0,
          totalCost: 0,
          id: 'd5efab0f-9137-4c85-8f20-7993ada20f9f'
        },
        {
          reconTaskTypeId: '50e888b8-ad85-449e-9dd5-161842f52d9e',
          preApprove: false,
          createdOn: '2022-02-22T13:44:10.407603Z',
          description: 'Engine Tune Up',
          laborRate: 0,
          laborTime: 0,
          totalCost: 90,
          id: 'c4adee5e-fe0f-4378-b49f-4f16caed36a8'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '67d20934-f9ae-44b5-9a92-0cff0972a6f4',
    name: 'Archer Task template',
    assignees: [
      {
        reconTaskTypeId: '67d20934-f9ae-44b5-9a92-0cff0972a6f4',
        userId: '85099009-a88a-4e11-bd51-89c8b9d6452a',
        userName: 'archerirecon',
        userFullName: 'Archer Irecon',
        priority: 1
      }
    ],
    description: 'test description',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 83,
    isDefault: false
  },
  {
    id: '2bf44caf-227e-4b04-a7ca-5048b1474760',
    name: 'New Task for testing',
    assignees: [],
    description: 'test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: false,
    goalThreshold: 'PT1H',
    maxThreshold: 'P7D',
    sequence: 84,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 1,
      items: [
        {
          reconTaskTypeId: '2bf44caf-227e-4b04-a7ca-5048b1474760',
          preApprove: false,
          createdOn: '2022-04-19T07:33:51.434484Z',
          description: 'Battery Replacement Test',
          laborRate: 0,
          laborTime: 0,
          laborCost: 6.99,
          partsCost: 100,
          totalCost: 106.99,
          id: 'b3ae19fa-45ca-4549-b559-f8125d388c1e'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'a2aa1597-c099-4a87-878d-55e33f26a101',
    name: 'VendorDentRepait1435994622',
    assignees: [
      {
        reconTaskTypeId: 'a2aa1597-c099-4a87-878d-55e33f26a101',
        userId: 'eaa81dfd-424b-49e2-bcd4-d9583f08867d',
        userName: 'adamirecon1',
        userFullName: 'Adam iRecon',
        assignedToGroupId: '5acc3702-7140-4aad-8fec-c72233c03a16',
        assignedToGroupName: 'Archer',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 0,
    isDefault: false
  },
  {
    id: '9a0f6b12-25d2-4b59-84b1-29af0013f306',
    name: 'VendorDentRepait1179932012',
    assignees: [
      {
        reconTaskTypeId: '9a0f6b12-25d2-4b59-84b1-29af0013f306',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 0,
    isDefault: false
  },
  {
    id: '33e9c0e3-e030-4814-b8ed-a487ed4ecf02',
    name: 'PaintTest1043200843',
    assignees: [
      {
        reconTaskTypeId: '33e9c0e3-e030-4814-b8ed-a487ed4ecf02',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 0,
    isDefault: false
  },
  {
    id: '67c0bb93-713a-4ead-a73a-46c04c17b8a5',
    name: 'VendorDentRepait183389071',
    assignees: [
      {
        reconTaskTypeId: '67c0bb93-713a-4ead-a73a-46c04c17b8a5',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 200000,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 2,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 2,
      items: [
        {
          reconTaskTypeId: '67c0bb93-713a-4ead-a73a-46c04c17b8a5',
          preApprove: false,
          createdOn: '2021-11-15T06:52:20.804193Z',
          description: 'a line item',
          laborRate: 0,
          laborTime: 0,
          id: '95f5acaf-c1ed-4d23-89aa-99c19995ad51'
        },
        {
          reconTaskTypeId: '67c0bb93-713a-4ead-a73a-46c04c17b8a5',
          preApprove: false,
          createdOn: '2021-11-15T06:52:20.817081Z',
          description: 'Line item 1',
          laborRate: 0,
          laborTime: 0,
          partsCost: 1,
          totalCost: 1,
          id: '537b2947-cae5-4bf7-a8c7-38dbf9759705'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '814b0906-08da-48de-bb5b-52152b2a341f',
    name: 'VendorDentRepait615059395',
    assignees: [
      {
        reconTaskTypeId: '814b0906-08da-48de-bb5b-52152b2a341f',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 5,
    isDefault: false
  },
  {
    id: 'a14967a2-7f7c-46cf-a717-4446d871d372',
    name: 'Vendor DentRepait43434343434',
    assignees: [
      {
        reconTaskTypeId: 'a14967a2-7f7c-46cf-a717-4446d871d372',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 100,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 6,
    isDefault: false
  },
  {
    id: '288486d1-a36e-4147-b7c3-659e94942217',
    name: 'VendorDentRepait1418386248',
    assignees: [
      {
        reconTaskTypeId: '288486d1-a36e-4147-b7c3-659e94942217',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 7,
    isDefault: false
  },
  {
    id: '7580cea7-0592-4f19-b402-84f0ade94455',
    name: 'VendorDentRepait21791127',
    assignees: [
      {
        reconTaskTypeId: '7580cea7-0592-4f19-b402-84f0ade94455',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 8,
    isDefault: false
  },
  {
    id: '7e533e8f-61b7-4343-a13e-38e8efca1240',
    name: 'Vendor DentRepait1301919131',
    assignees: [
      {
        reconTaskTypeId: '7e533e8f-61b7-4343-a13e-38e8efca1240',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 10000,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 9,
    isDefault: false
  },
  {
    id: '3a9cdb1e-deea-41bd-8b8b-cbea54108758',
    name: 'VendorDentRepait1576442128',
    assignees: [
      {
        reconTaskTypeId: '3a9cdb1e-deea-41bd-8b8b-cbea54108758',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 900,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 10,
    isDefault: false
  },
  {
    id: '6208b3e1-8109-4951-bb1b-14eee5ebe669',
    name: 'VendorDentRepait1223764981',
    assignees: [
      {
        reconTaskTypeId: '6208b3e1-8109-4951-bb1b-14eee5ebe669',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 11,
    isDefault: false
  },
  {
    id: '1cb2ac96-3c8d-4e50-bbde-78417d84343e',
    name: 'VendorDentRepait1954022999',
    assignees: [
      {
        reconTaskTypeId: '1cb2ac96-3c8d-4e50-bbde-78417d84343e',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 12,
    isDefault: false
  },
  {
    id: '8389730e-31ac-4e06-9550-29d8e9594629',
    name: 'VendorDentRepait2080849288',
    assignees: [
      {
        reconTaskTypeId: '8389730e-31ac-4e06-9550-29d8e9594629',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 13,
    isDefault: false
  },
  {
    id: '7461e29d-f1a1-4446-a089-ee65407b9e80',
    name: 'VendorDentRepait302155683',
    assignees: [
      {
        reconTaskTypeId: '7461e29d-f1a1-4446-a089-ee65407b9e80',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 15,
    isDefault: false
  },
  {
    id: 'cd1a3aae-ddf1-479e-8a8c-725ea9003140',
    name: 'VendorDentRepait291183524',
    assignees: [
      {
        reconTaskTypeId: 'cd1a3aae-ddf1-479e-8a8c-725ea9003140',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 18,
    isDefault: false
  },
  {
    id: '5cafbdba-5f97-4f8b-9efa-7f2a8128677a',
    name: 'Detail 2',
    assignees: [],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 20,
    isDefault: false
  },
  {
    id: 'cf3423c7-bc4e-4491-9910-8cd51bb7e829',
    name: 'VendorDentRepait2117172103',
    assignees: [
      {
        reconTaskTypeId: 'cf3423c7-bc4e-4491-9910-8cd51bb7e829',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 21,
    isDefault: false
  },
  {
    id: '56d323c5-21b3-4047-95ad-a1c6bd5c0305',
    name: 'PaintTest640684617',
    assignees: [
      {
        reconTaskTypeId: '56d323c5-21b3-4047-95ad-a1c6bd5c0305',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 23,
    isDefault: false
  },
  {
    id: '82dba6b9-8f8b-43ab-b005-92b01381f528',
    name: 'Task with Pending Items',
    assignees: [
      {
        reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
        userId: '1ec854cb-4c78-499a-9fa2-085a4f839353',
        userName: 'yhainguyen',
        userFullName: 'Yen HaiNguyen',
        priority: 1
      }
    ],
    cost: 198,
    description: 'This is a task template with some needs approval line items ',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT10H',
    sequence: 23,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 7,
      items: [
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-11T21:14:44.267109Z',
          description: '4 Wheel Alignment (1)',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 0,
          totalCost: 0,
          id: '5cfaa861-eb17-4f4b-8c59-4b42e816d568'
        },
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-11T21:14:44.267275Z',
          description: 'Mount/Bal Tires (2)',
          laborRate: 0,
          laborTime: 0,
          laborCost: 120,
          partsCost: 965.99,
          totalCost: 1085.99,
          id: '54239af0-7459-4ba9-ab9e-11594709f321'
        },
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-11T21:14:44.267305Z',
          description: 'Brake Fluid Flush (3)',
          laborRate: 0,
          laborTime: 0,
          laborCost: 52149.95,
          partsCost: 33614.58,
          totalCost: 85764.53,
          id: 'c0185943-95df-4b06-a2c4-4b5d1b506902'
        },
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-11T21:14:44.267332Z',
          description: 'Wiper Inserts (4)',
          laborRate: 0,
          laborTime: 0,
          laborCost: 445.66,
          partsCost: 33.44,
          totalCost: 479.1,
          id: '2e71086d-fdc4-4abe-a62f-02078ae3fcb6'
        },
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-17T01:54:43.884033Z',
          description: 'CPO Inspection (5)',
          laborRate: 0,
          laborTime: 0,
          laborCost: 1299.9,
          partsCost: 199.69,
          totalCost: 1499.59,
          id: '9166221c-1c97-4cd2-b263-5f8b7c45761b'
        },
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-17T01:55:48.84857Z',
          description: 'Item with No Cost (6)',
          laborRate: 0,
          laborTime: 0,
          id: '54c1b477-6e04-46b7-b8d0-21164bdcd0c5'
        },
        {
          reconTaskTypeId: '82dba6b9-8f8b-43ab-b005-92b01381f528',
          preApprove: false,
          createdOn: '2021-09-17T01:55:48.848684Z',
          description: 'Item with No Labor (7)',
          laborRate: 0,
          laborTime: 0,
          partsCost: 0,
          totalCost: 0,
          id: 'b377b9bd-dc39-4ec1-980a-f03b99e2cc9e'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: 'b8c3c648-6261-43ab-b117-644ae698c210',
    name: 'VendorDentRepait1543993056',
    assignees: [
      {
        reconTaskTypeId: 'b8c3c648-6261-43ab-b117-644ae698c210',
        userId: '94ed9c09-6e73-4b1c-a58e-d744dcdccf81',
        userName: 'hductran',
        userFullName: 'Hiep Duc Tran',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 25,
    isDefault: false
  },
  {
    id: '89e6c435-49da-43df-b70b-623aebad91d0',
    name: 'VendorDentRepait670448376',
    assignees: [
      {
        reconTaskTypeId: '89e6c435-49da-43df-b70b-623aebad91d0',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 27,
    isDefault: false
  },
  {
    id: '21c18d9b-4796-4e8f-a9a9-b4918e6cd9e4',
    name: 'VendorDentRepait287506218',
    assignees: [
      {
        reconTaskTypeId: '21c18d9b-4796-4e8f-a9a9-b4918e6cd9e4',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 30,
    isDefault: false
  },
  {
    id: '006d454c-589c-47f2-a3e8-0bdcbf78b972',
    name: 'PDR',
    assignees: [
      {
        reconTaskTypeId: '006d454c-589c-47f2-a3e8-0bdcbf78b972',
        userId: 'ed94f627-391b-4c0c-a7a9-24e59e188bb7',
        userName: 'vvando',
        userFullName: 'Viet Van Do',
        priority: 1
      }
    ],
    cost: 100,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 32,
    isDefault: false
  },
  {
    id: '361364df-b923-42a0-ab60-521dc445262a',
    name: 'RegressionTestTask1',
    assignees: [
      {
        reconTaskTypeId: '361364df-b923-42a0-ab60-521dc445262a',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    cost: 12,
    description: 'For release testing',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 32,
    isDefault: false
  },
  {
    id: '0d3e403a-4a87-46fe-838c-ea438a494464',
    name: 'Regression4',
    assignees: [
      {
        reconTaskTypeId: '0d3e403a-4a87-46fe-838c-ea438a494464',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 33,
    isDefault: false
  },
  {
    id: '97c5c3fb-41d3-4c94-a5bb-a5dcef377786',
    name: 'VendorDentRepait1916902500',
    assignees: [
      {
        reconTaskTypeId: '97c5c3fb-41d3-4c94-a5bb-a5dcef377786',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 0
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 33,
    isDefault: false
  },
  {
    id: 'e12d48af-618d-4166-8492-4e45c1788da2',
    name: 'TaskName1',
    assignees: [
      {
        reconTaskTypeId: 'e12d48af-618d-4166-8492-4e45c1788da2',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 33,
    isDefault: false
  },
  {
    id: 'c0bc5251-6950-4cc7-81f9-2e52adc49bb7',
    name: 'Test a long Task Name',
    assignees: [
      {
        reconTaskTypeId: 'c0bc5251-6950-4cc7-81f9-2e52adc49bb7',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 0,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 34,
    isDefault: false
  },
  {
    id: '450b2371-c605-41cc-995f-2aab3bbe04cd',
    name: 'VendorDentRepait1664350378',
    assignees: [
      {
        reconTaskTypeId: '450b2371-c605-41cc-995f-2aab3bbe04cd',
        userId: '3f10794a-386b-4329-9b72-cd82fe3ebec3',
        userName: 'ekelley2',
        userFullName: 'Erin Kelley',
        priority: 1
      }
    ],
    cost: 90,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 35,
    isDefault: false
  },
  {
    id: '5087b207-3612-45fc-af09-daac3dc59fa7',
    name: 'PaintTest439035813',
    assignees: [
      {
        reconTaskTypeId: '5087b207-3612-45fc-af09-daac3dc59fa7',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 38,
    isDefault: false
  },
  {
    id: '69e26c0b-09dc-4304-80bc-9d5d91e7c10c',
    name: 'PaintTest646979402',
    assignees: [
      {
        reconTaskTypeId: '69e26c0b-09dc-4304-80bc-9d5d91e7c10c',
        userId: '2780273c-2978-4705-88db-a1c6a412f518',
        userName: 'atuanho',
        userFullName: 'Anh TuaHo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 41,
    isDefault: false
  },
  {
    id: 'b00aa7c0-6cfa-4aaa-9ab5-6413193b3e91',
    name: 'PaintTest1691958254',
    assignees: [
      {
        reconTaskTypeId: 'b00aa7c0-6cfa-4aaa-9ab5-6413193b3e91',
        userId: '2780273c-2978-4705-88db-a1c6a412f518',
        userName: 'atuanho',
        userFullName: 'Anh TuaHo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT10H',
    maxThreshold: 'P5DT20H',
    sequence: 45,
    isDefault: false
  },
  {
    id: 'da928f62-0f86-4731-a2f2-557888bbe670',
    name: 'PaintTest1144353232',
    assignees: [
      {
        reconTaskTypeId: 'da928f62-0f86-4731-a2f2-557888bbe670',
        userId: '94ed9c09-6e73-4b1c-a58e-d744dcdccf81',
        userName: 'hductran',
        userFullName: 'Hiep Duc Tran',
        priority: 1
      }
    ],
    cost: 901,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 46,
    isDefault: false
  },
  {
    id: '0398dabb-cc12-43b8-baa3-5af2203a6817',
    name: 'PaintTest1473420102',
    assignees: [
      {
        reconTaskTypeId: '0398dabb-cc12-43b8-baa3-5af2203a6817',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 47,
    isDefault: false
  },
  {
    id: 'a5a58d67-d71c-4e0f-b096-6a602e6d7ac8',
    name: 'PaintTest2024357129',
    assignees: [
      {
        reconTaskTypeId: 'a5a58d67-d71c-4e0f-b096-6a602e6d7ac8',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 47,
    isDefault: false
  },
  {
    id: 'e8ba3c47-2b1d-4ad9-b96e-d3ca9f91e4ea',
    name: 'PaintTest1955056579',
    assignees: [
      {
        reconTaskTypeId: 'e8ba3c47-2b1d-4ad9-b96e-d3ca9f91e4ea',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 51,
    isDefault: false
  },
  {
    id: 'ff632297-8d6e-41bd-8015-c3f350f1dd65',
    name: 'PaintTest1912696433',
    assignees: [
      {
        reconTaskTypeId: 'ff632297-8d6e-41bd-8015-c3f350f1dd65',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        assignedToGroupId: 'a230ec11-ef95-41d8-88b1-327efe70c853',
        assignedToGroupType: 'VENDOR',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 54,
    isDefault: false
  },
  {
    id: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
    name: "TrangNguyen's Task",
    assignees: [
      {
        reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
        userId: 'ff76bff4-7dae-47b2-a177-1c28ade83829',
        userName: 'trangtt',
        userFullName: 'Trang Nguyen',
        priority: 1
      }
    ],
    cost: 1200,
    description: 'test line item',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P12DT12H',
    maxThreshold: 'P13DT12H',
    sequence: 55,
    isDefault: false,
    lineItemTemplateTemplates: {
      count: 6,
      items: [
        {
          reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
          preApprove: true,
          createdOn: '2021-09-10T04:41:33.171676Z',
          description: 'Item 2',
          laborRate: 0,
          laborTime: 0,
          partsCost: 1200,
          totalCost: 1200,
          id: '27af98c7-b9b5-40c1-9bb3-c72431f4788f'
        },
        {
          reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
          preApprove: true,
          createdOn: '2021-09-10T04:41:33.171708Z',
          description: 'item 3',
          laborRate: 0,
          laborTime: 0,
          laborCost: 334,
          partsCost: 5500,
          totalCost: 5834,
          id: '6f2fdd4a-6f2a-4050-8a81-bf663151c3f5'
        },
        {
          reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
          preApprove: false,
          createdOn: '2021-09-17T08:06:07.018392Z',
          description: 'Item 1 without cost',
          laborRate: 0,
          laborTime: 0,
          id: '7e7a41ca-4778-4844-802a-65988db942ca'
        },
        {
          reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
          preApprove: false,
          createdOn: '2021-09-17T08:06:07.032022Z',
          description: 'item 4 ',
          laborRate: 0,
          laborTime: 0,
          totalCost: 0,
          id: '87da3437-7353-4ad5-b904-34db26ae5020'
        },
        {
          reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
          preApprove: false,
          createdOn: '2021-09-17T08:06:07.0321Z',
          description: 'Item 5',
          laborRate: 0,
          laborTime: 0,
          laborCost: 0,
          partsCost: 0,
          totalCost: 0,
          id: '72171fb3-c638-482f-a5aa-f8e2d24e7221'
        },
        {
          reconTaskTypeId: 'aa0f8f36-05df-43ea-bd61-0a8fda4ffcfe',
          preApprove: false,
          createdOn: '2021-09-17T08:06:07.032148Z',
          description: 'item 6',
          laborRate: 0,
          laborTime: 0,
          laborCost: 129,
          partsCost: 123,
          totalCost: 252,
          id: '7dee4192-4b24-4f77-bad6-638cc088653a'
        }
      ],
      limit: 123456789
    }
  },
  {
    id: '6f5f7df3-4355-4ff6-8361-b3816cf1946e',
    name: 'PaintTest673733476',
    assignees: [
      {
        reconTaskTypeId: '6f5f7df3-4355-4ff6-8361-b3816cf1946e',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 56,
    isDefault: false
  },
  {
    id: '2e5ca75b-c94e-4603-9566-f41f0df30bb9',
    name: 'PaintTest1016691104',
    assignees: [
      {
        reconTaskTypeId: '2e5ca75b-c94e-4603-9566-f41f0df30bb9',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 59,
    isDefault: false
  },
  {
    id: '2c9d71bb-fc58-40a3-8943-b901280608ce',
    name: 'PaintTest858498234',
    assignees: [
      {
        reconTaskTypeId: '2c9d71bb-fc58-40a3-8943-b901280608ce',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 60,
    isDefault: false
  },
  {
    id: '27f60a45-4889-4e3e-8d7a-d62bedb993ba',
    name: 'Test API4',
    assignees: [
      {
        reconTaskTypeId: '27f60a45-4889-4e3e-8d7a-d62bedb993ba',
        assignedToGroupId: '47e66466-0ed7-49ab-b8e0-93d95a597edd',
        assignedToGroupName: 'Vendor Test 1',
        assignedToGroupType: 'VENDOR',
        priority: 0
      }
    ],
    cost: 0,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 61,
    isDefault: false
  },
  {
    id: '2ff57ca2-a5d6-40e5-ba72-eff99504bbea',
    name: 'RegressionTestTask',
    assignees: [
      {
        reconTaskTypeId: '2ff57ca2-a5d6-40e5-ba72-eff99504bbea',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    cost: 99,
    description: 'For Release Testing',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P1DT2H',
    maxThreshold: 'P5DT5H',
    sequence: 64,
    isDefault: false
  },
  {
    id: 'e44e64e2-fd47-4b91-95fa-dff4d1aa23c9',
    name: 'Test API task',
    assignees: [],
    cost: 0,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 64,
    isDefault: false
  },
  {
    id: '27d5ce82-84b9-4b07-9b43-3ccb6df2d0c9',
    name: 'Test API task type',
    assignees: [
      {
        reconTaskTypeId: '27d5ce82-84b9-4b07-9b43-3ccb6df2d0c9',
        assignedToGroupId: 'a230ec11-ef95-41d8-88b1-327efe70c853',
        priority: 0
      }
    ],
    cost: 0,
    description: 'Test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT12H',
    maxThreshold: 'P5DT12H',
    sequence: 65,
    isDefault: false
  },
  {
    id: '9c37964a-44ea-45d4-b3e5-d578cb541281',
    name: 'PaintTest1737008549',
    assignees: [
      {
        reconTaskTypeId: '9c37964a-44ea-45d4-b3e5-d578cb541281',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 72,
    isDefault: false
  },
  {
    id: '3b1e867d-6ca0-4cbe-87d4-03d87f613455',
    name: 'PaintTest423106453',
    assignees: [
      {
        reconTaskTypeId: '3b1e867d-6ca0-4cbe-87d4-03d87f613455',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 74,
    isDefault: false
  },
  {
    id: 'da16f049-f928-46ca-ba0d-01181d6c04db',
    name: 'test',
    assignees: [
      {
        reconTaskTypeId: 'da16f049-f928-46ca-ba0d-01181d6c04db',
        userId: 'ad33ebf0-65b4-44db-8fd9-28c0ff3dcb5c',
        userName: 'pverma',
        userFullName: 'Pavan Verma',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT2H',
    sequence: 79,
    isDefault: false
  },
  {
    id: '7f3f74e8-80da-497c-bd7e-9d760cbce72a',
    name: 'PaintTest198030730',
    assignees: [
      {
        reconTaskTypeId: '7f3f74e8-80da-497c-bd7e-9d760cbce72a',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 80,
    isDefault: false
  },
  {
    id: 'b0753294-98f0-4bfb-9853-9f1e04172f42',
    name: 'Test - HF',
    assignees: [
      {
        reconTaskTypeId: 'b0753294-98f0-4bfb-9853-9f1e04172f42',
        userId: '16f2f67d-e59a-472c-95c0-4ca028b83597',
        userName: 'hfaircloth',
        userFullName: 'Hailey Faircloth',
        priority: 1
      }
    ],
    cost: 5,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P2D',
    maxThreshold: 'P3D',
    sequence: 81,
    isDefault: false
  },
  {
    id: '3c58ab1b-84e6-406b-be93-97a2104ad184',
    name: 'task notification',
    assignees: [
      {
        reconTaskTypeId: '3c58ab1b-84e6-406b-be93-97a2104ad184',
        assignedToGroupId: 'c7dfe32b-ae52-484e-a27e-48135670db3b',
        assignedToGroupName: 'group test notification 1',
        assignedToGroupType: 'DEALER_INTERNAL_GROUP',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 82,
    isDefault: false
  },
  {
    id: '9f9042cf-3d48-4612-a946-93bc5f40f3ad',
    name: 'PaintTest1722355570',
    assignees: [
      {
        reconTaskTypeId: '9f9042cf-3d48-4612-a946-93bc5f40f3ad',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 82,
    isDefault: false
  },
  {
    id: '730e78a7-4141-4311-b8fb-a2501514dcfc',
    name: 'services12382019391',
    assignees: [
      {
        reconTaskTypeId: '730e78a7-4141-4311-b8fb-a2501514dcfc',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 85,
    isDefault: false
  },
  {
    id: 'a1e31485-0758-45e7-8f1d-16bcca9bd6cb',
    name: 'services290560624',
    assignees: [
      {
        reconTaskTypeId: 'a1e31485-0758-45e7-8f1d-16bcca9bd6cb',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 95,
    isDefault: false
  },
  {
    id: 'a2a0fb26-7668-4eac-b0c8-0dd942b64329',
    name: 'test2',
    assignees: [
      {
        reconTaskTypeId: 'a2a0fb26-7668-4eac-b0c8-0dd942b64329',
        userId: 'ad33ebf0-65b4-44db-8fd9-28c0ff3dcb5c',
        userName: 'pverma',
        userFullName: 'Pavan Verma',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT2H',
    sequence: 100,
    isDefault: false
  },
  {
    id: '00084699-3647-4130-afa3-cecf20c62135',
    name: 'test3',
    assignees: [
      {
        reconTaskTypeId: '00084699-3647-4130-afa3-cecf20c62135',
        userId: 'ad33ebf0-65b4-44db-8fd9-28c0ff3dcb5c',
        userName: 'pverma',
        userFullName: 'Pavan Verma',
        priority: 1
      }
    ],
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'PT1H',
    maxThreshold: 'PT2H',
    sequence: 102,
    isDefault: false
  },
  {
    id: 'c043415a-f074-4ceb-8cc2-40e49ec2f2f8',
    name: 'services2116074182',
    assignees: [
      {
        reconTaskTypeId: 'c043415a-f074-4ceb-8cc2-40e49ec2f2f8',
        userId: '30539da1-a0bc-4f40-9ec7-d44c3372e9cd',
        userName: 'reconautomation1',
        userFullName: 'Lindsay Matteo',
        priority: 1
      }
    ],
    cost: 90,
    description: 'string',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5DT19H',
    maxThreshold: 'P5DT20H',
    sequence: 107,
    isDefault: false
  },
  {
    id: '530dc217-6c7d-4d96-8e93-2f112cb42be6',
    name: 'Task For Testing',
    assignees: [
      {
        reconTaskTypeId: '530dc217-6c7d-4d96-8e93-2f112cb42be6',
        userId: '94ed9c09-6e73-4b1c-a58e-d744dcdccf81',
        userName: 'hductran',
        userFullName: 'Hiep Duc Tran',
        priority: 1
      }
    ],
    cost: 150,
    description: 'This task for testing',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 110,
    isDefault: false
  },
  {
    id: 'd4037ee8-732c-4812-a1ea-a8ecd840e03c',
    name: 'Task for DF128510',
    assignees: [
      {
        reconTaskTypeId: 'd4037ee8-732c-4812-a1ea-a8ecd840e03c',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    cost: 155,
    description: 'test',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 116,
    isDefault: false
  },
  {
    id: '734ce941-d766-44e2-bfb6-95a3e68d2a39',
    name: 'Long Task Nameeeeeeeeeeeee 353',
    assignees: [
      {
        reconTaskTypeId: '734ce941-d766-44e2-bfb6-95a3e68d2a39',
        userId: 'f67e597b-bdea-4306-87b9-e6a7456671ac',
        userName: '$SYNC$1$__vayyadurai',
        userFullName: 'Vijayakumar Ayyadurai',
        priority: 1
      }
    ],
    cost: 123,
    description: 'Long Task Nameeeeeeeeeeeee 353',
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P1D',
    maxThreshold: 'P3D',
    sequence: 124,
    isDefault: false
  },
  {
    id: 'c60d61de-66dd-45ca-931e-6938c58afc9f',
    name: 'VendorDentRepait21171721036777',
    assignees: [
      {
        reconTaskTypeId: 'c60d61de-66dd-45ca-931e-6938c58afc9f',
        userId: 'f0a07b37-ce2d-4273-a2a5-3d0af678ec3b',
        userName: 'internaltech',
        userFullName: 'Internal Tech',
        priority: 1
      }
    ],
    cost: 100,
    dealerId: 'bd22986a-f337-4ef8-af46-54f513e88215',
    scheduledForDeletion: true,
    goalThreshold: 'P5D',
    maxThreshold: 'P7D',
    sequence: 136,
    isDefault: false
  }
];
