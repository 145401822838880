import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Typography } from 'antd';
import { apiStatusConstants, detailContents } from 'app-constants';
import { DrawerContainer } from 'components';
import CircularSpinner from 'components/common/CircularSpinner.js';
import { CommonHeaderLabel, CommonRedButton, CommonSearch } from 'components/styledComponents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { messagesActions, messageSelector } from 'store/messagesStore';
import { vendorActions, vendorSearchDataSelector, vendorDealerActions } from 'store/vendorStore';
import styled from 'styled-components';
import { StringParam, useQueryParams } from 'use-query-params';
import { VendorForm, VendorListContent } from './';
import VendorDetails from './vendorDetails';
import NewVendorForm from './NewVendorForm';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const { Text } = Typography;

const vendorDrawerPropsSelector = createSelector(
  (state) => state.users,
  (state) => state.dealers?.current?.data?.id,
  (state) => state.vendors,
  messageSelector,
  vendorSearchDataSelector,
  (state) => state.vendors.vendorDealer,
  (usersContainer, dealerId, vendorStore, message, searchData, vendorDealer) => ({
    users: usersContainer.data,
    usersFetchStatus: usersContainer.fetchStatus,
    searchData: searchData,
    dealerId,
    message,
    isLoading: vendorStore.isLoading,
    vendorDealer: vendorDealer.data
  })
);

const formatPhoneNumber = (phone) => {
  let cleaned = phone.replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phone;
};

const VendorDrawer = ({ selectedCard, setSelectedCard, isNameTaken, isNameAndEmailMatch, isEmailExists, flags }) => {
  //#region Drawer
  const dispatch = useDispatch();
  const { message, searchData, isLoading, dealerId, vendorDealer } = useSelector(vendorDrawerPropsSelector);
  const { searchStatus } = useSelector((state) => state.vendors);
  const [drawerWidth, setDrawerWidth] = useState(450);
  const [onUpdateTaskForm, setOnUpdateTaskForm] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formIsValidated, setFormIsValidated] = useState(false);
  const [taskTypesIsDirty, setTaskTypesIsDirty] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [showInvNewVendor, setShowInvNewVendor] = useState(false);
  const [query, setQuery] = useQueryParams({
    vendorId: StringParam,
    dealerId: StringParam,
    isNotification: StringParam
  });
  const [isReinvite, setIsReinvite] = useState(false);

  useEffect(() => {
    let width = 580;
    setDrawerWidth(width);
    if (selectedCard?.vendor?.id && !isReinvite) setFormIsValidated(false);
    setOnUpdateTaskForm([detailContents.CREATE_EDIT_VENDOR].includes(selectedCard.contentType));
    const initial = {
      name: selectedCard?.vendor?.name,
      contactFirstName: selectedCard?.vendor?.primaryContact?.firstName,
      contactLastName: selectedCard?.vendor?.primaryContact?.firstName,
      email: selectedCard?.vendor?.email,
      phone: selectedCard.vendor ? formatPhoneNumber(selectedCard.vendor?.phone ?? '') : '',
      inviteMessage: selectedCard.vendor ? selectedCard.vendor?.inviteMessage ?? '' : ''
    };
    setInitialValues(initial);
    setFormValues(initial);

    return () => {
      dispatch(vendorActions.setErrorMsgEmailValidate(''));
    };
  }, [selectedCard]);

  useEffect(() => {
    if (message && message.messageType === 'success') {
      dispatch(messagesActions.clear());
      closeDrawer();
    }
  }, [message]);

  useEffect(() => {
    if (isLoading) {
      setIsSaving(true);
    } else {
      setIsSaving(false);
    }
  }, [isLoading]);

  const checkFormIsDirty = (newFormValues) => {
    const values = Object.entries(newFormValues);
    return values && values.length > 0
      ? values.every((x) => {
          return !(['name', 'contactFirstName', 'contactLastName', 'email', 'phone'].includes(x[0]) && !x[1]);
        }) &&
          (values.some((x) => {
            return !!(x[0] !== 'taskTypes' && initialValues[x[0]] !== x[1]);
          }) ||
            taskTypesIsDirty)
      : false;
  };

  useEffect(() => {
    const newFormValues = { ...formValues };
    setFormIsValidated(checkFormIsDirty(newFormValues));
  }, [taskTypesIsDirty]);
  const onChange = (name) => (value) => {
    const newFormValues = { ...formValues };
    newFormValues[name] = value ?? '';
    setFormValues(newFormValues);

    setFormIsValidated(checkFormIsDirty(newFormValues));
  };

  const onSave = () => {
    let successMessage = `${formValues.name} was invited to create a vendor account`;
    let errorMessage = 'An error occurred while adding the vendor';
    const newVendor = {
      ...selectedCard.vendor,
      name: formValues.name.trim(),
      contactFirstName: formValues.contactFirstName.trim(),
      contactLastName: formValues.contactLastName.trim(),
      email: formValues.email.trim(),
      phone: formValues.phone.trim(),
      inviteMessage: formValues.inviteMessage.trim()
    };

    if (!newVendor.id) {
      //Add a new vendor
      dispatch(vendorActions.add(newVendor, successMessage, errorMessage));
    } else if (isReinvite) {
      successMessage = `Add vendor request has been sent to ${formValues.name}`;
      errorMessage = 'An error occurred while reinviting the vendor';
      dispatch(
        vendorDealerActions.updateVendorDealerInvite(
          selectedCard.vendor.id,
          dealerId,
          {
            vendorId: selectedCard.vendor.id,
            dealerId: dealerId,
            status: 'PENDING',
            message: formValues.inviteMessage.trim(),
            dealerNote: vendorDealer?.dealerNotes,
            email: formValues.email.trim()
          },
          successMessage,
          errorMessage
        )
      );
    } else {
      //Update the vendor
      successMessage = `${newVendor.name} updates saved`;
      errorMessage = 'An error occurred while editing the vendor';
      dispatch(vendorActions.update(newVendor, successMessage, errorMessage));
    }
    setShowInvNewVendor(false);
  };

  const onChangeVendorSearch = (event) => {
    setValueSearch(event.target.value); // value in search input
    if (!event.target.value) {
      setShowInvNewVendor(false);
    }
  };

  const handleCanCloseDrawer = () => {
    if (!selectedCard.model?.saving) {
      if (onUpdateTaskForm && selectedCard.contentType === detailContents.VENDORS) {
        setOnUpdateTaskForm(false);
      } else {
        closeDrawer();
        setShowInvNewVendor(false);
      }
    }
    return true;
  };

  const resetForm = () => {
    setTaskTypesIsDirty(false);
    setFormIsValidated(false);
    setValueSearch('');
    dispatch(vendorActions.setSearchData([]));
  };

  const closeDrawer = () => {
    resetForm();
    setSelectedCard({ contentType: null }); //setting contentType to null will close the drawer
    setIsReinvite(false);
    setShowInvNewVendor(false);
    if (!!query?.vendorId || !!query?.dealerId) {
      setQuery({ vendorId: undefined, dealerId: undefined, isNotification: undefined });
    }
    if (flags.reconVendorWorkflow2023) {
      dispatch(vendorDealerActions.resetVendorDealerDetails());
      dispatch(vendorActions.setIsLoading(false));
    }
  };

  const handleAddNewVendor = () => {
    setSelectedCard({ contentType: detailContents.CREATE_EDIT_VENDOR });
  };
  const getHeaderLabel = () => {
    if (!selectedCard?.vendor || !selectedCard?.vendor?.id) {
      return 'Invite Vendor';
    } else if (isReinvite) {
      return 'Resend Invite';
    } else {
      return 'Edit Vendor';
    }
  };

  const onSearchVendor = () => {
    if (valueSearch.trim()) {
      dispatch(vendorActions.search(valueSearch.trim()));
      setShowInvNewVendor(true);
    } else {
      dispatch(vendorActions.setSearchData([]));
    }
  };
  //#endregion

  return (
    <Drawer
      closable={false}
      destroyOnClose={true}
      placement="right"
      width={drawerWidth}
      visible={!!selectedCard.contentType}
      onClose={() => handleCanCloseDrawer()}
      bodyStyle={{ padding: '0' }}
    >
      {selectedCard.contentType && (
        <>
          {
            {
              [detailContents.VENDORS]: (
                <DrawerContainer
                  headerLabel={<CommonHeaderLabel>Add Vendor</CommonHeaderLabel>}
                  onClose={() => closeDrawer()}
                >
                  <DrawerDescription>Search to add a vendor</DrawerDescription>
                  <StyledSearchContainer>
                    <StyledSearchInput
                      data-is-focused={true}
                      placeholder={'Search business name, primary contact or address'}
                      prefix={<FontAwesomeIcon icon={faSearch} />}
                      value={valueSearch}
                      onChange={onChangeVendorSearch}
                      onPressEnter={onSearchVendor}
                    />
                    <StyledSearchButton onClick={onSearchVendor} disabled={!valueSearch.trim()}>
                      Search
                    </StyledSearchButton>
                  </StyledSearchContainer>
                  {searchStatus === apiStatusConstants.IS_FETCHING ? (
                    <StyledSpinnerWrapper>
                      <CircularSpinner size={30} />
                    </StyledSpinnerWrapper>
                  ) : (
                    <React.Fragment>
                      <StyledDrawerContainer>
                        <VendorListContent
                          vendors={searchData || []}
                          valueSearch={valueSearch}
                          showInvNewVendor={showInvNewVendor}
                          isSaving={isSaving}
                          dealerId={dealerId}
                        />
                      </StyledDrawerContainer>
                      {showInvNewVendor && !searchData.length && (
                        <StyledButtonContainer>
                          <StyledInvVendorButton ghost onClick={handleAddNewVendor}>
                            Invite New Vendor
                          </StyledInvVendorButton>
                        </StyledButtonContainer>
                      )}
                      {showInvNewVendor && searchData.length > 0 && (
                        <StyledDrawerFooterContainer>
                          <FooterText>Didn’t find who you’re looking for? Invite them to join iRecon.</FooterText>
                          <StyledButtonContainer>
                            <StyledInvVendorButton ghost onClick={handleAddNewVendor}>
                              Invite New Vendor
                            </StyledInvVendorButton>
                          </StyledButtonContainer>
                        </StyledDrawerFooterContainer>
                      )}
                    </React.Fragment>
                  )}
                </DrawerContainer>
              ),
              [detailContents.CREATE_EDIT_VENDOR]: (
                <DrawerContainer
                  headerLabel={<CommonHeaderLabel>{getHeaderLabel()}</CommonHeaderLabel>}
                  onClose={closeDrawer}
                >
                  {flags.reconVendorWorkflow2023 ? (
                    <NewVendorForm handleCanClose={handleCanCloseDrawer} isReinvite={isReinvite} />
                  ) : (
                    <VendorForm
                      vendor={selectedCard?.vendor}
                      formValues={formValues}
                      isNameTaken={isNameTaken}
                      isEmailExists={isEmailExists}
                      isNameAndEmailMatch={isNameAndEmailMatch}
                      onChange={onChange}
                      isSaving={isSaving}
                      formIsValidated={formIsValidated}
                      onSave={onSave}
                      closeDrawer={closeDrawer}
                      handleCanClose={handleCanCloseDrawer}
                      setIsSaving={setIsSaving}
                      isReinvite={isReinvite}
                    />
                  )}
                </DrawerContainer>
              ), //this is waiting for implementation of creating or editing a vendor
              [detailContents.VIEW_VENDOR]: (
                <DrawerContainer
                  headerLabel={<CommonHeaderLabel>Vendor Details</CommonHeaderLabel>}
                  onClose={closeDrawer}
                >
                  <VendorDetails
                    closeDrawer={closeDrawer}
                    dealerId={dealerId}
                    setSelectedCard={setSelectedCard}
                    setIsReinvite={setIsReinvite}
                  />
                </DrawerContainer>
              )
            }[selectedCard.contentType]
          }
        </>
      )}
    </Drawer>
  );
};

// #region Styled Components
const StyledSearchContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 16px 8px 24px;
`;

const StyledDrawerContainer = styled.div`
  padding: 0 24px;
  margin: auto;
  max-height: calc(100vh - 287px);
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  overflow: auto;
`;

const FooterText = styled(Text)`
  .ant-typography& {
    color: ${({ theme }) => theme.colors.navy};
    font-family: Roboto;
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

const StyledButtonContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const StyledDrawerFooterContainer = styled.div`
  margin: 16px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 548px;
`;

const StyledSpinnerWrapper = styled.div`
  text-align: center;
`;

const DrawerDescription = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
  padding-top: 23px;
`;

const StyledSearchButton = styled(CommonRedButton)`
  margin-left: 8px;
  font-size: ${({ theme }) => theme.fontSizes.md} !important;
`;

const StyledInvVendorButton = styled(CommonRedButton)`
  font-size: ${({ theme }) => theme.fontSizes.md} !important;
`;

const StyledSearchInput = styled(CommonSearch)`
  .ant-input {
    font-size: ${({ theme }) => theme.fontSizes.sm} !important;
  }
  .ant-input-affix-wrapper& {
    margin-left: 0px;
  }
  max-width: 100% !important;
  margin: 0;
`;
// #endregion

export default withLDConsumer()(VendorDrawer);
