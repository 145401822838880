import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

/**
 * Alert component to show alert customize by IRecon
 * @param {Show meesage of the alert} message
 * @param {Function close alert} closeAlert
 * @returns
 */
const AlertIRecon = ({ message, closeAlert }) => {
  return (
    <AlertIReconWrapper>
      <Icon>
        <StyledIcon icon={faExclamationCircle} color={'#ffffff'} />
      </Icon>
      <Content>{message}</Content>
      <CloseButton onClick={closeAlert}>
        <StyledCloseIcon icon={faTimes} color={'#6D727A'} />
      </CloseButton>
    </AlertIReconWrapper>
  );
};

const AlertIReconWrapper = styled.div`
  display: flex;
  height: 42px;
  border: 1px #e4e9ef solid;
  z-index: 999;
  border-radius: 4px;
`;

const Icon = styled.div`
  width: 40px;
  background: #dd2b2b;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

const Content = styled.div`
  background: #ffffff;
  flex: 1;
  align-items: center;
  display: flex;
  padding: 8px;
`;

const CloseButton = styled.div`
  background: #ffffff;
  width: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledCloseIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;
export default AlertIRecon;
