import { useMediaPredicate } from 'react-media-hook';

//gives us ability to capture different screen sizes that can be used in react code
//each variable is boolean. true when the condition is met, false when the condition is not met
export const useScreenSize = () => {
  const max1600 = useMediaPredicate('(max-width: 1600px)');
  const max1500 = useMediaPredicate('(max-width: 1500px)');
  const max1400 = useMediaPredicate('(max-width: 1400px)');
  const max1300 = useMediaPredicate('(max-width: 1300px)');
  const max1150 = useMediaPredicate('(max-width: 1150px)');
  const max1050 = useMediaPredicate('(max-width: 1050px)');
  const max960 = useMediaPredicate('(max-width: 960px)');
  const max850 = useMediaPredicate('(max-width: 850px)');

  const screenSize = (() => {
    if (max850) return '<= 850px';    
    if (max960) return '<= 960px';    
    if (max1050) return '<= 1050px';  
    if (max1150) return '<= 1150px';  
    if (max1300) return '<= 1300px';  
    if (max1400) return '<= 1400px';  
    if (max1500) return '<= 1500px';
    if (max1600) return '<= 1600px';
    if (!max1600) return '> 1600px';
  })();

  return screenSize;
};