import { Row, Modal, Layout, Typography } from 'antd';
import styled from 'styled-components';
import { CommonLinkButton, CommonRedButton } from '../../styledComponents';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

export const StyledButton = styled(CommonRedButton)`
  &.ant-btn {
    font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
    font-size: ${({ theme }) => theme.fontSizes.md} !important;
    line-height: ${({ theme }) => theme.lineHeights.md};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
  }

  &.ant-btn-background-ghost.ant-btn-danger {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  .ant-btn-danger[disabled]& {
    .ant-btn-background-ghost& {
      color: ${({ theme }) => theme.colors.lightRedButton} !important;
      border-color: unset !important;
    }
  }
`;

export const StyledLinkButton = styled(CommonLinkButton)`
  &.ant-btn {
    font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
    font-size: ${({ theme }) => theme.fontSizes.md} !important;
    line-height: ${({ theme }) => theme.lineHeights.md};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
  }
`;

export const StyledLayout = styled(Layout)`
  &.ant-layout {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledLayoutHeader = styled(Header)`
  &.ant-layout-header {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0;
    height: auto;
    line-height: 0;
  }
`;

export const StyledLayoutFooter = styled(Footer)`
  &.ant-layout-footer {
    background-color: ${({ theme }) => theme.colors.white};
    position: sticky;
    padding: 0;
  }
`;

export const StyledLayoutContent = styled(Content)`
  &.ant-layout-content {
    overflow: auto;
    overflowx: hidden;
  }
`;

export const StyledTitleText = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.xxlg};
    line-height: ${({ theme }) => theme.lineHeights.smalltitle};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledSubTitleText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledBoldSubTitleText = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledFormTitle = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.lg};
    line-height: ${({ theme }) => theme.lineHeights.subTitle};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledSignOutText = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.xlg};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledStepText = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.xlg};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const CenteredRow = styled(Row).attrs(({ gutter }) => ({
  type: 'flex',
  justify: 'center',
  gutter: gutter
}))``;

export const StyledModal = styled(Modal)`
  &.ant-modal {
    width: 576px !important;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-height: 722px) {
      top: 2.5vh;
    }

    @media (min-height: 722px) {
      top: calc((100vh - 721px) / 2);
    }
  }

  .ant-modal-content {
    @media (min-height: 722px) {
      height: 721px;
    }
    @media (max-height: 722px) {
      height: 95vh;
    }
  }

  .ant-modal-body {
    height: 100%;
  }
`;
