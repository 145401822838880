import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography, Modal, Input } from 'antd';
import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../styles/appTheme';
import { BodySmallBold } from '../../../styledComponents';
import { currentDealerActions } from 'store/dealersStore';
import { useDispatch, useSelector } from 'react-redux';
import { isPassedNumberFormat } from 'utils/regexUtils';

const { Text } = Typography;

const ApprovalTimeModal = ({ visible, setModalOpen, defaultValue }) => {
  const initialState = { days: 0, hours: 12, minutes: 0, rawTime: 720 };

  const dispatch = useDispatch();
  const dealer = useSelector((state) => state.dealers?.current?.data);

  const MAX = 1000000; // max value for days, hours and minutes is 1000000
  const MAX_RAW_TIME = 1440000000; // max value for raw time (days + hours + minutes) is 1440000000 (1000000 days)

  const timeReducer = (state, action) => {
    switch (action.type) {
      case 'days':
        return { ...state, days: action.payload, rawTime: action.payload * 1440 + state.hours * 60 + state.minutes };
      case 'hours':
        return { ...state, hours: action.payload, rawTime: state.days * 1440 + action.payload * 60 + state.minutes };
      case 'minutes':
        return { ...state, minutes: action.payload, rawTime: state.days * 1440 + state.hours * 60 + action.payload };
      case 'reformat':
        let rawTime = state.rawTime;

        if (rawTime > MAX_RAW_TIME) {
          rawTime = MAX_RAW_TIME;
        }

        const days = Math.floor(rawTime / 1440);
        const hours = Math.floor((rawTime % 1440) / 60);
        const minutes = rawTime - days * 1440 - hours * 60;

        return { days, hours, minutes, rawTime };
      case 'setAll':
        return action.payload;
      default:
        return state;
    }
  };

  const [timeState, timeDispatch] = useReducer(timeReducer, initialState);

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue]);

  const reset = (value) => {
    let rawTime = value;

    const days = Math.floor(rawTime / 1440);
    const hours = Math.floor((rawTime % 1440) / 60);
    const minutes = rawTime - days * 1440 - hours * 60;

    timeDispatch({ type: 'setAll', payload: { days, hours, minutes, rawTime } });
  };

  const onOk = () => {
    const newDealer = {
      ...dealer,
      needApprovalReminderTime: timeState.rawTime
    };
    dispatch(currentDealerActions.updateData(newDealer));
    setModalOpen(false);
  };

  useEffect(() => {
    if (timeState.rawTime >= MAX_RAW_TIME) {
      timeDispatch({ type: 'reformat' });
    }
  }, [timeState.rawTime]);

  const onInputChange = (value, type) => {
    let timeValue = Number(value);

    if (timeValue > MAX) {
      timeValue = MAX;
    }

    if (isPassedNumberFormat(value)) {
      timeDispatch({ type: type, payload: timeValue });
    }
  };

  const reformatType = () => {
    timeDispatch({ type: 'reformat' });
  };

  return (
    <StyledModal
      visible={visible}
      okText={'Save'}
      okType={'danger'}
      cancelButtonProps={{
        style: {
          borderColor: theme.colors.red,
          color: theme.colors.red,
          fontFamily: 'Roboto',
          fontWeight: theme.fontWeights.medium
        }
      }}
      okButtonProps={{
        disabled: timeState.rawTime < 1 || timeState.rawTime === defaultValue
      }}
      width={416}
      onOk={onOk}
      onCancel={() => {
        setModalOpen(false);
        reset(defaultValue);
      }}
      closable={false}
    >
      <Row type="flex" justify="end" align="middle" gutter={[0, 8]}>
        <Col span={2}>
          <FontAwesomeIcon icon={faClock} size="lg" />
        </Col>

        <Col span={22}>
          <Text style={{ fontSize: '16px', fontWeight: theme.fontWeights.medium }}>Max. Approval Time</Text>
        </Col>

        <Col span={22}>
          <Text>Line items should be approved or declined before the max. time is reached.</Text>
        </Col>

        <Col span={22}>
          <Row type="flex" justify="space-between" gutter={8}>
            <Col xs={8}>
              <BodySmallBold>Days</BodySmallBold>
              <StyledInput
                size="large"
                value={timeState.days === 0 ? '' : timeState.days}
                placeholder={0}
                onChange={(event) => onInputChange(event.target.value, 'days')}
                onPressEnter={reformatType}
                onBlur={reformatType}
                error={timeState.rawTime < 1}
              />
            </Col>
            <Col xs={8}>
              <BodySmallBold>Hours</BodySmallBold>
              <StyledInput
                size="large"
                value={timeState.hours === 0 ? '' : timeState.hours}
                placeholder={0}
                onChange={(event) => onInputChange(event.target.value, 'hours')}
                onPressEnter={reformatType}
                onBlur={reformatType}
                error={timeState.rawTime < 1}
                disabled={timeState.rawTime >= MAX_RAW_TIME}
              />
            </Col>
            <Col xs={8}>
              <BodySmallBold>Minutes</BodySmallBold>
              <StyledInput
                size="large"
                placeholder={0}
                value={timeState.minutes === 0 ? '' : timeState.minutes}
                onChange={(event) => onInputChange(event.target.value, 'minutes')}
                onPressEnter={reformatType}
                onBlur={reformatType}
                error={timeState.rawTime < 1}
                disabled={timeState.rawTime >= MAX_RAW_TIME}
              />
            </Col>
          </Row>
        </Col>

        {timeState.rawTime < 1 && (
          <Col span={22}>
            <FontAwesomeIcon icon={faExclamationCircle} color={theme.colors.red} />
            <Text style={{ color: theme.colors.red, marginLeft: '4px' }}>Value must be 1 minute or more.</Text>
          </Col>
        )}
      </Row>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    border-top: 0 none;
    padding-bottom: 20px;
  }
  .ant-modal-footer button + button {
    margin-right: 8px;
  }

  .ant-btn-danger-disabled,
  .ant-btn-danger.disabled,
  .ant-btn-danger[disabled],
  .ant-btn-danger-disabled:hover,
  .ant-btn-danger.disabled:hover,
  .ant-btn-danger[disabled]:hover,
  .ant-btn-danger-disabled:focus,
  .ant-btn-danger.disabled:focus,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger-disabled:active,
  .ant-btn-danger.disabled:active,
  .ant-btn-danger[disabled]:active,
  .ant-btn-danger-disabled.active,
  .ant-btn-danger.disabled.active,
  .ant-btn-danger[disabled].active {
    background-color: ${theme.colors.red};
    opacity: 0.5;
    color: white;
  }
`;

const StyledInput = styled(({ error, ...rest }) => <Input {...rest} />)`
  &.ant-input-lg {
    border: ${(props) =>
      props.error ? `2px solid ${props.theme.colors.red}` : `1px solid ${props.theme.colors.secondaryGrey}`};
    width: 100%;
  }
  &.ant-input-lg:focus {
    border: 1px solid;
  }
  &.ant-input-lg:hover {
    border: 1px solid;
  }
  .ant-input-handler-wrap {
    display: none;
  }
`;

export default ApprovalTimeModal;
