import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';

const text = 'No Dealerships';
const subText = 'You are currently not affiliated with any dealerships. Dealerships that have invited you to work with them will appear here.';

const NoDealerships = () => {
  return (
    <StyledFlexRow>
      <StyledFlexColumn themeStyles={themeStyles}>
        <IconContainer>
          <StyledFontAwesomeIcon />
        </IconContainer>
        <NoDealersText>{text}</NoDealersText>
        <NoDealersSubText>
          {subText}
        </NoDealersSubText>
      </StyledFlexColumn>
    </StyledFlexRow>
  );
};

//#region Styled Components
const IconContainer = styled.div`
  width: 36px;
  height: 32px;
  position: relative;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).attrs({
  icon: faStoreAlt,
  color: themeStyles.colors.darkGray,
  style: {
    fontSize: themeStyles.fontSizes.title,
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)'
  }
})``;

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledFlexColumn = styled.div`
  min-height: calc(100vh - 293px);
  max-width: 520px;
  display: flex;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
  color: ${({ themeStyles }) => themeStyles.colors.darkGray};
`;

const NoDealersText = styled.div.attrs({
  fontSize: themeStyles.fontSizes.md,
  fontWeight: themeStyles.fontWeights.medium,
})`
  margin: 16px 0;
`;

const NoDealersSubText = styled.div.attrs({
  fontSize: themeStyles.fontSizes.md,
  lineHeight: '24px',
})`
  text-align: center;
`;
//#endregion

export default NoDealerships;
