import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowAltCircleDown, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, List, Menu, Modal, Typography } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { confirm } = Modal;
const { Text } = Typography;

const DocumentsDetail = ({
  document,
  deleteDocument,
  downloadDocument,
  hasInventoryEditPermission, // Only Dealer/Admin and ReconManager has this permission
  isLoading
}) => {
  const GetDocumentIconColor = () => {
    const iconColor = themeStyles.colors.navy;
    return iconColor;
  };
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  const moreMenu = (
    <Menu
      onClick={({ key }) => {
        switch (key) {
          case 'download':
            downloadDocument(document.id, document.originalFileName, !document.reconTaskTypeName);
            break;
          case 'delete':
            confirm({
              title: `Are you sure you want to delete this file ?`,
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'Cancel',
              onOk() {
                deleteDocument(document.id);
              },
              onCancel() {
                devLogger.log('Canceled Delete Task');
              }
            });
            break;
          default:
            devLogger.error(new Error(`Unexpected menu key ${key}`));
        }
      }}
    >
      <Menu.Item key="download">
        <MenuItemIcon icon={faArrowAltCircleDown} />
        Download
      </Menu.Item>
      {hasInventoryEditPermission || (isRootUser && vendorShipSelected) ? (
        <Menu.Item key="delete">
          <MenuItemIcon icon={faTrashAlt} />
          Delete
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const GetDocumentIcon = () => {
    var icon = faFileAlt;
    return icon;
  };

  const FormatDateTimeDocument = (author, time, reconTaskTypeName) => {
    return `${reconTaskTypeName ? ' • ' : ''}${author} • ${moment(new Date(time)).format('MM/DD/YYYY, hh:mm A')}`;
  };

  const GetFormatDateTimeDocument = FormatDateTimeDocument(
    document.createdBy,
    document.createdOn,
    document?.reconTaskTypeName
  );
  return (
    <StyledListItem>
      <VehicleDocumentItemContainer>
        <IconContainer>
          <Icon color={GetDocumentIconColor()} icon={GetDocumentIcon()} />
        </IconContainer>
        <DocumentContent>
          <DocumentNameContainer>
            <DocumentName as={'a'} href={document.originalUrl} target={'_blank'} title={document.originalFileName}>
              {document.originalFileName}
            </DocumentName>
            <StyledOverflowMenu>
              <Dropdown overlay={moreMenu} disabled={isLoading}>
                <StyledMoreIcon data-disabled={isLoading} icon={faEllipsisH} />
              </Dropdown>
            </StyledOverflowMenu>
          </DocumentNameContainer>
          <FormatDateContainer>
            {document?.reconTaskTypeName ? (
              <TaskTypeName title={document?.reconTaskTypeName}>{document?.reconTaskTypeName}</TaskTypeName>
            ) : null}
            <DateContainer>{GetFormatDateTimeDocument}</DateContainer>
          </FormatDateContainer>
        </DocumentContent>
      </VehicleDocumentItemContainer>
    </StyledListItem>
  );
};

//#region Styled Components
const VehicleDocumentItemContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 5% 95%;
`;
const IconContainer = styled.div`
  padding-right: 15px;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
const DocumentNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 0;
`;
const DocumentName = styled.a`
  display: inline-block;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  flex: 1 1 0;
  color: ${({ theme }) => theme.colors.navy};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  :hover {
    cursor: ${(props) => (!props['isLoading'] ? 'pointer' : 'inherit')};
    color: rgba(0, 173, 255, 0.54);
  }
  [disabled]& {
    color: ${({ theme }) => theme.colors.navy};
  }
`;
const DocumentContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  white-space: nowrap;
`;
const DateContainer = styled(Text).attrs({
  ellipsis: true
})`
  .ant-typography& {
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.sx};
    white-space: nowrap;
    max-width: calc(100% - 40px);
  }
`;

const TaskTypeName = styled(Text).attrs({
  ellipsis: true
})`
  .ant-typography& {
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.sx};
    white-space: nowrap;
    max-width: 125px;
  }
`;

const FormatDateContainer = styled.div``;
const StyledListItem = styled(List.Item)`
  .ant-list-item& {
    margin: 0px 24px;
    padding: 12px 0px !important;
  }
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props['data-disabled'] ? 'inherit' : 'pointer')};
  color: ${(props) => (props['data-disabled'] ? props.theme.colors.gray : props.theme.colors.navy)};
  height: 20px;
  padding: 0 8px 0 18px;
  .svg-inline--fa.fa-w-16& {
    width: 100%;
  }
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.navy};
`;
const StyledOverflowMenu = styled.div.attrs({
  className: 'center-content'
})`
  width: 40px;
  height: 100%;
  margin-left: auto;
`;
//#endregion

export default withLDConsumer()(DocumentsDetail);
