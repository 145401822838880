import 'antd4/lib/table/style/index.css';
import React from 'react';
import { apiStatusConstants } from 'app-constants';
import { StyledEnterpriseTable, StyledCell, StyledDealerName } from '../common/styles';
import { defaultEnterpriseSorter, noUnsortedDirections, SkeletonEnterpriseTable } from '../common/functions';
import { Error } from '../../../../common';

const SummaryTable = ({ data = [], fetchStatus, refreshAction, onMetricClick, onChange, sort }) => {
  if (fetchStatus === apiStatusConstants.FAILED) {
    return <Error refreshAction={refreshAction} />;
  }

  if (fetchStatus === apiStatusConstants.IS_FETCHING || fetchStatus === apiStatusConstants.PENDING) {
    return <SkeletonEnterpriseTable />;
  }

  return (
    <StyledEnterpriseTable
      rowKey="rowKey"
      pagination={false}
      dataSource={getDataSource(data)}
      columns={getColumnDefinitions(onMetricClick, sort)}
      showSorterTooltip={false}
      onChange={onChange}
    />
  );
};

export default SummaryTable;

/* ~~~~~~~~~~~~~~~~~~~~~ private ~~~~~~~~~~~~~~~~~~~~~ */

const getColumnDefinitions = (onMetricClick, sort) => {
  const columns = [
    {
      title: 'Dealership',
      dataIndex: 'dealerName',
      fixed: 'left',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.dealerName, b.dealerName),
      render: (text) => <StyledDealerName>{text}</StyledDealerName>
    },
    {
      title: 'No Plan',
      dataIndex: 'noPlan',
      className: 'border-left',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.noPlan, b.noPlan),
      render: (text, record) => <StyledCell onClick={() => onMetricClick('No Plan', record)}>{text}</StyledCell>
    },
    {
      title: 'Pending Approval',
      dataIndex: 'needsApproval',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.needsApproval, b.needsApproval),
      render: (text, record) => (
        <StyledCell onClick={() => onMetricClick('Pending Approval', record)}>{text}</StyledCell>
      )
    },
    {
      title: 'Declined Tasks',
      dataIndex: 'declined',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.declined, b.declined),
      render: (text, record) => <StyledCell onClick={() => onMetricClick('Declined Tasks', record)}>{text}</StyledCell>
    },
    {
      title: 'Overdue',
      dataIndex: 'overdue',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.overdue, b.overdue),
      render: (text, record) => <StyledCell onClick={() => onMetricClick('Overdue', record)}>{text}</StyledCell>
    },
    {
      title: 'Recall',
      dataIndex: 'recall',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.recall, b.recall),
      render: (text, record) => <StyledCell onClick={() => onMetricClick('Recall', record)}>{text}</StyledCell>
    },
    {
      title: 'Sold',
      dataIndex: 'sold',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.sold, b.sold),
      render: (text, record) => <StyledCell onClick={() => onMetricClick('Sold', record)}>{text}</StyledCell>
    }
  ];
  columns.forEach((c) => {
    if (c.dataIndex === sort.field) {
      c.defaultSortOrder = sort.order;
    }
  });
  return columns;
};

const getDataSource = (dataList) => {
  return (dataList || []).map((element) => ({
    rowKey: element.dealerId,
    dealerName: element.dealerName,
    ...element.inventory
  }));
};
