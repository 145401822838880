import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useScreenSize } from 'hooks';
import { WIDTH_RECON_PLAN_DRAWERS, detailContents } from 'app-constants';
import { LOCATION_MESSAGE_LINE_ITEM } from 'app-constants/lineItemConstants';
import { createSelector } from 'reselect';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { clone, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DrawerContainer } from 'components';
import { CommonHeaderLabel } from 'components/styledComponents';
import { Drawer } from 'antd';
import { renameKey } from 'utils/objectUtils';
import { planTemplatesActions } from 'store/planTemplatesStore';
import { lineItemsActions } from 'store/lineItemsStore';
import { messageSelector, messagesActions } from 'store/messagesStore';
import { TemplatePlan } from '.';
import { TaskForm } from 'components/layout/inventory/reconPlan';
import { TASK_GROUP } from '../../../TaskGroupsDnd/helpers/types';
import { stripReferences } from 'utils/arrayUtils';
import { VENDOR } from 'app-constants/groupTypes';

const planTemplateDrawerSelector = createSelector(
  (state) => state.dealers.current.data.id,
  messageSelector,
  (dealerId, message) => {
    return {
      dealerId,
      message
    };
  }
);
const PlanTemplateDrawer = ({ planTemplate, lastSequence, isNameTaken, onClose, visible, onDelete, flags }) => {
  const screenSize = useScreenSize();
  const { dealerId, message } = useSelector(planTemplateDrawerSelector);
  const dispatch = useDispatch();

  const [scrollToId, setScrollToId] = useState(null);
  const [formProps, setFormProps] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showDiscardButton, setShowDiscardButton] = useState(false);
  const [templateFormIsDirty, setTemplateFormIsDirty] = useState(false);
  const [templateFormValues, setTemplateFormValues] = useState({});
  const [taskGroups, setTaskGroups] = useState([]);
  const vendors = useSelector((state) => state.users.assigneeData?.vendors);
  const needApprovalList = useSelector((state) => state.lineItems.needApproval);
  const isShowAddLineItem = useSelector((state) => state.lineItems.isShowAddLineItem);

  useEffect(() => {
    if (!planTemplate) {
      return;
    }

    setTemplateFormValues({
      name: planTemplate.name,
      description: planTemplate.description
    });

    if (!planTemplate.templateTaskGroups) {
      return;
    }

    const hasAssociatedVendors = flags.reconVendorManagement && vendors && vendors.length > 0;

    const convertedTaskGroups = convertTaskGroupsFromSpecificToGeneric(planTemplate.templateTaskGroups).map(
      (taskGroup) => {
        if (!hasAssociatedVendors) {
          return taskGroup;
        }

        if (!taskGroup.tasks) {
          return taskGroup;
        }

        const tasks = taskGroup.tasks.map((task) => {
          if (task.assignedToGroupType !== VENDOR) {
            return task;
          }

          if (!flags.reconVendorManagement) {
            return task;
          }

          if (vendors.find((v) => v.id === task.assignedToGroupId)) {
            // default assigned vendor is still associated with dealer
            return task;
          }

          // vendor is no longer associated with dealer
          // remove default assignments
          // TODO : should this be done on the back end instead?
          task.assignedToGroupId = undefined;
          task.assignedToGroupType = undefined;
          task.assignedToGroupName = undefined;

          return task;
        });

        taskGroup.tasks = tasks;
        return taskGroup;
      }
    );

    setTaskGroups(convertedTaskGroups);
  }, [planTemplate, vendors]);

  const removeUnwantedEqualityProps = (taskGroups) => {
    let result = [];
    taskGroups.forEach((taskGroup) => {
      taskGroup.templateTasks.forEach((task) => {
        delete task.lineItemTemplates;
        delete task.taskSequence;
      });
      result.push(taskGroup);
    });
    return result;
  };

  const handleSetTaskGroups = (groups) => {
    const convertedTG = convertTaskGroupsFromGenericToSpecific(groups);
    const finalConvertedTG = removeUnwantedEqualityProps(clone(convertedTG));
    const finalTemplateTaskGroups = removeUnwantedEqualityProps(clone(planTemplate.templateTaskGroups));
    if (planTemplate !== null && !isEqual(finalConvertedTG, finalTemplateTaskGroups)) {
      setTemplateFormIsDirty(true);
    }
    setTaskGroups(groups);
  };

  useEffect(() => {
    // Here I'm latching on to the messages to determine if the save has succeeded or not, which I'm using to determine whether to close the drawer or not.
    // Longer term, latching on to the messages may not be viable if messages start being used more asynchronously
    if (message) {
      const { messageType, options } = message;
      if (messageType === 'loading') {
        setIsSaving(true);
      } else {
        setIsSaving(false);
      }
      if (messageType === 'success') {
        dispatch(messagesActions.clear());
        if (options?.location !== LOCATION_MESSAGE_LINE_ITEM) {
          closeDrawer();
        }
      }
    }
  }, [message]);

  const resetScrollToTask = () => setScrollToId(null);

  const closeForm = () => {
    dispatch(lineItemsActions.resetLineItem());
    setFormProps(null);
  };

  const handleCanClose = () => {
    if (!isSaving) {
      if (!showDiscardButton && needApprovalList.length > 0 && isShowAddLineItem) {
        // When it isDirty and on the first time the user clicks X, we show the discard button
        setShowDiscardButton(true);
        return false;
      }
      if (formProps) {
        closeForm();
      } else if (!showDiscardButton && templateFormIsDirty) {
        // When it isDirty and on the first time the user clicks X, we show the discard button
        setScrollToId(null);
        setShowDiscardButton(true);
      } else {
        // If the user clicks the top right X button again (2 clicks), it will force discard and close
        setTemplateFormIsDirty(false); // reset
        closeDrawer();
      }
    }
  };

  const closeDrawer = () => {
    setShowDiscardButton(false);
    setTemplateFormIsDirty(false);
    dispatch(lineItemsActions.resetLineItem());
    setScrollToId(null);
    setTaskGroups([]);
    onClose();
  };

  const onChangeTemplateMetadata = (name) => (e) => {
    const newFormValues = {
      ...templateFormValues,
      [name]: e.target.value === '' ? null : e.target.value
    };

    setTemplateFormIsDirty(
      planTemplate
        ? Object.entries(newFormValues).some((x) => planTemplate[x[0]] !== x[1])
        : Object.values(newFormValues).some((x) => x)
    );

    setTemplateFormValues(newFormValues);
  };

  const onTaskChangeWithTaskGroups = (type) => (task) => {
    switch (type) {
      case 'add': {
        const newGroupId = uuidv4();
        handleSetTaskGroups([
          ...taskGroups,
          {
            id: newGroupId,
            name: 'Task Group',
            reconPlanTemplateId: planTemplate.id,
            sequence: taskGroups.length + 1,
            modelType: TASK_GROUP,
            tasks: [
              {
                ...task,
                groupSequence: 1,
                taskGroupId: newGroupId
              }
            ]
          }
        ]);
        break;
      }
      case 'update': {
        // Sequence won't have changed - this was triggered from the task's overflow menu
        const indexOfGroup = taskGroups.findIndex((tg) => tg.id === task.taskGroupId);
        const indexOfTask =
          indexOfGroup === -1 ? -1 : taskGroups[indexOfGroup].tasks.findIndex((t) => t.id === task.id);
        if (indexOfTask > -1) {
          /*
           * Strip references so children components will actually detect a change.
           * Otherwise, the references doesn't change and useEffects won't pick up any changes,
           * and children components won't re-render!
           */
          const newGroups = stripReferences(taskGroups);
          const updatedGroup = taskGroups[indexOfGroup] && stripReferences(taskGroups[indexOfGroup]);
          updatedGroup.tasks.splice(indexOfTask, 1, task);
          newGroups[indexOfGroup] = updatedGroup;
          handleSetTaskGroups(newGroups);
        } else if (indexOfTask !== -1) {
          // This is the pre-passthrough logic where we had a bug that updated the task within its group by reference,
          // causing children components to not re-render
          const newGroups = [...taskGroups];
          const updatedGroup = { ...taskGroups[indexOfGroup] };
          updatedGroup.tasks.splice(indexOfTask, 1, task);
          newGroups[indexOfGroup] = updatedGroup;
          handleSetTaskGroups(newGroups);
        }
        break;
      }
      case 'delete': {
        const indexOfGroup = taskGroups.findIndex((tg) => tg.id === task.taskGroupId);
        if (indexOfGroup !== -1) {
          let newGroups = [...taskGroups];
          const groupToUpdate = taskGroups[indexOfGroup];
          const updatedGroup = {
            ...groupToUpdate,
            tasks: groupToUpdate.tasks.filter((t) => t.id !== task.id)
          };
          if (updatedGroup.tasks.length === 0) newGroups.splice(indexOfGroup, 1);
          else newGroups[indexOfGroup] = updatedGroup;

          // Resequence groups and tasks
          let numTasks = 0;
          newGroups = newGroups.map((group, groupIndex) => ({
            ...group,
            sequence: groupIndex + 1,
            tasks: group.tasks.map((task, taskIndex) => ({
              ...task,
              groupSequence: taskIndex + 1,
              sequence: ++numTasks
            }))
          }));

          handleSetTaskGroups(newGroups);
        }
        break;
      }
      default:
        break;
    }
  };

  const onSaveWithTaskGroups = () => {
    const loadingMessage = 'Saving plan template...';
    const successMessage = 'Plan template saved!';
    const errorMessage = 'An error occurred while saving the plan template';

    const newPlanTemplate = {
      ...planTemplate,
      name: templateFormValues.name,
      description: templateFormValues.description,
      templateTaskGroups: makeSureTaskGroupsHaveAllRequiredProperties(
        convertTaskGroupsFromGenericToSpecific(taskGroups),
        planTemplate
      )
    };

    if (!newPlanTemplate.id) {
      newPlanTemplate.dealerId = dealerId;
      newPlanTemplate.sequence = lastSequence + 1;
      dispatch(planTemplatesActions.add(newPlanTemplate, loadingMessage, successMessage, errorMessage));
    } else {
      dispatch(planTemplatesActions.update(newPlanTemplate, loadingMessage, successMessage, errorMessage));
    }
  };

  const getHeaderLabel = () => {
    if (formProps?.isLineItemsTask) return 'Line Items';
    if (formProps) {
      return formProps.taskToUpdate ? 'Update Task' : 'Add Task';
    }
    return !planTemplate || !planTemplate.id ? 'New Plan Template' : 'Edit Plan Template';
  };

  return (
    <Drawer
      closable={false}
      destroyOnClose={true}
      placement="right"
      width={WIDTH_RECON_PLAN_DRAWERS}
      visible={visible}
      onClose={handleCanClose}
      bodyStyle={{ padding: '0' }}
    >
      <DrawerContainer
        headerLabel={<CommonHeaderLabel>{getHeaderLabel()}</CommonHeaderLabel>}
        onClose={handleCanClose}
        showBackButton={!!formProps}
        handleBackButtonClick={() => closeForm()}
        isSaving={isSaving}
      >
        {formProps === null ? (
          <TemplatePlan
            planTemplate={planTemplate}
            templateFormValues={templateFormValues}
            closeDrawer={closeDrawer}
            setFormProps={setFormProps}
            showDiscardButton={showDiscardButton}
            scrollToId={scrollToId}
            handleCanClose={handleCanClose}
            resetScrollToTask={resetScrollToTask}
            isSaving={isSaving}
            onSave={onSaveWithTaskGroups}
            onTaskChange={onTaskChangeWithTaskGroups}
            onChangeTemplateMetadata={onChangeTemplateMetadata}
            templateFormIsDirty={templateFormIsDirty}
            isNameTaken={isNameTaken}
            onDelete={onDelete}
            taskGroups={taskGroups}
            setTaskGroups={handleSetTaskGroups}
          />
        ) : (
          <TaskForm
            parentId={formProps.parentId}
            taskToUpdate={formProps.taskToUpdate}
            closeForm={closeForm}
            contentType={detailContents.PLAN_TEMPLATES}
            setScrollToId={setScrollToId}
            onCreate={formProps.onCreate}
            onUpdate={formProps.onUpdate}
            onDelete={formProps.onDelete}
            assignedToOptional={true}
            isLineItemsTask={formProps.isLineItemsTask}
            setFormProps={setFormProps}
            setTemplateFormIsDirty={setTemplateFormIsDirty}
            formHeight={screenSize === '<= 1400px' ? 'calc(100vh - 161px)' : 'calc(100vh - 137px)'}
            onTaskChange={onTaskChangeWithTaskGroups}
            taskGroups={taskGroups}
          />
        )}
      </DrawerContainer>
    </Drawer>
  );
};
//#region Helper functions
export const convertTaskGroupsFromSpecificToGeneric = (specific) => {
  if (!specific) return [];
  let generic = specific.map((tg) => {
    let newTg = renameKey(tg, 'templateTasks', 'tasks');
    newTg.tasks = newTg.tasks.map((t) => renameKey(t, 'reconPlanTemplateTaskGroupId', 'taskGroupId'));
    return newTg;
  });
  return generic;
};

const convertTaskGroupsFromGenericToSpecific = (generic) => {
  if (!generic) return [];
  let specific = generic.map((tg) => {
    let newTg = renameKey(tg, 'tasks', 'templateTasks');
    newTg.templateTasks = newTg.templateTasks.map((t) => renameKey(t, 'taskGroupId', 'reconPlanTemplateTaskGroupId'));
    return newTg;
  });
  return specific;
};

// check for and add missing properties from task groups
// need to have name, reconPlanTemplateId, sequence, and id
const makeSureTaskGroupsHaveAllRequiredProperties = (taskGroups, planTemplate) =>
  taskGroups?.map((taskGroup, idx, taskGroupsOriginalArray) => {
    const mutableTaskGroup = stripReferences(taskGroup);
    const taskGroupKeys = Object.keys(mutableTaskGroup);
    if (!taskGroupKeys.includes('name')) {
      mutableTaskGroup.name = 'Task Group';
    }
    if (!taskGroupKeys.includes('reconPlanTemplateId')) {
      mutableTaskGroup.reconPlanTemplateId = planTemplate.id;
    }
    if (!taskGroupKeys.includes('sequence')) {
      const previousElementIdx = idx - 1;
      if (previousElementIdx > -1) {
        mutableTaskGroup.sequence = taskGroupsOriginalArray[previousElementIdx].sequence + 1;
      } else {
        mutableTaskGroup.sequence = 1;
      }
    }
    if (!taskGroupKeys.includes('id')) {
      mutableTaskGroup.id = uuidv4();
    }

    mutableTaskGroup.templateTasks = mutableTaskGroup.templateTasks.map((m) => {
      if (m?.assignedTo && m.assignedTo === 'NO_ASSIGNEE') {
        return {
          ...m,
          assignedTo: null
        };
      }

      return m;
    });

    return mutableTaskGroup;
  });
//#endregion

export default withLDConsumer()(PlanTemplateDrawer);
