import React from 'react';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import vAutoMono from 'assets/images/vauto_mono.svg';
//our components
import GetHelp from './GetHelp';

const Footer = ({ flags }) => {
  return (
    <StyledFooterContainer>
      <StyledFooterNavigation>
        <a href="https://vauto.com" target="_blank" rel="noopener noreferrer">
          <img alt="vAuto" src={vAutoMono} height="31px" width="132px" />
        </a>
        <StyledNavigationList>
          <StyledListItem>
            <StyledAnchor
              href="https://www.coxautoinc.com/terms/wp-content/uploads/sites/3/Common-Customer-TOU.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms Of Use
            </StyledAnchor>
          </StyledListItem>
          <StyledListItem>
            <StyledAnchor href="https://www.coxautoinc.com/privacy-statement" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </StyledAnchor>
          </StyledListItem>
          {flags?.supportFooterLink && (
            <StyledListItem>
              <StyledAnchor href="https://casfx.my.site.com/vauto/s/" target="_blank" rel="noopener noreferrer">
                Support
              </StyledAnchor>
            </StyledListItem>
          )}
        </StyledNavigationList>
      </StyledFooterNavigation>
      <GetHelp />
    </StyledFooterContainer>
  );
};

const StyledFooterContainer = styled.footer`
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f4f5;
`;

const StyledListItem = styled.li`
  display: inline;
  &::after {
    content: '|';
    margin: 0 4px;
    color: #d0d5dd;
  }
`;

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.darkGray};
  &:hover {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const StyledFooterNavigation = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavigationList = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
  list-style: none;
  display: inline;
  ${StyledListItem}:last-child::after {
    content: none;
  }
`;

export default withLDConsumer()(Footer);
