import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertIRecon } from 'components/common';
import { lineItemsActions } from 'store/lineItemsStore';
import styled from 'styled-components';

const AlertForLineItemError = ({ isTDP = false, isShowErrorNoFooter = false }) => {
  const dispatch = useDispatch();
  const needApprovalList = useSelector((state) => state.lineItems.needApproval);
  const approvedList = useSelector((state) => state.lineItems.approved);
  const declinedList = useSelector((state) => state.lineItems.declined);
  const needApprovalErrorMessage = useSelector((state) => state.lineItems.needApprovalErrorMessage);
  const hasErrorTotalCost = [...needApprovalList, ...approvedList, ...declinedList].find((x) => x.error);

  const closeAlert = () => {
    dispatch(lineItemsActions.resetError());
  };

  // If one of each needApprovalList, approvedList, declinedList have error => Show message
  return hasErrorTotalCost || needApprovalErrorMessage ? (
    <AlertWrapper isTDP={isTDP} isShowErrorNoFooter={isShowErrorNoFooter}>
      <AlertIRecon message={'One or more errors need to be resolved before you can submit.'} closeAlert={closeAlert} />
    </AlertWrapper>
  ) : null;
};

const AlertWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${({ isTDP, isShowErrorNoFooter }) => (isTDP ? (isShowErrorNoFooter ? '-35px' : '35px') : '83px')};
  padding: 0 14px;
  left: 0;
`;

export default AlertForLineItemError;
