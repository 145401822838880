import React from 'react';
import { Button, Row, Typography } from 'antd';
import { Input } from 'antd4';
import styled, { keyframes } from 'styled-components';
import { unicodeToChar } from './utils';
import { StopOutlined } from '@ant-design/icons';

const { Text } = Typography;
const smallDot = unicodeToChar('\u00B7');

//12.6833px is the size of a li's bullet point. this is added to make sure a new line is indented properly
export const StyledLi = styled.li`
  list-style: none;
  text-indent: -12.6833px;
  padding-left: 12.6833px;

  &:before {
    content: '${smallDot} ';
    font-size: 24px;
    vertical-align: middle;
    line-height: 20px;
  }
`;

export const StyledHeaderText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.title};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.header};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.white};
    white-space: pre;
  }
`;

export const StyledHeaderMobileText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.lg};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledTitleText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.jumbo};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.title};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
    overflow-wrap: break-word;
  }
`;

export const StyledSubTitleText = styled(({ error, ...rest }) => <Text {...rest} />)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: ${({ theme }) => theme.lineHeights.subTitle};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.navy)};
    overflow-wrap: break-word;
    white-space: pre-line;
  }
`;

export const StyledRequirmentText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.xs};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
    overflow-wrap: break-word;
    white-space: pre-line;
    display: inline-block;
  }
`;

export const StyledBannerTitle = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 12px;
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const StyledMobileDescriptionBottom = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.lg};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledBannerDescription = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 12px;
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.secondaryGrey};
    white-space: pre;
  }
`;

export const StyledBannerButton = styled(Button)`
  .ant-btn& {
    background-color: ${({ theme }) => theme.colors.lightSystemBlue};
    border-radius: 100px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.lg};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    padding-left: 8.5px;
    padding-right: 8.5px;
  }
`;

export const StyledRow = styled(({ backgroundColor, minHeight, paddingLeft, ...rest }) => <Row {...rest} />)`
  .ant-row-flex& {
    background-color: ${({ theme }) => theme.colors.navy};
    min-height: ${(props) => (props.minHeight ? props.minHeight : 'unset')};
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : 'unset')};
`;

export const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
  margin-top: 5%;
`;

export const StyledImgMobile = styled.img`
  margin-top: 16px;
  margin-bottom: 16px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: calc(17vh -32px);
  border-radius: 8px;
`;

const animation = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

export const StyledImageSpinner = styled.img`
  width: 50px;
  height: 50px;
  animation: ${animation} 2s infinite linear;
  background-color: transparent;
`;

export const Overlay = styled(({ show, ...rest }) => <div {...rest} />)`
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  position: fixed;
`;

export const ButtonText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    color: ${({ theme }) => theme.colors.inputGray};
    letter-spacing: ${({ theme }) => theme.letterSpacings.sm};
  }
`;

export const StyledButton = styled(Button)`
  .ant-btn& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    color: ${({ theme }) => `${theme.colors.infoPrimary} !important`};
    letter-spacing: ${({ theme }) => theme.letterSpacings.sm};
    padding: 0;
    height: auto;
  }
`;

export const ErrorText = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const SubmitButton = styled(Button)`
  .ant-btn-primary& {
    margin-top: 8px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4.5px;
    padding-bottom: 4.5px;

    [disabled]&,
    [disabled]:hover& {
      color: white;
      background-color: ${({ theme }) => theme.colors.infoPrimary};
      opacity: 0.5;
      border: ${({ theme }) => theme.borders.disabledButtonBorder};
    }
  }
`;

export const StyledSubSubtitleText = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.lg};
  letter-spacing: ${({ theme }) => theme.letterSpacings.md};
  color: ${({ theme }) => theme.colors.navy};
`;

export const DownloadSignInButton = styled(Button)`
  .ant-btn& {
    margin-top: 16px;
    width: 100%;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    background-color: ${({ theme }) => theme.colors.infoPrimary};
  }
`;

export const StyledInput = styled(({ error, bigger, ...rest }) => <Input {...rest} />)`
  &.ant-input {
    border: ${(props) => (props.error ? props.theme.borders.inputBorderError : props.theme.borders.inputBorderNormal)};
  }
  &.ant-input:focus {
    border: ${(props) =>
      props.error ? `${props.theme.borders.inputBorderError} !important` : props.theme.borders.inputBorderNormal};
  }
  &.ant-input:hover {
    border: 1px solid;
  }
`;

export const StyledSpan = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: ${({ theme }) => theme.lineHeights.xs};

  svg {
    width: ${12 * (12 / 11.625)}px !important;
    height: ${12 * (12 / 11.625)}px;
    vertical-align: text-top;
    line-height: ${({ theme }) => theme.lineHeights.xs};
    color: ${({ theme }) => theme.colors.krypton};
  }
`;

export const StyledAntIcon = styled(StopOutlined)`
  &.anticon {
    color: ${({ theme }) => theme.colors.ferrariRed};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    vertical-align: text-top;
    line-height: ${({ theme }) => theme.lineHeights.xs};
  }
`;
