const documentationContexts = {
  ACCEPTED_FILE_TYPES: ['pdf', 'docx', 'doc', 'PDF', 'DOCX', 'DOC'], // Accepted file type
  NUMBERS_DOCUMENTS_UPLOADED: 10, // the number of document uploaded in a time
  MAXIMUM_DOCUMENT_SIZE: 10, // in MB
  MINIMUM_DOCUMENT_SIZE: 0, // in MB
  MESSAGE_MAXIMUM_DOCUMENT_SIZE: 'File size cannot exceed 10MB.',
  MESSAGE_EXCEEDS_10_FILES_LIMIT: 'Upload exceeds 10 files limit.',
  MESSAGE_MINIMUM_DOCUMENT_SIZE: 'The file size should more than 0MB.',
  MESSAGE_INCORRECT_FILE_TYPE: 'This is an invalid file type. Upload a PDF, Doc, or Docx.',
  EMPTY_DOCUMENT_MESSAGE: 'Any documents will display here. Get started by attaching a document below.',
  INVENTORY_PAGE: 'INVENTORY_PAGE',
  TASKS_PAGE: 'TASKS_PAGE'
};

export default documentationContexts;
