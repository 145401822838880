import { takeLatest, put, select } from 'redux-saga/effects';
import { createRequestTypes, makeActionCreator } from 'utils';
import { getWithToken, putWithToken, postWithToken } from 'api';
import { apiStatusConstants, ROOT_ENTITY_PICKER, VENDORS, DEALERS, vautoDefaultEntityId } from 'app-constants';
import { messagesActions } from 'store/messagesStore';
import { createSelector } from 'reselect';
import { taskTypesActions } from 'store/taskTypesStore';
import { usersActions } from 'store/usersStore';
import { tasksActions } from './tasksStore';

//#region Actions
export const CURRENT_DEALER = createRequestTypes('CURRENT_DEALER', [
  'GET_DATA',
  'SET_DATA',
  'GET_DATA_VENDORS',
  'SET_DATA_VENDORS',
  'SET_FETCH_STATUS',
  'SET_UPDATE_STATUS',
  'UPDATE',
  'APPLY_EXCLUDE_FILTER',
  'SWITCH',
  'CHECK_ACCESSIBILITY',
  'SET_ACCESSIBILITY',
  'SET_VENDOR_SHIP_SELECTED',
  'LOAD_INVENTORY',
  'ROOT_USER_SET_DEALER_FEATURES'
]);

export const currentDealerActions = {
  getData: makeActionCreator(CURRENT_DEALER.GET_DATA, 'dealerId', 'vautoRedomainingCookiePartition', 'fallbackId'),
  setData: makeActionCreator(CURRENT_DEALER.SET_DATA, 'data'),
  updateData: makeActionCreator(CURRENT_DEALER.UPDATE, 'dealer'),
  applyExcludeFilter: makeActionCreator(CURRENT_DEALER.APPLY_EXCLUDE_FILTER, 'data'),
  setFetchStatus: makeActionCreator(CURRENT_DEALER.SET_FETCH_STATUS, 'fetchStatus'),
  setUpdateStatus: makeActionCreator(CURRENT_DEALER.SET_UPDATE_STATUS, 'updateStatus'),
  switch: makeActionCreator(CURRENT_DEALER.SWITCH),
  checkAccessibility: makeActionCreator(CURRENT_DEALER.CHECK_ACCESSIBILITY, 'logicalId'),
  setAccessibility: makeActionCreator(CURRENT_DEALER.SET_ACCESSIBILITY, 'isAccessible'),
  setVendorShipSelected: makeActionCreator(CURRENT_DEALER.SET_VENDOR_SHIP_SELECTED, 'vendorShip'),
  loadInventory: makeActionCreator(CURRENT_DEALER.LOAD_INVENTORY, 'dealerId'),
  setDealerFeaturesRootUser: makeActionCreator(CURRENT_DEALER.ROOT_USER_SET_DEALER_FEATURES, 'features')
};

const ACCESSIBLE_DEALERS = createRequestTypes('ACCESSIBLE_DEALERS', [
  'GET_DATA',
  'SET_DATA',
  'SET_PAGER',
  'SET_FILTERS',
  'SET_FETCH_STATUS',
  'SET_TOTAL_ACCESSIBLE_COUNT',
  'GET_DATA_VENDORS'
]);
export const accessibleDealersActions = {
  getData: makeActionCreator(ACCESSIBLE_DEALERS.GET_DATA, 'pager', 'filters', 'updateTotalAccessibleCount'),
  getDataVendors: makeActionCreator(
    ACCESSIBLE_DEALERS.GET_DATA_VENDORS,
    'pager',
    'filters',
    'updateTotalAccessibleCount'
  ),
  setData: makeActionCreator(ACCESSIBLE_DEALERS.SET_DATA, 'data'),
  setPager: makeActionCreator(ACCESSIBLE_DEALERS.SET_PAGER, 'pager'),
  setFilters: makeActionCreator(ACCESSIBLE_DEALERS.SET_FILTERS, 'filters'),
  setFetchStatus: makeActionCreator(ACCESSIBLE_DEALERS.SET_FETCH_STATUS, 'fetchStatus'),
  setTotalAccessibleCount: makeActionCreator(ACCESSIBLE_DEALERS.SET_TOTAL_ACCESSIBLE_COUNT, 'totalAccessibleCount')
};

const CURRENT_VENDOR = createRequestTypes('CURRENT_VENDOR', ['GET_DEALERS', 'SET_DEALERS', 'SET_FETCH_STATUS']);

export const currentVendorActions = {
  getDealers: makeActionCreator(CURRENT_VENDOR.GET_DEALERS, 'vendorId'),
  setDealers: makeActionCreator(CURRENT_VENDOR.SET_DEALERS, 'dealers'),
  setFetchStatus: makeActionCreator(CURRENT_VENDOR.SET_FETCH_STATUS, 'fetchStatus')
};

//#endregion

//#region Reducer
const initialState = {
  current: {
    data: {},
    fetchStatus: apiStatusConstants.IS_FETCHING,
    rootUserDealerFeatures: []
  },
  currentDealerIsAccessible: false,
  accessible: {
    data: {},
    pager: { start: 1, limit: 10 },
    filters: {},
    fetchStatus: apiStatusConstants.PENDING,
    totalAccessibleCount: null
  },
  vendorShipSelected: 'Dealers',
  currentVendor: {
    dealers: [],
    fetchStatus: apiStatusConstants.IS_FETCHING
  }
};

export const dealersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_DEALER.SET_DATA:
      return {
        ...state,
        current: {
          ...state.current,
          data: action.data
        }
      };
    case CURRENT_DEALER.SET_DATA_VENDORS:
      return {
        ...state,
        current: {
          ...state.current,
          data: action.data
        }
      };
    case CURRENT_DEALER.ROOT_USER_SET_DEALER_FEATURES:
      return {
        ...state,
        current: {
          ...state.current,
          rootUserDealerFeatures: action.features
        }
      };
    case CURRENT_DEALER.SET_FETCH_STATUS:
      return {
        ...state,
        current: {
          ...state.current,
          fetchStatus: action.fetchStatus
        }
      };
    case CURRENT_DEALER.SET_UPDATE_STATUS:
      return {
        ...state,
        current: {
          ...state.current,
          updateStatus: action.updateStatus
        }
      };
    case CURRENT_DEALER.SET_ACCESSIBILITY:
      return {
        ...state,
        currentDealerIsAccessible: action.isAccessible
      };
    case CURRENT_DEALER.SET_VENDOR_SHIP_SELECTED:
      if (action.vendorShip === DEALERS && state.vendorShipSelected === VENDORS) {
        localStorage.removeItem(ROOT_ENTITY_PICKER);
      }
      return {
        ...state,
        vendorShipSelected: action.vendorShip,
        current:
          action.vendorShip === DEALERS && state.vendorShipSelected === VENDORS
            ? {
                data: {},
                fetchStatus: apiStatusConstants.IS_FETCHING,
                rootUserDealerFeatures: []
              }
            : state.current
      };
    case ACCESSIBLE_DEALERS.SET_DATA:
      return {
        ...state,
        accessible: {
          ...state.accessible,
          data: action.data
        }
      };
    case ACCESSIBLE_DEALERS.SET_PAGER:
      return {
        ...state,
        accessible: {
          ...state.accessible,
          pager: action.pager
        }
      };
    case ACCESSIBLE_DEALERS.SET_FILTERS:
      return {
        ...state,
        accessible: {
          ...state.accessible,
          filters: action.filters
        }
      };
    case ACCESSIBLE_DEALERS.SET_FETCH_STATUS:
      return {
        ...state,
        accessible: {
          ...state.accessible,
          fetchStatus: action.fetchStatus
        }
      };
    case ACCESSIBLE_DEALERS.SET_TOTAL_ACCESSIBLE_COUNT:
      return {
        ...state,
        accessible: {
          ...state.accessible,
          totalAccessibleCount: action.totalAccessibleCount
        }
      };
    case CURRENT_VENDOR.SET_DEALERS:
      return {
        ...state,
        currentVendor: {
          ...state.currentVendor,
          dealers: action.dealers
        }
      };
    case CURRENT_VENDOR.SET_FETCH_STATUS:
      return {
        ...state,
        currentVendor: {
          ...state.currentVendor,
          fetchStatus: action.fetchStatus
        }
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const currentDealerSelector = (state) => state.dealers.current;

export const rootEntitySwitcherSelector = createSelector(
  (state) => state.dealers.vendorShipSelected,
  (state) => state.dealers.current.data,
  (state) => state.oidc,
  (state) => state.dealers.current.fetchStatus,
  (state) => state.dealers.current.rootUserDealerFeatures,
  (vendorShipSelected, currentData, oidcState, currentDealerFetchStatus, rootUserDealerFeatures) => ({
    vendorShipSelected: vendorShipSelected === VENDORS,
    currentId: currentData?.id,
    isRootUser: vautoDefaultEntityId === oidcState.user?.profile?.vauto_default_entity_id,
    currentDealerFetchStatus,
    rootUserDealerFeatures
  })
);

//#region Sagas
export function* getCurrentDealerSaga() {
  yield takeLatest(CURRENT_DEALER.GET_DATA, function* ({ dealerId, vautoRedomainingCookiePartition, fallbackId }) {
    try {
      yield put(currentDealerActions.setFetchStatus(apiStatusConstants.PENDING));
      const { isRootUser, vendorShipSelected, rootUserDealerFeatures } = yield select(rootEntitySwitcherSelector);
      let data;
      if (isRootUser && vendorShipSelected) {
        localStorage.setItem(ROOT_ENTITY_PICKER, dealerId);
        data = yield getWithToken(`/api/Vendors/id/${dealerId}`);

        //This api call is needed to get correct features for the current root user when viewing vendor entities.
        //Root Users have access to all dealers, so this endpoint should always return at least 1 dealer.
        //Excluding INVENTORY features as root users in vendor view do not have access to ILP
        const { items } = yield getWithToken(`/api/Dealers/simple-accessible`, {
          start: 1,
          limit: 1
        });
        if (items && items.length > 0 && (!rootUserDealerFeatures || rootUserDealerFeatures?.length === 0)) {
          //also check to see if rootUserDealerFeatures is already populated.
          //skip these api calls if so
          let firstDealerId = items[0].id;
          const firstDealerData = yield getWithToken(`/api/Dealers/id/${firstDealerId}`, { include: 'features' });
          yield put(
            currentDealerActions.setDealerFeaturesRootUser(
              firstDealerData.features.filter((f) => !f.includes('INVENTORY'))
            )
          );
        }

        // set data
        yield put(taskTypesActions.setFetchStatus(apiStatusConstants.PENDING));
        yield put(usersActions.setFetchStatus(apiStatusConstants.PENDING));
        yield put(tasksActions.setDealershipsFetchStatus(apiStatusConstants.PENDING));
        yield put(currentDealerActions.setData(data));
        // set shared vAuto cookie
        yield put(currentDealerActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      } else {
        try {
          data = yield getWithToken(`/api/Dealers/id/${dealerId}`, { include: 'features' });
        } catch (getDealerError) {
          if (fallbackId && fallbackId !== dealerId) {
            //fallback dealer id is necessary in cases when current dealer user does not have access
            //to the dealer id saved in our app's cookies.
            data = yield getWithToken(`/api/Dealers/id/${fallbackId}`, { include: 'features' });
          } else {
            throw getDealerError;
          }
        }

        // set data
        yield put(currentDealerActions.setData(data));
        yield put(currentDealerActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      }
    } catch (error) {
      const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
      if (isRootUser && error.statusCode === 404) {
        yield put(
          messagesActions.notify('error', `This ${vendorShipSelected ? 'vendor' : 'dealer'} cannot be found!`, {
            isVendorToastMessage: true
          })
        );
      } else {
        // one scenario where an error can happen is when the user switches to a different user (impersonates another user), and that impersonated user doesn't have access to the current entity (from the vAuto cookie), so here we're going to empty out the features so that they won't have access to anything,
        // but still set the data for this dealer so that the name can still be displayed in the header bar
        const newData = {
          ...(yield select((state) => state.dealers.current.data)),
          features: []
        };
        yield put(currentDealerActions.setData(newData));
      }
      devLogger.error(error);
      yield put(currentDealerActions.setFetchStatus(apiStatusConstants.FAILED));
    }
  });
}

export function* updateDealerSaga() {
  yield takeLatest(CURRENT_DEALER.UPDATE, function* ({ dealer }) {
    try {
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.PENDING));

      //todo: We should come up with a global pattern for how to handle issues like this, where we we're updating an object that we originally retrieved with optional includes.
      //todo (cont.): It seems crazy to update all our PUT endpoints to honor the same params, and ignoring the PUT's response altogether and issuing a GET instead isn't great either...
      const current = yield select(currentDealerSelector);
      const { features } = current.data;
      const data = { ...(yield putWithToken(`/api/Dealers/id/${dealer.id}`, dealer)), features };
      yield put(currentDealerActions.setData(data));

      yield put(messagesActions.notify('success', 'Successfully updated settings!', { duration: 2 }));
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(messagesActions.notify('error', 'Failed to update settings', { duration: 3.5 }));
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.FAILED));

      // TODO: handle error
      devLogger.log('error in updateDealerSaga', error);
    }
  });
}

export function* applyExcludeFilterSaga() {
  yield takeLatest(CURRENT_DEALER.APPLY_EXCLUDE_FILTER, function* ({ data }) {
    try {
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.PENDING));

      const excludedVehicles = yield postWithToken(`/api/vehicles/exclude`, {
        exclusionAge: data.exclusionAge,
        dealerId: data.dealerId
      }); // Yes, this is redundant, but want to make it obvious what data we're passing

      const successMessage =
        excludedVehicles.length > 0
          ? `Successfully excluded ${excludedVehicles.length} vehicles from recon!`
          : `No vehicles older than ${data.exclusionAge} days found to exclude`;
      yield put(messagesActions.notify('success', successMessage, { duration: 2 }));
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(messagesActions.notify('error', 'Failed to exclude vehicles', { duration: 3.5 }));
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.FAILED));

      // TODO: handle error
      devLogger.log('error in applyExcludeFilterSaga', error);
    }
  });
}

export function* getAccessibleDealersSaga() {
  yield takeLatest(ACCESSIBLE_DEALERS.GET_DATA, function* ({ pager, filters, updateTotalAccessibleCount = false }) {
    try {
      yield put(accessibleDealersActions.setFetchStatus(apiStatusConstants.PENDING));

      const data = yield getWithToken(`/api/Dealers/accessible`, {
        ...filters,
        start: pager.start,
        limit: pager.limit
      });

      const totalAccessibleCount = yield select((state) => state.dealers.accessible.totalAccessibleCount);
      if (totalAccessibleCount === null || updateTotalAccessibleCount) {
        // NOTE: setting this the very first time we fetch accessible dealers, because this first fetch always fetches all,
        // and capturing 'totalAccessibleCount' in redux state once.  This is needed in order to disable the dealer picker
        // if the user only has access to one dealer (hide dropdown arrow), the reason we need this state is because the accessible
        // dealers list is filterable, when you search down to 1 item, it's not the same as the user only have access to one dealer
        yield put(accessibleDealersActions.setTotalAccessibleCount(data.count));
      }

      yield put(accessibleDealersActions.setData(data));
      yield put(accessibleDealersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error loading accessible dealers', error);
      yield put(accessibleDealersActions.setFetchStatus(apiStatusConstants.FAILED));
    }
  });
}

export function* getAccessibleVendorsSaga() {
  yield takeLatest(
    ACCESSIBLE_DEALERS.GET_DATA_VENDORS,
    function* ({ pager, filters, updateTotalAccessibleCount = false }) {
      try {
        yield put(accessibleDealersActions.setFetchStatus(apiStatusConstants.PENDING));
        const dealer = yield select((state) => state.dealers.current.data);
        const data = yield getWithToken(`/api/Vendors/search/switcher`, {
          ...filters,
          dealerId: dealer.id,
          start: pager.start,
          limit: pager.limit
        });

        const totalAccessibleCount = yield select((state) => state.dealers.accessible.totalAccessibleCount);
        if (totalAccessibleCount === null || updateTotalAccessibleCount) {
          // NOTE: setting this the very first time we fetch accessible dealers, because this first fetch always fetches all,
          // and capturing 'totalAccessibleCount' in redux state once.  This is needed in order to disable the dealer picker
          // if the user only has access to one dealer (hide dropdown arrow), the reason we need this state is because the accessible
          // dealers list is filterable, when you search down to 1 item, it's not the same as the user only have access to one dealer
          yield put(accessibleDealersActions.setTotalAccessibleCount(data.count));
        }

        yield put(accessibleDealersActions.setData(data));
        yield put(accessibleDealersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        // TODO: handle error
        devLogger.log('error loading accessible dealers', error);
        yield put(accessibleDealersActions.setFetchStatus(apiStatusConstants.FAILED));
      }
    }
  );
}

export function* checkIfCurrentDealerIsAccessibleSaga() {
  yield takeLatest(CURRENT_DEALER.CHECK_ACCESSIBILITY, function* ({ logicalId }) {
    try {
      let accessible = false;
      if (logicalId !== undefined) {
        const data = yield getWithToken(`/api/Dealers/accessible`, { search: logicalId, start: 1, limit: 1 });
        accessible = data.count === 1;
      }
      yield put(currentDealerActions.setAccessibility(accessible));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in checkIfCurrentDealerIsAccessibleSaga', error);
    }
  });
}

export function* getDealersSaga() {
  yield takeLatest(CURRENT_VENDOR.GET_DEALERS, function* ({ vendorId }) {
    try {
      yield put(currentVendorActions.setFetchStatus(apiStatusConstants.IS_FETCHING));

      const dealers = yield getWithToken(`/api/Vendors/id/${vendorId}/dealers`);
      yield put(currentVendorActions.setDealers(dealers));

      yield put(currentVendorActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(currentVendorActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getDealersSaga', error);
    }
  });
}

export function* loadInventorySaga() {
  yield takeLatest(CURRENT_DEALER.LOAD_INVENTORY, function* ({ dealerId }) {
    try {
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.PENDING));

      yield postWithToken(`/api/InventoryLoader/dealer/${dealerId}`);

      yield put(
        messagesActions.notify(
          'success',
          'Successfully initiated import. Please wait, Inventory should be available in 5-10 minutes.',
          { duration: 3.5 }
        )
      );
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(
        messagesActions.notify(
          'error',
          `Sorry! We weren't able to initiate the import at this time. Please try again later.`,
          { duration: 3.5 }
        )
      );
      yield put(currentDealerActions.setUpdateStatus(apiStatusConstants.FAILED));

      // TODO: handle error
      devLogger.log('error in loadInventorySaga', error);
    }
  });
}

//#endregion
