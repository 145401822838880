import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'styles/appTheme';
import { StyledForm, StyledFormItem } from 'components/layout/settings/common/Styles';
import InputErrorMessage from '../common/InputErrorMessage';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const FormModalLayout = ({
  form,
  visible,
  handleSave,
  closeModal,
  title,
  icon,
  children,
  buttonText,
  message,
  dropdown,
  isSaving
}) => {
  const { getFieldDecorator, getFieldsError, validateFields, resetFields } = form;
  const [isMissingLoading, setIsMissingLoading] = useState();

  useEffect(() => {
    if (isSaving === undefined) {
      setIsMissingLoading(true);
    } else {
      setIsMissingLoading(false);
      if (!isSaving) {
        resetFields();
      }
    }
  }, [isSaving]);

  const hasFormErrors = () => {
    const formErrors = getFieldsError();
    return Object.keys(formErrors).some((field) => formErrors[field]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        handleSave(values);
        if (isMissingLoading) {
          setTimeout(() => {
            resetFields();
          }, 1000);
        }
      }
    });
  };

  return (
    <>
      {visible && (
        <StyledModal
          visible={visible}
          okText={buttonText}
          okType={'danger'}
          cancelButtonProps={{
            style: {
              borderColor: theme.colors.red,
              color: theme.colors.red,
              fontFamily: 'Roboto',
              fontWeight: theme.fontWeights.medium
            },
            disabled: isSaving
          }}
          width={416}
          onOk={handleSubmit}
          onCancel={() => {
            resetFields();
            closeModal();
          }}
          closable={false}
          okButtonProps={{ loading: isSaving, disabled: hasFormErrors() }}
        >
          <StyledForm>
            <StyledFlexRow>
              <StyledFlexCol className="section">
                <StyledLargeIcon icon={icon} color={theme.colors.navy} />
              </StyledFlexCol>
              <StyledFlexCol className="section">
                <StyledHeader className="contentSection" data-testid="modalRow">
                  {title}
                </StyledHeader>
                <div className="contentSection" data-testid="modalText">
                  {children}
                </div>
                {message && (
                  <StyledFormItem label={message?.label} className="inviteMessageInput contentSection">
                    {getFieldDecorator('message', {
                      initialValue: '',
                      rules: [
                        {
                          required: false,
                          validator: (rule, value) => {
                            if (value.trim().length > message?.maxLength) {
                              rule.message = (
                                <InputErrorMessage>
                                  Message cannot exceed {message.maxLength} characters
                                </InputErrorMessage>
                              );
                            }
                            if (rule.message) {
                              return Promise.reject(rule.message);
                            }
                            return Promise.resolve();
                          }
                        }
                      ]
                    })(
                      <Input.TextArea placeholder={message?.placeholder} rows={3} maxLength={message?.maxLength + 1} />
                    )}
                  </StyledFormItem>
                )}
                {dropdown && (
                  <StyledFormItem label={dropdown?.label} className="contentSection">
                    {getFieldDecorator('primaryContact', {
                      initialValue: dropdown?.values[0]?.id,
                      rules: [
                        {
                          required: dropdown?.isRequired,
                          message: 'Primary Contact is required'
                        }
                      ]
                    })(
                      <StyledSelect
                        size="large"
                        suffixIcon={
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="ant-select-suffix"
                            style={{
                              transition: 'all 0.3s ease',
                              transform: 'rotate 0.5turn'
                            }}
                          />
                        }
                      >
                        {dropdown?.values.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.fullName}
                          </Option>
                        ))}
                      </StyledSelect>
                    )}
                  </StyledFormItem>
                )}
              </StyledFlexCol>
            </StyledFlexRow>
          </StyledForm>
        </StyledModal>
      )}
    </>
  );
};

const StyledModal = styled(Modal)`
  width: 416px;
  .ant-modal-body {
    padding: 32px 32px 0 32px;
    display: flex;
  }

  .ant-modal-content {
  }

  .ant-modal-footer {
    padding: 0 32px 32px 32px;
    border-top: none;
  }

  .ant-btn-danger[disabled] {
    color: ${theme.colors.white} !important;
    background-color: rgba(254, 32, 59, 0.15) !important;
    border-color: unset !important;
  }

  form .ant-mentions,
  form textarea.ant-input {
    margin-bottom: 0;
  }

  .ant-form.ant-form-vertical {
    width: 100%;
  }

  div.section + div.section {
    padding-left: 16px;
    width: 100%;
  }

  div.contentSection {
    padding-bottom: 18px;
  }

  div.contentSection:last-child {
    padding-bottom: 22px;
  }

  div.ant-form-explain {
    margin-top: 4px;
  }
  /* move asterisks to right */
  .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    float: left;

    &::before {
      margin-left: 4px;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-arrow {
    color: ${theme.colors.secondaryGrey};
    pointer-events: none;
  }

  .ant-select-selection__rendered {
    font-weight: ${theme.fontWeights.normal};
    font-size: ${theme.fontSizes.sm};
    line-height: ${theme.lineHeights.dropdownText};
    letter-spacing: ${theme.letterSpacings.md};
    color: ${theme.colors.navy};
  }

  .ant-select-selection,
  .ant-select-selection:focus {
    border: ${theme.borders.mediumSecondaryGrey};
  }
`;

const StyledLargeIcon = styled(FontAwesomeIcon)`
  font-size: 21.31px;
`;

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.medium};
`;

export default Form.create()(FormModalLayout);
