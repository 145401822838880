import React, { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { List, Typography } from 'antd';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { VehicleImage } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
const { Text } = Typography;

const VehiclePhotosDetail = ({ vehicle }) => {
  const { images } = vehicle;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const renderImageTitle = (index) => {
    const title = `Image ${index + 1}/${images.length}, ${
      images[index]?.reconTaskTypeName ? images[index]?.reconTaskTypeName : ' Vehicle Note'
    }, ${images[index].fullName}`;
    return title;
  };

  const nextIndex = (photoIndex + 1) % images.length;
  const prevIndex = (photoIndex + images.length - 1) % images.length;

  const renderPhotos = () => {
    return (
      <>
        {images && images.length > 0 ? (
          <StyledPhotosList
            dataSource={images}
            renderItem={(im, index) => (
              <ItemImage
                countImages={images.length}
                key={index}
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(index);
                }}
              >
                <VehicleImage
                  isLoading={false}
                  src={im.thumbnailUrl}
                  imageStyle={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
                  noImageStyle={{ width: '100%' }}
                />
              </ItemImage>
            )}
          />
        ) : (
          NoPhotos()
        )}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].originalUrl}
            mainSrcThumbnail={images[photoIndex].thumbnailUrl}
            nextSrc={images[nextIndex].originalUrl}
            nextSrcThumbnail={images[nextIndex].thumbnailUrl}
            prevSrc={images[prevIndex].originalUrl}
            prevSrcThumbnail={images[prevIndex].thumbnailUrl}
            onCloseRequest={() => {
              setIsOpen(false);
            }}
            onMovePrevRequest={() => {
              setPhotoIndex((photoIndex + images.length - 1) % images.length);
            }}
            onMoveNextRequest={() => {
              setPhotoIndex((photoIndex + 1) % images.length);
            }}
            imageCaption={renderImageTitle(photoIndex)}
          />
        )}
      </>
    );
  };
  const NoPhotos = () => {
    return (
      <EmptyImage>
        <NoPhotosText>
          <FontAwesomeIcon icon={faImages} size={'2x'} />
        </NoPhotosText>
        <NoPhotosText>No Photos</NoPhotosText>
        <NoPhotosDescriptionText>Any task photos tied to this vehicle will display here.</NoPhotosDescriptionText>
      </EmptyImage>
    );
  };
  return <>{renderPhotos()}</>;
};

//#region Styled Components
const StyledPhotosList = styled(List).attrs({
  size: 'small'
})`
  .ant-list& {
    padding: 0 24px;
    display: flex;
  }
`;
const ItemImage = styled.div`
  padding: 8px 4px;
  float: left;
  width: ${({ countImages }) => (countImages === 1 ? '50%' : '25%')};
`;
const EmptyImage = styled.div`
  width: 100%;
  padding-top: 8px;
`;
const NoPhotosText = styled(Text)`
  .ant-typography& {
    margin-left: auto;
    margin-right: auto;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: 500;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.md};
    text-align: center;
  }
`;
const NoPhotosDescriptionText = styled(Text)`
  .ant-typography& {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;
    margin-top: 16px;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
export default withLDConsumer()(VehiclePhotosDetail);
