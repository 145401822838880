import { MAIN, TASK, TASK_GROUP, TASK_GROUP_TASK, DROP_PLACEHOLDER } from './types';
import { stripReferences } from 'utils/arrayUtils';
import { COMPLETED, DECLINED, IN_PROGRESS, PENDING } from 'app-constants/taskStatusTypes';

/**
 * createTaskGroupStructure
 * this function sets the shape of the models for task groups to be used in the dnd work
 * it does this by setting display properties based on number of tasks that there are in each of the tg (task group)
 * if there are more than 1 task in a task group, then we want to display this as a task group on the page
 *
 * this function is invoked in the useEffect in TaskGroupsDnd after checking for updates to task groups
 * these updates can either be from TaskForm update, complete or add tasks or from updates to the order of the task groups
 * the invocation of this function returns the structuredTaskGroups that are used elsewhere throughout the rest of the Dnd work
 *
 * @param {object array} taskGroups - taskGroups
 * modelType set as TASK_GROUP is picked up later in rendering the UI and shows a task group
 * modelType set as TASK_GROUP_TASK is for keeping track that the task item is in fact a task belonging to a larger task group
 * modelType set as TASK is picked up later in rendering the UI and shows a single task
 */
export const createTaskGroupStructure = (taskGroups) =>
  taskGroups
    .filter((tg) => tg.tasks && tg.tasks.length > 0)
    .map((tg) => {
      return tg.tasks.length > 1
        ? {
            // tasks.length > 1 means that this is a task group containing more than 1 task - set props accordingly
            ...tg,
            modelType: TASK_GROUP,
            parentGroupId: MAIN,
            tasks: tg.tasks.map((t) => ({
              // here each of the tasks in the larger task group is given properties to denote that they are individual tasks in a task group
              ...t,
              modelType: TASK_GROUP_TASK,
              parentGroupId: tg.id
            }))
          }
        : {
            // else tasks.length === 1 (will not be 0 for actual data (not dropplaceholders)) which means that this is a single task
            ...tg.tasks[0],
            sequence: tg.sequence,
            group: tg,
            modelType: TASK,
            parentGroupId: MAIN,
            taskSequence: tg.tasks[0].sequence,
            parentSequence: tg.sequence
          };
    });
//some general functions used frequently

/**
 * isTaskComplete and isTaskIncomplete
 * functions that are ran on inventory page
 * no need to check this on template plan tasks
 * checks status for COMPLETED task status
 *
 * @param {object} task - a standard task object
 */
export const isTaskComplete = (task) => task?.status === COMPLETED;
export const isTaskIncomplete = (task) => !isTaskComplete(task);

/*
 * Pass through flag cannot be added to tasks within a task group, or removed from an in-progress task
 * Task will only have a "group" property if it is the only task within its group (See L76 of DndContainerFunctions.js)
 * Template plan tasks don't have a status, so we skip checking if the task is in-progress
 * Pass through flag can be added to an in-progress task, but not removed
 * Keep pass through flag editable on an in-progress task that isn't in a group until the user clicks save (by checking the prevPassThroughState value)
 */
export const isTaskPassThroughEditable = (task, isInProgressTaskGroup, flags) => {
  return (
    !!task &&
    (!task.hasOwnProperty('status') ||
      (!task.declined && task.status === PENDING) ||
      ((task.declined || task.status === DECLINED) &&
        (!task.hasOwnProperty('taskStartedOn') || isInProgressTaskGroup === false)) ||
      (((!task.declined && task.status === IN_PROGRESS) || task.declined || task.status === DECLINED) &&
        (!task.passthrough || (task.hasOwnProperty('prevPassThroughState') && !task.prevPassThroughState)))) &&
    task.hasOwnProperty('group')
  );
};

/**
 * isTaskGroupCompleted, taskGroupContainsCompletedTasks and isTaskGroupIncomplete
 * functions which are used to determine complete status of an entire group
 * only ran on recon plan task groups
 *
 * partially completed task groups are important to detect because they cannot be dragged once at least one task in the group has been completed
 * @param {object} taskGroup - a single task group object
 */
// checking whether task group is complete or incomplete
export const isTaskGroupCompleted = (taskGroup) => taskGroup?.tasks?.every(isTaskComplete);
//task groups that have at least one completed task should not be able to be dragged and other task items should not be able to be dropped ahead of them
export const taskGroupContainsCompletedTasks = (taskGroup) => taskGroup?.tasks?.some(isTaskComplete);
export const isTaskGroupIncomplete = (taskGroup) => taskGroup?.tasks?.some(isTaskIncomplete);

/**
 * doesStepContainCompletedTasks
 * function that is invoked in both DndContainer and DraggableItem
 * all of the invocations of the function are used to determine whether a task group step is partially completed
 * important because, if so, the task group should not be draggable (determined in DraggableItem) and it should not be
 * @param {object} step - step can either be a single task or a task group (determined by checking the value of the step's modelType)
 */
//completed task items must be groups that have every task completed or they must be single tasks that have a status of COMPLETED
export const isStepCompleted = (step) => {
  if (step.modelType === TASK_GROUP) {
    return isTaskGroupCompleted(step);
  } else {
    return isTaskComplete(step);
  }
};

//looks for at least one task in a task group that is complete
export const doesStepContainCompletedTasks = (step) => {
  if (step.modelType === TASK_GROUP) {
    return taskGroupContainsCompletedTasks(step);
  } else {
    return isTaskComplete(step);
  }
};

/**
 *
 */
export const getInProgressStep = (taskGroups) => {
  //to determine whether the task group should be marked as in progress,
  //we need to check the value of status for each of the tasks in a task group
  //we also need to consider what comes before the task group - should a IN_PROGRESS task that is moved into a task group still be considered in progress, if the task is in this particular group?
  //we also need to ensure that if a task is moved from this task group that the tasks status is correctly updated
  //a task can be either the first task in a started plan or the first task right after completed tasks to be considered in progress
  //with in progress task groups, if one task is completed, the others are still in progress
  //first thing that is needed is determining which sequence the in progress item is - are there completed tasks/task groups? if so, first task/task group after this should be noted as in progress. if not, the first task in the plan is in progress. if there are no task groups, we still need to consider the change in order and fix this
  let mutableTaskGroups = stripReferences(taskGroups);
  if (taskGroups.length > 0 && !taskGroups[0].hasOwnProperty('modelType')) {
    mutableTaskGroups = createTaskGroupStructure(mutableTaskGroups);
  }
  const taskGroupsWithoutCompletedOrPassThroughTasksOrDropPlaceholders = removeCompletedAndPassThroughTasksAndDropPlaceholders(
    mutableTaskGroups
  );
  if (taskGroupsWithoutCompletedOrPassThroughTasksOrDropPlaceholders.length > 0) {
    //the first item in the list will be what should be considered in progress
    return taskGroupsWithoutCompletedOrPassThroughTasksOrDropPlaceholders[0];
  } else {
    //all tasks are either completed or passthrough, so return highest sequence
    let highestSequence = mutableTaskGroups.reduce(
      (highestSequence, tg) =>
        Math.max(highestSequence, tg.modelType === 'task' ? tg.group?.sequence : tg.sequence || -1), //default to -1 because dropplaceholders won't have a sequence value
      -1
    );
    return highestSequence > -1
      ? mutableTaskGroups.find((tg) => highestSequence === (tg.modelType === 'task' ? tg.group?.sequence : tg.sequence))
      : {};
  }
};

const removeCompletedAndPassThroughTasksAndDropPlaceholders = (taskGroupsWithPlaceholders) => {
  return (
    taskGroupsWithPlaceholders
      //filter out dropplaceholders
      .filter((step) => step.modelType !== DROP_PLACEHOLDER)
      //filter out pass through tasks
      .filter((step) => step.modelType !== TASK || !step.passthrough)
      //filter out completed task groups and tasks
      .filter((step) => {
        if (step.modelType === TASK_GROUP) {
          const taskGroupWithoutDropPlaceholders = {
            tasks: step.tasks.filter((task) => task.modelType !== DROP_PLACEHOLDER)
          };
          const allTasksInTaskGroupAreCompleted = isTaskGroupCompleted(taskGroupWithoutDropPlaceholders);
          //if not all tasks in the task group are marked completed, we need to keep that task group because some tasks may still be completed
          return !allTasksInTaskGroupAreCompleted;
        } else if (step.modelType === TASK) {
          return step.status !== COMPLETED;
        }
        return false;
      })
  );
};

/**
 *
 */
export const isInProgressTaskGroup = (item, inProgressStep, flags) => {
  if (!item || !inProgressStep) return false;
  const inProgressStepSequence =
    inProgressStep?.modelType === 'task' ? inProgressStep.group?.sequence : inProgressStep.sequence;

  return (
    item.id === inProgressStep?.id ||
    (item.status === IN_PROGRESS && item.passthrough) ||
    ((item.declined || [DECLINED, PENDING].indexOf(item.status) > -1) &&
      item.passthrough &&
      item.group.sequence < inProgressStepSequence)
  );
};
