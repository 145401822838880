import React from 'react';
import { CreateStringWithURLClickable } from 'utils/createStringWithURLClickable.js';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, List, Tooltip } from 'antd';
import { vehiclesActions } from 'store/vehiclesStore';
import { tasksActions } from 'store/tasksStore';
import { detailContents, features, communicationContexts } from 'app-constants';
import { dateTimeFromNow } from 'utils/dateTimeUtils';
import { CommunicationBase } from './communicationTypes';
import { CommunicationImages } from './common';
import { CommonLinkButton } from 'components/styledComponents';
import { useFeatures } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { vdpActions } from 'store/vdpStore';
import { NEW } from '../../app-constants/reconPlanStatusTypes';

const { Text } = Typography;

const CommunicationComponent = ({
  userId,
  firstName,
  lastName,
  fullName,
  location,
  reconTaskTypeName,
  content,
  comment,
  images,
  createdOn,
  addTaskTypeLabel = false
}) => {
  const initials = `${firstName[0]}${lastName[0]}`.toUpperCase();
  const contentElement = !!content ? (
    <Tooltip
      placement="left"
      overlayClassName="communication-tooltip"
      title={
        <StyledContentTooltip>
          {<CreateStringWithURLClickable content={content} comment={comment} />}
        </StyledContentTooltip>
      }
    >
      <StyledCommunication>
        <CommunicationImages tiny images={images} createdOn={createdOn} reconTaskTypeName={reconTaskTypeName} />
        <StyledContent>
          {<CreateStringWithURLClickable content={content} comment={comment} showEllipsis={true} />}
        </StyledContent>
      </StyledCommunication>
    </Tooltip>
  ) : (
    <StyledContent>
      <CommunicationImages tiny images={images} createdOn={createdOn} reconTaskTypeName={reconTaskTypeName} />
      {<CreateStringWithURLClickable content={content} comment={comment} />}
    </StyledContent>
  );

  return (
    <CommunicationBase
      // id={id}          Don't set the id here in CommunicationsSnippets, the id used to identify which communication to scroll to for the FullCommunications and the VDP/TDP, if the id is set here, then we'll have 2 nodes with the same id and the scrollTo won't scroll to the actual one we want
      authorId={userId}
      authorName={fullName}
      authorInitials={initials}
      label={addTaskTypeLabel ? reconTaskTypeName : null}
      datetime={dateTimeFromNow(createdOn)}
      hoverDateTime={createdOn}
      content={contentElement}
      location={location}
    />
  );
};

const CommunicationsSnippet = React.memo(
  ({ isLoading = false, vehicle, task = null, isSelected, setSelectedCard, location }) => {
    const dispatch = useDispatch();
    const [hasCommentInventory, hasCommentTasks] = useFeatures(features.INVENTORY_COMMENT, features.TASKS_COMMENT);
    const canComment = task ? hasCommentTasks : hasCommentInventory;
    const { isShowLoadingSendCommunication } = useSelector((state) => state.vehicles);
    const communications =
      location === communicationContexts.VEHICLE_CARD
        ? vehicle?.comments?.items?.slice(0, 2).sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))
        : task?.comments?.items?.slice(0, 2).sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1)) ?? [];

    const handleShowCommunication = (initialFocus) => (e) => {
      if (task) {
        dispatch(tasksActions.getCommentsForTaskEntity(vehicle.id, task.id));
        setSelectedCard({ contentType: detailContents.COMMUNICATIONS, model: task, initialFocus });
      } else {
        // If the communication count <= 2 and click on inventory list
        // Show the input vehicle note, not button create note
        if (vehicle?.comments?.count <= 2) {
          dispatch(vehiclesActions.setShowVehicleNote(true));
        }
        dispatch(vdpActions.getCommentsForVehicle(vehicle.id));

        dispatch(vdpActions.setModel(vehicle.id));
        dispatch(vdpActions.setContentType(detailContents.COMMUNICATIONS));
        dispatch(vdpActions.setInitialFocus(initialFocus));
      }
    };

    const communicationCount = task ? task.comments?.count || 0 : vehicle.comments?.count || 0;
    const showSeeMore = communicationCount > 2 || canComment;

    const SeeMoreTextMessaging = (communicationCount, location) => {
      if (location === communicationContexts.VEHICLE_CARD) {
        if (communicationCount > 2) {
          return `See All ${communicationCount} Communications`;
        } else {
          return 'Create a Note';
        }
      } else {
        if (communicationCount > 2) {
          return `See All ${communicationCount} Messages`;
        } else {
          return 'Send a Message';
        }
      }
    };

    return (
      <StyledContainer>
        {isLoading || isShowLoadingSendCommunication ? (
          <Skeleton count={3} />
        ) : (
          <>
            {communications && (
              <>
                <StyledListContainer>
                  <StyledList
                    dataSource={communications}
                    renderItem={(c) => (
                      <CommunicationComponent
                        {...c}
                        key={c.id}
                        comment={c}
                        addTaskTypeLabel={task ? false : c.reconTaskId}
                        location={location}
                      />
                    )}
                    location={location}
                  />
                </StyledListContainer>
                {showSeeMore && vehicle.inventoryType !== NEW && (
                  <StyledSeeMoreContainer>
                    <CommonLinkButton
                      disabled={isSelected}
                      style={{ fontSize: 'inherit', marginTop: '-4px' }}
                      onClick={handleShowCommunication(task ? task.id : vehicle.id)}
                    >
                      {SeeMoreTextMessaging(communicationCount, location)}
                    </CommonLinkButton>
                  </StyledSeeMoreContainer>
                )}
              </>
            )}
          </>
        )}
      </StyledContainer>
    );
  }
);

//#region Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const StyledListContainer = styled.div`
  flex: 1 1 0px;
`;

const StyledList = styled(List).attrs(({ location }) => ({
  size: 'small',
  locale: {
    emptyText:
      location === communicationContexts.VEHICLE_CARD ? 'No communications to display' : 'No messages to display'
  }
}))`
  /* This gets the 'No communications to display' empty text to be centered */
  .ant-list& {
    height: 100%;
    .ant-spin-nested-loading {
      height: inherit;
      .ant-spin-container {
        height: inherit;
        .ant-list-empty-text {
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.colors.gray};
        }
      }
    }
  }
`;

const StyledSeeMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 24px;
  width: 100%;
`;
const StyledContent = styled(Text).attrs({
  ellipsis: true
})`
  width: 100%;
`;
const StyledContentTooltip = styled.p`
  max-height: 200px;
  overflow-y: auto;
`;

const StyledCommunication = styled.span`
  display: flex;
  margin-bottom: 7px;
`;
//#endregion

export default withLDConsumer()(CommunicationsSnippet);
