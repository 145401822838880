import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';

import { stripReferences } from 'utils/arrayUtils';
import { DROP_PLACEHOLDER, MAIN, TASK_GROUP, TASK, TASK_GROUP_TASK } from './types';
import { isTaskGroupIncomplete, isTaskGroupCompleted, isTaskIncomplete, isTaskComplete } from './functions';
import { COMPLETED, DECLINED, PENDING } from 'app-constants/taskStatusTypes';

//#region moveItems functions
export const dragItemAtMainLevel = (taskGroups, draggedItemData, droppedOnItemData) => {
  const newTaskGroups = stripReferences(taskGroups);
  const draggedItem = newTaskGroups.splice(draggedItemData.index, 1)[0];
  newTaskGroups.splice(droppedOnItemData.index, 0, draggedItem);
  return newTaskGroups;
};

export const reorderExistingTaskGroup = (taskGroups, draggedItemData, droppedOnItemData) => {
  const newTaskGroups = stripReferences(taskGroups);

  //task group that is being reordered
  const sourceGroup = newTaskGroups?.find((taskGroup) => taskGroup.id === droppedOnItemData.parentGroupId);
  //find index of dragged item in that group
  const draggedItemIndex = sourceGroup?.tasks?.findIndex((task) => task.id === draggedItemData.id);
  //extract that draggedItem
  const [draggedItem] = sourceGroup?.tasks?.splice(draggedItemIndex, 1);
  //find where the drop placeholder is that the dragged item was dropped on
  const droppedOnItemIndex = sourceGroup?.tasks?.findIndex((task) => task.id === droppedOnItemData.id);
  sourceGroup.tasks.splice(droppedOnItemIndex, 0, draggedItem);

  return newTaskGroups;
};

export const moveTaskFromOneTaskGroupToAnother = (
  taskGroups,
  draggedItemData,
  droppedOnItemData,
  setStructureForRemainingSourceTasks
) => {
  const newTaskGroups = stripReferences(taskGroups);

  const sourceGroup = newTaskGroups?.find((x) => x.id === draggedItemData.parentGroupId);

  //find index of dragged item in that group
  const draggedItemIndex = sourceGroup?.tasks?.findIndex((task) => task.id === draggedItemData.id);
  //extract that draggedItem
  const [draggedItem] = sourceGroup?.tasks?.splice(draggedItemIndex, 1);

  const destinationGroup = newTaskGroups?.find((x) => x.id === droppedOnItemData.parentGroupId);
  draggedItem.parentGroupId = destinationGroup?.id;

  const droppedOnItemIndex = destinationGroup?.tasks?.findIndex((x) => x.id === droppedOnItemData.id);
  destinationGroup.tasks.splice(droppedOnItemIndex, 0, draggedItem);
  setStructureForRemainingSourceTasks(sourceGroup, newTaskGroups, draggedItemData);

  return newTaskGroups;
};

export const moveTaskInTopDropAreaIntoATaskGroup = (taskGroups, droppedOnItemData, draggedItemData) => {
  const newTaskGroups = stripReferences(taskGroups);
  const destinationGroup = newTaskGroups?.find((x) => x.id === droppedOnItemData.parentGroupId);
  const draggedItem = newTaskGroups?.splice(draggedItemData.index, 1)[0];
  draggedItem.modelType = TASK_GROUP_TASK;
  draggedItem.parentGroupId = droppedOnItemData?.parentGroupId;
  draggedItem.passthrough = false; //Tasks within a task group are not allowed to be pass through tasks

  const droppedOnItemIndex = destinationGroup?.tasks?.findIndex((x) => x.id === droppedOnItemData.id);
  destinationGroup.tasks.splice(droppedOnItemIndex, 0, draggedItem);

  return newTaskGroups;
};

export const moveTaskOutOfATaskGroup = (
  taskGroups,
  droppedOnItemData,
  draggedItemData,
  setStructureForRemainingSourceTasks
) => {
  const newTaskGroups = stripReferences(taskGroups);
  //find group of dragged item
  const sourceGroup = newTaskGroups?.find((taskGroups) => taskGroups.id === draggedItemData.parentGroupId);
  //find index of dragged item in that group
  const draggedItemIndex = sourceGroup?.tasks?.findIndex((task) => task.id === draggedItemData.id);
  const [draggedItem] = sourceGroup?.tasks?.splice(draggedItemIndex, 1);

  if (draggedItem) {
    draggedItem.modelType = TASK;
    draggedItem.parentGroupId = MAIN;
    draggedItem.taskGroupId = uuidv4();
    draggedItem.group = {
      id: draggedItem?.taskGroupId,
      name: 'Task Group',
      sequence: null
    };
  }

  newTaskGroups.splice(droppedOnItemData.index, 0, draggedItem);
  setStructureForRemainingSourceTasks(sourceGroup, newTaskGroups, draggedItemData);
  return newTaskGroups;
};

export const createNewTaskGroupByMergingTwoTasksIntoAGroup = (
  taskGroups,
  draggedItemData,
  droppedOnItemData,
  setStructureForRemainingSourceTasks,
  flags
) => {
  const newTaskGroups = stripReferences(taskGroups);

  let sourceGroup;
  let draggedItem;

  if (draggedItemData.parentGroupId === MAIN) {
    draggedItem = newTaskGroups?.splice(draggedItemData.index, 1)[0];
  } else {
    // task dragged out from a task group
    sourceGroup = newTaskGroups.find((x) => x.id === draggedItemData.parentGroupId);
    //find index of dragged item in that group
    const draggedItemIndex = sourceGroup?.tasks?.findIndex((task) => task.id === draggedItemData.id);
    //extract that draggedItem
    draggedItem = sourceGroup?.tasks?.splice(draggedItemIndex, 1)[0];
  }

  const modifiableSourceItem = stripReferences(draggedItem);

  const destinationItem = newTaskGroups.splice(
    newTaskGroups.findIndex((x) => x.id === droppedOnItemData.id),
    1
  )[0];
  const newGroupId = uuidv4();
  modifiableSourceItem.modelType = TASK_GROUP_TASK;
  modifiableSourceItem.parentGroupId = newGroupId;
  modifiableSourceItem.passthrough = false; //Tasks within a task group are not allowed to be pass through tasks

  destinationItem.modelType = TASK_GROUP_TASK;
  destinationItem.parentGroupId = newGroupId;
  destinationItem.passthrough = false; //Tasks within a task group are not allowed to be pass through tasks
  const newTaskGroup = {
    id: newGroupId,
    name: 'Task Group',
    sequence: Math.min(draggedItemData.parentSequence, droppedOnItemData.parentSequence),
    modelType: TASK_GROUP,
    parentGroupId: MAIN,
    tasks: [destinationItem, modifiableSourceItem].map((task, index) => {
      let t = { ...task, groupSequence: index + 1 };
      ['group', 'parentGroupId', 'modelType', 'parentSequence'].forEach((key) => delete t[key]);
      return t;
    }),
    dealerId: destinationItem.dealerId,
    vehicleId: destinationItem.vehicleId
  };

  newTaskGroups.splice(droppedOnItemData.index, 0, newTaskGroup);

  if (sourceGroup) {
    setStructureForRemainingSourceTasks(sourceGroup, newTaskGroups, draggedItemData);
  }

  return newTaskGroups;
};

/**
 * addTaskToEndOfATaskGroup
 * function which runs when a task is dropped onto a task group in any other place other than a drop placeholder
 *
 * @param {object array} taskGroups - same as param taskGroups for dragItemAtMainLevel
 * @param {object} draggedItemData  - same as param draggedItemData for dragItemAtMainLevel
 * @param {object} droppedOnItemData  - can be either a task group or a task group task - this function runs when a task is dropped on either type
 */
export const addTaskToEndOfATaskGroup = (taskGroups, droppedOnItemData, draggedItemData) => {
  const mutableTaskGroups = stripReferences(taskGroups);
  //droppedOnItem can either be a task group or a task group task. to know what the right id is to find the group by an id, we need to first check the type that we are dealing with
  const destinationTaskGroupId =
    droppedOnItemData.modelType === TASK_GROUP ? droppedOnItemData.id : droppedOnItemData.parentGroupId;
  const destinationGroup = mutableTaskGroups?.find((taskGroup) => taskGroup.id === destinationTaskGroupId);
  //find index of dragged item in that group
  const draggedItemIndex = mutableTaskGroups?.findIndex((taskGroup) => taskGroup.id === draggedItemData.id);
  //extract that draggedItem
  const [draggedItem] = draggedItemIndex > -1 ? mutableTaskGroups.splice(draggedItemIndex, 1) : [];
  if (draggedItem) {
    draggedItem.modelType = TASK_GROUP_TASK;
    draggedItem.parentGroupId = destinationTaskGroupId;
    draggedItem.passthrough = false; //Tasks within a task group are not allowed to be pass through tasks
    destinationGroup?.tasks && destinationGroup.tasks.push(draggedItem);
  }

  return mutableTaskGroups;
};

/**
 * addTaskGroupTaskFromOneTaskGroupToTheEndOfAnotherTaskGroup
 * function which runs when a task group task is dropped onto a task group in any other place other than a drop placeholder
 *
 * @param {object array} taskGroups - same as param taskGroups for dragItemAtMainLevel
 * @param {object} draggedItemData  - same as param draggedItemData for dragItemAtMainLevel
 * @param {object} droppedOnItemData  - can be either a task group or a task group task - this function runs when a task is dropped on either type
 * @param {object} setStructureForRemainingSourceTasks - function which determines what the structure for the remaining task group that was dragged from should be after the task group task has been dragged out of it
 */
export const addTaskGroupTaskFromOneTaskGroupToTheEndOfAnotherTaskGroup = (
  taskGroups,
  draggedItemData,
  droppedOnItemData,
  setStructureForRemainingSourceTasks
) => {
  const mutableTaskGroups = stripReferences(taskGroups);
  const sourceGroup = mutableTaskGroups?.find((x) => x.id === draggedItemData.parentGroupId);

  //find index of dragged item in that group
  const draggedItemIndex = sourceGroup?.tasks?.findIndex((task) => task.id === draggedItemData.id);
  //extract that draggedItem
  const [draggedItem] = draggedItemIndex > -1 ? sourceGroup?.tasks?.splice(draggedItemIndex, 1) : [];
  if (draggedItem) {
    //droppedOnItem can either be a task group or a task group task. to know what the right id is to find the group by an id, we need to first check the type that we are dealing with
    const destinationTaskGroupId =
      droppedOnItemData.modelType === TASK_GROUP ? droppedOnItemData.id : droppedOnItemData.parentGroupId;
    const destinationGroup = mutableTaskGroups?.find((x) => x.id === destinationTaskGroupId);
    //set properties of the draggedItem to make it a task group task belong to its new task group home
    draggedItem.parentGroupId = destinationGroup?.id;

    destinationGroup?.tasks && destinationGroup.tasks.push(draggedItem);

    setStructureForRemainingSourceTasks(sourceGroup, mutableTaskGroups, draggedItemData);
  }

  return mutableTaskGroups;
};
//#endregion

export const removeAllDropPlaceholders = (structuredTaskGroups) => {
  // create mutable copy of parameter structuredTaskGroups and strip away references for objects in the array structuredTaskGroups
  const mutableTaskGroups = stripReferences(structuredTaskGroups);

  return (
    mutableTaskGroups
      //filter out top level drop placeholders
      .filter((step) => step.modelType !== DROP_PLACEHOLDER)
      .map((step) => {
        if (step.modelType === TASK_GROUP) {
          //we need to remove (filter out) drop placeholders from task group's tasks as well
          return {
            ...step,
            tasks: [...step.tasks.filter((task) => task.modelType !== DROP_PLACEHOLDER)]
          };
        } else {
          //the only other top level modelType will be TASK, so we want to keep these
          return step;
        }
      })
  );
};

export const setTaskStatusIfNotCompletedOrDeclined = (task, taskStatus, flags) => {
  return task.declined || [COMPLETED, DECLINED].includes(task.status) ? task : { ...task, status: taskStatus };
};

export const setTaskStatusesToPending = (taskGroup, flags) =>
  taskGroup.tasks.map((task) => setTaskStatusIfNotCompletedOrDeclined(task, PENDING, flags));

/**
 * resequence
 * function that runs as part of the prepareTaskGroupsDataForRerender function in DndContainer
 * it is part of the cleanup required after reordering the task groups array in moveItems
 *
 * @param {object array} taskGroups - newly sorted array of taskGroups after operations in moveItems
 * @param {int} startIndex - the index at which to start resequencing (to preserve order of pass through tasks in the plan)
 */
export const resequence = (taskGroups) =>
  taskGroups.map((item, index) => {
    const tasksList = item.tasks ? [...item.tasks] : [];
    return {
      ...item,
      sequence: index + 1,
      // if the item type is a task group than we need to run through the tasks in the task group and resequence its tasks
      // if not, then we still need to preserve its tasks array if there are any tasks
      // finally, if there isn't a tasks array in the data, than we need to add an empty one
      tasks: item.type === TASK_GROUP ? item.tasks.map((t, i) => ({ ...t, sequence: i + 1 })) : tasksList
    };
  });
export const resequenceUS510688 = (taskGroups, startSequence) => {
  /**
   * Order task groups correctly before running .map on it
   * Create array of the completed items (ordered by sequence)
   * Create array of incomplete pass through tasks with sequence lower than the min of the highest completed task sequence and the startSequence (ordered by sequence)
   * Create array of remaining items, NOT ordered by sequence - these are the ones we're reordering to match the order they just got shuffled into
   * orderedTaskGroups = [...preservedPassThroughTasks, ...completedTaskGroups, ...restOfTaskGroups];
   */
  let completedItems = orderBy(
    taskGroups.filter((item) => (item.hasOwnProperty('tasks') ? isTaskGroupCompleted(item) : isTaskComplete(item))),
    'sequence'
  );
  let passThroughSequenceCutoff = Math.min(
    completedItems.length > 0 ? completedItems[completedItems.length - 1].sequence : startSequence,
    startSequence
  );
  let preservedIncompletePassThroughTasks = orderBy(
    taskGroups.filter(
      (item) =>
        item.passthrough &&
        item.sequence < passThroughSequenceCutoff &&
        (item.hasOwnProperty('tasks') ? !isTaskGroupCompleted(item) : !isTaskComplete(item))
    ),
    'sequence'
  );
  let idsOfAccountedForItems = completedItems
    .map((x) => x.id)
    .concat(preservedIncompletePassThroughTasks.map((x) => x.id));
  let restOfItems = taskGroups.filter((item) => idsOfAccountedForItems.indexOf(item.id) < 0);
  let orderedTaskGroups = [...preservedIncompletePassThroughTasks, ...completedItems, ...restOfItems];
  let numTasks = 0;
  // Now resequence the ordered task groups (so we can use the index in the array to determine new sequence values)
  let resequencedOrderedTaskGroups = orderedTaskGroups.map((item, index) => {
    if (!!item.sequence && item.sequence < startSequence) {
      numTasks += item.tasks ? item.tasks.length : 1;
      return item;
    }

    const sequence = index + 1;

    if (item.modelType === TASK_GROUP) {
      // If the item is a task group, sequence the child tasks
      return {
        ...item,
        sequence,
        tasks: item.tasks.map((t) => ({ ...t, sequence: ++numTasks }))
      };
    } else {
      // Else it is a standalone task, sequence the task itself, and update the group object to match
      return {
        ...item,
        sequence: ++numTasks,
        group: { ...item.group, sequence }
      };
    }
  });

  // Put the items back in the order they were passed in, since this ordering is used for rendering!
  return taskGroups.map((item) => resequencedOrderedTaskGroups.find((x) => x.id === item.id));
};

/**
 * isStepCompleted, isStepIncomplete
 * functions that are used in DndContainer when prepping the data for re-rendering
 * specifically invoked in the setTaskStatuses function
 *
 * @param {object} step - a step is either a task group or a task (determined by checking modelType)
 */

//an incomplete task item is either an incomplete single task or a task group that has at least one task that is not complete
export const isStepIncomplete = (step) => {
  if (step.modelType === TASK_GROUP) {
    return isTaskGroupIncomplete(step);
  } else {
    return isTaskIncomplete(step);
  }
};
