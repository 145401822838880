import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

//our components, functions and other 
import DraggableItem from './DraggableItem';
import DropPlaceholder from './DropPlaceholder';
import { RECON_PLAN } from 'app-constants/planTypes';
import { DROP_PLACEHOLDER } from './helpers/types';
import { getInProgressStep, isInProgressTaskGroup } from './helpers/functions';
import { draggableItemType } from './helpers/propTypes';
import { useDragDropContext } from 'utils/contexts';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

//renders the list of task groups with placeholders
const DragList = ({ taskGroupsWithDropPlaceholders, moveItem, flags }) => {
  const { planSource } = useDragDropContext();
  const [draggedItemId, setDraggedItemId] = useState(null);

  //want to determine if task item that is in progress is a task group or a single task
  //if it is a task group, then this will be used to pass additional information to its tasks to make sure that certain information such as workload is shown for all tasks
  const inProgressStep = getInProgressStep(taskGroupsWithDropPlaceholders);

  return taskGroupsWithDropPlaceholders.map((item, index) => {
    const isInProgress = isInProgressTaskGroup(item, inProgressStep, flags);
    return item.modelType === DROP_PLACEHOLDER
    ? <DropPlaceholder
        key={item.id}
        index={index}
        draggedItemId={draggedItemId}
        {...item}
        moveItem={moveItem}
      />
    : <DraggableItem
        key={item.id}
        index={index}
        isFirstInList={index === 0}
        draggedItemId={draggedItemId}
        setDraggedItemId={setDraggedItemId}
        isInProgressTaskGroup={planSource === RECON_PLAN && isInProgress}
        {...item}
        moveItem={moveItem}
      />;
  });
};

DragList.propTypes = {
  taskGroupsWithDropPlaceholders: PropTypes.arrayOf(draggableItemType),
  moveItem: PropTypes.func
};

export default withLDConsumer()(DragList);
