import React from 'react';
import styled from 'styled-components';
import { BasicLayeredTaskIcon, BaseHoverContent } from 'components/layout/tasks/icons';
import { taskProgressLabels, taskProgressColor } from 'app-constants';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { PENDING } from 'app-constants/taskStatusTypes';

const TaskClipboardIcon = ({ task, progress, flags, showClipBoarIconPlanTemplate, ...other }) => {
  // If this task is pending and Overdue or Past Goal, we want to show the regular
  // clipboard icon, but still show the progress warning in the popover content
  const hoverContent =
    !!progress &&
    task.status === PENDING &&
    [taskProgressLabels.PastGoal, taskProgressLabels.Overdue].indexOf(progress?.status) > -1 ? (
      <>
        <StyledHoverContentHeader color-task-progress={taskProgressColor[progress?.status]}>
          {progress.info}
        </StyledHoverContentHeader>
        <BaseHoverContent task={task} showLeftBorder={true} />
      </>
    ) : undefined; // Setting to undefined causes it to use default hoverContent defined on BasicLayeredTaskIcon

  return (
    <StyledBasicIcon
      task={task}
      reconTaskCollapseFlag={flags.reconTaskCollapse}
      {...other}
      foregroundIcon={faClipboard}
      backgroundIcon={null}
      foregroundTransform={'shrink-6.5'}
      showClipBoarIconPlanTemplate={showClipBoarIconPlanTemplate}
      customHoverContent={hoverContent}
    />
  );
};

const StyledBasicIcon = styled(BasicLayeredTaskIcon).attrs((props) => ({
  foregroundColor: props.theme.colors.navy
}))`
  margin-top: -4px;
  margin-left: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '-8px'};
  font-size: ${({ theme }) => theme.fontSizes.jumbo};
`;
const StyledHoverContentHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${(props) => props['color-task-progress']};
`;

export default withLDConsumer()(TaskClipboardIcon);
