import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CommonSearch } from '../styledComponents';

const SearchInput = ({ searchFunction, data, resetData, customHeight, setSearchValue }) => {
  const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);
  const [valueSearch, setValueSearch] = useState('');

  useEffect(() => {
    // if no search value, resets the state to unfiltered data    
    if (!valueSearch) {
      resetData(data);
      setSearchValue("");
    }
    searchFunction(valueSearch);
  }, [valueSearch]);

  return (
    <CommonSearch
      custom-height={customHeight}
      data-is-focused={searchBoxIsFocused}
      placeholder={searchBoxIsFocused ? 'Type to begin searching...' : 'Search'}
      prefix={<FontAwesomeIcon icon={faSearch} />}
      onFocus={() => setSearchBoxIsFocused(true)}
      onBlur={() => setSearchBoxIsFocused(false)}
      value={valueSearch}
      onChange={(e) => setValueSearch(e.target?.value)}
      onSearch={() => {
        searchFunction(valueSearch);
      }}
    />
  );
};

export default SearchInput;
