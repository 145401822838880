import React, { useState, useEffect } from 'react';

import { Row, Col } from 'antd';
import { Tooltip } from 'antd4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FormProvider, useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { VENDOR_USER_INFO, CoxAutoLogoutUrl, apiStatusConstants, onboardingStatusTypes } from 'app-constants';
import { theme } from 'styles/appTheme';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { vendorActions } from 'store/vendorStore';
import CustomForm from './CustomForm';
import {
  StyledLinkButton,
  StyledLayout,
  StyledLayoutContent,
  StyledLayoutFooter,
  StyledLayoutHeader,
  StyledButton,
  StyledTitleText,
  StyledSubTitleText,
  StyledFormTitle,
  CenteredRow,
  StyledModal,
  StyledSignOutText,
  StyledStepText,
  StyledBoldSubTitleText
} from './style';
import {
  businessAddressValidationSchema,
  businessDetailsValidationSchema,
  data,
  informationValidationSchema
} from './data';
import { regExp } from 'app-constants/regExp';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useQueryParam } from 'use-query-params';

const SignOutCenterText = ({ signOut }) => {
  return (
    signOut && (
      <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
        <Col>
          <StyledSignOutText>
            Your profile must be complete before you can begin receiving tasks from dealers. Are you sure you want to
            sign out?
          </StyledSignOutText>
        </Col>
      </Row>
    )
  );
};

const getStepText = (current) => {
  if (current < 2) {
    return `Step ${current + 1} of 3`;
  } else {
    return `Step 3 of 3 - almost complete!`;
  }
};

const checkData = (onboardingStatus, vendorId) => {
  return onboardingStatus === onboardingStatusTypes.REGISTERED && vendorId;
};
const parseLocalStorageData = (localData) => {
  return localData ? JSON.parse(localData) : {};
};

const GeneralSetting = ({ flags }) => {
  const [current, setCurrent] = useState(0);
  const [signOut, setSignout] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState([]);
  const [dealerName, setDealerName] = useState('');
  const [dealerUserName, setDealerUserName] = useState('');

  const dispatch = useDispatch();

  const vendorUserInfoData = localStorage.getItem(VENDOR_USER_INFO);
  const vendorUserInfo = parseLocalStorageData(vendorUserInfoData);
  const confirmStatus = useSelector((state) => state.vendors.confirmStatus);
  const vendorFetchStatus = useSelector((state) => state.vendors.fetchStatus);
  const vendor = useSelector((state) => state.vendors.vendor);

  const [dealerId] = useQueryParam('dealerId');

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const updateFormData = (index, newValues) => {
    const copy = formData.length === 0 ? [...data] : [...formData];
    let currentFormData = copy[index].data;
    Object.keys(newValues).forEach((newValueKey) => {
      const toUpdateIndex = currentFormData.findIndex((formEntry) => formEntry.name === newValueKey);
      currentFormData[toUpdateIndex].value = newValues[newValueKey];
    });

    setFormData(copy);
  };

  const persistData = (toPersistData, goNext) => {
    if (toPersistData) {
      updateFormData(current, toPersistData);
      if (goNext) {
        next();
      } else {
        prev();
      }
    } else {
      const form = forms[current];
      updateFormData(current, form.getValues());
    }
  };

  const informationFormResolver = useYupValidationResolver(informationValidationSchema);
  const businessDetailsFormResolver = useYupValidationResolver(businessDetailsValidationSchema);
  const businessAddressFormResolver = useYupValidationResolver(businessAddressValidationSchema);

  const informationForm = useForm({ mode: 'onSubmit', resolver: informationFormResolver });
  const businessDetailsForm = useForm({ mode: 'onSubmit', resolver: businessDetailsFormResolver });
  const businessAddressForm = useForm({ mode: 'onSubmit', resolver: businessAddressFormResolver });
  const forms = [informationForm, businessDetailsForm, businessAddressForm];

  const onClickNext = (e) => {
    forms[current].handleSubmit((d) => persistData(d, true))(e);
    e.currentTarget.blur();
  };

  const onClickPrev = (e) => {
    persistData();
    prev();
    e.currentTarget.blur();
  };

  const onClickSignout = () => {
    //no need to check if this is new sign in as this page is only available through new sign in
    localStorage.removeItem(VENDOR_USER_INFO);
    navigate(CoxAutoLogoutUrl);
  };

  const onClickFirstSignout = () => {
    persistData();
    setSignout(true);
  };

  const onClickBackSignout = () => {
    setSignout(false);
  };

  const onConfirmClick = (e) => {
    forms[current].handleSubmit(confirm)(e);
    e.currentTarget.blur();
  };

  const confirm = () => {
    const informationValues = informationForm.getValues();
    const businessProfileValues = businessDetailsForm.getValues();
    const businessAddressValues = businessAddressForm.getValues();
    const { email, ...primaryContact } = informationValues;
    primaryContact.phone = primaryContact.phone.replace(regExp.NOT_NUMBERS, '');
    const toPost = {
      primaryContact,
      businessProfile: {
        name: businessProfileValues.businessName,
        phone: businessProfileValues.businessPhone.replace(regExp.NOT_NUMBERS, ''),
        email: businessProfileValues.businessEmail,
        address: businessAddressValues.address,
        address2: businessAddressValues.address2,
        postalCode: businessAddressValues.postalCode,
        country: businessAddressValues.country,
        state: businessAddressValues.state,
        city: businessAddressValues.city
      }
    };
    if (flags.reconVendorWorkflow2023 && !!dealerId) {
      toPost.dealerId = dealerId;
    }
    dispatch(vendorActions.confirmBusinessProfile(vendorUserInfo.vendorId, toPost));
  };

  const getTooltipWidth = () => {
    if (current === 0) {
      return '286px';
    } else if (current === 1) {
      return '327px';
    } else {
      return '277px';
    }
  };

  useEffect(() => {
    // reset confirmStatus when exiting this page
    return () => dispatch(vendorActions.setConfirmStatus(apiStatusConstants.PENDING));
  }, []);

  useEffect(() => {
    if (flags.reconVendorWorkflow2023 && !!vendorUserInfo.vendorId && !!dealerId) {
      dispatch(vendorActions.getVendor(vendorUserInfo.vendorId, dealerId));
    }
  }, [flags.reconVendorWorkflow2023]);

  useEffect(() => {
    //no need to check if this is new sign in as this page is only available through new sign in
    if (
      !!vendorUserInfo.vendorId &&
      (!flags.reconVendorWorkflow2023 ||
        !dealerId ||
        ![apiStatusConstants.IS_FETCHING, apiStatusConstants.SUCCEEDED, apiStatusConstants.PENDING].includes(
          vendorFetchStatus
        ))
    ) {
      setDealerName(vendorUserInfo.dealerName ?? '');
      setDealerUserName(vendorUserInfo.dealerUserName ?? '');
    }
  }, [vendorUserInfoData, flags.reconVendorWorkflow2023, vendorFetchStatus]);

  useEffect(() => {
    if (
      !!vendor &&
      vendor.onboardingStatus === onboardingStatusTypes.REGISTERED &&
      !!vendor.userData?.inviteData &&
      flags.reconVendorWorkflow2023
    ) {
      updateFormData(0, {
        firstName: vendor.userData.inviteData.firstName,
        lastName: vendor.userData.inviteData.lastName,
        email: vendor.email,
        phone: vendor.userData.inviteData.phoneNumber
          ? vendor.userData.inviteData.phoneNumber.replace(regExp.NOT_NUMBERS, '')
          : ''
      });
      updateFormData(1, {
        businessName: vendor.userData.inviteData.businessName ?? ''
      });

      setDealerName(vendor.userData.inviteData.dealerName ?? '');
      setDealerUserName(vendor.userData.inviteData.dealerUserName ?? '');
    } else if (checkData(vendorUserInfo.vendorOnboardingStatus, vendorUserInfo.vendorId)) {
      if (flags.reconVendorWorkflow2023) {
        updateFormData(0, { email: vendorUserInfo.email });
      } else {
        updateFormData(0, {
          firstName: vendorUserInfo.firstName,
          lastName: vendorUserInfo.lastName,
          email: vendorUserInfo.email
        });
        updateFormData(1, {
          businessName: vendorUserInfo.businessName ?? '',
          businessPhone: vendorUserInfo.businessPhone
            ? vendorUserInfo.businessPhone.replace(regExp.NOT_NUMBERS, '')
            : ''
        });
      }
    }
  }, [vendor, flags.reconVendorWorkflow2023]);

  useEffect(() => {
    if (confirmStatus === apiStatusConstants.SUCCEEDED) {
      //confirm business profile succeeded, navigate to tasks
      if (flags.reconVendorWorkflow2023) {
        window.location.href = `${window.location.origin}/`;
      } else {
        navigate('/');
      }
    }
  }, [confirmStatus, flags.reconVendorWorkflow2023]);

  return (
    formData.length > 0 &&
    vendorUserInfo.vendorId &&
    (!flags.reconVendorWorkflow2023 || !dealerId || vendorFetchStatus !== apiStatusConstants.IS_FETCHING) && (
      <StyledModal visible={true} footer={null} closable={null}>
        <StyledLayout>
          <StyledLayoutHeader style={{ paddingBottom: '8px', paddingRight: '1px', paddingLeft: '1px' }}>
            <CenteredRow gutter={[16, 16]} style={{ height: '100%' }}>
              <Col xs={24}>
                <CenteredRow gutter={[7, 7]}>
                  <Col>
                    <StyledTitleText>Welcome to iRecon</StyledTitleText>
                  </Col>

                  <Col xs={22} style={{ textAlign: 'center' }}>
                    {current === 2 && !signOut ? (
                      <StyledSubTitleText>
                        Please input an accurate business address to make it easier for nearby dealers to start working
                        with you.
                      </StyledSubTitleText>
                    ) : (
                      <>
                        <StyledBoldSubTitleText>{`${dealerUserName} `}</StyledBoldSubTitleText>
                        <StyledSubTitleText>has invited you to work with </StyledSubTitleText>
                        <StyledBoldSubTitleText>{`${dealerName}. `}</StyledBoldSubTitleText>
                        <StyledSubTitleText>
                          Please confirm your personal and business information to begin using iRecon.
                        </StyledSubTitleText>
                      </>
                    )}
                  </Col>

                  {!signOut && (
                    <Col xs={24} style={{ marginTop: '9px' }}>
                      <Row type="flex" justify="start" align="middle">
                        <Col>
                          <StyledFormTitle>{formData[current].title}</StyledFormTitle>
                        </Col>

                        <Col style={{ marginLeft: '8.25px', height: '16px' }}>
                          <Tooltip
                            placement="top"
                            title={formData[current].tooltip}
                            overlayClassName="general-setting-tooltip"
                            overlayInnerStyle={{
                              backgroundColor: theme.colors.white,
                              color: theme.colors.navy,
                              fontWeight: theme.fontWeights.normal,
                              fontSize: theme.fontSizes.sm,
                              lineHeight: theme.lineHeights.sm,
                              letterSpacing: theme.letterSpacings.md,
                              width: getTooltipWidth()
                            }}
                            onOpenChange={(open) => setVisible(open)}
                            mouseLeaveDelay={0}
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              style={{
                                width: '16px',
                                height: '16px',
                                color: visible ? theme.colors.navy : theme.colors.darkGray
                              }}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </CenteredRow>
              </Col>
            </CenteredRow>
          </StyledLayoutHeader>

          <StyledLayoutContent
            style={{ overflow: 'auto', overflowX: 'hidden', paddingRight: '1px', paddingLeft: '1px' }}
          >
            <SignOutCenterText signOut={signOut} />

            {!signOut && (
              <CenteredRow gutter={[16, 16]}>
                <Col xs={24}>
                  <FormProvider {...forms[current]}>
                    <CustomForm formInputs={formData[current]} />
                  </FormProvider>
                </Col>
              </CenteredRow>
            )}
          </StyledLayoutContent>

          <StyledLayoutFooter style={{ backgroundColor: '#ffffff', position: 'sticky', padding: 0 }}>
            {signOut && (
              <Row type="flex" justify="end" gutter={[8, 8]}>
                <Col>
                  <StyledButton ghost onClick={onClickBackSignout}>
                    <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: '8px' }} />
                    Back
                  </StyledButton>
                </Col>

                <Col>
                  <StyledButton onClick={onClickSignout}>Sign out</StyledButton>
                </Col>
              </Row>
            )}

            {!signOut && (
              <Row type="flex" justify="end" gutter={[8, 8]} style={{ paddingTop: '4px' }}>
                <Col>
                  <StyledStepText>{getStepText(current)}</StyledStepText>
                </Col>

                <Col xs={24}>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <StyledLinkButton onClick={onClickFirstSignout}>Sign Out</StyledLinkButton>
                    </Col>

                    <Col>
                      <Row type="flex" justify="space-between" gutter={8}>
                        {current >= 1 && (
                          <Col>
                            <StyledButton ghost onClick={onClickPrev}>
                              <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: '8px' }} size="lg" />
                              Back
                            </StyledButton>
                          </Col>
                        )}

                        <Col>
                          <StyledButton
                            onClick={current < 2 ? onClickNext : onConfirmClick}
                            disabled={
                              Object.keys(forms[current].formState.dirtyFields).length === 0 &&
                              !forms[current].formState.isValid
                            }
                            loading={confirmStatus === apiStatusConstants.IS_FETCHING}
                          >
                            {current < 2 ? (
                              <>
                                Next
                                <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: '8px' }} size="lg" />
                              </>
                            ) : (
                              'Submit'
                            )}
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </StyledLayoutFooter>
        </StyledLayout>
      </StyledModal>
    )
  );
};

export default withLDConsumer()(GeneralSetting);
