import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default ({ children, divStyle }) => (
  <div style={divStyle}>
    <FontAwesomeIcon icon={faExclamationCircle} />
    {'  '}
    {children}
  </div>
);
