// Code borrowed and modified from StackOverflow :)
// https://stackoverflow.com/questions/49751396/determine-if-element-is-behind-another
export const isBehindOtherElement = (element) => {
  if (!element) return false;

  const boundingRect = element.getBoundingClientRect();
  // adjust coordinates to get more accurate results
  const left = boundingRect.left + 1;
  const right = boundingRect.right - 1;
  const top = boundingRect.top + 1;
  const bottom = boundingRect.bottom - 1;

  let topLeft = document.elementFromPoint(left, top);
  let topRight = document.elementFromPoint(right, top);
  let botLeft = document.elementFromPoint(left, bottom);
  let botRight = document.elementFromPoint(right, bottom);

  return !([topLeft, topRight, botLeft, botRight].includes(element));
};

export const addClass = (className) => (classList) => classList?.add(className);
export const removeClass = (className) => (classList) => classList?.remove(className);

export const addSelectedBackgroundColorClass = addClass('selected-background-color');
export const removeSelectedBackgroundColorClass = removeClass('selected-background-color');

export const addSelectedBackgroundColorClassConditional = (boolean) => (classList) =>
  boolean && addSelectedBackgroundColorClass(classList);
export const removeSelectedBackgroundColorClassConditional = (boolean) => (classList) =>
  boolean && removeSelectedBackgroundColorClass(classList);

// Compares widths of two elements
export const compareElementWidthByClassName = (firstElementClassName, secondElementClassName) => {
  // Get width of first element
  const firstElement = document.querySelector(firstElementClassName);
  const firstElementWidth = firstElement?.clientWidth;

  // Get width of second element
  const secondElement = document.querySelector(secondElementClassName);
  const secondElementWidth = secondElement?.clientWidth;

  // Return true if first element is wider
  return (firstElementWidth > secondElementWidth);
}
