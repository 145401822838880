// Sorts by Pending, Active, Declined/Deactivated, then date descending
export const statusSort = (a, b, sortOrder) => {
  const statusRankDifference = a.statusRank - b.statusRank;

  if (statusRankDifference !== 0) {
    return statusRankDifference;
  }

  const aDate = new Date(a.statusDate);
  const bDate = new Date(b.statusDate);

  if (sortOrder === 'ascend') {
    return bDate - aDate;
  }

  // else
  return aDate - bDate;
};

export const sortInvitationHistory = (array) => {
  const editedDatesArray = array?.map((item) => {
    return { ...item, createdOn: new Date(item?.createdOn) };
  });
  
  return editedDatesArray?.sort((a, b) => b?.createdOn - a?.createdOn);
};
