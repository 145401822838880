import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTasks, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import OverflowMenu, { StyledMenu, MenuItemIcon } from './OverflowMenu';
import { useGetMenuDropdownData } from './helpers/hooks';
import { RECON_PLAN } from 'app-constants/planTypes';
import { features } from 'app-constants';
import { useFeatures } from 'hooks';
import { getOnClickHandler, getLineItemsClick } from './helpers/functions';
import { useSelector, useDispatch } from 'react-redux';
import { vdpActions } from 'store/vdpStore';
import { IN_PROGRESS } from 'app-constants/taskStatusTypes';
import { DEALER_INTERNAL_GROUP } from 'app-constants/groupTypes';
import { faCheckCircle as regularCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { authSelector } from 'store/authStore';
import { assigneeDataSelector } from 'store/usersStore';

const MenuDropdownReconTask = ({ needsApproval, flags, approved }) => {
  const { overflowMenuDisabled, reconPlanFeatures, planSource, task, menuItems, ...taskAccessoryInfo } =
    useGetMenuDropdownData(flags);
  const { internalGroups } = useSelector(assigneeDataSelector);
  const [currentMemberData, setCurrentMemberData] = useState(null);
  const { user } = useSelector(authSelector);
  const isTaskGroup = task && task.assignedToGroupId && task.assignedToGroupType === DEALER_INTERNAL_GROUP;
  const hasEditTasks = reconPlanFeatures?.[0];
  const hasWorkOwnTasks = reconPlanFeatures?.[1];
  const [hasDealerSettings, hasLineItemsEditRole] = useFeatures(features.DEALER_SETTINGS, features.LINE_ITEMS_EDIT);
  const selectedTask = useSelector((state) => state.vdp?.selectedTask);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isTaskGroup) {
      const currentGroup = internalGroups && internalGroups.find((group) => group.id === task.assignedToGroupId);
      const currentMember = currentGroup?.internalGroupUsers?.find((member) => member.userId === user.id);
      setCurrentMemberData(currentMember);
    } else {
      setCurrentMemberData(null);
    }
  }, [user, internalGroups, task]);

  const userOwnsThisTask = task?.assignedTo === user.id;
  const editableStatuses = [IN_PROGRESS];
  const hasOwnThisTask = hasWorkOwnTasks && userOwnsThisTask;

  // Internal tech can complete own task, internal tech is group leader can complete own task and group member's task
  const isTaskInProgress = !task.declined && editableStatuses.includes(task?.status);
  const groupLeaderCanCompleteTask = currentMemberData && currentMemberData.isGroupLeader && isTaskInProgress;
  const canCompleteTask = (hasOwnThisTask && isTaskInProgress) || groupLeaderCanCompleteTask;

  const isInternalTech = !hasEditTasks && !hasDealerSettings && hasLineItemsEditRole;
  const enableCompleteForInternalTech = isInternalTech && canCompleteTask && task?.assignedTo;

  useEffect(() => {
    //We will go to line item page if a task is selected and view is 'lineItems'
    //For other views, message page for example, we will handle them in here if needed
    if (selectedTask && selectedTask.taskId && selectedTask.display && selectedTask.taskId === task.id) {
      switch (selectedTask.display) {
        case 'lineItems':
          getLineItemsClick(task, taskAccessoryInfo);
          dispatch(vdpActions.setSelectedTask(null));
          break;
        case 'messages':
          //Go to message page
          getOnClickHandler('message', task, planSource, taskAccessoryInfo)();
          dispatch(vdpActions.setSelectedTask(null));
          break;
        default:
          break;
      }
    }
  }, [JSON.stringify(selectedTask)]);

  if (hasEditTasks) {
    return (
      <StyledOverflowMenu recon-task-collapse-flag={flags.reconTaskCollapse?.toString()}>
        <Dropdown
          disabled={overflowMenuDisabled}
          overlay={OverflowMenu(
            task,
            planSource,
            taskAccessoryInfo,
            menuItems,
            needsApproval,
            flags?.reconVendorManagement
          )}
        >
          <StyledMoreIcon
            data-disabled={overflowMenuDisabled}
            icon={faEllipsisH}
            recon-task-collapse-flag={flags.reconTaskCollapse?.toString()}
          />
        </Dropdown>
      </StyledOverflowMenu>
    );
  } else if (!hasLineItemsEditRole) {
    return (
      <StyledOverflowMenu recon-task-collapse-flag={flags.reconTaskCollapse?.toString()}>
        <Dropdown
          disabled={approved === 0}
          overlay={
            <StyledMenu>
              <Menu.Item
                key="Line Items"
                onClick={getOnClickHandler('Line Items', task, planSource, taskAccessoryInfo)}
              >
                <MenuItemIcon icon={faTasks} />
                Line Items
              </Menu.Item>
            </StyledMenu>
          }
        >
          <StyledMoreIcon
            data-disabled={approved === 0}
            icon={faEllipsisH}
            recon-task-collapse-flag={flags.reconTaskCollapse?.toString()}
          />
        </Dropdown>
      </StyledOverflowMenu>
    );
  } else if (enableCompleteForInternalTech) {
    return (
      <StyledOverflowMenu recon-task-collapse-flag={flags.reconTaskCollapse?.toString()}>
        <Dropdown
          overlay={
            <StyledMenu>
              <Menu.Item
                disabled={task?.needsApproval}
                key="complete"
                onClick={getOnClickHandler('complete', task, planSource, taskAccessoryInfo)}
              >
                <MenuItemIcon icon={task?.needsApproval ? regularCheckCircle : faCheckCircle} />
                Complete
                {!!task?.needsApproval && <span className="disabled-extra">{'\nTask has pending line items'}</span>}
              </Menu.Item>
            </StyledMenu>
          }
        >
          <StyledMoreIcon icon={faEllipsisH} recon-task-collapse-flag={flags.reconTaskCollapse?.toString()} />
        </Dropdown>
      </StyledOverflowMenu>
    );
  } else {
    return null;
  }
};

const MenuDropdownTemplateTask = ({ flags }) => {
  const { isSaving, planSource, task, menuItems, ...taskAccessoryInfo } = useGetMenuDropdownData(flags);

  return (
    <StyledOverflowMenu>
      <Dropdown disabled={isSaving} overlay={OverflowMenu(task, planSource, taskAccessoryInfo, menuItems)}>
        <StyledMoreIcon data-disabled={isSaving} icon={faEllipsisH} />
      </Dropdown>
    </StyledOverflowMenu>
  );
};

const MenuDropdown = ({ needsApproval = 0, flags, approved = 0 }) => {
  const { planSource, hideOverflowMenu } = useGetMenuDropdownData(flags);
  return hideOverflowMenu ? (
    <></>
  ) : (
    <>
      {planSource === RECON_PLAN ? (
        <MenuDropdownReconTask needsApproval={needsApproval} flags={flags} approved={approved} />
      ) : (
        <MenuDropdownTemplateTask flags={flags} />
      )}
    </>
  );
};

export default withLDConsumer()(MenuDropdown);

const StyledOverflowMenu = styled.div.attrs({
  className: 'center-content'
})`
  width: ${(props) => (props['recon-task-collapse-flag'] === 'true' ? '32px' : '40px')};
  height: 100%;
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props['data-disabled'] ? 'inherit' : 'pointer')};
  color: ${(props) => {
    if (props['data-disabled']) {
      return props.theme.colors.gray;
    } else {
      return props['recon-task-collapse-flag'] === 'true' ? props.theme.colors.darkGray : props.theme.colors.navy;
    }
  }};
  height: ${(props) => (props['recon-task-collapse-flag'] === 'true' ? '32px' : '20px')};
  padding: ${(props) => (props['recon-task-collapse-flag'] === 'true' ? '0 8px 5px 8px' : '0 8px 0 18px')};
  .svg-inline--fa.fa-w-16& {
    width: 100%;
  }
`;
