import { useEffect, useState } from 'react';

//app-constants
import { dispositionTypes } from 'app-constants';
const dispositionFilter = Object.entries(dispositionTypes).map(([value,label]) => ({value: value, label}));
const dispositionFilterWithAnyCategory = [ {value: 'ANY', label: 'Any'}, ...dispositionFilter ];

//dropdown option values (passed down into Dropdown components)
//parameters are in uppercase, but want to display to user Title case 

const timeSpanFilter = [
    { value: '7', label: 'last 7 days'}, 
    { value: '30', label: 'last 30 days'},
    { value: '90', label: 'last 90 days'}, 
    { value: 'customDateRange', label: 'Custom Range'}
];
//set both the date range filter and the disposition filter
const useSetDisplayedFilters = (vehicleDispositionFilter = [], setFilters = () => {}) => {
    const [displayedDispositionFilters, setDisplayedDispositionFilters] = useState(dispositionFilterWithAnyCategory);
    //vehicleDispositionFilter comes from hitting the dealer endpoint - it is part of the currentDealer information returned
    useEffect(() => {
        if (vehicleDispositionFilter.length > 0) {      
            setDisplayedDispositionFilters(
                [
                    {
                        value: 'ANY', 
                        label: 'Any'
                    },
                    ...vehicleDispositionFilter.map(disposition => ({
                            value: disposition, 
                            label: disposition.substr(0,1)+disposition.substr(1).toLowerCase()//need to change case to 'Retail' from 'RETAIL', etc.
                        })
                    )
                ]
            );
        } else {
            setDisplayedDispositionFilters(dispositionFilterWithAnyCategory);                 // Per Erin, if none are checked, show all dispos
        } 
    }, [vehicleDispositionFilter]);//update displayedDispositionFilters when vehicleDispositionFilter changes
    
    return [displayedDispositionFilters, timeSpanFilter];
}

export default useSetDisplayedFilters;