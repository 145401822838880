import React, { useState, useRef, useEffect } from 'react';

// Only show the hover text for text fields that are truncated, and do not show the hover text for text fields that are not truncated
// inTable will change the behaviour of the tooltip to adjust its calculation for being in an antd Table. Make sure to set display to 'inline' for the component
const TruncatedTooltipOneLine = ({
  TypographyComponent = 'div',
  TypographyComponentProps = {},
  lineItemFieldClassName,
  TooltipComponent = ({ children }) => children,
  ToolTipComponentProps = {},
  children = '',
  inTable = false,
}) => {
  const textComponentRef = useRef(null);
  const [tooltipTitle, setTooltipTitle] = useState('');

  useEffect(() => {
    if (!inTable && (textComponentRef.current && textComponentRef.current.offsetWidth < textComponentRef.current.scrollWidth)) {
      setTooltipTitle(textComponentRef.current.innerText);
    }
    
  }, [children]);

  //(textComponentRef.current.offsetLeft / 3) is trying to calculate the right padding for the text component for full text box size
  //Compare total width of text box and see if it exceeds the table cell width, if so add tooltip.
  const updateToolTipTitle = () => {
    let textComponentTotalWidth = textComponentRef.current.offsetWidth + textComponentRef.current.offsetLeft + (textComponentRef.current.offsetLeft / 3);
    let tableCellWidth = textComponentRef.current.parentElement.offsetWidth;

    if(inTable && (textComponentRef.current && tableCellWidth <= textComponentTotalWidth)){
      setTooltipTitle(textComponentRef.current.innerText);
    } else if (inTable && !(textComponentRef.current && tableCellWidth < textComponentTotalWidth)) {
      setTooltipTitle(null);
    }
  };

  useEffect(() => {
    if(!inTable){
      return;
    }
    
    const textBox = textComponentRef.current;
    updateToolTipTitle();

    if(textBox){
      textBox.addEventListener("mouseover", updateToolTipTitle);
      return () => textBox.removeEventListener('mouseover', updateToolTipTitle);
    }

  }, []);

  return (
    <TooltipComponent {...ToolTipComponentProps} title={tooltipTitle}>
      <TypographyComponent
        {...TypographyComponentProps}
        lineItemFieldClassName={lineItemFieldClassName}
        ref={textComponentRef}
      >
        {children}
      </TypographyComponent>
    </TooltipComponent>
  );
};

export default TruncatedTooltipOneLine;