import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import styled, { css } from 'styled-components';
import { useLocalStorage } from 'hooks';
import { Col, Statistic, Tooltip } from 'antd';
import { PropTypes } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { apiStatusConstants, statisticTagTypes } from 'app-constants';
import trendUpRed from '../../../../assets/images/trend-up-red.svg';
import trendUpGreen from '../../../../assets/images/trend-up-green.svg';
import trendDownRed from '../../../../assets/images/trend-down-red.svg';
import trendDownGreen from '../../../../assets/images/trend-down-green.svg';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { theme } from 'styles/appTheme';
import { range } from 'lodash/util';
import { HQAndProfitTimeContext } from 'utils/contexts';

const Statistics = withLDConsumer()(
  ({
    statistics: {
      title = '',
      latestFigure = '',
      previousFigure = '',
      percentDifference = '',
      changeDirection = '',
      metricType = '',
      noDataToCompare = false,
      fetchStatus = '',
      previousPeriodFormattedDateRangeString = '',
      latestPeriodFormattedDateRangeString = '',
      daysAgo = '30'
    } = {},
    statisticLink = null,
    comparativeStatisticLink = null,
    screenSize = '',
    headerType = '',
    metricsType,
    flags,
    unfilteredStats
  }) => {
    const { isHQ } = useContext(HQAndProfitTimeContext);

    const filter =
      metricsType === statisticTagTypes.ACTIVE_METRICS ? 'inventorySnapshotFilters' : 'historicalPerformanceFilters';
    const [, , getData] = useLocalStorage('dashboardPreferences', {});

    const getExcludedTaskCategories = () => {
      const dashboardPreferences = getData();
      if (dashboardPreferences.hasOwnProperty(filter)) {
        return dashboardPreferences[filter]['excludedTaskCategories']?.length > 0;
      }

      return false;
    };

    const StatisticTooltipContent = (
      <>
        <div>
          <p>Last {daysAgo} Days</p>
          <p>{latestPeriodFormattedDateRangeString}</p>
        </div>
        {flags?.reconExcludeTaskCategories && getExcludedTaskCategories() && unfilteredStats ? (
          <StyledToolTipContainer>
            <StyledUnfilteredContainer>
              Unfiltered:
              <StyledToolTipHeader>
                {unfilteredStats?.latestFigure === '--' ? String.fromCharCode(8212) : unfilteredStats?.latestFigure}
                {GetTrendLine(unfilteredStats?.changeDirection, unfilteredStats?.metricType)}
              </StyledToolTipHeader>
            </StyledUnfilteredContainer>
          </StyledToolTipContainer>
        ) : (
          <></>
        )}
      </>
    );

    const StatisticFooterTooltipContent = (
      <>
        <div>
          <p>Previous {daysAgo} Days</p>
          <p>{previousPeriodFormattedDateRangeString}</p>
        </div>
        {flags?.reconExcludeTaskCategories && getExcludedTaskCategories() && unfilteredStats ? (
          <StyledToolTipContainer>
            <StyledUnfilteredContainer>
              Unfiltered:
              <StyledToolTipFooter>
                <span>
                  {unfilteredStats?.previousFigure === '--'
                    ? String.fromCharCode(8212)
                    : unfilteredStats?.previousFigure}
                </span>
                (
                {(unfilteredStats?.latestFigure === String.fromCharCode(8212) &&
                  unfilteredStats?.previousFigure === String.fromCharCode(8212)) ||
                unfilteredStats?.percentDifference === '--'
                  ? String.fromCharCode(8212)
                  : unfilteredStats?.percentDifference}
                )
              </StyledToolTipFooter>
            </StyledUnfilteredContainer>
          </StyledToolTipContainer>
        ) : (
          <></>
        )}
      </>
    );

    const onStatisticClick = (link) => {
      if (link !== null) {
        navigate(link);
      }
    };

    if (fetchStatus === apiStatusConstants.IS_FETCHING) {
      return <StatisticsSkeleton statisticsCount={1} />;
    }

    const showComparativeStatistics = !noDataToCompare || isHQ;

    return (
      <StyledCol screenSize={screenSize} headerType={headerType}>
        <div className="statisticsWrapper">
          <Tooltip placement="right" title={StatisticTooltipContent} overlayClassName="statistic-tooltip">
            <StyledStatisticContainer
              onClick={() => onStatisticClick(statisticLink)}
              is-clickable={statisticLink !== null}
              headerType={headerType}
            >
              <StyledStatistic
                title={<StatisticTitle className="statisticTitle">{title}</StatisticTitle>}
                value={latestFigure === '--' ? String.fromCharCode(8212) : latestFigure}
                screenSize={screenSize}
                headerType={headerType}
                suffix={GetTrendLine(changeDirection, metricType)}
              />
            </StyledStatisticContainer>
          </Tooltip>
          <StyledFlexStatisticFooter screenSize={screenSize} headerType={headerType}>
            {showComparativeStatistics ? (
              <Tooltip
                placement="right"
                title={StatisticFooterTooltipContent}
                overlayClassName="statistic-footer-tooltip"
              >
                {latestFigure === String.fromCharCode(8212) && previousFigure === String.fromCharCode(8212) ? (
                  <div className="footer-content">{String.fromCharCode(8212)}</div>
                ) : (
                  <StyledComparativeStatisticContainer
                    className="footer-content"
                    onClick={() => onStatisticClick(comparativeStatisticLink)}
                    is-clickable={comparativeStatisticLink !== null}
                    headerType={headerType}
                  >
                    <PreviousFigure className="footer-content_prev">
                      {previousFigure === '--' ? String.fromCharCode(8212) : previousFigure}
                    </PreviousFigure>
                    <StatisticDifference screenSize={screenSize} className="footer-content_stat_diff">
                      ({percentDifference === '--' ? String.fromCharCode(8212) : percentDifference})
                    </StatisticDifference>
                  </StyledComparativeStatisticContainer>
                )}
              </Tooltip>
            ) : (
              <div className="footer-content">{String.fromCharCode(8212)}</div>
            )}
          </StyledFlexStatisticFooter>
        </div>
      </StyledCol>
    );
  }
);

export const StatisticsSkeleton = ({ statisticsCount }) => {
  return (
    <>
      {range(statisticsCount).map((_) => (
        <div style={{ width: '150px' }}>
          <StatisticsSkeletonColumn />
        </div>
      ))}
    </>
  );
};

export const StatisticsSkeletonColumn = () => {
  return (
    <>
      <SkeletonContainer>
        <Skeleton height="16px" width="100%" />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton height="24px" width="100%" />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton height="14px" width="100%" />
      </SkeletonContainer>
    </>
  );
};

Statistics.propTypes = {
  statistics: PropTypes.shape({
    title: PropTypes.string,
    latestFigure: PropTypes.string,
    previousFigure: PropTypes.string,
    unitDifference: PropTypes.string,
    percentDifference: PropTypes.string,
    changeDirection: PropTypes.string,
    metricType: PropTypes.string,
    statisticMessage: PropTypes.string
  }),
  screenSize: PropTypes.string,
  headerType: PropTypes.string
};

const TrendAlignment = styled.div``;

const GetTrendLine = (trendDirection, metricType) => {
  if (trendDirection === 'increase') {
    return metricType === 'vehicles' ? (
      <TrendAlignment>
        <img alt="Arrow Trending Upwards Green" src={trendUpGreen} />
      </TrendAlignment>
    ) : (
      <TrendAlignment>
        <img alt="Arrow Trending Upwards Red" src={trendUpRed} />
      </TrendAlignment>
    );
  } else if (trendDirection === 'decrease') {
    return metricType === 'vehicles' ? (
      <TrendAlignment>
        <img alt="Arrow Trending Down Red" src={trendDownRed} />
      </TrendAlignment>
    ) : (
      <TrendAlignment>
        <img alt="Arrow Trending Down Green" src={trendDownGreen} />
      </TrendAlignment>
    );
  }
};

export const StyledFlexStatisticFooter = styled.div`
  span {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    align-self: flex-start;
  }
  .footer-content {
    padding: ${({ headerType }) => (headerType === 'enterprise' ? '4px 10px' : '4px 16px')};
    &.ant-tooltip-open {
      background-color: #f2f4f5;
    }
    display: flex;
    flex-wrap: ${({ headerType }) => (headerType === 'enterprise' ? 'nowrap' : 'wrap')};
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: 950px) {
      padding: 1%;
    }

    .footer-content_prev,
    .footer-content_stat_diff {
      @media (max-width: 1024px) {
        font-size: ${theme.fontSizes.xs};
      }
    }
  }
`;

export const StyledStatistic = styled(Statistic)`
  .ant-statistic& {
    padding: ${({ headerType }) => (headerType === 'enterprise' ? '4px 10px' : '4px 16px')};
    .ant-tooltip-open {
      background-color: red;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 950px) {
      padding: 1%;
    }

    @media (min-width: 1260px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  .ant-statistic-content {
    float: ${({ headerType }) => (headerType === 'enterprise' ? 'left' : 'none')};
    width: 100%;
    font-size: ${theme.fontSizes.lg};

    @media (max-width: 1200px) {
      font-size: ${theme.fontSizes.md};
    }
  }

  .ant-statistic-content-value {
    color: #25282b;
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    @media (max-width: 1024px) {
      font-size: ${theme.fontSizes.xs};
    }

    @media (min-width: 1025px) {
      font-size: ${({ headerType }) => (headerType === 'enterprise' ? theme.fontSizes.md : theme.fontSizes.lg)};
    }

    @media (min-width: 1500px) {
      font-size: ${({ headerType }) => (headerType === 'enterprise' ? theme.fontSizes.md : theme.fontSizes.xlg)};
    }
  }

  .statisticTitle {
    @media (max-width: 949px) {
      height: ${({ headerType }) => (headerType === 'enterprise' ? '25px' : '35px')};
    }
    @media (min-width: 950px) {
      height: ${({ headerType }) => (headerType === 'enterprise' ? '20px' : '30px')};
    }

    @media (max-width: 1024px) {
      font-size: ${theme.fontSizes.xs};
    }

    @media (min-width: 1025px) {
      font-size: ${({ headerType }) => (headerType === 'enterprise' ? theme.fontSizes.xs : theme.fontSizes.sm)};
    }

    @media (min-width: 1130px) {
      font-size: ${theme.fontSizes.sm};
    }

    @media (min-width: 2000px) {
      font-size: ${theme.fontSizes.md};
    }
  }
`;

export const StyledCol = styled(({ screenSize, headerType, ...rest }) => <Col {...rest} />)`
  ${(props) => {
    if (props.headerType === 'enterprise') {
      return css`
        .statisticsWrapper {
          display: inline-grid;
          flex-direction: row;
          align-content: flex-start;
          align-items: flex-end;
          justify-content: center;
        }
      `;
    } else {
      return css`
        min-width: 150px;
        width: fit-content;

        border-right: 1px solid ${({ theme }) => theme.borderColors.lightGray};
        display: flex;
        vertical-align: top;
      `;
    }
  }}
`;

const StatisticDifference = styled.span`
  margin: 0 4px;
  color: ${({ theme }) => theme.colors.navy};
  display: ${({ screenSize }) => screenSize === '<= 960px' && 'block'};
`;

const PreviousFigure = styled(StatisticDifference)`
  margin-right: 4px;
  margin-left: 0;
`;

const SkeletonContainer = styled.div`
  margin-bottom: 8px;
`;

const StatisticTitle = styled.div`
  font-size: ${({ headerType }) => (headerType === 'enterprise' ? theme.fontSizes.sm : theme.fontSizes.md)};
  color: ${({ theme }) => theme.colors.darkGray};
  flex-grow: 1;
`;

const StyledStatisticContainer = styled.div`
  display: inline-block;
  cursor: ${(props) => (props['is-clickable'] ? 'pointer' : 'initial')};

  &.ant-tooltip-open {
    background-color: #f2f4f5;
  }
`;

const StyledToolTipContainer = styled.div`
  border-top: 1px solid #e4e9ef;
  display: block;
`;

const StyledToolTipFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  paddin-top: 4px;
  gap: 10px;
`;

const StyledToolTipHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 4px;
  gap: 10px;
  font-weight: 500;
  font-size: 18px;
`;

const StyledUnfilteredContainer = styled.div`
  padding-top: 4px;
  gap: 10px;
`;

const StyledComparativeStatisticContainer = styled.div`
  cursor: ${(props) => (props['is-clickable'] ? 'pointer' : 'initial')};
`;
export default withLDConsumer()(Statistics);
