import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Switch, Tooltip, Typography } from 'antd';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { useMediaPredicate } from 'react-media-hook';
import Lightbox from 'react-image-lightbox';
import 'intersection-observer';

import { CommonImagesContainer, CommonLinkButton } from 'components/styledComponents';
import { apiStatusConstants, features } from 'app-constants';
import { appraisalInfoActions, appraisalInfoPropsSelector } from 'store/appraisalInfoStore';
import { formatPrice, isBehindOtherElement } from 'utils';
import { useFeatures } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { vdpSelector } from '../../store/vdpStore';

const { Text } = Typography;

const emDash = String.fromCharCode(8212);

const AppraisalTitle = () => <StyledTitle>Appraisal</StyledTitle>;

const AppraisalMeta = ({ data = {} }) => {
  let appraisalName = '';
  if (data.appraiserFirstName) {
    appraisalName += data.appraiserFirstName;
  }
  if (data.appraiserLastName) {
    if (appraisalName.length > 0) {
      appraisalName += ' ';
    }
    appraisalName += data.appraiserLastName;
  }
  if (appraisalName.length === 0) {
    appraisalName = emDash;
  }

  let appraisalDate = data.completedOn ? moment(data.completedOn).format('MM/DD/YYYY') : emDash;

  let appraisalValue = formatPrice(data.value); // formatPrice handles emDash
  return (
    <div>
      <MetaLineItem>
        <span>{appraisalName}</span>
        <StyledSpanDividor>|</StyledSpanDividor>
        <span>{appraisalDate}</span>
      </MetaLineItem>
      <MetaLineItem>
        Appraisal Value: <span>{appraisalValue}</span>
      </MetaLineItem>
    </div>
  );
};

const AppraisalContent = ({ data = {} }) => {
  const hasAppraisalData =
    data.appraiserFirstName ||
    data.appraiserLastName ||
    data.completedOn ||
    data.value ||
    data.appraisalExitStrategyType ||
    data.vehicleDescription ||
    data.id;

  if (!hasAppraisalData) {
    return <>No Appraisal Data Available</>;
  }

  return (
    <>
      <AppraisalTitle />
      <StyledAppraisalMeta data={data} />
      <Notes data={data} />
      <ReconCost data={data} />
      <TransportationCost data={data} />
      <ReconLineItems data={data} />
      {/* Commented out until Product/UX determines how to display comments. */}
      {/* <Comments data={data} /> */}
      <Photos data={data} />
      <ExternalAppraisalLink data={data} />
    </>
  );
};

const Notes = ({ data }) => {
  return data.vehicleDescription ? (
    <StyledSection>
      <StyledSectionTitle>Notes</StyledSectionTitle>
      <div>{data.vehicleDescription}</div>
    </StyledSection>
  ) : (
    <></>
  );
};

const ReconCost = ({ data }) => {
  const reconditioningCosts =
    (
      data.exitStrategies?.filter((es) => es.appraisalExitStrategyType === data.exitStrategyType)[0] || null
    )?.costs?.filter((c) => c.name === 'Reconditioning')[0] || null;

  return reconditioningCosts?.cost ? (
    <StyledSection>
      <StyledSectionTitle>Recon Cost</StyledSectionTitle>
      <div>{formatPrice(reconditioningCosts?.cost)}</div>
    </StyledSection>
  ) : (
    <></>
  );
};

const Photos = ({ data }) => {
  const images = data.appraisalImageUrls;
  const [modalIndex, setModalIndex] = useState(null);
  if (!images?.length) return null;

  const nextIndex = (modalIndex + 1) % images.length;
  const prevIndex = (modalIndex + images.length - 1) % images.length;

  return (
    <>
      <StyledSection>
        <StyledSectionTitle>Photos</StyledSectionTitle>
        <CommonImagesContainer style={{ marginTop: '8px' }}>
          {images.slice(0, 3).map((i, index) => (
            <StyledImg key={i} src={i} alt={`Appraisal Photo ${index + 1}`} onClick={() => setModalIndex(index)} />
          ))}
        </CommonImagesContainer>
      </StyledSection>

      <StyledMoreImagesBtnContainer>
        {images.length > 3 && (
          <CommonLinkButton onClick={(e) => setModalIndex(0)}>{`See All ${images.length} Photos`}</CommonLinkButton>
        )}
      </StyledMoreImagesBtnContainer>

      {modalIndex !== null && (
        <Lightbox
          mainSrc={images[modalIndex]}
          nextSrc={images.length > 1 ? images[nextIndex] : undefined}
          prevSrc={images.length > 1 ? images[prevIndex] : undefined}
          onCloseRequest={() => setModalIndex(null)}
          onMovePrevRequest={() => setModalIndex((modalIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setModalIndex((modalIndex + 1) % images.length)}
          imageCaption={`Appraisal Photos ${modalIndex + 1}/${images.length}`}
        />
      )}
    </>
  );
};

const TransportationCost = ({ data }) => {
  const transportationCosts =
    (
      data.exitStrategies?.filter((es) => es.appraisalExitStrategyType === data.exitStrategyType)[0] || null
    )?.costs?.filter((c) => c.name === 'Transportation')[0] || null;

  return transportationCosts?.cost ? (
    <StyledSection>
      <StyledSectionTitle>Transportation Cost</StyledSectionTitle>
      <div>{formatPrice(transportationCosts?.cost)}</div>
    </StyledSection>
  ) : (
    <></>
  );
};

const ReconLineItems = ({ data }) => {
  const lineItems = (
    (
      data.exitStrategies?.filter((es) => es.appraisalExitStrategyType === data.exitStrategyType)[0] || null
    )?.costs?.filter((c) => c.name === 'Reconditioning')[0] || null
  )?.lineItems?.filter((c) => c.isSelected === true);

  let total = 0;
  return lineItems && lineItems.length > 0 ? (
    <StyledSection>
      <StyledSectionTitle>Custom Recon Items</StyledSectionTitle>
      <StyledList>
        {lineItems.map((lineItem) => {
          total += lineItem.value;
          return (
            <StyledListItem key={lineItem.name}>
              <span>&#8226; {lineItem.name}</span>
              <span>{formatPrice(lineItem.value)}</span>
            </StyledListItem>
          );
        })}
        <StyledListItem key="Total" strong>
          <span>Total</span>
          <span>{formatPrice(!isNaN(total) ? total : null)}</span>
        </StyledListItem>
      </StyledList>
    </StyledSection>
  ) : (
    <></>
  );
};

// eslint-disable-next-line
const Comments = ({ data }) => (
  <StyledSection>
    <StyledSectionTitle>Comments</StyledSectionTitle>
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vulputate efficitur tellus sed gravida. Proin
      pulvinar, metus ultricies rutrum tincidunt, magna eros finibus orci, vel laoreet est ex vitae metus.
    </div>
  </StyledSection>
);

const ExternalAppraisalLink = ({ data }) => {
  return data.id ? (
    <ViewAppraisalLink
      href={`${process.env.REACT_APP_PROVISION_BASE_URL_NEW_DOMAIN}/Va/Appraisal/Default.aspx?Id=${data.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>View Appraisal in Provision</span>
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </ViewAppraisalLink>
  ) : (
    <></>
  );
};

const LoadingData = () => <Skeleton count={30} height={16} />;

export const AppraisalInfoToggle = withLDConsumer()(({ toggleStyles, flags }) => {
  const dispatch = useDispatch();
  const popoverTargetRef = useRef();
  const { data, isAppraisalPopoverOpen } = useSelector(appraisalInfoPropsSelector); // getData dispatched from contentswitcher component inside vehicles
  const disabled = Object.keys(data).length === 0;
  const [hasLineItemsEditRole] = useFeatures(features.LINE_ITEMS_EDIT);
  const { vehicle } = useSelector(vdpSelector);

  const toggleAppraisalPopover = () => {
    dispatch(appraisalInfoActions.setAppraisalPopoverState(!isAppraisalPopoverOpen));
  };

  const showToggle = hasLineItemsEditRole && vehicle.isAppraisalEnabled;

  if (!showToggle) {
    return <StyledContainer />;
  }

  return (
    <>
      <StyledContainer>
        {disabled ? (
          <Tooltip
            placement="top"
            overlayClassName="appraisal-info-no-data-tooltip"
            title={<Text>No appraisal available</Text>}
          >
            <StyledLabel className="appraisal-info-switch-container" style={toggleStyles} data-disabled={true}>
              <span>Appraisal info </span>
              <StyledSwitch defaultChecked={false} disabled={true} />
            </StyledLabel>
          </Tooltip>
        ) : (
          <AppraisalInfoPopover popoverTargetRef={popoverTargetRef} appraisalPopoverOpen={isAppraisalPopoverOpen}>
            <StyledLabel
              ref={popoverTargetRef}
              id="appraisal-toggle"
              className="appraisal-info-switch-container"
              style={toggleStyles}
            >
              <span>Appraisal info </span>
              <StyledSwitch defaultChecked={isAppraisalPopoverOpen} onChange={toggleAppraisalPopover} />
            </StyledLabel>
          </AppraisalInfoPopover>
        )}
      </StyledContainer>
    </>
  );
});

const HIDDEN_ARROW_BOUNDING_BOX = { top: 0, left: 0, bottom: 0, right: 0 };
const getPopoverTargetPosition = (isPopoverOpen, htmlElementRef) => {
  if (!isPopoverOpen) {
    return HIDDEN_ARROW_BOUNDING_BOX;
  }
  return htmlElementRef?.getBoundingClientRect() ?? HIDDEN_ARROW_BOUNDING_BOX;
};

const AppraisalInfoPopover = ({ popoverTargetRef, appraisalPopoverOpen = false, children }) => {
  const { fetchStatus, data: appraisalData } = useSelector(appraisalInfoPropsSelector); // getData dispatched from contentswitcher component inside vehicles
  const MaxWindowHeightIs300px = useMediaPredicate('(max-height: 300px)');

  const toggleInView = () => !isBehindOtherElement(document.getElementById('appraisal-toggle'));

  // `popoverTargetJsxElement` : refers to the JSX children (aka, target for the popover's arrow). Needed because JSX hasn't been rendered
  const popoverTargetJsxElement = children;

  // `popoverTargetHtmlElementRef` : refers to the HTML element ref.
  // Needed to calculate placement for the popover's arrow and because we can't get an HTML ref out of JSX children easily
  const popoverTargetHtmlElementRef = popoverTargetRef;

  return (
    <Popover
      position={'left'}
      align={'end'}
      isOpen={appraisalPopoverOpen}
      containerStyle={{
        zIndex: 1000
      }}
      content={(popoverProps) => (
        <ArrowContainer
          position={popoverProps.position}
          popoverRect={popoverProps.popoverRect}
          targetRect={getPopoverTargetPosition(appraisalPopoverOpen, popoverTargetHtmlElementRef?.current)}
          arrowSize={0}
          arrowStyle={{
            display: toggleInView() ? 'block' : 'none',
            transform:
              popoverProps.position === 'left'
                ? 'rotate(-45deg) translate(0, -12px)'
                : 'rotate(135deg) translate(-12px, 0px)',
            height: '16px',
            width: '16px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 5px',
            backgroundColor: 'white',
            border: 'none',
            zIndex: 1000
          }}
          style={{
            padding: popoverProps.position === 'left' ? '0 12px 0 0' : '0 0 0 12px'
          }}
        >
          <StyledPopoverContent MaxWindowHeightIs300px={MaxWindowHeightIs300px}>
            {fetchStatus === apiStatusConstants.IS_FETCHING ? (
              <LoadingData />
            ) : (
              <AppraisalContent data={appraisalData} />
            )}
          </StyledPopoverContent>
        </ArrowContainer>
      )}
    >
      {popoverTargetJsxElement}
    </Popover>
  );
};

//#region Styled Components
const StyledTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-bottom: 0;
`;

const StyledSectionTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledAppraisalMeta = styled(AppraisalMeta)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.darkGray};
  padding-bottom: 24px;
`;

const MetaLineItem = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.darkGray};
`;

const StyledSpanDividor = styled.span`
  margin-left: 6px;
  margin-right: 6px;
`;

const StyledLabel = styled.label`
  margin-left: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.navy};

  span {
    opacity: ${(props) => (Boolean(props['data-disabled']) ? '.5' : '1')};
  }
`;

const StyledContainer = styled.div`
  padding-bottom: 40px;
`;

const StyledList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: ${({ theme, strong }) => strong && theme.fontWeights.medium};
  line-height: 24px;
  span {
    display: inline-block;
  }
`;

const StyledSection = styled.div`
  padding-top: 16px;
  p {
    margin-bottom: 0;
  }
`;

const ViewAppraisalLink = styled.a`
  display: block;
  padding-top: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  &,
  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }

  span {
    margin-right: 8px;
  }
`;

const StyledSwitch = styled(Switch)`
  height: 16px !important;
  min-width: 32px !important;
  &::after {
    height: 14px !important;
    width: 14px !important;
    top: -0.5px !important;
  }
`;

const StyledPopoverContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 4px;
  width: 328px;
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
  min-height: ${({ MaxWindowHeightIs300px }) => (MaxWindowHeightIs300px ? '100vh' : '375px')};
`;

const StyledImg = styled.img`
  border-radius: 4px;
  height: 88px;
  width: 88px;
`;

const StyledMoreImagesBtnContainer = styled.div`
  display: flex;
  justify-content: center;

  .ant-btn-link {
    font-size: inherit;
  }
`;
//#endregion
