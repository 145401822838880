import React from 'react';
import {Stack} from 'components';

const ResponsiveStack = ({
  children,
  mediaQueryBoolean=false,
  stylesWhenMediaQueryThresholdIsMet={},
  stylesWhenMediaQueryThresholdIsNotMet={},
  ...otherPropsForStackComponent
}) => (
  <Stack 
    {...otherPropsForStackComponent} 
    divStyle={
      mediaQueryBoolean 
      ? stylesWhenMediaQueryThresholdIsMet 
      : stylesWhenMediaQueryThresholdIsNotMet
    }
  >
    {children}
  </Stack>
);

export default ResponsiveStack;