import styled from 'styled-components';
export { default as ShowExcludedTaskCategoriesFilter } from './ShowExcludedTaskCategoriesFilter';

export const ExcludeTaskCategoriesDivider = styled.div`
  width: 1px;
  height: 23px;
  border: ${({ theme }) => theme.borders.mediumSecondaryGrey};
  margin-left: 12px;
  margin-top: 4px;
`;
