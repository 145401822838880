import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'store/authStore';
import { usersActions } from 'store/usersStore';
import { apiStatusConstants, communicationContexts, features } from 'app-constants';
import { CircularSpinner } from 'components';
import { Typography, List } from 'antd';
import { VehicleNote, TaskMessage, SeeMoreReply } from './communicationTypes';
import { CommunicationInput, FullWidthDivider } from './common';
import { sort } from 'utils/arrayUtils';
import { useFeatures } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faComments } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEqual } from 'lodash';
import { NEW } from '../../app-constants/reconPlanStatusTypes';

const { Text } = Typography;

const NoCommunications = ({ isNewVehicle, location }) =>
  location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE ||
  location === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER ||
  location === communicationContexts.TASK_DETAILS_PAGE ? (
    <NoCommunicationsContainer>
      <NoCommunicationsText style={{ fontSize: 24 }}>
        <FontAwesomeIcon icon={faComment} />
      </NoCommunicationsText>
      <NoCommunicationsText>No Messages</NoCommunicationsText>
      <NoCommunicationsDescriptionText>
        Task messages will display here. Get started by sending a message below.
      </NoCommunicationsDescriptionText>
    </NoCommunicationsContainer>
  ) : (
    <NoCommunicationsContainer>
      <NoCommunicationsText style={{ fontSize: 24 }}>
        <FontAwesomeIcon icon={faComments} />
      </NoCommunicationsText>
      <NoCommunicationsText>No Communications</NoCommunicationsText>
      {!isNewVehicle && (
        <NoCommunicationsDescriptionText>
          Any task messages or vehicle notes will display here. Get started by adding a new note below.
        </NoCommunicationsDescriptionText>
      )}
    </NoCommunicationsContainer>
  );

const FullCommunications = ({
  threadCommunications = true,
  containerId,
  vehicleId,
  initialFocus,
  fetchStatus,
  passedInCommunications,
  passedInCommunicationsAreSnippetOnly = false,
  communications,
  setCommunications,
  focusTo,
  setFocusTo,
  addCommunication,
  updateCommunication,
  deleteCommunication, // these come from withCommunications HOC
  location,
  reconTaskId,
  refTransientCommunication,
  showSeeMoreReply,
  setShowSeeMoreReply,
  closeDrawer,
  scrollTo,
  setScrollTo,
  isSavingNote,
  flags,
  isTDP = false
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);

  const { id: currentDealerId } = useSelector((state) => state.dealers.current.data);
  const { isShowVehicleNote, isShowLoadingSendCommunication } = useSelector((state) => state.vehicles);
  const { taggableUsersVehicleNote, taggableUsersTaskMessage, taggableUsersFetchStatus } = useSelector(
    (state) => state.users
  );
  const { data } = useSelector((state) => state.vehicles);
  const isNewVehicle = data?.items && data.items.length === 1 && data.items[0].inventoryType === NEW;

  const [hasCommentInventory, hasCommentTasks] = useFeatures(features.INVENTORY_COMMENT, features.TASKS_COMMENT);
  const [latestCommunication, setLatestCommunication] = useState(null); // this is used to scroll to the bottom for communications, since the most recent communication is always at the bottom
  const [indexCommunication, setIndexCommunication] = useState(-1);

  const [prevPassedInCommunicationsAreSnippetOnly, setPrevPassedInCommunicationsAreSnippetOnly] = useState(null);

  const replyCommunicationInputRef = useRef(null);
  const communicationRef = useRef(null);

  const [hasChangeCommunicationInput, setHasChangeCommunicationInput] = useState(false);
  const [combinedFetchStatus, setCombinedFetchStatus] = useState(null);

  useEffect(() => {
    if (
      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
      location === communicationContexts.VEHICLE_DETAILS_PAGE
    ) {
      if (
        fetchStatus === apiStatusConstants.IS_FETCHING ||
        taggableUsersFetchStatus === apiStatusConstants.IS_FETCHING
      ) {
        setCombinedFetchStatus(apiStatusConstants.IS_FETCHING);
      }
      if (fetchStatus === apiStatusConstants.FAILED || taggableUsersFetchStatus === apiStatusConstants.FAILED) {
        setCombinedFetchStatus(apiStatusConstants.FAILED);
      }
      if (fetchStatus === apiStatusConstants.SUCCEEDED && taggableUsersFetchStatus === apiStatusConstants.SUCCEEDED) {
        setCombinedFetchStatus(apiStatusConstants.SUCCEEDED);
      }
    } else {
      setCombinedFetchStatus(fetchStatus);
    }
  }, [fetchStatus, taggableUsersFetchStatus]);

  useEffect(() => {
    if (prevPassedInCommunicationsAreSnippetOnly === null)
      setPrevPassedInCommunicationsAreSnippetOnly(passedInCommunicationsAreSnippetOnly);
  }, [passedInCommunicationsAreSnippetOnly]);

  useEffect(() => {
    if (replyCommunicationInputRef?.current && communicationRef?.current) {
      communicationRef.current.style.marginBottom = (replyCommunicationInputRef?.current?.clientHeight ?? 0) + 'px';
      setHasChangeCommunicationInput(false);
    }
  }, [isShowVehicleNote, hasChangeCommunicationInput, fetchStatus, communications]);

  useEffect(() => {
    if (threadCommunications) {
      let groupedCommunications = groupCommunications(passedInCommunications);

      // This is not initial load, and we got new data, we should maintain ordering of threads
      if (communications.length > 0 && !isEqual(communications, groupedCommunications)) {
        if (!prevPassedInCommunicationsAreSnippetOnly) {
          const topLevelCommunicationIds = communications.map((c) => c.id);
          groupedCommunications = preserveCommunicationOrder(topLevelCommunicationIds, [...groupedCommunications]);
        } else {
          // We had the snippets data, now we have the full data. So don't preserve the ordering the snippet data was in.
          setPrevPassedInCommunicationsAreSnippetOnly(passedInCommunicationsAreSnippetOnly);
        }
      }
      setCommunications(groupedCommunications);
    } else {
      const newCommunications = [...passedInCommunications];
      sort(newCommunications, 'createdOn', true);
      setCommunications(newCommunications);
    }

    if (passedInCommunications.length > 0) {
      setLatestCommunication(passedInCommunications[0]);
    }
    if (!scrollTo) {
      setScrollTo(passedInCommunications[0]?.id);
    }
  }, [passedInCommunications, threadCommunications, showSeeMoreReply]);

  useEffect(() => {
    if (initialFocus) {
      setFocusTo(initialFocus);
    }
  }, []);

  useEffect(() => {
    if (currentDealerId) {
      if (hasCommentInventory && !isTDP) dispatch(usersActions.getTaggableUsersVehicleNote(currentDealerId));
    }
  }, [currentDealerId, hasCommentInventory, hasCommentTasks, isTDP]);

  const groupCommunications = (communicationsCollection) => {
    // Motivation: The desired display for communications is for all task level messages be grouped together in it's own threads (like in slack),
    // the first message in a thread will be un-indented and all subsequent messages will be indented.  The top level communications will be all of
    // the vehicle level notes and the first message of a thread.  The top level communications will be sorted by oldest at the top and newest
    // at the bottom, and that a block of a thread be sorted within that top level message by it's newest message. The way we do indenting
    // is by tacking on an adhoc 'messages' array field on the single unindented message of that thread the items of that thread.

    // First group by reconTaskId
    const groups = communicationsCollection.reduce((groups, c) => {
      const groupId = c.reconTaskId ? c.reconTaskId : 'vehicle';
      if (groups.hasOwnProperty(groupId)) {
        groups[groupId].push(c);
      } else {
        groups[groupId] = [c];
      }
      return groups;
    }, {});

    const { vehicle, ...res } = groups; // split out vehicles notes
    const communications = vehicle ? [...vehicle] : []; // start the collection with all of the vehicle level notes
    Object.values(res).forEach((g) => {
      communications.push(g[0]); // only take the most recent item from each group for sorting, this item is only used temporary for sorting, will be replaced with the first item of group later
    });
    sort(communications, 'createdOn', true); // now sort ascending by createdOn
    Object.keys(res).forEach((groupId) => {
      const index = communications.findIndex((x) => x.reconTaskId === groupId);
      const items = res[groupId];
      sort(items, 'createdOn', true); // sort this thread block ascending
      communications.splice(index, 1, items[0]); // replace with first item of group with what should be the actual first message of the thread
      items.shift(); // now remove that first item, the remaining items will be attached to the first message as children
      communications[index].messages = items; // add the rest of the items as children
    });

    return communications;
  };

  const preserveCommunicationOrder = (previousOrderedIds, groupedCommunications) => {
    let orderedGroupedCommunications = [];
    previousOrderedIds.forEach((id) => {
      let matchingThread = groupedCommunications.find((x) => x.id === id);
      if (matchingThread) orderedGroupedCommunications.push(matchingThread);
    });

    // Add on any newly added vehicle notes or task message threads
    orderedGroupedCommunications = orderedGroupedCommunications.concat(
      groupedCommunications.filter((x) => previousOrderedIds.indexOf(x.id) === -1)
    );
    return orderedGroupedCommunications;
  };

  const _getMarginRight = (location) => {
    return location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
      location === communicationContexts.VEHICLE_DETAILS_PAGE ||
      communicationContexts.TASK_DETAILS_PAGE ||
      communicationContexts.TASKS_PAGE_MESSAGE_DRAWER
      ? '24px'
      : '0px';
  };
  const StyledCommunicationsListContainer = ({ disableIndent = false }) => (
    <StyledCommunicationsList
      margin-right={_getMarginRight(location)}
      dataSource={communications}
      renderItem={(p, index) =>
        p.messages || p.reconTaskId ? (
          <TaskMessageThread
            index={index}
            message={p}
            disableIndent={disableIndent}
            setIndexCommunication={setIndexCommunication}
            indexCommunication={indexCommunication}
            location={location}
            taskIsDeleted={p.taskIsDeleted}
          />
        ) : (
          <>
            <VehicleNote
              {...p}
              comment={p}
              user={user}
              scrollToNote={scrollTo ? scrollTo === p.id : latestCommunication.id === p.id}
              updateCommunication={(content, contentData, nestedLocation, successMessage) => {
                setScrollTo(communicationContexts.NO_SCROLL_ID);
                updateCommunication(
                  containerId,
                  null,
                  p.id,
                  content,
                  contentData,
                  nestedLocation,
                  successMessage,
                  index
                );
              }}
              deleteCommunication={(successMessage, deleteMessage) => {
                setScrollTo(communicationContexts.NO_SCROLL_ID);
                deleteCommunication(containerId, p.id, successMessage, deleteMessage);
              }}
              flags={flags}
              setIndexCommunication={setIndexCommunication}
              indexCommunication={indexCommunication}
              location={location}
              index={index}
              taggableUsers={taggableUsersVehicleNote}
              taskIsDeleted={p.taskIsDeleted}
            />
            {index !== communications.length - 1 && <FullWidthDivider />}
          </>
        )
      }
    />
  );

  const TaskMessageThread = ({
    index,
    message,
    parentId = null,
    indent = false,
    disableIndent = false,
    setIndexCommunication,
    indexCommunication,
    setHeightCommunicationInput,
    location,
    taskIsDeleted
  }) => (
    <>
      {message.messages && (
        <TaskTypeName
          id={message.reconTaskId}
          isScrollTo={
            scrollTo
              ? scrollTo === message.reconTaskId || scrollTo === message.id
              : latestCommunication.id === message.id
          }
          flags={flags}
          message={message}
          isShowLoadingSendCommunication={isShowLoadingSendCommunication}
        />
      )}
      <TaskMessage
        {...message}
        comment={message}
        addTaskTypeLabel={message.messages && threadCommunications}
        user={user}
        scrollToMessage={scrollTo ? scrollTo === message.id : latestCommunication.id === message.id}
        updateCommunication={(content, contentData, nestedLocation, successMessage) => {
          setScrollTo(communicationContexts.NO_SCROLL_ID);
          updateCommunication(
            containerId,
            parentId,
            message.id,
            content,
            contentData,
            nestedLocation,
            successMessage,
            index
          );
        }}
        deleteCommunication={(successMessage, deleteMessage) => {
          setScrollTo(communicationContexts.NO_SCROLL_ID);
          deleteCommunication(containerId, message.id, successMessage, deleteMessage);
        }}
        indent={indent && !disableIndent}
        flags={flags}
        style={{ marginBottom: !indent || disableIndent ? '8px' : '' }}
        index={index}
        setIndexCommunication={setIndexCommunication}
        indexCommunication={indexCommunication}
        location={location}
        parentId={parentId || message.id}
        taggableUsers={taggableUsersTaskMessage}
        taskIsDeleted={taskIsDeleted}
      />

      {
        <>
          {message.messages &&
            (!showSeeMoreReply[message.reconTaskId] && message.messages.length > 3 ? (
              <>
                {message.messages.map((c, i) =>
                  i === 0 ? (
                    <SeeMoreReply
                      setShowSeeMoreReply={setShowSeeMoreReply}
                      setScrollTo={setScrollTo}
                      count={message.messages.length - 2}
                      taskId={c.reconTaskId}
                      key={c.id + '_SeeMoreReply'}
                    />
                  ) : (
                    (i === message.messages.length - 2 || i === message.messages.length - 1) && (
                      <TaskMessageThread
                        message={c}
                        parentId={message.id}
                        indent={!disableIndent}
                        disableIndent={disableIndent}
                        key={c.id + '_TaskMessageThread'}
                        index={i}
                        setIndexCommunication={setIndexCommunication}
                        indexCommunication={indexCommunication}
                        setHeightCommunicationInput={setHeightCommunicationInput}
                        location={location}
                        taskIsDeleted={message.taskIsDeleted}
                      />
                    )
                  )
                )}
              </>
            ) : (
              message.messages.map((c, i) => (
                <TaskMessageThread
                  message={c}
                  parentId={message.id}
                  indent={!disableIndent}
                  disableIndent={disableIndent}
                  key={c.id + '_TaskMessageThread'}
                  index={i}
                  setIndexCommunication={setIndexCommunication}
                  indexCommunication={indexCommunication}
                  setHeightCommunicationInput={setHeightCommunicationInput}
                  location={location}
                  taskIsDeleted={message.taskIsDeleted}
                />
              ))
            ))}
        </>
      }

      {message.messages && hasCommentTasks && (
        <CommunicationInput
          id={message.reconTaskId}
          style={{ marginBottom: '5px' }}
          nullText={`Reply to ${message.reconTaskTypeName}...`}
          isReply={true}
          setFocusTo={setFocusTo}
          focusTo={focusTo}
          isSavingNote={isSavingNote}
          location={communicationContexts.VEHICLE_COMMUNICATION_REPLY_PAGE}
          addCommunication={(
            content,
            contentData,
            images,
            loadingMessage,
            successMessage,
            errorMessage,
            locationAction
          ) => {
            setScrollTo(message.reconTaskId ? communicationContexts.NO_SCROLL_ID : null);
            addCommunication(
              containerId,
              message.reconTaskId,
              vehicleId,
              content,
              contentData,
              images,
              loadingMessage,
              successMessage,
              errorMessage,
              locationAction
            );
            if (setShowSeeMoreReply) setShowSeeMoreReply({});
          }}
          flags={flags}
          index={index}
          setIndexCommunication={setIndexCommunication}
          indexCommunication={indexCommunication}
          setHeightCommunicationInput={setHeightCommunicationInput}
          hasReplyCommunication={true}
          setHasChangeCommunicationInput={setHasChangeCommunicationInput}
          taggableUsers={taggableUsersTaskMessage}
          taskId={message.reconTaskId}
          comment={message}
          taskIsDeleted={message.taskIsDeleted}
        />
      )}
      {message.messages && index !== communications.length - 1 && <FullWidthDivider />}
    </>
  );

  const TaskTypeName = ({ id, message, isScrollTo = false, isShowLoadingSendCommunication }) => {
    useEffect(() => {
      if (isScrollTo && !isShowLoadingSendCommunication) {
        const node = document.getElementById(id);
        if (node) {
          node.scrollIntoView();
        }
      }
    }, [id, isScrollTo]);
    return (
      <StyledTaskTypeNameContainer id={id}>
        <StyledTaskTypeName>{message.reconTaskTypeName}</StyledTaskTypeName>
      </StyledTaskTypeNameContainer>
    );
  };

  const idBasedOnLocation = location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE ? reconTaskId : containerId;

  return (
    <>
      {
        {
          [apiStatusConstants.IS_FETCHING]: (
            <StyledCenter>
              <CircularSpinner tip="Loading..." size="2.5rem" />
            </StyledCenter>
          ),
          [apiStatusConstants.SUCCEEDED]: (
            <StyledContainer>
              <StyledCommunicationsContainer
                ref={communicationRef}
                data-no-communications={communications?.length === 0}
                data-location={location}
                isShowVehicleNote={isShowVehicleNote}
              >
                {communications?.length === 0 ? (
                  <NoCommunications isNewVehicle={isNewVehicle} location={location} />
                ) : (
                  <StyledCommunicationsListContainer
                    disableIndent={location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE}
                  />
                )}
              </StyledCommunicationsContainer>
              {(threadCommunications ? hasCommentInventory : hasCommentTasks) && (
                <StyledInputContainer
                  data-location={location}
                  isShowVehicleNote={isShowVehicleNote}
                  ref={replyCommunicationInputRef}
                >
                  <CommunicationInput
                    id={containerId}
                    setFocusTo={setFocusTo}
                    focusTo={focusTo}
                    location={location}
                    addCommunication={(
                      content,
                      contentData,
                      images,
                      loadingMessage,
                      successMessage,
                      errorMessage,
                      locationAction
                    ) => {
                      setScrollTo(null);
                      addCommunication(
                        containerId,
                        threadCommunications ? null : idBasedOnLocation,
                        vehicleId,
                        content,
                        contentData,
                        images,
                        loadingMessage,
                        successMessage,
                        errorMessage,
                        locationAction
                      );
                    }}
                    isSavingNote={isSavingNote}
                    refTransientCommunication={refTransientCommunication}
                    closeDrawer={closeDrawer}
                    flags={flags}
                    setIndexCommunication={setIndexCommunication}
                    indexCommunication={indexCommunication}
                    hasReplyCommunication={false}
                    setHasChangeCommunicationInput={setHasChangeCommunicationInput}
                    taggableUsers={
                      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
                      location === communicationContexts.VEHICLE_DETAILS_PAGE
                        ? taggableUsersVehicleNote
                        : taggableUsersTaskMessage
                    }
                    taskId={
                      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
                      location === communicationContexts.VEHICLE_DETAILS_PAGE
                        ? null
                        : idBasedOnLocation
                    }
                  />
                </StyledInputContainer>
              )}
            </StyledContainer>
          ),
          [apiStatusConstants.FAILED]: (
            <StyledCenter>
              <Typography.Title type="danger" level={4}>
                Oops, something went wrong
              </Typography.Title>
            </StyledCenter>
          )
        }[combinedFetchStatus]
      }
    </>
  );
};

//#region Styled Components
const StyledContainer = styled.div``;
const StyledCommunicationsContainer = styled.div`
  padding: ${({ 'data-no-communications': noCommunications }) => !noCommunications && '24px 0px 12px 24px'};
  padding-top: ${({ 'data-location': location }) => location === communicationContexts.TASK_DETAILS_PAGE && '24px'};
  padding-bottom: ${({ 'data-location': location }) => location === communicationContexts.TASK_DETAILS_PAGE && '2px'};
  padding-left: ${({ 'data-location': location }) =>
    location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE && '0'};
  margin-left: ${({ 'data-no-communications': noCommunications }) => noCommunications && 'auto'};
  margin-right: ${({ 'data-no-communications': noCommunications }) => noCommunications && 'auto'};
  margin-bottom: 73px;
`;

const StyledInputContainer = styled.div`
  // overflow: hidden;
  background-color: ${({ theme, 'data-location': location }) => theme.colors.white};
  padding: ${({ 'data-location': location }) =>
    location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
    location === communicationContexts.VEHICLE_DETAILS_PAGE
      ? '0'
      : '5px 16px'};
      border-top: ${({ 'data-location': location, theme, isShowVehicleNote }) =>
        isShowVehicleNote &&
        (location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
          location === communicationContexts.VEHICLE_DETAILS_PAGE)
          ? `1px solid ${theme.colors.borderGray}`
          : 'none'}
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledCommunicationsList = styled(List).attrs({
  size: 'small',
  locale: { emptyText: 'No Communications' }
})`
  white-space: pre-wrap;
  .ant-list& {
    margin-right: ${(props) => props['margin-right']};
  }
`;
const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: calc(50vh);
`;
const StyledTaskTypeNameContainer = styled.div`
  margin: 16px 0;
`;
const StyledTaskTypeName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.lg};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const NoCommunicationsContainer = styled.div`
  margin-top: 24px;
`;
const NoCommunicationsText = styled(Text)`
  .ant-typography& {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: 500;
    display: block;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
const NoCommunicationsDescriptionText = styled(Text)`
  .ant-typography& {
    text-align: center;
    display: block;
    max-width: 380px;
    margin: auto;
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
//#endregion

export default withLDConsumer()(FullCommunications);
