import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentDealerSelector, rootEntitySwitcherSelector } from 'store/dealersStore';
import { notificationEventTypes } from 'app-constants/notificationEventTypes';
import { IS_NEW_SIGNIN, VENDOR_USER_INFO, features } from 'app-constants';
import useVendorRole from 'hooks/useVendorRole';

//note that this hook does not watch the features param itself for changes
export const useFeatures = (...features) => {
  const { data: currentDealer, rootUserDealerFeatures } = useSelector(currentDealerSelector);
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  const [result, setResult] = useState([]);
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';

  useEffect(() => {
    let localFeatures = features;
    if (!Array.isArray(localFeatures)) {
      localFeatures = [localFeatures];
    }

    //Hard coded this feature set here as the API to get vendor's features has not been implemented yet.
    //A Vendor admin should have these features.
    let featuresToCheck = [];
    if (isNewSignin) {
      featuresToCheck = [
        'TASKS_VIEW_OWN',
        'TASKS_VIEW_ANY',
        'TASKS_WORK_OWN',
        'TASKS_WORK_ANY',
        'TASKS_COMMENT',
        'VENDOR_USER_MANAGE'
      ];
    } else if (isRootUser && vendorShipSelected) {
      featuresToCheck = rootUserDealerFeatures;
    } else {
      featuresToCheck = currentDealer?.features;
    }

    const newResult = localFeatures.map((f) => featuresToCheck?.includes(f));
    setResult(newResult);
  }, [currentDealer?.features, currentDealer?.id, isRootUser, vendorShipSelected, rootUserDealerFeatures]);

  return result;
};

export const useFeaturesFilter = (array, featureFunc) => {
  const { data: currentDealer, rootUserDealerFeatures } = useSelector(currentDealerSelector);
  const vendorUserInfoData = localStorage.getItem(VENDOR_USER_INFO);
  const vendorUserInfo = vendorUserInfoData ? JSON.parse(vendorUserInfoData) : {};
  const vendorFeatures = vendorUserInfo.vendorFeatures || [];
  const [result, setResult] = useState([]);
  const { isVendor } = useVendorRole();
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  useEffect(() => {
    // Because vendor user has two feature and it is saved in the local storage so it should be used during check features
    let featuresSet;
    if (currentDealer?.features) {
      featuresSet = new Set(currentDealer?.features);
    } else if (isRootUser && vendorShipSelected) {
      featuresSet = new Set(rootUserDealerFeatures);
    } else {
      featuresSet = new Set(vendorFeatures);
    }
    const newResult = array.filter((o) => {
      const required = featureFunc(o, featuresSet);
      if (Array.isArray(required)) {
        if (
          o.id === notificationEventTypes.EXCEEDED_MAX_APPROVAL_TIME ||
          (o.id === notificationEventTypes.LINE_ITEM_APPROVED_DECLINED && !isVendor)
        ) {
          //in case logging in by dealer users, the feature of baseline should not be applied for dealer users so it should be removed from the features list
          return required.filter((r) => r !== features.BASELINE).every((r) => featuresSet.has(r));
        }
        return required.some((r) => featuresSet.has(r));
      }
      return required && featuresSet.has(required);
    });
    setResult(newResult);
  }, [array, currentDealer?.features, rootUserDealerFeatures]);

  return result;
};
