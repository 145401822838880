import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CommunicationImages } from '.';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { theme } from 'styles/appTheme';
const { confirm } = Modal;

const CommunicationOverflowMenu = ({
  content,
  comment = [],
  images,
  setShowEditor,
  setTransientCommunication,
  deleteCommunication,
  isVehicleNote,
  index,
  setIndexCommunication,
  successMessage,
  loadingMessage,
  flags,
  taskIsDeleted
}) => {
  const getTitleConfirm = () => {
    if (isVehicleNote) {
      return `Are you sure you want to delete this note?`;
    } else {
      return `Are you sure you want to delete this message?`;
    }
  };

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'edit':
        setShowEditor(true);
        setIndexCommunication(index);
        setTransientCommunication(comment.contentData ? comment.contentData : content);
        break;
      case 'delete':
        confirm({
          title: getTitleConfirm(),
          content: (
            <>
              {content}
              <CommunicationImages images={images} />
            </>
          ),
          okText: 'Delete',
          okType: 'danger',
          cancelText: 'Cancel',
          okButtonProps: {
            style: {
              borderRadius: '4px',
              height: '32px',
              fontSize: theme.fontSizes.sm
            }
          },
          cancelButtonProps: {
            style: {
              borderRadius: '4px',
              height: '32px',
              fontSize: theme.fontSizes.sm
            }
          },
          onOk() {
            deleteCommunication(successMessage, loadingMessage);
          },
          onCancel() {}
        });
        break;
      default:
    }
  };

  return taskIsDeleted ? null : (
    <StyledMoreContainer>
      <Dropdown
        overlay={
          <Menu onClick={handleMenuClick}>
            <Menu.Item key="edit" disabled={taskIsDeleted}>
              <MenuItemIcon icon={faEdit} /> Edit
            </Menu.Item>
            <Menu.Item key="delete" disabled={taskIsDeleted}>
              <MenuItemIcon icon={faTrashAlt} /> Delete
            </Menu.Item>
          </Menu>
        }
      >
        <StyledMoreIcon icon={faEllipsisH} />
      </Dropdown>
    </StyledMoreContainer>
  );
};

//#region Styled Components
const StyledMoreContainer = styled.div`
  margin-left: 8px;
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.darkGray};
  height: 20px;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.navy};
`;
//#endregion

export default withLDConsumer()(CommunicationOverflowMenu);
