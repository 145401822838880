const journalEventTypes = {
  TASK_COMMENT_ADDED: 'TASK_COMMENT_ADDED',
  VEHICLE_COMMENT_ADDED: 'VEHICLE_COMMENT_ADDED',
  TASK_COMMENT_UPDATED: 'TASK_COMMENT_UPDATED',
  VEHICLE_COMMENT_UPDATED: 'VEHICLE_COMMENT_UPDATED',
  VEHICLE_DOCUMENT_ADD: 'VEHICLE_DOCUMENT_ADD',
  VEHICLE_DOCUMENT_DELETE: 'VEHICLE_DOCUMENT_DELETE',
  TASK_ADDED: 'TASK_ADDED',
  TASK_READY: 'TASK_READY',
  TASK_ASSIGNED: 'TASK_ASSIGNED',
  TASK_REASSIGNED: 'TASK_REASSIGNED',
  TASK_COMPLETED: 'TASK_COMPLETED',
  TASK_COMPLETED_BY_DEALER: 'TASK_COMPLETED_BY_DEALER',
  TASK_CANCELED: 'TASK_CANCELED',
  TASK_DECLINED: 'TASK_DECLINED',
  TASK_DEFERRED: 'TASK_DEFERRED',
  VEHICLE_RECON_STARTED: 'VEHICLE_RECON_STARTED',
  VEHICLE_INVENTORY_ADDED: 'VEHICLE_INVENTORY_ADDED',
  VEHICLE_FRONT_LINE_READY: 'VEHICLE_FRONT_LINE_READY',
  VEHICLE_ARCHIVED: 'VEHICLE_ARCHIVED',
  VEHICLE_UNARCHIVED: 'VEHICLE_UNARCHIVED',
  VEHICLE_EXCLUDED: 'VEHICLE_EXCLUDED',
  VEHICLE_UNEXCLUDED: 'VEHICLE_UNEXCLUDED',
  VEHICLE_SOLD: 'VEHICLE_SOLD',
  VEHICLE_RECON_PLAN_ADDED: 'VEHICLE_RECON_PLAN_ADDED',
  TASK_DOCUMENT_ADD: 'TASK_DOCUMENT_ADD',
  TASK_DOCUMENT_DELETE: 'TASK_DOCUMENT_DELETE',
  LINE_ITEM_UPDATED: 'LINE_ITEM_UPDATED',
  TASK_COMMENT_USER_TAGGED: 'TASK_COMMENT_USER_TAGGED',
  VEHICLE_COMMENT_USER_TAGGED: 'VEHICLE_COMMENT_USER_TAGGED',
  VENDOR_CONFIRMATION: 'VENDOR_CONFIRMATION',
  VENDOR_INVITATION_RECIEVED: 'VENDOR_INVITATION_RECIEVED',
  VENDOR_INVITATION_ACCEPTED: 'VENDOR_INVITATION_ACCEPTED',
  VENDOR_INVITATION_DECLINED: 'VENDOR_INVITATION_DECLINED'
};
export default journalEventTypes;
