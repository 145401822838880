import React from 'react';
import styled from 'styled-components';
import { dateTimeFromNow } from 'utils/dateTimeUtils';
import { BasicLayeredTaskIcon } from 'components/layout/tasks/icons';
import { faBan, faCircle } from '@fortawesome/free-solid-svg-icons';
// Styles
import { theme as themeStyles } from 'styles/appTheme';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const TaskDeclinedIcon = ({ task, customContent = null, flags, ...other }) => {
  const hoverContent = (
    <StyledHoverContent>
      Declined: {task?.declinedOn && dateTimeFromNow(task.declinedOn)}
      {task?.declinedReason && <DeclinedReasonContent>Reason: {task.declinedReason}</DeclinedReasonContent>}
    </StyledHoverContent>
  );

  return (
    <BasicLayeredTaskIcon
      {...other}
      task={task}
      foregroundIcon={faBan}
      customContent={customContent}
      backgroundIcon={!flags.reconTaskCollapse && faCircle}
      customHoverContent={hoverContent}
      backgroundColor={flags.reconTaskCollapse ? themeStyles.colors.transparent : themeStyles.colors.red}
      foregroundColor={flags.reconTaskCollapse ? themeStyles.colors.red : themeStyles.colors.white}
      recon-task-collapse={flags.reconTaskCollapse}
    />
  );
};

const StyledHoverContent = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.red};
  max-width: 200px;
`;

const DeclinedReasonContent = styled.div`
  border-left: solid 1px ${({ theme }) => theme.colors.lightGray};
  padding-left: 9px; // To match combined margin/padding in BasicLayeredTaskIcon.js
  margin-top: 4px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.navy};
`;

export default withLDConsumer()(TaskDeclinedIcon);
