import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import ContentMetaAndOptions from './ContentMetaAndOptions';
import AssigneeAndTagContent from './AssigneeAndTagContent';
import CostContent from './CostContent';
import AdditionalTaskNotes from './AdditionalTaskNotes';
import MessageCountSegment from './MessageCountSegment';
import PassThroughIndicator from './PassThroughIndicator';
import { TaskContext } from '../helpers/context';
import { TASK_GROUP_TASK } from '../../helpers/types.js';
import { vdpActions, getExpandTasksSelector } from 'store/vdpStore';

const { Panel } = Collapse;

/**
 * ContentSegment renders everything but the step number/icon for the task card
 */
const ContentSegment = ({ flags, countMessagesTask = null, task, contentType, taskGroups }) => {
  const dispatch = useDispatch();
  const isExpand = useSelector(getExpandTasksSelector(task.id));
  const { isStandaloneTask, hidePassthroughIndicator } = useContext(TaskContext);
  
  const onChangeHandle = (key, task) => {
    let taskIds = [key];
    let taskExpands = [isExpand];
    if (task.groupSequence > 1 && !isStandaloneTask) {
      return;
    }
    if (task.modelType === TASK_GROUP_TASK && !isStandaloneTask) {
      const taskGroup = taskGroups.find((taskGroupItem) => taskGroupItem.id === task.taskGroupId);
      taskIds = taskGroup ? taskGroup.tasks.map((taskItem) => taskItem.id) : [];
      taskExpands = taskIds;
    }

    if (isExpand) {
      dispatch(vdpActions.setRemoveExpandItem(taskExpands));
    } else {
      dispatch(vdpActions.setExpandItem(taskIds));
    }
  };

  return (
    <StyledContentContainer isExpand={isExpand} recon-task-collapse={flags.reconTaskCollapse}>
      {!flags.reconTaskCollapse ? (
        <>
          <ContentMetaAndOptions countMessagesTask={countMessagesTask} isExpand={isExpand} />
          <AssigneeAndTagContent />
          <CostContent />
          <AdditionalTaskNotes contentType={contentType} />
          <MessageCountSegment
            countMessagesTask={countMessagesTask}
            flags={flags}
          />
        </>
      ) : (
        <>
          <Collapse
            activeKey={isExpand}
            accordion={true}
            bordered={false}
            onChange={(key) => onChangeHandle(key, task)}
            expandIcon={() =>
              task.groupSequence === 1 || isStandaloneTask ? <FontAwesomeIcon icon={faChevronRight} /> : null
            }
          >
            <Panel
              header={
                <HeaderPanel onClick={(event) => event.stopPropagation()}>
                  <ContentMetaAndOptions countMessagesTask={countMessagesTask} isExpand={isExpand} />
                </HeaderPanel>
              }
              key={task.id}
            />
          </Collapse>
          {!isEmpty(isExpand) && task.passthrough && !hidePassthroughIndicator && (
            <StyledPassThrough>
              <PassThroughIndicator reconTaskCollapse={flags.reconTaskCollapse} />
            </StyledPassThrough>
          )}
        </>
      )}
    </StyledContentContainer>
  );
};
//#region Styled Components
const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0px;

  .ant-collapse {
    background-color: initial;
    padding-right: 16px;

    .ant-collapse-item {
      border-bottom: initial;

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0 0 0 40px;
        }
      }
      .ant-collapse-header {
        padding: 0 0 0 40px;
        .fa-chevron-right {
          transform: ${(props) => `rotate(${props['isExpand'] ? 90 : 0}deg)`};
          transition: 0.3s;
          color: ${(props) => (props['isExpand'] ? props.theme.colors.navy : props.theme.colors.darkGray)};
          height: 18px;
          width: 14px;
          top: 3px;
          left: 10px;
          font-size: ${({ theme }) => theme.fontSizes.md};
          line-height: 18px;
        }
      }
    }
  }
`;
const HeaderPanel = styled.div`
  cursor: grab;
`;
const StyledPassThrough = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 16px;
`;
//#endregion

export default withLDConsumer()(ContentSegment);
