import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { enterpriseMetricsActions } from '../../../../../store/enterpriseMetricsStore';
import { createSelector } from 'reselect';
import { SummaryHeader, SummaryTable } from '.';
import { generateDateRangeFromDaysAgo } from 'utils/dateTimeUtils';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';
import { useDebouncedEffect, useLocalStorage } from '../../../../../hooks';
import { dashboardPreferencesTypes } from '../../../../../app-constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { apiStatusConstants, statisticTagTypes } from 'app-constants';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';

const changePresentationData = (isActiveMetrics, data, reconExcludeTaskCategories) => {
  if (reconExcludeTaskCategories) {
    if (isActiveMetrics) {
      return {
        presentationData: data.activeInventoryPerformanceMetrics,
        unfilteredData: data.unfilteredActiveMetrics,
        fetchStatus: data.activeInventoryPerformanceMetricsFetchStatus
      };
    } else {
      return {
        presentationData: data.historicalPerformanceMetrics,
        unfilteredData: data.unfilteredHistoricalMetrics,
        fetchStatus: data.historicalPerformanceMetricsFetchStatus
      };
    }
  } else {
    if (isActiveMetrics) {
      return {
        presentationData: data.snapshotHistoricalMetrics || [],
        fetchStatus: data.snapshotHistoricalMetricsFetchStatus
          ? apiStatusConstants.SUCCEEDED
          : data.historicalMetricsFetchStatus
      };
    } else {
      return {
        presentationData: data.historicalMetrics.overviewData,
        fetchStatus: data.historicalMetricsFetchStatus
      };
    }
  }
};

const summaryTablePropsSelector = createSelector(
  (state) => state?.enterpriseMetrics?.inventory,
  (state) => state.dealers.current.data,
  (enterpriseMetrics, currentEntity) => {
    return {
      data: enterpriseMetrics.data,
      fetchStatus: enterpriseMetrics.fetchStatus,
      currentEntity: currentEntity
    };
  }
);

const propsSelector = createSelector(
  (state) => state.metrics,
  (metrics) => ({
    metricsData: metrics
  })
);

const SUMMARY_FILTERS = { dispositions: [], ...generateDateRangeFromDaysAgo('30') };
const SummaryBody = ({ flags, metricsType = statisticTagTypes.ACTIVE_METRICS }) => {
  const useLocalStorageObject = useLocalStorage('dashboardPreferences', {});
  const [dashboardPreferences] = useLocalStorageObject;
  const { metricsData } = useSelector(propsSelector);
  const [unfilteredData, setUnfilteredData] = useState();
  const { items } = useCommonDropdownContext('dealerships');
  const isActiveMetrics = metricsType === statisticTagTypes.ACTIVE_METRICS;
  const excludedTaskCategories =
    dashboardPreferences?.[dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS]?.excludedTaskCategories || [];
  const filters = {
    ...SUMMARY_FILTERS,
    excludedTaskCategories
  };

  const [sort, setSort] = useState({ order: 'ascend', field: 'dealerName' });

  const onChange = (...params) => {
    const sortHeader = params[2];
    if (sortHeader && sortHeader.field) {
      setSort({ order: sortHeader.order, field: sortHeader.field });
    }
  };

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.ENTERPRISE_SUMMARY_INVENTORY_SNAPSHOT_VIEWED);

  const dispatch = useDispatch();
  const { data, fetchStatus, currentEntity } = useSelector(summaryTablePropsSelector);
  const fetchData = (dealerships) => {
    const dealershipIds = flags?.reconDealerFilter
      ? (dealerships ?? [])
          .filter((item) => item.checked)
          .map((item) => item.key)
          .join(',')
      : undefined;
    dispatch(
      enterpriseMetricsActions.getEnterprisePerformanceMetrics(
        currentEntity.id,
        {
          startTime: filters.startTime,
          endTime: filters.endTime,
          excludedTaskCategories: flags.reconExcludeTaskCategories ? filters.excludedTaskCategories : undefined,
          dealershipIds
        },
        flags.reconTimestampFilter
      )
    );
  };

  useEffect(() => {
    fetchData(items);
  }, [JSON.stringify(filters)]);

  useDebouncedEffect(
    () => {
      fetchData(items);
    },
    600,
    [items]
  );

  useEffect(() => {
    if (excludedTaskCategories.length > 0) {
      const data = changePresentationData(isActiveMetrics, metricsData, flags?.reconExcludeTaskCategories);
      setUnfilteredData(data.unfilteredData);
    } else if (excludedTaskCategories.length === 0) {
      setUnfilteredData([]);
    }
  }, [
    JSON.stringify(metricsData),
    metricsType,
    flags?.reconExcludeTaskCategories,
    JSON.stringify(excludedTaskCategories)
  ]);

  const tableData = data.dealershipLevelMetrics;

  return (
    <>
      <SummaryHeader
        data={data}
        filters={filters}
        fetchStatus={fetchStatus}
        createdOn={currentEntity.createdOn}
        currentEntity={currentEntity}
        useLocalStorageObject={useLocalStorageObject}
        sort={sort}
        unfilteredData={unfilteredData}
      />
      <SummaryTable
        data={tableData}
        filters={filters}
        fetchStatus={fetchStatus}
        refreshAction={fetchData}
        onChange={onChange}
        sort={sort}
        useLocalStorageObject={useLocalStorageObject}
      />
    </>
  );
};

export default withLDConsumer()(SummaryBody);
