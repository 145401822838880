import React, { useRef } from 'react';
import styled from 'styled-components';
import { Typography, Divider, Affix } from 'antd';
import { formatVehicleTitle } from 'app-constants';
import { CopyOnClickText, VehicleImage } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

const { Text } = Typography;

// This DrawerContainer contains the common header, vehicle photo, and vehicle description used in most of the drawers
const DrawerContainer = ({
  headerLabel,
  onClose,
  vehicle,
  showPhoto = true,
  showBackButton = false,
  handleBackButtonClick,
  children,
  isSaving = false,
  contentStyle = {},
  onHeaderClick = null,
  isLineItemsTask
}) => {
  const scrollcontainer = useRef();

  // hack to fix the vehicle note input not getting affixed to the bottom
  // IMPORTANT NOTE: scrollcontainer is being used to track when scrolling should occur when dragging tasks in the rpb
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { scrollcontainer })); // don't delete this unless you read the comment above and have an alternative working solution for scrolling in the rpb

  return (
    <StyledContainer>
      <div>
        <StyledHeaderContainer>
          {showBackButton && (
            <StyledBackButtonContainer
              disabled={isSaving}
              onClick={(e) => {
                if (!isSaving) {
                  handleBackButtonClick(e);
                }
              }}
            >
              <StyledIcon icon={faArrowLeft} />
            </StyledBackButtonContainer>
          )}
          {headerLabel}
          <StyledCloseButtonContainer
            disabled={isSaving}
            onClick={(e) => {
              if (!isSaving) {
                onClose(e);
              }
            }}
          >
            <StyledIcon icon={faTimes} />
          </StyledCloseButtonContainer>
        </StyledHeaderContainer>
      </div>
      <StyledScrollableContainer ref={scrollcontainer}>
        {vehicle && showPhoto && (
          <VehicleImage
            src={vehicle?.heroImageUrl}
            nonMarketingSrc={vehicle?.nonMarketingHeroImageUrl}
            fallbackSrc={vehicle?.appraisalHeroImageUrl}
            isHeroOrAppraisal
            imageQualityWidth={490} // This determines the image quality we pull back from the image api, setting it to twice the size of the box
            imageQualityHeight={360}
            imageStyle={{ width: 'auto', height: '180px' }}
            noImageStyle={{ width: 'auto', height: '180px' }}
          />
        )}
        {!isLineItemsTask && vehicle && (
          <Affix target={() => scrollcontainer.current}>
            <div className="center-content">
              <StyledVehicleInfoContainer
                clickable={typeof onHeaderClick === 'function'}
                onClick={() => {
                  onHeaderClick(true);
                }}
              >
                <StyledVehicleTitle>{formatVehicleTitle(vehicle)}</StyledVehicleTitle>
                <StyledVehicleInfo>
                  <CopyOnClickText text={vehicle.stockNumber} />
                  {vehicle.vin && (
                    <>
                      <Divider type="vertical" />
                      <CopyOnClickText text={vehicle.vin} />
                    </>
                  )}
                  {vehicle.baseColor && (
                    <>
                      <Divider type="vertical" />
                      {vehicle.baseColor}
                    </>
                  )}
                </StyledVehicleInfo>
              </StyledVehicleInfoContainer>
            </div>
          </Affix>
        )}

        <StyledContentContainer style={contentStyle}>{childrenWithProps}</StyledContentContainer>
      </StyledScrollableContainer>
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const StyledScrollableContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 64px); // Subtract header height
`;
const StyledVehicleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 76px;
  width: 532px;
  padding: 16px;
  border-radius: 0 0 4px 4px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);

  z-index: 1; // So you can click it!
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  :hover {
    background-color: ${(props) => (props.clickable ? props.theme.colors.backgroundGray : props.theme.colors.white)};
  }
`;
const StyledVehicleTitle = styled(Text).attrs({
  ellipsis: true
})`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const StyledVehicleInfo = styled(Text).attrs({
  ellipsis: true
})`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.darkGray};
`;
const StyledContentContainer = styled.div`
  flex: 1 1 0;
`;
const StyledHeaderContainer = styled.div.attrs({
  className: 'center-content'
})`
  height: 64px;
  background-color: ${({ theme }) => theme.colors.navy};
`;
const StyledButtonContainer = styled.div.attrs({
  className: 'center-content'
})`
  position: absolute;
  cursor: ${(props) => (props['disabled'] ? 'inherit' : 'pointer')};
  color: ${(props) => (props['disabled'] ? '#909497' : '#fff')};
  z-index: 10;
  transition: 0.2s;
  :hover {
    background-color: ${(props) => (props['disabled'] ? 'inherit' : '#34495E')};
  }
`;
const StyledCloseButtonContainer = styled(StyledButtonContainer)`
  right: 15px;
  top: 17px; //((64 - 30) / 2)
  height: 30px;
  width: 30px;
  border-radius: 3px;
`;
const StyledBackButtonContainer = styled(StyledButtonContainer)`
  left: 15px;
  top: 12px; //((64 - 40) / 2)
  height: 40px;
  width: 40px;
  border-radius: 24px;
`;
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
//#endregion

export default DrawerContainer;
