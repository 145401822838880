import moment from 'moment';
import {
  formatAndCalculateDurationStrings,
  formattedPercentCalculation,
  calculateDaysAndHoursFromDurationDecimal,
  formatDateRanges,
  sortSubArrays
} from 'utils';
import { formatPrice } from 'utils/numberUtils';
import { earliestDateString } from 'utils/dateTimeUtils';
import { stripReferences } from 'utils/arrayUtils';
import {
  MOMENT_FORMATTING,
  taskTypeRow,
  HISTORICAL_TASK_BREAK_DOWN_HEADERS,
  ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS
} from 'app-constants';
import { Tooltip } from 'antd';
import React from 'react';
import { DEALER_INTERNAL_GROUP, VENDOR } from 'app-constants/groupTypes';

//#region historical metrics api data manipulation
//the data retrieved from the apis needs to be manipulated a bit prior to presentation
//that should be done outside of the UI layer (outside of the React components)

//manipulating data from historical data endpoint

const changeDirectionString = (rawDiffFigure) => {
  if (rawDiffFigure === undefined) devLogger.error('value undefined passed into changeDirectionString function'); //return 'neutral' in case of undefined
  if (rawDiffFigure === undefined) return 'neutral';
  if (rawDiffFigure === 0) return 'neutral';
  if (rawDiffFigure > 0) return 'increase';
  if (rawDiffFigure < 0) return 'decrease';
};

const getDurationDiff = (latestDuration, previousDuration, reconDealerFilterFlag = false) => {
  //Set values to null if they are '-' so we can do vanilla falsey checks
  latestDuration === String.fromCharCode(8212) && (latestDuration = null);
  previousDuration === String.fromCharCode(8212) && (previousDuration = null);

  //data from endpoint can either have both, latest, previous, or neither durations
  //need to handle everything in this order
  //1. when both durations are returned and have data
  //2. if this is false, then check to see if latestDuration has a value
  //3. if this is false, then check to see if previousDuration has a value
  //4. neither has data
  if (latestDuration && previousDuration) {
    //either getting all of the data that we need or
    //checking average durations needed for calculations exists in both latestPeriod and previousPeriod data
    //destructure out the following ONLY DO THIS IF THE DATA PASSED INTO THE FUNCTION ARE OF VALID TYPE (this check is done internally in the formatAndCalculationDurationStrings function)
    const {
      latestDurationFormattedString,
      previousDurationFormattedString,
      timeDiffFormattedString,
      formattedTimePercentDifference,
      dayDiffDecimal
    } = formatAndCalculateDurationStrings(latestDuration, previousDuration, reconDealerFilterFlag);

    return [
      latestDurationFormattedString,
      previousDurationFormattedString,
      timeDiffFormattedString,
      formattedTimePercentDifference,
      dayDiffDecimal
    ];
  } else if (latestDuration) {
    //previousDuration could be undefined
    const { latestDurationFormattedString, formattedTimePercentDifference } = formatAndCalculateDurationStrings(
      latestDuration,
      previousDuration,
      reconDealerFilterFlag
    );
    return reconDealerFilterFlag
      ? [latestDurationFormattedString, null, null, formattedTimePercentDifference, null]
      : [latestDurationFormattedString];
    //getting the latestTime parsed and converted into a decimal number
  } else if (previousDuration) {
    //latestDuration could be undefined
    const parsedDurationPrevious = moment.duration(previousDuration);
    const previousTimeSeconds = parsedDurationPrevious.asSeconds();
    const percentWithReconDealerFilterFlag = previousTimeSeconds === 0 ? '0%' : '-100%';
    const formattedTimePercentDifference = reconDealerFilterFlag ? percentWithReconDealerFilterFlag : '--';
    return [
      null,
      calculateDaysAndHoursFromDurationDecimal(
        moment.duration(previousDuration),
        'longHandFormat',
        false,
        reconDealerFilterFlag
      ).durationFormattedString,
      '0 days',
      formattedTimePercentDifference,
      0
    ];
  } else {
    //missing some data - the formatAndCalculateDurationStrings function takes care of these different cases internally
    devLogger.error(
      'getDurationDiff error: at least one of these durations was evaluated as falsy. Data returned is potentially completely from the formatAndCalculateDurationStrings function'
    );
    const {
      latestDurationFormattedString,
      previousDurationFormattedString,
      timeDiffFormattedString,
      formattedTimePercentDifference,
      dayDiffDecimal
    } = formatAndCalculateDurationStrings(latestDuration, previousDuration, reconDealerFilterFlag); //essentially what we get back is formatted values for 0 duration, which is what product has requested in the scenario of missing data
    const correctFormatLatestDuration = reconDealerFilterFlag ? null : latestDurationFormattedString;
    const correctFormatPreviousDuration = reconDealerFilterFlag ? null : previousDurationFormattedString;
    return [
      correctFormatLatestDuration,
      correctFormatPreviousDuration,
      timeDiffFormattedString,
      formattedTimePercentDifference,
      dayDiffDecimal
    ];
  }
};

const evaluateMetricValue = (latest, previous, startDateBeforeDataAvailable, requiredMetricType, defaultValue) => {
  let evaluatedPrevious = previous;
  // assuming that end date will always be after the date that data starts becoming available for dealership
  // this is because the date is either today's date, or if the user chooses a date from the custom select,
  //they will not be able to choose a date when data is not available because those dates will be disabled in the picker
  // really the only times that the createdOn date could come after the chosen start date for an observation period (when startDateBeforeDataAvailable) is when the user chooses 'last 90 days'
  // or a period that could be calculated with a start date prior to when data was available
  if (typeof previous !== requiredMetricType || typeof latest !== requiredMetricType)
    devLogger.error('latest or previous parameters do not match the expected type for these parameters');

  if (typeof previous !== requiredMetricType) evaluatedPrevious = !startDateBeforeDataAvailable && defaultValue;

  return [
    typeof latest !== requiredMetricType ? defaultValue : latest, //because of assumption explained above,
    evaluatedPrevious
  ];
};

const shapeMetricDataObject = (
  latest,
  previous,
  title = '',
  metricType = '',
  requiredMetricType = 'number',
  startDateBeforeDataAvailable = false,
  tasksHaveBeenCompleted,
  reconDealerFilterFlag = false
) => {
  if (tasksHaveBeenCompleted) {
    if (typeof latest !== requiredMetricType || typeof previous !== requiredMetricType) {
      devLogger.error(`shapeMetricDataObject error: either latest or previous values are not a ${requiredMetricType}`);
    }
    let periodDiff, latestFigure, previousFigure, unitDifference, percentDifference;
    if (metricType === 'money' || metricType === 'vehicles') {
      const [evaluatedLatest, evaluatedPrevious] = evaluateMetricValue(
        latest,
        previous,
        startDateBeforeDataAvailable,
        requiredMetricType,
        String.fromCharCode(8212)
      );

      if (evaluatedLatest === String.fromCharCode(8212)) {
        return {
          title,
          latestFigure: String.fromCharCode(8212) //no other data is needed because they aren't going to be used if latestFigure === String.fromCharCode(8212)
        };
      }

      if (typeof evaluatedLatest === requiredMetricType) {
        latestFigure =
          metricType === 'money' ? formatPrice(evaluatedLatest) : `${evaluatedLatest.toLocaleString()} vehicles`;

        if (typeof evaluatedPrevious === requiredMetricType) {
          previousFigure =
            metricType === 'money' ? formatPrice(evaluatedPrevious) : `${evaluatedPrevious.toLocaleString()} vehicles`;
          //diff calculations of data to be set in the state
          periodDiff = evaluatedLatest - evaluatedPrevious;
          const percentDiffDecimal = periodDiff / evaluatedPrevious;
          const unitDiffAmount = Math.abs(periodDiff);
          unitDifference =
            metricType === 'money' ? formatPrice(unitDiffAmount) : `${unitDiffAmount.toLocaleString()} vehicles`;

          const reportedPercentDifference = Math.round(percentDiffDecimal * 100);

          if (reportedPercentDifference === 0) {
            percentDifference = reconDealerFilterFlag ? '0%' : 0;
          } else if (!reportedPercentDifference || Number.isNaN(reportedPercentDifference)) {
            percentDifference = '--';
          } else {
            percentDifference = formattedPercentCalculation(percentDiffDecimal);
          }
          if (reconDealerFilterFlag) {
            if (latest && !previous) {
              percentDifference = '100%';
            }
            if (!latest && previous) {
              percentDifference = '-100%';
            }
            if (!latest && !previous) {
              percentDifference = '0%';
            }
          }
        }
      }
    } else if (metricType === 'duration') {
      const [evaluatedLatest, evaluatedPrevious] = evaluateMetricValue(
        latest,
        previous,
        startDateBeforeDataAvailable,
        'string',
        String.fromCharCode(8212)
      );

      if (evaluatedLatest !== String.fromCharCode(8212) || evaluatedPrevious !== String.fromCharCode(8212)) {
        const durationDiffCalcs = getDurationDiff(evaluatedLatest, evaluatedPrevious, reconDealerFilterFlag);
        [latestFigure, previousFigure, unitDifference, percentDifference, periodDiff] = durationDiffCalcs;
      }
    } else {
      devLogger.error('metricType passed to shapeMetricDataObject is not an expected value for this parameter');
    }

    return {
      title,
      changeDirection: changeDirectionString(periodDiff),
      latestFigure: (typeof latestFigure === 'string' && latestFigure) || String.fromCharCode(8212),
      previousFigure: (typeof previousFigure === 'string' && previousFigure) || String.fromCharCode(8212),
      unitDifference: (typeof unitDifference === 'string' && unitDifference) || String.fromCharCode(8212),
      percentDifference: (typeof percentDifference === 'string' && percentDifference) || String.fromCharCode(8212),
      metricType,
      noDataToCompare: startDateBeforeDataAvailable
    };
  } else {
    return {
      title,
      latestFigure: String.fromCharCode(8212),
      previousFigure: String.fromCharCode(8212)
    };
  }
};

export const retrieveUnfilteredMetrics = (performanceMetrics, reconDealerFilterFlag = false) => {
  const {
    currentPeriodMetrics: {
      totalVehicles: currentTotalVehicles,
      averageReconSpend: currentAverageReconSpend,
      averageTimeInRecon: currentAverageTimeInRecon,
      averageTimeToPlanCreation: currentAverageTimeToPlanCreation,
      averageTimeInApproval: currentAverageTimeInApproval
    },
    previousPeriodMetrics: {
      totalVehicles: previousTotalVehicles,
      averageReconSpend: previousAverageReconSpend,
      averageTimeInRecon: previousAverageTimeInRecon,
      averageTimeToPlanCreation: previousAverageTimeToPlanCreation,
      averageTimeInApproval: previousAverageTimeInApproval
    },
    earliestDateAvailable,
    startDateChosen,
    endDateChosen,
    daysAgo
  } = performanceMetrics;

  // Time calculations required for shaping metric data objects
  const startDateChosenMoment = moment(startDateChosen, 'MM-DD-YYYY');
  const startDateBeforeDataAvailable =
    earliestDateString(earliestDateAvailable, startDateChosenMoment) === startDateChosen;
  const formattedDateRangeStrings = formatDateRanges(
    daysAgo,
    startDateChosenMoment,
    moment(endDateChosen, 'MM-DD-YYYY')
  );

  return [
    {
      ...shapeMetricDataObject(
        currentTotalVehicles,
        previousTotalVehicles,
        'Vehicles Completed Recon',
        'vehicles',
        'number',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageTimeInRecon,
        previousAverageTimeInRecon,
        'Avg Time in Recon Unfiltered',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageTimeInApproval,
        previousAverageTimeInApproval,
        'Avg Task Approval Time Unfiltered',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageReconSpend,
        previousAverageReconSpend,
        'Avg Recon Spend Unfiltered',
        'money',
        'number',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageTimeToPlanCreation,
        previousAverageTimeToPlanCreation,
        'Avg Time Before Plan Unfiltered',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    }
  ];
};

export const shapePerformanceMetricsForPresentation = (performanceMetrics, reconDealerFilterFlag = false) => {
  // Destructure data from parameter
  const {
    currentPeriodExcludedCategoryMetrics: {
      totalVehicles: currentTotalVehiclesWithCategoryExcluded,
      averageReconSpend: currentAverageReconSpendWithCategoryExcluded,
      averageTimeInRecon: currentAverageTimeInReconWithCategoryExcluded,
      averageTimeToPlanCreation: currentAverageTimeToPlanCreationWithCategoryExcluded,
      averageTimeInApproval: currentAverageTimeInApprovalWithCategoryExcluded
    },
    previousPeriodExcludedCategoryMetrics: {
      totalVehicles: previousTotalVehiclesWithCategoryExcluded,
      averageReconSpend: previousAverageReconSpendWithCategoryExcluded,
      averageTimeInRecon: previousAverageTimeInReconWithCategoryExcluded,
      averageTimeToPlanCreation: previousAverageTimeToPlanCreationWithCategoryExcluded,
      averageTimeInApproval: previousAverageTimeInApprovalWithCategoryExcluded
    },
    earliestDateAvailable,
    startDateChosen,
    endDateChosen,
    daysAgo
  } = performanceMetrics;

  // Time calculations required for shaping metric data objects
  const startDateChosenMoment = moment(startDateChosen, 'MM-DD-YYYY');
  const startDateBeforeDataAvailable =
    earliestDateString(earliestDateAvailable, startDateChosenMoment) === startDateChosen;
  const formattedDateRangeStrings = formatDateRanges(
    daysAgo,
    startDateChosenMoment,
    moment(endDateChosen, 'MM-DD-YYYY')
  );

  // Returning an array allows us to map metric data into react components
  return [
    {
      ...shapeMetricDataObject(
        currentTotalVehiclesWithCategoryExcluded,
        previousTotalVehiclesWithCategoryExcluded,
        'Vehicles Completed Recon',
        'vehicles',
        'number',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageTimeInReconWithCategoryExcluded,
        previousAverageTimeInReconWithCategoryExcluded,
        'Avg Time in Recon',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageTimeInApprovalWithCategoryExcluded,
        previousAverageTimeInApprovalWithCategoryExcluded,
        'Avg Task Approval Time',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageReconSpendWithCategoryExcluded,
        previousAverageReconSpendWithCategoryExcluded,
        'Avg Recon Spend',
        'money',
        'number',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        currentAverageTimeToPlanCreationWithCategoryExcluded,
        previousAverageTimeToPlanCreationWithCategoryExcluded,
        'Avg Time Before Plan',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        true,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    }
  ];
};

//takes an object of data with expected structure of object properties with keys latestPeriodVehiclesSummary and previosPeriodVehiclesSummary
export const createOverviewDataForPresentation = (historicalData, reconDealerFilterFlag = false) => {
  //if this is not passed in or is undefined/null for some reason, then throw an error
  if (!historicalData) devLogger.error('no data passed into createOverviewDataForPresentation');

  //destructure data from parameter
  const {
    latestPeriodVehiclesSummary: {
      totalVehicles: latestTotalVehicles,
      averageReconSpend: latestAverageReconSpend,
      averageTimeInRecon: latestAverageTimeInRecon,
      averageTimeToPlanCreation: latestAverageTimeToPlanCreation,
      averageTimeInApproval: latestAverageTimeInApproval
    },
    previousPeriodVehiclesSummary: {
      totalVehicles: previousTotalVehicles,
      averageReconSpend: previousAverageReconSpend,
      averageTimeInRecon: previousAverageTimeInRecon,
      averageTimeToPlanCreation: previousAverageTimeToPlanCreation,
      averageTimeInApproval: previousAverageTimeInApproval
    },
    earliestDateAvailable, //already in a format that is acceptable by momentjs (RFCP or ISO)
    startDateChosen, //needs some additional formatting to be acceptable by momentjs,
    endDateChosen,
    daysAgo,
    taskBreakdowns
  } = historicalData;

  const startDateChosenMoment = moment(startDateChosen, 'MM-DD-YYYY'); //need to specify the format that the string is in
  const startDateBeforeDataAvailable =
    earliestDateString(earliestDateAvailable, startDateChosenMoment) === startDateChosen;
  const tasksHaveBeenCompleted = taskBreakdowns.length > 0;

  //calculate previous period's date range
  const formattedDateRangeStrings = formatDateRanges(
    daysAgo,
    startDateChosenMoment,
    moment(endDateChosen, 'MM-DD-YYYY')
  );

  return [
    //returning an array allows for mapping over data in react component rendering
    {
      ...shapeMetricDataObject(
        latestTotalVehicles,
        previousTotalVehicles,
        'Vehicles Completed Recon',
        'vehicles',
        'number',
        startDateBeforeDataAvailable,
        tasksHaveBeenCompleted,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        latestAverageTimeInRecon,
        previousAverageTimeInRecon,
        'Avg Time in Recon',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        tasksHaveBeenCompleted,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        latestAverageTimeInApproval,
        previousAverageTimeInApproval,
        'Avg Task Approval Time',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        tasksHaveBeenCompleted,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        latestAverageReconSpend,
        previousAverageReconSpend,
        'Avg Recon Spend',
        'money',
        'number',
        startDateBeforeDataAvailable,
        tasksHaveBeenCompleted,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    },
    {
      ...shapeMetricDataObject(
        latestAverageTimeToPlanCreation,
        previousAverageTimeToPlanCreation,
        'Avg Time Before Plan',
        'duration',
        'string',
        startDateBeforeDataAvailable,
        tasksHaveBeenCompleted,
        reconDealerFilterFlag
      ),
      ...formattedDateRangeStrings,
      daysAgo
    }
  ];
};

export const stringSorterMethod = (firstObject, secondObject, propertyName, ignoreCase = false) => {
  const firstValue = ignoreCase ? firstObject[propertyName].toUpperCase() : firstObject[propertyName];
  const secondValue = ignoreCase ? secondObject[propertyName].toUpperCase() : secondObject[propertyName];
  if (firstValue < secondValue) {
    return -1;
  }
  return firstValue > secondValue ? 1 : 0;
};

export const sortbyAssignToGroupType = (
  firstAssignedToGroupType,
  firstAssignedToGroupId,
  secondAssignedToGroupType,
  secondAssignedToGroupId
) => {
  if (firstAssignedToGroupType === VENDOR && secondAssignedToGroupType !== VENDOR) {
    return 1;
  }

  if (firstAssignedToGroupType !== VENDOR && secondAssignedToGroupType === VENDOR) {
    return -1;
  }

  if (
    (firstAssignedToGroupType === DEALER_INTERNAL_GROUP || (firstAssignedToGroupId && !firstAssignedToGroupType)) &&
    (secondAssignedToGroupType !== DEALER_INTERNAL_GROUP || !secondAssignedToGroupId)
  ) {
    return -1;
  }

  if (
    (firstAssignedToGroupType !== DEALER_INTERNAL_GROUP || !firstAssignedToGroupId) &&
    (secondAssignedToGroupType === DEALER_INTERNAL_GROUP || (secondAssignedToGroupId && !secondAssignedToGroupType))
  ) {
    return 1;
  }

  return 0;
};

//Change the format of the data in the fields of type Time
export const createSummaryTotal = (isActive, dataObject, reconDealerFilterFlag) => {
  if (dataObject) {
    if (isActive) {
      const parsedAvgTotalTimeDurationMoment =
        (dataObject.totalAverageTime && moment.duration(dataObject.totalAverageTime)) || moment.duration('P0D');
      dataObject.totalAverageTime = calculateDaysAndHoursFromDurationDecimal(
        parsedAvgTotalTimeDurationMoment,
        'shortHandFormat',
        false,
        reconDealerFilterFlag
      ).durationFormattedString;
    } else {
      const parsedAvgTotalTimeDurationMoment =
        (dataObject.totalAverageTime && moment.duration(dataObject.totalAverageTime)) || moment.duration('P0D');
      const parsedPreviousAvgTotalTimeDurationMoment =
        (dataObject.totalPreviousAverageTotalTime && moment.duration(dataObject.totalPreviousAverageTotalTime)) ||
        moment.duration('P0D');

      dataObject.totalAverageTime = calculateDaysAndHoursFromDurationDecimal(
        parsedAvgTotalTimeDurationMoment,
        'shortHandFormat',
        false,
        reconDealerFilterFlag
      ).durationFormattedString;
      dataObject.totalPreviousAverageTotalTime = calculateDaysAndHoursFromDurationDecimal(
        parsedPreviousAvgTotalTimeDurationMoment,
        'shortHandFormat',
        false,
        reconDealerFilterFlag
      ).durationFormattedString;
    }
    return dataObject;
  }
};

export const createTaskBreakdownDataForPresentation = (
  isActive,
  dataObject,
  arrayKey,
  avgTimePropInArrayKey,
  prevAvgTimePropInArrayKey,
  isHq,
  reconDealerFilterFlag = false
) => {
  if (!dataObject) {
    devLogger.error('need to pass a data object into this function. If you have done this, then the dataset is falsy');
    return [];
  }

  const { [arrayKey]: taskBreakdowns } = dataObject;
  let taskBreakdownDisplayData;

  if (taskBreakdowns.length >= 0) {
    // format data
    const formatColumnsData = ({
      displayText: currentDisplayText,
      [avgTimePropInArrayKey]: averageTotalTime,
      userBreakdowns,
      [prevAvgTimePropInArrayKey]: previousAverageTotalTime,
      ...restOfCurrentTaskData
    }) => {
      const parsedAvgTotalTimeDurationMoment =
        (averageTotalTime && moment.duration(averageTotalTime)) || (!reconDealerFilterFlag && moment.duration('P0D'));
      const parsedPreviousAvgTotalTimeDurationMoment =
        (previousAverageTotalTime && moment.duration(previousAverageTotalTime)) ||
        (!reconDealerFilterFlag && moment.duration('P0D'));

      const avgTotalTimeDuration = calculateDaysAndHoursFromDurationDecimal(
        parsedAvgTotalTimeDurationMoment,
        'shortHandFormat',
        false,
        reconDealerFilterFlag
      );
      const previousAvgTotalTimeDuration = calculateDaysAndHoursFromDurationDecimal(
        parsedPreviousAvgTotalTimeDurationMoment,
        'shortHandFormat',
        false,
        reconDealerFilterFlag
      );

      const taskData = {
        ...restOfCurrentTaskData,
        displayText: currentDisplayText,
        rowKey: (restOfCurrentTaskData.taskTypeId ?? restOfCurrentTaskData.categoryId) + '',
        parsedAverageTotalTime: parsedAvgTotalTimeDurationMoment._data,
        durationDecimal: avgTotalTimeDuration.durationDecimalMagnitude, //for sorting
        averageTotalTime: avgTotalTimeDuration.durationFormattedString,
        parsedPreviousAverageTotalTime: parsedPreviousAvgTotalTimeDurationMoment._data,
        previousDurationDecimal: previousAvgTotalTimeDuration.durationDecimalMagnitude,
        previousAverageTotalTime: previousAvgTotalTimeDuration.durationFormattedString,
        children: userBreakdowns
      };

      taskData.children?.length > 0 &&
        taskData.children.forEach((child) => {
          child.children = child.userBreakdowns;
        });

      return taskData;
    };

    //additionally, changes averageTotalTime to be in x days y hours format
    //and adds parsedAverageTotalTime to the properties for each task
    //all other properties remain
    taskBreakdownDisplayData = taskBreakdowns.reduce((list, taskData) => {
      //need to format date to be read as days, hours NOT ISO8601 format
      const formatedTaskData = formatColumnsData(taskData);

      return [...list, formatedTaskData];
    }, []);
  } else {
    taskBreakdownDisplayData = [];
  }

  // Creates unique key based on metrics data
  const getRowKey = (preRowKey, data) => {
    if (preRowKey) {
      let rowKeySuffix = data.assignedTo ? data.assignedTo : data.taskTypeId;
      return `${preRowKey} - ${rowKeySuffix}`;
    } else {
      return data.assignedToGroupId || data.assignedTo
        ? `${taskTypeRow.TASK_TYPE_CHILD_ROW} - ${data.taskTypeId} - ${data.assignedToGroupId || data.assignedTo}` // Row is either a group or user breakdown
        : data.taskTypeId
        ? `${taskTypeRow.DEALER_CHILD_ROW} - ${data.categoryId}  - ${data.dealerId} - ${data.taskTypeId}` // Row is a child task type breakdown
        : `${taskTypeRow.CATEGORY_CHILD_ROW} - ${data.categoryId} - ${data.dealerId}`; // Row is a dealer breakdown
    }
  };

  const formatChildrenData = (
    taskTypeName,
    {
      assignee,
      [avgTimePropInArrayKey]: averageTotalTime,
      [prevAvgTimePropInArrayKey]: previousAverageTotalTime,
      ...restOfChildrenData
    },
    preRowKey,
    reconDealerFilterFlag
  ) => {
    //need to format date to be read as days, hours NOT ISO8601 format
    const parsedDurationMoment =
      (averageTotalTime && moment.duration(averageTotalTime)) || (!reconDealerFilterFlag && moment.duration('P0D'));
    const parsedPreviousDurationMoment =
      (previousAverageTotalTime && moment.duration(previousAverageTotalTime)) ||
      (!reconDealerFilterFlag && moment.duration('P0D'));

    const currentDuration = calculateDaysAndHoursFromDurationDecimal(
      parsedDurationMoment,
      'shortHandFormat',
      false,
      reconDealerFilterFlag
    );

    const previousDuration = calculateDaysAndHoursFromDurationDecimal(
      parsedPreviousDurationMoment,
      'shortHandFormat',
      false,
      reconDealerFilterFlag
    );

    const childernData = {
      ...restOfChildrenData,
      averageTotalTime: currentDuration.durationFormattedString,
      averageTotalTimeDuration: currentDuration,
      previousAverageTotalTime: previousDuration.durationFormattedString,
      previousAverageTotalTimeDuration: previousDuration,
      taskType: taskTypeName,
      taskTypeName: assignee, //we need to assign the assignee data to the taskTypeName column
      rowKey: getRowKey(preRowKey, restOfChildrenData)
    };

    if (childernData.children?.length > 0) {
      childernData.children = childernData.children.map((child) =>
        formatChildrenData(
          taskTypeName,
          child,
          childernData.rowKey
            .replace(taskTypeRow.TASK_TYPE_CHILD_ROW, taskTypeRow.INTERNAL_GROUP_CHILD_ROW)
            .replace(taskTypeRow.CATEGORY_CHILD_ROW, taskTypeRow.DEALER_CHILD_ROW),
          reconDealerFilterFlag
        )
      );
    }

    return childernData;
  };

  const remappedData = taskBreakdownDisplayData.map(({ children, taskTypeName, ...restOfTaskBreakdownData }) => ({
    children: children?.map((child) => formatChildrenData(taskTypeName, child, null, reconDealerFilterFlag)),
    taskTypeName,
    ...restOfTaskBreakdownData
  }));

  const getTableInstructions = () => {
    const title = isHq ? 'Category' : isActive ? 'Task' : 'Task Type';
    return [
      {
        title: title,
        dataIndex: HISTORICAL_TASK_BREAK_DOWN_HEADERS.DISPLAY_TEXT,
        sorter: (firstValue, secondValue) =>
          determineWhetherToSort(firstValue, secondValue, (firstValue, secondValue) =>
            stringSorterMethod(firstValue, secondValue, HISTORICAL_TASK_BREAK_DOWN_HEADERS.DISPLAY_TEXT, true)
          )
      },
      {
        title: isActive ? 'In Progress' : 'Completed',
        dataIndex: isActive
          ? ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS.IN_PROGRESS
          : HISTORICAL_TASK_BREAK_DOWN_HEADERS.COMPLETED,
        sorter: (firstObservation, secondObservation) =>
          determineWhetherToSort(firstObservation, secondObservation, (firstObservationArg, secondObservationArg) => {
            return isActive
              ? firstObservationArg.inProgressTotal - secondObservationArg.inProgressTotal
              : firstObservationArg.completedTotalCount - secondObservationArg.completedTotalCount;
          })
      },
      {
        title: 'On Track',
        dataIndex: isActive
          ? ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS.ON_TRACK
          : HISTORICAL_TASK_BREAK_DOWN_HEADERS.ON_TRACK,
        sorter: (firstObservation, secondObservation) =>
          determineWhetherToSort(firstObservation, secondObservation, (firstObservationArg, secondObservationArg) => {
            return isActive
              ? firstObservationArg.inProgressOnTrack - secondObservationArg.inProgressOnTrack
              : firstObservationArg.onTrackCount - secondObservationArg.onTrackCount;
          })
      },
      {
        title: 'Past Goal',
        dataIndex: isActive
          ? ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS.PAST_GOAL
          : HISTORICAL_TASK_BREAK_DOWN_HEADERS.PAST_GOAL,
        sorter: (firstObservation, secondObservation) =>
          determineWhetherToSort(firstObservation, secondObservation, (firstObservationArg, secondObservationArg) => {
            return isActive
              ? firstObservationArg.inProgressPastGoal - secondObservationArg.inProgressPastGoal
              : firstObservationArg.pastGoalCount - secondObservationArg.pastGoalCount;
          })
      },
      {
        title: 'Overdue',
        dataIndex: isActive
          ? ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS.OVERDUE
          : HISTORICAL_TASK_BREAK_DOWN_HEADERS.OVERDUE,
        sorter: (firstObservation, secondObservation) =>
          determineWhetherToSort(firstObservation, secondObservation, (firstObservationArg, secondObservationArg) => {
            return isActive
              ? firstObservationArg.inProgressOutOfThreshold - secondObservationArg.inProgressOutOfThreshold
              : firstObservationArg.outOfThresholdCount - secondObservationArg.outOfThresholdCount;
          })
      }
    ];
  };

  const childActiveSortFunction = (firstObservation, secondObservation) => {
    const {
      inProgressTotal: firstTotal,
      inProgressPastGoal: firstPastGoal,
      inProgressOutOfThreshold: firstOverdue,
      assignedToGroupId: firstAssignedToGroupId,
      assignedToGroupType: firstAssignedToGroupType
    } = firstObservation;
    const {
      inProgressTotal: secondTotal,
      inProgressPastGoal: secondPastGoal,
      inProgressOutOfThreshold: secondOverdue,
      assignedToGroupId: secondAssignedToGroupId,
      assignedToGroupType: secondAssignedToGroupType
    } = secondObservation;

    const sortByGroupType = sortbyAssignToGroupType(
      firstAssignedToGroupType,
      firstAssignedToGroupId,
      secondAssignedToGroupType,
      secondAssignedToGroupId
    );
    if (sortByGroupType !== 0) {
      return sortByGroupType;
    }

    //1. want to sort assignees with overdue tasks on top
    //2. if overdue tasks are tied and not 0, look at total out of threshold and sort by that
    //3. if overdue tasks are 0 for one of them, put the nonzero above the 0
    //4. if overdue tasks are 0 for both, sort by past goal values
    //5. if overdue tasks and past goal tasks are 0 for one of them, put the nonzero total of those above the 0 total
    //6. if overdue tasks and past goal tasks are 0 for both, sort by total in progress
    if (firstOverdue !== 0 && secondOverdue !== 0) {
      if (firstOverdue > secondOverdue) return -1;
      if (firstOverdue < secondOverdue) return 1;
      if (firstOverdue === secondOverdue) {
        if (firstPastGoal > secondPastGoal) return -1;
        if (firstPastGoal < secondPastGoal) return 1;
        if (firstPastGoal === secondPastGoal) return 0;
      }
    } else if (firstOverdue === 0 || secondOverdue === 0) {
      if (firstOverdue === 0) {
        if (secondOverdue !== 0) return 1;
        if (secondOverdue === 0) {
          if (firstPastGoal > secondPastGoal) return -1;
          if (firstPastGoal < secondPastGoal) return 1;
          if (firstPastGoal !== 0 && secondPastGoal !== 0 && firstPastGoal === secondPastGoal) return 0;
          if (firstPastGoal === 0 && secondPastGoal === 0) {
            if (firstTotal > secondTotal) return -1;
            if (firstTotal < secondTotal) return 1;
            if (firstTotal === secondTotal) return 0;
          }
        }
      }
      if (secondOverdue === 0) {
        if (firstOverdue !== 0) return -1;
        if (firstOverdue === 0) {
          if (firstPastGoal > secondPastGoal) return -1;
          if (firstPastGoal < secondPastGoal) return 1;
          if (firstPastGoal !== 0 && secondPastGoal !== 0 && firstPastGoal === secondPastGoal) return 0;
          if (firstPastGoal === 0 && secondPastGoal === 0) {
            if (firstTotal > secondTotal) return -1;
            if (firstTotal < secondTotal) return 1;
            if (firstTotal === secondTotal) return 0;
          }
        }
      }
    }
  };

  const childHistoricalSortFunction = (firstObservation, secondObservation) => {
    const {
      completedTotalCount: firstTotal,
      outOfThresholdCount: firstOverdue,
      assignedToGroupId: firstAssignedToGroupId,
      assignedToGroupType: firstAssignedToGroupType
    } = firstObservation;
    const {
      completedTotalCount: secondTotal,
      outOfThresholdCount: secondOverdue,
      assignedToGroupId: secondAssignedToGroupId,
      assignedToGroupType: secondAssignedToGroupType
    } = secondObservation;

    const sortByGroupType = sortbyAssignToGroupType(
      firstAssignedToGroupType,
      firstAssignedToGroupId,
      secondAssignedToGroupType,
      secondAssignedToGroupId
    );
    if (sortByGroupType !== 0) {
      return sortByGroupType;
    }

    if (firstOverdue !== 0 && secondOverdue !== 0) {
      //sort by overdue
      if (firstOverdue > secondOverdue) return -1;
      if (firstOverdue < secondOverdue) return 1;
      if (firstOverdue === secondOverdue) {
        if (firstTotal > secondTotal) return -1;
        if (firstTotal < secondTotal) return 1;
        if (firstTotal === secondTotal) return 0;
      }
    } else if (firstOverdue === 0 || secondOverdue === 0) {
      if (firstOverdue === 0) {
        if (secondOverdue !== 0) return 1;
        if (secondOverdue === 0) {
          if (firstTotal > secondTotal) return -1;
          if (firstTotal < secondTotal) return 1;
          if (firstTotal === secondTotal) return 0;
        }
      }
      if (secondOverdue === 0) {
        if (firstOverdue !== 0) return -1;
        if (firstOverdue === 0) {
          if (firstTotal > secondTotal) return -1;
          if (firstTotal < secondTotal) return 1;
          if (firstTotal === secondTotal) return 0;
        }
      }
    }
  };

  const taskBreakdownDisplayDataWithSortedChildrenRow = sortSubArrays(
    remappedData,
    'children',
    isActive ? childActiveSortFunction : childHistoricalSortFunction
  );
  const taskBreakdownDisplayDataWithSortedChildrenRowAndLastChildRowProperty =
    taskBreakdownDisplayDataWithSortedChildrenRow.map((task) => {
      const copyOfTask = stripReferences(task);
      //Check last user/internal group
      const lastChildRow = copyOfTask?.children?.slice(-1)[0];
      if (lastChildRow) {
        const lastMember = lastChildRow.children?.slice(-1)[0];
        if (lastMember) {
          lastMember.lastUserRow = true;
        }
        lastChildRow.lastUserRow = true;
      }
      return copyOfTask;
    });

  const determineWhetherToSort = (firstValue, secondValue, parentSortFunction) => {
    if (
      !firstValue.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW) &&
      !secondValue.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW) &&
      !firstValue.rowKey.includes(taskTypeRow.INTERNAL_GROUP_CHILD_ROW) &&
      !secondValue.rowKey.includes(taskTypeRow.INTERNAL_GROUP_CHILD_ROW) &&
      !firstValue.rowKey.includes(taskTypeRow.CATEGORY_CHILD_ROW) &&
      !secondValue.rowKey.includes(taskTypeRow.CATEGORY_CHILD_ROW) &&
      !firstValue.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW) &&
      !secondValue.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)
    ) {
      return parentSortFunction(firstValue, secondValue);
    }
    return 0; //will not re-sort anything if child row
  };

  const tableInstructions = getTableInstructions();

  //push additional data instructions for antd to determine columns to present
  //push additional data instructions for antd to determine columns to present
  //active inventory (inventory snapshot) has one more column than historical performance does
  if (taskBreakdownDisplayData.length > 0) {
    //push additional data instructions for antd to determine columns to present
    //active inventory (inventory snapshot) has one more column than historical performance does

    if (taskBreakdownDisplayData[0].averageTotalTime !== undefined) {
      const AvgTimeInTaskToolTip = () => {
        return (
          <div>
            <p style={{ marginTop: '10px' }}>Last {dataObject.daysAgo} days</p>
            <p style={{ marginBottom: '8px' }}>
              {dataObject.startDateChosen.replace(/-/g, '/')}
              <span>&#8212;</span>
              {dataObject.endDateChosen.replace(/-/g, '/')}
            </p>
          </div>
        );
      };

      tableInstructions.push({
        title: () => {
          return isActive ? (
            <div>Avg. Time in Task</div>
          ) : (
            <Tooltip placement="top" title={AvgTimeInTaskToolTip()} overlayClassName="avg-time-in-task-tooltip">
              Avg. Total Time
            </Tooltip>
          );
        },
        dataIndex: HISTORICAL_TASK_BREAK_DOWN_HEADERS.AVG_TOTAL_TIME,
        sorter: (firstObservation, secondObservation) =>
          determineWhetherToSort(
            firstObservation,
            secondObservation,
            (firstObservationArg, secondObservationArg) =>
              firstObservationArg.durationDecimal - secondObservationArg.durationDecimal
          )
      });
    }

    if (!isActive && taskBreakdownDisplayData[0].previousAverageTotalTime !== undefined) {
      const PrevAvgTimeInTaskToolTip = () => {
        const previousStartDate = moment(dataObject.startDateChosen, MOMENT_FORMATTING)
          .subtract(dataObject.daysAgo, 'days')
          .format(MOMENT_FORMATTING);
        const previousEndDate = moment(dataObject.endDateChosen, MOMENT_FORMATTING)
          .subtract(dataObject.daysAgo, 'days')
          .format(MOMENT_FORMATTING);

        return (
          <div>
            <p style={{ marginTop: '10px' }}>Prev. {dataObject.daysAgo} days</p>
            <p style={{ marginBottom: '8px' }}>
              {previousStartDate}
              <span>&#8212;</span>
              {previousEndDate}
            </p>
          </div>
        );
      };

      tableInstructions.push({
        title: () => {
          return (
            <Tooltip
              placement="top"
              title={PrevAvgTimeInTaskToolTip()}
              overlayClassName="prev-avg-time-in-task-tooltip"
            >
              Prev. Avg. Time
            </Tooltip>
          );
        },
        dataIndex: HISTORICAL_TASK_BREAK_DOWN_HEADERS.PREV_AVG_TIME,
        sorter: (firstObservation, secondObservation) =>
          determineWhetherToSort(
            firstObservation,
            secondObservation,
            (firstObservationArg, secondObservationArg) =>
              firstObservationArg.previousDurationDecimal - secondObservationArg.previousDurationDecimal
          )
      });

      //Add Task Reassigned column in index 2 for historical task breakdown table
      if (remappedData.length > 0 && remappedData[0].hasOwnProperty('reassignmentCount')) {
        tableInstructions.splice(2, 0, {
          title: 'Reassigned',
          dataIndex: HISTORICAL_TASK_BREAK_DOWN_HEADERS.REASSIGNED,
          sorter: (firstObservation, secondObservation) =>
            determineWhetherToSort(
              firstObservation,
              secondObservation,
              (firstObservationArg, secondObservationArg) =>
                firstObservationArg.reassignmentCount - secondObservationArg.reassignmentCount
            )
        });
      }
    }
  }

  return [
    //column headings - required format for antd Table component
    //note that dataIndex must match the property key names in table row data objects
    tableInstructions,
    ...taskBreakdownDisplayDataWithSortedChildrenRowAndLastChildRowProperty //data returned from the array reduce methods
  ];
};
//#endregion
