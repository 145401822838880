import { getWithToken, putWithToken } from 'api';
import { apiStatusConstants, journalEventTypes } from 'app-constants';
import { IN_APP } from 'app-constants/notificationChannelsTypes.js';
import { TOGGLE_CHANGE } from 'app-constants/stateChangeToggleSettings.js';
import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { messagesActions } from 'store/messagesStore';
import { userAppStateActions } from 'store/userAppStateStore.js';
import { createRequestTypes, makeActionCreator } from 'utils';
import { rootEntitySwitcherSelector } from './dealersStore';
import { ewsEntityTypes } from '../../src/app-constants';

//#region Actions
export const USER_SETTINGS = createRequestTypes('USER_SETTINGS', [
  'GET',
  'GET_ACCESSIBLE_DEALERS_BY_TYPE',
  'SET_FETCH_STATUS',
  'SET_IS_LOADING',
  'SET_DATA',
  'SET_MAX_PAGE',
  'SET_DEALERIDS',
  'SET_DEALERIDS_BY_TYPE',
  'SET_HAS_NEXT_PAGE',
  'UPDATE_NOTIFICATIONS',
  'SET_NOTIFICATIONS',
  'SET_ACCESSIBLE_DEALERS',
  'SET_ACCESSIBLE_DEALERS_RAW',
  'DEBOUNCE_UPDATE_NOTIFICATIONS',
  'SEARCHING_ACCESSIBLE_DEALERS',
  'SET_SEARCH_RESULT',
  'UPDATE_IN_SEARCH'
]);
export const userSettingsActions = {
  get: makeActionCreator(USER_SETTINGS.GET, 'userId', 'dealerId'),
  getAccessibleDealersByType: makeActionCreator(
    USER_SETTINGS.GET_ACCESSIBLE_DEALERS_BY_TYPE,
    'notificationMessageType',
    'searchValue'
  ),
  setFetchStatus: makeActionCreator(USER_SETTINGS.SET_FETCH_STATUS, 'status'),
  setIsLoading: makeActionCreator(USER_SETTINGS.SET_IS_LOADING, 'status'),
  setData: makeActionCreator(USER_SETTINGS.SET_DATA, 'data', 'dealerId'),
  setMaxPage: makeActionCreator(USER_SETTINGS.SET_MAX_PAGE, 'page'),
  setHasNextPage: makeActionCreator(USER_SETTINGS.SET_HAS_NEXT_PAGE, 'hasNextPage'),
  setDealerIdsByType: makeActionCreator(
    USER_SETTINGS.SET_DEALERIDS_BY_TYPE,
    'accessibleDealers',
    'notificationMessageType',
    'page',
    'hasNextPage',
    'searchValue'
  ),
  setDealerIds: makeActionCreator(USER_SETTINGS.SET_DEALERIDS, 'accessibleDealers', 'hasNextPage', 'page'),
  setAccessibleDealers: makeActionCreator(USER_SETTINGS.SET_ACCESSIBLE_DEALERS, 'dealers'),
  setNotifications: makeActionCreator(USER_SETTINGS.SET_NOTIFICATIONS, 'notifications', 'channels'),
  updateNotifications: makeActionCreator(
    USER_SETTINGS.UPDATE_NOTIFICATIONS,
    'userId',
    'dealerId',
    'notifications',
    'stateChangeSetting',
    'selected'
  ),
  debounceUpdateNotifications: makeActionCreator(
    USER_SETTINGS.DEBOUNCE_UPDATE_NOTIFICATIONS,
    'userId',
    'dealerId',
    'notifications',
    'stateChangeSetting',
    'selected',
    'isSalesperson',
    'eventId',
    'isChangedDealers'
  ),
  updateInSearch: makeActionCreator(USER_SETTINGS.UPDATE_IN_SEARCH, 'userId', 'dealerId', 'selected', 'eventId'),
  searchingAccessibleDealers: makeActionCreator(
    USER_SETTINGS.SEARCHING_ACCESSIBLE_DEALERS,
    'searchValue',
    'notificationMessageType'
  ),
  setSearchResult: makeActionCreator(USER_SETTINGS.SET_SEARCH_RESULT, 'searchResult')
};
//#endregion

//#region Reducer
const initialState = {
  data: {
    notifications: {
      channels: [],
      subscribedNotificationMessageTypes: [],
      displayNotificationMessageTypes: []
    }
  },
  accessibleDealers: [],
  searchResult: [],
  hasNextPage: false,
  maxPage: 1,
  isLoading: false,
  fetchStatus: apiStatusConstants.IS_FETCHING
};

export const userSettingsReducer = (state = initialState, action, dealerId = '') => {
  switch (action.type) {
    case USER_SETTINGS.SET_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.data },
        dealerId
      };
    case USER_SETTINGS.SET_DEALERIDS:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: {
            ...state.data.notifications,
            subscribedNotificationMessageTypes: state.data.notifications.subscribedNotificationMessageTypes.map((x) => {
              x.dealerIds = [...x.dealerIds.filter((x) => x.checked).map((dealer) => dealer.id), ...x.unuseDealerIds];
              return {
                ...x,
                unuseDealerIds: x.dealerIds.filter((id) => {
                  return !action.accessibleDealers.map((x) => x.id).includes(id);
                }),
                dealerIds: action.accessibleDealers.map((dealer) => {
                  return {
                    ...dealer,
                    checked: x.dealerIds.includes(dealer.id)
                  };
                }),
                hasNextPage: action.hasNextPage
                // page: action.page
              };
            })
          }
        }
      };
    case USER_SETTINGS.SET_DEALERIDS_BY_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: {
            ...state.data.notifications,
            subscribedNotificationMessageTypes: state.data.notifications.subscribedNotificationMessageTypes.map((x) => {
              if (x.notificationMessageType === action.notificationMessageType) {
                x.dealerIds = [...x.dealerIds.filter((x) => x.checked).map((dealer) => dealer.id), ...x.unuseDealerIds];
                return {
                  ...x,
                  unuseDealerIds: x.dealerIds.filter((id) => {
                    return !action.accessibleDealers.map((x) => x.id).includes(id);
                  }),
                  dealerIds: action.accessibleDealers.map((dealer) => {
                    return {
                      ...dealer,
                      checked: x.dealerIds.includes(dealer.id)
                    };
                  }),
                  hasNextPage: action.hasNextPage,
                  page: action.page,
                  searchValue: action.searchValue
                };
              } else {
                return x;
              }
            })
          }
        }
      };
    case USER_SETTINGS.SET_NOTIFICATIONS:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: {
            channels: action.channels,
            subscribedNotificationMessageTypes: action.notifications,
            displayNotificationMessageTypes: action.notifications,
            subscribedNotificationDealerIds: new Set(
              action.notifications
                .filter((x) => x.isActive)
                .map((x) => x.dealerIds)
                .flat()
                .filter((x) => x.checked)
                .map((x) => x.id)
            )
          }
        }
      };
    case USER_SETTINGS.SET_ACCESSIBLE_DEALERS:
      return {
        ...state,
        accessibleDealers: action.dealers
      };
    case USER_SETTINGS.SET_HAS_NEXT_PAGE:
      return {
        ...state,
        hasNextPage: action.hasNextPage
      };
    case USER_SETTINGS.SET_MAX_PAGE:
      return {
        ...state,
        maxPage: action.page
      };
    case USER_SETTINGS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.status
      };
    case USER_SETTINGS.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.status
      };
    case USER_SETTINGS.SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.searchResult
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const userSettingsSelector = (state) => state.userSettings.data;
export const accessibleDealersSelector = (state) => state.userSettings.accessibleDealers;

export const userNotificationChannelsSelector = createSelector(
  userSettingsSelector,
  (userSettings) => userSettings.notifications.channels || []
);

export const userNotificationEventsSelector = createSelector(
  userSettingsSelector,
  (userSettings) => userSettings.notifications.subscribedNotificationMessageTypes || []
);

export const isUserSubscribedToNotificationsAtMultipleDealersSelector = createSelector(
  userSettingsSelector,
  (userSettings) => (userSettings.notifications.subscribedNotificationDealerIds || []).size > 1
);
//#endregion

const convertedNotification = (result, accessibleDealers) => {
  return {
    ...result,
    settings: {
      ...result.settings,
      notifications: {
        ...result.settings.notifications,
        subscribedNotificationMessageTypes: result.settings.notifications.subscribedNotificationMessageTypes.map(
          (x) => {
            return {
              ...x,
              unuseDealerIds: x.dealerIds.filter((id) => {
                return !accessibleDealers.items.map((x) => x.id).includes(id);
              }),
              dealerIds: accessibleDealers.items.map((dealer) => {
                return {
                  ...dealer,
                  checked: x.dealerIds.includes(dealer.id)
                };
              }),
              hasNextPage: accessibleDealers.count > accessibleDealers.items.length,
              page: 11
            };
          }
        ),
        subscribedNotificationDealerIds: new Set(
          result.settings.notifications.subscribedNotificationMessageTypes
            .filter((x) => x.isActive)
            .map((x) => x.dealerIds)
            .flat()
        )
      }
    }
  };
};

//#region Sagas
export function* getUserSettingsSaga() {
  yield takeLatest([USER_SETTINGS.GET], function* ({ userId, dealerId }) {
    try {
      let params = {};
      const { isRootUser, vendorShipSelected, currentId } = yield select(rootEntitySwitcherSelector);
      if (!!dealerId && !(isRootUser && vendorShipSelected)) params.dealerId = dealerId;
      if (isRootUser && vendorShipSelected) params.vendorIdSelected = currentId;
      yield put(userSettingsActions.setFetchStatus(apiStatusConstants.PENDING));
      const [result, accessibleDealers] = yield all([
        getWithToken(`/api/Users/id/${userId}/settings`, params),
        getWithToken(`/api/Dealers/simple-accessible`, {
          start: 1,
          limit: 10,
          entityType: ewsEntityTypes.DEALER
        })
      ]);
      const { count, items } = accessibleDealers;
      yield put(userSettingsActions.setMaxPage(11));
      let hasNextPage = count > items.length;
      yield put(userSettingsActions.setHasNextPage(hasNextPage));

      // Mapping accessibleDealers to dealerIds on each notification message
      yield put(userSettingsActions.setData(convertedNotification(result, accessibleDealers).settings, dealerId));
      yield put(userSettingsActions.setAccessibleDealers(items));
      yield put(userSettingsActions.setFetchStatus(apiStatusConstants.SUCCEEDED));

      // Call the next time if have next page. Reduce waiting time of users
      if (hasNextPage) {
        yield put(userSettingsActions.setIsLoading(true));
        const result = yield getWithToken(`/api/Dealers/simple-accessible`, {
          start: 11,
          limit: 10,
          entityType: ewsEntityTypes.DEALER
        });
        yield put(userSettingsActions.setMaxPage(21));
        hasNextPage = items.length + result.items.length < result.count;
        yield put(userSettingsActions.setHasNextPage(hasNextPage));
        yield put(userSettingsActions.setAccessibleDealers([...items, ...result.items]));
        yield put(userSettingsActions.setDealerIds([...items, ...result.items], hasNextPage, 21));
        yield put(userSettingsActions.setIsLoading(false));
      }
    } catch (error) {
      // TODO: handle error
      yield put(userSettingsActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getUserSettingsSaga', error);
    }
  });
}

export function* getFullAccessibleDealersSaga() {
  yield takeLatest(
    [USER_SETTINGS.GET_ACCESSIBLE_DEALERS_BY_TYPE],
    function* ({ notificationMessageType, searchValue }) {
      try {
        yield put(userSettingsActions.setIsLoading(true));
        let hasNextPage = yield select((state) => state.userSettings.hasNextPage);
        const maxPage = yield select((state) => state.userSettings.maxPage);
        let accessibleDealers = yield select((state) => state.userSettings.accessibleDealers);

        if (hasNextPage) {
          const result = yield getWithToken(`/api/Dealers/simple-accessible`, {
            start: maxPage,
            limit: 10,
            search: searchValue,
            entityType: ewsEntityTypes.DEALER
          });
          yield put(userSettingsActions.setMaxPage(maxPage + 10));
          hasNextPage = accessibleDealers.length + result.items.length < result.count;
          yield put(userSettingsActions.setHasNextPage(accessibleDealers.length + result.items.length < result.count));
          accessibleDealers = [...accessibleDealers, ...result.items];
          yield put(userSettingsActions.setAccessibleDealers(accessibleDealers));
        }
        yield put(
          userSettingsActions.setDealerIdsByType(
            accessibleDealers,
            notificationMessageType,
            maxPage,
            hasNextPage,
            searchValue
          )
        );
        yield put(userSettingsActions.setIsLoading(false));
      } catch (error) {
        // TODO: handle error
        yield put(userSettingsActions.setFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getUserSettingsSaga', error);
      }
    }
  );
}

export function* getAccessibleDealersBySearchSaga() {
  yield takeLatest([USER_SETTINGS.SEARCHING_ACCESSIBLE_DEALERS], function* ({ searchValue, notificationMessageType }) {
    try {
      yield delay(500);
      let { subscribedNotificationMessageTypes } = yield select((state) => state.userSettings.data.notifications);
      const {
        dealerIds,
        unuseDealerIds,
        page,
        searchValue: currentValue
      } = subscribedNotificationMessageTypes.find((noti) => noti.notificationMessageType === notificationMessageType);
      const result = yield getWithToken(`/api/Dealers/simple-accessible`, {
        start: searchValue === currentValue ? page : 1,
        limit: searchValue === currentValue ? 10 : 20,
        search: searchValue,
        entityType: ewsEntityTypes.DEALER
      });
      const mappedResult = result.items.map((i) => {
        return {
          ...i,
          checked: [...unuseDealerIds, ...dealerIds.filter((x) => x.checked).map((y) => y.id)].includes(i.id)
        };
      });
      yield put(
        userSettingsActions.setDealerIdsByType(
          searchValue !== currentValue ? mappedResult : [...dealerIds, ...mappedResult],
          notificationMessageType,
          searchValue !== currentValue ? 21 : page + 10,
          searchValue !== currentValue
            ? result.items.length < result.count
            : dealerIds.length + result.items.length < result.count,
          searchValue
        )
      );
    } catch (error) {
      // TODO: handle error
      yield put(userSettingsActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getUserSettingsSaga', error);
    }
  });
}

// Using for backup
export function* updateNotificationSettingsSaga() {
  yield takeLatest(
    [USER_SETTINGS.UPDATE_NOTIFICATIONS],
    function* ({ notifications, userId, dealerId, stateChangeSetting, selected }) {
      try {
        let params = {};
        const { isRootUser, vendorShipSelected, currentId } = yield select(rootEntitySwitcherSelector);
        if (!!dealerId && !(isRootUser && vendorShipSelected)) params.dealerId = dealerId;
        if (isRootUser && vendorShipSelected) params.vendorIdSelected = currentId;
        const current = yield select(userSettingsSelector);
        const result = yield putWithToken(
          `/api/Users/id/${userId}/settings`,
          {
            ...current,
            notifications: notifications
          },
          params
        );

        yield put(userSettingsActions.setData(convertedNotification(result).settings, dealerId));
        if (
          (stateChangeSetting === TOGGLE_CHANGE.event &&
            selected &&
            notifications.subscribedNotificationMessageTypes.length === 1) ||
          (stateChangeSetting === TOGGLE_CHANGE.channel && notifications.channels.includes(IN_APP))
        ) {
          yield put(userAppStateActions.setJournalHistoryChecked(userId, dealerId));
        }
      } catch (error) {
        devLogger.log('error in updateNotificationSettingsSaga', error);
      }
    }
  );
}

export function* debounceUpdateNotificationSettingsSaga() {
  yield takeLatest(
    [USER_SETTINGS.DEBOUNCE_UPDATE_NOTIFICATIONS],
    function* ({
      notifications,
      userId,
      dealerId,
      stateChangeSetting,
      selected,
      isSalesperson,
      eventId,
      isChangedDealers
    }) {
      try {
        let params = {};
        const { isRootUser, vendorShipSelected, currentId } = yield select(rootEntitySwitcherSelector);
        if (!!dealerId && !(isRootUser && vendorShipSelected)) params.dealerId = dealerId;
        if (isRootUser && vendorShipSelected) params.vendorIdSelected = currentId;
        const selectedEvents = notifications.subscribedNotificationMessageTypes;
        let accessibleDealers = yield select((state) => state.userSettings.accessibleDealers);
        let accessibleDealerIds = [];
        const hasNextPage = yield select((state) => state.userSettings.hasNextPage);
        let updatedEvents;
        // Check case ON message type for the first time
        if (!selectedEvents.find((e) => e.notificationMessageType === eventId)) {
          // Add Object with all accessibleDealers checked as true
          if (hasNextPage) {
            yield put(userSettingsActions.setFetchStatus(apiStatusConstants.PENDING));
            const result = yield getWithToken(`/api/Dealers/accessible-id`);
            accessibleDealerIds = result;
            // yield put(userSettingsActions.setHasNextPage(true));
            // yield put(userSettingsActions.setAccessibleDealers(result.items));
          }
          yield (updatedEvents = [
            ...selectedEvents,
            {
              dealerIds: accessibleDealers.map((dealer) => {
                return {
                  ...dealer,
                  checked: true
                };
              }),
              isActive: true,
              notificationMessageType: eventId,
              hasNextPage: hasNextPage,
              unuseDealerIds: hasNextPage
                ? accessibleDealerIds.slice(
                    accessibleDealers.map((dealer) => {
                      return {
                        ...dealer,
                        checked: true
                      };
                    }).length
                  )
                : []
            }
          ]);

          yield put(
            userSettingsActions.setNotifications(
              [...updatedEvents].map((e) => {
                if (e.notificationMessageType === eventId) {
                  // Keep state isShowMore when turn on toggle
                  return {
                    ...e,
                    unuseDealerIds: hasNextPage ? accessibleDealerIds.slice(e.dealerIds.length) : [],
                    dealerIds: e.dealerIds,
                    hasMore: accessibleDealers.length > 10,
                    hasNextPage: hasNextPage
                  };
                } else {
                  return e;
                }
              }),
              notifications.channels
            )
          );
        } else {
          // Check when on toggle, if all checkboxes are uncheck and wasn't get All accessibledealers yet
          // Need to get list accessibles dealers => send list dealerIds to BE for updating
          // Can't do yield on selectedEvents.map below => need to do here
          const isNeedGetAllAccessibleDealers =
            !isChangedDealers &&
            hasNextPage &&
            !!selectedEvents.find(
              (e) => e.notificationMessageType === eventId && !e.dealerIds.filter((x) => x.checked).length > 0
            );
          let result = [];
          let accessibleDealerIds = [];
          if (isNeedGetAllAccessibleDealers) {
            yield put(userSettingsActions.setFetchStatus(apiStatusConstants.PENDING));
            result = yield getWithToken(`/api/Dealers/accessible-id`);
            accessibleDealerIds = result;
          } else {
            accessibleDealers = yield select((state) => state.userSettings.accessibleDealers);
          }
          updatedEvents = selectedEvents.map((e) => {
            if (e.notificationMessageType === eventId) {
              if (isChangedDealers) {
                // If uncheck all checkbox, off toggle else filter only checked
                return {
                  ...e,
                  isActive: selected.length > 0 || e.unuseDealerIds.length > 0,
                  dealerIds: e.dealerIds.map((dealer) => {
                    return {
                      ...dealer,
                      checked: selected.includes(dealer.id)
                    };
                  })
                };
              } else {
                // ON toggle, if had checkbox has been checked, check only cheked checkbox
                // else check all
                let { dealerIds } = e;
                if (dealerIds.filter((x) => x.checked).length > 0 || e.unuseDealerIds.length > 0) {
                  dealerIds = e.dealerIds;
                  return {
                    ...e,
                    isActive: selected,
                    dealerIds: dealerIds
                  };
                } else {
                  // dealerIds = accessibleDealers.map((dealer) => {
                  //   return {
                  //     ...dealer,
                  //     checked: true
                  //   };
                  // });
                  return {
                    ...e,
                    // isActive: selected,
                    // unuseDealerIds: isNeedGetAllAccessibleDealers
                    //   ? accessibleDealerIds.slice(10)
                    //   : dealerIds.slice(10).map((x) => x.id),
                    // dealerIds: e.hasMore ? dealerIds.slice(0, 10) : dealerIds,
                    // hasNextPage: hasNextPage
                    dealerIds: accessibleDealers.map((dealer) => {
                      return {
                        ...dealer,
                        checked: true
                      };
                    }),
                    isActive: true,
                    notificationMessageType: eventId,
                    hasNextPage: hasNextPage,
                    unuseDealerIds: hasNextPage
                      ? accessibleDealerIds.slice(
                          accessibleDealers.map((dealer) => {
                            return {
                              ...dealer,
                              checked: true
                            };
                          }).length
                        )
                      : []
                  };
                }
              }
            } else {
              const dealerList = e.dealerIds;
              return { ...e, dealerIds: dealerList };
            }
          });

          yield put(userSettingsActions.setNotifications(updatedEvents, notifications.channels));
        }

        // Update isActive and dealerIds of VEHICLE_COMMENT_USER_TAGGED is the same as TASK_COMMENT_USER_TAGGED
        const taskUserTagged = updatedEvents.find(
          (object) => object.notificationMessageType === journalEventTypes.TASK_COMMENT_USER_TAGGED
        );
        const vehicleUserTagged = updatedEvents.find(
          (object) => object.notificationMessageType === journalEventTypes.VEHICLE_COMMENT_USER_TAGGED
        );

        const vendorConfirmation = updatedEvents.find(
          (object) => object.notificationMessageType === journalEventTypes.VENDOR_CONFIRMATION
        );
        const vendorInvitationDeclined = updatedEvents.find(
          (object) => object.notificationMessageType === journalEventTypes.VENDOR_INVITATION_DECLINED
        );
        const vendorInvitationAccepted = updatedEvents.find(
          (object) => object.notificationMessageType === journalEventTypes.VENDOR_INVITATION_ACCEPTED
        );

        if (!isSalesperson) {
          if (taskUserTagged) {
            if (vehicleUserTagged) {
              vehicleUserTagged.isActive = taskUserTagged.isActive;
              vehicleUserTagged.dealerIds = taskUserTagged.dealerIds;
              vehicleUserTagged.unuseDealerIds = taskUserTagged.unuseDealerIds;
              vehicleUserTagged.hasNextPage = taskUserTagged.hasNextPage;
              vehicleUserTagged.page = taskUserTagged.page;
              vehicleUserTagged.searchValue = taskUserTagged.searchValue;
            } else {
              let newVehicleUserTagged = { ...taskUserTagged };
              newVehicleUserTagged.notificationMessageType = journalEventTypes.VEHICLE_COMMENT_USER_TAGGED;
              updatedEvents.push(newVehicleUserTagged);
            }
          } else {
            updatedEvents = [
              ...updatedEvents.filter(
                (x) => x.notificationMessageType !== journalEventTypes.VEHICLE_COMMENT_USER_TAGGED
              )
            ];
          }
        } else {
          updatedEvents = [
            ...updatedEvents.filter((x) => x.notificationMessageType !== journalEventTypes.TASK_COMMENT_USER_TAGGED)
          ];
        }

        if (vendorConfirmation) {
          if (vendorInvitationDeclined) {
            vendorInvitationDeclined.isActive = vendorConfirmation.isActive;
            vendorInvitationDeclined.dealerIds = vendorConfirmation.dealerIds;
            vendorInvitationDeclined.unuseDealerIds = vendorConfirmation.unuseDealerIds;
            vendorInvitationDeclined.hasNextPage = vendorConfirmation.hasNextPage;
            vendorInvitationDeclined.page = vendorConfirmation.page;
            vendorInvitationDeclined.searchValue = vendorConfirmation.searchValue;
          } else {
            let newVendorInvitationDeclined = { ...vendorConfirmation };
            newVendorInvitationDeclined.notificationMessageType = journalEventTypes.VENDOR_INVITATION_DECLINED;
            updatedEvents.push(newVendorInvitationDeclined);
          }

          if (vendorInvitationAccepted) {
            vendorInvitationAccepted.isActive = vendorConfirmation.isActive;
            vendorInvitationAccepted.dealerIds = vendorConfirmation.dealerIds;
            vendorInvitationAccepted.unuseDealerIds = vendorConfirmation.unuseDealerIds;
            vendorInvitationAccepted.hasNextPage = vendorConfirmation.hasNextPage;
            vendorInvitationAccepted.page = vendorConfirmation.page;
            vendorInvitationAccepted.searchValue = vendorConfirmation.searchValue;
          } else {
            let newVendorInvitationAccepted = { ...vendorConfirmation };
            newVendorInvitationAccepted.notificationMessageType = journalEventTypes.VENDOR_INVITATION_ACCEPTED;
            updatedEvents.push(newVendorInvitationAccepted);
          }
        }

        if (!vendorConfirmation && vendorInvitationDeclined) {
          updatedEvents = [
            ...updatedEvents.filter((x) => x.notificationMessageType !== journalEventTypes.VENDOR_INVITATION_DECLINED)
          ];
        }

        if (!vendorConfirmation && vendorInvitationAccepted) {
          updatedEvents = [
            ...updatedEvents.filter((x) => x.notificationMessageType !== journalEventTypes.VENDOR_INVITATION_ACCEPTED)
          ];
        }

        // Convert dealerIds to model PUT
        const mappedPayload = {
          notifications: {
            ...notifications,
            subscribedNotificationMessageTypes: updatedEvents
              .filter((x) => x.notificationMessageType)
              .map((x) => ({
                ...x,
                dealerIds: [...x.dealerIds.filter((x) => x.checked).map((dealer) => dealer.id), ...x.unuseDealerIds]
              }))
              .map(({ unuseDealerIds, hasMore, ...item }) => item),
            displayNotificationMessageTypes: updatedEvents
              .filter((x) => x.notificationMessageType)
              .map((x) => ({
                ...x,
                dealerIds: [...x.dealerIds.filter((x) => x.checked).map((dealer) => dealer.id), ...x.unuseDealerIds]
              }))
              .map(({ unuseDealerIds, hasMore, ...item }) => item)
          }
        };
        yield delay(700);
        yield putWithToken(`/api/Users/id/${userId}/settings`, mappedPayload, params);
        if (
          (stateChangeSetting === TOGGLE_CHANGE.event &&
            selected &&
            notifications.subscribedNotificationMessageTypes.length === 1) ||
          (stateChangeSetting === TOGGLE_CHANGE.channel && notifications.channels.includes(IN_APP))
        ) {
          yield put(userAppStateActions.setJournalHistoryChecked(userId, dealerId));
        }
        yield put(userSettingsActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        yield put(messagesActions.notify('error', 'Failed to update settings', { duration: 3.5 }));
        devLogger.log('error in debounceUpdateNotificationSettingsSaga', error);
      }
    }
  );
}

export function* updateInSearchSaga() {
  yield takeLatest([USER_SETTINGS.UPDATE_IN_SEARCH], function* ({ userId, dealerId, selected, eventId }) {
    try {
      let { notifications } = yield select((state) => state.userSettings.data);
      // dealerId of login user
      const dealerIdLogin = yield select((state) => state.dealers?.current?.data?.id) || '';
      let searchResult = yield select((state) => state.userSettings.searchResult);
      const updatedNotifications = {
        ...notifications,
        subscribedNotificationMessageTypes: notifications.subscribedNotificationMessageTypes.map((item) => {
          if (item.notificationMessageType === eventId) {
            if (item.dealerIds.find((x) => x.id === dealerId)) {
              return {
                ...item,
                dealerIds: item.dealerIds.map((x) => {
                  if (x.id === dealerId) {
                    return {
                      ...x,
                      checked: selected
                    };
                  } else {
                    return x;
                  }
                })
              };
            } else {
              if (selected) {
                return {
                  ...item,
                  unuseDealerIds: [...item.unuseDealerIds, ...dealerId]
                };
              } else {
                return {
                  ...item,
                  unuseDealerIds: [...item.unuseDealerIds].filter((x) => x !== dealerId)
                };
              }
            }
          } else {
            return item;
          }
        })
      };
      const { dealerIds } = updatedNotifications.subscribedNotificationMessageTypes.find(
        (x) => x.notificationMessageType === eventId
      );
      yield put(
        userSettingsActions.setSearchResult(
          searchResult.map((x) =>
            x.id === dealerId
              ? {
                  ...x,
                  checked: selected
                }
              : x
          )
        )
      );
      yield put(
        userSettingsActions.debounceUpdateNotifications(
          userId,
          dealerIdLogin,
          updatedNotifications,
          TOGGLE_CHANGE.event,
          dealerIds.filter((dealer) => dealer.checked).map((item) => item.id),
          eventId,
          true
        )
      );

      // yield putWithToken(`/api/Users/id/${userId}/settings`, mappedPayload, params);
      // if (
      //   (stateChangeSetting === TOGGLE_CHANGE.event &&
      //     selected &&
      //     notifications.subscribedNotificationMessageTypes.length === 1) ||
      //   (stateChangeSetting === TOGGLE_CHANGE.channel && notifications.channels.includes(IN_APP))
      // ) {
      //   yield put(userAppStateActions.setJournalHistoryChecked(userId, dealerId));
      // }
      yield put(userSettingsActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(messagesActions.notify('error', 'Failed to update settings', { duration: 3.5 }));
      devLogger.log('error in updateInSearchSaga', error);
    }
  });
}

//#endregion
