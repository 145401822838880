import { Affix, Pagination } from 'antd';
import { inventorySortLabels, sortOptionDefault } from 'app-constants';
import { BorderlessSelect, ExportFunctionality } from 'components';
import { CommonListPageContainer, CommonMenu, CommonMenuItem, CommonSortRow } from 'components/styledComponents';
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { vehiclesSelector } from 'store/vehiclesStore';
import { authSelector } from 'store/authStore';
import { StyledCard, StyledListContainer, StyledPaginationContainer, VehicleList } from '.';
import { VehicleFilters } from '..';
import { GlobalAlertContext } from '../../../App';
import { ewsEntityTypes } from '../../../../app-constants';
import { HQAndProfitTimeContext } from 'utils/contexts';

export default React.memo(
  ({
    scrollcontainer,
    menuState,
    setMenuState, // check this
    displayRange,
    query,
    setQuery,
    sortOptions,
    sortDropdownIsOpen,
    flags,
    sort,
    filters,
    currentDealer,
    memorizedOnTemplatePlanChosen,
    count,
    pager,
    fetchPage,
    vehiclePresentationDispatch,
    setVehiclesPerPage,
    assignedToTechnician,
    setAssignedToTechnician,
    assignedToGroup,
    setAssignedToGroup,
    userId
  }) => {
    const vehiclesData = useSelector(vehiclesSelector);
    const { isBridgeUser } = useSelector(authSelector);
    const [hasNoFilters, setHasNoFilters] = useState(true);
    const globalAlertWrapper = useContext(GlobalAlertContext);
    const { hasReconProfitTime } = useContext(HQAndProfitTimeContext);

    const sortMenu = (
      <CommonMenu
        onClick={({ key }) => {
          vehiclePresentationDispatch({ type: 'sortDropdownIsOpen', payload: false });
          if (key !== sort) {
            setQuery({ sort: key }, 'pushIn');
          }
        }}
      >
        {Object.entries(sortOptions).map((o) => (
          <CommonMenuItem key={o[0]} data-isselected={sort === o[0]}>
            {o[1]}
          </CommonMenuItem>
        ))}
      </CommonMenu>
    );

    const decideSortValuesShown = (sort) => {
      if (sortOptions && Object.keys(sortOptions).length !== 0 && !sortOptions[sort]) {
        setQuery({ sort: sortOptionDefault.VEHICLE }, 'pushIn');
        return sortOptions[sortOptionDefault.VEHICLE];
      }
      return inventorySortLabels[sort];
    };

    // onChangePage is a function that controls pagination information - what page number is the user viewing?
    const onChangePage = (pageNumber, pageSize) => {
      const newPager = {
        start: (pageNumber - 1) * pageSize + 1,
        limit: pageSize
      };
      fetchPage(newPager, filters, sort);
    };

    const onShowSizeChange = (pageNumber, pageSize) => {
      const newPager = {
        start: 1, // Page size is changing. Reset the page offset.
        limit: pageSize
      };
      setVehiclesPerPage(pageSize);
      fetchPage(newPager, filters, sort);
    };

    return (
      <CommonListPageContainer>
        <Affix offsetTop={0} target={() => scrollcontainer.current}>
          <VehicleFilters
            menuState={menuState}
            setMenuState={setMenuState}
            displayRange={displayRange}
            query={query}
            setQuery={setQuery}
            setHasNoFilters={setHasNoFilters}
            assignedToTechnician={assignedToTechnician}
            setAssignedToTechnician={setAssignedToTechnician}
            assignedToGroup={assignedToGroup}
            setAssignedToGroup={setAssignedToGroup}
            userId={userId}
            flags={flags}
          />
        </Affix>
        <CommonSortRow>
          <div>
            {vehiclesData && (
              <span>
                Showing&nbsp;<strong>{displayRange}&nbsp;</strong>of&nbsp;
                <strong>{vehiclesData.count}&nbsp;</strong>vehicles
              </span>
            )}
          </div>
          <BorderlessSelect
            menu={sortMenu}
            dropdownIsOpen={sortDropdownIsOpen}
            setDropdownIsOpen={(val) => vehiclePresentationDispatch({ type: 'sortDropdownIsOpen', payload: val })}
            label="Sort By"
            // Decide if the sort state is available with the filters selected
            selectedLabel={decideSortValuesShown(sort)}
          />
          <ExportFunctionality filters={filters} sort={sort} exportType="Vehicle" currentEntityId={currentDealer?.id} />
        </CommonSortRow>
        <StyledListContainer
          hasNoFilters={hasNoFilters}
          is-bridge-user={isBridgeUser}
          global-alert={flags?.reconAlerts && globalAlertWrapper}
          reconFooter={flags?.reconFooter}
        >
          <VehicleList
            isLoading={false}
            setTaskGroups={(val) => vehiclePresentationDispatch({ type: 'taskGroups', payload: val })}
            setTemplatePlanCreatedFrom={(val) =>
              vehiclePresentationDispatch({ type: 'templatePlanCreatedFrom', payload: val })
            }
            onTemplatePlanChosen={memorizedOnTemplatePlanChosen}
            setFormProps={(val) => vehiclePresentationDispatch({ type: 'formProps', payload: val })}
            reconProfitTime={hasReconProfitTime}
            reconFooter={flags?.reconFooter}
            hasNoFilters={hasNoFilters}
            isBridgeUser={isBridgeUser}
            globalAlert={flags?.reconAlerts && globalAlertWrapper}
            showDealerName={
              flags?.ireconHqView &&
              (currentDealer?.entityType === ewsEntityTypes.CONTAINER ||
                currentDealer?.entityType === ewsEntityTypes.ENTERPRISE)
            }
            pagination={
              <StyledCard>
                <StyledPaginationContainer>
                  <Pagination
                    size="small"
                    showSizeChanger
                    showQuickJumper
                    total={count}
                    pageSize={Number(pager.limit)}
                    pageSizeOptions={['10', '25', '50', '100']}
                    current={Math.floor(pager.start / pager.limit) + 1}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    onChange={onChangePage}
                    onShowSizeChange={onShowSizeChange}
                  />
                </StyledPaginationContainer>
              </StyledCard>
            }
          />
        </StyledListContainer>

        {!flags?.reconFooter && (
          <Affix offsetBottom={0} target={() => scrollcontainer.current}>
            <StyledCard>
              <StyledPaginationContainer>
                <Pagination
                  size="small"
                  showSizeChanger
                  showQuickJumper
                  total={count}
                  pageSize={Number(pager.limit)}
                  pageSizeOptions={['10', '25', '50', '100']}
                  current={Math.floor(pager.start / pager.limit) + 1}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={onChangePage}
                  onShowSizeChange={onShowSizeChange}
                />
              </StyledPaginationContainer>
            </StyledCard>
          </Affix>
        )}
      </CommonListPageContainer>
    );
  }
);
