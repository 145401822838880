import React from 'react';
import { ReconTag, reconTagTypes } from 'components/tags';
import { getTaskProgress, getNeedsApprovalEstimateRequest } from 'components/layout/tasks/common/taskUtils';
import { taskProgressLabels, apiStatusConstants } from 'app-constants';
import { PENDING } from 'app-constants/taskStatusTypes';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { DECLINED } from 'app-constants/taskStatusTypes.js';
import { getCountLineItemByTaskIdSelector } from 'store/tasksStore';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';

/**
 * Displays tags that are related to warning statuses
 *  to consolidate some common logic around task warnings.
 *
 * The possible warning tags are not all displayed. Only the tag
 *  with the "top" priority is displayed.
 *
 * @param {Task} task The task to display warning tags about
 * @param {*} other This encompasses any other props that might be
 *  wanted on the underlying antd components
 */
const ReconTaskWarningTag = ({
  insideTaskCard = false,
  task = false,
  isInProgressTaskGroup,
  isHideNeedsApprovalTag = false,
  flags,
  ...other
}) => {
  let { disableCompleteTask, needsApproval } = useSelector(getCountLineItemByTaskIdSelector(task.id));

  if (!!task?.lineItems?.items?.length) {
    disableCompleteTask = getNeedsApprovalEstimateRequest(task?.lineItems);
  }

  let isFetching = useSelector((state) => state.tasks.fetchStatus);
  let isFetchingTLP = useSelector((state) => state.lineItems.fetchLineItemsStatus);
  let location = useLocation();

  // Declined task icon takes first priority
  if (task.status === DECLINED || task.declined || task.declinedOn) {
    return <ReconTag insideTaskCard={insideTaskCard} type={reconTagTypes.DECLINED} {...other} />;
  }

  if (!isHideNeedsApprovalTag && disableCompleteTask > 0 && needsApproval > 0) {
    return (
      <ReconTag
        type={flags.reconApproveButton ? reconTagTypes.PENDING_APPROVAL : reconTagTypes.NEEDS_APPROVAL}
        insideTaskCard={insideTaskCard}
        {...other}
      />
    );
  }

  const progress = getTaskProgress(task);

  // Don't want to show OVERDUE tag on overdue pending tasks
  if (task.status === PENDING || isInProgressTaskGroup === false) {
    // Short circuit here, don't want to display a warning tag
    return <></>;
  }

  if (progress.status === taskProgressLabels.Overdue) {
    if (checkWhenToNotShowOverdue(insideTaskCard, isFetching, isFetchingTLP, location)) {
      return <></>;
    } else {
      return <ReconTag insideTaskCard={insideTaskCard} type={reconTagTypes.OVERDUE} {...other} />;
    }
  } else {
    return <></>;
  }
};

const checkWhenToNotShowOverdue = (insideTaskCard, isFetching, isFetchingTLP, location) => {
  if (insideTaskCard) {
    if (checkIfInVDP(location)) {
      return isFetching === apiStatusConstants.IS_FETCHING;
    } else {
      return isFetchingTLP === apiStatusConstants.IS_FETCHING;
    }
  } else {
    return false;
  }
};

const checkIfInVDP = (location) => {
  return location.pathname === '/inventory';
};

export default withLDConsumer()(ReconTaskWarningTag);
