import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { navigate, Link } from '@reach/router';
import { Menu } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { SettingsMenu } from 'components/layout/settings';
import { NotificationHistory } from 'components/layout/notifications/';
import { BuildInfo, UsernameAndDealerPicker } from '.';
import { features } from 'app-constants';
import { useFeatures } from 'hooks';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import ReconLogo from 'assets/images/iRecon_logo.svg';

const Navbar = ({ location, setForceLoadPageQueryFromStorage, selectedNav, setSelectedNav, flags }) => {
  const [hasEditTasks, hasInventory, hasOwnTasks, hasAnyTask] = useFeatures(
    features.TASKS_EDIT,
    features.INVENTORY_VIEW,
    features.TASKS_VIEW_OWN,
    features.TASKS_VIEW_ANY
  );
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  useEffect(() => {
    const { pathname } = location;
    const [, path] = pathname.split('/');
    const validPaths = ['dashboard', 'inventory', 'tasks', 'settings', 'configure'];
    if (validPaths.includes(path)) {
      setSelectedNav(path);
    } else {
      setSelectedNav(null);
    }
  }, [location]);

  const onMenuClick = ({ key }) => {
    //Ensure that when we click the inventory or tasks tab, the associated page component can read the state value and know to update the query url from values in local storage.
    //This typically happens when the page component mounts, but a page will not remount if you are already on that page, and the saved query params would not be set.
    if (key === 'inventory' || key === 'tasks') {
      setForceLoadPageQueryFromStorage(true);
    }

    setSelectedNav(key);
  };

  return (
    <StyledContainer>
      <StyledTitleContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
        <BuildInfo>
          <StyledLogo onClick={() => navigate('/')}>
            <img alt="iRecon" src={ReconLogo} height="40px" width="100%" />
          </StyledLogo>
        </BuildInfo>
      </StyledTitleContainer>
      <StyledMenu selectedKeys={[selectedNav]} onClick={onMenuClick} reconDealerFilterFlag={flags?.reconDealerFilter}>
        {buildNavItem({
          path: 'dashboard',
          label: 'Dashboard',
          authorized: hasEditTasks && !(isRootUser && vendorShipSelected),
          reconDealerFilterFlag: flags?.reconDealerFilter
        })}
        {buildNavItem({
          path: 'inventory',
          label: 'Inventory',
          authorized: hasInventory,
          reconDealerFilterFlag: flags?.reconDealerFilter
        })}
        {buildNavItem({
          path: 'tasks',
          label: 'Tasks',
          authorized: hasAnyTask || hasOwnTasks,
          reconDealerFilterFlag: flags?.reconDealerFilter
        })}
      </StyledMenu>
      <StyledExtraContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
        <UsernameAndDealerPicker />
        {flags?.reconDealerFilter && <StyledUsernameAndSettingsDivider />}
        <SettingsMenu setSelectedNav={setSelectedNav} />
        <NotificationHistory />
      </StyledExtraContainer>
    </StyledContainer>
  );
};

const buildNavItem = ({ authorized = false, path, label, reconDealerFilterFlag, ...rest }) => {
  return authorized ? (
    <StyledMenuItem key={path} reconDealerFilterFlag={reconDealerFilterFlag} {...rest}>
      <Link to={path}>{label}</Link>
    </StyledMenuItem>
  ) : null;
};

//#region Styled Components
const StyledContainer = styled.div`
  display: flex;
`;
const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding-right: 15px;
`;

const StyledLogo = styled.div`
  margin-top: -20px;
  margin-bottom: -20px;
  padding: 16px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.backgroundColors.gray} !important;
  }
`;

const StyledMenu = styled(Menu).attrs({
  mode: 'horizontal'
})`
  display: flex;
  align-items: center;
  .ant-menu& {
    margin: 0 0 0 33.5px;
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
  .ant-menu-horizontal& {
    line-height: 30px;
    border-bottom: 0;
    .ant-menu-item-active {
      border-bottom: 0 !important;
      ${({ reconDealerFilterFlag, theme }) =>
        reconDealerFilterFlag
          ? `font-weight: ${theme.fontWeights.medium} !important;
          background-color: ${theme.backgroundColors.gray} !important;`
          : ''}
    }
  }
  .ant-menu-horizontal& > li {
    border-bottom: 0;
    ${({ reconDealerFilterFlag }) =>
      reconDealerFilterFlag ? 'height: calc(100% - 2px); display: flex; align-items: center;' : ''}
  }
`;
const StyledMenuItem = styled(Menu.Item)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  .ant-menu-item-selected& {
    font-weight: ${({ reconDealerFilterFlag, theme }) =>
      reconDealerFilterFlag ? theme.fontWeights.medium : theme.fontWeights.semibold};
    ${({ reconDealerFilterFlag, theme }) =>
      reconDealerFilterFlag ? `background-color: ${theme.backgroundColors.gray};` : ''}
  }
  ${({ reconDealerFilterFlag, theme }) =>
    reconDealerFilterFlag
      ? ''
      : `.ant-menu-item-selected& > a {
        border-bottom: 2px solid ${theme.colors.red};
      }`}
`;
const StyledExtraContainer = styled.div`
  flex: 1 0;
  display: flex;
  justify-content: flex-end;
  align-items: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 'center' : 'stretch')};
`;

const StyledUsernameAndSettingsDivider = styled.div`
  width: 0;
  height: 32px;
  border-right: ${({ theme }) => theme.borders.mediumSolidGray};
`;
//#endregion

export default withLDConsumer()(Navbar);
