import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const StyledHeader = styled(Text)`
  &.ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes.title};
    line-height: ${({ theme }) => theme.lineHeights.title};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;
