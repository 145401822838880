import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

//our components
import Statistics, { StatisticsSkeleton, StyledCol, StyledStatistic, StyledFlexStatisticFooter } from './Statistics';
import { ShowMoreHqInventorySummary, ShowMoreHqHistoricalSummary } from '../enterprise';
//styled components

//media query hook
import { useScreenSize } from 'hooks';

//app constants
import { apiStatusConstants, statisticTagTypes } from 'app-constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { generateDealershipsLink, getStatisticLink, getTaskCategoriesParamsFromExcludedIds } from '../commonFunctions';

import { HQAndProfitTimeContext } from 'utils/contexts';

import { taskCategoriesActions } from 'store/taskCategoriesStore';

import { ShowExcludedTaskCategoriesFilter, ExcludeTaskCategoriesDivider } from '../taskCategoryFilter';

import { createSelector } from 'reselect';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';

const changePresentationData = (isActiveMetrics, data, reconExcludeTaskCategories) => {
  if (reconExcludeTaskCategories) {
    if (isActiveMetrics) {
      return {
        presentationData: data.activeInventoryPerformanceMetrics,
        unfilteredData: data.unfilteredActiveMetrics,
        fetchStatus: data.activeInventoryPerformanceMetricsFetchStatus
      };
    } else {
      return {
        presentationData: data.historicalPerformanceMetrics,
        unfilteredData: data.unfilteredHistoricalMetrics,
        fetchStatus: data.historicalPerformanceMetricsFetchStatus
      };
    }
  } else {
    if (isActiveMetrics) {
      return {
        presentationData: data.snapshotHistoricalMetrics || [],
        fetchStatus: data.snapshotHistoricalMetricsFetchStatus
      };
    } else {
      return {
        presentationData: data.historicalMetrics.overviewData,
        fetchStatus: data.historicalMetricsFetchStatus
      };
    }
  }
};

const propsSelector = createSelector(
  (state) => state.taskCategories.categoriesAll,
  (state) => state.metrics,
  (taskCategoriesData, metrics) => ({
    taskCategoriesData: taskCategoriesData,
    metricsData: metrics
  })
);

/**
 * @prop metricsType - Possible values are 'activeMetrics' and 'historicalMetrics'
 * **/

const Overview = ({
  metricsType = statisticTagTypes.ACTIVE_METRICS,
  filters = null,
  flags = {},
  useLocalStorageObject
}) => {
  const isActiveMetrics = metricsType === statisticTagTypes.ACTIVE_METRICS;
  const { taskCategoriesData, metricsData } = useSelector(propsSelector);
  const dispatch = useDispatch();
  const screenSize = useScreenSize();
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const { items } = useCommonDropdownContext('dealerships');
  const mappedItems = (items ?? []).filter((item) => item.checked).map((item) => item.name);
  const [fetchStatus, setFetchStatus] = useState(apiStatusConstants.IS_FETCHING);
  const [presentationData, setPresentationData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [, setData, getData] = useLocalStorageObject;

  const getExcludedTaskCategoryIds = () => {
    const filterType = metricsType === 'activeMetrics' ? 'inventorySnapshotFilters' : 'historicalPerformanceFilters';
    const dashboardPreference = getData();
    return dashboardPreference?.[filterType]?.excludedTaskCategories || [];
  };

  const getExtraFiltersForStatisticsLink = (statisticsType) => {
    if (!flags?.reconExcludeTaskCategories && !flags.reconDealerFilter) {
      return [];
    }

    const dealershipsFilterLink = generateDealershipsLink(flags.reconDealerFilter && isHQ ? mappedItems : null);

    // Category exclusion filters do not apply to certain statistic types. Do not apply category filters for those click throughs
    if (statisticsType === 'Vehicles Completed Recon' || statisticsType === 'Avg. Time Before Plan') {
      return [dealershipsFilterLink];
    }

    const excludedTaskCategoryIds = getExcludedTaskCategoryIds();
    let toReturn = getTaskCategoriesParamsFromExcludedIds(excludedTaskCategoryIds, taskCategoriesData.dict);
    toReturn.push(dealershipsFilterLink);
    return toReturn;
  };

  useEffect(() => {
    const data = changePresentationData(isActiveMetrics, metricsData, flags?.reconExcludeTaskCategories);
    setPresentationData(data.presentationData);
    setUnfilteredData(data.unfilteredData);
    setFetchStatus(data.fetchStatus);
  }, [metricsData, metricsType, flags?.reconExcludeTaskCategories]);

  useEffect(() => {
    dispatch(taskCategoriesActions.getData());
  }, []);

  const headerStatsCount = isActiveMetrics ? 4 : 5;
  const disableLinkForNonSpecialColumns =
    flags.reconDealerFilter && taskCategoriesData?.data?.length === getExcludedTaskCategoryIds()?.length;
  const noDealershipFiltersSelectedHQ = flags.reconDealerFilter && isHQ && mappedItems.length === 0;
  return (
    <div>
      <DashboardHeaderAndExcludeTaskCategoryModalContainer
        metricsType={metricsType}
        reconDealerFilterFlag={flags?.reconDealerFilter}
      >
        <DataHeader reconDealerFilterFlag={flags?.reconDealerFilter}>Performance Metrics</DataHeader>
        {flags?.reconExcludeTaskCategories && taskCategoriesData.fetchStatus === apiStatusConstants.SUCCEEDED ? (
          <>
            <ExcludeTaskCategoriesDivider style={flags?.reconDealerFilter ? { marginTop: 0 } : {}} />
            <ShowExcludedTaskCategoriesFilter metricsType={metricsType} useLocalStorageObject={useLocalStorageObject} />
          </>
        ) : (
          <></>
        )}
      </DashboardHeaderAndExcludeTaskCategoryModalContainer>
      <StyledSummaryContainer
        screenSize={screenSize}
        metricsType={metricsType}
        recon-export-dashboard={flags?.reconExportDashboard}
        reconDealerFilterFlag={flags?.reconDealerFilter}
      >
        <div className="statisticContainer">
          {fetchStatus !== apiStatusConstants.SUCCEEDED ||
          (flags?.reconExcludeTaskCategories && taskCategoriesData.fetchStatus !== apiStatusConstants.SUCCEEDED) ? (
            <StatisticsSkeleton key={`skeleton-${metricsType}`} statisticsCount={headerStatsCount} />
          ) : (
            <>
              {presentationData.map((statistics, index) => (
                <Statistics
                  key={`${metricsType}-${statistics.title}`}
                  statistics={statistics}
                  screenSize={screenSize}
                  statisticLink={getStatisticLink(
                    false,
                    statistics.title,
                    metricsType,
                    filters,
                    getExtraFiltersForStatisticsLink(statistics.title),
                    disableLinkForNonSpecialColumns,
                    noDealershipFiltersSelectedHQ,
                    false,
                    flags.reconHiddenFilter
                  )}
                  comparativeStatisticLink={getStatisticLink(
                    true,
                    statistics.title,
                    metricsType,
                    filters,
                    getExtraFiltersForStatisticsLink(statistics.title),
                    disableLinkForNonSpecialColumns,
                    noDealershipFiltersSelectedHQ,
                    false,
                    flags.reconHiddenFilter
                  )}
                  headerType="dashboard"
                  metricsType={
                    isActiveMetrics ? statisticTagTypes.ACTIVE_METRICS : statisticTagTypes.HISTORICAL_METRICS
                  }
                  unfilteredStats={unfilteredData ? unfilteredData[index] : null}
                />
              ))}
            </>
          )}
        </div>
        {isHQ && flags.ireconHqViewEnterprise && fetchStatus === apiStatusConstants.SUCCEEDED ? (
          <>
            {isActiveMetrics ? (
              <ShowMoreHqInventorySummary
                onClose={() => {
                  setData(getData());
                }}
              />
            ) : (
              <ShowMoreHqHistoricalSummary
                filters={filters}
                onClose={() => {
                  setData(getData());
                }}
              />
            )}
          </>
        ) : (
          ''
        )}
      </StyledSummaryContainer>
    </div>
  );
};

const StyledSummaryContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.borderColors.lightGray};
  padding: 16px;
  ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 'border-top: 0;' : '')}
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: ${(props) =>
    props['recon-export-dashboard'] ? 0 : props?.metricsType === statisticTagTypes.ACTIVE_METRICS ? 0 : '24px'}
  margin-bottom: 24px;
  border-radius: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? '0px 0px 4px 4px' : '4px')};

  ${StyledCol}:first-child > ${StyledStatistic},
  ${StyledCol}:first-child > ${StyledFlexStatisticFooter} {
    left: 0;
  }

  ${StyledCol}:first-child > span {
    left: 0;
  }

  ${StyledCol}:last-child {
    border-right: none;
  }

  .statisticContainer {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    > * {
      width: fit-content;
      flex-grow: 1;
    }
  }
`;
const DataHeader = styled.div`
  color: ${({ reconDealerFilterFlag, theme }) => (reconDealerFilterFlag ? theme.colors.darkGray : theme.colors.navy)};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ reconDealerFilterFlag, theme }) => (reconDealerFilterFlag ? theme.fontSizes.md : theme.fontSizes.lg)};
  ${({ reconDealerFilterFlag, theme }) =>
    reconDealerFilterFlag ? `line-height: ${theme.lineHeights.lg};` : 'margin-top: 1px;'}
`;

const DashboardHeaderAndExcludeTaskCategoryModalContainer = styled.div`
  display: flex;
  margin-bottom: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 0 : '8px')};
  margin-top: ${({ metricsType }) => (metricsType === 'historicalMetrics' ? '24px' : 0)};
  ${({ reconDealerFilterFlag, theme }) =>
    reconDealerFilterFlag
      ? `align-items: center;
      border: 1px solid ${theme.borderColors.lightGray};
      border-bottom: 0px;
      border-radius: 4px 4px 0 0;
      background-color: white;
      padding: 16px 16px 0px;`
      : ''}
`;

export default withLDConsumer()(Overview);
