import { takeLatest, put } from 'redux-saga/effects';
import { createRequestTypes, makeActionCreator } from 'utils';
import { apiStatusConstants, EXPORT_FAILURE_MESSAGE } from 'app-constants';
import { getFileWithToken, getWithToken } from 'api';
import { dateTimeWithFormat } from 'utils/dateTimeUtils';
import { messagesActions } from './messagesStore';

//#region Actions
export const ENTERPRISE_METRICS = createRequestTypes('ENTERPRISE_METRICS', [
  'GET_INVETORY_METRICS',
  'UPDATE_INVETORY_METRICS',
  'GET_HISTORICAL_METRICS',
  'UPDATE_HISTORICAL_METRICS',
  'GET_NEEDS_ATTENTION_METRICS',
  'UPDATE_NEEDS_ATTENTION_METRICS',
  'GET_EXPORT_NEED_ATTENTION_METRICS',
  'GET_EXPORT_HISTORICAL_METRICS',
  'SET_EXPORT_NEED_ATTENTION_FETCH_STATUS',
  'SET_EXPORT_HISTORICAL_FETCH_STATUS',
  'SET_EXPORT_PERFORMANCE_METRICS_FETCH_STATUS',
  'GET_EXPORT_PERFORMANCE_METRICS'
]);
export const enterpriseMetricsActions = {
  getEnterprisePerformanceMetrics: makeActionCreator(
    ENTERPRISE_METRICS.GET_INVETORY_METRICS,
    'entityId',
    'filters',
    'reconTimestampFilterFlag'
  ),
  getEnterpriseHistoricalMetrics: makeActionCreator(
    ENTERPRISE_METRICS.GET_HISTORICAL_METRICS,
    'entityId',
    'filters',
    'reconTimestampFilterFlag'
  ),
  getEnterpriseNeedsAttentionMetrics: makeActionCreator(
    ENTERPRISE_METRICS.GET_NEEDS_ATTENTION_METRICS,
    'entityId',
    'params'
  ),
  updatePerformanceMetrics: makeActionCreator(ENTERPRISE_METRICS.UPDATE_INVETORY_METRICS, 'result'),
  updateHistoricalMetrics: makeActionCreator(ENTERPRISE_METRICS.UPDATE_HISTORICAL_METRICS, 'result'),
  updateNeedsAttentionMetrics: makeActionCreator(ENTERPRISE_METRICS.UPDATE_NEEDS_ATTENTION_METRICS, 'result'),
  getExportNeedAttentionMetrics: makeActionCreator(
    ENTERPRISE_METRICS.GET_EXPORT_NEED_ATTENTION_METRICS,
    'entityId',
    'sort',
    'dealershipIds'
  ),
  getExportHistoricalMetrics: makeActionCreator(
    ENTERPRISE_METRICS.GET_EXPORT_HISTORICAL_METRICS,
    'entityId',
    'filters',
    'reconTimestampFilterFlag',
    'sort'
  ),
  setExportNeedAttentionFetchStatus: makeActionCreator(
    ENTERPRISE_METRICS.SET_EXPORT_NEED_ATTENTION_FETCH_STATUS,
    'fetchStatus'
  ),
  setExportHistoricalFetchStatus: makeActionCreator(
    ENTERPRISE_METRICS.SET_EXPORT_HISTORICAL_FETCH_STATUS,
    'fetchStatus'
  ),
  setExportEnterprisePerformanceMetricsFetchStatus: makeActionCreator(
    ENTERPRISE_METRICS.SET_EXPORT_PERFORMANCE_METRICS_FETCH_STATUS,
    'fetchStatus'
  ),
  getExportEnterprisePerformanceMetrics: makeActionCreator(
    ENTERPRISE_METRICS.GET_EXPORT_PERFORMANCE_METRICS,
    'entityId',
    'reconTimestampFilterFlag',
    'filters',
    'sort'
  )
};
//#endregion

//#region Reducer
const initialState = {
  inventory: {
    data: {},
    fetchStatus: apiStatusConstants.PENDING,
    exportEnterprisePerformanceMetricsFetchStatus: apiStatusConstants.PENDING
  },
  historical: {
    data: {},
    fetchStatus: apiStatusConstants.PENDING,
    exportHistoricalFetchStatus: apiStatusConstants.PENDING
  },
  needsAttention: {
    data: {},
    fetchStatus: apiStatusConstants.PENDING,
    exportNeedAttentionFetchStatus: apiStatusConstants.PENDING
  }
};

export const enterpriseMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENTERPRISE_METRICS.UPDATE_INVETORY_METRICS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          data: action.result.data,
          fetchStatus: action.result.fetchStatus
        }
      };
    case ENTERPRISE_METRICS.UPDATE_HISTORICAL_METRICS:
      return {
        ...state,
        historical: {
          ...state.historical,
          data: action.result.data,
          fetchStatus: action.result.fetchStatus
        }
      };
    case ENTERPRISE_METRICS.UPDATE_NEEDS_ATTENTION_METRICS:
      return {
        ...state,
        needsAttention: {
          ...state.needsAttention,
          data: action.result.data,
          fetchStatus: action.result.fetchStatus
        }
      };
    case ENTERPRISE_METRICS.SET_EXPORT_HISTORICAL_FETCH_STATUS:
      return {
        ...state,
        historical: {
          ...state.historical,
          exportHistoricalFetchStatus: action.fetchStatus
        }
      };
    case ENTERPRISE_METRICS.SET_EXPORT_NEED_ATTENTION_FETCH_STATUS:
      return {
        ...state,
        needsAttention: {
          ...state.needsAttention,
          exportNeedAttentionFetchStatus: action.fetchStatus
        }
      };
    case ENTERPRISE_METRICS.SET_EXPORT_PERFORMANCE_METRICS_FETCH_STATUS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          exportEnterprisePerformanceMetricsFetchStatus: action.fetchStatus
        }
      };
    default:
      return state;
  }
};
//#endregion

//#region Sagas
export function* getEnterprisePerformanceMetricsSaga() {
  yield takeLatest(
    ENTERPRISE_METRICS.GET_INVETORY_METRICS,
    function* ({ entityId, filters, reconTimestampFilterFlag }) {
      try {
        if (!entityId) {
          return;
        }
        yield put(
          enterpriseMetricsActions.updatePerformanceMetrics({ data: [], fetchStatus: apiStatusConstants.IS_FETCHING })
        );

        const { excludedTaskCategories, ...otherFilters } = filters;
        const params = {
          categoryFilters: excludedTaskCategories || [],
          ...otherFilters,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0
        };

        const performanceMetrics = yield getWithToken(`/api/enterpriseMetrics/historical/${entityId}`, params);

        yield put(
          enterpriseMetricsActions.updatePerformanceMetrics({
            data: performanceMetrics,
            fetchStatus: apiStatusConstants.SUCCEEDED
          })
        );
      } catch (error) {
        yield put(
          enterpriseMetricsActions.updatePerformanceMetrics({ data: [], fetchStatus: apiStatusConstants.FAILED })
        );
        devLogger.log('error in getPerformanceMetricsSaga', error);
      }
    }
  );
}

export function* getEnterpriseHistoricalMetricsSaga() {
  yield takeLatest(
    ENTERPRISE_METRICS.GET_HISTORICAL_METRICS,
    function* ({ entityId, filters, reconTimestampFilterFlag }) {
      try {
        yield put(
          enterpriseMetricsActions.updateHistoricalMetrics({ data: [], fetchStatus: apiStatusConstants.IS_FETCHING })
        );

        if (filters.dispositions === 'ANY') {
          // if selection is ANY, back end will use dispositions applicable to each dealer's default setup
          // this is handled by the API
          filters.dispositions = [];
        }

        const { excludedTaskCategories, ...otherFilters } = filters;

        const params = {
          categoryFilters: excludedTaskCategories || [],
          ...otherFilters,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0
        };

        const performanceMetrics = yield getWithToken(`/api/enterpriseMetrics/historical/${entityId}`, params);

        yield put(
          enterpriseMetricsActions.updateHistoricalMetrics({
            data: performanceMetrics,
            fetchStatus: apiStatusConstants.SUCCEEDED
          })
        );
      } catch (error) {
        yield put(
          enterpriseMetricsActions.updateHistoricalMetrics({ data: [], fetchStatus: apiStatusConstants.FAILED })
        );
        devLogger.log('error in getHistoricalMetricsSaga', error);
      }
    }
  );
}

export function* getEnterpriseNeedsAttentionMetricsSaga() {
  yield takeLatest(ENTERPRISE_METRICS.GET_NEEDS_ATTENTION_METRICS, function* ({ entityId, params = {} }) {
    try {
      if (!entityId) {
        return;
      }
      yield put(
        enterpriseMetricsActions.updateNeedsAttentionMetrics({ data: [], fetchStatus: apiStatusConstants.IS_FETCHING })
      );
      const performanceMetrics = yield getWithToken(`/api/enterpriseMetrics/needsAttention/${entityId}`, params);
      yield put(
        enterpriseMetricsActions.updateNeedsAttentionMetrics({
          data: performanceMetrics,
          fetchStatus: apiStatusConstants.SUCCEEDED
        })
      );
    } catch (error) {
      yield put(
        enterpriseMetricsActions.updateNeedsAttentionMetrics({ data: [], fetchStatus: apiStatusConstants.FAILED })
      );
      devLogger.log('error in getEnterpriseNeedsAttentionMetricsSaga', error);
    }
  });
}

export function* getExportNeedAttentionMetricsSaga() {
  const now = new Date();
  yield takeLatest(ENTERPRISE_METRICS.GET_EXPORT_NEED_ATTENTION_METRICS, function* ({ entityId, sort, dealershipIds }) {
    try {
      if (!entityId) {
        return;
      }

      const params = {
        sort: formatAndAddSortValue(sort),
        dealershipIds
      };
      yield put(enterpriseMetricsActions.setExportNeedAttentionFetchStatus(apiStatusConstants.IS_FETCHING));
      const exportedNeedAttentionMetrics = yield getFileWithToken(
        `/api/EnterpriseMetrics/needsAttention/${entityId}/export`,
        params
      );

      const fileName = `Need_Attention_${dateTimeWithFormat(now, 'YYYY_MM_DD')}.xlsx`;
      require('downloadjs')(
        exportedNeedAttentionMetrics.data,
        fileName,
        exportedNeedAttentionMetrics.headers['content-type']
      );
      yield put(enterpriseMetricsActions.setExportNeedAttentionFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      devLogger.error('error in getExportNeedAttentionMetricsSaga()', error);
      yield put(enterpriseMetricsActions.setExportNeedAttentionFetchStatus(apiStatusConstants.FAILED));
      yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
    }
  });
}

export function* getExportHistoricalMetricsSaga() {
  const now = new Date();
  yield takeLatest(
    ENTERPRISE_METRICS.GET_EXPORT_HISTORICAL_METRICS,
    function* ({ entityId, filters, reconTimestampFilterFlag, sort }) {
      try {
        if (!entityId) {
          return;
        }
        yield put(enterpriseMetricsActions.setExportHistoricalFetchStatus(apiStatusConstants.IS_FETCHING));
        const { endTime, startTime, excludedTaskCategories } = filters;
        const params = {
          dispositions: filters.dispositions === 'ANY' ? [] : filters.dispositions,
          endTime,
          startTime,
          clientTimezoneOffset: reconTimestampFilterFlag ? now.getTimezoneOffset() : 0,
          categoryFilters: excludedTaskCategories || [],
          sort: formatAndAddSortValue(sort),
          dealershipIds: filters.dealershipIds
        };
        const exportedHistoricalMetrics = yield getFileWithToken(
          `/api/EnterpriseMetrics/historical/${entityId}/export`,
          params
        );
        const fileName = `Historical_Performance_${dateTimeWithFormat(now, 'YYYY_MM_DD')}.xlsx`;
        require('downloadjs')(
          exportedHistoricalMetrics.data,
          fileName,
          exportedHistoricalMetrics.headers['content-type']
        );
        yield put(enterpriseMetricsActions.setExportHistoricalFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        devLogger.error('error in getExportHistoricalMetricsSaga()', error);
        yield put(enterpriseMetricsActions.setExportHistoricalFetchStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
      }
    }
  );
}

export function* getExportEnterprisePerformanceMetrics() {
  const now = new Date();
  yield takeLatest(
    ENTERPRISE_METRICS.GET_EXPORT_PERFORMANCE_METRICS,
    function* ({ entityId, reconTimestampFilterFlag, filters, sort }) {
      try {
        if (!entityId) {
          return;
        }
        yield put(
          enterpriseMetricsActions.setExportEnterprisePerformanceMetricsFetchStatus(apiStatusConstants.IS_FETCHING)
        );
        const { excludedTaskCategories, ...otherFilters } = filters;

        const params = {
          categoryFilters: excludedTaskCategories || [],
          ...otherFilters,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0,
          sort: formatAndAddSortValue(sort),
          dealershipIds: filters.dealershipIds
        };

        const exportedHistoricalMetrics = yield getFileWithToken(
          `/api/EnterpriseMetrics/inventorySnapshot/${entityId}/export`,
          params
        );
        const fileName = `Inventory_Snapshot__${dateTimeWithFormat(now, 'YYYY_MM_DD')}.xlsx`;
        require('downloadjs')(
          exportedHistoricalMetrics.data,
          fileName,
          exportedHistoricalMetrics.headers['content-type']
        );
        yield put(
          enterpriseMetricsActions.setExportEnterprisePerformanceMetricsFetchStatus(apiStatusConstants.SUCCEEDED)
        );
      } catch (error) {
        devLogger.error('error in getExportEnterprisePerformanceMetrics()', error);
        yield put(enterpriseMetricsActions.setExportEnterprisePerformanceMetricsFetchStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
      }
    }
  );
}

export const formatAndAddSortValue = (sort) => {
  if (sort.order) {
    sort.order = sort.order === 'ascend' ? 'ASC' : 'DESC';
  }
  return sort;
};
//#endregion
