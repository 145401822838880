import { v4 as uuidv4 } from 'uuid';
import * as arrayUtils from 'utils/arrayUtils';
import { takeLatest, takeEvery, put, all, select, take, race } from 'redux-saga/effects';
import { executeWithMinimumTime } from 'store/helperFunctions/sagaHelpers';
import { createSelector } from 'reselect';
import { messagesActions } from 'store/messagesStore';
import { CURRENT_DEALER } from 'store/dealersStore';
import { getWithToken, postWithToken, putWithToken, deleteWithToken, postFileWithToken, getFileWithToken } from 'api';
import { apiStatusConstants, communicationContexts, EXPORT_FAILURE_MESSAGE } from 'app-constants';
import { createRequestTypes, makeActionCreator, convertDataToSend, convertDataToDisplay } from 'utils';
import { vdpActions } from './vdpStore';
import { taskTypesActions } from 'store/taskTypesStore';
import { taskCategoriesActions } from 'store/taskCategoriesStore';
import { usersActions, USERS } from 'store/usersStore';
import { dateTimeWithFormat } from 'utils/dateTimeUtils';

//#region Actions
export const VEHICLES = createRequestTypes('VEHICLES', [
  'GET_EXPORT_DATA',
  'GET_DATA',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'SET_PAGER',
  'SET_POPOUT_WIDTH',
  'SET_FILTERS',
  'SET_SORT',
  'GET_VEHICLE_LOCATIONS',
  'SET_VEHICLE_LOCATIONS',
  'GET_VEHICLE_BY_ID',
  'SET_VEHICLE_BY_ID',
  'GET_IN_PROGRESS_COUNT_BY_DISPOSITION',
  'SET_IN_PROGRESS_COUNT_BY_DISPOSITION',
  'SET_ARCHIVE_STATUS',
  'SET_EXCLUDE_STATUS',
  // 'SET_COMMENTS_FETCH_STATUS',
  'SET_DOCUMENTS_ACTION_STATUS',
  'GET_COMMENTS_FOR_VEHICLE_ENTITY',
  'SET_COMMENTS',
  'ADD_COMMENT_FROM_VEHICLES_STORE',
  'ADD_COMMENT',
  'UPDATE_COMMENT_FROM_VEHICLES_STORE',
  'UPDATE_COMMENT',
  'DELETE_COMMENT_FROM_VEHICLES_STORE',
  'DELETE_COMMENT',
  'SET_DEALER_SIZE_IS_SET',
  //obsolete: Action GET_DOCUMENTS is no longer valid. Instead, use the GET_ALL_DOCUMENTS action.
  'GET_DOCUMENTS',
  'SET_DOCUMENTS',
  'SET_DOCUMENTS_FETCH_STATUS',
  'DOWNLOAD_DOCUMENT',
  'UPLOAD_DOCUMENT',
  'DELETE_DOCUMENT_FROM_VEHICLES_STORE',
  'DELETE_DOCUMENT',
  'ADD_DOCUMENTS',
  'SET_SHOW_VEHICLE_NOTE',
  'SET_SHOW_DISCARD_VEHICLE_NOTE_BUTTON',
  'GET_PHOTOS_FOR_VEHICLE_ENTITY',
  'SET_PHOTOS',
  'SET_PHOTOS_FETCH_STATUS',
  'SET_SHOW_LOADING_SEND_COMMENT',
  'SET_INDEX_COMMENT_UPDATE',
  'SET_FOCUS_TO_COMMUNICATION_UPDATE',
  'SET_LOCATION_ACTION',
  'ADD_COMMENTS_BY_VEHICLE',
  'UPDATE_COMMENTS_BY_VEHICLE',
  'DELETE_COMMENTS_BY_VEHICLE',
  'GET_DEPENDENCY_DATA',
  'GET_VEHICLE_PROFIT_TIME',
  'SET_VEHICLE_PROFIT_TIME',
  'SET_VEHICLE_PROFIT_TIME_FETCH_STATUS',
  'SET_VEHICLES_EXPORT_FETCH_STATUS'
]);
export const vehiclesActions = {
  getData: makeActionCreator(
    VEHICLES.GET_DATA,
    'dealerId',
    'pager',
    'filters',
    'sort',
    'include',
    'minimumExecutionTime',
    'reconTimestampFilterFlag'
  ), // no reducer, triggers getVehiclesSaga
  getExportData: makeActionCreator(
    VEHICLES.GET_EXPORT_DATA,
    'dealerId',
    'filters',
    'sort',
    'include',
    'minimumExecutionTime',
    'reconTimestampFilterFlag'
  ),
  setData: makeActionCreator(VEHICLES.SET_DATA, 'data'),
  setFetchStatus: makeActionCreator(VEHICLES.SET_FETCH_STATUS, 'fetchStatus'),
  setPager: makeActionCreator(VEHICLES.SET_PAGER, 'pager'),
  setPopoutWidth: makeActionCreator(VEHICLES.SET_POPOUT_WIDTH, 'width'),
  setFilters: makeActionCreator(VEHICLES.SET_FILTERS, 'filters'),
  setSort: makeActionCreator(VEHICLES.SET_SORT, 'sort'),
  getVehicleLocations: makeActionCreator(VEHICLES.GET_VEHICLE_LOCATIONS, 'id'), // no reducer, triggers getVehicleLocationsSaga
  setVehicleLocations: makeActionCreator(VEHICLES.SET_VEHICLE_LOCATIONS, 'id', 'data'),
  getVehicleById: makeActionCreator(
    VEHICLES.GET_VEHICLE_BY_ID,
    'id',
    'clearData',
    'setLoading',
    'includeFullComments',
    'commentLimit',
    'taskLimit'
  ), // this is called after a new recon plan is started, to get the updated recon_status change on the vehicle (NEW -> IN_PROGRESS)
  setVehicleById: makeActionCreator(VEHICLES.SET_VEHICLE_BY_ID, 'id', 'data'),
  getInProgressCountByDisposition: makeActionCreator(VEHICLES.GET_IN_PROGRESS_COUNT_BY_DISPOSITION, 'dealerId'),
  setInProgressCountByDisposition: makeActionCreator(VEHICLES.SET_IN_PROGRESS_COUNT_BY_DISPOSITION, 'data'),
  setVehicleArchiveStatus: makeActionCreator(VEHICLES.SET_ARCHIVE_STATUS, 'vehicleId', 'archive'),
  setVehicleExcludeStatus: makeActionCreator(VEHICLES.SET_EXCLUDE_STATUS, 'vehicleId', 'exclude'),
  // setCommentsFetchStatus: makeActionCreator(VEHICLES.SET_COMMENTS_FETCH_STATUS, 'communicationsFetchStatus'),
  getCommentsForVehicleEntity: makeActionCreator(VEHICLES.GET_COMMENTS_FOR_VEHICLE_ENTITY, 'vehicleId'),
  setComments: makeActionCreator(VEHICLES.SET_COMMENTS, 'vehicleId', 'comments'),
  addCommentsByVehicle: makeActionCreator(VEHICLES.ADD_COMMENTS_BY_VEHICLE, 'vehicleId', 'listComments'),
  updateCommentsByVehicle: makeActionCreator(VEHICLES.UPDATE_COMMENTS_BY_VEHICLE, 'vehicleId', 'listComments'),
  deleteCommentsByVehicle: makeActionCreator(VEHICLES.DELETE_COMMENTS_BY_VEHICLE, 'vehicleId', 'listComments'),
  addCommentFromVehiclesStore: makeActionCreator(
    VEHICLES.ADD_COMMENT_FROM_VEHICLES_STORE,
    'vehicleId',
    'comment',
    'files',
    'loadingMessage',
    'successMessage',
    'errorMessage',
    'locationAction'
  ), // no reducer, triggers addCommentFromVehiclesStoreSaga
  addComment: makeActionCreator(VEHICLES.ADD_COMMENT, 'vehicleId', 'comment', 'files'),
  addCommentImages: makeActionCreator(VEHICLES.ADD_COMMENT_IMAGES, 'commentId', 'file'),
  updateCommentFromVehiclesStore: makeActionCreator(
    VEHICLES.UPDATE_COMMENT_FROM_VEHICLES_STORE,
    'vehicleId',
    'comment',
    'index',
    'locationAction',
    'successMessage'
  ), // no reducer, triggers updateCommentSaga
  updateComment: makeActionCreator(VEHICLES.UPDATE_COMMENT, 'vehicleId', 'comment'),
  deleteCommentFromVehiclesStore: makeActionCreator(
    VEHICLES.DELETE_COMMENT_FROM_VEHICLES_STORE,
    'vehicleId',
    'commentId',
    'successMessage',
    'loadingMessage'
  ), // no reducer, triggers deleteCommentSaga
  deleteComment: makeActionCreator(VEHICLES.DELETE_COMMENT, 'vehicleId', 'commentId'),
  //obsolete: Action GET_DOCUMENTS is no longer valid. Instead, use the GET_ALL_DOCUMENTS action.
  getDocuments: makeActionCreator(VEHICLES.GET_DOCUMENTS, 'vehicleId'),
  setDocuments: makeActionCreator(VEHICLES.SET_DOCUMENTS, 'vehicleId', 'documents'),
  setDocumentsFetchStatus: makeActionCreator(VEHICLES.SET_DOCUMENTS_FETCH_STATUS, 'vehicleId', 'fetchStatus'),
  setDocumentsActionStatus: makeActionCreator(
    VEHICLES.SET_DOCUMENTS_ACTION_STATUS,
    'vehicleId',
    'action',
    'actionStatus'
  ),
  deleteDocumentFromVehiclesStore: makeActionCreator(
    VEHICLES.DELETE_DOCUMENT_FROM_VEHICLES_STORE,
    'vehicleId',
    'documentId'
  ), // no reducer, triggers deleteDocumentSaga
  deleteDocument: makeActionCreator(VEHICLES.DELETE_DOCUMENT, 'vehicleId', 'documentId'),
  downloadDocument: makeActionCreator(VEHICLES.DOWNLOAD_DOCUMENT, 'vehicleId', 'documentId', 'documentName'),
  uploadDocument: makeActionCreator(
    VEHICLES.UPLOAD_DOCUMENT,
    'vehicleId',
    'fileList',
    'stockNumber',
    'message',
    'hasLoadingToast'
  ),
  addDocuments: makeActionCreator(VEHICLES.ADD_DOCUMENTS, 'vehicleId', 'documents'),
  setDealerSizeIsSet: makeActionCreator(VEHICLES.SET_DEALER_SIZE_IS_SET, 'isSet'),
  setShowVehicleNote: makeActionCreator(VEHICLES.SET_SHOW_VEHICLE_NOTE, 'isShowVHInput'),
  setShowDiscardVHButton: makeActionCreator(VEHICLES.SET_SHOW_DISCARD_VEHICLE_NOTE_BUTTON, 'isShowVHButton'),
  getPhotosForVehicleEntity: makeActionCreator(VEHICLES.GET_PHOTOS_FOR_VEHICLE_ENTITY, 'vehicleId', 'dealerId'),
  setPhotos: makeActionCreator(VEHICLES.SET_PHOTOS, 'vehicleId', 'images'),
  setPhotosFetchStatus: makeActionCreator(VEHICLES.SET_PHOTOS_FETCH_STATUS, 'vehicleId', 'fetchStatus'),
  setShowLoadingSendComment: makeActionCreator(
    VEHICLES.SET_SHOW_LOADING_SEND_COMMENT,
    'isShowLoadingSendCommunication'
  ),
  setIndexCommunication: makeActionCreator(VEHICLES.SET_INDEX_COMMENT_UPDATE, 'index'),
  setFocusToCommunication: makeActionCreator(VEHICLES.SET_FOCUS_TO_COMMUNICATION_UPDATE, 'id'),
  setLocationAction: makeActionCreator(VEHICLES.SET_LOCATION_ACTION, 'locationAction'),
  getDependencyData: makeActionCreator(VEHICLES.GET_DEPENDENCY_DATA, 'dealerId'),
  getVehicleProfitTime: makeActionCreator(VEHICLES.GET_VEHICLE_PROFIT_TIME, 'vehicleId', 'dealerId'),
  setVehicleProfitTime: makeActionCreator(VEHICLES.SET_VEHICLE_PROFIT_TIME, 'profitTimeData'),
  setVehicleProfitTimeFetchStatus: makeActionCreator(VEHICLES.SET_VEHICLE_PROFIT_TIME_FETCH_STATUS, 'fetchStatus'),
  setVehiclesExportFetchStatus: makeActionCreator(VEHICLES.SET_VEHICLES_EXPORT_FETCH_STATUS, 'fetchStatus')
};
//#endregion

//#region Reducer
const initialState = {
  data: null,
  fetchStatus: apiStatusConstants.IS_FETCHING,
  popoutWidth: 392,
  pager: {
    start: 1,
    limit: window.localStorage.getItem('vehiclePageSize') || 10
  },
  locations: {}, // Dictionary of locations: key = vehicle.id, value = [ location ]
  inProgressCountByDisposition: {},
  dealerSizeIsSet: false,
  isShowVehicleNote: false,
  isShowDiscardVHButton: false,
  isShowLoadingSendCommunication: false,
  indexCommunicationWhenUpdate: -1,
  focusToCommunicationId: null,
  locationAction: null,
  communicationsFetchStatus: apiStatusConstants.IS_FETCHING,
  vehicleProfitTime: {},
  vehicleProfitTimeFetchStatus: apiStatusConstants.PENDING,
  vehiclesExportFetchStatus: apiStatusConstants.PENDING
};
export const vehiclesReducer = (state = initialState, action) => {
  let newData;
  let vehicle;
  switch (action.type) {
    case VEHICLES.SET_DATA:
      return {
        ...state,
        data: { ...action.data }
      };
    case VEHICLES.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case VEHICLES.SET_VEHICLES_EXPORT_FETCH_STATUS:
      return {
        ...state,
        vehiclesExportFetchStatus: action.fetchStatus
      };
    case VEHICLES.SET_PAGER:
      return {
        ...state,
        pager: { ...action.pager }
      };
    case VEHICLES.SET_POPOUT_WIDTH:
      return {
        ...state,
        popoutWidth: state.popoutWidth + action.width // the action.width payload is just the change in the width
      };
    case VEHICLES.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.filters }
      };
    case VEHICLES.SET_SORT:
      return {
        ...state,
        sort: action.sort
      };
    case VEHICLES.SET_VEHICLE_LOCATIONS:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.id]: {
            fetchStatus: action.data.fetchStatus,
            locations: [...action.data.locations]
          }
        }
      };
    case VEHICLES.SET_VEHICLE_BY_ID:
      let newItems;
      const index = state.data.items.findIndex((v) => v.id === action.id);
      if (index !== -1) {
        newItems = arrayUtils.updateObjectInArray(state.data.items, {
          index,
          item: action.data
        });
      } else {
        newItems = arrayUtils.insertItem(state.data.items, {
          index: state.data.items.length,
          item: action.data
        });
      }
      return {
        ...state,
        data: {
          ...state.data,
          items: newItems
        }
      };
    case VEHICLES.SET_IN_PROGRESS_COUNT_BY_DISPOSITION:
      return {
        ...state,
        inProgressCountByDisposition: { ...action.data }
      };
    case VEHICLES.ADD_COMMENTS_BY_VEHICLE:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map((vehicle, index) => {
            if (vehicle.id === action.vehicleId) {
              return {
                ...vehicle,
                comments: {
                  ...state.data.items[index].comments,
                  items: action.listComments,
                  count: state.data.items[index].comments.count + 1
                }
              };
            }
            return vehicle;
          })
        }
      };
    case VEHICLES.UPDATE_COMMENTS_BY_VEHICLE:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map((vehicle, index) => {
            if (vehicle.id === action.vehicleId) {
              return {
                ...vehicle,
                comments: {
                  ...state.data.items[index].comments,
                  items: action.listComments
                }
              };
            }
            return vehicle;
          })
        }
      };
    case VEHICLES.DELETE_COMMENTS_BY_VEHICLE:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map((vehicle, index) => {
            if (vehicle.id === action.vehicleId) {
              return {
                ...vehicle,
                comments: {
                  ...state.data.items[index].comments,
                  items: action.listComments,
                  count: state.data.items[index].comments.count - 1
                }
              };
            }
            return vehicle;
          })
        }
      };
    case VEHICLES.SET_DOCUMENTS:
      newData = arrayUtils.stripReferences(state.data);
      vehicle = newData.items.find((x) => x.id === action.vehicleId);
      vehicle.documents = action.documents;
      vehicle.documentsFetchStatus = apiStatusConstants.SUCCEEDED;
      return {
        ...state,
        data: newData
      };
    case VEHICLES.SET_DOCUMENTS_FETCH_STATUS:
      newData = arrayUtils.stripReferences(state.data);
      newData.items.find((x) => x.id === action.vehicleId).documentsFetchStatus = action.fetchStatus;
      return {
        ...state,
        data: newData
      };
    case VEHICLES.DELETE_DOCUMENT:
      newData = arrayUtils.stripReferences(state.data);
      vehicle = newData.items.find((x) => x.id === action.vehicleId);
      vehicle.documents = vehicle.documents.filter((x) => x.id !== action.documentId);
      return {
        ...state,
        data: newData
      };
    case VEHICLES.SET_DEALER_SIZE_IS_SET:
      return {
        ...state,
        dealerSizeIsSet: action.isSet
      };
    case VEHICLES.ADD_DOCUMENTS:
      newData = arrayUtils.stripReferences(state.data);
      vehicle = newData.items.find((x) => x.id === action.vehicleId);
      vehicle.documents = vehicle.documents ? [...vehicle.documents, ...action.documents] : action.documents;
      return {
        ...state,
        data: newData
      };
    case VEHICLES.SET_DOCUMENTS_ACTION_STATUS:
      newData = arrayUtils.stripReferences(state.data);
      switch (action.action) {
        case 'upload':
          newData.items.find((x) => x.id === action.vehicleId).documentsUploadStatus = action.actionStatus;
          break;
        case 'delete':
          newData.items.find((x) => x.id === action.vehicleId).documentsDeleteStatus = action.actionStatus;
          break;
        default:
          newData.items.find((x) => x.id === action.vehicleId).documentsUploadStatus = action.actionStatus;
      }
      return {
        ...state,
        data: newData
      };
    case VEHICLES.SET_SHOW_VEHICLE_NOTE:
      return {
        ...state,
        isShowVehicleNote: action.isShowVHInput
      };
    case VEHICLES.SET_SHOW_DISCARD_VEHICLE_NOTE_BUTTON:
      return {
        ...state,
        isShowDiscardVHButton: action.isShowVHButton
      };
    case VEHICLES.SET_PHOTOS:
      newData = arrayUtils.stripReferences(state.data);
      vehicle = newData.items.find((x) => x.id === action.vehicleId);
      vehicle.images = action.images?.items;
      vehicle.imagesFetchStatus = apiStatusConstants.SUCCEEDED;
      return {
        ...state,
        data: newData
      };
    case VEHICLES.SET_PHOTOS_FETCH_STATUS:
      newData = arrayUtils.stripReferences(state.data);
      newData.items.find((x) => x.id === action.vehicleId).imagesFetchStatus = action.fetchStatus;
      return {
        ...state,
        data: newData
      };
    case VEHICLES.SET_SHOW_LOADING_SEND_COMMENT:
      return {
        ...state,
        isShowLoadingSendCommunication: action.isShowLoadingSendCommunication
      };
    case VEHICLES.SET_INDEX_COMMENT_UPDATE:
      return {
        ...state,
        indexCommunicationWhenUpdate: action.index
      };
    case VEHICLES.SET_FOCUS_TO_COMMUNICATION_UPDATE:
      return {
        ...state,
        focusToCommunicationId: action.id
      };
    case VEHICLES.SET_LOCATION_ACTION:
      return {
        ...state,
        locationAction: action.locationAction
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    case VEHICLES.SET_VEHICLE_PROFIT_TIME:
      if (action.profitTimeData) {
        return {
          ...state,
          vehicleProfitTime: {
            ...action.profitTimeData
          }
        };
      }
      return {
        ...state,
        vehicleProfitTime: {}
      };
    case VEHICLES.SET_VEHICLE_PROFIT_TIME_FETCH_STATUS:
      return {
        ...state,
        vehicleProfitTimeFetchStatus: action.fetchStatus
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const vehiclesSelector = createSelector(
  (state) => state.vehicles,
  (vehicles) => vehicles.data
);
//#endregion

//#region Sagas
export function* exportVehiclesSaga() {
  yield takeLatest(
    VEHICLES.GET_EXPORT_DATA,
    function* ({ dealerId, filters, sort, include, minimumExecutionTime, reconTimestampFilterFlag }) {
      try {
        yield put(vehiclesActions.setVehiclesExportFetchStatus(apiStatusConstants.IS_FETCHING));
        const params = {
          ...filters,
          dealerId,
          start: 1,
          sort,
          include,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0
        };

        const exportedVehicleData = yield executeWithMinimumTime(function* () {
          return yield getWithToken(`/api/Vehicles/Export`, params);
        }, minimumExecutionTime);

        const now = new Date();
        require('downloadjs')(
          exportedVehicleData,
          `Export_Vehicle_Data_${dateTimeWithFormat(now, 'YYYY_MM_DD')}.csv`,
          'text/csv'
        );
        yield put(vehiclesActions.setVehiclesExportFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        devLogger.log('error in exportVehiclesSaga', error);
        yield put(vehiclesActions.setVehiclesExportFetchStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
      }
    }
  );
}

export function* getVehiclesSaga() {
  yield takeLatest(
    VEHICLES.GET_DATA,
    function* ({ dealerId, pager, filters, sort, include, minimumExecutionTime, csvExport, reconTimestampFilterFlag }) {
      try {
        yield put(vehiclesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
        const params = {
          ...filters,
          dealerId,
          start: pager.start,
          limit: pager.limit,
          sort,
          include,
          csvExport: csvExport,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0
        };

        const vehiclesData = yield executeWithMinimumTime(function* () {
          return yield getWithToken(`/api/Vehicles`, params);
        }, minimumExecutionTime);
        //-Convert user tagging data if existed to display correctly
        vehiclesData.items.forEach((v) => {
          if (v.comments && v.comments.items) {
            v.comments.items.forEach((c) => {
              c.contentData = convertDataToDisplay(c.contentData);
            });
          }
        });
        if (csvExport === undefined) {
          const dealerSizeIsSet = yield select((state) => state.vehicles.dealerSizeIsSet);
          if (!dealerSizeIsSet) {
            yield put(vehiclesActions.setDealerSizeIsSet(true));
          }

          yield put(vehiclesActions.setData(vehiclesData));
          yield put(vehiclesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
        }
      } catch (error) {
        // TODO: handle error
        yield put(vehiclesActions.setFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getVehiclesSaga', error);
      }
    }
  );
}

export function* getVehicleLocationsSaga() {
  yield takeLatest(VEHICLES.GET_VEHICLE_LOCATIONS, function* ({ id }) {
    try {
      yield put(
        vehiclesActions.setVehicleLocations(id, {
          // first set fetchStatus to IS_FETCHING
          fetchStatus: apiStatusConstants.IS_FETCHING,
          locations: []
        })
      );
      const vehicleData = yield getWithToken(`/api/Vehicles/id/${id}`, { include: 'Location' });
      yield put(
        vehiclesActions.setVehicleLocations(id, {
          fetchStatus: apiStatusConstants.SUCCEEDED,
          locations: vehicleData.locations
        })
      );
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in getVehicleLocationsSaga', error);
    }
  });
}

// Loads a single vehicle by id. optionally clears current data and manipulates fetchstatus
// CommentLimit and taskLimit are optional parameters that can be passed to the API to choose how many comments or tasks are returned when include has Tasks and Comments
export function* getVehicleByIdSaga() {
  yield takeLatest(
    VEHICLES.GET_VEHICLE_BY_ID,
    function* ({
      id,
      clearData = false,
      setLoading = false,
      includeFullComments = false,
      commentLimit = 2,
      taskLimit = 100
    }) {
      try {
        if (setLoading) {
          yield put(vehiclesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
        }

        const data = yield getWithToken(`/api/Vehicles/id/${id}`, {
          include: ['Tasks', 'Comments'],
          commentLimit: commentLimit,
          taskLimit: taskLimit
        });

        if (clearData) {
          yield put(vehiclesActions.setData({ count: 1, items: [data] }));
        } else {
          yield put(vehiclesActions.setVehicleById(id, data));
        }

        if (includeFullComments) {
          yield put(vdpActions.getCommentsForVehicle(id));
        }

        if (setLoading) {
          yield put(vehiclesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
        }
      } catch (error) {
        // TODO: handle error
        devLogger.log('error in getVehicleByIdSaga', error);
        if (setLoading) {
          yield put(vehiclesActions.setFetchStatus(apiStatusConstants.FAILED));
        }
      }
    }
  );
}

export function* getInProgressCountByDispositionSaga() {
  yield takeLatest(VEHICLES.GET_IN_PROGRESS_COUNT_BY_DISPOSITION, function* ({ dealerId }) {
    try {
      yield put(vehiclesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));

      const [retail, wholesale, subprime] = yield all([
        getWithToken(`/api/Vehicles`, {
          dealerId,
          start: 1,
          limit: 1,
          reconStatus: 'IN_PROGRESS',
          disposition: ['RETAIL']
        }),
        getWithToken(`/api/Vehicles`, {
          dealerId,
          start: 1,
          limit: 1,
          reconStatus: 'IN_PROGRESS',
          disposition: ['WHOLESALE']
        }),
        getWithToken(`/api/Vehicles`, {
          dealerId,
          start: 1,
          limit: 1,
          reconStatus: 'IN_PROGRESS',
          disposition: ['SUBPRIME']
        })
      ]);

      yield put(
        vehiclesActions.setInProgressCountByDisposition({
          RETAIL: retail.count,
          WHOLESALE: wholesale.count,
          SUBPRIME: subprime.count
        })
      );

      yield put(vehiclesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(vehiclesActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getInProgressCountByDispositionSaga', error);
    }
  });
}

export function* archiveVehicle() {
  yield takeLatest(VEHICLES.SET_ARCHIVE_STATUS, function* ({ vehicleId, archive }) {
    const messageId = uuidv4();

    const loadingMessage = archive ? 'Archiving vehicle...' : 'Unarchiving vehicle...';
    const successMessage = archive ? 'Vehicle archived!' : 'Vehicle unarchived!';
    const errorMessage = archive
      ? 'An error occurred while archiving the vehicle'
      : 'An error occurred while unarchiving the vehicle';

    try {
      // archive

      // duration of 0 will show the message indefinitely
      yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0 }));

      const requestBody = { archive: archive };
      const vehicleResult = yield postWithToken(`/api/Vehicles/id/${vehicleId}/archive`, requestBody);

      // get the current set of vehicles in the store
      const data = { ...(yield select(vehiclesSelector)) };
      // reset vehicle in store with new value from api return value
      const itemIndex = data.items.findIndex((x) => x.id === vehicleId);
      data.items[itemIndex] = vehicleResult;
      yield put(vehiclesActions.setData(data));

      // passing in the same key and duration will make the message go away
      yield put(messagesActions.notify('success', successMessage, { key: messageId, duration: 2 }));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in archiveVehicle', error);

      // turn off loading before the error
      yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0.1 }));
      yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
    }
  });
}

export function* excludeVehicle() {
  yield takeLatest(VEHICLES.SET_EXCLUDE_STATUS, function* ({ vehicleId, exclude }) {
    const messageId = uuidv4();

    const loadingMessage = exclude ? 'Excluding vehicle...' : 'Unexcluding vehicle...';
    const successMessage = exclude ? 'Vehicle excluded!' : 'Vehicle unexcluded!';
    const errorMessage = exclude
      ? 'An error occurred while excluding the vehicle'
      : 'An error occurred while unexcluding the vehicle';

    try {
      // exclude

      // duration of 0 will show the message indefinitely
      yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0 }));

      const requestBody = { exclude: exclude };
      const vehicleResult = yield postWithToken(`/api/Vehicles/id/${vehicleId}/exclude`, requestBody);

      // get the current set of vehicles in the store
      const data = { ...(yield select(vehiclesSelector)) };
      // reset vehicle in store with new value from api return value
      const itemIndex = data.items.findIndex((x) => x.id === vehicleId);
      data.items[itemIndex] = vehicleResult;
      yield put(vehiclesActions.setData(data));

      // passing in the same key and duration will make the message go away
      yield put(messagesActions.notify('success', successMessage, { key: messageId, duration: 2 }));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in excludeVehicle', error);

      // turn off loading before the error
      yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0.1 }));
      yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
    }
  });
}

export function* addCommentFromVehiclesStoreSaga() {
  yield takeEvery(
    VEHICLES.ADD_COMMENT_FROM_VEHICLES_STORE,
    function* ({
      vehicleId,
      comment,
      files = [],
      loadingMessage = '',
      successMessage = '',
      errorMessage = '',
      locationAction = ''
    }) {
      try {
        yield put(vehiclesActions.setShowLoadingSendComment(true));
        yield put(vehiclesActions.setLocationAction(locationAction));

        const tempComment = !!comment.contentData
          ? {
              ...comment,
              contentData: convertDataToSend({
                ...comment.contentData,
                blocks: arrayUtils.trimLineBreakBlocks(comment.contentData.blocks)
              })
            }
          : { ...comment };

        const addedComment = yield postWithToken(`/api/Comments`, tempComment);
        if (files.length > 0) {
          let images = [];
          for (let file of files) {
            const addedCommentImage = yield postFileWithToken(`/api/CommentImages/comment/${addedComment.id}`, file);
            images = [...images, addedCommentImage];
          }
          addedComment.images = [...images];
        }
        addedComment.contentData = convertDataToDisplay(addedComment.contentData);
        yield put(vdpActions.addComments(addedComment));
        const listComments = yield select((state) => state.vdp.comments);
        yield put(
          vehiclesActions.addCommentsByVehicle(
            vehicleId,
            listComments.items.slice(0, 2).sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))
          )
        );

        yield put(
          messagesActions.notify('success', successMessage, {
            duration: 2,
            location: communicationContexts.COMMUNICATION_BUTTON_NOTE
          })
        );
        yield put(vehiclesActions.setShowLoadingSendComment(false));
        yield put(vehiclesActions.setShowVehicleNote(false));
        yield put(vehiclesActions.setLocationAction(null));
        yield put(vehiclesActions.setFocusToCommunication(null));
      } catch (error) {
        // TODO: handle error
        yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
        yield put(vehiclesActions.setShowLoadingSendComment(false));
      }
    }
  );
}

export function* updateCommentFromVehiclesStoreSaga() {
  yield takeEvery(
    VEHICLES.UPDATE_COMMENT_FROM_VEHICLES_STORE,
    function* ({ vehicleId, comment, index = 0, locationAction, successMessage = '' }) {
      try {
        yield put(vehiclesActions.setShowLoadingSendComment(true));
        yield put(vehiclesActions.setIndexCommunication(index));
        yield put(vehiclesActions.setFocusToCommunication(comment.id));
        yield put(vehiclesActions.setLocationAction(locationAction));

        const tempComment = !!comment.contentData
          ? {
              ...comment,
              contentData: convertDataToSend({
                ...comment.contentData,
                blocks: arrayUtils.trimLineBreakBlocks(comment.contentData.blocks)
              })
            }
          : { ...comment };

        let updatedComment = yield putWithToken(`/api/Comments/id/${comment.id}`, tempComment);
        updatedComment.contentData = convertDataToDisplay(updatedComment.contentData);
        yield put(vdpActions.updateComment(updatedComment));
        const listComments = yield select((state) => state.vdp.comments);
        yield put(
          vehiclesActions.updateCommentsByVehicle(
            vehicleId,
            listComments.items.slice(0, 2).sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))
          )
        );
        yield put(vehiclesActions.setShowLoadingSendComment(false));
        yield put(vehiclesActions.setFocusToCommunication(null));
        yield put(vehiclesActions.setIndexCommunication(-1));
        yield put(vehiclesActions.setLocationAction(null));
        yield put(
          messagesActions.notify('success', successMessage, {
            duration: 2,
            location: communicationContexts.COMMUNICATION_BUTTON_NOTE
          })
        );
      } catch (error) {
        // TODO: handle error
        yield put(messagesActions.notify('error', 'An error occurred while updating comment', { duration: 3.5 }));
        yield put(vehiclesActions.setShowLoadingSendComment(false));
        yield put(vehiclesActions.setFocusToCommunication(null));
      }
    }
  );
}

export function* uploadDocumentsFromVehiclesStoreSaga() {
  yield takeLatest(
    VEHICLES.UPLOAD_DOCUMENT,
    function* ({ vehicleId, fileList, stockNumber, message, hasLoadingToast = true }) {
      let uploadedFiles = [];
      const messageId = hasLoadingToast ? uuidv4() : null;
      if (hasLoadingToast) {
        const loadingMessage = 'Uploading document...';
        // duration of 0 will show the message indefinitely
        yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0 }));
      }
      yield put(vehiclesActions.setDocumentsActionStatus(vehicleId, 'upload', apiStatusConstants.PENDING));
      for (const file of fileList) {
        const data = new FormData();
        data.append('file', file);
        try {
          const resDoc = yield postWithToken(`/api/Vehicles/${vehicleId}/documents`, data);
          uploadedFiles = [...uploadedFiles, resDoc];
        } catch (error) {
          Error(error);
        }
      }
      if (uploadedFiles.length > 0) {
        yield put(vehiclesActions.addDocuments(vehicleId, uploadedFiles));
        const mes = yield message(uploadedFiles.length, stockNumber);
        // passing in the same key and duration will make the message go away
        yield put(messagesActions.notify('success', mes, { key: messageId, duration: 3.5 }));
      }
      if (uploadedFiles.length === 0) {
        yield put(
          messagesActions.notify('error', `Error with ${fileList.length - uploadedFiles.length} files ! `, {
            duration: 2
          })
        );
      }
      yield put(vehiclesActions.setDocumentsActionStatus(vehicleId, 'upload', apiStatusConstants.SUCCEEDED));
    }
  );
}

export function* deleteCommentFromVehiclesStoreSaga() {
  yield takeEvery(
    VEHICLES.DELETE_COMMENT_FROM_VEHICLES_STORE,
    function* ({ vehicleId, commentId, successMessage = '', loadingMessage = '' }) {
      const messageId = uuidv4();
      yield put(
        messagesActions.notify('loading', loadingMessage, {
          key: messageId,
          duration: 0,
          location: communicationContexts.COMMUNICATION_BUTTON_NOTE
        })
      );
      try {
        yield put(vehiclesActions.setShowLoadingSendComment(true));
        yield deleteWithToken(`/api/Comments/id/${commentId}`);
        yield put(vdpActions.deleteComment(commentId));
        const listComments = yield select((state) => state.vdp.comments);
        yield put(
          vehiclesActions.deleteCommentsByVehicle(
            vehicleId,
            listComments.items.slice(0, 2).sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))
          )
        );
        yield put(vehiclesActions.setShowLoadingSendComment(false));

        yield put(
          messagesActions.notify('success', successMessage, {
            key: messageId,
            duration: 2,
            location: communicationContexts.COMMUNICATION_BUTTON_NOTE
          })
        );
      } catch (error) {
        yield put(
          messagesActions.notify('error', 'An error occurred while deleting a communication', {
            key: messageId,
            duration: 3.5
          })
        );
      }
    }
  );
}

export function* getPhotosForVehicleEntitySaga() {
  yield takeEvery(VEHICLES.GET_PHOTOS_FOR_VEHICLE_ENTITY, function* ({ vehicleId, dealerId }) {
    try {
      yield put(vehiclesActions.setPhotosFetchStatus(vehicleId, apiStatusConstants.IS_FETCHING));
      const images = yield getWithToken(`/api/Vehicles/images`, { vehicleId, dealerId });
      yield put(vehiclesActions.setPhotos(vehicleId, images));
    } catch (error) {
      yield put(vehiclesActions.setPhotosFetchStatus(vehicleId, apiStatusConstants.FAILED));
      devLogger.log('error in getPhotosForVehicleEntitySaga', error);
    }
  });
}

export function* getDocumentByVehiclesStoreSaga() {
  yield takeLatest(VEHICLES.GET_DOCUMENTS, function* ({ vehicleId }) {
    try {
      yield put(vehiclesActions.setDocumentsFetchStatus(vehicleId, apiStatusConstants.IS_FETCHING));
      const documents = yield getWithToken(`/api/Vehicles/${vehicleId}/documents`);
      yield put(vehiclesActions.setDocuments(vehicleId, documents)); // this action also sets the communicationsFetchStatus to succeeded in the reducer
    } catch (error) {
      yield put(vehiclesActions.setDocumentsFetchStatus(vehicleId, apiStatusConstants.FAILED));
      devLogger.log('error in getDocumentByVehicleSaga', error);
    }
  });
}

export function* deleteDocumentFromVehiclesStoreSaga() {
  yield takeEvery(VEHICLES.DELETE_DOCUMENT_FROM_VEHICLES_STORE, function* ({ vehicleId, documentId }) {
    const messageId = uuidv4();
    const loadingMessage = 'Deleting document...';
    try {
      yield put(vehiclesActions.setDocumentsActionStatus(vehicleId, 'delete', apiStatusConstants.PENDING));
      // duration of 0 will show the message indefinitely
      yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0 }));

      yield deleteWithToken(`/api/Vehicles/${vehicleId}/documents/${documentId}`);
      yield put(vehiclesActions.deleteDocument(vehicleId, documentId));
      yield put(
        messagesActions.notify('success', 'Document deleted successfully !', { key: messageId, duration: 3.5 })
      );
    } catch (error) {
      yield put(
        messagesActions.notify('error', 'An error occurred while deleting document', { key: messageId, duration: 3.5 })
      );
    } finally {
      yield put(vehiclesActions.setDocumentsActionStatus(vehicleId, 'delete', apiStatusConstants.SUCCEEDED));
    }
  });
}

export function* downloadDocumentFromVehiclesStoreSaga() {
  yield takeEvery(VEHICLES.DOWNLOAD_DOCUMENT, function* ({ vehicleId, documentId, documentName }) {
    try {
      const downloadedDocument = yield getFileWithToken(`/api/Vehicles/${vehicleId}/documents/${documentId}`);
      // file name can get from Content-Disposition but it was missing from the response.
      require('downloadjs')(downloadedDocument.data, documentName, downloadedDocument.headers['content-type']);
    } catch (error) {
      yield put(messagesActions.notify('error', 'An error occurred while downloading document', { duration: 3.5 }));
    }
  });
}

export function* getDependencyDataFromVehiclesStoreSaga() {
  yield takeEvery(VEHICLES.GET_DEPENDENCY_DATA, function* ({ dealerId }) {
    try {
      yield put(usersActions.getAssigneeData(dealerId));
      // after the internal group toggle removed, use data of API assignee instead of API users and remove usersActions.getData
      yield put(usersActions.getData(dealerId, false));

      // this need to be done before tasktype
      // if any of these action fail, pass out and get tasktype to avoid blocking
      yield race([take(USERS.SET_FETCH_STATUS), all([take([USERS.SET_ASSIGNEE_DATA]), take([USERS.SET_DATA])])]);

      yield put(taskTypesActions.getData(dealerId));

      yield put(taskCategoriesActions.getDataFromTaskType(dealerId));
    } catch (error) {
      yield put(messagesActions.notify('error', 'An error occurred while get dependency data', { duration: 3.5 }));
    }
  });
}

export function* getVehicleProfitTimeSaga() {
  yield takeLatest(VEHICLES.GET_VEHICLE_PROFIT_TIME, function* ({ vehicleId, dealerId }) {
    try {
      yield put(vehiclesActions.setVehicleProfitTimeFetchStatus(apiStatusConstants.IS_FETCHING));
      const profitTimeData = yield getWithToken(`/api/ProfitTime/profittime/${vehicleId}`, { dealerId });
      yield put(vehiclesActions.setVehicleProfitTime(profitTimeData));
      yield put(vehiclesActions.setVehicleProfitTimeFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(vehiclesActions.setVehicleProfitTimeFetchStatus(vehicleId, apiStatusConstants.FAILED));
      devLogger.log('error in getVehicleProfitTimeSaga', error);
    }
  });
}

//#endregion
