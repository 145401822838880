import React from 'react';
import { Row, Col, Typography, Popover} from 'antd';
import { formatDateTime } from 'utils/dateTimeUtils';
const { Text } = Typography;

const build = {
  Build: process.env.REACT_APP_BUILD_NAME,
  Branch: process.env.REACT_APP_GIT_BRANCH,
  Hash: process.env.REACT_APP_GIT_HASH,
  Version: process.env.REACT_APP_VERSION,
  Built: formatDateTime(process.env.REACT_APP_BUILD_DATE)
}

const renderToolTip = () => {
  return Object.entries(build).map(([key, value]) => (
    !!key && !!value &&
    <Row key={key} type='flex' justify='start'>
      <Col span={7}>
        <Text strong>{key}</Text>
      </Col>
      <Col span={17}>
        <Text>{value}</Text>
      </Col>
    </Row>
  ))
}

const BuildInfo = ({children}) => {
  return (
    <Popover content={renderToolTip()} mouseEnterDelay={5} placement='bottom'>
      {children}
    </Popover>
  );
}

export default BuildInfo;