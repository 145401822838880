import React from 'react';
import { oidcSelector } from 'store/authStore';
import { useSelector } from 'react-redux';
import { userManagerNewDomain } from 'utils/userManager';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { IS_NEW_SIGNIN } from 'app-constants';
import { LoginSelection } from 'components/auth';
import { Redirect } from '@reach/router';

const Login = ({ flags }) => {
  const oidcState = useSelector(oidcSelector);

  if (!oidcState.user || oidcState.user.expired) {
    devLogger.log(`auth token missing or expired...`);
    const urlParameters = new URLSearchParams(window.location.search);
    let redirectUrl = urlParameters.get('redirect_url') || undefined;
    const entityWhenSwitchingDomain = urlParameters.get('entity') || undefined;
    if (entityWhenSwitchingDomain) {
      redirectUrl = `https://${window.location.hostname}/?entity=${entityWhenSwitchingDomain}`;
    }

    if (flags.reconVendorDisambiguationPage) {
      // query params have dealer id then expect to login as dealer user. supposed to be navigated from Vauto
      const parsedParams = redirectUrl && redirectUrl.split('?')[1];
      // dealerId and other params might be encoded in redirectUrl
      if (urlParameters.get('dealerId') || (parsedParams && new URLSearchParams(parsedParams).get('dealerId'))) {
        localStorage.setItem(IS_NEW_SIGNIN, false);
        userManagerNewDomain.signinRedirect({ state: { redirect_url: redirectUrl } });
        return null;
      }

      return <LoginSelection />;
    } else {
      localStorage.setItem(IS_NEW_SIGNIN, false);
      userManagerNewDomain.signinRedirect({ state: { redirect_url: redirectUrl } });
    }
    return null;
  }

  return (
    <Redirect
      from="login"
      to={window.location.pathname && window.location.pathname !== '/login' ? window.location.pathname : '/'}
      noThrow
    />
  );
};

export default withLDConsumer()(Login);
