import React, { useState, useEffect, useContext } from 'react';
import { OPERATION_LINE_ITEM } from 'app-constants/lineItemConstants';
import { CircularSpinner } from 'components/common';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  apiStatusConstants,
  features,
  getEmptyLineItemDescreption,
  lineItemStatusTypes,
  entityTypes
} from 'app-constants';
import { Button, Icon, Menu, Dropdown, Breadcrumb, Tooltip, Typography, Modal } from 'antd';
import { TaskForm } from 'components/layout/inventory/reconPlan';
import { EmptyLineItem } from 'components/layout/inventory/reconPlan/TaskForms/LineItemsTaskForm';
import StandaloneTask from 'components/TaskGroupsDnd/Task/StandaloneTask';
import {
  CommonStrongText,
  CommonCenteredText,
  CommonBreadcrumb,
  CommonBreadcrumbButton,
  HeaderSmall,
  BodyXSmallBold,
  CommonBreadcrumbLink,
  CommonFooterContainer,
  CommonRedButton,
  StyleReassignCompletedContainer,
  StyledConfirmationModalIcon
} from 'components/styledComponents';
import { useFeatures } from 'hooks';
import { tasksActions } from 'store/tasksStore';
import { lineItemsActions, getShowTagNeedApprovel } from 'store/lineItemsStore';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { SavingTaskOutline } from '../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PENDING, IN_PROGRESS, COMPLETED } from 'app-constants/taskStatusTypes';
import AddLineItemForm from 'components/layout/inventory/reconPlan/TaskForms/AddLineItemForm';
import AlertForLineItemError from 'components/layout/inventory/reconPlan/TaskForms/AlertForLineItemError';
import uuidv4 from 'uuid/v4';
import { DEALER_INTERNAL_GROUP, VENDOR } from 'app-constants/groupTypes';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { assigneeDataSelector } from 'store/usersStore';
import useVendorRole from 'hooks/useVendorRole';
import { useTaskReadOnlyStatus } from '../../../../../hooks/useTaskReadOnlyStatus';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { HQAndProfitTimeContext } from 'utils/contexts';

const { Title } = Typography;

const lineItemSelector = createSelector(
  (state) => state.lineItems.approved,
  (approved) => ({
    totalCostApproved:
      approved.length > 0
        ? approved
            .map((item) => (item.totalCost ? Number(item.totalCost) : 0))
            .reduce((accumulator, current) => {
              return accumulator + current;
            })
        : 0
  })
);

const Loading = () => (
  <StyledCenter>
    <CircularSpinner tip="Loading..." size="2rem" />
  </StyledCenter>
);
const Error = () => (
  <StyledCenter>
    <Title type="danger" level={4}>
      Oops, something went wrong
    </Title>
  </StyledCenter>
);

export const TaskInfo = withLDConsumer()(({ task, user, onForm, setOnForm, contentType, flags, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const userOwnsThisTask = task.assignedTo === user.id;
  const editableStatuses = [IN_PROGRESS];
  const [hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask, hasApproverRole, hasLineItemsEditRole] = useFeatures(
    features.TASKS_EDIT,
    features.TASKS_WORK_OWN,
    features.TASKS_WORK_ANY,
    features.TASKS_APPROVE_LINE_ITEMS,
    features.LINE_ITEMS_EDIT
  );
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const { isVendorAdmin, isVendorTech, isVendor } = useVendorRole(flags.reconVendorManagement);
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const checkIfVendor = isVendor && !vendorShipSelected;
  const hasWorkPermission = hasWorkAnyTask || (hasWorkOwnTasks && userOwnsThisTask);
  const isUndeclinedInprogressTask = !task.declined && editableStatuses.includes(task.status);
  const isTaskGroup = task && task.assignedToGroupId && task.assignedToGroupType === DEALER_INTERNAL_GROUP;
  const canCompleteOrDecline = hasWorkPermission && isUndeclinedInprogressTask;
  const canEditWorkAnyTask = hasEditTasks && hasWorkAnyTask;
  const [currentTaskUpdateType, setCurrentTaskUpdateType] = useState('');
  const [currentMemberData, setCurrentMemberData] = useState(null);
  const { internalGroups, vendors, vendorUsers } = useSelector(assigneeDataSelector);
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  // group leaders have the right to complete/decline a group task as if they have feature workAnyTask even if they don't
  const groupLeaderCanCompleteOrDecline =
    currentMemberData && currentMemberData.isGroupLeader && isUndeclinedInprogressTask;
  // hide button when user without hasWorkAnyTask feature edit declinable group task. The group that user is not a leader
  const isHideDeclineButtonIdGroupTask =
    !hasWorkAnyTask && isTaskGroup && currentMemberData && !currentMemberData.isGroupLeader;

  useEffect(() => {
    if (isTaskGroup) {
      const currentGroup = internalGroups && internalGroups.find((group) => group.id === task.assignedToGroupId);
      const currentMember = currentGroup?.internalGroupUsers?.find((member) => member.userId === user.id);
      setCurrentMemberData(currentMember);
    } else {
      setCurrentMemberData(null);
    }
  }, [user, internalGroups, task]);
  const getBreadcrumbLabel = () => {
    if (task.completedOn) {
      return 'Edit Completed Task';
    } else if (currentTaskUpdateType === 'complete') {
      return 'Complete Task';
    } else if (currentTaskUpdateType === 'decline') {
      return 'Decline Task';
    } else if (currentTaskUpdateType === 'assign') {
      return 'Assign Group Task';
    } else if (currentTaskUpdateType === 'assign-task') {
      return 'Assign Task';
    }
  };

  // StandaloneTask relies on the prop formProps to for its overflow menu (which in this instance, will only
  // Display the "Edit" option, if the user has access to edit a completed task (TASKS_WORK_ALL or TASKS_WORK_OWN and its their own)
  // Since The TDP and Tasks page don't use formProps like the VDP/RPB/Inventory pages do,
  // we instead just want to watch for changes in the formProps prop. For that reason, when we pass it into TaskForm,
  // we're calling it fakeFormProps. This allows the TDP and Tasks page to recognize that the user wants to edit the
  // given completed task, and can update the UI to show the TaskForm component accordingly.
  const [fakeFormProps, setFakeFormProps] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const showNoLineItems = useSelector((state) => state.lineItems.showNoLineItems);
  const fetchLineItemsStatus = useSelector((state) => state.lineItems.fetchLineItemsStatus);
  const patchLineItemsStatus = useSelector((state) => state.lineItems.patchLineItemsStatus);
  const needApprovalList = useSelector((state) => state.lineItems.needApproval);
  const lineItemForBodySaveRequest = useSelector((state) => state.lineItems.lineItemForBodySaveRequest);
  const lineItemsList = useSelector((state) => state.lineItems.lineItemsList);
  const hasLineItemsButtons =
    (!showNoLineItems && !lineItemsList.length && !needApprovalList.length) || !!lineItemForBodySaveRequest.length;
  const isReadonlyTask = useTaskReadOnlyStatus(task.id, flags.reconVendorManagement);

  useEffect(() => {
    dispatch(lineItemsActions.getLineItems(task));

    dispatch(
      tasksActions.getTaskTimeInApprovalList(isVendor ? task?.vehicle?.dealerId : dealerId, task.id, entityTypes.TASK)
    );

    //unmount
    return () => {
      dispatch(lineItemsActions.resetLineItem());
      dispatch(tasksActions.resetTasksLineItemByVehicleId());
    };
  }, []);

  useEffect(() => {
    if (patchLineItemsStatus === apiStatusConstants.SUCCEEDED) {
      dispatch(
        tasksActions.getTaskTimeInApprovalList(isVendor ? task?.vehicle?.dealerId : dealerId, task.id, entityTypes.TASK)
      );
    }
  }, [patchLineItemsStatus]);

  useEffect(() => {
    if (!onForm && fakeFormProps !== null) {
      setOnForm(true);
    }
  }, [fakeFormProps]);

  const OverflowMenu = () => {
    const handleMenuClick = (key) => {
      if (key === 'decline') {
        setCurrentTaskUpdateType('decline');
        setOnForm(true);
      }
    };

    return (
      <Dropdown
        disabled={!canCompleteOrDecline}
        overlay={
          <Menu>
            {canCompleteOrDecline && (
              <Menu.Item key="decline" onClick={() => handleMenuClick('decline')}>
                <Icon type="stop" style={{ marginRight: '5px' }} />
                Decline Task
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <StyledMoreButton data-disabled={!canCompleteOrDecline} />
      </Dropdown>
    );
  };

  const onUpdateCompletedTask = (task) => {
    dispatch(tasksActions.updateTask(task.id, task));
  };

  const onComplete = ({ completedCost, completionNote, assignedToGroupId, assignedToGroupType }) => {
    dispatch(tasksActions.completeTask(task.id, completedCost, completionNote, assignedToGroupId, assignedToGroupType));
    setOnForm(false);
  };

  const onDecline = ({ declinedReason, assignedToGroupId, assignedToGroupType }) => {
    dispatch(tasksActions.declineTask(user.id, task.id, declinedReason, assignedToGroupId, assignedToGroupType));
    setOnForm(false);
  };

  const onAssignGroupTask = (assignedTo) => {
    const updatededTask = {
      ...task,
      assignedTo: assignedTo
    };
    dispatch(tasksActions.updateTask(task.id, updatededTask));
    setOnForm(false);
  };

  const onAssignTask = (assignedTo, assigednToMember) => {
    const updatededTask = {
      ...task,
      assignedTo: assignedTo,
      assignedToGroupId: null,
      assignedToGroupType: null
    };

    let group = null;
    let isAssignedToVendor = false;

    if (internalGroups?.length) {
      group = internalGroups.find((groupItem) => groupItem.id === assignedTo);
    }

    if (flags.reconVendorManagement && !vendorShipSelected) {
      if (vendors?.length && !group) {
        group = vendors.find((v) => v.id === assignedTo);
        if (group) {
          isAssignedToVendor = true;
        }
      }
    }

    //Case Root User in vendor view
    //root user can assign tasks directly to vendor users
    if (isRootUser && vendorShipSelected) {
      let vendorRootUser = vendors.find((v) => v.id === assignedTo);
      if (vendorRootUser) {
        updatededTask.assignedTo = assigednToMember;
        updatededTask.assignedToGroupId = assignedTo;
        updatededTask.assignedToGroupType = VENDOR;
        updatededTask.assignedToGroupName = '';
      }
    }

    if (group) {
      updatededTask.assignedTo = isAssignedToVendor ? null : assigednToMember;
      updatededTask.assignedToGroupId = assignedTo;
      updatededTask.assignedToGroupType = isAssignedToVendor ? VENDOR : DEALER_INTERNAL_GROUP;
    }

    dispatch(tasksActions.updateTask(updatededTask.id, updatededTask));
    setOnForm(false);
  };

  const onSetAssignTaskForm = () => {
    if (canEditWorkAnyTask || (isRootUser && vendorShipSelected)) {
      setCurrentTaskUpdateType('assign-task');
    } else if (isVendorAdmin || (currentMemberData && currentMemberData.isGroupLeader)) {
      setCurrentTaskUpdateType('assign');
    }
    setOnForm(true);
  };

  const onSetDeclineTaskForm = () => {
    setCurrentTaskUpdateType('decline');
    setOnForm(true);
  };

  const openCompleteForm = () => {
    setCurrentTaskUpdateType('complete');
    setOnForm(true);
  };

  const addLineItem = () => {
    setIsDirty(false);
    const newLineItems = {
      idTemp: uuidv4(),
      laborCost: '',
      partsCost: '',
      totalCost: '',
      description: '',
      isAdding: true,
      op: OPERATION_LINE_ITEM.ADD,
      status: lineItemStatusTypes.ESTIMATE_REQUESTED,
      entityId: task?.id || '',
      entityType: 'TASK'
    };
    dispatch(lineItemsActions.addNeedApproval(newLineItems));
    dispatch(lineItemsActions.hideAddNewLineItem());
    dispatch(lineItemsActions.toggleShowNoLineItems(false));
  };

  const onCloseTaskForm = () => {
    setOnForm(false);
    // When cancel completing an in-progress task, reset edited line items
    // and call API to get line items again
    if (currentTaskUpdateType === 'complete') {
      dispatch(lineItemsActions.resetLineItemForBodySaveRequest());
      dispatch(lineItemsActions.getLineItems(task));
      setIsDirty(false);
    }
  };

  const isShowErrorNoFooter = !(
    (((!showNoLineItems && (needApprovalList.length !== 0 || (!lineItemsList.length && !needApprovalList.length))) ||
      !!lineItemForBodySaveRequest.length ||
      canCompleteOrDecline) &&
      !onForm) ||
    (task.status === PENDING && !(task.declined || task.declinedOn))
  );

  const completeButtonToggleOn = (
    <StyledCompleteButtonToggleOn
      type="danger"
      loading={task.saving && currentTaskUpdateType === 'complete'}
      onClick={openCompleteForm}
      disabled={task.saving || needApprovalList.length !== 0 || fetchLineItemsStatus === apiStatusConstants.IS_FETCHING}
    >
      {task.saving && currentTaskUpdateType === 'complete' ? 'Completing' : 'Complete'}
    </StyledCompleteButtonToggleOn>
  );

  const isVendorTaskOnDealerview =
    flags.reconVendorManagement && canEditWorkAnyTask && task.assignedToGroupId && task.assignedToGroupType === VENDOR;

  const assignButtonText = isVendorTaskOnDealerview || (isRootUser && vendorShipSelected) ? 'Reassign' : 'Assign';

  // group leaders and dealer admin/managers can see button Assign
  // assign to me button only visible for member of group
  const buttonsForAssignedOrUnassignedTask = !task.assignedTo ? (
    <StyleReassignCompletedContainer>
      {((isVendorAdmin && vendorUsers.length > 2) ||
        currentMemberData?.isGroupLeader ||
        canEditWorkAnyTask ||
        (isRootUser && vendorShipSelected)) && (
        <StyledReassignButton
          className="btn-reassign"
          type="danger"
          ghost={true}
          disabled={task.saving}
          recon-tdp-update={flags.reconTdpUpdate.toString()}
          onClick={onSetAssignTaskForm}
        >
          {assignButtonText}
        </StyledReassignButton>
      )}
      {((isVendorAdmin && !isRootUser) || currentMemberData) && (
        <AssignToMeButton
          task={task}
          user={user}
          onUpdateCompletedTask={onUpdateCompletedTask}
          flags={flags}
          isVendor={isVendor}
        />
      )}
      {(isVendorTaskOnDealerview || (isRootUser && vendorShipSelected)) && completeButtonToggleOn}
    </StyleReassignCompletedContainer>
  ) : (
    <StyleReassignCompletedContainer>
      <ReassignButton
        task={task}
        member={currentMemberData}
        canEditWorkAnyTask={canEditWorkAnyTask}
        onSetAssignTaskForm={onSetAssignTaskForm}
        onUpdateCompletedTask={onUpdateCompletedTask}
        currentTaskUpdateType={currentTaskUpdateType}
        setCurrentTaskUpdateType={setCurrentTaskUpdateType}
        flags={flags}
        isVendorAdmin={isVendorAdmin}
        isVendorTech={isVendorTech}
        vendorUsers={vendorUsers}
        handleCloseDrawer={handleCloseDrawer}
      />
      {completeButtonToggleOn}
    </StyleReassignCompletedContainer>
  );

  const assignCompleteButtons =
    isVendor || (isTaskGroup && currentMemberData) || canEditWorkAnyTask
      ? buttonsForAssignedOrUnassignedTask
      : completeButtonToggleOn;

  const buttonsOfUndeclinedInprogressTask =
    (!showNoLineItems && !lineItemsList.length && !needApprovalList.length) || !!lineItemForBodySaveRequest.length
      ? (checkIfVendor || hasLineItemsEditRole) && (
          <LineItemButton
            task={task}
            setIsDirty={setIsDirty}
            contentType={contentType}
            isDirty={isDirty}
            reconTdpUpdate={flags.reconTdpUpdate}
            is-line-item={!!showNoLineItems}
          />
        )
      : assignCompleteButtons;

  return (
    <StyledTaskInfo>
      <StyledColumnContainer
        show-task-form={onForm}
        showNoLineItems={showNoLineItems}
        recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}
      >
        <StyledTaskHeaderLabel
          show-task-form={onForm ? 1 : 0}
          recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}
        >
          {flags.reconBreadcrumbUpdate ? (
            <>
              {onForm && (
                <CommonBreadcrumbLink
                  style={{ alignSelf: 'flex-start', marginBottom: '-3px' }}
                  onClick={() => {
                    if (onForm) {
                      setOnForm(false);
                    }
                  }}
                >
                  <BodyXSmallBold>
                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '4px', height: '13px' }} />
                    Back to Task
                  </BodyXSmallBold>
                </CommonBreadcrumbLink>
              )}
              <StyledLabelItem>{onForm ? <HeaderSmall>{getBreadcrumbLabel()}</HeaderSmall> : 'Task'}</StyledLabelItem>
            </>
          ) : (
            <CommonBreadcrumb style={{ fontSize: '18px' }} separator=">">
              <Breadcrumb.Item>
                <CommonBreadcrumbButton
                  disabled={!onForm}
                  onClick={() => {
                    if (onForm) {
                      setOnForm(false);
                    }
                  }}
                >
                  Task
                </CommonBreadcrumbButton>
              </Breadcrumb.Item>
              {onForm && <Breadcrumb.Item>{getBreadcrumbLabel()}</Breadcrumb.Item>}
            </CommonBreadcrumb>
          )}
        </StyledTaskHeaderLabel>
        {(task.saving || !onForm) && (
          <div style={{ marginTop: '10px', marginBottom: flags.reconTdpUpdate ? '8px' : '16px' }}>
            {task.saving && <SavingTaskOutline />}
            {!task.saving && !onForm && (
              <StandaloneTask
                key={task.id}
                task={task}
                editable={false}
                contentType={contentType}
                setFormProps={setFakeFormProps}
                vehicleId={task.vehicle.id}
                hidePassthroughIndicator={true}
                hideAssigneeWorkload={true}
                showCompletedTag={true}
                showPendingTag={!task.needsApproval} // Need approve status higher than pending => if needsApproval false => show pending tag
                hideOverflowMenu={!task.completedOn}
                hideMessageOverflowMenuItem={true}
                hidePassthroughOverflowMenuItem={true}
                reconPlanFeatures={[hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask]}
              />
            )}
          </div>
        )}
        {!task.saving && onForm ? (
          <div
            style={{
              height: `calc(100vh - ${flags.reconBreadcrumbUpdate ? '243px' : '242px'})`,
              margin: '12px -24px 0'
            }}
          >
            <TaskForm
              contentType={contentType}
              parentId={task.vehicle.id}
              taskToUpdate={task}
              count={1}
              closeForm={onCloseTaskForm}
              onAssignTask={currentTaskUpdateType === 'assign-task' && onAssignTask}
              onAssignGroupTask={currentTaskUpdateType === 'assign' && onAssignGroupTask}
              onUpdate={task.completedOn ? onUpdateCompletedTask : null} //task.completedOn only has a value in the task dto when the task is already marked completed - this is for updating a completed task
              onComplete={!task.completedOn && currentTaskUpdateType === 'complete' && onComplete} //so with that, the function for completing a task should only be set when task.completedOn is not present in the dto
              onDecline={!task.completedOn && currentTaskUpdateType === 'decline' ? onDecline : null} //currentTaskUpdateType is set when pushing the 'complete' button and when clicking 'decline' on the overflow menu
              formHeight={'calc(100vh - 245px)'}
              hidePassthroughIndicator={true}
            />
          </div>
        ) : (
          flags.reconTdpUpdate &&
          (task.saving ? (
            <div style={{ verticalAlign: 'center', height: '100%' }}>
              <Loading />
            </div>
          ) : (
            <>
              <StyledRequestLineItems>
                {
                  {
                    [apiStatusConstants.IS_FETCHING]: <Loading />,
                    [apiStatusConstants.SUCCEEDED]: (
                      <>
                        {showNoLineItems && task.status !== COMPLETED ? (
                          (hasLineItemsEditRole || checkIfVendor) &&
                          !isReadonlyTask &&
                          !isHQ && (
                            <EmptyLineItem
                              addLineItem={addLineItem}
                              buttonTitle={getEmptyLineItemDescreption(hasApproverRole).text}
                              description={getEmptyLineItemDescreption(hasApproverRole).descriptions}
                            ></EmptyLineItem>
                          )
                        ) : (
                          <AddLineItemForm
                            task={task}
                            setIsDirty={setIsDirty}
                            isForViewingCompletedTaskTDP={task.status === COMPLETED}
                            hasPermissionApprovedDecline={hasApproverRole}
                            hasLineItemsEditRole={hasLineItemsEditRole}
                            contentType={contentType}
                          />
                        )}
                      </>
                    ),
                    [apiStatusConstants.FAILED]: <Error />
                  }[fetchLineItemsStatus]
                }
              </StyledRequestLineItems>
              <AlertForLineItemError isTDP={true} isShowErrorNoFooter={isShowErrorNoFooter} />
            </>
          ))
        )}
        {!flags.reconTdpUpdate &&
          (canCompleteOrDecline && !onForm ? (
            <span>
              <StyledCompleteButton
                type="danger"
                icon={task.saving ? '' : 'check'}
                loading={task.saving && currentTaskUpdateType === 'complete'}
                onClick={openCompleteForm}
                disabled={task.saving}
                recon-tdp-update={flags.reconTdpUpdate.toString()}
              >
                {task.saving && currentTaskUpdateType === 'complete' ? 'Completing' : 'Complete'}
              </StyledCompleteButton>
              <OverflowMenu />
            </span>
          ) : task.status === PENDING ? (
            !hasWorkPermission || !isVendor || task.declined || task.declinedOn ? (
              <></>
            ) : (
              <div style={{ display: 'block' }}>
                <Tooltip
                  placement="top"
                  overlayClassName="task-pending-tdp-toggle"
                  title={<CommonCenteredText>This action cannot be taken on pending tasks</CommonCenteredText>}
                >
                  <div style={{ width: 'max-content' }}>
                    <StyledCompleteButton
                      type="danger"
                      icon={'check'}
                      disabled={true}
                      recon-tdp-update={flags.reconTdpUpdate.toString()}
                    >
                      Complete
                    </StyledCompleteButton>
                    <StyledMoreButton data-disabled={true} />
                  </div>
                </Tooltip>
              </div>
            )
          ) : (
            <></>
          ))}
      </StyledColumnContainer>

      {flags.reconTdpUpdate && !isHQ && (
        <>
          {((isVendor && !task.declined && !task.declinedOn && task.status !== PENDING && task.status !== COMPLETED) ||
            canCompleteOrDecline ||
            groupLeaderCanCompleteOrDecline ||
            (isUndeclinedInprogressTask &&
              !hasWorkPermission &&
              !task?.assignedTo &&
              currentMemberData &&
              !currentMemberData.isGroupLeader)) &&
          !onForm ? (
            <StyledFooterContainer
              className={isHideDeclineButtonIdGroupTask || isVendorTech ? 'without-decline-button' : ''}
            >
              <StyledDeclineButton
                type="danger"
                ghost={true}
                disabled={task.saving || fetchLineItemsStatus === apiStatusConstants.IS_FETCHING}
                className={isHideDeclineButtonIdGroupTask || isVendorTech ? 'd-none' : ''}
                recon-tdp-update={flags.reconTdpUpdate.toString()}
                loading={task.saving && currentTaskUpdateType === 'decline'}
                onClick={onSetDeclineTaskForm}
              >
                {task.saving && currentTaskUpdateType === 'decline' ? 'Declining' : 'Decline'}
              </StyledDeclineButton>
              {buttonsOfUndeclinedInprogressTask}
            </StyledFooterContainer>
          ) : task.declined ? (
            onForm && (currentTaskUpdateType === 'assign' || currentTaskUpdateType === 'assign-task') ? null : (
              <StyledFooterContainer
                style={
                  (!hasLineItemsButtons || (!checkIfVendor && !hasLineItemsEditRole)) &&
                  !(canEditWorkAnyTask || (isRootUser && vendorShipSelected))
                    ? { borderTop: 'none' }
                    : {}
                }
              >
                <StyledButton>
                  <Tooltip></Tooltip>
                  <>
                    {hasLineItemsButtons ? (
                      (checkIfVendor || hasLineItemsEditRole) && (
                        <LineItemButton
                          setIsDirty={setIsDirty}
                          contentType={contentType}
                          task={task}
                          isDirty={isDirty}
                          reconTdpUpdate={flags.reconTdpUpdate}
                          is-line-item={!!showNoLineItems}
                        />
                      )
                    ) : (
                      <>
                        <StyleReassignCompletedContainer>
                          {(canEditWorkAnyTask || (isRootUser && vendorShipSelected)) && (
                            <ReassignButton
                              task={task}
                              member={currentMemberData}
                              canEditWorkAnyTask={canEditWorkAnyTask}
                              onSetAssignTaskForm={onSetAssignTaskForm}
                              onUpdateCompletedTask={onUpdateCompletedTask}
                              currentTaskUpdateType={currentTaskUpdateType}
                              setCurrentTaskUpdateType={setCurrentTaskUpdateType}
                              flags={flags}
                            />
                          )}
                        </StyleReassignCompletedContainer>
                      </>
                    )}
                  </>
                </StyledButton>
              </StyledFooterContainer>
            )
          ) : task.status === PENDING ? (
            (!hasWorkPermission && (!isTaskGroup || (isTaskGroup && !currentMemberData))) ||
            task.declined ||
            task.declinedOn ? (
              (checkIfVendor || hasLineItemsEditRole) && (
                <FooterCancelSave
                  setIsDirty={setIsDirty}
                  contentType={contentType}
                  showNoLineItems={showNoLineItems}
                  onForm={onForm}
                  task={task}
                  isDirty={isDirty}
                  reconTdpUpdateFlag={flags.reconTdpUpdate}
                />
              )
            ) : // hide all button when on onForm with currentTaskUpdateType = assign/assign-task
            onForm && (currentTaskUpdateType === 'assign' || currentTaskUpdateType === 'assign-task') ? null : (
              <StyledFooterContainer>
                <StyledButton className={isVendorTech ? 'without-decline-button' : ''}>
                  <Tooltip
                    placement="top"
                    overlayClassName="task-pending-tdp-toggle"
                    title={<CommonCenteredText>This action cannot be taken on pending tasks</CommonCenteredText>}
                    className={isVendorTech ? 'd-none' : ''}
                  >
                    <StyledDeclineButton
                      disabled={true}
                      recon-tdp-update={flags.reconTdpUpdate.toString()}
                      style={{ marginRight: '16px' }}
                    >
                      Decline
                    </StyledDeclineButton>
                  </Tooltip>
                  <>
                    {hasLineItemsButtons ? (
                      (checkIfVendor || hasLineItemsEditRole) && (
                        <LineItemButton
                          setIsDirty={setIsDirty}
                          contentType={contentType}
                          task={task}
                          isDirty={isDirty}
                          reconTdpUpdate={flags.reconTdpUpdate}
                          is-line-item={!!showNoLineItems}
                        />
                      )
                    ) : (
                      <>
                        {isVendor || isTaskGroup || (isTaskGroup && !currentMemberData) || canEditWorkAnyTask ? (
                          !task.assignedTo ? (
                            <StyleReassignCompletedContainer>
                              {((isVendorAdmin && vendorUsers.length > 2) ||
                                (isRootUser && vendorShipSelected) ||
                                currentMemberData?.isGroupLeader ||
                                canEditWorkAnyTask) && (
                                <StyledReassignButton
                                  type="danger"
                                  ghost={true}
                                  disabled={task.saving}
                                  recon-tdp-update={flags.reconTdpUpdate.toString()}
                                  onClick={onSetAssignTaskForm}
                                >
                                  {assignButtonText}
                                </StyledReassignButton>
                              )}
                              {((isVendorAdmin && !isRootUser) || currentMemberData) && (
                                <AssignToMeButton
                                  task={task}
                                  user={user}
                                  onUpdateCompletedTask={onUpdateCompletedTask}
                                  flags={flags}
                                  isVendor={isVendor}
                                />
                              )}
                            </StyleReassignCompletedContainer>
                          ) : (
                            <StyleReassignCompletedContainer>
                              {(isVendor ||
                                userOwnsThisTask ||
                                canEditWorkAnyTask ||
                                currentMemberData?.isGroupLeader) && (
                                <ReassignButton
                                  task={task}
                                  member={currentMemberData}
                                  canEditWorkAnyTask={canEditWorkAnyTask}
                                  onSetAssignTaskForm={onSetAssignTaskForm}
                                  onUpdateCompletedTask={onUpdateCompletedTask}
                                  currentTaskUpdateType={currentTaskUpdateType}
                                  setCurrentTaskUpdateType={setCurrentTaskUpdateType}
                                  flags={flags}
                                  isVendorAdmin={isVendorAdmin}
                                  isVendorTech={isVendorTech}
                                  vendorUsers={vendorUsers}
                                  handleCloseDrawer={handleCloseDrawer}
                                />
                              )}
                              <Tooltip
                                placement="top"
                                overlayClassName="task-pending-tdp-toggle"
                                title={
                                  <CommonCenteredText>This action cannot be taken on pending tasks</CommonCenteredText>
                                }
                              >
                                <StyledCompleteButtonToggleOn type="danger" disabled={true}>
                                  Complete
                                </StyledCompleteButtonToggleOn>
                              </Tooltip>
                            </StyleReassignCompletedContainer>
                          )
                        ) : (
                          <Tooltip
                            placement="top"
                            overlayClassName="task-pending-tdp-toggle"
                            title={
                              <CommonCenteredText>This action cannot be taken on pending tasks</CommonCenteredText>
                            }
                          >
                            <StyledCompleteButtonToggleOn type="danger" disabled={true}>
                              Complete
                            </StyledCompleteButtonToggleOn>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                </StyledButton>
              </StyledFooterContainer>
            )
          ) : flags.reconTdpUpdate && (checkIfVendor || hasLineItemsEditRole) ? (
            task.completedOn && !lineItemsList.length ? (
              <></>
            ) : (
              <FooterCancelSave
                setIsDirty={setIsDirty}
                contentType={contentType}
                showNoLineItems={showNoLineItems}
                onForm={onForm}
                task={task}
                isDirty={isDirty}
                reconTdpUpdateFlag={flags.reconTdpUpdate}
              />
            )
          ) : (
            <></>
          )}
        </>
      )}
    </StyledTaskInfo>
  );
});

const LineItemButton = withLDConsumer()(
  ({ task, showNoLineItems, reconTdpUpdate = false, isDirty, setIsDirty, flags, contentType }) => {
    const lineItemForBodySaveRequest = useSelector((state) => state.lineItems.lineItemForBodySaveRequest);
    const lineItemsList = useSelector((state) => state.lineItems.lineItemsList);
    const needApprovalList = useSelector((state) => state.lineItems.needApproval);
    const approvedList = useSelector((state) => state.lineItems.approved);
    const declinedList = useSelector((state) => state.lineItems.declined);
    const originalLineItemsList = useSelector((state) => state.lineItems.originalLineItemsList);
    const fetchLineItemsStatus = useSelector((state) => state.lineItems.fetchLineItemsStatus);
    const needApprovalErrorMessage = useSelector((state) => state.lineItems.needApprovalErrorMessage);
    const hasErrorTotalCost =
      [...needApprovalList, ...approvedList, ...declinedList].find((x) => x.error) || needApprovalErrorMessage;
    const [hasEditTasks] = useFeatures(features.TASKS_EDIT);

    const { totalCostApproved } = useSelector(lineItemSelector);
    const dispatch = useDispatch();

    const onCancel = () => {
      if (lineItemsList.length === 0) {
        dispatch(lineItemsActions.resetLineItem());
      } else {
        dispatch(lineItemsActions.resetLineItemForBodySaveRequest());
        dispatch(lineItemsActions.getLineItems(task));
      }
      if (originalLineItemsList.length === 0) {
        dispatch(lineItemsActions.resetLineItem());
      }
      dispatch(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
    };

    const openCompleteForm = () => {
      task.completedCost = totalCostApproved;
      const showTagNeedApproval = getShowTagNeedApprovel(needApprovalList);
      dispatch(lineItemsActions.patchLineItemsTDP(lineItemForBodySaveRequest, task, contentType));

      if (hasEditTasks && task.completedOn) {
        // call api update completed cost for this task
        dispatch(tasksActions.refreshFinalCostForTask(task.id, totalCostApproved));

        // refresh state
        dispatch(tasksActions.updateTaskFinalCost(task, task.id, totalCostApproved, showTagNeedApproval));
      } else {
        dispatch(tasksActions.updateTaskNeedsApprovalStatus(task.id, showTagNeedApproval));
      }

      setIsDirty(false);
    };

    return (
      <StyledLineItemButton>
        <StyledDeclineButton
          type="danger"
          style={{ minWidth: '98px' }}
          ghost={true}
          disabled={
            fetchLineItemsStatus === apiStatusConstants.IS_FETCHING ||
            (!isDirty && !!lineItemsList.filter((item) => !item.isAdding).length)
          }
          is-line-item={!showNoLineItems}
          recon-tdp-update={reconTdpUpdate.toString()}
          onClick={onCancel}
        >
          Cancel
        </StyledDeclineButton>
        <StyledCompleteButtonToggleOn
          type="danger"
          onClick={openCompleteForm}
          disabled={!isDirty || fetchLineItemsStatus === apiStatusConstants.IS_FETCHING || !!hasErrorTotalCost}
        >
          Save
        </StyledCompleteButtonToggleOn>
      </StyledLineItemButton>
    );
  }
);

const FooterCancelSave = ({ showNoLineItems, onForm, task, isDirty, reconTdpUpdateFlag, setIsDirty, contentType }) => {
  const needApprovalList = useSelector((state) => state.lineItems.needApproval);
  const lineItemForBodySaveRequest = useSelector((state) => state.lineItems.lineItemForBodySaveRequest);
  const lineItemsList = useSelector((state) => state.lineItems.lineItemsList);

  return ((!showNoLineItems &&
    (needApprovalList.length !== 0 || (!lineItemsList.length && !needApprovalList.length))) ||
    !!lineItemForBodySaveRequest.length) &&
    !onForm ? (
    <StyledFooterContainer>
      <StyledButton style={{ justifyContent: 'flex-end' }}>
        <LineItemButton
          setIsDirty={setIsDirty}
          contentType={contentType}
          task={task}
          isDirty={isDirty}
          reconTdpUpdate={reconTdpUpdateFlag}
          is-line-item={!!showNoLineItems}
        />
      </StyledButton>
    </StyledFooterContainer>
  ) : (
    <></>
  );
};

const AssignToMeButton = ({ onUpdateCompletedTask, task, user, flags, isVendor }) => {
  const onUpdateAssignTo = () => {
    if (
      task.assignedToGroupId !== null &&
      (task.assignedToGroupType === DEALER_INTERNAL_GROUP || (isVendor && task.assignedToGroupType === VENDOR))
    ) {
      // I want to use deep clone here because in case when submitting to BE i still want to keep button AssignToMe displayed with loading icon
      let updateTask = { ...task };
      updateTask.assignedTo = user.id;
      onUpdateCompletedTask(updateTask);
    }
  };

  return (
    <StyledAssignToMeButton
      type="danger"
      loading={task.saving}
      onClick={onUpdateAssignTo}
      disabled={task.saving}
      recon-tdp-update={flags.reconTdpUpdate.toString()}
    >
      Assign To Me
    </StyledAssignToMeButton>
  );
};

const ReassignButton = ({
  onUpdateCompletedTask,
  task,
  member,
  canEditWorkAnyTask,
  onSetAssignTaskForm,
  currentTaskUpdateType,
  setCurrentTaskUpdateType,
  flags,
  isVendorAdmin,
  isVendorTech,
  vendorUsers,
  handleCloseDrawer
}) => {
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const onUpdateAssignTo = () => {
    // group leaders and dealer admin/managers able to open the assign task group form
    if (
      (isVendorAdmin && vendorUsers.length > 2) ||
      (member && member.isGroupLeader) ||
      canEditWorkAnyTask ||
      (isRootUser && vendorShipSelected)
    ) {
      onSetAssignTaskForm();
      return;
    }
    Modal.confirm({
      title: `Reassign task to ${task.assignedToGroupName}?`,
      content: `The task will become unassigned so anyone in the group can start working on it.`,
      okText: 'Reassign',
      okType: 'danger',
      cancelText: 'Cancel',
      cancelButtonProps: { className: 'btn-dangerous' },
      onOk() {
        setCurrentTaskUpdateType('reassign');
        if (
          task?.assignedTo !== null &&
          task?.assignedToGroupId !== null &&
          (task?.assignedToGroupType === DEALER_INTERNAL_GROUP ||
            ((isVendorAdmin || isVendorTech) && !isRootUser && task?.assignedToGroupType === VENDOR))
        ) {
          let updateTask = { ...task };
          updateTask.assignedTo = null;
          onUpdateCompletedTask(updateTask);
          if (isVendorTech && handleCloseDrawer) {
            handleCloseDrawer();
          }
        }
      },
      onCancel() {
        devLogger.log(`Canceled Reassign ${task.assignedToGroupName}`);
      },
      icon: <StyledConfirmationModalIcon icon={faQuestionCircle} className="anticon" />
    });
  };

  return (
    <StyledReassignButton
      type="danger"
      ghost={true}
      className="btn-reassign"
      disabled={task.saving}
      recon-tdp-update={flags.reconTdpUpdate.toString()}
      loading={task.saving && currentTaskUpdateType === 'reassign'}
      onClick={onUpdateAssignTo}
    >
      Reassign
    </StyledReassignButton>
  );
};
//#region Styled Components for TaskInfo
const StyledTaskInfo = styled.div`
  position: relative;

  .without-decline-button {
    flex-direction: row-reverse;
  }
`;
export const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => !props['show-task-form'] && 'auto'};
  height: calc(100vh - 232px);
  padding: ${(props) =>
    props['recon-breadcrumb-update-flag']
      ? props['show-task-form']
        ? '10px 24px 0px'
        : '34px 24px 0px 24px'
      : '24px 24px 0px'};
`;
const StyledLabelItem = styled.div`
  color: ${({ theme }) => theme.colors.navy};
`;
export const StyledTaskHeaderLabel = styled(CommonStrongText)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) =>
    props['recon-breadcrumb-update-flag'] ? (!props['show-task-form'] ? '10px' : '0') : '8px'};
  line-height: 10px;
`;
export const StyledCompleteButton = styled(Button)`
  .ant-btn& {
    width: min-content;
    min-width: ${(props) => (props['recon-tdp-update'] === 'true' ? '98px' : '128px')};
    height: 32px;
    border-radius: 4px;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.white)};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    box-shadow: none;
    ${({ disabled }) =>
      !disabled
        ? `
        :hover, :focus {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.primaryButtonHoverBackgroundColor};
          border-color: ${({ theme }) => theme.colors.primaryButtonHoverBackgroundColor};
        }`
        : ''}
  }
`;

export const StyledCompleteButtonToggleOn = styled(Button).attrs({
  type: 'danger'
})`
  .ant-btn& {
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    min-width: 98px;
    margin-right: 24px;
    border-radius: 4px;
    height: 32px;
    padding: 0 16px;
    display: inline-flex;
    align-items: center;
  }
  .ant-btn-danger[disabled]& {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.red} !important;
    opacity: 0.5;
    :hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: rgba(254, 32, 59, 0.15);
      border-color: unset;
    }
    .ant-btn-background-ghost& {
      color: ${({ theme }) => theme.colors.red} !important;
      border-color: ${({ theme }) => theme.colors.red} !important;
    }
  }
`;

export const StyledDeclineButton = styled(StyledCompleteButton)`
  opacity: ${({ disabled }) => disabled && '0.5'};
  color: ${({ theme }) => theme.colors.red} !important;
  border-color: ${({ theme }) => theme.colors.red} !important;
  margin-left: 24px;
  margin-right: ${(props) => props['is-line-item'] && '16px'};
`;

export const StyledMoreButton = styled((props) => {
  return (
    <span {...props} className={props.className + ' fa-layers fa-fw'}>
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="47" fill="transparent" strokeWidth="3" />
      </svg>
      <FontAwesomeIcon icon={faEllipsisH} />
    </span>
  );
})`
  & {
    height: 34px;
    width: 34px;
    margin-left: 8px;
    vertical-align: middle;

    > svg {
      cursor: ${(props) => (props['data-disabled'] ? 'not-allowed' : 'pointer')};
      opacity: ${(props) => (props['data-disabled'] ? '.5' : '1')};
      stroke: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};
    }
    :hover > svg {
      opacity: 0.5;
    }
  }
`;

const StyledRequestLineItems = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
`;
const StyledLineItemButton = styled.div``;

const StyledFooterContainer = styled(CommonFooterContainer)`
  justify-content: space-between;
  height: 73px;
  position: absolute;
  bottom: -50px;
  z-index: 150;
  width: 100%;
`;

const StyledButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  width: 100%;
  height: 100%;
`;

export const StyledAssignToMeButton = styled(CommonRedButton)`
  margin-right: 24px;
`;

export const StyledReassignButton = styled(CommonRedButton)`
  opacity: ${({ disabled }) => disabled && '0.5'};
  color: ${({ theme }) => theme.colors.red} !important;
  border-color: ${({ theme }) => theme.colors.red} !important;
  margin-right: 16px;
`;
