import React, { useState, useEffect } from 'react';
import { CreateStringWithURLClickable } from 'utils/createStringWithURLClickable.js';
import styled from 'styled-components';
import moment from 'moment';
import { Typography, Popover, Row, Col } from 'antd';
import { EditCommunicationInput, CommunicationImages, CommunicationOverflowMenu } from '../common';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { formatDateWithYear, formatTime } from 'utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import { communicationContexts, locationActions } from 'app-constants';

const { Text } = Typography;

const VehicleNote = ({
  id,
  user,
  userId,
  content,
  comment,
  createdOn,
  updatedOn,
  fullName,
  images,
  scrollToNote = false,
  updateCommunication,
  deleteCommunication,
  location,
  index,
  taggableUsers,
  flags,
  taskIsDeleted
}) => {
  const [transientCommunication, setTransientCommunication] = useState('');
  const { isShowLoadingSendCommunication, indexCommunicationWhenUpdate, focusToCommunicationId, locationAction } =
    useSelector((state) => state.vehicles);
  const [showEditor, setShowEditor] = useState(isShowLoadingSendCommunication || !!updatedOn);
  const [indexCommunication, setIndexCommunication] = useState(indexCommunicationWhenUpdate || 0);

  useEffect(() => {
    if (scrollToNote) {
      const node = document.getElementById(id);
      if (node) {
        node.scrollIntoView();
      }
    }
  }, [id, scrollToNote]);

  const getLocationActionSubmit = (location) => {
    if (
      location === communicationContexts.TASK_DETAILS_PAGE ||
      location === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER
    ) {
      return locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_TASK_DETAIL_PAGE;
    } else if (location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE) {
      return locationActions.BUTTON_SAVE_VEHICLE_EDIT_COMMUNICATION_PAGE_MESSAGE;
    } else if (location === communicationContexts.VEHICLE_DETAILS_PAGE) {
      return locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DETAIL_PAGE;
    } else if (location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER) {
      return locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DRAWER_COMMUNICATION_PAGE;
    } else {
      return null;
    }
  };

  const checkShowEditorInventoryPage = () => {
    return (
      showEditor &&
      ((index === indexCommunication && focusToCommunicationId === null) || focusToCommunicationId === id) &&
      ((locationAction === locationActions.BUTTON_SAVE_VEHICLE_EDIT_COMMUNICATION_PAGE_MESSAGE &&
        location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE) ||
        (locationAction === locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DETAIL_PAGE &&
          location === communicationContexts.VEHICLE_DETAILS_PAGE) ||
        (locationAction === locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DRAWER_COMMUNICATION_PAGE &&
          location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER) ||
        locationAction === null)
    );
  };

  const hasShowEditorInventoryPage = checkShowEditorInventoryPage();

  const hoverTooltipContent = (date) => (
    <div style={{ width: '135px' }}>
      <Row type="flex" justify="center">
        <Col>
          <Text>{formatDateWithYear(date)}</Text>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Text>at {formatTime(date)}</Text>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <StyledContentContainer
        show-editor={showEditor}
        has-images={images.length > 0}
        has-content={!!content && content.length > 0}
        has-overflow-menu={user.id === userId}
      >
        {hasShowEditorInventoryPage ? (
          <EditCommunicationInput
            id={id}
            transientCommunication={transientCommunication}
            setTransientCommunication={setTransientCommunication}
            setShowEditor={setShowEditor}
            updateCommunication={updateCommunication}
            images={images}
            flags={flags}
            setIndexCommunication={setIndexCommunication}
            index={index}
            indexCommunication={indexCommunication}
            location={location}
            getLocationActionSubmit={getLocationActionSubmit}
            taggableUsers={taggableUsers}
          />
        ) : (
          <StyledContent>
            {<CreateStringWithURLClickable content={content} comment={comment} />}
            {!!updatedOn ? <StyledEditedStamp>(Edited)</StyledEditedStamp> : null}
          </StyledContent>
        )}
        <StyledCommunicationOverflowMenu>
          {user.id === userId && (
            <CommunicationOverflowMenu
              content={content}
              comment={comment}
              images={images}
              setShowEditor={setShowEditor}
              setTransientCommunication={setTransientCommunication}
              isVehicleNote={true}
              deleteCommunication={deleteCommunication}
              flags={flags}
              loading={isShowLoadingSendCommunication}
              setIndexCommunication={setIndexCommunication}
              index={index}
              successMessage={communicationContexts.MESSAGE_SUCCESS_DELETE_NOTE}
              loadingMessage={communicationContexts.MESSAGE_LOADING_DELETE_NOTE}
              taskIsDeleted={taskIsDeleted}
            />
          )}
        </StyledCommunicationOverflowMenu>
      </StyledContentContainer>
      {(index !== indexCommunication || !showEditor) && (
        <CommunicationImages
          images={images}
          createdOn={createdOn}
          isKeepWrapWidth={!(user.id === userId && !updatedOn && !content)}
        />
      )}
      <StyledAuthorInfo id={id}>
        Vehicle note added by <strong>{fullName}</strong> on
        <Popover content={hoverTooltipContent(createdOn)} trigger="hover">
          <span>
            {' '}
            <strong>{moment(createdOn).format('MM-DD-YY')}</strong>
          </span>
        </Popover>
      </StyledAuthorInfo>
    </>
  );
};

//#region Styled Components
const StyledContentContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  margin-bottom: ${(props) => {
    if (props['has-images']) {
      if (props['has-content']) {
        return '8px';
      } else {
        return props['has-overflow-menu'] && !props['show-editor'] //Avoid overlap buttons and text 'Vehicle note added ...'
          ? '-24px' //If there are images but no text, move this div down in-line with the images
          : '8px'; //If there are images but no text or overflow menu, remove margin-bottom so images don't overlap with the divider above them
      }
    } else {
      return '4px';
    }
  }};
`;
const StyledCommunicationOverflowMenu = styled.div`
  cursor: pointer;
`;
const StyledAuthorInfo = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.xs};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const StyledContent = styled.p`
  margin-bottom: 4px;
  overflow-wrap: break-word;
`;

const StyledEditedStamp = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
//#endregion

export default withLDConsumer()(VehicleNote);
