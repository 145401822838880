import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';
import PropTypes from 'prop-types';
import { BodyBaseBold } from 'components/styledComponents';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Popover, Typography } from 'antd';
import { Stack } from 'components';
import { displayDurationSummary, getMomentDurationFromString } from 'utils/dateTimeUtils';
import { taskStatusLabels } from 'app-constants';

const { Text } = Typography;

const BasicLayeredTaskIcon = withLDConsumer()(
  ({
    task,
    customHoverContent,
    backgroundColor,
    foregroundColor,
    foregroundTransform,
    customContent,
    backgroundIcon = faCircle,
    foregroundIcon = faCircle,
    group = false,
    disableIconHover = false,
    showClipBoarIconPlanTemplate = false,
    flags,
    ...other
  }) => {
    return (
      <>
        <Popover
          content={customHoverContent || <BaseHoverContent task={task} />}
          placement={flags.reconTaskCollapse ? 'topLeft' : 'top'}
          trigger={(task || group) && !disableIconHover ? 'hover' : 'none'}
        >
          {showClipBoarIconPlanTemplate ? (
            <>
              <StyledLayeredIconContainer
                task-completed={task?.completedOn}
                reconTaskCollapseFlag={flags.reconTaskCollapse}
                {...other}
              >
                {/**Setting icons to null will remove it*/}
                {backgroundIcon && <StyledBackground icon={backgroundIcon} color={backgroundColor} />}
                {foregroundIcon && (
                  <StyledForeground icon={foregroundIcon} color={foregroundColor} transform={foregroundTransform} />
                )}
              </StyledLayeredIconContainer>
              <StyledTaskName
                id={task.reconTaskTypeName}
                className="task-type-name"
                recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0}
              >
                {flags.reconTaskCollapse ? (
                  <BodyBaseBold>{task.reconTaskTypeName}</BodyBaseBold>
                ) : (
                  <span>{task.reconTaskTypeName}</span>
                )}
              </StyledTaskName>
            </>
          ) : (
            customContent || (
              <StyledLayeredIconContainer
                task-completed={task?.completedOn}
                reconTaskCollapseFlag={flags.reconTaskCollapse}
                {...other}
              >
                {/**Setting icons to null will remove it*/}
                {backgroundIcon && <StyledBackground icon={backgroundIcon} color={backgroundColor} />}
                {foregroundIcon && (
                  <StyledForeground icon={foregroundIcon} color={foregroundColor} transform={foregroundTransform} />
                )}
              </StyledLayeredIconContainer>
            )
          )}
        </Popover>
      </>
    );
  }
);

export const BaseHoverContent = withLDConsumer()(({ task, showLeftBorder }) => {
  if (
    (task.declined || (task.status && task.status.toLowerCase() === taskStatusLabels.DECLINED.toLowerCase())) &&
    !task.declinedReason
  ) {
    return null;
  }

  return (
    <Stack horizontal>
      {showLeftBorder ? (
        <StyledDividerContent
          declinedTask={
            task.declined || (task.status && task.status.toLowerCase() === taskStatusLabels.DECLINED.toLowerCase())
          }
        />
      ) : null}
      <StyledHoverContent>
        {!(task.declined || (task.status && task.status.toLowerCase() === taskStatusLabels.DECLINED.toLowerCase())) ? (
          <React.Fragment>
            <Stack>
              Goal Time: {task && displayDurationSummary(getMomentDurationFromString(task.goalThreshold), false, true)}
            </Stack>
            <Stack>
              Max Time: {task && displayDurationSummary(getMomentDurationFromString(task.maxThreshold), false, true)}
            </Stack>
          </React.Fragment>
        ) : (
          <Stack>Reason: {task.declinedReason}</Stack>
        )}
      </StyledHoverContent>
    </Stack>
  );
});
BaseHoverContent.propTypes = {
  task: PropTypes.objectOf(PropTypes.any),
  showLeftBorder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  additionalInformation: PropTypes.node,
  showAdditionalInformationHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showAdditionalInformationSubHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};
BaseHoverContent.defaultProps = {
  task: {},
  showLeftBorder: false,
  additionalInformation: false,
  showAdditionalInformationHeader: false,
  showAdditionalInformationSubHeader: false
};

const StyledHoverContent = styled.div`
  display: flex column nowrap;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  padding-left: 5px;
`;

const StyledLayeredIconContainer = styled.div.attrs({
  className: `fa-layers fa-fw`
})`
  display: ${(props) => props['task-completed'] && 'none'};
  font-size: ${({ theme }) => theme.icons.fontSizes.layeredIcon};
  width: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '1em'};
  margin-left: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '-6px'};
  height: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '20px'};
`;

const StyledBackground = styled(FontAwesomeIcon).attrs((props) => ({
  color: props.color || props.theme.colors.red
}))``;

const StyledForeground = styled(FontAwesomeIcon).attrs((props) => ({
  color: props.color || props.theme.colors.white,
  // shrink proportional to the overall size to fit inside the background
  transform: props.transform || 'shrink-5'
}))``;

const StyledDividerContent = styled.div`
  display: flex;
  border-left: solid 1px ${({ theme }) => theme.colors.lightGray};
  height: ${({ declinedTask }) => (declinedTask ? '16px' : '33px')};
  margin: 4px 4px 0px 0px;
`;

const StyledTaskName = styled(Text).attrs((props) => ({
  ellipsis: !props['recon-task-collapse-flag']
}))`
  flex: 1 1 0px;
  font-size: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontSizes.md};
  font-weight: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontWeights.medium};
`;

export default BasicLayeredTaskIcon;
