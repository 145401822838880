import {css} from 'styled-components';

/**
 * A mixin that can be used with any element that can be rotated. Defaults 
 * @param {String} className
 * @param {String} toggleName
 * @param {Number} degrees
 * @param {Number} transitionDuration
 */

export const elementRotate = (
  className,
  toggleName,
  degrees = 180, 
  transitionDuration = 0.3
) => css`
  .${className} {
    transform: ${props => `rotate(${props[toggleName] ? degrees : 0}deg)`};
    transition: ${transitionDuration}s;
  }
`;