//third party dependencies
import { useLocation } from '@reach/router';
import React, { useEffect, useState, useContext } from 'react';
import { isEqual, clone } from 'lodash';
import { useDispatch } from 'react-redux';

//our components, enums, hooks, helper functions, redux actions,...
import { DragDropContext as OurDragDropContext, HQAndProfitTimeContext } from 'utils/contexts';
import { RECON_PLAN, TEMPLATE_PLAN } from 'app-constants/planTypes';
import Task from './Task';
import { IN_PROGRESS } from 'app-constants/taskStatusTypes';
import { oldPathNamePlanTemplate, pathNamePlanTemplate } from 'app-constants/planTemplatePath';
/**
 * StandaloneTask - this component allows us to reuse the Task.js component
 * outside of a drag-n-drop context by wrapping it in it's own
 * context (replaces the old ReconTask.js component).
 */
const StandaloneTask = ({
  vehicleId,
  task,
  editable,
  onUpdate,
  onComplete,
  onDelete,
  contentType = null,
  closeForm,
  setFormProps,
  isSaving = false,
  hidePassthroughIndicator = false,
  showCompletedTag = false,
  showPendingTag = false,
  hideAssigneeWorkload = false,
  hideTaskProgressInfo = false,
  planStarted = true,
  reconPlanFeatures,
  hideOverflowMenu = false,
  hideMessageOverflowMenuItem = false,
  hideLineItemsOverflowMenuItem = false,
  hidePassthroughOverflowMenuItem = false,
  countMessagesTask = null,
  isInProgressTaskGroup = null,
  isShowContentSegmentTDP,
  showPendingApprovalTime = true
}) => {
  const onTaskChange = (type) => (updatedTask) => {
    switch (type) {
      case 'add':
      case 'update':
        return onUpdate(updatedTask);
      case 'complete':
        return onComplete(updatedTask);
      case 'delete':
        return onDelete(updatedTask);
      default:
        return () => {};
    }
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const { isHQ } = useContext(HQAndProfitTimeContext);

  const newDndData = () => ({
    vehicleId,
    planStarted,
    setFormProps,
    onTaskChange,
    closeForm,
    isSaving,
    editable,
    planSource:
      location.pathname === oldPathNamePlanTemplate || location.pathname === pathNamePlanTemplate
        ? TEMPLATE_PLAN
        : RECON_PLAN,
    isStandaloneTask: true,
    reconPlanFeatures,
    hideOverflowMenu: isHQ ? true : hideOverflowMenu,
    hideMessageOverflowMenuItem,
    hideLineItemsOverflowMenuItem,
    hidePassthroughOverflowMenuItem,
    hideAssigneeWorkload,
    hidePassthroughIndicator,
    hideTaskProgressInfo,
    showCompletedTag,
    showPendingTag,
    contentType,
    dispatch
  });

  const [dndData, setDndData] = useState(newDndData());

  useEffect(() => {
    const copyOfDndData = clone({ ...dndData });
    const changeInDndData = !isEqual(copyOfDndData, newDndData());
    changeInDndData && setDndData(newDndData);
  }, [reconPlanFeatures]);

  return (
    <OurDragDropContext.Provider value={dndData}>
      <Task
        id={task.id}
        task={task}
        isTask={true}
        isInProgressTaskGroup={isInProgressTaskGroup || task.status === IN_PROGRESS}
        reconPlanFeatures={reconPlanFeatures}
        countMessagesTask={countMessagesTask}
        isShowContentSegmentTDP={isShowContentSegmentTDP}
        showPendingApprovalTime={showPendingApprovalTime}
      />
    </OurDragDropContext.Provider>
  );
};

export default StandaloneTask;
