import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Typography } from 'antd';
import { Stack } from 'components';
import { formatPrice } from 'utils/numberUtils';
import { displayDurationSummary, toMomentDuration } from 'utils/dateTimeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { COMPLETED } from 'app-constants/taskStatusTypes';

const { Text } = Typography;

const VehicleCardReconPlan = ({ isLoading = false, vehicle }) => {
  const { tasks } = vehicle;
  const [status, setStatus] = useState('NEW');
  const timeInReconPlan = displayDurationSummary(
    toMomentDuration({ seconds: vehicle.secondsInReconPlan }),
    false,
    true
  );

  useEffect(() => {
    if (tasks) {
      if (tasks.count === 0) {
        setStatus('NEW');
      } else {
        setStatus('PLAN');
      }
    }
  }, [tasks]);

  const determineTaskCost = (task) => (task.status === COMPLETED ? task.completedCost : 0) || 0;
  const determinePlanCost = (tasks) => tasks?.items.reduce((totalCost, task) => totalCost + determineTaskCost(task), 0);

  const Plan = () => (
    <Stack horizontal>
      <StyledListIcon icon={faListUl} />
      <Stack>
        <Stack horizontal>
          <Text className={'medium-font strong'}>Recon Plan</Text>
        </Stack>
        {/* Passing in null to formatPrice makes it print an emdash character instead of $0 */}
        <Text>{formatPrice(determinePlanCost(tasks) || null)}</Text>
        <Text>Recon Time: {timeInReconPlan}</Text>
      </Stack>
    </Stack>
  );

  if (isLoading) {
    return (
      <div className="center-content">
        <div style={{ width: '200px', margin: '0 18px' }}>
          <Skeleton count={3} />
        </div>
      </div>
    );
  } else {
    return (
      <>
        {
          {
            FAILED: (
              <div className="center-content gray" style={{ marginLeft: '80px' }}>
                Oops, something went wrong
              </div>
            ),
            NEW: <></>,
            PLAN: <Plan />
          }[status]
        }
      </>
    );
  }
};

//#region Styled Components
const StyledListIcon = styled(FontAwesomeIcon)`
  margin: 4px 12px 0 5px;
`;
//#endregion

export default withLDConsumer()(VehicleCardReconPlan);
