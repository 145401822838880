import { stripReferences } from 'utils/arrayUtils';
import { renameKey } from 'utils';
import { isTaskGroupCompleted } from 'components/TaskGroupsDnd/helpers/functions';
import { DECLINED, IN_PROGRESS } from 'app-constants/taskStatusTypes';

export const resequenceTasksAndTaskGroups = (newGroups) => {
  const mutableGroups = stripReferences(newGroups);
  // Resequence groups and tasks
  let numTasks = 0;
  mutableGroups.forEach((g, i) => {
    g.sequence = i + 1;
    g.tasks.forEach((t, j) => {
      t.groupSequence = j + 1;
      t.sequence = ++numTasks;
    });
  });
  return mutableGroups;
};
export const resequenceTasksAndTaskGroupsUS510688 = (newGroups, startSequence) => {
  const mutableGroups = stripReferences(newGroups);
  // Resequence groups and tasks starting at provided index (so no preserved pass through sequences get overwritten)
  mutableGroups.forEach((g, i) => {
    if (g.sequence >= startSequence) {
      g.sequence = i + 1;
      g.tasks.forEach((t, j) => {
        t.groupSequence = j + 1;
        //t.sequence = ++numTasks; No longer set sequence of tasks here, because have to preserve sequence of pass through tasks!
      });
    }
  });
  return mutableGroups;
};
export const convertTaskGroupsFromSpecificToGeneric = (specific) => {
  if (!specific) return [];
  let generic = specific.map((tg) => {
    tg = renameKey(tg, 'reconTasks', 'tasks');
    tg.tasks = tg.tasks.items;
    tg.tasks = tg.tasks.map((t) => {
      t.taskGroupId = tg.id;
      return t;
    });
    return tg;
  });
  return generic;
};
export const resetTaskStatusAfterTaskDeletion = (
  newGroups,
  indexOfGroup,
  updatedGroup,
  deletedTaskWasInProgress,
  flags
) => {
  const mutableGroups = stripReferences(newGroups);
  /**
   * determine if there is an adjustment to the in progress task
   * was the deleted task in progress?
   * if so, is it part of a larger task group?
   * if it is, are there any other incomplete tasks in the task group? do nothing
   * if all other tasks are complete in the task group, then that task group is complete, is there a task group after this one that should be set to in progress
   * if it isn't part of a larger task group with other incomplete tasks, is there a task group after this one that should be set to in progress?
   **/

  //was task deleted part of a larger group? check the updatedGroup to see if there are remaining tasks
  const moreTasksInTaskGroup = updatedGroup?.tasks?.length > 0;

  //if it is a larger task group,
  if (moreTasksInTaskGroup) {
    // then check if task group at large is complete (all tasks complete)
    const isTaskGroupComplete = isTaskGroupCompleted(mutableGroups[indexOfGroup]);

    if (isTaskGroupComplete) {
      // if the task group is complete, then check if it is the last task group in the plan. if it isn't, then update the next one to be in progress
      if (indexOfGroup + 1 !== mutableGroups.length) {
        // checking if the next index after the modified group isn't equal to the length of newGroups checks if there are any task groups after the one that was just modified
        mutableGroups[indexOfGroup + 1].tasks = mutableGroups[indexOfGroup + 1].tasks.map((task) => {
          return {
            ...task,
            status: task.declined || task.status === DECLINED ? DECLINED : IN_PROGRESS
          };
        });
      }
    }
    // else, do nothing - there are other tasks still in progress in this group
  } else {
    // this is for when the task that was deleted was not part of a larger group: moreTasksInTaskGroup === false
    // need to check whether the task that was deleted was in progress prior to deletion - if so, we need to set the next task to being in progress
    if (deletedTaskWasInProgress) {
      //was the deleted task the last task in the plan? if not, commence with the setting of the next task's status to in progress

      /**
       * in the onTaskChangeWithTaskGroups block for type delete, before this function runs, newGroups had the group that the deleted task was a part of spliced out of it,
       * so indexOfGroup is now, actually, the index of the next group
       * to give an example, let's say that the deleted task was the only task in a task group. it was at index 5. once it is deleted, the code in the onTaskChangeWithTaskGroups
       * has already spliced it out to remove it. so the group that is at index 5 now is the one that used to come after the one that was deleted
       * so we can rename indexOfGroup to nextGroupIndex for clarity
       **/
      const nextGroupIndex = indexOfGroup;

      if (nextGroupIndex !== mutableGroups.length) {
        // if this is true, then there is at least one more task group in the plan
        mutableGroups[nextGroupIndex].tasks = mutableGroups[nextGroupIndex].tasks.map((task) => {
          return {
            ...task,
            status: task.declined || task.status === DECLINED ? DECLINED : IN_PROGRESS
          };
        });
      }
    } // else, the deleted the task was later in the plan, it wasn't in progress, so nothing needs to happen
  }
  return mutableGroups;
};
export const prepareTaskGroupsAfterTaskDeletion = (
  setTaskGroups,
  newGroups,
  indexOfGroup,
  updatedGroup,
  deletedTaskWasInProgress,
  flags
) => {
  let mutableTaskGroups = stripReferences(newGroups);
  //reset statuses of tasks
  mutableTaskGroups = resetTaskStatusAfterTaskDeletion(
    mutableTaskGroups,
    indexOfGroup,
    updatedGroup,
    deletedTaskWasInProgress,
    flags
  );
  //resequence tasks after deleted group
  mutableTaskGroups = resequenceTasksAndTaskGroupsUS510688(mutableTaskGroups, updatedGroup.sequence);
  setTaskGroups(mutableTaskGroups);
};
