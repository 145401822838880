/**
 * String constants for tagging analytics events and actions
 * */
export const analyticsTagConstants = {
  // GTM names associated with "Component Viewed" events
  componentViewed: {
    DASHBOARD_INVENTORY_SNAPSHOT_VIEWED: 'Dashboard/Inventory Viewed',
    DASHBOARD_HISTORICAL_PERFORMANCE_VIEWED: 'Dashboard/Historical Viewed',
    INVENTORY_LIST_VIEWED: 'Inventory List Viewed',
    VEHICLE_DETAIL_VIEWED: 'Vehicle Detail Viewed',
    ENTERPRISE_SUMMARY_INVENTORY_SNAPSHOT_VIEWED: 'Inventory/Enterprise Viewed',
    ENTERPRISE_SUMMARY_HISTORICAL_PERFORMANCE_VIEWED: 'Historical/Enterprise Viewed',
    TASK_LIST_VIEWED: 'Task List Viewed',
    TASK_DETAILS_VIEWED: 'Task Detail Viewed',
    GENERAL_SETTINGS_VIEWED: 'General Settings Viewed',
    TASK_TEMPLATES_VIEWED: 'Task Templates Viewed',
    PLAN_TEMPLATES_VIEWED: 'Plan Templates Viewed',
    CONFIGURE_VENDORS_VIEWED: 'External Vendors Viewed',
    CONFIGURE_INTERNAL_GROUPS_VIEWED: 'Internal Groups Viewed',
    NOTIFICATION_SETTINGS_VIEWED: 'Notification Settings Viewed',
    NOTIFICATION_DRAWER_VIEWED: 'Notifications Viewed',
    LINE_ITEM_CATALOG_VIEWED: 'Line Items Viewed'
  },
  filterOpened: {
    DEALERSHIP_FILTER_OPENED: 'Dashboard/Dealership filter Opened',
    TASK_CATEGORY_FILTER_OPENED: 'Dashboard/Task Category filter Open'
  },
  filterSet: {
    DEALERSHIP_FILTER_SET: 'Dashboard/Dealership filter Set',
    TASK_CATEGORY_FILTER_SET: 'Dashboard/Task Category filter Set'
  },
  ALL_VENDORS_LOGICAL_ID: 'VND-ALL',
  ENTITY_TYPE_VENDOR: 'Vendor'
};
