import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tooltip, Dropdown, Icon, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as regularCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { COMPLETED, IN_PROGRESS, PENDING } from 'app-constants/taskStatusTypes';
import { CommonCenteredText } from 'components/styledComponents';
import { MenuItemIcon, StyledMenu } from '../../../TaskGroupsDnd/Task/TaskMenu/OverflowMenu';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { detailContents, apiStatusConstants } from 'app-constants';
import { DEALER_INTERNAL_GROUP, VENDOR } from 'app-constants/groupTypes';
import useVendorRole from 'hooks/useVendorRole';
import { HQAndProfitTimeContext } from 'utils/contexts';
const OverflowMenu = ({
  flags,
  task,
  needApprovalItem,
  hasWorkAnyTask,
  currentMemberData,
  hasWorkOwnTasks,
  user,
  setSelectedCard,
  hasTasksEdit
}) => {
  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'decline':
        setSelectedCard({ contentType: detailContents.DECLINE_TASK, model: task });
        break;
      case 'complete':
        setSelectedCard({ contentType: detailContents.COMPLETE_TASK, model: task });
        break;
      case 'edit':
        setSelectedCard({ contentType: detailContents.EDIT_COMPLETED_TASK, model: task });
        break;
      case 'attach':
        break;
      default:
        devLogger.error(new Error(`Unexpected menu key: ${key}`));
        break;
    }
  };

  const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);
  const { isVendorAdmin, isVendorTech, vendorUserId, isVendor } = useVendorRole(flags.reconVendorManagement);
  const { vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const tasksFetchStatus = useSelector((state) => state.tasks.fetchStatus);
  const userOwnsThisTask = task.assignedTo === user.id || (isVendor && task.assignedTo === vendorUserId);
  const editableStatuses = [IN_PROGRESS];

  let hasWorkPermission = false;
  if (isVendor) {
    if (isVendorAdmin) {
      hasWorkPermission = isVendorAdmin;
    } else if (isVendorTech) {
      hasWorkPermission = hasWorkOwnTasks && userOwnsThisTask;
    }
  } else {
    hasWorkPermission = hasWorkAnyTask || (hasWorkOwnTasks && userOwnsThisTask);
  }

  const isUndeclinedInprogressTask = !task.declined && editableStatuses.includes(task.status);
  const groupLeaderCanCompleteOrDecline =
    currentMemberData && currentMemberData.isGroupLeader && isUndeclinedInprogressTask;

  let canCompleteOrDecline = false;
  if (!isVendorTech) {
    canCompleteOrDecline = (hasWorkPermission && isUndeclinedInprogressTask) || groupLeaderCanCompleteOrDecline;
  }

  // group members cannot decline their group task. leader can
  const isGroupMember = currentMemberData && !currentMemberData.isGroupLeader;
  const canEditCompleted = hasWorkPermission && task.status === COMPLETED;
  const overflowMenuDisabled =
    task.saving || (!canCompleteOrDecline && !canEditCompleted && !isVendor) || (isVendor && task.declined);
  if (canEditCompleted && !hasTasksEdit) return <></>;
  const disableCompleteHasUnassignedGroup =
    (task.assignedToGroupType === DEALER_INTERNAL_GROUP ||
      (isVendor && task.assignedToGroupType === VENDOR && !vendorShipSelected)) &&
    !task?.assignedTo &&
    task?.assignedToGroupId;
  const disableCompleteInvalidData = !task?.assignedTo && !task?.assignedToGroupId;

  const disableComplete =
    !!needApprovalItem || (!needApprovalItem && disableCompleteHasUnassignedGroup) || disableCompleteInvalidData;

  return (
    <>
      <StyledMenuContainer reconProfitTime={hasReconProfitTime || vendorShipSelected}>
        {(hasWorkPermission || groupLeaderCanCompleteOrDecline) && !isHQ && (
          <Dropdown
            disabled={overflowMenuDisabled}
            overlay={
              <StyledMenu onClick={handleMenuClick}>
                {canCompleteOrDecline && (!isGroupMember || (isGroupMember && hasWorkAnyTask)) && (
                  <Menu.Item key="decline">
                    <Icon type="stop" style={{ marginRight: '5px' }} />
                    Decline
                  </Menu.Item>
                )}
                {(canCompleteOrDecline || isVendorTech) && (
                  <Menu.Item key="complete" disabled={disableComplete}>
                    <MenuItemIcon icon={disableComplete ? regularCheckCircle : faCheckCircle} />
                    Complete
                    {!!needApprovalItem && <span className="disabled-extra">{'\nTask has pending line items'}</span>}
                    {!needApprovalItem && disableCompleteHasUnassignedGroup && (
                      <span className="disabled-extra">{'\nTask is not assigned to a group member'}</span>
                    )}
                  </Menu.Item>
                )}
                {canEditCompleted && (
                  <Menu.Item key="edit">
                    <Icon type="edit" style={{ marginRight: '5px' }} />
                    Edit
                  </Menu.Item>
                )}
              </StyledMenu>
            }
          >
            <StyledMoreIcon
              data-disabled={overflowMenuDisabled || tasksFetchStatus === apiStatusConstants.IS_FETCHING}
              icon={faEllipsisH}
            />
          </Dropdown>
        )}
      </StyledMenuContainer>
    </>
  );
};

const TaskOverflowMenu = ({
  flags,
  task,
  needApprovalItem,
  hasWorkAnyTask,
  currentMemberData,
  hasWorkOwnTasks,
  user,
  setSelectedCard,
  hasTasksEdit,
  vendorShipSelected
}) => {
  const { hasReconProfitTime } = useContext(HQAndProfitTimeContext);

  return task.status === PENDING ? (
    <StyledMenuContainer reconProfitTime={hasReconProfitTime || vendorShipSelected}>
      <Tooltip
        placement="top"
        overlayClassName="task-pending-task-card-toggle"
        title={<CommonCenteredText>This action cannot be taken on pending tasks</CommonCenteredText>}
      >
        <StyledMoreIcon data-disabled={true} icon={faEllipsisH} />
      </Tooltip>
    </StyledMenuContainer>
  ) : (
    <OverflowMenu
      task={task}
      flags={flags}
      needApprovalItem={needApprovalItem}
      hasWorkAnyTask={hasWorkAnyTask}
      currentMemberData={currentMemberData}
      hasWorkOwnTasks={hasWorkOwnTasks}
      user={user}
      setSelectedCard={setSelectedCard}
      hasTasksEdit={hasTasksEdit}
    />
  );
};

const StyledMenuContainer = styled.div.attrs({
  className: 'center-content'
})`
  width: ${(props) => (props['reconProfitTime'] ? '30px' : '70px')};
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props['data-disabled'] ? 'inherit' : 'pointer')};
  color: ${(props) => (props['data-disabled'] ? props.theme.colors.gray : props.theme.colors.navy)};
  height: 20px;
`;

export default TaskOverflowMenu;
