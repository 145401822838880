import { Tabs } from 'antd';
import { communicationContexts, documentationContexts } from 'app-constants';
import { FullCommunications, withCommunications } from 'components/communications';
import { VehiclePhotos } from 'components/layout/inventory/vehiclePhotos';
import { CommonStrongText } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vdpActions } from 'store/vdpStore';
import styled from 'styled-components';

import { DocumentUpload, withDocuments } from '../../../documents';
import { VehicleHistory } from '../VehicleHistory';
import { vdpSelector } from '../../../../store/vdpStore';

const { TabPane } = Tabs;

const FullCommunicationsFromVehiclesStore = withCommunications('vehiclesStore')(FullCommunications);
const DocumentsFromDocumentStore = withDocuments(documentationContexts.INVENTORY_PAGE)(DocumentUpload);

const Activity = ({ vehicle, flags, showSeeMoreReply, setShowSeeMoreReply, isSavingNote }) => {
  const { activeTab, comments } = useSelector(vdpSelector);
  const documents = useSelector((state) => state.documents);

  const [paddingBottom, setPaddingBottom] = useState(70);
  const dispatch = useDispatch();
  return (
    <StyledActivityContainer>
      <StyledHeaderLabel recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}>Activity</StyledHeaderLabel>
      <StyledTab
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(activedKey) => {
          dispatch(vdpActions.setActiveTab(activedKey));
        }}
      >
        <TabPane key="1" tab={'Communications'}>
          <StyledCommunicationsList recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}>
            <FullCommunicationsFromVehiclesStore
              containerId={vehicle.id}
              vehicleId={vehicle.id}
              passedInCommunications={comments.items}
              passedInCommunicationsAreSnippetOnly={comments.limit === 2}
              initialFocus={vehicle.id}
              location={communicationContexts.VEHICLE_DETAILS_PAGE}
              showSeeMoreReply={showSeeMoreReply}
              setShowSeeMoreReply={setShowSeeMoreReply}
              isSavingNote={isSavingNote}
            />
          </StyledCommunicationsList>
        </TabPane>
        <TabPane key="2" tab="Documents">
          <StyleDocumentsContainer
            padding-bottom={paddingBottom}
            recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}
          >
            <DocumentsFromDocumentStore
              vehicleId={vehicle.id}
              stockNumber={vehicle.stockNumber}
              documents={documents?.documentsData}
              documentsFetchStatus={documents?.documentsFetchStatus}
              documentsUploadStatus={documents?.documentsUploadStatus}
              documentsDeleteStatus={documents?.documentsDeleteStatus}
              setPaddingBottom={setPaddingBottom}
              flags={flags}
            />
          </StyleDocumentsContainer>
        </TabPane>
        <TabPane key="3" tab="Photos">
          <VehiclePhotos vehicle={vehicle} isSavingNote={isSavingNote} flags={flags} />
        </TabPane>
        <TabPane key="4" tab="History">
          <StyleHistoryContainer recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}>
            <VehicleHistory vehicle={vehicle} documents={documents?.documentsData} />
          </StyleHistoryContainer>
        </TabPane>
      </StyledTab>
    </StyledActivityContainer>
  );
};

//#region Styled Components for Activity

const StyledTab = styled(Tabs)`
  .ant-tabs& {
    .ant-tabs-bar {
      margin: 0;
      border-bottom: initial;

      .ant-tabs-nav-container {
        margin-left: 24px;

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav {
              color: ${({ theme }) => theme.colors.gray};

              .ant-tabs-tab {
                margin-right: 4px;
                padding: 10px 16px 12px 16px;
                :hover {
                  color: ${({ theme }) => theme.colors.navy};
                }
              }

              .ant-tabs-ink-bar {
                background-color: ${({ theme }) => theme.colors.red};
              }

              .ant-tabs-tab-active {
                color: ${({ theme }) => theme.colors.navy};
              }
            }
          }
        }
      }
    }
  }
`;
const StyledHeaderLabel = styled(CommonStrongText)`
  padding: ${(props) => (props['recon-breadcrumb-update-flag'] ? '34px 24px 20px 24px' : '24px 24px 20px')};
  line-height: 10px;
`;

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledActivityContainer = styled(StyledColumnContainer)`
  height: 100%;
`;
const StyledCommunicationsList = styled.div`
  height: calc(100vh - ${(props) => (props['recon-breadcrumb-update-flag'] ? '285px' : '275px')});
  overflow-y: auto;
`;
const StyleHistoryContainer = styled.div`
  height: calc(100vh - ${(props) => (props['recon-breadcrumb-update-flag'] ? '285px' : '275px')});
  padding-top: 24px;
  padding-bottom: 30px;
  overflow-y: auto;
`;
const StyleDocumentsContainer = styled.div`
  height: calc(100vh - ${(props) => (props['recon-breadcrumb-update-flag'] ? '285px' : '275px')});
  padding-top: 20px;
  padding-bottom: ${(props) => props['padding-bottom'] + 'px'};
`;
//#endregion

export default withLDConsumer()(Activity);
