import moment from 'moment';
import { taskProgressLabels, lineItemStatusTypes } from 'app-constants';
import { compareDuration, displayDurationSummary } from 'utils/dateTimeUtils';

export const getTaskProgress = (task) => {
  // check over max
  const taskDuration = moment.duration(task.secondsInTask, 'seconds');
  const maxDuration = moment.duration(task.maxThreshold);
  const pastMax = compareDuration(taskDuration, maxDuration);

  if (pastMax > 0) {
    return { status: taskProgressLabels.Overdue, info: getOverdueInfo(task) };
  }

  // check over goal
  const goalDuration = moment.duration(task.goalThreshold);
  const pastGoal = compareDuration(taskDuration, goalDuration);

  if (pastGoal > 0) {
    return { status: taskProgressLabels.PastGoal, info: getPastGoalInfo(task) };
  }

  return { status: taskProgressLabels.OnTrack };
};

// This assumes that maxThreshold is greater than secondsInTask
const getPastGoalInfo = (task) => {
  const taskDuration = moment.duration(task.secondsInTask, 'seconds');

  // Completed tasks have their times displayed as time past goal time
  if (task.completedOn) {
    const durationPast = taskDuration.subtract(task.goalThreshold);
    return `Past Goal: ${displayDurationSummary(durationPast, false, true)}`;
  }

  // we round up here to the nearest hour to both make time remaining more user friendly and
  //  to make it match up better with the Task Time taken which gets rounded down on display
  const durationRemaining = moment.duration(task.maxThreshold).subtract(taskDuration).add(1, 'hours');

  return `Remaining: ${displayDurationSummary(durationRemaining, false, true)}`;
};

// This assumes that seconds in task is greater than maxThreshold
const getOverdueInfo = (task) => {
  const taskDuration = moment.duration(task.secondsInTask, 'seconds');
  const durationPast = taskDuration.subtract(task.maxThreshold);
  return `Overdue: ${displayDurationSummary(durationPast, false, true)}`;
};

export const getNeedsApprovalEstimateRequest = (lineItemTasks) => {
  let needsApprovalEstimateRequest = [];
  if (lineItemTasks?.items?.length > 0) {
    needsApprovalEstimateRequest = lineItemTasks.items.filter((item) =>
      [lineItemStatusTypes.PENDING].includes(item.status)
    );
  }
  return needsApprovalEstimateRequest.length;
};
