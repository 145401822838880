// Util Libraries
import React, { useEffect, useState, useContext } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Divider, Typography } from 'antd';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
// Utils (local)
import { detailContents, ewsEntityTypes, features, formatVehicleTitle, lineItemStatusTypes } from 'app-constants';
import { VehicleImage } from 'components';
import { CommunicationsSnippet } from 'components/communications';
import { CommonCardContainer } from 'components/styledComponents';
import VehicleInfo from 'components/common/VehicleInfo';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { DEALER_INTERNAL_GROUP } from 'app-constants/groupTypes';
import { TaskInfo, TaskTime } from '.';
import TaskOverflowMenu from './TaskOverflowMenu';
import ProfitTimeMetal from 'components/common/ProfitTimeMetal';
import { HQAndProfitTimeContext } from 'utils/contexts';
// Store
import { authSelector } from 'store/authStore';
import { tasksActions } from 'store/tasksStore';
import { assigneeDataSelector } from 'store/usersStore';
import useVendorRole from 'hooks/useVendorRole';
import { useFeatures } from 'hooks';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { Text } = Typography;

const TaskCard = ({ isLoading = false, task, isSelected, setSelectedCard, flags }) => {
  const dispatch = useDispatch();
  const { vehicle } = task;
  const { profitTime } = task;
  const { user } = useSelector(authSelector);
  const { internalGroups } = useSelector(assigneeDataSelector);
  const { vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const dealer = useSelector((state) => state.dealers.current.data);
  const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);
  // only have currentMemberData when toggle on, is task group and current user is member of the group
  const [currentMemberData, setCurrentMemberData] = useState(null);
  const [hasWorkOwnTasks, hasWorkAnyTask, hasTasksEdit] = useFeatures(
    features.TASKS_WORK_OWN,
    features.TASKS_WORK_ANY,
    features.TASKS_EDIT
  );
  const { isVendor } = useVendorRole(flags.reconVendorManagement);
  const vehicleTitle = formatVehicleTitle(vehicle);
  const screenWidthIsMax1200px = useMediaPredicate('(max-width: 1200px)');
  let needApprovalItem = task.lineItems?.items.find((item) =>
    [lineItemStatusTypes.PENDING, lineItemStatusTypes.ESTIMATE_REQUESTED].includes(item.status)
  );
  const isTaskGroup = task && task.assignedToGroupId && task.assignedToGroupType === DEALER_INTERNAL_GROUP;
  const isEnterpriseOrContainerDealer =
    dealer.entityType === ewsEntityTypes.ENTERPRISE || dealer.entityType === ewsEntityTypes.CONTAINER;
  const showDealerName = isVendor || (isEnterpriseOrContainerDealer && flags.ireconHqView);

  useEffect(() => {
    if (isTaskGroup) {
      const currentGroup = internalGroups && internalGroups.find((group) => group.id === task.assignedToGroupId);
      const currentMember = currentGroup?.internalGroupUsers?.find((member) => member.userId === user.id);
      setCurrentMemberData(currentMember);
    } else {
      setCurrentMemberData(null);
    }
  }, [user, internalGroups, task]);

  const handleClickOpenTDP = (e) => {
    e.preventDefault();
    if (!isLoading) {
      dispatch(tasksActions.getCommentsForTaskEntity(task.vehicle.id, task.id)); // kicking off the fetch here rather than the component inside taskDetails because the component where it was originally dispatching from gets rendered multiple time causing multiple dispatches
      setSelectedCard({ contentType: detailContents.TASK_DETAILS, model: task }); // This will open the popout
    }
  };

  return (
    <CommonCardContainer data-isselected={isSelected}>
      <StyledFlexContainer>
        <VehicleImage
          isLoading={isLoading}
          src={vehicle?.heroImageUrl}
          nonMarketingSrc={vehicle?.nonMarketingHeroImageUrl}
          fallbackSrc={vehicle?.appraisalHeroImageUrl}
          isHeroOrAppraisal
          imageQualityWidth={490} // This determines the image quality we pull back from the image api, setting it to twice the size of the box
          imageQualityHeight={showDealerName ? 370 : hasReconProfitTime ? 358 : 320}
          imageStyle={{ width: '245px', height: showDealerName ? '185px' : hasReconProfitTime ? '179px' : '160px' }}
          noImageStyle={{
            width: '245px',
            minWidth: '245px',
            height: isLoading ? '160px' : showDealerName ? '185px' : hasReconProfitTime ? '179px' : '160px'
          }}
        />
        <StyledCardInfoContainer>
          <StyledDescriptionAndMenuContainer
            showDealerName={showDealerName}
            reconProfitTime={hasReconProfitTime}
            isLoading={isLoading}
          >
            <StyledDescriptionContainer>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: hasReconProfitTime ? 'space-between' : '',
                    marginRight: hasReconProfitTime ? 15 : 0
                  }}
                >
                  <div style={{ width: '100%', maxWidth: '460px', margin: '0 25px' }}>
                    <Skeleton count={2} width="100%" />
                  </div>
                  {hasReconProfitTime && !isHQ && (
                    <div>
                      <StyledDisabledIcon icon={faEllipsisH} />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <StyledVehicleTitleContainer reconProfitTime={hasReconProfitTime}>
                    <StyledVehicleTitle
                      reconUpdatedListViews={flags?.reconUpdatedListViews}
                      onClick={flags?.reconUpdatedListViews ? handleClickOpenTDP : () => {}}
                    >
                      {vehicleTitle}
                    </StyledVehicleTitle>
                    {hasReconProfitTime && (
                      <TaskOverflowMenu
                        task={task}
                        flags={flags}
                        needApprovalItem={needApprovalItem}
                        hasWorkAnyTask={hasWorkAnyTask}
                        currentMemberData={currentMemberData}
                        hasWorkOwnTasks={hasWorkOwnTasks}
                        user={user}
                        setSelectedCard={setSelectedCard}
                        hasTasksEdit={hasTasksEdit}
                      />
                    )}
                  </StyledVehicleTitleContainer>
                  {hasReconProfitTime ? (
                    <StyledInfoProfitTimeContainer>
                      <div style={{ paddingTop: 8 }}>
                        <VehicleInfo vehicle={vehicle} showDealerName={showDealerName} isForTLP={true} />
                      </div>
                      <ProfitTimeMetal profitTimeScore={profitTime?.calculationResult} />
                    </StyledInfoProfitTimeContainer>
                  ) : (
                    <VehicleInfo vehicle={vehicle} showDealerName={showDealerName} isForTLP={true} />
                  )}
                </>
              )}
            </StyledDescriptionContainer>
            {!hasReconProfitTime && (
              <TaskOverflowMenu
                task={task}
                flags={flags}
                needApprovalItem={needApprovalItem}
                hasWorkAnyTask={hasWorkAnyTask}
                currentMemberData={currentMemberData}
                hasWorkOwnTasks={hasWorkOwnTasks}
                user={user}
                setSelectedCard={setSelectedCard}
                hasTasksEdit={hasTasksEdit}
                vendorShipSelected={vendorShipSelected}
              />
            )}
          </StyledDescriptionAndMenuContainer>
          <StyledTaskInfoAndTimeContainer
            isLoading={isLoading}
            reconUpdatedListViews={flags?.reconUpdatedListViews}
            onClick={flags?.reconUpdatedListViews ? () => {} : handleClickOpenTDP}
          >
            <StyledCurrentTaskContainer>
              {isLoading ? (
                <div style={{ width: screenWidthIsMax1200px ? '260px' : '300px', margin: '0 25px' }}>
                  <Skeleton count={3} />
                </div>
              ) : (
                <TaskInfo task={task} screenWidthIsMax1200px={screenWidthIsMax1200px} />
              )}
            </StyledCurrentTaskContainer>
            <Divider type="vertical" style={{ height: '68px' }} />
            {isLoading ? (
              <div style={{ width: '200px', margin: '0 18px' }}>
                <Skeleton count={3} />
              </div>
            ) : (
              <TaskTime isLoading={isLoading} task={task} flags={flags} />
            )}
          </StyledTaskInfoAndTimeContainer>
        </StyledCardInfoContainer>
        <StyledCommunicationsContainer>
          <CommunicationsSnippet
            isLoading={isLoading}
            vehicle={vehicle}
            task={task}
            isSelected={isSelected}
            setSelectedCard={setSelectedCard}
          />
        </StyledCommunicationsContainer>
      </StyledFlexContainer>
    </CommonCardContainer>
  );
};

//#region StyledComponents
const StyledFlexContainer = styled.div`
  display: flex;
`;
const StyledCardInfoContainer = styled.div`
  min-width: 550px;
  flex: 2 2 0px;
  display: flex;
  flex-direction: column;
  border-width: 0 1px 0 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borderGray};
`;
const StyledDescriptionAndMenuContainer = styled.div`
  display: flex;
  height: ${(props) =>
    props['isLoading'] ? '75px' : props['showDealerName'] ? '100px' : props['reconProfitTime'] ? '94px' : '75px'};
  border-width: 0 0 0.5px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borderGray};
`;
const StyledDescriptionContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
`;
const StyledTaskInfoAndTimeContainer = styled.div`
  padding: 9px 15px 8px 0px;
  display: flex;
  flex: 1 1 0px;
  :hover {
    cursor: ${(props) => (!props['isLoading'] && !props['reconUpdatedListViews'] ? 'pointer' : 'inherit')};
    background-color: ${(props) =>
      !props['isLoading'] && !props['reconUpdatedListViews'] ? props.theme.colors.backgroundGray : 'inherit'};
  }
`;
const StyledCurrentTaskContainer = styled.div`
  width: auto;
`;
const StyledCommunicationsContainer = styled.div`
  flex: 1 1 0px;
  min-width: 300px;
  margin: 18px 16px 7px 16px;
`;
const StyledVehicleTitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 12px 15px ${(props) => (props['reconProfitTime'] ? '0px' : '2px')} 15px;
  ${(props) => (props['reconProfitTime'] ? 'justify-content: space-between;' : '')}
`;
const StyledVehicleTitle = styled(({ reconUpdatedListViews, ...rest }) => <Text {...rest} />).attrs({
  ellipsis: true,
  className: 'large-font'
})`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  cursor: ${({ reconUpdatedListViews }) => (reconUpdatedListViews ? 'pointer' : 'inherit')};
  color: ${({ reconUpdatedListViews, theme }) => reconUpdatedListViews && theme.colors.infoPrimary} !important;
  &:hover {
    text-decoration: ${({ reconUpdatedListViews }) => (reconUpdatedListViews ? 'underline' : 'inherit')};
  }
`;
const StyledDisabledIcon = styled(FontAwesomeIcon)`
  cursor: inherit;
  color: ${({ theme }) => theme.colors.lightGray};
  height: 20px;
`;
const StyledInfoProfitTimeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`;
//#endregion

export default withLDConsumer()(TaskCard);
