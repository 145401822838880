import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

//our components, helpers
import { DROP_PLACEHOLDER, MAIN, DRAGGABLE_ITEM, TASK_GROUP } from './helpers/types';
import { setPerformDropOperationsToggle } from './helpers/dndFunctions';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { IN_PROGRESS } from 'app-constants/taskStatusTypes';

const DropPlaceholder = ({
  index,
  moveItem,
  state,
  taskId,
  parentGroupId,
  lastTaskGroupDropPlaceholder,
  id,
  draggedItemId,
  draggingOverTaskGroupPropFromTaskGroup,
  flags
}) => {
  const canDrop = (draggedItem) => {
    if (!draggedItem) return false;
    if (draggedItem.parentGroupId === parentGroupId) {
      return !(index === draggedItem.index + 1 || index === draggedItem.index - 1);
    } else if (draggedItem.modelType === TASK_GROUP && parentGroupId !== MAIN) {
      // can't drag a taskGroup into another taskGroup
      return false;
    }
    return true;
  };

  const [{ isOver }, drop] = useDrop({
    accept: DRAGGABLE_ITEM,
    canDrop: (e, monitor) => canDrop(e),
    drop: (e, monitor) => {
      const droppedOnItemData = { index, modelType: DROP_PLACEHOLDER, parentGroupId, id };
      const { type, ...draggedItemData } = e; // stripping out type bc we don't need it
      moveItem(draggedItemData, droppedOnItemData);
      // the drop placeholder or task group task's drop event runs prior to the task group task's drop event
      // to prevent propagation of the drop event to a task group, once the task group task's drop event has ran and the taskGroups have been updated to the correct order of tasks, set the toggle for allowing drop operations to be false
      setPerformDropOperationsToggle(false);
    },
    collect: (monitor) => ({
      isOver: canDrop(monitor.getItem()) && !!monitor.isOver()
    })
  });
  return (
    <StyledDropPlaceholder
      state={state}
      ref={drop}
      parentGroupId={parentGroupId}
      associatedTaskIsDragging={taskId === draggedItemId}
      lastTaskGroupDropPlaceholder={lastTaskGroupDropPlaceholder}
      draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupPropFromTaskGroup}
      isOver={isOver}
      reconTaskCollapseFlag={flags.reconTaskCollapse}
    >
      <div />
    </StyledDropPlaceholder>
  );
};

DropPlaceholder.propTypes = {
  index: PropTypes.number,
  moveItem: PropTypes.func,
  parentGroupId: PropTypes.string,
  state: PropTypes.string,
  lastTaskGroupDropPlaceholder: PropTypes.bool
};

const dropPlaceholderStateToColorMapping = {
  IN_PROGRESS: 'navy',
  'Past Goal': 'orange',
  Overdue: 'red',
  DECLINED: 'red',
  'no styling state': 'transparent' // this includes PENDING and COMPLETED states
};

const stylingTaskColorMapping = (status) => {
  if (status === IN_PROGRESS) {
    return 'navy';
  } else {
    return 'transparent';
  }
};

const StyledDropPlaceholder = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ isOver }) => (isOver ? '112px' : '8px')};
  max-width: 354px;
  transition: min-height 0.3s;
  position: ${({ parentGroupId, lastTaskGroupDropPlaceholder }) =>
    (parentGroupId === MAIN || lastTaskGroupDropPlaceholder) && 'relative'};
  padding: ${({ parentGroupId, lastTaskGroupDropPlaceholder }) => {
    if (parentGroupId === MAIN) return '6px 0';
    if (lastTaskGroupDropPlaceholder) return '6px 0 0';
  }};
  margin-top: ${({ parentGroupId, lastTaskGroupDropPlaceholder }) =>
    (parentGroupId === MAIN || lastTaskGroupDropPlaceholder) && '-6px'};
  margin-bottom: ${({ parentGroupId }) => parentGroupId === MAIN && '-6px'};
  z-index: ${({ parentGroupId, lastTaskGroupDropPlaceholder }) =>
    (parentGroupId === MAIN || lastTaskGroupDropPlaceholder) && 100};
  > div {
    background-color: ${({
      state,
      associatedTaskIsDragging,
      theme,
      isOver,
      draggingOverTaskGroupPropFromTaskGroup,
      reconTaskCollapseFlag
    }) => {
      const colorMapping = reconTaskCollapseFlag
        ? stylingTaskColorMapping(state)
        : dropPlaceholderStateToColorMapping[state];
      return !draggingOverTaskGroupPropFromTaskGroup && !associatedTaskIsDragging && !isOver && state
        ? theme.colors[colorMapping]
        : theme.colors.transparent;
    }};
    height: ${({ lastTaskGroupDropPlaceholder }) => (lastTaskGroupDropPlaceholder ? '14px' : '8px')};
    width: 8px;
    transition: background-color 0s ${({ isOver, associatedTaskIsDragging, draggingOverTaskGroupPropFromTaskGroup }) =>
      isOver || associatedTaskIsDragging || draggingOverTaskGroupPropFromTaskGroup ? '0s' : '0.3s'};
    border-radius: ${({ lastTaskGroupDropPlaceholder }) => (lastTaskGroupDropPlaceholder ? '0 0 0 4px' : '0')};
  }
  &:last-child {
    ${({ isOver, lastTaskGroupDropPlaceholder }) => {
      const lastChildMinHeight = !lastTaskGroupDropPlaceholder ? '50px' : '8px';
      return `min-height: ${isOver ? '100px' : lastChildMinHeight};`;
    }}
`;

export default withLDConsumer()(DropPlaceholder);
