import { SkeletonRowContainer, IncreasedIcon, DecreasedIcon } from '../styles';
import Skeleton from 'react-loading-skeleton';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { calculateDaysAndHoursFromDurationDecimal } from 'utils';
import React from 'react';
import uuidv4 from 'uuid/v4';
import { round } from '../../../../../../utils/numberUtils';

const noUnsortedDirections = ['ascend', 'descend', 'ascend'];

const SkeletonEnterpriseTable = () => {
  return (
    <>
      {Array.from(Array(11)).map((_, i) => (
        <SkeletonRowContainer key={uuidv4()}>
          <Skeleton width="100%" height="32px" />
        </SkeletonRowContainer>
      ))}
    </>
  );
};

// private constants
const defaultEnterpriseSorter = (a, b) => {
  if (a == null && b == null) {
    return 0;
  }
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }

  // assumption : if one value is a string, so is the other
  if (typeof a == 'string') {
    return a.localeCompare(b, undefined, { sensitivity: 'base' });
  }

  // these are numbers or dates (which are internally just numbers)
  return round(a) - round(b);
};

const dataEnterpriseTimeFormatFields = (data, reconDealerFilterFlag = false) => {
  const parsedInformation = (data && moment.duration(data)) || (!reconDealerFilterFlag && moment.duration('P0D'));
  const duration = calculateDaysAndHoursFromDurationDecimal(
    parsedInformation,
    'shortHandFormat',
    false,
    reconDealerFilterFlag
  );

  return {
    sortValue: duration.durationDecimalMagnitude,
    displayValue: duration.durationFormattedString
  };
};

const getEnterpriseIcon = (value = 0, prevValue = 0) => {
  if (round(value) === round(prevValue)) {
    return <></>;
  }
  return value > prevValue ? <IncreasedIcon icon={faCaretUp} /> : <DecreasedIcon icon={faCaretDown} />;
};

const formatEnterpriseSpendFields = (currencyData) => {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(currencyData);

  return {
    sortValue: currencyData,
    displayValue: formattedCurrency
  };
};

export {
  SkeletonEnterpriseTable,
  defaultEnterpriseSorter,
  dataEnterpriseTimeFormatFields,
  getEnterpriseIcon,
  formatEnterpriseSpendFields,
  noUnsortedDirections
};
