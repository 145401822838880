import { useLocation } from '@reach/router';
import React from 'react';
import { taskProgressLabels } from 'app-constants';
import { getTaskProgress } from 'components/layout/tasks/common/taskUtils';
import { TaskPastDueIcon, TaskPastGoalIcon, TaskDeclinedIcon, TaskClipboardIcon, TaskCompletedIcon } from '.';
import { PENDING, DECLINED } from 'app-constants/taskStatusTypes';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { oldPathNamePlanTemplate, pathNamePlanTemplate } from 'app-constants/planTemplatePath';

// Choose which icon to display based on the state of the task
const CustomizedTaskIcon = ({ task = {}, isShowCompletedIcon, isShowTaskClipboardIcon, flags, ...other }) => {
  // Completed and Declined task icons take first priority
  const location = useLocation();
  if (task.completedOn) {
    return <TaskCompletedIcon isShowCompletedIcon={isShowCompletedIcon} task={task} {...other} />;
  }
  if (task.declined || task.status === DECLINED || task.declinedOn) {
    return <TaskDeclinedIcon task={task} {...other} />;
  }

  const progress = getTaskProgress(task);
  // Want to show regular clipboard icon for pending tasks, even if they're overdue or past goal
  if (task.status === PENDING && isShowTaskClipboardIcon) {
    // Short circuit here, pass in progress so correct hover info can be displayed
    return <TaskClipboardIcon task={task} progress={progress} {...other} />;
  }

  if (
    progress.status === taskProgressLabels.OnTrack &&
    (location.pathname === oldPathNamePlanTemplate || location.pathname === pathNamePlanTemplate)
  ) {
    return <TaskClipboardIcon task={task} progress={progress} {...other} showClipBoarIconPlanTemplate={true} />;
  }

  switch (progress.status) {
    case taskProgressLabels.OnTrack:
      return <TaskClipboardIcon task={task} {...other} />;
    case taskProgressLabels.PastGoal:
      return <TaskPastGoalIcon task={task} progress={progress} {...other} />;
    case taskProgressLabels.Overdue:
      return <TaskPastDueIcon task={task} progress={progress} {...other} />;
    default:
      devLogger.error(new Error(`Unexpected progress status: ${progress}`));
      break;
  }
};

export default withLDConsumer()(CustomizedTaskIcon);
