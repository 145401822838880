import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vdpActions, getExpandItemsSelector } from 'store/vdpStore';
import { CommonLinkButton } from 'components/styledComponents';
import styled from 'styled-components';

const ExpandCollapseLinkButton = ({ taskGroups, reconTaskCollapseFlag }) => {
  const expandItems = useSelector(getExpandItemsSelector());
  const [expandAll, setExpandAll] = useState(false);
  const dispatch = useDispatch();

  const getTaskByTaskGroups = (taskGroups) => {
    let listTasks = [];
    taskGroups.forEach((template) => {
      listTasks = listTasks.concat(template.tasks);
    });
    return listTasks;
  };

  useEffect(() => {
    if (expandItems.length > 0 && expandItems.length === getTaskByTaskGroups(taskGroups).length) {
      setExpandAll(true);
    }

    if (expandItems.length === 0) {
      setExpandAll(false);
    }
  }, [expandItems]);

  useEffect(() => {
    if (taskGroups.length === 0) dispatch(vdpActions.setRemoveExpandItem([]));
  }, [taskGroups]);

  const toggleExpandCollapse = () => {
    const tasks = getTaskByTaskGroups(taskGroups).map((task) => task.id);
    if (expandAll) {
      dispatch(vdpActions.setRemoveExpandItem(tasks));
    } else {
      dispatch(vdpActions.setExpandItem(tasks));
    }
    setExpandAll(!expandAll);
  };

  return (
    <StyledExspanCollapseContainer>
      {reconTaskCollapseFlag && getTaskByTaskGroups(taskGroups).length > 0 ? (
        <CommonLinkButton toggle-expand-collapse="true" onClick={toggleExpandCollapse}>
          {expandAll ? 'Collapse All' : 'Expand All'}
        </CommonLinkButton>
      ) : (
        <></>
      )}
    </StyledExspanCollapseContainer>
  );
};

const StyledExspanCollapseContainer = styled.div``;

export default ExpandCollapseLinkButton;
