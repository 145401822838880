import 'antd4/lib/table/style/index.css';
import React from 'react';
import { apiStatusConstants, specialColumns } from 'app-constants';
import { StyledCell, StyledCellPrev, StyledDealerName, StyledEnterpriseTable } from '../common/styles';
import {
  dataEnterpriseTimeFormatFields,
  defaultEnterpriseSorter,
  formatEnterpriseSpendFields,
  getEnterpriseIcon,
  noUnsortedDirections,
  SkeletonEnterpriseTable
} from '../common/functions';
import { Error } from '../../../../common';
import { navigate } from '@reach/router';
import { getStatisticLink, getTaskCategoriesParamsFromExcludedIds } from '../../commonFunctions';
import PreviousDateRangeTooltip from '../common/components/PreviousDateRangeTooltip';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

const SummaryTable = ({
  data = [],
  fetchStatus,
  refreshAction,
  filters,
  onChange,
  sort,
  flags,
  useLocalStorageObject
}) => {
  const reconDealerFilterFlag = flags?.reconDealerFilter;
  const [, , getData] = useLocalStorageObject;

  const taskCategoriesData = useSelector((state) => state.taskCategories.categoriesAll);

  const getExcludedTaskCategoryIds = () => {
    const filterType = 'historicalPerformanceFilters';
    const dashboardPreference = getData();
    return dashboardPreference?.[filterType]?.excludedTaskCategories || [];
  };

  if (fetchStatus === apiStatusConstants.FAILED) {
    return <Error refreshAction={refreshAction} />;
  }
  if (fetchStatus === apiStatusConstants.IS_FETCHING || fetchStatus === apiStatusConstants.PENDING) {
    return <SkeletonEnterpriseTable />;
  }

  const onStatisticClick = (title, record, isPrev = false) => {
    const extraFilters = specialColumns.includes(title)
      ? []
      : getTaskCategoriesParamsFromExcludedIds(getExcludedTaskCategoryIds(), taskCategoriesData.dict);
    extraFilters.push(`entitiesFilter=${record.dealerName}`);
    const link = getStatisticLink(
      false,
      title,
      '',
      filters,
      extraFilters,
      false,
      false,
      isPrev,
      flags.reconHiddenFilter
    );
    navigate(link);
  };

  return (
    <StyledEnterpriseTable
      rowKey="rowKey"
      pagination={false}
      dataSource={getDataSource(data, flags?.reconExcludeTaskCategories, reconDealerFilterFlag)}
      columns={getColumnDefinitions(onStatisticClick, sort, filters)}
      showSorterTooltip={false}
      onChange={onChange}
    />
  );
};
export default withLDConsumer()(SummaryTable);

/* ~~~~~~~~~~~~~~~~~~~~~ private ~~~~~~~~~~~~~~~~~~~~~ */

const getColumnDefinitions = (onStatisticClick, sort, filters) => {
  const columns = [
    {
      title: 'Dealership',
      dataIndex: 'dealerName',
      fixed: 'left',
      sortDirections: noUnsortedDirections,
      sorter: (a, b) => defaultEnterpriseSorter(a.dealerName, b.dealerName),
      render: (text) => <StyledDealerName>{text}</StyledDealerName>
    },
    {
      title: 'Vehicles Completed Recon',
      className: 'border-left',
      children: [
        {
          title: 'Current',
          className: 'border-left',
          dataIndex: 'vehiclesCompletedRecon',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.vehiclesCompletedRecon, b.vehiclesCompletedRecon),
          render: (text, record) => (
            <StyledCell onClick={() => onStatisticClick('Vehicles Completed Recon', record)}>{text}</StyledCell>
          )
        },
        {
          title: 'Prev',
          dataIndex: 'prevVehiclesCompletedRecon',
          className: 'small-right-padding',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.prevVehiclesCompletedRecon, b.prevVehiclesCompletedRecon),
          render: (text, record) => (
            <PreviousDateRangeTooltip startDate={filters.startTime} endDate={filters.endTime}>
              <StyledCellPrev onClick={() => onStatisticClick('Vehicles Completed Recon', record, true)}>
                {text}
              </StyledCellPrev>
            </PreviousDateRangeTooltip>
          )
        }
      ]
    },
    {
      title: 'Avg Time in Recon',
      className: 'border-left',
      children: [
        {
          title: 'Current',
          className: 'border-left',
          dataIndex: 'averageTimeInRecon',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.averageTimeInReconSort, b.averageTimeInReconSort),
          render: (text, record) => (
            <StyledCell onClick={() => onStatisticClick('Avg Time in Recon', record)}>
              {getEnterpriseIcon(record.averageTimeInReconSort, record.prevAverageTimeInReconSort)}
              {text}
            </StyledCell>
          )
        },
        {
          title: 'Prev',
          dataIndex: 'prevAverageTimeInRecon',
          className: 'small-right-padding',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.prevAverageTimeInReconSort, b.prevAverageTimeInReconSort),
          render: (text, record) => (
            <PreviousDateRangeTooltip startDate={filters.startTime} endDate={filters.endTime}>
              <StyledCellPrev onClick={() => onStatisticClick('Avg Time in Recon', record, true)}>
                {text}
              </StyledCellPrev>
            </PreviousDateRangeTooltip>
          )
        }
      ]
    },
    {
      title: 'Avg Task Approval Time',
      className: 'border-left',
      children: [
        {
          title: 'Current',
          className: 'border-left',
          dataIndex: 'averageTimeInApproval',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.averageTimeInApprovalSort, b.averageTimeInApprovalSort),
          render: (text, record) => (
            <StyledCell onClick={() => onStatisticClick('Avg Task Approval Time', record)}>
              {getEnterpriseIcon(record.averageTimeInApprovalSort, record.prevAverageTimeInApprovalSort)}
              {text}
            </StyledCell>
          )
        },
        {
          title: 'Prev',
          dataIndex: 'prevAverageTimeInApproval',
          className: 'small-right-padding',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.prevAverageTimeInApprovalSort, b.prevAverageTimeInApprovalSort),
          render: (text, record) => (
            <PreviousDateRangeTooltip startDate={filters.startTime} endDate={filters.endTime}>
              <StyledCellPrev onClick={() => onStatisticClick('Avg Task Approval Time', record, true)}>
                {text}
              </StyledCellPrev>
            </PreviousDateRangeTooltip>
          )
        }
      ]
    },
    {
      title: 'Avg Recon Spend',
      className: 'border-left',
      children: [
        {
          title: 'Current',
          className: 'border-left',
          dataIndex: 'averageReconSpend',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.averageReconSpendSort, b.averageReconSpendSort),
          render: (text, record) => (
            <StyledCell onClick={() => onStatisticClick('Avg Recon Spend', record)}>
              {getEnterpriseIcon(record.averageReconSpendSort, record.prevAverageReconSpendSort)}
              {text}
            </StyledCell>
          )
        },
        {
          title: 'Prev',
          dataIndex: 'prevAverageReconSpend',
          className: 'small-right-padding',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.prevAverageReconSpendSort, b.prevAverageReconSpendSort),
          render: (text, record) => (
            <PreviousDateRangeTooltip startDate={filters.startTime} endDate={filters.endTime}>
              <StyledCellPrev onClick={() => onStatisticClick('Avg Recon pend', record, true)}>{text}</StyledCellPrev>
            </PreviousDateRangeTooltip>
          )
        }
      ]
    },
    {
      title: 'Avg Time Before Plan',
      className: 'border-left',
      children: [
        {
          title: 'Current',
          className: 'border-left',
          dataIndex: 'averageTimeToPlanCreation',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) => defaultEnterpriseSorter(a.averageTimeToPlanCreationSort, b.averageTimeToPlanCreationSort),
          render: (text, record) => (
            <StyledCell onClick={() => onStatisticClick('Avg Time Before Plan', record)}>
              {getEnterpriseIcon(record.averageTimeToPlanCreationSort, record.prevAverageTimeToPlanCreationSort)}
              {text}
            </StyledCell>
          )
        },
        {
          title: 'Prev',
          dataIndex: 'prevAverageTimeToPlanCreation',
          className: 'small-right-padding',
          sortDirections: noUnsortedDirections,
          sorter: (a, b) =>
            defaultEnterpriseSorter(a.prevAverageTimeToPlanCreationSort, b.prevAverageTimeToPlanCreationSort),
          render: (text, record) => (
            <PreviousDateRangeTooltip startDate={filters.startTime} endDate={filters.endTime}>
              <StyledCellPrev onClick={() => onStatisticClick('Avg Time Before Plan', record, true)}>
                {text}
              </StyledCellPrev>
            </PreviousDateRangeTooltip>
          )
        }
      ]
    }
  ];
  columns.forEach((c) => {
    if (c.dataIndex === sort.field) {
      c.defaultSortOrder = sort.order;
    }
  });
  return columns;
};

const getDataSource = (dataList, reconExcludeTaskCategories, reconDealerFilterFlag) => {
  const dataField = reconExcludeTaskCategories ? 'currentPeriodExcludedCategoryMetrics' : 'currentPeriodMetrics';
  const previousDataField = reconExcludeTaskCategories
    ? 'previousPeriodExcludedCategoryMetrics'
    : 'previousPeriodMetrics';

  return (dataList || []).map((element) => {
    const averageTimeInRecon = dataEnterpriseTimeFormatFields(
      element[dataField].averageTimeInRecon,
      reconDealerFilterFlag
    );
    const prevAverageTimeInRecon = dataEnterpriseTimeFormatFields(
      element[previousDataField].averageTimeInRecon,
      reconDealerFilterFlag
    );

    const averageTimeInApproval = dataEnterpriseTimeFormatFields(
      element[dataField].averageTimeInApproval,
      reconDealerFilterFlag
    );
    const prevAverageTimeInApproval = dataEnterpriseTimeFormatFields(
      element[previousDataField].averageTimeInApproval,
      reconDealerFilterFlag
    );

    const averageReconSpend = formatEnterpriseSpendFields(element[dataField].averageReconSpend);
    const prevAverageReconSpend = formatEnterpriseSpendFields(element[previousDataField].averageReconSpend);

    const averageTimeToPlanCreation = dataEnterpriseTimeFormatFields(
      element[dataField].averageTimeToPlanCreation,
      reconDealerFilterFlag
    );
    const prevAverageTimeToPlanCreation = dataEnterpriseTimeFormatFields(
      element[previousDataField].averageTimeToPlanCreation,
      reconDealerFilterFlag
    );

    return {
      rowKey: element.dealerId,
      dealerName: element.dealerName,

      vehiclesCompletedRecon: element.currentPeriodMetrics.totalVehicles,
      prevVehiclesCompletedRecon: element.previousPeriodMetrics.totalVehicles,

      averageTimeInRecon: averageTimeInRecon.displayValue,
      averageTimeInReconSort: averageTimeInRecon.sortValue,

      prevAverageTimeInRecon: prevAverageTimeInRecon.displayValue,
      prevAverageTimeInReconSort: prevAverageTimeInRecon.sortValue,

      averageTimeInApproval: averageTimeInApproval.displayValue,
      averageTimeInApprovalSort: averageTimeInApproval.sortValue,

      prevAverageTimeInApproval: prevAverageTimeInApproval.displayValue,
      prevAverageTimeInApprovalSort: prevAverageTimeInApproval.sortValue,

      averageReconSpend: averageReconSpend.displayValue,
      averageReconSpendSort: averageReconSpend.sortValue,

      prevAverageReconSpend: prevAverageReconSpend.displayValue,
      prevAverageReconSpendSort: prevAverageReconSpend.sortValue,

      averageTimeToPlanCreation: averageTimeToPlanCreation.displayValue,
      averageTimeToPlanCreationSort: averageTimeToPlanCreation.sortValue,

      prevAverageTimeToPlanCreation: prevAverageTimeToPlanCreation.displayValue,
      prevAverageTimeToPlanCreationSort: prevAverageTimeToPlanCreation.sortValue
    };
  });
};
