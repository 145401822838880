import { useEffect, useState } from 'react';

const userAgentRegex = /Android|webOS|iPhone|iPad|iPod|Blackberry|IEMobile|Opera Mini|Windows Phone/i;
const iOsRegex = /iPhone|iPad|iPod/i;

export const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator ? navigator.userAgent : '';

    if (userAgentRegex.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return isMobile;
};

export const useDeviceOSDetect = () => {
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const userAgent = navigator ? navigator.userAgent : '';

    if (iOsRegex.test(userAgent)) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }
  }, []);

  return isIos;
};
