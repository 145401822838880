import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { CircularSpinner } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { elementRotate } from 'styles/mixins';
import { theme as themeStyles } from 'styles/appTheme';

const BorderlessSelect = ({ menu, dropdownIsOpen, setDropdownIsOpen, label, selectedLabel, loading = false }) => {
  return (
    <StyledDropdown
      trigger={['click']}
      overlay={menu}
      visible={dropdownIsOpen}
      onVisibleChange={setDropdownIsOpen}
      loading={loading}
    >
      <StyledSortSelect dropdownIsOpen={dropdownIsOpen}>
        <strong>{label}</strong>
        <span>{selectedLabel}</span>
        {loading ? (
          <CircularSpinner size={13} additionalStyles={{ color: themeStyles.colors.iconGray, marginLeft: '15px' }} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </StyledSortSelect>
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  cursor: ${(props) => (props.loading ? 'default' : 'pointer')};
`;
const StyledSortSelect = styled.div`
  display: flex;
  align-items: center;

  strong:first-child {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-right: 8px;
  }

  ${elementRotate('fa-chevron-down', 'dropdownIsOpen', 180, 0.3)}

  .fa-chevron-down {
    margin-left: 15px;
    color: ${(props) => (props['dropdownIsOpen'] ? props.theme.colors.navy : props.theme.colors.darkGray)};
    height: 12px;
    width: 12px;
  }

  :hover {
    .fa-chevron-down {
      color: ${({ theme }) => theme.colors.navy};
    }
  }
`;

export default BorderlessSelect;
