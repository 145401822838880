import { regExp } from '../app-constants/regExp';

export const truncateString = (string, characterLimit) =>
  string?.length > characterLimit ? `${string.substr(0, characterLimit)}...` : string;

export const determineCharLimit = (screenSize, xlCharLimit, lgCharLimit, mdCharLimit, smCharLimit, xsCharLimit) => {
  switch (screenSize) {
    case '> 1600px':
      return xlCharLimit;
    case '<= 1600px':
    case '<= 1500px':
      return lgCharLimit;
    case '<= 1400px':
    case '<= 1300px':
      return mdCharLimit;
    case '<= 1150px':
    case '<= 1050px':
      return smCharLimit;
    case '<= 960px':
    case '<= 850px':
      return xsCharLimit;
    default:
      return 0;
  }
};

export const capitalizeFirstLetterOfString = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const capitalizeWordsOfString = (string) => string.split(' ').map(capitalizeFirstLetterOfString).join(' ');
export const formatPhoneNumber = (string) => {
  return string.replace(regExp.NOT_NUMBERS_OR_HYPHEN, '').replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3');
};

export const isVendorIdValid = (vendorId) =>
  !!vendorId && typeof vendorId == 'string' && vendorId !== 'null' && vendorId !== 'undefined';
