import { v4 as uuidv4 } from 'uuid';
import { takeLatest, put, takeEvery, select, all, fork, join } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { METRICS } from 'store/metricsStore';
import { messagesActions } from 'store/messagesStore';
import { CURRENT_DEALER } from 'store/dealersStore';
import { apiStatusConstants, lineItemStatusTypes, IS_NEW_SIGNIN, VENDOR_USER_INFO } from 'app-constants';
import { createRequestTypes, makeActionCreator } from 'utils';
import { updateObjectInArray } from 'utils/arrayUtils';
import { getWithToken, postWithToken, putWithToken, deleteWithToken, patchWithToken } from 'api';
import { OPERATION_LINE_ITEM } from 'app-constants/lineItemConstants';
import taskTypesMockData from '../assets/mock_data/taskTypesMockData';
import { rootEntitySwitcherSelector } from './dealersStore';

//#region Actions
export const TASK_TYPES = createRequestTypes('TASK_TYPES', [
  'GET_DATA',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'ADD',
  'UPDATE',
  'DELETE',
  'BULK_UPDATE'
]);
export const taskTypesActions = {
  getData: makeActionCreator(TASK_TYPES.GET_DATA, 'dealerId'),
  setData: makeActionCreator(TASK_TYPES.SET_DATA, 'taskTypes'),
  setFetchStatus: makeActionCreator(TASK_TYPES.SET_FETCH_STATUS, 'fetchStatus'),
  add: makeActionCreator(
    TASK_TYPES.ADD,
    'dealerId',
    'taskType',
    'loadingMessage',
    'successMessage',
    'errorMessage',
    'listLineItemToUpdate'
  ),
  update: makeActionCreator(TASK_TYPES.UPDATE, 'taskType', 'loadingMessage', 'successMessage', 'errorMessage'),
  delete: makeActionCreator(TASK_TYPES.DELETE, 'taskType', 'loadingMessage', 'successMessage', 'errorMessage'),
  bulkUpdate: makeActionCreator(TASK_TYPES.BULK_UPDATE, 'taskTypes')
};
//#endregion

//#region Reducer
const initialState = {
  data: [],
  fetchStatus: apiStatusConstants.IS_FETCHING
};

export const taskTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TASK_TYPES.SET_DATA:
      return {
        ...state,
        data: action.taskTypes
      };
    case TASK_TYPES.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const taskTypesSelector = createSelector(
  (state) => state.taskTypes,
  (taskTypes) => taskTypes.data
);
//#endregion

//#region Sagas
// this will trigger on either TASK_TYPES.GET_DATA, METRICS.GET_ACTIVE_METRICS
export function* getTaskTypesSaga() {
  yield takeLatest([TASK_TYPES.GET_DATA, METRICS.GET_ACTIVE_METRICS], function* ({ dealerId }) {
    // TODO: Remove this mock after API is available
    const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
    const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
    const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
    const isVendorAdmin = vendorInfo.role?.id === 1 && isNewSignin;
    const isVendorTech = vendorInfo.role?.id === 2 && isNewSignin;
    const { isRootUser, vendorShipSelected, currentDealerFetchStatus } = yield select(rootEntitySwitcherSelector);
    if (isVendorAdmin || isVendorTech || (isRootUser && vendorShipSelected)) {
      yield put(taskTypesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const taskTypes = taskTypesMockData;
      yield put(taskTypesActions.setData(taskTypes));
      yield put(taskTypesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      return;
    }
    if (!dealerId || (isRootUser && currentDealerFetchStatus === apiStatusConstants.IS_FETCHING)) {
      return;
    }
    try {
      yield put(taskTypesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const taskTypes = yield getWithToken(`/api/reconTaskTypes`, { dealerId: dealerId });
      yield put(taskTypesActions.setData(taskTypes));      

      yield put(taskTypesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(taskTypesActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTaskTypesSaga', error);
    }
  });
}

export function* updateTaskTypesSaga() {
  yield takeEvery(
    [TASK_TYPES.ADD, TASK_TYPES.UPDATE, TASK_TYPES.DELETE],
    function* ({
      type: action,
      dealerId,
      taskType,
      loadingMessage,
      successMessage,
      errorMessage,
      listLineItemToUpdate
    }) {
      const loadingMessageId = uuidv4();
      try {
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0 })); // duration of 0 will show the message indefinitely

        const currentTaskTypes = yield select(taskTypesSelector);
        let newTaskTypes;
        taskType.name = taskType.name.trim();
        switch (action) {
          case TASK_TYPES.ADD:
            const added = yield postWithToken('/api/reconTaskTypes', { ...taskType, dealerId });
            newTaskTypes = [...currentTaskTypes, added];
            yield put(taskTypesActions.setData(newTaskTypes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))));
            // If there are line items when adding task template, call PATCH API
            if (Array.isArray(listLineItemToUpdate) && listLineItemToUpdate.length > 0) {
              const requestBody = listLineItemToUpdate.map((item) => {
                return {
                  op: item.op,
                  path: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
                  value: {
                    reconTaskTypeId: added.id,
                    preApprove: lineItemStatusTypes.APPROVED === item.status,
                    id: item.op === OPERATION_LINE_ITEM.ADD ? '' : item.id,
                    description: item.description,
                    laborRate: item.laborRate || 0,
                    laborTime: item.laborTime || 0,
                    laborCost: item.laborCost,
                    partsCost: item.partsCost,
                    totalCost: item.totalCost
                  }
                };
              });
              yield patchWithToken(`/api/LineItemTemplateTemplates`, requestBody);
            }
            break;
          case TASK_TYPES.UPDATE:
            const updated = yield putWithToken(`/api/reconTaskTypes/id/${taskType.id}`, taskType);
            const updatedIndex = currentTaskTypes.findIndex((tt) => tt.id === updated.id);
            newTaskTypes = [...currentTaskTypes];
            newTaskTypes[updatedIndex] = updated;
            yield put(taskTypesActions.setData(newTaskTypes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))));
            break;
          case TASK_TYPES.DELETE:
            yield deleteWithToken(`/api/reconTaskTypes/id/${taskType.id}`);
            newTaskTypes = [...currentTaskTypes.filter((tt) => tt.id !== taskType.id)];
            yield put(taskTypesActions.setData(newTaskTypes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))));
            break;
          default:
            devLogger.log(`Unrecognized action for updateTaskTypesSaga: ${action}`);
            break;
        }

        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // passing in the same key and duration will make the message go away after that duration time
        yield put(messagesActions.notify('success', successMessage, { duration: 2 }));
      } catch (error) {
        devLogger.log('failed updateTaskTypesSaga', error);
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // need to turn off loading before the error, because I'm latching on the messageType for isSaving prop in PlanTemplateDrawer.js
        yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
      }
    }
  );
}

export function* bulkUpdateTaskTypesSaga() {
  yield takeEvery(TASK_TYPES.BULK_UPDATE, function* ({ taskTypes }) {
    try {
      const updateTemplate = function* (taskType) {
        taskType.name = taskType.name.trim();
        return yield putWithToken(`/api/ReconTaskTypes/id/${taskType.id}`, taskType);
      };

      const updatedTaskTypesResults = yield all(taskTypes.map((t) => fork(updateTemplate, t)));
      const updatedTaskTypes = yield join([...updatedTaskTypesResults]);
      let newTaskTypes = [...(yield select(taskTypesSelector))];
      updatedTaskTypes.forEach((item) => {
        const index = newTaskTypes.findIndex((x) => x.id === item.id);
        newTaskTypes = updateObjectInArray(newTaskTypes, { index, item });
      });
      newTaskTypes = newTaskTypes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
      yield put(taskTypesActions.setData(newTaskTypes));
    } catch (error) {
      devLogger.log('failed bulkUpdateTaskTypesSaga', error);
    }
  });
}
//#endregion
