import React, {useState} from 'react';
import {Tooltip, Typography} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const {Text} = Typography;

const CopyOnClickText = ({text}) => {
  const [tooltipLabel, setTooltipLabel] = useState('Click to copy');

  const setTextLabelToCopied = () => setTooltipLabel('Copied!');
  const setTextLabelToClickToCopy = () => setTooltipLabel('Click to copy');

  const onClick = eventData => {
    eventData.stopPropagation();
    eventData.preventDefault();
    setTextLabelToCopied();
  };

  return (
    <Tooltip
      placement='top'
      overlayClassName='copy-tooltip'
      title={<p>{tooltipLabel}</p>}
    >
      <CopyToClipboard text={text} onMouseEnter={setTextLabelToClickToCopy}>
        <Text 
          style={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            color: 'inherit',
            cursor: 'pointer'
          }}
          onClick={onClick}
        >
          {text}
        </Text>
      </CopyToClipboard>
    </Tooltip>
  );
};

export default CopyOnClickText;