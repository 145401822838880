import { Row, Col } from 'antd';
import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiStatusConstants } from 'app-constants';
import { vendorActions } from 'store/vendorStore';
import CustomInput from './CustomInput';
import { ErrorText, SubmitButton } from './style';
import InputErrorMessage from '../../common/InputErrorMessage';
import { theme } from 'styles/appTheme';

const lettersAndNumbersRegex = /^(?=.*?[A-Za-z])(?=.*\d.*)/;

const InputGroup = ({ vendorUserId, code, dealerId }) => {
  const setPasswordStatus = useSelector((state) => state.vendors.setPasswordStatus);
  const dispatch = useDispatch();
  const passwordErrorMessage = useSelector((state) => state.vendors.passwordErrorMessage);

  const initialState = {
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
    errorText: '',
    errorTextConfirm: ''
  };

  const passwordReducer = (state, action) => {
    switch (action.type) {
      case 'SET_PASSWORD':
        let checkedPassword = checkPassword(action.payload);
        return {
          ...state,
          password: action.payload,
          errorText: checkedPassword.errorMessage,
          errorTextConfirm:
            !!state.confirmPassword && action.payload !== state.confirmPassword ? 'Passwords do not match.' : ''
        };
      case 'CONFIRM_PASSWORD':
        return {
          ...state,
          confirmPassword: action.payload,
          errorTextConfirm: !!action.payload && action.payload !== state.password ? 'Passwords do not match.' : ''
        };
      case 'BACKEND_ERROR':
        return {
          ...state,
          errorText: action.payload
        };
      default:
        return state;
    }
  };

  const checkPassword = (password) => {
    let errorMessage = '';
    const hasNumbersAndLetters = !!password.match(lettersAndNumbersRegex);
    const validLength = password.length >= 8 && password.length <= 72;
    if (!hasNumbersAndLetters || !validLength) {
      errorMessage = 'Please input a valid password.';
    }
    errorMessage = password === '' ? '' : errorMessage;
    return { error: hasNumbersAndLetters && validLength, errorMessage };
  };

  const [passwordState, passwordDispatch] = useReducer(passwordReducer, initialState);

  useEffect(() => {
    if (setPasswordStatus === apiStatusConstants.FAILED && !!passwordErrorMessage) {
      passwordDispatch({ type: 'BACKEND_ERROR', payload: passwordErrorMessage });
    }
  }, [setPasswordStatus, passwordErrorMessage]);

  const onChangePassword = (value) => {
    passwordDispatch({ type: 'SET_PASSWORD', payload: value });
  };

  const onChangeConfirmPassword = (value) => {
    passwordDispatch({ type: 'CONFIRM_PASSWORD', payload: value });
  };

  const onCreatePassword = () => {
    dispatch(vendorActions.setPassword(vendorUserId, code, passwordState.password, dealerId));
  };

  return (
    <Row type="flex" justify="end" align="middle" gutter={[8, 8]}>
      <Col xs={24}>
        <CustomInput
          title="Password"
          placeholder="Enter your new password"
          updateValue={onChangePassword}
          error={!!passwordState.errorText}
        />

        {passwordState.errorText && (
          <InputErrorMessage
            divStyle={{ color: theme.colors.red, lineHeight: theme.lineHeights.sm, paddingTop: '3px' }}
          >
            <ErrorText>{passwordState.errorText}</ErrorText>
          </InputErrorMessage>
        )}
      </Col>

      <Col xs={24}>
        <CustomInput
          title="Confirm Password"
          placeholder="Confirm your new password"
          updateValue={onChangeConfirmPassword}
          error={!!passwordState.errorTextConfirm}
        />

        {passwordState.errorTextConfirm && (
          <InputErrorMessage
            divStyle={{ color: theme.colors.red, lineHeight: theme.lineHeights.sm, paddingTop: '3px' }}
          >
            <ErrorText>{passwordState.errorTextConfirm}</ErrorText>
          </InputErrorMessage>
        )}
      </Col>

      <Col>
        <SubmitButton
          type="primary"
          disabled={
            !passwordState.password ||
            !!passwordState.errorText ||
            passwordState.confirmPassword !== passwordState.password
          }
          onClick={onCreatePassword}
        >
          Create Password
        </SubmitButton>
      </Col>
    </Row>
  );
};

export default InputGroup;
