import { useState, useEffect } from 'react';

//as can be seen in the useState function below, initialValue is used in case there isn't data already stored with the keyForDataInLocalStorage key
export const useLocalStorage = (keyForDataInLocalStorage, initialValue) => {
  //passing in a function definition to useState allows this to only run once - on mount

  const getLocallyStoredData = () => {
    const dataFromLocalStorage = window.localStorage.getItem(keyForDataInLocalStorage);
    //it is still in string form at this point if there is returned data
    //so JSON.parse it to convert it to JS
    //if no returned data from localstorage, then return the default initialValue that has been passed
    try {
      return dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : initialValue;
    } catch {
      return initialValue;
    }
  };

  const setLocallyStoredData = (valueToStore) => {
    window.localStorage.setItem(keyForDataInLocalStorage, JSON.stringify(valueToStore));
    setValue(valueToStore);
  };

  const [value, setValue] = useState(() => {
    return getLocallyStoredData();
  });

  useEffect(() => {
    window.localStorage.setItem(keyForDataInLocalStorage, JSON.stringify(value));
  }, []);

  return [value, setLocallyStoredData, getLocallyStoredData];
};
