import { faEdit, faEllipsisH, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Dropdown, Menu, Popover } from 'antd';
import { apiStatusConstants, VENDOR_USER_INFO } from 'app-constants';
import { vendorUserOnboardingStatusTypes, vendorUserRoleDisplay } from 'app-constants/vendorUserConstants';
import { CopyOnClickText, DrawerContainer } from 'components';
import React, { useEffect, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { vendorActions } from '../../../../store/vendorStore';
import { formatMMDDYY } from '../../../../utils/dateTimeUtils';
import { compareElementWidthByClassName } from '../../../../utils/domUtils';
import { formatPhoneNumber } from '../../../../utils/numberUtils';
import { statusSort } from '../../../../utils/sortUtils';
import SearchInput from '../../../inputs/SearchInput';
import { CommonHeaderLabel, CommonRedButton } from '../../../styledComponents';
import Table from '../../../tables/Table';
import AddUsersForm from './AddUsersForm';
import VendorUserDetails from './VendorUserDetails';
import { GlobalAlertContext } from '../../../App';

const vendorUsersPropsSelector = createSelector(
  (state) => state.vendors.currentVendor.vendorUsers,
  (state) => state.vendors.currentVendor.fetchStatus,
  (state) => state.vendors.currentVendor.putStatus,
  (state) => state.vendors.currentVendor.postStatus,
  (vendorUsers, fetchStatus, putStatus, postStatus) => ({
    vendorUsers,
    fetchStatus,
    putStatus,
    postStatus
  })
);

export const VendorUsers = () => {
  const rawVendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
  const vendorUserInfo = rawVendorUserInfo ? JSON.parse(rawVendorUserInfo) : {};
  const dispatch = useDispatch();
  const { vendorUsers, fetchStatus, putStatus, postStatus } = useSelector(vendorUsersPropsSelector, shallowEqual);
  const { isRootUser, vendorShipSelected, currentId } = useSelector(rootEntitySwitcherSelector);
  // states
  const [menuOpen, setMenuOpen] = useState({});
  const [showInnerBorders, setShowInnerBorders] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContentType, setDrawerContentType] = useState('');
  const [selectedVendorUser, setSelectedVendorUser] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const hasGlobalAlert = !!useContext(GlobalAlertContext);

  useEffect(() => {
    if (isRootUser && vendorShipSelected && currentId) {
      dispatch(vendorActions.getVendorUsers(currentId));
    } else if (vendorUserInfo && vendorUserInfo.vendorId) {
      dispatch(vendorActions.getVendorUsers(vendorUserInfo.vendorId));
    }
  }, [vendorUserInfo?.vendorId, isRootUser, vendorShipSelected, currentId]);

  useEffect(() => {
    if (filteredTableData.length === 0) {
      setShowInnerBorders(false);
    } else {
      changeBorders();
    }
    window.addEventListener('resize', changeBorders);
    // cleanup func
    return () => window.removeEventListener('resize', changeBorders);
  }, [filteredTableData]);

  useEffect(() => {
    const vendorUsersList = [
      ...vendorUsers.map((vendorUser) => {
        const statusDisplay = vendorUserOnboardingStatusTypes[vendorUser.onboardingStatus].Name;
        const formattedDate = formatMMDDYY(vendorUser.onboardingStatusDate);
        return {
          ...vendorUser,
          fullname: `${vendorUser.firstName} ${vendorUser.lastName}`,
          roleName: vendorUser.onboardingStatus === 'DEACTIVATED' ? '—' : vendorUserRoleDisplay[vendorUser.role.name],
          status: vendorUser.inviteSent ? `${statusDisplay}, ${formattedDate}` : `Failed To Send`,
          isPrimary: Boolean(vendorUser.isPrimaryContact) ? 'Yes' : 'No',
          phone: formatPhoneNumber(vendorUser.phone),
          statusRank: vendorUserOnboardingStatusTypes[vendorUser.onboardingStatus].Rank,
          statusDate: vendorUser.onboardingStatusDate
        };
      })
    ];
    setTableData(vendorUsersList);
    if (searchValue) {
      const filteredArray = vendorUsersList.filter(
        (vendorUser) =>
          vendorUser.fullName?.toLowerCase().includes(searchValue) ||
          vendorUser.status?.toLowerCase().includes(searchValue) ||
          vendorUser.status?.toLowerCase().replace(/-/g, '/').includes(searchValue) ||
          vendorUser.status?.toLowerCase().replace(/-/g, ' ').includes(searchValue) ||
          vendorUser.roleName?.toLowerCase().includes(searchValue) ||
          vendorUser.isPrimary?.toLowerCase().includes(searchValue) ||
          vendorUser.phone?.includes(searchValue) ||
          vendorUser.phone?.replace(/-/g, '').includes(searchValue) ||
          vendorUser.phone?.replace(/-/g, ' ').includes(searchValue) ||
          vendorUser.email?.toLowerCase().includes(searchValue)
      );
      setFilteredTableData(filteredArray);
    } else {
      setFilteredTableData(vendorUsersList);
    }
  }, [vendorUsers]);

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const changeBorders = () => {
    if (filteredTableData.length > 0) {
      setShowInnerBorders(compareElementWidthByClassName('.ant-table-measure-row', '.ant-table-wrapper'));
    }
  };

  const onViewUser = (vendorUser) => {
    if (vendorUser) {
      // open dealer details drawer]
      setSelectedVendorUser(vendorUser);
      openDrawer();
    }
  };

  // action column ellipsis
  const moreMenu = (vendorUser) => (
    <Dropdown
      display="flex"
      onVisibleChange={() => setMenuOpen({ id: vendorUser.id, open: !menuOpen.open })}
      overlay={
        <Menu
          onClick={() => {
            setMenuOpen({ id: vendorUser.id, open: false });
            setDrawerContentType('view');
            onViewUser(vendorUser);
          }}
        >
          <Menu.Item key="view">
            <StyledMenuIcon icon={faEdit} />
            View Details
          </Menu.Item>
        </Menu>
      }
    >
      <StyledOverflowIcon id={vendorUser.id} icon={faEllipsisH} data-selected={menuOpen} />
    </Dropdown>
  );

  const failedToSendMessage = (text) => (
    <Popover
      content={
        <StyledText>
          User invite failed to send. View the user details to resend the invite. If this problem persists, contact
          support.
        </StyledText>
      }
    >
      <FailedToSendMessage>
        <StyledAlertIcon icon={faExclamationCircle} />
        <p className={'table-cell-failed-to-send'}>{text}</p>
      </FailedToSendMessage>
    </Popover>
  );

  // table definitions
  const columnsDef = [
    {
      title: 'User Name',
      dataIndex: 'fullName',
      width: '125px',
      fixed: 'left',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: statusSort,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '100px',
      render: (text, record) => {
        if (text === 'Failed To Send') {
          return failedToSendMessage(text);
        }
        if (text.includes('Pending')) {
          return <p className={'table-cell-pending'}>{text}</p>;
        }
        return text;
      }
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      width: '80px',
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Primary',
      dataIndex: 'isPrimary',
      width: '110px',
      sorter: (a, b) => a.isPrimary.localeCompare(b.isPrimary),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      width: '100px',
      sorter: (a, b) => {
        if (!a.phone) {
          return -1;
        }
        if (!b.phone) {
          return 1;
        } else {
          return a.phone.localeCompare(b.phone, { numeric: true });
        }
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => {
        if (!a.email) {
          return -1;
        }

        if (!b.email) {
          return 1;
        } else {
          return a.email.localeCompare(b.email);
        }
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text) => {
        return <CopyOnClickText text={text.trim()} />;
      }
    },
    {
      title: '',
      dataIndex: 'operation',
      className: 'vendor-operation-col-header',
      fixed: 'right',
      width: '50px',
      align: 'right',
      render: (_text, record) => {
        return moreMenu(record);
      }
    }
  ];

  const columns = columnsDef.map((col) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width
    })
  }));

  const filterTableData = (value) => {
    const trimmedValue = value.toLowerCase().trim();
    setSearchValue(trimmedValue);
    if (trimmedValue) {
      const filteredArray = tableData.filter(
        (vendorUser) =>
          vendorUser.fullName?.toLowerCase().includes(trimmedValue) ||
          vendorUser.status?.toLowerCase().includes(trimmedValue) ||
          vendorUser.status?.toLowerCase().replace(/-/g, '/').includes(trimmedValue) ||
          vendorUser.status?.toLowerCase().replace(/-/g, ' ').includes(trimmedValue) ||
          vendorUser.roleName?.toLowerCase().includes(trimmedValue) ||
          vendorUser.isPrimary?.toLowerCase().includes(trimmedValue) ||
          vendorUser.phone?.includes(trimmedValue) ||
          vendorUser.phone?.replace(/-/g, '').includes(trimmedValue) ||
          vendorUser.phone?.replace(/-/g, ' ').includes(trimmedValue) ||
          vendorUser.email?.toLowerCase().includes(trimmedValue)
      );
      setFilteredTableData(filteredArray);
    }
  };

  const scroll = filteredTableData.length !== 0 && {
    y: `calc(100vh - 400px${hasGlobalAlert ? ' - 42px' : ''})`,
    x: 'max-content'
  };

  const vendorUserRowClass = (record) => {
    if (record.status.includes('Deactivated')) {
      return 'table-row-declined';
    }
    return 'table-row-normal';
  };

  const isFetching = fetchStatus === apiStatusConstants.IS_FETCHING || fetchStatus === apiStatusConstants.PENDING;

  const vendorUsersTable = (
    <StyledContainer>
      <StyledVendorUsersTable
        isFetching={isFetching}
        columnsDef={columns}
        dataSource={filteredTableData}
        scroll={scroll}
        rowClassName={vendorUserRowClass}
        showInnerBorders={showInnerBorders}
        fetchFailed={fetchStatus === apiStatusConstants.FAILED}
      />
    </StyledContainer>
  );

  return (
    <>
      <StyledHeader>
        <SearchInput
          searchFunction={filterTableData}
          data={tableData}
          resetData={setFilteredTableData}
          customHeight={32}
          setSearchValue={setSearchValue}
        />
        <CommonRedButton
          onClick={() => {
            openDrawer();
            setDrawerContentType('add');
          }}
        >
          Add User
        </CommonRedButton>
      </StyledHeader>
      {vendorUsersTable}
      <Drawer
        closable={false}
        destroyOnClose={true}
        placement="right"
        width={580}
        visible={showDrawer}
        onClose={closeDrawer}
        bodyStyle={{ padding: '0' }}
      >
        <DrawerContainer
          headerLabel={
            <CommonHeaderLabel>{drawerContentType === 'add' ? 'Add User' : 'User Details'}</CommonHeaderLabel>
          }
          onClose={() => closeDrawer()}
        >
          {drawerContentType === 'add' ? (
            <AddUsersForm
              closeDrawer={closeDrawer}
              vendor={vendorUserInfo}
              vendorUsers={vendorUsers}
              postStatus={postStatus}
            />
          ) : (
            <VendorUserDetails
              closeDrawer={closeDrawer}
              vendorUser={selectedVendorUser}
              vendorUsers={tableData}
              vendor={vendorUserInfo}
              putStatus={putStatus}
            />
          )}
        </DrawerContainer>
      </Drawer>
    </>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-content: end;
  grid-gap: 8px;
  white-space: nowrap;
  margin: 0 ${(props) => (props['recon-vendor-management'] ? `24px` : `32px`)} 16px 0;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin: 0 ${(props) => (props['recon-vendor-management'] ? `24px` : `32px`)} 16px 0;
  button {
    margin-left: 8px;
  }
`;

const StyledVendorUsersTable = styled(Table)``;

const StyledMenuIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  width: 14px;
  height: 14px;
`;

const StyledAlertIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  width: 14px;
  height: 14px;
  color: #eb002e;
`;

const FailedToSendMessage = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledOverflowIcon = styled(FontAwesomeIcon)`
  height: 20px;
  cursor: pointer;
  color: ${(props) =>
    props['data-selected']['open'] && props['data-selected']['id'] === props['id']
      ? 'inherit'
      : props.theme.colors.darkGray};
  vertical-align: -0.45em;
`;

const StyledText = styled.div`
  width: 250px;
  height: 63px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;

  /* iRecon/iRecon Blue */
  color: #002950;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

//#endregion
export default VendorUsers;
