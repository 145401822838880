import { useFeatures } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import useVendorRole from 'hooks/useVendorRole';

const Authorize = ({ children, feature, forbiddenMessage = "You don't have access to this page" }) => {
  const [hasRequiredFeature] = useFeatures(feature);
  const { isBridgeUser } = useSelector((state) => state.auth);
  const { isVendorAdmin } = useVendorRole();
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  if (isRootUser && vendorShipSelected && window.location.pathname.includes('/dashboard')) {
    return <StyledForbiddenText is-bridge-user={isBridgeUser}>{forbiddenMessage}</StyledForbiddenText>;
  }
  // TODO: Address this hard-coded list of pages after updates from the server side
  if (
    hasRequiredFeature ||
    (isVendorAdmin &&
      ['/configure/users', '/configure/dealerships', 'settings/notifications', 'tasks', '/business-profile'].includes(
        window.location.pathname
      ))
  ) {
    return children;
  } else if (typeof hasRequiredFeature === 'undefined') {
    return null;
  } else {
    return <StyledForbiddenText is-bridge-user={isBridgeUser}>{forbiddenMessage}</StyledForbiddenText>;
  }
};

const StyledForbiddenText = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  height: calc(100vh - ${(props) => (props['is-bridge-user'] ? 202 : 166)}px);
  padding: 24px;
`;

export default Authorize;
