import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Input, Menu } from 'antd';
import { StyledFormItem } from 'components/layout/settings/common/Styles';
import { createSequentialIntegerArray } from 'utils/arrayUtils';

const TimeUnitInputWithDropdown = ({
  isAcceptableInteger,
  onChange,
  formValues,
  getFieldDecorator,
  field,
  label,
  validatorFunction = () => {}
}) => {
  const isDaysField = field.indexOf('Days') > -1;
  const timeUnitsArray = createSequentialIntegerArray(isDaysField ? 21 : 24, 0, false);
  const [filteredTimeUnits, setFilteredTimeUnits] = useState(timeUnitsArray); //at first, we want all timeUnits to be available
  const [dropdownVisible, setDropdownVisible] = useState(false);
  //determining when to hide menu based on user interactions other than typing (clicking on dropdown (input), clicking on menu, scrolling on menu, wheeling over menu)
  const [focusBlurState, setFocusBlurState] = useState({ dropdown: 'blur', menu: 'blur' }); //piece of state that keeps track of both the dropdown (the wrapper element of the input) and the menu (the overlay in the dropdown)
  //when the input is in focus, the dropdown is in focus 100% of the time; when the dropdown is in focus, the input is in focus 100% of the time
  //when the menu is in focus, the input and the dropdown are out of focus (blurred) 100% of the time
  //both can be blurred at the same time - when both are blurred, that is when the menu should disappear
  const timerIDForFocusBlurState = useRef(); //needed to properly clear timer when different states are changing

  //functions for setting focusBlurState - when focus/blur events happen for either, the appropriate focus/blur handler function is invoked
  const onDropdownFocus = () => setFocusBlurState((previousState) => ({ ...previousState, dropdown: 'focus' }));
  const onDropdownBlur = () => setFocusBlurState((previousState) => ({ ...previousState, dropdown: 'blur' }));
  const onMenuFocus = () => setFocusBlurState((previousState) => ({ ...previousState, menu: 'focus' }));
  const onMenuBlur = () => setFocusBlurState((previousState) => ({ ...previousState, menu: 'blur' }));
  //menu should only be shown if either of the focusBlurStates are in 'focus', another way of saying this is that the menu should be hidden if both focusBlurStates are in 'blur'
  const determineMenuVisiblity = () =>
    focusBlurState.dropdown === 'focus' || focusBlurState.menu === 'focus' ? 'show' : 'hide';

  //useEffect which manages visiblity of menu
  //timeout is important here so that the proper focus/blur handler functions are invoked and completed by the time that the setter for dropdownVisible could be invoked
  //this useEffect is really only going to have any meaningful UI changes occur when both the dropdown and the menu are in blur state
  //in which case the result of determineMenuVisiblity() will be 'hide'
  //this useEffect returns a garbage collecting function that will clear the timeout that was set on the previous render of the component (that is why we need the ref state timerIDForFocusBlurState)
  useEffect(() => {
    const action = determineMenuVisiblity(); //either 'show' or 'hide' menu
    timerIDForFocusBlurState.current = setTimeout(() => action === 'hide' && setDropdownVisible(false), 200);
    return () => clearTimeout(timerIDForFocusBlurState.current);
  }, [JSON.stringify(focusBlurState), determineMenuVisiblity, timerIDForFocusBlurState]);

  const filterAndChangeDays = (fieldName, value, source) => {
    if (value.length === 0) {
      setFilteredTimeUnits(timeUnitsArray);
      setDropdownVisible(true);
    } else if (source === 'input' && isAcceptableInteger(value)) {
      const parsedValue = parseInt(value);
      if (timeUnitsArray.some((timeUnits) => timeUnits.indexOf(parsedValue) > -1)) {
        setDropdownVisible(true);
        setFilteredTimeUnits(timeUnitsArray.filter((timeUnits) => timeUnits.indexOf(parsedValue) > -1));
      } else {
        if (isDaysField) setDropdownVisible(false); //hide if days field
        if (!isDaysField) setFilteredTimeUnits(['Cannot exceed 23 hours']); //show warning
      }
    }

    onChange(fieldName)(value);
    if (source === 'dropdown') {
      setDropdownVisible(false);
    }
  };

  const onFocus = () => {
    const passedValueForField = (() => {
      if (formValues[field] === 0) return 0;
      if (formValues[field]) return formValues[field];
      return '';
    })();
    filterAndChangeDays(field, passedValueForField, 'input');
  };
  const onBlur = (event) => {
    event.preventDefault();
    if (!isDaysField) {
      if (timeUnitsArray.indexOf(formValues[field]?.toString()) === -1) {
        onChange(field)(0);
      }
    }
  };

  return (
    <StyledFormItem
      label={label}
      style={isDaysField ? { marginRight: 10, flex: '1 1 0px' } : { marginLeft: 10, marginTop: 24, flex: '1 1 0px' }}
    >
      {getFieldDecorator(field, {
        rules: [
          {
            required: true,
            type: 'number',
            validator: validatorFunction
          }
        ]
      })(
        <div>
          <Dropdown
            trigger={['click']}
            onFocus={onDropdownFocus}
            onBlur={onDropdownBlur}
            visible={dropdownVisible}
            overlay={
              <Menu onFocus={onMenuFocus} onBlur={onMenuBlur} style={{ maxHeight: '100px', overflowY: 'auto' }}>
                {filteredTimeUnits.map((timeUnits) => (
                  <StyledMenuItem
                    className="here"
                    key={timeUnits}
                    onClick={() => filterAndChangeDays(field, timeUnits, 'dropdown')}
                  >
                    {timeUnits}
                  </StyledMenuItem>
                ))}
              </Menu>
            }
          >
            <Input
              suffix={isDaysField ? 'Days' : 'Hours'}
              size="large"
              value={formValues[field]}
              onFocus={onFocus}
              onBlur={onBlur} //default onBlur needs to
              onChange={(eventData) => filterAndChangeDays(field, eventData.target.value, 'input')}
            />
          </Dropdown>
        </div>
      )}
    </StyledFormItem>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-item& {
    line-height: 20px;
    padding: 1px 12px;
  }
`;

export default TimeUnitInputWithDropdown;
