import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { tasksActions } from 'store/tasksStore';
import { Card, Pagination, Drawer, Affix } from 'antd';
import { TaskFilters } from 'components/layout/tasks';
import { FullCommunications, withCommunications } from 'components/communications';
import { BorderlessSelect, DrawerContainer, Error, ExportFunctionality } from 'components';
import {
  CommonListPageContainer,
  CommonHeaderLabel,
  CommonSortRow,
  CommonMenuItem,
  CommonMenu,
  GridArea
} from 'components/styledComponents';
import {
  apiStatusConstants,
  ewsEntityTypes,
  getTaskStatusLabels,
  taskProgressLabels,
  detailContents,
  communicationContexts,
  allUnassignedTasksLabel,
  features,
  sortOptionDefault,
  TECHNICIAN,
  GROUP,
  VENDOR,
  DEALERSHIP,
  CATEGORY,
  profitTimeLabels
} from 'app-constants';
import { useLocalStorage, useScreenSize, useDebouncedEffect, useFeatures } from 'hooks';
import { stripReferences } from 'utils/arrayUtils';
import { TaskList, StyledListContainer, tasksPresentationPropsSelector } from './Tasks';
import { TaskDetails, TaskFormDrawer } from '.';
import { COMPLETED, DECLINED, IN_PROGRESS } from 'app-constants/taskStatusTypes';
import { vdpActions } from 'store/vdpStore';
import { lineItemsActions } from 'store/lineItemsStore';
import useVendorRole from '../../../../hooks/useVendorRole';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { HQAndProfitTimeContext, RootContext } from 'utils/contexts';
import { Footer } from 'components/layout/nav';
import { GlobalAlertContext } from '../../../App';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../../google-analytics';

const FullCommunicationsFromTasksStore = withCommunications('tasksStore')(FullCommunications);

const TasksPresentation = ({
  selectedTaskId,
  scrollcontainer,
  hasViewAnyTask,
  hasWorkOwnTasks,
  query,
  setQuery,
  dealerDispositions,
  taskStatusReverseLookup,
  taskProgressReverseLookup,
  profitTimeReverseLookup,
  dispositionReverseLookup,
  toggleFiltersChangedByUser,
  currentVendorId,
  isRefreshPage,
  forceLoadPageQueryFromStorage,
  setForceLoadPageQueryFromStorage,
  flags
}) => {
  const dispatch = useDispatch();
  const [isExternalTech, isAdminGeneralRole, hasTasksEdit] = useFeatures(
    features.EXTERNAL_TECH_ONLY,
    features.ADMINGENERAL,
    features.TASKS_EDIT
  );
  const isExternalTechnicianRole = isExternalTech && !isAdminGeneralRole;
  const {
    authState,
    currentDealerId,
    currentDealer,
    fetchStatus,
    tasksData,
    pager,
    taskTypesData,
    taskCategoriesData,
    users,
    documents,
    assigneeData,
    dealerships,
    dealershipFetchStatus
  } = useSelector(tasksPresentationPropsSelector);

  const [isFirstFetch, setIsFirstFetch] = useState(null); //  used for capturing page load time for google analytics, considering the page as done loading when the tasksActions.getData call has completed
  const [selectedCard, setSelectedCard] = useState({ contentType: null });
  const [count, setCount] = useState(0);
  const screenSize = useScreenSize();
  const [filters, setFilters] = useState({});
  const [sortValues, setSortValues] = useState({});
  const {
    taskStatus,
    completionDate,
    completedOnStart,
    completedOnEnd,
    taskType,
    taskCategories,
    taskProgress,
    entitiesFilter,
    disposition,
    assignedTo,
    sort,
    search,
    fromDashboard,
    assignedToTechnicianFilter,
    assignedToGroupFilter,
    profitTime,
    dealerAndTaskTypeFilter,
    reassigned,
    assignedToVendor
  } = query;
  const [displayRange, setDisplayRange] = useState();
  const [isInitialTasksFetch, setIsInitialTasksFetch] = useState(true);
  const { isVendor, isVendorAdmin, isVendorTech } = useVendorRole();
  const { vendorShipSelected, isRootUser, currentId } = useSelector(rootEntitySwitcherSelector);
  const { hasReconProfitTime } = useContext(HQAndProfitTimeContext);
  const isEnterpriseOrContainerDealer =
    currentDealer?.entityType === ewsEntityTypes.ENTERPRISE || currentDealer?.entityType === ewsEntityTypes.CONTAINER;
  const showDealerName = isVendor || (isEnterpriseOrContainerDealer && flags.ireconHqView);
  const hasGlobalAlert = !!useContext(GlobalAlertContext);

  const getInternalUsers = () => {
    let internalUsers = [];
    if ((isVendor && isVendorAdmin) || (isRootUser && vendorShipSelected)) {
      // vendors
      const toCheckId = isRootUser ? currentId : currentVendorId;
      let newVendors = stripReferences(assigneeData?.vendors ?? []);
      if (newVendors && newVendors.length > 0) {
        const currentVendor = newVendors.find((vendor) => vendor.id === toCheckId);
        if (currentVendor !== undefined) {
          const currentVendorUsers = currentVendor?.vendorUsers;
          if (currentVendorUsers && currentVendorUsers.length > 0) {
            currentVendorUsers.forEach((item) => {
              let obj = {};
              obj['id'] = item.id;
              obj['fullName'] = item.fullName;
              obj['isChecked'] = authState.user.id === item.id;
              obj['isMemberOfAnyGroup'] = false;
              obj['isAssignedDirectly'] = authState.user.id === item.id;
              obj['isAssignedThruGroup'] = false;
              obj['isShowSubMenu'] = false;
              obj['isShowMinusOutlinedIcon'] = false;
              // User Unassigned not show in filter Technician
              if (item.id !== undefined && item.fullName !== 'Unassigned') {
                internalUsers.push(obj);
              }
            });
            let topCurrentUser = internalUsers.find((f) => f.isChecked);
            let indexTopCurrentUser = internalUsers.findIndex((f) => f.id === authState.user.id);
            if (indexTopCurrentUser > -1) {
              internalUsers.splice(indexTopCurrentUser, 1);
              if (topCurrentUser) {
                internalUsers.unshift(topCurrentUser);
              }
            }
            // Vendor Admin/Tech selected default
            // Add a option for group "All Unassigned Tasks"
            if (internalUsers.length > 0) {
              let optionAllUnassignedTasks = {
                id: null,
                isChecked: isVendorAdmin,
                fullName: allUnassignedTasksLabel,
                isMemberOfAnyGroup: false,
                isAssignedDirectly: isVendorAdmin,
                isAssignedThruGroup: false,
                isShowSubMenu: false,
                isShowMinusOutlinedIcon: false
              };
              internalUsers.unshift(optionAllUnassignedTasks);
              return internalUsers;
            }
          }
        }
      }
    } else {
      let newInternalUsers = stripReferences(assigneeData?.internalUsers ?? []);
      if (newInternalUsers && newInternalUsers.length > 0) {
        newInternalUsers.forEach((item) => {
          let obj = {};
          obj['id'] = item.id;
          obj['fullName'] = item.fullName;
          obj['isChecked'] = authState.user.id === item.id;
          obj['isMemberOfAnyGroup'] = item.isMemberOfAnyGroup;
          obj['isAssignedDirectly'] = authState.user.id === item.id;
          obj['isAssignedThruGroup'] = authState.user.id === item.id;
          obj['isShowSubMenu'] = false;
          obj['isShowMinusOutlinedIcon'] = false;
          internalUsers.push(obj);
        });
        let topCurrentUser = internalUsers.find((f) => f.isChecked);
        let indexTopCurrentUser = internalUsers.findIndex((f) => f.id === authState.user.id);
        if (indexTopCurrentUser > -1) {
          internalUsers.splice(indexTopCurrentUser, 1);
          if (topCurrentUser) {
            internalUsers.unshift(topCurrentUser);
          }
        }
        return internalUsers;
      }
    }
  };

  const getInternalGroups = () => {
    let newInternalGroups = stripReferences(assigneeData?.internalGroups ?? []);
    let internalGroups = [];
    if (newInternalGroups && newInternalGroups.length > 0) {
      newInternalGroups.forEach((group) => {
        let obj = {};
        obj['id'] = group.id;
        let indexGroupMemberChecked = group.internalGroupUsers.findIndex((f) => f.userId === authState.user.id);
        let groupMembers = group.internalGroupUsers.map((user) => {
          let idxRawUser = assigneeData?.internalUsers?.findIndex((f) => f.id === user.userId);
          if (idxRawUser !== -1 && user.fullName !== assigneeData?.internalUsers[idxRawUser].fullName) {
            user.fullName = assigneeData?.internalUsers[idxRawUser].fullName;
          }
          user['isChecked'] = indexGroupMemberChecked !== -1;
          if (user.fullName === 'Unassigned') {
            user.userId = null;
          }
          return user;
        });
        obj['isChecked'] = indexGroupMemberChecked !== -1;
        obj['fullName'] = group.name;
        obj['internalGroupUsers'] = groupMembers;
        obj['isShowSubMenu'] = false;
        obj['isShowMinusOutlinedIcon'] = indexGroupMemberChecked !== -1 ? false : true;
        obj['isYourGroup'] = indexGroupMemberChecked !== -1;
        internalGroups.push(obj);
      });
    }
    // Add a option for group "All Unassigned Tasks"
    if (internalGroups.length > 0) {
      let optionGroupAllUnassignedTasks = {
        id: null,
        isChecked: false,
        fullName: allUnassignedTasksLabel,
        internalGroupUsers: [],
        isShowSubMenu: false,
        isShowMinusOutlinedIcon: false,
        isYourGroup: false
      };
      internalGroups.unshift(optionGroupAllUnassignedTasks);
    }
    return internalGroups;
  };

  const getDealerships = () => {
    let dataDealerships = stripReferences(dealerships ?? []);
    let newDataDealerships = [];
    if (dataDealerships && dataDealerships.length > 0) {
      dataDealerships.forEach((dealer) => {
        let obj = {};
        obj['id'] = dealer.dealerId;
        let taskTypes = dealer.taskTypes.map((type) => {
          let newTaskType = {};
          newTaskType['id'] = type.id;
          newTaskType['fullName'] = type.name;
          newTaskType['isChecked'] = false;
          return newTaskType;
        });
        obj['isChecked'] = false;
        obj['fullName'] = dealer.dealerName;
        obj['taskTypes'] = taskTypes || [];
        obj['isShowSubMenu'] = false;
        obj['isShowMinusOutlinedIcon'] = false;
        newDataDealerships.push(obj);
      });
      return newDataDealerships;
    }
  };

  const internalUsers = getInternalUsers() || [];
  const internalGroups = getInternalGroups();
  const newDealerships = getDealerships() || [];

  const [menuState, setMenuState] = useState({
    // the initial state of all menu items is all false
    'Task Status': {
      type: 'group',
      'Task Status': {
        type: 'checkbox',
        // only show Approval Time if coming from dashboard
        values: Object.values(getTaskStatusLabels())
          .filter((v) => v !== 'Approval Time')
          .reduce((obj, v) => {
            obj[v] = false;
            return obj;
          }, {})
      },
      'Completion Date': {
        type: 'radio',
        visible: false,
        disabled: true,
        values: null,
        options: ['Last 7 days', 'Last 30 days', 'Last 90 days', 'Custom Range']
      },
      datepicker: {
        type: 'datepicker',
        disabled: true,
        values: null,
        visible: false
      }
    },
    'Task Type': {
      type: 'checkbox',
      values: taskTypesData.reduce((obj, v) => {
        obj[v.name] = false;
        return obj;
      }, {})
    },
    Category: {
      type: 'checkbox',
      values: Object.values(taskCategoriesData).reduce((obj, v) => {
        obj[v.name] = false;
        return obj;
      }, {})
    },
    'Task Progress': {
      type: 'checkbox',
      values: Object.values(taskProgressLabels)
        .filter((t) => t !== 'Declined')
        .filter((t) => t !== 'Declined' && t)
        .reduce((obj, v) => {
          // Not including Declined here, declined is already in Task Status menu
          obj[v] = false;
          return obj;
        }, {})
    },
    Dealership: {
      type: 'checkbox',
      values: isVendor
        ? newDealerships
        : assigneeData?.dealerships?.reduce((obj, v) => {
            obj[v.name] = false;
            return obj;
          }, {})
    },
    Vendor: {
      type: 'checkbox',
      values:
        assigneeData?.vendors.reduce((obj, v) => {
          obj[v.name] = false;
          return obj;
        }, {}) || {}
    },
    Group: {
      type: 'checkbox',
      values: internalGroups
    },
    Technician: {
      type: 'checkbox',
      values: internalUsers
    },
    ProfitTime: {
      type: 'checkbox',
      values: hasReconProfitTime
        ? Object.values(profitTimeLabels).reduce((obj, v) => {
            obj[v] = false;
            return obj;
          }, {})
        : {}
    },
    More: {
      type: 'group',
      Disposition: {
        type: 'checkbox',
        values: dealerDispositions.reduce((obj, v) => {
          obj[v] = false;
          return obj;
        }, {})
      }
    }
  });

  const [tasksPerPage, setTasksPerPage] = useLocalStorage('tasksPageSize', 10);
  const [sortDropdownIsOpen, setSortDropdownIsOpen] = useState(false);
  const [hasNoFilters, setHasNoFilters] = useState(true);

  //#region filter
  // remove internal group filter when user is extech or no groups in system (dealer)
  const removeInternalGroupFilter = () => {
    delete menuState[GROUP];
    setMenuState({ ...menuState });
    const newQuery = {
      assignedToGroupFilter: []
    };
    if (isExternalTechnicianRole) {
      newQuery.assignedToTechnicianFilter = getDefaultAssignedToNewFilter();
    }
    setQuery(newQuery, 'pushIn');
  };

  const removeVendorFilter = () => {
    delete menuState[VENDOR];
    setMenuState({ ...menuState });
    const newQuery = {
      assignedToVendor: []
    };
    setQuery(newQuery, 'pushIn');
  };

  const removeEntitiesFilter = () => {
    delete menuState[DEALERSHIP];
    setMenuState({ ...menuState });
    const newQuery = {
      entitiesFilter: []
    };
    setQuery(newQuery, 'pushIn');
  };

  const removeTaskCategoriesFilter = () => {
    let menuStateCopy = { ...menuState };
    delete menuStateCopy[CATEGORY];
    setMenuState(menuStateCopy);
    const newQuery = {
      taskCategories: []
    };
    setQuery(newQuery, 'pushIn');
  };

  const removeFilterWhenVendorLogin = () => {
    delete menuState['Task Type'];
    delete menuState[GROUP];
    delete menuState['More'];
    if (isVendorTech) {
      delete menuState[TECHNICIAN];
    }
    setMenuState({ ...menuState });
    const newQuery = {
      dealerAndTaskTypeFilter: JSON.stringify([]),
      assignedToTechnicianFilter: JSON.stringify([])
    };
    if (isVendorTech) {
      newQuery.assignedToTechnicianFilter = getDefaultAssignedToNewFilter();
    }
    setQuery(newQuery, 'pushIn');
  };

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.TASK_LIST_VIEWED);

  useEffect(() => {
    if (isVendor) {
      delete menuState[VENDOR];
      setMenuState({ ...menuState });
    }
  }, [isVendor]);

  useEffect(() => {
    if (assigneeData && assigneeData.internalGroups && !assigneeData.internalGroups.length && !isVendor) {
      removeInternalGroupFilter();
    }

    if (!flags.reconVendorManagement || (assigneeData && assigneeData.vendors && !assigneeData.vendors.length)) {
      removeVendorFilter();
    }
    if (isVendor && flags.reconVendorManagement) {
      removeFilterWhenVendorLogin();
    }

    if (!isVendor && (!flags.ireconHqView || currentDealer.entityType === ewsEntityTypes.DEALER)) {
      removeEntitiesFilter();
    }

    if (isVendor) {
      removeTaskCategoriesFilter();
    }

    if (!flags.reconVendorManagement || !assignedToVendor) {
      setQuery({ assignedToVendor: undefined }, 'pushIn');
    }
    //when currentDealerId or taskId param changes, de-select current card and reload list
    setSelectedCard({ contentType: null });

    if (selectedTaskId && !(isRootUser && vendorShipSelected)) {
      dispatch(tasksActions.getSingle(selectedTaskId, true));
    } else if (query['fromDashboard'] && (!flags.reconHiddenFilter || typeof hasTasksEdit === 'boolean')) {
      onQueryChange();
    }
  }, [currentDealerId, selectedTaskId, hasViewAnyTask, hasTasksEdit]);

  useEffect(() => {
    if (isExternalTechnicianRole) {
      removeInternalGroupFilter();
    }
  }, [isExternalTechnicianRole]);

  useEffect(() => {
    if (isVendor && isRefreshPage && newDealerships?.length > 0) {
      onQueryChange();
    }
  }, [selectedTaskId, isRefreshPage]);

  useEffect(() => {
    // Subscribe query filter when user navigate from drawer Notifications page.
    if (forceLoadPageQueryFromStorage && !selectedTaskId && !isVendor) {
      onQueryChange();
      setForceLoadPageQueryFromStorage(false);
    }
  }, [forceLoadPageQueryFromStorage]);

  useEffect(() => {
    if (dealershipFetchStatus === apiStatusConstants.SUCCEEDED && newDealerships?.length === 0) {
      delete menuState[DEALERSHIP];
      setMenuState({ ...menuState });
      if (isRefreshPage) {
        let newQuery = stripReferences(query);
        newQuery.dealerAndTaskTypeFilter = JSON.stringify([]);
        setQuery(newQuery, 'pushIn');
        onQueryChange();
      }
    }
  }, [dealershipFetchStatus]);

  useDebouncedEffect(
    () => {
      // add to fix call api recontask twice.
      if (isFirstFetch !== null && !isFirstFetch && !selectedTaskId) {
        onQueryChange({ ...pager, start: 1 }); //  when filters change, we should return to the first page
      }
    },
    300,
    [
      JSON.stringify(taskStatus),
      JSON.stringify(completionDate),
      JSON.stringify(completedOnStart),
      JSON.stringify(completedOnEnd),
      JSON.stringify(taskType),
      JSON.stringify(taskCategories),
      JSON.stringify(taskProgress),
      JSON.stringify(entitiesFilter),
      JSON.stringify(assignedTo),
      JSON.stringify(disposition),
      selectedTaskId,
      sort,
      search,
      fromDashboard,
      assignedToTechnicianFilter,
      assignedToGroupFilter,
      JSON.stringify(dealerAndTaskTypeFilter),
      reassigned,
      JSON.stringify(assignedToVendor),
      JSON.stringify(profitTime)
    ]
  );
  useEffect(() => {
    // When user dealerships first login
    if (isRefreshPage && !isVendor && !selectedTaskId) {
      onQueryChange();
    }

    if (isRefreshPage && isRootUser && vendorShipSelected) {
      // When root user first login
      // Why we call onQueryChange() in here. Because at the moment data dealership is set to menustate
      onQueryChange();
    }
  }, [isRefreshPage]);

  const onQueryChange = (localPager = pager) => {
    let transientMenuState = stripReferences(menuState);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Task Status', 'Task Status', taskStatus);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Task Status', 'Completion Date', completionDate);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Task Status', 'datepicker', [
      completedOnStart,
      completedOnEnd
    ]);
    if (!isVendor) {
      transientMenuState = updateTransientMenuState(transientMenuState, 'Task Type', null, taskType);
    }

    if (!isVendor) {
      transientMenuState = updateTransientMenuState(transientMenuState, CATEGORY, null, taskCategories);
    }

    transientMenuState = updateTransientMenuState(transientMenuState, 'Task Progress', null, taskProgress);
    if (hasReconProfitTime) {
      transientMenuState = updateTransientMenuState(transientMenuState, 'ProfitTime', null, profitTime);
    }

    if (menuState.Vendor && flags.reconVendorManagement && !isVendor) {
      transientMenuState = updateTransientMenuState(transientMenuState, VENDOR, null, assignedToVendor);
    }
    if (!isVendor || isVendorAdmin) {
      transientMenuState = updateTransientMenuState(transientMenuState, TECHNICIAN, null, assignedToTechnicianFilter);
    }
    if (menuState[GROUP] !== undefined && !isVendor) {
      transientMenuState = updateTransientMenuState(transientMenuState, GROUP, null, assignedToGroupFilter);
    }

    if (menuState[DEALERSHIP] !== undefined) {
      transientMenuState = updateTransientMenuState(
        transientMenuState,
        DEALERSHIP,
        null,
        isVendor ? dealerAndTaskTypeFilter : entitiesFilter
      );
    }
    if (!isVendor) {
      transientMenuState = updateTransientMenuState(transientMenuState, 'More', 'Disposition', disposition);
    }
    setMenuState(transientMenuState);
    const vendorIds = assigneeData?.vendors?.filter((v) => assignedToVendor?.includes(v.name)).map((v) => v.id);

    const newFilters = {
      status:
        taskStatus.length > 0
          ? taskStatus.map((s) => taskStatusReverseLookup[s])
          : Object.keys(getTaskStatusLabels()).filter((label) => label !== 'APPROVAL_TIME'), // when none are selected, it means show all, but since we're not exposing all possible enums options, not passing in any values will get everything on the api side, which will include PENDING and CANCELED
      completedOnStart,
      completedOnEnd,
      taskType: getTaskTypeIds(taskType),
      taskCategories: getTaskCategoriesIds(taskCategories),
      taskProgress: taskProgress.map((t) => taskProgressReverseLookup[t]),
      assignedTo: hasViewAnyTask ? getAssignedToIds(assignedTo) : [authState.user.id],
      profitTime: profitTime.map((s) => profitTimeReverseLookup[s]),
      disposition: disposition.map((d) => dispositionReverseLookup[d]),
      search,
      assignedToTechnicianFilter: hasViewAnyTask ? assignedToTechnicianFilter : getDefaultAssignedToNewFilter(),
      assignedToGroupFilter:
        hasViewAnyTask && assigneeData?.internalGroups?.length ? assignedToGroupFilter : JSON.stringify([]),
      dealerAndTaskTypeFilter: isVendor ? dealerAndTaskTypeFilter : JSON.stringify([]),
      reassigned: reassigned ?? false,
      assignedToVendor: hasViewAnyTask && vendorIds ? vendorIds : undefined,
      entitiesFilter: assigneeData?.dealerships?.filter((v) => (entitiesFilter || []).includes(v.name)).map((v) => v.id)
    };

    delete newFilters.assignedTo;

    if (flags?.reconVendorManagement && !isVendor) {
      delete newFilters.dealerAndTaskTypeFilter;
    }
    if (!flags.reconVendorManagement || isVendor) {
      delete newFilters.assignedToVendor;
    }

    if (!isVendor) {
      try {
        let parsedArray = JSON.parse(`${newFilters.assignedToTechnicianFilter}`);
        parsedArray = parsedArray.filter((v) => v.assignedTo !== null);
        newFilters.assignedToTechnicianFilter = JSON.stringify(parsedArray);
      } catch {
        newFilters.assignedToTechnicianFilter = '[]';
      }
    }

    fetchPage(localPager.start, localPager.limit, newFilters, sort);
  };

  useEffect(() => {
    if (isVendor || (isRootUser && vendorShipSelected)) {
      if (menuState[TECHNICIAN]?.values?.length === 0 && internalUsers?.length > 0 && isVendorAdmin) {
        menuState[TECHNICIAN].values = internalUsers;
        setMenuState({ ...menuState });
      }
      if (menuState[DEALERSHIP]?.values?.length === 0 && newDealerships?.length > 0) {
        menuState[DEALERSHIP].values = newDealerships;
        setMenuState({ ...menuState });
        if (isRefreshPage) {
          // When user vendor first login
          // Why we call onQueryChange() in here. Because at the moment data dealership is set to menustate
          onQueryChange();
        }
      }
    }
  }, [internalUsers, newDealerships]);

  useEffect(() => {
    if (tasksData) {
      const pageNum = (pager.start - 1) / pager.limit + 1;
      const rangeMax = pageNum * pager.limit;
      const to = Math.min(rangeMax, tasksData.count);
      setDisplayRange(`${Math.min(pager.start, to)}-${to}`);
      setCount(tasksData.count);

      if (selectedTaskId) {
        const taskToSelect = tasksData.items.find((t) => t.id === selectedTaskId);
        if (taskToSelect) {
          setSelectedCard({ contentType: detailContents.TASK_DETAILS, model: taskToSelect, documents: documents });
        }
      } else if (selectedCard.contentType) {
        // If the task changes while the drawer is open, update the task - occurs when a user declines or completes a task from the task details view
        const model = tasksData.items.find((x) => x.id === selectedCard.model.id);
        if (model) {
          setSelectedCard({ ...selectedCard, model, documents: documents });
        }
      }

      if (isFirstFetch) {
        setIsFirstFetch(false);
      }
    }
  }, [tasksData, selectedTaskId, documents]);

  const updateTransientMenuState = (transientMenuState, menu, subMenu, queryItems) => {
    if (queryItems) {
      if (subMenu && transientMenuState[menu][subMenu].type === 'checkbox') {
        Object.keys(transientMenuState[menu][subMenu].values || {}).forEach(
          (key) => (transientMenuState[menu][subMenu].values[key] = queryItems.includes(key))
        );
      } else if (subMenu && transientMenuState[menu][subMenu].type === 'radio') {
        transientMenuState[menu][subMenu].values = queryItems;
      } else if (subMenu && transientMenuState[menu][subMenu].type === 'datepicker') {
        transientMenuState[menu][subMenu].values = { startDate: queryItems[0], endDate: queryItems[1] };
      } else if (menu === TECHNICIAN && queryItems.includes('All')) {
        Object.keys(transientMenuState[menu].values).forEach((key) => (transientMenuState[menu].values[key] = true));
      } else {
        if (menu === TECHNICIAN || menu === GROUP || (menu === DEALERSHIP && isVendor)) {
          let queryItemValues = Object.values(JSON.parse(queryItems));
          if (queryItemValues?.length > 0) {
            transientMenuState[menu].values.forEach((item, index) => {
              if (menu === TECHNICIAN) {
                let indexQueryItem = queryItemValues.findIndex((f) => f.assignedTo === item.id);
                if (isVendor && flags?.reconVendorManagement) {
                  if (indexQueryItem !== -1) {
                    transientMenuState[menu].values[index].isChecked = true;
                    transientMenuState[menu].values[index].isAssignedDirectly = true;
                  } else {
                    transientMenuState[menu].values[index].isChecked = false;
                    transientMenuState[menu].values[index].isAssignedDirectly = false;
                  }
                } else {
                  if (indexQueryItem !== -1) {
                    transientMenuState[menu].values[index].isChecked = true;
                    transientMenuState[menu].values[index].isAssignedDirectly =
                      queryItemValues[indexQueryItem].assignedDirectly;
                    if (transientMenuState[menu].values[index].isMemberOfAnyGroup) {
                      transientMenuState[menu].values[index].isAssignedThruGroup =
                        queryItemValues[indexQueryItem].assignedThruGroup;
                    }
                    if (
                      !transientMenuState[menu].values[index].isAssignedThruGroup ||
                      !transientMenuState[menu].values[index].isAssignedDirectly
                    ) {
                      transientMenuState[menu].values[index].isShowMinusOutlinedIcon = true;
                    } else {
                      transientMenuState[menu].values[index].isShowMinusOutlinedIcon = false;
                    }
                  } else {
                    transientMenuState[menu].values[index].isChecked = false;
                    transientMenuState[menu].values[index].isAssignedDirectly = false;
                    if (transientMenuState[menu].values[index].isMemberOfAnyGroup) {
                      transientMenuState[menu].values[index].isAssignedThruGroup = false;
                    }
                  }
                }
              }
              if (menu === GROUP) {
                let queryItem = queryItemValues.find((f) => f.assignedToGroup === item.id);
                if (queryItem !== undefined) {
                  transientMenuState[menu].values[index].isChecked = true;
                  transientMenuState[menu].values[index].internalGroupUsers.forEach((member) => {
                    let indexMember = -1;
                    if (queryItem.assignedToMembers?.length > 0) {
                      indexMember = queryItem?.assignedToMembers.findIndex((userId) => userId === member.userId);
                    }
                    member.isChecked = indexMember !== -1;
                    if (member.userId === null) {
                      member.isChecked = queryItem.unassigned;
                    }
                  });

                  // Check 'All Unassigned Tasks' icon
                  if (transientMenuState[menu].values[index].internalGroupUsers?.length > 0) {
                    let isShowMinusOutlinedIcon = transientMenuState[menu].values[index].internalGroupUsers.some(
                      (member) => !member.isChecked
                    );
                    transientMenuState[menu].values[index].isShowMinusOutlinedIcon = isShowMinusOutlinedIcon;
                  }
                  let groupHasMemberUnassignedIsUnChecked = transientMenuState[menu].values.find((newMenuWithType) =>
                    newMenuWithType.internalGroupUsers.some(
                      (groupMember) => groupMember.fullName === 'Unassigned' && !groupMember.isChecked
                    )
                  );
                  let groupHasMemberUnassignedIsChecked = transientMenuState[menu].values.find((menuWithType) =>
                    menuWithType.internalGroupUsers.some(
                      (groupMember) => groupMember.fullName === 'Unassigned' && groupMember.isChecked
                    )
                  );
                  let indexMenuAllUnassignedTasks = transientMenuState[menu].values.findIndex(
                    (f) => f.fullName === allUnassignedTasksLabel
                  );
                  if (indexMenuAllUnassignedTasks !== -1) {
                    if (groupHasMemberUnassignedIsUnChecked !== undefined) {
                      if (
                        groupHasMemberUnassignedIsChecked !== undefined &&
                        queryItemValues.find((f) => f.unassigned)
                      ) {
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = true;
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isChecked = true;
                      } else {
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isChecked = false;
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = false;
                      }
                    } else {
                      transientMenuState[menu].values[indexMenuAllUnassignedTasks].isChecked = true;
                      transientMenuState[menu].values[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = false;
                    }
                  }
                } else {
                  transientMenuState[menu].values[index].isChecked = false;
                  if (transientMenuState[menu].values[index].internalGroupUsers?.length > 0) {
                    transientMenuState[menu].values[index].internalGroupUsers.forEach((member) => {
                      member.isChecked = false;
                    });
                  }
                }
              }
              if (menu === DEALERSHIP && isVendor) {
                let queryItem = queryItemValues.find((f) => f.dealerId === item.id);
                if (queryItem !== undefined) {
                  transientMenuState[menu].values[index].isChecked = true;
                  transientMenuState[menu].values[index].taskTypes.forEach((type) => {
                    let indexMember = -1;
                    if (queryItem.taskTypes?.length > 0) {
                      indexMember = queryItem?.taskTypes.findIndex((taskTypeId) => taskTypeId === type.id);
                    }
                    type.isChecked = indexMember !== -1;
                  });
                  let isShowMinusOutlinedIcon = transientMenuState[menu].values[index].taskTypes.some(
                    (subTaskType) => !subTaskType.isChecked
                  );
                  transientMenuState[menu].values[index].isShowMinusOutlinedIcon = isShowMinusOutlinedIcon;
                } else {
                  transientMenuState[menu].values[index].isChecked = false;
                  if (transientMenuState[menu].values[index].taskTypes?.length > 0) {
                    transientMenuState[menu].values[index].taskTypes.forEach((member) => {
                      member.isChecked = false;
                    });
                  }
                }
              }
            });
          } else {
            transientMenuState[menu].values.forEach((item) => {
              if (item.isChecked) {
                item.isChecked = false;
                item.isShowSubMenu = false;
                item.isShowMinusOutlinedIcon = false;
                if (menu === TECHNICIAN) {
                  item.isAssignedDirectly = false;
                  item.isAssignedThruGroup = false;
                }
                if (menu === GROUP) {
                  // item.isYourGroup = false;
                  if (item.internalGroupUsers?.length > 0) {
                    item.internalGroupUsers.forEach((member) => {
                      if (member.isChecked) {
                        member.isChecked = false;
                      }
                    });
                  }
                }
              }
            });
          }
        } else {
          Object.keys(transientMenuState[menu].values || {}).forEach(
            (key) => (transientMenuState[menu].values[key] = queryItems.includes(key))
          );
        }
      }
    } else {
      if (!isExternalTechnicianRole && (menu === TECHNICIAN || menu === GROUP)) {
        let menuStateIsChecked = transientMenuState[menu].values.filter((f) => f.isChecked);
        if (menuStateIsChecked?.length > 0) {
          menuStateIsChecked.forEach((item) => {
            if (item.isChecked) {
              item.isChecked = false;
              item.isShowSubMenu = false;
              item.isShowMinusOutlinedIcon = false;
              if (menu === TECHNICIAN) {
                item.isAssignedDirectly = false;
                item.isAssignedThruGroup = false;
              }
              if (menu === GROUP) {
                if (item.internalGroupUsers?.length > 0) {
                  let groupMemberIsChecked = item.internalGroupUsers.filter((f) => f.isChecked);
                  if (groupMemberIsChecked?.length > 0) {
                    item.internalGroupUsers.forEach((member) => {
                      if (member.isChecked) {
                        member.isChecked = false;
                      }
                    });
                  }
                }
              }
            }
          });
        }
      }
    }
    if (!fromDashboard && ['Completion Date', 'datepicker'].includes(subMenu)) {
      transientMenuState[menu][subMenu].disabled = true;
      transientMenuState[menu][subMenu].visible = false;
    }
    return transientMenuState;
  };

  const getTaskTypeIds = (taskType) => taskTypesData.filter((t) => taskType.includes(t.name)).map((t) => t.id);
  const getTaskCategoriesIds = (taskCategory) =>
    taskCategoriesData.filter((t) => taskCategory.includes(t.name)).map((t) => t.id);
  const getAssignedToIds = (assignedTo) => {
    if (assignedTo.includes('All')) {
      return users.map((t) => t.id);
    } else {
      return users.filter((t) => assignedTo.includes(t.fullName)).map((t) => t.id);
    }
  };

  const sortOptionsInitialize = {
    TIME_IN_TASK_DESC: 'Time in Task (longest)',
    TIME_IN_TASK: 'Time in Task (shortest)',
    COMPLETED_DATE_DESC: 'Completed Date (oldest)',
    COMPLETED_DATE: 'Completed Date (newest)',
    PROFITTIME_SCORE_DESC: 'ProfitTime Score (highest)',
    PROFITTIME_SCORE: 'ProfitTime Score (lowest)'
  };

  const fetchPage = (start, limit, filters, sort) => {
    if (isFirstFetch === null) setIsFirstFetch(true);
    dispatch(tasksActions.setPager({ start, limit }));
    dispatch(
      tasksActions.getData(
        currentDealerId,
        start,
        limit,
        filters,
        sort,
        isInitialTasksFetch ? 0 : 600,
        flags,
        fromDashboard,
        hasTasksEdit
      )
    ); // Since the initialization of this page has to fetch taskTypes and users, which is taking a long time already, removing the minimum load time on the first tasks fetch
    setFilters(filters);

    let sortOptionsInitializeTemp = sortOptionsInitialize;
    if (!hasReconProfitTime) {
      sortOptionsInitializeTemp = Object.keys(sortOptionsInitialize)
        .filter((key) => key !== 'PROFITTIME_SCORE_DESC' && key !== 'PROFITTIME_SCORE')
        .reduce((result, current) => {
          result[current] = sortOptionsInitialize[current];
          return result;
        }, {});
    }

    //If the Complete filter is selected, or NO status filters are selected (so all results are shown), show the Completed sort filters
    if (
      (!filters?.status?.includes(COMPLETED) || !filters.status?.includes(COMPLETED, IN_PROGRESS, DECLINED)) &&
      filters?.status !== undefined
    ) {
      const sortFiltered = Object.keys(sortOptionsInitializeTemp)
        .filter((key) => key !== 'COMPLETED_DATE_DESC' && key !== 'COMPLETED_DATE')
        .reduce((result, current) => {
          result[current] = sortOptionsInitializeTemp[current];
          return result;
        }, {});

      setSortValues(sortFiltered);
    } else {
      setSortValues(sortOptionsInitializeTemp);
    }

    setIsInitialTasksFetch(false);
    if (flags?.reconVendorManagement) toggleFiltersChangedByUser(false);
  };

  const onChangeLimit = (pageNum, pageSize) => {
    const newLimit = pageSize;
    const newStart = Math.floor(pager.start / newLimit) * newLimit + 1;
    setTasksPerPage(pageSize);
    fetchPage(newStart, newLimit, filters, sort);
    dispatch(tasksActions.setPager({ ...pager, limit: newLimit, start: newStart }));
  };

  //useEffect for updating pageSize (limit) stored in redux store
  useEffect(() => {
    if (pager.limit !== tasksPerPage) {
      dispatch(tasksActions.setPager({ ...pager, limit: tasksPerPage }));
    }
  }, [tasksPerPage, pager]);

  const onChangePage = (pageNum) => {
    pageNum = pageNum || 1;
    const newStart = (pageNum - 1) * pager.limit + 1;
    fetchPage(newStart, pager.limit, filters, sort);
    dispatch(tasksActions.setPager({ ...pager, start: newStart }));
  };
  //#endregion

  //#region Drawer
  const [drawerWidth, setDrawerWidth] = useState(450);
  const [onUpdateTaskForm, setOnUpdateTaskForm] = useState(false);
  const [showDiscardButton, setShowDiscardButton] = useState(false);
  const [openTdpOnDiscard, setOpenTdpOnDiscard] = useState(false);

  useEffect(() => {
    let width = 580;
    if (selectedCard.contentType === detailContents.TASK_DETAILS) {
      // Set the drawer width for taskDetails to 2 thirds of the viewport width or max 1200px
      const maxWidth = 1232;
      const percentage = hasReconProfitTime ? 0.95 : 0.7;
      const calculatedWidth = Math.round(
        Math.max(document.documentElement.clientWidth, window.innerWidth) * percentage
      );
      width = Math.min(calculatedWidth, maxWidth);
    }
    setDrawerWidth(width);
    setOnUpdateTaskForm(
      [detailContents.EDIT_COMPLETED_TASK, detailContents.COMPLETE_TASK, detailContents.DECLINE_TASK].includes(
        selectedCard.contentType
      )
    );
  }, [selectedCard.contentType]);

  const handleCanCloseDrawer = () => {
    if (!selectedCard.model?.saving) {
      if (onUpdateTaskForm && selectedCard.contentType === detailContents.TASK_DETAILS) {
        setOnUpdateTaskForm(false);
      } else if (
        onUpdateTaskForm &&
        selectedCard.contentType === detailContents.EDIT_COMPLETED_TASK &&
        !showDiscardButton
      ) {
        setShowDiscardButton(true);
      } else {
        closeDrawer();
      }
    }
    return true;
  };

  const closeDrawer = () => {
    const model = selectedCard.model;
    setSelectedCard({ contentType: null }); //setting contentType to null will close the drawer
    setShowDiscardButton(false);
    // reset flag isNotification when open TDP from Notification then back to Inventory
    dispatch(vdpActions.setNotification(false));
    // reset line item
    dispatch(lineItemsActions.resetLineItem());

    //if we had navigated to a task by id, go back to the normal list
    if (openTdpOnDiscard) {
      openTDP(model);
    } else if (selectedTaskId) {
      navigate('/tasks');
    }
  };

  const handleCanOpenTDP = () => {
    const model = selectedCard.model;
    setOpenTdpOnDiscard(true);
    if (handleCanCloseDrawer()) {
      openTDP(model);
    }
  };

  const openTDP = (model) => {
    dispatch(tasksActions.getCommentsForTaskEntity(model.vehicle.id, model.id));
    setSelectedCard({ contentType: detailContents.TASK_DETAILS, model });
    setOpenTdpOnDiscard(false);
  };
  //#endregion

  /*
  If we switch from a state where we have tasks have the "Completed On" sort filters, to a state where they are not available,
  we default to "TIME IN TASK DESCENDING" as out default sort value.
*/
  const decideSortValuesShown = (sort) => {
    if (!sortValues || (sortValues && Object.keys(sortValues).length === 0)) return '';
    if (!sortValues[sort]) {
      sort = sortOptionDefault.TASK;
      setQuery({ sort: sort }, 'pushIn');
    }
    return sortValues[sort];
  };

  const sortMenu = (
    <CommonMenu
      onClick={({ key }) => {
        setSortDropdownIsOpen(false);
        if (key !== sort) {
          setQuery({ sort: key }, 'pushIn');
          toggleFiltersChangedByUser(true);
        }
      }}
    >
      {Object.entries(sortValues).map((o) => (
        <CommonMenuItem key={o[0]} data-isselected={sort === o[0]}>
          {o[1]}
        </CommonMenuItem>
      ))}
    </CommonMenu>
  );

  const getDefaultAssignedToNewFilter = () => {
    return JSON.stringify([
      {
        assignedTo: authState.user.id,
        assignedDirectly: true,
        assignedThruGroup: false
      }
    ]);
  };

  return (
    <RootContext.Provider
      value={{ rootDealerId: isRootUser && vendorShipSelected ? selectedCard?.model?.vehicle?.dealerId : null }}
    >
      <CommonListPageContainer>
        <Affix offsetTop={0} target={() => scrollcontainer.current}>
          <TaskFilters
            menuState={menuState}
            setMenuState={setMenuState}
            displayRange={displayRange}
            query={query}
            setQuery={setQuery}
            hasViewAnyTask={hasViewAnyTask}
            hasWorkOwnTasks={hasWorkOwnTasks}
            toggleFiltersChangedByUser={toggleFiltersChangedByUser}
            authState={authState}
            setHasNoFilters={setHasNoFilters}
          />
        </Affix>
        <CommonSortRow>
          <div>
            {tasksData && (
              <span>
                Showing&nbsp;<strong>{displayRange}&nbsp;</strong>of&nbsp;<strong>{tasksData?.count}&nbsp;</strong>tasks
              </span>
            )}
          </div>
          <BorderlessSelect
            menu={sortMenu}
            dropdownIsOpen={sortDropdownIsOpen}
            setDropdownIsOpen={setSortDropdownIsOpen}
            label="Sort By"
            // Decide if the sort state is available with the filters selected
            selectedLabel={decideSortValuesShown(sort)}
          />
          {typeof hasTasksEdit === 'boolean' && (
            <ExportFunctionality
              filters={filters}
              sort={sort}
              exportType="Task"
              currentEntityId={currentDealerId}
              doesExportHiddenTasks={flags?.reconHiddenFilter && fromDashboard && hasTasksEdit}
            />
          )}
        </CommonSortRow>
        <StyledListContainer
          screenSize={screenSize}
          hasNoFilters={hasNoFilters}
          is-bridge-user={authState.isBridgeUser}
          reconFooter={flags?.reconFooter}
          global-alert={hasGlobalAlert}
        >
          {
            {
              [apiStatusConstants.IS_FETCHING]: (
                <TaskList
                  isLoading={true}
                  items={Array.from(Array(10)).map((_, i) => ({ id: i }))}
                  selectedCard={selectedCard}
                  reconProfitTime={hasReconProfitTime}
                  reconFooter={flags?.reconFooter}
                  globalAlert={hasGlobalAlert}
                  pagination={
                    <StyledPagerCard size="small" bordered={true}>
                      <Pagination
                        size="small"
                        showSizeChanger
                        showQuickJumper
                        total={count}
                        pageSize={Number(pager.limit)}
                        pageSizeOptions={['10', '25', '50', '100']}
                        current={Math.floor(pager.start / pager.limit) + 1}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        onChange={onChangePage}
                        onShowSizeChange={onChangeLimit}
                      />
                    </StyledPagerCard>
                  }
                />
              ),
              [apiStatusConstants.SUCCEEDED]: (
                <TaskList
                  isLoading={false}
                  items={tasksData ? tasksData.items : []}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  showDealerName={showDealerName}
                  reconProfitTime={hasReconProfitTime}
                  reconFooter={flags?.reconFooter}
                  hasNoFilters={hasNoFilters}
                  isBridgeUser={authState.isBridgeUser}
                  globalAlert={hasGlobalAlert}
                  pagination={
                    <StyledPagerCard size="small" bordered={true}>
                      <Pagination
                        size="small"
                        showSizeChanger
                        showQuickJumper
                        total={count}
                        pageSize={Number(pager.limit)}
                        pageSizeOptions={['10', '25', '50', '100']}
                        current={Math.floor(pager.start / pager.limit) + 1}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        onChange={onChangePage}
                        onShowSizeChange={onChangeLimit}
                      />
                    </StyledPagerCard>
                  }
                />
              ),
              [apiStatusConstants.FAILED]: (
                <>
                  <Error />
                  {flags?.reconFooter && (
                    <StyledFooterContainer>
                      <Footer />
                    </StyledFooterContainer>
                  )}
                </>
              )
            }[fetchStatus]
          }
        </StyledListContainer>
        {!flags?.reconFooter && (
          <Affix offsetBottom={0} target={() => scrollcontainer.current}>
            <StyledPagerCard size="small" bordered={true}>
              <Pagination
                size="small"
                showSizeChanger
                showQuickJumper
                total={count}
                pageSize={Number(pager.limit)}
                pageSizeOptions={['10', '25', '50', '100']}
                current={Math.floor(pager.start / pager.limit) + 1}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
              />
            </StyledPagerCard>
          </Affix>
        )}
      </CommonListPageContainer>

      <Drawer
        closable={false}
        destroyOnClose={true}
        placement="right"
        width={drawerWidth}
        visible={!!selectedCard.contentType}
        onClose={() => handleCanCloseDrawer()}
        bodyStyle={{ padding: '0', overflow: 'hidden' }}
      >
        {selectedCard.contentType && (
          <>
            {
              {
                [detailContents.TASK_DETAILS]: (
                  <TaskDetails
                    documents={selectedCard.documents}
                    task={selectedCard.model}
                    onForm={onUpdateTaskForm}
                    setOnForm={setOnUpdateTaskForm}
                    contentType={detailContents.TASK_DETAILS}
                    handleCloseDrawer={() => closeDrawer()}
                    activeTab={selectedCard.activeTab} // use for select activity
                  />
                ),
                [detailContents.COMMUNICATIONS]: (
                  <DrawerContainer
                    headerLabel={<CommonHeaderLabel>Messages</CommonHeaderLabel>}
                    onClose={() => closeDrawer()}
                    vehicle={selectedCard.model.vehicle}
                    onHeaderClick={handleCanOpenTDP}
                  >
                    <FullCommunicationsFromTasksStore
                      threadCommunications={false}
                      containerId={selectedCard.model.id}
                      vehicleId={selectedCard.model.vehicle.id}
                      communicationsFetchStatus={selectedCard.model.communicationsFetchStatus}
                      passedInCommunications={selectedCard.model.comments.items || []}
                      initialFocus={selectedCard.initialFocus}
                      location={communicationContexts.TASKS_PAGE_MESSAGE_DRAWER}
                      isTDP={true}
                    />
                  </DrawerContainer>
                ),
                [detailContents.COMPLETE_TASK]: (
                  <TaskFormDrawer
                    task={selectedCard.model}
                    closeDrawer={closeDrawer}
                    onForm={onUpdateTaskForm}
                    setOnForm={setOnUpdateTaskForm}
                    handleCanOpenTDP={handleCanOpenTDP}
                    user={authState.user}
                    contentType={selectedCard.contentType}
                  />
                ),
                [detailContents.DECLINE_TASK]: (
                  <TaskFormDrawer
                    task={selectedCard.model}
                    closeDrawer={closeDrawer}
                    onForm={onUpdateTaskForm}
                    setOnForm={setOnUpdateTaskForm}
                    handleCanOpenTDP={handleCanOpenTDP}
                    isDecliningTask={true}
                    user={authState.user}
                    contentType={selectedCard.contentType}
                  />
                ),
                [detailContents.EDIT_COMPLETED_TASK]: (
                  <TaskFormDrawer
                    task={selectedCard.model}
                    closeDrawer={closeDrawer}
                    onForm={onUpdateTaskForm}
                    setOnForm={setOnUpdateTaskForm}
                    handleCanOpenTDP={handleCanOpenTDP}
                    showDiscardButton={showDiscardButton}
                    setShowDiscardButton={setShowDiscardButton}
                    contentType={selectedCard.contentType}
                  />
                )
              }[selectedCard.contentType]
            }
          </>
        )}
      </Drawer>
    </RootContext.Provider>
  );
};

const StyledPagerCard = styled(Card)`
  .ant-card& {
    margin: 0;
    height: 47px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-card-body {
      padding: 0;
    }
  }
`;
const StyledFooterContainer = GridArea('footer', 2, 1);

export default TasksPresentation;
