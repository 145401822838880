import { Row, Col } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatPhoneNumber } from 'utils/stringUtils';
import CommonFormRender from '../../common/CommonFormRender';

const toResetWhenSwitchingcountry = ['address', 'address2', 'city', 'state', 'postalCode'];

const CustomForm = ({ formInputs }) => {
  const {
    control,
    setValue,
    formState: { errors, isDirty },
    watch,
    clearErrors,
    reset
  } = useFormContext();

  let watchCountry = watch('country');

  useEffect(() => {
    if (isDirty && !!watchCountry) {
      for (var toCheck of toResetWhenSwitchingcountry) {
        setValue(toCheck, '');
      }
      clearErrors();
    }
  }, [watchCountry]);

  useEffect(() => {
    const toReset = {};
    formInputs.data.forEach((formInput) => {
      if (['phone', 'businessPhone'].includes(formInput.name)) {
        toReset[formInput.name] = formatPhoneNumber(formInput.value ?? '');
      } else if (formInput.name === 'country') {
        toReset[formInput.name] = formInput.value ?? 'United States';
      } else {
        toReset[formInput.name] = formInput.value ?? '';
      }
    });

    reset(toReset, {
      keepDirty: true
    });
  }, [formInputs]);

  const formRef = useRef();

  return (
    <form ref={formRef}>
      <Row type="flex" justify="start" gutter={[16, 16]}>
        <Col xs={24}>
          <CommonFormRender
            forms={[formInputs]}
            formRef={formRef}
            errors={errors}
            control={control}
            watchCountry={watchCountry}
          />
        </Col>
      </Row>
    </form>
  );
};

export default CustomForm;
