import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LINE_ITEMS_CHANGE_STATUS } from 'app-constants/lineItemConstants';
import { vdpActions } from 'store/vdpStore';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useDrop } from 'react-dnd';

//our functions, components
import { stripReferences } from 'utils/arrayUtils';
import {
  createTaskGroupStructure,
  isTaskGroupCompleted,
  isTaskGroupIncomplete,
  isStepCompleted,
  isInProgressTaskGroup
} from './helpers/functions';
import { COMPLETED, IN_PROGRESS, DECLINED, PENDING } from 'app-constants/taskStatusTypes';
import { RECON_PLAN } from 'app-constants/planTypes';
import { DRAGGABLE_ITEM, DROP_PLACEHOLDER, MAIN, TASK_GROUP, TASK, TASK_GROUP_TASK } from './helpers/types';
import {
  dragItemAtMainLevel,
  reorderExistingTaskGroup,
  moveTaskFromOneTaskGroupToAnother,
  moveTaskInTopDropAreaIntoATaskGroup,
  moveTaskOutOfATaskGroup,
  createNewTaskGroupByMergingTwoTasksIntoAGroup,
  addTaskToEndOfATaskGroup,
  addTaskGroupTaskFromOneTaskGroupToTheEndOfAnotherTaskGroup,
  removeAllDropPlaceholders,
  setTaskStatusesToPending,
  resequenceUS510688
} from './helpers/DndContainerFunctions';
import DragList from './DragList';
import CustomDragLayer from './CustomDragLayer';
import { useDragDropContext } from 'utils/contexts';
import { taskProgressLabels } from 'app-constants';
import { getTaskProgress } from 'components/layout/tasks/common/taskUtils';
import { useUpdateEffect } from 'hooks';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';

/**
 * TaskGroupsDnd manages the connection between taskGroups outside of the drag and drop area and the taskGroups (also called steps in this component tree)
 * It's rendered UI is the list of draggable items, the DragList presentation component
 * This is a container component which controls the logic of the list drag actions
 * taskGroupsWithDropPlaceholders is rendered in this list - taskGroupsWithDropPlaceholders is setStructuredTaskGroups but with DropPlaceholders injected into it
 * DropPlaceholders are our way of rendering the dropline indicating where a dragged task will end up being dropped.
 * If viewing this work on the web, it is the blue line that appears in between or after or before tasks
 */
const TaskGroupsDnd = ({ flags }) => {
  const { planSource, planStarted, taskGroups, setTaskGroups, inProgressStep } = useDragDropContext();

  const [taskGroupsWithDropPlaceholders, setTaskGroupsWithDropPlaceholders] = useState([]);
  const lastRecordedTaskGroupsProp = useRef([]);
  const lastRecordedInProgressStepProp = useRef([]);

  const [, drop] = useDrop({ accept: DRAGGABLE_ITEM });

  const lineItemChangeStatus = useSelector((state) => state.vdp.lineItemChangeStatus);
  const dispatch = useDispatch();

  const getTaskStatus = (flags, task, taskGroupIsInProgress) => {
    if (!planStarted) return PENDING;

    if (task.status) {
      if (task.declined || task.status === DECLINED || task.declinedOn) {
        if (flags.reconTaskCollapse && [IN_PROGRESS, PENDING, DECLINED].indexOf(task?.status) > -1) {
          return taskGroupIsInProgress ? IN_PROGRESS : PENDING;
        }
        return DECLINED;
      } else {
        if ([DECLINED, COMPLETED].indexOf(task?.status) > -1 || task.declinedOn) {
          if (flags.reconTaskCollapse) {
            return taskGroupIsInProgress ? IN_PROGRESS : PENDING;
          }
          if (task.declinedOn) {
            return DECLINED;
          }
          return task?.status;
        } else {
          return taskGroupIsInProgress ? IN_PROGRESS : PENDING;
        }
      }
    } else {
      return task?.status;
    }
  };
  /* eslint-disable no-param-reassign */
  const pushDropPlaceholder = (
    task,
    newTaskGroupsWithDropPlaceholders,
    dropPlaceholder,
    parentGroupId,
    lastTaskGroupDropPlaceholder = false,
    taskGroupIsInProgress = false,
    reconTaskCollapseFlag = false
  ) => {
    // there are drop placeholders potentially added to both the top and bottom of a task item
    // a task item can be in progress, pending, completed, declined, past goal or overdue
    // drop placeholder styles should be red for overdue or declined, white (or no styles) for completed or pending, orange for past goal, or blue for in progress
    // overdue and past goal are determined by checking taskProgress
    // in progress, declined, completed, pending are all task statuses
    // check task status

    const taskStatus = getTaskStatus(flags, task, taskGroupIsInProgress);

    if (parentGroupId !== MAIN && planSource === RECON_PLAN && planStarted) {
      // this means that the dropplaceholder is being added to a task group - WE ONLY WANT THESE STYLES APPLIED TO A DROP PLACEHOLDER IF THE DROP PLACEHOLDER IS IN A TASK GROUP
      // if the drop placeholder is being added to a task group, we need to provide some additional information to the drop placeholder component to determine some styling
      // specifically, we need to look at task progress and task status

      // if task status is In Progress, is the task progress overdue or past goal?
      if (taskStatus === IN_PROGRESS) {
        const { status } = getTaskProgress(task);
        const { PastGoal, Overdue } = taskProgressLabels;

        if (reconTaskCollapseFlag) {
          newTaskGroupsWithDropPlaceholders.push({
            ...dropPlaceholder,
            state: taskStatus,
            parentGroupId,
            id: uuidv4(),
            taskId: task.id,
            lastTaskGroupDropPlaceholder
          });
        } else {
          if ([PastGoal, Overdue].includes(status)) {
            newTaskGroupsWithDropPlaceholders.push({
              ...dropPlaceholder,
              state: status,
              parentGroupId,
              id: uuidv4(),
              taskId: task.id,
              lastTaskGroupDropPlaceholder
            });
          } else {
            newTaskGroupsWithDropPlaceholders.push({
              ...dropPlaceholder,
              state: taskStatus,
              parentGroupId,
              id: uuidv4(),
              taskId: task.id,
              lastTaskGroupDropPlaceholder
            });
          }
        }
      } else if (taskStatus === DECLINED) {
        newTaskGroupsWithDropPlaceholders.push({
          ...dropPlaceholder,
          state: taskStatus,
          parentGroupId,
          id: uuidv4(),
          taskId: task.id,
          lastTaskGroupDropPlaceholder
        });
      } else {
        newTaskGroupsWithDropPlaceholders.push({
          ...dropPlaceholder,
          state: 'no styling state',
          parentGroupId,
          id: uuidv4(),
          lastTaskGroupDropPlaceholder
        });
      }
    } else {
      if (reconTaskCollapseFlag && taskStatus === IN_PROGRESS && parentGroupId !== MAIN) {
        newTaskGroupsWithDropPlaceholders.push({
          ...dropPlaceholder,
          state: taskStatus,
          parentGroupId,
          id: uuidv4(),
          taskId: task.id,
          lastTaskGroupDropPlaceholder
        });
      } else {
        newTaskGroupsWithDropPlaceholders.push({
          ...dropPlaceholder,
          state: 'no styling state',
          parentGroupId,
          id: uuidv4(),
          lastTaskGroupDropPlaceholder
        });
      }
    }
  };
  /* eslint-enable no-param-reassign */

  const addDropPlaceholders = (structuredTaskGroups, parentGroupId = MAIN) => {
    const newTaskGroups = stripReferences(structuredTaskGroups);
    const dropPlaceholder = {
      modelType: DROP_PLACEHOLDER,
      parentGroupId
    };

    /* eslint-disable no-param-reassign */
    return newTaskGroups.reduce((newTaskGroupsWithDropPlaceholders, taskGroup, index, originalTaskGroupsArray) => {
      const isStepFullyCompleted = isStepCompleted(taskGroup);
      const taskGroupIsInProgress =
        parentGroupId === MAIN
          ? isInProgressTaskGroup(taskGroup, inProgressStep, flags) // taskGroup is either a standalone task or an actual task group
          : isInProgressTaskGroup({ ...taskGroup, id: parentGroupId }, inProgressStep, flags); // taskGroup is a task within a task group, need to use id of parent task group when checking if this particular task is in progress

      if (index === 0 && !isStepFullyCompleted) {
        // if first task or group is completed, don't place a dropplaceholder before it
        // task groups are sorted with completed tasks at the top, so if a taskGroup contains any completed tasks, we do not want to add a drop placeholder before it
        // because task groups also have this function ran to determine if a dropplaceholder should be added before the first task in the task group, we still need to provide parentGroupId to determine styling of the dropplaceholder
        pushDropPlaceholder(
          taskGroup,
          newTaskGroupsWithDropPlaceholders,
          dropPlaceholder,
          parentGroupId,
          false,
          taskGroupIsInProgress,
          flags.reconTaskCollapse
        );
      }
      newTaskGroupsWithDropPlaceholders.push(taskGroup);
      // don't push a dropPlaceholder if the next task is completed as well
      // how to determine if next task is completed?
      // first make sure index of the current task is not at the end of the taskGroup array
      // second if the next task is completed, it will have a status of COMPLETED
      const stepContainsCompletedTasks =
        index < originalTaskGroupsArray.length - 1 && isStepCompleted(originalTaskGroupsArray?.[index + 1]);

      if (!stepContainsCompletedTasks) {
        //in addition to pushing a placeholder, now that we know that the task item doesn't contain completed tasks, for task groups only, we need to determine what the statuses are for

        //determine if this drop placeholder will be the last in a task group container
        const isLastTaskGroupDropPlaceholder = parentGroupId !== MAIN && index === originalTaskGroupsArray.length - 1;
        pushDropPlaceholder(
          taskGroup,
          newTaskGroupsWithDropPlaceholders,
          dropPlaceholder,
          parentGroupId,
          isLastTaskGroupDropPlaceholder,
          taskGroupIsInProgress,
          flags.reconTaskCollapse
        );
      }

      if (taskGroup.modelType === TASK_GROUP && !isTaskGroupCompleted(taskGroup)) {
        taskGroup.tasks = addDropPlaceholders(taskGroup.tasks, taskGroup.id);
      }

      return newTaskGroupsWithDropPlaceholders;
    }, []);
    /* eslint-enable no-param-reassign */
  };

  /**
   * setStructureForRemainingSourceTasks is used for determining if a task has been dragged from and dropped outside of a task group if the task group should display anymore
   * remainingTasks is the number of tasks that remains after the drop - if it is equal to 1 then the task group shouldn't show - it is a single task
   */
  const setStructureForRemainingSourceTasks = (sourceGroup, newTaskGroups, draggedItemData) => {
    const remainingTasks = sourceGroup.tasks.filter((x) => x.modelType !== DROP_PLACEHOLDER);
    if (remainingTasks.length === 1) {
      // there is only 1 item left in the task group, reset the task group to represent a single task
      const indexOfTaskGroup = newTaskGroups.findIndex((x) => x.id === draggedItemData.parentGroupId);
      newTaskGroups.splice(indexOfTaskGroup, 1); //will mutate newTaskGroups from moveItem function - but newGroups is a copy of other data so this isn't an issue
      remainingTasks[0].modelType = TASK;
      remainingTasks[0].parentGroupId = MAIN;
      remainingTasks[0].group = {
        id: sourceGroup.taskGroupId,
        name: 'Task Group',
        sequence: null
      };
      newTaskGroups.splice(indexOfTaskGroup, 0, remainingTasks[0]);
    } else if (remainingTasks.length === 0) {
      //remainingTasks should never be 0, because the task group should not be rendered if remainingTasks.length is 1 and this function will not be run then
      const indexOfTaskGroup = newTaskGroups.findIndex((x) => x.id === draggedItemData.parentGroupId);
      newTaskGroups.splice(indexOfTaskGroup, 1);
    }
  };

  //moveItem is invoked on a drop of a DraggableItem
  //draggedItemData is the object of data set in the item object of the useDrag call for the dragged DraggableItem
  //droppedOnItemData is the object of data passed in as props for the item that dragged DraggableItem is dragged over and dropped on (either a dropplaceholder or a task or a task group)

  const moveItem = (draggedItemData, droppedOnItemData) => {
    if (lineItemChangeStatus !== LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM) {
      dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
    }
    //for this function to run, there has to be at least two tasks in a plan
    let newTaskGroups = stripReferences(taskGroupsWithDropPlaceholders);

    /* *
     * resequenceStartSequence is calculated from either the draggedItemData or droppedOnItemData index (depending on whether the draggedItem was dragged higher or lower in the plan),
     * it is used when resequencing the plan after we've moved something, to preserve pass through sequences unless they were just directly affected
     * */
    let resequenceStartSequence = null,
      dragStartSequence = null;
    if (draggedItemData.passthrough || droppedOnItemData.passthrough) {
      // Pass through task was involved. Make resequenceStartSequence start at the sequence of the pass through task, to handle if it was a "preserved" sequence
      resequenceStartSequence = draggedItemData.passthrough ? draggedItemData.sequence : droppedOnItemData.sequence;
      resequenceStartSequence = isNaN(resequenceStartSequence) ? Number.MAX_SAFE_INTEGER : resequenceStartSequence;
    }

    if (droppedOnItemData.modelType === DROP_PLACEHOLDER) {
      /** droppedOnItemData.modelType === 'dropPlaceholder' means that what is being dragged has been dropped between other task items in the plan
       * it is not merging a task into a task group or creating a new task group from merging into another task
       */
      if (draggedItemData.parentGroupId === MAIN && droppedOnItemData.parentGroupId === MAIN) {
        /**
         * both parentGroupIds being MAIN means that the drop placement is in the top level of the plan (not a task group)
         * and the item dragged started in the top level of the plan
         */
        if (draggedItemData.index < droppedOnItemData.index) {
          // Item was moved down
          let stepsBeforeDropPlaceholder = taskGroupsWithDropPlaceholders
            .slice(0, droppedOnItemData.index)
            .filter((x) => x.modelType !== DROP_PLACEHOLDER);
          dragStartSequence =
            stepsBeforeDropPlaceholder.length > 0
              ? Math.min(draggedItemData.sequence ?? Number.MAX_SAFE_INTEGER, stepsBeforeDropPlaceholder.pop().sequence)
              : draggedItemData.sequence ?? Number.MAX_SAFE_INTEGER;
        } else {
          // Item was moved up
          let stepsAfterDropPlaceholder = taskGroupsWithDropPlaceholders
            .slice(droppedOnItemData.index)
            .filter((x) => x.modelType !== DROP_PLACEHOLDER);
          dragStartSequence =
            stepsAfterDropPlaceholder.length > 0
              ? Math.min(draggedItemData.sequence ?? Number.MAX_SAFE_INTEGER, stepsAfterDropPlaceholder[0].sequence)
              : draggedItemData.sequence ?? Number.MAX_SAFE_INTEGER;
        }

        newTaskGroups = dragItemAtMainLevel(newTaskGroups, draggedItemData, droppedOnItemData);
      } else if (draggedItemData.parentGroupId === droppedOnItemData.parentGroupId) {
        /**
         * since the case where both parentGroupIds are MAIN has already been checked, the only other case where parentGroupIds are the same is
         * when a task inside of a group is being reordered to another spot inside the same group
         * No resequencing of the groups is required
         **/
        newTaskGroups = reorderExistingTaskGroup(newTaskGroups, draggedItemData, droppedOnItemData);
      } else if (draggedItemData.parentGroupId !== MAIN && droppedOnItemData.parentGroupId !== MAIN) {
        /**
         * otherwise, if both parentGroupIds aren't the same and they are both not MAIN, then this is moving task from one task group to another
         * No resequencing of the groups is required
         **/
        newTaskGroups = moveTaskFromOneTaskGroupToAnother(
          newTaskGroups,
          draggedItemData,
          droppedOnItemData,
          setStructureForRemainingSourceTasks
        );
      } else if (droppedOnItemData.parentGroupId !== MAIN) {
        /**
         * dropPlaceholders are inside of task groups. when droppedOnItemData.parentGroupId !== MAIN, then this means that draggedItemData was, otherwise the else if block above would have been true and that would have ran
         * so this is for moving a task in the top level of the plan into an existing task group
         **/
        let destinationGroup = newTaskGroups?.find((taskGroups) => taskGroups.id === droppedOnItemData.parentGroupId);
        dragStartSequence = Math.min(destinationGroup.sequence, draggedItemData.sequence);
        newTaskGroups = moveTaskInTopDropAreaIntoATaskGroup(newTaskGroups, droppedOnItemData, draggedItemData);
      } else if (droppedOnItemData.parentGroupId === MAIN) {
        /**
         * droppedOnItemData.parentGroupId === MAIN and !(draggedItemData.parentGroupId !== MAIN && droppedOnItemData.parentGroupId !== MAIN) means that
         * draggedItemData.parentGroupId !== MAIN which means that the dragged item came from a task group
         * task from a group is being pulled out of a task group
         **/
        const sourceGroupIndex = newTaskGroups?.findIndex(
          (taskGroups) => taskGroups.id === draggedItemData.parentGroupId
        );
        const sourceGroup = newTaskGroups?.[sourceGroupIndex];
        if (sourceGroupIndex < droppedOnItemData.index) {
          // Item was moved down
          let stepsBeforeDropPlaceholder = taskGroupsWithDropPlaceholders
            .slice(0, droppedOnItemData.index)
            .filter((x) => x.modelType !== DROP_PLACEHOLDER);
          dragStartSequence =
            stepsBeforeDropPlaceholder.length > 0
              ? Math.min(sourceGroup.sequence, stepsBeforeDropPlaceholder.pop().sequence)
              : sourceGroup.sequence;
        } else {
          // Item was moved up
          let stepsAfterDropPlaceholder = taskGroupsWithDropPlaceholders
            .slice(droppedOnItemData.index)
            .filter((x) => x.modelType !== DROP_PLACEHOLDER);
          dragStartSequence =
            stepsAfterDropPlaceholder.length > 0
              ? Math.min(sourceGroup.sequence, stepsAfterDropPlaceholder[0].sequence)
              : sourceGroup.sequence;
        }
        newTaskGroups = moveTaskOutOfATaskGroup(
          newTaskGroups,
          droppedOnItemData,
          draggedItemData,
          setStructureForRemainingSourceTasks
        );
      }
    } else if ([TASK_GROUP, TASK_GROUP_TASK].includes(droppedOnItemData.modelType)) {
      if (draggedItemData.modelType === TASK) {
        dragStartSequence = Math.min(
          droppedOnItemData.parentSequence || droppedOnItemData.sequence,
          draggedItemData.sequence
        );
        newTaskGroups = addTaskToEndOfATaskGroup(newTaskGroups, droppedOnItemData, draggedItemData);
      } else if (draggedItemData.modelType === TASK_GROUP_TASK) {
        // No resequencing of the groups is required
        newTaskGroups = addTaskGroupTaskFromOneTaskGroupToTheEndOfAnotherTaskGroup(
          newTaskGroups,
          draggedItemData,
          droppedOnItemData,
          setStructureForRemainingSourceTasks
        );
      }
    } else {
      // if droppedOnItem isn't a drop placeholder, then it must be another task and the user is forming a new task group from merging two tasks
      dragStartSequence = Math.min(droppedOnItemData.parentSequence, draggedItemData.parentSequence);
      newTaskGroups = createNewTaskGroupByMergingTwoTasksIntoAGroup(
        newTaskGroups,
        draggedItemData,
        droppedOnItemData,
        setStructureForRemainingSourceTasks,
        flags
      );
    }

    resequenceStartSequence =
      resequenceStartSequence !== null ? Math.min(resequenceStartSequence, dragStartSequence) : dragStartSequence;
    prepareTaskGroupsDataForRerender(newTaskGroups, planSource, resequenceStartSequence);
  };

  const prepareTaskGroupsDataForRerender = (taskGroups, planSource, resequenceStartSequence) => {
    let mutableTaskGroups = stripReferences(taskGroups);
    //first remove all drop placeholders - makes updating sequences so much easier
    mutableTaskGroups = removeAllDropPlaceholders(mutableTaskGroups);
    //second make sure sequence is in order
    if (resequenceStartSequence !== null)
      mutableTaskGroups = resequenceUS510688(mutableTaskGroups, resequenceStartSequence);

    if (planSource === RECON_PLAN) {
      //if this is in a recon plan, we then need to consider how reordering affects task statuses
      mutableTaskGroups = setTaskStatusesUS510688(mutableTaskGroups, flags);
    }
    //add drop placeholders back
    mutableTaskGroups = addDropPlaceholders(mutableTaskGroups);
    setTaskGroupsWithDropPlaceholders(mutableTaskGroups);
  };

  const setTaskStatusesUS510688 = (taskGroups, flags) => {
    // tasks can be completed, in progress or pending
    // the only statuses that we want to change are in progress and pending
    // a completed task can not become in progress or pending
    // a task becomes in progress once the task is moved into the active task placement
    // completed tasks can come before it, active task placement is immediately after any completed tasks
    // if the task item in the active task placement is a task group, then all non-completed tasks in the task group should be in progress
    // if the task item is a single task, that task should be in progress
    // anything that comes after the in progress task item should have a status of pending
    const mutableTaskGroups = stripReferences(taskGroups);
    //completed task items must be groups that have every task completed or they must be single tasks that have a status of COMPLETED
    const completeSteps = mutableTaskGroups.filter(isStepCompleted);
    const incompleteSteps = mutableTaskGroups.filter((x) => !isStepCompleted(x));

    let foundFirstNonPassThroughIncompleteStep = false;
    incompleteSteps.forEach((step) => {
      if (step.modelType === TASK_GROUP) {
        if (!foundFirstNonPassThroughIncompleteStep) {
          foundFirstNonPassThroughIncompleteStep = true;
          step.tasks = step.tasks.map((task) => {
            if (flags) {
              if (
                (!task.declined && task.status !== COMPLETED) ||
                (task.status !== COMPLETED && task.status !== DECLINED)
              ) {
                return { ...task, status: IN_PROGRESS };
              } else {
                return task;
              }
            } else {
              if (task.status !== COMPLETED && task.status !== DECLINED) {
                return { ...task, status: IN_PROGRESS };
              } else {
                return task;
              }
            }
          });
        } else {
          return { ...step, tasks: setTaskStatusesToPending(step, flags) };
        }
      } else {
        if (!foundFirstNonPassThroughIncompleteStep) {
          if (flags) {
            if (
              (!step.declined && step.status !== COMPLETED) ||
              (step.status !== COMPLETED && step.status !== DECLINED)
            )
              step.status = IN_PROGRESS;
          } else {
            if (step.status !== COMPLETED && step.status !== DECLINED) step.status = IN_PROGRESS;
          }
          if (!step.passthrough) foundFirstNonPassThroughIncompleteStep = true;
        } else if (step.status !== DECLINED) {
          step.status = PENDING;
        }
      }
    });

    return [...completeSteps, ...incompleteSteps];
  };

  useEffect(() => {
    //only want to make changes to taskGroupsWithDropPlaceholders when there is an actual change to taskGroups or inProgressStep - these can be when the user has completed/deleted/edited a task or when user has re-arranged task
    //to track actual changes to taskGroups, we compare the current taskGroups prop to the last recorded value of taskGroups was - we store that in lastRecordedTaskGroupsProp and only update that state when there is found to be a change in the value of taskGroups,
    //same with inProgressStep (we store the last recorded value of that in lastRecordedInProgressStepProp)
    if (
      !!taskGroups &&
      (!isEqual(taskGroups, lastRecordedTaskGroupsProp.current) ||
        !isEqual(inProgressStep, lastRecordedInProgressStepProp.current))
    ) {
      //sort taskGroups before using them
      const completedTaskGroups = stripReferences(taskGroups?.filter(isTaskGroupCompleted));
      const incompleteTaskGroups = stripReferences(taskGroups?.filter(isTaskGroupIncomplete));
      const sortedTaskGroups = [...completedTaskGroups, ...incompleteTaskGroups];
      // add dnd modeltypes to taskGroups data passed into component
      const modeledTaskGroups = createTaskGroupStructure(sortedTaskGroups);
      // update record of task groups and inProgressStep
      lastRecordedTaskGroupsProp.current = taskGroups;
      lastRecordedInProgressStepProp.current = inProgressStep;
      // create temporary taskGroupsWithDropPlaceholders that we will compare against what we have as the value
      const temporaryTaskGroupsWithDropPlaceholders = addDropPlaceholders(modeledTaskGroups);
      // check to make sure that there's a different between what we already have recorded as taskGroupsWithDropPlaceholders and the temporaryTaskGroupsWithDropPlaceholders
      if (!isEqual(taskGroupsWithDropPlaceholders, temporaryTaskGroupsWithDropPlaceholders)) {
        setTaskGroupsWithDropPlaceholders(temporaryTaskGroupsWithDropPlaceholders);
      }
    }
  }, [taskGroups, inProgressStep]);

  useUpdateEffect(() => {
    //keep taskGroups state higher in component tree (ReconPlan and TemplatePlan and higher up) in sync with changes that are made to taskGroupsWithDropPlaceholders
    //remove drop placeholders and then remove model structures
    const structuredTaskGroups = removeAllDropPlaceholders(taskGroupsWithDropPlaceholders);
    let unstructuredTaskGroups = structuredTaskGroups.map((stg, index) => {
      const taskGroupId = stg.taskGroupId || uuidv4();
      const tg =
        stg.modelType === TASK
          ? {
              ...stg.group,
              id: taskGroupId,
              tasks: [{ ...stg, taskGroupId }]
            }
          : { ...stg };
      ['modelType', 'parentGroupId'].forEach((key) => delete tg[key]);
      tg.sequence = tg.sequence || index + 1; //Since sequence of 0 is not valid, this works. Don't override sequences of groups, because have to preserve sequence of pass through tasks!
      tg.tasks = tg.tasks.map((t, index) => {
        let task = {
          ...t,
          sequence: t.taskSequence ?? t.sequence,
          taskGroupId: tg.id,
          groupSequence: index + 1
          //sequence: ++taskCount //No longer set sequence of tasks here, because have to preserve sequence of pass through tasks!
        };
        ['group', 'parentGroupId', 'modelType', 'parentSequence'].forEach((key) => delete task[key]);
        return task;
      });
      return tg;
    });
    if (!!taskGroups && !isEqual(taskGroups, unstructuredTaskGroups)) {
      setTaskGroups(unstructuredTaskGroups);
    }
  }, [taskGroupsWithDropPlaceholders]);

  return (
    <div>
      <StyledContentContainer>
        <div ref={drop}>
          {/* can't figure out why the structure needs to be three divs deep, but I've tried modifying this
             and there's always a weird issue that occurs where the preview appears center screen
             when removing one of these div parents and moving the ref */}
          <DragList taskGroupsWithDropPlaceholders={taskGroupsWithDropPlaceholders} moveItem={moveItem} />
        </div>
        <CustomDragLayer />
      </StyledContentContainer>
    </div>
  );
};
//#endregion

//#region styles
const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 6px;
  margin-top: -15px; //To account for drop placeholder at top
`;

//#endregion

export default withLDConsumer()(TaskGroupsDnd);
