import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

export const newSigninSettings = {
  client_id: process.env.REACT_APP_AUTH_BRIDGE_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/login`,
  response_type: 'code',
  scope: 'bridge.signin',
  authority: process.env.REACT_APP_AUTH_BRIDGE_URL,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/silent_renew_callback.html`,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.sessionStorage })
};

export const userManagerNewSignin = createUserManager(newSigninSettings);

export const newDomainSettings = {
  client_id: 'recon_web',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/login`,
  response_type: 'token id_token',
  scope: 'recon_api openid profile',
  authority: process.env.REACT_APP_AUTH_BASE_URL_NEW_DOMAIN,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/silent_renew_callback.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.sessionStorage })
};

export const userManagerNewDomain = createUserManager(newDomainSettings);
