import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { devLogger } from 'utils/logging';

export const setDealerCookie = (value, vautoRedomainingCookiePartition) => {
  devLogger.log(`Setting CurrentEntity cookie to: ${value}`);

  if (window.cookieStore) {
    window.cookieStore.delete({
      name: 'CurrentEntity',
      partitioned: !vautoRedomainingCookiePartition,
      domain: process.env.REACT_APP_COOKIE_DOMAIN_NEW
    });
  } else {
    Cookies.remove('CurrentEntity', getStandardCookieAttributes(vautoRedomainingCookiePartition));
  }
  Cookies.set('CurrentEntity', value, getStandardCookieAttributes(vautoRedomainingCookiePartition));
};

export const getDealerCookie = () => {
  return getCookie('CurrentEntity');
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

const getStandardCookieAttributes = (vautoRedomainingCookiePartition) => {
  var attributes = {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN_NEW,
    expires: new Date('9999-12-31'),
    partitioned: vautoRedomainingCookiePartition
  };
  // Do not set these during local development.
  // We can't set "secure" because it requires local https
  // We can't set "sameSite" because it requires "secure=true"
  if (attributes.domain !== '') {
    attributes.sameSite = 'None';
    attributes.secure = true;
  }

  return attributes;
};
/**
 * Creates a cookie watcher that will poll the provided cookie name and pass
 *  the value through the provided callback function.
 *
 * Note that the callback function will only be called if a change to the
 *  cookie was detected.
 *
 * @param {*} cookieName Name of the cookie to poll
 * @param {*} cookieCallback Callback function to send the cookie value to
 * @param {*} pollingRate The rate in milliseconds to poll the cookie
 */
export const CookieWatcher = (cookieName, cookieCallback, pollingRate = 1000) => {
  // Keep track of changes locally so we don't constantly call the callback function
  const [localCookie, setLocalCookie] = useState(getCookie(cookieName));

  // Poll for changes to the cookie
  useEffect(() => {
    const interval = setInterval(() => {
      setLocalCookie(getCookie(cookieName));
    }, pollingRate);
    return () => clearInterval(interval);
  });

  // If changes were detected, callback
  useEffect(() => {
    cookieCallback(localCookie);
  }, [localCookie]);
};

export const checkIf3rdPartyCookiesAreBlocked = async ({
  vauto3rdPartyNoticeAvailable,
  vauto3rdPartyNoticeStorage
}) => {
  // Only show for Chrome bases browsers (Chrome, Edge, other Chromium based)
  const isChromiumBasedBrowser = navigator.userAgent.includes('Chrome');
  if (!isChromiumBasedBrowser) {
    return;
  }

  // Check if notice should be shown or not based on toggle
  if (!vauto3rdPartyNoticeAvailable) {
    return;
  }

  // Use Session Storage or LocalStorage - (Per Login Notice or longer lifetime)
  const storage = vauto3rdPartyNoticeStorage ? sessionStorage : localStorage;
  const storageKey = 'vauto-3rd-party-notice';
  const hasLearnedMore = storage.getItem(storageKey);

  // If already learned more, don't show again
  if (hasLearnedMore === 'true') {
    return;
  }

  const response = await fetch(
    'https://signin.coxautoinc.com/bridgebar/user-details/mine?solutionId=VAT&bridgeEnv=prod',
    { credentials: 'include' }
  );
  return response.status === 401;
};
