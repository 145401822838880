import { FullCommunications, withCommunications } from 'components/communications';
import React, { useState } from 'react';
import { Tabs } from 'antd';
import { communicationContexts, documentationContexts } from 'app-constants';
import styled from 'styled-components';
import { CommonStrongText } from 'components/styledComponents';
import { DocumentUpload, withDocuments } from '../../../../documents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { TaskHistory } from '../TaskHistory';
import { useSelector } from 'react-redux';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { RootContext } from 'utils/contexts';
const { TabPane } = Tabs;

const FullCommunicationsFromTasksStore = withCommunications('tasksStore')(FullCommunications);
const DocumentsFromDocumentStore = withDocuments(documentationContexts.TASKS_PAGE)(DocumentUpload);

const TaskActivity = ({ task, activeTab, documents, flags }) => {
  const [activedKey, setActivedKey] = useState(activeTab ? activeTab : '1');
  const [paddingBottom, setPaddingBottom] = useState(70);
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  return (
    <RootContext.Provider value={{ rootDealerId: isRootUser && vendorShipSelected ? task?.vehicle?.dealerId : null }}>
      <StyledActivityContainer>
        <StyledHeaderLabel>Activity</StyledHeaderLabel>
        <StyledTab
          defaultActiveKey="1"
          activeKey={activedKey}
          onChange={(activedKey) => {
            setActivedKey(activedKey);
          }}
        >
          <TabPane key="1" tab={'Messages'}>
            <StyledCommunicationsList tasks-comments={task.comments?.items.length}>
              <FullCommunicationsFromTasksStore
                threadCommunications={false}
                containerId={task.id}
                vehicleId={task.vehicleId}
                communicationsFetchStatus={task.communicationsFetchStatus}
                passedInCommunications={task.comments?.items || []}
                initialFocus={task.id} //set focus to the main message input
                location={communicationContexts.TASK_DETAILS_PAGE}
                isTDP={true}
              />
            </StyledCommunicationsList>
          </TabPane>
          <TabPane key="2" tab="Documents">
            <StyleDocumentsContainer padding-bottom={paddingBottom}>
              <DocumentsFromDocumentStore
                task={task}
                taskId={task.id}
                stockNumber={task.vehicle.stockNumber}
                documents={documents?.documentsData}
                documentsFetchStatus={documents?.documentsFetchStatus}
                documentsUploadStatus={documents?.documentsUploadStatus}
                documentsDeleteStatus={documents?.documentsDeleteStatus}
                setPaddingBottom={setPaddingBottom}
                flags={flags}
              />
            </StyleDocumentsContainer>
          </TabPane>
          <TabPane key="3" tab="History">
            <StyleHistoryContainer>
              <TaskHistory task={task} documents={documents?.documentsData} />
            </StyleHistoryContainer>
          </TabPane>
        </StyledTab>
      </StyledActivityContainer>
    </RootContext.Provider>
  );
};

//#region Styled Components for Activity

const StyledTab = styled(Tabs)`
  .ant-tabs& {
    .ant-tabs-bar {
      margin: 0;
      border-bottom: initial;

      .ant-tabs-nav-container {
        margin-left: 24px;

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav {
              color: ${({ theme }) => theme.colors.gray};

              .ant-tabs-tab {
                margin-right: 4px;
                padding: 10px 16px 12px 16px;
                :hover {
                  color: ${({ theme }) => theme.colors.navy};
                }
              }

              .ant-tabs-ink-bar {
                background-color: ${({ theme }) => theme.colors.red};
              }

              .ant-tabs-tab-active {
                color: ${({ theme }) => theme.colors.navy};
              }
            }
          }
        }
      }
    }
  }
`;
const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledActivityContainer = styled(StyledColumnContainer)`
  height: 100%;
`;
const StyledCommunicationsList = styled.div`
  height: calc(100vh - 275px);
  overflow-y: ${(props) => props['tasks-comments'] > 0 && 'auto'};
`;
const StyleDocumentsContainer = styled.div`
  height: calc(100vh - 275px);
  padding-top: 20px;
  padding-bottom: ${(props) => props['padding-bottom'] + 'px'};
`;
const StyleHistoryContainer = styled.div`
  height: calc(100vh - 275px);
  padding-top: 24px;
  padding-bottom: 30px;
  overflow-y: auto;
`;

const StyledHeaderLabel = styled(CommonStrongText)`
  padding: 34px 24px 20px 24px;
  line-height: 10px;
`;
//#endregion

export default withLDConsumer()(TaskActivity);
