import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { Select } from 'antd';

import { useScreenSize } from 'hooks';

//extracting out Option component from ant design StyledSelect component export
const { Option } = Select;

const Dropdown = ({
  //setting default props in the parameter list
  options = [
    {
      value: '',
      label: ''
    }
  ],
  defaultValue = '',
  changeFilters = () => {}
}) => {
  const screenSize = useScreenSize();

  return (
    <StyledSelect value={defaultValue} onChange={changeFilters} screenSize={screenSize}>
      {options.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </StyledSelect>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  defaultValue: PropTypes.string,
  changeFilters: PropTypes.func
};

export const StyledSelect = styled(Select)`
  width: ${({ screenSize }) => {
    if (screenSize === '<= 850px') return '115px';
    if (screenSize === '<= 960px') return '120px';
    return '140px';
  }};
  &.ant-select {
    margin-left: ${({ screenSize }) => {
      if (screenSize === '<= 850px') return '2px';
      if (screenSize === '<= 960px') return '4px';
      return '10px';
    }};
  }
`;

export default Dropdown;
