import React, { useState } from 'react';
import { Row, Col, Select } from 'antd';
import { Controller } from 'react-hook-form';
import { StyledRow, StyledSectionTitle, StyledSelect, StyledFormLabel, StyledInput, StyledTextArea } from './formStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumber } from 'utils/stringUtils';
import { theme } from 'styles/appTheme';
import InputErrorMessage from './InputErrorMessage';

const { Option } = Select;

const CommonFormRender = ({ forms, formRef, errors, watchCountry = 'United States', control, gutter = [16, 25] }) => {
  const [stateOpen, setStateOpen] = useState(false);
  const [countryOpen, setCountryOpen] = useState(false);

  const checkStateSelect = (name) => {
    return name === 'state' ? stateOpen : countryOpen;
  };

  const renderDropdownOptions = (formName, isCanada = false, data = []) => {
    if (formName === 'country') {
      return data.map((country) => (
        <Option value={country} key={country}>
          {country}
        </Option>
      ));
    } else if (formName === 'state') {
      if (isCanada) {
        return data.CA.map((province) => (
          <Option value={province} key={province}>
            {province}
          </Option>
        ));
      } else {
        return data.US.map((state) => (
          <Option value={state} key={state}>
            {state}
          </Option>
        ));
      }
    }
  };

  const customOnChange = (e, formInput, onChange) => {
    if (['phone', 'businessPhone'].includes(formInput.name)) {
      onChange(formatPhoneNumber(e.target.value));
    } else {
      onChange(e.target.value);
    }
  };

  const getInputLabel = (formInput) => {
    if (watchCountry === 'Canada' && !!formInput.labelCanada) {
      return formInput.labelCanada;
    } else {
      return formInput.label;
    }
  };

  return (
    <Row type="flex" gutter={[16, 25]}>
      {forms.map((formEntry, index) => (
        <Col xs={24} key={formEntry.key ?? index}>
          <StyledRow type="flex" justify="center" gutter={gutter}>
            {formEntry.showTitle && (
              <Col xs={24} style={{ marginBottom: formEntry.marginBottom ?? '0px' }}>
                <StyledSectionTitle>{formEntry.title}</StyledSectionTitle>
              </Col>
            )}
            {formEntry.data.map((formInput, i) => (
              <Col
                sm={formInput.sizeSM ? formInput.sizeSM : formInput.size ? formInput.size : 24}
                xs={formInput.sizeSmall ?? 24}
                key={formInput.key}
              >
                <StyledFormLabel>{getInputLabel(formInput)}</StyledFormLabel>
                {formInput.required && <StyledFormLabel style={{ color: 'red' }}>{' *'}</StyledFormLabel>}

                {formInput.dropdown ? (
                  <Controller
                    control={control}
                    name={formInput.name}
                    render={({ field }) => {
                      const fieldToSpread = { ...field };
                      delete fieldToSpread.ref;
                      return (
                        <StyledSelect
                          {...fieldToSpread}
                          size="large"
                          getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
                          showAction={['focus']}
                          aria-label={formInput.name}
                          suffixIcon={
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className="ant-select-suffix"
                              style={{
                                transition: 'all 0.3s ease',
                                transform: `rotate(${checkStateSelect(formInput.name) ? '0.5turn' : 0})`
                              }}
                            />
                          }
                          open={formInput.name === 'state' ? stateOpen : countryOpen}
                          onFocus={() => {
                            if (formInput.name === 'state') {
                              setStateOpen(true);
                            } else {
                              setCountryOpen(true);
                            }
                          }}
                          onBlur={() => {
                            if (formInput.name === 'state') {
                              setStateOpen(false);
                            } else {
                              setCountryOpen(false);
                            }
                          }}
                          notFoundContent={null}
                          defaultActiveFirstOption={false}
                          style={{
                            marginTop: '6px',
                            marginBottom: (errors[formInput.name] || formEntry.showTitle) && '8px'
                          }}
                          error={errors[formInput.name]}
                          onSelect={() => {
                            if (formRef.current) {
                              let form = formRef.current;
                              let nextName = formEntry.data[i + 1].name;
                              for (const element of form.elements) {
                                if (element.name === nextName) {
                                  element.focus();
                                }
                              }
                            }
                          }}
                        >
                          {renderDropdownOptions(formInput.name, watchCountry === 'Canada', formInput.data)}
                        </StyledSelect>
                      );
                    }}
                  />
                ) : formInput.multiline ? (
                  <Controller
                    control={control}
                    name={formInput.name}
                    render={({ field }) => {
                      const fieldToSpread = { ...field };
                      delete fieldToSpread.ref;
                      return (
                        <StyledTextArea
                          {...fieldToSpread}
                          placeholder="Include an optional message"
                          autoSize={{ minRows: 7, maxRows: 14 }}
                          onChange={(e) => customOnChange(e, formInput, field.onChange)}
                          style={{
                            resize: 'none',
                            marginTop: '6px',
                            marginBottom: (errors[formInput.name] || formEntry.showTitle) && '8px'
                          }}
                          error={errors[formInput.name]}
                          disabled={formInput.disabled}
                          aria-label={formInput.name}
                        />
                      );
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={formInput.name}
                    render={({ field }) => {
                      const fieldToSpread = { ...field };
                      delete fieldToSpread.ref;
                      return (
                        <StyledInput
                          {...fieldToSpread}
                          placeholder={formInput.placeholder}
                          size="large"
                          onChange={(e) => customOnChange(e, formInput, field.onChange)}
                          style={{
                            marginTop: '6px',
                            marginBottom: (errors[formInput.name] || formEntry.showTitle) && '8px'
                          }}
                          error={errors[formInput.name]}
                          maxLength={formInput.maxLength}
                          disabled={formInput.disabled}
                          aria-label={formInput.name}
                        />
                      );
                    }}
                  />
                )}
                {errors[formInput.name] && (
                  <InputErrorMessage divStyle={{ color: theme.colors.red, lineHeight: theme.lineHeights.sm }}>
                    {errors[formInput.name].message}
                  </InputErrorMessage>
                )}
              </Col>
            ))}
          </StyledRow>
        </Col>
      ))}
    </Row>
  );
};

export default CommonFormRender;
