import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Typography, Tag } from 'antd';
import { navigate } from '@reach/router';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonLinkButton } from 'components/styledComponents';
import { stripReferences } from 'utils/arrayUtils';

const { Text } = Typography;

const FilterTags = ({
  flags = {},
  filterTags,
  removeFilterTag,
  resetLink,
  toggleFiltersChangedByUser = () => {},
  setValueSearch = (value) => {},
  removedAllTag = (value) => {}
}) => {
  //counts for +X more tags that are not currently viewed
  const [totalTagsNotInView, setTotalTagsNotInView] = useState(0);
  //tracks with actual filters in filterTags, but keeps information about the tags, like which tags are currently visible (property inView) and calculated information about the position of the tags on the screen
  // this helps with calculating number of hidden tags and for placing the filter controls to the right of the last visible tag
  const [filterTagsWithInViewTracking, setFilterTagsWithInViewTracking] = useState(() =>
    filterTags?.map?.((tag) => ({ ...tag, inView: false }))
  );
  //this is for tracking where to place the filter controls (+X more, Reset Filters) to place them to the right of the last tag when the tag container is collapsed
  const [placementOfFilterControls, setPlacementOfFilterControls] = useState(null);
  const [expand, toggleExpand] = useState(false); //control state for expanding/collapsing the filter tag container to show all or hide tags
  const [windowWidth, setWindowWidth] = useState(0); //track state for the size of the window width - used as explained below
  const [showLessTagVisible, setShowLessTagVisible] = useState(true); //when there are tags hidden from view because they are on a second line, you can expand the container to show all of the tags. when you do this, a 'show less' button shows up. under certain circumstances, we want to hide this button. this state keeps track of when to show the show less button

  //detect changes to actual filters, and adjust the
  useEffect(() => {
    if (filterTags.length !== filterTagsWithInViewTracking.length) {
      if (filterTags.length > 0 && filterTagsWithInViewTracking.length === 0) {
        setFilterTagsWithInViewTracking(filterTags?.map?.((tag) => ({ ...tag, inView: false })));
      }
      if (filterTags.length < filterTagsWithInViewTracking.length) {
        //find tags that have been removed and re-evaluate total for not in view
        const removedTags = filterTagsWithInViewTracking.filter(
          (tag) => !filterTags.find((filterTag) => filterTag.label === tag.label && filterTag.id === tag.id)
        );
        if (removedTags.length > 0) {
          const countOfTagsRemovedThatWereNotInView = removedTags.reduce(
            (count, tag) => count + (tag.inView ? 0 : 1),
            0
          );
          setTotalTagsNotInView((previousTotal) => previousTotal - countOfTagsRemovedThatWereNotInView);
          setFilterTagsWithInViewTracking((previousTags) => {
            return previousTags.filter((tag) =>
              filterTags.some((filterTag) => filterTag.label === tag.label && filterTag.id === tag.id)
            );
          });
        }
      }
    }
  }, [JSON.stringify(filterTags), JSON.stringify(filterTagsWithInViewTracking)]);

  useEffect(() => {
    //any time that a tag's inView property changes, there will be a change to filterTagsWithInViewTracking
    //placement of tags that are being tracked for inView have placement updates when the tag goes in and out of view because there are updates made to the tag's entry data
    //any of these things will cause this useEffect to run again
    //toggling expand via the 'show less' or '+X more' buttons will also cause this useEffect to run
    //**PURPOSE OF USE EFFECT - when the filter tags container is collapsed to show only the first row of the tags, when window resizing occurs, different tags come in and out of view, thus causing where the right side of the last visible tag is shown
    //when the filter tag container is collapsed, the way that the filter controls are adjusted to be close to the tags is by calculating the position on the page of the right side of the last filter tag and placing the controls outside of the controls container and placing them 30px to the right of the last tag
    //that's why we calculate maxRightSideXCoordinate and add 30 to it
    if (filterTagsWithInViewTracking.length > 0 && !expand) {
      const inViewTags = filterTagsWithInViewTracking.filter((tag) => tag.inView);
      if (inViewTags.length > 0) {
        const maxRightSideXCoordinate = inViewTags.reduce(
          (maxValue, tag) => (tag.rightSideXCoordinate ? Math.max(maxValue, tag.rightSideXCoordinate) : maxValue),
          0
        );
        setPlacementOfFilterControls(maxRightSideXCoordinate + 30);
      } else {
        setPlacementOfFilterControls(null);
      }
    }
    if (filterTagsWithInViewTracking.length > 0 && expand) {
      determineTagPlacementAndSetShowLessTagVisibility();
    }
  }, [JSON.stringify(filterTagsWithInViewTracking), expand]);

  //need to track window width for
  //- calculating position of tags to determine if "Show less" should actually be shown (explained below)
  //- length of the filter tags container for calculating placement of the filter controls (used in conjunction with the calculations done in the useEffect above)
  useEffect(() => {
    detectWindowWidthAndPlacementOfFilterControls();
    window.addEventListener('resize', detectWindowWidthAndPlacementOfFilterControls);
    return () => window.removeEventListener('resize', detectWindowWidthAndPlacementOfFilterControls);
  }, []);

  //this is a side effect function that only runs on window resize events
  //its external from React and using refs or checking the values of our other variables here doesn't work
  //we would have to pass filterTags into this function to check values but then that would trigger this function any time that the other variables changed. We only want this to run on window resize, after the initial calculation
  //that's why it is necessary to get the DOM nodes external from React
  //because it is an external side effect from window events, this is alright to do here
  //we do want to keep track of the setShowLessTagVisible function though, so we need to define the function inside the component
  //we need to do this separate from tracking inView, because once expand is true, inView is true for all tags. a tag that is visible on the second line has no change in that value when moving from the second line to the first line
  //refs are not enough to get live updates on the x, y positioning of the tags, we have to have it connected to some event that runs, and unfortunately in that case, as can be seen here, this function does not receive updated information about anything internal to a react component, so we have to use DOM functions
  //accessing the DOM allows us access to getBoundingClientRect which gives us the "live" placement for elements
  const detectWindowWidthAndPlacementOfFilterControls = () => {
    setWindowWidth(window.innerWidth);
    determineTagPlacementAndSetShowLessTagVisibility();
  };
  const determineTagPlacementAndSetShowLessTagVisibility = () => {
    //detect placement of filter tags - question being answered here is at this new window width, are all of the filter tags present on the same line, i.e. is there wrapping of the filters to multiple lines?
    //if there is no wrapping, then there is no need to show the "show less" tag button because that's only for hiding the second through nth rows
    const tagElements = document.querySelectorAll('.filter-tag');
    const firstTagElement = tagElements?.[0]; //we need to compare the top position of the first filter tag to the top position of the last filter tag
    const lastTagElement = tagElements?.[tagElements?.length - 1];
    const lastTagElementBoundingClientRect = lastTagElement?.getBoundingClientRect?.(); //gets us the position on the page of
    const firstTagElementBoundingClientRect = firstTagElement?.getBoundingClientRect?.();
    const lastTagElementTop = lastTagElementBoundingClientRect?.top;
    const firstTagElementTop = firstTagElementBoundingClientRect?.top;
    if (!isNaN(lastTagElementTop) && !isNaN(firstTagElementTop)) {
      const differenceInTop = Math.abs(lastTagElementTop - firstTagElementTop);
      if (!isNaN(differenceInTop)) {
        if (differenceInTop < 5) {
          setShowLessTagVisible(false);
        }
        differenceInTop > 30 && setShowLessTagVisible(true); //differenceInTop should actually be close to 40 if they are on different lines, but because of differences in values across browsers I'm using 30, which would definitely indicate that the show less button was on at least a line down from the first filter tag
      }
    }
  };

  const ResetFiltersButton = ({ renderedInTagList, toggleFiltersChangedByUser }) => {
    return (
      <CommonLinkButton
        className="label"
        onClick={() => {
          toggleFiltersChangedByUser(true);
          navigate(resetLink);
          toggleExpand(false);
          setValueSearch('');
          removedAllTag('');
        }}
        style={{ top: !renderedInTagList ? 1 : null }}
      >
        <span style={{ fontSize: 14 }}> Reset Filters</span>
      </CommonLinkButton>
    );
  };

  const getFilterTagLabel = (tag) => {
    if (tag.key === 'Needs Approval' && flags.reconApproveButton) {
      return tag.label.replace('Needs Approval', 'Pending Approval');
    } else if (tag.menu === 'Assigned To' && flags.reconVendorManagement) {
      return tag.label.replace('Assigned To', 'Technician');
    } else {
      return tag.label;
    }
  };

  return (
    filterTags.length > 0 && (
      <FilterTagContainer expand={expand}>
        <Text style={{ width: 56, paddingTop: 3 }} className="label">
          Applied
        </Text>{' '}
        <TagContainer>
          {filterTags.map((tag) => (
            <FilterTagWithRef
              key={tag.key + tag.id + tag.menu} //key can be the same between different menues (for example: Task Type Detail and Category Detail would have the same key, which leads to defect #)
              // Adding menu is sufficient as labels are unique in each menu.
              tag={tag}
              label={getFilterTagLabel(tag)}
              removeFilterTag={removeFilterTag}
              setTotalTagsNotInView={setTotalTagsNotInView}
              setFilterTagsWithInViewTracking={setFilterTagsWithInViewTracking}
            />
          ))}
          {expand && (
            <>
              {showLessTagVisible && (
                <ShowMoreFilterTagsToggle
                  className="show-less-filters-tag"
                  onClick={() => toggleExpand((previousValue) => !previousValue)}
                >
                  Show less
                </ShowMoreFilterTagsToggle>
              )}
              <ResetFiltersButton renderedInTagList={true} toggleFiltersChangedByUser={toggleFiltersChangedByUser} />
            </>
          )}
        </TagContainer>
        <div
          style={{
            width: (expand && showLessTagVisible) || !expand ? 200 : 0, //showLessTagVisible is only false when all the filter tags are on the same line. only wanting to give some extra space to the filter tags container when the "reset filters" is in the container with the tags in attempts to keep the reset filters button to be on the same line as well. otherwise, the positioning of the filter controls in this style prop are used to determine the placement of the controls and require the width of this container to be 200
            position: placementOfFilterControls !== null ? 'relative' : null,
            //explanation of the left calculation below
            //256 is the total of the widths of the two containers surrounding the tag container - 'Applied' and the filter controls have a total width of 256
            //22 is the horizontal padding width of the filter container
            //windowWidth - (256 + 22) = the width of the filter tags container's contents
            //the placementOfFilterControls is calculated as the last visible tag's inner text span's left position within the page + the width of the span + the width of the horizontal padding of the tag (thus giving us the right side of the tag) + 30px for some adding space after the tag and taking account of the icon space in the tag
            //what we get from taking placementOfFilterControls - (windowWidth - (256 + 22)) = right side of last visible tag - the width of the filter tags container's contents = the negative difference between the right side of the last visible tag and the right side of the tag container
            //so this difference can be used to help us determine the amount of space that we need to move the filter controls to the left (it will be negative, because we are moving left farther past the original left side of the filter control's container)
            left:
              !expand && placementOfFilterControls !== null && windowWidth > 0
                ? placementOfFilterControls - (windowWidth - (256 + 5))
                : null,
            top: -2 //in order for the top of the controls to be in line with the tags, needed to move this up a little
          }}
        >
          {!expand && (
            <>
              {totalTagsNotInView > 0 && (
                <ShowMoreFilterTagsToggle onClick={() => toggleExpand((previousValue) => !previousValue)}>
                  +{totalTagsNotInView} more
                </ShowMoreFilterTagsToggle>
              )}
              <ResetFiltersButton renderedInTagList={false} toggleFiltersChangedByUser={toggleFiltersChangedByUser} />
            </>
          )}
        </div>
      </FilterTagContainer>
    )
  );
};

const FilterTagWithRef = ({ tag, removeFilterTag, setTotalTagsNotInView, setFilterTagsWithInViewTracking, label }) => {
  const [ref, inView, entry] = useInView(); //gives us the ability to track a tag's visibility on the page
  const [recordedInView, setRecordedInView] = useState(null); //keeps track of a tag's previously set inView value - only want to update total tags not in view when there is a real change to the inView value for a tag
  useEffect(() => {
    if (inView !== recordedInView) {
      setRecordedInView(inView);
      if (inView) {
        setTotalTagsNotInView((previousTotal) => previousTotal - 1); //this value is what we see as X in +X more button
      }
      if (!inView) {
        setTotalTagsNotInView((previousTotal) => previousTotal + 1);
      }
    } //additionally, when a change to inView occurs, we get information about the DOM element, so in addition to tracking whether the tag is visible or not, we track the right side and the top of the tag so that we can use this in other calculations
    setFilterTagsWithInViewTracking((previousTags) => {
      const changedTagIndex = previousTags.findIndex((prevTag) => prevTag.label === tag.label && prevTag.id === tag.id);
      let copyOfPreviousTags = stripReferences(previousTags);
      if (changedTagIndex > -1) {
        //if present in the filterTagsWithInViewTracking, just update the information tracked
        copyOfPreviousTags[changedTagIndex].inView = inView;
        //entry.target is the element being analyzed, the element that is inView or not inView - if null/undefined, just set the following data as 0, that will be taken care of very quickly and actual data will be used to fill these data points soon
        copyOfPreviousTags[changedTagIndex].rightSideXCoordinate = entry?.target
          ? entry.target.offsetLeft + entry.target.offsetWidth + 30
          : 0;
        copyOfPreviousTags[changedTagIndex].top = entry?.target ? entry.target.offsetTop : 0;
      } else {
        //if not present in the filterTagsWithInViewTracking, push the tag information into it
        copyOfPreviousTags.push({
          ...tag,
          inView,
          rightSideXCoordinate: entry?.target?.offsetLeft + entry?.target?.offsetWidth + 30,
          top: entry?.target?.offsetTop
        });
      }
      return copyOfPreviousTags;
    });
  }, [inView, recordedInView, tag, entry]);

  const onRemoveFilterTagClick = useCallback(() => {
    removeFilterTag(tag);
  }, [tag]);

  return (
    <StyledFilterTag className="filter-tag" onClick={onRemoveFilterTagClick}>
      <span ref={ref}>{label}</span>
      <StyledCloseIcon icon={faTimes} />
    </StyledFilterTag>
  );
};

const FilterTagContainer = styled.div`
  padding: 4px 11px 8px 11px;
  display: inline-flex;
  overflow-y: ${({ expand }) => !expand && 'hidden'};
  overflow-x: hidden;
  height: ${({ expand }) => !expand && '40px'};
  .label {
    margin-right: 10px;
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;
const TagContainer = styled.div`
  flex: 1 1 0px;
  position: relative;
`;
const StyledFilterTag = styled(Tag)`
  &.ant-tag {
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 8px;
    margin-top: 2px;
    background: ${({ theme }) => theme.backgroundColors.ironGray}
    color: ${({ theme }) => theme.colors.navy};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.ss};
  }
`;
const ShowMoreFilterTagsToggle = styled(Tag)`
  &.ant-tag {
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 8px;
    background: ${({ theme }) => theme.backgroundColors.ironGray}
    color: ${({ theme }) => theme.colors.navy};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.ss};
  }
`;
const StyledCloseIcon = styled(FontAwesomeIcon)`
  transform: translateY(1px);
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export default FilterTags;
