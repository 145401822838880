import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Popover, Row, Checkbox, Dropdown, Menu, Typography, Divider } from 'antd';
import React, { createContext, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { theme } from 'styles/appTheme';

const { Text } = Typography;

export const CommonDropdownContext = createContext('CommonDropdown');
export const useCommonDropdownContext = (key) => {
  const dropdownContext = useContext(CommonDropdownContext);
  if (key && dropdownContext[key]) {
    return dropdownContext[key];
  }
  return {};
};

const CommonDropdown = ({
  title = '',
  identifier,
  tooltipMessage = '',
  hasAllSection = true,
  additionalStyle = {},
  hasDivider = true
}) => {
  const { items, handleChange = () => {}, handleDropdownOpen = () => {} } = useCommonDropdownContext(identifier);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  useEffect(() => {
    handleDropdownOpen(dropdownIsOpen);
  }, [dropdownIsOpen]);

  const dropdownItems = items ?? [];
  const checkedCount = dropdownItems.filter((item) => item.checked).length;
  const buttonLabel =
    checkedCount === dropdownItems.length && dropdownItems.length > 0
      ? `All (${dropdownItems.length})`
      : `${checkedCount} (of ${dropdownItems.length})`;

  return (
    <Row type="flex" justify="center" align="middle" gutter={3} style={additionalStyle}>
      <Col>
        <StyledTitle>{title}</StyledTitle>
      </Col>
      <Col>
        <StyledDropdown
          overlay={
            <StyledMenu>
              {hasAllSection && dropdownItems.length > 0 && (
                <Menu.Item key="All">
                  <CustomCheckBox
                    id="select-all-checkbox"
                    checked={checkedCount === dropdownItems.length}
                    onChange={() => handleChange(checkedCount === 0, checkedCount > 0 ? [] : dropdownItems)}
                    indeterminate={checkedCount > 0 && checkedCount < dropdownItems.length}
                  >
                    All
                  </CustomCheckBox>
                </Menu.Item>
              )}
              <Menu.Divider style={{ margin: '4px 12px', backgroundColor: theme.colors.secondaryGrey }} />
              {dropdownItems.map((item) => (
                <Menu.Item key={item.key}>
                  <CustomCheckBox
                    id={item.key}
                    checked={item.checked}
                    onChange={(e) => handleChange(e.target.checked, [item])}
                  >
                    {item.name}
                  </CustomCheckBox>
                </Menu.Item>
              ))}
            </StyledMenu>
          }
          visible={dropdownIsOpen}
          onVisibleChange={setDropdownIsOpen}
          trigger={['click']}
          getPopupContainer={(trigger) => trigger.parentNode}
          disabled={dropdownItems.length === 0}
          placement="bottomRight"
        >
          <Button type="text" onClick={(e) => e.preventDefault()}>
            {buttonLabel}
          </Button>
        </StyledDropdown>
      </Col>
      {checkedCount === 0 && (
        <Col>
          <Row type="flex" align="middle">
            <Popover overlayStyle={{ width: '172px' }} placement="top" content={tooltipMessage} trigger="hover">
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{
                  color: theme.colors.red,
                  width: '14px',
                  height: '14px'
                }}
              />
            </Popover>
          </Row>
        </Col>
      )}
      {hasDivider && (
        <Col>
          <Divider
            type="vertical"
            style={{
              backgroundColor: theme.colors.secondaryGrey,
              top: '0px',
              margin: '0 10.5px 0 9px',
              height: '16px',
              display: 'block'
            }}
          />
        </Col>
      )}
    </Row>
  );
};

const StyledTitle = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    display: inline-block;
  }
`;

const CustomCheckBox = styled(Checkbox)`
  .ant-checkbox-wrapper& {
    width: 100%;

    .ant-checkbox-checked .ant-checkbox-inner::after {
      -webkit-transform: rotate(45deg) scale(1) translate(-100%, -20%);
      transform: rotate(45deg) scale(1) translate(-100%, -20%);
    }

    .ant-checkbox-inner::after {
      left: 50%;
    }

    .ant-checkbox-checked::after {
      border: ${({ theme }) => theme.borders.invalidRedBorder};
      border-radius: 4px;
    }

    .ant-checkbox-inner {
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
    }
    :hover .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors.secondaryGrey};
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors.secondaryGrey};
    }

    .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors.red} !important;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.red} !important;
      border-radius: 4px;
      border: unset;
      :after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.white};
        border: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
      }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .ant-btn& {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: transparent !important;
    border-color: transparent !important;
    color: ${({ theme }) => theme.colors.infoPrimary} !important;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    padding: 1px 6px;
    border-radius: 4px;
    height: 23px;

    :hover {
      > span {
        text-decoration: underline;
      }
    }

    :focus {
      border-color: ${({ theme }) => theme.colors.infoPrimary} !important;
    }
  }
`;

const StyledMenu = styled(Menu)`
  .ant-dropdown-menu& {
    max-height: min(420px, 100vh - 139px);
    width: auto;
    padding: 8px 0;
    overflow-y: auto;
    .ant-dropdown-menu-item {
      padding: 5px 16px;
    }
  }
`;

export default CommonDropdown;
