import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Router } from '@reach/router';
import { Menu, Typography } from 'antd';
import { features } from 'app-constants';
import { Authorize } from 'components/auth';
import { InventorySettings, Notifications, PlanTemplates, TaskTypes } from 'components/layout/settings';
import { Catalog } from 'components/layout/settings/catalog';
import { VendorAndGroups } from 'components/layout/vendorAndGroups';
import { VendorUsers, Dealerships } from 'components/layout/vendorUsersAndDealerships';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import styled from 'styled-components';
import useVendorRole from 'hooks/useVendorRole';
import { GlobalAlertContext } from '../../App';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';

const { Text, Title } = Typography;

const PageNotFound = () => {
  return (
    <StyledPageNotFound>
      <Title level={3}>Page Not Found</Title>
    </StyledPageNotFound>
  );
};

const settingsPages = [
  {
    label: 'General Settings',
    default: true,
    path: 'inventory',
    getComponent: (props) => <InventorySettings {...props} />,
    feature: features.DEALER_SETTINGS
  },
  {
    label: 'Task Templates',
    default: false,
    path: 'tasktypes',
    getComponent: (props) => <TaskTypes {...props} />,
    feature: features.DEALER_SETTINGS
  },
  {
    label: 'Plan Templates',
    default: false,
    path: 'plantemplates',
    getComponent: (props) => <PlanTemplates {...props} />,
    feature: features.DEALER_SETTINGS
  },
  {
    label: 'Notifications',
    default: false,
    path: 'notifications',
    getComponent: (props) => <Notifications {...props} />,
    feature: features.RECON
  },
  {
    label: 'Catalog',
    default: false,
    path: 'catalog',
    getComponent: (props) => <Catalog {...props} />,
    feature: features.RECON,
    flag: 'reconLineItemCatalog'
  },
  {
    label: 'Internal Groups',
    default: false,
    path: 'internal-groups',
    getComponent: (props) => <ConfigureInternalGroups {...props} />,
    feature: features.RECON
  },
  {
    label: 'External Vendors',
    default: false,
    path: 'external-vendors',
    getComponent: (props) => <ConfigureVendors {...props} />,
    feature: features.RECON,
    flag: 'reconVendorManagement'
  }
];

const vendorSettingsPages = [
  {
    label: 'Users',
    default: false,
    path: 'users',
    getComponent: (props) => <VendorUsers {...props} />,
    feature: features.RECON,
    flag: 'reconVendorManagement'
  },
  {
    label: 'Dealerships',
    default: false,
    path: 'dealerships',
    getComponent: (props) => <Dealerships {...props} />,
    feature: features.RECON,
    flag: 'reconVendorManagement'
  }
];

const configureIReconMenu = [
  {
    name: 'Settings',
    items: [
      {
        name: 'General Settings',
        path: 'inventory',
        getComponent: (props) => <InventorySettings {...props} />,
        feature: features.DEALER_SETTINGS
      }
    ]
  },
  {
    name: 'Work Items',
    items: [
      {
        name: 'Plan Templates',
        path: 'plantemplates',
        getComponent: (props) => <PlanTemplates {...props} />,
        feature: features.DEALER_SETTINGS
      },
      {
        name: 'Task Templates',
        path: 'tasktypes',
        getComponent: (props) => <TaskTypes {...props} />,
        feature: features.DEALER_SETTINGS
      },
      {
        name: 'Line Item Catalog',
        path: 'catalog',
        getComponent: (props) => <Catalog {...props} />,
        feature: features.DEALER_SETTINGS,
        flag: 'reconLineItemCatalog'
      }
    ]
  },
  {
    name: 'People',
    items: [
      {
        name: 'Internal Groups',
        path: 'internal-groups',
        getComponent: (props) => <ConfigureInternalGroups {...props} />,
        feature: features.DEALER_SETTINGS
      },
      {
        name: 'External Vendors',
        path: 'external-vendors',
        getComponent: (props) => <ConfigureVendors {...props} />,
        feature: features.DEALER_SETTINGS,
        flag: 'reconVendorManagement'
      },
      {
        name: 'Manage Users (Provision)',
        path: 'Provision',
        icon: faExternalLinkAlt,
        linkToNewTab: `${process.env.REACT_APP_PROVISION_BASE_URL_NEW_DOMAIN}/Va/Settings/UserManagement/UserFeatures.aspx`
      }
    ]
  }
];

const configureIReconMenuVendor = [
  {
    name: 'People',
    items: [
      {
        name: 'Users',
        path: 'users',
        getComponent: () => <VendorUsers />,
        flag: 'reconVendorManagement'
      },
      {
        name: 'Dealerships',
        path: 'dealerships',
        getComponent: () => <Dealerships />,
        flag: 'reconVendorManagement'
      }
    ]
  }
];

const settingsPropsSelector = createSelector(authSelector, (authStore) => ({
  isBridgeUser: authStore.isBridgeUser
}));

const GroupMenu = (itemGroup, flags) => {
  return (
    <Menu.ItemGroup key={itemGroup.name} title={itemGroup.name}>
      {itemGroup.items.map((item) => {
        if (item.linkToNewTab) {
          return (
            <Menu.Item key={item.path} onClick={(e) => window.open(item.linkToNewTab, '_blank')}>
              {item.icon && (
                <FontAwesomeIcon
                  style={{ position: 'absolute', left: '0px', fontSize: '12px', top: '6px' }}
                  icon={item.icon}
                />
              )}
              {item.name}
            </Menu.Item>
          );
        } else {
          return !item.flag || (item.flag && flags[item.flag]) ? (
            <Menu.Item key={item.path}>
              <Link to={item.path || ''}>{item.name}</Link>
            </Menu.Item>
          ) : null;
        }
      })}
    </Menu.ItemGroup>
  );
};

const AuthSettingPage = (page, flags) => {
  return !page.flag || (page.flag && flags[page.flag]) ? (
    <Authorize key={page.path} path={page.path} feature={page.feature}>
      {page.getComponent({ default: true, flags: flags })}
    </Authorize>
  ) : null;
};

const ConfigureIRecon = ({ location, flags }) => {
  const { pathname } = location;
  const path = pathname.split('/').pop();
  const { isBridgeUser } = useSelector(settingsPropsSelector);
  const { isVendorAdmin } = useVendorRole();
  const hasGlobalAlert = useContext(GlobalAlertContext);

  return (
    <StyledPage is-bridge-user={isBridgeUser ? 1 : 0} has-global-alert={hasGlobalAlert ? 1 : 0}>
      <StyledHeader>
        <Text>Configure iRecon</Text>
      </StyledHeader>

      <StyledSettingsPage is-bridge-user={isBridgeUser ? 1 : 0}>
        <StyledMenu selectedKeys={[path]}>
          {isVendorAdmin
            ? configureIReconMenuVendor.map((itemGroup) => GroupMenu(itemGroup, flags))
            : configureIReconMenu.map((itemGroup) => GroupMenu(itemGroup, flags))}
        </StyledMenu>
        <MainPageStyled>
          <Router>
            {isVendorAdmin ? (
              <>{vendorSettingsPages.map((p) => AuthSettingPage(p, flags))}</>
            ) : (
              <>{settingsPages.map((p) => AuthSettingPage(p, flags))}</>
            )}

            <PageNotFound default />
          </Router>
        </MainPageStyled>
      </StyledSettingsPage>
    </StyledPage>
  );
};

const ConfigureVendors = () => {
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.CONFIGURE_VENDORS_VIEWED);
  return <VendorAndGroups isExternal={true} />;
};

const ConfigureInternalGroups = () => {
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.CONFIGURE_INTERNAL_GROUPS_VIEWED);
  return <VendorAndGroups isExternal={false} />;
};

const calculatedContentHeight = (isBridgeUser, hasGlobalAlert) => {
  let height = 181;
  if (hasGlobalAlert) {
    height += 42;
  }
  if (isBridgeUser) {
    height += 35;
  }
  return `min-height: calc(100vh - ${height}px);`;
};

//#region Styled Components
const StyledPage = styled.div`
  ${(props) => calculatedContentHeight(props['is-bridge-user'], props['has-global-alert'])}
  background-color: #F2F4F5;
`;

const StyledHeader = styled.div`
  & > .ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes.title};
    line-height: normal;
  }
  display: flex;
  margin: 24px;
`;

export const StyledSettingsPage = styled.div`
  display: flex;
`;
const StyledMenu = styled(Menu).attrs({
  mode: 'inline',
  style: {
    width: 256,
    background: '#F2F4F5',
    borderRight: 'none',
    marginLeft: '24px'
  }
})`
  li.ant-menu-item-group {
    margin-bottom: 20px;
  }

  .ant-menu-item::after {
    border-right: none !important;
  }

  .ant-menu-item-selected {
    background: none !important;
    font-weight: 500;
    &::after {
      right: initial !important;
      left: 0;
      border-right: ${({ theme }) => theme.borders.thickSolidRed} !important;
    }
  }

  .ant-menu-item-group-title {
    padding: 0;
    color: #6d727a;
    font-family: Roboto;
    font-size: 16px;
  }
  .ant-menu-item {
    height: initial !important;
    font-family: Roboto;
    font-size: 16px !important;
    letter-spacing: 0;
    line-height: 1.5 !important;
    padding-left: 15px !important;
    margin-bottom: 0 !important;
    color: #002950;
    & > * {
      color: #002950;
    }
  }
`;
const MainPageStyled = styled.div`
  flex: 1;
`;
const StyledPageNotFound = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
//#endregion

export default ConfigureIRecon;
