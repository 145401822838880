import React from 'react';
import styled from 'styled-components';
import { CommonInputNumber } from 'components/styledComponents';
import { numberThousandsSepFormatter } from 'utils/numberUtils';
import { numberOnlyParser } from 'utils/regexUtils';

const CurrencyInput = React.forwardRef(({ value, onChange, min = 0, max = 10000, precision = 2 }, ref) => (
  <>
    <Prefix>$</Prefix>
    <CommonInputNumber
      ref={ref}
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      formatter={numberThousandsSepFormatter}
      parser={numberOnlyParser}
      precision={precision}
      style={{ width: '100%', paddingLeft: '12px' }}
    />
  </>
));

const Prefix = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  left: 12px;
  top: -2px;
`;

export default CurrencyInput;
