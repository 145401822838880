import { deleteWithToken, getFileWithToken, getWithToken, postWithToken } from 'api';
import { apiStatusConstants, communicationContexts } from 'app-constants';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { currentDealerSelector } from 'store/dealersStore';
import { messagesActions } from 'store/messagesStore';
import { usersActions } from 'store/usersStore';
import { createRequestTypes, makeActionCreator } from 'utils';
import { stripReferences } from 'utils/arrayUtils';
import { v4 as uuidv4 } from 'uuid';

const EndpointDocument = '/api/Documents';

//#region Actions
export const DOCUMENTS = createRequestTypes('DOCUMENTS', [
  'GET_ALL_DOCUMENTS',
  'GET_TASK_DOCUMENTS',
  'SET_DOCUMENTS',
  'SET_DOCUMENTS_FETCH_STATUS',
  'SET_DOCUMENTS_ACTION_STATUS',
  'DOWNLOAD_TASK_DOCUMENT',
  'DOWNLOAD_VEHICLE_DOCUMENT',
  'UPLOAD_TASK_DOCUMENT',
  'UPLOAD_VEHICLE_DOCUMENT',
  'DELETE_DOCUMENT_FROM_STORE',
  'DELETE_DOCUMENT',
  'ADD_DOCUMENTS'
]);
export const documentsActions = {
  getAllDocuments: makeActionCreator(DOCUMENTS.GET_ALL_DOCUMENTS, 'vehicleId'),
  getTaskDocuments: makeActionCreator(DOCUMENTS.GET_TASK_DOCUMENTS, 'taskId'),
  setDocuments: makeActionCreator(DOCUMENTS.SET_DOCUMENTS, 'documents'),
  setDocumentsFetchStatus: makeActionCreator(DOCUMENTS.SET_DOCUMENTS_FETCH_STATUS, 'fetchStatus'),
  setDocumentsActionStatus: makeActionCreator(DOCUMENTS.SET_DOCUMENTS_ACTION_STATUS, 'action', 'actionStatus'),
  deleteDocumentFromStore: makeActionCreator(DOCUMENTS.DELETE_DOCUMENT_FROM_STORE, 'documentId'), // no reducer, triggers deleteDocumentSaga
  deleteDocument: makeActionCreator(DOCUMENTS.DELETE_DOCUMENT, 'documentId'),
  downloadTaskDocument: makeActionCreator(DOCUMENTS.DOWNLOAD_TASK_DOCUMENT, 'documentId', 'documentName'),
  downloadVehicleDocument: makeActionCreator(DOCUMENTS.DOWNLOAD_VEHICLE_DOCUMENT, 'documentId', 'documentName'),
  uploadTaskDocument: makeActionCreator(
    DOCUMENTS.UPLOAD_TASK_DOCUMENT,
    'reconTaskId',
    'fileList',
    'stockNumber',
    'message',
    'hasLoadingToast',
    'isVehicleDocument'
  ),
  uploadVehicleDocument: makeActionCreator(
    DOCUMENTS.UPLOAD_VEHICLE_DOCUMENT,
    'vehicleId',
    'fileList',
    'stockNumber',
    'message',
    'hasLoadingToast'
  ),
  addDocuments: makeActionCreator(DOCUMENTS.ADD_DOCUMENTS, 'documents')
};
//#endregion

//#region Reducer
const initialState = {
  documentsData: null,
  documentsFetchStatus: null,
  documentsUploadStatus: null,
  documentsDeleteStatus: null
};

export const documentsReducer = (state = initialState, action) => {
  let newData;
  switch (action.type) {
    case DOCUMENTS.SET_DOCUMENTS:
      newData = stripReferences(state.documentsData);
      newData = action.documents;
      return {
        ...state,
        documentsData: newData,
        documentsFetchStatus: apiStatusConstants.SUCCEEDED
      };
    case DOCUMENTS.SET_DOCUMENTS_FETCH_STATUS:
      return {
        ...state,
        documentsFetchStatus: action.fetchStatus
      };
    case DOCUMENTS.DELETE_DOCUMENT_FROM_STORE:
      newData = stripReferences(state.documentsData);
      newData = newData.filter((x) => x.id !== action.documentId);
      return {
        ...state,
        documentsData: newData
      };
    case DOCUMENTS.ADD_DOCUMENTS:
      newData = stripReferences(state.documentsData);
      newData = newData ? [...newData, ...action.documents] : action.documents;
      return {
        ...state,
        documentsData: newData
      };
    case DOCUMENTS.SET_DOCUMENTS_ACTION_STATUS:
      newData = stripReferences(state);
      switch (action.action) {
        case 'upload':
          return {
            ...state,
            documentsUploadStatus: action.actionStatus
          };
        case 'delete':
          return {
            ...state,
            documentsDeleteStatus: action.actionStatus
          };
        default:
          return {
            ...state,
            documentsUploadStatus: action.actionStatus
          };
      }
    default:
      return state;
  }
};

//#endregion

//#region Selectors
export const documentsDataSelector = createSelector(
  (state) => state.documents,
  (documents) => documents.documentsData
);
//#endregion

//#region common function for get,download,upload document
function* getDocument(path) {
  try {
    yield put(documentsActions.setDocumentsFetchStatus(apiStatusConstants.IS_FETCHING));
    const documents = yield getWithToken(path);
    yield put(documentsActions.setDocuments(documents)); // this action also sets the communicationsFetchStatus to succeeded in the reducer
  } catch (error) {
    devLogger.log('error in getDocumentsSaga', error);
    yield put(documentsActions.setDocumentsFetchStatus(apiStatusConstants.FAILED));
    devLogger.log('error in getDocumentsSaga', error);
  }
}

function* downloadDocument(path, documentName) {
  try {
    var downloadedDocument = yield getFileWithToken(path);
    // file name can get from Content-Disposition but it was missing from the response.
    require('downloadjs')(downloadedDocument.data, documentName, downloadedDocument.headers['content-type']);
  } catch (error) {
    yield put(messagesActions.notify('error', 'An error occurred while download document', { duration: 3.5 }));
  }
}

function* uploadDocument(path, fileList, stockNumber, message, hasLoadingToast = true) {
  let uploadedFiles = [];
  const messageId = hasLoadingToast ? uuidv4() : null;
  if (hasLoadingToast) {
    const loadingMessage = 'Uploading document...';
    // duration of 0 will show the message indefinitely
    yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0 }));
  }
  yield put(documentsActions.setDocumentsActionStatus('upload', apiStatusConstants.PENDING));
  for (const file of fileList) {
    const data = new FormData();
    data.append('file', file);
    try {
      const resDoc = yield postWithToken(path, data);
      uploadedFiles = [...uploadedFiles, resDoc];
    } catch (error) {
      Error(error);
    }
  }
  if (uploadedFiles.length > 0) {
    yield put(documentsActions.addDocuments(uploadedFiles));
    const mes = yield message(uploadedFiles.length, stockNumber);
    // passing in the same key and duration will make the message go away
    yield put(
      messagesActions.notify('success', mes, {
        key: messageId,
        duration: 3.5,
        location: communicationContexts.DOCUMENT_SUCCESS
      })
    );
  }
  if (uploadedFiles.length === 0) {
    yield put(
      messagesActions.notify('error', `Error with ${fileList.length - uploadedFiles.length} files ! `, {
        duration: 2
      })
    );
  }
  yield put(documentsActions.setDocumentsActionStatus('upload', apiStatusConstants.SUCCEEDED));
}
//#endregion

//#region Sagas
export function* getDocumentsSaga() {
  yield takeEvery(DOCUMENTS.GET_ALL_DOCUMENTS, function* ({ vehicleId }) {
    let path = `${EndpointDocument}/Vehicle/${vehicleId}?include=TaskDocuments`;
    yield getDocument(path);
  });
}

export function* getDocumentsByTaskSaga() {
  yield takeEvery(DOCUMENTS.GET_TASK_DOCUMENTS, function* ({ taskId }) {
    let path = `${EndpointDocument}/Task/${taskId}`;
    yield getDocument(path);
  });
}

export function* deleteDocumentSaga() {
  yield takeEvery(DOCUMENTS.DELETE_DOCUMENT, function* ({ documentId }) {
    const messageId = uuidv4();
    const loadingMessage = 'Deleting document...';
    try {
      yield put(documentsActions.setDocumentsActionStatus('delete', apiStatusConstants.PENDING));
      // duration of 0 will show the message indefinitely
      yield put(messagesActions.notify('loading', loadingMessage, { key: messageId, duration: 0 }));
      const path = `${EndpointDocument}/${documentId}`;
      yield deleteWithToken(path);
      yield put(documentsActions.deleteDocumentFromStore(documentId));
      yield put(
        messagesActions.notify('success', 'Document deleted successfully !', {
          key: messageId,
          duration: 3.5,
          location: communicationContexts.DOCUMENT_SUCCESS
        })
      );
    } catch (error) {
      yield put(
        messagesActions.notify('error', 'An error occurred while deleting document', {
          key: messageId,
          duration: 3.5
        })
      );
    } finally {
      yield put(documentsActions.setDocumentsActionStatus('delete', apiStatusConstants.SUCCEEDED));
    }
  });
}

export function* downloadTaskDocumentSaga() {
  yield takeEvery(DOCUMENTS.DOWNLOAD_TASK_DOCUMENT, function* ({ documentId, documentName }) {
    let path = `${EndpointDocument}/${documentId}/Task`;
    yield downloadDocument(path, documentName);
  });
}

export function* downloadVehicleDocumentSaga() {
  yield takeEvery(DOCUMENTS.DOWNLOAD_VEHICLE_DOCUMENT, function* ({ documentId, documentName }) {
    let path = `${EndpointDocument}/${documentId}/Vehicle`;
    yield downloadDocument(path, documentName);
  });
}

export function* uploadTaskDocumentSaga() {
  yield takeLatest(
    DOCUMENTS.UPLOAD_TASK_DOCUMENT,
    function* ({ reconTaskId, fileList, stockNumber, message, hasLoadingToast = true }) {
      let path = `${EndpointDocument}/Task/${reconTaskId}`;
      yield uploadDocument(path, fileList, stockNumber, message, hasLoadingToast);
    }
  );
}

export function* uploadVehicleDocumentSaga() {
  yield takeLatest(
    DOCUMENTS.UPLOAD_VEHICLE_DOCUMENT,
    function* ({ vehicleId, fileList, stockNumber, message, hasLoadingToast = true }) {
      let path = `${EndpointDocument}/Vehicle/${vehicleId}`;
      yield uploadDocument(path, fileList, stockNumber, message, hasLoadingToast);
    }
  );
}
//#endregion

//#region Helpers
export function* refreshUsers() {
  const dealer = yield select(currentDealerSelector);
  const dealerId = dealer?.data?.id;
  yield put(usersActions.getData(dealerId));
}
//#endregion
