export const vendorUserOnboardingStatusTypes = {
  ADDED: { Name: 'Pending', Rank: 1 },
  REGISTERED: { Name: 'Active', Rank: 2 },
  DEACTIVATED: { Name: 'Deactivated', Rank: 3 }
};

export const vendorUserRoleDisplay =
{
    'Vendor Technician': 'Tech',
    'Vendor Admin': 'Admin'
}

export const vendorUserRoleDisplayLong =
{
    'Tech': 'Vendor Technician',
    'Admin': 'Vendor Admin'
}

export const vendorUserRoles = [
  {
    id: 2,
    name: 'Vendor Technician',
    label: 'Tech',
    isDefaultRole: true,
  },
  {
    id: 1,
    name: 'Vendor Admin',
    label: 'Admin',
    isDefaultRole: false,
  }
];
