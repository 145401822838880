import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PageNotFound = () => {
  const { Title } = Typography;
  const { isBridgeUser } = useSelector((state) => state.auth);
  return (
    <StyledPageNotFound is-bridge-user={isBridgeUser}>
      <Title level={3}>Page Not Found</Title>
    </StyledPageNotFound>
  );
};

const StyledPageNotFound = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  height: calc(100vh - ${(props) => (props['is-bridge-user'] ? 202 : 166)}px);
  padding: 24px;
`;

export default PageNotFound;
