import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';

const StyledAlertIcon = ({ type }) => {
  let colorBg;
  let faIcon;
  switch (type) {
    case 'success':
      faIcon = faCheckCircle;
      colorBg = '#1fa13d';
      break;
    case 'warning':
      faIcon = faExclamationTriangle;
      colorBg = '#ffdd00';
      break;
    case 'error':
      faIcon = faExclamationCircle;
      colorBg = themeStyles.colors.red || '#dd2b2b';
      break;
    case 'info':
    default:
      faIcon = faInfoCircle;
      colorBg = '#639cff';
      break;
  }

  return (
    <span className={'fa-layers fa-fw alert-icon-container'}>
      <StyledSquareBackground className="square-bg" color={colorBg} />
      <FontAwesomeIcon icon={faIcon} color={themeStyles.colors.white} className="icon fa-lg" />
    </span>
  );
};

const StyledSquareBackground = styled.div`
  background-color: ${(props) => props['color']};
  border-radius: 4px 0 0 4px;
  width: 100%;
  height: 100%;
`;

export default StyledAlertIcon;
