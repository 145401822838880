import { isEmpty } from 'lodash';
const mentionType = 'mention';
export const convertDataToDisplay = (contentData) => {
  if (!contentData || isEmpty(contentData)) return contentData;
  const cloneData = { ...contentData };

  if (!!cloneData.blocks.length) {
    cloneData.blocks[0].entityRanges.forEach((e) => {
      const entityMap = contentData.entityMap[e.key];
      if (entityMap && entityMap.type === mentionType) {
        e.offset = [...contentData.blocks[0].text.substr(0, e.offset)].length;
      }
    });
  }

  return cloneData;
};

export const convertDataToSend = (contentData) => {
  if (!contentData || isEmpty(contentData)) return contentData;
  const cloneData = { ...contentData };
  //For multi platform, we are going to merge all blocks into only one block of contentData
  let block = { text: contentData.blocks.map((b) => b.text).join('\n'), entityRanges: [] };
  let text = '';
  contentData.blocks.forEach((b) => {
    b.entityRanges.forEach((e) => {
      const entityMap = contentData.entityMap[e.key];
      if (entityMap && entityMap.type === mentionType) {
        e.offset = [...b.text].splice(0, e.offset).join('').length + text.length;
      }
      block.entityRanges.push(e);
    });
    text += '\n' + b.text;
  });
  cloneData.blocks = [block];
  return cloneData;
};

export const emojiRegExp =
  /((?:\p{RI}\p{RI}|\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(?:\u{200D}\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)*)|[\u{1f900}-\u{1f9ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}])$/gu;
