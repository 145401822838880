import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover, Avatar, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faTimes, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { contactInfo } from 'app-constants';

const { Meta } = Card;

const GetHelpCard = ({ cardHeading, cardType = 'performance manager', divider = false, description, cardTitle }) => {
  const PerformanceManagerAvatar = () => (
    <StyledAvatar size={45} icon={<StyledAvatarIcon icon={faSmile} size="lg" />} />
  );
  const TechnicalSupportAvatar = () => (
    <GetHelpPopoverIconContainer>
      <StyledAvatarIcon icon={faHeadset} size="lg" />
    </GetHelpPopoverIconContainer>
  );

  return (
    <GetHelpCardContainer divider={divider}>
      <h3>{cardHeading}</h3>
      <StyledMeta
        avatar={cardType === 'performance manager' ? <PerformanceManagerAvatar /> : <TechnicalSupportAvatar />}
        title={cardTitle}
        description={description}
      />
    </GetHelpCardContainer>
  );
};

const GetHelp = () => {
  const [popoverShown, showPopover] = useState(false);

  const title = (
    <>
      <span>Help</span>
      <StyledIcon className="footerGetHelpPopoverClose" icon={faTimes} onClick={() => showPopover((open) => !open)} />
    </>
  );

  const content = (
    <div>
      <GetHelpCard
        cardHeading="Technical Support"
        cardType="technical support"
        cardTitle={
          <StyledMetaDetail>
            <span>{contactInfo.SUPPORT_PHONE}</span>
          </StyledMetaDetail>
        }
      />
    </div>
  );

  return (
    <Popover
      overlayClassName="footerGetHelpPopover"
      placement="topRight"
      title={title}
      content={content}
      trigger="click"
      visible={popoverShown}
      onVisibleChange={(visible) => showPopover(visible)}
    >
      <StyledGetHelpContainer onClick={() => showPopover((open) => !open)}>
        <StyledText popoverShown={popoverShown}>Get Help</StyledText>
        <IconContainer popoverShown={popoverShown}>
          <StyledGetHelpCTAIcon icon={faQuestion} />
        </IconContainer>
      </StyledGetHelpContainer>
    </Popover>
  );
};

const StyledGetHelpContainer = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const StyledText = styled.p`
  color: ${({ theme, popoverShown }) => (popoverShown ? theme.colors.navy : theme.colors.darkGray)};
  font-weight: ${({ theme, popoverShown }) => popoverShown && theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-bottom: 0;
`;

const SharedIconStyles = `
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const StyledIcon = styled(FontAwesomeIcon)`
  ${SharedIconStyles}
  right: 5%;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  font-size: ${({ theme }) => theme.fontSizes.sm} !important;
`;

const StyledAvatarIcon = styled(FontAwesomeIcon)`
  ${SharedIconStyles}
  color: ${({ theme }) => theme.colors.navy};
  left: 50%;
`;

const IconContainer = styled.div`
  margin-left: 16px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  position: relative;
  padding: 10px 14px;
  border-radius: 50%;
  background-color: ${({ theme, popoverShown }) => (popoverShown ? theme.colors.navy : '#D8D8D8')};
  color: ${({ theme, popoverShown }) => (popoverShown ? theme.colors.white : theme.colors.darkGray)};
`;

const StyledGetHelpCTAIcon = styled(FontAwesomeIcon)`
  ${SharedIconStyles}
  left: 50%;
`;

const GetHelpPopoverIconContainer = styled(IconContainer)`
  margin-left: 0;
  cursor: auto;
  background-color: #d8d8d8;
  padding: 16px;
`;

const StyledMeta = styled(Meta)`
  margin: 18px 0 0;
  padding: 0;
  display: flex;
  align-items: center;

  .ant-card-meta-title {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }
`;

const GetHelpCardContainer = styled.div`
  padding: 24px;
  border-bottom: ${({ divider }) => divider && '1px solid #F0F0F0'};
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

const StyledMetaDetail = styled.div`
  span {
    display: block;
    letter-spacing: 0;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export default GetHelp;
