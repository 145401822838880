import React from 'react';
import styled from 'styled-components';

// Make Export Link Look Like A Button
const MessageUpload = ({ amount, stockNumber, handleOpenDetailPage }) => {
  return (
    <>
      {`${amount} ${amount > 1 ? 'files were' : 'file was'} attached to ${stockNumber} `}
      {handleOpenDetailPage ? <LinkButton onClick={handleOpenDetailPage}>View</LinkButton> : null}
    </>
  );
};

const LinkButton = styled.button.attrs({ type: 'link' })`
  background: none;
  border: none;
  color: #2B6BDD
  :hover {
    text-decoration: underline;
    outline: 0;
    cursor: pointer
  }
  :focus {
    box-shadow: none;
    outline: 0;
  }
  .ant-btn-link& {
    color: #2B6BDD;
    top: -2px;
    height: 20px;
    width: 71px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    font-size: 14px;
  }`;

export default MessageUpload;
