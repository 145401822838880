import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { NeedAttention, InventoryOverview } from 'components/layout/dashboard/currentInventory';
import { Overview, TaskBreakdown } from 'components/layout/dashboard/sharedComponents';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../../google-analytics';

const InventorySnapshot = ({ flags, activeMetrics, activeMetricsIsFetching, useLocalStorageObject }) => {
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.DASHBOARD_INVENTORY_SNAPSHOT_VIEWED);

  return (
    <FlexContainer>
      <LeftPanel>
        <StyledCard>
          <NeedAttention flags={flags} activeMetrics={activeMetrics} isFetching={activeMetricsIsFetching} />
        </StyledCard>
        <StyledCard>
          <InventoryOverview activeMetrics={activeMetrics} isFetching={activeMetricsIsFetching} />
        </StyledCard>
      </LeftPanel>

      <RightPanel>
        <Overview metricsType="activeMetrics" useLocalStorageObject={useLocalStorageObject} />
        <TaskBreakdown metricsType="activeMetrics" />
      </RightPanel>
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledCard = styled(Card)`
  .ant-card& {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

const LeftPanel = styled(FlexContainer)`
  flex-direction: column;
  width: 350px;
  margin-top: 24px;
  margin-right: 24px;
`;

const RightPanel = styled(Card)`
  flex: 1 1 0;

  .ant-card& {
    margin-top: 24px;
    padding: 0;
    width: 100%;
    background-color: transparent;
    border: none;

    .ant-card-body {
      padding: 0;
    }
  }
`;

export default InventorySnapshot;
