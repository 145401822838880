import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faCar,
  faClipboardCheck,
  faClipboardList,
  faCommentDots,
  faStoreAlt,
  faTasks,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import { Card, List } from 'antd';
import { formatVehicleTitle, IS_NEW_SIGNIN } from 'app-constants';
import { VENDOR, VENDOR_USER } from 'app-constants/groupTypes';
import journalEventTypes from 'app-constants/journalEventTypes';
import { CopyOnClickText } from 'components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useDispatch } from 'react-redux';
import { journalActions } from 'store/journalStore';
import styled from 'styled-components';
import { dateTimeFromNow } from 'utils/dateTimeUtils';

const NotificationMessage = withLDConsumer()(({ notification, handleCloseDrawer, flags }) => {
  const linkUrl = notification.relativeWebUrl;
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  let messageText;
  let name = '';
  let nameForGroup = (
    <>
      <strong>{notification.journalCreatedByName}</strong> in <strong>{notification.relatedToGroupName}</strong>
    </>
  );

  if (notification.targetUserType !== VENDOR_USER && notification.relatedToGroupType === VENDOR) {
    nameForGroup = (
      <>
        <strong>
          {notification.relatedToGroupName} ({notification.journalCreatedByName})
        </strong>
      </>
    );
  }
  const createrInfo = notification.isNotificationForGroup ? (
    <>{nameForGroup}</>
  ) : (
    <strong>
      {notification.dealerName} ({notification.journalCreatedByName})
    </strong>
  );

  switch (notification.messageType) {
    case 'TASK_ADDED':
      messageText = (
        <>
          <strong>{notification.journalCreatedByName}</strong> added a{' '}
          <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
        </>
      );
      break;
    case 'TASK_READY':
    case 'TASK_ASSIGNED':
    case 'TASK_REASSIGNED':
      const taskPosition = notification.relatedToUserId ? ' task in ' : ' task to ';
      const groupNotificationContent = (
        <>
          <strong>
            {notification.journalCreatedUserType !== VENDOR_USER && isNewSignin && flags?.reconVendorManagement
              ? notification.dealerName
              : notification.journalCreatedByName}
          </strong>{' '}
          assigned a <strong>{notification.reconTask?.reconTaskTypeName}</strong>
          {taskPosition}
          <strong>{notification.relatedToGroupName}</strong>
        </>
      );

      messageText =
        notification.isNotificationForGroup && notification.relatedToGroupName ? (
          groupNotificationContent
        ) : (
          <>
            <strong>{notification.journalCreatedByName}</strong> assigned a{' '}
            <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      break;
    case 'TASK_COMPLETED':
      // Just use for only isGroupTask = true
      name = (
        <>
          <strong>{notification.journalCreatedByName}</strong> in <strong>{notification.relatedToGroupName}</strong>
        </>
      );

      if (
        notification.relatedToGroupType === VENDOR &&
        flags?.reconVendorManagement &&
        notification.targetUserType !== VENDOR_USER
      ) {
        name = (
          <>
            <strong>
              {notification.relatedToGroupName} ({notification.journalCreatedByName})
            </strong>
          </>
        );
      }

      if (notification.isNotificationForGroup && notification.relatedToGroupName) {
        messageText = (
          <>
            {name} completed a <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      } else {
        messageText =
          notification.relatedToGroupType === VENDOR &&
          flags?.reconVendorManagement &&
          notification.targetUserType === VENDOR_USER ? (
            <>
              <strong>{notification.dealerName}</strong> completed a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          ) : (
            <>
              <strong>{notification.journalCreatedByName}</strong> completed a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          );
      }
      break;
    case 'TASK_COMPLETED_BY_DEALER':
      if (notification.isNotificationForGroup && notification.relatedToGroupName) {
        messageText = (
          <>
            <strong>{notification.journalCreatedByName}</strong> completed a{' '}
            <strong>{notification.reconTask?.reconTaskTypeName}</strong> task in{' '}
            <strong>{notification.relatedToGroupName}</strong> on your behalf
          </>
        );
      } else {
        messageText =
          notification.relatedToGroupType === VENDOR &&
          flags?.reconVendorManagement &&
          notification.targetUserType === VENDOR_USER ? (
            <>
              <strong>{notification.dealerName}</strong> completed a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task on your behalf
            </>
          ) : (
            <>
              <strong>{notification.journalCreatedByName}</strong> completed a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task on your behalf
            </>
          );
      }
      break;
    case 'TASK_CANCELED':
      if (
        notification.isNotificationForGroup &&
        notification.relatedToGroupName &&
        (notification.relatedToGroupType !== VENDOR ||
          flags?.reconVendorManagement ||
          notification.journalCreatedUserType === VENDOR_USER)
      ) {
        messageText = (
          <>
            <strong>{notification.journalCreatedByName}</strong> in <strong>{notification.relatedToGroupName}</strong>{' '}
            canceled a <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      } else {
        messageText =
          flags?.reconVendorManagement &&
          notification.targetUserType === VENDOR_USER &&
          (notification.relatedToGroupType === VENDOR ||
            notification.reconJournalEventType === journalEventTypes.TASK_REASSIGNED) ? (
            <>
              <strong>{notification.dealerName}</strong> canceled a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          ) : (
            <>
              <strong>{notification.journalCreatedByName}</strong> canceled a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          );
      }
      break;
    case 'TASK_DECLINED':
      // Just use for only isGroupTask = true
      name = (
        <>
          <strong>{notification.journalCreatedByName}</strong> in <strong>{notification.relatedToGroupName}</strong>
        </>
      );

      if (
        notification.relatedToGroupType === VENDOR &&
        flags?.reconVendorManagement &&
        notification.targetUserType !== VENDOR_USER
      ) {
        name = (
          <>
            <strong>
              {notification.relatedToGroupName} ({notification.journalCreatedByName})
            </strong>
          </>
        );
      } else if (!notification.relatedToUserId && notification.relatedToGroupType !== VENDOR) {
        name = (
          <>
            <strong>{notification.relatedToGroupName}</strong>
          </>
        );
      }

      if (notification.isNotificationForGroup && notification.relatedToGroupName) {
        messageText = (
          <>
            {name} declined a <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      } else {
        messageText =
          notification.relatedToGroupType === VENDOR &&
          flags?.reconVendorManagement &&
          notification.targetUserType === VENDOR_USER ? (
            <>
              <strong>{notification.dealerName}</strong> declined a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          ) : (
            <>
              <strong>{notification.journalCreatedByName}</strong> declined a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          );
      }
      break;
    case 'TASK_DEFERRED':
      messageText = (
        <>
          {notification.relatedToGroupType === VENDOR &&
          flags?.reconVendorManagement &&
          notification.targetUserType === VENDOR_USER ? (
            <>
              <strong>{notification.dealerName}</strong> deferred a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          ) : (
            <>
              <strong>{notification.journalCreatedByName}</strong> deferred a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          )}
        </>
      );
      break;
    case 'VENDOR_CONFIRMATION':
      messageText = (
        <>
          <strong>{notification.relatedToGroupName ?? ''}</strong> accepted your invitation and can now begin receiving
          task assignments.
        </>
      );
      break;
    /*TASK_COMMENT_ADDED/TASK_COMMENT_UPDATED can only be changed to TASK_MESSAGE_ADDED/TASK_MESSAGE_UPDATED once the API contract has been changed.
     * API contract changes requires working with mobile so we do not break their application */
    case 'TASK_COMMENT_ADDED':
      if (flags.reconVendorManagement && notification.relatedToGroupType === VENDOR) {
        messageText = (
          <>
            {createrInfo} sent a message for a <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      } else if (notification.isNotificationForGroup && notification.relatedToGroupName) {
        messageText = (
          <>
            <strong>{notification.journalCreatedByName}</strong> in <strong>{notification.relatedToGroupName}</strong>{' '}
            sent a message for a <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      } else {
        messageText =
          notification.relatedToGroupType === VENDOR &&
          flags?.reconVendorManagement &&
          notification.targetUserType === VENDOR_USER ? (
            <>
              <strong>{notification.dealerName}</strong> sent a message for a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          ) : (
            <>
              <strong>{notification.journalCreatedByName}</strong> sent a message for a{' '}
              <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
            </>
          );
      }
      break;
    case 'TASK_COMMENT_UPDATED':
      messageText = (
        <>
          <strong>{notification.journalCreatedByName}</strong> updated a message for a{' '}
          <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
        </>
      );
      break;
    case 'VEHICLE_INVENTORY_ADDED':
      messageText = (
        <>
          <strong>
            <CopyOnClickText text={notification.vehicle.stockNumber} />
          </strong>{' '}
          added to inventory
        </>
      );
      break;
    /* VEHICLE_COMMENT_ADDED/VEHICLE_COMMENT_UPDATED can only be changed to VEHICLE_NOTE_ADDED/VEHICLE_NOTE_UPDATED once the API contract has been changed.
     * API contract changes requires working with mobile so we do not break their application */
    case 'VEHICLE_COMMENT_ADDED':
      messageText = (
        <>
          <strong>{notification.journalCreatedByName}</strong> created a note for{' '}
          <strong>
            <CopyOnClickText text={notification.vehicle.stockNumber} />
          </strong>
        </>
      );
      break;
    case 'VEHICLE_FRONT_LINE_READY':
      messageText = (
        <>
          <strong>
            <CopyOnClickText text={notification.vehicle.stockNumber} />
          </strong>{' '}
          completed recon and is front line ready
        </>
      );
      break;
    case 'VEHICLE_COMMENT_UPDATED':
      messageText = (
        <>
          <strong>{notification.journalCreatedByName}</strong> updated a note for{' '}
          <strong>
            <CopyOnClickText text={notification.vehicle.stockNumber} />
          </strong>
        </>
      );
      break;
    case 'LINE_ITEM_APPROVED_DECLINED':
      const approvedLineItemCount = notification.data?.lineItemData?.approvedItems?.length;
      const declinedLineItemCount = notification.data?.lineItemData?.declinedItems?.length;
      name = notification.journalCreatedByName;
      if (notification.dealerName && notification.relatedToGroupType === VENDOR && flags?.reconVendorManagement) {
        name = (
          <>
            {notification.dealerName} ({notification.journalCreatedByName})
          </>
        );
      }
      const textInPluralOrSingular = approvedLineItemCount > 1 ? 'items' : 'item';
      messageText = (
        <>
          <strong>{name}</strong> made changes to <strong>{notification.reconTask?.reconTaskTypeName}</strong>:
          {approvedLineItemCount && approvedLineItemCount > 0 ? (
            <>
              <br />
              Approved {approvedLineItemCount} line {textInPluralOrSingular}
            </>
          ) : (
            <></>
          )}
          {declinedLineItemCount && declinedLineItemCount > 0 ? (
            <>
              <br />
              Declined {declinedLineItemCount} line {textInPluralOrSingular}
            </>
          ) : (
            <></>
          )}
        </>
      );
      break;
    case 'LINE_ITEM_APPROVAL_REQUESTED':
      const lineItemCount = notification.data?.lineItemData?.approvalRequestedItems?.length;
      name = notification.journalCreatedByName;
      if (
        notification.relatedToGroupName &&
        notification.relatedToGroupType === VENDOR &&
        notification.journalCreatedUserType === VENDOR_USER &&
        flags?.reconVendorManagement
      ) {
        name = (
          <>
            {notification.relatedToGroupName} ({notification.journalCreatedByName})
          </>
        );
      }
      messageText = (
        <>
          <strong>{name}</strong> has requested approval for {lineItemCount} new line{' '}
          {lineItemCount && lineItemCount > 1 ? 'items' : 'item'} on{' '}
          <strong>{notification.reconTask?.reconTaskTypeName}</strong>.
        </>
      );
      break;
    case 'VEHICLE_COMMENT_USER_TAGGED':
      messageText = (
        <>
          <strong>{notification.journalCreatedByName}</strong> tagged you in a vehicle note
        </>
      );
      break;
    case 'TASK_COMMENT_USER_TAGGED':
      messageText =
        flags.reconVendorManagement && notification.relatedToGroupType === VENDOR ? (
          <>
            {createrInfo} tagged you in a message for <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        ) : (
          <>
            <strong>{notification.journalCreatedByName}</strong> tagged you in a message for{' '}
            <strong>{notification.reconTask?.reconTaskTypeName}</strong> task
          </>
        );
      break;
    case 'EXCEEDED_MAX_APPROVAL_TIME':
      messageText = (
        <>
          Line items have exceeded max. approval time for <strong>{notification.reconTask?.reconTaskTypeName}.</strong>
        </>
      );

      break;
    case 'VENDOR_INVITATION_RECIEVED':
      messageText = (
        <>
          <strong>{notification.dealerName}</strong> has requested to add you as an External Vendor.
        </>
      );
      break;
    case 'VENDOR_INVITATION_ACCEPTED':
      messageText = (
        <>
          <strong>{notification.relatedToGroupName ?? ''}</strong> accepted your invite and can now begin receiving task
          assignments.
        </>
      );
      break;
    case 'VENDOR_INVITATION_DECLINED':
      messageText = (
        <>
          <strong>{notification.relatedToGroupName ?? ''}</strong> declined your invite.
        </>
      );
      break;
    default:
      messageText = notification.message;
      break;
  }

  return linkUrl ? (
    <StyledLink to={linkUrl} onClick={handleCloseDrawer}>
      {messageText}
    </StyledLink>
  ) : (
    messageText
  );
});

const Notification = ({ notification, index, handleCloseDrawer, showDealerName = false }) => {
  const dispatch = useDispatch();

  // Update the Seen status of the recon journal event
  const journalCardClicked = (notificationId, seenOn) => {
    if (seenOn) {
      return;
    }
    dispatch(journalActions.setSeen(notificationId));
  };

  const eventIconMap = {
    TASK_ADDED: faClipboardCheck,
    TASK_REASSIGNED: faClipboardList,
    TASK_ASSIGNED: faClipboardList,
    TASK_READY: faClipboardList,
    TASK_COMPLETED: faClipboardCheck,
    TASK_COMPLETED_BY_DEALER: faClipboardCheck,
    TASK_CANCELED: faTimesCircle,
    TASK_DECLINED: faTimesCircle,
    TASK_DEFERRED: faClipboardList,
    /* TASK_COMMENT_ADDED/TASK_COMMENT_UPDATED can only be changed to TASK_MESSAGE_ADDED/TASK_MESSAGE_UPDATED once the API contract has been changed.
     * API contract changes requires working with mobile so we do not break their application */
    TASK_COMMENT_ADDED: faCommentDots,
    TASK_COMMENT_UPDATED: faCommentDots,
    VEHICLE_INVENTORY_ADDED: faCar,
    /* VEHICLE_COMMENT_ADDED/VEHICLE_COMMENT_UPDATED can only be changed to VEHICLE_NOTE_ADDED/VEHICLE_NOTE_UPDATED once the API contract has been changed.
     * API contract changes requires working with mobile so we do not break their application */
    VEHICLE_COMMENT_ADDED: faCommentDots,
    VEHICLE_COMMENT_UPDATED: faCommentDots,
    VEHICLE_FRONT_LINE_READY: faCar,
    LINE_ITEM_APPROVAL_REQUESTED: faTasks,
    LINE_ITEM_APPROVED_DECLINED: faTasks,
    VEHICLE_COMMENT_USER_TAGGED: faCommentDots,
    TASK_COMMENT_USER_TAGGED: faCommentDots,
    EXCEEDED_MAX_APPROVAL_TIME: faClock,
    VENDOR_CONFIRMATION: faStoreAlt,
    VENDOR_INVITATION_RECIEVED: faStoreAlt,
    VENDOR_INVITATION_ACCEPTED: faStoreAlt,
    VENDOR_INVITATION_DECLINED: faStoreAlt
  };

  const getIconForEvent = (event) => eventIconMap[event];

  return (
    <StyledListItem key={index} onClick={() => journalCardClicked(notification.notificationId, notification.seenOn)}>
      <StyledReconJournalEventCard data-seenon={notification.seenOn}>
        <NotificationContainer>
          <IconContainer>
            <Icon icon={getIconForEvent(notification.messageType)} />
          </IconContainer>
          <Body>
            <Details>
              <Message>
                <NotificationMessage notification={notification} handleCloseDrawer={handleCloseDrawer} />
              </Message>
              <Elapsed>{dateTimeFromNow(notification.journalCreatedOn)}</Elapsed>
            </Details>
            {notification.vehicle?.id !== '00000000-0000-0000-0000-000000000000' && (
              <Description>
                <CopyOnClickText text={notification.vehicle.stockNumber} /> | {formatVehicleTitle(notification.vehicle)}
              </Description>
            )}
            {showDealerName && <Description>{notification.dealerName}</Description>}
          </Body>
        </NotificationContainer>
      </StyledReconJournalEventCard>
    </StyledListItem>
  );
};

//#region Styled Components
const StyledReconJournalEventCard = styled(Card).attrs({
  hoverable: true
})`
  cursor: default;
  padding: 0px;
  background-color: ${(props) => (props['data-seenon'] ? 'white' : '#f2f4f5 !important')};
  width: 100%;
  .ant-card& {
    .ant-card-body {
      padding: 20px 15px;
    }
  }
`;
const NotificationContainer = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  flex: 0 0 auto;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.icons.fontSizes.notifIcon};
  vertical-align: middle;
  padding-top: 4px;
`;
const Body = styled.div`
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
`;
const Details = styled.div`
  display: flex;
  flex: 1 0 auto;
`;
const Message = styled.div`
  padding-left: 15px;
  width: 100%;
  min-width: 390px;
  word-break: break-word;
`;
const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font: Roboto Regular;
  padding: 0 15px;
`;
const Elapsed = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  flex: 0 0 auto;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font: Roboto Regular;
  white-space: nowrap;
  padding-left: 15px;
  text-align: right;
`;
const StyledLink = styled(Link)`
  color: inherit;
`;
const StyledListItem = styled(List.Item)`
  .ant-list-item& {
    margin: 12px 24px;
    padding: 0;
  }
`;
//#endregion

export default Notification;
