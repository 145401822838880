import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';

const getText = (PageType, valueSearch) => {
  switch (PageType) {
    case NoRecordsPage.VENDOR:
      return [
        'Vendors',
        `You are currently not affiliated with any vendors. Vendors that you have added will appear here.`
      ];
    case NoRecordsPage.INTERNAL_GROUP:
      return ['Internal Groups', `Select "Create Group" to get started.`];
    case NoRecordsPage.TASK_TEMPLATE:
      return ['Task Templates', `Select "New Task" to get started.`];
    case NoRecordsPage.PLAN_TEMPLATE:
      return ['Plan Templates', `Select "New Plan" to get started.`];
    case NoRecordsPage.INTERNAL_GROUP_NOT_FOUND:
      return ['internal groups with the name', `"${valueSearch}"`];
    default:
      return ['Records'];
  }
};

const NoRecords = ({ PageType, valueSearch }) => {
  const textInfo = getText(PageType, valueSearch);
  return (
    <NoRecordsContainer>
      <IconContainer>
        <StyledFontAwesomeIcon />
      </IconContainer>
      <NoRecordsText>No {textInfo[0]}</NoRecordsText>
      <NoRecordsSubText>{textInfo[1]}</NoRecordsSubText>
    </NoRecordsContainer>
  );
};

//#region Styled Components
const IconContainer = styled.div`
  width: 36px;
  height: 32px;
  position: relative;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).attrs({
  icon: faStoreAlt,
  color: themeStyles.colors.darkGray,
  style: {
    fontSize: themeStyles.fontSizes.title,
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)'
  }
})``;

const NoRecordsContainer = styled.div`
  min-height: calc(100vh - 293px);
  display: flex;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
`;

const NoRecordsText = styled.div.attrs({
  color: themeStyles.colors.darkGray,
  fontSize: themeStyles.fontSizes.md,
  fontWeight: themeStyles.fontWeights.medium,
  margin: '16px 0'
})``;

const NoRecordsSubText = styled.div.attrs({
  width: '520px',
  color: themeStyles.colors.darkGray,
  fontSize: themeStyles.fontSizes.md,
  lineHeight: '24px',
  textAlign: 'center'
})``;
//#endregion
export const NoRecordsPage = {
  VENDOR: 'VENDOR',
  TASK_TEMPLATE: 'TASK_TEMPLATE',
  PLAN_TEMPLATE: 'PLAN_TEMPLATE',
  INTERNAL_GROUP: 'INTERNAL_GROUP',
  INTERNAL_GROUP_NOT_FOUND: 'INTERNAL_GROUP_NOT_FOUND'
}
export default NoRecords;
