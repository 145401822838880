import { ASSIGNED_TO, DEALERSHIP, GROUP, TECHNICIAN } from '../app-constants';

export const assignedToAndGroupTagConverter = (tags, menu, values, flags) => {
  const checkedValues = values.filter((f) => f.isChecked);
  if (menu === GROUP) {
    const hasUncheckedUnassignedValue = values.some((checkedVal) =>
      checkedVal.internalGroupUsers.some((igUser) => igUser.fullName === 'Unassigned' && !igUser.isChecked)
    );
    const fullyCheckedGroups = checkedValues.filter(
      (checkedVal) =>
        (checkedVal.fullName === 'All Unassigned Tasks' && !hasUncheckedUnassignedValue) ||
        (checkedVal.internalGroupUsers.length !== 0 &&
          !checkedVal.internalGroupUsers.some((igUser) => !igUser.isChecked))
    );
    const fullyCheckedGroupTags = fullyCheckedGroups.map((fullyCheckedGroup) => {
      return {
        menu,
        subMenu: null,
        key: fullyCheckedGroup.fullName,
        type: 'checkbox',
        id: fullyCheckedGroup.id,
        label: `${menu}: ${fullyCheckedGroup.fullName}`
      };
    });
    tags.push(fullyCheckedGroupTags);
    const partiallyCheckedGroups = checkedValues.filter((checkedVal) =>
      checkedVal.internalGroupUsers.some((igUser) => !igUser.isChecked)
    );
    const usersOfPartiallyCheckedGroups = partiallyCheckedGroups
      .map((partiallyCheckedGroup) =>
        partiallyCheckedGroup.internalGroupUsers.map((internalGroupUser) => ({
          ...internalGroupUser,
          internalGroupId: partiallyCheckedGroup.id,
          internalGroupName: partiallyCheckedGroup.fullName
        }))
      )
      .flat();
    const checkedInternalGroupUsers = usersOfPartiallyCheckedGroups.filter((user) => user.isChecked);
    const partiallyCheckedGroupTags = checkedInternalGroupUsers
      .filter((user) => user.fullName !== 'Unassigned' || hasUncheckedUnassignedValue)
      .map((igUser) => {
        return {
          menu,
          subMenu: null,
          key: `${igUser.fullName}(${igUser.internalGroupId})`,
          type: 'checkbox',
          id: igUser.userId,
          label: `${menu}: ${igUser.fullName} (${igUser.internalGroupName})`,
          internalGroupId: igUser.internalGroupId,
          internalGroupName: igUser.internalGroupName
        };
      });
    tags.push(partiallyCheckedGroupTags);
  } else if (menu === DEALERSHIP) {
    const fullyCheckedDealership = checkedValues.filter(
      (checkedVal) =>
        checkedVal.taskTypes?.length !== 0 && !checkedVal.taskTypes.some((taskType) => !taskType.isChecked)
    );
    const fullyCheckedDealershipTags = fullyCheckedDealership.map((dealer) => {
      return {
        menu,
        subMenu: null,
        key: dealer.fullName,
        type: 'checkbox',
        id: dealer.id,
        label: `${menu}: ${dealer.fullName}`
      };
    });
    tags.push(fullyCheckedDealershipTags);
    const partiallyCheckedDealerships = checkedValues.filter((checkedVal) =>
      checkedVal.taskTypes?.some((taskType) => !taskType.isChecked)
    );
    const taskTypeOfPartiallyCheckedDealerships = partiallyCheckedDealerships
      .map((partiallyCheckedDealership) =>
        partiallyCheckedDealership.taskTypes?.map((taskType) => ({
          ...taskType,
          internalGroupId: partiallyCheckedDealership.id,
          internalGroupName: partiallyCheckedDealership.fullName
        }))
      )
      .flat();

    const checkedTaskTypes = taskTypeOfPartiallyCheckedDealerships?.filter((taskType) => taskType.isChecked);
    const partiallyCheckedTaskTypesTags = checkedTaskTypes.map((taskType) => {
      return {
        menu,
        subMenu: null,
        key: `${taskType.fullName}(${taskType.internalGroupId})`,
        type: 'checkbox',
        id: taskType.id,
        label: `${menu}: ${taskType.fullName} (${taskType.internalGroupName})`,
        internalGroupId: taskType.internalGroupId,
        internalGroupName: taskType.internalGroupName
      };
    });
    tags.push(partiallyCheckedTaskTypesTags);
  } else {
    const getLabelName = () => {
      if (menu === TECHNICIAN && !flags?.reconVendorManagement) {
        return ASSIGNED_TO;
      }
      return menu;
    };
    checkedValues.forEach((checkedValue) => {
      const assignedTag = {
        menu,
        subMenu: null,
        type: 'checkbox',
        assignedToId: checkedValue.id,
        assignedToFullName: checkedValue.fullName
      };
      if (checkedValue.isAssignedDirectly) {
        const assignedDirectlyTag = {
          ...assignedTag,
          id: `${checkedValue.id}isAssignedDirectly`,
          key: `${checkedValue.fullName} isAssignedDirectly`,
          label: `${getLabelName()}: ${checkedValue.fullName}`,
          isAssignedDirectly: true
        };
        tags.push(assignedDirectlyTag);
      }
      if (checkedValue.isAssignedThruGroup) {
        const assignedThruGroupTag = {
          ...assignedTag,
          id: `${checkedValue.id}isAssignedThruGroup`,
          key: `${checkedValue.fullName} isAssignedThruGroup`,
          label: `${getLabelName()}: ${checkedValue.fullName} (Thru Group)`,
          isAssignedThruGroup: true
        };
        tags.push(assignedThruGroupTag);
      }
    });
  }
};

export const groupTagRemover = (tag, menuComponentToChange) => {
  if (tag.internalGroupId) {
    if (tag.menu === GROUP) {
      const indexAffectedGroup = menuComponentToChange.values.findIndex(
        (f) => f.fullName === tag.internalGroupName && f.id === tag.internalGroupId
      );
      const affectedGroupUsers = menuComponentToChange.values[indexAffectedGroup].internalGroupUsers;
      const mappedGroupUsers = affectedGroupUsers.map((affectedGroupUser) => ({
        ...affectedGroupUser,
        key: `${affectedGroupUser.fullName}(${menuComponentToChange.values[indexAffectedGroup].id})`
      }));
      const indexRemovedTagItem = mappedGroupUsers.findIndex((f) => f.key === tag.key && f.userId === tag.id);
      if (mappedGroupUsers.filter((user) => user.isChecked).length > 1) {
        menuComponentToChange.values[indexAffectedGroup].isChecked = true;
        menuComponentToChange.values[indexAffectedGroup].isShowMinusOutlinedIcon = true;
      }
      if (mappedGroupUsers.filter((user) => user.isChecked).length === 1) {
        menuComponentToChange.values[indexAffectedGroup].isChecked = false;
        menuComponentToChange.values[indexAffectedGroup].isShowMinusOutlinedIcon = false;
      }
      menuComponentToChange.values[indexAffectedGroup].internalGroupUsers[indexRemovedTagItem].isChecked = false;
    }
    if (tag.menu === DEALERSHIP) {
      const indexAffectedGroup = menuComponentToChange.values.findIndex(
        (f) => f.fullName === tag.internalGroupName && f.id === tag.internalGroupId
      );
      const affectedTaskTypes = menuComponentToChange.values[indexAffectedGroup].taskTypes;
      const mappedTaskTypes = affectedTaskTypes.map((affectedTaskType) => ({
        ...affectedTaskType,
        key: `${affectedTaskType.fullName}(${menuComponentToChange.values[indexAffectedGroup].id})`
      }));
      const indexRemovedTagItem = mappedTaskTypes.findIndex((f) => f.key === tag.key && f.id === tag.id);
      if (mappedTaskTypes.filter((dealer) => dealer.isChecked).length > 1) {
        menuComponentToChange.values[indexAffectedGroup].isChecked = true;
        menuComponentToChange.values[indexAffectedGroup].isShowMinusOutlinedIcon = true;
      }
      if (mappedTaskTypes.filter((dealer) => dealer.isChecked).length === 1) {
        menuComponentToChange.values[indexAffectedGroup].isChecked = false;
        menuComponentToChange.values[indexAffectedGroup].isShowMinusOutlinedIcon = false;
      }
      menuComponentToChange.values[indexAffectedGroup].taskTypes[indexRemovedTagItem].isChecked = false;
    }
  } else {
    const indexRemovedTagItem = menuComponentToChange.values.findIndex(
      (f) => f.fullName === tag.key && f.id === tag.id
    );
    menuComponentToChange.values[indexRemovedTagItem].isChecked = false;
    if (menuComponentToChange.values[indexRemovedTagItem].internalGroupUsers?.length > 0) {
      menuComponentToChange.values[indexRemovedTagItem].internalGroupUsers.forEach((member) => {
        member.isChecked = false;
      });
    }
    if (menuComponentToChange.values[indexRemovedTagItem].taskTypes?.length > 0) {
      menuComponentToChange.values[indexRemovedTagItem].taskTypes.forEach((taskType) => {
        taskType.isChecked = false;
      });
    }
  }
};

export const assignedToTagRemover = (tag, menuComponentToChange) => {
  const indexRemovedTagItem = menuComponentToChange.values.findIndex(
    (f) => f.fullName === tag.assignedToFullName && f.id === tag.assignedToId
  );
  if (menuComponentToChange.values[indexRemovedTagItem].isMemberOfAnyGroup) {
    if (tag.isAssignedDirectly) {
      menuComponentToChange.values[indexRemovedTagItem].isAssignedDirectly = false;
      if (!menuComponentToChange.values[indexRemovedTagItem].isAssignedThruGroup) {
        menuComponentToChange.values[indexRemovedTagItem].isChecked = false;
      }
    }
    if (tag.isAssignedThruGroup) {
      menuComponentToChange.values[indexRemovedTagItem].isAssignedThruGroup = false;
      if (!menuComponentToChange.values[indexRemovedTagItem].isAssignedDirectly) {
        menuComponentToChange.values[indexRemovedTagItem].isChecked = false;
      }
    }
  } else {
    menuComponentToChange.values[indexRemovedTagItem].isChecked = false;
  }
};
