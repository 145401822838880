import { Affix, Col, Divider, Row } from 'antd';
import { createVendorErrorTypes } from 'app-constants/createVendorErrorTypes';
import { regExp } from 'app-constants/regExp';
import CommonFormRender from 'components/common/CommonFormRender';
import {
  StyledButton,
  StyledLayout,
  StyledLayoutContent,
  StyledLayoutFooter
} from 'components/layout/GeneralSetting/style';
import { CommonLinkButton } from 'components/styledComponents';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { vendorActions, vendorDealerActions } from 'store/vendorStore';
import { formatPhoneNumber } from 'utils/numberUtils';
import { sortInvitationHistory } from 'utils/sortUtils';
import * as yup from 'yup';

const forms = (isReinvite = false) => [
  {
    title: '',
    showTitle: false,
    key: 'recipientEmail',
    data: [
      {
        key: 'forms:1:1',
        label: 'Recipient Email',
        name: 'email',
        placeholder: 'Recipient email address',
        required: true,
        maxLength: 50
      }
    ]
  },
  {
    title: 'Optional Information:',
    key: 'optionalInformation',
    showTitle: true,
    marginBottom: '4px',
    data: [
      {
        key: 'forms:2:1',
        label: 'Recipient First Name',
        name: 'contactFirstName',
        placeholder: 'Recipient first name',
        required: false,
        size: 12,
        maxLength: 50,
        disabled: isReinvite
      },
      {
        key: 'forms:2:2',
        label: 'Recipient Last Name',
        name: 'contactLastName',
        placeholder: 'Recipient last name',
        required: false,
        size: 12,
        maxLength: 50,
        disabled: isReinvite
      },
      {
        key: 'forms:2:3',
        label: 'Recipient Phone',
        name: 'phone',
        placeholder: '###-###-####',
        required: false,
        maxLength: 50,
        disabled: isReinvite
      },
      {
        key: 'forms:2:4',
        label: 'Vendor Business Name',
        name: 'name',
        placeholder: 'Business where recipient works',
        required: false,
        maxLength: 50,
        disabled: isReinvite
      },
      {
        key: 'forms:2:5',
        label: 'Message to Recipient',
        name: 'inviteMessage',
        placeholder: 'Include an optional message',
        required: false,
        multiline: true,
        maxLength: 50
      }
    ]
  }
];

const validationSchema = yup.object({
  phone: yup
    .string()
    .trim()
    .test('check-phone-number', 'Valid phone number is required.', (value) => {
      if (!!value) {
        return regExp.PHONE.test(value);
      } else {
        return true;
      }
    }),
  email: yup.string().trim().email('Valid email is required.').required('Valid email is required.')
});

const vendorFormSelector = createSelector(
  (state) => state.dealers?.current?.data?.id,
  (state) => state.vendors,
  (dealerId, vendorStore) => ({
    dealerId,
    vendorDealer: vendorStore.vendorDealer.data,
    isLoading: vendorStore.isLoading,
    apiErrorMessage: vendorStore.errorMessageEmail,
    formErrorType: vendorStore.formErrorType
  })
);

const NewVendorForm = ({ handleCanClose, isReinvite }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { dealerId, vendorDealer, isLoading, apiErrorMessage, formErrorType } = useSelector(vendorFormSelector);

  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    setError,
    watch
  } = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema)
  });

  const watchEmail = watch('email');

  const onSubmit = (data) => {
    const toUpdate = { ...data };
    let successMessage = `${data.name ? data.name : data.email} was invited to create a vendor account`;
    let errorMessage = 'An error occurred while adding the vendor';
    const vendor = vendorDealer?.vendor;
    dispatch(vendorActions.setErrorMsgEmailValidate(''));
    if (isReinvite && vendor?.id && dealerId) {
      //Reinvite
      successMessage = `Add vendor request has been sent to ${toUpdate.name ? toUpdate.name : toUpdate.email}`;
      errorMessage = 'An error occurred while reinviting the vendor';
      dispatch(
        vendorDealerActions.updateVendorDealerInvite(
          vendor.id,
          dealerId,
          {
            vendorId: vendor.id,
            dealerId: dealerId,
            status: 'PENDING',
            message: (toUpdate.inviteMessage ?? '').trim(),
            dealerNote: vendorDealer?.dealerNotes,
            email: toUpdate.email
          },
          successMessage,
          errorMessage,
          true
        )
      );
    } else if (!vendor?.id && dealerId) {
      // Add a new vendor
      dispatch(vendorActions.add({ ...toUpdate, dealerId }, successMessage, errorMessage, true));
    } else if (vendor?.Id && dealerId) {
      // Update the vendor
      successMessage = `${toUpdate.name ? toUpdate.name : toUpdate.email} updates saved`;
      errorMessage = 'An error occurred while editing the vendor';
      dispatch(vendorActions.update({ ...toUpdate, dealerId }, successMessage, errorMessage, true));
    }
  };

  useEffect(() => {
    if (apiErrorMessage && !!apiErrorMessage.length) {
      dispatch(vendorActions.setErrorMsgEmailValidate(''));
    }
  }, [watchEmail]);

  useEffect(() => {
    if (apiErrorMessage && !!apiErrorMessage.length) {
      if (formErrorType === createVendorErrorTypes.DUPLICATE_VENDOR_NAME) {
        setError('name', { type: 'custom', message: apiErrorMessage });
      } else {
        setError('email', { type: 'custom', message: apiErrorMessage });
      }
    }
  }, [apiErrorMessage, formErrorType]);

  useEffect(() => {
    if (vendorDealer?.invitationHistory && !!vendorDealer.invitationHistory.length && vendorDealer?.vendor) {
      const sortedArray = sortInvitationHistory(vendorDealer.invitationHistory);
      let latestInvitation;
      if (sortedArray && sortedArray.length > 0) {
        latestInvitation = sortedArray[sortedArray.length - 1];
      }
      let phoneNumber = latestInvitation?.phoneNumber?.replace(regExp.NOT_NUMBERS, '');
      if (phoneNumber) {
        phoneNumber = formatPhoneNumber(phoneNumber);
      }
      reset({
        email: vendorDealer.vendor.email,
        contactFirstName: latestInvitation?.firstName,
        contactLastName: latestInvitation?.lastName,
        phone: phoneNumber,
        name: latestInvitation?.businessName
      });
    } else if (vendorDealer?.vendor) {
      reset({
        email: vendorDealer.vendor.email
      });
    }
  }, [vendorDealer]);

  return (
    <StyledLayout style={{ overflow: 'hidden' }}>
      <StyledLayoutContent style={{ overflow: 'hidden' }}>
        <Row style={{ marginTop: '24px' }} type="flex" align="middle" justify="center">
          <Col xs={22}>
            <form ref={formRef}>
              <CommonFormRender
                forms={forms(isReinvite)}
                formRef={formRef}
                errors={errors}
                control={control}
                gutter={[16, 8]}
              />
            </form>
          </Col>
        </Row>
      </StyledLayoutContent>

      <Affix offsetBottom={0}>
        <StyledLayoutFooter
          style={{
            backgroundColor: '#ffffff',
            width: '100%',
            height: '72px'
          }}
        >
          <Divider style={{ margin: 0, width: '100%' }} />
          <Row type="flex" justify="end" align="middle" style={{ height: '100%' }}>
            <Col style={{ marginRight: '24px' }}>
              <Row type="flex" justify="space-between" gutter={16}>
                <Col>
                  <CommonLinkButton style={{ fontSize: '16px' }} type="link" onClick={handleCanClose}>
                    Cancel
                  </CommonLinkButton>
                </Col>

                <Col>
                  <StyledButton
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    disabled={isReinvite ? !watchEmail : !isDirty || !watchEmail}
                  >
                    {!!vendorDealer?.vendor?.id && !isReinvite ? 'Save' : 'Send Invite'}
                  </StyledButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledLayoutFooter>
      </Affix>
    </StyledLayout>
  );
};

export default NewVendorForm;
