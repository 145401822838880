import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { CircularSpinner } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { elementRotate } from 'styles/mixins';
import { theme as themeStyles } from 'styles/appTheme';

const MultiSelect = ({ label, menu, checkedCount, style, onVisibleChange = (v) => {}, loading = false, marginLeft = 0 }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      visible={dropdownIsOpen}
      onVisibleChange={(v) => {
        setDropdownIsOpen(v);
        onVisibleChange(v);
      }}
    >
      <StyledMultiSelect dropdownIsOpen={dropdownIsOpen} boldLabel={checkedCount > 0} style={{...style, marginLeft: marginLeft ? `${marginLeft}px` : '0'}}>
        <div>{label}</div>
        {loading ? (
          <CircularSpinner size={14} additionalStyles={{ color: themeStyles.colors.iconGray, marginRight: '15px' }} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </StyledMultiSelect>
    </Dropdown>
  );
};

const StyledMultiSelect = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(props) => (props['dropdownIsOpen'] ? props.theme.colors.navy : props.theme.colors.borderGray)};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  height: 32px;
  padding: 6px 8px;
  gap: 4px;

  div:first-child {
    flex: 1 1 auto;
    white-space: nowrap;
    line-height: 20px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    letter-spacing: 0.25px;
  }

  ${elementRotate('fa-chevron-down', 'dropdownIsOpen', 180, 0.3)}

  .fa-chevron-down {
    color: ${(props) => (props['dropdownIsOpen'] ? props.theme.colors.navy : props.theme.colors.darkGray)};
    left: 7.35%;
    right: 7.35%;
    top: 24.12%;
    bottom: 24.12%;
    height: 12px;
    width: 12px;
  }

  :hover {
    border: 1px solid ${(props) => (props['dropdownIsOpen'] ? 'inherit' : props.theme.colors.lightGray)};
    .fa-chevron-down {
      color: ${({ theme }) => theme.colors.navy};
    }
  }
`;

export default MultiSelect;
