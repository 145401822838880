import React from 'react';
import { Popover, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Component Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faClipboard, faCopy, faStopwatch, faBan } from '@fortawesome/free-solid-svg-icons';

// Local Components
import { BaseHoverContent, BasicLayeredTaskIcon } from 'components/layout/tasks/icons';

// Local Utils
import { dateTimeFromNow } from 'utils/dateTimeUtils';
import { getTaskProgress } from 'components/layout/tasks/common/taskUtils';
import { taskProgressLabels } from 'app-constants';
import {  DECLINED } from 'app-constants/taskStatusTypes';

// Styles
import { theme as themeStyles } from 'styles/appTheme';

const { Text } = Typography;

/**
 * Highlight Task progress text in popover.
 *
 * @param {object} task
 */
const TaskProgressInformation = ({ task }) => {
  const { info, status } = getTaskProgress(task);
  const { colors: { red, orange }, fontWeights: { medium } } = themeStyles;
  const { Overdue, PastGoal } = taskProgressLabels;

  if ([Overdue, PastGoal].includes(status)) {
    return <Text style={{ color: status === Overdue ? red : orange, fontWeight: medium }}>{info}</Text>;
  }

  return null;
};
TaskProgressInformation.propTypes = {
  task: PropTypes.objectOf(PropTypes.any),
};
TaskProgressInformation.defaultProps = {
  task: {},
};

/**
 * Set icon to show and popover content to show order of priority -
 *   - show declined icon if at least one of the tasks is declined
 *   - show overdue icon if at least one of the tasks is overdue
 *   - show past goal if at least one of the tasks is past goal
 *
 * @param {[object]} remainingTasks
 * @param {boolean} singleTask
 */
const TaskGroupIconAndPopover = ({ remainingTasks, singleTask, flags}) => {
  const tasks = Array.isArray(remainingTasks) ? remainingTasks : [remainingTasks];

  // This will be what is displayed for any groups that have tasks determined to be overdue, declined or
  // past goal - there's additional information that is needed to be displayed for these groups
  let CustomHoverContent = (
    <div>
      {tasks.map((task) => {
        return (
          <TaskInfo key={task.id}>
            {!singleTask ? (
              <Text style={{ fontWeight: themeStyles.fontWeights.medium }}>{task.reconTaskTypeName}</Text>
            ) : null}

            {task?.declinedOn ? (
              <Text
                style={{
                  lineHeight: 1,
                  color: themeStyles.colors.red,
                  fontWeight: themeStyles.fontWeights.medium,
                  display: 'block'
                }}
              >
                Declined: {dateTimeFromNow(task.declinedOn)}
              </Text>
            ) : null}

            <div>
              <TaskProgressInformation task={task} />
            </div>

            <BaseHoverContent task={task} showLeftBorder />
          </TaskInfo>
        )
      })}
    </div>
  );

  const anyDeclinedTasks = tasks.some(task => (task.declined || task.status === DECLINED));
  // Here's where we can determine the icon to show based on product priority
  if (anyDeclinedTasks) {
    return <BasicLayeredTaskIcon customHoverContent={CustomHoverContent} backgroundColor={flags.reconTaskCollapse ? themeStyles.colors.transparent : themeStyles.colors.red}  foregroundColor={flags.reconTaskCollapse ? themeStyles.colors.red : themeStyles.colors.white} backgroundIcon={faCircle} foregroundIcon={faBan} group />;
  } else if (tasks.some(task => getTaskProgress(task).status === taskProgressLabels.Overdue)) {
    return <BasicLayeredTaskIcon customHoverContent={CustomHoverContent} backgroundColor={flags.reconTaskCollapse ? themeStyles.colors.transparent : themeStyles.colors.red} foregroundColor={flags.reconTaskCollapse ? themeStyles.colors.red : themeStyles.colors.white} backgroundIcon={faCircle} foregroundIcon={faStopwatch} group />;
  } else if (tasks.some(task => getTaskProgress(task).status === taskProgressLabels.PastGoal)) {
    return <BasicLayeredTaskIcon customHoverContent={CustomHoverContent} backgroundColor={flags.reconTaskCollapse ? themeStyles.colors.transparent : themeStyles.colors.orange} foregroundColor={flags.reconTaskCollapse ? themeStyles.colors.orange : themeStyles.colors.white} backgroundIcon={faCircle} foregroundIcon={faStopwatch} group />;
  } else {
    // Else keep icon as default here - in progress
    // Default for icon and popover content will be in progress
    // Show all task information
    // In progress groups do not need to display additional information
    CustomHoverContent = (
      <div>
        {tasks.map((task) => (
          <TaskInfo key={task.id}>
            {!singleTask ? <Text style={{ fontWeight: themeStyles.fontWeights.medium }}>{task.reconTaskTypeName}</Text> : null}
            <BaseHoverContent showLeftBorder={!singleTask} task={task} />
          </TaskInfo>
        ))}
      </div>
    );

    let iconContent = <FontAwesomeIcon icon={singleTask ? faClipboard : faCopy} style={{ fontSize: 16 }} />;

    return (
      <Popover content={CustomHoverContent} trigger='hover'>
        {iconContent}
      </Popover>
    );
  }
}
TaskGroupIconAndPopover.propTypes = {
  remainingTasks: PropTypes.arrayOf(PropTypes.object),
  singleTask: PropTypes.bool,
}
TaskGroupIconAndPopover.defaultProps = {
  remainingTasks: [],
  singleTask: false,
}

const TaskInfo = styled.div`
  margin-bottom: 8px;
`;

export default TaskGroupIconAndPopover;
