export const isPassedNumberFormat = (value) => {
  const globalRegex = /^\d+\.?\d{0,2}$/;
  return value ? globalRegex.test(value) : true;
};

export const getFractionDigits = (cost1, cost2) => {
  const globalRegex = /^\d+\.?\d$/;
  if (cost1 && cost2) {
    return globalRegex.test(cost1) && globalRegex.test(cost2) ? 1 : 2;
  }
  if (cost1 && !cost2) {
    return globalRegex.test(cost1) ? 1 : 2;
  }
  if (!cost1 && cost2) {
    return globalRegex.test(cost2) ? 1 : 2;
  }
};

export const numberOnlyParser = (value) => {
  const firstRegex = /^(\d*\.?)/;
  const secondRegex = /(\d*)\.?/g;
  const regexFlags = (firstRegex.flags + secondRegex.flags)
    .split('')
    .sort()
    .join('')
    .replace(/(.)(?=.*\1)/g, '');
  const combinedRegex = new RegExp(firstRegex.source + '|' + secondRegex.source, regexFlags);
  const valueWithDigitsAndDots = value.replace(/[^.\d]/g, '');
  const valueWithOnlyOneDot = valueWithDigitsAndDots.replace(combinedRegex, '$1$2');
  return valueWithOnlyOneDot;
};
