import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { CommonStrongText, GridArea, GridTemplate } from 'components/styledComponents';
import { useTimeoutBoolean } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ReconPlanContainer } from './ReconPlanContainer';
import { Header } from './Header';
import Activity from './Activity';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';

export const { Text } = Typography;

const VehicleDetails = ({
  vehicle,
  closeDrawer,
  formProps,
  setFormProps,
  closeForm,
  showDiscardButton,
  setShowDiscardButton,
  scrollToId,
  setScrollToId,
  isSaving,
  taskGroups,
  setTaskGroups,
  originalTaskGroups,
  planStarted,
  onTaskChange,
  templateDropdownMenu,
  planTemplates,
  showCreateReconPlanFromTemplateButton,
  contentType,
  setTemplatePlanCreatedFrom,
  templatePlanCreatedFrom,
  reconPlanFeatures,
  showSeeMoreReply,
  setShowSeeMoreReply,
  isSavingNote,
  flags
}) => {
  const drawerReady = useTimeoutBoolean(1500, false, true);

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.VEHICLE_DETAIL_VIEWED);

  return (
    <StyledVehicleDetailsContainer>
      <StyledHeaderGridArea>
        <Header vehicle={vehicle} />
        <StyledCloseButtonContainer onClick={() => closeDrawer()}>
          <StyledIcon icon={faTimes} />
        </StyledCloseButtonContainer>
      </StyledHeaderGridArea>
      <ReconPlanContainer
        drawerReady={drawerReady}
        vehicle={vehicle}
        closeDrawer={closeDrawer}
        formProps={formProps}
        setFormProps={setFormProps}
        closeForm={closeForm}
        showDiscardButton={showDiscardButton}
        setShowDiscardButton={setShowDiscardButton}
        scrollToId={scrollToId}
        setScrollToId={setScrollToId}
        isSaving={isSaving}
        taskGroups={taskGroups}
        setTaskGroups={setTaskGroups}
        originalTaskGroups={originalTaskGroups}
        planStarted={planStarted}
        onTaskChange={onTaskChange}
        templateDropdownMenu={templateDropdownMenu}
        planTemplates={planTemplates}
        showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
        contentType={contentType}
        setTemplatePlanCreatedFrom={setTemplatePlanCreatedFrom}
        reconPlanFeatures={reconPlanFeatures}
        flags={flags}
        templatePlanCreatedFrom={templatePlanCreatedFrom}
      />
      {/* DON'T REMOVE, JUST HIDING TEMPORARILY UNTIL THIS AREA GETS IMPLEMENTED */}
      {/* <StyledPhotosGridArea>
        <Photos vehicle={vehicle} />
      </StyledPhotosGridArea> */}
      <StyledCommunicationsGridArea>
        <Activity
          vehicle={vehicle}
          showSeeMoreReply={showSeeMoreReply}
          setShowSeeMoreReply={setShowSeeMoreReply}
          flags={flags}
          isSavingNote={isSavingNote}
        />
      </StyledCommunicationsGridArea>
    </StyledVehicleDetailsContainer>
  );
};

// DON'T REMOVE, JUST HIDING TEMPORARILY UNTIL THIS AREA GETS IMPLEMENTED
// const Photos = ({ vehicle }) => {
//   return (
//     <StyledPhotosContainer>
//       <StyledHeaderLabel>
//         Photos
//       </StyledHeaderLabel>
//       <StyledWrapContainer>
//       </StyledWrapContainer>
//     </StyledPhotosContainer>
//   )
// }

//#region Styled components
//#region Shared Styled Components
export const StyledHeaderLabel = styled(CommonStrongText)`
  padding: 24px 24px 20px 24px;
  line-height: 10px;
`;
//#endregion
//#region Styled Components for VehicleDetails
const StyledVehicleDetailsContainer = styled(
  GridTemplate(
    `'header header'
   'reconPlan communications'`,
    '178px minmax(auto, 1fr)',
    'minmax(0px, 1fr) minmax(0px, 1fr)'
  )
)`
  height: 100vh;
  -ms-overflow-x: hidden;
  -ms-overflow-y: hidden;
`;
const StyledHeaderGridArea = styled(GridArea('header', 1, 1, 1, 2))`
  border-bottom: ${({ theme }) => theme.borders.thinSolidGray};
  display: flex;
  background: ${({ theme }) => theme.colors.backgroundGray};
`;
const StyledCommunicationsGridArea = styled(GridArea('communications', 2, 2))`
  background-color: ${({ theme }) => theme.colors.white};
`;
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
//#endregion
// eslint-disable-next-line
const StyledCloseButtonContainer = styled.div.attrs({
  className: 'center-content'
})`
  margin: 15px 10px 0px 5px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 100;
  border-radius: 3px;
  transition: 0.2s;
  :hover {
    background-color: #eaeded;
  }
`;
//#endregion
export default withLDConsumer()(VehicleDetails);
