import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

// This DrawerContainer contains the base header used in most of the drawers
const BaseDrawerContainer = ({ headerLabel, onClose, showBackButton = false, handleBackButtonClick, children, isSaving = false }) => {

  return (
    <StyledContainer>
      <div>
        <StyledHeaderContainer>
          {showBackButton && <StyledBackButtonContainer
            disabled={isSaving}
            onClick={e => {
              if (!isSaving) {
                handleBackButtonClick(e);
              }
            }}>
            <StyledIcon icon={faArrowLeft} />
          </StyledBackButtonContainer>}
          {headerLabel}
          <StyledCloseButtonContainer
            disabled={isSaving}
            onClick={e => {
              if (!isSaving) {
                onClose(e);
              }
            }}>
            <StyledIcon data-testid='drawer-close-button' icon={faTimes} />
          </StyledCloseButtonContainer>
        </StyledHeaderContainer>
      </div>
      <StyledScrollableContainer>
        <StyledContentContainer>
          {children}
        </StyledContentContainer>
      </StyledScrollableContainer>
    </StyledContainer>
  )
}

//#region Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const StyledScrollableContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const StyledContentContainer = styled.div`
  flex: 1 1 0px;
`;
const StyledHeaderContainer = styled.div.attrs({
  className: 'center-content'
})`  
  height: 64px;
  background-color: ${({theme}) => theme.colors.navy};
`;
const StyledButtonContainer = styled.div.attrs({
  className: 'center-content'
})`
  position: absolute;
  cursor: ${props => props['disabled'] ? 'inherit' : 'pointer'};
  color: ${props => props['disabled'] ? '#909497' : props.theme.colors.white};
  z-index: 10;
  transition: 0.2s;
  :hover {
    background-color: ${props => props['disabled'] ? 'inherit' : '#34495E'};
  }
`;
const StyledCloseButtonContainer = styled(StyledButtonContainer)`
  right: 15px;
  height: 30px;
  width: 30px;
  border-radius: 3px;
`;
const StyledBackButtonContainer = styled(StyledButtonContainer)`
  left: 15px;
  height: 40px;
  width: 40px;
  border-radius: 24px;
`;
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${({theme}) => theme.fontSizes.lg};
`;
//#endregion

export default BaseDrawerContainer
