import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { createSelector } from 'reselect';
import { createRequestTypes, makeActionCreator } from 'utils';
import { USER_FOUND, LOADING_USER } from 'redux-oidc';
import { IS_NEW_SIGNIN, VENDOR_USER_INFO } from '../app-constants';

//used for parsing inital state from localstorage
const parseAuthToken = (token, tokenExpires, expiresAt) => {
  if (!token) {
    return {};
  }

  if (!tokenExpires) {
    tokenExpires = new Date(expiresAt * 1000);
  }
  const jwt = jwtDecode(token);
  const user = {
    username: jwt.sub,
    id: jwt.recon_user_id ?? jwt.sub,
    firstName: jwt.given_name,
    lastName: jwt.family_name
  };

  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  if (isNewSignin) {
    const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
    const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
    if (vendorInfo && vendorInfo.id) {
      user.id = vendorInfo.id;
    }
  }

  return {
    token: token,
    tokenExpires: tokenExpires,
    user: user,
    defaultDealerId: jwt.recon_dealer_id
  };
};

export const AUTH_TYPES = createRequestTypes('AUTH_TYPES', ['SET_BRIDGE_USER']);

export const authActions = {
  setBridgeUser: makeActionCreator(AUTH_TYPES.SET_BRIDGE_USER, 'isBridgeUser')
};

export const authReducer = (state = null, action) => {
  switch (action.type) {
    case USER_FOUND:
      return {
        ...state,
        ...parseAuthToken(action.payload.access_token, null, action.payload.expires_at)
      };
    case AUTH_TYPES.SET_BRIDGE_USER:
      return {
        ...state,
        isBridgeUser: action.isBridgeUser
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const authSelector = createSelector(
  (state) => state.auth,
  (auth) => auth
);
export const oidcSelector = createSelector(
  (state) => state.oidc,
  (auth) => auth
);
//#endregion

export const authTokenMiddleware = (store) => (next) => (action) => {
  if (action.type === LOADING_USER) {
    axios.interceptors.request.use(
      function (config) {
        const auth = store.getState().auth;
        config.headers.Authorization = `bearer ${auth.token}`;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
  return next(action);
};
