import styled, { css } from 'styled-components';
import { Button, Typography, Card, InputNumber, Select, Menu, Input, Breadcrumb, Checkbox } from 'antd';
import { theme as themeStyles } from 'styles/appTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CommonCardContainer = styled(Card).attrs({
  size: 'small',
  hoverable: false
})`
  .ant-card& {
    margin: 0px 10px 10px 10px;
    cursor: default;
    .ant-card-body {
      padding: 0;
    }
  }
  .ant-card-bordered& {
    border: ${(props) =>
      props['data-is-error']
        ? `${props.theme.borders.thickSolidRedBorder}`
        : props['data-isselected']
        ? `2px solid ${props.theme.colors.navy}`
        : '1px solid #e8e8e8'};
    border-radius: ${(props) => (props['data-is-error'] ? '6px' : 'none')};
  }
`;
export const CommonHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  button {
    margin-left: 8px;
  }
`;
export const CommonHeaderLabel = styled(Typography.Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.white};
  }
`;
export const CommonLinkButton = styled(Button).attrs({
  type: 'link'
})`
  .ant-btn-link& {
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    padding: 0;
    :hover,
    :active,
    :visited,
    :focus {
      color: ${(props) => (props['toggle-expand-collapse'] ? props.theme.colors.red : props.theme.toggle.red)};
    }
    :disabled& {
      color: ${({ theme }) => theme.colors.lightGray} !important;
    }
  }
`;
// Must be updated with CommonRedButton
export const StyleReassignCompletedContainer = styled.div.attrs({
  className: 'box-reassign-complete'
})`
  button:last-child {
    background-color: rgb(235, 0, 46) !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
`;
export const CommonRedButton = styled(Button).attrs({
  type: 'danger'
})`
  .ant-btn& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border-radius: 4px;
    height: 32px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
  }
  .ant-btn-danger[disabled]& {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.lightRedButton};
    border-color: unset;
    :hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.lightRedButton};
      border-color: unset;
    }
    .ant-btn-background-ghost& {
      color: ${({ theme }) => theme.colors.lightRedButton} !important;
      border-color: ${({ theme }) => theme.colors.lightRedButton} !important;
    }
    .btn-reassign:last-child& {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

export const CommonFooterContainer = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
  background-color: ${({ theme }) => theme.colors.white};
  height: 73px;
`;
export const CommonSortableItem = styled.div`
  cursor: move; // fallback if grab cursor is unsupported
  cursor: grab;
  flex: 1;
  min-width: 340px;
  margin: 0 4px 8px 4px;
`;
export const CommonInput = styled(Input)`
  .ant-input-lg& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const CommonTextArea = styled(Input.TextArea)`
  .ant-input-lg& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
  padding: 6px 11px !important;
`;

export const CommonInputNumber = styled(InputNumber).attrs((props) => ({
  size: 'large'
}))`
  .ant-input-number-lg& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;
export const CommonSelect = styled(Select).attrs((props) => ({
  size: 'large'
}))`
  .ant-select& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;
export const CommonListPageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundGray};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const CommonControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundGray};
`;
export const CommonFiltersRow = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 15px 11px 7px 11px;

  .label {
    margin-right: 10px;
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;
export const CommonSortRow = styled.div`
  display: flex;
  height: 30px;
  padding: 0 11px 5px 11px;

  div:first-child {
    flex: 1 1 0px;
    > strong {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }
`;
export const CommonSelectorContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledDropdownMenuItem = css`
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 0;
    height: 32px;
    font-size: ${({ theme }) => theme.fontSizes.md};
    transition: none;
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      font-size: ${({ theme }) => theme.fontSizes.md};
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 4px;
        border: unset;
      }
      .ant-checkbox-inner {
        border: 1px solid ${({ theme }) => theme.colors.borderGray};
        border-radius: 4px;
      }
      .ant-checkbox-checked::after {
        border: unset;
      }
    }
    :hover {
      background-color: ${({ theme }) => theme.colors.backgroundGray};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }
`;
export const CommonMenu = styled(Menu)`
  .ant-dropdown-menu& {
    padding: 8px 0;
    border-radius: unset;
    max-height: min(400px, 100vh - 139px);
    overflow-y: auto;

    .ant-dropdown-menu-item {
      display: flex;
      align-items: center;
      height: 32px;
      font-size: ${({ theme }) => theme.fontSizes.md};
      transition: none;

      :hover {
        background-color: ${({ theme }) => theme.colors.backgroundGray};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
    }
  }
`;
export const CommonCheckboxMenu = styled(Menu)`
  .ant-dropdown-menu& {
    padding: 8px 0;
    border-radius: unset;
    max-height: min(${(props) => (props['data-max-height'] ? props['data-max-height'] : '488px')}, 100vh - 139px);
    overflow-y: auto;
    width: 106% ${StyledDropdownMenuItem};
  }
`;
export const CommonGroupCheckboxMenu = styled(Menu)`
  .ant-dropdown-menu& {
    padding: 8px 0;
    border-radius: unset;
    max-height: min(${(props) => (props['data-max-height'] ? props['data-max-height'] : '476px')}, 100vh - 139px);
    overflow-y: auto;

    .ant-dropdown-menu-item-group {
      .ant-dropdown-menu-item-group-title {
        color: ${({ theme }) => theme.colors.navy};
        font-size: ${({ theme }) => theme.fontSizes.md};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        padding: 8px 16px;
        line-height: 19px;
      }
      .ant-dropdown-menu-item-group-list {
        margin: 0;
        padding: 0;
        ${StyledDropdownMenuItem}
      }
    }
  }
`;
export const CommonMenuItem = styled(Menu.Item)`
  :not(:hover) {
    background-color: ${(props) => (props['data-isselected'] ? props.theme.colors.borderGray : 'inherit')};
  }
  > span {
    font-weight: ${(props) => (props['data-isselected'] ? props.theme.fontWeights.medium : 'inherit')};
  }
`;
export const CommonSearch = styled(Input.Search).attrs({
  allowClear: true
})`
  .ant-input-affix-wrapper& {
    width: ${(props) => (props['data-is-focused'] ? '100%' : '150px')};
    max-width: 330px;
    transition: width 0.6s ease-in-out;
    margin-left: 8px;
    height: ${(props) => (props['custom-height'] ? `${props['custom-height']}px` : '32px')};
    border-radius: 4px;

    .ant-input-prefix {
      font-size: ${({ theme }) => theme.fontSizes.md};
      color: ${({ theme }) => theme.colors.secondaryGrey};
      left: 16px;
    }
    .ant-input-suffix .ant-input-search-icon {
      display: none;
    }
    .ant-input {
      font-size: ${({ theme }) => theme.fontSizes.md};
      border: ${({ theme }) => theme.borders.thinSolidGray};
      line-height: 24px;
      letter-spacing: 0.25px;
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      border-radius: 4px;
      :focus {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
      }
    }
    .ant-input:not(:first-child) {
      padding-left: 45px;
    }
  }
`;

export const CommonSearchLineItemCatalog = styled(Input.Search).attrs({
  allowClear: true
})`
  .ant-input-affix-wrapper& {
    width: ${(props) => (props['data-is-focused'] ? '100%' : '150px')};
    max-width: 330px;
    transition: width 0.6s ease-in-out;

    margin-left: 8px;
    height: ${(props) => (props['custom-height'] ? `${props['custom-height']}px` : '40px')};
    border-radius: 4px;

    .ant-input-prefix {
      font-size: ${({ theme }) => theme.fontSizes.md};
      color: ${({ theme }) => theme.colors.iconGray};
      left: 16px;
    }
    .ant-input-suffix .ant-input-search-icon {
      display: none;
    }
    .ant-input {
      font-size: ${({ theme }) => theme.fontSizes.md};
      border: ${({ theme }) => theme.borders.thinSolidGray};
      border-radius: 4px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      :focus {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
      }
    }
    .ant-input:not(:first-child) {
      padding-left: 45px;
    }
  }
`;

export const CommonStrongText = styled(Typography.Text)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
export const CommonCenteredText = styled(Typography.Text)`
  display: block;
  text-align: center;
`;

export const CommonBreadcrumb = styled(Breadcrumb)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  .ant-breadcrumb& {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    line-height: inherit;
  }
`;
export const CommonBreadcrumbButton = styled.span`
  :hover {
    color: ${({ theme }) => theme.colors.navy};
    cursor: ${(props) => (props['disabled'] ? 'inherit' : 'pointer')};
  }
`;

export const CommonBreadcrumbLink = styled.span`
  color: ${({ theme }) => theme.colors.lightBlueLinkColor};
  font-family: Roboto;
  letter-spacing: 0;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const CommonImagesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  & > img {
    margin: 0 8px 8px 0;
    cursor: pointer;
  }
`;

export const GridTemplate = (
  templateAreas = '',
  templateRows = '1fr',
  templateColumns = '1fr',
  elementType = 'div'
) => styled[elementType]`
  display: grid;
  overflow: hidden;
  display: -ms-grid;
  grid-template-areas: ${templateAreas};
  grid-auto-rows: ${(props) => (props['grid-auto-rows'] ? props['grid-auto-rows'] : templateRows)};
  grid-auto-columns: ${templateColumns};
  -ms-grid-rows: ${templateRows};
  -ms-grid-columns: ${templateColumns};
`;
export const GridArea = (
  gridArea,
  gridRow = 1,
  gridColumn = 1,
  gridRowSpan = 1,
  gridColumnSpan = 1,
  elementType = 'div'
) => styled[elementType]`
  grid-area: ${gridArea};
  -ms-grid-row: ${gridRow};
  -ms-grid-column: ${gridColumn};
  -ms-grid-row-span: ${gridRowSpan};
  -ms-grid-column-span: ${gridColumnSpan};
`;

export const LinkStyle = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.lightBlueLinkColor};
  height: 20px;
  width: 71px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  margin-left: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderXLarge = styled.span`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
`;

export const HeaderLarge = styled.span`
  font-size: 28px;
  line-height: 44px;
  font-weight: 400;
`;

export const HeaderMedium = styled.span`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
`;

export const HeaderSmall = styled.span`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
`;

export const BodyBase = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const BodyBaseBold = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const BodyBaseBoldShort = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const BodySmall = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const BodySmallBold = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const BodyXSmall = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

export const BodyXSmallBold = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

export const BodyXSmallBoldShort = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;

export const BodyVerySmallBold = styled.span`
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
`;

export const StyledConfirmationModalIcon = styled(FontAwesomeIcon)`
  color: ${themeStyles.colors.navy} !important;
`;
export const BlueCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.infoPrimary};
    border-color: ${({ theme }) => theme.colors.infoPrimary};
  }
  .ant-checkbox-disabled + span {
    color: ${({ theme }) => theme.colors.titanium700};
  }
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.titanium700};
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ theme }) => theme.colors.white};
  }
`;
