import { Drawer } from 'antd';
import { createSelector } from 'reselect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InternalGroupForm } from './';
import { DrawerContainer } from 'components';
import { usersActions } from 'store/usersStore';
import { internalGroupActions } from 'store/internalGroupStore';
import { CommonHeaderLabel } from 'components/styledComponents';
import { apiStatusConstants, detailContents } from 'app-constants';
import { messagesActions, messageSelector } from 'store/messagesStore';

const internalGroupDrawerPropsSelector = createSelector(
  (state) => state.users,
  (state) => state.dealers?.current?.data?.id,
  (state) => state.internalGroups,
  messageSelector,
  (usersContainer, dealerId, internalGroupStore, message) => ({
    users: usersContainer.assigneeData?.internalUsers,
    usersFetchStatus: usersContainer.fetchStatus,
    currentInternalGroup: internalGroupStore.internalGroup,
    dealerId,
    message
  })
);

const InternalGroupDrawer = ({ selectedCard, setSelectedCard, isNameTaken }) => {
  //#region Drawer
  const dispatch = useDispatch();
  const { message, users, currentInternalGroup, usersFetchStatus, dealerId } = useSelector(internalGroupDrawerPropsSelector);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const drawerWidth = 600;

  useEffect(() => {
    if (!usersFetchStatus || usersFetchStatus !== apiStatusConstants.IS_FETCHING) {
      dispatch(usersActions.getAssigneeData(dealerId));
    }
  }, []);

  useEffect(() => {
    if (currentInternalGroup?.id)
      setSelectedCard({ contentType: detailContents.INTERNAL_GROUPS, internalGroup: currentInternalGroup });
  }, [currentInternalGroup]);

  useEffect(() => {
    const initial = {
      name: selectedCard.internalGroup?.name,
      members: selectedCard.internalGroup?.internalGroupUsers || []
    };
    setInitialValues(initial);
  }, [selectedCard]);

  useEffect(() => {
    // Here I'm latching on to the messages to determine if the save has succeeded or not, which I'm using to determine whether to close the drawer or not.
    // Longer term, latching on to the messages may not be viable if messages start being used more asynchronously
    if (message) {
      const { messageType } = message;
      if (messageType === 'loading') {
        setIsSaving(true);
      } else {
        setIsSaving(false);
      }
      if (messageType === 'error' && selectedCard.isFetchingGroup)
        closeDrawer();
      if (messageType === 'success') {
        dispatch(messagesActions.clear());
        closeDrawer();
      }
    }
  }, [message]);

  useEffect(() => {
    if (!usersFetchStatus || usersFetchStatus !== apiStatusConstants.IS_FETCHING) {
      dispatch(usersActions.getAssigneeData(dealerId));
    }
  }, [dealerId]);

  const resetData = () => {
    dispatch(internalGroupActions.setInternalGroup(null));
  }

  const closeDrawer = () => {
    resetData();
    setSelectedCard({ contentType: null }); //setting contentType to null will close the drawer
  };

  const handleCanCloseDrawer = () => {
    if (!isSaving) { // can't close while saving
      closeDrawer();
    }
    return true;
  };

  const onSave = (value) => {
    const isEdit = !!selectedCard.internalGroup?.id;
    const loadingMessage = `${isEdit ? 'Saving' : 'Creating'} Internal Group...`;
    const successMessage = `Internal Group ${isEdit ? 'saved' : 'created'}.`;
    const errorMessage = `An error occurred while ${isEdit ? 'saving' : 'creating'} the Internal Group.`;
    const newInternalGroup = {
      id: selectedCard.internalGroup?.id,
      ...value,
      dealerId: dealerId
    };

    if (!newInternalGroup.id) {
      //Add a new vendor
      dispatch(internalGroupActions.add(newInternalGroup, loadingMessage, successMessage, errorMessage));
    } else {
      //Update the vendor
      dispatch(internalGroupActions.update(newInternalGroup, loadingMessage, successMessage, errorMessage));
    }

  }
  const getHeaderLabel = () =>
    selectedCard.internalGroup && selectedCard.internalGroup?.id ? 'Internal Group Details' : 'Create Internal Group';
  //#endregion

  return (
    <Drawer
      closable={false}
      destroyOnClose={true}
      placement="right"
      width={drawerWidth}
      visible={!!selectedCard.contentType}
      onClose={() => closeDrawer()}
      bodyStyle={{ padding: '0' }}
    >
      {selectedCard.contentType && (
        <DrawerContainer
          headerLabel={<CommonHeaderLabel>{getHeaderLabel()}</CommonHeaderLabel>}
          onClose={closeDrawer}>
            <InternalGroupForm 
              internalGroup={selectedCard.internalGroup}
              isFetching={selectedCard.isFetchingGroup}
              isNameTaken={isNameTaken}
              isSaving={isSaving}
              onSave={onSave}
              closeDrawer={closeDrawer}
              handleCanClose={handleCanCloseDrawer}
              users={users}
              usersFetchStatus={usersFetchStatus}
              initialValues={initialValues}
            />
        </DrawerContainer>
      )}
    </Drawer>
  );
};

// #region Styled Components
// #endregion

export default InternalGroupDrawer;
