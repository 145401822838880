import { getWithToken } from 'api';
import { apiStatusConstants } from 'app-constants';
import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { LINE_ITEMS_CHANGE_STATUS } from 'app-constants/lineItemConstants';
import { createRequestTypes, makeActionCreator, convertDataToDisplay } from 'utils';
import { messageSelector } from 'store/messagesStore';

//#region Actions
export const VDP_TYPES = createRequestTypes('VDP_TYPES', [
  'SET_CONTENT_TYPE',
  'SET_MODEL',
  'SET_ACTIVE_TAB',
  'SET_ERROR',
  'SET_TASKS_WITH_TRANSIENT_TASKS',
  'SET_TRANSIENT_TASKS',
  'SET_ORIGINAL_TASK_GROUPS',
  'SET_COMMENTS',
  'SET_COMMENTS_FETCH_STATUS',
  'GET_COMMENTS_FOR_VEHICLE',
  'SET_INITIAL_FOCUS',
  'ADD_COMMENTS',
  'UPDATE_COMMENTS',
  'DELETE_COMMENTS',
  'SET_DOCUMENTS',
  'ADD_EXPAND_ITEM',
  'REMOVE_EXPAND_ITEM',
  'SET_NOTIFICATION',
  'SET_LINE_ITEM_CHANGE_STATUS',
  'GET_SELECTED_TASK',
  'SET_SELECTED_TASK',
  'SET_COMMENTS_TASK_IS_DELETED'
]);
export const vdpActions = {
  setContentType: makeActionCreator(VDP_TYPES.SET_CONTENT_TYPE, 'contentType'),
  getModel: makeActionCreator(VDP_TYPES.GET_MODEL),
  setModel: makeActionCreator(VDP_TYPES.SET_MODEL, 'model'),
  setDocuments: makeActionCreator(VDP_TYPES.SET_DOCUMENTS, 'documents'),
  setInitialFocus: makeActionCreator(VDP_TYPES.SET_INITIAL_FOCUS, 'initialFocus'),
  setActiveTab: makeActionCreator(VDP_TYPES.SET_ACTIVE_TAB, 'tab'),
  setError: makeActionCreator(VDP_TYPES.SET_ERROR, 'errorId'),
  setTasksWithTransientTasks: makeActionCreator(VDP_TYPES.SET_TASKS_WITH_TRANSIENT_TASKS, 'tasksWithTransientTasks'),
  setTransientTasks: makeActionCreator(VDP_TYPES.SET_TRANSIENT_TASKS, 'transientTasks'),
  setOriginalTaskGroups: makeActionCreator(VDP_TYPES.SET_ORIGINAL_TASK_GROUPS, 'originalTaskGroups'),
  setCommentsFetchStatus: makeActionCreator(VDP_TYPES.SET_COMMENTS_FETCH_STATUS, 'communicationsFetchStatus'),
  setComments: makeActionCreator(VDP_TYPES.SET_COMMENTS, 'comments'),
  setCommentsTaskIsDeleted: makeActionCreator(VDP_TYPES.SET_COMMENTS_TASK_IS_DELETED, 'taskId'),
  addComments: makeActionCreator(VDP_TYPES.ADD_COMMENTS, 'comment'),
  updateComment: makeActionCreator(VDP_TYPES.UPDATE_COMMENTS, 'updatedComment'),
  deleteComment: makeActionCreator(VDP_TYPES.DELETE_COMMENTS, 'commentId'),
  getCommentsForVehicle: makeActionCreator(VDP_TYPES.GET_COMMENTS_FOR_VEHICLE, 'vehicleId'),
  setExpandItem: makeActionCreator(VDP_TYPES.ADD_EXPAND_ITEM, 'taskIds'),
  setRemoveExpandItem: makeActionCreator(VDP_TYPES.REMOVE_EXPAND_ITEM, 'taskIds'),
  setNotification: makeActionCreator(VDP_TYPES.SET_NOTIFICATION, 'isNotification'),
  setLineItemChangeStatus: makeActionCreator(VDP_TYPES.SET_LINE_ITEM_CHANGE_STATUS, 'lineItemChangeStatus'),
  setSelectedTask: makeActionCreator(VDP_TYPES.SET_SELECTED_TASK, 'data')
};
//#endregion

//#region Reducer
const initialState = {
  contentType: '',
  model: '',
  documents: null,
  initialFocus: null,
  activeTab: '1',
  errorId: '',
  tasksWithTransientTasks: [],
  transientTasks: [],
  originalTaskGroups: [],
  taskGroups: [],
  comments: {
    count: 0,
    items: [],
    limit: 2
  },
  communicationsFetchStatus: apiStatusConstants.IS_FETCHING,
  expandItems: [],
  isNotification: false, // Using for bugs when open vdp from notification history. Prevent double call vehicle list API,
  lineItemChangeStatus: LINE_ITEMS_CHANGE_STATUS.INITIAL,
  selectedTask: null
};

export const vdpReducer = (state = initialState, action) => {
  switch (action.type) {
    case VDP_TYPES.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.contentType
      };
    case VDP_TYPES.SET_MODEL:
      return {
        ...state,
        model: action.model
      };
    case VDP_TYPES.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents
      };
    case VDP_TYPES.SET_INITIAL_FOCUS:
      return {
        ...state,
        initialFocus: action.initialFocus
      };
    case VDP_TYPES.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab
      };
    case VDP_TYPES.SET_ERROR:
      return {
        ...state,
        errorId: action.errorId
      };
    case VDP_TYPES.SET_TASKS_WITH_TRANSIENT_TASKS:
      return {
        ...state,
        tasksWithTransientTasks: action.tasksWithTransientTasks
      };
    case VDP_TYPES.SET_TRANSIENT_TASKS:
      return {
        ...state,
        transientTasks: action.transientTasks
      };
    case VDP_TYPES.SET_ORIGINAL_TASK_GROUPS:
      return {
        ...state,
        originalTaskGroups: action.originalTaskGroups
      };
    case VDP_TYPES.SET_COMMENTS_FETCH_STATUS: {
      return {
        ...state,
        communicationsFetchStatus: action.communicationsFetchStatus
      };
    }
    case VDP_TYPES.SET_COMMENTS:
      return {
        ...state,
        comments: action.comments
      };
    case VDP_TYPES.SET_COMMENTS_TASK_IS_DELETED:
      return {
        ...state,
        comments: {
          count: state.comments.count,
          items: state.comments.items.map((comment) => {
            return comment.reconTaskId === action.taskId ? { ...comment, taskIsDeleted: true } : { ...comment };
          })
        }
      };
    case VDP_TYPES.ADD_COMMENTS:
      return {
        ...state,
        comments: {
          count: state.comments.count + 1,
          items: [action.comment, ...state.comments.items]
        }
      };
    case VDP_TYPES.UPDATE_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          items: state.comments.items.map((item) => {
            if (item.id === action.updatedComment.id) {
              return action.updatedComment;
            } else {
              return item;
            }
          })
        }
      };
    case VDP_TYPES.DELETE_COMMENTS:
      return {
        ...state,
        comments: {
          items: state.comments.items.filter((item) => item.id !== action.commentId),
          count: state.comments.count - 1
        }
      };
    case VDP_TYPES.ADD_EXPAND_ITEM:
      const newTaskIds = action.taskIds.filter((item) => !state.expandItems.includes(item));
      return {
        ...state,
        expandItems: [...state.expandItems, ...newTaskIds]
      };
    case VDP_TYPES.REMOVE_EXPAND_ITEM:
      return {
        ...state,
        expandItems:
          action.taskIds.length === 0 ? [] : state.expandItems.filter((item) => !action.taskIds.includes(item))
      };
    case VDP_TYPES.SET_NOTIFICATION:
      return {
        ...state,
        isNotification: action.isNotification
      };
    case VDP_TYPES.SET_LINE_ITEM_CHANGE_STATUS:
      return {
        ...state,
        lineItemChangeStatus: action.lineItemChangeStatus
      };
    case VDP_TYPES.SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: action.data
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
const getIsExpand = (expandList, taskId) => {
  return expandList.includes(taskId) ? taskId : null;
};

const getMessagesByTaskId = (comments, taskId) => {
  return comments.filter((o) => o.reconTaskId === taskId);
};

export const getExpandTasksSelector = (taskId) => {
  return createSelector(
    (state) => state.vdp.expandItems,
    (expandItems) => {
      return getIsExpand(expandItems, taskId);
    }
  );
};

export const getExpandItemsSelector = () => {
  return createSelector(
    (state) => state.vdp.expandItems,
    (expandItems) => expandItems
  );
};

export const messagesByTaskIdSelector = (taskId) => {
  return createSelector(
    (state) => state.vdp.comments,
    (comments) => {
      return getMessagesByTaskId(comments.items, taskId);
    }
  );
};

const selectVehicleById = (state, vehicleId) => {
  return state?.items?.find((vehicle) => vehicle.id === vehicleId);
};

export const vdpSelector = createSelector(
  (state) => state.vdp.contentType,
  (state) => state.vdp.originalTaskGroups,
  (state) => state.vdp.model,
  (state) => state.vdp.activeTab,
  (state) => state.vdp.comments,
  (state) => state.vehicles.data,
  (state) => state.vdp.errorId,
  messageSelector,
  (contentType, originalTaskGroups, modelId, activeTab, comments, vehiclesList, errorId, message) => {
    const model = selectVehicleById(vehiclesList, modelId);
    return {
      contentType,
      modelId,
      vehicle: model,
      originalTaskGroups,
      activeTab,
      comments,
      vehiclesList,
      errorId,
      message
    };
  }
);
//#endregion

//#region Sagas
export function* getCommentsForVehicleSaga() {
  yield takeEvery(VDP_TYPES.GET_COMMENTS_FOR_VEHICLE, function* ({ vehicleId }) {
    try {
      yield put(vdpActions.setCommentsFetchStatus(apiStatusConstants.IS_FETCHING));
      // yield put(vdpActions.setCommentsFetchStatus(vehicleId, apiStatusConstants.IS_FETCHING));

      let comments = yield getWithToken(`/api/Comments`, { vehicleId, start: 1, limit: 123456789 });
      if (comments && comments.items) {
        comments.items.forEach((i) => {
          i.contentData = convertDataToDisplay(i.contentData);
        });
      }

      // yield put(vdpActions.setComments(vehicleId, comments)); // this action also sets the communicationsFetchStatus to succeeded in the reducer
      yield put(vdpActions.setComments(comments)); // this action also sets the communicationsFetchStatus to succeeded in the reducer
      yield put(vdpActions.setCommentsFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(vdpActions.setCommentsFetchStatus(apiStatusConstants.FAILED));
      // yield put(vdpActions.setCommentsFetchStatus(vehicleId, apiStatusConstants.FAILED));
      devLogger.log('error in getCommentsForVehicleSaga', error);
    }
  });
}

//#endregion
