import React, { useState, useEffect, useContext } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Menu, Button, Input, Modal } from 'antd';
import { Table } from 'antd4';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { apiStatusConstants, features } from 'app-constants';
import { BodyBaseBoldShort, BodyXSmallBold, BodySmall } from 'components/styledComponents';
import { formatterNumberUSD } from 'utils/numberUtils';
import { compareCost, searchLineItemCatalog } from '../../../../utils/arrayUtils';
import { lineItemCatalogActions } from 'store/lineItemCatalogStore';
import { useFeatures } from 'hooks';
import { LIMIT_COST, LIMIT_TOTAL_COST } from 'app-constants/lineItemConstants';
import { AlertIRecon } from 'components/common';
import TruncatedTooltip from '../../../common/TruncatedTooltip';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { GlobalAlertContext } from '../../../App';
import { getFractionDigits, isPassedNumberFormat } from 'utils/regexUtils';
import uuidv4 from 'uuid/v4';

const lineItemCatalogSelector = createSelector(
  (state) => state.lineItemCatalog.lineItemCatalog,
  (state) => state.lineItemCatalog.fetchLineItemCatalogStatus,
  (state) => state.lineItemCatalog.isEditingOrAdding,
  (state) => state.lineItemCatalog.sortedInfo,
  (state) => state.lineItemCatalog.searchString,
  (lineItemCatalog, fetchLineItemCatalogStatus, isEditingOrAdding, sortedInfo, searchString) => ({
    lineItemCatalog: lineItemCatalog,
    fetchLineItemCatalogStatus: fetchLineItemCatalogStatus,
    isEditingOrAdding: isEditingOrAdding,
    sortedInfo: sortedInfo,
    searchString: searchString
  })
);

const TableCatalog = ({ flags, isShowBanner }) => {
  const dispatch = useDispatch();
  const [hasApproverRole] = useFeatures(features.TASKS_APPROVE_LINE_ITEMS);
  const [isDirtyDescription, setIsDirtyDescription] = useState(false);
  const [isDirtyTotalCost, setIsDirtyTotalCost] = useState(false);
  const [description, setDescription] = useState('');
  const [partsCost, setPartsCost] = useState('');
  const [laborCost, setLaborCost] = useState('');
  const [totalCost, setTotalCost] = useState('');
  const [isDisableTotalCost, setIsDisableTotalCost] = useState(false);
  const [isDisableCost, setIsDisableCost] = useState(false);
  const [isDescriptionDuplicated, setIsDescriptionDuplicated] = useState(false);
  const [showDuplicationError, setShowDuplicationError] = useState(false);
  const [saveCondition, setSaveCondition] = useState(
    description.trim() && (hasApproverRole || totalCost) && (isDirtyDescription || isDirtyTotalCost)
  );
  const hasGlobalAlert = !!useContext(GlobalAlertContext);

  useEffect(() => {
    setSaveCondition(description.trim() && (hasApproverRole || totalCost) && (isDirtyDescription || isDirtyTotalCost));
  }, [description, hasApproverRole, totalCost, isDirtyDescription, isDirtyTotalCost]);

  const { fetchLineItemCatalogStatus, lineItemCatalog, isEditingOrAdding, sortedInfo, searchString } = useSelector(
    lineItemCatalogSelector
  );
  const { isBridgeUser } = useSelector((state) => state.auth);

  const handleChange = (pagination, filters, sorter) => {
    if (!isEditingOrAdding) {
      dispatch(lineItemCatalogActions.setSortedInfo(sorter));
    }
  };

  const onChangePartsCost = (event) => {
    setIsDirtyTotalCost(true);
    if (isPassedNumberFormat(event.target.value)) {
      const newPartsCost = event.target.value > LIMIT_COST ? LIMIT_COST : event.target.value;
      setPartsCost(newPartsCost);
      let sum = '';
      if (newPartsCost || laborCost) {
        sum = Number(newPartsCost) + Number(laborCost);
        setTotalCost(
          Number.isInteger(sum) ? sum.toString() : sum.toFixed(getFractionDigits(event.target.value, laborCost))
        );
        setIsDisableTotalCost(true);
      } else {
        setTotalCost('');
        setIsDisableTotalCost(false);
      }
    }
  };

  const onChangeLaborCost = (event) => {
    setIsDirtyTotalCost(true);
    if (isPassedNumberFormat(event.target.value)) {
      const newLaborCost = event.target.value > LIMIT_COST ? LIMIT_COST : event.target.value;
      setLaborCost(newLaborCost);
      let sum = '';
      if (newLaborCost || partsCost) {
        sum = Number(newLaborCost) + Number(partsCost);
        setTotalCost(
          Number.isInteger(sum) ? sum.toString() : sum.toFixed(getFractionDigits(event.target.value, partsCost))
        );
        setIsDisableTotalCost(true);
      } else {
        setTotalCost('');
        setIsDisableTotalCost(false);
      }
    }
  };

  const onChangeTotalCost = (event) => {
    setIsDisableCost(!!String(event.target.value));
    setIsDirtyTotalCost(true);
    if (isPassedNumberFormat(event.target.value)) {
      setTotalCost(event.target.value > LIMIT_TOTAL_COST ? LIMIT_TOTAL_COST : event.target.value);
    }
  };

  const onChangeDescription = (event) => {
    if (!isDirtyDescription) {
      setIsDirtyDescription(true);
    }
    setIsDescriptionDuplicated(false);
    setShowDuplicationError(false);
    setDescription(event.target.value);
  };

  const handleKeyPress = (event, lineItem) => {
    if (event.key === 'Enter' && saveCondition) {
      onSave(lineItem);
    }
  };

  const columnsDef = [
    {
      title: 'Title',
      dataIndex: 'description',
      width: '40%',
      sorter: isEditingOrAdding ? () => {} : (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo?.field === 'description' && sortedInfo?.order,
      render: (_text, record, _index) => {
        return (
          <>
            {record.editable ? (
              <Input
                placeholder="Title Required"
                value={description}
                onChange={onChangeDescription}
                onKeyPress={(event) => handleKeyPress(event, record)}
                className={(isDirtyDescription && !description) || isDescriptionDuplicated ? 'input-error' : ''}
                autoFocus
              />
            ) : (
              <TruncatedTooltip>{record.description}</TruncatedTooltip>
            )}
          </>
        );
      }
    },
    {
      title: 'Parts',
      dataIndex: 'partsCost',
      width: '15%',
      sorter: isEditingOrAdding ? () => {} : (a, b) => compareCost(a, b, 'partsCost'),
      sortOrder: sortedInfo?.field === 'partsCost' && sortedInfo?.order,
      render: (_text, record, _index) => {
        return (
          <>
            {record.editable ? (
              <Input
                placeholder="$0"
                disabled={isDisableCost}
                value={partsCost}
                onChange={onChangePartsCost}
                onKeyPress={(event) => handleKeyPress(event, record)}
              />
            ) : (
              <BodySmall>
                {!!record?.partsCost?.toString()
                  ? `$${formatterNumberUSD(Number(record?.partsCost))}`
                  : `${String.fromCharCode(8212)}${String.fromCharCode(8212)}`}
              </BodySmall>
            )}
          </>
        );
      }
    },
    {
      title: 'Labor',
      dataIndex: 'laborCost',
      width: '15%',
      sorter: isEditingOrAdding ? () => {} : (a, b) => compareCost(a, b, 'laborCost'),
      sortOrder: sortedInfo?.field === 'laborCost' && sortedInfo?.order,
      render: (_text, record, _index) => (
        <>
          {record.editable ? (
            <Input
              placeholder="$0"
              disabled={isDisableCost}
              value={laborCost}
              onChange={onChangeLaborCost}
              onKeyPress={(event) => handleKeyPress(event, record)}
            />
          ) : (
            <BodySmall>
              {!!record?.laborCost?.toString()
                ? `$${formatterNumberUSD(Number(record?.laborCost))}`
                : `${String.fromCharCode(8212)}${String.fromCharCode(8212)}`}
            </BodySmall>
          )}
        </>
      )
    },
    {
      title: 'Total',
      dataIndex: 'totalCost',
      width: '15%',
      sorter: isEditingOrAdding ? () => {} : (a, b) => compareCost(a, b, 'totalCost'),
      sortOrder: sortedInfo?.field === 'totalCost' && sortedInfo?.order,
      render: (_text, record, _index) => (
        <>
          {record.editable ? (
            <Input
              placeholder="$0"
              className={!hasApproverRole && isDirtyTotalCost && !totalCost ? 'input-error' : ''}
              disabled={isDisableTotalCost}
              value={totalCost}
              onChange={onChangeTotalCost}
              onKeyPress={(event) => handleKeyPress(event, record)}
            />
          ) : (
            <BodySmall>
              {!!record?.totalCost?.toString()
                ? `$${formatterNumberUSD(Number(record?.totalCost))}`
                : `${String.fromCharCode(8212)}${String.fromCharCode(8212)}`}
            </BodySmall>
          )}
        </>
      )
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'right',
      width: '15%',
      render: (_text, record) => {
        return record.editable ? (
          <>
            <Button className="cancel-btn" onClick={() => onCancel(record)}>
              <BodyXSmallBold>Cancel</BodyXSmallBold>
            </Button>
            <Button className="save-btn" disabled={!saveCondition} onClick={() => onSave(record)}>
              <BodyXSmallBold>Save</BodyXSmallBold>
            </Button>
          </>
        ) : (
          <Dropdown
            display="flex"
            disabled={isEditingOrAdding}
            placement={
              lineItemCatalog.findIndex((item) => item.id === record.id) === lineItemCatalog.length - 1
                ? 'topRight'
                : 'bottomRight'
            }
            overlay={
              <StyledMenu>
                <Menu.Item key="edit" onClick={() => onEdit(record)}>
                  <FontAwesomeIcon icon={faEdit} />
                  <BodyBaseBoldShortCustom>Edit</BodyBaseBoldShortCustom>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => onDelete(record)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <BodyBaseBoldShortCustom>Delete</BodyBaseBoldShortCustom>
                </Menu.Item>
              </StyledMenu>
            }
          >
            <StyledMoreIcon icon={faEllipsisH} data-disabled={isEditingOrAdding} />
          </Dropdown>
        );
      }
    }
  ];

  const columns = columnsDef.map((col) => {
    return {
      ...col
    };
  });

  const onDelete = (lineItem) => {
    const confirmText = `Are you sure you want to delete "${lineItem.description.trim()}"?`;
    Modal.confirm({
      title: confirmText,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(lineItemCatalogActions.deleteLineItemCatalog(lineItem, lineItemCatalog));
      },
      onCancel() {
        devLogger.log(`Canceled Delete ${lineItem.description}`);
      }
    });
  };

  const onEdit = (lineItem) => {
    lineItem.editable = true;
    dispatch(lineItemCatalogActions.updateLocalLineItemCatalog(lineItem));
    dispatch(lineItemCatalogActions.setIsEditingOrAdding(true));
    setDescription(lineItem.description);
    setLaborCost(lineItem.laborCost || lineItem.laborCost === 0 ? lineItem.laborCost : '');
    setPartsCost(lineItem.partsCost || lineItem.partsCost === 0 ? lineItem.partsCost : '');
    setTotalCost(lineItem.totalCost || lineItem.totalCost === 0 ? lineItem.totalCost : '');
    if (
      !lineItem.laborCost &&
      lineItem.laborCost !== 0 &&
      !lineItem.partsCost &&
      lineItem.partsCost !== 0 &&
      (lineItem.totalCost || lineItem.totalCost === 0)
    ) {
      setIsDisableCost(true);
    }
    if (lineItem.laborCost || lineItem.laborCost === 0 || lineItem.partsCost || lineItem.partsCost === 0) {
      setIsDisableTotalCost(true);
    }
  };

  const clearLineItemInput = () => {
    setDescription('');
    setLaborCost('');
    setPartsCost('');
    setTotalCost('');
    setIsDisableTotalCost(false);
    setIsDisableCost(false);
    setIsDirtyDescription(false);
    setIsDirtyTotalCost(false);
    setIsDescriptionDuplicated(false);
    setShowDuplicationError(false);
  };

  const checkDuplicatedDescription = (lineItemId) =>
    lineItemCatalog
      .filter((item) => item.id !== lineItemId)
      .map((item) => item.description.trim().toLowerCase())
      .includes(description.trim().toLowerCase());

  const onSave = (lineItem) => {
    if (checkDuplicatedDescription(lineItem.id)) {
      setIsDescriptionDuplicated(true);
      setShowDuplicationError(true);
      return;
    }
    setShowDuplicationError(false);
    if (!lineItem.isAdding) {
      dispatch(
        lineItemCatalogActions.updateLineItemCatalog(
          {
            ...lineItem,
            editable: false,
            description: description.trim(),
            laborCost,
            partsCost,
            totalCost
          },
          lineItemCatalog,
          sortedInfo,
          clearLineItemInput
        )
      );
    } else {
      dispatch(
        lineItemCatalogActions.addNewLineItemCatalog(
          {
            ...lineItem,
            editable: false,
            description: description.trim(),
            laborCost,
            partsCost,
            totalCost
          },
          [...lineItemCatalog],
          sortedInfo,
          clearLineItemInput
        )
      );
    }
    dispatch(lineItemCatalogActions.setIsEditingOrAdding(false));
  };

  const onCancel = (lineItem) => {
    if (lineItem.isAdding) {
      dispatch(lineItemCatalogActions.deleteLocalLineItemCatalog(lineItem));
    } else {
      lineItem.editable = false;
      dispatch(lineItemCatalogActions.updateLocalLineItemCatalog(lineItem));
    }
    dispatch(lineItemCatalogActions.setIsEditingOrAdding(false));
    clearLineItemInput();
  };
  const dataSource = searchLineItemCatalog(lineItemCatalog, searchString);

  return fetchLineItemCatalogStatus === apiStatusConstants.SUCCEEDED ? (
    <>
      {showDuplicationError && (
        <AlertWrapper>
          <AlertIRecon
            message={`"${description.trim()}" already exists. Please enter a new title.`}
            closeAlert={() => setShowDuplicationError(false)}
          />
        </AlertWrapper>
      )}
      <StyledTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record?.id}
        pagination={false}
        bordered={false}
        align="right"
        onChange={handleChange}
        isEditing={isEditingOrAdding}
        showSorterTooltip={false}
        scroll={
          dataSource.length !== 0
            ? {
                y: `calc(100vh - ${
                  isBridgeUser // calculate height of table for bridge users
                    ? isShowBanner // check for warning banner visibility
                      ? 490
                      : 414 // the 76px subtracted from 490px is the banner height
                    : // calculate height of table for non-bridge users, check for editing max approval time UI
                    isShowBanner // check for warning banner visibility
                    ? 454
                    : 378 // the 76px subtracted from 454px is the banner height
                }px${hasGlobalAlert ? ' - 42px' : ''})`,
                x: 'max-content'
              }
            : null
        }
      />
    </>
  ) : (
    <SkeletonContainer>
      {Array.from(Array(13)).map((_, i) => (
        <SkeletonRowContainer key={uuidv4()}>
          <Skeleton width="100%" height="32px" />
        </SkeletonRowContainer>
      ))}
    </SkeletonContainer>
  );
};

const StyledTable = styled(Table).attrs({
  className: 'smallBorder',
  locale: { emptyText: 'No results found' }
})`
  .ant-table-thead {
    & > tr > th {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.tableHeaderFontColor};
      cursor: ${(props) => (props.isEditing ? 'not-allowed !important' : 'pointer')};
      .ant-table-column-title {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.navy};
        font-size: ${({ theme }) => theme.fontSizes.md};
        line-height: ${({ theme }) => theme.lineHeights.ss};
        margin-top: 3px;
      }
    }
  }

  .ant-table-column-sorters {
    display: flex;
    flex: auto;
    align-items: center;
    justify-contents: space-between;
  }

  .ant-table-column-title {
    position: relative;
    z-index: 1;
    flex: 0;
  }

  .ant-table-column-sorter-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${({ theme }) => theme.borderColors.blue};
  }

  .ant-table-tbody > tr > td {
    padding: 4px 16px;
  }

  .ant-table-tbody {
    tr {
      line-height: 3;
    }
    tr td {
      position: relative;
    }
    tr > td:nth-child(1) {
      max-width: 400px;
    }
    > tr.ant-table-row-level-0.ant-table-row,
    > tr.ant-table-row-level-1.ant-table-row {
      &,
      &:hover > td {
        background: ${({ theme }) => theme.colors.white};
      }
      a {
        &,
        &:active,
        &:focus,
        &:hover {
          color: ${({ theme }) => theme.colors.navy};
        }
      }
    }
    > tr.ant-table-row-level-1.ant-table-row,
    > tr.ant-table-row-level-1.ant-table-row td {
      border-bottom: none;
    }
    > tr.ant-table-row-level-1.ant-table-row td:nth-child(1) {
      padding-left: 40px;
    }
    > tr.ant-table-row-level-1.lastUserRow.ant-table-row,
    > tr.ant-table-row-level-1.lastUserRow.ant-table-row td {
      border-bottom: ${({ theme }) => theme.borders.tableRowBorder};
    }

    .input-error {
      border-color: #eb002e !important;
    }
  }

  .ant-table-placeholder {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
    line-height: 17px;
  }

  .cancel-btn {
    margin-right: 22px;
  }

  .save-btn:disabled {
    background: transparent;
    opacity: 0.5;
  }

  .cancel-btn,
  .save-btn {
    padding: 0;
    border: none;
    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 160px;
    padding: 0;

    li.ant-dropdown-menu-item:first-child {
      padding: 16px 24px 11px 24px;
      border-bottom: ${({ theme }) => theme.borders.grayLineItemDropdownBorder};
    }

    li.ant-dropdown-menu-item:last-child {
      padding: 11px 24px 16px 24px;
    }
  }
`;

const BodyBaseBoldShortCustom = styled(BodyBaseBoldShort)`
  font-weight: 400;
  display: inline-block;
  margin-left: 15px;
`;

const StyledMoreIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props['data-disabled'] ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.colors.gray};
`;

const SkeletonContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
`;
const SkeletonRowContainer = styled.div`
  margin-bottom: 8px;
`;

const AlertWrapper = styled.div`
  position: absolute;
  width: 477px;
  top: 10%;
  left: calc((100% - 477px) / 2);
  z-index: 11;
`;

export default withLDConsumer()(TableCatalog);
