import { v4 as uuidv4 } from 'uuid';
import { createSelector } from 'reselect';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { apiStatusConstants } from 'app-constants';
import { CURRENT_DEALER } from 'store/dealersStore';
import { messagesActions } from 'store/messagesStore';
import { createRequestTypes, makeActionCreator } from 'utils';
import { deleteWithToken, getWithToken, postWithToken, putWithToken } from 'api';

//#region Actions
export const INTERNAL_GROUP_TYPES = createRequestTypes('INTERNAL_GROUP_TYPES', [
  'GET_DATA',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'ADD',
  'UPDATE',
  'DELETE',
  'GET_INTERNAL_GROUP',
  'SET_INTERNAL_GROUP'
]);
export const internalGroupActions = {
  getData: makeActionCreator(INTERNAL_GROUP_TYPES.GET_DATA, 'dealerId'),
  setData: makeActionCreator(INTERNAL_GROUP_TYPES.SET_DATA, 'internalGroups'),
  setFetchStatus: makeActionCreator(INTERNAL_GROUP_TYPES.SET_FETCH_STATUS, 'fetchStatus'),
  add: makeActionCreator(INTERNAL_GROUP_TYPES.ADD, 'internalGroup', 'loadingMessage', 'successMessage', 'errorMessage'),
  update: makeActionCreator(INTERNAL_GROUP_TYPES.UPDATE, 'internalGroup', 'loadingMessage', 'successMessage', 'errorMessage'),
  delete: makeActionCreator(INTERNAL_GROUP_TYPES.DELETE, 'internalGroup', 'loadingMessage', 'successMessage', 'errorMessage'),
  getInternalGroup: makeActionCreator(INTERNAL_GROUP_TYPES.GET_INTERNAL_GROUP, 'internalGroupId'),
  setInternalGroup: makeActionCreator(INTERNAL_GROUP_TYPES.SET_INTERNAL_GROUP, 'internalGroup')
};
//#endregion

//#region Reducer
const initialState = {
  data: [],
  internalGroup: null,
  fetchStatus: apiStatusConstants.IS_FETCHING,
};

export const internalGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTERNAL_GROUP_TYPES.SET_DATA:
      // Mock sequence, remove when integrate
      action.internalGroups.forEach((item, i) => {
        item.sequence = i + 1;
      });
      return {
        ...state,
        data: action.internalGroups
      };
    case INTERNAL_GROUP_TYPES.SET_INTERNAL_GROUP:
      return {
        ...state,
        internalGroup: action.internalGroup
      };
    case INTERNAL_GROUP_TYPES.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const internalGroupSelector = createSelector(
  (state) => state.internalGroups,
  (internalGroupsStore) => internalGroupsStore.data
);
//#endregion

//#region Sagas
export function* getInternalGroupSaga() {
  yield takeLatest(INTERNAL_GROUP_TYPES.GET_INTERNAL_GROUP, function* ({ internalGroupId }) {
    try {
      const currentInternalGroup = yield select(internalGroupSelector);
      const internalGroup = yield getWithToken(`/api/InternalGroups/id/${internalGroupId}`);
      const internalGroupIndex = currentInternalGroup.findIndex(ig => ig.id === internalGroupId);
      internalGroup[internalGroupIndex] = internalGroup;
      yield put(internalGroupActions.setInternalGroup(internalGroup));
    } catch (error) {
      // Show message when failed
      yield put(messagesActions.notify('error', 'Failed to get internal group information', { duration: 3.5 }));
      devLogger.log('error in getInternalGroupSaga', error);
    }
  });
}

export function* getInternalGroupsSaga() {
  yield takeLatest(INTERNAL_GROUP_TYPES.GET_DATA, function* ({ dealerId }) {
    try {
      yield put(internalGroupActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const internalGroups = yield getWithToken(`/api/InternalGroups/dealer/${dealerId}`);
      yield put(internalGroupActions.setData(internalGroups));
      yield put(internalGroupActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(internalGroupActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getInternalGroupsSaga', error);
    }
  });
}

export function* updateInternalGroupsSaga() {
  yield takeEvery(
    [INTERNAL_GROUP_TYPES.ADD, INTERNAL_GROUP_TYPES.UPDATE, INTERNAL_GROUP_TYPES.DELETE],
    function* ({ type: action, internalGroup, loadingMessage, successMessage, errorMessage }) {
      const loadingMessageId = uuidv4();
      try {
         // duration of 0 will show the message indefinitely
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0 }));
        const currentInternalGroup = yield select(internalGroupSelector);
        let newInternalGroup;
        switch (action) {
          case INTERNAL_GROUP_TYPES.ADD:
            yield postWithToken('/api/InternalGroups', internalGroup);
            // refetch list for new order
            yield put(internalGroupActions.getData(internalGroup.dealerId));
            break;
          case INTERNAL_GROUP_TYPES.UPDATE:
            yield putWithToken(`/api/InternalGroups/id/${internalGroup.id}`, internalGroup);
            // refetch list for new order
            yield put(internalGroupActions.getData(internalGroup.dealerId));
            break;
          case INTERNAL_GROUP_TYPES.DELETE:
            yield deleteWithToken(`/api/InternalGroups/id/${internalGroup.id}`);
            newInternalGroup = [...currentInternalGroup.filter((tt) => tt.id !== internalGroup.id)];
            yield put(internalGroupActions.setData(newInternalGroup));
            break;
          default:
            devLogger.log(`Unrecognized action for updateVendorsSaga: ${action}`);
            break;
        }

        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // passing in the same key and duration will make the message go away after that duration time
        yield put(messagesActions.notify('success', successMessage, { duration: 2 }));
      } catch (error) {
        // refetch data
        if (action === INTERNAL_GROUP_TYPES.DELETE) {
          yield put(internalGroupActions.getData(internalGroup.dealerId));
        } else if (action === INTERNAL_GROUP_TYPES.UPDATE) {
          yield put(internalGroupActions.getInternalGroup(internalGroup.id));
        }
        devLogger.log('failed updateInternalGroupsSaga', error);
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 }));
        yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
      }
    }
  );
}

//#endregion