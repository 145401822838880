import React, { memo } from 'react';
import { useDragLayer } from 'react-dnd';
import styled from 'styled-components';

import DraggableItem from './DraggableItem';
import { draggableItemType } from './helpers/propTypes';

/**
 * PreviewDraggableItem
 * component that renders the dragged
 */
const PreviewDraggableItem = memo(({ item }) => {
  return <DraggableItem key={item.id} {...item} isRenderedForDragging />;
});

PreviewDraggableItem.propTypes = {
  item: draggableItemType
};

const getPreviewContainerStyle = (initialOffset, currentOffset) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
};

/**
 * We don't want to accept the browser default image for dragging and dropping.
 * It has a bunch of CSS applied which makes it transparent and other styles that are not desired
 * There are usually a lot of issues in trying to control the dragging image (what you see as the component when dragging the component)
 * https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
 * https://kryogenix.org/code/browser/custom-drag-image.html
 *
 * CustomDragLayer allows us to create our own drag image that will track with the mouse during a drag
 * initialOffset and currentOffset are provided from useDragLayer because we have requested it as collectorProps
 * Helpful documentation:
 * - https://github.com/react-dnd/react-dnd/blob/64c4060f00c0dd9858a4911086245f1887b5b6f8/packages/core/react-dnd/src/hooks/useDragLayer.ts
 * - https://react-dnd.github.io/react-dnd/docs/api/use-drag-layer
 * - https://react-dnd.github.io/react-dnd/docs/api/drag-layer-monitor
 * - https://github.com/react-dnd/react-dnd/blob/64c4060f00c0dd9858a4911086245f1887b5b6f8/packages/core/react-dnd/src/common/DragSourceMonitorImpl.ts
 * - code for monitors with good comments: https://github.com/react-dnd/react-dnd/blob/main/packages/core/dnd-core/src/interfaces.ts
 */
const CustomDragLayer = () => {
  const { item, initialOffset, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));
  if (!isDragging) {
    //if no dragging is occurring, we do not need to create the preview or drag layer overlay
    return null;
  }
  return (
    <Overlay>
      <div style={getPreviewContainerStyle(initialOffset, currentOffset)}>
        <PreviewDraggableItem item={item} />
      </div>
    </Overlay>
  );
};

CustomDragLayer.propTypes = {};

/**
 * Styled component used for creating an overlay over the entire screen in which the drag preview can be dragged
 * Does not mean that you can drop it anywhere, still needs to be in a droppable area
 * See CustomDragLayer for implementation
 */
const Overlay = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export default CustomDragLayer;
