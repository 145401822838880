import { takeLatest, put, select } from 'redux-saga/effects';
import { authSelector } from 'store/authStore';
import { getWithToken, postWithToken } from 'api';
import { createSelector } from 'reselect';
import { createRequestTypes, makeActionCreator } from 'utils';
import { CURRENT_DEALER } from 'store/dealersStore';
import {
  features,
  apiStatusConstants,
  taggingTypes,
  assigneeTypes,
  IS_NEW_SIGNIN,
  VENDOR_USER_INFO
} from 'app-constants';
import { messagesActions } from 'store/messagesStore';
import { rootEntitySwitcherSelector } from './dealersStore';
import { vendorActions, vendorUsersSelector } from './vendorStore';

//#region Actions
export const USERS = createRequestTypes('USERS', [
  'GET_DATA',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'GET_CURRENT_USER_INFO',
  'GET_TAGGABLE_USERS_VEHICLE_NOTE',
  'GET_TAGGABLE_USERS_TASK_MESSAGE',
  'SET_CURRENT_USER_INFO',
  'SET_TAGGABLE_USERS_VEHICLE_NOTE',
  'SET_TAGGABLE_USERS_TASK_MESSAGE',
  'SET_TAGGABLE_USERS_FETCH_STATUS',
  'GET_ASSIGNEE_DATA',
  'SET_ASSIGNEE_DATA',
  'RESEND_VENDOR_USER_INVITE'
]);
export const usersActions = {
  getData: makeActionCreator(USERS.GET_DATA, 'dealerId', 'isUpdateFetchStatus', 'isRootReFetch'), //no reducer - triggers getUsersSaga
  setData: makeActionCreator(USERS.SET_DATA, 'users'),
  setFetchStatus: makeActionCreator(USERS.SET_FETCH_STATUS, 'fetchStatus'),
  getCurrentUserInfo: makeActionCreator(USERS.GET_CURRENT_USER_INFO), //no reducer - triggers getUserByIdSaga
  setCurrentUserInfo: makeActionCreator(USERS.SET_CURRENT_USER_INFO, 'info'),
  getTaggableUsersVehicleNote: makeActionCreator(USERS.GET_TAGGABLE_USERS_VEHICLE_NOTE, 'dealerId'),
  setTaggableUsersVehicleNote: makeActionCreator(USERS.SET_TAGGABLE_USERS_VEHICLE_NOTE, 'users'),
  getTaggableUsersTaskMessage: makeActionCreator(USERS.GET_TAGGABLE_USERS_TASK_MESSAGE, 'dealerId', 'taskId'),
  setTaggableUsersTaskMessage: makeActionCreator(USERS.SET_TAGGABLE_USERS_TASK_MESSAGE, 'users'),
  setTaggableUsersFetchStatus: makeActionCreator(USERS.SET_TAGGABLE_USERS_FETCH_STATUS, 'taggableUsersFetchStatus'),
  getAssigneeData: makeActionCreator(USERS.GET_ASSIGNEE_DATA, 'dealerId', 'isUpdateFetchStatus', 'isRootReFetch'),
  setAssigneeData: makeActionCreator(USERS.SET_ASSIGNEE_DATA, 'assigneeData'),
  resendVendorUserInvite: makeActionCreator(USERS.RESEND_VENDOR_USER_INVITE, 'userId', 'vendorId', 'message')
};
//#endregion

//#region Reducer
const initialState = {
  data: [],
  fetchStatus: null,
  currentUserInfo: null,
  assigneeData: {
    internalUsers: [],
    vendors: [],
    internalGroups: []
  },
  taggableUsersFetchStatus: null
};
export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS.SET_DATA:
      return {
        ...state,
        data: action.users
      };
    case USERS.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case USERS.SET_CURRENT_USER_INFO:
      return {
        ...state,
        currentUserInfo: { ...action.info }
      };
    case USERS.SET_TAGGABLE_USERS_VEHICLE_NOTE:
      const vehicleTaggableUsers = action.users.map((users) => {
        return { id: users.id, name: users.fullName };
      });
      return {
        ...state,
        taggableUsersVehicleNote: vehicleTaggableUsers
      };
    case USERS.SET_TAGGABLE_USERS_TASK_MESSAGE:
      const taskTaggableUsers = action.users.map((users) => {
        return { id: users.id, name: users.fullName };
      });
      return {
        ...state,
        taggableUsersTaskMessage: taskTaggableUsers
      };
    case USERS.SET_TAGGABLE_USERS_FETCH_STATUS:
      return {
        ...state,
        taggableUsersFetchStatus: action.taggableUsersFetchStatus
      };
    case USERS.SET_ASSIGNEE_DATA:
      return {
        ...state,
        assigneeData: action.assigneeData
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const usersSelector = createSelector(
  (state) => state.users,
  (users) => users.data
);

export const assigneeDataSelector = createSelector(
  (state) => state.users,
  (usersContainer) => ({
    internalGroups: usersContainer.assigneeData?.internalGroups,
    vendors: usersContainer.assigneeData?.vendors,
    vendorUsers: usersContainer.assigneeData?.vendors?.length
      ? usersContainer.assigneeData?.vendors[0]?.vendorUsers
      : []
  })
);
//#endregion

//#region Sagas
export function* ResendVendorUserInviteSaga() {
  yield takeLatest([USERS.RESEND_VENDOR_USER_INVITE], function* ({ userId, vendorId, message }) {
    try {
      yield put(vendorActions.setPutStatus(apiStatusConstants.PENDING));
      yield postWithToken(`/api/Vendors/resend-invite`, {
        sourceId: vendorId,
        sourceType: 'VENDOR',
        destinationId: userId,
        destinationType: 'VENDOR_USER',
        message: message
      });
      const vendorUserData = yield getWithToken(`/api/Vendors/id/${vendorId}/users/${userId}`);
      yield put(usersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));

      // re-fetch updated user after resend
      const vendorUsers = yield select(vendorUsersSelector);
      // just updating the updated user in the current list since it might have been filtered before
      const newVendorUsers = vendorUsers.map((user) => (user.id === userId ? vendorUserData : user));
      yield put(vendorActions.setVendorUsers(newVendorUsers));
      yield put(vendorActions.setPutStatus(apiStatusConstants.SUCCEEDED));
      const successMessage = `${vendorUserData.firstName} ${vendorUserData.lastName} was invited to be one of your Users`;
      yield put(messagesActions.notify('success', successMessage, { duration: 2, isVendorToastMessage: true }));
    } catch (error) {
      yield put(usersActions.setFetchStatus(apiStatusConstants.FAILED));
      yield put(vendorActions.setPutStatus(apiStatusConstants.FAILED));
      yield put(
        messagesActions.notify('error', 'An error occurred while inviting the vendor user', {
          duration: 3.5,
          isVendorToastMessage: true
        })
      );
      devLogger.log('error in ResendVendorUserInvite', error);
    }
  });
}
export function* getUsersSaga() {
  yield takeLatest([USERS.GET_DATA], function* ({ dealerId, isUpdateFetchStatus = true, isRootReFetch = false }) {
    const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
    const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
    const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
    const isVendorAdmin = vendorInfo.role?.id === 1 && isNewSignin;
    const isVendorTech = vendorInfo.role?.id === 2 && isNewSignin;
    const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);

    if (isVendorAdmin || isVendorTech || !dealerId || (isRootUser && vendorShipSelected && !isRootReFetch)) {
      yield put(usersActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      yield put(usersActions.setData([]));
      yield put(usersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      return;
    }
    try {
      // Condition to avoid re-render on VLP
      if (isUpdateFetchStatus) {
        yield put(usersActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      }

      //TODO: We may need to rework this whole store a bit if we ever have a case of needing more types of users than just task workers
      const users = yield getWithToken(`/api/Users`, {
        dealerId: dealerId,
        hasFeature: features.TASKS_WORK_OWN
      });
      yield put(usersActions.setData(users));

      if (isUpdateFetchStatus) {
        yield put(usersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      }
    } catch (error) {
      // TODO: handle error
      yield put(usersActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getUsersSaga', error);
    }
  });
}

export function* getAssigneeSaga() {
  yield takeLatest(
    [USERS.GET_ASSIGNEE_DATA],
    function* ({ dealerId, isUpdateFetchStatus = true, isRootReFetch = false }) {
      try {
        const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
        const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
        const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
        const isVendorAdmin = vendorInfo.role?.id === 1 && isNewSignin;
        const isVendorTech = vendorInfo.role?.id === 2 && isNewSignin;

        const { vendorShipSelected, isRootUser, currentId } = yield select(rootEntitySwitcherSelector);
        if (!dealerId) {
          return;
        }
        if (isVendorTech) {
          return;
        }
        if (isUpdateFetchStatus) {
          yield put(usersActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
        }

        // This Api response contains list internal user equal with the list user get from API /api/Users with features.TASKS_WORK_OWN
        // If we just need list user with features.TASKS_WORK_OWN, we should replace the getData with getAssigneeData for all current call
        const assignee = yield getWithToken(`/api/Users/assignee?includeUnassignedWorkload=true`, {
          dealerId:
            isVendorAdmin || (isRootUser && vendorShipSelected && dealerId === currentId) ? undefined : dealerId,
          vendorId:
            isVendorAdmin || (isRootUser && vendorShipSelected)
              ? isRootUser && vendorShipSelected
                ? currentId
                : vendorInfo?.vendorId
              : undefined,
          include: [assigneeTypes.INTERNAL_USERS, assigneeTypes.INTERNAL_GROUPS, assigneeTypes.VENDORS]
        });
        yield put(usersActions.setAssigneeData(assignee));

        if (isUpdateFetchStatus) {
          yield put(usersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
        }
      } catch (error) {
        yield put(usersActions.setFetchStatus(apiStatusConstants.FAILED));
        //set default value for recon's dealership filters
        yield put(
          usersActions.setAssigneeData({ internalUsers: [], vendors: [], internalGroups: [], dealerships: [] })
        );
        devLogger.log('error in getAssigneeSaga', error);
      }
    }
  );
}

export function* getTaggableUsersVehicleNoteSaga() {
  yield takeLatest([USERS.GET_TAGGABLE_USERS_VEHICLE_NOTE], function* ({ dealerId }) {
    try {
      yield put(usersActions.setTaggableUsersFetchStatus(apiStatusConstants.IS_FETCHING));
      const users = yield getWithToken(`/api/Users/TaggableUsers`, {
        dealerId,
        taggingType: taggingTypes.VEHICLE_NOTE
      });
      yield put(usersActions.setTaggableUsersVehicleNote(users));
      yield put(usersActions.setTaggableUsersFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(usersActions.setTaggableUsersFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTaggableUsersSaga', error);
    }
  });
}

export function* getTaggableUsersTaskMessageSaga() {
  yield takeLatest([USERS.GET_TAGGABLE_USERS_TASK_MESSAGE], function* ({ dealerId, taskId }) {
    try {
      const users = yield getWithToken(`/api/Users/TaggableUsers`, {
        dealerId,
        taggingType: taggingTypes.TASK_MESSAGE,
        taskId
      });
      yield put(usersActions.setTaggableUsersTaskMessage(users));
    } catch (error) {
      // TODO: handle error
      yield put(usersActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTaggableUsersSaga', error);
    }
  });
}

export function* getCurrentUserInfoSaga() {
  yield takeLatest([USERS.GET_CURRENT_USER_INFO], function* () {
    try {
      const { user } = yield select(authSelector);
      if (user) {
        yield put(usersActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
        const currentUserInfo = yield getWithToken(`/api/Users/id/${user.id}`);
        yield put(usersActions.setCurrentUserInfo(currentUserInfo));
        yield put(usersActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      }
    } catch (error) {
      // TODO: handle error
      yield put(usersActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getUsersSaga', error);
    }
  });
}
//#endregion
