import { useEffect, useRef } from 'react';

export const useDebouncedEffect = (callback, delay, deps = [], shouldDebounce = true) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(
      () => {
        callback();
      },
      shouldDebounce ? delay : 0
    );

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};
