import React, { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

const ProfitTimeMetal = ({ width = '44px', height = '44px', profitTimeScore = {}, isLoading = false }) => {
  const ref = useRef();

  useDeepCompareEffect(() => {
    if (ref.current) {
      ref.current.isLoading = isLoading;
      if (profitTimeScore && !!Object.keys(profitTimeScore).length) {
        ref.current.profitTimeScore = {
          ...profitTimeScore,
          score: profitTimeScore.score ?? 'N',
          metal: profitTimeScore.scoreBadge,
          scoreReasons: mapScoreReasons(profitTimeScore.scoreFactors)
        };
      }
    }
  }, [profitTimeScore, isLoading]);

  const mapScoreReasons = (scoreReasons) => {
    //Rahul's code: map factory type from string to integer values + refactor properties from camelCased to pascalCased
    const mapFactorType = (factorType) => {
      switch (factorType) {
        case 'Invalid':
          return 1;
        case 'Positive':
          return 2;
        case 'Neutral':
          return 3;
        case 'Negative':
          return 4;
        case 'Notes':
          return 5;
        default:
          return 1;
      }
    };

    return (scoreReasons || []).map((reason) => {
      return {
        FactorType: mapFactorType(reason.factorType),
        Code: reason.code,
        CodeIdentifier: reason.codeIdentifier,
        Message: reason.message
      };
    });
  };

  return (
    Object.keys(profitTimeScore).length > 0 && (
      <triforce-widget
        ref={ref}
        style={{ width, height, display: 'inline-block' }}
        show-labels={false}
        popover-positioning-strategy={'absolute'}
        has-stocking={false}
      />
    )
  );
};

export default React.memo(ProfitTimeMetal);
