import styled from 'styled-components';
import { Card, Form, Checkbox } from 'antd';
import { CommonRedButton } from 'components/styledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledSettingsCard = styled(Card)`
  .ant-card& {
    line-height: 20px;
    height: 100%;
    max-height: 135px;
    .ant-card-body {
      display: flex;
      padding: 15px 18px 15px 16px;
    }
    .ant-card-loading-content {
      width: 100%;
    }
  }
`;
export const StyledHiddenSettingsCard = styled(StyledSettingsCard)`
  .ant-card& {
    flex: 1;
    min-width: 340px;
    margin: 0 4px 8px 4px;
    visibility: hidden;
    height: auto;
  }
`;
export const StyledCircleIcon = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.navy};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-top: 10px;
`;
export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.md};
  display: flex;
  align-self: center;
`;
export const StyledMoreIcon = styled(FontAwesomeIcon)`
  height: 20px;
  cursor: pointer;
  color: ${(props) => (props['data-selected'] ? 'inherit' : props.theme.colors.darkGray)};
`;
export const StyledHeader = styled.div`
  display: flex;
`;
export const StyledContent = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
export const StyledPage = styled.div`
  background-color: #f2f4f5;
  overflow: auto;
  padding-right: 24px;
`;

export const StyledPageLineItemCatalog = styled.div`
  background-color: #f2f4f5;
  padding-right: 24px;
`;

export const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 8px;
  margin-bottom: 20px;
`;
export const StyledForm = styled(Form).attrs({
  layout: 'vertical'
})`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const StyledFormItem = styled(Form.Item)`
  .ant-form-item& {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    .ant-form-item-label {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      line-height: ${({ theme }) => theme.lineHeights.ss};
      margin-bottom: 6px;
    }

    .ant-form-item-control {
      &.has-error .ant-input:focus {
        border-color: ${({ theme }) => theme.borderColors.error} !important;
      }
    }
  }
`;

export const CustomPositionedCommonRedButton = styled(CommonRedButton)`
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
`;

export const NewTaskButtonStyled = styled(CommonRedButton)`
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
`;
export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.titanium};
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.infoPrimary};
  }
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.borderColors.gray};
  }
  .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.borderColors.blue};
  }
`;
