import React from 'react';
import styled from 'styled-components';
import { Divider, Typography } from 'antd';
import { dispositionTypes, formatOdometer } from 'app-constants';
import { CopyOnClickText } from 'components';

const { Text } = Typography;

const VehicleInfo = React.memo(({ vehicle, showDealerName = false, isForTLP = false }) => {
  return (
    <StyledVehicleInfo>
      <CopyOnClickText text={vehicle.stockNumber} />
      {vehicle.vin && (
        <>
          <Divider type="vertical" />
          <CopyOnClickText text={vehicle.vin} />
        </>
      )}
      {vehicle.odometer != null && (
        <>
          <Divider type="vertical" />
          {formatOdometer(vehicle)}
        </>
      )}
      {vehicle.baseColor && (
        <>
          <Divider type="vertical" />
          {vehicle.baseColor}
        </>
      )}
      {vehicle.age != null && (
        <>
          <Divider type="vertical" />
          {`Age: ${vehicle.age < 1 ? '<1' : vehicle.age} days`}
        </>
      )}
      {!isForTLP && vehicle.disposition && vehicle.disposition !== 'RETAIL' && (
        <>
          <Divider type="vertical" />
          {dispositionTypes[vehicle.disposition]}
        </>
      )}
      {showDealerName && !!vehicle.dealerName && <div>{vehicle.dealerName}</div>}
    </StyledVehicleInfo>
  );
});

//#region Styled Components
const StyledVehicleInfo = styled(Text).attrs({
  ellipsis: true
})`
  margin: 0px 15px 0px 15px;
`;
//#endregion

export default VehicleInfo;
