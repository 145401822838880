// An example of what this utility does:
// export const FOO = createRequestTypes('FOO', ['BAR', 'BAZ']);
// would essentially creat this:
// const FOO = {
//   BAR: 'FOO_BAR',
//   BAZ: 'FOO_BAZ',
// }

export default (base, types) => {
	return types.reduce((acc, type) => {
		acc[type] = `${base}_${type}`
		return acc
	}, {})
}