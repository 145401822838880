import { faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonLinkButton } from 'components/styledComponents';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { vendorDealerActions } from 'store/vendorStore';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';
import FormModal from '../../../modals/FormModal';

const VendorCard = ({ vendor, isSaving, dealerId }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isSaving) {
      setIsModalOpen(false);
    }
  }, [isSaving]);

  const handleSave = (values) => {
    dispatch(
      vendorDealerActions.addToDealerShip(
        vendor.id,
        dealerId,
        {
          vendorId: vendor.id,
          dealerId: dealerId,
          status: 'PENDING',
          message: values.message ? values.message : '',
          dealerNote: ''
        },
        `Add vendor request has been sent to ${vendor.name}`,
        'An error occurred while adding the vendor'
      )
    );
  };

  return (
    <VendorCardWrapper>
      <StyledFontAwesomeIcon />
      <VendorInfoWrapper>
        <VendorName>{vendor.name}</VendorName>
        <VendorInfo>{vendor.address}</VendorInfo>
        <VendorInfo>
          {vendor.city} {vendor.state} {vendor.postalCode}
        </VendorInfo>
        <VendorInfo>
          {vendor.contactFirstName} {vendor.contactLastName}
        </VendorInfo>
        <CommonLinkButton onClick={() => setIsModalOpen(true)} style={{ height: '20px' }}>
          + Add Vendor
        </CommonLinkButton>
      </VendorInfoWrapper>
      {/* Hide overflow for now. Will use in future */}
      {/* <OverflowMenuWrapper>
        <OverflowMenu></OverflowMenu>
      </OverflowMenuWrapper> */}
      <FormModal
        visible={isModalOpen}
        isSaving={isSaving}
        handleSave={handleSave}
        closeModal={() => setIsModalOpen(false)}
        title={'Add Vendor'}
        icon={faQuestionCircle}
        buttonText={isSaving ? 'Adding' : 'Add Vendor'}
        message={{
          label: 'Message to Vendor',
          placeholder: 'Include a message to the vendor',
          maxLength: 120
        }}
      >
        Are you sure you want to add <StyledVendorName>{vendor.name}</StyledVendorName> as a vendor?
      </FormModal>
    </VendorCardWrapper>
  );
};

// #region Styled Components
const VendorCardWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.vendorCardBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 8px;
  padding-right: 20px;
  display: flex;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).attrs({
  icon: faStoreAlt,
  color: themeStyles.colors.navy,
  style: {
    fontSize: themeStyles.fontSizes.md,
    margin: '21px 20px'
  }
})``;

const StyledVendorName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const VendorInfoWrapper = styled.div`
  margin: 16px 0;
  flex: 1;
`;

const VendorName = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

const VendorInfo = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
`;

// Hide overflow for now. Will use in future
// const OverflowMenuWrapper = styled.div`
//   width: 36px;
//   height: 36px;
//   margin: 8px;
//   position: relative;
// `;

// const OverflowMenu = styled(FontAwesomeIcon).attrs({
//   icon: faEllipsisH,
//   color: themeStyles.colors.navy,
//   style: {
//     fontSize: themeStyles.fontSizes.sm,
//     position: 'absolute',
//     top: '50%',
//     right: '50%',
//     transform: 'translate(50%, -50%)'
//   }
// })``;
//#endregion

export default VendorCard;
