import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Modal, Typography } from 'antd';
import { apiStatusConstants } from 'app-constants';
import arrayMove from 'array-move';
import { CommonHeader, CommonRedButton, CommonSearch } from 'components/styledComponents';
import { useWindowSize } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { createSelector } from 'reselect';
import { taskTypesActions } from 'store/taskTypesStore';
import { taskCategoriesActions } from 'store/taskCategoriesStore';
import { CustomizedAlert } from '../../../alerts';
import { usersActions } from 'store/usersStore';
import styled from 'styled-components';
import { updateObjectInArray } from 'utils/arrayUtils';

import { TaskTypeCard, TaskTypeDrawer } from '.';
import { NoRecords, NoRecordsPage } from '../../vendorAndGroups';
import { StyledHiddenSettingsCard, StyledList, StyledPage, StyledSettingsCard } from '../common/Styles.js';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../../google-analytics';
import uuidv4 from 'uuid/v4';

const { Text } = Typography;

const taskTypesPropsSelector = createSelector(
  (state) => state.dealers.current.data,
  (state) => state.taskTypes,
  (state) => state.taskCategories.categoriesAll,
  (currentDealer, taskTypesStore, taskCategoriesStore) => ({
    currentDealer,
    taskTypes: taskTypesStore.data,
    fetchStatus: taskTypesStore.fetchStatus,
    taskCategoriesDict: taskCategoriesStore.dict
  })
);
const TaskTypes = ({ flags }) => {
  const dispatch = useDispatch();
  const { currentDealer, taskTypes, fetchStatus, taskCategoriesDict } = useSelector(taskTypesPropsSelector);
  const { isBridgeUser } = useSelector((state) => state.auth);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [localTaskTypes, setLocalTaskTypes] = useState([]); // using a local copy so that we don't mutate the actual collection during reordering
  const [fetchStarted, setFetchStarted] = useState(null); //  used for capturing page load time for google analytics, considering the page as done loading when the taskTypesActions.getData call has completed
  const [width, height] = useWindowSize();
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [hasAlert, setHasAlert] = useState(false);

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.TASK_TEMPLATES_VIEWED);

  useEffect(() => {
    const clientHeight = document.getElementById('taskTypesContainer').clientHeight;
    const scrollHeight = document.getElementById('taskTypesContainer').scrollHeight;
    if (clientHeight < scrollHeight) {
      setHasScrollBar(true);
    } else {
      setHasScrollBar(false);
    }
  }, [width, height, fetchStatus]);

  useEffect(() => {
    if (flags?.reconTaskTemplateAlerts) {
      const { platforms, message, screenSection } = flags?.reconTaskTemplateAlerts;
      const path = window.location.pathname.split('/');
      const endPath = path[path.length - 1];

      const isShowAlert = screenSection === endPath;
      const isShowAlertInWeb = platforms?.includes('WEB');

      setHasAlert(isShowAlert && isShowAlertInWeb && message);
    }
  }, []);

  useEffect(() => {
    if (taskTypes) {
      setLocalTaskTypes([...taskTypes].filter((tt) => !tt.scheduledForDeletion));

      if (fetchStarted) {
        setFetchStarted(false);
      }
    }
  }, [taskTypes]);

  useEffect(() => {
    dispatch(taskCategoriesActions.getData());
  }, []);

  useEffect(() => {
    if (currentDealer && currentDealer.id) {
      setFetchStarted(true);
      dispatch(taskTypesActions.getData(currentDealer.id));
    }
  }, [currentDealer]);

  const onAdd = (e) => {
    e.preventDefault();
    dispatch(usersActions.getData(currentDealer.id)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
    setSelectedTaskType({});

    dispatch(usersActions.getAssigneeData(currentDealer.id));
  };

  const onEdit = (taskType) => {
    dispatch(usersActions.getData(currentDealer.id)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
    setSelectedTaskType(taskType);

    dispatch(usersActions.getAssigneeData(currentDealer.id));
  };

  const onDelete = (taskType) => {
    Modal.confirm({
      title: `Delete ${taskType.name} task type?`,
      content: `This task type will be unavailable when creating new tasks. Existing tasks will be unaffected.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        devLogger.log(`OK. Deleting ${taskType.name}`);
        dispatch(
          taskTypesActions.delete(
            taskType,
            'Deleting task template...',
            'Task template deleted!',
            'An error occurred while deleting the task template'
          )
        );
      },
      onCancel() {
        devLogger.log(`Canceled Delete ${taskType.name}`);
      }
    });
  };

  const isNameTaken = (name, taskTypeId) => {
    //TODO: it's possible that another user may have just added another plan with the same name,
    //      longer term, the same name check should probably happen on the api side
    if (taskTypeId) {
      return taskTypes.filter((t) => t.id !== taskTypeId).some((t) => t.name === name);
    } else {
      return taskTypes.some((t) => t.name === name);
    }
  };

  const onCloseDrawer = () => {
    setSelectedTaskType(null); // clear out to close drawer
  };

  const isTaskTypeFilteredBySearch = (taskType) => {
    const trimmedValue = valueSearch.toLowerCase().trim();
    if (trimmedValue) {
      const matchesNameOrCategory =
        taskType.name?.toLowerCase().includes(trimmedValue) ||
        taskCategoriesDict[taskType?.categoryId]?.toLowerCase().includes(trimmedValue);

      const assignee = taskType.assignees ? taskType.assignees[0] : null;
      const matchesAssignee =
        assignee?.userFullName?.toLowerCase().includes(trimmedValue) ||
        assignee?.assignedToGroupName?.toLowerCase().includes(trimmedValue) ||
        (!assignee?.userFullName && 'unassigned'.includes(trimmedValue));

      return matchesNameOrCategory || matchesAssignee;
    }

    return true;
  };

  //Todo: remove at a later date.
  const viewMiscellaneous = () => {
    setValueSearch('Miscellaneous');
  };

  return (
    <>
      {hasAlert && (
        <div style={{ marginBottom: '15px' }}>
          <CustomizedAlert
            message={
              <>
                <Text>{flags?.reconTaskTemplateAlerts?.message}</Text>
              </>
            }
            type="info"
            detail={
              <LinkButton className="label" style={{ fontWeight: 500 }} onClick={viewMiscellaneous}>
                Review All Miscellaneous
              </LinkButton>
            }
            visible
            closable={false}
            showIcon={true}
          />
        </div>
      )}
      <CommonHeader style={{ margin: `0 ${hasScrollBar ? 36 : 28}px 16px auto` }}>
        <CommonSearch
          custom-height={34}
          data-is-focused={searchBoxIsFocused}
          placeholder={searchBoxIsFocused ? 'Type to begin searching...' : 'Search'}
          prefix={<FontAwesomeIcon icon={faSearch} />}
          onFocus={() => setSearchBoxIsFocused(true)}
          onBlur={() => setSearchBoxIsFocused(false)}
          value={valueSearch}
          onChange={(e) => setValueSearch(e.target?.value)}
        />
        <CommonRedButton onClick={onAdd}>New Task</CommonRedButton>
      </CommonHeader>
      {/**TODO disabled for now until we implement the full list view */}
      {/* <Divider type="vertical" style={{ width: '2px', height: '32px', marginTop: '5px' }} />
      <StyledIcon icon={faImage} style={{ margin: '10px 10px 0px 10px', fontSize: '18px' }} />
      <StyledIcon icon={faListUl} style={{ margin: '10px 10px 0px 10px', fontSize: '18px' }} /> */}
      {/** The flags change the style of 2 wrapper component. So make sure if has any changed, change on both true/false conditions */}
      <StyledPage is-bridge-user={isBridgeUser ? 1 : 0} id={'taskTypesContainer'}>
        {
          {
            [apiStatusConstants.FAILED]: (
              <Text type="danger" ellipsis>
                Something went wrong retrieving Task Type information
              </Text>
            ),
            [apiStatusConstants.IS_FETCHING]: (
              <StyledList>
                <Loading />
              </StyledList>
            ),
            [apiStatusConstants.SUCCEEDED]: (
              <TaskTemplateCards
                localTaskTypes={localTaskTypes}
                setLocalTaskTypes={setLocalTaskTypes}
                onEdit={onEdit}
                onDelete={onDelete}
                taskTypeFilter={isTaskTypeFilteredBySearch}
                isFilteredView={valueSearch?.length > 0}
              />
            )
          }[fetchStatus]
        }
      </StyledPage>

      <TaskTypeDrawer
        visible={!!selectedTaskType}
        taskType={selectedTaskType}
        onDelete={onDelete}
        onClose={onCloseDrawer}
        isNameTaken={isNameTaken}
        lastSequence={localTaskTypes[localTaskTypes.length - 1]?.sequence || 0}
      />
      {/* using the sequence from the very last item, it's possible for the sequence to diverge from the actual count since onDelete, we're not resequencing the numbers */}
    </>
  );
};

const Loading = () => (
  <>
    {Array.from(Array(10)).map((_, i) => (
      <StyledSettingsCard key={uuidv4()} loading={true} />
    ))}
  </>
);

const SortableItem = SortableElement(({ taskType, onEdit, onDelete, isFilteredView }) => (
  <StyledSortableItem style={isFilteredView ? {} : { cursor: 'grab' }}>
    <TaskTypeCard taskType={taskType} onEdit={onEdit} onDelete={onDelete} />
  </StyledSortableItem>
));

const SortableTaskTypes = SortableContainer(({ taskTypes, onEdit, onDelete, taskTypeFilter, isFilteredView }) => (
  <StyledSortableTaskTypes>
    {taskTypes
      .filter((pt) => taskTypeFilter(pt))
      .map((pt, i) => (
        <SortableItem
          key={pt.id}
          index={i}
          taskType={pt}
          onEdit={onEdit}
          onDelete={onDelete}
          isFilteredView={isFilteredView}
          disabled={isFilteredView}
        />
      ))}

    {/* These are to keep any cards that wrap the next row the same width as the ones in the row above */}
    {Array.from(Array(10)).map((_, i) => (
      <StyledHiddenSettingsCard key={uuidv4()} />
    ))}
  </StyledSortableTaskTypes>
));

const TaskTemplateCards = ({ localTaskTypes, setLocalTaskTypes, onEdit, onDelete, taskTypeFilter, isFilteredView }) => {
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    let newLocalTemplates = arrayMove(localTaskTypes, oldIndex, newIndex);
    // reorder sequences
    const startIndex = Math.min(oldIndex, newIndex); // take lesser of the two, and update all sequences from there
    const updatedTemplates = []; // collect all the templates where the sequence is changing and send to the api for update
    for (let index = startIndex; index < newLocalTemplates.length; index++) {
      if (newLocalTemplates[index].sequence !== index + 1) {
        const item = {
          // create copy, don't mutate the underlying template
          ...newLocalTemplates[index],
          sequence: index + 1
        };
        newLocalTemplates = updateObjectInArray(newLocalTemplates, { index, item });
        updatedTemplates.push(item);
      }
    }

    setLocalTaskTypes(newLocalTemplates);
    dispatch(taskTypesActions.bulkUpdate(updatedTemplates));
  };

  return (
    <>
      {localTaskTypes.length === 0 ? (
        <NoRecords PageType={NoRecordsPage.TASK_TEMPLATE} />
      ) : (
        <SortableTaskTypes
          axis="xy"
          taskTypes={localTaskTypes}
          onEdit={onEdit}
          onDelete={onDelete}
          onSortEnd={onSortEnd}
          taskTypeFilter={taskTypeFilter}
          isFilteredView={isFilteredView}
        />
      )}
    </>
  );
};

//#region Styled Components
const StyledSortableItem = styled.div`
  flex: 1;
  min-width: 340px;
  margin: 0 4px 8px 4px;
`;
const StyledSortableTaskTypes = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
`;

// Make Export Link Look Like A Button
const LinkButton = styled.button.attrs({ type: 'link' })`
  background: none;
  border: none;
  padding: 0 0 0 2px;
  color: ${({ theme }) => theme.colors.lightBlueLinkColor};
  :hover {
    text-decoration: underline;
    outline: 0;
    cursor: pointer;
  }
  :focus {
    box-shadow: ${({ theme }) => theme.borders.inactiveNavBorder};
    outline: 0;
  }
  .ant-btn-link& {
    color: ${({ theme }) => theme.colors.lightBlueLinkColor};
    top: -2px;
    height: 20px;
    width: 71px;
    font-family: Roboto;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 20px;
    text-align: right;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;
//#endregion

export default withLDConsumer()(TaskTypes);
