import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

import { theme as themeStyles } from 'styles/appTheme';
import { CommonRedButton } from 'components/styledComponents';

const { Text } = Typography;

const Error = ({
  icon = faExclamation,
  iconSize = '3x',
  errorText = 'Oops, something went wrong',
  infoText = 'Looks like we ran into an error.',
  showRefreshButton = true,
  refreshAction
}) => {
  if (typeof refreshAction != 'function') {
    refreshAction = () => window.location.reload();
  }

  return (
    <StyledFeedbackContainer>
      <IconContainer>
        <StyledIcon icon={icon} size={iconSize} color={themeStyles.colors.darkGray} />
      </IconContainer>
      <StyledText>{errorText}</StyledText>
      <StyledHelperInfo>{infoText}</StyledHelperInfo>
      {showRefreshButton && (
        <CommonRedButton onClick={refreshAction} style={{ marginTop: '32px' }}>
          Refresh
        </CommonRedButton>
      )}
    </StyledFeedbackContainer>
  );
};

const StyledFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 30px;
  min-height: calc(100vh - 195px);
`;

const IconContainer = styled.div`
  position: relative;
  margin-bottom: 32px;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: solid 4px ${({ theme }) => theme.colors.titanium300};
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
  .ant-typography& {
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

const StyledHelperInfo = styled(StyledText)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }
`;

export default Error;
