import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BodyBaseBold, BodyXSmall, BodySmall, BodyXSmallBold } from 'components/styledComponents';
import styled from 'styled-components';
import { Popover, Typography } from 'antd';

import { TaskContext } from '../helpers/context';
import { taskProgressLabels, detailContents } from 'app-constants';
import { RECON_PLAN, TEMPLATE_PLAN } from 'app-constants/planTypes';
import MenuDropdown from '../TaskMenu/TaskMenu';
import PassThroughIndicator from './PassThroughIndicator';
import MessageCountSegment from './MessageCountSegment';
import StepSegment, { StepSegmentForReconTask } from '../StepSegment';
import { getAssigneeWorkloadText, WarningTagForReconTask } from './AssigneeAndTagContent';
import AdditionalTaskNotes from './AdditionalTaskNotes';
import { getCountLineItemByTaskIdSelector } from 'store/tasksStore';
import { isEmpty } from 'lodash';
import CostContent from './CostContent';
import { VENDOR, DEALER_INTERNAL_GROUP } from 'app-constants/groupTypes';
import { getCountLineItemSelector } from 'store/planTemplatesStore';
import { displayDurationSummary, toMomentDuration } from 'utils/dateTimeUtils';
import useVendorRole from 'hooks/useVendorRole';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { Text } = Typography;

/**
 * ContentMetaAndOptions
 * HOC component that contains branching logic to determine which component to render
 * determines component rendered based on planSource which is set in DragDropContext in ReconPlan and TemplatePlan and passed into TaskContext
 */
const ContentMetaAndOptions = withLDConsumer()(({ countMessagesTask, isExpand, flags }) => {
  const { planSource } = useContext(TaskContext);
  return planSource === RECON_PLAN ? (
    <ContentMetaAndOptionsForReconTask
      reconTaskCollapseFlag={flags.reconTaskCollapse}
      countMessagesTask={countMessagesTask}
      isExpand={isExpand}
      flags={flags}
    />
  ) : (
    <ContentMetaAndOptionsForTemplateTask
      isExpand={isExpand}
      reconTaskCollapseFlag={flags.reconTaskCollapse}
      reconApproveButtonFlag={flags.reconApproveButton}
      reconVendorManagementFlag={flags.reconVendorManagement}
    />
  );
});

/**
 * ContentMetaAndOptionsForReconTask
 * recon task component which renders task name, time in task and the overflow menu for editing, completing or deleting a task
 */
const ContentMetaAndOptionsForReconTask = ({ countMessagesTask, isExpand, flags }) => {
  /**
   * task - task dto passed into Task component instance as a prop
   * timeInTask - determined in getReconPlanData function in Task component file
   */
  const {
    task,
    timeInTask,
    taskProgress,
    hidePassthroughIndicator,
    isStandaloneTask,
    showPendingTag,
    showCompletedTag,
    showingOverflowMenu,
    hideOverflowMenu
  } = useContext(TaskContext);
  const { disableCompleteTask, approved } = useSelector(getCountLineItemByTaskIdSelector(task.id));

  return (
    <>
      {flags.reconTaskCollapse ? (
        <>
          <StyledRow>
            <ContentSegmentInfo
              expaned={!isEmpty(isExpand)}
              reconTaskCollapseFlag={flags.reconTaskCollapse}
              reconApproveButtonFlag={flags.reconApproveButton}
              reconVendorManagementFlag={flags.reconVendorManagement}
            />
            <StyledSegmentAction>
              <StyledRow
                style={{
                  justifyContent: 'flex-end',
                  marginRight: !hideOverflowMenu && '-8px',
                  marginTop: !hideOverflowMenu && '-8px'
                }}
              >
                {!isStandaloneTask && flags.reconTaskCollapse && (
                  <MessageCountSegment countMessagesTask={countMessagesTask} />
                )}
                <MenuDropdown needsApproval={disableCompleteTask} approved={approved} />
              </StyledRow>
              <StyledRow style={{ justifyContent: 'flex-end' }}>
                <ContentSegmentTimeTracking task={task} timeInTask={timeInTask}></ContentSegmentTimeTracking>
              </StyledRow>
              <StyledRow style={{ justifyContent: 'flex-end' }}>
                <BodyXSmallBold>
                  <StyledTaskProgress
                    recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0}
                    progress={taskProgress.status}
                  >
                    {taskProgress.info}
                  </StyledTaskProgress>
                </BodyXSmallBold>
              </StyledRow>
              <StyledRow style={{ justifyContent: 'flex-end', marginTop: '3px' }}>
                <WarningTagForReconTask
                  showPendingTag={showPendingTag}
                  showCompletedTag={showCompletedTag}
                  showingOverflowMenu={showingOverflowMenu}
                  flags={flags}
                />
              </StyledRow>
            </StyledSegmentAction>
          </StyledRow>
          {!isEmpty(isExpand) && (
            <StyledRow>
              <AdditionalTaskNotes reconTaskCollapseFlag={flags.reconTaskCollapse} />
            </StyledRow>
          )}
        </>
      ) : (
        <StyledRow>
          <StyledTaskName>
            {task.passthrough && !hidePassthroughIndicator && (
              <PassThroughIndicator reconTaskCollapse={flags.reconTaskCollapse} />
            )}
            {task.reconTaskTypeName}
          </StyledTaskName>
          <StyledTaskTime>{timeInTask}</StyledTaskTime>
          <MenuDropdown />
        </StyledRow>
      )}
    </>
  );
};

/**
 * ContentSegmentInfo
 * Content Segment Task Info for TaskName, assignedToName, workload and cost content
 */
const ContentSegmentInfo = ({
  expaned,
  contentType,
  reconTaskCollapseFlag,
  showAdditionalTaskNotes = false,
  reconApproveButtonFlag = false,
  reconVendorManagementFlag = false
}) => {
  const {
    users,
    task,
    isSaving,
    hidePassthroughIndicator,
    isNotInProgressStep,
    hideAssigneeWorkload,
    reconPlanFeatures,
    planSource
  } = useContext(TaskContext);

  const lineItemsCount = useSelector(
    planSource === TEMPLATE_PLAN
      ? getCountLineItemSelector(task.reconPlanTemplateId, task.id)
      : getCountLineItemByTaskIdSelector(task.id)
  );
  const { needsApproval, approved } = lineItemsCount;
  const hasEditTasks = reconPlanFeatures?.[0];
  const correctVerbForm = needsApproval < 2 ? 's' : '';
  const textBasedOnReconApproveButtonFlag = reconApproveButtonFlag
    ? `${needsApproval} pending approval`
    : `${needsApproval} need${correctVerbForm} approval`;
  const lineItemCountMapText = [
    needsApproval ? textBasedOnReconApproveButtonFlag : '',
    approved ? `${approved} approved` : ''
  ]
    .filter((item) => item)
    .join(', ');

  return (
    <StyledContentSegmentInfo completed={task.completedOn}>
      <StepSegmentForReconTask
        customContent={
          <StyledTaskNameAndIcon completed={task.completedOn}>
            <StyledStepSegment>
              <StepSegment disableIconHover={true} />
            </StyledStepSegment>
            <StyledTaskName
              id={task.reconTaskTypeName}
              className="task-type-name"
              recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}
            >
              {reconTaskCollapseFlag ? (
                <BodyBaseBold>{task.reconTaskTypeName}</BodyBaseBold>
              ) : (
                <span>{task.reconTaskTypeName}</span>
              )}
            </StyledTaskName>
          </StyledTaskNameAndIcon>
        }
      />

      <StyledAssignTo
        id={task?.assignedToName}
        completed={task.completedOn}
        data-assigned-to={task?.assignedToName}
        data-assigned-vendor={reconVendorManagementFlag && task?.assignedToGroupType === VENDOR}
      >
        <AssigneeNameDisplayingTDP
          reconVendorManagementFlag={reconVendorManagementFlag}
          reconTaskCollapseFlag={reconTaskCollapseFlag}
        />
      </StyledAssignTo>
      {expaned && (
        <StyledRow recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
          <StyledAssigneeAndCostContent recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
            {!(!hasEditTasks || isSaving) && task?.assignedTo && !hideAssigneeWorkload && reconTaskCollapseFlag ? (
              <Text className="dark-gray">
                {getAssigneeWorkloadText(users, isNotInProgressStep, task, reconTaskCollapseFlag)}
              </Text>
            ) : null}
            <CostContent reconTaskCollapseFlag={reconTaskCollapseFlag} />
            {contentType !== detailContents.TASK_DETAILS && contentType !== detailContents.DECLINE_TASK && (
              <StyledRow>
                <BodySmall>
                  <LineItems>Line Items: {lineItemCountMapText || String.fromCharCode(8212)}</LineItems>
                </BodySmall>
              </StyledRow>
            )}
          </StyledAssigneeAndCostContent>
        </StyledRow>
      )}
      {showAdditionalTaskNotes && <AdditionalTaskNotes />}
      {!expaned && task.passthrough && !hidePassthroughIndicator && (
        <PassThroughIndicator reconTaskCollapse={reconTaskCollapseFlag} />
      )}
    </StyledContentSegmentInfo>
  );
};

/**
 * Vendors and Technician Names Displaying on TDP
 * @param {boolean} reconVendorManagementFlag
 * @param {boolean} reconTaskCollapseFlag
 * @returns
 */
const AssigneeNameDisplayingTDP = ({ reconVendorManagementFlag, reconTaskCollapseFlag }) => {
  // task - task dto passed into Task component instance as a prop
  const { task } = useContext(TaskContext);
  const { isVendor } = useVendorRole();
  const { isRootUser } = useSelector(rootEntitySwitcherSelector);

  if (reconVendorManagementFlag && task?.assignedToGroupType === VENDOR) {
    if (isVendor && !isRootUser) {
      return <>{task?.assignedToName || <span style={{ color: '#EB002E' }}>Unassigned</span>}</>;
    }
    return (
      <>
        {task?.assignedToGroupName}
        {task?.assignedToName && <>&nbsp;({task?.assignedToName})</>}
      </>
    );
  } else {
    return (
      <>
        {reconTaskCollapseFlag ? (
          <BodySmall>{task?.assignedToName || 'Unassigned'}</BodySmall>
        ) : (
          <span>{task?.assignedToName || 'Unassigned'}</span>
        )}
        {task?.assignedToGroupName &&
        task?.assignedToGroupType &&
        task?.assignedToGroupType === DEALER_INTERNAL_GROUP ? (
          <>&nbsp;({task?.assignedToGroupName})</>
        ) : (
          <></>
        )}
      </>
    );
  }
};

/**
 * ContentMetaAndOptionsForTemplateTask
 * template task component which shows overflow menu for editing/deleting task and the task name
 */
const ContentMetaAndOptionsForTemplateTask = ({
  isExpand,
  reconTaskCollapseFlag,
  reconApproveButtonFlag,
  reconVendorManagementFlag
}) => {
  // task - task dto passed into Task component instance as a prop
  const { task } = useContext(TaskContext);

  return (
    <>
      {reconTaskCollapseFlag ? (
        <>
          <StyledRow>
            <ContentSegmentInfo
              expaned={!isEmpty(isExpand)}
              reconTaskCollapseFlag={reconTaskCollapseFlag}
              reconApproveButtonFlag={reconApproveButtonFlag}
              reconVendorManagementFlag={reconVendorManagementFlag}
            />
            <StyledSegmentAction>
              <StyledRow
                style={{
                  justifyContent: 'flex-end'
                }}
              >
                <MenuDropdown />
              </StyledRow>
            </StyledSegmentAction>
          </StyledRow>
          {!isEmpty(isExpand) && (
            <StyledRow>
              <AdditionalTaskNotes reconTaskCollapseFlag={reconTaskCollapseFlag} />
            </StyledRow>
          )}
        </>
      ) : (
        <StyledRow>
          <StyledTaskName>
            {task.passthrough && <PassThroughIndicator />}
            {task.reconTaskTypeName}
          </StyledTaskName>
          <MenuDropdown />
        </StyledRow>
      )}
    </>
  );
};

/**
 * ContentMetaAndOptionsForTDP
 * recon task component which renders task name, time in task and the overflow menu for editing, completing or deleting a task
 */
export const ContentMetaAndOptionsForTDP = withLDConsumer()(({ contentType, flags }) => {
  /**
   * task - task dto passed into Task component instance as a prop
   * timeInTask - determined in getReconPlanData function in Task component file
   */
  const { task, timeInTask, taskProgress, showPendingTag, showCompletedTag, showingOverflowMenu } =
    useContext(TaskContext);
  const { disableCompleteTask } = useSelector(getCountLineItemByTaskIdSelector(task.id));

  return (
    <StyledRow>
      <ContentSegmentInfo
        expaned={true}
        contentType={contentType}
        showAdditionalTaskNotes={true}
        reconTaskCollapseFlag={flags.reconTaskCollapse}
        reconApproveButtonFlag={flags.reconApproveButton}
        reconVendorManagementFlag={flags.reconVendorManagement}
      />
      <StyledSegmentAction>
        <StyledRow
          style={{
            justifyContent: 'flex-end',
            marginRight: '-8px'
          }}
        >
          <MenuDropdown needsApproval={disableCompleteTask} />
        </StyledRow>
        <StyledRow style={{ justifyContent: 'flex-end' }}>
          <ContentSegmentTimeTracking task={task} timeInTask={timeInTask}></ContentSegmentTimeTracking>
        </StyledRow>
        <StyledRow style={{ justifyContent: 'flex-end' }}>
          <BodyXSmallBold>
            <StyledTaskProgress
              recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0}
              progress={taskProgress?.status}
            >
              {taskProgress?.info}
            </StyledTaskProgress>
          </BodyXSmallBold>
        </StyledRow>
        <StyledRow style={{ justifyContent: 'flex-end', marginTop: '3px' }}>
          <WarningTagForReconTask
            showPendingTag={showPendingTag}
            showCompletedTag={showCompletedTag}
            showingOverflowMenu={showingOverflowMenu}
            flags={flags}
          />
        </StyledRow>
      </StyledSegmentAction>
    </StyledRow>
  );
});

const ContentSegmentTimeTracking = withLDConsumer()(({ task, timeInTask, flags }) => {
  const { isVendor } = useVendorRole();
  const { isRootUser } = useSelector(rootEntitySwitcherSelector);
  const hasShowListAssignments =
    task?.assignedToGroupType === DEALER_INTERNAL_GROUP ||
    (isVendor && !isRootUser && task?.assignedToGroupType === VENDOR && flags?.reconVendorManagement);
  const hasViewDealerUserWithTimeTrackingOfVendor =
    !(isVendor && !isRootUser) &&
    task?.assignedToGroupId &&
    task?.assignedToGroupType === VENDOR &&
    flags?.reconVendorManagement;
  const fontSizeForVendorUser = '20px Roboto';
  const fontSizeForDealerUser = '18px Roboto';
  const [widthText, setWidthText] = useState(100);
  const [widthLeftPercent, setWidthLeftPercent] = useState(10);
  const widthRightPercent = 80;
  const assignedToName = () => {
    if (!task?.assignedToName) {
      return 'Unassigned';
    }
    return task?.assignedToName;
  };

  const assignedToGroupName = (groupName) => {
    // When Vendor user login we don't display vendor name in list reassignment task
    if (isVendor || !groupName) {
      return '';
    }
    return `(${groupName})`;
  };

  // Because we need to show tooltip time tracking two-column. Currently, the width of the tooltip auto follows the width text. So I should calculate width based on text and font size.
  const getTextWidth = (text, font) => {
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    const addMoreWidth = isVendor ? 20 : 0;
    if (metrics?.width > 0 && metrics.width > widthText) setWidthText(metrics.width + addMoreWidth);
  };

  if (hasViewDealerUserWithTimeTrackingOfVendor) {
    getTextWidth(`${timeInTask}: ${task?.assignedToGroupName}`, fontSizeForVendorUser);
  }
  if (!task?.assignedToGroupId) {
    getTextWidth(`${timeInTask}: ${assignedToName()}`, fontSizeForDealerUser);
  }
  if (hasShowListAssignments && (!task?.reassignments || task?.reassignments?.length === 0)) {
    const fullTextTaskTime = `${timeInTask}: ${assignedToName()} ${assignedToGroupName(task?.assignedToGroupName)}`;
    const timeLeftPercent = Math.ceil(((timeInTask?.length + 3) / fullTextTaskTime.trim()?.length) * 100);
    if (timeLeftPercent !== widthLeftPercent && timeLeftPercent > widthLeftPercent) {
      setWidthLeftPercent(timeLeftPercent);
    }
    getTextWidth(fullTextTaskTime, fontSizeForDealerUser);
  }

  const renderListAssignment =
    hasShowListAssignments &&
    (task?.reassignments?.length > 0 ? (
      task?.reassignments?.map((assignment) => {
        const textTimeLeft = displayDurationSummary(
          toMomentDuration({ seconds: assignment?.secondsInAssignment }),
          true,
          true
        );
        const fullTextTime = `${textTimeLeft}:  ${assignment?.assignedToName} ${assignedToGroupName(
          assignment?.assignedToGroupName
        )}`;
        const textTimeLeftPercent = Math.ceil(((textTimeLeft?.length + 3) / fullTextTime.trim()?.length) * 100);
        if (textTimeLeftPercent !== widthLeftPercent && textTimeLeftPercent > widthLeftPercent) {
          setWidthLeftPercent(textTimeLeftPercent);
        }
        getTextWidth(fullTextTime, fontSizeForDealerUser);

        return (
          <StyledLineTime key={assignment.id}>
            <StyledParagraphLeft style={{ width: widthLeftPercent + '%' }}>
              {displayDurationSummary(toMomentDuration({ seconds: assignment?.secondsInAssignment }), true, true)}:
            </StyledParagraphLeft>
            <StyledParagraphRight style={{ width: widthRightPercent + '%' }}>
              {assignment?.assignedToName} {assignedToGroupName(assignment?.assignedToGroupName)}
            </StyledParagraphRight>
          </StyledLineTime>
        );
      })
    ) : (
      <StyledLineTime>
        <StyledParagraphLeft style={{ fontWeight: 'normal', width: widthLeftPercent + '%' }}>
          {timeInTask}:
        </StyledParagraphLeft>
        <StyledParagraphRight style={{ fontWeight: 'normal', width: widthRightPercent + '%' }}>
          {assignedToName()} {assignedToGroupName(task?.assignedToGroupName)}
        </StyledParagraphRight>
      </StyledLineTime>
    ));
  const renderContentTimeTracking = (
    <StyledContentSegmentTimeTracking isShowFontWeight={task?.assignedToGroupId && task?.reassignments?.length > 0}>
      {hasViewDealerUserWithTimeTrackingOfVendor && (
        <StyledParagraphBold>
          {timeInTask}: {task?.assignedToGroupName}
        </StyledParagraphBold>
      )}
      {hasShowListAssignments && task?.assignedToGroupId && (
        <StyledLineTime>
          <StyledParagraphBold style={{ textAlign: 'right', paddingRight: '8px', width: widthLeftPercent + '%' }}>
            {timeInTask}:
          </StyledParagraphBold>
          <StyledParagraphBold style={{ width: widthRightPercent + '%' }}>Task Time</StyledParagraphBold>
        </StyledLineTime>
      )}
      {task?.assignedToGroupId ? (
        renderListAssignment
      ) : (
        <p>
          {timeInTask}: {assignedToName()}
        </p>
      )}
    </StyledContentSegmentTimeTracking>
  );

  const reconTaskCollapseFlag = flags?.reconTaskCollapse ? 1 : 0;

  return (
    <BodyXSmall>
      <Popover
        content={renderContentTimeTracking}
        trigger="hover"
        overlayStyle={{ width: widthText + 'px' }}
        overlayClassName={'time-tracking-reassignment'}
      >
        <StyledTaskNotesAndTimeInTask style={{ cursor: 'pointer' }} recon-task-collapse-flag={reconTaskCollapseFlag}>
          {timeInTask}
        </StyledTaskNotesAndTimeInTask>
      </Popover>
    </BodyXSmall>
  );
});

//#region Styled Components
const StyledRow = styled.div`
  flex: 1 1 auto;
  display: flex;
`;
const StyledTaskName = styled(Text).attrs((props) => ({
  ellipsis: !props['recon-task-collapse-flag']
}))`
  flex: 1 1 0px;
  font-size: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontSizes.md};
  font-weight: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontWeights.medium};
`;
const StyledTaskTime = styled(Text)`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${(props) => props.theme.colors.darkGray};
    margin: 2px 0 0 3px;
  }
`;
const StyledContentSegmentInfo = styled.div`
  flex: 1 1 0px;
  padding-right: 8px;
  .ant-typography& {
    color: ${(props) => props.completed && props.theme.colors.darkGray};
  }
`;
const StyledStepSegment = styled(Text)`
  display: inline-block;
`;
const StyledTaskNameAndIcon = styled.div`
  line-height: 24px;
  .task-type-name {
    word-break: break-word;
    color: ${(props) => props.completed && props.theme.colors.darkGray};
  }
`;
const StyledSegmentAction = styled.div``;
const StyledTaskNotesAndTimeInTask = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontSizes.xs};
  letter-spacing: 0;
  line-height: ${(props) => !props['recon-task-collapse-flag'] && '18px'};
  text-align: right;
  :hover {
    color: ${(props) => props.theme.colors.navy};
  }
`;
const StyledAssigneeAndCostContent = styled.div`
  display: inline-grid;
  font-size: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontSizes.sm};
  letter-spacing: 0;
  line-height: ${(props) => !props['recon-task-collapse-flag'] && '20px'};
`;
const StyledAssignTo = styled(Text)`
  flex: 1 1 0px;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
  .ant-typography& {
    color: ${(props) => props.completed && props.theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    span {
      color: ${(props) => {
        if (props['data-assigned-vendor']) return props.theme.colors.navy;
        if (!props['data-assigned-to']) return props.theme.colors.red;
        if (props.completed) return props.theme.colors.darkGray;
      }};
    }
  }
`;
const StyledTaskProgress = styled.div`
  font-weight: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontWeights.medium};
  display: flex;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${(props) => !props['recon-task-collapse-flag'] && props.theme.fontSizes.xs};
  letter-spacing: 0;
  line-height: ${(props) => !props['recon-task-collapse-flag'] && '18px'};
  text-align: right;
  color: ${(props) => {
    switch (props['progress']) {
      case taskProgressLabels.Overdue:
        return props.theme.colors.red;
      case taskProgressLabels.PastGoal:
        return props.theme.colors.orange;
      default:
        return 'inherit';
    }
  }};
`;

const LineItems = styled(Text)`
  .ant-typography& {
    color: ${(props) => props.theme.colors.darkGray};
  }
`;

const StyledContentSegmentTimeTracking = styled(Text)`
  flex: 1 1 0px;
  letter-spacing: 0;
  word-break: break-word;
  .ant-typography& {
    color: ${(props) => props.completed && props.theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    p {
      font-weight: ${(props) => !props.isShowFontWeight && props.theme.fontWeights.medium};
      margin-bottom: 0px;
    }
  }
`;

const StyledParagraphBold = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;
const StyledParagraphLeft = styled.p`
  text-align: right;
  padding-right: 8px;
`;
const StyledParagraphRight = styled.p``;
const StyledLineTime = styled.div`
  display: flex;
`;
//#endregion

export default ContentMetaAndOptions;
