import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils/dateTimeUtils';
import Lightbox from 'react-image-lightbox';
import { CommonImagesContainer } from 'components/styledComponents';
import 'react-image-lightbox/style.css';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const CommunicationImages = ({ images, tiny = false, createdOn, reconTaskTypeName, flags, isKeepWrapWidth = true }) => {
  const imageHeightWidth = 88;
  const imageMargin = 8;
  const wrapDivRef = useRef(null);
  const [imagesEachRow, setImagesEachRow] = useState(1);
  useEffect(() => {
    if (wrapDivRef.current) {
      let imagesEachRowTemp = Math.floor(wrapDivRef.current.offsetWidth / (imageHeightWidth + imageMargin));
      if (imagesEachRowTemp <= 3) {
        imagesEachRowTemp = 3;
      } else if (imagesEachRowTemp >= 5) {
        imagesEachRowTemp = 5;
      }
      setImagesEachRow(imagesEachRowTemp);
    }
  }, [wrapDivRef.current]);
  const [modalIndex, setModalIndex] = useState(null);
  if (!images?.length) return null;
  const renderImageTitle = (index) => {
    const title = `Image ${index + 1}/${images.length}, ${
      reconTaskTypeName ? ' ' + reconTaskTypeName : ' Vehicle Note'
    }, ${images[index].originalFileName} ${' | ' + formatDateTime(createdOn)}`;
    return title;
  };
  const nextIndex = (modalIndex + 1) % images.length;
  const prevIndex = (modalIndex + images.length - 1) % images.length;
  const imageInfoElement = (
    <StyledImageInfo className="images-info" onClick={() => setModalIndex(0)}>
      (+{images.length} photo{images.length > 1 ? 's' : ''})
    </StyledImageInfo>
  );
  // 30 is space to show three dots icon
  return (
    <div
      ref={wrapDivRef}
      style={isKeepWrapWidth ? { flexShrink: 0, marginRight: '2px' } : { width: 'calc(100% - 30px)' }}
    >
      {tiny ? (
        imageInfoElement
      ) : (
        <CommonImagesContainer
          style={{ height: `${Math.ceil(images.length / imagesEachRow) * (imageHeightWidth + imageMargin)}px` }}
        >
          {images.map((i, index) => (
            <StyledImage
              key={i.id}
              src={i.thumbnailUrl}
              alt={i.originalFileName}
              height={imageHeightWidth}
              width={imageHeightWidth}
              onClick={() => setModalIndex(index)}
            />
          ))}
        </CommonImagesContainer>
      )}
      {modalIndex !== null && (
        <Lightbox
          mainSrc={images[modalIndex].originalUrl}
          mainSrcThumbnail={images[modalIndex].thumbnailUrl}
          nextSrc={images[nextIndex].originalUrl}
          nextSrcThumbnail={images[nextIndex].thumbnailUrl}
          prevSrc={images[prevIndex].originalUrl}
          prevSrcThumbnail={images[prevIndex].thumbnailUrl}
          onCloseRequest={() => setModalIndex(null)}
          onMovePrevRequest={() => setModalIndex((modalIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setModalIndex((modalIndex + 1) % images.length)}
          imageCaption={renderImageTitle(modalIndex)}
        />
      )}
    </div>
  );
};

//#region Styled Components
const StyledImageInfo = styled.a`
  && {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.navy};
  }
  &.images-info {
    &:hover {
      color: ${({ theme }) => theme.colors.lightBlueLinkColor};
    }
  }
`;

//max-height = imageHeightWidth
const StyledImage = styled.img`
  border-radius: 4px;
  max-width: 88px;
  max-height: 88px;
`;
//#endregion

export default withLDConsumer()(CommunicationImages);
