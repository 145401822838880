import { all, put, takeLatest } from 'redux-saga/effects';
import { createRequestTypes, makeActionCreator } from 'utils';
import { dateTimeWithFormat } from 'utils/dateTimeUtils';
import { createSelector } from 'reselect';
import { getFileWithToken, getWithToken } from 'api';
import { CURRENT_DEALER } from 'store/dealersStore';
import {
  ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS,
  apiStatusConstants,
  EXPORT_FAILURE_MESSAGE,
  HISTORICAL_TASK_BREAK_DOWN_HEADERS,
  statisticTagTypes
} from 'app-constants';
import {
  createOverviewDataForPresentation,
  createSummaryTotal,
  createTaskBreakdownDataForPresentation,
  shapePerformanceMetricsForPresentation,
  retrieveUnfilteredMetrics
} from './helperFunctions/metricsStore';
import { messagesActions } from './messagesStore';

//#region Actions
export const METRICS = createRequestTypes('METRICS', [
  'SET_ACTIVE_METRICS_FETCH_STATUS',
  'GET_ACTIVE_METRICS',
  'SET_ACTIVE_METRICS',
  'SET_HISTORICAL_METRICS_FETCH_STATUS',
  'SET_SNAPSHOT_HISTORICAL_METRICS_FETCH_STATUS',
  'GET_HISTORICAL_METRICS',
  'SET_HISTORICAL_METRICS',
  'GET_SNAPSHOT_HISTORICAL_METRICS',
  'SET_SNAPSHOT_HISTORICAL_METRICS',
  'GET_EXPORT_INVENTORY_METRICS',
  'GET_EXPORT_HISTORICAL_METRICS',
  'SET_EXPORT_HISTORICAL_FETCH_STATUS',
  'SET_EXPORT_INVENTORY_FETCH_STATUS',
  'GET_ACTIVE_INVENTORY_PERFORMANCE_METRICS',
  'SET_ACTIVE_INVENTORY_PERFORMANCE_METRICS',
  'SET_ACTIVE_INVENTORY_PERFORMANCE_METRICS_FETCH_STATUS',
  'GET_HISTORICAL_PERFORMANCE_METRICS',
  'SET_HISTORICAL_PERFORMANCE_METRICS',
  'SET_HISTORICAL_PERFORMANCE_METRICS_FETCH_STATUS'
]);

export const metricsActions = {
  setActiveMetricsFetchStatus: makeActionCreator(METRICS.SET_ACTIVE_METRICS_FETCH_STATUS, 'activeMetricsFetchStatus'),
  getActiveMetrics: makeActionCreator(
    METRICS.GET_ACTIVE_METRICS,
    'dealerId',
    'reconDealerFilterFlag',
    'isHq',
    'dealerIds'
  ), //no reducer, triggers getActiveInventoryMetricsSaga
  setActiveMetrics: makeActionCreator(
    METRICS.SET_ACTIVE_METRICS,
    statisticTagTypes.ACTIVE_METRICS,
    'reconDealerFilterFlag',
    'isHq'
  ),
  setHistoricalMetricsFetchStatus: makeActionCreator(
    METRICS.SET_HISTORICAL_METRICS_FETCH_STATUS,
    'historicalMetricsFetchStatus'
  ),
  setSnapshotHistoricalMetricsFetchStatus: makeActionCreator(
    METRICS.SET_SNAPSHOT_HISTORICAL_METRICS_FETCH_STATUS,
    'snapshotHistoricalMetricsFetchStatus'
  ),
  getHistoricalMetrics: makeActionCreator(
    METRICS.GET_HISTORICAL_METRICS,
    'dealerId',
    'filters',
    'createdOn',
    'flags',
    'isHq'
  ), // no reducer, triggers getHistoricalMetricsSaga
  setHistoricalMetrics: makeActionCreator(
    METRICS.SET_HISTORICAL_METRICS,
    'historicalMetrics',
    'reconDealerFilterFlag',
    'isHq'
  ),
  getSnapshotHistoricalMetrics: makeActionCreator(
    METRICS.GET_SNAPSHOT_HISTORICAL_METRICS,
    'dealerId',
    'filters',
    'createdOn',
    'flags'
  ), // no reducer, triggers snapshotGetHistoricalMetricsSaga
  setSnapshotHistoricalMetrics: makeActionCreator(METRICS.SET_SNAPSHOT_HISTORICAL_METRICS, 'snapshotHistoricalMetrics'),
  getExportInventoryMetrics: makeActionCreator(
    METRICS.GET_EXPORT_INVENTORY_METRICS,
    'dealerId',
    'filters',
    'sort',
    'reconTimestampFilterFlag'
  ),
  getExportHistoricalMetrics: makeActionCreator(
    METRICS.GET_EXPORT_HISTORICAL_METRICS,
    'dealerId',
    'filters',
    'sort',
    'reconTimestampFilterFlag'
  ),
  setExportHistorialFetchStatus: makeActionCreator(METRICS.SET_EXPORT_HISTORICAL_FETCH_STATUS, 'fetchStatus'),
  setExportInventoryFetchStatus: makeActionCreator(METRICS.SET_EXPORT_INVENTORY_FETCH_STATUS, 'fetchStatus'),
  getActiveInventoryPerformanceMetrics: makeActionCreator(
    METRICS.GET_ACTIVE_INVENTORY_PERFORMANCE_METRICS,
    'dealerId',
    'filters',
    'dealerCreatedOnDate',
    'reconTimestampFilterFlag',
    'reconDealerFilterFlag'
  ),
  setActiveInventoryPerformanceMetrics: makeActionCreator(
    METRICS.SET_ACTIVE_INVENTORY_PERFORMANCE_METRICS,
    'performanceMetrics',
    'reconDealerFilterFlag'
  ),
  setActiveInventoryPerformanceMetricsFetchStatus: makeActionCreator(
    METRICS.SET_ACTIVE_INVENTORY_PERFORMANCE_METRICS_FETCH_STATUS,
    'fetchStatus'
  ),
  getHistoricalPerformanceMetrics: makeActionCreator(
    METRICS.GET_HISTORICAL_PERFORMANCE_METRICS,
    'dealerId',
    'filters',
    'dealerCreatedOnDate',
    'reconTimestampFilterFlag',
    'reconDealerFilterFlag'
  ),
  setHistoricalPerformanceMetrics: makeActionCreator(
    METRICS.SET_HISTORICAL_PERFORMANCE_METRICS,
    'historicalPerformanceMetrics',
    'reconDealerFilterFlag'
  ),
  setHistoricalPerformanceMetricsFetchStatus: makeActionCreator(
    METRICS.SET_HISTORICAL_PERFORMANCE_METRICS_FETCH_STATUS,
    'fetchStatus'
  )
};
//#endregion

//#region Reducer
const initialState = {
  activeMetrics: {},
  activeMetricsFetchStatus: apiStatusConstants.IS_FETCHING,
  historicalMetrics: {
    overviewData: [],
    taskBreakdownData: []
  },
  unfilteredActiveMetrics: {},
  unfilteredHistoricalMetrics: {},
  historicalMetricsFetchStatus: apiStatusConstants.IS_FETCHING,
  snapshotHistoricalMetrics: [],
  snapshotHistoricalMetricsFetchStatus: false,
  exportHistoricalFetchStatus: apiStatusConstants.PENDING,
  exportInventoryFetchStatus: apiStatusConstants.PENDING,
  activeInventoryPerformanceMetrics: {},
  activeInventoryPerformanceMetricsFetchStatus: apiStatusConstants.PENDING,
  historicalPerformanceMetrics: {},
  historicalPerformanceMetricsFetchStatus: apiStatusConstants.PENDING
};

export const metricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case METRICS.SET_EXPORT_HISTORICAL_FETCH_STATUS: {
      return {
        ...state,
        exportHistoricalFetchStatus: action.fetchStatus
      };
    }
    case METRICS.SET_EXPORT_INVENTORY_FETCH_STATUS: {
      return {
        ...state,
        exportInventoryFetchStatus: action.fetchStatus
      };
    }
    case METRICS.SET_ACTIVE_METRICS_FETCH_STATUS:
      return {
        ...state,
        activeMetricsFetchStatus: action.activeMetricsFetchStatus
      };
    case METRICS.SET_HISTORICAL_METRICS_FETCH_STATUS:
      return {
        ...state,
        historicalMetricsFetchStatus: action.historicalMetricsFetchStatus
      };
    case METRICS.SET_SNAPSHOT_HISTORICAL_METRICS_FETCH_STATUS:
      return {
        ...state,
        snapshotHistoricalMetricsFetchStatus: action.snapshotHistoricalMetricsFetchStatus
      };
    case METRICS.SET_ACTIVE_INVENTORY_PERFORMANCE_METRICS_FETCH_STATUS:
      return {
        ...state,
        activeInventoryPerformanceMetricsFetchStatus: action.fetchStatus
      };
    case METRICS.SET_ACTIVE_METRICS:
      return {
        ...state,
        activeMetrics: {
          ...action.activeMetrics,
          //taskBreakdownData is the same as taskStatusMetrics, but with formatted duration time, and includes data for how to form the presentational data
          //it is renamed this so that we can easily re-use the TaskBreakdown component without re-working that a lot
          taskBreakdownData: [
            ...createTaskBreakdownDataForPresentation(
              true,
              action.activeMetrics,
              'taskStatusMetrics',
              'inProgressAverageTime',
              null,
              action.isHq,
              action.reconDealerFilterFlag
            )
          ],
          summary: createSummaryTotal(true, action.activeMetrics.taskSummaryMetrics, action.reconDealerFilterFlag)
        }
      };
    case METRICS.SET_ACTIVE_INVENTORY_PERFORMANCE_METRICS:
      const [, ...activeInventoryPerformanceMetrics] = shapePerformanceMetricsForPresentation(
        action.performanceMetrics,
        action.reconDealerFilterFlag
      );
      const [, ...unfilteredActiveMetrics] = retrieveUnfilteredMetrics(
        action.performanceMetrics,
        action.reconDealerFilterFlag
      );

      return {
        ...state,
        activeInventoryPerformanceMetrics: activeInventoryPerformanceMetrics,
        unfilteredActiveMetrics: unfilteredActiveMetrics
      };
    case METRICS.SET_HISTORICAL_PERFORMANCE_METRICS_FETCH_STATUS:
      return {
        ...state,
        historicalPerformanceMetricsFetchStatus: action.fetchStatus
      };
    case METRICS.SET_HISTORICAL_PERFORMANCE_METRICS:
      const [...historicalPerformanceMetrics] = shapePerformanceMetricsForPresentation(
        action.historicalPerformanceMetrics,
        action.reconDealerFilterFlag
      );

      const [...unfilteredHistoricalMetrics] = retrieveUnfilteredMetrics(
        action.historicalPerformanceMetrics,
        action.reconDealerFilterFlag
      );

      return {
        ...state,
        historicalPerformanceMetrics: historicalPerformanceMetrics,
        unfilteredHistoricalMetrics: unfilteredHistoricalMetrics
      };
    case METRICS.SET_HISTORICAL_METRICS:
      const overviewData = createOverviewDataForPresentation(action.historicalMetrics, action.reconDealerFilterFlag);
      const historicalInfo = action.historicalMetrics;
      const taskBreakdownData = createTaskBreakdownDataForPresentation(
        false,
        action.historicalMetrics,
        'taskBreakdowns',
        'averageTotalTime',
        'previousAverageTotalTime',
        action.isHq,
        action.reconDealerFilterFlag
      );

      const summary = createSummaryTotal(false, historicalInfo.taskSummaryHistorical, action.reconDealerFilterFlag);
      return {
        ...state,
        historicalMetrics: {
          overviewData,
          taskBreakdownData,
          historicalInfo,
          summary
        }
      };
    case METRICS.SET_SNAPSHOT_HISTORICAL_METRICS:
      const [, ...snapshotOverviewData] = createOverviewDataForPresentation(
        action.snapshotHistoricalMetrics,
        action.reconDealerFilterFlag
      );

      return {
        ...state,
        snapshotHistoricalMetrics: snapshotOverviewData
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const activeMetricsSelector = createSelector((state) => state.activeMetrics);
//#endregion

//#region Sagas
export function* getActiveInventoryMetricsSaga() {
  yield takeLatest(METRICS.GET_ACTIVE_METRICS, function* ({ dealerId, reconDealerFilterFlag, isHq, dealerIds }) {
    try {
      if (!dealerId) {
        return;
      }
      yield put(metricsActions.setActiveMetricsFetchStatus(apiStatusConstants.IS_FETCHING));

      // not passing in dispositions, the queryHandler will handle pulling the in the disposition settings from the dealer
      const data = yield getWithToken(
        `/api/Metrics/activeInventory${
          reconDealerFilterFlag && isHq && dealerIds ? `?dealershipIds=${dealerIds}` : ''
        }`,
        { dealerId, inventoryTypes: ['NEW', 'USED'] }
      );

      //dispositions: ['RETAIL', 'WHOLESALE', 'SUBPRIME'],
      yield all([
        put(metricsActions.setActiveMetrics(data, reconDealerFilterFlag, isHq)),
        put(metricsActions.setActiveMetricsFetchStatus(apiStatusConstants.SUCCEEDED))
      ]);
    } catch (error) {
      yield put(metricsActions.setActiveMetricsFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getActiveInventoryMetricsSaga', error);
    }
  });
}

export function* getActiveInventoryPerformanceMetricsSaga() {
  yield takeLatest(
    METRICS.GET_ACTIVE_INVENTORY_PERFORMANCE_METRICS,
    function* ({ dealerId, filters, dealerCreatedOnDate, reconTimestampFilterFlag, reconDealerFilterFlag }) {
      try {
        if (!dealerId) {
          return;
        }

        yield put(metricsActions.setActiveInventoryPerformanceMetricsFetchStatus(apiStatusConstants.IS_FETCHING));

        // restOfFilters contains dispositions, startTime, and endTime
        const { daysAgo, endingMoment, excludedTaskCategories, ...restOfFilters } = filters;

        const params = {
          dealerId,
          categoryFilters: excludedTaskCategories || [],
          ...restOfFilters,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0
        };
        const data = yield getWithToken(`/api/Metrics/performanceMetrics`, params);

        // Additional data needed to shape performance metrics for Statistics component
        data.earliestDateAvailable = dealerCreatedOnDate;
        data.startDateChosen = filters.startTime;
        data.endDateChosen = filters.endTime;
        data.daysAgo = daysAgo;
        data.endingMoment = endingMoment;

        yield all([
          put(metricsActions.setActiveInventoryPerformanceMetrics(data, reconDealerFilterFlag)),
          put(metricsActions.setActiveInventoryPerformanceMetricsFetchStatus(apiStatusConstants.SUCCEEDED))
        ]);
      } catch (error) {
        yield put(metricsActions.setActiveInventoryPerformanceMetricsFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getActiveInventoryPerformanceMetricsSaga', error);
      }
    }
  );
}

export function* getHistoricalPerformanceMetricsSaga() {
  yield takeLatest(
    METRICS.GET_HISTORICAL_PERFORMANCE_METRICS,
    function* ({ dealerId, filters, dealerCreatedOnDate, reconTimestampFilterFlag, reconDealerFilterFlag }) {
      try {
        if (!dealerId) {
          return;
        }

        yield put(metricsActions.setHistoricalPerformanceMetricsFetchStatus(apiStatusConstants.IS_FETCHING));

        // restOfFilters contains dispositions, startTime, and endTime
        const { daysAgo, endingMoment, excludedTaskCategories, ...restOfFilters } = filters;
        const params = {
          dealerId,
          categoryFilters: excludedTaskCategories || [],
          ...restOfFilters,
          clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0
        };
        const data = yield getWithToken(`/api/Metrics/performanceMetrics`, params);
        // Additional data needed to shape performance metrics for Statistics component
        data.earliestDateAvailable = dealerCreatedOnDate;
        data.startDateChosen = filters.startTime;
        data.endDateChosen = filters.endTime;
        data.daysAgo = daysAgo;
        data.endingMoment = endingMoment;

        yield all([
          put(metricsActions.setHistoricalPerformanceMetrics(data, reconDealerFilterFlag)),
          put(metricsActions.setHistoricalPerformanceMetricsFetchStatus(apiStatusConstants.SUCCEEDED))
        ]);
      } catch (error) {
        yield put(metricsActions.setHistoricalPerformanceMetricsFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getHistoryPerformanceMetricsSaga', error);
      }
    }
  );
}

export function* getHistoricalMetricsSaga() {
  yield takeLatest(METRICS.GET_HISTORICAL_METRICS, function* ({ dealerId, filters, createdOn, flags, isHq }) {
    try {
      if (!dealerId) {
        return;
      }
      yield put(metricsActions.setHistoricalMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
      const { daysAgo, endingMoment, customDateRange, ...restOfFilters } = filters; //restOfFilters contains dispositions, start and end date
      const params = {
        ...restOfFilters,
        dealerId,
        clientTimezoneOffset: flags.reconTimestampFilter ? new Date().getTimezoneOffset() : 0
      };
      const data = yield getWithToken(`/api/Metrics/historical`, params);
      data.earliestDateAvailable = createdOn;
      data.startDateChosen = filters.startTime;
      data.endDateChosen = filters.endTime;
      data.daysAgo = daysAgo;
      data.endingMoment = endingMoment;

      yield all([
        put(metricsActions.setHistoricalMetrics(data, flags.reconDealerFilter, isHq)),
        put(metricsActions.setHistoricalMetricsFetchStatus(apiStatusConstants.SUCCEEDED))
      ]);
    } catch (error) {
      yield put(metricsActions.setHistoricalMetricsFetchStatus(apiStatusConstants.FAILED));
      devLogger.error('error in getHistoricalMetricsSaga', error);
    }
  });
}

export function* getSnapshotHistoricalMetricsSaga() {
  yield takeLatest(METRICS.GET_SNAPSHOT_HISTORICAL_METRICS, function* ({ dealerId, filters, createdOn, flags }) {
    try {
      if (!dealerId) {
        return;
      }
      yield put(metricsActions.setSnapshotHistoricalMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
      const { daysAgo, endingMoment, customDateRange, dealershipIds, ...restOfFilters } = filters; //restOfFilters contains dispositions, start and end date
      const params = {
        ...restOfFilters,
        dealerId,
        dealershipIds,
        clientTimezoneOffset: flags?.reconTimestampFilter ? new Date().getTimezoneOffset() : 0
      };
      const data = yield getWithToken(`/api/Metrics/historical`, params);
      data.earliestDateAvailable = createdOn;
      data.startDateChosen = filters.startTime;
      data.endDateChosen = filters.endTime;
      data.daysAgo = daysAgo;
      data.endingMoment = endingMoment;
      data.flags = flags;
      yield all([
        put(metricsActions.setSnapshotHistoricalMetrics(data)),
        put(metricsActions.setSnapshotHistoricalMetricsFetchStatus(apiStatusConstants.SUCCEEDED))
      ]);
    } catch (error) {
      yield put(metricsActions.setSnapshotHistoricalMetricsFetchStatus(apiStatusConstants.FAILED));
      devLogger.error('error in getSnapshotHistoricalMetricsSaga', error);
    }
  });
}

export function* getHistoricalExportMetricsSaga() {
  const now = new Date();
  yield takeLatest(
    METRICS.GET_EXPORT_HISTORICAL_METRICS,
    function* ({ dealerId, filters, sort = {}, reconTimestampFilterFlag }) {
      try {
        if (!dealerId) {
          return;
        }
        yield put(metricsActions.setExportHistorialFetchStatus(apiStatusConstants.IS_FETCHING));
        const { inventoryTypes, endTime, startTime, excludedTaskCategories } = filters;
        const params = {
          dealerId,
          dispositions: filters.dispositions === 'ANY' ? [] : filters.dispositions,
          inventoryTypes,
          endTime,
          startTime,
          clientTimezoneOffset: reconTimestampFilterFlag ? now.getTimezoneOffset() : 0,
          categoryFilters: excludedTaskCategories || [],
          dealershipIds: filters.dealershipIds
        };
        formatAndAddSortValue(params, sort, HISTORICAL_TASK_BREAK_DOWN_HEADERS);
        const exportedHistoricalData = yield getFileWithToken(`/api/Metrics/historical/export`, params);
        const fileName = `Historical_Performance_${dateTimeWithFormat(now, 'YYYY_MM_DD')}.xlsx`;
        require('downloadjs')(exportedHistoricalData.data, fileName, exportedHistoricalData.headers['content-type']);
        yield put(metricsActions.setExportHistorialFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        devLogger.error('error in getHistoricalExportMetricsSaga', error);
        yield put(metricsActions.setExportHistorialFetchStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
      }
    }
  );
}

export function* getInventoryExportMetricsSaga() {
  const now = new Date();
  yield takeLatest(
    METRICS.GET_EXPORT_INVENTORY_METRICS,
    function* ({ dealerId, filters, sort = {}, reconTimestampFilterFlag }) {
      if (!dealerId) {
        return;
      }

      const params = {
        dealerId,
        dispositions: [],
        inventoryTypes: filters.inventoryTypes,
        categoryFilters: filters.excludedTaskCategories || [],
        clientTimezoneOffset: reconTimestampFilterFlag ? new Date().getTimezoneOffset() : 0,
        dealershipIds: filters.dealershipIds
      };
      formatAndAddSortValue(params, sort, ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS);
      try {
        yield put(metricsActions.setExportInventoryFetchStatus(apiStatusConstants.IS_FETCHING));
        const exportedInventoryData = yield getFileWithToken(`/api/Metrics/activeInventory/export`, params);
        const fileName = `Inventory_Snapshot_${dateTimeWithFormat(now, 'YYYY_MM_DD')}.xlsx`;
        require('downloadjs')(exportedInventoryData.data, fileName, exportedInventoryData.headers['content-type']);
        yield put(metricsActions.setExportInventoryFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        devLogger.error('error in getInventoryExportMetricsSaga', error);
        yield put(metricsActions.setExportInventoryFetchStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
      }
    }
  );
}

const formatAndAddSortValue = (params, sort, object) => {
  const sortHeader = sort.field && Object.keys(object).find((k) => object[k] === sort.field);
  if (sort.order && sortHeader) {
    params.sortType = sort.order === 'ascend' ? 'ASC' : 'DESC';
    params.orderByColumn = sortHeader;
  }
};
//#endregion
