import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Modal, Typography } from 'antd';
import { apiStatusConstants } from 'app-constants';
import arrayMove from 'array-move';
import { useWindowSize } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { createSelector } from 'reselect';
import { planTemplatesActions } from 'store/planTemplatesStore';
import { taskTypesActions } from 'store/taskTypesStore';
import { usersActions } from 'store/usersStore';
import { taskCategoriesActions } from 'store/taskCategoriesStore';
import { CommonSearch, CommonRedButton, CommonHeader } from 'components/styledComponents';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

import { PlanTemplateCard, PlanTemplateDrawer } from '.';
import { NoRecords, NoRecordsPage } from '../../vendorAndGroups';
import { StyledHiddenSettingsCard, StyledList, StyledPage, StyledSettingsCard } from '../common/Styles.js';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../../google-analytics';

const { Text } = Typography;

const planTemplatesPropsSelector = createSelector(
  (state) => state.dealers.current.data,
  (state) => state.planTemplates,
  (state) => state.taskCategories,
  (currentDealer, planTemplatesStore, taskCategoriesStore) => ({
    currentDealer,
    planTemplates: planTemplatesStore.data,
    fetchStatus: planTemplatesStore.fetchStatus,
    taskCategoriesDict: taskCategoriesStore.categoriesAll.dict
  })
);

const PlanTemplates = ({ flags }) => {
  const dispatch = useDispatch();
  const { currentDealer, planTemplates, fetchStatus, taskCategoriesDict } = useSelector(planTemplatesPropsSelector);
  const { isBridgeUser } = useSelector((state) => state.auth);
  const [selectedPlanTemplate, setSelectedPlanTemplate] = useState(null);
  const [localPlanTemplates, setLocalPlanTemplates] = useState([]); // using a local copy so that we don't mutate the actual collection during reordering
  const [fetchStarted, setFetchStarted] = useState(null); //  used for capturing page load time for google analytics, considering the page as done loading when the planTemplatesActions.get call has completed
  const [width, height] = useWindowSize();
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.PLAN_TEMPLATES_VIEWED);

  useEffect(() => {
    const clientHeight = document.getElementById('planTemplatesContainer').clientHeight;
    const scrollHeight = document.getElementById('planTemplatesContainer').scrollHeight;
    if (clientHeight < scrollHeight) {
      setHasScrollBar(true);
    } else {
      setHasScrollBar(false);
    }
  }, [width, height, fetchStatus]);

  useEffect(() => {
    setLocalPlanTemplates([...planTemplates]);

    if (fetchStarted) {
      setFetchStarted(false);
    }
  }, [planTemplates]);

  useEffect(() => {
    dispatch(taskCategoriesActions.getData());
  }, []);

  useEffect(() => {
    if (currentDealer && currentDealer.id) {
      setFetchStarted(true);
      dispatch(planTemplatesActions.get(currentDealer.id));
      dispatch(taskTypesActions.getData(currentDealer.id));
    }
  }, [currentDealer]);

  const onAdd = (e) => {
    e.preventDefault();
    dispatch(usersActions.getData(currentDealer.id)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
    setSelectedPlanTemplate({ templateTaskGroups: [] });

    dispatch(usersActions.getAssigneeData(currentDealer.id));
  };

  const onEdit = (planTemplate) => {
    dispatch(usersActions.getData(currentDealer.id)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
    setSelectedPlanTemplate(planTemplate);

    dispatch(usersActions.getAssigneeData(currentDealer.id));
  };

  const onDelete = (planTemplate) => {
    Modal.confirm({
      title: `Delete ${planTemplate.name} plan template?`,
      content: `This plan template will be unavailable when creating new recon plans. Existing recon plans will be unaffected.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(
          planTemplatesActions.delete(
            planTemplate,
            'Deleting plan template...',
            'Plan template deleted!',
            'An error occurred while deleting the plan template'
          )
        );
      },
      onCancel() {
        devLogger.log(`Canceled Delete ${planTemplate.name}`);
      }
    });
  };

  const planFilteredBySearch = (plan) => {
    const trimmedValue = valueSearch.toLowerCase().trim();

    if (trimmedValue) {
      const matchesPlanName = plan.name?.toLowerCase().includes(trimmedValue);
      const matchesPlanDesc = plan.description?.toLowerCase().includes(trimmedValue);

      let matchesTaskTypeCategory = false;
      let matchesTaskTypeName = false;
      let matchesTaskTypeAssignee = false;
      let matchesTaskTypeGroupAssignee = false;

      //Go thru each task in a plan, if we have found a match set to true then break. (we break, if our first one has a match we need to return that, otherwise it could be possible to reset back to false)
      for (let task in plan?.templateTasks) {
        if (plan?.templateTasks.hasOwnProperty(task)) {
          matchesTaskTypeName = plan?.templateTasks[task]?.reconTaskTypeName.toLowerCase().includes(trimmedValue);
          matchesTaskTypeAssignee = plan?.templateTasks[task]?.assignedToName?.toLowerCase().includes(trimmedValue);
          matchesTaskTypeGroupAssignee = plan?.templateTasks[task]?.assignedToGroupName
            ?.toLowerCase()
            .includes(trimmedValue);
          matchesTaskTypeCategory = taskCategoriesDict[plan?.templateTasks[task]?.categoryId]
            ?.toLowerCase()
            .includes(trimmedValue);
        }

        if (matchesTaskTypeName || matchesTaskTypeAssignee || matchesTaskTypeGroupAssignee || matchesTaskTypeCategory) {
          break;
        }
      }

      return (
        matchesPlanName ||
        matchesPlanDesc ||
        matchesTaskTypeName ||
        matchesTaskTypeAssignee ||
        matchesTaskTypeGroupAssignee ||
        matchesTaskTypeCategory
      );
    }

    return true;
  };

  const isNameTaken = (name, templateId) => {
    //TODO: it's possible that another user may have just added another plan with the same name,
    //      longer term, the same name check should probably happen on the api side
    if (templateId) {
      return planTemplates.filter((t) => t.id !== templateId).some((t) => t.name === name);
    } else {
      return planTemplates.some((t) => t.name === name);
    }
  };

  const onCloseDrawer = () => {
    setSelectedPlanTemplate(null); // clear out to close drawer
  };

  return (
    <>
      <CommonHeader style={{ margin: `0 ${hasScrollBar ? 36 : 28}px 16px auto` }}>
        <CommonSearch
          data-is-focused={searchBoxIsFocused}
          placeholder={searchBoxIsFocused ? 'Type to begin searching...' : 'Search'}
          onFocus={() => setSearchBoxIsFocused(true)}
          onBlur={() => setSearchBoxIsFocused(false)}
          value={valueSearch}
          custom-height={34}
          prefix={<FontAwesomeIcon icon={faSearch} />}
          onChange={(e) => setValueSearch(e.target?.value)}
        />

        <CommonRedButton onClick={onAdd}>New Plan</CommonRedButton>
        {/**TODO disabled for now until we implement the full list view */}
        {/* <Divider type="vertical" style={{ width: '2px', height: '32px', marginTop: '5px' }} />
          <StyledIcon icon={faImage} style={{ margin: '10px 10px 0px 10px', fontSize: '18px' }} />
          <StyledIcon icon={faListUl} style={{ margin: '10px 10px 0px 10px', fontSize: '18px' }} /> */}
      </CommonHeader>
      {/** The flags change the style of 2 wrapper component. So make sure if has any changed, change on both true/false conditions */}
      <StyledPage is-bridge-user={isBridgeUser ? 1 : 0} id={'planTemplatesContainer'}>
        {
          {
            [apiStatusConstants.FAILED]: (
              <Text type="danger" ellipsis>
                Something went wrong retrieving Plan Template information
              </Text>
            ),
            [apiStatusConstants.IS_FETCHING]: (
              <StyledList>
                <Loading />
              </StyledList>
            ),
            [apiStatusConstants.SUCCEEDED]: (
              <PlanTemplateCards
                localPlanTemplates={localPlanTemplates}
                setLocalPlanTemplates={setLocalPlanTemplates}
                onEdit={onEdit}
                onDelete={onDelete}
                flags={flags}
                isFilteredView={valueSearch?.length > 0}
                planFilter={planFilteredBySearch}
              />
            )
          }[fetchStatus]
        }
      </StyledPage>

      <PlanTemplateDrawer
        visible={!!selectedPlanTemplate}
        planTemplate={selectedPlanTemplate}
        lastSequence={planTemplates[planTemplates.length - 1]?.sequence || 0} // using the sequence from the very last item, it's possible for the sequence to diverge from the actual count since onDelete, we're not resequencing the numbers
        onClose={onCloseDrawer}
        isNameTaken={isNameTaken}
        onDelete={onDelete}
      />
    </>
  );
};

const Loading = ({ reconSettingsUpdateFlags }) =>
  reconSettingsUpdateFlags ? (
    <>
      {Array.from(Array(10)).map((_, i) => (
        <StyledSettingsCard key={uuidv4()} loading={true} />
      ))}
    </>
  ) : (
    <>
      {Array.from(Array(10)).map((_, i) => (
        <StyledSortableItem key={uuidv4()}>
          <StyledSettingsCard key={uuidv4()} loading={true} />
        </StyledSortableItem>
      ))}
      {Array.from(Array(10)).map((_, i) => (
        <StyledHiddenSettingsCard key={uuidv4()} />
      ))}
      {/**These are invisible filler items to make items on a partially filled last row the correct size */}
    </>
  );

const SortableItem = SortableElement(({ planTemplate, onEdit, onDelete, isFilteredView }) => (
  <StyledSortableItem style={isFilteredView ? {} : { cursor: 'grab' }}>
    <PlanTemplateCard planTemplate={planTemplate} onEdit={onEdit} onDelete={onDelete} />
  </StyledSortableItem>
));

const SortablePlanTemplates = SortableContainer(({ planTemplates, onEdit, onDelete, isFilteredView, planFilter }) => (
  <StyledSortablePlanTemplates>
    {planTemplates
      .filter((pt) => planFilter(pt))
      .map((pt, i) => (
        <SortableItem
          key={pt.id}
          index={i}
          planTemplate={pt}
          onEdit={onEdit}
          onDelete={onDelete}
          isFilteredView={isFilteredView}
          disabled={isFilteredView}
        />
      ))}

    {/* These are to keep any cards that wrap the next row the same width as the ones in the row above */}
    {Array.from(Array(10)).map((_, i) => (
      <StyledHiddenSettingsCard key={uuidv4()} />
    ))}
  </StyledSortablePlanTemplates>
));

const PlanTemplateCards = ({
  localPlanTemplates,
  setLocalPlanTemplates,
  onEdit,
  onDelete,
  planFilter,
  isFilteredView
}) => {
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    let newLocalTemplates = arrayMove(localPlanTemplates, oldIndex, newIndex);

    // reorder sequences
    const updatedTemplates = [];
    newLocalTemplates = newLocalTemplates.map((template, index) => {
      if (template.sequence === index + 1) return template;
      let t = {
        ...template,
        sequence: index + 1
      };
      updatedTemplates.push(t);
      return t;
    });

    setLocalPlanTemplates(newLocalTemplates);
    dispatch(planTemplatesActions.bulkUpdate(updatedTemplates));
  };

  return (
    <>
      {localPlanTemplates.length === 0 ? (
        <NoRecords PageType={NoRecordsPage.PLAN_TEMPLATE} />
      ) : (
        <SortablePlanTemplates
          axis="xy"
          planTemplates={localPlanTemplates}
          onEdit={onEdit}
          onDelete={onDelete}
          onSortEnd={onSortEnd}
          isFilteredView={isFilteredView}
          planFilter={planFilter}
        />
      )}
    </>
  );
};

//#region Styled Components
const StyledSortableItem = styled.div`
  flex: 1;
  min-width: 340px;
  margin: 0 4px 8px 4px;
`;
const StyledSortablePlanTemplates = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
`;
//#endregion

export default PlanTemplates;
