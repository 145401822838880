import React from 'react';
import { BodyXSmall } from 'components/styledComponents';
import styled, { keyframes } from 'styled-components';
import { displayDurationSummary, getMomentDurationFromString } from '../../../../utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import { apiStatusConstants } from '../../../../app-constants';

const PendingApprovalTime = ({ timeInApproval }) => {
  const displayedTimeInApproval =
    typeof timeInApproval === 'string'
      ? displayDurationSummary(getMomentDurationFromString(timeInApproval), true, true, false)
      : String.fromCharCode(8212);

  const timeInApproveFetchStatus = useSelector((state) => state.tasks.timeInApprovalStatus);

  return (
    <StyledPendingApprovalTime>
      {timeInApproveFetchStatus === apiStatusConstants.IS_FETCHING ? (
        <div style={{display: 'flex'}}>
          <PendingApprovalTimeContainer>
            <BodyXSmall>Pending Approval: </BodyXSmall>
            <ThreeDotsLoader />
          </PendingApprovalTimeContainer>
        </div>
      ) : (
        <BodyXSmall>Pending Approval: {displayedTimeInApproval}</BodyXSmall>
      )}
    </StyledPendingApprovalTime>
  );
};

const ThreeDotsLoader = () => {
  return (
    <StyledThreeDotsSpinner>
      <StyledDot />
      <StyledDot />
      <StyledDot />
    </StyledThreeDotsSpinner>
  );
};

//#region Styled Components
const StyledPendingApprovalTime = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  text-align: right;
`;

const PendingApprovalTimeContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-left: auto;
`;

const animation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-1px);
  }
`;
const StyledDot = styled.div`
  width: 6px;
  height: 6px;
  margin: 1px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;
  animation: ${animation} 0.6s infinite alternate;
`;

const StyledThreeDotsSpinner = styled.div`
  display: flex;
  align-items: center;

  ${StyledDot}:nth-child(2) {
    -webkit-animation-delay: 0.2s;
  }

  ${StyledDot}:nth-child(3) {
    -webkit-animation-delay: 0.4s;
  }
`;
//#endregion

export default PendingApprovalTime;
