import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { Empty, Form, Icon, Select, Typography } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { apiStatusConstants } from 'app-constants';
import { CommonSelect } from 'components/styledComponents';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useSelector } from 'react-redux';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { Option, OptGroup } = Select;
const { Text } = Typography;

const excludeExternalTech = (data, assignee, flags) => {
  if (!data?.length) {
    return data;
  }

  if (flags?.reconDisableExtechsPhase1) {
    // For case add new
    if (
      !assignee ||
      (!assignee.assignedTo && assignee.assignedToGroupId) ||
      !IsTaskAssigningToExternalTechnicians(data, assignee)
    ) {
      return data.filter((x) => !x.isExternalTech);
    }

    const id = GetAssigneeId(assignee);
    if (id) {
      return data.filter(
        (x) =>
          !IsTaskAssigningToExternalTechnicians(data, x) ||
          (IsTaskAssigningToExternalTechnicians(data, x) && x.id === id)
      );
    }
  }

  return data;
};

const IsTaskAssigningToExternalTechnicians = (users, assignee) => {
  if (assignee?.isExternalTech !== undefined) {
    return assignee?.isExternalTech;
  }

  const id = assignee?.assignedTo ?? false;

  if (id) {
    var user = users.find((u) => u.id === id);
    return user !== null && user !== undefined && user.isExternalTech;
  } else {
    return false;
  }
};

const GetAssigneeId = (assignee) => {
  if (assignee?.isExternalTech !== undefined && assignee?.userId) {
    return assignee.userId;
  }

  return assignee?.assignedTo;
};

const AssignedToSelect = ({
  form = null,
  getFieldDecorator,
  assignedToOptional = false,
  users,
  internalGroups,
  vendors,
  usersFetchStatus,
  onChange,
  assignedToMember,
  userIdGetAssignToByTypeName,
  taskToUpdate,
  withWorkload = true,
  flags,
  // only for task template
  firstAssignee,
  usersIsLoading,
  isShowNoAssignee = false
}) => {
  // object members of group with key is group id
  const [allGroupsMembers, setAllGroupsMembers] = useState({});

  const [allVendorUsers, setAllVendorUsers] = useState({});
  const [showVendorUsers, setShowVendorUsers] = useState([]);

  // list state for show list member in assigned to select
  const [showGroupMembers, setShowGroupMembers] = useState([]);
  // have to manually set open because member option is not antd option which will not close dropdown after selecting
  const [isOpen, setIsOpen] = useState(false);
  // search key for assigned to
  const [searchKey, setSearchKey] = useState('');
  // assigneeRef.current.blur() to blur select that trigger close dropdown
  const assigneeRef = useRef(null);
  const internalGroupRefs = useScrollIntoView(internalGroups);
  const vendorRefs = useScrollIntoView(vendors);
  const NO_ASSIGNEE = 'NO_ASSIGNEE';

  const [internalUsers, setInternalUsers] = useState([]);

  const { isRootUser, vendorShipSelected, currentId } = useSelector(rootEntitySwitcherSelector);

  useEffect(() => {
    if (firstAssignee) {
      // Task Template
      setInternalUsers(excludeExternalTech(users, firstAssignee, flags));
    } else if (taskToUpdate) {
      // Plan Template
      setInternalUsers(excludeExternalTech(users, taskToUpdate, flags));
    } else {
      // Add new - all case
      setInternalUsers(excludeExternalTech(users, null, flags));
    }
  }, [users, flags.reconDisableExtechsPhase1]);

  useEffect(() => {
    let memberDetails = {};
    if (internalGroups && users) {
      internalGroups.forEach((ig) => {
        const groupMembers = [];
        let memberUnassigned = ig.internalGroupUsers?.find((groupMember) => groupMember.fullName === 'Unassigned');
        if (memberUnassigned) {
          memberUnassigned.id = null;
          groupMembers.push(memberUnassigned);
        }
        users.forEach((u) => {
          const member = ig.internalGroupUsers?.find((groupMember) => groupMember.userId === u.id);
          if (member)
            groupMembers.push({
              ...u,
              workload: member.workload
            });
        });
        memberDetails[ig.id] = groupMembers;
      });
    }
    setAllGroupsMembers(memberDetails);
  }, [users, internalGroups]);

  useEffect(() => {
    if (internalGroups && internalGroups.length) {
      setShowGroupMembers(internalGroups.map(() => false));
    }
  }, [internalGroups]);

  useEffect(() => {
    let newShowGroupMembers = [...showGroupMembers];
    if (searchKey) {
      internalGroups.forEach((internalGroup, index) => {
        const memberSearched = allGroupsMembers[internalGroup.id]?.some((member) =>
          `${member.fullName} (${internalGroup.name})`.toLowerCase().includes(searchKey)
        );
        newShowGroupMembers[index] = memberSearched;
      });
    } else {
      newShowGroupMembers = showGroupMembers.map(() => false);
    }
    setShowGroupMembers(newShowGroupMembers);

    if (isRootUser && vendorShipSelected) {
      let newShowVendorUsers = [...showVendorUsers];
      if (searchKey) {
        vendors.forEach((v, index) => {
          const memberSearched = allVendorUsers[v.id]?.some((v) => v.fullName.toLowerCase().includes(searchKey));
          newShowVendorUsers[index] = memberSearched;
        });
      } else {
        newShowVendorUsers = vendors.map(() => false);
      }
      setShowVendorUsers(newShowVendorUsers);
    }
  }, [searchKey]);

  const getIndexOfCurrentGroupWhenOpen = () => {
    let index = -1;
    if (withWorkload) {
      index = internalGroups?.findIndex(
        (ig) =>
          ig.id === userIdGetAssignToByTypeName ||
          (userIdGetAssignToByTypeName === undefined && ig.id === taskToUpdate?.assignedToGroupId)
      );
    } else {
      index = internalGroups?.findIndex(
        (ig) =>
          ig.id === userIdGetAssignToByTypeName ||
          (userIdGetAssignToByTypeName === undefined && ig.id === firstAssignee?.assignedToGroupId)
      );
    }
    return index;
  };

  const getIndexOfCurrentVendorWhenOpen = () => {
    let index = -1;
    if (isRootUser && vendorShipSelected) {
      if (withWorkload) {
        index = vendors.findIndex(
          (v) =>
            v.id === userIdGetAssignToByTypeName ||
            (userIdGetAssignToByTypeName === undefined && v.id === taskToUpdate?.assignedToGroupId)
        );
      } else {
        index = vendors?.findIndex(
          (v) =>
            v.id === userIdGetAssignToByTypeName ||
            (userIdGetAssignToByTypeName === undefined && v.id === firstAssignee?.assignedToGroupId)
        );
      }
    }
    return index;
  };

  useEffect(() => {
    if (isOpen) {
      const index = getIndexOfCurrentGroupWhenOpen();
      const indexRootUser = getIndexOfCurrentVendorWhenOpen();
      if (index >= 0) {
        let newShowGroupMembers = [...showGroupMembers];
        newShowGroupMembers[index] = true;
        setShowGroupMembers(newShowGroupMembers);
        // internalGroupRefs[index].current will be null at the first time open, so we might need to wait for it to bind
        setTimeout(
          () => {
            if (internalGroupRefs[index]?.current) {
              internalGroupRefs[index].current.scrollIntoView(true);
            }
          },
          internalGroupRefs[index]?.current ? 0 : 200
        );
      } else if (indexRootUser >= 0) {
        let newShowVendorUsers = [...showVendorUsers];
        newShowVendorUsers[indexRootUser] = true;
        setShowVendorUsers(newShowVendorUsers);
        setTimeout(
          () => {
            if (vendorRefs[indexRootUser]?.current) {
              vendorRefs[indexRootUser].current.scrollIntoView(true);
            }
          },
          vendorRefs[indexRootUser]?.current ? 0 : 200
        );
      }
    } else {
      setShowGroupMembers(showGroupMembers.map(() => false));
      setShowVendorUsers(showVendorUsers.map(() => false));
    }
  }, [isOpen]);

  const forceUpdateValue = () => {
    setTimeout(() => {
      if (assigneeRef && assigneeRef.current.props.value !== userIdGetAssignToByTypeName) {
        if (withWorkload) onChange('assignedTo')(userIdGetAssignToByTypeName);
        if (internalGroups?.find((ig) => ig.id === userIdGetAssignToByTypeName)) {
          onChange('assignedToMember')(assignedToMember, userIdGetAssignToByTypeName);
        }
      }
    }, 100);
  };

  const filterMember = (value) => {
    setSearchKey(value.trim().toLowerCase());
  };

  const openMenuAndFocusInput = () => {
    setIsOpen(true);
    assigneeRef.current.rcSelect.inputRef.focus();
  };

  // show list member
  const onIconShowSubMenuClick = (event, index, vendorUser = false) => {
    if (event) {
      // not trigger antd select option since this is clicking in a child element of antd select option
      event.preventDefault();
      event.stopPropagation();
    }

    if (vendorUser) {
      if (vendorRefs[index] && vendorRefs[index].current) {
        setTimeout(() => {
          vendorRefs[index].current.scrollIntoView(true);
        });
      }

      const newShowVendorUsers = [...showVendorUsers];
      newShowVendorUsers[index] = !showVendorUsers[index];
      setShowVendorUsers(newShowVendorUsers);
    } else {
      if (internalGroupRefs[index] && internalGroupRefs[index].current) {
        setTimeout(() => {
          internalGroupRefs[index].current.scrollIntoView(true);
        });
      }

      const newShowGroupMembers = [...showGroupMembers];
      newShowGroupMembers[index] = !showGroupMembers[index];
      setShowGroupMembers(newShowGroupMembers);
    }
  };

  const blurSelect = () => {
    assigneeRef.current.blur();
    assigneeRef.current.rcSelect.inputRef.blur();
  };

  const onBlurHandler = () => {
    setIsOpen(false);
    setSearchKey('');
  };

  const tempState = useRef({ isSelectingMember: false });

  // the select component of antd some how self update value and trigger this function with the wrong data when clicking in child (case search data has value)
  // ignore that call and forceUpdateValue to the exact one
  const onAssigneeChange = (value, isSelectGroup) => {
    if (tempState.current.isSelectingMember) {
      tempState.current.isSelectingMember = false;
      forceUpdateValue();
      return;
    }
    blurSelect();
    const group = internalGroups.find((ig) => ig.id === value);
    const inVendorRootUser = isRootUser && vendorShipSelected && vendors.find((v) => v.id === value);
    if (group || inVendorRootUser) {
      if (inVendorRootUser) {
        if (withWorkload) onChange('assignedTo')(inVendorRootUser.id);
        const isVendorUser = allVendorUsers[inVendorRootUser.id]?.some((m) => m.id === assignedToMember);
        if (isVendorUser || isSelectGroup) {
          onChange('assignedToMember')(isSelectGroup ? null : assignedToMember, inVendorRootUser.id);
        }
      } else {
        if (withWorkload) onChange('assignedTo')(group.id);
        const isGroupMember = allGroupsMembers[group.id]?.some((m) => m.id === assignedToMember);
        if (isGroupMember || isSelectGroup) {
          onChange('assignedToMember')(isSelectGroup ? null : assignedToMember, group.id);
        }
      }
    } else {
      onChange('assignedTo')(value);
    }
  };

  const onSelectMember = (event, member, entity) => {
    if (event) {
      // not trigger antd select option since this is clicking in a child element of antd select option
      event.preventDefault();
      event.stopPropagation();
    }
    // no data change then do nothing
    if (
      member.id === assignedToMember &&
      (userIdGetAssignToByTypeName || taskToUpdate?.assignedToGroupId) === entity.id
    ) {
      return;
    }
    tempState.current.isSelectingMember = true;
    blurSelect();
    if (withWorkload) onChange('assignedTo')(entity.id);
    onChange('assignedToMember')(member.id, entity.id);
    setTimeout(() => {
      tempState.current.isSelectingMember = false;
    }, 100);
  };

  const filterHandler = (input, option) => {
    const label = option.props.label.toLowerCase();
    const value = option.props.value;
    const inputValue = input.trim().toLowerCase();
    const internalGroup = internalGroups.find((ig) => ig.id === value);
    const rootUserVendor = isRootUser && vendorShipSelected && vendors.find((v) => v.id === value);
    const memberSearched =
      (internalGroup &&
        allGroupsMembers[internalGroup.id]?.some((member) =>
          `${member.fullName} (${internalGroup.name})`.toLowerCase().includes(inputValue)
        )) ||
      (rootUserVendor &&
        allVendorUsers[rootUserVendor.id]?.some((member) =>
          `${member.fullName} (${rootUserVendor.name})`.toLowerCase().includes(inputValue)
        ));
    if (label.includes(inputValue)) {
      // not filter for optGroup Internal Groups because some group will visible with no member, that cause confuse
      return !(option.props.children?.length && option.props.label === 'Internal Groups');
    } else if (memberSearched) {
      return true;
    }
  };

  const AllGroupMemberMapping = (ig) => {
    return allGroupsMembers[ig.id]?.map((member, i) => {
      if (`${member.fullName} (${ig.name})`.toLowerCase().includes(searchKey)) {
        return (
          <AssigneeGroupMemberOptionContent
            key={member.id + ig.id + '_AssigneeGroupMemberOptionContent'}
            onClick={(event) => onSelectMember(event, member, ig)}
            className={
              assignedToMember === member.id &&
              (userIdGetAssignToByTypeName || taskToUpdate?.assignedToGroupId) === ig.id
                ? 'selected-member'
                : ''
            }
          >
            <AssigneeOptionContentName>{`${member.fullName} (${ig.name})`}</AssigneeOptionContentName>
            <AssigneeOptionContentWorkload className="workload">
              <UserWorkloadText user={member} />
            </AssigneeOptionContentWorkload>
          </AssigneeGroupMemberOptionContent>
        );
      } else return null;
    });
  };

  const InternalGroupWorkload = () => {
    return !!internalGroups?.length ? (
      <OptGroup label="Internal Groups">
        {internalGroups.map((ig, index) => (
          <Option
            key={ig.id + '_InternalGroupWorkload'}
            value={ig.id}
            label={ig.name}
            selectedLabel={
              <SelectedGroupLabel
                internalGroup={ig}
                allGroupsMembers={allGroupsMembers}
                withWorkload={withWorkload}
                assignedToMember={assignedToMember}
              />
            }
            style={{ padding: '0', marginBottom: internalGroups.length === index + 1 ? '10px' : '0' }}
          >
            <AssigneeGroupOption
              className={internalGroups.length === index + 1 ? 'seperate-item' : ''}
              ref={internalGroupRefs[index]}
            >
              <AssigneeOptionContent>
                <AssigneeOptionContentName>{ig.name}</AssigneeOptionContentName>
                <AssigneeOptionContentWorkload className="workload">
                  <UserWorkloadText user={ig} />
                </AssigneeOptionContentWorkload>
              </AssigneeOptionContent>
              <IconHitBoxArea
                onClick={(e) => {
                  onIconShowSubMenuClick(e, index);
                }}
              >
                <Icon id={ig.id} type={showGroupMembers[index] ? 'up' : 'down'} />
              </IconHitBoxArea>
            </AssigneeGroupOption>
            <AssigneeGroupMemberOption className={showGroupMembers[index] ? '' : 'd-none'}>
              {AllGroupMemberMapping(ig)}
            </AssigneeGroupMemberOption>
          </Option>
        ))}
      </OptGroup>
    ) : (
      NoGroup()
    );
  };

  const VendorWorkload = () => {
    return (
      flags.reconVendorManagement &&
      (!!vendors?.length ? (
        <OptGroup label="Vendors">
          {vendors.map((v, index) => (
            <Option
              key={v.id + '_VendorWorkload'}
              value={v.id}
              label={v.name}
              selectedLabel={<SelectedAssigneeVendorLabel vendor={v} />}
            >
              <AssigneeOptionContent className={vendors.length === index + 1 ? 'seperate-item' : ''}>
                <AssigneeOptionContentName>{v.name}</AssigneeOptionContentName>
                {/* US863265 - Product decided to hide the workload of Vendor Entities from Dealership users, until we are able to research how best to display this information to Dealership users */}
                {/* <AssigneeOptionContentWorkload className="workload">
                  {getUserWorkloadText(v)}
                </AssigneeOptionContentWorkload> */}
              </AssigneeOptionContent>
            </Option>
          ))}
        </OptGroup>
      ) : (
        NoVendor()
      ))
    );
  };

  useEffect(() => {
    if (isRootUser && vendorShipSelected && vendors && vendors.length && !isOpen) {
      const memberDetails = {};
      vendors.forEach((v) => {
        const groupMembers = [];
        v.vendorUsers.forEach((vendorUser) => {
          groupMembers.push(vendorUser.id ? vendorUser : { ...vendorUser, id: null });
        });

        memberDetails[v.id] = groupMembers;
      });

      setAllVendorUsers(memberDetails);
      setShowVendorUsers(vendors.map(() => false));
    }
  }, [vendors, isRootUser, vendorShipSelected]);

  const vendorWorkLoadForRootUser = useMemo(() => {
    if (isRootUser && vendorShipSelected && vendors) {
      return !!vendors.length ? (
        <OptGroup label="Vendors">
          {vendors.map((v, index) => (
            <Option
              key={v.id + '_vendorWorkLoadForRootUser'}
              value={v.id}
              label={v.name}
              selectedLabel={
                <SelectedVendorUserLabel
                  vendor={v}
                  allVendorUsers={allVendorUsers}
                  withWorkload={withWorkload}
                  assignedToMember={assignedToMember}
                />
              }
              style={{ padding: '0', marginBottom: vendors.length === index + 1 ? '10px' : '0' }}
            >
              <AssigneeGroupOption
                className={vendors.length === index + 1 ? 'seperate-item' : ''}
                ref={vendorRefs[index]}
              >
                <AssigneeOptionContent className={vendors.length === index + 1 ? 'seperate-item' : ''}>
                  <AssigneeOptionContentName>{v.name}</AssigneeOptionContentName>
                </AssigneeOptionContent>
                {v.vendorUsers && !!v.vendorUsers.length && (
                  <IconHitBoxArea
                    onClick={(e) => {
                      onIconShowSubMenuClick(e, index, true);
                    }}
                  >
                    <Icon id={v.id} type={showVendorUsers[index] ? 'up' : 'down'} />
                  </IconHitBoxArea>
                )}
              </AssigneeGroupOption>
              <AssigneeGroupMemberOption className={showVendorUsers[index] ? '' : 'd-none'}>
                {allVendorUsers[v.id]?.map((member, i) => {
                  if (`${member.fullName} (${v.name})`.toLowerCase().includes(searchKey)) {
                    return (
                      <AssigneeGroupMemberOptionContent
                        key={member.id + '_AssigneeGroupMemberOptionContent'}
                        onClick={(event) => onSelectMember(event, member, v)}
                        className={
                          assignedToMember === member.id &&
                          (userIdGetAssignToByTypeName || taskToUpdate?.assignedToGroupId) === v.id
                            ? 'selected-member'
                            : ''
                        }
                      >
                        <AssigneeOptionContentName>{`${member.fullName} (${v.name})`}</AssigneeOptionContentName>
                        <AssigneeOptionContentWorkload className="workload">
                          <UserWorkloadText user={member} />
                        </AssigneeOptionContentWorkload>
                      </AssigneeGroupMemberOptionContent>
                    );
                  } else {
                    return null;
                  }
                })}
              </AssigneeGroupMemberOption>
            </Option>
          ))}
        </OptGroup>
      ) : (
        NoVendor()
      );
    } else {
      return <></>;
    }
  }, [vendors, isRootUser, vendorShipSelected, currentId, showVendorUsers, searchKey, assignedToMember]);

  useEffect(() => {
    if (form?.setFieldsValue && isShowNoAssignee) {
      setTimeout(() => {
        let value = form.getFieldsValue();
        if (!value.assignedTo) {
          form.setFieldsValue({
            assignedTo: NO_ASSIGNEE
          });
        }
      });
    }
  }, [form?.setFieldsValue, isShowNoAssignee]);

  useEffect(() => {
    if (assignedToMember) {
      if (form) {
        form.validateFields(['assignedTo']);
      }
    }
  }, [assignedToMember]);

  const getGroupMemberContentClasses = (member, ig) =>
    assignedToMember === member.id && userIdGetAssignToByTypeName === ig.id ? 'selected-member' : '';

  const showUnassignedLabelText = flags.reconDisableExtechsPhase1 ? 'Unassigned' : 'No Assignee';

  if (withWorkload) {
    return (
      <StyledFormItem label="Assigned to">
        {getFieldDecorator('assignedTo', {
          rules: [
            {
              initialValue: NO_ASSIGNEE,
              required: assignedToOptional ? false : true,
              validator: (rule, value) => {
                if (!value && !assignedToOptional) {
                  rule.message = 'Assigned to is required';
                }
                if (
                  value &&
                  value !== NO_ASSIGNEE &&
                  internalGroups?.find((ig) => ig.id === value) &&
                  ['/inventory', '/tasks'].includes(window.location.pathname)
                ) {
                  //Case: Internal Group
                  const groupMembers = allGroupsMembers[value];
                  const selectedMember = groupMembers && groupMembers.find((m) => m.id === (assignedToMember || null));
                  if (!selectedMember) {
                    rule.message = 'The assignee is invalid!';
                  }
                }
                if (
                  value &&
                  value !== NO_ASSIGNEE &&
                  !users.find((u) => u.id === value) &&
                  !(internalGroups || []).find((ig) => ig.id === value) &&
                  !(vendors || []).find((v) => v.id === value) &&
                  !(
                    isRootUser &&
                    vendorShipSelected &&
                    ((vendors || []).find((v) => v.id === currentId)?.vendorUsers ?? []).find(
                      (vendorUser) => vendorUser.id === value
                    )
                  )
                ) {
                  rule.message = 'The assignee is invalid!';
                }
                if (rule.message) {
                  return Promise.reject(rule.message);
                }
                return Promise.resolve();
              }
            }
          ]
        })(
          usersFetchStatus !== apiStatusConstants.FAILED ? (
            <AssigneeSelect
              showSearch
              placeholder="Select an assignee"
              loading={!usersFetchStatus || usersFetchStatus === apiStatusConstants.IS_FETCHING}
              onChange={(v) => onAssigneeChange(v)}
              onSelect={(v) => onAssigneeChange(v, true)}
              filterOption={filterHandler}
              optionLabelProp="selectedLabel"
              key={JSON.stringify(taskToUpdate)}
              open={isOpen}
              onFocus={openMenuAndFocusInput}
              onBlur={onBlurHandler}
              onSearch={filterMember}
              ref={assigneeRef}
            >
              {isShowNoAssignee && (
                <Option
                  key={NO_ASSIGNEE}
                  value={NO_ASSIGNEE}
                  label={showUnassignedLabelText}
                  style={{ fontWeight: 'normal' }}
                  selectedLabel={
                    <StyledAssigneeLabel>
                      <StyledAssigneeLabelName>{showUnassignedLabelText}</StyledAssigneeLabelName>
                    </StyledAssigneeLabel>
                  }
                >
                  <AssigneeOptionContent className="seperate-item">
                    <AssigneeOptionContentName>{showUnassignedLabelText}</AssigneeOptionContentName>
                  </AssigneeOptionContent>
                </Option>
              )}
              {isRootUser && vendorShipSelected ? vendorWorkLoadForRootUser : VendorWorkload()}
              {InternalGroupWorkload()}
              {TechinicianWorkload(internalUsers, withWorkload, true, flags)}
            </AssigneeSelect>
          ) : (
            TextDanger('Something went wrong retrieving users information')
          )
        )}
      </StyledFormItem>
    );
  } else {
    return (
      <CommonSelect
        showSearch
        placeholder="Select an assignee"
        onChange={(v) => onAssigneeChange(v)}
        onSelect={(v) => onAssigneeChange(v, true)}
        filterOption={filterHandler}
        optionLabelProp="selectedLabel"
        key={{ taskToUpdate }}
        open={isOpen}
        defaultValue={firstAssignee?.assignedToGroupId || firstAssignee?.userId || NO_ASSIGNEE}
        loading={usersIsLoading}
        value={userIdGetAssignToByTypeName || NO_ASSIGNEE}
        onFocus={openMenuAndFocusInput}
        onBlur={onBlurHandler}
        onSearch={filterMember}
        ref={assigneeRef}
      >
        {isShowNoAssignee && (
          <Option
            key={NO_ASSIGNEE}
            value={NO_ASSIGNEE}
            label={showUnassignedLabelText}
            style={{ fontWeight: 'normal' }}
            selectedLabel={
              <StyledAssigneeLabel>
                <StyledAssigneeLabelName>{showUnassignedLabelText}</StyledAssigneeLabelName>
              </StyledAssigneeLabel>
            }
          >
            <AssigneeOptionContent className="seperate-item">
              <AssigneeOptionContentName>{showUnassignedLabelText}</AssigneeOptionContentName>
            </AssigneeOptionContent>
          </Option>
        )}

        {flags.reconVendorManagement &&
          (!!vendors?.length ? (
            <OptGroup label="Vendors">
              {vendors.map((v, index) => (
                <Option
                  key={v.id + '_OptionSelectedAssigneeVendor'}
                  value={v.id}
                  label={v.name}
                  selectedLabel={<SelectedAssigneeVendorLabel vendor={v} />}
                  className={vendors.length === index + 1 ? 'seperate-item' : ''}
                >
                  {v.name}
                </Option>
              ))}
            </OptGroup>
          ) : (
            NoVendor()
          ))}
        {!!internalGroups?.length ? (
          <OptGroup label="Internal Groups">
            {internalGroups.map((ig, index) => (
              <Option
                key={ig.id + '_OptionSelectedGroup'}
                value={ig.id}
                label={ig.name}
                selectedLabel={
                  <SelectedGroupLabel
                    internalGroup={ig}
                    allGroupsMembers={allGroupsMembers}
                    withWorkload={withWorkload}
                    assignedToMember={assignedToMember}
                  />
                }
                style={{ padding: '0', marginBottom: internalGroups.length === index + 1 ? '10px' : '0' }}
              >
                <AssigneeGroupOption
                  className={internalGroups.length === index + 1 ? 'seperate-item' : ''}
                  ref={internalGroupRefs[index]}
                >
                  <AssigneeOptionContent>
                    <AssigneeOptionContentName>{ig.name}</AssigneeOptionContentName>
                  </AssigneeOptionContent>
                  <IconHitBoxArea onClick={(e) => onIconShowSubMenuClick(e, index)}>
                    <Icon id={ig.id} type={showGroupMembers[index] ? 'up' : 'down'} />
                  </IconHitBoxArea>
                </AssigneeGroupOption>
                <AssigneeGroupMemberOption className={showGroupMembers[index] ? '' : 'd-none'}>
                  {allGroupsMembers[ig.id]?.map((member, i) =>
                    `${member.fullName} (${ig.name})`.toLowerCase().includes(searchKey) ? (
                      <AssigneeGroupMemberOptionContent
                        key={member.id + ig.id + '_AssigneeGroupMemberOptionContent'}
                        onClick={(event) => onSelectMember(event, member, ig)}
                        className={getGroupMemberContentClasses(member, ig)}
                      >
                        <AssigneeOptionContentName>{`${member.fullName} (${ig.name})`}</AssigneeOptionContentName>
                      </AssigneeGroupMemberOptionContent>
                    ) : null
                  )}
                </AssigneeGroupMemberOption>
              </Option>
            ))}
          </OptGroup>
        ) : (
          NoGroup()
        )}
        {TechinicianWorkload(internalUsers, withWorkload, false, flags)}
      </CommonSelect>
    );
  }
};

const TechinicianWorkload = (
  internalUsers = [],
  withWorkload = false,
  includeUserWorkloadText = false,
  flags = null
) => {
  return !!internalUsers?.length ? (
    <OptGroup label="Technicians">
      {internalUsers.map((u, index) => (
        <Option
          key={u.id + '_TechinicianWorkload'}
          value={u.id}
          label={u.fullName}
          selectedLabel={<SelectedAssigneeLabel assignee={u} withWorkload={withWorkload} />}
          disabled={
            flags?.reconDisableExtechsPhase1 && IsTaskAssigningToExternalTechnicians(internalUsers, u) ? 'disable' : ''
          }
        >
          <AssigneeOptionContent>
            <AssigneeOptionContentName>{u.fullName}</AssigneeOptionContentName>
            {includeUserWorkloadText && (
              <AssigneeOptionContentWorkload className="workload">
                <UserWorkloadText user={u} />
              </AssigneeOptionContentWorkload>
            )}
          </AssigneeOptionContent>
        </Option>
      ))}
    </OptGroup>
  ) : (
    NoTechnician()
  );
};

const UserWorkloadText = ({ user = {} }) => {
  if (!user.workload || user.workload.active + user.workload.pending === 0) return <span>No tasks</span>;
  return (
    <span>
      {user.workload.active} active, {user.workload.pending} pending
    </span>
  );
};

const SelectedAssigneeLabel = ({ assignee = {}, withWorkload = false }) => {
  return (
    <StyledAssigneeLabel>
      <StyledAssigneeLabelName>{assignee.fullName}</StyledAssigneeLabelName>
      {withWorkload && (
        <StyledAssigneeLabelWorkload>
          <UserWorkloadText user={assignee} />
        </StyledAssigneeLabelWorkload>
      )}
    </StyledAssigneeLabel>
  );
};

const SelectedVendorUserLabel = ({ vendor = {}, allVendorUsers = [], withWorkload = false, assignedToMember }) => {
  const groupMembers = allVendorUsers[vendor.id];
  const selectedMember = groupMembers && groupMembers.find((m) => m.id === (assignedToMember || null));
  const memberName = selectedMember ? selectedMember.fullName : 'Invalid Member';
  return (
    <StyledAssigneeLabel>
      <StyledAssigneeLabelName>
        {vendor.vendorUsers?.length > 0 ? `${memberName} (${vendor.name})` : vendor.name}
      </StyledAssigneeLabelName>
      {withWorkload && vendor.vendorUsers?.length > 0 && (
        <StyledAssigneeLabelWorkload>
          <UserWorkloadText user={selectedMember} />
        </StyledAssigneeLabelWorkload>
      )}
    </StyledAssigneeLabel>
  );
};

const SelectedGroupLabel = ({ internalGroup = {}, allGroupsMembers = [], withWorkload = false, assignedToMember }) => {
  const groupMembers = allGroupsMembers[internalGroup.id];
  const selectedMember = groupMembers && groupMembers.find((m) => m.id === (assignedToMember || null));
  const memberName = selectedMember ? selectedMember.fullName : 'Invalid Member';
  return (
    <StyledAssigneeLabel>
      <StyledAssigneeLabelName>{`${memberName} (${internalGroup.name})`}</StyledAssigneeLabelName>
      {withWorkload && (
        <StyledAssigneeLabelWorkload>
          <UserWorkloadText user={selectedMember} />
        </StyledAssigneeLabelWorkload>
      )}
    </StyledAssigneeLabel>
  );
};

const SelectedAssigneeVendorLabel = ({ vendor = {} }) => {
  return (
    <StyledAssigneeLabel>
      <StyledAssigneeLabelName>{vendor.name}</StyledAssigneeLabelName>
    </StyledAssigneeLabel>
  );
};

const NoVendor = () => {
  return (
    <OptGroup label="Vendors">
      <Option key={'empty-vendors'} value={'empty-vendors'} label={'empty-vendors'} disabled>
        <Empty image={null} imageStyle={{ height: 0 }} description={'No Vendors'} />
      </Option>
    </OptGroup>
  );
};

const NoGroup = () => {
  return (
    <OptGroup label="Internal Groups">
      <Option key={'empty-internal-groups'} value={'empty-internal-groups'} label={'empty-internal-groups'} disabled>
        <Empty image={null} imageStyle={{ height: 0 }} description={'No Internal Groups'} />
      </Option>
    </OptGroup>
  );
};

const NoTechnician = () => {
  return (
    <OptGroup label="Technicians">
      <Option key={'empty-technicians'} value={'empty-technicians'} label={'empty-technicians'} disabled>
        <Empty image={null} imageStyle={{ height: 0 }} description={'No Technicians'} />
      </Option>
    </OptGroup>
  );
};

const TextDanger = (text) => {
  return (
    <Text type="danger" ellipsis>
      {text}
    </Text>
  );
};

const StyledFormItem = styled(Form.Item)`
  .ant-form-item& {
    .ant-form-item-label {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      margin-bottom: 4px;
    }
    &[disabled] .ant-form-item-label {
      label {
        color: ${({ theme }) => theme.colors.darkGray};
      }
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const IconHitBoxArea = styled.div`
  display: flex;
  align-items: center;
  width: 10%;
  min-width: 32px;
  flex-direction: row-reverse;
  &:hover {
    color: ${({ theme }) => theme.colors.navy};
  }
`;

const AssigneeSelect = styled(CommonSelect)`
  .ant-select-selection-selected-value {
    max-width: none;
    width: 100%;
  }
`;

const AssigneeOptionContent = styled.div`
  .workload {
    color: ${({ theme }) => theme.colors.gray};
  }
  &:hover > .workload {
    color: ${({ theme }) => theme.colors.navy};
  }
`;

// padding left is big because the option need to have full width to have full background color change on hover/selected
const AssigneeGroupOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 12px 5px 20px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundGray};
  }
`;

const AssigneeGroupMemberOption = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

const AssigneeGroupMemberOptionContent = styled(AssigneeOptionContent)`
  font-weight: normal;
  padding: 5px 12px 5px 40px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundGray};
  }
  &.selected-member {
    background: ${({ theme }) => theme.colors.backgroundSelectedLightGray};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }
`;

const StyledAssigneeLabel = styled.div`
  display: flex;
  width: 100%;
`;

const AssigneeOptionContentName = styled.div``;
const AssigneeOptionContentWorkload = styled.div``;
const StyledAssigneeLabelName = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledAssigneeLabelWorkload = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  padding: 0px 12px;
  text-align: right;
  flex: 1 1 0px;
`;

export default withLDConsumer()(AssignedToSelect);
