import { createRef, useEffect, useState } from 'react';

export const useScrollIntoView = (inputArray = []) => {
  const [refs, setRefs] = useState([]);

  useEffect(() => {
    setRefs((currentRefs) =>
      Array(inputArray.length)
        .fill()
        .map((_, i) => currentRefs[i] || createRef())
    );
  }, [inputArray.length]);

  return refs;
};
