import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { vehiclesActions } from 'store/vehiclesStore';
import { documentsActions } from 'store/documentsStore';
import { Dropdown, Menu, Modal, Typography, Upload, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faPaperclip,
  faArchive,
  faMinusCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';
import { CopyOnClickText } from 'components';
import { MessageUpload } from '../../../documents';
import { detailContents, documentationContexts } from 'app-constants';
import { vdpActions } from 'store/vdpStore';
import { NEW } from '../../../../app-constants/reconPlanStatusTypes';
import { messagesActions } from 'store/messagesStore';

const { Text } = Typography;

const VehicleCardOverflowMenu = React.memo(({ isLoading = false, vehicle, hasInventoryEdit = true, flags }) => {
  const dispatch = useDispatch();

  const onArchive = () => {
    dispatch(vehiclesActions.setVehicleArchiveStatus(vehicle.id, true));
  };

  const onUnarchive = () => {
    dispatch(vehiclesActions.setVehicleArchiveStatus(vehicle.id, false));
  };

  const onExclude = () => {
    dispatch(vehiclesActions.setVehicleExcludeStatus(vehicle.id, true));
  };

  const onUnexclude = () => {
    dispatch(vehiclesActions.setVehicleExcludeStatus(vehicle.id, false));
  };

  const handleOpenDetailPage = () => {
    dispatch(vdpActions.getCommentsForVehicle(vehicle.id));
    dispatch(vdpActions.setModel(vehicle.id));
    dispatch(vdpActions.setContentType(detailContents.VEHICLE_DETAILS));
    dispatch(vdpActions.setActiveTab('2'));
  };

  const onClose = () => {
    dispatch(vdpActions.setContentType(''));
    dispatch(vdpActions.setModel(''));
    dispatch(vdpActions.setError(''));
  };

  const UploadCustomized = () => {
    let checkInvalid = false;
    let fileListUpload = [];
    const uploadAction = () => {
      const messageConvertFunc = (amount, stockNumber) => (
        <MessageUpload amount={amount} stockNumber={stockNumber} handleOpenDetailPage={handleOpenDetailPage} />
      );
      dispatch(
        documentsActions.uploadVehicleDocument(
          vehicle.id,
          fileListUpload,
          vehicle.stockNumber,
          messageConvertFunc,
          true
        )
      );
    };

    const openErrorMessage = (content) => {
      // Since the the error icon in the Mock is changed as the default icon in antd, this func is created for display customized error toast and not impact to the old one.
      dispatch(vdpActions.setModel(vehicle.id));
      dispatch(vdpActions.setContentType(''));
      dispatch(vdpActions.setError(vehicle.id));
      if (flags.reconToastMessageUpdate) {
        dispatch(messagesActions.notify('error', content, { onClose }));
      } else {
        message.error({
          content: <Text style={{ color: `${themeStyles.colors.blackErrorColor}` }}>{content}</Text>,
          duration: 4,
          icon: <StyledErrorIcon icon={faExclamationCircle} color={themeStyles.colors.red} className="icon fa-lg" />,
          onClose
        });
      }
    };

    const checkInValidFileType = (file) => {
      const acceptedFileType = documentationContexts.ACCEPTED_FILE_TYPES;
      const arr = file.name.split('.');
      return !acceptedFileType.includes(arr[arr.length - 1]);
    };

    const handleValidate = (_, fileList) => {
      if (checkInvalid) {
        return false;
      }
      const stockNumber = vehicle.stockNumber;
      const isInvalidAmount = fileList.length > documentationContexts.NUMBERS_DOCUMENTS_UPLOADED;
      const isInvalidSizeMinimum = !!fileList.find(
        (file) => file.size / 1024 / 1024 <= documentationContexts.MINIMUM_DOCUMENT_SIZE
      );
      const isInvalidSizeMaximum = !!fileList.find(
        (file) => file.size / 1024 / 1024 > documentationContexts.MAXIMUM_DOCUMENT_SIZE
      );
      const isInvalidFileType = !!fileList.find((file) => checkInValidFileType(file));
      if (isInvalidAmount) {
        openErrorMessage(`Error on ${stockNumber}. ${documentationContexts.MESSAGE_EXCEEDS_10_FILES_LIMIT}`);
        checkInvalid = true;
        return !isInvalidAmount;
      }
      if (isInvalidFileType) {
        openErrorMessage(`Error on ${stockNumber}. ${documentationContexts.MESSAGE_INCORRECT_FILE_TYPE}`);
        checkInvalid = true;
        return !isInvalidFileType;
      }
      if (isInvalidSizeMaximum) {
        openErrorMessage(`Error on ${stockNumber}. ${documentationContexts.MESSAGE_MAXIMUM_DOCUMENT_SIZE}`);
        checkInvalid = true;
        return !isInvalidSizeMaximum;
      }
      if (isInvalidSizeMinimum) {
        openErrorMessage(`Error on ${stockNumber}. ${documentationContexts.MESSAGE_MINIMUM_DOCUMENT_SIZE}`);
        checkInvalid = true;
        return !isInvalidSizeMinimum;
      }
      fileListUpload = fileList; // we will upload all the array fileList
      checkInvalid = true; // this make sure handleValidate() function only check for the first time.
      return true;
    };

    return (
      <Upload
        multiple={true}
        beforeUpload={handleValidate}
        showUploadList={false}
        action={uploadAction}
        accept={'.pdf,.docx,.doc'}
        customRequest={() => {}}
      >
        <StyledText
          onClick={() => {
            checkInvalid = false;
            fileListUpload = [];
          }}
          type="submit"
          style={{ margin: '0 auto' }}
        >
          <MenuItemIcon icon={faPaperclip} />
          Attach Files
        </StyledText>
      </Upload>
    );
  };

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'archive':
        Modal.confirm({
          title: (
            <>
              Archive <CopyOnClickText text={vehicle.stockNumber} />
            </>
          ),
          content: `This vehicle will be archived. Any pending or in progress tasks will be canceled and the current plan will be completed.`,
          okText: 'Archive',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk() {
            devLogger.log(`OK. Archiving ${vehicle.stockNumber}`);
            onArchive();
          },
          onCancel() {
            devLogger.log(`Canceled Archiving ${vehicle.stockNumber}`);
          }
        });
        break;
      case 'restore':
        Modal.confirm({
          title: (
            <>
              Unarchive <CopyOnClickText text={vehicle.stockNumber} />
            </>
          ),
          content: `This vehicle will be unarchived and placed back into active iRecon inventory.`,
          okText: 'Unarchive',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk() {
            devLogger.log(`OK. Unarchiving ${vehicle.stockNumber}`);
            onUnarchive();
          },
          onCancel() {
            devLogger.log(`Canceled Unarchiving ${vehicle.stockNumber}`);
          }
        });
        break;
      case 'exclude':
        Modal.confirm({
          title: (
            <>
              Exclude <CopyOnClickText text={vehicle.stockNumber} />
            </>
          ),
          content: `This vehicle will be excluded from your recon statistics. You will not be able to create a plan for this vehicle while it is excluded.`,
          okText: 'Exclude',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk() {
            devLogger.log(`OK. Excluding ${vehicle.stockNumber}`);
            onExclude();
          },
          onCancel() {
            devLogger.log(`Canceled Excluding ${vehicle.stockNumber}`);
          }
        });
        break;
      case 'unexclude':
        Modal.confirm({
          title: (
            <>
              Unexclude <CopyOnClickText text={vehicle.stockNumber} />
            </>
          ),
          content: `This vehicle will be included in your recon statistics and recon plans can be created for it.`,
          okText: 'Unexclude',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk() {
            devLogger.log(`OK. Unexcluding ${vehicle.stockNumber}`);
            onUnexclude();
          },
          onCancel() {
            devLogger.log(`Canceled Unexcluding ${vehicle.stockNumber}`);
          }
        });
        break;
      case 'attach':
        break;
      default:
        devLogger.error(new Error(`Unexpected menu key: ${key}`));
    }
  };

  return (
    <>
      <StyledMenuContainer>
        <Dropdown
          disabled={isLoading}
          overlay={
            <Menu onClick={handleMenuClick}>
              <Menu.Item key="attach" style={{ display: 'contents' }}>
                <UploadCustomized />
              </Menu.Item>
              {hasInventoryEdit && <Menu.Divider />}
              {hasInventoryEdit && vehicle.removedOn == null && (
                <Menu.Item key="archive">
                  <MenuItemIcon icon={faArchive} />
                  Archive
                </Menu.Item>
              )}
              {hasInventoryEdit && vehicle.removedOn && (
                <Menu.Item key="restore">
                  <MenuItemIcon icon={faArchive} />
                  Unarchive
                </Menu.Item>
              )}
              {hasInventoryEdit && vehicle.excludedOn == null && vehicle.reconStatus === NEW && (
                <Menu.Item key="exclude">
                  <MenuItemIcon icon={faMinusCircle} />
                  Exclude
                </Menu.Item>
              )}
              {hasInventoryEdit && vehicle.excludedOn != null && (
                <Menu.Item key="unexclude">
                  <MenuItemIcon icon={faMinusCircle} />
                  Unexclude
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <DropdownIconWrapper data-isloading={isLoading}>
            <StyledMoreIcon data-isloading={isLoading} icon={faEllipsisH} />
          </DropdownIconWrapper>
        </Dropdown>
      </StyledMenuContainer>
    </>
  );
});

const StyledMenuContainer = styled.div.attrs({
  className: 'center-content'
})`
  display: flex;
  cursor: default;
  padding-left: 8px;
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  color: ${(props) => (props['data-isloading'] ? props.theme.colors.lightGray : 'inherit')};
  height: 24px;
`;
const StyledText = styled(Text)`
  padding: 5px 12px;
  display: block;
`;
const StyledErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
`;

const MenuItemIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.navy};
`;
const DropdownIconWrapper = styled.div.attrs({ className: 'center-content' })`
  width: 30px;
  height: 24px;
  cursor: ${(props) => (props['data-isloading'] ? 'default' : 'pointer')};
`;

export default withLDConsumer()(VehicleCardOverflowMenu);
