import React from 'react';
import { Menu, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirstLetterOfString } from 'utils/stringUtils';
import { getOnClickHandler } from './helpers/functions';
import styled from 'styled-components';
import { faCheckCircle as regularCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { DEALER_INTERNAL_GROUP } from 'app-constants/groupTypes';
/**
 * notes on this section:
 * antd has a weird bug where you have to have one of its specified children components, be directly accessible to its parent component
 * for instance, Menu.Item needs to be rendered directly inside of a Menu component
 * it cannot be wrapped in a custom component or the classes applied to the Menu.Item by antd will not be correct
 *
 * that is why OverflowMenu, MenuItem are functions, NOT function components
 * I'm still able to separate out these pieces, but have to do it this way, not as a function component
 */

/**
 * OverflowMenu component
 * renders menu of options for edit, delete, complete a task on a task card
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {string} planSource - either RECON_PLAN or TEMPLATE_PLAN (set in ReconPlan and TemplatePlan components and passed into DragDropContext in those components)
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 * @param {array} menuItems - array of the different menu options to render - set in useGetMenuDropdownData hook in ./helpers/hooks
 */
const OverflowMenu = (
  task,
  planSource,
  taskAccessoryInfo,
  menuItems,
  needsApproval
) => {
  return (
    <StyledMenu>
      {menuItems.current.map((menuItem) =>
        MenuItemWithPassThrough(menuItem, task, planSource, taskAccessoryInfo, needsApproval)
      )}
    </StyledMenu>
  );
};

const MenuItemWithPassThrough = (
  menuItem,
  task,
  planSource,
  taskAccessoryInfo,
  needsApproval,
  reconVendorManagementFlag
) => {
  const onClickHandler = getOnClickHandler(menuItem.label, task, planSource, taskAccessoryInfo);
  const disableComplete = menuItem.label === 'complete' && needsApproval > 0;
  const optionCompleteOfInvalidTask = 
    menuItem.label === 'complete' &&
    !task?.assignedTo &&
    !task?.assignedToGroupId;

  // With case off toggle recon vendor management
  const disableCompleteWithVendorOff = !reconVendorManagementFlag && optionCompleteOfInvalidTask;
  // With case on toggle recon internal groups
  const disableCompleteHasUnassignedGroup =
    menuItem.label === 'complete' && !task?.assignedTo && task?.assignedToGroupId && task.assignedToGroupType === DEALER_INTERNAL_GROUP;
  const disableCompleteIcon =
    disableComplete ||
    (disableCompleteHasUnassignedGroup && !disableComplete) ||
    disableCompleteWithVendorOff;

  switch (menuItem.type) {
    case 'link':
      return (
        <Menu.Item
          key={menuItem.label}
          disabled={disableComplete || disableCompleteHasUnassignedGroup || disableCompleteWithVendorOff}
          onClick={onClickHandler}
          className={menuItem.borderAbove ? 'border-above' : ''}
        >
          <MenuItemIcon
            icon={disableCompleteIcon ? regularCheckCircle : menuItem.icon}
            disable-complete={disableCompleteIcon.toString()}
          />
          {capitalizeFirstLetterOfString(menuItem.label)}
          {disableComplete && <span className="disabled-extra">{'\nTask has pending line items'}</span>}
          {disableCompleteHasUnassignedGroup && !disableComplete && (
            <span className="disabled-extra">{'\nTask is not assigned to a group member'}</span>
          )}
        </Menu.Item>
      );
    case 'checkbox':
      return (
        <Menu.Item
          key={menuItem.label}
          disabled={menuItem.disabled}
          className={menuItem.borderAbove ? 'border-above' : ''}
        >
          <StyledCheckbox
            id={menuItem.label}
            checked={task.passthrough}
            onChange={(e) => onClickHandler(e)}
            disabled={menuItem.disabled}
          >
            {capitalizeFirstLetterOfString(menuItem.label)}
          </StyledCheckbox>
        </Menu.Item>
      );
    default:
      return null;
  }
};

export const MenuItemIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${(props) => props['disable-complete'] === 'false' && props.theme.colors.navy};
`;
export const StyledMenu = styled(Menu)`
  .ant-dropdown-menu& {
    .border-above {
      border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: 1px solid ${({ theme }) => theme.colors.borderGray};
    }
    .ant-checkbox-checked::after {
      border: unset;
    }
    .disabled-extra {
      font-size: ${({ theme }) => theme.fontSizes.ss};
      line-height: ${({ theme }) => theme.fontSizes.md};
    }
  }
  .ant-dropdown-menu-item-disabled {
    color: ${({ theme }) => theme.colors.titanium700} !important;
    white-space: pre-line;
    line-height: ${({ theme }) => theme.fontSizes.md};
  }
  .ant-checkbox-wrapper-disabled span {
    color: ${({ theme }) => theme.colors.titanium700} !important;
  }
`;
// This styling is to increase the clickable area of the checkbox to fill the entire Menu.Item area
const StyledCheckbox = styled(Checkbox)`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }
`;

export default OverflowMenu;
