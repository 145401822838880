import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducers } from 'store/rootReducer';
import { initSagas } from 'initSagas';
import createSagaMiddleware from 'redux-saga';
import { loadUser } from 'redux-oidc';
import { authTokenMiddleware } from 'store/authStore';
import { userManagerNewDomain, userManagerNewSignin } from 'utils/userManager';
import { IS_NEW_SIGNIN } from 'app-constants';

export const configureStore = () => {
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [authTokenMiddleware, sagaMiddleware];
  const store = createStore(rootReducers, composeEnhancers(applyMiddleware(...middlewares)));

  if (isNewSignin) {
    loadUser(store, userManagerNewSignin);
  } else {
    loadUser(store, userManagerNewDomain);
  }

  initSagas(sagaMiddleware);

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('../store/reducers', () => {
  //     const nextRootReducer = require('../reducers').default
  //     store.replaceReducer(nextRootReducer)
  //   })
  // }

  return store;
};
