import { navigate } from '@reach/router';
import { Alert, Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faExternalLinkAlt, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';

const GlobalAlert = ({ flagValue, onCloseGlobalAlert }) => {
  const [isShowModal, setShowModal] = useState(false);
  // Update new property {imagesInDetail} for web
  const {
    message,
    urlActionView,
    labelActionView,
    urlVideo,
    urlViewAnnouncement,
    detail,
    imagesInDetail,
    titleModal
  } = flagValue;
  const sizePixel16 = '16px';
  const sizePixel22 = '22px';
  const openAndCloseModal = () => {
    setShowModal(!isShowModal);
  };
  const navigateActionView = () => {
    setShowModal(!isShowModal);
    navigate(urlActionView);
  };

  return (
    <StyledContainerAlert className="global-alert-container">
      <StyledAlert
        message={
          <div className="alert-container">
            <span>{message}</span>
            &nbsp;
            <div>
              {detail && (
                <button type="button" className="link-button" onClick={openAndCloseModal}>
                  Learn More
                </button>
              )}
              {detail && urlActionView && labelActionView && <> | </>}
              {urlActionView && labelActionView && (
                <>
                  <button type="button" className="link-button" onClick={() => navigate(urlActionView)}>
                    {labelActionView}
                  </button>
                </>
              )}
            </div>
          </div>
        }
        closable
        // TODO: Set default to type is info. In future, we can use property type to set type alert
        type={'info'}
        onClose={onCloseGlobalAlert}
        showIcon={true}
        icon={
          <StyleIconInfo>
            <StyleAlertIcon
              icon={faInfoCircle}
              style={{ color: themeStyles.colors.white, width: sizePixel16, height: sizePixel16 }}
            />
          </StyleIconInfo>
        }
        closeText={
          <StyleIconClose>
            <StyleAlertIcon
              icon={faTimes}
              style={{ color: themeStyles.colors.darkGray, width: 20, height: 20 }}
              size="2x"
            />
          </StyleIconClose>
        }
      ></StyledAlert>
      <StyleModal
        visible={isShowModal}
        title={
          titleModal ? (
            <p style={{ marginBottom: '0px' }}>
              <StyleAlertIcon
                icon={faInfoCircle}
                style={{ marginRight: '16px', width: sizePixel22, height: sizePixel22 }}
              />
              <span>{titleModal}</span>
            </p>
          ) : (
            <></>
          )
        }
        cancelText="Close"
        onCancel={openAndCloseModal}
        destroyOnClose="true"
        okText={labelActionView}
        okType="danger"
        onOk={navigateActionView}
        closable={false}
        is-show-action-view={labelActionView && urlActionView ? 0 : 1}
        is-change-background-btn-close={labelActionView && urlActionView ? 1 : 0}
      >
        {imagesInDetail && <p className="box-detail-image" dangerouslySetInnerHTML={{ __html: imagesInDetail }}></p>}
        {detail && <p dangerouslySetInnerHTML={{ __html: detail }}></p>}
        {urlVideo && (
          <p>
            <a target={'_blank'} href={urlVideo} className="link-button">
              <StyleAlertIcon
                icon={faPlayCircle}
                style={{ marginRight: '4px', width: sizePixel16, height: sizePixel16 }}
              />
              Watch Video
            </a>
          </p>
        )}
        {urlViewAnnouncement && (
          <p style={{ marginBottom: '0px' }}>
            <a className="link-button" target={'_blank'} href={urlViewAnnouncement}>
              <StyleAlertIcon
                icon={faExternalLinkAlt}
                style={{ marginRight: '4px', width: sizePixel16, height: sizePixel16 }}
              />
              View Announcement
            </a>
          </p>
        )}
      </StyleModal>
    </StyledContainerAlert>
  );
};

const StyledContainerAlert = styled.div`
  width: 100%;
`;

const StyledAlert = styled(Alert)`
  animation: fade-in 1s;
  z-index: 11;
  line-height: 26px !important;
  height: 42px;
  display: flex;

  .ant-alert-icon {
    top: 14px;
  }
  .alert-container {
    display: inline-flex;
  }

  &.ant-alert-info {
    background-color: #ffffff;
    border: 1px solid #90939d;
    border-radius: 0px;
  }
  .ant-alert-message {
    padding-right: 20px;
    padding-left: 12px;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: inline;
      margin: 0;
      padding: 0;
      color: #2b6bdd;
    }
  }
  .ant-alert-close-icon {
    top: 0px;
    width: 42px;
    height: 42px;
    right: 0px;
  }
`;
const StyleModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 70px;
    padding-right: 32px;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: inline;
      margin: 0;
      padding: 0;
      color: #2b6bdd;
      height: 0;
      font-size: 16px;
    }
    max-height: 50vh;
    overflow-y: auto;
    .box-detail-image {
      display: flex;
      justify-content: center
      padding: 8px;
    }
  }
  .ant-modal-footer {
    border-top: unset;
    padding-top: 16px;
    padding-bottom: 32px;
    button:first-child {
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      height: 32px;
      padding: 0px 16px;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      text-shadow: none;
      color: ${(props) => (props['is-change-background-btn-close'] ? '#eb002e' : '#fff')};
      background-color: ${(props) => (props['is-change-background-btn-close'] ? '#ffffff' : '#eb002e')};
      border-color: #eb002e;
    }
    .ant-btn-danger {
      font-size: 16px;
    }
    button:nth-child(2) {
      display: ${(props) => (props['is-show-action-view'] ? 'none' : 'unset')};
    }
  }
  .ant-modal-header {
    border-bottom: unset;
    padding-bottom: unset;
    padding-left: 32px;
    padding-top: 32px;
  }
  .ant-modal-title {
    p {
      align-items: center;
      display: flex;
    }
  }
`;
export const StyleIconInfo = styled.div`
  background: #639cff;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  &.ant-alert-icon {
    left: 0;
    top: 0;
  }
`;
export const StyleIconClose = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
`;
export const StyleAlertIcon = styled(FontAwesomeIcon).attrs()``;
export default GlobalAlert;
