import React, { useState } from 'react';
import { Tooltip } from 'antd4';
import Paragraph from 'antd4/lib/typography/Paragraph';
import styled from 'styled-components';

const TruncatedTooltip = ({ children, ellipsis }) => {
  const [isTruncated, setIsTruncated] = useState(false);

  return (
    <StyledTooltip title={isTruncated ? children : null} getPopupContainer={(trigger) => trigger}>
      <Paragraph ellipsis={{ ...ellipsis, onEllipsis: setIsTruncated }} style={{ marginBottom: 0 }}>
        <>{children}</>
      </Paragraph>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    color: ${({ theme }) => theme.colors.navy};
    background-color: ${({ theme }) => theme.colors.white};
  }

  .ant-tooltip-arrow {
    border-bottom-color: ${({ theme }) => theme.colors.white};
  }

  .ant-tooltip-arrow::before {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default TruncatedTooltip;
