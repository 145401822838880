import React from 'react';
import { Divider, Typography } from 'antd';
import styled from 'styled-components';
import { CopyOnClickText } from 'components';
import { formatOdometer, dispositionTypes } from 'app-constants';

export const { Text } = Typography;
const DetailPageVehicleInfo = ({ vehicle, colors = [], reconProfitTime, showDealerName = false, isForTDP = false }) => (
  <>
    <StyledVehicleDescription reconProfitTime={reconProfitTime} isForTDP={isForTDP}>
      {
        <>
          <CopyOnClickText text={vehicle.stockNumber} />
          {vehicle.vin && (
            <>
              <StyledDivider />
              <CopyOnClickText text={vehicle.vin} />
            </>
          )}
          {/*eslint-disable-next-line eqeqeq*/}
          {vehicle.odometer != undefined && (
            <>
              <StyledDivider />
              {formatOdometer(vehicle)}
            </>
          )}
          {/*eslint-disable-next-line eqeqeq*/}
          {vehicle.age != undefined && (
            <>
              <StyledDivider />
              {`Age: ${vehicle.age < 1 ? '<1' : vehicle.age} days`}
            </>
          )}
          {!isForTDP && vehicle.disposition && vehicle.disposition !== 'RETAIL' && (
            <>
              <StyledDivider />
              {dispositionTypes[vehicle.disposition]}
            </>
          )}
        </>
      }
    </StyledVehicleDescription>
    {colors.length > 0 && (
      <div style={{ height: reconProfitTime ? 21 : 30 }}>
        <StyledVehicleDescription reconProfitTime={reconProfitTime} isForTDP={isForTDP}>
          Exterior: {colors[0]} {colors.length > 1 && <>({colors[1]})</>}
        </StyledVehicleDescription>
      </div>
    )}
    {showDealerName && !!vehicle.dealerName && <div>{vehicle.dealerName}</div>}
  </>
);

const StyledVehicleDescription = styled(Text).attrs({
  ellipsis: true
})`
  font-size: ${({ theme, reconProfitTime }) => (reconProfitTime ? theme.fontSizes.sm : theme.fontSizes.md)};
  line-height: ${({ reconProfitTime, isForTDP }) => {
    const lineHeightBasedOnTDP = isForTDP ? '24px' : '30px';
    return reconProfitTime ? '21px' : lineHeightBasedOnTDP;
  }};
  letter-spacing: 0px;
`;
const StyledDivider = styled(Divider).attrs({
  type: 'vertical'
})`
  .ant-divider& {
    background-color: #babcc1;
    margin: 0 5px;
  }
`;

export default DetailPageVehicleInfo;
