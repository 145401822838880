const features = {
  ADMINGENERAL: 'ADMINGENERAL',
  RECON: 'RECON',
  INVENTORY_VIEW: 'INVENTORY_VIEW',
  INVENTORY_EDIT: 'INVENTORY_EDIT',
  INVENTORY_COMMENT: 'INVENTORY_COMMENT',
  DEALER_SETTINGS: 'DEALER_SETTINGS',
  TASKS_COMMENT: 'TASKS_COMMENT',
  TASKS_VIEW_OWN: 'TASKS_VIEW_OWN',
  TASKS_VIEW_ANY: 'TASKS_VIEW_ANY',
  TASKS_WORK_OWN: 'TASKS_WORK_OWN',
  TASKS_WORK_ANY: 'TASKS_WORK_ANY',
  TASKS_EDIT: 'TASKS_EDIT',
  SYSTEM_VIEW: 'SYSTEM_VIEW',
  SYSTEM_MANAGE: 'SYSTEM_MANAGE',
  NOTIFICATIONS_FLR: 'NOTIFICATIONS_FLR',
  TASKS_APPROVE_LINE_ITEMS: 'TASKS_APPROVE_LINE_ITEMS',
  EXTERNAL_TECH_ONLY: 'EXTERNAL_TECH_ONLY',
  EXCEEDED_MAX_APPROVAL_TIME: 'EXCEEDED_MAX_APPROVAL_TIME',
  LINE_ITEMS_EDIT: 'LINE_ITEMS_EDIT',
  INTERNAL_TECH_ONLY: 'INTERNAL_TECH_ONLY',
  VENDOR_ADMIN: 'VENDOR_ADMIN',
  BASELINE: 'BASELINE'
};
export default features;
