import { Link, Router } from '@reach/router';
import { Menu, Typography } from 'antd';
import { features } from 'app-constants';
import { Authorize } from 'components/auth';
import { PageNotFound } from 'components/layout/nav';
import { InventorySettings, Notifications, PlanTemplates, TaskTypes } from 'components/layout/settings';
import { useFeaturesFilter } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import styled from 'styled-components';

// import { TaskTypes, PlanTemplates, InventorySettings, Notifications, Catalog } from 'components/layout/settings';
const { Text } = Typography;

const settingsPages = [
  {
    label: 'General Settings',
    path: 'inventory',
    getComponent: (props) => <InventorySettings {...props} />,
    feature: features.DEALER_SETTINGS
  },
  {
    label: 'Task Templates',
    path: 'tasktypes',
    getComponent: (props) => <TaskTypes {...props} />,
    feature: features.DEALER_SETTINGS
  },
  {
    label: 'Plan Templates',
    path: 'plantemplates',
    getComponent: (props) => <PlanTemplates {...props} />,
    feature: features.DEALER_SETTINGS
  },
  {
    label: 'Notifications',
    path: 'notifications',
    getComponent: (props) => <Notifications {...props} />,
    feature: features.RECON
  }
  // {
  //   label: 'Line Item Catalog',
  //   path: 'catalog',
  //   getComponent: (props) => (<Catalog {...props} />),
  //   feature: features.RECON
  // }
];

const settingsPropsSelector = createSelector(authSelector, (authStore) => ({
  isBridgeUser: authStore.isBridgeUser
}));

const Settings = ({ location, flags }) => {
  const { pathname } = location;
  const path = pathname.split('/').pop();
  const reachablePages = useFeaturesFilter(settingsPages, (p) => p.feature);
  const { isBridgeUser } = useSelector(settingsPropsSelector);

  return (
    <StyledPage is-bridge-user={isBridgeUser}>
      <StyledHeader>
        <Text>Settings</Text>
      </StyledHeader>
      <StyledMenu selectedKeys={[path]}>
        {reachablePages.map(({ path, label }) => (
          <StyledMenuItem key={path}>
            <Link to={path}>{label}</Link>
          </StyledMenuItem>
        ))}
      </StyledMenu>

      <StyledSettingsPage is-bridge-user={isBridgeUser}>
        <Router>
          {settingsPages.map((p) => (
            <Authorize key={p.path} path={p.path} feature={p.feature}>
              {p.getComponent({ default: true, flags: flags })}
            </Authorize>
          ))}
          <PageNotFound default />
        </Router>
      </StyledSettingsPage>
    </StyledPage>
  );
};

//#region Styled Components
const StyledPage = styled.div`
  ${(props) => (props['is-bridge-user'] ? 'min-height: calc(100vh - 210px);' : 'min-height: calc(100vh - 175px);')}
  background-color: #F2F4F5;
  padding: 0 5%;
`;
const StyledHeader = styled.div`
  & > .ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes.title};
  }

  margin-top: 18px;
  margin-left: 2px;
  display: flex;
`;
export const StyledSettingsPage = styled.div`
  ${(props) => (props['is-bridge-user'] ? '' : 'min-height: calc(100vh - 298px);')}
  background-color: #F2F4F5;
  margin-bottom: 20px;
  margin-top: 24px;
`;
const StyledMenu = styled(Menu).attrs({
  mode: 'horizontal'
})`
  .ant-menu& {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.md};
    background-color: #f2f4f5;
  }
  .ant-menu-horizontal& {
    line-height: 30px;
    border-bottom: 0;

    > .ant-menu-item {
      padding-left: 0;
      padding-right: 40px;
    }

    .ant-menu-item-active {
      border-bottom: 0 !important;
    }
  }
  .ant-menu-horizontal& > li {
    border-bottom: 0;
  }
`;
const StyledMenuItem = styled(Menu.Item)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  .ant-menu-item-selected& {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }
  .ant-menu-item-selected& > a {
    border-bottom: 2px solid ${({ theme }) => theme.colors.red};
  }
`;
//#endregion

export default Settings;
