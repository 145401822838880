import React from 'react';
import { Redirect } from '@reach/router';
import { features } from 'app-constants';
import { useFeatures } from 'hooks';
import { useSelector } from 'react-redux';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const Home = () => {
  const [hasDashboard, hasTasks, hasInventory] = useFeatures(
    features.TASKS_EDIT,
    features.TASKS_WORK_OWN,
    features.INVENTORY_VIEW
  );
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  if (typeof hasDashboard === 'undefined') {
    return null;
  }

  let homePath;

  if (hasDashboard && !(isRootUser && vendorShipSelected)) {
    homePath = '/dashboard';
  } else if (hasTasks) {
    homePath = '/tasks';
  } else if (hasInventory) {
    homePath = '/inventory';
  } else {
    homePath = null;
  }

  if (homePath) {
    return <Redirect to={homePath} noThrow />;
  } else {
    return null;
  }
};

export default Home;
