import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Icon } from 'antd';
import LineItemFormWrapper from 'components/layout/inventory/reconPlan/TaskForms/LineItemForm';
import { BodyBaseBold, BodyXSmallBold } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { lineItemsActions } from 'store/lineItemsStore';
import styled from 'styled-components';
import { formatterNumberUSD } from 'utils/numberUtils.js';
import { lineItemCatalogActions } from 'store/lineItemCatalogStore';
import useVendorRole from 'hooks/useVendorRole';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { Panel } = Collapse;

const lineItemSelector = createSelector(
  (state) => state.lineItems.needApproval,
  (state) => state.lineItems.approved,
  (state) => state.lineItems.declined,
  (needApproval, approved, declined) => ({
    needApprovalList: needApproval,
    totalCostNeedApproval:
      needApproval.length > 0
        ? needApproval
            .map((item) => (item.totalCost ? Number(item.totalCost) : 0))
            .reduce((accumulator, current) => {
              return accumulator + current;
            })
        : 0,
    approvedList: approved,
    totalCostApproved:
      approved.length > 0
        ? approved
            .map((item) => (item.totalCost ? Number(item.totalCost) : 0))
            .reduce((accumulator, current) => {
              return accumulator + current;
            })
        : 0,
    declinedList: declined,
    totalCostDeclined:
      declined.length > 0
        ? declined
            .map((item) => (item.totalCost ? Number(item.totalCost) : 0))
            .reduce((accumulator, current) => {
              return accumulator + current;
            })
        : 0
  })
);

const AddLineItemForm = ({
  task,
  setIsDirty,
  contentType,
  hasPermissionApprovedDecline = false,
  isForViewingCompletedTaskTDP = false,
  hasLineItemsEditRole = true,
  flags
}) => {
  const {
    needApprovalList,
    totalCostNeedApproval,
    approvedList,
    totalCostApproved,
    declinedList,
    totalCostDeclined
  } = useSelector(lineItemSelector);
  const dispatch = useDispatch();

  const hideDeclineSection = false;
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  const { isVendor } = useVendorRole(flags.reconVendorManagement);
  const { vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const checkIfVendor = isVendor && !vendorShipSelected;

  useEffect(() => {
    if (flags.reconLineItemCatalog && !isForViewingCompletedTaskTDP && (hasLineItemsEditRole || checkIfVendor)) {
      dispatch(lineItemCatalogActions.getLineItemCatalog(isVendor ? task?.vehicle?.dealerId : dealerId));
    }
  }, [dealerId, isVendor, task?.vehicle?.dealerId, isForViewingCompletedTaskTDP]);
  // handle editable line item in complete task form
  useEffect(() => {
    if (isForViewingCompletedTaskTDP) {
      dispatch(
        lineItemsActions.setLineItems(
          [...needApprovalList, ...approvedList, ...declinedList].map((lineItem) => ({
            ...lineItem,
            isEditing: false
          }))
        )
      );
    }
  }, [isForViewingCompletedTaskTDP]);

  let lineItemFieldClassName = 'line-item-field';
  let partsFieldClassName = 'parts-field';
  let laborFieldClassName = 'labor-field';
  let totalFieldClassName = 'total-field';
  if (
    (isForViewingCompletedTaskTDP && (!hasPermissionApprovedDecline || approvedList.length === 0)) ||
    (!hasLineItemsEditRole && !checkIfVendor)
  ) {
    lineItemFieldClassName += ' line-item-field-no-action';
    partsFieldClassName += ' parts-field-no-action';
    laborFieldClassName += ' labor-field-no-action';
    totalFieldClassName += ' total-field-no-action';
  }

  return (
    <AddLineItemContainer>
      {!isForViewingCompletedTaskTDP && (hasLineItemsEditRole || checkIfVendor) && (
        <Collapse
          accordion={true}
          bordered={false}
          defaultActiveKey={['need-approval']}
          expandIcon={({ isActive }) => (
            <Icon rotate={isActive ? 90 : 0} viewBox="0 0 1024 1024">
              <FontAwesomeIcon icon={faChevronRight} />
            </Icon>
          )}
        >
          <Panel
            header={
              <HeaderPanel>
                <BodyBaseBold>
                  {flags.reconApproveButton ? 'Pending Approval' : 'Needs Approval'} (
                  {needApprovalList.filter((item) => !item.isAdding).length})
                </BodyBaseBold>
                <BodyBaseBold>${formatterNumberUSD(totalCostNeedApproval)}</BodyBaseBold>
              </HeaderPanel>
            }
            key={'need-approval'}
          >
            {needApprovalList.length === 0 ? (
              <LineItemFormWrapper
                task={task}
                setIsDirty={setIsDirty}
                listName={'needApproval'}
                hasPermissionApprovedDecline={hasPermissionApprovedDecline}
                contentType={contentType}
              />
            ) : (
              <>
                <LineItemsWrapper>
                  <div className={lineItemFieldClassName}>
                    <span className="required-char">*</span> <BodyXSmallBold>LINE ITEM</BodyXSmallBold>
                  </div>
                  <div className={partsFieldClassName}>
                    <BodyXSmallBold>PARTS</BodyXSmallBold>
                  </div>
                  <div className={laborFieldClassName}>
                    <BodyXSmallBold>LABOR</BodyXSmallBold>
                  </div>
                  <div className={totalFieldClassName}>
                    {hasPermissionApprovedDecline ? null : <span className="required-char">*&nbsp;</span>}
                    <BodyXSmallBold>TOTAL</BodyXSmallBold>
                  </div>
                  <div className="action-field"></div>
                </LineItemsWrapper>
                <LineItemFormWrapper
                  hasPermissionApprovedDecline={hasPermissionApprovedDecline}
                  task={task}
                  setIsDirty={setIsDirty}
                  listName={'needApproval'}
                  hideDeclineSection={hideDeclineSection}
                  contentType={contentType}
                />
              </>
            )}
          </Panel>
        </Collapse>
      )}

      {((!isForViewingCompletedTaskTDP && (hasLineItemsEditRole || checkIfVendor)) || approvedList.length > 0) && (
        <Collapse
          accordion={true}
          bordered={false}
          defaultActiveKey={['approved']}
          expandIcon={({ isActive }) => (
            <Icon rotate={isActive ? 90 : 0} viewBox="0 0 1024 1024">
              <FontAwesomeIcon icon={faChevronRight} />
            </Icon>
          )}
        >
          <Panel
            header={
              <HeaderPanel>
                <BodyBaseBold>Approved ({approvedList.filter((item) => !item.isAdding).length})</BodyBaseBold>
                <BodyBaseBold>${formatterNumberUSD(totalCostApproved)}</BodyBaseBold>
              </HeaderPanel>
            }
            key={'approved'}
          >
            {approvedList.length === 0 ? (
              <NoLineItems>No Approved Line Items</NoLineItems>
            ) : (
              <>
                <LineItemsWrapper>
                  <div className={lineItemFieldClassName}>
                    <span className="required-char">*</span> <BodyXSmallBold>LINE ITEM</BodyXSmallBold>
                  </div>
                  <div className={partsFieldClassName}>
                    <BodyXSmallBold>PARTS</BodyXSmallBold>
                  </div>
                  <div className={laborFieldClassName}>
                    <BodyXSmallBold>LABOR</BodyXSmallBold>
                  </div>
                  <div className={totalFieldClassName}>
                    {hasPermissionApprovedDecline && !isForViewingCompletedTaskTDP ? null : (
                      <span className="required-char">*&nbsp;</span>
                    )}
                    <BodyXSmallBold>TOTAL</BodyXSmallBold>
                  </div>
                  {!isForViewingCompletedTaskTDP && hasLineItemsEditRole && <div className="action-field"></div>}
                </LineItemsWrapper>
                <LineItemFormWrapper
                  hasPermissionApprovedDecline={hasPermissionApprovedDecline}
                  task={task}
                  setIsDirty={setIsDirty}
                  listName={'approved'}
                  hideDeclineSection={hideDeclineSection}
                  isForViewingCompletedTaskTDP={isForViewingCompletedTaskTDP}
                  isNeedApprovalSection={false}
                  hasLineItemsEditRole={hasLineItemsEditRole}
                  contentType={contentType}
                />
              </>
            )}
          </Panel>
        </Collapse>
      )}

      {!hideDeclineSection &&
        (!isForViewingCompletedTaskTDP || declinedList.length > 0) &&
        (hasLineItemsEditRole || checkIfVendor) && (
          <Collapse
            accordion={true}
            bordered={false}
            defaultActiveKey={['declined']}
            expandIcon={({ isActive }) => (
              <Icon rotate={isActive ? 90 : 0} viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faChevronRight} />
              </Icon>
            )}
          >
            <Panel
              header={
                <HeaderPanel>
                  <BodyBaseBold>Declined ({declinedList.filter((item) => !item.isAdding).length})</BodyBaseBold>
                  <BodyBaseBold>${formatterNumberUSD(totalCostDeclined)}</BodyBaseBold>
                </HeaderPanel>
              }
              key={'declined'}
            >
              {declinedList.length === 0 ? (
                <NoLineItems>No Declined Line Items</NoLineItems>
              ) : (
                <>
                  <LineItemsWrapper>
                    <div className={lineItemFieldClassName}>
                      <span className="required-char">*</span> <BodyXSmallBold>LINE ITEM</BodyXSmallBold>
                    </div>
                    <div className={partsFieldClassName}>
                      <BodyXSmallBold>PARTS</BodyXSmallBold>
                    </div>
                    <div className={laborFieldClassName}>
                      <BodyXSmallBold>LABOR</BodyXSmallBold>
                    </div>
                    <div className={totalFieldClassName}>
                      {hasPermissionApprovedDecline && !isForViewingCompletedTaskTDP ? null : (
                        <span className="required-char">*&nbsp;</span>
                      )}
                      <BodyXSmallBold>TOTAL</BodyXSmallBold>
                    </div>
                    {!isForViewingCompletedTaskTDP && <div className="action-field"></div>}
                  </LineItemsWrapper>
                  <LineItemFormWrapper
                    hasPermissionApprovedDecline={hasPermissionApprovedDecline}
                    task={task}
                    setIsDirty={setIsDirty}
                    listName={'declined'}
                    isForViewingCompletedTaskTDP={isForViewingCompletedTaskTDP}
                    isNeedApprovalSection={false}
                    contentType={contentType}
                  />
                </>
              )}
            </Panel>
          </Collapse>
        )}
    </AddLineItemContainer>
  );
};

const HeaderPanel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 5px;
`;

const AddLineItemContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
  .ant-collapse {
    background-color: initial;

    .ant-collapse-item {
      border-bottom: initial;

      .ant-collapse-content > .ant-collapse-content-box {
        padding-bottom: 0;
        padding-left: 24px;
        padding-right: 0;
        margin-bottom: 4px;
      }

      .ant-collapse-header {
        padding-left: 24px;
        padding-right: 0px;

        .ant-collapse-arrow {
          left: 0;
          font-size: ${({ theme }) => theme.fontSizes.md};
          padding-bottom: 5px;
        }
      }
    }
  }
`;

const NoLineItems = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 16px;
  color: ${({ theme }) => theme.colors.darkGray};
  display: block;
  width: 100%;
  text-align: center;
`;

const LineItemsWrapper = styled.div`
  width: 100%;
  display: flex;

  span {
    color: ${({ theme }) => theme.colors.darkGray};

    &.required-char {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  .line-item-field {
    width: 31%;
    padding-right: 5px;
  }

  .parts-field,
  .labor-field,
  .total-field {
    width: 15%;
    padding-right: 5px;
  }

  .action-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 24%;
  }

  .line-item-field-no-action {
    width: 31%;
  }

  .parts-field-no-action,
  .labor-field-no-action,
  .total-field-no-action {
    width: 23%;
  }
`;

export default withLDConsumer()(AddLineItemForm);
