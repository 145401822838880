import { useContext, useRef } from 'react';
import { faCheckCircle, faEdit, faTrashAlt, faComment, faTasks } from '@fortawesome/free-solid-svg-icons';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { TaskContext } from '../../helpers/context';
import { isTaskPassThroughEditable } from 'components/TaskGroupsDnd/helpers/functions';
import { RECON_PLAN, TEMPLATE_PLAN } from 'app-constants/planTypes';

const EDIT_MENU_ITEM = {
  label: 'edit',
  type: 'link',
  icon: faEdit
};
const PASS_THROUGH_MENU_ITEM = {
  label: 'pass through',
  type: 'checkbox',
  borderAbove: true,
  icon: null
};
const COMPLETE_MENU_ITEM = {
  label: 'complete',
  type: 'link',
  icon: faCheckCircle
};
const DELETE_MENU_ITEM = {
  label: 'delete',
  type: 'link',
  borderAbove: true,
  icon: faTrashAlt
};
const MESSAGE_MENU_ITEM = {
  label: 'message',
  type: 'link',
  icon: faComment
};

const LINE_ITEMS_MENU_ITEM = {
  label: 'Line Items',
  type: 'link',
  icon: faTasks
};

export const getMenuItemsForReconTask = (
  planStarted,
  canCompleteTask,
  isCompletedTask,
  isDeclinedTask,
  canDelete,
  hideMessageOverflowMenuItem,
  hideLineItemsOverflowMenuItem,
  hidePassthroughOverflowMenuItem,
  canEditPassThrough,
  isAddingTask,
  contentType
) => {
  /**
   *  function getMenuItemsForReconTask is required to determine the appropriate menu options that a user should see
   *  a user is only able to see the menu for a task if the user has edit task permissions
   *  so at the point that the menu renders, they will have at least edit
   */
  const itemsArray = [EDIT_MENU_ITEM];

  if (contentType && !isCompletedTask && !hideLineItemsOverflowMenuItem) {
    itemsArray.push(LINE_ITEMS_MENU_ITEM);
  }

  if (planStarted && !isAddingTask && !hideMessageOverflowMenuItem) {
    itemsArray.push(MESSAGE_MENU_ITEM);
  }

  if (planStarted && canCompleteTask && !isDeclinedTask && !isAddingTask) {
    itemsArray.push(COMPLETE_MENU_ITEM);
  }

  !isCompletedTask &&
    !hidePassthroughOverflowMenuItem &&
    itemsArray.push(Object.assign(PASS_THROUGH_MENU_ITEM, { disabled: !canEditPassThrough })); //Push after Complete option to match mockups

  if (canDelete) {
    const deleteItem = hidePassthroughOverflowMenuItem
      ? Object.assign(DELETE_MENU_ITEM, { borderAbove: false }) //Don't put a border above Delete menu item if not displaying pass through checkbox
      : DELETE_MENU_ITEM;
    itemsArray.push(deleteItem);
  }

  return itemsArray;
};

//hook to facilitate setting up the menu items that should show and their click handlers
export const useGetMenuDropdownData = (flags) => {
  /** different data depending on the type of task because they show up different places
   *  and users have to have different permissions to edit tasks in recon plans
   *  by the time they are able to edit tasks in a template plan, their permissions have already been checked and they have been
   *  granted access to the template plan to edit it
   *  differing data is all contained in the taskContextData - pass all of this down
   */

  /**
   * @constant {string} planSource - string that specifies which plan type user has opened (RECON_PLAN or TEMPLATE_PLAN). set in either ReconPlan or TemplatePlan and passed into DragDropContext as part of dndData
   * @constant {boolean} planStarted - boolean that is set in ReconPlan and passed into dndData; true - user has started the plan that this task is a part of; false - user hasn't started the plan that this task is a part of. planStarted is needed because we can't complete recon tasks until after the plan has started
   */
  const { planSource, planStarted, ...taskContextData } = useContext(TaskContext);
  // menuItems contains the different user options for editing, completing or deleting a task
  // set as a ref because we don't need to cause a re-render when the value changes, we don't want to constantly set new values for it either
  const menuItems = useRef([]);
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const { task, isInProgressTaskGroup, contentType } = taskContextData;
  const canEditPassThrough = isTaskPassThroughEditable(task, isInProgressTaskGroup, flags);
  const {
    canCompleteTask,
    isCompletedTask,
    isDeclinedTask,
    canDelete,
    hideMessageOverflowMenuItem,
    hideLineItemsOverflowMenuItem,
    hidePassthroughOverflowMenuItem,
    isAddingTask
  } = taskContextData;

  if (planSource === RECON_PLAN) {
    /** edit task is part of this menu for recon plan by default (menu will not show up, unless the user can edit a task)
     * but complete and decline are only conditional on certain permissions and states determined by the three parameters passed in below
     */
    // we should only complete an assigned task
    menuItems.current = getMenuItemsForReconTask(
      planStarted,
      canCompleteTask,
      isCompletedTask,
      isDeclinedTask,
      canDelete,
      hideMessageOverflowMenuItem,
      hideLineItemsOverflowMenuItem,
      hidePassthroughOverflowMenuItem,
      canEditPassThrough,
      isAddingTask,
      contentType
    );

    if (isHQ && menuItems?.current && Array.isArray(menuItems.current)) {
      menuItems.current = menuItems.current.filter((menuItem) => menuItem.label === 'message');
    }

    return {
      ...taskContextData,
      planSource,
      menuItems
    };
  } else if (planSource === TEMPLATE_PLAN) {
    // template plan tasks always have both edit and delete options - user features won't let a user access the plan templates settings unless they have the authorization to make plan templates
    menuItems.current = [
      EDIT_MENU_ITEM,
      Object.assign(PASS_THROUGH_MENU_ITEM, { disabled: !canEditPassThrough }),
      DELETE_MENU_ITEM
    ];

    if (!hideLineItemsOverflowMenuItem) {
      menuItems.current.splice(1, 0, LINE_ITEMS_MENU_ITEM);
    }

    return {
      ...taskContextData,
      planSource,
      menuItems
    };
  }

  return {};
};
