//button with two clickable parts
//currently only used for creating a recon plan for a vehicle that doesn't currently have a recon plan
//and for quick applying a recon plan template
import React, { useState } from 'react';
import { Button, Dropdown, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { elementRotate } from 'styles/mixins';
import { DropdownList } from '.';
import { apiStatusConstants } from 'app-constants';

const {Text} = Typography;

const SplitButtonWithDropdown = ({
    type='danger',
    dropdownCTAIcon=faAngleDown,
    className='',
    btnStyles={},
    children='Create Recon Plan',
    dropdownList={
        data:[],
        fetchStatus: apiStatusConstants.IS_FETCHING
    },
    onClick=() => {},
    listItemOnClick=() => {},
    disabled=false,
    additionalStyles={},
    noListDataMessage=''
}) => {
    const [dropdownVisible, toggleDropdownVisible] = useState(false);
    return (
        <div 
            style={{
                display: 'inline-block',
                cursor: 'pointer',
                ...additionalStyles
            }}
        >
            <LeftPart 
                type={type}
                className={className}
                style={{...btnStyles}}
                disabled={disabled}
                onClick={onClick}>
                <span>{children}</span>
            </LeftPart>
            {
                dropdownList.data.length > 0 || disabled
                ? (
                    <Dropdown 
                        trigger={['click']}
                        disabled={disabled}
                        overlay={ <DropdownList dropdownList={dropdownList} onClick={listItemOnClick}/> }
                        onVisibleChange={ visible => toggleDropdownVisible(visible) }
                    >
                        <RightPart 
                            type={type}
                            className={className}
                            style={{...btnStyles}}
                            data-dropdownvisible={dropdownVisible}
                        >
                            <FontAwesomeIcon icon={dropdownCTAIcon}/>
                        </RightPart>
                    </Dropdown>
                )
                : (
                    <Tooltip
                        placement='right'
                        overlayClassName='create-recon-plan-from-template-tooltip'
                        title={<Text style={{color: 'red'}}>{noListDataMessage}</Text>}
                    >
                        <RightPart 
                            type={type}
                            className={className}
                            style={{...btnStyles}}
                            data-dropdownvisible={dropdownVisible}
                        >
                            <FontAwesomeIcon icon={dropdownCTAIcon}/>
                        </RightPart>
                    </Tooltip>
                )
            }
        </div>
    );
}

const LeftPart = styled(Button)`
    .ant-btn&,
    .ant-btn[disabled]& {
        border-radius: 4px 0 0 4px;
        border-right: 1px solid ${({theme, disabled}) => disabled ? theme.colors.primaryButtonDisabledBorderColor : theme.colors.white};
        padding-right: 0;
        margin-right: 0;
        font-size: ${({ theme }) => theme.fontSizes.sm};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        &:hover {
            border-right: 1px solid ${({theme, disabled}) => disabled ? theme.colors.primaryButtonDisabledBorderColor : theme.colors.white};
        }
        span {
            padding-right: 15px;            
        }
    }
`;

const RightPart = styled(Button)`
    .ant-btn&,
    .ant-btn[disabled]& {
        padding-left: 8px;
        padding-right: 8px;
        border-left: none;
        border-radius: 0 4px 4px 0;
        font-size: ${({ theme }) => theme.fontSizes.sm};
        &.ant-dropdown-open {
            border-color: ${({theme}) => theme.colors.primaryButtonBackgroundColor} !important;
            &:hover,
            &:focus,
            &:active {
                background-color: ${({theme}) => theme.colors.primaryButtonBackgroundColor};
            }
        }
        svg {
            width: 16px;            
        }
    }
    ${elementRotate('fa-angle-down','data-dropdownvisible',180,0.3)}
`;

export default SplitButtonWithDropdown;