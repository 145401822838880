import React from 'react';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BasicLayeredTaskIcon } from 'components/layout/tasks/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

/**
 * Displays a check-mark with a circle around it as an icon.
 *
 * @param {Task} task The task related to the icon. This will be used to provide
 *  hover content when available. If not provided, hover content will be disabled.
 * @param {string} color The center check-mark will be set to this color. Defaults to gray.
 * @param {string} colorSecondary The outer circle will be set to this color. Defaults to the color parameter.
 * @param {*} other This encompasses any other props that might be wanted on the underlying antd components
 */
const TaskCompletedIcon = ({
  task,
  color,
  colorSecondary,
  outerSize = '26px',
  iconSize = '1x',
  customContent,
  isShowCompletedIcon,
  flags,
  ...other
}) => {
  const CustomContent = (props) => {
    return (
      <StyledCircleIcon outerSize={outerSize} {...props}>
        <StyledCheckIcon icon={faCheck} data-color={color} size={iconSize}></StyledCheckIcon>
      </StyledCircleIcon>
    );
  };

  return (
    <BasicLayeredTaskIcon
      task={task}
      customContent={
        flags.reconTaskCollapse && !isShowCompletedIcon ? (
          customContent || null
        ) : (
          <CustomContent color={color} colorsecondary={colorSecondary} {...other} />
        )
      }
      backgroundIcon={flags.reconTaskCollapse && null}
      {...other}
    />
  );
};

const StyledCircleIcon = styled.div`
  border: 1px solid ${(props) => props['colorsecondary'] || props['color'] || props.theme.colors.iconGray};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: ${({ outerSize }) => outerSize};
  height: ${({ outerSize }) => outerSize};
`;

const StyledCheckIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props['data-color'] || props.theme.colors.iconGray};
  display: flex;
  align-self: center;
`;

export default withLDConsumer()(TaskCompletedIcon);
