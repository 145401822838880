export const NAME_OF_LIST_LINE_ITEMS = {
    needApproval: 'needApproval',
    approved: 'approved',
    declined: 'declined'
}

export const OPERATION_LINE_ITEM = {
    ADD: 'ADD',
    REPLACE: 'REPLACE',
    REMOVE: 'REMOVE'
}

export const LINE_ITEMS_CHANGE_STATUS = {
    INITIAL: 'INITIAL',
    ONLY_CHANGE_LINE_ITEM: 'ONLY_CHANGE_LINE_ITEM',
    NOT_ONLY_CHANGE_LINE_ITEM: 'NOT_ONLY_CHANGE_LINE_ITEM'
}

export const LOCATION_MESSAGE_LINE_ITEM = 'LOCATION_MESSAGE_LINE_ITEM';

export const LIMIT_COST = 100000;
export const LIMIT_TOTAL_COST = 200000;