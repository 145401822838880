import React from 'react';
import { Menu } from 'antd';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { apiStatusConstants } from 'app-constants';

const DropdownList = ({
  dropdownList,
  onClick = () => {}
}) => (
  <StyledMenu
      style={{
          boxShadow: '0 6px 3px rgba(0,0,0,0.2)',
          width: 'auto',
          paddingTop: '16px',
          paddingBottom: '16px',
          maxHeight: '300px',
          overflowY: 'auto'
      }}
  >
      {
          dropdownList.fetchStatus === apiStatusConstants.IS_FETCHING 
          ? <Skeleton count={4} height={16}/>
          : (
              dropdownList?.data?.map((item) => (
                  <StyledMenuItem 
                      key={item?.id}
                      onClick={() => onClick(item)}
                  >{item?.name}</StyledMenuItem>
              ))
          )
      }
  </StyledMenu>
);

const StyledMenu = styled(Menu)`
    .ant-menu-item-active {
        &:hover,
        &:active,
        &:focus {
            font-weight: ${({theme}) => theme.fontWeights.medium};
            background-color: ${({theme}) => theme.colors.backgroundGray};
        }
    }
`;

const StyledMenuItem = styled(Menu.Item)`
    color: ${({theme}) => theme.colors.navy};
    .ant-menu-vertical .ant-menu-item& {
        font-size: ${({theme}) => theme.fontSizes.md};
    }
`;

export default DropdownList;