import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CoBrowseScreenShare = () => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  // Need to use a ref to access state changes inside event listener callback
  // as event listener is only registered on initial render
  const isVisibleRef = React.useRef(isVisible);

  const config = {
    webchatAppUrl: process.env.REACT_APP_COBROWSE_WEBCHAT_APP_URL,
    webchatServiceUrl: process.env.REACT_APP_COBROWSE_WEBCHAT_SERVICE_URL,
    webchatDeploymentKey: process.env.REACT_APP_COBROWSE_WEBCHAT_DEPLOYMENT_KEY,
    orgGuid: process.env.REACT_APP_COBROWSE_ORG_GUID,
    orgName: process.env.REACT_APP_COBROWSE_ORG_NAME,
    logLevel: process.env.REACT_APP_COBROWSE_LOG_LEVEL,
    locale: 'en',
    // CSS class applied to the security key window, but not its contents
    cssClass: 'webchat-frame',
    // Custom style applied to the security key window, but not its contents
    css: {
      width: '100%',
      height: '100%'
    },
    // Optional CSS for styling security key form (Only needed if you wanted to publicly expose a style sheet for Genesys to consume)
    //'contentCssUrl': 'http://example.com/style.css',
    // Element id where the security key form will appear.
    containerEl: 'cobrowse-security-form',
    // Set to true to display security key form. Do not include for co-browse with web chat or for PureConnect co-browse with voice.
    promptForSecurityKey: true
  };

  useEffect(() => {
    if (!scriptsLoaded) {
      PageScripts.forEach((s) => {
        const script = document.createElement('script');
        script.src = s;
        script.async = true;
        document.body.appendChild(script);
      });

      setScriptsLoaded(true);

      const body = document.getElementsByTagName('body')[0];
      body.addEventListener('keydown', openModal);
      return () => window.removeEventListener('keydown', openModal);
    }
  }, []);

  const closeModal = () => {
    setIsVisible(false);
    isVisibleRef.current = false;
  };

  const openModal = async (event) => {
    const isModalVisible = isVisibleRef.current;

    if (!isModalVisible) {
      if (event.ctrlKey && event.altKey && event.key === '1') {
        setIsVisible(true);
        isVisibleRef.current = true;

        try {
          await window.ININ.cobrowse.create(config);
          console.log('Successfully created CoBrowse session');
        } catch (err) {
          console.error('Error encountered when creating CoBrowse session', err);
        }
      }
    }
  };

  return isVisible ? (
    <StyledCoBrowse role="cobrowse-screen-share">
      <StyledModal>
        <StyledHeader>Initiate a screen share with vAuto Support</StyledHeader>
        <StyledBody>
          <div id="cobrowse-security-form"></div>
        </StyledBody>
        <StyledFooter>
          <button onClick={closeModal}>Close</button>
        </StyledFooter>
      </StyledModal>
    </StyledCoBrowse>
  ) : null;
};

const PageScripts = [
  'https://apps.mypurecloud.com/webchat/jsapi-v1.js',
  'https://cobrowse.mypurecloud.com/cobrowse-jsapi-v1.js?deploymentKey=jDNoP4W6CbTYkpAY3fj4JZ1TOxFgMpOd'
];

const StyledCoBrowse = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColors.transparentGray};
  z-index: 1001;
`;

const StyledModal = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 5px ${({ theme }) => theme.colors.gray};
  border-radius: 3px;
  width: 600px;
  padding: 10px;
`;

const StyledHeader = styled.div`
  height: 40px;
  font-size: larger;
`;

const StyledBody = styled.div`
  padding: 10px 0;
  font-size: larger;
  #cobrowse-security-form iframe {
    height: 285px !important;
    border: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 3px;
  }
`;

const StyledFooter = styled.div`
  display: grid;
  button {
    padding: 8px;
    font-size: larger;
    background-color: ${({ theme }) => theme.colors.red};
    border: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
  }
  button:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.darkRed};
  }
`;

export default CoBrowseScreenShare;
