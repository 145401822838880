import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { DrawerContainer } from 'components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { CommonHeaderLabel } from 'components/styledComponents';
import { TemplateTask } from '.';
import { Drawer } from 'antd';
import { WIDTH_RECON_PLAN_DRAWERS, apiStatusConstants } from 'app-constants';
import { taskTypesActions } from 'store/taskTypesStore';
import { lineItemsActions } from 'store/lineItemsStore';
import { messageSelector, messagesActions } from 'store/messagesStore';
import { getHoursAndDaysFromString, toIsoDurationString } from 'utils/dateTimeUtils';
import { DEALER_INTERNAL_GROUP, VENDOR } from 'app-constants/groupTypes';

const taskTypeDrawerPropsSelector = createSelector(
  (state) => state.users,
  (state) => state.taskCategories.categoriesAll,
  (state) => state.dealers.current.data.id,
  messageSelector,
  (usersContainer, taskCategoriesContainer, dealerId, message) => ({
    taskCategories: taskCategoriesContainer.data,
    users: usersContainer.assigneeData?.internalUsers,
    vendors: usersContainer.assigneeData?.vendors,
    internalGroups: usersContainer.assigneeData?.internalGroups,
    usersFetchStatus: usersContainer.fetchStatus,
    dealerId,
    message
  })
);

const TaskTypeDrawer = ({ visible, taskType, onDelete, onClose, isNameTaken, lastSequence, flags }) => {
  const { users, taskCategories, internalGroups, vendors, usersFetchStatus, dealerId, message } = useSelector(
    taskTypeDrawerPropsSelector
  );
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [showDiscardButton, setShowDiscardButton] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [formValues, setFormValues] = useState({});
  const [formIsDirty, setFormIsDirty] = useState(false);

  useEffect(() => {
    if (taskType && vendors) {
      //This checks to see if the currently assigned vendor exists and is associated with this dealer
      //if not remove it from the task type assignee list
      const firstAssignee = (taskType.assignees && taskType.assignees[0]) || {};
      let assignedTo;
      if (firstAssignee.assignedToGroupType === VENDOR && flags.reconVendorManagement) {
        if (vendors.find((v) => v.id === firstAssignee.assignedToGroupId)) {
          assignedTo = firstAssignee.assignedToGroupId;
        } else {
          setFormIsDirty(true);
        }
      } else {
        assignedTo = firstAssignee.assignedToGroupId || firstAssignee.userId || undefined;
      }
      setFormValues({
        ...formValues,
        assignedTo,
        assignedToMember:
          firstAssignee.assignedToGroupId && firstAssignee.assignedToGroupType === DEALER_INTERNAL_GROUP
            ? firstAssignee.userId
            : null
      });
    }
  }, [taskType, vendors, flags.reconVendorManagement]);

  useEffect(() => {
    if (taskType) {
      const { days: goalDays, hours: goalHours } = getHoursAndDaysFromString(taskType.goalThreshold, 5, 0);
      const { days: maxDays, hours: maxHours } = getHoursAndDaysFromString(taskType.maxThreshold, 7, 0);
      const initial = {
        name: taskType.name,
        estimatedCost: taskType.cost,
        goalDays,
        goalHours,
        maxDays,
        maxHours,
        description: taskType.description,
        categoryId: taskType.categoryId
      };
      if (!flags.reconVendorManagement) {
        const firstAssignee = (taskType.assignees && taskType.assignees[0]) || {};
        initial.assignedTo = firstAssignee.assignedToGroupId || firstAssignee.userId || undefined;
        initial.assignedToMember =
          firstAssignee.assignedToGroupId && firstAssignee.assignedToGroupType === DEALER_INTERNAL_GROUP
            ? firstAssignee.userId
            : null;
      }
      setInitialValues(initial);
      setFormValues(initial);
    }
  }, [taskType, flags.reconVendorManagement]);

  useEffect(() => {
    // Here I'm latching on to the messages to determine if the save has succeeded or not, which I'm using to determine whether to close the drawer or not.
    // Longer term, latching on to the messages may not be viable if messages start being used more asynchronously
    if (message) {
      const { messageType } = message;
      if (messageType === 'loading') {
        setIsSaving(true);
      } else {
        setIsSaving(false);
      }
      if (messageType === 'success') {
        dispatch(messagesActions.clear());
        closeDrawer();
        setFormIsDirty(false); // reset
      }
    }
  }, [message]);

  const isAcceptableInteger = (value) => {
    const reg = /^\d*$/;
    return (!isNaN(value) && reg.test(value)) || value === '';
  };

  const onChange = (name) => (value, assignedTo) => {
    const newFormValues = { ...formValues };

    if (name === 'assignedTo') {
      newFormValues.assignedToMember = null;
    }

    if (name === 'assignedToMember') {
      newFormValues.assignedTo = assignedTo;
    }

    if (['goalDays', 'goalHours', 'maxDays', 'maxHours'].includes(name)) {
      if (isAcceptableInteger(value)) {
        newFormValues[name] = value === '' ? null : parseInt(value);
      }
    } else {
      newFormValues[name] = value === '' ? null : value;
    }

    const isFormIsDirty = Object.entries(newFormValues).some((x) => initialValues[x[0]] !== x[1]);
    setFormIsDirty(isFormIsDirty);
    setFormValues(newFormValues);
  };

  const onSave = (listLineItemToUpdate) => {
    const loadingMessage = 'Saving task template...';
    const successMessage = 'Task template saved!';
    const errorMessage = 'An error occurred while saving the task template';
    const assignedTo = formValues.assignedTo !== 'NO_ASSIGNEE' ? formValues.assignedTo : null;

    let assignees = assignedTo
      ? [
          {
            userId: assignedTo,
            priority: 1
          }
        ]
      : [];

    const isAssignedToInternalGroup = !!(internalGroups && internalGroups.find((ig) => ig.id === assignedTo));
    assignees =
      assignedTo || formValues.assignedToGroupId
        ? [
            {
              assignedToGroupType: isAssignedToInternalGroup ? DEALER_INTERNAL_GROUP : null,
              assignedToGroupId: isAssignedToInternalGroup ? formValues.assignedTo : null,
              userId: isAssignedToInternalGroup ? formValues.assignedToMember : assignedTo,
              priority: 1
            }
          ]
        : [];

    if (flags.reconVendorManagement) {
      const isAssignedToVendor = !!(vendors && vendors.find((v) => v.id === assignedTo));
      if (isAssignedToVendor) {
        assignees = [
          {
            assignedToGroupType: VENDOR,
            assignedToGroupId: assignedTo,
            userId: null,
            priority: 1
          }
        ];
      }
    }

    const newTaskType = {
      ...taskType,
      name: formValues.name,
      assignees,
      cost: formValues.estimatedCost,
      categoryId: formValues.categoryId,
      goalThreshold: toIsoDurationString({ days: formValues.goalDays, hours: formValues.goalHours }),
      maxThreshold: toIsoDurationString({ days: formValues.maxDays, hours: formValues.maxHours }),
      description: formValues.description
    };

    if (!newTaskType.id) {
      newTaskType.sequence = lastSequence + 1;
      dispatch(
        taskTypesActions.add(dealerId, newTaskType, loadingMessage, successMessage, errorMessage, listLineItemToUpdate)
      );
    } else {
      dispatch(taskTypesActions.update(newTaskType, loadingMessage, successMessage, errorMessage));
    }
  };

  const handleCanClose = () => {
    if (!isSaving) {
      if (!showDiscardButton && formIsDirty) {
        // When it isDirty and on the first time the user clicks X, we show the discard button
        setShowDiscardButton(true);
      } else {
        // If the user clicks the top right X button again (2 clicks), it will force discard and close
        setFormIsDirty(false); // reset
        closeDrawer();
      }
    }
  };

  const closeDrawer = () => {
    dispatch(lineItemsActions.resetLineItem());
    setShowDiscardButton(false);
    onClose();
  };

  const getHeaderLabel = () => (!taskType || !taskType.id ? 'New Task' : 'Edit Task');

  return (
    <Drawer
      closable={false}
      destroyOnClose={true}
      placement="right"
      width={WIDTH_RECON_PLAN_DRAWERS}
      visible={visible}
      onClose={handleCanClose}
      bodyStyle={{ padding: '0' }}
    >
      <DrawerContainer
        headerLabel={<CommonHeaderLabel>{getHeaderLabel()}</CommonHeaderLabel>}
        onClose={handleCanClose}
        contentStyle={{ marginTop: '24px' }}
      >
        <TemplateTask
          taskType={taskType}
          formValues={formValues}
          isNameTaken={isNameTaken}
          onChange={onChange}
          isSaving={isSaving}
          formIsDirty={formIsDirty}
          onSave={onSave}
          onDelete={() => onDelete(taskType)}
          users={users}
          taskCategories={taskCategories}
          usersIsLoading={!usersFetchStatus || usersFetchStatus === apiStatusConstants.IS_FETCHING}
          showDiscardButton={showDiscardButton}
          closeDrawer={closeDrawer}
          setFormIsDirty={setFormIsDirty}
          handleCanClose={handleCanClose}
          isAcceptableInteger={isAcceptableInteger}
          internalGroups={internalGroups}
          vendors={vendors}
        />
      </DrawerContainer>
    </Drawer>
  );
};

export default withLDConsumer()(TaskTypeDrawer);
