import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'store/authStore';
import { Typography, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonStrongText, GridTemplate, GridArea } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { TaskDetailsHeader } from './TaskDetailsHeader';
import { TaskInfo } from './TaskInfo';
import TaskActivity from './TaskActivity';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { usersActions } from '../../../../../store/usersStore';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../../../google-analytics';

export const { Text } = Typography;

export const TaskDetails = ({ task, handleCloseDrawer, onForm, setOnForm, contentType, activeTab, documents }) => {
  const { user } = useSelector(authSelector);
  const { isRootUser, currentId, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const dispatch = useDispatch();

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.TASK_DETAILS_VIEWED);

  useEffect(() => {
    if (currentId && isRootUser && vendorShipSelected) {
      if (task.vehicle.dealerId) {
        dispatch(usersActions.getAssigneeData(task.vehicle.dealerId, false, true));
        dispatch(usersActions.getData(task.vehicle.dealerId, false, true));
      }
    }
  }, [currentId, isRootUser, vendorShipSelected]);

  return (
    <StyledTaskDetailsContainer>
      <StyledHeaderContainer>
        <TaskDetailsHeader task={task} />
        <StyledCloseButtonContainer onClick={handleCloseDrawer}>
          <StyledIcon icon={faTimes} />
        </StyledCloseButtonContainer>
      </StyledHeaderContainer>
      <StyledTaskInfoContainer>
        <TaskInfo
          task={task}
          user={user}
          onForm={onForm}
          setOnForm={setOnForm}
          contentType={contentType}
          handleCloseDrawer={handleCloseDrawer}
        />
      </StyledTaskInfoContainer>
      {/* DON'T REMOVE, JUST HIDING TEMPORARILY UNTIL THIS AREA GETS IMPLEMENTED */}
      {/* <StyledPhotosContainer>
      <Photos task={task} />
    </StyledPhotosContainer> */}
      <StyledCommunicationsContainer>
        <TaskActivity task={task} activeTab={activeTab} documents={documents} />
      </StyledCommunicationsContainer>
    </StyledTaskDetailsContainer>
  );
};

// DON'T REMOVE, JUST HIDING TEMPORARILY UNTIL THIS AREA GETS IMPLEMENTED
// const Photos = ({ task }) => {
//   return (
//     <StyledColumnContainer>
//       <StyledPhotosLabel>
//         Photos
//       </StyledPhotosLabel>
//       <StyledPlaceholderContainer>
//       </StyledPlaceholderContainer>
//     </StyledColumnContainer>
//   )
// }

//#region Styled Components
//#region Shared Components
export const StyledInputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundGray};
`;
//#endregion
//#region Styled Components for TaskDetails
export const StyledTaskDetailsContainer = styled(
  GridTemplate(
    `'header header'
   'taskInfo communications'`,
    '178px minmax(0px, 1fr)',
    'minmax(0px, 1fr) minmax(0px, 1fr)'
  )
)`
  height: 100vh;
`;
export const StyledHeaderContainer = styled(GridArea('header', 1, 1, 1, 2))`
  border-bottom: ${({ theme }) => theme.borders.thinSolidGray};
  display: flex;
  background: ${({ theme }) => theme.colors.backgroundGray};
`;
export const StyledCloseButtonContainer = styled.div.attrs({
  className: 'center-content'
})`
  margin: 15px 10px 0px 5px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 100;
  border-radius: 3px;
  transition: 0.2s;
  :hover {
    background-color: #eaeded;
  }
`;
export const StyledTaskInfoContainer = styled(GridArea('taskInfo', 2, 1))`
  border-right: ${({ theme }) => theme.borders.thinSolidGray};
  border-bottom: ${({ theme }) => theme.borders.thinSolidGray};
  background-color: ${({ theme }) => theme.colors.white};
`;
export const StyledCommunicationsContainer = styled(GridArea('communications', 2, 2, 2))`
  background-color: ${({ theme }) => theme.colors.white};
`;
// eslint-disable-next-line
export const StyledPhotosContainer = styled(GridArea('photos', 3, 1))`
  border-right: ${({ theme }) => theme.borders.thinSolidGray};
  background-color: ${({ theme }) => theme.colors.white};
`;
export const StyledVehicleDescription = styled(Text).attrs({
  ellipsis: true
})`
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 'normal';
  letter-spacing: 0px;
`;
export const StyledDivider = styled(Divider).attrs({
  type: 'vertical'
})`
  .ant-divider& {
    background-color: #babcc1;
    margin: 0 5px;
  }
`;
export const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: calc(50vh);
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
//#endregion

//#region Styled Components for Photos
// eslint-disable-next-line
export const StyledPhotosLabel = styled(CommonStrongText)`
  line-height: 13px;
  margin-bottom: 29px;
`;
// eslint-disable-next-line
export const StyledPlaceholderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
//#endregion
//#endregion Styled Components
export default withLDConsumer()(TaskDetails);
