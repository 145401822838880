import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { clone } from 'lodash';
import { PropTypes } from 'prop-types';

import StepSegment from './StepSegment';
import ContentSegment from './ContentSegment/ContentSegment';
import ContentSegmentTDP from './ContentSegment/ContentSegmentTDP';
import { taskProgressLabels, contentsSegmentTDP } from 'app-constants';
import { getTaskProgress } from 'components/layout/tasks/common/taskUtils';
import { GridTemplate, GridArea } from 'components/styledComponents';
import { authSelector } from 'store/authStore';
import { useDragDropContext } from 'utils/contexts';
import { displayDurationSummary, toMomentDuration } from 'utils/dateTimeUtils';
import { TaskContext } from './helpers/context';
import {
  addSelectedBackgroundColorClass,
  removeSelectedBackgroundColorClass,
  addSelectedBackgroundColorClassConditional,
  removeSelectedBackgroundColorClassConditional
} from 'utils/domUtils';
import { COMPLETED, DECLINED, IN_PROGRESS, PENDING } from 'app-constants/taskStatusTypes';
import { RECON_PLAN, TEMPLATE_PLAN } from 'app-constants/planTypes';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const useCreateRefs = () => ({ taskRef: useRef(null), barRef: useRef(null) });
const scrollIntoView = (task) => task.scrollIntoView({ block: 'center', behavior: 'smooth' });

const useScrollToTask = (planSource, scrollToTask, isSaving) => {
  const { taskRef, barRef } = useCreateRefs();

  const scrollToTaskAndHighlight = () => {
    scrollIntoView(taskRef?.current);
    setTimeout(() => {
      if (refsSetForTask) {
        // this does some box shadow animation
        addSelectedBackgroundColorClass(taskRef?.current?.classList);
        addSelectedBackgroundColorClassToReconPlanTask(barRef?.current?.classList);

        setTimeout(() => {
          if (refsSetForTask) {
            removeSelectedBackgroundColorClass(taskRef?.current?.classList);
            removeSelectedBackgroundColorClassToReconPlanTask(barRef?.current?.classList);
          }
        }, 500);
      }
    }, 1000);
  };

  const shouldScreenScrollToTask = scrollToTask && !isSaving && !!taskRef?.current;
  useEffect(() => {
    if (shouldScreenScrollToTask) {
      scrollToTaskAndHighlight();
    }
  }, [shouldScreenScrollToTask]);

  const isReconPlanTask = planSource === RECON_PLAN;
  const addSelectedBackgroundColorClassToReconPlanTask = addSelectedBackgroundColorClassConditional(isReconPlanTask);
  const removeSelectedBackgroundColorClassToReconPlanTask =
    removeSelectedBackgroundColorClassConditional(isReconPlanTask);

  const refsSetForTask = isReconPlanTask ? taskRef?.current && barRef?.current : taskRef?.current;

  return { taskRef, barRef };
};

const getReconPlanData = (
  task,
  user,
  { reconPlanFeatures, isSaving, planStarted, isStandaloneTask },
  isInProgressTaskGroup
) => {
  let hasEditTasks = false,
    hasWorkOwnTasks = false,
    hasWorkAnyTask = false;
  if (reconPlanFeatures && reconPlanFeatures.length === 3) {
    hasEditTasks = reconPlanFeatures[0];
    hasWorkOwnTasks = reconPlanFeatures[1];
    hasWorkAnyTask = reconPlanFeatures[2];
  }
  //need to get editable, planStarted
  const canEditCompleted = hasWorkAnyTask || hasEditTasks;
  const taskIsCompleted = task.status === COMPLETED || task.transientType === 'complete';

  //Singular tasks are still tecnically part of a group
  const isNotInProgressStep = planStarted && !isInProgressTaskGroup;
  const userId = !user?.id && user?.user?.id ? user.user.id : user.id;

  const canCompleteTask =
    (canEditCompleted || (hasWorkOwnTasks && task.assignedTo === userId)) && // has permission
    !taskIsCompleted && // hasn't already been completed
    isInProgressTaskGroup; // is next in sequence (tasks may have been reordered, so can't trust IN_PROGRESS)
  const canDelete = hasEditTasks && !taskIsCompleted;
  const canDragOrUpdate = canDelete && !isStandaloneTask;
  const taskProgress = getTaskProgress(task, true);
  const timeInTask = displayDurationSummary(toMomentDuration({ seconds: task.secondsInTask }), true, true);
  const showBar = task.status !== COMPLETED && planStarted;

  const isCompletedTask = Boolean(task.status === COMPLETED);
  const isDeclinedTask = Boolean(task.declinedOn);

  const overflowMenuDisabled = (!canDelete && !canEditCompleted) || isSaving;
  const isAddingTask = task.isAddingTask;

  return {
    canCompleteTask,
    canDelete,
    canDragOrUpdate,
    taskProgress,
    timeInTask,
    showBar,
    isCompletedTask,
    isDeclinedTask,
    overflowMenuDisabled,
    isNotInProgressStep,
    isAddingTask
  };
};

const reconTaskSelector = createSelector(
  (state) => state.users,
  authSelector,
  (usersContainer, authSelector) => {
    return {
      users: usersContainer.data,
      user: authSelector
    };
  }
);

const useSetTaskFunctionalityAndData = (task, index, isInProgressTaskGroup, isTask, expand) => {
  // both task types will have context data
  const dndData = useDragDropContext();
  const { taskRef, barRef } = useScrollToTask(dndData.planSource, dndData.scrollToId === task.id, dndData.isSaving);

  //create mutable copy of dnd context data
  const dndContextData = clone(dndData);

  const wrappedOnTaskChange = (callback) => (task) => {
    task = { ...task };
    ['modelType', 'parentGroupId', 'parentSequence', 'group', 'isTask'].forEach((key) => delete task[key]);
    callback(task);
  };

  dndContextData.onDelete = wrappedOnTaskChange(dndContextData.onTaskChange('delete'));
  dndContextData.onUpdate = wrappedOnTaskChange(dndContextData.onTaskChange('update'));
  dndContextData.onComplete = wrappedOnTaskChange(dndContextData.onTaskChange('complete'));

  /* recon task functions - only want to use these on a recon task
   * can't put this after the return on planSource being TEMPLATE_PLAN because then some hooks would only be called conditionally
   * against rules of hooks */
  const { users, user } = useSelector(dndContextData.planSource ? reconTaskSelector : null);

  if (dndContextData.planSource === TEMPLATE_PLAN) return { ...dndContextData, taskRef, task, isTask, index }; // if not task rendered in recon plan, just return the data passed from context

  const reconPlanData = getReconPlanData(task, user, dndContextData, isInProgressTaskGroup);

  // if task rendered in recon plan,
  return {
    ...dndContextData,
    users,
    taskRef,
    barRef,
    task,
    index,
    isTask,
    isInProgressTaskGroup,
    expand,
    ...reconPlanData
  };
};

/**
 * Task
 * Task card component that renders for each task
 * @prop {object} task - task dto passed into this component from DraggableItem
 * @prop {boolean} isTask - is this task component instance of modeltype task? this is important to distinguish tasks that are in a larger task group from tasks that are single and outside of a task group. comes straight from DraggableItem
 * @prop {boolean} isInProgressTaskGroup - determined in DragList - denotes the step that is in progress
 * @prop {object} taskElementRef - passed as a prop into this component so that we can attach it to a tag. needed for styling in DraggableItem
 * @prop {boolean} isDragging - observed from a DraggableItem's useDrag function invocation - determines if a particular DraggableItem is being dragged
 * @prop {boolean} expand - expand's value is determined in DraggableItem - through a function in DraggableItem it is determined if a task is being dragged over by a task group task or a task and this boolean reflects that value
 * @prop {boolean} draggingOverTaskGroupPropFromTaskGroup - there's some additional styling that needs to be applied to tasks in a task group when a task is being dragged over a task group, this boolean reflects when that happens and its value is set in DraggableItem. because DraggableItem is recursive, its important to be able to distinguish a hover boolean for the parent or the child individually, so that's why we denote it as PropFromTaskGroup
 * @prop {integer} index - order value for where this is rendered in the list (task group or main)
 */
const Task = ({
  task,
  isTask,
  isInProgressTaskGroup,
  taskElementRef,
  isDragging,
  expand,
  draggingOverTaskGroupPropFromTaskGroup,
  index,
  countMessagesTask = null,
  taskGroups,
  flags,
  isShowContentSegmentTDP,
  showPendingApprovalTime = true
}) => {
  const { taskRef, barRef, showBar, ...taskFunctionalityAndData } = useSetTaskFunctionalityAndData(
    task,
    index,
    isInProgressTaskGroup,
    isTask,
    expand
  );
  const { planStarted, isSaving, planSource, contentType, canDragOrUpdate } = taskFunctionalityAndData;

  const dragBarStatus = () => {
    if (task.status) {
      if (!planStarted) return PENDING;
      if (task.completedOn) return COMPLETED;
      if (task.declined || task.status === DECLINED) {
        if (flags.reconTaskCollapse) return isInProgressTaskGroup ? IN_PROGRESS : PENDING;
        return DECLINED;
      } else {
        if (flags.reconTaskCollapse) return isInProgressTaskGroup ? IN_PROGRESS : PENDING;
        if (task.status === DECLINED || task.declinedOn) return DECLINED;
      }
      if (isInProgressTaskGroup) return IN_PROGRESS;
      return PENDING;
    }

    return task?.status;
  };

  return (
    <StyledCSSGrid
      ref={taskElementRef}
      isDragging={isDragging}
      isTask={isTask}
      candrag={!isSaving && canDragOrUpdate}
      expand={expand}
      isInProgressTaskGroup={isInProgressTaskGroup}
      draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupPropFromTaskGroup}
    >
      {planSource === RECON_PLAN && (!flags.reconTaskCollapse || !contentsSegmentTDP.includes(contentType)) ? (
        <StyledBar
          ref={barRef}
          expand={expand}
          isTask={isTask}
          show-bar={showBar}
          status={dragBarStatus()}
          draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupPropFromTaskGroup}
          progress={taskFunctionalityAndData.taskProgress.status}
          reconTaskCollapseFlag={flags.reconTaskCollapse ? 1 : 0}
        />
      ) : null}
      <TaskContext.Provider value={taskFunctionalityAndData}>
        <StyledTaskContainer
          ref={taskRef}
          status={task.status}
          reconTaskCollapse={flags.reconTaskCollapse}
          isTask={isTask}
        >
          {!flags.reconTaskCollapse && <StepSegment />}
          {(contentsSegmentTDP.includes(contentType) || isShowContentSegmentTDP) && flags.reconTaskCollapse ? (
            <ContentSegmentTDP
              planSource={planSource}
              contentType={contentType}
              task={task}
              taskGroups={taskGroups}
              showPendingApprovalTime={showPendingApprovalTime}
            />
          ) : (
            <ContentSegment
              countMessagesTask={countMessagesTask}
              planSource={planSource}
              contentType={contentType}
              task={task}
              taskGroups={taskGroups}
            />
          )}
        </StyledTaskContainer>
      </TaskContext.Provider>
    </StyledCSSGrid>
  );
};

Task.propTypes = {
  task: PropTypes.object,
  isTask: PropTypes.bool,
  isInProgressTaskGroup: PropTypes.bool,
  taskElementRef: PropTypes.object,
  isDragging: PropTypes.bool,
  expand: PropTypes.bool,
  draggingOverTaskGroupPropFromTaskGroup: PropTypes.bool,
  index: PropTypes.number
};

Task.defaultProps = {
  task: {},
  isTask: false,
  isInProgressTaskGroup: false,
  taskElementRef: { current: null },
  isDragging: false,
  expand: false,
  draggingOverTaskGroupPropFromTaskGroup: false,
  index: 0
};

//#region StyledComponents
const StyledCSSGrid = styled(GridTemplate(`'bar content'`, '1fr', 'auto minmax(0px, 1fr)'))`
  // fallback if grab cursor is unsupported
  cursor: ${(props) => (props['candrag'] ? 'move' : 'default')};
  cursor: ${(props) => (props['candrag'] ? 'grab' : 'default')};
  max-height: ${({ isRenderedForDragging, isDragging }) => {
    const maxHeightNotForDragging = isDragging ? 50 : 1000;
    return isRenderedForDragging ? 1000 : maxHeightNotForDragging;
  }};
  border-radius: ${({ isInProgressTaskGroup }) => !isInProgressTaskGroup && '4px'};
  background-color: ${({ expand, draggingOverTaskGroupPropFromTaskGroup, theme }) =>
    expand || draggingOverTaskGroupPropFromTaskGroup ? theme.colors.transparent : theme.colors.white};
  transition: background-color 0s, border 0s;
  transition-delay: ${({ draggingOverTaskGroupPropFromTaskGroup }) =>
    !draggingOverTaskGroupPropFromTaskGroup ? '0.5s' : '0s'};
  border: ${({ theme, isTask }) => isTask && theme.borders.mediumSolidGray};
`;
const StyledBar = styled(GridArea('bar', 1, 1))`
  width: 8px;
  border-radius: ${({ isTask }) => (isTask ? '3px 0 0 3px' : 0)};
  display: ${(props) => (props['show-bar'] || props.reconTaskCollapseFlag ? 'block' : 'none')};
  min-height: ${({ reconTaskCollapseFlag }) => !reconTaskCollapseFlag && '75px'};
  transition: background-color 0s
    ${({ expand, draggingOverTaskGroupPropFromTaskGroup }) =>
      expand && !draggingOverTaskGroupPropFromTaskGroup ? '0.5s' : 0};
  background-color: ${({
    theme,
    status,
    progress,
    expand,
    draggingOverTaskGroupPropFromTaskGroup,
    reconTaskCollapseFlag
  }) => {
    if (expand || draggingOverTaskGroupPropFromTaskGroup) {
      return theme.colors.transparent;
    }
    switch (status) {
      case IN_PROGRESS:
        if (reconTaskCollapseFlag) {
          return theme.colors.navy;
        }
        switch (progress) {
          case taskProgressLabels.Overdue:
            return theme.colors.red;
          case taskProgressLabels.PastGoal:
            return theme.colors.orange;
          default:
            return theme.colors.navy;
        }
      case DECLINED:
        if (reconTaskCollapseFlag) {
          return theme.colors.white;
        }
        return theme.colors.red;
      default:
        return theme.colors.white;
    }
  }};
`;
const StyledTaskContainer = styled(GridArea('content', 1, 2))`
  display: flex;
  min-height: ${({ reconTaskCollapse }) => !reconTaskCollapse && '75px'};
  align-items: flex-start;
  padding: ${({ reconTaskCollapse, isTask }) => {
    const paddingReconTaskCollapseToggle = isTask ? '16px 0px' : '8px 0px 0px 0px';
    return reconTaskCollapse ? paddingReconTaskCollapseToggle : '10px 10px 10px 0';
  }};
`;
//#endregion

export default withLDConsumer()(Task);
