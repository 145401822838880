export const renameKey = (obj, oldKey, newKey) => {
  // Safely renames key without affecting existing object
  let tmp = {
    ...obj,
    [newKey]: obj[oldKey]
  };
  delete tmp[oldKey];
  return tmp;
};

export const isObjectEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}