import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Upload, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCircle, faSquare, faImages } from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';
import { communicationContexts, locationActions } from 'app-constants';
import { CustomizedAlert } from 'components/alerts';
import {
  CommonRedButton,
  CommonFooterContainer,
  CommonLinkButton,
  CommonStrongText
} from 'components/styledComponents';
import { useSelector, useDispatch } from 'react-redux';
import { vehiclesActions } from 'store/vehiclesStore';
import { LoadingRedButton, LoadingIcon } from 'components/common';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { sortBy } from 'lodash';
import { updateVisibleStateError } from 'utils/arrayUtils';
import autosize from 'autosize';
import { MentionsSuggestion } from './MentionsSuggestion';
import { useTaskReadOnlyStatus } from '../../../hooks/useTaskReadOnlyStatus';
import { NEW } from '../../../app-constants/reconPlanStatusTypes';
import uuidv4 from 'uuid/v4';

const ImgBasedOnFile = ({ file }) =>
  file?.thumbUrl !== undefined && file?.thumbUrl !== '' && file?.type.includes('image') ? (
    <img src={file?.thumbUrl} alt={file.name} />
  ) : (
    <img src={require('../../../assets/images/placeholderCar.jpg')} alt="" />
  );

const ListImageAttachment = ({
  imageList,
  setImageList,
  checkUploadError,
  errorList,
  setErrorList,
  setHasChangeCommunicationInput,
  hasReplyCommunication
}) => {
  const inputEl = useRef(null);
  const [isHiddenArrowLeft, setToHideArrowLeft] = useState(true);
  const [isHiddenArrowRight, setToHideArrowRight] = useState(true);
  const [iconInfo, setIconInfo] = useState('exclamation-circle');
  const [typeButton, setTypeButton] = useState('danger');
  const [indexButton, setIndexButton] = useState(0);

  useEffect(() => {
    const scrollLeft = inputEl?.current.scrollLeft;
    const scrollWidth = inputEl?.current.scrollWidth;
    const widthScrolled = scrollLeft + inputEl?.current.offsetWidth;
    _checkDisplayArrowControl(scrollLeft, scrollWidth, widthScrolled);
  }, [imageList]);

  const _checkDisplayArrowControl = (scrollLeft, scrollWidth, widthScrolled) => {
    setToHideArrowLeft(scrollLeft <= 0);
    setToHideArrowRight(widthScrolled === scrollWidth || widthScrolled - scrollWidth === 1);
  };

  const handleArrowButton = (nav) => {
    const currentInputEl = inputEl?.current;
    const scrollLeft = currentInputEl.scrollLeft;
    const widthScrolled = scrollLeft + currentInputEl.offsetWidth - 50;
    const scrollWidth = currentInputEl.scrollWidth;
    _checkDisplayArrowControl(scrollLeft, scrollWidth, widthScrolled);
    const numberScroll = Math.ceil(scrollWidth / widthScrolled);
    const currIndex = (widthScrolled / scrollWidth) * numberScroll;
    const diffScroll = currentInputEl.offsetWidth - 50;
    if (nav === 'left') {
      if (currentInputEl && scrollLeft > 0) {
        currentInputEl.scrollLeft -= diffScroll;
        setToHideArrowLeft(true);
        setToHideArrowRight(false);
        //Check Arrow Left visible
        const part = currentInputEl.scrollLeft;
        if (part < diffScroll || (part > 0 && part <= 70) || currIndex < 1.5) {
          if (part > 0) currentInputEl.scrollLeft -= part;
          setToHideArrowLeft(true);
        } else setToHideArrowLeft(false);
      }
    } else {
      if (currentInputEl && scrollLeft >= 0 && widthScrolled !== scrollWidth) {
        currentInputEl.scrollLeft += diffScroll;
        setToHideArrowLeft(false);
        setToHideArrowRight(true);
        //Check Arrow Right visible
        const part = Math.abs(scrollWidth - widthScrolled);
        if ((part > 0 && part <= 70) || (currIndex <= numberScroll && currIndex > numberScroll - 1)) {
          currentInputEl.scrollLeft += part;
          setToHideArrowRight(true);
        } else setToHideArrowRight(false);
      }
    }
  };

  const handleCloseImage = (index) => {
    const currentInputEl = inputEl?.current;
    if (setHasChangeCommunicationInput && !hasReplyCommunication) {
      setHasChangeCommunicationInput(true);
    }
    if (imageList.length > 0) {
      const listFiles = imageList.filter((f, idx) => idx !== index);
      // Get files for parent component
      errorList = [...errorList];
      setImageList(listFiles);
      if (listFiles?.length === 0) {
        checkUploadError(false);
        return;
      }
      _checkListFileContainError(listFiles);
      const scrollLeft = currentInputEl.scrollLeft;
      const scrollWidth = currentInputEl.scrollWidth;
      const widthScrolled = scrollLeft + currentInputEl.offsetWidth;
      _checkDisplayArrowControl(scrollLeft, scrollWidth, widthScrolled);
      //Check error Maximum File size 10MB
      let conditionMaxFileSize = listFiles.find(
        (file) => file.size > communicationContexts.MAXIMUM_FILE_IMAGE && file.response === 'error'
      );
      setErrorList(
        updateVisibleStateError(errorList, communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, conditionMaxFileSize)
      );
      //Check error for incorrecting file type
      let conditionIncorrectFileType = listFiles.find(
        (file) => !file?.originFileObj.type.includes('image') && file.response === 'error'
      );
      setErrorList(
        updateVisibleStateError(
          errorList,
          communicationContexts.MESSAGE_INCORRECT_FILE_TYPE,
          conditionIncorrectFileType
        )
      );
      //Check error 10 files limit
      let conditionMax10Files = listFiles?.length > communicationContexts.NUMBERS_IMAGE_UPLOADED;
      setErrorList(
        updateVisibleStateError(errorList, communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, conditionMax10Files)
      );
    }
  };

  // check file list contain error
  const _checkListFileContainError = (files) => {
    if (files?.length > 0) {
      const fileErr = files.find((file) => file.response === 'error');
      checkUploadError(fileErr);
    }
  };

  const ArrowLeft = () => {
    return (
      <Button
        hidden={isHiddenArrowLeft}
        onClick={() => handleArrowButton('left')}
        shape="circle"
        icon="arrow-left"
        className={'arrow-image-left arrow-image'}
        size={'large'}
      />
    );
  };

  const ArrowRight = () => {
    return (
      <Button
        hidden={isHiddenArrowRight}
        onClick={() => handleArrowButton('right')}
        shape="circle"
        icon="arrow-right"
        className={'arrow-image-right arrow-image'}
        size={'large'}
      />
    );
  };

  return (
    <StyledListPhotos ref={inputEl} recon-files={imageList.length > 0}>
      {imageList?.length > 0 &&
        imageList.map((file, index) => {
          return (
            <div key={file.uid} className={`item-image ${file.response === 'error' ? 'item-image-error' : ''}`}>
              {file.status === 'done' ? (
                <ImgBasedOnFile file={file} />
              ) : (
                <Button shape="circle" loading size={'small'} />
              )}
              <Button
                onClick={() => handleCloseImage(index)}
                hidden={!(file.status === 'done' && file.response === 'ok')}
                shape="circle"
                icon="close-circle"
                className={'image-close'}
                size={'small'}
              />
              <Button
                hidden={file.status !== 'uploading'}
                shape="circle"
                className={'image-close'}
                loading
                size={'small'}
              />
              <Button
                onClick={() => handleCloseImage(index)}
                hidden={file.response !== 'error'}
                className={'image-exclamation'}
                shape="circle"
                icon={indexButton === index ? iconInfo : 'exclamation-circle'}
                type={indexButton === index ? typeButton : 'danger'}
                onMouseOver={() => {
                  setIconInfo('close-circle');
                  setTypeButton('default');
                  setIndexButton(index);
                }}
                onMouseOut={() => {
                  setIconInfo('exclamation-circle');
                  setTypeButton('danger');
                  setIndexButton(0);
                }}
                size={'small'}
              />
            </div>
          );
        })}
      <ArrowLeft />
      <ArrowRight />
    </StyledListPhotos>
  );
};

const CustomFileListUploader = ({
  setImageList,
  imageList,
  checkUploadError,
  vehicleNote = false,
  isMaxSizeUpload,
  setMaxSizeUploadFlag,
  isFileTypeIncorrect,
  setFileTypeIncorrectFlag,
  isMaxFilesUpload,
  transientCommunication,
  isSavingNote,
  setMaxFilesUploadFlag,
  isShowLoadingSendCommunication,
  shiftingIconSpace,
  flags,
  errorList,
  setHasChangeCommunicationInput,
  hasReplyCommunication,
  setErrorList,
  taskIsDeleted
}) => {
  const handleChange = (options) => {
    const { file, fileList } = options;
    let listImage = [...fileList];

    if (setHasChangeCommunicationInput && !hasReplyCommunication) {
      setHasChangeCommunicationInput(true);
    }

    //Check contain file over 10MB
    if (file.size > communicationContexts.MAXIMUM_FILE_IMAGE && file.response === 'error') {
      if (!isMaxSizeUpload) {
        setMaxSizeUploadFlag(!isMaxSizeUpload);
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, true));
      }
      checkUploadError(true);
    }
    //Check contain not image file
    if (!file?.originFileObj.type.includes('image') && file.response === 'error') {
      if (!isFileTypeIncorrect) {
        setFileTypeIncorrectFlag(!isFileTypeIncorrect);
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_INCORRECT_FILE_TYPE, true));
      }
      checkUploadError(true);
    }
    //Check max 10 files uploaded
    if (listImage?.length > communicationContexts.NUMBERS_IMAGE_UPLOADED) {
      if (!isMaxFilesUpload) {
        setMaxFilesUploadFlag(!isMaxFilesUpload);
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, true));
      }
      checkUploadError(true);
    }
    //Order Error list on top of list
    listImage = sortBy(listImage, (image) => image.response);
    //Check on each file
    listImage.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.thumbUrl = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
      //Double check state of all error in list
      //-- File size over 10mb
      if (file.size > communicationContexts.MAXIMUM_FILE_IMAGE && file.response === 'error')
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, true));
      //-- Check contain not image file
      if (!file?.originFileObj.type.includes('image') && file.response === 'error')
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_INCORRECT_FILE_TYPE, true));
      //-- Check max 10 files uploaded
      if (listImage?.length > communicationContexts.NUMBERS_IMAGE_UPLOADED)
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, true));
    });
    // get data for parent component
    setImageList([...listImage]);
  };

  const customRequest = (options) => {
    const { onSuccess, file } = options;
    setTimeout(() => {
      if (file?.size > communicationContexts.MAXIMUM_FILE_IMAGE || !file?.type.includes('image')) {
        onSuccess('error');
      } else {
        onSuccess('ok');
      }
    }, 1000);
  };

  const resetErrorAlerts = () => {
    setMaxSizeUploadFlag(false);
    setFileTypeIncorrectFlag(false);
    setMaxFilesUploadFlag(false);
  };

  const onPreventClickable = (eventData) => {
    eventData.stopPropagation();
    eventData.preventDefault();
  };

  if (vehicleNote) {
    return (
      <StyledAttachPhotos>
        <ListImageAttachment
          imageList={imageList}
          setImageList={setImageList}
          checkUploadError={checkUploadError}
          setMaxSizeUploadFlag={setMaxSizeUploadFlag}
          transientCommunication={transientCommunication}
          errorList={errorList}
          setErrorList={setErrorList}
          setHasChangeCommunicationInput={setHasChangeCommunicationInput}
          hasReplyCommunication={hasReplyCommunication}
        />

        <StyledUploadImages
          multiple={true}
          fileList={imageList}
          customRequest={customRequest}
          onChange={handleChange}
          recon-files={imageList.length > 0}
          listType={'picture-card'}
          accept={'image/jpg, image/jpeg, image/pjpeg, image/gif, image/x-png, image/png, image/bmp'}
          name={'avatar'}
          disabled={isSavingNote}
        >
          <div onClick={resetErrorAlerts}>
            <Tooltip
              placement="top"
              overlayClassName="statistic-tooltip attach-tooltip"
              title={
                <div className="attach-photo-title" onClick={onPreventClickable}>
                  {communicationContexts.TOOLTIP_ATTACH_PHOTOS}
                </div>
              }
            >
              <span className={' fa-images fa'} style={{ marginRight: '10px' }}>
                <FontAwesomeIcon icon={faImages} color={themeStyles.colors.red} />
              </span>
              <span style={{ color: themeStyles.colors.red }}>Attach Photos</span>
            </Tooltip>
          </div>
        </StyledUploadImages>
      </StyledAttachPhotos>
    );
  } else {
    return (
      <StyledUploadImagesInsideCommunication
        multiple={true}
        fileList={imageList}
        recon-files={imageList.length > 0}
        customRequest={customRequest}
        onChange={handleChange}
        listType={'picture-card'}
        accept={'image/jpg, image/jpeg, image/pjpeg, image/gif, image/x-png, image/png, image/bmp'}
        name={'avatar'}
        disabled={isShowLoadingSendCommunication || taskIsDeleted}
      >
        <div onClick={resetErrorAlerts}>
          <StyledAttachPhotoButton
            shiftingIconSpace={`${shiftingIconSpace}px`}
            taskIsDeleted={taskIsDeleted}
          ></StyledAttachPhotoButton>
        </div>
      </StyledUploadImagesInsideCommunication>
    );
  }
};

const CommunicationInput = ({
  id,
  nullText = '',
  isReply = false,
  setFocusTo,
  focusTo,
  addCommunication,
  style = {},
  location,
  refTransientCommunication,
  closeDrawer,
  isSavingNote,
  index = 0,
  indexCommunication,
  setHasChangeCommunicationInput,
  hasReplyCommunication = false,
  taggableUsers = [],
  taskId,
  flags,
  taskIsDeleted
}) => {
  const inputRef = useRef(null);
  const heightRef = useRef(null);
  const heightAlertContainerRef = useRef(null);
  const mentionRef = useRef(null);

  const dispatch = useDispatch();
  const [transientCommunication, setTransientCommunication] = useState(
    refTransientCommunication?.current?.communication || ''
  );

  const [contentData, setContentData] = useState('');
  const [isContainError, setIsContainError] = useState(false);
  const { isShowVehicleNote, isShowLoadingSendCommunication, locationAction, focusToCommunicationId, data } =
    useSelector((state) => state.vehicles); // VH === VehicleNote  const [imageList, setImageList] = useState(refTransientCommunication?.current?.images || []);
  const [imageList, setImageList] = useState(refTransientCommunication?.current?.images || []);
  const [isMaxSizeUpload, setMaxSizeUploadFlag] = useState(false);
  const [isFileTypeIncorrect, setFileTypeIncorrectFlag] = useState(false);
  const [isMaxFilesUpload, setMaxFilesUploadFlag] = useState(false);
  const [errorList, setErrorList] = useState([
    { messageContent: communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, visibleState: false },
    { messageContent: communicationContexts.MESSAGE_INCORRECT_FILE_TYPE, visibleState: false },
    { messageContent: communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, visibleState: false }
  ]);
  const { isShowDiscardVHButton } = useSelector((state) => state.vehicles);

  const [paddingMentionsBox, setPaddingMentionBox] = useState(0);
  const [enterSubmitContent, setEnterSubmitContent] = useState(false);
  const isReadonlyTask = useTaskReadOnlyStatus(taskId, flags.reconVendorManagement);
  const isNewVehicle = data?.items && data.items.length === 1 && data.items[0].inventoryType === NEW;

  useEffect(() => {
    if (refTransientCommunication) {
      refTransientCommunication.current = { communication: transientCommunication, images: imageList };
    }
  }, [transientCommunication, imageList]);

  useEffect(() => {
    if (mentionRef?.current?.rcMentions?.textarea) {
      autosize(mentionRef?.current?.rcMentions?.textarea);
      mentionRef.current.rcMentions.textarea.style.maxHeight = '145px';
    }
  });

  if (!nullText) {
    switch (location) {
      case communicationContexts.TASK_DETAILS_PAGE:
      case communicationContexts.TASKS_PAGE_MESSAGE_DRAWER:
        nullText = 'Send a message...';
        break;
      case communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE:
        nullText = 'Send a message...';
        break;
      default:
        nullText = 'Type to enter vehicle note...';
        break;
    }
  }

  if (taskIsDeleted) {
    nullText = 'This task was deleted.';
  }
  //check contain error attachment photos
  const checkContainErrorUploadPhotos = (isError) => {
    setIsContainError(isError);
    if (setHasChangeCommunicationInput && !hasReplyCommunication) {
      setHasChangeCommunicationInput(true);
    }
    if (isMaxSizeUpload)
      setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, isError));
    if (isFileTypeIncorrect)
      setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_INCORRECT_FILE_TYPE, isError));
    if (isMaxFilesUpload)
      setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, isError));
  };

  const getLocationActionSubmit = (location) => {
    if (
      location === communicationContexts.TASK_DETAILS_PAGE ||
      location === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER
    ) {
      return locationActions.BUTTON_SAVE_TASK_DETAIL_PAGE;
    } else if (location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE) {
      return locationActions.BUTTON_SAVE_VEHICLE_DETAILS_PAGE_MESSAGE;
    } else if (location === communicationContexts.VEHICLE_COMMUNICATION_REPLY_PAGE) {
      return locationActions.BUTTON_SAVE_VEHICLE_COMMUNICATION_REPLY_PAGE;
    } else {
      return locationActions.BUTTON_SAVE_VEHICLE_CREATE_NOTE_PAGE;
    }
  };

  const checkImageErrorInListPhoto = () => {
    if (imageList?.length > 10) {
      return true;
    }
    if (imageList?.length > 0) {
      let errorImage = imageList?.find(
        (im) => !im.originFileObj.type.includes('image') || im.size > communicationContexts.MAXIMUM_FILE_IMAGE
      );
      return errorImage && 'originFileObj' in errorImage;
    }
  };

  const onSubmit = (event) => {
    let isCreateNote = true;
    if (imageList?.length > 0) {
      let foundFiles = imageList.filter((file) => file.size > communicationContexts.MAXIMUM_FILE_IMAGE);
      if (foundFiles.length > 0) isCreateNote = false;
    }
    event.preventDefault();
    if ((transientCommunication || imageList?.length > 0) && isCreateNote) {
      if (setHasChangeCommunicationInput && !hasReplyCommunication) {
        setHasChangeCommunicationInput(true);
      }
      if (!enterSubmitContent) {
        handleAddCommunication();
      }
    }
  };

  const handleAddCommunication = () => {
    //handle for empty communication case
    if (transientCommunication.trim().length <= 0) {
      setTransientCommunication('');
      if (imageList?.length <= 0) {
        return;
      }
    }

    let loadingMessage = 'Saving message...';
    let successMessage = 'Created a new message successfully!';
    let errorMessage = 'An error occurred while adding message';
    dispatch(vehiclesActions.setFocusToCommunication(id));
    if (
      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
      location === communicationContexts.VEHICLE_DETAILS_PAGE
    ) {
      loadingMessage = 'Saving note...';
      successMessage = 'Created a note successfully!';
      errorMessage = 'An error occurred while creating note';
    }

    let locationActionSubmit = getLocationActionSubmit(location);

    addCommunication(
      transientCommunication,
      contentData,
      imageList,
      loadingMessage,
      successMessage,
      errorMessage,
      locationActionSubmit
    );
    setFocusTo(id);

    if (
      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
      location === communicationContexts.VEHICLE_DETAILS_PAGE
    ) {
      dispatch(vehiclesActions.setShowDiscardVHButton(false));
    }
    reset();
  };

  const toggleShowCommunicationInput = () => {
    dispatch(vehiclesActions.setShowVehicleNote(!isShowVehicleNote));
    setFocusTo(id);
    reset();
  };

  const reset = () => {
    setTransientCommunication('');
    setPaddingMentionBox(0);
    setImageList([]);
    resetErrorState(null, true);
    dispatch(vehiclesActions.setShowDiscardVHButton(false));
    if (mentionRef?.current?.rcMentions?.textarea) {
      mentionRef.current.rcMentions.textarea.style.height = '30px';
      mentionRef.current.rcMentions.textarea.style.marginBottom = '0px';
    }

    if (refTransientCommunication) {
      refTransientCommunication.current = { communication: '' };
      if (
        location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER &&
        (refTransientCommunication?.current?.communication || refTransientCommunication?.current?.images?.length > 0)
      ) {
        if (!isShowDiscardVHButton) closeDrawer();
      }
      refTransientCommunication.current = { communication: '', images: [] };
    }
  };

  const discardChanged = () => {
    setTransientCommunication('');
    setImageList([]);
    if (refTransientCommunication) {
      if (
        location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER &&
        (refTransientCommunication?.current?.communication || refTransientCommunication?.current?.images?.length > 0)
      ) {
        if (isShowDiscardVHButton) {
          dispatch(vehiclesActions.setShowDiscardVHButton(false));
          refTransientCommunication.current = { communication: '', images: [] };
          closeDrawer();
        }
      }
    }
  };

  const handleCancelBtn = (e) => {
    //Reset all alert
    errorList.forEach((err) => {
      err.visibleState = false;
    });
    toggleShowCommunicationInput();
  };

  const checkShowLoadingWhenSend = () => {
    return (
      isShowLoadingSendCommunication &&
      (focusTo === id || index === indexCommunication || focusToCommunicationId === id) &&
      (locationAction === locationActions.BUTTON_SAVE_TASK_DETAIL_PAGE ||
        (locationAction === locationActions.BUTTON_SAVE_VEHICLE_DETAILS_PAGE_MESSAGE &&
          location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE) ||
        (locationAction === locationActions.BUTTON_SAVE_VEHICLE_COMMUNICATION_REPLY_PAGE &&
          location === communicationContexts.VEHICLE_COMMUNICATION_REPLY_PAGE) ||
        (locationAction === locationActions.BUTTON_SAVE_VEHICLE_CREATE_NOTE_PAGE &&
          location === communicationContexts.VEHICLE_DETAILS_PAGE))
    );
  };

  const hasImageErrorInImageList = checkImageErrorInListPhoto();

  const resetErrorState = (contentMessage, isResetAll = false) => {
    if (isResetAll) {
      // use after send a task message, all pendding error alert should be gone.
      setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, false));
      setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_INCORRECT_FILE_TYPE, false));
      setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, false));
      return;
    }
    switch (contentMessage) {
      case communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE:
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_MAXIMUM_FILE_IMAGE, false));
        break;
      case communicationContexts.MESSAGE_INCORRECT_FILE_TYPE:
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_INCORRECT_FILE_TYPE, false));
        break;
      case communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT:
        setErrorList(updateVisibleStateError(errorList, communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT, false));
        break;
      default:
        break;
    }
  };

  const checkDisableButtonSave = () => {
    return (
      ((!transientCommunication || transientCommunication?.trim().length === 0) &&
        (imageList?.length <= 0 || imageList?.length > 10)) ||
      isContainError ||
      (isShowDiscardVHButton && hasImageErrorInImageList) ||
      imageList?.length > communicationContexts.NUMBERS_IMAGE_UPLOADED
    );
  };

  const disableButtonSave = checkDisableButtonSave();

  const invalidCondition = () => {
    return errorList.some((err) => err.visibleState);
  };

  const checkInvalidCondition = invalidCondition();
  if (!isNewVehicle) {
    if (
      location === communicationContexts.VEHICLE_DETAILS_PAGE ||
      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER
    ) {
      return isShowVehicleNote ? (
        <StyledNoteContainer ref={heightRef}>
          <StyledHeaderLabel>Create a Note</StyledHeaderLabel>
          {errorList?.length > 0 &&
            errorList.map((error, index) => {
              return (
                <CustomizedAlert
                  key={uuidv4()}
                  type="error"
                  onClose={(e) => resetErrorState(error.messageContent)}
                  location={location}
                  message={
                    error.messageContent === communicationContexts.MESSAGE_EXCEED_10_PHOTOS_LIMIT
                      ? communicationContexts.VEHICLE_NOTE_EXCEED_10_PHOTOS_LIMIT
                      : error.messageContent
                  }
                  visible={error.visibleState}
                  closable
                  showIcon
                  widthAlert={inputRef?.current?.offsetWidth}
                  setHasChangeCommunicationInput={setHasChangeCommunicationInput}
                  hasReplyCommunication={hasReplyCommunication}
                />
              );
            })}

          <StyledCommunicationInputContent
            style={style}
            is-reply={isReply}
            recon-photos={true}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                onSubmit(event);
              }
            }}
            spellCheck
          >
            <MentionsSuggestion
              isCreateANote={true}
              placeholder={nullText}
              setEnterSubmitContent={setEnterSubmitContent}
              transientCommunication={transientCommunication}
              setTransientCommunication={setTransientCommunication}
              setContentData={setContentData}
              disabled={isSavingNote || isShowLoadingSendCommunication || taskIsDeleted}
              taggableUsers={taggableUsers}
              taskId={taskId}
              ref={(m) => {
                if (m && focusTo === id) m.focus();
              }}
              flags={flags}
            />
            <CustomFileListUploader
              vehicleNote={true}
              checkUploadError={checkContainErrorUploadPhotos}
              imageList={imageList}
              isMaxSizeUpload={isMaxSizeUpload}
              setMaxSizeUploadFlag={setMaxSizeUploadFlag}
              isFileTypeIncorrect={isFileTypeIncorrect}
              setFileTypeIncorrectFlag={setFileTypeIncorrectFlag}
              isMaxFilesUpload={isMaxFilesUpload}
              setMaxFilesUploadFlag={setMaxFilesUploadFlag}
              setImageList={setImageList}
              errorList={errorList}
              setErrorList={setErrorList}
              transientCommunication={transientCommunication}
              isSavingNote={isSavingNote || isShowLoadingSendCommunication}
              flags={flags}
              setHasChangeCommunicationInput={setHasChangeCommunicationInput}
              hasReplyCommunication={hasReplyCommunication}
              taskIsDeleted={taskIsDeleted}
            ></CustomFileListUploader>
          </StyledCommunicationInputContent>
          <StyledFooterContainer fullWidth={true} isShowVehicleNote={true}>
            {isShowDiscardVHButton && location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ? (
              <CommonRedButton onClick={discardChanged} style={{ marginRight: '32px' }}>
                Discard Changes
              </CommonRedButton>
            ) : (
              <CommonLinkButton
                disabled={isSavingNote || isShowLoadingSendCommunication}
                onClick={handleCancelBtn}
                style={{ marginRight: '32px' }}
              >
                Cancel
              </CommonLinkButton>
            )}
            <LoadingRedButton
              loadingIcon={
                isShowLoadingSendCommunication &&
                focusTo === id &&
                locationAction === locationActions.BUTTON_SAVE_VEHICLE_CREATE_NOTE_PAGE
              }
              theme={themeStyles}
              disabled={disableButtonSave}
              onClick={onSubmit}
            >
              Save
            </LoadingRedButton>
          </StyledFooterContainer>
        </StyledNoteContainer>
      ) : (
        <StyledFooterContainer isShowVehicleNote={false} fullWidth={true}>
          <CommonRedButton
            disabled={isShowLoadingSendCommunication}
            ghost={true}
            onClick={toggleShowCommunicationInput}
          >
            Create a Note
          </CommonRedButton>
        </StyledFooterContainer>
      );
    } else {
      return (
        <StyleMessageContainer ref={heightRef}>
          {checkInvalidCondition && (
            <StyledAlertContainer
              ref={heightAlertContainerRef}
              className="alert-container"
              location={location}
              data-location={location}
              width-alert={inputRef?.current?.offsetWidth}
            >
              {errorList.map((error, index) => {
                return (
                  <CustomizedAlert
                    key={uuidv4()}
                    type="error"
                    onClose={(e) => resetErrorState(error.messageContent)}
                    location={location}
                    message={error.messageContent}
                    visible={error.visibleState}
                    closable
                    showIcon
                    setHasChangeCommunicationInput={setHasChangeCommunicationInput}
                    hasReplyCommunication={hasReplyCommunication}
                  />
                );
              })}
            </StyledAlertContainer>
          )}
          <StyledInputCommunication data-location={location}>
            <StyledInputContent
              style={{ ...style, paddingBottom: `${paddingMentionsBox}px` }}
              data-enabled={!disableButtonSave}
              loading={isShowLoadingSendCommunication}
              is-reply={isReply}
              recon-photos={true}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey && !enterSubmitContent) {
                  onSubmit(event);
                }
              }}
              className={isReadonlyTask ? 'hard-disabled' : ''}
              task-is-deleted={taskIsDeleted}
            >
              <MentionsSuggestion
                isReply
                mentionRef={mentionRef}
                className={'input-photos'}
                placeholder={nullText}
                taggableUsers={taggableUsers}
                taskId={taskId}
                disabled={isShowLoadingSendCommunication || taskIsDeleted}
                setEnterSubmitContent={setEnterSubmitContent}
                transientCommunication={transientCommunication}
                setTransientCommunication={setTransientCommunication}
                setContentData={setContentData}
                flags={flags}
              />
              {checkShowLoadingWhenSend() ? (
                <StyledContainerIcons data-shifting-icon={`0px`} recon-photos={true}>
                  <StyledSendButton
                    data-disabled={disableButtonSave}
                    red-icon-square={true}
                    loading={isShowLoadingSendCommunication}
                    onClick={onSubmit}
                    recon-photos={true}
                  />
                </StyledContainerIcons>
              ) : (
                <StyledContainerIcons
                  data-shifting-icon={`${paddingMentionsBox}px`}
                  className="icon-container"
                  location-vehicle-details-message={location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE}
                  location-task-page={location === communicationContexts.TASK_DETAILS_PAGE}
                  location-task-page-drawer={location === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER}
                  location-inventory-page-communications={
                    location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER
                  }
                  location-vehicle-detail-page={location === communicationContexts.VEHICLE_DETAILS_PAGE}
                  recon-photos={true}
                >
                  <CustomFileListUploader
                    vehicleNote={false}
                    checkUploadError={checkContainErrorUploadPhotos}
                    imageList={imageList}
                    isMaxSizeUpload={isMaxSizeUpload}
                    setMaxSizeUploadFlag={setMaxSizeUploadFlag}
                    isFileTypeIncorrect={isFileTypeIncorrect}
                    setFileTypeIncorrectFlag={setFileTypeIncorrectFlag}
                    isMaxFilesUpload={isMaxFilesUpload}
                    setMaxFilesUploadFlag={setMaxFilesUploadFlag}
                    setImageList={setImageList}
                    transientCommunication={transientCommunication}
                    errorList={errorList}
                    setErrorList={setErrorList}
                    isShowLoadingSendCommunication={isShowLoadingSendCommunication}
                    flags={flags}
                    setHasChangeCommunicationInput={setHasChangeCommunicationInput}
                    hasReplyCommunication={hasReplyCommunication}
                    shiftingIconSpace={`${paddingMentionsBox}px`}
                    taskIsDeleted={taskIsDeleted}
                  ></CustomFileListUploader>
                  <StyledSendButton data-disabled={disableButtonSave} red-icon-square={true} onClick={onSubmit} />
                </StyledContainerIcons>
              )}
            </StyledInputContent>
            <StyledListImageInCommunication>
              <ListImageAttachment
                checkUploadError={checkContainErrorUploadPhotos}
                imageList={imageList}
                setImageList={setImageList}
                errorList={errorList}
                setErrorList={setErrorList}
                setMaxSizeUploadFlag={setMaxSizeUploadFlag}
                transientCommunication={transientCommunication}
                setHasChangeCommunicationInput={setHasChangeCommunicationInput}
                hasReplyCommunication={hasReplyCommunication}
              />
            </StyledListImageInCommunication>
          </StyledInputCommunication>
        </StyleMessageContainer>
      );
    }
  }
  return null;
};

const StyledInputCommunication = styled.div`
  ${(props) => props['data-location'] === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE && 'min-height: 68px'};
  padding-top: ${({ 'data-location': location }) =>
    location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE ? `16px` : 'unset'};
  border-top: ${({ 'data-location': location, theme }) =>
    location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE ? `1px solid ${theme.colors.borderGray}` : 'unset'};
`;

//#region Styled Components
const StyledInputContent = styled(({ loading, ...rest }) => <div {...rest} />)`
  background-color: #ffffff;
  margin-top: ${(props) => (props['is-reply'] ? '8px' : 'unset')};

  ${(props) => props['loading'] && 'height: 32px'};
  position: relative;
  border-radius: 4px;

  .mention-editor {
    margin: 0;
    color: ${(props) => (props['task-is-deleted'] ? '#90939D' : '#002950')};
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .DraftEditor-editorContainer {
      .public-DraftEditor-content {
        overflow: hidden;
        overflow-wrap: break-word;
        margin-right: 60px;
        margin-bottom: 0px;
      }
    }
  }
`;

const StyledAttachPhotoButton = styled((props) => {
  const onPreventClickable = (eventData) => {
    eventData.stopPropagation();
    eventData.preventDefault();
  };

  return props.taskIsDeleted ? (
    <span className={props.className} style={{ textAlign: 'center', marginTop: props.shiftingIconSpace }}>
      <FontAwesomeIcon icon={faImages} color={themeStyles.colors.darkGray} />
    </span>
  ) : (
    <Tooltip
      placement="top"
      overlayClassName="statistic-tooltip attach-tooltip"
      title={
        <div className="attach-photo-title" onClick={onPreventClickable}>
          {communicationContexts.TOOLTIP_ATTACH_PHOTOS}
        </div>
      }
    >
      <span className={props.className} style={{ textAlign: 'center', marginTop: props.shiftingIconSpace }}>
        <FontAwesomeIcon icon={faImages} color={themeStyles.colors.darkGray} />
      </span>
    </Tooltip>
  );
})`
  & {
    max-width: 220px;
    position: absolute;
    right: 30px;
    top: 0;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
  }
  & > svg {
    height: 32px;
    width: 32px;
    cursor: ${(props) => (props['data-disabled'] ? 'default' : 'pointer')};
  }
  & > svg.fa-circle {
    opacity: ${(props) => (props['data-disabled'] ? '.3' : '1')};
  }
`;

const StyledSendButton = styled((props) => {
  if (props['red-icon-square'] && !props['data-disabled']) {
    return (
      <span className={props.className + ' fa-layers fa-fw'} onClick={props.onClick}>
        <FontAwesomeIcon
          icon={props['red-icon-square'] ? faSquare : faCircle}
          color={themeStyles.colors.red}
          size="2x"
        />
        <FontAwesomeIcon icon={faPaperPlane} color={themeStyles.colors.white} />
      </span>
    );
  } else if (!!props?.loading) {
    return (
      <span className={props.className + ' fa-layers fa-fw'}>
        <FontAwesomeIcon
          icon={props['red-icon-square'] ? faSquare : faCircle}
          color={themeStyles.colors.red}
          size="2x"
        />
        <LoadingIcon />
      </span>
    );
  } else {
    return (
      <span className={props.className} style={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon={faPaperPlane} color={themeStyles.colors.darkGray} />
      </span>
    );
  }
})`
  & {
    position: ${(props) => (props['loading'] ? 'initial' : 'absolute')};
    top: ${(props) => (props['recon-photos'] ? 'none' : '0')};
    right: 0px;
    height: 32px;
    width: 32px;
    margin-left: 0;
    display: flex;
    justify-content: center;
    z-index: 0;
  }
  & > svg {
    height: 30px;
    width: 30px;
    cursor: ${(props) => (props['data-disabled'] ? 'default' : 'pointer')};
    color: ${(props) => (props['red-icon-square'] ? 'default' : 'currentColor')};
  }
  & > svg.fa-circle {
    opacity: ${(props) => (props['data-disabled'] && !props['red-icon-square'] ? '.3' : '1')};
  }
`;

const StyledHeaderLabel = styled(CommonStrongText)`
  padding-top: 16px;
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

const StyledNoteContainer = styled.div`
  z-index: 150;
`;

const StyledCommunicationInputContent = styled.div`
  padding: ${(props) => (props['recon-photos'] ? '16px' : '18px')} 24px;

  .mention-editor {
    :active,
    :focus,
    :hover {
      border: ${({ theme }) => theme.borders.thickSolidLightNavy};
      border-right-width: 2px !important;
    }

    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-height: 74px;

    .DraftEditor-root {
      min-height: 60px;
    }
  }
`;

const StyledFooterContainer = styled(CommonFooterContainer)`
  justify-content: ${({ isShowVehicleNote }) => (isShowVehicleNote ? 'flex-end' : 'center')};
  // height: 73px;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
  // bottom: 0;
  z-index: 150;
  width: ${({ fullWidth }) =>
    fullWidth
      ? '100%'
      : '49.9%'}; /* The 49.9% is to give it a little gap so that it doesn't cover up the right border on the VDP/TDP */
`;

const StyledUploadImagesInsideCommunication = styled(Upload)`
  & {
    display: ${(props) => (props['recon-files'] ? 'block' : 'none')};
    height: 32px;
    width: 32px;
    margin-right: 8px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
  }
  & > svg {
    height: 28px;
    width: 28px;
    cursor: 'pointer';
  }
  & > svg.fa-circle {
    opacity: '1';
  }
  width: auto !important;
  :hover {
    opacity: 0.8;
    border-color: unset;
    cursor: pointer;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0 !important;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 0 !important;
    height: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    background-color: unset !important;
    border: unset !important;
    border-radius: 0px !important;
  }
  .ant-upload-list-picture-card {
    display: none !important;
  }
`;
const StyledUploadImages = styled(Upload)`
  color: ${({ theme }) => theme.colors.red} !important;
  top: ${(props) => (props['recon-files'] ? '70px' : '-7px')};
  position: absolute;
  :hover {
    opacity: 0.8;
    border-color: unset;
    cursor: pointer;
  }
  .ant-upload.ant-upload-select-picture-card {
    display: inline-block !important;
    width: 100% !important;
    height: 0 !important;
    border: none !important;
  }
  .ant-upload-list-picture-card {
    display: none !important;
  }
`;

const StyledCreatedNoteContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .arrow-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    height: 32px !important;
    width: 32px !important;
    transition: all 0.3s ease-out;
    opacity: 1;
    min-width: 32px !important;
    color: ${({ theme }) => theme.colors.red};
  }
  .arrow-image:hover {
    cursor: pointer;
  }
  .arrow-image-right {
    right: -12px;
  }
  .arrow-image-left {
    left: -12px;
  }
`;

const StyledListImageInCommunication = styled(StyledCreatedNoteContainer)`
  display: 'inline-block';
  margin: 8px auto;
  width: 95%;
  font-weight: 600;
  position: relative;
`;

const StyledAttachPhotos = styled(StyledCreatedNoteContainer)`
  display: 'inline-block';
  margin: 8px 0;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red};
  position: relative;
`;

const StyledListPhotos = styled.div.attrs({
  className: 'list-photos'
})`
  display: ${(props) => (props['recon-files'] ? 'flex' : 'none')};
  overflow-x: scroll;
  white-space: nowrap;
  overflow: hidden;
  scroll-behavior: smooth;
  padding: 8px;
  min-width: 100%;
  .item-image {
    position: relative;
    line-height: 0;
    display: grid;
    margin-right: 8px;
    border: 0;
    width: 62px;
    height: 62px;
    border: 1px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 6%);
    border-radius: 4px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    align-items: center;
    justify-content: center;
  }
  & > .item-image-error {
    background-color: red;
    & > img {
      border: red 1px solid;
      opacity: 0.7;
    }
  }
  img {
    height: 62px;
    width: 62px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 6%);
    border-radius: 4px;
    background-repeat: no-repeat;
    transition-duration: 0.1s;
    transition-property: opacity, box-shadow;
  }
  .image-close {
    font-size: 17px !important;
    border-radius: 50%;
    background: rgba(var(--sk_primary_background, 255, 255, 255), 1);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    right: -8px;
    top: -8px;
    &:hover {
      border: none !important;
    }
  }
  .image-exclamation {
    font-size: 17px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    right: -8px;
    top: -8px;
    z-index: 0;
    &:hover {
      border: none !important;
    }
  }
`;

const StyledContainerIcons = styled.div`
  position: ${(props) => {
    if (props['recon-photos']) {
      if (props['location-task-page']) {
        return 'absolute';
      } else {
        return props['location-inventory-page-communications'] || props['location-vehicle-detail-page']
          ? 'relative'
          : 'absolute';
      }
    } else {
      return 'absolute';
    }
  }};
  right: 0;
  top: ${(props) => {
    return props['recon-photos'] ? 'none' : '-32px';
  }};
  bottom: 0;
  display: inline-flex;
  & > span {
    padding-top: ${(props) => (props['data-shifting-icon'] ? props['data-shifting-icon'] : '2px')};
    &.fa-layers {
      margin-top: ${(props) => (props['data-shifting-icon'] ? props['data-shifting-icon'] : '2px')};
    }
  }
`;

const StyledAlertContainer = styled.div`
  width: ${(props) => props['width-alert'] + 'px'};
  padding: ${(props) => {
    if (props['location'] === communicationContexts.VEHICLE_COMMUNICATION_REPLY_PAGE) {
      return '0 0 8px 0';
    } else {
      return props['location'] !== communicationContexts.TASK_DETAILS_PAGE ||
        props['location'] !== communicationContexts.TASKS_PAGE_MESSAGE_DRAWER
        ? '0 0 16px 0'
        : 'unset';
    }
  }};
  position: ${(props) => {
    if (props['location'] === communicationContexts.VEHICLE_COMMUNICATION_REPLY_PAGE) {
      return 'relative';
    } else {
      return props['location'] === communicationContexts.TASK_DETAILS_PAGE ||
        props['location'] === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER ||
        props['location'] === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE
        ? 'initial'
        : 'absolute';
    }
  }};
  background-color: ${(props) =>
    props['location'] === communicationContexts.TASK_DETAILS_PAGE ||
    props['location'] === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER ||
    props['location'] === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE
      ? 'unset'
      : 'white'};
  top: ${(props) => (props['location'] === communicationContexts.VEHICLE_COMMUNICATION_REPLY_PAGE ? '8px' : 'unset')};
  bottom: ${(props) => (props['location'] === communicationContexts.TASK_DETAILS_PAGE ? '161px;' : '160px')};
`;
const StyleMessageContainer = styled.div``;
//#endregion

export default withLDConsumer()(CommunicationInput);
