import { BodyBaseBoldShort, BodySmall } from 'components/styledComponents';
import { StyledSettingsCard, StyledCircleIcon, StyledIcon, StyledMoreIcon } from '../common/Styles.js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Card, Typography, Menu, Icon, Dropdown } from 'antd';
import { Stack } from 'components';
import { displayDurationSummary, getMomentDurationFromString } from 'utils/dateTimeUtils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { formatPrice } from 'utils/numberUtils';
import { faEllipsisH, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { VENDOR } from 'app-constants/groupTypes';
const { Text } = Typography;

const taskTypesPropsSelector = createSelector(
  (state) => state.taskCategories.categoriesAll,
  (taskCategoriesStore) => ({
    taskCategoriesDict: taskCategoriesStore.dict
  })
);

const taskTypeIcon = (
  <StyledCircleIcon>
    <StyledIcon icon={faClipboardCheck} />
  </StyledCircleIcon>
);

const UnassignedText = () => <span className="unassigned-text-style">Unassigned</span>;

const MoreMenu = ({ taskType, setMenuOpen, menuOpen, onEdit, onDelete }) => (
  <Dropdown
    display="flex"
    onVisibleChange={() => setMenuOpen(!menuOpen)}
    overlay={
      <Menu
        onClick={({ key }) => {
          setMenuOpen(false);
          switch (key) {
            case 'edit':
              onEdit(taskType);
              break;
            case 'delete':
              onDelete(taskType);
              break;
            default:
              devLogger.log(`Unexpected response: ${key}. Canceled Task action`);
              break;
          }
        }}
      >
        <Menu.Item key="edit">
          <Icon type="edit" style={{ marginRight: '5px' }} />
          Edit
        </Menu.Item>
        <Menu.Item key="delete">
          <Icon type="delete" style={{ marginRight: '5px' }} />
          Delete
        </Menu.Item>
      </Menu>
    }
  >
    <StyledMoreIcon icon={faEllipsisH} data-selected={menuOpen} />
  </Dropdown>
);

const AssigneeGroupName = ({ assignees, reconTaskCollapse, reconVendorManagement }) => {
  const assignee = assignees ? assignees[0] : null;
  const getVendorTechName = (assigneeMember) => {
    return assigneeMember.userFullName ? ` (${assigneeMember.userFullName})` : '';
  };

  if (assignees?.length > 0) {
    if (reconTaskCollapse) {
      return (
        <BodySmall>
          {reconVendorManagement && assignee.assignedToGroupType === VENDOR && assignee.assignedToGroupName && (
            <>{assignee.assignedToGroupName + getVendorTechName(assignee)}</>
          )}
          {assignee.assignedToGroupType !== VENDOR && (assignee.userFullName || <UnassignedText />)}
          {assignee.assignedToGroupType !== VENDOR && assignee.assignedToGroupName && (
            <>&nbsp;({assignee.assignedToGroupName})</>
          )}
        </BodySmall>
      );
    }
    return (
      <span>
        {reconVendorManagement && assignee.assignedToGroupType === VENDOR && assignee.assignedToGroupName && (
          <>{assignee.assignedToGroupName + getVendorTechName(assignee)}</>
        )}
        {assignee.assignedToGroupType !== VENDOR && (assignee.userFullName || <UnassignedText />)}
        {assignee.assignedToGroupType !== VENDOR && assignee.assignedToGroupName && (
          <>&nbsp;({assignee.assignedToGroupName})</>
        )}
      </span>
    );
  } else {
    //no assignee - display Unassigned text
    return (
      <Text>
        <UnassignedText />
      </Text>
    );
  }
};

const TaskTypeCard = ({ taskType, onDelete, onEdit, flags }) => {
  const { taskCategoriesDict } = useSelector(taskTypesPropsSelector);
  const [menuOpen, setMenuOpen] = useState(false);

  const goalTime =
    (taskType.goalThreshold &&
      displayDurationSummary(getMomentDurationFromString(taskType.goalThreshold), true, true)) ||
    'Not configured';
  const maxTime =
    (taskType.maxThreshold && displayDurationSummary(getMomentDurationFromString(taskType.maxThreshold), true, true)) ||
    'Not configured';
  const taskCategory = taskCategoriesDict[taskType.categoryId];

  return (
    <StyledSettingsCard>
      <Card.Meta avatar={taskTypeIcon} />
      <Stack grow divStyle={{ minWidth: '1px' }}>
        <Stack grow horizontal divStyle={{ minWidth: '1px' }}>
          <Text ellipsis className="strong medium-font" style={{ marginBottom: '3px' }}>
            <BodyBaseBoldShort>{taskType.name}</BodyBaseBoldShort>
          </Text>
          <Stack grow divStyle={{ marginLeft: '5px', alignItems: 'flex-end' }}>
            <MoreMenu
              taskType={taskType}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </Stack>
        </Stack>
        <Stack grow horizontal divStyle={{ minWidth: '1px' }}>
          <Stack grow divStyle={{ minWidth: '1px' }}>
            <Text ellipsis>
              <BodySmall>Category: {taskCategory}</BodySmall>
            </Text>
            {flags.reconDefaultSettingsUpdate && (
              <AssigneeGroupName
                reconTaskCollapse={flags.reconTaskCollapse}
                reconVendorManagement={flags.reconVendorManagement}
                assignees={taskType.assignees}
              />
            )}
            <Text>
              <BodySmall>Est. Cost: {formatPrice(taskType.cost)}</BodySmall>
            </Text>
          </Stack>
          <Stack grow divStyle={{ minWidth: '1px', textAlign: 'right', flexShrink: '0' }}>
            <Text>
              <BodySmall>Goal Time: {goalTime}</BodySmall>
            </Text>
            <Text>
              <BodySmall>Max Time: {maxTime}</BodySmall>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </StyledSettingsCard>
  );
};

export default withLDConsumer()(TaskTypeCard);
