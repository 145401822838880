import { useLocation } from '@reach/router';
import { Form } from 'antd';
import { apiStatusConstants, detailContents, features, entityTypes } from 'app-constants';
import { LINE_ITEMS_CHANGE_STATUS } from 'app-constants/lineItemConstants';
import { COMPLETED, DECLINED, IN_PROGRESS } from 'app-constants/taskStatusTypes';
import { AppraisalInfoToggle } from 'components';
import { CommonLinkButton, CommonRedButton } from 'components/styledComponents';
import { useFeatures } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { lineItemsActions, getShowTagNeedApprovel } from 'store/lineItemsStore';
import { tasksActions } from 'store/tasksStore';
import { vdpActions } from 'store/vdpStore';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import {
  AddNewOrEditTaskForm,
  AssignGroupTaskForm,
  CompletedTaskForm,
  DeclineTaskForm,
  LineItemsTaskForm,
  TaskMessages
} from '.';
import { updateStatusForLineItemTemplate } from 'utils/lineItemsTemplateUtils';
import AlertForLineItemError from './AlertForLineItemError';
import LineItemsTaskFormTemplate from './LineItemsTemplate/LineItemsTaskFormTemplate';
import { planTemplatesActions } from 'store/planTemplatesStore';
import { oldPathNamePlanTemplate, pathNamePlanTemplate } from 'app-constants/planTemplatePath';
import { DEALER_INTERNAL_GROUP, VENDOR } from 'app-constants/groupTypes';
import { usePrevious } from '../../../../../hooks';
import useVendorRole from 'hooks/useVendorRole';

const taskFormSelector = createSelector(
  (state) => state.users,
  (state) => state.taskTypes,
  (usersContainer, taskTypesContainer) => {
    return {
      users: usersContainer.data,
      internalGroups: usersContainer.assigneeData?.internalGroups,
      vendors: usersContainer.assigneeData?.vendors,
      usersFetchStatus: usersContainer.fetchStatus,
      taskTypes: taskTypesContainer.data
    };
  }
);
const TaskForm = ({
  // vehicle,
  initialFocus,
  form,
  vin,
  parentId,
  assignedToOptional,
  taskToUpdate = null,
  isMessageTask = false,
  isLineItemsTask,
  setFormProps,
  closeForm = () => {},
  setScrollToId = () => {},
  onCreate = null,
  onUpdate = null,
  onComplete = null,
  onCompleteTaskMessage = null,
  onDelete = null,
  onDecline = null,
  onAssignGroupTask = null,
  onAssignTask = null,
  additionalFormStyles = {},
  templateDropdownMenu,
  planTemplates,
  showCreateReconPlanFromTemplateButton,
  formHeight,
  showDiscardButton = false,
  setShowDiscardButton,
  isInProgressTaskGroup,
  hidePassthroughIndicator = false,
  reconPlanFeatures,
  contentType,
  isShowContentSegmentTDP,
  setTemplateFormIsDirty,
  planStarted,
  taskGroups,
  flags,
  onTLP
}) => {
  const { users, usersFetchStatus, taskTypes, internalGroups, vendors } = useSelector(taskFormSelector);
  const comments = useSelector((state) => state.vdp.comments);
  const taskIsCompleted = taskToUpdate?.status === COMPLETED || taskToUpdate?.transientType === 'complete';
  const isUpdating = taskToUpdate && onUpdate ? true : false;
  const isCompleting = taskToUpdate && onComplete ? true : false;
  const isDecliningTask = taskToUpdate && onDecline ? true : false;
  const isAssigningGroupTask = taskToUpdate && onAssignGroupTask ? true : false;
  const isAssigningTask = taskToUpdate && onAssignTask ? true : false;
  const isAddNewOrEditTask = !isMessageTask && !isLineItemsTask && !isDecliningTask;

  const prevIsMessageTask = usePrevious(isMessageTask);

  const needApprovalList = useSelector((state) => state.lineItems.needApproval);
  const approvedList = useSelector((state) => state.lineItems.approved);
  const declinedList = useSelector((state) => state.lineItems.declined);
  const needApprovalErrorMessage = useSelector((state) => state.lineItems.needApprovalErrorMessage);
  const showNoLineItems = useSelector((state) => state.lineItems.showNoLineItems);
  const lineItemForBodySaveRequest = useSelector((state) => state.lineItems.lineItemForBodySaveRequest);
  const fetchLineItemsStatus = useSelector((state) => state.lineItems.fetchLineItemsStatus);
  const taskFetchStatus = useSelector((state) => state.tasks.fetchStatus);
  const lineItemChangeStatus = useSelector((state) => state.vdp.lineItemChangeStatus);
  const lineItemsOfTasks = useSelector((state) => state.tasks?.lineItemTasks);
  const listVehicles = useSelector((state) => state.vehicles?.data?.items);
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  const [hasEditTasks, hasLineItemsEditRole] = useFeatures(features.TASKS_EDIT, features.LINE_ITEMS_EDIT);
  const { isVendor } = useVendorRole(flags.reconVendorManagement);
  const taskTimeInApprovalList = useSelector((state) => state.tasks.taskTimeInApprovalList);

  const dispatch = useDispatch();
  const location = useLocation();
  let isUpdatingDeclinedTask;
  isUpdatingDeclinedTask = isUpdating && (taskToUpdate?.declined || taskToUpdate?.status === DECLINED);

  let taskMessages = [];

  // get list communications by taskId
  if (comments.items && taskToUpdate?.id) {
    taskMessages = comments.items.filter((vehicle) => vehicle.reconTaskId === taskToUpdate.id);
    // ignore task.messages to disable reply message
    taskMessages = taskMessages.map((taskMessage) => omit(taskMessage, ['messages']));
  }

  // which form fields should show?
  // CompletedTaskForm also includes form which is for updating a task to completed status - this will show when a user is either editing or completing a form
  // DeclineTaskForm are the form fields for declining a task
  // AddNewOrEditTaskForm is for adding a task to a recon plan or editing an existing task

  useEffect(() => {
    if (isLineItemsTask) {
      setShowDiscardButton && setShowDiscardButton(false);
    }
  }, [isLineItemsTask]);

  useEffect(() => {
    if (
      !isMessageTask &&
      !taskToUpdate?.isChangeReconTaskType &&
      (isLineItemsTask ||
        taskIsCompleted ||
        (contentType !== detailContents.VEHICLE_DETAILS &&
          contentType !== detailContents.RECON_PLAN &&
          (isDecliningTask || isCompleting)))
    ) {
      //added this to not call API in Complete Form/Edit Completed Task screens if we already have timeInApproval data
      if (onTLP) {
        dispatch(
          tasksActions.getTaskTimeInApprovalList(
            isVendor ? taskToUpdate?.vehicle?.dealerId : dealerId,
            taskToUpdate.id,
            entityTypes.TASK
          )
        );
      } else if (location.pathname !== pathNamePlanTemplate) {
        if (!isDecliningTask && !isCompleting && !isAddNewOrEditTask) {
          dispatch(tasksActions.getTaskTimeInApprovalList(dealerId, taskToUpdate.id, entityTypes.TASK));
        } else {
          let found = taskTimeInApprovalList.filter((task) => task.entityId === taskToUpdate.id);

          if (found.length === 0) {
            dispatch(tasksActions.getTaskTimeInApprovalList(dealerId, taskToUpdate.id, entityTypes.TASK));
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    //Added this to call API when go to message task first and then to line items.
    if (prevIsMessageTask && !isMessageTask) {
      dispatch(tasksActions.getTaskTimeInApprovalList(dealerId, taskToUpdate.id, entityTypes.TASK));
    }
  }, [isMessageTask]);

  const chooseFormContent = () => {
    if (isMessageTask) {
      return (
        <TaskMessages
          editable={false}
          onUpdate={onUpdate}
          onComplete={onCompleteTaskMessage}
          onDelete={onDelete}
          vehicleId={parentId}
          containerId={parentId}
          task={taskToUpdate}
          setFormProps={setFormProps}
          showEstimates={true}
          hidePassthroughIndicator={hidePassthroughIndicator}
          threadCommunications={false}
          passedInCommunications={taskMessages}
          initialFocus={initialFocus}
          isInProgressTaskGroup={isInProgressTaskGroup}
          closeForm={closeForm}
          setIsDirty={setIsDirty}
          reconPlanFeatures={reconPlanFeatures}
          contentType={contentType}
          isShowContentSegmentTDP={isShowContentSegmentTDP}
        />
      );
    } else if (isLineItemsTask) {
      if (
        // Just keep location.pathname === pathNamePlanTemplate when remove flag reconSettingsUpdate
        location.pathname === pathNamePlanTemplate ||
        location.pathname === oldPathNamePlanTemplate
      ) {
        return (
          <LineItemsTaskFormTemplate
            editable={false}
            planStarted={planStarted}
            onUpdate={onUpdate}
            onComplete={onCompleteTaskMessage}
            onDelete={onDelete}
            vehicleId={parentId}
            containerId={parentId}
            task={taskToUpdate}
            setFormProps={setFormProps}
            showEstimates={true}
            hidePassthroughIndicator={hidePassthroughIndicator}
            passedInCommunications={taskMessages}
            isInProgressTaskGroup={isInProgressTaskGroup}
            closeForm={closeForm}
            setIsDirty={setIsDirty}
            reconPlanFeatures={reconPlanFeatures}
            contentType={contentType}
          />
        );
      } else {
        return (
          <LineItemsTaskForm
            editable={false}
            planStarted={planStarted}
            onUpdate={onUpdate}
            onComplete={onCompleteTaskMessage}
            onDelete={onDelete}
            vehicleId={parentId}
            containerId={parentId}
            task={taskToUpdate}
            setFormProps={setFormProps}
            showEstimates={true}
            hidePassthroughIndicator={hidePassthroughIndicator}
            passedInCommunications={taskMessages}
            isInProgressTaskGroup={isInProgressTaskGroup}
            closeForm={closeForm}
            setIsDirty={setIsDirty}
            reconPlanFeatures={reconPlanFeatures}
            contentType={contentType}
          />
        );
      }
    } else if (taskIsCompleted || isCompleting) {
      return (
        <CompletedTaskForm
          taskToUpdate={taskToUpdate}
          vehicleId={parentId}
          getFieldDecorator={getFieldDecorator}
          onChange={onChange}
          hidePassthroughIndicator={hidePassthroughIndicator}
          reconPlanFeatures={reconPlanFeatures}
          isInProgressTaskGroup={isInProgressTaskGroup}
          contentType={contentType}
          setIsDirty={setIsDirty}
          isCompleting={isCompleting}
        />
      );
    } else if (isDecliningTask) {
      return (
        <DeclineTaskForm
          taskToDecline={taskToUpdate}
          vehicleId={parentId}
          getFieldDecorator={getFieldDecorator}
          onChange={onChange}
          hidePassthroughIndicator={hidePassthroughIndicator}
          reconPlanFeatures={reconPlanFeatures}
          isInProgressTaskGroup={isInProgressTaskGroup}
          contentType={contentType}
        />
      );
    } else if (isAssigningGroupTask || isAssigningTask) {
      return (
        <AssignGroupTaskForm
          taskToUpdate={taskToUpdate}
          getFieldDecorator={getFieldDecorator}
          onChange={onChange}
          assignedToOptional={assignedToOptional}
          users={users}
          internalGroups={internalGroups}
          usersFetchStatus={usersFetchStatus}
          assignedToMember={assignedToMember}
          userIdGetAssignToByTypeName={userIdGetAssignToByTypeName}
          isAssignTask={isAssigningTask}
        />
      );
    } else {
      return (
        <AddNewOrEditTaskForm
          form={form}
          taskToUpdate={taskToUpdate}
          assignedToOptional={assignedToOptional}
          templateDropdownMenu={templateDropdownMenu}
          planTemplates={planTemplates}
          showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
          closeForm={closeForm}
          getFieldDecorator={getFieldDecorator}
          onChange={onChange}
          isInProgressTaskGroup={isInProgressTaskGroup}
          userIdGetAssignToByTypeName={userIdGetAssignToByTypeName}
          assignedToMember={assignedToMember}
        />
      );
    }
  };

  useEffect(() => {
    // definition isDirty when re-renders TaskForm Component
    setIsDirty(isCompleting || isDecliningTask);
    setScrollToId(taskToUpdate?.id || null); // we want to set this at the beginning, so that if the user clicks outside of the drawer or the x button, then it will know where to scroll, rather than setting this on button onClick actions
    if (isMessageTask) return;
    if (taskIsCompleted || isCompleting) {
      form.setFieldsValue({
        completedCost: taskIsCompleted ? taskToUpdate?.completedCost : taskToUpdate?.estimatedCost,
        completionNote: taskToUpdate?.completionNote
      });
    } else if (isUpdating) {
      const member = (taskToUpdate?.assignedToGroupId && taskToUpdate?.assignedTo) || null;
      form.setFieldsValue({
        // populate with previous values
        reconTaskTypeName: taskToUpdate?.reconTaskTypeName,
        description: taskToUpdate?.description,
        assignedTo: taskToUpdate?.assignedToGroupId || taskToUpdate?.assignedTo,
        assignedToMember: member,
        estimatedCost: taskToUpdate?.estimatedCost,
        passthrough: taskToUpdate?.passthrough
      });
      setAssignedToMember(member);
    } else if (isAssigningGroupTask) {
      form.setFieldsValue({
        assignedTo: taskToUpdate.assignedTo || null
      });
    } else if (isAssigningTask) {
      const member = (taskToUpdate?.assignedToGroupId && taskToUpdate?.assignedTo) || null;
      form.setFieldsValue({
        assignedTo: taskToUpdate?.assignedToGroupId || taskToUpdate?.assignedTo,
        assignedToMember: member
      });
      setAssignedToMember(member);
    }
  }, [isMessageTask, isUpdating, isCompleting, isAssigningGroupTask, isAssigningTask, taskToUpdate, isLineItemsTask]);
  const { getFieldDecorator } = form;
  //#region IsDirty Tracking
  //If completing task, neither form field is required, so enable Complete button by default
  const [isDirty, setIsDirty] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [userIdGetAssignToByTypeName, setUserIdGetAssignToByTypeName] = useState(undefined);
  // have to has this state because of the form value will not keep the value for field dont have field decorator
  const [assignedToMember, setAssignedToMember] = useState(undefined);

  const onChange = (name) => (value, assignedTo) => {
    const newFormValues = {
      ...formValues,
      assignedToMember: assignedToMember,
      [name]: value === '' ? null : value
    };

    if (isAssigningGroupTask) {
      delete newFormValues.assignedToMember;
    }

    // newFormValues.assignedTo === undefined means assignedTo has not been changed directly or through task type change.
    // so detect if task assigned to group by taskToUpdate.assignedToGroupId (origin)
    const assigneeToFilter =
      newFormValues.assignedTo === undefined ? taskToUpdate?.assignedToGroupId : assignedTo || newFormValues.assignedTo;
    const groupId = isAssigningGroupTask ? taskToUpdate?.assignedToGroupId : assigneeToFilter;
    let isAssignToGroup = groupId && internalGroups?.some((ig) => ig.id === groupId);
    let isAssignToVendor = flags.reconVendorManagement && groupId && vendors?.some((v) => v.id === groupId);

    if (name === 'passthrough') {
      // antd bug with form decorator and switches, need to set any switch form fields ourselves...
      form.setFieldsValue({
        passthrough: value
      });
    }
    // Changing this type should prefill other fields
    if (name === 'reconTaskTypeName') {
      const selectedTaskType = taskTypes.find((x) => x.name === value);
      if (!selectedTaskType) {
        devLogger.error(`Could not find selected task type: ${value}`);
        return;
      }
      const assignee = (selectedTaskType.assignees && selectedTaskType.assignees[0]) || {};
      let newAssignedTo = assignee.userId || null;

      if (assignee.assignedToGroupId) {
        if (flags.reconVendorManagement && assignee.assignedToGroupType === VENDOR) {
          isAssignToVendor = true;
          if (window.location.pathname === '/configure/plantemplates' && vendors) {
            newAssignedTo = vendors.find((v) => v.id === assignee.assignedToGroupId)
              ? assignee.assignedToGroupId
              : undefined;
          } else {
            newAssignedTo = assignee.assignedToGroupId;
          }
        } else if (assignee.assignedToGroupType === DEALER_INTERNAL_GROUP) {
          isAssignToGroup = true;
          newAssignedTo = assignee.assignedToGroupId;
        } else {
          newAssignedTo = null;
        }
        newFormValues.assignedTo = newAssignedTo;
        newFormValues.assignedToMember = assignee.userId || null;
        setAssignedToMember(assignee.userId || null);
      } else {
        isAssignToGroup = false;
        newFormValues.assignedTo = newAssignedTo;
        newFormValues.assignedToMember = null;
        setAssignedToMember(null);
      }

      form.setFieldsValue({
        assignedTo: newAssignedTo,
        estimatedCost: selectedTaskType.cost,
        description: selectedTaskType.description
      });

      // assign here to trick render assign to field in AddNewOrEditTaskForm
      setUserIdGetAssignToByTypeName(newAssignedTo);
    }

    if (name === 'assignedTo') {
      setUserIdGetAssignToByTypeName(value);
      if (!isAssigningGroupTask) {
        setAssignedToMember(null);
        newFormValues.assignedToMember = null;
      }
      form.setFieldsValue({
        assignedTo: value
      });
    }

    if (name === 'assignedToMember') {
      newFormValues.assignedTo = assignedTo;
      setAssignedToMember(value);
    }

    if (isCompleting) {
      setIsDirty(true); // Neither completionNote or completedCost is required, so never disable button
    } else {
      const isFormDirty = taskToUpdate
        ? Object.entries(newFormValues).some((x) => {
            let fieldName = x[0];
            if ((isAssignToGroup || isAssignToVendor) && fieldName === 'assignedTo' && !isAssigningGroupTask) {
              fieldName = 'assignedToGroupId';
            }
            // reassign assigned group task to same technicians
            if (!isAssignToGroup && !isAssignToVendor && fieldName === 'assignedTo' && taskToUpdate.assignedToGroupId) {
              return true;
            }
            if ((isAssignToGroup || isAssignToVendor) && fieldName === 'assignedToMember') {
              fieldName = 'assignedTo';
            }
            // We're using != instead of !== because we may be comparing 0 and null
            // Empty string != null
            // eslint-disable-next-line
            return (taskToUpdate[fieldName] || null) != x[1];
          })
        : Object.values(newFormValues).some((x) => x);
      setIsDirty(isFormDirty);
    }

    setFormValues(newFormValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (isLineItemsTask) {
          // TODO: call api create line items
          if (location.pathname === pathNamePlanTemplate || location.pathname === oldPathNamePlanTemplate) {
            const updatedTask = {
              ...taskToUpdate
            };
            updatedTask.lineItemTemplates = {
              items: [...needApprovalList, ...declinedList, ...approvedList],
              count: [...needApprovalList, ...declinedList, ...approvedList].length
            };

            // update line Item of task template to show on collapse
            dispatch(
              planTemplatesActions.updatelineItemTemplatesTask(
                updatedTask.reconPlanTemplateId,
                updatedTask.id,
                updatedTask.lineItemTemplates.items
              )
            );

            // find vehicle to update needApproval filed for tasks
            // let vehicle = listVehicles.find((item) => item.id === updatedTask.vehicleId);

            if (!updatedTask.isAddingTask) {
              if (updatedTask.isChangeReconTaskType) {
                onUpdate(updatedTask);
                dispatch(lineItemsActions.resetLineItem());
                dispatch(lineItemsActions.resetLineItemForBodySaveRequest());
                closeForm();
              } else {
                onUpdate(updatedTask);
                dispatch(
                  lineItemsActions.patchLineItemsPlanTemplate(lineItemForBodySaveRequest, updatedTask, closeForm)
                );
              }
            } else {
              setTemplateFormIsDirty(true);
              updatedTask.isAppliedLineItemsTemplate = true;
              onUpdate(updatedTask);
              dispatch(lineItemsActions.resetLineItem());
              dispatch(lineItemsActions.resetLineItemForBodySaveRequest());
              closeForm();
            }
          } else {
            const updatedTask = {
              ...taskToUpdate
            };
            updatedTask.lineItems = {
              items: [...needApprovalList, ...declinedList, ...approvedList],
              count: [...needApprovalList, ...declinedList, ...approvedList].length
            };

            // update field needsApproval of task
            const showTagNeedApprovel = getShowTagNeedApprovel(updatedTask.lineItems.items);
            updatedTask.needsApproval = showTagNeedApprovel;

            // find vehicle to update needApproval filed for tasks
            let vehicle = listVehicles.find((item) => item.id === updatedTask.vehicleId);

            if (!updatedTask.isAddingTask && planStarted) {
              if (updatedTask.isChangeReconTaskType) {
                onUpdate(updatedTask);
                dispatch(tasksActions.setTasksLineItemStore(updatedTask));
                dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
                dispatch(lineItemsActions.resetLineItem());
                closeForm();
              } else {
                onUpdate(updatedTask);
                dispatch(
                  lineItemsActions.patchLineItems(
                    lineItemForBodySaveRequest,
                    updatedTask,
                    true,
                    lineItemsOfTasks,
                    closeForm,
                    vehicle,
                    flags?.reconTimestampFilter
                  )
                );

                // handle status of discard change button case change line item
                if (lineItemChangeStatus === LINE_ITEMS_CHANGE_STATUS.INITIAL) {
                  dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.ONLY_CHANGE_LINE_ITEM));
                }
              }
            } else {
              updatedTask.isAppliedLineItemsTemplate = true;
              onUpdate(updatedTask);
              dispatch(tasksActions.setTasksLineItemStore(updatedTask));
              dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
              dispatch(lineItemsActions.resetLineItem());
              closeForm();
            }
          }
        } else {
          if (setTemplateFormIsDirty) {
            setTemplateFormIsDirty(true);
          }
          let selectedTaskType;
          if (!taskIsCompleted && !isCompleting && !isDecliningTask && !isAssigningGroupTask && !isAssigningTask) {
            //the following block of code should only be set when on the AddNewOrEditTaskForm because the form values must contain a task type
            selectedTaskType = taskTypes.find((x) => x.name === values.reconTaskTypeName);
            if (!selectedTaskType) {
              devLogger.error(`Could not find selected task type: ${values.reconTaskTypeName}`);
              return;
            }
          }
          const user = users.find((x) => x.id === values.assignedTo);
          const assignedToName = user ? user.fullName : '';
          // values.assignedToMember might be removed by antd
          values.assignedToMember = values.assignedToMember || assignedToMember;
          const assignedToMemberName = users.find((x) => x.id === values.assignedToMember)?.fullName || '';
          let group = null;
          let assignedToGroupType = null;

          if (!user && internalGroups?.length) {
            group = internalGroups.find((ig) => ig.id === values.assignedTo);
            if (group) {
              assignedToGroupType = DEALER_INTERNAL_GROUP;
            }
          }
          if (flags.reconVendorManagement) {
            if (!user && vendors?.length && !group) {
              group = vendors.find((v) => v.id === values.assignedTo);
              if (group) {
                assignedToGroupType = VENDOR;
              }
            }
          }

          if (!isUpdating && !isCompleting && !isDecliningTask && !isAssigningGroupTask && !isAssigningTask) {
            dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
            // the following block is for adding a new task
            const newTask = {
              id: uuidv4(), // create a temporary id to help manage the transientTaskCollections
              vehicleId: parentId,
              reconPlanTemplateId: parentId,
              reconTaskTypeName: values.reconTaskTypeName,
              reconTaskTypeId: selectedTaskType.id,
              required: true,
              description: values.description,
              assignedToName: assignedToName,
              assignedTo: values.assignedTo,
              estimatedCost: values.estimatedCost,
              goalThreshold: selectedTaskType.goalThreshold,
              maxThreshold: selectedTaskType.maxThreshold,
              isTransient: true, // tacking on this adhoc field to track the Transient nature of this task
              passthrough: values.passthrough || false,
              prevPassThroughState: false,
              isAddingTask: true,
              isAppliedLineItemsTemplate: false // tracking applied line item template or not
            };

            if (group) {
              newTask.assignedTo = values.assignedToMember;
              newTask.assignedToName = assignedToMemberName;
              newTask.assignedToGroupId = values.assignedTo;
              newTask.assignedToGroupType = assignedToGroupType;
              newTask.assignedToGroupName = group.name;
            }

            if (!!selectedTaskType.lineItemTemplateTemplates) {
              newTask.lineItemTemplates = {
                count: selectedTaskType.lineItemTemplateTemplates.count,
                items: selectedTaskType.lineItemTemplateTemplates.items.map((lineItem) =>
                  updateStatusForLineItemTemplate(lineItem)
                )
              };

              newTask.lineItems = {
                count: selectedTaskType.lineItemTemplateTemplates.count,
                items: selectedTaskType.lineItemTemplateTemplates.items.map((lineItem) =>
                  updateStatusForLineItemTemplate(lineItem)
                )
              };
            } else {
              newTask.lineItemTemplates = {
                count: 0,
                items: []
              };
              newTask.lineItems = {
                count: 0,
                items: []
              };
            }

            // update field needsApproval of task
            const showTagNeedApprovel = getShowTagNeedApprovel(newTask.lineItemTemplates.items);
            newTask.needsApproval = showTagNeedApprovel;
            dispatch(tasksActions.setTasksLineItemStore(newTask));

            if (location.pathname === pathNamePlanTemplate || location.pathname === oldPathNamePlanTemplate) {
              // add new task of plan template to show line item on collapse

              const newTaskTemp = { ...newTask, lineItemTemplates: [...newTask.lineItemTemplates.items] };
              dispatch(planTemplatesActions.addNewlineItemTemplatesTasks(newTaskTemp.reconPlanTemplateId, newTaskTemp));
            }

            setScrollToId(newTask.id);
            onCreate(newTask, taskGroups);
            closeForm();
          } else {
            // the following block is for everything else - updating a completed task, updating a task to complete status, updating a task to decline status
            // Update AssignedToGroupID and Member ID
            const updatedTask = {
              ...taskToUpdate,
              isTransient: true, // tacking on this adhoc field to track the Transient nature of this task
              prevPassThroughState: taskToUpdate?.passthrough // tacking on this adhoc field to track the original value of pass through, so the user can remove it from a in-progress task if they just added it, before saving
            };

            if (isAssigningTask) {
              onAssignTask(values.assignedTo, values.assignedToMember);
              return;
            }

            if (isAssigningGroupTask) {
              onAssignGroupTask(values.assignedTo);
              return;
            }

            if (taskIsCompleted || isCompleting) {
              updatedTask.lineItems = {
                items: [...approvedList, ...declinedList],
                count: approvedList.length + declinedList.length
              };
              updatedTask.completedCost =
                approvedList.length === 0
                  ? values.completedCost
                  : approvedList
                      .map((item) => (item.totalCost ? Number(item.totalCost) : 0))
                      .reduce((accumulator, current) => {
                        return accumulator + current;
                      });
              updatedTask.completionNote = values.completionNote;
              updatedTask.completedJustNow =
                updatedTask.completedJustNow ||
                updatedTask.completedOn === null ||
                updatedTask.completedOn === undefined; // We set completedJustNow so we know it just got completed, and for analytics tracking of completing tasks
              updatedTask.completedOn = updatedTask.completedOn || new Date(); // completedOn is a date field in the API. it's not necessary to have a value for it in the request body if the vehicle is newly completed, but other UI state depends on this, so setting a value

              if (!updatedTask.isChangeReconTaskType) {
                dispatch(tasksActions.setTasksLineItemStore(updatedTask));
                if (!!lineItemForBodySaveRequest.length) {
                  if (contentType === detailContents.VEHICLE_DETAILS || contentType === detailContents.RECON_PLAN) {
                    const vehicle = listVehicles.find((item) => item.id === updatedTask.vehicleId);
                    dispatch(
                      lineItemsActions.patchLineItems(
                        lineItemForBodySaveRequest,
                        updatedTask,
                        true,
                        lineItemsOfTasks,
                        closeForm,
                        vehicle,
                        flags?.reconTimestampFilter
                      )
                    );
                  } else if (
                    contentType === detailContents.TASK_DETAILS ||
                    contentType === detailContents.EDIT_COMPLETED_TASK ||
                    contentType === detailContents.COMPLETE_TASK
                  ) {
                    dispatch(
                      lineItemsActions.patchLineItemsTDP(
                        lineItemForBodySaveRequest,
                        updatedTask,
                        contentType,
                        isUpdating
                      )
                    );
                  }
                }
              } else {
                dispatch(tasksActions.setTasksLineItemStore(updatedTask));
              }
            } else if (isDecliningTask) {
              updatedTask.declinedReason = values.declineReason;
            } else {
              const isChangeReconTaskType = updatedTask.reconTaskTypeId !== selectedTaskType.id;

              //store lineItemsBeforeChangeTaskType
              if (!updatedTask.isChangeReconTaskType && isChangeReconTaskType) {
                const lineItemOfTask = lineItemsOfTasks.items.find(
                  (taskLineItem) => taskLineItem.id === updatedTask.id
                );
                if (!!lineItemOfTask && !!lineItemOfTask?.lineItems?.count) {
                  updatedTask.lineItemsBeforeChangeTaskType = {
                    count: lineItemOfTask.lineItems.count,
                    items: [...lineItemOfTask.lineItems.items]
                  };
                } else {
                  updatedTask.lineItemsBeforeChangeTaskType = {
                    count: 0,
                    items: []
                  };
                }
              }

              updatedTask.isChangeReconTaskType = !updatedTask.isChangeReconTaskType
                ? isChangeReconTaskType
                : updatedTask.isChangeReconTaskType;
              updatedTask.reconTaskTypeName = values.reconTaskTypeName;
              updatedTask.reconTaskTypeId = selectedTaskType.id;
              updatedTask.description = values.description;
              updatedTask.isReAssigned =
                updatedTask.assignedToGroupId !== values.assignedTo ||
                updatedTask.assignedTo !== values.assignedToMember;
              updatedTask.estimatedCost = values.estimatedCost;
              updatedTask.goalThreshold = selectedTaskType.goalThreshold;
              updatedTask.maxThreshold = selectedTaskType.maxThreshold;
              updatedTask.passthrough = values.passthrough;

              // just handle internal group for now
              // the var "group" only have data when the toggle on with above logic, can use as the flag
              if (group) {
                // group task
                updatedTask.assignedTo = values.assignedToMember;
                updatedTask.assignedToName = assignedToMemberName;
                updatedTask.assignedToGroupId = values.assignedTo;
                updatedTask.assignedToGroupType = assignedToGroupType;
                updatedTask.assignedToGroupName = group.name;
              } else {
                updatedTask.assignedTo = values.assignedTo;
                updatedTask.assignedToName = assignedToName;
                updatedTask.assignedToGroupId = null;
                updatedTask.assignedToGroupType = null;
                updatedTask.assignedToGroupName = '';
              }

              // update lineitem in here
              if (updatedTask.isChangeReconTaskType) {
                //update lineitem
                if (!!selectedTaskType.lineItemTemplateTemplates) {
                  updatedTask.lineItems = {
                    count: selectedTaskType.lineItemTemplateTemplates.count,
                    items: selectedTaskType.lineItemTemplateTemplates.items.map((lineItem) =>
                      updateStatusForLineItemTemplate(lineItem)
                    )
                  };

                  //for adding task or task template
                  updatedTask.lineItemTemplates = {
                    count: selectedTaskType.lineItemTemplateTemplates.count,
                    items: selectedTaskType.lineItemTemplateTemplates.items.map((lineItem) =>
                      updateStatusForLineItemTemplate(lineItem)
                    )
                  };
                } else {
                  updatedTask.lineItems = {
                    count: 0,
                    items: []
                  };

                  //for adding task or task template
                  updatedTask.lineItemTemplates = {
                    count: 0,
                    items: []
                  };
                }

                // update field needsApproval of task
                const showTagNeedApprovel = getShowTagNeedApprovel(updatedTask.lineItems.items);
                updatedTask.needsApproval = showTagNeedApprovel;
                dispatch(tasksActions.setTasksLineItemStore(updatedTask));
                dispatch(tasksActions.resetTaskTimeInApprovalList());
              }

              if (location.pathname === pathNamePlanTemplate || location.pathname === oldPathNamePlanTemplate) {
                // update line Item of task template to show on collapse
                dispatch(
                  planTemplatesActions.updatelineItemTemplatesTask(
                    updatedTask.reconPlanTemplateId,
                    updatedTask.id,
                    updatedTask.lineItemTemplates?.items
                  )
                );
              }
            }
            if (isUpdatingDeclinedTask) {
              const { reconTaskTypeId: currentTaskTypeId, assignedTo: currentAssignedTo } = taskToUpdate;
              const { reconTaskTypeId: updatedTaskTypeId, assignedTo: updatedAssignedTo } = updatedTask;
              if (currentTaskTypeId !== updatedTaskTypeId || currentAssignedTo !== updatedAssignedTo) {
                updatedTask.status = IN_PROGRESS;
                updatedTask.declinedById = null;
                updatedTask.declinedByName = null;
                updatedTask.declinedOn = null;
                updatedTask.declined = false;
              }
            }
            if (isUpdating) {
              //check to show discard change btn
              if (
                !taskIsCompleted ||
                (taskIsCompleted &&
                  (taskToUpdate.completionNote !== updatedTask.completionNote ||
                    (taskToUpdate.completedCost !== updatedTask.completedCost && !lineItemForBodySaveRequest.length)))
              ) {
                dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
              } else {
                if (lineItemChangeStatus !== LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM) {
                  dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.ONLY_CHANGE_LINE_ITEM));
                }
              }
              onUpdate(updatedTask);
            } else if (isCompleting) {
              //show discard change btn
              dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
              onComplete(updatedTask);
            } else if (isDecliningTask) {
              //show discard change btn
              dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
              onDecline(updatedTask);
            }
            closeForm();
          }
        }
      }
    });
  };

  const determineSubmitButtonLabel = () => {
    if (isUpdating) {
      return 'Update';
    } else if (isCompleting) {
      return 'Complete';
    } else if (isDecliningTask) {
      return 'Decline';
    } else {
      return 'Save';
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit} style={{ ...additionalFormStyles }}>
      <StyledFormContainer>
        {!isLineItemsTask && vin && <AppraisalInfoToggle toggleStyles={{ marginLeft: '24px' }} />}
        <StyledFormItemsContainer
          form-height={formHeight}
          contentType={contentType}
          isAddNewOrEditTask={isAddNewOrEditTask}
          isLineItemsTask={isLineItemsTask}
        >
          {chooseFormContent()}
        </StyledFormItemsContainer>
        {!isMessageTask ? (
          <>
            <AlertForLineItemError />
            {!isLineItemsTask || (isLineItemsTask && !showNoLineItems) ? (
              <StyledButtonsContainer>
                {showDiscardButton && !!lineItemForBodySaveRequest.length ? (
                  <CommonRedButton onClick={() => closeForm()} style={{ marginRight: '24px' }}>
                    Discard Changes
                  </CommonRedButton>
                ) : isAssigningGroupTask || isAssigningTask ? (
                  <StyledBorderedButton onClick={() => closeForm()} className="thin-button">
                    Cancel
                  </StyledBorderedButton>
                ) : (
                  <CommonLinkButton onClick={() => closeForm()} style={{ marginRight: '24px' }}>
                    Cancel
                  </CommonLinkButton>
                )}
                {!hasEditTasks && !hasLineItemsEditRole && !isVendor ? (
                  <></>
                ) : (
                  <StyledSubmitButton
                    className={isAssigningGroupTask || isAssigningTask ? 'thin-button' : ''}
                    disabled={
                      !isDirty ||
                      needApprovalList.find((x) => x.error) ||
                      approvedList.find((x) => x.error) ||
                      declinedList.find((x) => x.error) ||
                      needApprovalErrorMessage ||
                      (form && Object.values(form.getFieldsError() ?? {}).some((v) => !!v))
                    }
                    loading={
                      taskToUpdate?.saving ||
                      (fetchLineItemsStatus === apiStatusConstants.IS_FETCHING && isLineItemsTask) ||
                      (flags.reconApproveButton &&
                        contentType !== detailContents.PLAN_TEMPLATES &&
                        taskFetchStatus === apiStatusConstants.IS_FETCHING)
                    }
                  >
                    {determineSubmitButtonLabel()}
                  </StyledSubmitButton>
                )}
              </StyledButtonsContainer>
            ) : null}
          </>
        ) : null}
      </StyledFormContainer>
    </StyledForm>
  );
};

//#region StyledComponents
const StyledForm = styled(Form).attrs({
  layout: 'vertical'
})`
  height: 100%;
`;
const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const StyledFormItemsContainer = styled.div`
  margin-top: ${({ contentType }) => contentType === detailContents.PLAN_TEMPLATES && '24px'};
  padding: ${(props) => (props['isAddNewOrEditTask'] ? '0px 24px 73px 24px' : '0px 24px 24px')};
  height: ${(props) =>
    props['form-height']
      ? props['form-height']
      : props['isLineItemsTask']
      ? 'calc(100vh - 130px)'
      : 'calc(100vh - 261px)'};
  overflow-y: auto;
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
  background-color: ${({ theme }) => theme.colors.white};
  height: 73px;
  position: absolute;
  width: 100%;
  bottom: 0;
`;
const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})`
  margin-right: 24px;
`;
export const StyledBorderedButton = styled(CommonRedButton)`
  opacity: ${({ disabled }) => disabled && '0.5'};
  color: ${({ theme }) => theme.colors.red} !important;
  border-color: ${({ theme }) => theme.colors.red} !important;
  background: ${({ theme }) => theme.colors.white} !important;
  // substract border
  height: 30px !important;
  margin-right: 16px;
`;

export default withLDConsumer()(Form.create()(TaskForm));
