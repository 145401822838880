import React, { useEffect, useState } from 'react';
import { apiStatusConstants } from 'app-constants';
import { IN_APP } from 'app-constants/notificationChannelsTypes.js';
import CircularSpinner from 'components/common/CircularSpinner.js';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Notification } from 'components/layout/notifications';
import {
  userNotificationChannelsSelector,
  userNotificationEventsSelector,
  isUserSubscribedToNotificationsAtMultipleDealersSelector
} from 'store/userSettingsStore';
import useVendorRole from 'hooks/useVendorRole';
import { journalActions } from 'store/journalStore';
import { authSelector } from 'store/authStore';
import { List } from 'antd';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDeepCompareEffect from 'use-deep-compare-effect';
import journalEventTypes from 'app-constants/journalEventTypes';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';

const notificationsPropsSelector = createSelector(
  authSelector,
  (state) => state.journal.userData,
  (state) => state.journal.userDataFetchStatus,
  (authState, journalUserData, userDataFetchStatus) => {
    return {
      fetchStatus: userDataFetchStatus,
      userId: authState.user.id,
      data: journalUserData
    };
  }
);
const Notifications = ({ handleCloseDrawer, flags }) => {
  const { userId, data, fetchStatus } = useSelector(notificationsPropsSelector);
  const userNotificationChannels = useSelector(userNotificationChannelsSelector);
  const userNotificationEvents = useSelector(userNotificationEventsSelector);
  const isUserSubscribedToNotificationsAtMultipleDealers = useSelector(
    isUserSubscribedToNotificationsAtMultipleDealersSelector
  );
  const { isVendor } = useVendorRole();
  const [userNotificationEventsWithFlags, setUserNotificationEventsWithFlags] = useState([]);
  const [isShowNotifications, setIsShowNotifications] = useState(false);
  const dispatch = useDispatch();
  let notifications = isShowNotifications && data && data.items ? data.items : [];
  const showDealerName =
    flags?.reconNotificationsIncludeDealerName && (isVendor || isUserSubscribedToNotificationsAtMultipleDealers);

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.NOTIFICATION_DRAWER_VIEWED);
  useDeepCompareEffect(() => {
    let newUserNotificationEventsWithFlags = userNotificationEvents
      .filter(
        (event) =>
          event.isActive &&
          (flags.reconVendorManagement || event.notificationMessageType !== journalEventTypes.VENDOR_CONFIRMATION)
      )
      .map((e) => e.notificationMessageType);
    if (isVendor) {
      //if vendor login, always includes this notification type
      newUserNotificationEventsWithFlags.push(journalEventTypes.VENDOR_INVITATION_RECIEVED);
    }
    //When reconVendorManagement flag is off, do not include VENDOR_CONFIRMATION event type
    setUserNotificationEventsWithFlags(newUserNotificationEventsWithFlags);
  }, [flags.reconVendorManagement, userNotificationEvents, isVendor]);

  useEffect(() => {
    setIsShowNotifications(userNotificationChannels.includes(IN_APP) && userNotificationEventsWithFlags.length > 0);
  }, [JSON.stringify(userNotificationChannels), JSON.stringify(userNotificationEventsWithFlags)]);

  useEffect(() => {
    if (!isShowNotifications) {
      notifications = [];
      dispatch(journalActions.setUserData({}));
    }
  }, [isShowNotifications]);

  useEffect(() => {
    if (!isShowNotifications) {
      notifications = [];
      dispatch(journalActions.setUserData({}));
    }
  }, [userId, isShowNotifications]);

  return (
    <StyledWrapNotifications>
      {fetchStatus === apiStatusConstants.IS_FETCHING && userNotificationChannels.includes(IN_APP) ? (
        <CircularSpinner size={30} />
      ) : (
        <>
          {notifications.length > 0 ? (
            <StyledList>
              {notifications.map((notification, index) => (
                <Notification
                  notification={notification}
                  index={index}
                  key={notification.notificationId}
                  handleCloseDrawer={handleCloseDrawer}
                  showDealerName={showDealerName}
                />
              ))}
            </StyledList>
          ) : (
            <StyledWrapNoneNotifications>
              <NotificationIconOverride icon={faBell} />
              <StyledNoNotificationStatement>No Notifications</StyledNoNotificationStatement>
              <StyledManageNotificationsStatement>
                Manage the way you get notified in
                <a style={{ color: '#eb002e' }} href="/settings/notifications">
                  {' '}
                  Settings
                </a>
              </StyledManageNotificationsStatement>
            </StyledWrapNoneNotifications>
          )}
        </>
      )}
    </StyledWrapNotifications>
  );
};

const StyledList = styled(List)`
  max-height: calc(100vh - 70px);
  overflow-y: auto;
`;

const StyledNoNotificationStatement = styled.div`
  height: 19px;
  width: 117px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: Roboto;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-left: 138.5px;
  margin-right: 136.5px;
  margin-top: 16px;
`;

const StyledManageNotificationsStatement = styled.div`
  height: 24px;
  width: 344px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fontSizes.md};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
`;

// Overrides the Notification Bell Icon in the notification drawer
const NotificationIconOverride = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.darkGray};
  height: 24px;
  width: 21px;
  font-family: 'Font Awesome 5 Free';
  font-size: ${({ theme }) => theme.fontSizes.xxlg};
  margin-left: 186px;
  margin-right: 185px;
  margin-top: 164px;
  text-align: center;
`;

const StyledWrapNotifications = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .ant-spin {
    align-self: center;
  }
`;

const StyledWrapNoneNotifications = styled.div`
  align-self: flex-start;
`;

export default withLDConsumer()(Notifications);
