import React from 'react';
import styled from 'styled-components';
import { Alert, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';
import { communicationContexts, documentationContexts } from 'app-constants';
import StyledAlertIcon from './StyledAlertIcon';

const { Text } = Typography;
const CustomizedAlert = ({
  type = 'info' || 'success' || 'warning' || 'error',
  showIcon,
  message = 'Please input message here', //string
  detail,
  actionLink, // string
  titleLink = 'See Details', //Link type: https://somekindoflinkhere...
  closable,
  visible,
  afterClose,
  onClose,
  setHasChangeCommunicationInput,
  hasReplyCommunication,
  location
}) => {
  const closeMessageError = () => {
    onClose();
    if (setHasChangeCommunicationInput && !hasReplyCommunication) {
      setHasChangeCommunicationInput(true);
    }
  };
  const marginBasedOnMessage =
    message === documentationContexts.MESSAGE_MAXIMUM_DOCUMENT_SIZE ||
    message === documentationContexts.MESSAGE_EXCEEDS_10_FILES_LIMIT ||
    message === documentationContexts.MESSAGE_MINIMUM_DOCUMENT_SIZE ||
    message === documentationContexts.MESSAGE_INCORRECT_FILE_TYPE
      ? '4px 30px' //In file attach error handle
      : '4px 24px';

  return (
    <>
      {visible ? (
        <StyledAlertWrapper className="alert-wrapper" location={location}>
          <StyledAlert
            message={
              <>
                <Text style={{ color: `${themeStyles.colors.mediumSolidNavy}` }} className="message-content">
                  {message}
                </Text>
                {detail ? detail : null}
                <StyledActionLink typeAlert={type} href={actionLink} target="_blank">
                  {actionLink ? ' ' + titleLink : null}
                </StyledActionLink>
              </>
            }
            closeText={
              closable ? (
                <FontAwesomeIcon icon={faTimes} color={themeStyles.colors.darkGray} className="icon fa-lg" />
              ) : null
            }
            icon={<StyledAlertIcon type={type} />}
            type={type}
            showIcon={showIcon}
            closable={closable}
            afterClose={afterClose}
            onClose={closable ? { closeMessageError } : null}
            style={{
              fontSize: '14px',
              border: '1px solid lightgray',
              paddingRight: '35px',
              margin:
                location === communicationContexts.INVENTORY_PAGE_COMMENT_DRAWER ||
                location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
                location === communicationContexts.VEHICLE_DETAILS_PAGE
                  ? marginBasedOnMessage
                  : '4px 0',
              color: 'inherit',
              backgroundColor: 'white',
              boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.05)'
            }}
          />
        </StyledAlertWrapper>
      ) : null}
    </>
  );
};

const StyledAlertWrapper = styled.div`
  width: 100%;
  position: relative;
  top: 4px;
`;

const StyledActionLink = styled.a.attrs({
  className: 'action-link'
})`
  text-decoration: none;
  font-weight: bold;

  color: ${(props) => {
    switch (props['typeAlert']) {
      case 'success':
        return 'green';
      case 'error':
        return 'red';
      case 'warning':
        return 'orange';
      default:
        return 'inherit';
    }
  }};

  &:hover {
    cursor: pointer;
  }
`;

const StyledAlert = styled(Alert)`
  & {
    visibility: 'visible';
    animation: fade-in 1s;
    & > span.alert-icon-container {
      position: absolute;
      height: 100%;
      width: 40px;
      top: 0;
      left: 0;
    }
    & > .ant-alert-message {
      position: relative;
      left: 12px;
    }
    & > .ant-alert-close-icon {
      right: 10px;
      height: 100%;
      top: 0;
      position: absolute;
      transition: opacity 0.3s linear;
      :hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

export default CustomizedAlert;
