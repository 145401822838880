import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button, Typography } from 'antd';
import { CircularSpinner } from 'components/common';
import { lineItemsActions } from 'store/lineItemsStore';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BodyBase, BodyBaseBoldShort } from 'components/styledComponents';
import StandaloneTask from 'components/TaskGroupsDnd/Task/StandaloneTask';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { OPERATION_LINE_ITEM } from 'app-constants/lineItemConstants';
import { apiStatusConstants, lineItemStatusTypes, features, getEmptyLineItemDescreption } from 'app-constants';
import { useFeatures } from 'hooks';
import { userAppStateActions } from 'store/userAppStateStore';
import AddLineItemFormTemplate from './AddLineItemFormTemplate';
const { Title } = Typography;

const Loading = () => (
  <StyledCenter>
    <CircularSpinner tip="Loading..." size="2rem" />
  </StyledCenter>
);
const Error = () => (
  <StyledCenter>
    <Title type="danger" level={4}>
      Oops, something went wrong
    </Title>
  </StyledCenter>
);

const LineItemsTaskFormTemplate = ({
  editable,
  onUpdate,
  onComplete,
  onDelete,
  vehicleId,
  task,
  setIsDirty,
  setFormProps,
  hidePassthroughIndicator,
  passedInCommunications,
  isInProgressTaskGroup,
  closeForm,
  reconPlanFeatures,
  contentType,
  planStarted
}) => {
  const dispatch = useDispatch();
  const showNoLineItems = useSelector((state) => state.lineItems.showNoLineItems);
  const fetchLineItemsStatus = useSelector((state) => state.lineItems.fetchLineItemsStatus);
  const [hasApproverRole] = useFeatures(features.TASKS_APPROVE_LINE_ITEMS);

  //add a raw line item
  const addLineItem = () => {
    const newLineItems = {
      idTemp: uuidv4(),
      laborCost: '',
      partsCost: '',
      totalCost: '',
      description: '',
      isAdding: true,
      op: OPERATION_LINE_ITEM.ADD,
      status: lineItemStatusTypes.ESTIMATE_REQUESTED,
      reconPlanTemplateTaskId: task?.id || '', // for plan template
      reconTaskTypeId: task?.id || '' // for task template
    };
    dispatch(lineItemsActions.addNeedApproval(newLineItems));
    dispatch(lineItemsActions.hideAddNewLineItem());
    dispatch(lineItemsActions.toggleShowNoLineItems(false));
  };

  useEffect(() => {
    if (!task?.isAddingTask) {
      if (task?.isChangeReconTaskType) {
        if (!!task?.lineItemTemplates?.count) {
          dispatch(lineItemsActions.toggleShowNoLineItems(false));
          dispatch(lineItemsActions.setLineItems(task?.lineItemTemplates?.items));
        }
        dispatch(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
      } else {
        dispatch(lineItemsActions.getLineItemsReconPlanTemplateTask(task.reconPlanTemplateId, task.id));
      }
    } else {
      if (!!task?.lineItemTemplates?.count) {
        dispatch(lineItemsActions.toggleShowNoLineItems(false));
        dispatch(lineItemsActions.setLineItems(task?.lineItemTemplates?.items));
      }
      dispatch(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
    }
    return () => {
      // unmout component => reset line item
      dispatch(lineItemsActions.resetLineItem());
      dispatch(lineItemsActions.resetLineItemForBodySaveRequest());
    };
  }, [task.id]);

  return (
    <>
      <StandaloneTask
        key={task.id}
        editable={editable}
        vehicleId={vehicleId}
        onUpdate={onUpdate}
        onComplete={onComplete}
        onDelete={onDelete}
        closeForm={closeForm}
        task={task}
        planStarted={planStarted}
        setFormProps={setFormProps}
        showEstimates={true}
        hidePassthroughIndicator={hidePassthroughIndicator}
        hideTaskProgressInfo={true}
        hideAssigneeWorkload={false}
        hideLineItemsOverflowMenuItem={true}
        reconPlanFeatures={reconPlanFeatures}
        countMessagesTask={passedInCommunications.length}
        isInProgressTaskGroup={isInProgressTaskGroup}
        contentType={contentType}
        isShowContentSegmentTDP={true}
        showPendingApprovalTime={false}
      />
      {
        {
          [apiStatusConstants.IS_FETCHING]: <Loading />,
          [apiStatusConstants.SUCCEEDED]: (
            <>
              {showNoLineItems ? (
                <EmptyLineItemTemplate
                  addLineItem={addLineItem}
                  isVDP={false}
                  buttonTitle={getEmptyLineItemDescreption(hasApproverRole).text}
                  description={getEmptyLineItemDescreption(hasApproverRole).descriptions}
                ></EmptyLineItemTemplate>
              ) : (
                <AddLineItemFormTemplate
                  hasPermissionApprovedDecline={hasApproverRole}
                  setIsDirty={setIsDirty}
                  task={task}
                  contentType={contentType}
                />
              )}
            </>
          ),
          [apiStatusConstants.FAILED]: <Error />
        }[fetchLineItemsStatus]
      }
    </>
  );
};

export const EmptyLineItemTemplate = ({ addLineItem, description, buttonTitle, isVDP = false }) => {
  const dispatch = useDispatch();
  const displayedEmptyLineItemPage = useSelector((state) => state.userAppState?.data?.displayEmptyLineItemPage);
  const oidcState = useSelector((state) => state.oidc);
  useEffect(() => {
    if (isVDP && !displayedEmptyLineItemPage)
      dispatch(userAppStateActions.updateDisplayEmptyLineItemPage(oidcState.user.profile.recon_user_id));
  }, []);
  return (
    <EmptyLineItemsBlock>
      <FontAwesomeIcon icon={faTasks} />
      <div className="title-empty">
        <BodyBaseBoldShort>No Line Items</BodyBaseBoldShort>
      </div>
      <div className="desc-empty">
        <BodyBase>{description}</BodyBase>
      </div>
      <StyledAddButton isVDP={isVDP} onClick={addLineItem}>
        <BodyBaseBoldShort>{buttonTitle}</BodyBaseBoldShort>
      </StyledAddButton>
    </EmptyLineItemsBlock>
  );
};

const EmptyLineItemsBlock = styled.div`
  padding: 50px 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-empty {
    margin: 16px 0;
    span {
      color: ${({ theme }) => theme.colors.darkGray};
    }
  }

  .desc-empty {
    margin-bottom: 24px;
    text-align: center;
    span {
      color: ${({ theme }) => theme.colors.darkGray};
    }
  }

  .svg-inline--fa {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const StyledAddButton = styled(Button).attrs({
  type: 'danger'
})`
  &.ant-btn-danger {
    height: 36px;
    min-width: ${({ isVDP }) => (isVDP ? '136px' : '170px')};
    border-radius: 4px;
  }
`;

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: 100%;
`;

export default withLDConsumer()(LineItemsTaskFormTemplate);
