import React, { useState } from 'react';
import {
  faCheckCircle,
  faClipboardList,
  faBan,
  faCommentDots,
  faTasks,
  faIdBadge,
  faTimesCircle,
  faCar
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { formatDateTime, formatDateWithYear, formatTime, formartMMMDDYYYY } from 'utils/dateTimeUtils';
import { Popover, Row, Col, Typography, Tooltip, List } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme as themeStyles } from 'styles/appTheme';
import { CopyOnClickText } from 'components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { journalEventTypes } from 'app-constants';
import TruncatedTooltipOneLine from '../../../common/TruncatedTooltipOneLine';
const { Text } = Typography;

const UserInfo = ({ userName, groupName, isVendorTask }) => {
  if (!groupName || groupName === '') {
    return <strong>{userName}</strong>;
  }
  if (isVendorTask) {
    return <strong>{userName && userName !== '' ? `${groupName} (${userName})` : groupName}</strong>;
  }
  return (
    <strong>
      {userName}&nbsp;{groupName}
    </strong>
  );
};

const VehicleHistoryDetail = ({ vehicleHistoryEvent, vehicle, flags }) => {
  const GetVehicleHistoryMessage = (historyEvent, vehicle) => {
    let messageText;
    let relatedToGroupName = historyEvent.relatedToGroupName ? `(${historyEvent.relatedToGroupName})` : '';
    let relatedToName = historyEvent.relatedToName ? historyEvent.relatedToName : 'Unassigned';
    let isVendorTask = false;
    let isMemberOfRelatedGroup = historyEvent.isMemberOfRelatedGroup;
    if (
      flags.reconVendorManagement &&
      historyEvent.relatedToGroupType === 'VENDOR' &&
      historyEvent.relatedToGroupName
    ) {
      relatedToGroupName = historyEvent.relatedToGroupName;
      relatedToName = historyEvent.relatedToName || '';
      isVendorTask = true;
      // incase API get problem with data, if the user type equal VENDOR_USER then that user has to be member of related vendor
      if (!historyEvent.isMemberOfRelatedGroup && historyEvent.journalCreatedByUserType === 'VENDOR_USER') {
        isMemberOfRelatedGroup = true;
      }
    }

    const checkedGroupName = isMemberOfRelatedGroup ? relatedToGroupName : '';
    const checkedUserInfo = (
      <UserInfo isVendorTask={isVendorTask} userName={historyEvent.journalCreatedByName} groupName={checkedGroupName} />
    );
    const relatedUserInfo = (
      <UserInfo isVendorTask={isVendorTask} userName={relatedToName} groupName={relatedToGroupName} />
    );

    switch (historyEvent.reconJournalEventType) {
      case journalEventTypes.TASK_ADDED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;added a&nbsp;
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task
          </span>
        );
        break;
      case journalEventTypes.TASK_READY:
        messageText = (
          <span>
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task ready for work
          </span>
        );
        break;
      case journalEventTypes.TASK_ASSIGNED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;assigned a&nbsp;
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task to&nbsp;
            {relatedUserInfo}
          </span>
        );
        break;
      case journalEventTypes.TASK_REASSIGNED:
        if (historyEvent.relatedToUserId === historyEvent.journalCreatedBy) {
          messageText = (
            <span>
              <UserInfo
                isVendorTask={isVendorTask}
                userName={historyEvent.journalCreatedByName}
                groupName={relatedToGroupName}
              />
              &nbsp;assigned a&nbsp;
              <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task to themself
            </span>
          );
        } else {
          messageText = (
            <span>
              {isVendorTask && isMemberOfRelatedGroup ? (
                <UserInfo
                  isVendorTask={isVendorTask}
                  userName={historyEvent.journalCreatedByName}
                  groupName={relatedToGroupName}
                />
              ) : (
                <strong>{historyEvent.journalCreatedByName}</strong>
              )}
              &nbsp;re-assigned a&nbsp;
              <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task to&nbsp;
              <UserInfo isVendorTask={isVendorTask} userName={relatedToName} groupName={relatedToGroupName} />
            </span>
          );
        }
        break;
      case journalEventTypes.TASK_COMPLETED:
        messageText = (
          <span>
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task completed by&nbsp;{checkedUserInfo}
          </span>
        );
        break;
      case journalEventTypes.TASK_COMPLETED_BY_DEALER:
        messageText = (
          <span>
            <UserInfo
              isVendorTask={isVendorTask}
              userName={historyEvent.journalCreatedByName}
              groupName={isMemberOfRelatedGroup && !!historyEvent.completedByGroupId ? relatedToGroupName : ''}
            />
            &nbsp;completed a&nbsp;
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task on behalf of&nbsp;
            {relatedUserInfo}
          </span>
        );
        break;
      case journalEventTypes.TASK_CANCELED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;canceled a&nbsp;
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task
          </span>
        );
        break;
      case journalEventTypes.TASK_DECLINED:
        messageText = (
          <span>
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task declined by&nbsp;{checkedUserInfo}
          </span>
        );
        break;
      case journalEventTypes.TASK_DEFERRED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;deferred a&nbsp;
            <strong>{historyEvent.reconTaskTypeName}</strong>&nbsp;task
          </span>
        );
        break;
      case journalEventTypes.TASK_COMMENT_ADDED:
        messageText = (
          <span>
            {checkedUserInfo}&nbsp;sent a message to&nbsp;<strong>{historyEvent.reconTaskTypeName}</strong>
          </span>
        );
        break;
      case journalEventTypes.TASK_COMMENT_UPDATED:
        messageText = (
          <span>
            {checkedUserInfo}&nbsp;updated a message for a&nbsp;<strong>{historyEvent.reconTaskTypeName}</strong>
            &nbsp;task
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_RECON_STARTED:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;has started recon
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_INVENTORY_ADDED:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;added to inventory
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_COMMENT_ADDED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;created a vehicle note for&nbsp;
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_FRONT_LINE_READY:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;completed recon and is front line ready
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_ARCHIVED:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;was archived by&nbsp;<strong>{historyEvent.journalCreatedByName || 'an automated process'}</strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_UNARCHIVED:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;was unarchived by&nbsp;<strong>{historyEvent.journalCreatedByName || 'an automated process'}</strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_EXCLUDED:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;was excluded from recon by&nbsp;
            <strong>{historyEvent.journalCreatedByName || 'an automated process'}</strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_UNEXCLUDED:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;was unexcluded and made available for recon by&nbsp;
            <strong>{historyEvent.journalCreatedByName || 'an automated process'}</strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_SOLD:
        messageText = (
          <span>
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
            &nbsp;was marked sold in Provision
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_COMMENT_UPDATED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;updated a vehicle note for&nbsp;
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_DOCUMENT_ADD:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;attached {historyEvent.documentFileName} to&nbsp;
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_DOCUMENT_DELETE:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong>&nbsp;deleted a {historyEvent.documentFileName}{' '}
            from&nbsp;
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
          </span>
        );
        break;
      case journalEventTypes.TASK_DOCUMENT_ADD:
        messageText = (
          <span>
            {checkedUserInfo}&nbsp;added a document to&nbsp;<strong>{historyEvent.reconTaskTypeName}</strong>
          </span>
        );
        break;
      case journalEventTypes.TASK_DOCUMENT_DELETE:
        messageText = (
          <span>
            {checkedUserInfo}&nbsp;deleted a document from&nbsp;<strong>{historyEvent.reconTaskTypeName}</strong>
          </span>
        );
        break;
      case journalEventTypes.LINE_ITEM_UPDATED:
        messageText = (
          <span>
            {checkedUserInfo}&nbsp;made updates to&nbsp;<strong>{historyEvent.reconTaskTypeName}</strong> task line
            items.
          </span>
        );
        break;
      case journalEventTypes.VEHICLE_COMMENT_USER_TAGGED:
        messageText = (
          <span>
            <strong>{historyEvent.journalCreatedByName}</strong> tagged <strong>{getTaggedUsers(historyEvent)}</strong>
            &nbsp;in a vehicle note for&nbsp;
            <strong>
              <CopyOnClickText text={vehicle.stockNumber} />
            </strong>
          </span>
        );
        break;
      case journalEventTypes.TASK_COMMENT_USER_TAGGED:
        messageText = (
          <span>
            {isVendorTask ? checkedUserInfo : <strong>{historyEvent.journalCreatedByName}</strong>}
            &nbsp;tagged&nbsp;
            <strong>{getTaggedUsers(historyEvent)}</strong>
            &nbsp;in a message to&nbsp;
            <strong>{historyEvent.reconTaskTypeName}</strong>
          </span>
        );
        break;
      default:
        messageText = (
          <span>
            <strong>{historyEvent.reconJournalEventType}</strong>
          </span>
        );
        break;
    }
    return messageText;
  };

  const getTaggedUsers = (historyEvent) => {
    let taggedUsers = '';
    if (historyEvent?.data?.taggedUsers) {
      taggedUsers = historyEvent?.data?.taggedUsers.map((u) => u.name).join(', ');
    }
    return taggedUsers;
  };

  const GetVehicleEventIcon = (historyEvent) => {
    switch (historyEvent.reconJournalEventType) {
      case journalEventTypes.VEHICLE_INVENTORY_ADDED:
        return faCar;
      case journalEventTypes.VEHICLE_RECON_PLAN_ADDED:
        return faClipboardList;
      case journalEventTypes.VEHICLE_FRONT_LINE_READY:
        return faCheckCircle;
      case journalEventTypes.VEHICLE_COMMENT_ADDED:
        return faCommentDots;
      case journalEventTypes.VEHICLE_DOCUMENT_ADD:
        return faFileAlt;
      case journalEventTypes.VEHICLE_DOCUMENT_DELETE:
        return faFileAlt;
      case journalEventTypes.TASK_DOCUMENT_ADD:
        return faFileAlt;
      case journalEventTypes.TASK_DOCUMENT_DELETE:
        return faFileAlt;
      case journalEventTypes.VEHICLE_COMMENT_USER_TAGGED:
        return faCommentDots;
      default:
        return faCar;
    }
  };

  const GetTaskEventIcon = (historyEvent) => {
    if (historyEvent.reconJournalEventType === journalEventTypes.TASK_CANCELED) return faTimesCircle;
    if (historyEvent.reconJournalEventType === journalEventTypes.TASK_DECLINED) return faBan;
    if (historyEvent.reconJournalEventType === journalEventTypes.TASK_COMMENT_ADDED) return faCommentDots;
    if (
      historyEvent.reconJournalEventType === journalEventTypes.TASK_DOCUMENT_ADD ||
      historyEvent.reconJournalEventType === journalEventTypes.TASK_DOCUMENT_DELETE
    )
      return faFileAlt;
    if (historyEvent.reconJournalEventType === journalEventTypes.LINE_ITEM_UPDATED) return faTasks;
    if (historyEvent.reconJournalEventType === journalEventTypes.TASK_COMMENT_USER_TAGGED) return faCommentDots;

    return faIdBadge;
  };

  const RedIcons = [journalEventTypes.TASK_CANCELED, journalEventTypes.TASK_DECLINED];

  const hoverTooltipContent = (date) => (
    <div style={{ width: '135px' }}>
      <Row type="flex" justify="center">
        <Col>
          <Text>{formatDateWithYear(date)}</Text>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Text>at {formatTime(date)}</Text>
        </Col>
      </Row>
    </div>
  );

  // Should this event have an alert color
  const IsAlertColor = (eventType) => {
    return RedIcons.indexOf(eventType) > -1;
  };

  const GetHistoryIcon = (historyEvent) => {
    return historyEvent?.reconTaskId != null ? GetTaskEventIcon(historyEvent) : GetVehicleEventIcon(historyEvent);
  };

  const HistoryIcon = ({ historyEvent }) => {
    const iconColor = IsAlertColor(historyEvent.reconJournalEventType)
      ? themeStyles.colors.red
      : themeStyles.colors.navy;
    return <Icon className="fa-fw" color={iconColor} icon={GetHistoryIcon(vehicleHistoryEvent)} />;
  };
  const [showMore, setShowMore] = useState(false);
  const isLineItemUpdateEvent = journalEventTypes.LINE_ITEM_UPDATED === vehicleHistoryEvent.reconJournalEventType;
  return (
    <StyledListItem>
      <VehicleHistoryItemRow>
        <VehicleHistoryItemContainer>
          <Details>
            <IconContainer>
              <HistoryIcon historyEvent={vehicleHistoryEvent} />
            </IconContainer>
            <TruncatedTooltipOneLine
              TypographyComponentProps={{
                style: {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textAlign: 'left',
                  width: '100%',
                  paddingRight: '5px',
                  fontSize: ({ theme }) => theme.fontSizes.xs
                }
              }}
              lineItemFieldClassName="line-item-field"
              TooltipComponent={Tooltip}
              ToolTipComponentProps={{
                placement: 'top',
                overlayStyle: { fontWeight: '400' },
                overlayClassName: 'statistic-tooltip'
              }}
            >
              {GetVehicleHistoryMessage(vehicleHistoryEvent, vehicle)}
            </TruncatedTooltipOneLine>
          </Details>
          {flags.reconHistoryUpdate ? (
            <Popover content={hoverTooltipContent(vehicleHistoryEvent.journalCreatedOn)} trigger="hover">
              <StyledDatetime>{formartMMMDDYYYY(vehicleHistoryEvent.journalCreatedOn)}</StyledDatetime>
            </Popover>
          ) : (
            <DateContainer>{formatDateTime(vehicleHistoryEvent.journalCreatedOn)}</DateContainer>
          )}
        </VehicleHistoryItemContainer>
        {!showMore && isLineItemUpdateEvent && vehicleHistoryEvent.data?.lineItemUpdateMessages?.length ? (
          <StyledExpand onClick={() => setShowMore(true)}>
            See {vehicleHistoryEvent.data?.lineItemUpdateMessages?.length} update
            {vehicleHistoryEvent.data?.lineItemUpdateMessages?.length > 1 ? 's' : ''}
          </StyledExpand>
        ) : null}
        {showMore && vehicleHistoryEvent.data?.lineItemUpdateMessages?.length ? (
          <ExpandedContent>
            {vehicleHistoryEvent.data?.lineItemUpdateMessages?.map((log, i) => (
              <div key={vehicleHistoryEvent.reconJournalId + '_ExpandedContent'}>{log}</div>
            ))}
          </ExpandedContent>
        ) : null}
      </VehicleHistoryItemRow>
    </StyledListItem>
  );
};

//#region Styled Components
const VehicleHistoryItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const IconContainer = styled.div`
  padding-right: 15px;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;
const Details = styled.div`
  display: flex;
  width: 80%;
`;
const DateContainer = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  white-space: nowrap;
  padding-left: 15px;
`;
const StyledListItem = styled(List.Item)`
  .ant-list-item& {
    margin: 12px 24px;
  }
`;
const StyledDatetime = styled(Text).attrs({
  ellipsis: true
})`
  .ant-typography& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.darkGray};
    white-space: nowrap;
    -ms-flex: 1 1 0px;
    text-align: right;
  }
`;
const ExpandContentMarginleft = styled.div`
  margin-left: 35px;
`;
const ExpandedContent = styled(ExpandContentMarginleft)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
const StyledExpand = styled(ExpandContentMarginleft)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
  font-weight: 500;
`;
const VehicleHistoryItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
//#endregion

export default withLDConsumer()(VehicleHistoryDetail);
