// makeActionCreator will create you a function that returns a redux action object
// signature of a redux action object: { type: 'ACTION_TYPE', payload1: payload1, payload2: payload2, ... }
// Ex: const exampleFunction = makeActionCreator('ACTION_TYPE', 'payload1', 'payload2') would create you this function 
// const exampleFunction = (payload1, payload2) => { type: 'ACTION_TYPE', payload1, payload2 }

export default (type, ...argNames) => {
  return function (...args) {
    let action = { type };
    argNames.forEach((argName, i) => {
      action[argName] = args[i];
    });

    return action;
  }
}