import React, { useContext } from 'react';
import styled from 'styled-components';
import { TaskContext } from './helpers/context';
import { COMPLETED, DECLINED, IN_PROGRESS, PENDING } from 'app-constants/taskStatusTypes';
import { OVERDUE, PAST_GOAL } from 'app-constants/taskProgressTypes';
import { RECON_PLAN } from 'app-constants/planTypes';
import { detailContents } from 'app-constants';
import { CustomizedTaskIcon, TaskSequenceIcon } from 'components/layout/tasks/icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

/**
 * StepSegment component
 * HOC which has branching logic to determine whether to show the StepSegment component for a recon task or for template task depending on the value of planSource
 */
const StepSegment = ({ disableIconHover = false }) => {
  /**
   * @constant {string} planSource - set to RECON_PLAN or TEMPLATE_PLAN depending on which plan is loaded - set up in useEffect in either of those components and passed into dndData which is passed into DragDropContext
   */
  const { planSource } = useContext(TaskContext);
  return planSource === RECON_PLAN ? (
    <StepSegmentForReconTask disableIconHover={disableIconHover} />
  ) : (
    <StepSegmentForTemplateTask disableIconHover={disableIconHover} />
  );
};

const determineReconTaskStatusUS510688 = (planStarted, task, taskProgressStatus, isInProgressTaskGroup, flags) => {
  if (planStarted) {
    if (task.declined) return DECLINED;
    if ([COMPLETED, DECLINED].includes(task.status)) return task.status;
    if (isInProgressTaskGroup) {
      if ([OVERDUE, PAST_GOAL].includes(taskProgressStatus)) return taskProgressStatus;
      return IN_PROGRESS;
    }
    // if task isn't overdue, past goal or declined, and it is an in progress task, then the task is on track and show sequence number
    return PENDING;
  } else {
    return PENDING;
  }
};

/**
 * StepSegmentForReconTask
 * for Recon Task creation - segment of Task Component that will show step in plan
 */
export const StepSegmentForReconTask = withLDConsumer()(({ customContent = null, disableIconHover, flags }) => {
  /**
   * @constant {object} task - task dto that is passed to each Task component instance via a prop and is accessible here through TaskContext set up Task component file
   * @constant {object} taskProgress - taskProgress calculated in getReconPlanData in Task component file
   * @constant {boolean} isTask - value calculated in DraggableItem - modelType === TASK - is the Task a single task? needed here because the correct sequence value to display will be based on whether or not the task is in a task group or is a single task step
   * @constant {planStarted} planStarted - boolean set in ReconPlan when the recon plan has started - passed to TaskGroupsDnd via dndData and DragDropContext
   * @constant {boolean} expand - set up in DraggableItem - its value is true when a task is being dragged over another a single task, and it is false for everything else. passed in here because we want to make the background color for the TaskSequenceIcon transparent if expand is true
   * @constant {boolean} isInProgressTaskGroup - boolean that is passed to each Task component instance via a prop and is accessible here through TaskContext
   */
  const {
    task,
    taskProgress,
    isTask,
    isStandaloneTask,
    planStarted,
    expand,
    isInProgressTaskGroup,
    contentType
  } = useContext(TaskContext);

  const taskProgressStatus = taskProgress?.status;
  const status = determineReconTaskStatusUS510688(planStarted, task, taskProgressStatus, isInProgressTaskGroup, flags);

  return (
    <StyledStepContainer recon-task-collapse={flags.reconTaskCollapse}>
      {(() => {
        //show icon for completed, overdue, past goal or declined tasks
        //show sequence number for pending or in progress on track tasks
        switch (status) {
          case COMPLETED:
          case OVERDUE:
          case PAST_GOAL:
          case DECLINED:
            return (
              <StyledIconContainer recon-task-collapse={flags.reconTaskCollapse}>
                <CustomizedTaskIcon
                  task={task}
                  backgroundIcon={flags.reconTaskCollapse && null}
                  customContent={customContent}
                  disableIconHover={disableIconHover}
                  isShowTaskClipboardIcon={contentType === detailContents.TASK_DETAILS}
                />
              </StyledIconContainer>
            );
          default:
            return (
              <StyledIconContainer recon-task-collapse={flags.reconTaskCollapse}>
                {isStandaloneTask ? (
                  <CustomizedTaskIcon
                    task={task}
                    customContent={customContent}
                    disableIconHover={disableIconHover}
                    isShowTaskClipboardIcon={contentType === detailContents.TASK_DETAILS}
                  /> //If showing the task by itself (outside context of whole plan), don't show it's sequence number, show the icon (usually a clipboard)
                ) : (
                  <TaskSequenceIcon
                    task={{ ...task, status }}
                    progress={taskProgress}
                    isTask={isTask}
                    expand={expand}
                    customContent={customContent}
                    disableIconHover={disableIconHover}
                  />
                )}
              </StyledIconContainer>
            );
        }
      })()}
    </StyledStepContainer>
  );
});

// segment of Task Component that will show step in plan - FOR TEMPLATE TASK ONLY
// template tasks aren't worked on, so they can only show a sequence value in a plan
const StepSegmentForTemplateTask = withLDConsumer()(({ disableIconHover, flags }) => {
  const { task, isTask } = useContext(TaskContext);
  return (
    <StyledStepContainer recon-task-collapse={flags.reconTaskCollapse}>
      <StyledIconContainer recon-task-collapse={flags.reconTaskCollapse}>
        <TaskSequenceIcon task={task} isTask={isTask} disableIconHover={disableIconHover} />
      </StyledIconContainer>
    </StyledStepContainer>
  );
});

//#region Styled Components
const StyledStepContainer = styled.div.attrs((props) => ({
  className: !props['recon-task-collapse'] && 'center-content'
}))`
  flex: 1 1 0px;
  margin: ${(props) => !props['recon-task-collapse'] && '0 16px'};
`;
const StyledIconContainer = styled.div`
  justify-content: ${(props) => !props['recon-task-collapse'] && 'center'};
  padding-top: ${(props) => !props['recon-task-collapse'] && '10px'};
  width: ${(props) => !props['recon-task-collapse'] && '36px'};
`;
//#endregion

export default withLDConsumer()(StepSegment);
