import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input, Menu, Modal, Tooltip, AutoComplete } from 'antd';
import { createSelector } from 'reselect';
import { faCheck, faEdit, faEllipsisH, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatterNumberUSD } from 'utils/numberUtils';
import { OPERATION_LINE_ITEM, LIMIT_COST, LIMIT_TOTAL_COST } from 'app-constants/lineItemConstants';
import { lineItemStatusTypes, detailContents } from 'app-constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  BodyBaseBoldShort,
  BodySmall,
  BodyXSmallBold,
  CommonLinkButton,
  CommonRedButton
} from 'components/styledComponents';
import TruncatedTooltipOneLine from '../../../../common/TruncatedTooltipOneLine';

import { lineItemsActions } from 'store/lineItemsStore';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import useVendorRole from 'hooks/useVendorRole';
import { useTaskReadOnlyStatus } from '../../../../../hooks/useTaskReadOnlyStatus';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { getFractionDigits, isPassedNumberFormat } from 'utils/regexUtils';

const ButtonGroup = Button.Group;

// Sort line items by action date from oldest to newest
const compareItems = (listName) => (firstLineItem, secondLineItem) => {
  let firstDateString = '';
  let secondDateString = '';
  switch (listName) {
    case 'needApproval':
      firstDateString = firstLineItem.createdOn ?? '';
      secondDateString = secondLineItem.createdOn ?? '';
      break;
    case 'approved':
      firstDateString = firstLineItem.approvedOn ?? '';
      secondDateString = secondLineItem.approvedOn ?? '';
      break;
    case 'declined':
      firstDateString = firstLineItem.declinedOn ?? '';
      secondDateString = secondLineItem.declinedOn ?? '';
      break;
    default:
      break;
  }

  let firstTimeSince1970 = firstDateString !== '' ? new Date(firstDateString).getTime() : null;
  let secondTimeSince1970 = secondDateString !== '' ? new Date(secondDateString).getTime() : null;

  // If both line items has no action date, we don't need to sort anymore.
  // Just move this line item to end of the destination list
  if (firstTimeSince1970 === null && secondTimeSince1970 === null) return;

  // If 1 of 2 line items has action date,
  // We will set action date for line item without action date with new Date() to perform sorting.
  // Because the sorting logic is oldest to newest, the old line item is still sorted at the top.
  if (firstTimeSince1970 !== null && secondTimeSince1970 === null) {
    secondTimeSince1970 = new Date().getTime();
  } else if (firstTimeSince1970 === null && secondTimeSince1970 !== null) {
    firstTimeSince1970 = new Date().getTime();
  }

  let comparison = 0;
  if (firstTimeSince1970 > secondTimeSince1970) {
    comparison = 1;
  } else if (firstTimeSince1970 < secondTimeSince1970) {
    comparison = -1;
  } else {
    comparison = firstDateString > secondDateString ? 1 : -1;
  }
  return comparison;
};

const AddLineItem = withLDConsumer()(({ hasPermissionApprovedDecline, task, contentType, flags }) => {
  const dispatch = useDispatch();
  const isShowAddLineItem = useSelector((state) => state.lineItems.isShowAddLineItem);
  const needApproval = useSelector((state) => state.lineItems.needApproval);
  const approved = useSelector((state) => state.lineItems.approved);
  const needApprovalErrorMessage = useSelector((state) => state.lineItems.needApprovalErrorMessage);
  const originalLineItemsList = useSelector((state) => state.lineItems.originalLineItemsList);
  const isReadonlyTask = useTaskReadOnlyStatus(task.id, flags.reconVendorManagement);
  const { isHQ } = useContext(HQAndProfitTimeContext);
  // Always show add line item button when there are no line items
  useEffect(() => {
    if (
      ((originalLineItemsList.length !== 0 && contentType === detailContents.TASK_DETAILS) ||
        contentType === detailContents.VEHICLE_DETAILS ||
        contentType === detailContents.RECON_PLAN) &&
      (needApproval.filter((item) => !item.isAdding).length !== 0 || needApproval.length === 0)
    ) {
      dispatch(lineItemsActions.showAddNewLineItem());
    }
  }, []);

  // Remove error when the approve button gone
  useEffect(() => {
    if (needApproval.filter((item) => item?.totalCost?.toString()).length < 2) {
      dispatch(lineItemsActions.setNeedApprovalErrorMessage(''));
    }
  }, [needApproval]);

  // add a raw lineitem
  const addNewLineItem = () => {
    dispatch(lineItemsActions.setEditingLineItem(true));
    const newLineItems = {
      idTemp: uuidv4(),
      laborCost: '',
      partsCost: '',
      totalCost: '',
      description: '',
      isAdding: true,
      op: OPERATION_LINE_ITEM.ADD,
      status: lineItemStatusTypes.ESTIMATE_REQUESTED,
      entityId: task?.id || '',
      entityType: 'TASK'
    };
    dispatch(lineItemsActions.addNeedApproval(newLineItems));
    dispatch(lineItemsActions.hideAddNewLineItem());
  };

  const onApprovedAll = () => {
    dispatch(lineItemsActions.setNeedApprovalErrorMessage(''));
    // Sum of all total cost by need approval
    const needApprovalCost = needApproval
      .filter((item) => item?.totalCost?.toString())
      .map((item) => Number(item.totalCost))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // Sum of all total cost by approved
    const approvedCost = approved
      .map((item) => Number(item.totalCost))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // Check if approved cost over 200000. Display message
    if (Number(approvedCost) + Number(needApprovalCost) > 200000) {
      dispatch(lineItemsActions.resetErrorMessageByList('needApproval'));
      dispatch(lineItemsActions.setNeedApprovalErrorMessage('Approved total cannot exceed $200,000'));
    } else {
      dispatch(lineItemsActions.approvedAll());
      needApproval.forEach((item) => {
        if (!!item?.totalCost?.toString()) {
          dispatch(
            lineItemsActions.updateLineItemForBodySaveRequest({
              ...item,
              op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
              status: lineItemStatusTypes.APPROVED
            })
          );
        }
      });
    }
  };

  return (
    isShowAddLineItem &&
    !isHQ && (
      <>
        {hasPermissionApprovedDecline &&
          (needApproval.length > 0 ? (
            needApproval.filter((item) => item?.totalCost?.toString()).length >= 2 ? (
              <ApproveAllWrapper>
                <ApproveAllButton
                  disabled={needApproval.filter((item) => item.isEditing || item.isAdding).length > 0}
                  ghost={true}
                  onClick={onApprovedAll}
                >
                  Approve All
                </ApproveAllButton>
              </ApproveAllWrapper>
            ) : null
          ) : null)}
        <AddLineItemsWrapper>
          <div className={isReadonlyTask ? 'hard-disabled' : ''}>
            <AddLineItemButton
              onClick={addNewLineItem}
              disabled={needApproval.filter((item) => item.isEditing || item.isAdding).length > 0}
            >
              {hasPermissionApprovedDecline ? '+ Add Line Item' : '+ Request Line Item'}
            </AddLineItemButton>
          </div>
          {needApprovalErrorMessage ? <ErrorMessage no-margin={true}>{needApprovalErrorMessage}</ErrorMessage> : null}
        </AddLineItemsWrapper>
      </>
    )
  );
});

/**
 * hasPermissionApprovedDecline: false if the user does not have the approver role
 * @param {*} param0
 * @returns
 */
const LineItemForm = withLDConsumer()(
  ({
    lineItem,
    listName,
    task,
    hasPermissionApprovedDecline,
    isForCompletedTaskForm = false,
    approvedList,
    isForViewingCompletedTaskTDP = false,
    contentType,
    flags
  }) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState(lineItem.description);
    const [isDirtyDescription, setIsDirtyDescription] = useState(false);
    const [isDirtyTotalCost, setIsDirtyTotalCost] = useState(false);
    const [partsCost, setPartsCost] = useState(lineItem?.partsCost?.toString() || '');
    const [laborCost, setLaborCost] = useState(lineItem?.laborCost?.toString() || '');
    const [totalCost, setTotalCost] = useState(lineItem?.totalCost?.toString() || '');
    const [isDisableTotalCost, setIsDisableTotalCost] = useState(lineItem.isDisableTotalCost);
    const [isDisableCost, setIsDisableCost] = useState(lineItem.isDisableCost);
    const lineItemCatalog = useSelector((state) => state.lineItemCatalog?.lineItemCatalog);
    let lineItemFieldClassName = 'line-item-field';
    let partsFieldClassName = 'parts-field';
    let laborFieldClassName = 'labor-field';
    let totalFieldClassName = 'total-field';

    useEffect(() => {
      setIsDisableTotalCost(!!(laborCost || partsCost));
      setIsDisableCost(!!(!laborCost && !partsCost && totalCost));
    }, [lineItem]);

    const lineItemCatalogOptions = lineItemCatalog?.map((i) => ({ value: i.id, text: i.description }));
    const isVDPorTDP = contentType === detailContents.TASK_DETAILS || contentType === detailContents.VEHICLE_DETAILS;
    // add new lineitem
    const onUpdateItem = (item) => {
      if (listName === 'approved') {
        const approvedCost = approvedList
          .filter((x) => x.idTemp !== item.idTemp)
          .map((item) => Number(item.totalCost))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        if (Number(approvedCost) + Number(totalCost) > 200000) {
          dispatch(lineItemsActions.setErrorItem('approved', item, 'Approved total cannot exceed $200,000'));
          return;
        }
      }
      dispatch(lineItemsActions.setEditingLineItem(false));
      let newItem = {
        ...item,
        isAdding: false,
        isEditing: false,
        description,
        partsCost: partsCost,
        laborCost: laborCost,
        totalCost: totalCost,
        isDisableTotalCost: isDisableTotalCost,
        isDisableCost: isDisableCost,
        op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
        status: [lineItemStatusTypes.DECLINED, lineItemStatusTypes.APPROVED].includes(item.status)
          ? item.status
          : !!totalCost.toString()
          ? lineItemStatusTypes.PENDING
          : lineItemStatusTypes.ESTIMATE_REQUESTED,
        entityId: task?.id || '',
        entityType: 'TASK'
      };
      dispatch(lineItemsActions.updateLineItem(newItem, listName));
      dispatch(lineItemsActions.showAddNewLineItem());
      dispatch(lineItemsActions.updateLineItemForBodySaveRequest(newItem));
      setIsDisableCost(false);
      dispatch(lineItemsActions.setDiscardLineItem(true));
    };

    const onCancelAddItem = (item) => {
      dispatch(lineItemsActions.setEditingLineItem(false));
      if (item.isEditing) {
        dispatch(
          lineItemsActions.updateLineItem(
            {
              ...item,
              isEditing: false
            },
            listName
          )
        );
        setIsDisableCost(false);
        return;
      }
      if (item.isAdding) {
        dispatch(lineItemsActions.deleteNeedApproval(item.idTemp));
        dispatch(lineItemsActions.showAddNewLineItem());
        setIsDisableCost(false);
      }
    };

    const onChangePartsCost = (event) => {
      dispatch(lineItemsActions.setDiscardLineItem(true));
      !hasPermissionApprovedDecline && setIsDirtyTotalCost(true);
      if (isPassedNumberFormat(event.target.value)) {
        const newPartsCost = event.target.value > LIMIT_COST ? LIMIT_COST : event.target.value;
        setPartsCost(newPartsCost);
        let sum = '';
        if (newPartsCost || laborCost) {
          sum = Number(newPartsCost) + Number(laborCost);
          setTotalCost(
            Number.isInteger(sum) ? sum.toString() : sum.toFixed(getFractionDigits(event.target.value, laborCost))
          );
          setIsDisableTotalCost(true);
        } else {
          setTotalCost('');
          setIsDisableTotalCost(false);
        }
      }
    };

    const onChangeLaborCost = (event) => {
      dispatch(lineItemsActions.setDiscardLineItem(true));
      !hasPermissionApprovedDecline && setIsDirtyTotalCost(true);
      if (isPassedNumberFormat(event.target.value)) {
        const newLaborCost = event.target.value > LIMIT_COST ? LIMIT_COST : event.target.value;
        setLaborCost(newLaborCost);
        let sum = '';
        if (newLaborCost || partsCost) {
          sum = Number(newLaborCost) + Number(partsCost);
          setTotalCost(
            Number.isInteger(sum) ? sum.toString() : sum.toFixed(getFractionDigits(event.target.value, partsCost))
          );
          setIsDisableTotalCost(true);
        } else {
          setTotalCost('');
          setIsDisableTotalCost(false);
        }
      }
    };

    const onChangeTotalCost = (event) => {
      setIsDisableCost(!!String(event.target.value));
      !hasPermissionApprovedDecline && setIsDirtyTotalCost(true);
      dispatch(lineItemsActions.setDiscardLineItem(true));
      if (isPassedNumberFormat(event.target.value)) {
        setTotalCost(event.target.value > LIMIT_TOTAL_COST ? LIMIT_TOTAL_COST : event.target.value);
      }
    };

    const onChangeDescription = (data) => {
      dispatch(lineItemsActions.setDiscardLineItem(true));
      if (!isDirtyDescription) {
        setIsDirtyDescription(true);
      }
      flags.reconLineItemCatalog ? setDescription(data) : setDescription(data.target.value);
    };

    function onLineItemCatalogSelect(value) {
      const selectedCatalog = lineItemCatalog?.find((x) => x.id === value);
      if (selectedCatalog) {
        setDescription(selectedCatalog.description);
        setPartsCost(selectedCatalog.partsCost !== undefined ? selectedCatalog.partsCost.toString() : '');
        setLaborCost(selectedCatalog.laborCost !== undefined ? selectedCatalog.laborCost.toString() : '');
        setTotalCost(selectedCatalog.totalCost !== undefined ? selectedCatalog.totalCost.toString() : '');
        setIsDisableTotalCost(!!(selectedCatalog.laborCost !== undefined || selectedCatalog.partsCost !== undefined));
        setIsDisableCost(
          !!(
            selectedCatalog.laborCost === undefined &&
            selectedCatalog.partsCost === undefined &&
            selectedCatalog.totalCost !== undefined
          )
        );
      }
    }

    return (
      <>
        <LineItemsWrapperForm>
          {isForCompletedTaskForm || isForViewingCompletedTaskTDP ? (
            <div className={lineItemFieldClassName}>
              <NeedsEstimateLineItems isNeedEstimate={!lineItem?.totalCost?.toString()}>
                {description}
              </NeedsEstimateLineItems>
            </div>
          ) : (
            <div className={lineItemFieldClassName}>
              {flags.reconLineItemCatalog ? (
                <AutoComplete
                  dataSource={lineItemCatalogOptions}
                  placeholder="Title Required"
                  value={description}
                  onChange={onChangeDescription}
                  onSelect={onLineItemCatalogSelect}
                  className={isDirtyDescription && !description ? 'input-error' : ''}
                  autoFocus
                  showArrow={false}
                  dropdownMatchSelectWidth={false}
                  dropdownMenuStyle={{
                    maxHeight: '157px',
                    padding: '0px'
                  }}
                  dropdownStyle={{
                    maxWidth: isVDPorTDP ? (window.innerWidth / 3 - 72) * 0.76 + 8 : (580 - 80) * 0.76 - 5
                  }}
                  filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              ) : (
                <Input
                  placeholder="Title Required"
                  value={description}
                  onChange={onChangeDescription}
                  className={isDirtyDescription && !description ? 'input-error' : ''}
                  autoFocus
                />
              )}

              {isDirtyDescription && !description ? <RequiredText>Required</RequiredText> : null}
            </div>
          )}
          <div className={partsFieldClassName}>
            <Input placeholder="$0" disabled={isDisableCost} value={partsCost} onChange={onChangePartsCost} />
          </div>
          <div className={laborFieldClassName}>
            <Input placeholder="$0" disabled={isDisableCost} value={laborCost} onChange={onChangeLaborCost} />
          </div>
          <div className={totalFieldClassName}>
            <Input
              className={!hasPermissionApprovedDecline && isDirtyTotalCost && !totalCost ? 'input-error' : ''}
              placeholder="$0"
              disabled={isDisableTotalCost}
              value={totalCost}
              onChange={onChangeTotalCost}
            />
            {!hasPermissionApprovedDecline && isDirtyTotalCost && !totalCost ? (
              <RequiredText>Required</RequiredText>
            ) : null}
          </div>
          <div className="action-field">
            <Button className="cancel-btn" onClick={() => onCancelAddItem(lineItem)}>
              <BodyXSmallBold>Cancel</BodyXSmallBold>
            </Button>
            <Button
              className="add-btn"
              disabled={
                (!hasPermissionApprovedDecline && !totalCost) ||
                !description.trim() ||
                (!totalCost && (listName === 'declined' || listName === 'approved'))
              }
              onClick={() => onUpdateItem(lineItem)}
            >
              <BodyXSmallBold>{lineItem.isEditing ? 'Save' : '+ Add'}</BodyXSmallBold>
            </Button>
          </div>
        </LineItemsWrapperForm>
        {lineItem.error && <ErrorMessage>{lineItem.error}</ErrorMessage>}
      </>
    );
  }
);

const LineItemFormWrapperSelector = createSelector(
  (state) => state.lineItems.needApproval,
  (state) => state.lineItems.approved,
  (state) => state.lineItems.declined,
  (needApproval, approved, declined) => ({
    needApprovalList: needApproval,
    approvedList: approved,
    declinedList: declined
  })
);

const LineItemFormWrapper = ({
  task,
  setIsDirty,
  listName,
  hasPermissionApprovedDecline,
  hideDeclineSection,
  contentType,
  isForCompletedTaskForm = false,
  isCompleting = false,
  isForViewingCompletedTaskTDP = false,
  isNeedApprovalSection = true,
  hasLineItemsEditRole = true,
  flags
}) => {
  const dispatch = useDispatch();
  const { needApprovalList, approvedList, declinedList } = useSelector(LineItemFormWrapperSelector);
  const listLineItems = useSelector((state) => state.lineItems[listName]);
  const sortedLineItem = listLineItems.sort(compareItems(listName));
  const lineItemForBodySaveRequest = useSelector((state) => state.lineItems.lineItemForBodySaveRequest);
  const editingLineItem = useSelector((state) => state.lineItems.editingLineItem);
  const { isVendor } = useVendorRole(flags.reconVendorManagement);
  const isReadonlyTask = useTaskReadOnlyStatus(task.id, flags.reconVendorManagement);
  const { isHQ } = useContext(HQAndProfitTimeContext);
  // handle disable update button on line item page
  useEffect(() => {
    if (!!setIsDirty) {
      setIsDirty(!editingLineItem && !!lineItemForBodySaveRequest.length);
    }
    if (isCompleting) {
      setIsDirty(!editingLineItem);
    }
  }, [lineItemForBodySaveRequest, editingLineItem]);

  // show edit form line item
  const editLineItem = (item) => {
    dispatch(lineItemsActions.setEditingLineItem(true));
    dispatch(
      lineItemsActions.updateLineItem(
        {
          ...item,
          isEditing: true
        },
        listName
      )
    );
  };

  const deleteLineItem = (item) => {
    const confirmText = `Are you sure you want to delete "`;
    const description = task
      ? `${confirmText}${item.description}" from "${task.reconTaskTypeName}" ?`
      : `${confirmText}${item.description}"?`;
    Modal.confirm({
      title: description,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(lineItemsActions.deleteLineItem(item.idTemp, listName));
        dispatch(lineItemsActions.setDiscardLineItem(true));
        dispatch(
          lineItemsActions.updateLineItemForBodySaveRequest({
            ...item,
            op: OPERATION_LINE_ITEM.REMOVE
          })
        );
      },
      onCancel() {
        devLogger.log(`Canceled Delete ${item.description}`);
      }
    });
  };

  const onApproved = (item) => {
    dispatch(lineItemsActions.setDiscardLineItem(true));
    const approvedCost = approvedList
      .map((item) => Number(item.totalCost))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    switch (listName) {
      case 'needApproval':
        if (Number(approvedCost) + Number(item.totalCost) > 200000) {
          dispatch(lineItemsActions.setNeedApprovalErrorMessage(''));
          dispatch(lineItemsActions.setErrorItem('needApproval', item, 'Approved total cannot exceed $200,000'));
        } else {
          dispatch(lineItemsActions.moveItem(needApprovalList, 'needApproval', approvedList, 'approved', item));
          dispatch(
            lineItemsActions.updateLineItemForBodySaveRequest({
              ...item,
              op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
              status: lineItemStatusTypes.APPROVED
            })
          );
        }
        break;
      case 'approved':
        dispatch(lineItemsActions.moveItem(approvedList, 'approved', needApprovalList, 'needApproval', item));
        dispatch(
          lineItemsActions.updateLineItemForBodySaveRequest({
            ...item,
            op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
            status: lineItemStatusTypes.PENDING
          })
        );
        break;
      case 'declined':
        if (Number(approvedCost) + Number(item.totalCost) > 200000) {
          dispatch(lineItemsActions.setErrorItem('declined', item, 'Approved total cannot exceed $200,000'));
        } else {
          dispatch(lineItemsActions.moveItem(declinedList, 'declined', approvedList, 'approved', item));
          dispatch(
            lineItemsActions.updateLineItemForBodySaveRequest({
              ...item,
              op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
              status: lineItemStatusTypes.APPROVED
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const onDeclined = (item) => {
    dispatch(lineItemsActions.setDiscardLineItem(true));
    switch (listName) {
      case 'needApproval':
        dispatch(lineItemsActions.moveItem(needApprovalList, 'needApproval', declinedList, 'declined', item));
        dispatch(
          lineItemsActions.updateLineItemForBodySaveRequest({
            ...item,
            op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
            status: lineItemStatusTypes.DECLINED
          })
        );
        break;
      case 'approved':
        dispatch(lineItemsActions.moveItem(approvedList, 'approved', declinedList, 'declined', item));
        dispatch(
          lineItemsActions.updateLineItemForBodySaveRequest({
            ...item,
            op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
            status: lineItemStatusTypes.DECLINED
          })
        );
        break;
      case 'declined':
        dispatch(lineItemsActions.moveItem(declinedList, 'declined', needApprovalList, 'needApproval', item));
        dispatch(
          lineItemsActions.updateLineItemForBodySaveRequest({
            ...item,
            op: !item.id ? OPERATION_LINE_ITEM.ADD : OPERATION_LINE_ITEM.REPLACE,
            status: lineItemStatusTypes.PENDING
          })
        );
        break;
      default:
        break;
    }
  };

  let lineItemFieldClassName = 'line-item-field';
  let partsFieldClassName = 'parts-field';
  let laborFieldClassName = 'labor-field';
  let totalFieldClassName = 'total-field';
  if (
    (isForViewingCompletedTaskTDP && (!hasPermissionApprovedDecline || approvedList.length === 0)) ||
    (!hasPermissionApprovedDecline && isForCompletedTaskForm) ||
    (!hasLineItemsEditRole && !isVendor)
  ) {
    lineItemFieldClassName += ' line-item-field-no-action';
    partsFieldClassName += ' parts-field-no-action';
    laborFieldClassName += ' labor-field-no-action';
    totalFieldClassName += ' total-field-no-action';
  }
  const renderLineItems = () => (
    <>
      {sortedLineItem.map((item, index) => {
        if (item.isAdding || item.isEditing) {
          return (
            <LineItemForm
              lineItem={item}
              key={item?.idTemp + '_LineItemForm'}
              task={task}
              listName={listName}
              hasPermissionApprovedDecline={hasPermissionApprovedDecline}
              isForCompletedTaskForm={isForCompletedTaskForm}
              approvedList={approvedList}
              isForViewingCompletedTaskTDP={isForViewingCompletedTaskTDP}
              contentType={contentType}
            />
          );
        } else {
          return (
            <>
              <LineItemsShowWrapper
                className={index === 0 ? 'first-item' : ''}
                key={item?.idTemp + '_LineItemsShowWrapper'}
              >
                <TruncatedTooltipOneLine
                  TypographyComponentProps={{
                    style: {
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      textAlign: 'left',
                      width: '31%',
                      paddingRight: '5px'
                    }
                  }}
                  lineItemFieldClassName={lineItemFieldClassName}
                  TooltipComponent={Tooltip}
                  ToolTipComponentProps={{
                    placement: 'top',
                    overlayStyle: { fontWeight: '400' },
                    overlayClassName: 'statistic-tooltip'
                  }}
                >
                  <NeedsEstimateLineItems isNeedEstimate={!item?.totalCost?.toString()}>
                    {item.description}
                  </NeedsEstimateLineItems>
                </TruncatedTooltipOneLine>
                <div className={partsFieldClassName}>
                  <NeedsEstimateLineItems isNeedEstimate={!item?.totalCost?.toString()}>
                    {!!item?.partsCost?.toString()
                      ? `$${formatterNumberUSD(Number(item?.partsCost))}`
                      : `${String.fromCharCode(8212)}${String.fromCharCode(8212)}`}
                  </NeedsEstimateLineItems>
                </div>
                <div className={laborFieldClassName}>
                  <NeedsEstimateLineItems isNeedEstimate={!item?.totalCost?.toString()}>
                    {!!item?.laborCost?.toString()
                      ? `$${formatterNumberUSD(Number(item?.laborCost))}`
                      : `${String.fromCharCode(8212)}${String.fromCharCode(8212)}`}
                  </NeedsEstimateLineItems>
                </div>
                <div className={totalFieldClassName}>
                  <NeedsEstimateLineItems isNeedEstimate={!item?.totalCost?.toString()}>
                    {!!item?.totalCost?.toString()
                      ? `$${formatterNumberUSD(Number(item?.totalCost))}`
                      : `${String.fromCharCode(8212)}${String.fromCharCode(8212)}`}
                  </NeedsEstimateLineItems>
                </div>
                {!hasLineItemsEditRole || (isForViewingCompletedTaskTDP && approvedList.length === 0) || isHQ ? (
                  <></>
                ) : isForCompletedTaskForm || isForViewingCompletedTaskTDP ? (
                  hasPermissionApprovedDecline ? (
                    listName !== 'approved' ? (
                      <div className="action-field" />
                    ) : (
                      <div className="action-field">
                        <Dropdown
                          display="flex"
                          disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                          overlay={
                            <StyledMenu>
                              <Menu.Item key="edit" onClick={() => editLineItem(item)}>
                                <FontAwesomeIcon icon={faEdit} />
                                <BodyBaseBoldShortCustom>Edit</BodyBaseBoldShortCustom>
                              </Menu.Item>
                            </StyledMenu>
                          }
                        >
                          <StyledMoreIcon
                            icon={faEllipsisH}
                            data-disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                          />
                        </Dropdown>
                      </div>
                    )
                  ) : (
                    <></>
                  )
                ) : hasPermissionApprovedDecline ? (
                  <div className="action-field">
                    {!item?.totalCost?.toString() ? (
                      <NeedEstimateLabel>Needs Estimate</NeedEstimateLabel>
                    ) : (
                      <>
                        <ButtonGroup style={{ marginRight: '8px', width: '70px' }}>
                          {!hideDeclineSection && (
                            <AcceptDeclineButton isdecline={1} listName={listName} onClick={() => onDeclined(item)}>
                              <FontAwesomeIcon icon={faTimes} />
                            </AcceptDeclineButton>
                          )}
                          <AcceptDeclineButton isdecline={0} listName={listName} onClick={() => onApproved(item)}>
                            <FontAwesomeIcon icon={faCheck} />
                          </AcceptDeclineButton>
                        </ButtonGroup>
                      </>
                    )}
                    <Dropdown
                      display="flex"
                      disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                      overlay={
                        <StyledMenu>
                          <Menu.Item key="edit" onClick={() => editLineItem(item)}>
                            <FontAwesomeIcon icon={faEdit} />
                            <BodyBaseBoldShortCustom>Edit</BodyBaseBoldShortCustom>
                          </Menu.Item>
                          <Menu.Item key="delete" onClick={() => deleteLineItem(item)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                            <BodyBaseBoldShortCustom>Delete</BodyBaseBoldShortCustom>
                          </Menu.Item>
                        </StyledMenu>
                      }
                    >
                      <StyledMoreIcon
                        icon={faEllipsisH}
                        data-disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                      />
                    </Dropdown>
                  </div>
                ) : (
                  <div className="action-field">
                    {isNeedApprovalSection && !isReadonlyTask ? (
                      !item?.totalCost?.toString() ? (
                        <AddEstimateButton
                          onClick={() => editLineItem(item)}
                          disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                        >
                          + Add Estimate
                        </AddEstimateButton>
                      ) : (
                        <StyledDeleteEdit>
                          <CommonLinkButton
                            className="btn-delete"
                            onClick={() => deleteLineItem(item)}
                            disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </CommonLinkButton>
                          <CommonLinkButton
                            onClick={() => editLineItem(item)}
                            disabled={sortedLineItem.find((item) => item.isAdding || item.isEditing)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </CommonLinkButton>
                        </StyledDeleteEdit>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </LineItemsShowWrapper>
              {item.error && <ErrorMessage>{item.error}</ErrorMessage>}
            </>
          );
        }
      })}
      {listName === 'needApproval' && (
        <AddLineItem
          hasPermissionApprovedDecline={hasPermissionApprovedDecline}
          task={task}
          contentType={contentType}
        />
      )}
    </>
  );
  return <>{renderLineItems()}</>;
};

const NeedsEstimateLineItems = styled(BodySmall)``;

const LineItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  padding-top: 5px;
  min-height: 30px;

  .line-item-field {
    width: 31%;
    padding-right: 5px;
  }

  .line-item-field,
  .parts-field,
  .labor-field,
  .total-field {
    .input-error {
      border-color: #eb002e !important;
    }
    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .parts-field,
  .labor-field,
  .total-field {
    width: 15%;
    padding-right: 5px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .ant-input::placeholder {
      text-align: right;
    }
  }

  .line-item-field-no-action {
    width: 31%;
  }

  .parts-field-no-action,
  .labor-field-no-action,
  .total-field-no-action {
    width: 23%;
  }

  .action-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 24%;

    .ant-form-item-control {
      display: flex;
      justify-content: flex-end;
    }

    .cancel-btn {
      margin-right: 14px;
    }

    .add-btn:disabled {
      background: transparent;
      opacity: 0.5;
    }

    [ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
      content: none;
    }

    .cancel-btn,
    .add-btn {
      padding: 0;
      border: none;
      span {
        color: ${({ theme }) => theme.colors.red};
      }
    }
  }
`;

const RequiredText = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-top: 4px;
  line-height: 13px;
`;

const LineItemsWrapperForm = styled(LineItemsWrapper)``;

const LineItemsShowWrapper = styled(LineItemsWrapper)`
  align-items: center;
  border-top: ${({ theme }) => theme.borders.grayLineItemBorder};
  margin-bottom: ${(props) => (props['has-error'] ? '0' : '5px')};
  &.first-item {
    border-top: none;
  }
`;

const ErrorMessage = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-bottom: ${(props) => (props['no-margin'] ? '0' : '2px')};
`;

const ApproveAllWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 28px;
`;

const AddLineItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 22px;
`;

const StyledMoreIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props['data-disabled'] ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const ApproveAllButton = styled(CommonRedButton).attrs({
  type: 'danger'
})`
  ${'' /* position: absolute !important;
  right: 0;
  top: 50%;
  transform: translate(0, -50%); */}
  .ant-btn-danger& {
    justify-content: center;
    width: 90px;
    font-size: 12px;
    height: 24px;
    :disabled& {
      opacity: 0.5;
    }
  }
`;

const AddLineItemButton = styled(Button).attrs({
  type: 'link'
})`
  ${'' /* position: absolute !important;
  left: 0;
  top: 50%;
  transform: translate(0, -50%); */}
  .ant-btn-link& {
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    padding: 0;
    height: auto;
    :hover,
    :active,
    :visited,
    :focus {
      color: ${({ theme }) => theme.colors.red};
    }
    :disabled& {
      color: ${({ theme }) => theme.colors.red};
      opacity: 0.5;
    }
  }
`;

const AddEstimateButton = styled(Button).attrs({
  type: 'link'
})`
  .ant-btn-link& {
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    padding: 0;
    :hover,
    :active,
    :visited,
    :focus {
      color: ${({ theme }) => theme.colors.red};
    }
    :disabled& {
      color: ${({ theme }) => theme.colors.lightGray} !important;
    }
  }
`;

const NeedEstimateLabel = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-right: 10px;
`;

const acceptDeclineBackgroundColor = (isdecline, theme, listName, isBorderColor) => {
  if (isdecline) {
    if (listName === 'declined') {
      return theme.backgroundColors.red;
    } else {
      return isBorderColor ? theme.colors.darkGray : theme.colors.white;
    }
  } else {
    if (listName === 'approved') {
      return theme.backgroundColors.green;
    } else {
      return isBorderColor ? theme.colors.darkGray : theme.colors.white;
    }
  }
};

const hoverBorderColor = (isdecline, theme) => {
  if (isdecline) {
    return theme.backgroundColors.red;
  } else {
    return theme.backgroundColors.green;
  }
};

export const AcceptDeclineButton = styled(Button)`
  .ant-btn& {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    height: 20px;
    padding: 0 10px;
    display: inline-flex;
    align-items: center;
    width: 35px;
    background-color: ${({ isdecline, theme, listName }) =>
      acceptDeclineBackgroundColor(isdecline, theme, listName, false)};
    color: ${({ theme, isdecline, listName }) =>
      (listName === 'declined' && isdecline) || (listName === 'approved' && !isdecline)
        ? theme.colors.white
        : theme.colors.darkGray};
    border-color: ${({ isdecline, theme, listName }) => acceptDeclineBackgroundColor(isdecline, theme, listName, true)};
    :focus {
      color: ${({ theme, isdecline, listName }) =>
        (listName === 'declined' && isdecline) || (listName === 'approved' && !isdecline)
          ? theme.colors.white
          : theme.colors.darkGray};
      border-color: ${({ isdecline, theme, listName }) =>
        acceptDeclineBackgroundColor(isdecline, theme, listName, true)};
      background-color: ${({ isdecline, theme, listName }) =>
        acceptDeclineBackgroundColor(isdecline, theme, listName, false)};
      z-index: initial !important;
    }
    :hover,
    :active {
      border-color: ${({ isdecline, theme }) => hoverBorderColor(isdecline, theme)};
      background-color: ${({ isdecline, theme }) =>
        isdecline ? theme.backgroundColors.red : theme.backgroundColors.green};
      color: ${({ theme }) => theme.colors.white};
      margin-left: 0 !important;
      opacity: 0.8;
      z-index: initial !important;
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const BodyBaseBoldShortCustom = styled(BodyBaseBoldShort)`
  font-weight: 400;
  display: inline-block;
  margin-left: 15px;
`;

const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 160px;
    padding: 0;

    li.ant-dropdown-menu-item:first-child {
      padding: 16px 24px 11px 24px;
      border-bottom: ${({ theme }) => theme.borders.grayLineItemDropdownBorder};
    }

    li.ant-dropdown-menu-item:last-child {
      padding: 11px 24px 16px 24px;
    }
  }
`;

const StyledDeleteEdit = styled.div`
  padding-right: 5px;
  .btn-delete {
    margin-right: 16px;
  }

  .ant-btn-link {
    height: 16px;
    width: 14px;
    color: #6d727a;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;

    :hover,
    :active,
    :focus {
      color: #6d727a !important;
    }
  }
`;

export default withLDConsumer()(LineItemFormWrapper);
