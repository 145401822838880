const locationActions = {
  BUTTON_SAVE_TASK_DETAIL_PAGE: 'button-save-task-detail-page',
  BUTTON_SAVE_VEHICLE_DETAILS_PAGE_MESSAGE: 'button-save-vehicle-details-page-message',
  BUTTON_SAVE_VEHICLE_COMMUNICATION_REPLY_PAGE: 'button-save-vehicle-communication-reply-page',
  BUTTON_SAVE_VEHICLE_CREATE_NOTE_PAGE: 'button-save-vehicle-create-note-page',
  BUTTON_SAVE_VEHICLE_EDIT_COMMUNICATION_PAGE: 'button-save-vehicle-edit-communication-page',
  BUTTON_SAVE_VEHICLE_EDIT_COMMUNICATION_PAGE_MESSAGE: 'button-save-vehicle-edit-communication-page',
  BUTTON_SAVE_EDIT_COMMUNICATION_TASK_DETAIL_PAGE: 'button-save-edit-communication-task-detail-page',
  BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DETAIL_PAGE: 'button-save-edit-communication-vehicle-detail-page',
  BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DRAWER_COMMUNICATION_PAGE:
    'button-save-edit-communication-vehicle-drawer-communication-page'
};

export default locationActions;
