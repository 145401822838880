import React, { useEffect, useReducer, useState, useContext } from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faEdit,
  faEllipsisH,
  faExclamationCircle,
  faTrashAlt,
  faUserCircle,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { Dropdown, Menu, Modal, Typography } from 'antd';
import { apiStatusConstants, detailContents, emDash, onboardingStatusTypes } from 'app-constants';
import { CommonRedButton, StyledConfirmationModalIcon } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { internalGroupActions } from 'store/internalGroupStore';
import { taskTypesActions } from 'store/taskTypesStore';
import { vendorActions, vendorDealerActions } from 'store/vendorStore';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';
import { isObjectEqual } from 'utils/objectUtils.js';
import { statusSort } from '../../../utils/sortUtils';
import { vendorDealerStatusTypes } from 'app-constants/index';
import { userAppStateActions } from 'store/userAppStateStore';
import { formatMMDDYY } from 'utils/dateTimeUtils';
import { compareElementWidthByClassName } from 'utils/domUtils';
import { formatPhoneNumber } from 'utils/numberUtils';
import CopyOnClickText from '../../common/CopyOnClickText';
import SearchInput from '../../inputs/SearchInput';
import { StyledList, StyledPage, StyledSettingsCard } from '../settings/common/Styles.js';
import { NoRecords, NoRecordsPage } from '../vendorAndGroups';
import { InternalGroupDrawer } from './InternalGroups';
import { VendorDrawer } from './vendors';
import Table from '../../tables/Table';
import CommonModal, { StyledModalBodyText } from '../../common/CommonModal';
import { usersActions } from 'store/usersStore';
import { useWindowSize } from 'hooks/useWindowSize';
import { StringParam, useQueryParams } from 'use-query-params';
import { compact } from 'lodash';
import { GlobalAlertContext } from '../../App';
import uuidv4 from 'uuid/v4';

const { Text } = Typography;

const vendorPropsSelector = createSelector(
  (state) => state.dealers?.current?.data,
  (state) => state.vendors,
  (currentDealer, vendorStore) => ({
    currentDealer,
    vendors: vendorStore.data,
    allVendorsInSystem: vendorStore.allVendorsInSystem,
    fetchStatus: vendorStore.fetchStatus
  })
);

const internalGroupPropsSelector = createSelector(
  (state) => state.users,
  (state) => state.internalGroups,
  (usersContainer, internalGroupsStore) => ({
    users: usersContainer.data,
    usersFetchStatus: usersContainer.fetchStatus,
    internalGroups: internalGroupsStore.data,
    internalGroupFetchStatus: internalGroupsStore.fetchStatus,
    assigneeData: usersContainer.assigneeData?.internalUsers
  })
);

const Loading = () => (
  <>
    {Array.from(Array(10)).map((_, i) => (
      <StyledSettingsCard key={uuidv4()} loading={true} />
    ))}
  </>
);

const MoreMenu = ({ vendor, vendorModalProps }) => (
  <Dropdown
    display="flex"
    onVisibleChange={() => vendorModalProps.setMenuOpen({ id: vendor.id, open: !vendorModalProps.menuOpen.open })}
    overlay={
      <Menu
        onClick={({ key }) => {
          if (key === 'view') {
            vendorModalProps.setMenuOpen({ id: vendor.id, open: false });
            vendorModalProps.onViewVendor(vendor);
          } else if (key === 'remove') {
            const workload = vendorModalProps.vendorsAssigneeData.find(
              (vendorData) => vendorData.id === vendor.id
            )?.workload;
            vendorModalProps.vendorStateDispatch({ type: 'OPEN_VENDOR_MODAL', payload: { ...vendor, workload } });
          }
        }}
      >
        <Menu.Item key="view">
          <StyledMenuIcon icon={faEdit} />
          View Details
        </Menu.Item>

        <Menu.Item key="remove">
          <StyledMenuIcon icon={faTrashAlt} />
          Remove
        </Menu.Item>
      </Menu>
    }
  >
    <StyledOverflowIcon id={vendor.id} icon={faEllipsisH} data-selected={vendorModalProps.menuOpen} />
  </Dropdown>
);

const initialState = {
  open: false,
  text: [],
  title: '',
  icon: null,
  iconStyle: {},
  hasTasks: false,
  vendor: {},
  width: 416
};

const vendorReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_VENDOR_MODAL':
      const vendor = action.payload;
      const hasTasks = vendor.workload && vendor.workload.pending + vendor.workload.active > 0;
      let text = '';
      let title = '';
      let icon;
      const iconStyle = {};
      const width = vendor.name.length < 24 ? 416 : 466;

      if (hasTasks) {
        title = `${vendor.name} has assigned tasks`;
        text = [
          <>
            <StyledModalBodyText>
              This external vendor cannot be removed until all of its tasks are canceled, declined or completed.
            </StyledModalBodyText>
          </>
        ];
        icon = faExclamationCircle;
        iconStyle.color = themeStyles.colors.red;
      } else {
        title = 'Remove Vendor';
        text = [
          <>
            <StyledModalBodyText>Are you sure you want to remove </StyledModalBodyText>
            <StyledModalBodyText strong>{vendor.name}</StyledModalBodyText>
            <StyledModalBodyText> as a vendor?</StyledModalBodyText>
          </>
        ];
        icon = faQuestionCircle;
      }
      return {
        open: true,
        text,
        title,
        hasTasks,
        icon,
        iconStyle,
        vendor,
        width
      };
    case 'CLOSE_VENDOR_MODAL':
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

const VendorAndGroups = ({ flags, isExternal }) => {
  const dispatch = useDispatch();

  const displayVendorTermsAndConditions = useSelector(
    (state) => state.userAppState.data?.displayVendorTermsAndConditions
  );
  const vendorTermsAndConditionsFetchStatus = useSelector(
    (state) => state.userAppState.vendorTermsAndConditionsFetchStatus
  );
  const vendorsAssigneeData = useSelector((state) => state.users.assigneeData?.vendors) ?? [];
  const isLoading = useSelector((state) => state.vendors.isLoading);
  const userId = useSelector((state) => state.auth.user.id);
  const { currentDealer, vendors, fetchStatus, allVendorsInSystem } = useSelector(vendorPropsSelector);
  const { users, usersFetchStatus, internalGroups, internalGroupFetchStatus, assigneeData } =
    useSelector(internalGroupPropsSelector);
  const [localInternalGroups, setLocalInternalGroups] = useState([]);
  const { isBridgeUser } = useSelector((state) => state.auth);

  const [selectedCard, setSelectedCard] = useState({ contentType: null });
  const [menuOpen, setMenuOpen] = useState({});
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [showInnerBorders, setShowInnerBorders] = useState(false);
  const [filteredInternalGroup, setFilteredInternalGroup] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [vendorState, vendorStateDispatch] = useReducer(vendorReducer, initialState);
  const windowSize = useWindowSize();
  const [query] = useQueryParams({
    vendorId: StringParam,
    dealerId: StringParam,
    isNotification: StringParam
  });
  const hasGlobalAlert = !!useContext(GlobalAlertContext);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (query.vendorId && query.isNotification === 'true') {
      if (!query.dealerId || (!!query.dealerId && query.dealerId === currentDealer.id)) {
        dispatch(vendorDealerActions.getVendorDealerDetails(query.vendorId, currentDealer.id));
        setSelectedCard({ contentType: detailContents.VIEW_VENDOR });
      }
    }
  }, [query.vendorId, query.dealerId]);

  useEffect(() => {
    if (!isExternal && !isObjectEqual(internalGroups, localInternalGroups)) {
      setLocalInternalGroups(internalGroups || []);
      setFilteredInternalGroup(internalGroups || []);
    }
  }, [internalGroups, isExternal]);

  const onAddVendor = () => {
    if (!isExternal) {
      setSelectedCard({ contentType: detailContents.INTERNAL_GROUPS });
      return;
    }

    if (!displayVendorTermsAndConditions) {
      setSelectedCard({ contentType: detailContents.VENDORS });
    } else {
      setTermsAndConditionsOpen(true);
    }
  };

  const onAcceptLegalDisclaimer = () => {
    dispatch(userAppStateActions.updateDisplayVendorTermsAndConditions(userId));
  };

  const onCloseLegalDisclaimer = () => {
    if (vendorTermsAndConditionsFetchStatus !== apiStatusConstants.IS_FETCHING) {
      setTermsAndConditionsOpen(false);
    }
  };

  const onViewVendor = (vendor) => {
    if (vendor) {
      dispatch(vendorDealerActions.getVendorDealerDetails(vendor.id, currentDealer.id));
      setSelectedCard({ contentType: detailContents.VIEW_VENDOR });
    }
  };

  const vendorModalProps = { menuOpen, setMenuOpen, onViewVendor, vendorStateDispatch, vendorsAssigneeData };

  useEffect(() => {
    return () =>
      dispatch(userAppStateActions.setDisplayVendorTermsAndConditionsFetchStatus(apiStatusConstants.PENDING));
  }, []);

  useEffect(() => {
    if (termsAndConditionsOpen && vendorTermsAndConditionsFetchStatus === apiStatusConstants.SUCCEEDED) {
      setTermsAndConditionsOpen(false);
      setSelectedCard({ contentType: detailContents.VENDORS });
    } else if (vendorTermsAndConditionsFetchStatus === apiStatusConstants.FAILED) {
      setTermsAndConditionsOpen(false);
    }
  }, [vendorTermsAndConditionsFetchStatus, termsAndConditionsOpen]);

  useEffect(() => {
    if (!isLoading && vendorState.open) {
      vendorStateDispatch({ type: 'CLOSE_VENDOR_MODAL' });
    }
  }, [isLoading]);

  useEffect(() => {
    if (currentDealer && currentDealer.id) {
      if (isExternal) {
        dispatch(usersActions.getAssigneeData(currentDealer.id));
        dispatch(vendorActions.getData(currentDealer.id));
        if (!flags.reconVendorWorkflow2023) {
          dispatch(vendorActions.getAllVendors());
        }
        dispatch(taskTypesActions.getData(currentDealer.id));
      } else {
        // get all vendors for checking if group name is valid
        dispatch(vendorActions.getAllVendors());
        dispatch(internalGroupActions.getData(currentDealer.id));
      }
    }
  }, [currentDealer?.id, isExternal, flags.reconVendorWorkflow2023]);

  const changeBorders = () => {
    if (filteredTableData.length > 0) {
      setShowInnerBorders(compareElementWidthByClassName('.ant-table-measure-row', '.ant-table-wrapper'));
    }
  };

  useEffect(() => {
    if (filteredTableData.length === 0) {
      setShowInnerBorders(false);
    } else {
      changeBorders();
    }

    window.addEventListener('resize', changeBorders);

    // cleanup func
    return () => window.removeEventListener('resize', changeBorders);
  }, [filteredTableData]);

  // Check if vendor name already exists
  const isNameTaken = (name, vendorId) => {
    if (vendorId) {
      return allVendorsInSystem
        .filter((t) => t.id !== vendorId)
        .some((t) => t.name.toLowerCase().replace(/\s/g, '') === name.toLowerCase().replace(/\s/g, ''));
    } else {
      return allVendorsInSystem.some(
        (t) => t.name.toLowerCase().replace(/\s/g, '') === name.toLowerCase().replace(/\s/g, '')
      );
    }
  };

  // Check if email already exists for primary contact
  const isEmailExists = (email, vendorId, checkAll = true) => {
    let emailExits = [];
    if (vendorId) {
      emailExits = (checkAll ? allVendorsInSystem : vendors)
        .filter((t) => t.id !== vendorId)
        .find((t) => t.email.toLowerCase() === email.toLowerCase());
    } else {
      emailExits = (checkAll ? allVendorsInSystem : vendors).find((t) => t.email.toLowerCase() === email.toLowerCase());
    }

    return emailExits;
  };

  const isNameAndEmailMatch = (pairValue, vendorId) => {
    if (vendorId) {
      return allVendorsInSystem
        .filter((t) => t.id !== vendorId)
        .some((t) => t.name === pairValue.name && t.email === pairValue.email);
    } else {
      return allVendorsInSystem.some((t) => t.name === pairValue.name && t.email === pairValue.email);
    }
  };

  const isInternalGroupNameTaken = (name, id) => {
    if (!name) {
      return false;
    }

    const nameLowerCase = name?.toLowerCase();
    const groupsToCheck = id ? internalGroups.filter((ig) => ig.id !== id) : internalGroups;
    if (groupsToCheck?.some((t) => t.name?.toLowerCase() === nameLowerCase)) {
      return {
        hasExistedGroupName: true,
        entityName: 'an internal group'
      };
    } else if (allVendorsInSystem?.some((t) => t.name?.toLowerCase() === nameLowerCase)) {
      return {
        hasExistedVendorName: true,
        entityName: 'an external vendor'
      };
    } else if (users?.some((t) => t.fullName?.toLowerCase() === nameLowerCase)) {
      return {
        hasExistedUserName: true,
        entityName: 'a technician'
      };
    }
    return false;
  };

  const onEditInternalGroup = (internalGroup) => {
    if (internalGroup && internalGroup.id) {
      dispatch(internalGroupActions.getInternalGroup(internalGroup.id));
      setSelectedCard({ contentType: detailContents.INTERNAL_GROUPS, isFetchingGroup: true });
    }
  };

  const onDeleteInternalGroup = (internalGroup) => {
    const { workload } = internalGroup;
    if (workload && workload.pending + workload.active > 0) {
      Modal.confirm({
        title: `${internalGroup.name} has assigned tasks`,
        content: `This group cannot be deleted until all of its tasks are canceled, declined or completed.`,
        okText: 'Close',
        okType: 'danger',
        cancelText: 'View Tasks',
        cancelButtonProps: { className: 'btn-dangerous' },
        icon: <StyledExclamationCircleIcon icon={faExclamationCircle} className="anticon" />,
        className: 'modal-with-thin-button',
        onOk() {
          devLogger.log(`Close warning info ${internalGroup.name}`);
        },
        onCancel() {
          devLogger.log(`Navigate to TLP filtered with ${internalGroup.name}`);
          let membersInGroup = internalGroup.internalGroupUsers
            ?.filter((member) => member.userId !== null)
            .map((memberInGroup) => memberInGroup.userId);
          const groupFilter = JSON.stringify([
            {
              assignedToGroup: internalGroup.id,
              assignedToMembers: compact(membersInGroup),
              unassigned: true
            }
          ]);
          let linkNavigate = `/tasks?assignedToGroupFilter=${encodeURIComponent(
            groupFilter
          )}&assignedToTechnicianFilter=${encodeURIComponent(
            JSON.stringify([])
          )}&taskStatus=Pending&taskStatus=${encodeURIComponent('In Progress')}&fromDashboard=1`;
          navigate(linkNavigate);
        }
      });
    } else {
      Modal.confirm({
        title: `Delete ${internalGroup.name} from internal groups?`,
        content: `Individual group members will not be deleted from iRecon, however tasks can no longer be assigned to this group.`,
        okText: 'Delete Group',
        okType: 'danger',
        cancelText: 'Cancel',
        cancelButtonProps: { className: 'btn-dangerous' },
        className: 'modal-with-thin-button',
        onOk() {
          dispatch(
            internalGroupActions.delete(
              internalGroup,
              'Deleting internal group...',
              'Internal group deleted.',
              'An error occurred while deleting internal group.'
            )
          );
        },
        onCancel() {
          devLogger.log(`Canceled Delete ${internalGroup.name}`);
        },
        icon: <StyledConfirmationModalIcon icon={faQuestionCircle} className="anticon" />
      });
    }
  };

  const navigateTLPVendor = (vendorId) => {
    navigate(
      `/tasks?taskStatus=Pending&taskStatus=${encodeURIComponent(
        'In Progress'
      )}&fromDashboard=1&assignedToVendor=${vendorId}`
    );
  };

  const removeVendor = (vendor) => {
    if (vendor.id) {
      dispatch(
        vendorActions.delete(vendor, `${vendor.name} has been removed`, 'An error occurred while removing the vendor')
      );
    }
  };

  const onCloseRemoveVendorModal = (e) => {
    if (vendorState.hasTasks) {
      if (e.target.type === 'button') {
        navigateTLPVendor(vendorState.vendor.name);
      } else {
        vendorStateDispatch({ type: 'CLOSE_VENDOR_MODAL' });
      }
    } else if (!isLoading) {
      vendorStateDispatch({ type: 'CLOSE_VENDOR_MODAL' });
    }
  };

  const onAcceptRemoveVendorModal = () => {
    if (vendorState.hasTasks) {
      vendorStateDispatch({ type: 'CLOSE_VENDOR_MODAL' });
    } else {
      removeVendor(vendorState.vendor);
    }
  };

  const internalGroupOverFlowMenu = (internalGroup) => (
    <Dropdown
      display="flex"
      onVisibleChange={() => setMenuOpen({ id: internalGroup.id, open: !menuOpen.open })}
      overlay={
        <Menu
          onClick={({ key }) => {
            setMenuOpen({ id: internalGroup.id, open: false });
            switch (key) {
              case 'edit':
                onEditInternalGroup(internalGroup);
                break;
              case 'remove':
                onDeleteInternalGroup(internalGroup);
                break;
              default:
                devLogger.log(`Unexpected response: ${key}. Canceled Vendor action`);
                break;
            }
          }}
        >
          <Menu.Item key="edit">
            <StyledMenuIcon icon={faEdit} />
            View Details
          </Menu.Item>
          <Menu.Item key="remove">
            <StyledMenuIcon icon={faTrashAlt} />
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <StyledOverflowIcon id={internalGroup.id} icon={faEllipsisH} data-selected={menuOpen} />
    </Dropdown>
  );

  const noRecordsView = (
    <NoRecords PageType={isExternal ? NoRecordsPage.VENDOR : NoRecordsPage.INTERNAL_GROUP} valueSearch={searchValue} />
  );

  useEffect(() => {
    if (isExternal) {
      // Vendor Table Columns
      const columnsDef = [
        {
          title: 'Vendor',
          dataIndex: 'name',
          width: '100px',
          fixed: 'left',
          sorter: (a, b) => a.name.localeCompare(b.name),
          sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: '100px',
          sorter: statusSort,
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text) => {
            if (text.includes('Pending')) {
              return <p className={'table-cell-pending'}>{text}</p>;
            }
            return text;
          }
        },
        {
          title: 'Primary',
          dataIndex: 'primaryContact',
          width: '110px',
          sorter: (a, b) => a.primaryContact.localeCompare(b.primaryContact)
        },
        {
          title: 'Address',
          dataIndex: 'address',
          width: '110px',
          sorter: (a, b) => a.address.localeCompare(b.address),
          sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          width: '100px',
          sorter: (a, b) => {
            if (!a.phone) {
              return -1;
            } else if (!b.phone) {
              return 1;
            } else {
              return a.phone.localeCompare(b.phone, { numeric: true });
            }
          },
          sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: '75px',
          sorter: (a, b) => a.email.localeCompare(b.email),
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text) => {
            return <CopyOnClickText text={text} />;
          }
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          width: '75px',
          sorter: (a, b) => a.notes.localeCompare(b.notes),
          sortDirections: ['ascend', 'descend', 'ascend'],
          onHeaderCell: {
            width: '75px'
          }
        },
        {
          title: ''
        },
        {
          title: '',
          dataIndex: 'operation',
          className: 'vendor-operation-col-header',
          fixed: 'right',
          width: '50px',
          render: (_text, record) => {
            return <MoreMenu vendor={record} vendorModalProps={vendorModalProps} />;
          }
        }
      ];
      let columnsToSet = columnsDef.map((col) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width
        })
      }));

      if (flags.reconVendorWorkflow2023) {
        const emailIndex = columnsToSet.findIndex((col) => col.title === 'Email');
        const addressIndex = columnsToSet.findIndex((col) => col.title === 'Address');
        if (emailIndex > -1 && addressIndex > -1) {
          let emailColumn = columnsToSet.splice(emailIndex, 1);
          if (emailColumn.length === 1) {
            columnsToSet.splice(addressIndex, 0, emailColumn[0]);
          }
        }
      }

      setColumns(columnsToSet);
    }
  }, [flags.reconVendorWorkflow2023, vendorsAssigneeData, isExternal]);

  useEffect(() => {
    const vendorList = [
      ...vendors.map((vendor) => {
        let firstName = !!vendor?.contactFirstName
          ? `${vendor.contactFirstName} `
          : !vendor?.contactLastName
          ? emDash
          : ' ';
        let lastName = !!vendor?.contactLastName ? `${vendor.contactLastName}` : '';
        let phoneNumber = emDash;
        if (
          (flags.reconVendorWorkflow2023 &&
            vendor?.onboardingStatus === onboardingStatusTypes.VERIFIED &&
            !!vendor?.phone) ||
          (!flags.reconVendorWorkflow2023 && !!vendor?.phone)
        ) {
          phoneNumber = formatPhoneNumber(vendor?.phone);
        }
        return {
          // Include all existing fields
          ...vendor,
          name: !!vendor?.name ? vendor?.name : vendor.email,
          // Overwrite phone number with correct formatting
          phone: phoneNumber,
          // Add new properties
          status: `${vendorDealerStatusTypes[vendor?.invitationStatus]?.Name}, ${formatMMDDYY(
            vendor?.statusUpdatedOn
          )}`,
          primaryContact: firstName + lastName,
          address: vendor?.address !== undefined ? `${vendor?.address}, ${vendor?.city}` : emDash,
          statusRank: vendorDealerStatusTypes[vendor?.invitationStatus]?.Rank,
          statusDate: vendor?.statusUpdatedOn,
          notes: vendor?.dealerNotes !== undefined ? vendor?.dealerNotes : emDash
        };
      })
    ];
    setTableData(vendorList);
    if (searchValue) {
      const filteredArray = vendorList.filter(
        (vendor) =>
          vendor.name?.toLowerCase().includes(searchValue) ||
          vendor.status?.toLowerCase().includes(searchValue) ||
          vendor.status?.toLowerCase().replace(/-/g, '/').includes(searchValue) ||
          vendor.status?.toLowerCase().replace(/-/g, ' ').includes(searchValue) ||
          vendor.primaryContact?.toLowerCase().includes(searchValue) ||
          vendor.address?.toLowerCase().includes(searchValue) ||
          vendor.phone?.includes(searchValue) ||
          vendor.phone?.replace(/-/g, '').includes(searchValue) ||
          vendor.phone?.replace(/-/g, ' ').includes(searchValue) ||
          vendor.email?.toLowerCase().includes(searchValue) ||
          vendor.notes?.toLowerCase().includes(searchValue)
      );
      setFilteredTableData(filteredArray);
    } else {
      setFilteredTableData(vendorList);
    }
  }, [vendors]);

  const scroll = filteredTableData.length !== 0 && {
    y: `calc(100vh - ${
      isBridgeUser // calculate height of table for bridge users
        ? 396
        : 360
    }px${hasGlobalAlert ? ' - 42px' : ''})`,
    x: 'max-content'
  };

  const vendorRowClass = (record, _index) => {
    if (record.status.includes('Declined')) {
      return 'table-row-declined';
    }
    return 'table-row-normal';
  };

  const isFetching = fetchStatus === apiStatusConstants.IS_FETCHING;
  const fetchFailed = fetchStatus === apiStatusConstants.FAILED;

  const vendorTableWithToogle = flags.reconVendorManagement ? (
    <StyledContainer>
      <StyledVendorTable
        columnsDef={columns}
        dataSource={filteredTableData}
        scroll={scroll}
        rowClassName={vendorRowClass}
        showInnerBorders={showInnerBorders}
        isFetching={isFetching}
        fetchFailed={fetchFailed}
      />
    </StyledContainer>
  ) : (
    <StyledList>
      {vendors.map((vendor) => (
        <VendorCardWrapper key={vendor.id}>
          <StyledFontAwesomeIcon />
          <VendorInfoWrapper>
            <VendorName>{vendor.name}</VendorName>
            <VendorInfo>
              Tasks:{' '}
              {vendor.vendorTaskTypes && vendor.vendorTaskTypes.length > 0
                ? Object.values(vendor.vendorTaskTypes)
                    .reduce((obj, v, index) => {
                      obj += v.name;
                      if (index !== vendor.vendorTaskTypes.length - 1) {
                        obj += ', ';
                      }
                      return obj;
                    }, '')
                    .trim()
                : String.fromCharCode(8212)}
            </VendorInfo>
            {/* <VendorInfo>
                      {vendor.city} {vendor.state} {vendor.postalCode}
                    </VendorInfo>
                    <VendorInfo>
                      {vendor.contactFirstName} {vendor.contactLastName}
                    </VendorInfo> */}
          </VendorInfoWrapper>
          <MoreMenu vendor={vendor} vendorModalProps={vendorModalProps} />
        </VendorCardWrapper>
      ))}
    </StyledList>
  );

  const vendorTable = !isFetching && !fetchFailed && vendors.length === 0 ? noRecordsView : vendorTableWithToogle;

  const externalVendorView = {
    [apiStatusConstants.FAILED]: vendorTable,
    [apiStatusConstants.IS_FETCHING]: flags.reconVendorManagement ? (
      <SkeletonContainer>
        {Array.from(Array(11)).map((_, i) => (
          <SkeletonRowContainer key={uuidv4()}>
            <Skeleton width="100%" height="32px" />
          </SkeletonRowContainer>
        ))}
      </SkeletonContainer>
    ) : (
      <StyledList>
        <Loading />
      </StyledList>
    ),
    [apiStatusConstants.SUCCEEDED]: vendorTable
  };

  const NoRecordInternalGroup = () => {
    return internalGroups.length > 0 ? (
      <NoRecords valueSearch={searchValue} PageType={NoRecordsPage.INTERNAL_GROUP_NOT_FOUND} />
    ) : (
      noRecordsView
    );
  };

  const internalGroupsView = {
    [apiStatusConstants.FAILED]: (
      <Text type="danger" ellipsis>
        Something went wrong retrieving Internal Groups information
      </Text>
    ),
    [apiStatusConstants.IS_FETCHING]: (
      <StyledList>
        <Loading />
      </StyledList>
    ),
    [apiStatusConstants.SUCCEEDED]:
      usersFetchStatus === apiStatusConstants.FAILED ? (
        <Text type="danger" ellipsis>
          Something went wrong retrieving Internal Groups information
        </Text>
      ) : usersFetchStatus === apiStatusConstants.IS_FETCHING ? (
        <StyledList>
          <Loading />
        </StyledList>
      ) : filteredInternalGroup?.length > 0 ? (
        <StyledList>
          {filteredInternalGroup.map((internalGroup) => {
            const internalGroupComparedWithAssigneeList = {
              ...internalGroup,
              internalGroupUsers:
                internalGroup?.internalGroupUsers?.filter((member) =>
                  assigneeData.some((a) => a.id === member.userId)
                ) ?? []
            };
            return (
              <StyledSortableItem key={internalGroup.id}>
                <UsersIconRoundContainer>
                  <StyledFontAwesomeIconUsers />
                </UsersIconRoundContainer>
                <GroupWrapper>
                  <Text ellipsis className="strong medium-font white-space-pre" style={{ marginBottom: '3px' }}>
                    {internalGroup?.name}
                  </Text>
                  <VendorInfo>
                    Members:{' '}
                    {internalGroupComparedWithAssigneeList?.internalGroupUsers?.length > 0
                      ? internalGroupComparedWithAssigneeList?.internalGroupUsers?.length
                      : 0}
                  </VendorInfo>
                </GroupWrapper>
                {internalGroupOverFlowMenu(internalGroup)}
              </StyledSortableItem>
            );
          })}
        </StyledList>
      ) : (
        <NoRecordInternalGroup />
      )
  };

  const filterTableData = (value) => {
    const trimmedValue = value.toLowerCase().trim();
    setSearchValue(trimmedValue);
    if (trimmedValue) {
      if (isExternal) {
        const filteredArray = tableData.filter(
          (vendor) =>
            vendor.name?.toLowerCase().includes(trimmedValue) ||
            vendor.status?.toLowerCase().includes(trimmedValue) ||
            vendor.status?.toLowerCase().replace(/-/g, '/').includes(trimmedValue) ||
            vendor.status?.toLowerCase().replace(/-/g, ' ').includes(trimmedValue) ||
            vendor.primaryContact?.toLowerCase().includes(trimmedValue) ||
            vendor.address?.toLowerCase().includes(trimmedValue) ||
            vendor.phone?.includes(trimmedValue) ||
            vendor.phone?.replace(/-/g, '').includes(trimmedValue) ||
            vendor.phone?.replace(/-/g, ' ').includes(trimmedValue) ||
            vendor.email?.toLowerCase().includes(trimmedValue) ||
            vendor.notes?.toLowerCase().includes(trimmedValue)
        );
        setFilteredTableData(filteredArray);
      } else if (internalGroups) {
        setFilteredInternalGroup(internalGroups.filter((ig) => ig.name?.toLowerCase()?.includes(searchValue)));
      }
    }
  };

  /** The flags change the style of 2 wrapper component. So make sure if has any changed, change on both true/false conditions */
  // show latest page
  const externalVendorFetchStatus = fetchStatus === apiStatusConstants ? usersFetchStatus : fetchStatus;
  return (
    <>
      <CommonModal
        open={termsAndConditionsOpen}
        onAccept={onAcceptLegalDisclaimer}
        onClose={onCloseLegalDisclaimer}
        isLoading={vendorTermsAndConditionsFetchStatus === apiStatusConstants.IS_FETCHING}
        title="Adding External Vendors: Terms and Conditions"
        bodyTexts={[
          'Vendors and vendor information found using the Cox Automotive External Vendor search feature is provided solely as a convenience to users of iRecon.',
          'Cox Automotive and its affiliates do not endorse, approve, warrant or guaranty any vendor services, products, websites, or publications (and expressly disclaims all warranties with respect to such products and services, including, without limitation, warranty of suitability, legality, accuracy, timeliness, completeness, efficacy, merchantability or fitness for a particular purpose, non-infringement or any other representation or warranty of any type or nature, all of which are expressly disclaimed).'
        ]}
        buttonText="Accept & Continue"
        cancelText="Cancel"
      />

      <CommonModal
        open={vendorState.open}
        onAccept={onAcceptRemoveVendorModal}
        onClose={onCloseRemoveVendorModal}
        title={vendorState.title}
        bodyTexts={vendorState.text}
        buttonText={vendorState.hasTasks ? 'Close' : 'Remove Vendor'}
        cancelText={vendorState.hasTasks ? 'View Tasks' : 'Cancel'}
        height={windowSize[0] && windowSize[0] < vendorState.width + 16 ? 220 : -1}
        icon={vendorState.icon}
        iconStyle={vendorState.iconStyle}
        width={vendorState.width}
        isLoading={isLoading}
      />

      <StyledHeader recon-vendor-management={flags.reconVendorManagement}>
        <SearchInput
          searchFunction={filterTableData}
          data={isExternal ? tableData : internalGroups}
          resetData={isExternal ? setFilteredTableData : setFilteredInternalGroup}
          customHeight={32}
          setSearchValue={setSearchValue}
        />
        <CommonRedButton onClick={onAddVendor}>{isExternal ? 'Add Vendor' : 'Create Group'}</CommonRedButton>
      </StyledHeader>
      <StyledPeoplePage is-bridge-user={isBridgeUser} id={'vendorAndGroupsContainer'}>
        {isExternal
          ? // External Vendor
            externalVendorView[externalVendorFetchStatus]
          : // Internal Groups
            internalGroupsView[internalGroupFetchStatus]}
      </StyledPeoplePage>
      {isExternal ? (
        <VendorDrawer
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          isNameTaken={isNameTaken}
          isEmailExists={isEmailExists}
          isNameAndEmailMatch={isNameAndEmailMatch}
        />
      ) : (
        <InternalGroupDrawer
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          isNameTaken={isInternalGroupNameTaken}
        />
      )}
    </>
  );
};

//#region Styled Components
const StyledFontAwesomeIcon = styled(FontAwesomeIcon).attrs({
  icon: faUserCircle,
  color: themeStyles.colors.navy,
  style: {
    fontSize: themeStyles.fontSizes.xxlg,
    marginRight: '16px',
    marginTop: '6px'
  }
})``;
const UsersIconRoundContainer = styled.div`
  border-color: ${({ theme }) => theme.colors.navy};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: inherit;
  border: 1px solid;
  height: 24px;
  width: 24px;
  margin-right: 16px;
  margin-top: 6px;
`;
// font size 13px is a specific case when the design want the icon 16px wide on its largest dimension
const StyledFontAwesomeIconUsers = styled(FontAwesomeIcon).attrs({
  icon: faUsers,
  color: themeStyles.colors.navy,
  style: {
    fontSize: '13px'
  }
})``;

const StyledExclamationCircleIcon = styled(FontAwesomeIcon)`
  color: ${themeStyles.colors.red} !important;
`;

const StyledMenuIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  width: 14px !important;
  height: 14px;
`;

const GroupWrapper = styled.div`
  min-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
`;

const StyledOverflowIcon = styled(FontAwesomeIcon)`
  height: 20px;
  cursor: pointer;
  color: ${(props) =>
    props['data-selected']['open'] && props['data-selected']['id'] === props['id']
      ? 'inherit'
      : props.theme.colors.darkGray};
  vertical-align: -0.45em;
`;

const VendorInfoWrapper = styled.div`
  flex: 1;
`;

const VendorCardWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.vendorCardBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 15px 18px 15px 16px;
`;

const StyledSortableItem = styled(VendorCardWrapper)`
  border: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
`;

const VendorName = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 3px;
`;

const VendorInfo = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  letter-spacing: 0;
  line-height: 20px;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  white-space: nowrap;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin: 0 ${(props) => (props['recon-vendor-management'] ? `24px` : `32px`)} 16px 0;
  button {
    margin-left: 8px;
  }
`;

const SkeletonContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const SkeletonRowContainer = styled.div`
  margin-bottom: 8px;
`;

const StyledVendorTable = styled(Table)``;

const StyledPeoplePage = styled(StyledPage)`
  grid-gap: 0;
`;
//#endregion

export default withLDConsumer()(VendorAndGroups);
