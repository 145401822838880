import { combineReducers } from 'redux';
import { appraisalInfoReducer } from 'store/appraisalInfoStore';
import { authReducer } from 'store/authStore';
import { dealersReducer } from 'store/dealersStore';
import { messagesReducer } from 'store/messagesStore';
import { metricsReducer } from 'store/metricsStore';
import { enterpriseMetricsReducer } from './enterpriseMetricsStore';
import { reducer as oidcReducer } from 'redux-oidc';
import { taskCategoriesReducer } from 'store/taskCategoriesStore';
import { tasksReducer } from 'store/tasksStore';
import { taskTypesReducer } from 'store/taskTypesStore';
import { planTemplatesReducer } from 'store/planTemplatesStore';
import { usersReducer } from 'store/usersStore';
import { journalReducer } from 'store/journalStore';
import { userAppStateReducer } from 'store/userAppStateStore';
import { userSettingsReducer } from 'store/userSettingsStore';
import { vehiclesReducer } from 'store/vehiclesStore';
import { vendorReducer } from 'store/vendorStore';
import { vdpReducer } from 'store/vdpStore';
import { documentsReducer } from 'store/documentsStore';
import { lineItemsReducer } from 'store/lineItemsStore';
import { lineItemCatalogReducer } from 'store/lineItemCatalogStore';
import { internalGroupReducer } from 'store/internalGroupStore';

export const rootReducers = combineReducers({
  appraisalInfo: appraisalInfoReducer,
  auth: authReducer,
  dealers: dealersReducer,
  journal: journalReducer,
  messages: messagesReducer,
  metrics: metricsReducer,
  oidc: oidcReducer,
  enterpriseMetrics: enterpriseMetricsReducer,
  taskCategories: taskCategoriesReducer,
  tasks: tasksReducer,
  taskTypes: taskTypesReducer,
  planTemplates: planTemplatesReducer,
  users: usersReducer,
  userSettings: userSettingsReducer,
  userAppState: userAppStateReducer,
  vehicles: vehiclesReducer,
  vendors: vendorReducer,
  vdp: vdpReducer,
  documents: documentsReducer,
  lineItems: lineItemsReducer,
  lineItemCatalog: lineItemCatalogReducer,
  internalGroups: internalGroupReducer
});
