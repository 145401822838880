import { Col, Row } from 'antd';
import { Grid } from 'antd4';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

import logo from 'assets/images/vendor-welcome/logo.svg';
import backdrop from 'assets/images/vendor-welcome/backdrop.png';

import InputGroup from './InputGroup';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from './Spinner';
import { apiStatusConstants, IS_NEW_SIGNIN, downloadAppUrl, VENDOR_USER_INFO } from 'app-constants';
import { useQueryParam } from 'use-query-params';
import { vendorActions } from 'store/vendorStore';
import {
  StyledRow,
  StyledHeaderText,
  StyledImg,
  StyledImgMobile,
  StyledHeaderMobileText,
  StyledTitleText,
  StyledSubTitleText,
  StyledRequirmentText,
  StyledSubSubtitleText,
  DownloadSignInButton
} from './style';
import { useDeviceDetect, useDeviceOSDetect } from 'hooks/useDeviceDetect';
import { userManagerNewSignin } from 'utils/userManager';
import { navigate } from '@reach/router';
import RuleList from './RuleList';
import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { USER_FOUND } from 'redux-oidc';

const ColWithRef = forwardRef((props, ref) => (
  <div ref={ref}>
    <Col {...props} />
  </div>
));

const VendorWelcome = ({ flags }) => {
  //state
  const bannerRef = useRef(null);
  const topMobileRef = useRef(null);
  const [ldInitialized, setLdInitialized] = useState(false);

  //hooks
  const isMobile = useDeviceDetect();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const isIos = useDeviceOSDetect();
  const ldClient = useLDClient();

  //query strings
  const [code] = useQueryParam('code');
  const [vendorUserId] = useQueryParam('user_id');
  const [dealerId] = useQueryParam('dealerId');

  //redux
  const linkValidationStatus = useSelector((state) => state.vendors.linkValidationStatus);
  const validateActivationLink = useSelector((state) => state.vendors.validateActivationLink);
  const setPasswordStatus = useSelector((state) => state.vendors.setPasswordStatus);

  //functions
  const onCompletedButtonClick = async () => {
    if (!isMobile) {
      localStorage.setItem(IS_NEW_SIGNIN, true);
      if (flags.reconVendorWorkflow2023) {
        try {
          const user = await userManagerNewSignin.getUser();
          const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
          const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
          //first, check if the user is logged in
          if (!!user && !user.expired) {
            if (!!dealerId && vendorInfo?.id === vendorUserId) {
              //if dealerId param is present
              //check if the logged in user's type is VENDOR and their id is the same as vendorId
              //navigate to configure dealerships page
              dispatch({ type: USER_FOUND, payload: user });
              navigate(`/configure/dealerships?dealerId=${dealerId}`);
            } else {
              window.location.href = `${window.location.origin}/`;
            }
          } else {
            //not logged in or not the same as logged in user
            userManagerNewSignin.signinRedirect({
              state: { invite_dealer_id: dealerId }
            });
          }
        } catch {
          userManagerNewSignin.signinRedirect({
            state: { invite_dealer_id: dealerId }
          });
        }
      } else {
        userManagerNewSignin.signinRedirect();
      }
    } else {
      const url = isIos ? downloadAppUrl.IOS : downloadAppUrl.ANDROID;
      navigate(url);
    }
  };

  const setPasswordSucceeded = () => {
    return (
      linkValidationStatus === apiStatusConstants.SUCCEEDED &&
      ((validateActivationLink.isRegistered && flags.reconVendorWorkflow2023) ||
        (setPasswordStatus === apiStatusConstants.SUCCEEDED && validateActivationLink.isValid))
    );
  };

  const linkValidationFailed = () => {
    return (
      linkValidationStatus === apiStatusConstants.FAILED ||
      (linkValidationStatus === apiStatusConstants.SUCCEEDED &&
        (!validateActivationLink ||
          (!flags.reconVendorWorkflow2023 && !validateActivationLink.isValid) ||
          (flags.reconVendorWorkflow2023 && !validateActivationLink.isValid && !validateActivationLink.isRegistered)))
    );
  };

  const linkValidationSucceeded = () => {
    return (
      linkValidationStatus === apiStatusConstants.SUCCEEDED &&
      validateActivationLink.isValid &&
      setPasswordStatus !== apiStatusConstants.SUCCEEDED
    );
  };

  //side effects
  useEffect(() => {
    if (ldClient) {
      ldClient
        .identify({
          key: 'VND-ALL',
          name: 'All Vendors',
          custom: {
            ReleaseGroup: 1
          }
        })
        .then(() => {
          setLdInitialized(true);
        });
    }
    return () => {
      dispatch(vendorActions.resetVendorWelcome());
    };
  }, []);

  useEffect(() => {
    if (ldInitialized) {
      dispatch(vendorActions.validateActivationLink(vendorUserId ?? '', code ?? '', dealerId ?? ''));
    }
  }, [ldInitialized]);

  return (
    <Row type="flex" style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
      <Spinner
        show={
          linkValidationStatus === apiStatusConstants.IS_FETCHING ||
          setPasswordStatus === apiStatusConstants.IS_FETCHING ||
          !ldInitialized
        }
      />

      {/* Browser View */}
      <Col md={8} xs={0}>
        <StyledRow
          minHeight={bannerRef.current ? `calc(100vh - ${bannerRef.current.clientHeight}px)` : '100vh'}
          type="flex"
          justify="center"
          align="top"
          style={{ overflow: 'hidden', height: '100%' }}
        >
          <Col md={20}>
            <img src={logo} alt="" style={{ paddingTop: '8%' }} />
          </Col>

          <Col md={20}>
            <StyledHeaderText>{'Streamlined\nReconditioning'}</StyledHeaderText>

            <StyledImg src={backdrop} alt="backdrop-browser" />
          </Col>
        </StyledRow>
      </Col>

      {/* Mobile View */}
      <ColWithRef md={0} xs={24} ref={topMobileRef}>
        <StyledRow
          type="flex"
          justify="center"
          align="middle"
          minHeight="17vh"
          paddingLeft="16px"
          style={{ width: '100vw' }}
        >
          <Col xs={8}>
            <StyledImgMobile src={backdrop} alt="backdrop-mobile" />
          </Col>

          <Col xs={16}>
            <Row
              type="flex"
              justify="start"
              align="middle"
              style={{ paddingLeft: '12px', marginTop: '16px', marginBottom: '16px', paddingRight: '12px' }}
              gutter={[16, 16]}
            >
              <Col xs={24}>
                <img src={logo} alt="" />
              </Col>

              <Col xs={24}>
                <StyledHeaderMobileText>Streamlined Reconditioning</StyledHeaderMobileText>
              </Col>
            </Row>
          </Col>
        </StyledRow>
      </ColWithRef>

      {setPasswordSucceeded() && (
        <Col md={16} xs={24}>
          <Row type="flex" justify="center" align="middle">
            <Col
              md={14}
              lg={12}
              xl={10}
              xs={22}
              style={{
                marginTop: !screens.md ? '16px' : '10%',
                minHeight:
                  isMobile &&
                  bannerRef.current &&
                  topMobileRef.current &&
                  `calc(100vh - ${bannerRef.current.clientHeight}px - ${topMobileRef.current.clientHeight}px - 16px)`
              }}
            >
              <div>
                <StyledTitleText>Welcome to iRecon</StyledTitleText>
              </div>

              <div style={{ marginTop: '16px' }}>
                <StyledSubTitleText>{'Password created!'}</StyledSubTitleText>
              </div>

              {isMobile && (
                <div style={{ marginTop: 'calc(14vh)' }}>
                  <StyledSubTitleText>{'Next, download our app.'}</StyledSubTitleText>
                </div>
              )}

              {isMobile && (
                <div style={{ marginTop: '4px' }}>
                  <StyledSubSubtitleText>
                    iRecon is optimized for web use on a desktop and laptop computer or through our native mobile app.
                  </StyledSubSubtitleText>
                </div>
              )}

              <DownloadSignInButton type="primary" onClick={onCompletedButtonClick}>
                {isMobile ? 'Download the App' : 'Sign in to iRecon'}
              </DownloadSignInButton>
            </Col>
          </Row>
        </Col>
      )}

      {linkValidationSucceeded() && (
        <Col md={16} xs={24}>
          <Row type="flex" justify="center" align="middle">
            <Col
              md={16}
              lg={14}
              xl={12}
              xxl={10}
              xs={22}
              style={{ marginTop: !screens.md ? '16px' : '10%', marginBottom: !screens.md ? '16px' : '10%' }}
            >
              <Row type="flex" justify="center" align="middle" gutter={[16, 14]}>
                <Col md={22} xs={24}>
                  <div>
                    <StyledTitleText>Welcome to iRecon</StyledTitleText>
                  </div>

                  <div style={{ marginTop: !screens.md ? '16px' : '40px' }}>
                    <StyledSubTitleText>{`Username: ${validateActivationLink.username ?? ''}`}</StyledSubTitleText>
                  </div>

                  <StyledRequirmentText>Your username may be updated later in "My Profile"</StyledRequirmentText>

                  <div style={{ marginTop: !screens.md ? '16px' : '24px' }}>
                    <StyledSubTitleText>{'Create a password to get started:'}</StyledSubTitleText>
                  </div>
                </Col>

                <Col md={22} xs={24}>
                  <InputGroup vendorUserId={vendorUserId} code={code} dealerId={dealerId} />
                </Col>

                <RuleList />
              </Row>
            </Col>
          </Row>
        </Col>
      )}

      {linkValidationFailed() && (
        <Col md={16} xs={24}>
          <Row type="flex" justify="center" align="middle">
            <Col md={14} lg={12} xl={10} xs={22} style={{ marginTop: !screens.md ? '16px' : '10%' }}>
              <div>
                <StyledSubTitleText error>The link you followed is invalid or has expired.</StyledSubTitleText>
              </div>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default withLDConsumer()(VendorWelcome);
