import { getAppraisalInfoSaga } from 'store/appraisalInfoStore';
import {
  applyExcludeFilterSaga,
  checkIfCurrentDealerIsAccessibleSaga,
  getAccessibleDealersSaga,
  getAccessibleVendorsSaga,
  getCurrentDealerSaga,
  getDealersSaga,
  loadInventorySaga,
  updateDealerSaga
} from 'store/dealersStore';
import {
  deleteDocumentSaga,
  downloadTaskDocumentSaga,
  downloadVehicleDocumentSaga,
  getDocumentsByTaskSaga,
  getDocumentsSaga,
  uploadTaskDocumentSaga,
  uploadVehicleDocumentSaga
} from 'store/documentsStore';
import {
  getEnterprisePerformanceMetricsSaga,
  getEnterpriseHistoricalMetricsSaga,
  getEnterpriseNeedsAttentionMetricsSaga,
  getExportNeedAttentionMetricsSaga,
  getExportHistoricalMetricsSaga,
  getExportEnterprisePerformanceMetrics
} from 'store/enterpriseMetricsStore';
import { getInternalGroupSaga, getInternalGroupsSaga, updateInternalGroupsSaga } from 'store/internalGroupStore';
import {
  getTaskHistoryJournalSaga,
  getUserJournalSaga,
  getVehicleHistoryJournalSaga,
  setUserJournalSeenSaga
} from 'store/journalStore';
import {
  addNewLineItemCatalogSaga,
  deleteLineItemCatalogSaga,
  getLineItemCatalogSaga,
  updateLineItemCatalogSaga
} from 'store/lineItemCatalogStore';
import {
  getLineItemsReconPlanTemplateTaskSaga,
  getLineItemsSaga,
  getLineItemsTaskTemplateSaga,
  patchLineItemsPlanTemplateSaga,
  patchLineItemsSaga,
  patchLineItemsTaskTemplateSaga,
  patchLineItemsTDPSaga,
  updateReconTaskSaga
} from 'store/lineItemsStore';
import {
  getActiveInventoryMetricsSaga,
  getHistoricalExportMetricsSaga,
  getHistoricalMetricsSaga,
  getInventoryExportMetricsSaga,
  getSnapshotHistoricalMetricsSaga,
  getActiveInventoryPerformanceMetricsSaga,
  getHistoricalPerformanceMetricsSaga
} from 'store/metricsStore';
import { bulkUpdatePlanTemplatesSaga, getPlanTemplatesSaga, updatePlanTemplatesSaga } from 'store/planTemplatesStore';
import { getTaskCategoriesSaga, getTaskCategoriesFromTaskTypeSaga } from 'store/taskCategoriesStore';
import {
  addCommentFromTasksStoreSaga,
  completeTaskSaga,
  declineTaskSaga,
  deleteCommentFromTasksStoreSaga,
  getCommentsForTaskEntitySaga,
  getDependencyDataFromTasksStoreSaga,
  getExportTaskDataSaga,
  getSingleTaskSaga,
  getTaskByVehicleIdSaga,
  getTasksSaga,
  getTaskTimeInApprovalSaga,
  updateCommentFromTasksStoreSaga,
  updateFinalCostForTaskSaga,
  updateReconPlanSaga,
  updateReconPlanSagaWithGroups,
  updateTaskSaga,
  getDealershipForVendor
} from 'store/tasksStore';
import { bulkUpdateTaskTypesSaga, getTaskTypesSaga, updateTaskTypesSaga } from 'store/taskTypesStore';
import {
  getUserAppStateSaga,
  setDisplayEmptyLineItemPageSaga,
  setDisplayMessageUpdateMaxApprovalTimeSaga,
  setDisplayVendorTermsAndConditionsSaga,
  setUserAppStateJournalLastCheckedSaga
} from 'store/userAppStateStore';
import {
  debounceUpdateNotificationSettingsSaga,
  getAccessibleDealersBySearchSaga,
  getFullAccessibleDealersSaga,
  getUserSettingsSaga,
  updateInSearchSaga,
  updateNotificationSettingsSaga
} from 'store/userSettingsStore';
import {
  getAssigneeSaga,
  getCurrentUserInfoSaga,
  getTaggableUsersTaskMessageSaga,
  getTaggableUsersVehicleNoteSaga,
  getUsersSaga,
  ResendVendorUserInviteSaga
} from 'store/usersStore';
import { getCommentsForVehicleSaga } from 'store/vdpStore';
import {
  addCommentFromVehiclesStoreSaga,
  archiveVehicle,
  deleteCommentFromVehiclesStoreSaga,
  deleteDocumentFromVehiclesStoreSaga,
  downloadDocumentFromVehiclesStoreSaga,
  excludeVehicle,
  exportVehiclesSaga,
  getDependencyDataFromVehiclesStoreSaga,
  getDocumentByVehiclesStoreSaga,
  getInProgressCountByDispositionSaga,
  getPhotosForVehicleEntitySaga,
  getVehicleByIdSaga,
  getVehicleLocationsSaga,
  getVehiclesSaga,
  updateCommentFromVehiclesStoreSaga,
  uploadDocumentsFromVehiclesStoreSaga,
  getVehicleProfitTimeSaga
} from 'store/vehiclesStore';
import {
  addVendorUserSaga,
  updateVendorDealerInviteSaga,
  getAllVendorsSaga,
  getVendorDealerSaga,
  getVendorSaga,
  getVendorsSaga,
  getVendorTaskTypesSaga,
  getVendorUsersSaga,
  resetVendorDealerSaga,
  resetVendorSaga,
  searchVendorsSaga,
  setConfirmBusinessProfileSaga,
  setPasswordSaga,
  updateVendorByVendorSaga,
  updateVendorsSaga,
  updateVendorUserSaga,
  validateActivationLinkSaga
} from 'store/vendorStore';

const sagasToRun = {
  //appraisalInfo
  getAppraisalInfoSaga,
  // dealer
  getCurrentDealerSaga,
  updateDealerSaga,
  applyExcludeFilterSaga,
  getAccessibleDealersSaga,
  getAccessibleVendorsSaga,
  checkIfCurrentDealerIsAccessibleSaga,
  getDealersSaga,
  loadInventorySaga,
  //enterprise metrics
  getEnterprisePerformanceMetricsSaga,
  getEnterpriseHistoricalMetricsSaga,
  getEnterpriseNeedsAttentionMetricsSaga,
  getExportNeedAttentionMetricsSaga,
  getExportHistoricalMetricsSaga,
  // metrics
  getActiveInventoryMetricsSaga,
  getHistoricalMetricsSaga,
  getSnapshotHistoricalMetricsSaga,
  getHistoricalExportMetricsSaga,
  getExportEnterprisePerformanceMetrics,
  getInventoryExportMetricsSaga,
  getActiveInventoryPerformanceMetricsSaga,
  getHistoricalPerformanceMetricsSaga,
  // plan templates
  getPlanTemplatesSaga,
  updatePlanTemplatesSaga,
  bulkUpdatePlanTemplatesSaga,
  // task categories
  getTaskCategoriesSaga,
  getTaskCategoriesFromTaskTypeSaga,
  // tasks
  addCommentFromTasksStoreSaga,
  completeTaskSaga,
  declineTaskSaga,
  deleteCommentFromTasksStoreSaga,
  getCommentsForTaskEntitySaga,
  getExportTaskDataSaga,
  getSingleTaskSaga,
  getTaskByVehicleIdSaga,
  getTasksSaga,
  updateTaskSaga,
  updateReconPlanSaga,
  updateReconPlanSagaWithGroups,
  updateCommentFromTasksStoreSaga,
  updateFinalCostForTaskSaga,
  getTaskTimeInApprovalSaga,
  getDependencyDataFromTasksStoreSaga,
  getDealershipForVendor,
  // task types
  getTaskTypesSaga,
  updateTaskTypesSaga,
  bulkUpdateTaskTypesSaga,
  // vendors
  getVendorsSaga,
  updateVendorsSaga,
  searchVendorsSaga,
  getAllVendorsSaga,
  getVendorTaskTypesSaga,
  getVendorSaga,
  setPasswordSaga,
  validateActivationLinkSaga,
  resetVendorSaga,
  setConfirmBusinessProfileSaga,
  getVendorUsersSaga,
  addVendorUserSaga,
  updateVendorDealerInviteSaga,
  updateVendorUserSaga,
  updateVendorByVendorSaga,
  getVendorDealerSaga,
  resetVendorDealerSaga,
  // users
  getUsersSaga,
  getCurrentUserInfoSaga,
  getTaggableUsersVehicleNoteSaga,
  getTaggableUsersTaskMessageSaga,
  getAssigneeSaga,
  ResendVendorUserInviteSaga,
  //userSettings,
  getUserSettingsSaga,
  updateNotificationSettingsSaga,
  debounceUpdateNotificationSettingsSaga,
  getFullAccessibleDealersSaga,
  getAccessibleDealersBySearchSaga,
  updateInSearchSaga,
  // vehicles
  exportVehiclesSaga,
  getVehiclesSaga,
  getVehicleLocationsSaga,
  getVehicleByIdSaga,
  getInProgressCountByDispositionSaga,
  archiveVehicle,
  excludeVehicle,
  getDependencyDataFromVehiclesStoreSaga,
  getVehicleProfitTimeSaga,
  // getCommentsForVehicleEntitySaga,
  addCommentFromVehiclesStoreSaga,
  updateCommentFromVehiclesStoreSaga,
  deleteCommentFromVehiclesStoreSaga,
  getPhotosForVehicleEntitySaga,
  // journalHistory
  getUserJournalSaga,
  setUserJournalSeenSaga,
  getVehicleHistoryJournalSaga,
  getTaskHistoryJournalSaga,
  // userAppState
  getUserAppStateSaga,
  setUserAppStateJournalLastCheckedSaga,
  setDisplayEmptyLineItemPageSaga,
  setDisplayMessageUpdateMaxApprovalTimeSaga,
  setDisplayVendorTermsAndConditionsSaga,
  // documents
  getDocumentsSaga,
  getDocumentsByTaskSaga,
  deleteDocumentSaga,
  getDocumentByVehiclesStoreSaga,
  deleteDocumentFromVehiclesStoreSaga,
  downloadDocumentFromVehiclesStoreSaga,
  uploadDocumentsFromVehiclesStoreSaga,
  // vdp
  getCommentsForVehicleSaga,
  downloadTaskDocumentSaga,
  downloadVehicleDocumentSaga,
  uploadTaskDocumentSaga,
  uploadVehicleDocumentSaga,
  // line item
  getLineItemsSaga,
  patchLineItemsSaga,
  patchLineItemsTDPSaga,
  patchLineItemsPlanTemplateSaga,
  patchLineItemsTaskTemplateSaga,
  updateReconTaskSaga,
  getLineItemsReconPlanTemplateTaskSaga,
  getLineItemsTaskTemplateSaga,
  // line item catalog
  getLineItemCatalogSaga,
  deleteLineItemCatalogSaga,
  updateLineItemCatalogSaga,
  addNewLineItemCatalogSaga,
  // internal group
  getInternalGroupSaga,
  getInternalGroupsSaga,
  updateInternalGroupsSaga
};

export const initSagas = (sagaMiddleWare) => {
  Object.values(sagasToRun).forEach(sagaMiddleWare.run.bind(sagaMiddleWare));
};
