import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Input, Select, Switch, Tooltip, Typography, Form } from 'antd';
import { apiStatusConstants, maxEstAndTotalCostReconApprovalWorkflow } from 'app-constants';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { CurrencyInput, AssignedToSelect } from 'components';
import { CommonSelect } from 'components/styledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { elementRotate } from 'styles/mixins';
import { isTaskPassThroughEditable } from 'components/TaskGroupsDnd/helpers/functions';

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const addNewOrEditTaskFormSelector = createSelector(
  (state) => state.users,
  (state) => state.taskTypes,
  (usersContainer, taskTypesContainer) => {
    return {
      users: usersContainer.assigneeData?.internalUsers,
      internalGroups: usersContainer.assigneeData?.internalGroups,
      vendors: usersContainer.assigneeData?.vendors,
      usersFetchStatus: usersContainer.fetchStatus,
      taskTypes: taskTypesContainer.data,
      taskTypesFetchStatus: taskTypesContainer.fetchStatus
    };
  }
);

const AddNewOrEditTaskForm = ({
  form,
  taskToUpdate,
  assignedToOptional,
  templateDropdownMenu,
  planTemplates,
  showCreateReconPlanFromTemplateButton,
  closeForm,
  getFieldDecorator,
  onChange,
  isInProgressTaskGroup,
  userIdGetAssignToByTypeName = undefined,
  flags,
  assignedToMember
}) => {
  const { users, usersFetchStatus, taskTypes, taskTypesFetchStatus, internalGroups, vendors } = useSelector(
    addNewOrEditTaskFormSelector
  );
  const [templateDropdownVisible, toggleTemplateDropdownVisible] = useState(false); //for 'create using templates' button icon animation

  //When adding a brand new task, taskToUpdate is null. We should enable editing pass through for new tasks.
  const canEditPassThrough = !!taskToUpdate
    ? isTaskPassThroughEditable(taskToUpdate, isInProgressTaskGroup, flags)
    : true;

  return (
    <>
      <StyledFormItem label="Type">
        {getFieldDecorator('reconTaskTypeName', {
          rules: [
            {
              required: true,
              message: 'Type is required'
            }
          ]
        })(
          taskTypesFetchStatus !== apiStatusConstants.FAILED ? (
            <CommonSelect
              showSearch
              placeholder="Select task type"
              loading={!taskTypesFetchStatus || taskTypesFetchStatus === apiStatusConstants.IS_FETCHING}
              onChange={onChange('reconTaskTypeName')}
            >
              {/**Only show types not scheduled for deletion unless updating a task that is using a type scheduled for deletion */}

              {taskTypes
                .filter((tt) => tt.id === taskToUpdate?.reconTaskTypeId || !tt.scheduledForDeletion)
                .map((t, index) => (
                  <WrapOption key={t.id + '_WrapOption'} value={t.name}>
                    {t.name}
                  </WrapOption>
                ))}
            </CommonSelect>
          ) : (
            <Text type="danger" ellipsis>
              Something went wrong retrieving task types information
            </Text>
          )
        )}
      </StyledFormItem>

      <StyledFormItem
        disabled={!canEditPassThrough}
        label={
          <>
            Pass through <StyledArrowRightIcon icon={faArrowRight} />
          </>
        }
      >
        {getFieldDecorator('passthrough', {
          valuePropName: 'checked'
        })(
          <>
            <StyledTooltip
              placement="topRight"
              overlayClassName="passthrough-toggle"
              title={
                <Text>
                  {
                    canEditPassThrough
                      ? 'Once this task is in progress it will remain a pass through task and this state cannot be removed.' //Pass through is editable on this task, warn user that once task is in-progress, pass through cannot be removed
                      : !taskToUpdate?.hasOwnProperty('group') //Task will only have a "group" property if it is the only task within its group (See L76 of DndContainerFunctions.js)
                      ? 'Pass through cannot be applied to tasks within a task group.' //This task is in a group of 2+ tasks, so display this message about not being able to add pass through to a task in a group
                      : 'The pass through state cannot be removed once the task is in progress.' //This task is not in a group of 2+ tasks (displayed standalone), so display this message about not being allowed to remove pass through from an in-progress task
                  }
                </Text>
              }
            >
              <Switch
                defaultChecked={taskToUpdate?.passthrough}
                disabled={!canEditPassThrough}
                onChange={onChange('passthrough')}
              />
            </StyledTooltip>
            <StyledPassThroughSubtext disabled={!canEditPassThrough}>
              Pass through tasks will notify the assignee and progress to the next step in the plan until it is
              completed.
            </StyledPassThroughSubtext>
          </>
        )}
      </StyledFormItem>

      <AssignedToSelect
        form={form}
        getFieldDecorator={getFieldDecorator}
        assignedToOptional={assignedToOptional}
        users={users}
        internalGroups={internalGroups}
        vendors={vendors}
        usersFetchStatus={usersFetchStatus}
        onChange={onChange}
        assignedToMember={assignedToMember}
        userIdGetAssignToByTypeName={userIdGetAssignToByTypeName}
        taskToUpdate={taskToUpdate}
        isShowNoAssignee={window.location.pathname.includes('/configure/plantemplates')}
      />

      <StyledFormItem label="Estimated cost">
        {getFieldDecorator('estimatedCost')(
          <CurrencyInput
            onChange={(e) => onChange('estimatedCost')(!isNaN(e) ? e : null)}
            max={maxEstAndTotalCostReconApprovalWorkflow}
          />
        )}
      </StyledFormItem>
      <StyledFormItem label="Description">
        {getFieldDecorator('description')(
          <TextArea
            placeholder="Type to enter a description"
            rows={3}
            autoSize={{ minRows: 7, maxRows: 14 }}
            onChange={(e) => onChange('description')(e.target.value)}
          />
        )}
      </StyledFormItem>
      {showCreateReconPlanFromTemplateButton &&
        (planTemplates.data.length > 0 ? (
          <Dropdown
            trigger={['click']}
            overlay={templateDropdownMenu(closeForm)}
            onVisibleChange={(visible) => toggleTemplateDropdownVisible(visible)}
          >
            <TemplatePlanCTA data-template-dropdown-visible={templateDropdownVisible}>
              Create Using Templates
              <DropdownIcon icon={faAngleDown} size="lg" />
            </TemplatePlanCTA>
          </Dropdown>
        ) : (
          <Tooltip
            placement="right"
            overlayClassName="create-recon-plan-from-template-tooltip"
            title={<Text style={{ color: 'red' }}>No templates available</Text>}
          >
            <TemplatePlanCTA data-template-dropdown-visible={templateDropdownVisible}>
              Create Using Templates
              <DropdownIcon icon={faAngleDown} size="lg" />
            </TemplatePlanCTA>
          </Tooltip>
        ))}
    </>
  );
};

//#region Styled Components
const StyledTooltip = styled(Tooltip)`
  display: block;
  float: right;
  margin-top: -27px !important;
`;
const StyledArrowRightIcon = styled(FontAwesomeIcon)`
  margin-left: 6px;
`;
const StyledPassThroughSubtext = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.darkGray};
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const TemplatePlanCTA = styled(Button).attrs({
  type: 'danger'
})`
  .ant-btn& {
    pointer-events: all;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    border-radius: 4px;
    border: none;
    outline: none;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 8px;
    cursor: pointer;
  }
  ${elementRotate('fa-angle-down', 'data-template-dropdown-visible', 180, 0.3)}
`;
const DropdownIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;
`;

const WrapOption = styled(Option)``;

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item& {
    .ant-form-item-label {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      margin-bottom: 4px;
    }
    &[disabled] .ant-form-item-label {
      label {
        color: ${({ theme }) => theme.colors.darkGray};
      }
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
//#endregion

export default withLDConsumer()(AddNewOrEditTaskForm);
