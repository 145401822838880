//DraggableItem is a recursive function component
//the code that is written for a task group task is the same code written for a task group
//while this works great in the restrictive situations that we have written to allow for drops, it creates problems for us when we are needing to separate logic out specifically for a drop event on a task group task
//we can't set state to prevent this propagation from occurring because it takes too long for the new state value to be used. same thing with refs.

//to solve this issue, ES6 modules provides a nice feature
//by setting up a getter and a setter to change the value of a variable from within a different module, the value of the variable becomes live updated and its new value becomes immediately accessible to us

//that's why this small bit of code is in a different module
//it is used in DraggableItem and DropPlaceholder to prevent a double drop event (one on the task group and one on the task group task or on the drop placeholder) from occurring when a drop event has occurred on a task group task or a drop placeholder
//we need to be able to have the same variable be used for different instances of the same component as well as for DropPlaceholder
//we toggle performDropOperations to be true when dragging starts
//we toggle performDropOperations to be false after the first drop event has finished
//we use performDropOperations as a gatekeeper when checking whether or not a drop can occur in the useDrop property canDrop

// a setter is required to change performDropOperations because when we import performDropOperations into another module, it is read-only
// it prevents direct write access to only be from a setter function
// but we can read it from anywhere


//it should be stated that the primary reason that all of this is needed is because we still want to be able to drop on a task group header, but that's nothing but a DraggableItem with a model type of TASK_GROUP

// PLEASE READ THE ABOVE AND UNDERSTAND HOW THIS IS BEING USED BEFORE CHANGING THIS

let performDropOperationsToggle = false;
export const getPerformDropOperationsToggle = () => performDropOperationsToggle;
export const setPerformDropOperationsToggle = (booleanValue) => {
  performDropOperationsToggle = booleanValue;
}