import React from 'react';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';

const LoadingIcon = ({ ghost = false }) => {
  return (
    <StyledLoadingIcon isGhostMode={ghost}>
      <div className="spinner-icon">
        <div className="spinner1"></div>
        <div className="spinner2"></div>
        <div className="spinner3"></div>
      </div>
    </StyledLoadingIcon>
  );
};

const StyledLoadingIcon = styled.div`
  & {
    display: inline-block;
  }
  .spinner-icon {
    margin: auto;
    width: 28px;
    height: 28px;
    margin-top: 2px;
    text-align: center;
  }
  .spinner-icon > div {
    width: 3px;
    height: 3px;
    background-color: ${({ isGhostMode }) => (isGhostMode ? themeStyles.colors.red : themeStyles.colors.white)};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: spd-bouncedelay 1.4s infinite ease-in-out both;
    animation: spd-bouncedelay 1.4s infinite ease-in-out both;
  }
  .spinner-icon .spinner1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    margin-right: 4px;
  }
  .spinner-icon .spinner2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    margin-right: 4px;
  }
  .spinner-icon .spinner3 {
    margin-right: 2px;
  }
  @-webkit-keyframes spd-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes spd-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export default LoadingIcon;
