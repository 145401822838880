import moment from 'moment';
import { formattedPercentCalculation } from 'utils';
// eslint-disable-next-line no-unused-vars
import momentDurationFormatSetup from 'moment-duration-format'; // Do not remove this even if it looks like it's not used.

const locale = 'en-US';
const withYear = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
const noYear = { weekday: 'short', month: 'short', day: 'numeric' };
const weekdayShort = { weekday: 'short' };
const withoutWeekday = { year: 'numeric', month: 'short', day: 'numeric' };
const currentYear = new Date().getFullYear();
const oneYearAgo = moment().subtract(1, 'years');

export const formatDateTime = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }

  //include year if it's not the current one
  const options = date.getFullYear() === currentYear ? noYear : withYear;
  return `${date.toLocaleDateString(locale, options)} at ${date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: 'numeric'
  })}`;
};

export const formatDateTimeMMDDYYYY = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return {
    date: moment(date).format('MM-DD-YY'),
    time: date.toLocaleTimeString(locale, {
      hour: 'numeric',
      minute: 'numeric'
    })
  };
};

export const formatDateWithYear = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return `${date.toLocaleDateString(locale, weekdayShort)}., ${date.toLocaleDateString(locale, withoutWeekday)}`;
};

export const formatTime = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return `${date.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' })}`;
};

export const formartMMMDDYYYY = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return `${moment(date).format('MMM DD, YYYY')}`;
};

export const formatMMDDYY = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return `${moment(date).format('MM-DD-YY')}`;
};

export const dateTimeWithFormat = (date, format) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return `${moment(date).format(format)}`;
};

export const simpleElapsedDuration = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  // Display the largest value or '< 1 minute' if less than one minute.
  return `${duration.format(`d __ h _ m _`, { minValue: 1, largest: 1 })} ago`;
};

const monthDiff = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  var currentDate = new Date();
  var months;
  months = (currentDate.getFullYear() - date.getFullYear()) * 12;
  months -= date.getMonth();
  months += currentDate.getMonth();
  return months <= 0 ? 0 : months;
};

export const dateTimeFromNow = (dateTime) => {
  const dateTimeMoment = moment(dateTime);
  // Round relative time evaluation down
  moment.relativeTimeRounding(Math.floor);
  // Set relative time thresholds
  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 30);
  moment.relativeTimeThreshold('M', 12);
  if (dateTimeMoment > oneYearAgo) {
    // Current day: "3 hours ago"
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'Just Now',
        ss: 'Just Now',
        m: '1m ago',
        mm: '%dm ago',
        h: '1h ago',
        hh: '%dh ago',
        d: '1d ago',
        dd: '%dd ago',
        M: '1 month ago',
        MM: '%d months ago'
      }
    });
    return dateTimeMoment.fromNow(true);
  }
  // if the date is older than 1 year from now, calculate the elapsed duration to months
  const diffMonth = monthDiff(dateTime);
  return diffMonth + ' months ago';
};

const emDash = String.fromCharCode(8212);

export const displayDurationSummary = (
  durationToDisplay,
  showDefaultForZero = true,
  abbreviate = false,
  includeMinutes = false,
  defaultValue = emDash
) => {
  if (!durationToDisplay) {
    return defaultValue;
  }

  const totalMinutes = durationToDisplay.asMinutes();

  if (totalMinutes === 0 && showDefaultForZero) {
    return defaultValue;
  }

  //the following format variables are used by momentjs to return a string in a specific format
  //where the 'm', 'd', 'h' are specified in the format string, that is where the actual values for those time units are displayed
  //what follows (either __ or []) is what the unit measure will display as

  const minuteFormat = abbreviate ? `m[m]` : `m __`; // [] are escape tokens where you can specify your own string, Double underscore uses full name with pluralization.
  if (totalMinutes < 60) {
    return durationToDisplay.format(minuteFormat, { minValue: 1 });
  }

  let fullDurationFormat = abbreviate ? `d[d] h[h]` : `d __ h __`;
  if (includeMinutes) {
    fullDurationFormat = fullDurationFormat + ' ' + minuteFormat;
  }
  return durationToDisplay.format(fullDurationFormat, { trunc: true, trim: 'all' }); // trunc will truncate any fractions example: 1.1 hour would just be 1 hour.  trim will trim all 0 values
};

// Converts to an ISO8601 compliant duration string format
export const toIsoDurationString = ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
  return toMomentDuration({ days, hours, minutes, seconds }).toISOString();
};

// Converts from numbers to a MomentJs duration object
export const toMomentDuration = ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
  return moment.duration({ days, hours, minutes, seconds });
};

// Converts from an ISO8601 compliant duration string format into a MomentJs duration object
export const getMomentDurationFromString = (iso8601duration) => {
  return moment.duration(iso8601duration);
};

export const getHoursAndDaysFromString = (thresholdString, defaultDays, defaultHours) => {
  const thresholdMoment = thresholdString && getMomentDurationFromString(thresholdString);
  let days, hours;
  if (thresholdMoment?._days === 0) {
    days = 0;
  } else if (thresholdMoment?._days) {
    days = thresholdMoment._days;
  } else {
    days = defaultDays;
  }
  if (thresholdMoment?._data?.hours === 0) {
    hours = 0;
  } else if (thresholdMoment?._data?.hours) {
    hours = thresholdMoment._data.hours;
  } else {
    hours = defaultHours;
  }
  return { days, hours };
};

// Compares two durations to see which is larger
//  1 = duration1 is larger
// -1 = duration2 is larger
//  0 = durations are equal
export const compareDuration = (duration1, duration2) => {
  // we need a local copy because the subtract() function modifies the object
  let duration1Local = moment.duration(duration1.toISOString());
  const millisecondsDiff = duration1Local.subtract(duration2).asMilliseconds();
  return Math.sign(millisecondsDiff);
};

//calculate difference between two ISO8601 duration strings
//format of the string passed in as parameter value need to be in ISO8601 format
//an example of this would be 'P9DT23H17M2S144s208000n'
//format duration diff strings in terms of days and hours as well as percent
//if the function is invoked with only one date, use the first parameter's value as the second's default value - the duration differences will be 0
export const formatAndCalculateDurationStrings = (latestDuration, previousDuration, reconDealerFilterFlag = false) => {
  if (!latestDuration) devLogger.error('need to pass at least one duration');
  if (typeof latestDuration !== 'string') devLogger.error('need to pass duration in ISO8601 string format');
  if (previousDuration && typeof previousDuration !== 'string')
    devLogger.error('need to pass both durations in ISO8601 string format');
  //calculate duration decimals from momentjs duration
  const parsedDurationLatest = moment.duration(latestDuration);

  const parsedDurationPrevious = moment.duration(previousDuration);

  const latestTimeInDaysDecimal = parsedDurationLatest.asDays();
  const previousTimeInDaysDecimal = parsedDurationPrevious.asDays();
  //calculate x days y hours strings, and diff values for whole days and whole hours
  // this function only used in overview for now, please check the business and decide whether to pass
  // the allowNullValueAsDash into calculateDaysAndHoursFromDurationDecimal or not
  const { durationFormattedString: latestDurationFormattedString } = calculateDaysAndHoursFromDurationDecimal(
    parsedDurationLatest,
    'shortHandFormat',
    true,
    reconDealerFilterFlag
  );
  const { durationFormattedString: previousDurationFormattedString } = calculateDaysAndHoursFromDurationDecimal(
    parsedDurationPrevious,
    'shortHandFormat',
    true,
    reconDealerFilterFlag
  );
  let numWholeDaysDiff, numWholeHoursDiff, timeDiffFormattedString, formattedTimePercentDifference, percentTimeDiff; //data calculated and returned IF latest duration is different than previous duration
  if (typeof latestDuration === 'string' && typeof previousDuration === 'string') {
    if (latestDuration !== previousDuration) {
      //only calculate diffs if the durations are not the same, making sure that they are both strings
      const durationDiffObject = parsedDurationLatest.clone().subtract(parsedDurationPrevious);

      ({
        reportedDays: numWholeDaysDiff,
        reportedHours: numWholeHoursDiff,
        durationFormattedString: timeDiffFormattedString
      } = calculateDaysAndHoursFromDurationDecimal(durationDiffObject, 'shortHandFormat', true, reconDealerFilterFlag));

      //calculating percent difference in time for ISO 8601 formatted time stamps
      //most easily handled in seconds
      const latestTimeSeconds = parsedDurationLatest.asSeconds();
      const previousTimeSeconds = parsedDurationPrevious.asSeconds();
      const timeDiffSeconds = latestTimeSeconds - previousTimeSeconds;
      percentTimeDiff =
        reconDealerFilterFlag && latestTimeSeconds === timeDiffSeconds ? 1 : timeDiffSeconds / previousTimeSeconds;
      formattedTimePercentDifference = formattedPercentCalculation(percentTimeDiff, reconDealerFilterFlag);
    } else {
      numWholeDaysDiff = numWholeHoursDiff = 0;
      numWholeHoursDiff = '0';
      timeDiffFormattedString = '0 days';
      formattedTimePercentDifference = reconDealerFilterFlag ? '0%' : '--';
      percentTimeDiff = 0;
    }
  }
  if (reconDealerFilterFlag && typeof latestDuration === 'string' && !previousDuration) {
    const latestTimeSeconds = parsedDurationLatest.asSeconds();
    percentTimeDiff = !!latestTimeSeconds ? 1 : 0;
    formattedTimePercentDifference = !!latestTimeSeconds ? '+100%' : '0%';
  }

  //returning a bunch of stuff here so that users of this function can have options as to what they want to destructure from an invocation of the function
  return {
    latestTimeInDaysDecimal,
    previousTimeInDaysDecimal,
    latestDurationFormattedString,
    previousDurationFormattedString,
    dayDiffDecimal: latestTimeInDaysDecimal - previousTimeInDaysDecimal,
    numWholeDaysDiff,
    numWholeHoursDiff,
    percentTimeDiff,
    timeDiffFormattedString,
    formattedTimePercentDifference
  };
};

export const calculateDaysAndHoursFromDurationDecimal = (
  durationObject,
  dashboardType,
  allowNullValueAsDash = false,
  reconDealerFilterFlag = false
) => {
  if (reconDealerFilterFlag && !durationObject) {
    return { durationFormattedString: String.fromCharCode(8212), sortValue: 0 };
  }
  if (process.env.NODE_ENV === 'development') {
    if (typeof durationObject && !durationObject._isValid)
      return console.error(
        'calculateDaysAndHoursFromDurationDecimal type error: need to pass a moment object as parameter in function calculateDaysAndHoursFromDurationDecimal'
      );
  }
  const durationDecimalMagnitude = Math.abs(durationObject.asDays()); //need to convert durationDecimal to magnitude in case it is negative
  const wholeDays = Math.floor(durationDecimalMagnitude); //wholeDays is the whole day portion of dayDecimal variables
  const hoursDecimal = Math.abs(durationDecimalMagnitude - wholeDays) * 24;
  if (wholeDays === 0 && hoursDecimal < 1) {
    //handling smaller than ~ 1 hour durations - want to show minutes
    const minutesDecimal = durationDecimalMagnitude * 24 * 60;
    const wholeMinutes = Math.floor(minutesDecimal);

    const durationFormattedString = setDurationFormattedString(
      allowNullValueAsDash,
      wholeMinutes,
      minutesDecimal,
      dashboardType,
      reconDealerFilterFlag
    );
    return { durationFormattedString, durationDecimalMagnitude, reportedDays: 0, reportedHours: 0 };
  } else {
    const wholeHours = Math.floor(hoursDecimal); //numWholeHours is the ROUNDED number of hours that are represented in dayDecimal variables

    const additionalDayComputedFromRoundedHours = wholeHours === 24 ? 1 : 0; //if roundedHours === 24, that's an extra day, and should be reported as a day, not x days 24 hours
    const reportedDays = wholeDays + additionalDayComputedFromRoundedHours; //add an extra day to the wholeDays figure, if roundedHours is 24 hours
    const reportedHours = wholeHours === 24 ? 0 : wholeHours; //if roundedHours === 24, that's an extra day which will be accounted for in reportedDays - in this case, report 0 hours; otherwise, show the roundedHours calculated

    let durationFormattedString = decideFormat(reportedDays, reportedHours, dashboardType);

    return { reportedDays, reportedHours, durationDecimalMagnitude, durationFormattedString };
  }
};

const setDurationFormattedString = (
  allowNullValueAsDash,
  wholeMinutes,
  minutesDecimal,
  dashboardType,
  reconDealerFilterFlag
) => {
  const correctFormatForOneMin = dashboardType === 'longHandFormat' ? '1 minute' : '1m';
  const correctFormatForLessThanOneMin = dashboardType === 'longHandFormat' ? '< 1 minute' : '< 1m';
  if (reconDealerFilterFlag) {
    if (minutesDecimal < 1) {
      return minutesDecimal === 0 ? '0h' : correctFormatForLessThanOneMin;
    }
    if (wholeMinutes <= 1) {
      return wholeMinutes === 0 ? '0h' : correctFormatForOneMin;
    }
  }
  if (allowNullValueAsDash) {
    if (wholeMinutes <= 1) {
      if (wholeMinutes === 0) {
        return '--';
      } else {
        return correctFormatForOneMin;
      }
    }
  } else {
    if (minutesDecimal < 1) {
      if (minutesDecimal === 0) {
        return '--';
      } else {
        return correctFormatForLessThanOneMin;
      }
    }
  }

  return dashboardType === 'longHandFormat' ? `${wholeMinutes} minutes` : `${wholeMinutes}m`;
};

const decideFormat = (reportedDays, reportedHours, dashboardType) => {
  let durationFormattedString;

  if (dashboardType === 'longHandFormat') {
    if (reportedDays > 0 && reportedHours > 0) {
      durationFormattedString = `${reportedDays} days ${reportedHours} hours`; //formatted duration strings in x days x hours format
    } else if (reportedDays > 0) {
      durationFormattedString = `${reportedDays} days`;
    } else if (reportedHours > 0) {
      durationFormattedString = `${reportedHours} hour${reportedHours === 1 ? '' : 's'}`;
    }
  } else if (dashboardType === 'shortHandFormat') {
    if (reportedDays > 0 && reportedHours > 0) {
      durationFormattedString = `${reportedDays}d ${reportedHours}h`; //formatted duration strings in x days x hours format
    } else if (reportedDays > 0) {
      durationFormattedString = `${reportedDays}d`;
    } else if (reportedHours > 0) {
      durationFormattedString = `${reportedHours}h`;
    }
  }

  return durationFormattedString;
};

//calculates total duration across multiple durations
//array shape expected is an object array
//keyOfDurationProp should be a string
//duration
export const addDurationsFromObjectArray = (array, keyOfDurationProp) => {
  if (!Array.isArray(array))
    return devLogger.error('addDurationsFromObjectArray error: need to pass an array as the first parameter') || {};
  if (array.length > 0) {
    if (typeof array[0] !== 'object')
      return devLogger.error('addDurationsFromObjectArray error: need to pass an array of objects') || {};
    if (typeof array[0][keyOfDurationProp] === 'undefined')
      return (
        devLogger.error(
          'addDurationsFromObjectArray error: need to pass a key for a property that is in the objects of the array passed. Currently, when searching for keyOfDurationProp, returning undefined.'
        ) || {}
      );
  } else {
    return devLogger.error('addDurationsFromObjectArray error: array length is 0') || {};
  }

  if (
    !Array.isArray(array) ||
    (array.length > 0 && (typeof array[0] !== 'object' || typeof array[0][keyOfDurationProp] === 'undefined')) ||
    array.length === 0
  ) {
    return {};
  }

  const totalDurationMoment = array.reduce((totalDurationMoment, { [keyOfDurationProp]: duration }) => {
    const durationToPass = moment.isMoment(duration) ? duration : moment.duration(duration);
    return totalDurationMoment.add(durationToPass);
  }, moment.duration('P0D'));

  const {
    _data: { days, hours, months }
  } = totalDurationMoment;

  //return several parts of this so that dev users can choose what they want from this function
  return {
    totalDurationMoment,
    days,
    hours,
    months
  };
};

//this function returns the start and end date parameters for retrieving data from the historical api endpoint
//function takes a daysAgo variable: a number in string format which will be parsed in this function to use to calculate the date daysAgo days ago
//returned from this function is today's date and the calculated beginning date of a period daysAgo longs
//end date is calculated as today
export const generateDateRangeFromDaysAgo = (daysAgo, endingMoment) => {
  //endingMoment will not be passed in the case that the end date should be today's date
  if (process.env.NODE_ENV === 'development' && isNaN(daysAgo))
    throw new Error('generateDateRangeParams error: variable daysAgo passed with a value that is not a number');
  //today moment
  let endDateMoment;
  if (typeof endingMoment === 'undefined') {
    //no value was passed for endingMoment -> end date should be today's date
    endDateMoment = moment().endOf('day');
  } else {
    endDateMoment = endingMoment.clone();
  }
  //end time calculation
  const endTime = endDateMoment.format('MM-DD-YYYY');
  //start time calculation
  // Add back one day to account for the fact that we're now treating "today" as an entire day
  const startDateMoment = endDateMoment.subtract(daysAgo, 'days').add(1, 'days');
  const startTime = startDateMoment.format('MM-DD-YYYY');
  return { startTime, endTime, daysAgo };
};

//generate date range parameters given beginning and ending date momentjs objects
export const generateDateRangeFromMoments = ([beginningMoment, endingMoment]) => {
  if (process.env.NODE_ENV === 'development') {
    if (!beginningMoment?._isAMomentObject || !endingMoment?._isAMomentObject)
      return console.error(
        'generateDateRangeFromMoments error: moment parameter was passed with something that was not a momentjs object.'
      );
  }
  //convert to month-day-year format (+1 needed because month starts at January = 0)
  const endTime = `${endingMoment._d.getMonth() + 1}-${endingMoment._d.getDate()}-${endingMoment._d.getFullYear()}`;
  const startTime = `${
    beginningMoment._d.getMonth() + 1
  }-${beginningMoment._d.getDate()}-${beginningMoment._d.getFullYear()}`;
  return { startTime, endTime, daysAgo: endingMoment.diff(beginningMoment, 'days') + 1 }; // Add 1 day because 7/15/20 - 7/15/20 should display "1 day" instead of "0"
};

//takes different date strings with different date formats and finds the earliest date using momentjs
export const earliestDateString = (...dateStrings) => {
  const moments = dateStrings
    .map((dateString) => {
      if (typeof dateString === 'object' && dateString._isAMomentObject) {
        return dateString;
      } else if (typeof dateString === 'string') {
        return moment(dateString);
      } else return null;
    })
    .filter((m) => !!m);
  return moment.min(moments)._i;
};

const determineMonthAbbrev = (dateMoment) => (dateMoment._i.substr(0, 2) !== '05' ? '.' : '');

export const formatDateRanges = (daysAgo, startDateMoment, endDateMoment) => {
  const { startTime: previousPeriodBeginDate, endTime: previousPeriodEndDate } = generateDateRangeFromDaysAgo(
    daysAgo,
    startDateMoment.clone().subtract(1, 'days')
  ); //calculate previous date range's beginning date

  const startDateFormattedString = startDateMoment.format(`MMM${determineMonthAbbrev(startDateMoment)} DD`); //should abbreviate months' names longer than 3 letters - only May, the 5th month, doesn't have a '.' after it
  const endDateFormattedString = endDateMoment.format(`MMM${determineMonthAbbrev(endDateMoment)} DD`);

  let previousPeriodBeginMoment = moment(previousPeriodBeginDate, 'MM-DD-YYYY');
  let previousPeriodEndMoment = moment(previousPeriodEndDate, 'MM-DD-YYYY');
  const previousPeriodBeginDateFormattedString = previousPeriodBeginMoment.format(
    `MMM${determineMonthAbbrev(previousPeriodBeginMoment)} DD`
  );
  const previousPeriodEndDateFormattedString = previousPeriodEndMoment.format(
    `MMM${determineMonthAbbrev(previousPeriodEndMoment)} DD`
  );

  const previousPeriodFormattedDateRangeString = `${previousPeriodBeginDateFormattedString} - ${previousPeriodEndDateFormattedString}`;
  const latestPeriodFormattedDateRangeString = `${startDateFormattedString} - ${endDateFormattedString}`;

  return {
    previousPeriodFormattedDateRangeString,
    latestPeriodFormattedDateRangeString
  };
};

export const elapsedHours = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return duration.asHours();
};
