import { useMemo } from 'react';
import { IS_NEW_SIGNIN, VENDOR_USER_INFO } from 'app-constants';
import { useSelector } from 'react-redux';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const useVendorRole = () => {
  const isNewSignin = JSON.parse(localStorage.getItem(IS_NEW_SIGNIN));
  const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
  const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  return useMemo(() => {
    const isVendorAdmin = (vendorInfo.role?.id === 1 && isNewSignin) || (isRootUser && vendorShipSelected);
    const isVendorTech = vendorInfo.role?.id === 2 && isNewSignin;

    return {
      isVendorAdmin,
      isVendorTech,
      vendorUserId: vendorInfo.id,
      isVendor: isVendorAdmin || isVendorTech
    };
  }, [vendorInfo.role?.id, vendorShipSelected]);
};

export default useVendorRole;
