import { useReducer } from 'react';

const initialState = {
  isFirstFetch: true,
  count: 0,
  sortOptions: {},
  selectedCard: { contentType: null },
  filters: {},
  displayRange: '',
  isInitialVehiclesFetch: true,
  sortDropdownIsOpen: false,
  formProps: null,
  showDiscardButton: false,
  scrollToId: null,
  isSaving: '',
  transientTasks: [],
  templatePlanCreatedFrom: '',
  tasksWithTransientTasks: [],
  openVdpOnDiscard: false,
  taskGroups: [],
  originalTaskGroups: [],
  isShowCommunicationInput: false,
  isSavingNote: false
};

const reducer = (state, action) => {
  const { type, payload } = action;
  return { ...state, [type]: payload };
};

export const useVehiclePresentationState = (query) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, dispatch];
};
