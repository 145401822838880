import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tasksActions } from 'store/tasksStore';
import { vehiclesActions } from 'store/vehiclesStore';

const withCommunications = (communicationsStoreType) => (WrappedComponent) => {
  const HOC = (props) => {
    const dispatch = useDispatch();
    const communicationsFetchStatus = useSelector((state) => state.vdp.communicationsFetchStatus);
    const { messageTaskStatus } = useSelector((state) => state.tasks);
    const [communications, setCommunications] = useState([]);
    const [focusTo, setFocusTo] = useState(null);
    const [scrollTo, setScrollTo] = useState(null);

    const handleSetCommunications = (communications) => setCommunications(communications);

    const handleSetFocusTo = (focusTo) => setFocusTo(focusTo);

    const handleAddCommunication = (
      containerId,
      reconTaskId,
      vehicleId,
      content,
      contentData,
      images = [],
      loadingMessage = '',
      successMessage = '',
      errorMessage = '',
      locationAction = ''
    ) => {
      content = content.trimRight(); // trim message before save to DB
      const communication = {
        content,
        vehicleId,
        reconTaskId,
        contentData
      };

      if (communicationsStoreType === 'vehiclesStore') {
        dispatch(
          vehiclesActions.addCommentFromVehiclesStore(
            containerId,
            communication,
            images,
            loadingMessage,
            successMessage,
            errorMessage,
            locationAction
          )
        );
      } else if (communicationsStoreType === 'tasksStore') {
        dispatch(
          tasksActions.addCommentFromTasksStore(
            containerId,
            communication,
            images,
            loadingMessage,
            successMessage,
            errorMessage,
            locationAction
          )
        );
      } else {
        throw new Error('communicationsStoreType is invalid');
      }
    };

    const handleUpdateCommunication = (
      containerId,
      parentId,
      id,
      content,
      contentData,
      locationAction,
      successMessage,
      index = 0
    ) => {
      const newCommunications = [...communications];
      content = content.trimRight(); // trim message before save to DB
      let communicationToUpdate;
      if (parentId) {
        // If parentId exists, then we are updating a message in a task message thread
        const parent = newCommunications.find((x) => x.id === parentId);
        communicationToUpdate = parent.messages.find((x) => x.id === id);
      } else {
        communicationToUpdate = newCommunications.find((x) => x.id === id);
      }
      communicationToUpdate.content = content;
      communicationToUpdate.contentData = contentData;

      if (communicationsStoreType === 'vehiclesStore') {
        dispatch(
          vehiclesActions.updateCommentFromVehiclesStore(
            containerId,
            communicationToUpdate,
            index,
            locationAction,
            successMessage
          )
        );
      } else if (communicationsStoreType === 'tasksStore') {
        dispatch(tasksActions.updateCommentFromTasksStore(containerId, communicationToUpdate, index));
      } else {
        throw new Error('communicationsStoreType is invalid');
      }
    };

    const handleDeleteCommunication = (containerId, id, successMessage = '', loadingMessage = '') => {
      if (communicationsStoreType === 'vehiclesStore') {
        dispatch(vehiclesActions.deleteCommentFromVehiclesStore(containerId, id, successMessage, loadingMessage));
      } else if (communicationsStoreType === 'tasksStore') {
        dispatch(tasksActions.deleteCommentFromTasksStore(containerId, id, successMessage, loadingMessage));
      } else {
        throw new Error('communicationsStoreType is invalid');
      }
    };

    return (
      <WrappedComponent
        {...props}
        communications={communications}
        setCommunications={handleSetCommunications}
        focusTo={focusTo}
        setFocusTo={handleSetFocusTo}
        scrollTo={scrollTo}
        setScrollTo={setScrollTo}
        addCommunication={handleAddCommunication}
        updateCommunication={handleUpdateCommunication}
        deleteCommunication={handleDeleteCommunication}
        fetchStatus={communicationsStoreType === 'vehiclesStore' ? communicationsFetchStatus : messageTaskStatus}
      />
    );
  };

  return HOC;
};

export default withCommunications;
