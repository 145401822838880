import React from 'react';
import { BodyXSmallBoldShort } from 'components/styledComponents';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const PassThroughIndicator = ({ reconTaskCollapse = false }) => {
  return (
    <StyledPassthroughIndicator reconTaskCollapse={reconTaskCollapse}>
      {reconTaskCollapse ?
        <BodyXSmallBoldShort><StyledArrowRightIcon icon={faArrowRight}/>Pass Through</BodyXSmallBoldShort> :
        <span><StyledArrowRightIcon icon={faArrowRight}/>Pass Through</span>}
    </StyledPassthroughIndicator>
  );
};

//#region Styled Components
const StyledPassthroughIndicator = styled.div`
  font-size: ${({ theme, reconTaskCollapse }) => !reconTaskCollapse && theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: ${({ reconTaskCollapse }) => !reconTaskCollapse && '4px'};
  font-weight: ${({ theme, reconTaskCollapse }) => reconTaskCollapse && theme.fontWeights.medium};
`;
const StyledArrowRightIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
`;
//#endregion

export default PassThroughIndicator;
