import {useState, useEffect, useRef} from 'react';

//this is useful in case you want to cancel an action that is set to take place in the future by a setTimeout function
export const useTimeoutBoolean = (time = 1000, initialValue = false, setToValue = false) => {
    const [boolean, setBoolean] = useState(initialValue);
    let timeoutId = useRef();//need to persist this value across all renders

    useEffect(() => {
      timeoutId.current = setTimeout(() => {
        setBoolean(setToValue)
      }, time);
      return () => clearTimeout(timeoutId.current);//this garbage collecting is key here - it will run when the component is unmounted
    },[ setToValue, time ]);//only ran when mounting and unmounting component - time won't change, but is a required dependency

    return boolean;
};