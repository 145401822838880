import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useDebouncedEffect, useLocalStorage } from 'hooks';
import styled from 'styled-components';
import { isEqual, omit } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import DashboardControls from './DashboardControls';
import DashboardGreeting from './DashboardGreeting';
import { metricsActions } from 'store/metricsStore';
import { authSelector } from 'store/authStore';
import { InventorySnapshot } from 'components/layout/dashboard/currentInventory';
import { HistoricalPerformance } from './historicalPerformance';
import { apiStatusConstants, dashboardPreferencesTypes } from 'app-constants';
import { generateDateRangeFromDaysAgo } from 'utils/dateTimeUtils';
import { StringParam, useQueryParams } from 'use-query-params';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { usersActions } from 'store/usersStore';
import { CommonDropdownContext } from 'components/common/CommonDropdown';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { analyticsTagConstants, pushTagManagerEvent } from 'google-analytics';

export const SortContext = React.createContext();

const dashboardPropsSelector = createSelector(
  authSelector,
  (state) => state.dealers.current.data,
  (state) => state.metrics,
  (state) => state.users.assigneeData.dealerships,
  (state) => state.users.fetchStatus,
  (authStore, currentDealer, { activeMetricsFetchStatus, activeMetrics }, dealerships, usersFetchStatus) => ({
    userId: authStore.user.id,
    isBridgeUser: authStore.isBridgeUser,
    currentDealer,
    activeMetricsFetchStatus,
    activeMetrics,
    dealerships,
    usersFetchStatus
  })
);

const SNAPSHOT_FILTERS = { dispositions: [], ...generateDateRangeFromDaysAgo('30') };

const Dashboard = ({ flags }) => {
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const dispatch = useDispatch();
  const dashboardPreferencesLocalStorageObject = useLocalStorage('dashboardPreferences', {});
  const [savedDashboardPreferences, setSavedDashboardPreferences] = dashboardPreferencesLocalStorageObject;
  const [savedDealershipsFilter, setSavedDealershipsFilter] = useLocalStorage('dealershipsFilter', {});
  const [inDrawer, setInDrawer] = useState({
    open: false,
    before: {
      dealershipsFilter: null,
      historicalPerformanceFilters: null,
      excludedTaskCategoriesHistorical: null,
      excludedTaskCategoriesInventorySnapshot: null
    }
  });

  const savedHistoricalPerformanceFilters =
    savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS] ?? {};
  const [historicalPerformanceFilters, setHistoricalPerformanceFilters] = useState({
    ...savedHistoricalPerformanceFilters,
    dispositions: savedHistoricalPerformanceFilters?.dispositions || [],
    excludedTaskCategories:
      (flags?.reconExcludeTaskCategories &&
        savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]
          ?.excludedTaskCategories) ||
      [],
    ...(!savedHistoricalPerformanceFilters?.customDateRange && {
      ...generateDateRangeFromDaysAgo(savedHistoricalPerformanceFilters?.daysAgo || '30')
    })
  });
  const [dealershipsFilter, setDealershipsFilter] = useState(null);
  const [showSnapshot, toggleShowSnapshot] = useState(savedDashboardPreferences?.['showSnapshot'] ?? true); // Whether to Inventory Snapshot tab or Historical Performance tab
  const {
    userId,
    isBridgeUser,
    currentDealer,
    activeMetricsFetchStatus,
    activeMetrics,
    dealerships,
    usersFetchStatus
  } = useSelector(dashboardPropsSelector);
  const [taskBreakdownSort, setTaskBreakdownSort] = useState({});
  const shouldDebounce = useRef(false);

  const areHistoricalPerformanceFiltersValid = (filters) => {
    return (
      filters.startTime?.length > 0 &&
      typeof filters.endTime === 'string' &&
      filters.endTime?.length > 0 &&
      currentDealer.id
    );
  };

  useEffect(() => {
    return () => {
      if (flags.reconDealerFilter && isHQ) {
        //when exiting dashboard, set all these back to isFetching
        //as when recon dealer filter is on, we need to wait for getAssigneeAPI before fetching other stuff
        dispatch(metricsActions.setActiveMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
        dispatch(metricsActions.setHistoricalMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
        dispatch(metricsActions.setActiveInventoryPerformanceMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
        dispatch(metricsActions.setHistoricalPerformanceMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
        dispatch(metricsActions.setSnapshotHistoricalMetricsFetchStatus(apiStatusConstants.IS_FETCHING));
      }
    };
  }, [flags.reconDealerFilter && isHQ]);

  const checkIfChanged = (before = {}, after = {}) => {
    return !isEqual(before, after);
  };

  const [reconDealerFilterCondition, setReconDealerFilterCondition] = useState({
    isDealershipsFilterChanged: 0,
    isHistoricalFiltersChanged: 0,
    isExcludedTaskCategoriesInventorySnapshotChanged: 0,
    isExcludedTaskCategoriesHistoricalChanged: 0
  });

  useEffect(() => {
    if (!flags?.reconDealerFilter) {
      setDealershipsFilter(null);
      setReconDealerFilterCondition({
        isDealershipsFilterChanged: 0,
        isHistoricalFiltersChanged: 1,
        isExcludedTaskCategoriesInventorySnapshotChanged: 1,
        isExcludedTaskCategoriesHistoricalChanged: 1
      });
    }
  }, [flags?.reconDealerFilter]);

  useDeepCompareEffectNoCheck(() => {
    const isReconDealerFilterEnabled = flags.reconDealerFilter && isHQ;
    const areDealershipsFetched =
      !inDrawer.open &&
      usersFetchStatus === apiStatusConstants.SUCCEEDED &&
      dealershipsFilter !== null &&
      !!Object.keys(savedDealershipsFilter).length;
    const condition =
      (isReconDealerFilterEnabled && areDealershipsFetched) || (!isReconDealerFilterEnabled && !inDrawer.open);
    const savedFilters = inDrawer.before;

    const toReturn = {
      isDealershipsFilterChanged:
        isReconDealerFilterEnabled &&
        areDealershipsFetched &&
        checkIfChanged(savedFilters.dealershipsFilter, savedDealershipsFilter),
      isHistoricalFiltersChanged:
        condition &&
        checkIfChanged(
          savedFilters.historicalPerformanceFilters,
          omit(historicalPerformanceFilters, ['excludedTaskCategories'])
        ),
      isExcludedTaskCategoriesInventorySnapshotChanged:
        condition &&
        flags?.reconExcludeTaskCategories &&
        checkIfChanged(
          savedFilters.excludedTaskCategoriesInventorySnapshot,
          savedDashboardPreferences?.[dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS]?.excludedTaskCategories
        ),
      isExcludedTaskCategoriesHistoricalChanged:
        condition &&
        flags?.reconExcludeTaskCategories &&
        checkIfChanged(
          savedFilters.excludedTaskCategoriesHistorical,
          savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]?.excludedTaskCategories
        )
    };

    if (condition) {
      setReconDealerFilterCondition((current) => ({
        isDealershipsFilterChanged: toReturn.isDealershipsFilterChanged
          ? current.isDealershipsFilterChanged + 1
          : current.isDealershipsFilterChanged,
        isHistoricalFiltersChanged: toReturn.isHistoricalFiltersChanged
          ? current.isHistoricalFiltersChanged + 1
          : current.isHistoricalFiltersChanged,
        isExcludedTaskCategoriesInventorySnapshotChanged: toReturn.isExcludedTaskCategoriesInventorySnapshotChanged
          ? current.isExcludedTaskCategoriesInventorySnapshotChanged + 1
          : current.isExcludedTaskCategoriesInventorySnapshotChanged,
        isExcludedTaskCategoriesHistoricalChanged: toReturn.isExcludedTaskCategoriesHistoricalChanged
          ? current.isExcludedTaskCategoriesHistoricalChanged + 1
          : current.isExcludedTaskCategoriesHistoricalChanged
      }));

      setInDrawer((currentInDrawer) => ({
        ...currentInDrawer,
        before: {
          dealershipsFilter: flags?.reconDealerFilter ? savedDealershipsFilter : {},
          historicalPerformanceFilters: omit(historicalPerformanceFilters, ['excludedTaskCategories']),
          excludedTaskCategoriesInventorySnapshot:
            savedDashboardPreferences?.[dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS]?.excludedTaskCategories,
          excludedTaskCategoriesHistorical:
            savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]
              ?.excludedTaskCategories
        }
      }));
    }
  }, [
    inDrawer.open,
    savedDealershipsFilter,
    savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]?.excludedTaskCategories,
    savedDashboardPreferences?.[dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS]?.excludedTaskCategories,
    omit(historicalPerformanceFilters, ['excludedTaskCategories']),
    flags.reconDealerFilter,
    flags.reconExcludeTaskCategories,
    usersFetchStatus,
    isHQ
  ]);

  useEffect(() => {
    if (isHQ && currentDealer?.id && flags.reconDealerFilter) {
      // if recon dealer filter is on and isHQ
      // query dealerships. other APIs have to wait for this
      dispatch(usersActions.getAssigneeData(currentDealer.id));
    }
  }, [isHQ, currentDealer?.id, flags?.reconDealerFilter]);

  useDeepCompareEffectNoCheck(() => {
    if (!!Object.keys(savedHistoricalPerformanceFilters).length) {
      setHistoricalPerformanceFilters({
        ...savedHistoricalPerformanceFilters,
        startTime: historicalPerformanceFilters?.startTime,
        endTime: historicalPerformanceFilters?.endTime
      });
    }
  }, [savedHistoricalPerformanceFilters]);

  // Delete this and all related methods when removing reconExcludeTaskCategoriesFlag
  useEffect(() => {
    if (!flags.reconDealerFilter || !isHQ || reconDealerFilterCondition.isDealershipsFilterChanged) {
      let dealershipIds = getDealershipIds();
      dispatch(
        metricsActions.getSnapshotHistoricalMetrics(
          currentDealer.id,
          { ...SNAPSHOT_FILTERS, dealershipIds },
          currentDealer.createdOn,
          flags
        )
      );
    }
  }, [
    currentDealer.id,
    currentDealer.createdOn,
    reconDealerFilterCondition.isDealershipsFilterChanged,
    userId,
    flags.reconDealerFilter,
    flags?.reconExcludeTaskCategories
  ]);

  // Fetch performance metrics in the active inventory dashboard
  useEffect(() => {
    if (!flags?.reconExcludeTaskCategories) {
      return;
    }
    let dealershipIds = getDealershipIds();
    if (
      reconDealerFilterCondition.isExcludedTaskCategoriesInventorySnapshotChanged ||
      reconDealerFilterCondition.isDealershipsFilterChanged
    ) {
      dispatch(
        metricsActions.getActiveInventoryPerformanceMetrics(
          currentDealer.id,
          {
            ...SNAPSHOT_FILTERS,
            excludedTaskCategories:
              savedDashboardPreferences?.[dashboardPreferencesTypes.INVENTORY_SNAPSHOT_FILTERS]?.excludedTaskCategories,
            dealershipIds
          },
          currentDealer.createdOn,
          flags?.reconTimestampFilter,
          flags?.reconDealerFilter
        )
      );
    }
  }, [
    reconDealerFilterCondition.isExcludedTaskCategoriesInventorySnapshotChanged,
    reconDealerFilterCondition.isDealershipsFilterChanged,
    currentDealer.id,
    currentDealer.createdOn,
    userId,
    flags?.reconExcludeTaskCategories
  ]);

  const handleChangeDealershipsFilter = (checked, items) => {
    const newDealershipsFilter = [...dealershipsFilter];
    if (items.length === 0) {
      newDealershipsFilter.forEach((item) => (item.checked = checked));
    } else {
      items.forEach((item) => {
        const index = newDealershipsFilter.findIndex((dealership) => dealership.key === item.key);
        if (index > -1) {
          newDealershipsFilter[index].checked = checked;
        }
      });
    }
    setDealershipsFilter(newDealershipsFilter);
    pushTagManagerEvent(analyticsTagConstants.filterSet.DEALERSHIP_FILTER_SET);
  };

  const updateDealershipsFilterInStorage = (newDealershipsFilter = []) => {
    const newSavedDealershipsFilter = { ...savedDealershipsFilter };
    if (newSavedDealershipsFilter[userId]) {
      newSavedDealershipsFilter[userId] = {
        ...newSavedDealershipsFilter[userId],
        [currentDealer.id]: newDealershipsFilter.filter((dealer) => dealer.checked).map((dealer) => dealer.key)
      };
    } else {
      newSavedDealershipsFilter[userId] = {
        [currentDealer.id]: newDealershipsFilter.filter((dealer) => dealer.checked).map((dealer) => dealer.key)
      };
    }

    setSavedDealershipsFilter(newSavedDealershipsFilter);
  };

  useDebouncedEffect(
    () => {
      //debounce this to debounce API calls.
      //first round is not debounced.
      if (userId && currentDealer?.id && dealershipsFilter) {
        updateDealershipsFilterInStorage(dealershipsFilter);
        if (!shouldDebounce.current) {
          shouldDebounce.current = true;
        }
      }
    },
    600,
    [dealershipsFilter],
    shouldDebounce.current
  );

  useUpdateEffect(() => {
    if (dealerships && isHQ && flags?.reconDealerFilter && userId && currentDealer?.id) {
      const currentEntry = (savedDealershipsFilter[userId] ?? {})[currentDealer?.id];
      const newDealerships = (dealerships ?? []).map((dealership) => ({
        key: dealership.id,
        name: dealership.name,
        checked: currentEntry && Array.isArray(currentEntry) ? currentEntry.includes(dealership.id) : true
      }));

      setDealershipsFilter(newDealerships);
    }
  }, [dealerships, flags?.reconDealerFilter]);

  // We need this in order to populate historicalPerformanceFilters with the latest excluded categories from local storage
  // (cont.) since the modal for this modifies local storage directly so historicalPerformanceFilters state is not updated.
  // (cont.) We could consider passing down a setHistoricalPerformanceFilters instead and updating local storage in this
  // (cont.) component similar to the other filters.
  useEffect(() => {
    if (!flags?.reconExcludeTaskCategories) {
      return;
    }

    const filters = {
      ...historicalPerformanceFilters,
      dispositions:
        historicalPerformanceFilters.dispositions === 'ANY' ? [] : historicalPerformanceFilters.dispositions ?? [],
      excludedTaskCategories:
        savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]?.excludedTaskCategories ||
        []
    };
    setHistoricalPerformanceFilters(filters);
  }, [
    JSON.stringify(
      savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]?.excludedTaskCategories
    ),
    flags?.reconExcludeTaskCategories
  ]);

  // Fetch performance metrics in the historical performance dashboard
  useEffect(() => {
    if (!flags?.reconExcludeTaskCategories) {
      return;
    }

    if (areHistoricalPerformanceFiltersValid(historicalPerformanceFilters)) {
      let dealershipIds = getDealershipIds();
      const filters = {
        ...historicalPerformanceFilters,
        dispositions:
          historicalPerformanceFilters.dispositions === 'ANY' ? [] : historicalPerformanceFilters.dispositions ?? [],
        excludedTaskCategories:
          savedDashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]
            ?.excludedTaskCategories || [],
        dealershipIds
      };

      if (
        reconDealerFilterCondition.isDealershipsFilterChanged ||
        reconDealerFilterCondition.isHistoricalFiltersChanged ||
        reconDealerFilterCondition.isExcludedTaskCategoriesHistoricalChanged
      ) {
        dispatch(
          metricsActions.getHistoricalPerformanceMetrics(
            currentDealer.id,
            filters,
            currentDealer.createdOn,
            flags?.reconTimestampFilter,
            flags?.reconDealerFilter
          )
        );
      }
    }
  }, [
    reconDealerFilterCondition.isDealershipsFilterChanged,
    reconDealerFilterCondition.isHistoricalFiltersChanged,
    reconDealerFilterCondition.isExcludedTaskCategoriesHistoricalChanged,
    currentDealer.id,
    currentDealer.createdOn,
    userId,
    flags?.reconExcludeTaskCategories
  ]);

  const getDealershipIds = () => {
    let dealershipIds = undefined;
    if (
      flags.reconDealerFilter &&
      isHQ &&
      savedDealershipsFilter[userId] &&
      savedDealershipsFilter[userId][currentDealer.id]
    ) {
      dealershipIds = savedDealershipsFilter[userId][currentDealer.id].join(',');
    }
    return dealershipIds;
  };

  // Fetch historical performance task breakdowns in the historical performance dashboard
  useEffect(() => {
    if (areHistoricalPerformanceFiltersValid(historicalPerformanceFilters)) {
      let dealershipIds = getDealershipIds();
      const filters = {
        ...historicalPerformanceFilters,
        dispositions:
          historicalPerformanceFilters.dispositions === 'ANY' ? [] : historicalPerformanceFilters.dispositions || [], // set to empty if ANY, the backend will fetch all dispos according to dealer's filter settings when none are provided
        ...(!historicalPerformanceFilters?.customDateRange && {
          ...generateDateRangeFromDaysAgo(historicalPerformanceFilters?.daysAgo || '30')
        })
      };

      setSavedDashboardPreferences({
        ...savedDashboardPreferences,
        historicalPerformanceFilters: filters
      });

      if (
        reconDealerFilterCondition.isHistoricalFiltersChanged ||
        reconDealerFilterCondition.isDealershipsFilterChanged
      ) {
        dispatch(
          metricsActions.getHistoricalMetrics(
            currentDealer.id,
            { ...filters, dealershipIds },
            currentDealer.createdOn,
            flags,
            isHQ
          )
        );
      }

      if (flags?.reconDealerFilter && reconDealerFilterCondition.isDealershipsFilterChanged) {
        dispatch(metricsActions.getActiveMetrics(currentDealer.id, flags?.reconDealerFilter, isHQ, dealershipIds));
      }
    }
  }, [
    reconDealerFilterCondition.isHistoricalFiltersChanged,
    reconDealerFilterCondition.isDealershipsFilterChanged,
    currentDealer.id,
    currentDealer.createdOn,
    userId,
    flags?.reconExcludeTaskCategories,
    flags.reconHiddenFilter
  ]);

  const activeMetricsIsFetching = activeMetricsFetchStatus === apiStatusConstants.IS_FETCHING;

  useEffect(() => {
    if (!(isHQ && flags?.reconDealerFilter)) {
      dispatch(metricsActions.getActiveMetrics(currentDealer.id, flags?.reconDealerFilter, isHQ));
    }
  }, [currentDealer.id, userId, savedDealershipsFilter, isHQ, flags?.reconDealerFilter]);

  const handleToggleShowSnapshot = (show) => {
    toggleShowSnapshot(show);
    setSavedDashboardPreferences({ ...savedDashboardPreferences, showSnapshot: show });
  };

  const [query, setQuery] = useQueryParams({
    code: StringParam,
    user_id: StringParam
  });

  useEffect(() => {
    if (query.code || query.user_id) {
      //redirect from vendor-welcome page when already logined. needs to remove query
      setQuery({
        code: undefined,
        user_id: undefined
      });
    }
  }, [query]);

  const dropdownProvider = useMemo(() => ({
    dealerships: {
      items: dealershipsFilter,
      handleChange: handleChangeDealershipsFilter,
      setInDrawer: (newInDrawer) => {
        if (flags.reconDealerFilter && isHQ) {
          setInDrawer((currentInDrawer) => ({ ...currentInDrawer, open: newInDrawer }));
        }
      },
      handleDropdownOpen: (dropdownOpen) => {
        if (dropdownOpen) {
          pushTagManagerEvent(analyticsTagConstants.filterOpened.DEALERSHIP_FILTER_OPENED);
        }
      }
    }
  }));

  return (
    <SortContext.Provider value={{ taskBreakdownSort, setTaskBreakdownSort }}>
      <CommonDropdownContext.Provider value={dropdownProvider}>
        <StyledContainer is-bridge-user={isBridgeUser} reconDealerFilterFlag={flags?.reconDealerFilter}>
          {!flags?.reconDealerFilter && <DashboardGreeting />}
          <DashboardControls
            toggleShowSnapshot={handleToggleShowSnapshot}
            showSnapshot={showSnapshot}
            setFilters={setHistoricalPerformanceFilters}
            filters={historicalPerformanceFilters}
            currentDealer={currentDealer}
            createdOn={currentDealer.createdOn}
          />
          {showSnapshot ? (
            <InventorySnapshot
              flags={flags}
              activeMetrics={activeMetrics}
              activeMetricsIsFetching={activeMetricsIsFetching}
              useLocalStorageObject={dashboardPreferencesLocalStorageObject}
            />
          ) : (
            <HistoricalPerformance
              filters={historicalPerformanceFilters}
              useLocalStorageObject={dashboardPreferencesLocalStorageObject}
            />
          )}
        </StyledContainer>
      </CommonDropdownContext.Provider>
    </SortContext.Provider>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  background-color: #f2f4f5;
  display: flex;
  flex-direction: column;
  ${(props) => (props['is-bridge-user'] ? 'min-height: calc(100vh - 210px);' : 'min-height: calc(100vh - 175px);')}
  height: 100%;
  padding: ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? '24px 24px 32px' : '32px 24px')};
  > .title {
    font-size: ${({ theme }) => theme.fontSizes.title};
    font-weight: ${({ theme }) => theme.fontWeights.light};
    line-height: 38px;
  }

  > .ant-card {
    margin: 24px 0 32px 0;
    .ant-card-body {
      padding: 24px 42px;
      display: flex;
      .ant-divider {
        margin: 0 42px;
        height: 100%;
      }
    }
  }
`;

//#endregion
export default withLDConsumer()(Dashboard);
