import { createRequestTypes, makeActionCreator } from 'utils';
import { createSelector } from 'reselect';

// based off antd's messages component, look at their docs for mesageTypes and behavior
//#region Actions
export const MESSAGES = createRequestTypes('MESSAGES', ['NOTIFY', 'CLEAR']);
export const messagesActions = {
  notify: makeActionCreator(MESSAGES.NOTIFY, 'messageType', 'content', 'options'),    // options: duration, key
  clear: makeActionCreator(MESSAGES.CLEAR),
}
//#endregion

//#region Reducer
const initialState = {
  message: null,
}

export const messagesReducer = (state = initialState, { type, messageType, content, options }) => {
  switch (type) {
    case MESSAGES.NOTIFY:
      return {
        message: {
          messageType,
          content,
          options
        }
      }
    case MESSAGES.CLEAR: 
      return {
        message: null
      }
    default:
      return state;
  }
}
//#endregion

//#region Selectors
export const messageSelector = createSelector(
  state => state.messages,
  messages => messages.message
);
//#endregion
