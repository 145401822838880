import React from 'react';
import styled from 'styled-components';
import { CommonRedButton } from 'components/styledComponents';
import LoadingIcon from './LoadingIcon';

const LoadingRedButton = styled(({ loadingIcon,...rest }) => {
  return (
    <CommonRedButton {...rest}>
      {loadingIcon ? <LoadingIcon ghost={rest.ghost} /> : null}
      {rest.children}
    </CommonRedButton>
  );
})``;

export default LoadingRedButton;
