import React from 'react';
import styled from 'styled-components';
import { BasicLayeredTaskIcon, BaseHoverContent } from 'components/layout/tasks/icons';
import { faCircle, faStopwatch } from '@fortawesome/free-solid-svg-icons';
// Styles
import { theme as themeStyles } from 'styles/appTheme';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const TaskPastDueIcon = ({ task, progress, flags, ...other }) => {
  const hoverContent = (
    <>
      <StyledHoverContentHeader>{progress.info}</StyledHoverContentHeader>
      <BaseHoverContent task={task} showLeftBorder={true} />
    </>
  );

  return (
    <BasicLayeredTaskIcon
      {...other}
      task={task}
      foregroundIcon={faStopwatch}
      customHoverContent={hoverContent}
      backgroundIcon={!flags.reconTaskCollapse && faCircle}
      backgroundColor={flags.reconTaskCollapse ? themeStyles.colors.transparent : themeStyles.colors.red}
      foregroundColor={flags.reconTaskCollapse ? themeStyles.colors.red : themeStyles.colors.white}
      recon-task-collapse={flags.reconTaskCollapse}
    />
  );
};

const StyledHoverContentHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.red};
`;

export default withLDConsumer()(TaskPastDueIcon);
