import { throttle } from 'lodash';

// modules operate in strict mode, so none of these variables will be global
// no need to put this work in a IIAF

// the variables and functions in this module are written to create the scrolling functionality for a plan while the user is performing a drag
// it does this by setting up dragover event listeners and detecting when a DraggableItem instance has been dragged to either the top or the bottom edge of a plan
// really it is just setting a vertical threshold based on the dnd container's parent component (which is scrollcontainer) and detecting if the mouse is within that threshold
// or if the mouse is at or above the upper threshold (scroll up) or at or below the lower threshold (scroll down)

/*------------------------------------------------*/
/* CONSTANTS
/*------------------------------------------------*/
const OFFSET = 50; // This is the top/bottom offset you use to start scrolling in the div.
const PX_DIFF = 6; // how much to scroll displace the scroll container on each call of othe goUp or goDown functions

// Global Variables
let scrollIncrement = 0; // this variable gets decremented or incremented depending on the direction that a scroll should occur and is used to set where the scrollTop is for the scrollContainer which in turn causes the animation of the scroll to occur
let isScrolling = false; // scrolling because of a drag should only happen when a drag is occurring - isScrolling is set to true to allow scrolling when a drag event has started and the drag is occurring outside the bounds and isScrolling is set to false to prevent scrolling when a drag event has stopped and/or the drag is occurring inside the bounds
let scrollContainerElement = null; // the DOM element that the y position of the mouse's threshold is based on - it is a parent container for the dnd stuff
let scrollHeightScrollContainer = 0; // need to know how tall the scroll container is so that we can make sure that our scrollIncrement is 
let clientRectBottom = 0; // bottom threshold of the scrollContainer - if mouse is dragging below this, then this is one condition that would indicate scroll down
let clientRectTop = 0; // top threshold of the scrollContainer - if mouse is dragging above this, then this is one condition that would indicate scroll up

//#region Methods

/**
 * Function to scroll up in the scrollContainer when a drag is detected at the top of the container holding the dnd (named scrollcontainer)
 */
const goUp = () => {
  //first decrement the scrollIncrement (remember higher up, smaller y values - can even be negative)
  scrollIncrement -= PX_DIFF;
  //set the scrollTop to be scrollIncrement 
  scrollContainerElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement >= 0) {
    //scroll animation
    window.requestAnimationFrame(goUp); // method tells the browser that you wish to perform an animation and requests that the browser calls a specified function to update an animation before the next repaint. 
  }
};

/**
 * function to Scroll down in the scrollContainer when a drag is detected at the bottom of the container holding the dnd (named scrollcontainer)
 */
const goDown = () => {
  scrollIncrement += PX_DIFF;
  scrollContainerElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement <= scrollHeightScrollContainer) {
    //scroll animation
    window.requestAnimationFrame(goDown);
  }
};

/**
 * onDragOver
 * function that is throttled and ran in the dragOver event listener
 * detects 
 * @param {object} event - event data from the dragover event handler
 */
const onDragOver = event => {
  const isMouseOnTop =
    scrollIncrement >= 0 &&
    event.clientY > clientRectTop &&
    event.clientY < clientRectTop + OFFSET;
  const isMouseOnBottom =
    scrollIncrement <= scrollHeightScrollContainer &&
    event.clientY > clientRectBottom - OFFSET &&
    event.clientY <= clientRectBottom;

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true;
    scrollIncrement = scrollContainerElement.scrollTop;

    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp);
    } else {
      window.requestAnimationFrame(goDown);
    }
  } else if (!isMouseOnTop && !isMouseOnBottom) {
    isScrolling = false;
  }
};

const throttleOnDragOver = throttle(onDragOver, 150); //throttling this to only run every 150ms

/**
 * addEventListenerForScrollContainer
 * function that sets variables and sets up an event listener for dragover events
 * @param {object} scrollContainer - ref instance for the container that wraps the dnd stuff - its top and bottom edges are used to determine when a scroll up or down should occur
 */
export const addEventListenerForScrollContainer = (scrollContainer) => {
  scrollContainerElement = scrollContainer?.current;
  scrollHeightScrollContainer = scrollContainerElement.scrollHeight;
  const clientRect = scrollContainerElement.getBoundingClientRect();
  clientRectTop = clientRect.top;
  clientRectBottom = clientRect.bottom;
  scrollContainerElement.addEventListener("dragover", throttleOnDragOver);
};

export const removeEventListenerForScrollContainer = () => {
  isScrolling = false;

  if (scrollContainerElement) {
    scrollContainerElement.removeEventListener("dragover", throttleOnDragOver);
  }
};
//#endregion