import React, { useState } from 'react';
import { userManagerNewDomain, userManagerNewSignin } from 'utils/userManager';
import QueryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { CallbackComponent, USER_FOUND } from 'redux-oidc';
import { navigate } from '@reach/router';
import { oidcSelector } from 'store/authStore';
import { Error } from 'components';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useLocalStorage } from 'hooks';
import { IS_NEW_SIGNIN, VENDOR_USER_INFO, onboardingStatusTypes } from 'app-constants';
import { getWithToken } from 'api';

const LoginCallback = () => {
  const oidcState = useSelector(oidcSelector);
  const [showClockError, setShowClockError] = useState(false);
  const [redirectCount, setRedirectCount] = useLocalStorage('redirectCount', 0);
  const isAuthenticated = oidcState && oidcState.user && !oidcState.expired;
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  const dispatch = useDispatch();

  if (isAuthenticated) {
    return null;
  }

  const successCallback = async (user) => {
    //setRedirectCount(0);       // resetting it here doesn't work for some reason, probably because the component is unmounted, doing the reset in App component instead

    try {
      if (isNewSignin) {
        const vendorUserInfo = await getWithToken(`/api/Vendors/users/mine`);
        if (vendorUserInfo) {
          localStorage.setItem(VENDOR_USER_INFO, JSON.stringify(vendorUserInfo));
          const currentUser = await userManagerNewSignin.getUser();
          dispatch({ type: USER_FOUND, payload: currentUser });
        }

        if (!!user.state?.invite_dealer_id) {
          if (vendorUserInfo.vendorOnboardingStatus === onboardingStatusTypes.REGISTERED) {
            navigate(`/configure/dealerships?dealerId=${user.state?.invite_dealer_id}`);
            return;
          }
        }
      }
      if (!!user.state?.redirect_url) {
        window.location.href = user.state.redirect_url;
        return;
      }

      const search = QueryString.parse(window.location.search);
      navigate(search.redirectUri ? search.redirectUri : '/');
    } catch (e) {
      if (e.statusCode === 401) {
        const hasEwsLink = await getWithToken(`/api/Users/mine/has-ews-link`);
        if (hasEwsLink) {
          localStorage.setItem(IS_NEW_SIGNIN, false);
          userManagerNewDomain.signinRedirect();
        }
      }
    }
  };

  return (
    <CallbackComponent
      userManager={isNewSignin ? userManagerNewSignin : userManagerNewDomain}
      successCallback={successCallback}
      errorCallback={(error) => {
        console.log(error);
        const errorMsgs = ['iat is in the future', 'exp is in the past'];
        if (errorMsgs.some((e) => error.toString().includes(e))) {
          const datetime = new Date(error.toString().split(':')[2] * 1000);
          console.log(datetime);

          if (redirectCount > 0) {
            setRedirectCount(0); // reset back to 0
            setShowClockError(true);
            return;
          } else {
            // when this error occurs, allow the redirect to happen at least once, there is a scenario when a users puts their machine to sleep, when the
            // machine is woken up, it tries to authenticate with the same token, but the clock has lapsed since and throws the 'exp is in the past' error,
            // so a solution to combat this is to allow the redirect to happen at least once, if it redirects more than once, than it is truly a clock issue
            setRedirectCount((prev) => ++prev);
          }
        }

        navigate('/login');
      }}
    >
      {!showClockError ? (
        <div>Redirecting...</div>
      ) : (
        <Error
          icon={faClock}
          iconSize="4x"
          errorText="Your clock is out of sync"
          infoText="We couldn't log you in to iRecon. Re-sync your clock and try again."
          showRefreshButton={false}
        />
      )}
    </CallbackComponent>
  );
};

export default LoginCallback;
