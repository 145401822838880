//third party dependencies
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { maxBy } from 'lodash';
import { Divider, Typography } from 'antd';
//our components, enums, hooks, helper functions, redux actions,...
import { Stack } from 'components';
import {
  toMomentDuration,
  addDurationsFromObjectArray,
  displayDurationSummary,
  getMomentDurationFromString
} from 'utils/dateTimeUtils';
import { formatPrice } from 'utils/numberUtils';
import { COMPLETED } from 'app-constants/taskStatusTypes';

const { Text } = Typography;

export const PlanStatistics = ({ vehicle, taskGroups }) => {
  const [timeInReconPlan, setTimeInReconPlan] = useState(String.fromCharCode(8212));
  const [timeToPlanCreation, setTimeToPlanCreation] = useState(String.fromCharCode(8212));
  useEffect(() => {
    if (vehicle?.secondsInReconPlan) {
      const duration = toMomentDuration({
        seconds: vehicle?.tasks?.items?.length > 0 ? vehicle.secondsInReconPlan : 0
      });
      //we only want to have duration displayed in d h, or h m, not d h m
      //so we are using duration.asMinutes() <= 1440 to determine true or false for the includeMinutes parameter.
      //1440 is the number of minutes in a day
      const timeInRP = displayDurationSummary(duration, true, true, duration.asMinutes() <= 1440);
      setTimeInReconPlan(timeInRP);
    }

    if (vehicle?.timeToPlanCreation) {
      const durationInTimeToPlanCreation = moment.duration(vehicle.timeToPlanCreation);
      const durationBeforeReconPlanStarted = displayDurationSummary(
        durationInTimeToPlanCreation,
        true,
        true,
        durationInTimeToPlanCreation.asMinutes() <= 1440
      );
      setTimeToPlanCreation(durationBeforeReconPlanStarted);
    }
  }, [vehicle]);

  const ESTIMATION_STRING = 'Est.';

  const localTasks = taskGroups.reduce((acc, tg) => acc.concat(tg.tasks), []);
  const hasSavedPlan = localTasks.length > 0;
  let longestTaskPerGroup = taskGroups.reduce(
    (acc, tg) => acc.concat(maxBy(tg.tasks, (t) => getMomentDurationFromString(t.goalThreshold))),
    []
  );
  const estimatedTime = addDurationsFromObjectArray(longestTaskPerGroup, 'goalThreshold', 'string').totalDurationMoment;

  //we only want to have duration displayed in d h, or h m, not d h m
  //so we are using duration.asMinutes() <= 1440 to determine true or false for the includeMinutes parameter.
  //1440 is the number of minutes in a day
  let estimatedTimeString = `${ESTIMATION_STRING}: ${displayDurationSummary(
    estimatedTime,
    true,
    true,
    estimatedTime.asMinutes() <= 1440
  )}`;
  const estimatedTimeStringLength = estimatedTimeString.length;
  const estimatedCost = localTasks.reduce((totalCost, task) => totalCost + (task.estimatedCost ?? 0), 0);
  const estimatedCostFormatted = `${ESTIMATION_STRING}: ${formatPrice(estimatedCost || null)}`; // Passing in null to formatPrice makes it print an emdash character instead of $0
  const estimatedCostFormattedLength = estimatedCostFormatted.length;

  const completedTasks = localTasks.filter((task) => task.status === COMPLETED || task.transientType === 'complete');
  const currentCost =
    timeInReconPlan === '—'
      ? '—'
      : completedTasks?.reduce((sumOfCompletedCosts, { completedCost }) => {
          if (typeof completedCost === 'number') {
            return sumOfCompletedCosts + completedCost;
          }
          return sumOfCompletedCosts;
        }, 0);
  const currentCostFormatted = formatPrice(currentCost || null); // Passing in null to formatPrice makes it print an emdash character instead of $0

  const widthOfStatisticsContainers = (() => {
    if (estimatedCostFormattedLength + estimatedTimeStringLength >= 30) {
      if (estimatedCostFormattedLength >= 15 && estimatedTimeStringLength <= 15)
        return { time: '25%', cost: '42%', timeBeforeRecon: '33%' };
      if (estimatedCostFormattedLength <= 15 && estimatedTimeStringLength >= 15)
        return { time: '42%', cost: '25%', timeBeforeRecon: '33%' };
      if (estimatedCostFormattedLength >= 16 && estimatedTimeStringLength >= 16)
        return { time: '35%', cost: '35%', timeBeforeRecon: '30%' };
    }
    return { time: '30%', cost: '30%', timeBeforeRecon: '40%' };
  })();

  return (
    <StyledStatisticsContainer>
      <Stack style={{ width: widthOfStatisticsContainers.time }}>
        <StatTitle>Time</StatTitle>
        <CurrentStat>{timeInReconPlan}</CurrentStat>
        <EstimateStat data-hassavedplan={hasSavedPlan}>{estimatedTimeString}</EstimateStat>
      </Stack>
      <Divider type="vertical" style={{ height: '65px', margin: `0 12px` }} />
      <Stack style={{ width: widthOfStatisticsContainers.cost }}>
        <StatTitle>Cost</StatTitle>
        <CurrentStat>{currentCostFormatted}</CurrentStat>
        <EstimateStat data-hassavedplan={hasSavedPlan}>{estimatedCostFormatted}</EstimateStat>
      </Stack>
      <Divider type="vertical" style={{ height: '65px', margin: `0 12px` }} />
      <Stack style={{ width: widthOfStatisticsContainers.timeBeforeRecon }}>
        <StatTitle>Time Before Plan</StatTitle>
        <CurrentStat>{timeToPlanCreation}</CurrentStat>
      </Stack>
    </StyledStatisticsContainer>
  );
};

//#region Styled Components
const StyledStatisticsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 8px;
  width: 100%;
  margin-bottom: 25px;
`;
const StatTitle = styled(Text)`
  .ant-typography& {
    letter-spacing: 0;
    display: block;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    white-space: nowrap;
  }
`;
const CurrentStat = styled(Text)`
  .ant-typography& {
    letter-spacing: 0;
    white-space: nowrap;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 21px;
  }
`;
const EstimateStat = styled(Text)`
  .ant-typography& {
    letter-spacing: 0;
    white-space: nowrap;
    display: block;
    line-height: 18px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${(props) => props['data-hassavedplan'] && props.theme.colors.gray};
  }
`;
