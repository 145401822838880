import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import { Typography } from 'antd';
const { Text } = Typography;

const NoImageAvailable = ({ isLoading = false, style = {} }) => {
  return (
    <StyledContainer style={style}>
      {isLoading ? (
        <Skeleton circle height={100} width={100} />
      ) : (
        <>
          <StyledIcon icon={faCar} data-height={style.height} />
          <StyledText>No Image Available</StyledText>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div.attrs({
  className: 'center-content'
})`
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  position: relative;
`;
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.gray};
  height: ${(props) => props['data-height']};
  font-size: 45px;
`;
const StyledText = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  margin-top: 76px;
`;

export default NoImageAvailable;
