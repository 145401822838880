import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { authSelector } from 'store/authStore';
import { Affix, Row, Col, Typography } from 'antd';
import { TaskFilters, TaskCard } from 'components/layout/tasks';
import { BorderlessSelect, Error } from 'components';
import { CommonListPageContainer, CommonSortRow, GridArea } from 'components/styledComponents';
import {
  apiStatusConstants,
  taskStatusLabels,
  taskProgressLabels,
  features,
  dispositionTypes,
  sortOptionDefault,
  VENDORS,
  ewsEntityTypes,
  profitTimeLabels
} from 'app-constants';
import { useQueryParams, ArrayParam, withDefault, StringParam, BooleanParam } from 'use-query-params';
import { useFeatures, useLocalStorage, useUpdateEffect } from 'hooks';
import { stripReferences } from 'utils/arrayUtils';
import { TasksPresentation } from '.';
import { tasksActions } from 'store/tasksStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { GlobalAlertContext } from '../../../App';
import useVendorRole from 'hooks/useVendorRole';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { taskTypesActions } from 'store/taskTypesStore';
import { Footer } from 'components/layout/nav';

const createReverseLookup = (dict) =>
  Object.entries(dict).reduce((obj, r) => {
    obj[r[1]] = r[0];
    return obj;
  }, {});

const tasksPropsSelector = createSelector(
  authSelector,
  (state) => state.dealers.current.data,
  (state) => state.vendors.vendor,
  (state) => state.taskTypes.fetchStatus,
  (state) => state.users.fetchStatus,
  (state) => state.users.assigneeData,
  (state) => state.tasks.dealershipFetchStatus,
  (state) => state.taskCategories.categoriesFromTaskTypes.fetchStatus,
  (
    authState,
    currentDealerStore,
    currentVendorStore,
    taskTypesFetchStatus,
    usersFetchStatus,
    assigneeData,
    dealershipFetchStatus,
    categoriesFetchStatus
  ) => ({
    authState,
    currentDealer: currentDealerStore,
    isBridgeUser: authState.isBridgeUser,
    currentDealerId: currentDealerStore.id,
    currentVendorId: currentVendorStore.id,
    dealerDispositions: currentDealerStore.vehicleDispositionFilter || [], // default to [], later we'll set to show all if undefined or none checked
    taskTypesFetchStatus,
    usersFetchStatus,
    assigneeData,
    dealershipFetchStatus,
    categoriesFetchStatus
  })
);

const Tasks = ({
  selectedTaskId,
  scrollcontainer,
  forceLoadPageQueryFromStorage,
  setForceLoadPageQueryFromStorage,
  selectedNav,
  flags
}) => {
  const dispatch = useDispatch();
  const {
    authState,
    isBridgeUser,
    currentDealerId,
    currentDealer,
    currentVendorId,
    dealerDispositions,
    taskTypesFetchStatus,
    usersFetchStatus,
    assigneeData,
    dealershipFetchStatus,
    categoriesFetchStatus
  } = useSelector(tasksPropsSelector);
  const { isRootUser, vendorShipSelected, currentDealerFetchStatus } = useSelector(rootEntitySwitcherSelector);
  const [hasViewAnyTask, hasWorkOwnTasks] = useFeatures(features.TASKS_VIEW_ANY, features.TASKS_WORK_OWN);
  const [dataFetched, setDataFetched] = useState(false);
  const [taskStatusReverseLookup, setTaskStatusReverseLookup] = useState({});
  const [taskProgressReverseLookup, setTaskProgressReverseLookup] = useState({});
  const [profitTimeReverseLookup, setProfitTimeReverseLookup] = useState({});
  const [dispositionReverseLookup, setDispositionReverseLookup] = useState({});
  const [filtersChangedByUser, toggleFiltersChangedByUser] = useState(false); //flag for determining when user has interacted with filters
  //because we need to wait for the feature value to be determined before we know what the right value of the defaults, don't set an initial value for queryInStorage
  const [queryInStorage, setQueryInStorage] = useLocalStorage('taskQuery', {});
  const [queryIsSetFromLocalStorageValue, toggleQueryIsSetFromLocalStorageValue] = useState(false); //only want the initial load from local storage to happen once, so this flag will be set to true once setQuery is called with queryInStorage value
  const { isVendor, isVendorAdmin } = useVendorRole();
  const [isRefreshPage, setRefreshPage] = useState(false);
  const hasGlobalAlert = !!useContext(GlobalAlertContext);

  const getCacheOrDefaultSortValue = () => {
    return queryInStorage && queryInStorage.sort && queryInStorage.sort !== ''
      ? queryInStorage.sort
      : sortOptionDefault.TASK;
  };

  const [query, setQuery] = useQueryParams({
    fromDashboard: BooleanParam,
    showDateFilter: BooleanParam,
    taskStatus: withDefault(ArrayParam, []),
    showApprovalTime: BooleanParam, // setting default like this (withDefault(ArrayParam, ['In Progress'])) doesn't work, the useEffect won't get triggered when you deselect, might be a bug and I posted an issue on their github
    completionDate: StringParam,
    completedOnStart: StringParam,
    completedOnEnd: StringParam,
    taskType: withDefault(ArrayParam, []),
    taskCategories: withDefault(ArrayParam, []),
    taskProgress: withDefault(ArrayParam, []),
    profitTime: withDefault(ArrayParam, []),
    entitiesFilter: withDefault(ArrayParam, []),
    assignedTo: withDefault(ArrayParam, []), // setting default like this (withDefault(ArrayParam, hasWorkOwnTasks ? [authState.user.name] : [])) doesn't work, the useEffect won't get triggered when you deselect, might be a bug and I posted an issue on their github
    disposition: withDefault(ArrayParam, []),
    sort: withDefault(StringParam, getCacheOrDefaultSortValue()),
    search: StringParam,
    assignedToTechnicianFilter: StringParam,
    assignedToGroupFilter: StringParam,
    dealerAndTaskTypeFilter: StringParam,
    reassigned: BooleanParam,
    assignedToVendor: withDefault(ArrayParam, [])
  });

  const isUnfiltered = () => {
    const {
      fromDashboard,
      taskStatus,
      taskType,
      taskCategories,
      taskProgress,
      assignedTo,
      disposition,
      search
    } = query;
    return (
      !taskStatus.length &&
      !taskType.length &&
      !taskCategories.length &&
      !taskProgress.length &&
      !assignedTo.length &&
      !disposition.length &&
      !search &&
      !fromDashboard
    );
  };
  // Get default filter for technician
  const getDefaultAssignedToFilter = () => {
    let technicians = isRootUser
      ? []
      : [
          {
            assignedTo: authState.user.id,
            assignedDirectly: true,
            assignedThruGroup: isVendor ? false : true
          }
        ];
    if (isVendor && isVendorAdmin) {
      technicians.push({
        assignedTo: null,
        assignedDirectly: true,
        assignedThruGroup: false
      });
    }
    return JSON.stringify(technicians);
  };
  // Get default filter for Groups
  const getDefaultAssignedGroupToFilter = () => {
    let groups = [
      {
        assignedToGroup: null,
        assignedToMembers: [],
        unassigned: false
      }
    ];
    let newInternalGroups = stripReferences(assigneeData?.internalGroups ?? []);
    if (newInternalGroups && newInternalGroups.length > 0) {
      newInternalGroups.forEach((group) => {
        let indexGroupMemberChecked = group.internalGroupUsers.findIndex((f) => f.userId === authState.user.id);
        if (indexGroupMemberChecked !== -1) {
          let obj = {};
          obj['assignedToGroup'] = group.id;
          let groupMemberIds = group.internalGroupUsers
            .filter((user) => !!user.userId)
            .map((user) => {
              return user.userId;
            });
          obj['assignedToMembers'] = groupMemberIds;
          obj['unassigned'] = true;
          groups.push(obj);
        }
      });
    }
    return JSON.stringify(groups);
  };

  useUpdateEffect(() => {
    //wait for user interactions before setting a value for queryInStorage - queryInStorage for tasks will used exclusively to keep track of filters set by users
    //We only want to set query in storage if we are on the tasks tab, otherwise it could be set from another tab's query
    if (filtersChangedByUser && selectedNav === 'tasks' && window.location.pathname === '/tasks') {
      if (!query['fromDashboard']) {
        const copyOfQuery = stripReferences(query);
        //to indicate that user interacted with filters when checking whether to load from queryInStorage set the value of filtersChangedByUser
        setQueryInStorage({ ...copyOfQuery, filtersChangedByUser: true });
      } else {
        const copyOfQuery = stripReferences(queryInStorage);
        setQueryInStorage({ ...copyOfQuery, filtersChangedByUser: true, sort: query.sort });
      }
    }
    const copyOfQueryInStorage = stripReferences(queryInStorage);
    // On filter task page, The user refresh page which we need load value filter before when refresh
    // If user refresh page, we should be keep value filters on Dealer and Vendor
    if (flags?.reconVendorManagement) {
      if (!filtersChangedByUser && selectedNav === 'tasks' && window.location.pathname === '/tasks') {
        if (!query['fromDashboard']) {
          if (isVendor || (isRootUser && vendorShipSelected)) {
            setQueryInStorage({ ...copyOfQueryInStorage, filtersChangedByUser: true, sort: query.sort });
            setForceLoadPageQueryFromStorage(true);
          }
          // Dealer set
          setRefreshPage(true);
          // The first user login, the filter task page need load default filter
          if (Object.keys(copyOfQueryInStorage).length === 0) {
            let defaultOfQuery = {
              taskStatus: [taskStatusLabels.IN_PROGRESS],
              assignedToTechnicianFilter: getDefaultAssignedToFilter(),
              assignedToGroupFilter:
                !isVendor && hasViewAnyTask && assigneeData?.internalGroups?.length
                  ? getDefaultAssignedGroupToFilter()
                  : JSON.stringify([])
            };
            let newQuery = { ...defaultOfQuery, sort: query.sort };
            setQueryInStorage({ ...newQuery, filtersChangedByUser: true });
            setQuery(newQuery, 'replace');
          } else {
            delete copyOfQueryInStorage.filtersChangedByUser;
            copyOfQueryInStorage.sort = query.sort;
            setQuery(copyOfQueryInStorage, 'pushIn');
          }
        }
      }
    } else {
      if (!filtersChangedByUser && selectedNav === 'tasks' && window.location.pathname === '/tasks') {
        if (!query['fromDashboard']) {
          setQueryInStorage({ ...copyOfQueryInStorage, filtersChangedByUser: true, sort: query.sort });
          setForceLoadPageQueryFromStorage(true);
          setQuery({ ...copyOfQueryInStorage, sort: query.sort }, 'pushIn');
          setRefreshPage(true);
        }
      }
    }
  }, [JSON.stringify(query), filtersChangedByUser]);

  useEffect(() => {
    dispatch(taskTypesActions.setFetchStatus(apiStatusConstants.PENDING));
  }, []);

  useEffect(() => {
    if (query) {
      if (!flags.reconVendorManagement || isVendor) {
        delete query.assignedToVendor;
      }
      if (!flags.reconVendorManagement || !isVendor) {
        delete query.dealerAndTaskTypeFilter;
      }
      if (!flags.ireconHqView || isVendor || currentDealer.entityType === ewsEntityTypes.DEALER) {
        delete query.entitiesFilter; //adjust query if user is in Vendor view or does not have the flag.
      }
    }
    setTaskStatusReverseLookup(createReverseLookup(taskStatusLabels));
    setTaskProgressReverseLookup(createReverseLookup(taskProgressLabels));
    setProfitTimeReverseLookup(createReverseLookup(profitTimeLabels));
    setDispositionReverseLookup(createReverseLookup(dispositionTypes));
    setDataFetched(true); // depending on the scenario, the taskTypesFetchStatus and usersFetchStatus may start at apiStatusConstants.SUCCEEDED, and would render the TasksPresentation before issuing these data fetches, so using this flag to allow the fetch to be called before rendering
  }, [authState.user.id]); // when user changes (impersonating), refetch data

  useEffect(() => {
    if (!isUnfiltered()) {
      // do nothing, query is set so use query params as is
    } else {
      // if query is unfiltered, first check queryInStorage to see if params should be loaded from that
      // only call setForceLoadPageQueryFromStorage in the filter page
      if (
        isUnfiltered() &&
        !queryIsSetFromLocalStorageValue &&
        queryInStorage['filtersChangedByUser'] &&
        window.location.pathname === '/tasks'
      ) {
        setForceLoadPageQueryFromStorage(true);
      }
    }
  }, [hasWorkOwnTasks, query, queryInStorage, queryIsSetFromLocalStorageValue]);

  const setQueryFromStorage = () => {
    const copyOfQueryInStorage = stripReferences(queryInStorage);
    delete copyOfQueryInStorage.filtersChangedByUser;
    setQuery(copyOfQueryInStorage, 'replace');
    toggleQueryIsSetFromLocalStorageValue(true);
  };

  //The navbar will set forceLoadPageQueryFromStorage to indicate that we need to pull the query from storage. This is needed when we navigate to the same tab we were already on,
  //since the component will not remount and set the query from storage on its own
  useEffect(() => {
    if (forceLoadPageQueryFromStorage && queryInStorage['filtersChangedByUser'] && selectedNav === 'tasks') {
      setQueryFromStorage();
    }
  }, [forceLoadPageQueryFromStorage]);

  useEffect(() => {
    if (isUnfiltered() && !selectedTaskId && queryIsSetFromLocalStorageValue) {
      // load filter from store after close TDP drawer and un-filtered
      setForceLoadPageQueryFromStorage(true);
    }
  }, [selectedTaskId]);

  useEffect(() => {
    //Added this new task to make sure we are only making these calls when we have the latest data
    //Should only affect root users
    if (currentDealerId && currentDealerFetchStatus === apiStatusConstants.SUCCEEDED) {
      if (isRootUser && vendorShipSelected) {
        dispatch(tasksActions.getDependencyData(currentDealerId, true));
        dispatch(tasksActions.getDealerships(currentDealerId));
      } else {
        dispatch(tasksActions.getDependencyData(currentDealerId, false));
      }
    }
  }, [currentDealerId, currentDealerFetchStatus]);

  useEffect(() => {
    if (currentVendorId && isVendor) {
      dispatch(tasksActions.getDependencyData(currentVendorId, true));
      dispatch(tasksActions.getDealerships(currentVendorId));
    }
  }, [currentVendorId]);

  const vendorship = useSelector((state) => state.dealers.vendorShipSelected);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!currentDealerId) {
      return;
    }
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (isRootUser) {
        const newQuery = {
          ...query,
          assignedToTechnicianFilter:
            vendorship === VENDORS
              ? JSON.stringify([
                  {
                    assignedTo: null,
                    assignedDirectly: true,
                    assignedThruGroup: false
                  }
                ])
              : '[]',
          assignedToGroupFilter: '[]',
          dealerAndTaskTypeFilter: '[]',
          filtersChangedByUser: true
        };
        delete newQuery.assignedToGroup;
        toggleFiltersChangedByUser(false);
        setQuery(newQuery);
      }
    }
  }, [currentDealerId]);

  if (
    taskTypesFetchStatus === apiStatusConstants.SUCCEEDED &&
    usersFetchStatus === apiStatusConstants.SUCCEEDED &&
    (!(isRootUser && vendorShipSelected) || dealershipFetchStatus === apiStatusConstants.SUCCEEDED) &&
    hasViewAnyTask !== undefined &&
    hasWorkOwnTasks !== undefined &&
    dataFetched &&
    (isVendor || categoriesFetchStatus === apiStatusConstants.SUCCEEDED)
  ) {
    return (
      <TasksPresentation
        selectedTaskId={selectedTaskId}
        scrollcontainer={scrollcontainer}
        hasViewAnyTask={hasViewAnyTask}
        hasWorkOwnTasks={hasWorkOwnTasks}
        query={query}
        setQuery={setQuery}
        dealerDispositions={
          dealerDispositions.length > 0
            ? dealerDispositions.map((d) => dispositionTypes[d])
            : Object.values(dispositionTypes)
        } // per Erin, we want to show all dispos if none are checked
        taskStatusReverseLookup={taskStatusReverseLookup}
        taskProgressReverseLookup={taskProgressReverseLookup}
        profitTimeReverseLookup={profitTimeReverseLookup}
        dispositionReverseLookup={dispositionReverseLookup}
        toggleFiltersChangedByUser={toggleFiltersChangedByUser}
        forceLoadPageQueryFromStorage={forceLoadPageQueryFromStorage}
        setForceLoadPageQueryFromStorage={setForceLoadPageQueryFromStorage}
        flags={flags}
        currentVendorId={currentVendorId}
        isRefreshPage={isRefreshPage}
        setRefreshPage={setRefreshPage}
      />
    );
  } else if (
    taskTypesFetchStatus === apiStatusConstants.FAILED ||
    usersFetchStatus === apiStatusConstants.FAILED ||
    (!isVendor && categoriesFetchStatus === apiStatusConstants.FAILED)
  ) {
    return (
      <>
        <Error />
        {flags?.reconFooter && (
          <StyledFooterContainer>
            <Footer />
          </StyledFooterContainer>
        )}
      </>
    );
  } else {
    return (
      <CommonListPageContainer>
        <Affix offsetTop={0} target={() => scrollcontainer.current}>
          <TaskFilters
            flags={flags}
            isLoading={true}
            hasViewAnyTask={hasViewAnyTask}
            hasWorkOwnTasks={hasWorkOwnTasks}
            authState={authState}
            toggleFiltersChangedByUser={toggleFiltersChangedByUser}
          />
        </Affix>

        <CommonSortRow>
          <div>
            <span>
              Showing&nbsp;<strong>0-0&nbsp;</strong>of&nbsp;<strong>0&nbsp;</strong>tasks
            </span>
          </div>
          <BorderlessSelect label="Sort By" loading />
        </CommonSortRow>

        <StyledListContainer
          is-bridge-user={isBridgeUser ? 1 : 0}
          hasNoFilters={true}
          global-alert={hasGlobalAlert}
          reconFooter={flags?.reconFooter}
        >
          <TaskList
            isLoading={true}
            items={Array.from(Array(10)).map((_, i) => ({ id: i }))}
            selectedCard={{ contentType: null }}
          />
        </StyledListContainer>
      </CommonListPageContainer>
    );
  }
};

export const tasksPresentationPropsSelector = createSelector(
  authSelector,
  (state) => state.dealers.current.data,
  (state) => state.tasks,
  (state) => state.taskTypes.data,
  (state) => state.users.data,
  (state) => state.documents,
  (state) => state.users.assigneeData,
  (state) => state.taskCategories.categoriesFromTaskTypes.data,
  (authState, currentDealer, tasksStore, taskTypesData, users, documents, assigneeData, taskCategoriesData) => ({
    authState,
    currentDealerId: currentDealer.id,
    currentDealer: currentDealer,
    fetchStatus: tasksStore.fetchStatus,
    tasksData: tasksStore.data,
    pager: tasksStore.pager,
    taskTypesData,
    taskCategoriesData,
    users,
    documents,
    assigneeData,
    dealerships: tasksStore.dealerships?.items,
    dealershipFetchStatus: tasksStore.dealershipFetchStatus
  })
);

const RenderedTaskRow = React.memo(({ index, style, data }) => {
  const heightNotContainingList = calculatedContentHeight(
    data.isBridgeUser,
    data.globalAlert,
    data.hasNoFilters,
    data.reconFooter,
    true
  );
  const remainingHeight = window.innerHeight - heightNotContainingList - data.items?.length * style.height;
  return data.reconFooter && data.items?.length === index ? (
    <div
      style={{
        ...style,
        bottom: 0
      }}
    >
      {data.pagination}
      <div style={{ paddingTop: remainingHeight <= style.height ? style.height - 132 : remainingHeight - 132 }}>
        <StyledFooterContainer>
          <Footer />
        </StyledFooterContainer>
      </div>
    </div>
  ) : (
    <div style={style}>
      <TaskCard
        isLoading={data?.isLoading}
        task={data?.items[index]}
        isSelected={data?.selectedCard?.model?.id === data?.items[index].id || false}
        setSelectedCard={data?.setSelectedCard}
      />
    </div>
  );
});

export const TaskList = ({
  isLoading = false,
  items,
  selectedCard,
  setSelectedCard,
  showDealerName,
  hasNoFilters,
  isBridgeUser,
  globalAlert,
  reconProfitTime,
  pagination,
  reconFooter
}) => {
  const heightNotContainingList = calculatedContentHeight(isBridgeUser, globalAlert, hasNoFilters, reconFooter, true);
  const remainingHeight = window.innerHeight - heightNotContainingList;

  return !items || !items.length ? (
    <>
      <JustifyCenterRowContainer>
        <Col>
          <VerticalAlignContainer>
            <JustifyCenterRow>
              <NoTaskIcon icon={faClipboard} />
            </JustifyCenterRow>
            <NoTaskTitleRow>
              <NoTaskTitle>No Tasks Found</NoTaskTitle>
            </NoTaskTitleRow>
          </VerticalAlignContainer>
        </Col>
      </JustifyCenterRowContainer>
      {reconFooter && (
        <div style={{ paddingTop: remainingHeight - 272.25 }}>
          {/* 272.25px is the combined height of footer and No Tasks Found message */}
          <StyledFooterContainer>
            <Footer />
          </StyledFooterContainer>
        </div>
      )}
    </>
  ) : (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          className="List"
          height={height}
          itemCount={reconFooter ? items.length + 1 : items.length}
          itemSize={!isLoading && showDealerName ? 197 : !isLoading && reconProfitTime ? 191 : 172}
          // 172px due to inner component's 160px height, 10px bottom margin, 1px top and bottom borders
          // profit time widget increases the height by 19px
          // dealer name increases the height by 25px overall (including profit time widget)
          width={width}
          itemData={{
            isLoading,
            items,
            selectedCard,
            setSelectedCard,
            reconFooter,
            hasNoFilters,
            isBridgeUser,
            showDealerName,
            pagination,
            globalAlert
          }}
        >
          {RenderedTaskRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

const calculatedContentHeight = (isBridgeUser, hasGlobalAlert, hasNoFilters, reconFooter, valueOnly = false) => {
  let height = 338;
  if (isBridgeUser) {
    height += 35;
  }
  if (hasGlobalAlert) {
    height += 42;
  }
  if (hasNoFilters) {
    height -= 40;
  }
  if (reconFooter) {
    height -= 132;
  }
  return valueOnly ? height : `min-height: calc(100vh - ${height}px);`;
};

//#region Styled Components
export const StyledListContainer = styled.div`
  ${(props) =>
    calculatedContentHeight(
      props['is-bridge-user'],
      props['global-alert'],
      props['hasNoFilters'],
      props['reconFooter']
    )}
`;

export const JustifyCenterRow = styled(Row).attrs({
  type: 'flex',
  justify: 'center'
})``;

export const JustifyCenterRowContainer = styled(JustifyCenterRow)`
  width: 100%;
`;

export const VerticalAlignContainer = styled.div.attrs({
  className: 'align-middle'
})`
  margin-top: calc(15vh);
`;

export const NoTaskIcon = styled(FontAwesomeIcon)`
  width: 18px !important;
  height: 24px !important;
  color: ${({ theme }) => theme.colors.darkGray} !important;
`;

export const NoTaskTitle = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.darkGray} !important;
`;

export const NoTaskTitleRow = styled(JustifyCenterRow)`
  height: 21px;
  margin-top: 8px;
`;

const StyledFooterContainer = GridArea('footer', 2, 1);

//#endregion

export default withLDConsumer()(Tasks);
