import { Form, Input, Select, Typography } from 'antd';
import { CurrencyInput, Stack, TimeUnitInputWithDropdown, AssignedToSelect } from 'components';
import { CircularSpinner } from 'components/common';
import { StyledForm, StyledFormItem } from 'components/layout/settings/common/Styles';
import { CommonFooterContainer, CommonLinkButton, CommonRedButton, CommonSelect } from 'components/styledComponents';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import AddLineItemFormTemplate from '../../inventory/reconPlan/TaskForms/LineItemsTemplate/AddLineItemFormTemplate';
import AlertForLineItemErrorTemplate from '../../inventory/reconPlan/TaskForms/LineItemsTemplate/AlertForLineItemErrorTemplate';
import { maxEstAndTotalCostReconApprovalWorkflow, features, apiStatusConstants } from 'app-constants';
import { useFeatures } from 'hooks';
import { lineItemsActions } from 'store/lineItemsStore';

const { Option } = Select;
const { Title } = Typography;
const differenceBetweenTimesMeetsRequirements = (goalDays, goalHours, maxDays, maxHours) =>
  goalDays <= maxDays && (goalDays !== maxDays || goalHours <= maxHours);
const Loading = () => (
  <StyledCenter>
    <CircularSpinner tip="Loading..." size="2rem" />
  </StyledCenter>
);

const Error = () => (
  <StyledCenter>
    <Title type="danger" level={4}>
      Oops, something went wrong
    </Title>
  </StyledCenter>
);

const TemplateTask = ({
  form,
  taskType,
  formValues,
  isNameTaken,
  isAcceptableInteger,
  onChange,
  isSaving,
  formIsDirty,
  onSave,
  onDelete,
  users,
  taskCategories,
  usersIsLoading,
  showDiscardButton,
  closeDrawer,
  handleCanClose,
  setFormIsDirty,
  flags,
  internalGroups,
  vendors
}) => {
  const dispatch = useDispatch();
  const { getFieldDecorator } = form;
  const needApprovalList = useSelector((state) => state.lineItems.needApproval);
  const approvedList = useSelector((state) => state.lineItems.approved);
  const fetchLineItemsStatus = useSelector((state) => state.lineItems.fetchLineItemsStatus);
  const lineItemForBodySaveRequest = useSelector((state) => state.lineItems.lineItemForBodySaveRequest);
  const needApprovalErrorMessage = useSelector((state) => state.lineItems.needApprovalErrorMessage);
  const firstAssignee = taskType && taskType.assignees && taskType.assignees[0];
  const [hasApproverRole] = useFeatures(features.TASKS_APPROVE_LINE_ITEMS);

  const [isDisabled, setDisabled] = useState(true);
  const [isDuplicated, setDuplicated] = useState(false);

  useEffect(() => {
    if (taskType?.id) {
      dispatch(lineItemsActions.getLineItemsTaskTemplate(taskType.id));
    } else {
      dispatch(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
    }
    return () => {
      // unmout component => reset line item
      dispatch(lineItemsActions.resetLineItem());
      dispatch(lineItemsActions.resetLineItemForBodySaveRequest());
    };
  }, [taskType?.id]);

  useEffect(() => {
    if (taskType) {
      form.setFieldsValue({
        name: formValues.name,
        assignedTo: formValues.assignedTo,
        estimatedCost: formValues.estimatedCost,
        goalDays: formValues.goalDays,
        goalHours: formValues.goalHours,
        maxDays: formValues.maxDays,
        maxHours: formValues.maxHours,
        description: formValues.description,
        categoryId: formValues.categoryId
      });
      setDisabled(getSaveButtonStatus(formValues.name ?? ''));
    }
  }, [formValues]);

  const getSaveButtonLabel = () => {
    if (taskType && taskType.id) {
      return isSaving ? 'Saving' : 'Save';
    } else {
      return isSaving ? 'Creating' : 'Create Task';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (taskType?.id) {
          if (Array.isArray(lineItemForBodySaveRequest) && lineItemForBodySaveRequest.length > 0) {
            dispatch(lineItemsActions.patchLineItemsTaskTemplate(lineItemForBodySaveRequest, onSave));
          } else {
            onSave();
          }
        } else {
          onSave([...lineItemForBodySaveRequest]);
        }
      } else {
        onSave();
      }
    });
  };

  const getSaveButtonStatus = (value) => {
    let isNameNullOrDuplicated = !value || !value.trim() || isDuplicated;
    let isCategoryIdNull = !formValues.categoryId;
    return isNameNullOrDuplicated || isCategoryIdNull;
  };

  let needToValidateSiblingField = false;

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit}>
        <StyledFormItemsContainer>
          <StyledFormItem label="Task Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    setDisabled(getSaveButtonStatus(value));
                    if (!value || !value.trim()) {
                      rule.message = 'Name is required';
                    } else if (isNameTaken(value.trim(), taskType.name) && value.trim() !== taskType.name) {
                      setDuplicated(true);
                      rule.message = 'There is already a Task Type with that name';
                    } else {
                      setDuplicated(false);
                    }
                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                }
              ]
            })(
              <Input
                size="large"
                style={{ fontSize: '14px' }}
                placeholder="Enter a name"
                maxLength={30}
                onChange={(e) => onChange('name')(e.target.value)}
              />
            )}
          </StyledFormItem>

          <StyledFormItem label="Category">
            {getFieldDecorator('categoryId', {
              rules: [
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value && value < 0) {
                      rule.message = 'Category is required';
                    }
                    if (rule.message) {
                      return Promise.reject(rule.message);
                    }
                    return Promise.resolve();
                  }
                }
              ]
            })(
              <CommonSelect
                id="CategoryCombo"
                showSearch
                key={{ taskType }}
                placeholder="Select a category"
                onChange={onChange('categoryId')}
                optionFilterProp="label"
              >
                {taskCategories.map((t, index) => (
                  <Option
                    key={t.id + '_taskCategoriesOption'}
                    value={t.id}
                    label={t.name}
                    className={t.name === 'Miscellaneous' ? 'seperate-item-top' : ''}
                  >
                    {t.name}
                  </Option>
                ))}
              </CommonSelect>
            )}
          </StyledFormItem>
          <StyledFormItem label="Assigned to">
            {getFieldDecorator('assignedTo')(
              <>
                <AssignedToSelect
                  form={form}
                  getFieldDecorator={getFieldDecorator}
                  assignedToOptional={true}
                  users={users}
                  internalGroups={internalGroups}
                  onChange={onChange}
                  assignedToMember={formValues?.assignedToMember}
                  userIdGetAssignToByTypeName={formValues?.assignedTo}
                  taskToUpdate={taskType}
                  flags={flags}
                  withWorkload={false}
                  usersIsLoading={usersIsLoading}
                  firstAssignee={firstAssignee}
                  vendors={vendors}
                  isShowNoAssignee={true}
                />
              </>
            )}
          </StyledFormItem>

          <StyledFormItem label="Estimated Cost">
            {getFieldDecorator('estimatedCost')(
              <CurrencyInput
                onChange={onChange('estimatedCost')}
                precision={0}
                max={maxEstAndTotalCostReconApprovalWorkflow}
              />
            )}
          </StyledFormItem>
          <Stack horizontal>
            <TimeUnitInputWithDropdown
              isAcceptableInteger={isAcceptableInteger}
              onChange={onChange}
              formValues={formValues}
              getFieldDecorator={getFieldDecorator}
              field="goalDays"
              label="Goal Time"
              validatorFunction={(rule, value) => {
                needToValidateSiblingField = !needToValidateSiblingField;
                const maxDays = parseInt(form.getFieldValue('maxDays'));
                const maxHours = parseInt(form.getFieldValue('maxHours'));
                const goalDays = parseInt(value);
                const goalHours = parseInt(form.getFieldValue('goalHours'));
                if (isNaN(goalDays)) {
                  rule.message = 'Goal Days is required';
                } else if (goalDays <= 0 && goalHours <= 0) {
                  rule.message = 'Goal Time must be greater than zero';
                  if (needToValidateSiblingField) {
                    form.validateFields(['maxDays', 'maxHours']);
                  }
                } else if (goalDays > 100) {
                  rule.message = 'Goal Time cannot exceed 100 days';
                } else if (!differenceBetweenTimesMeetsRequirements(goalDays, goalHours, maxDays, maxHours)) {
                  rule.message = 'Goal Time must be <= Max Time';
                } else if (needToValidateSiblingField) {
                  form.validateFields(['maxDays']);
                }
                if (rule.message) {
                  return Promise.reject(rule.message);
                }
                return Promise.resolve();
              }}
            />
            <TimeUnitInputWithDropdown
              isAcceptableInteger={isAcceptableInteger}
              onChange={onChange}
              formValues={formValues}
              getFieldDecorator={getFieldDecorator}
              field="goalHours"
              validatorFunction={(rule, value) => {
                needToValidateSiblingField = !needToValidateSiblingField;
                const goalDays = parseInt(form.getFieldValue('goalDays'));
                const goalHours = parseInt(value);
                if (isNaN(goalHours)) {
                  rule.message = 'Goal Hours is required';
                } else if (goalHours > 23) {
                  rule.message = 'Goal Hours must be <= 23';
                } else if (goalDays <= 0 && goalHours <= 0) {
                  rule.message = 'Goal Time must be greater than zero';
                  if (needToValidateSiblingField) {
                    form.validateFields(['maxDays', 'maxHours']);
                  }
                } else if (goalDays >= 100 && goalHours > 0) {
                  rule.message = 'Goal Time cannot exceed 100 days';
                } else if (needToValidateSiblingField) {
                  form.validateFields(['maxHours']);
                }
                if (rule.message) {
                  return Promise.reject(rule.message);
                }
                return Promise.resolve();
              }}
            />
          </Stack>
          <Stack horizontal>
            <TimeUnitInputWithDropdown
              isAcceptableInteger={isAcceptableInteger}
              onChange={onChange}
              formValues={formValues}
              getFieldDecorator={getFieldDecorator}
              field="maxDays"
              label="Max Time"
              validatorFunction={(rule, value) => {
                needToValidateSiblingField = !needToValidateSiblingField;
                const maxDays = parseInt(value);
                const maxHours = parseInt(form.getFieldValue('maxHours'));
                const goalDays = parseInt(form.getFieldValue('goalDays'));
                const goalHours = parseInt(form.getFieldValue('goalHours'));
                if (isNaN(maxDays)) {
                  rule.message = 'Max Days is required';
                } else if (maxDays <= 0 && maxHours <= 0) {
                  rule.message = 'Max Time must be greater than zero';
                  if (needToValidateSiblingField) {
                    form.validateFields(['goalDays', 'goalHours']);
                  }
                } else if (maxDays > 100) {
                  rule.message = 'Max Time cannot exceed 100 days';
                } else if (!differenceBetweenTimesMeetsRequirements(goalDays, goalHours, maxDays, maxHours)) {
                  rule.message = 'Max Time must be >= Goal Time';
                } else if (needToValidateSiblingField) {
                  form.validateFields(['goalDays']);
                }
                if (rule.message) {
                  return Promise.reject(rule.message);
                }
                return Promise.resolve();
              }}
            />
            <TimeUnitInputWithDropdown
              isAcceptableInteger={isAcceptableInteger}
              onChange={onChange}
              formValues={formValues}
              getFieldDecorator={getFieldDecorator}
              field="maxHours"
              validatorFunction={(rule, value) => {
                needToValidateSiblingField = !needToValidateSiblingField;
                const maxDays = parseInt(form.getFieldValue('maxDays'));
                const maxHours = parseInt(value);
                if (isNaN(maxHours)) {
                  rule.message = 'Max Hours is required';
                } else if (maxHours > 23) {
                  rule.message = 'Max Hours must be <= 23';
                } else if (maxDays <= 0 && maxHours <= 0) {
                  rule.message = 'Max Time must be greater than zero';
                  if (needToValidateSiblingField) {
                    form.validateFields(['goalDays', 'goalHours']);
                  }
                } else if (maxDays >= 100 && maxHours > 0) {
                  rule.message = 'Max Time cannot exceed 100 days';
                } else if (needToValidateSiblingField) {
                  form.validateFields(['goalHours']);
                }
                if (rule.message) {
                  return Promise.reject(rule.message);
                }
                return Promise.resolve();
              }}
            />
          </Stack>
          <StyledFormItem label="Description">
            {getFieldDecorator('description')(
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 14 }}
                placeholder="Type to enter a description"
                onChange={(e) => onChange('description')(e.target.value)}
              />
            )}
          </StyledFormItem>
        </StyledFormItemsContainer>

        <StyledFooterContainer>
          {taskType && taskType.id && (
            <CommonLinkButton disabled={isSaving} onClick={onDelete} style={{ marginLeft: '24px' }}>
              Delete
            </CommonLinkButton>
          )}
          {showDiscardButton ? (
            <CommonRedButton
              disabled={isSaving}
              onClick={(e) => closeDrawer()}
              style={{ marginLeft: 'auto', marginRight: '24px' }}
            >
              Discard Changes
            </CommonRedButton>
          ) : (
            <CommonLinkButton
              disabled={isSaving}
              onClick={handleCanClose}
              style={{ marginLeft: 'auto', marginRight: '24px' }}
            >
              Cancel
            </CommonLinkButton>
          )}

          <StyledSubmitButton
            loading={isSaving}
            disabled={
              !formIsDirty ||
              needApprovalList.find((x) => x.error) ||
              approvedList.find((x) => x.error) ||
              needApprovalErrorMessage ||
              isDisabled
            }
            style={{ marginRight: '24px' }}
          >
            {getSaveButtonLabel()}
          </StyledSubmitButton>
        </StyledFooterContainer>
      </StyledForm>
      {
        <StyledLineItem>
          {
            {
              [apiStatusConstants.IS_FETCHING]: <Loading />,
              [apiStatusConstants.SUCCEEDED]: (
                <AddLineItemFormTemplate
                  hasPermissionApprovedDecline={hasApproverRole}
                  setIsDirty={setFormIsDirty}
                  task={taskType}
                />
              ),
              [apiStatusConstants.FAILED]: <Error />
            }[fetchLineItemsStatus]
          }
        </StyledLineItem>
      }
      <AlertForLineItemErrorTemplate />
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div``;
const StyledLineItem = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  margin-bottom: 73px;
`;
export const StyledFormItemsContainer = styled.div`
  padding: 0 24px;
  width: 100%;
  margin-bottom: 24px;
`;
const StyledFooterContainer = styled(CommonFooterContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})``;

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: 100%;
`;
//#endregion

export default withLDConsumer()(Form.create()(TemplateTask));
