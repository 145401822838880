import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const PreviousDateRangeTooltip = ({ startDate, endDate, children }) => {
  const parsedEndDate = moment(startDate, 'MM-DD-YYYY').subtract(1, 'd');
  const daysAgo = moment(endDate, 'MM-DD-YYYY').diff(startDate, 'days');
  const parsedStartDate = moment(parsedEndDate, 'MM-DD-YYYY').subtract(daysAgo, 'd');
  const tooltipContent = (
    <div>
      <StyledContentSpan>Previous {daysAgo} days</StyledContentSpan>
      <StyledContentSpan>
        {parsedStartDate.format('MMM. D')} - {parsedEndDate.format('MMM. D')}
      </StyledContentSpan>
    </div>
  );

  return (
    <Tooltip title={tooltipContent} placement={'left'} trigger={'hover'} overlayClassName="enterprise-summary-tooltip">
      <div style={{ display: 'inline' }}>{children}</div>
    </Tooltip>
  );
};

const StyledContentSpan = styled.span`
  display: block;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.xs};
  letter-spacing: ${({ theme }) => theme.letterSpacings.md};
`;

export default PreviousDateRangeTooltip;
