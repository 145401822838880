import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { Dropdown, Menu } from 'antd';
import { features, CoxAutoLogoutUrl, tempLogoutUrlNewDomain } from 'app-constants';
import { useFeatures } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import styled from 'styled-components';
import useVendorRole from '../../../hooks/useVendorRole';
import { HQAndProfitTimeContext } from 'utils/contexts';

//commented by it not use
// const AuthedMenuItemGroup = ({ authorized, ...rest }) => (authorized ? <Menu.ItemGroup {...rest} /> : null);

const AuthedMenuItem = ({ authorized, ...rest }) => (authorized ? <Menu.Item {...rest} /> : null);

const SettingsMenuSelector = createSelector(authSelector, (authStore) => ({
  isBridgeUser: authStore.isBridgeUser
}));

const SettingsMenu = ({ setSelectedNav, flags }) => {
  const { isBridgeUser } = useSelector(SettingsMenuSelector);

  const [hasDealerSettings] = useFeatures(features.DEALER_SETTINGS);
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { isVendor, isVendorAdmin } = useVendorRole();
  const { isHQ } = useContext(HQAndProfitTimeContext);
  useEffect(() => {
    if (dropdownIsVisible) {
      setIsActive(true);
    } else {
      setIsActive(isHovering);
    }
  }, [dropdownIsVisible, isHovering]);

  const handleNavigate = () => {
    navigate('/settings/notifications');
    setSelectedNav('notifications');
  };

  const handleNavigateToConfigureIRecon = (e) => {
    isVendorAdmin ? navigate(`/configure/users`) : navigate(`/configure/inventory`);
    setSelectedNav('configure');
  };

  const handleNavigateToBusinessProfile = (e) => {
    navigate('/business-profile');
  };

  return (
    <Dropdown
      trigger={['click']}
      onVisibleChange={(visible) => setDropdownIsVisible(visible)}
      overlay={
        <StyledMenu
          onClick={() => {
            setDropdownIsVisible(false);
          }}
        >
          <Menu.ItemGroup>
            <AuthedMenuItem
              key="profile"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_PROVISION_BASE_URL_NEW_DOMAIN}/Va/Settings/User/UserSettings.aspx`,
                  '_blank'
                )
              }
              authorized={!isVendor}
            >
              My Profile (Provision)
            </AuthedMenuItem>
            <AuthedMenuItem key="businessProfile" onClick={handleNavigateToBusinessProfile} authorized={isVendorAdmin}>
              Business Profile
            </AuthedMenuItem>
            <AuthedMenuItem
              key="configureIRecon"
              onClick={handleNavigateToConfigureIRecon}
              authorized={(hasDealerSettings || isVendorAdmin) && !isHQ}
            >
              Configure iRecon
            </AuthedMenuItem>
            <AuthedMenuItem
              key="manageUsers"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_PROVISION_BASE_URL}/Va/Settings/UserManagement/UserFeatures.aspx`,
                  '_blank'
                )
              }
              authorized={isHQ}
            >
              Manage Users (Provision)
            </AuthedMenuItem>
            <Menu.Item key="notifications" onClick={handleNavigate}>
              Notifications
            </Menu.Item>
          </Menu.ItemGroup>
          {!isBridgeUser ? (
            <Menu.Item key="signout" className="sign-out">
              <div onClick={() => (isVendor ? navigate(CoxAutoLogoutUrl) : navigate(tempLogoutUrlNewDomain))}>
                Log out
              </div>
            </Menu.Item>
          ) : (
            <Menu.Item />
          )}
        </StyledMenu>
      }
    >
      <StyledSettingsHighlightContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        data-isactive={isActive}
        reconDealerFilterFlag={flags?.reconDealerFilter}
      >
        <StyledCogIcon icon={faCog} data-isactive={isActive} />
      </StyledSettingsHighlightContainer>
    </Dropdown>
  );
};

//#region Styled Components
const StyledMenu = styled(Menu)`
  .ant-dropdown-menu& {
    min-width: 216px;
    padding: 0;
    border-radius: initial;
    .ant-dropdown-menu-item-group {
      .ant-dropdown-menu-item-group-title {
        color: ${({ theme }) => theme.colors.darkGray};
        font-size: ${({ theme }) => theme.fontSizes.md};
        line-height: 19px;
      }
      .ant-dropdown-menu-item-group-list {
        margin: 0;
        padding: 0;
        .ant-dropdown-menu-item {
          display: flex;
          align-items: center;
          padding: 0 24px;
          height: 32px;
          font-size: ${({ theme }) => theme.fontSizes.md};
          transition: none;
          > a {
            transition: none;
          }
          .svg-inline--fa {
            font-size: ${({ theme }) => theme.fontSizes.sm};
            margin-right: 19px;
          }
          .fa-list-ul {
            margin-bottom: 3px;
          }
          :hover {
            background-color: ${({ theme }) => theme.colors.backgroundGray};
            // use text-shadow instead of font-weight to simulate bold fonts without triggering a layout shift that resizes the menu by just a little
            text-shadow: 0 0 0.65px ${({ theme }) => theme.colors.darkGray},
              0 0 0.65px ${({ theme }) => theme.colors.darkGray};
          }
        }
      }
    }
    .sign-out {
      cursor: default;
      height: 50px;
      margin-top: 9px;
      border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
      padding: 9px 0;
      :hover {
        background-color: ${({ theme }) => theme.colors.white};
      }

      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 32px;
        padding-left: 24px;
        font-size: ${({ theme }) => theme.fontSizes.md};
        transition: none;
        a {
          transition: none;
        }
        .svg-inline--fa {
          font-size: ${({ theme }) => theme.fontSizes.sm};
          margin-right: 19px;
        }
        :hover {
          background-color: ${({ theme }) => theme.colors.backgroundGray};
          font-weight: ${({ theme }) => theme.fontWeights.medium};
        }
      }
    }
  }
`;
const StyledSettingsHighlightContainer = styled.div.attrs({
  className: 'center-content'
})`
  width: 50px;
  background-color: ${(props) => (props['data-isactive'] ? props.theme.colors.backgroundGray : 'inherit')};
  cursor: pointer;
  ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? 'height: 100%;' : '')}
`;
const StyledCogIcon = styled(FontAwesomeIcon)`
  color: ${(props) => (props['data-isactive'] ? 'inherit' : props.theme.colors.darkGray)};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.md};
`;
//#endregion

export default withLDConsumer()(SettingsMenu);
