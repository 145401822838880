import React, { useEffect } from 'react';
import { Input, Typography, Collapse, Icon } from 'antd';
import {
  communicationContexts,
  apiStatusConstants,
  features,
  detailContents,
  maxEstAndTotalCostReconApprovalWorkflow
} from 'app-constants';
import { FullCommunications, withCommunications } from 'components/communications';
import StandaloneTask from 'components/TaskGroupsDnd/Task/StandaloneTask';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BodyBaseBold, BodyXSmallBold } from 'components/styledComponents';
import { getLineItemByTaskIdSelector } from 'store/tasksStore.js';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import LineItemFormWrapper from 'components/layout/inventory/reconPlan/TaskForms/LineItemForm';
import { lineItemsActions } from 'store/lineItemsStore';
import { CircularSpinner, CurrencyInput } from 'components';
import { StyledFormItem } from './AddNewOrEditTaskForm';
import { formatterNumberUSD } from 'utils/numberUtils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useFeatures } from 'hooks';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Title } = Typography;
const Loading = () => (
  <StyledCenter>
    <CircularSpinner tip="Loading..." size="2rem" />
  </StyledCenter>
);
const Error = () => (
  <StyledCenter>
    <Title type="danger" level={4}>
      Oops, something went wrong
    </Title>
  </StyledCenter>
);

const lineItemSelector = createSelector(
  (state) => state.lineItems.needApproval,
  (state) => state.lineItems.approved,
  (state) => state.lineItems.declined,
  (state) => state.lineItems.fetchLineItemsStatus,
  (needApproval, approved, declined, fetchLineItemsStatus) => ({
    needApprovalList: needApproval,
    approvedList: approved,
    declinedList: declined,
    totalCostApproved:
      approved.length > 0
        ? approved
            .map((item) => (item.totalCost ? Number(item.totalCost) : 0))
            .reduce((accumulator, current) => {
              return accumulator + current;
            })
        : 0,
    fetchLineItemsStatus: fetchLineItemsStatus
  })
);

export const CompletedTaskForm = withLDConsumer()(
  ({
    taskToUpdate,
    vehicleId,
    getFieldDecorator,
    onChange,
    hidePassthroughIndicator,
    reconPlanFeatures,
    isInProgressTaskGroup,
    contentType,
    setIsDirty,
    isCompleting,
    flags
  }) => {
    const dispatch = useDispatch();
    const { needApprovalList, declinedList, approvedList, totalCostApproved, fetchLineItemsStatus } = useSelector(
      lineItemSelector
    );
    const lineItemsOfTask = useSelector(getLineItemByTaskIdSelector(taskToUpdate.id));
    const [hasApproverRole] = useFeatures(features.TASKS_APPROVE_LINE_ITEMS);

    useEffect(() => {
      if (
        (contentType === detailContents.VEHICLE_DETAILS || contentType === detailContents.RECON_PLAN) &&
        lineItemsOfTask
      ) {
        dispatch(lineItemsActions.setLineItems(lineItemsOfTask.items));
      } else {
        dispatch(lineItemsActions.getLineItems(taskToUpdate));
      }
      dispatch(lineItemsActions.setFetchLineItemsStatus(apiStatusConstants.SUCCEEDED));
    }, [taskToUpdate.id, JSON.stringify(lineItemsOfTask)]);

    // handle editable line item in complete task form
    useEffect(() => {
      dispatch(
        lineItemsActions.setLineItems(
          [...needApprovalList, ...approvedList, ...declinedList].map((lineItem) => ({
            ...lineItem,
            isEditing: false
          }))
        )
      );

      //reset lineitem if complete task on three dot dropdown on TLP
      return () => {
        if (contentType === detailContents.COMPLETE_TASK) {
          dispatch(lineItemsActions.resetLineItem());
        }
      };
    }, []);

    let lineItemFieldClassName = 'line-item-field';
    let partsFieldClassName = 'parts-field';
    let laborFieldClassName = 'labor-field';
    let totalFieldClassName = 'total-field';
    if (!hasApproverRole) {
      lineItemFieldClassName += ' line-item-field-no-action';
      partsFieldClassName += ' parts-field-no-action';
      laborFieldClassName += ' labor-field-no-action';
      totalFieldClassName += ' total-field-no-action';
    }

    return (
      <>
        {/* Task Card that shows non-editable fields */}
        <StandaloneTask
          key={taskToUpdate.id}
          editable={true}
          vehicleId={vehicleId}
          task={taskToUpdate}
          showEstimates={true}
          hidePassthroughIndicator={hidePassthroughIndicator}
          hideTaskProgressInfo={true}
          hideAssigneeWorkload={true}
          reconPlanFeatures={reconPlanFeatures}
          hideOverflowMenu={true}
          isInProgressTaskGroup={isInProgressTaskGroup}
          contentType={contentType}
          isShowContentSegmentTDP={true}
          showPendingApprovalTime={
            (contentType !== detailContents.VEHICLE_DETAILS && contentType !== detailContents.RECON_PLAN) ||
            !isCompleting
          }
        />

        {/* Form for editable fields */}
        {
          {
            [apiStatusConstants.IS_FETCHING]: <Loading />,
            [apiStatusConstants.SUCCEEDED]: isEmpty(approvedList) ? (
              <StyledFormItem label="Final Cost" style={{ marginTop: '14px' }}>
                {getFieldDecorator('completedCost')(
                  <CurrencyInput
                    onChange={(e) => onChange('completedCost')(!isNaN(e) ? e : null)}
                    max={maxEstAndTotalCostReconApprovalWorkflow}
                  />
                )}
              </StyledFormItem>
            ) : (
              <>
                <AddLineItemContainer>
                  <Collapse
                    accordion={true}
                    bordered={false}
                    defaultActiveKey={['approved']}
                    expandIcon={({ isActive }) => (
                      <Icon rotate={isActive ? 90 : 0}>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Icon>
                    )}
                  >
                    <Panel
                      header={
                        <HeaderPanel>
                          <BodyBaseBold>Approved ({approvedList.filter((item) => !item.isAdding).length})</BodyBaseBold>
                          <BodyBaseBold>${formatterNumberUSD(totalCostApproved)}</BodyBaseBold>
                        </HeaderPanel>
                      }
                      key={'approved'}
                    >
                      {
                        {
                          [apiStatusConstants.IS_FETCHING]: <Loading />,
                          [apiStatusConstants.SUCCEEDED]: (
                            <>
                              <LineItemsWrapper>
                                <div className={lineItemFieldClassName}>
                                  <span className="required-char">*</span> <BodyXSmallBold>LINE ITEM</BodyXSmallBold>
                                </div>
                                <div className={partsFieldClassName}>
                                  <BodyXSmallBold>PARTS</BodyXSmallBold>
                                </div>
                                <div className={laborFieldClassName}>
                                  <BodyXSmallBold>LABOR</BodyXSmallBold>
                                </div>
                                <div className={totalFieldClassName}>
                                  <span className="required-char">*</span> <BodyXSmallBold>TOTAL</BodyXSmallBold>
                                </div>
                                {hasApproverRole && <div className="action-field"></div>}
                              </LineItemsWrapper>
                              <LineItemFormWrapper
                                hasPermissionApprovedDecline={hasApproverRole}
                                task={taskToUpdate}
                                setIsDirty={setIsDirty}
                                listName={'approved'}
                                hideDeclineSection={false}
                                isForCompletedTaskForm={true}
                                isCompleting={isCompleting}
                                flags={flags}
                                contentType={contentType}
                              />
                            </>
                          ),
                          [apiStatusConstants.FAILED]: <Error />
                        }[fetchLineItemsStatus]
                      }
                    </Panel>
                  </Collapse>
                </AddLineItemContainer>
                <StyledFormItem label="Final Cost" style={{ marginBottom: '28px' }}>
                  <FinalCostWrapper>{`$${formatterNumberUSD(totalCostApproved)}`}</FinalCostWrapper>
                </StyledFormItem>
              </>
            ),
            [apiStatusConstants.FAILED]: <Error />
          }[fetchLineItemsStatus]
        }
        <StyledFormItem label="Completion Note">
          {getFieldDecorator('completionNote')(
            <TextArea
              placeholder="Type to enter a completion note"
              autoSize={{ minRows: 7, maxRows: 14 }}
              onChange={(e) => onChange('completionNote')(e.target.value)}
            />
          )}
        </StyledFormItem>
      </>
    );
  }
);

export const DeclineTaskForm = ({
  taskToDecline,
  vehicleId,
  getFieldDecorator,
  onChange,
  hidePassthroughIndicator,
  reconPlanFeatures,
  isInProgressTaskGroup,
  contentType
}) => {
  return (
    <>
      {/*Task Card that shows non-editable fields*/}
      <StandaloneTask
        key={taskToDecline.id}
        editable={false}
        vehicleId={vehicleId}
        task={taskToDecline}
        showEstimates={true}
        hidePassthroughIndicator={hidePassthroughIndicator}
        hideTaskProgressInfo={true}
        reconPlanFeatures={reconPlanFeatures}
        hideOverflowMenu={true}
        isInProgressTaskGroup={isInProgressTaskGroup}
        contentType={contentType}
      />
      <StyledFormItem label="Decline Note" style={{ marginTop: '14px' }}>
        {getFieldDecorator('declineReason')(
          <TextArea
            onChange={(e) => onChange('declineReason')(e.target.value)}
            placeholder="Type to enter a decline note"
            autoSize={{ minRows: 7, maxRows: 14 }}
          />
        )}
      </StyledFormItem>
    </>
  );
};

const FullMessagesFromTasksStore = withCommunications('vehiclesStore')(FullCommunications);

export const TaskMessages = ({
  editable,
  onUpdate,
  onComplete,
  onDelete,
  vehicleId,
  task,
  setFormProps,
  hidePassthroughIndicator,
  threadCommunications,
  // communicationsFetchStatus,
  passedInCommunications,
  initialFocus,
  isInProgressTaskGroup,
  closeForm,
  reconPlanFeatures,
  contentType,
  isShowContentSegmentTDP
}) => {
  const communicationsFetchStatus = useSelector((state) => state.vdp.communicationsFetchStatus);

  return (
    <>
      <StandaloneTask
        key={task.id}
        editable={editable}
        vehicleId={vehicleId}
        onUpdate={onUpdate}
        onComplete={onComplete}
        onDelete={onDelete}
        closeForm={closeForm}
        task={task}
        setFormProps={setFormProps}
        showEstimates={true}
        hidePassthroughIndicator={hidePassthroughIndicator}
        hideTaskProgressInfo={true}
        hideAssigneeWorkload={false}
        hideMessageOverflowMenuItem={true}
        reconPlanFeatures={reconPlanFeatures}
        countMessagesTask={passedInCommunications.length}
        isInProgressTaskGroup={isInProgressTaskGroup}
        contentType={contentType}
        isShowContentSegmentTDP={isShowContentSegmentTDP}
        showPendingApprovalTime={false}
      />
      <FullMessagesFromTasksStore
        threadCommunications={threadCommunications}
        location={communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE}
        containerId={vehicleId}
        vehicleId={vehicleId}
        reconTaskId={task?.id}
        communicationsFetchStatus={communicationsFetchStatus}
        passedInCommunications={passedInCommunications}
        initialFocus={initialFocus}
      />
    </>
  );
};

const AddLineItemContainer = styled.div`
  margin-bottom: 0px;
  width: 100%;
  .ant-collapse {
    background-color: initial;

    .ant-collapse-item {
      border-bottom: initial;

      .ant-collapse-content > .ant-collapse-content-box {
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 0px;
      }

      .ant-collapse-header {
        padding-left: 24px;
        padding-right: 0px;

        .ant-collapse-arrow {
          left: 0;
          font-size: ${({ theme }) => theme.fontSizes.md};
          padding-bottom: 5px;
        }
      }
    }
  }
`;

const HeaderPanel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 5px;
`;

const LineItemsWrapper = styled.div`
  width: 100%;
  display: flex;

  span {
    color: ${({ theme }) => theme.colors.darkGray};

    &.required-char {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  .line-item-field {
    width: 31%;
    padding-right: 5px;
  }

  .parts-field,
  .labor-field,
  .total-field {
    width: 15%;
    padding-right: 5px;
  }

  .action-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 24%;
  }

  .line-item-field-no-action {
    width: 31%;
  }

  .parts-field-no-action,
  .labor-field-no-action,
  .total-field-no-action {
    width: 23%;
  }
`;

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: 100%;
`;

const FinalCostWrapper = styled.div`
  padding-left: 24px;
`;
