import { v4 as uuidv4 } from 'uuid';
import { takeLatest, put, takeEvery, select, all, fork, join } from 'redux-saga/effects';
import { apiStatusConstants, lineItemStatusTypes } from 'app-constants';
import { createRequestTypes, makeActionCreator } from 'utils';
import { getWithToken, postWithToken, putWithToken, deleteWithToken, patchWithToken } from 'api';
import { createSelector } from 'reselect';
import { CURRENT_DEALER } from 'store/dealersStore';
import { messagesActions } from 'store/messagesStore';
import { updateObjectInArray } from 'utils/arrayUtils';
import { OPERATION_LINE_ITEM } from 'app-constants/lineItemConstants';
import { updateStatusForLineItemTemplate } from 'utils/lineItemsTemplateUtils';

//#region Actions
export const PLAN_TEMPLATES = createRequestTypes('PLAN_TEMPLATES', [
  'GET',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'ADD',
  'UPDATE',
  'DELETE',
  'BULK_UPDATE',
  'UPDATE_LINE_ITEM_TEMPLATES_TASK',
  'ADD_NEW_LINE_ITEM_TEMPLATES_TASKS',
  'ADD_UPDATE_LINE_ITEM_PLAN_TEMPLATES'
]);
export const planTemplatesActions = {
  get: makeActionCreator(PLAN_TEMPLATES.GET, 'dealerId'),
  setPlanTemplates: makeActionCreator(PLAN_TEMPLATES.SET_DATA, 'planTemplates'),
  setFetchStatus: makeActionCreator(PLAN_TEMPLATES.SET_FETCH_STATUS, 'fetchStatus'),
  add: makeActionCreator(PLAN_TEMPLATES.ADD, 'planTemplate', 'loadingMessage', 'successMessage', 'errorMessage'),
  update: makeActionCreator(PLAN_TEMPLATES.UPDATE, 'planTemplate', 'loadingMessage', 'successMessage', 'errorMessage'),
  delete: makeActionCreator(PLAN_TEMPLATES.DELETE, 'planTemplate', 'loadingMessage', 'successMessage', 'errorMessage'),
  bulkUpdate: makeActionCreator(PLAN_TEMPLATES.BULK_UPDATE, 'planTemplates'),
  updatelineItemTemplatesTask: makeActionCreator(
    PLAN_TEMPLATES.UPDATE_LINE_ITEM_TEMPLATES_TASK,
    'planTemplateId',
    'taskId',
    'lineItemtemplates'
  ),
  addNewlineItemTemplatesTasks: makeActionCreator(
    PLAN_TEMPLATES.ADD_NEW_LINE_ITEM_TEMPLATES_TASKS,
    'planTemplateId',
    'lineItemtemplatesTask'
  ),
  addUpdatelineItemPlanTemplates: makeActionCreator(
    PLAN_TEMPLATES.ADD_UPDATE_LINE_ITEM_PLAN_TEMPLATES,
    'lineItemPlanTemplate'
  )
};
//#endregion

//#region Reducer
const initialState = {
  data: [],
  fetchStatus: apiStatusConstants.IS_FETCHING,
  lineItemTemplatesTasks: []
};

export const planTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAN_TEMPLATES.SET_DATA:
      return {
        ...state,
        data: action.planTemplates,
        lineItemTemplatesTasks: flatLineItemTemplateTasks(action.planTemplates)
      };
    case PLAN_TEMPLATES.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case PLAN_TEMPLATES.UPDATE_LINE_ITEM_TEMPLATES_TASK:
      const newLineItemTemplatesTasks = [...state.lineItemTemplatesTasks];
      const planTemplate = newLineItemTemplatesTasks.find((lineItemPlan) => lineItemPlan.id === action.planTemplateId);
      if (planTemplate && Array.isArray(planTemplate.templateTasks) && !!planTemplate.templateTasks.length) {
        const lineItemTask = planTemplate.templateTasks.find((task) => task.id === action.taskId);

        if (lineItemTask && Array.isArray(action.lineItemtemplates)) {
          lineItemTask.lineItemTemplates = [...action.lineItemtemplates];
        }
      }
      return {
        ...state,
        lineItemTemplatesTasks: newLineItemTemplatesTasks
      };
    case PLAN_TEMPLATES.ADD_NEW_LINE_ITEM_TEMPLATES_TASKS:
      const newLineItemTemplatesTasksForAddCase = [...state.lineItemTemplatesTasks];
      if (!!action.planTemplateId) {
        const planTemplateForAddCase = newLineItemTemplatesTasksForAddCase.find(
          (lineItemPlan) => lineItemPlan.id === action.planTemplateId
        );
        if (planTemplateForAddCase && Array.isArray(planTemplateForAddCase.templateTasks)) {
          planTemplateForAddCase.templateTasks = [
            ...planTemplateForAddCase.templateTasks,
            action.lineItemtemplatesTask
          ];
        }
      } else {
        const planTemplateForAddCase = newLineItemTemplatesTasksForAddCase.find((lineItemPlan) => !lineItemPlan.id);
        if (planTemplateForAddCase && Array.isArray(planTemplateForAddCase.templateTasks)) {
          planTemplateForAddCase.templateTasks = [
            ...planTemplateForAddCase.templateTasks,
            action.lineItemtemplatesTask
          ];
        } else {
          newLineItemTemplatesTasksForAddCase.push({
            id: undefined,
            templateTasks: [action.lineItemtemplatesTask]
          });
        }
      }
      return {
        ...state,
        lineItemTemplatesTasks: newLineItemTemplatesTasksForAddCase
      };
    case PLAN_TEMPLATES.ADD_UPDATE_LINE_ITEM_PLAN_TEMPLATES:
      const newLineItemTemplatesTasksForAddUpdatePlanTemplate = [...state.lineItemTemplatesTasks];
      const planTemplateForAddUpdatePlanTemplateIndex = newLineItemTemplatesTasksForAddUpdatePlanTemplate.findIndex(
        (lineItemPlan) => lineItemPlan.id === action.lineItemPlanTemplate.id
      );
      if (planTemplateForAddUpdatePlanTemplateIndex > -1) {
        // case update plan template
        newLineItemTemplatesTasksForAddUpdatePlanTemplate[planTemplateForAddUpdatePlanTemplateIndex] = {
          ...action.lineItemPlanTemplate
        };
      } else {
        // case add plan template
        const planTemplateForAddPlanTemplateIndex = newLineItemTemplatesTasksForAddUpdatePlanTemplate.findIndex(
          (lineItemPlan) => !lineItemPlan.id
        );
        newLineItemTemplatesTasksForAddUpdatePlanTemplate[planTemplateForAddPlanTemplateIndex] = {
          ...action.lineItemPlanTemplate
        };
      }
      return {
        ...state,
        lineItemTemplatesTasks: newLineItemTemplatesTasksForAddUpdatePlanTemplate
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const planTemplatesSelector = createSelector(
  (state) => state.planTemplates,
  (planTemplates) => planTemplates
);
//#endregion

//#region Sagas
export function* getPlanTemplatesSaga() {
  yield takeLatest([PLAN_TEMPLATES.GET], function* ({ dealerId }) {
    try {
      yield put(planTemplatesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));

      const planTemplates = yield getWithToken(`/api/ReconPlanTemplates/with-groups`, { dealerId: dealerId });

      yield put(
        planTemplatesActions.setPlanTemplates(planTemplates.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)))
      );
      yield put(planTemplatesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        yield put(planTemplatesActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      } else {
        yield put(planTemplatesActions.setFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getPlanTemplatesSaga', error);
      }
    }
  });
}

export function* updatePlanTemplatesSaga() {
  yield takeEvery(
    [PLAN_TEMPLATES.ADD, PLAN_TEMPLATES.UPDATE, PLAN_TEMPLATES.DELETE],
    function* ({ type: action, planTemplate, loadingMessage, successMessage, errorMessage }) {
      const loadingMessageId = uuidv4();
      try {
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0 })); // duration of 0 will show the message indefinitely

        const { data: currentPlanTemplates } = yield select(planTemplatesSelector);
        let newPlanTemplates;
        planTemplate.name = planTemplate.name.trim();
        switch (action) {
          case PLAN_TEMPLATES.ADD:
            const added = yield postWithToken(`/api/ReconPlanTemplates/with-groups`, planTemplate);

            newPlanTemplates = [...currentPlanTemplates, added];

            yield put(
              planTemplatesActions.setPlanTemplates(newPlanTemplates.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)))
            );

            yield patchLineItemForPlanTemplate(planTemplate, added);
            break;
          case PLAN_TEMPLATES.UPDATE:
            const updated = yield putWithToken(
              `/api/ReconPlanTemplates/id/${planTemplate.id}/with-groups`,
              planTemplate
            );

            const updatedIndex = currentPlanTemplates.findIndex((tt) => tt.id === updated.id);
            newPlanTemplates = [...currentPlanTemplates];
            newPlanTemplates[updatedIndex] = updated;
            yield put(
              planTemplatesActions.setPlanTemplates(newPlanTemplates.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)))
            );

            yield patchLineItemForPlanTemplate(planTemplate, updated);
            break;
          case PLAN_TEMPLATES.DELETE:
            yield deleteWithToken(`/api/ReconPlanTemplates/id/${planTemplate.id}`);
            newPlanTemplates = [...currentPlanTemplates.filter((tt) => tt.id !== planTemplate.id)];
            yield put(
              planTemplatesActions.setPlanTemplates(newPlanTemplates.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)))
            );
            break;
          default:
            devLogger.log(`Unrecognized action for updatePlanTemplatesSaga: ${action}`);
            break;
        }

        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // passing in the same key and duration will make the message go away after that duration time
        yield put(messagesActions.notify('success', successMessage, { duration: 2 }));
      } catch (error) {
        devLogger.log('failed updatePlanTemplatesSaga', error);
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // need to turn off loading before the error, because I'm latching on the messageType for isSaving prop in PlanTemplateDrawer.js
        if (
          action === PLAN_TEMPLATES.ADD &&
          error.response &&
          error.response.status === 400 &&
          error.response.data?.message?.includes('is removed from')
        ) {
          yield put(messagesActions.notify('error', error.response.data.message, { duration: 3.5 }));
        } else {
          yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
        }
      }
    }
  );
}

export function* bulkUpdatePlanTemplatesSaga() {
  yield takeEvery(PLAN_TEMPLATES.BULK_UPDATE, function* ({ planTemplates }) {
    try {
      const updateTemplate = function* (planTemplate) {
        return yield putWithToken(`/api/ReconPlanTemplates/id/${planTemplate.id}/with-groups`, planTemplate);
      };

      const updatedPlanTemplateResults = yield all(planTemplates.map((t) => fork(updateTemplate, t)));
      const updatedPlanTemplates = yield join([...updatedPlanTemplateResults]);

      const { data } = yield select(planTemplatesSelector);
      let newPlanTemplates = [...data];
      updatedPlanTemplates.forEach((item) => {
        const index = newPlanTemplates.findIndex((x) => x.id === item.id);
        newPlanTemplates = updateObjectInArray(newPlanTemplates, { index, item });
      });

      yield put(
        planTemplatesActions.setPlanTemplates(newPlanTemplates.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)))
      );
    } catch (error) {
      devLogger.log('failed bulkUpdatePlanTemplatesSaga', error);
    }
  });
}

function* patchLineItemForPlanTemplate(planTemplateLocal, planTemplateUpdated) {
  const listTemplateTasksLocal = flatTemplateTasks(planTemplateLocal);
  const listTemplateTasksUpdated = flatTemplateTasks(planTemplateUpdated);
  let lineItemPlanTemplate = {
    id: planTemplateUpdated.id,
    templateTasks: []
  };

  for (let i = 0; i < listTemplateTasksLocal.length; i++) {
    lineItemPlanTemplate.templateTasks.push({
      ...listTemplateTasksUpdated[i],
      lineItemTemplates:
        listTemplateTasksLocal[i].lineItemTemplates && !!listTemplateTasksLocal[i].lineItemTemplates.count
          ? [...listTemplateTasksLocal[i].lineItemTemplates.items]
          : []
    });
  }

  yield put(planTemplatesActions.addUpdatelineItemPlanTemplates(lineItemPlanTemplate));

  const requestBody = [];

  if (listTemplateTasksLocal.length !== listTemplateTasksUpdated.length) {
    return;
  } else {
    for (let i = 0; i < listTemplateTasksLocal.length; i++) {
      if (listTemplateTasksLocal[i].isAddingTask && listTemplateTasksLocal[i].id !== listTemplateTasksUpdated[i].id) {
        if (Array.isArray(listTemplateTasksLocal[i].lineItemTemplates.items)) {
          listTemplateTasksLocal[i].lineItemTemplates.items.forEach((item) => {
            requestBody.push({
              op: OPERATION_LINE_ITEM.ADD,
              value: {
                reconPlanTemplateTaskId: listTemplateTasksUpdated[i].id,
                preApprove: lineItemStatusTypes.APPROVED === item.status,
                id: '',
                description: item.description,
                laborRate: item.laborRate || 0,
                laborTime: item.laborTime || 0,
                laborCost: item.laborCost,
                partsCost: item.partsCost,
                totalCost: item.totalCost
              }
            });
          });
        }
      } else if (listTemplateTasksLocal[i].isChangeReconTaskType) {
        if (Array.isArray(listTemplateTasksLocal[i].lineItemTemplates.items)) {
          listTemplateTasksLocal[i].lineItemTemplates.items.forEach((item) => {
            requestBody.push({
              op: OPERATION_LINE_ITEM.ADD,
              value: {
                reconPlanTemplateTaskId: listTemplateTasksUpdated[i].id,
                preApprove: lineItemStatusTypes.APPROVED === item.status,
                id: '',
                description: item.description,
                laborRate: item.laborRate || 0,
                laborTime: item.laborTime || 0,
                laborCost: item.laborCost,
                partsCost: item.partsCost,
                totalCost: item.totalCost
              }
            });
          });
        }

        const lineItemsData = yield getWithToken(`/api/LineItemTemplates`, {
          reconPlanTemplateTaskId: listTemplateTasksUpdated[i].id
        });

        if (Array.isArray(lineItemsData?.items)) {
          lineItemsData.items.forEach((item) => {
            requestBody.push({
              op: OPERATION_LINE_ITEM.REMOVE,
              path: item.id,
              value: {
                reconPlanTemplateTaskId: item.reconPlanTemplateTaskId,
                preApprove: item.preApprove,
                id: item.id,
                description: item.description,
                laborRate: item.laborRate || 0,
                laborTime: item.laborTime || 0,
                laborCost: item.laborCost,
                partsCost: item.partsCost,
                totalCost: item.totalCost
              }
            });
          });
        }
      }
    }

    if (requestBody.length) {
      yield patchWithToken(`/api/LineItemTemplates`, requestBody);
    }
  }
}

const flatTemplateTasks = (planTemplate) => {
  const result = [];
  planTemplate.templateTaskGroups.forEach((taskGroup) => {
    taskGroup.templateTasks.forEach((templateTask) => {
      result.push(templateTask);
    });
  });
  result.sort((templateTaskA, templateTaskB) => templateTaskA.sequence - templateTaskB.sequence);
  return result;
};

const flatLineItemTemplateTasks = (planTemplates) => {
  const result = [];
  planTemplates.forEach((planTemplate) => {
    let templateTasksTemp = [...planTemplate.templateTasks];
    templateTasksTemp.forEach((task) => {
      task.lineItemTemplates = Array.isArray(task.lineItemTemplates)
        ? task.lineItemTemplates.map((lineItem) => updateStatusForLineItemTemplate(lineItem))
        : [];
    });
    result.push({
      id: planTemplate.id,
      templateTasks: templateTasksTemp
    });
  });
  return result;
};

export const getCountLineItemSelector = (planTemplateId, taskId) => {
  return createSelector(
    (state) => state.planTemplates.lineItemTemplatesTasks,
    (lineItemTemplatesTasks) => {
      let countNeedApprove = [];
      let countApproved = [];
      let countDeclined = [];

      const planTemplate = lineItemTemplatesTasks.find((lineItemPlan) => lineItemPlan.id === planTemplateId);

      if (planTemplate && Array.isArray(planTemplate.templateTasks) && !!planTemplate.templateTasks.length) {
        const lineItemTask = planTemplate.templateTasks.find((task) => task.id === taskId);

        if (lineItemTask && Array.isArray(lineItemTask.lineItemTemplates) && !!lineItemTask.lineItemTemplates.length) {
          countNeedApprove = lineItemTask.lineItemTemplates.filter((item) =>
            [lineItemStatusTypes.PENDING].includes(item.status)
          );
          countApproved = lineItemTask.lineItemTemplates.filter((item) =>
            [lineItemStatusTypes.APPROVED].includes(item.status)
          );
          countDeclined = lineItemTask.lineItemTemplates.filter((item) =>
            [lineItemStatusTypes.DECLINED].includes(item.status)
          );
        }
      }
      return { needsApproval: countNeedApprove.length, declined: countDeclined.length, approved: countApproved.length };
    }
  );
};
//#endregion
