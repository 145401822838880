import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Divider } from 'antd';
import { formatVehicleTitle, ewsEntityTypes, apiStatusConstants } from 'app-constants';
import { MapContainer, VehicleImage, CircularSpinner } from 'components';
import DetailPageVehicleInfo from 'components/common/DetailPageVehicleInfo';
import { CommonStrongText } from 'components/styledComponents';
import { theme as themeStyles } from 'styles/appTheme';
import { ReconTag, reconTagTypes } from 'components/tags';
import { Text } from './index';
import useVendorRole from 'hooks/useVendorRole';
import { vehiclesActions } from 'store/vehiclesStore';
import ProfitTimeMetal from 'components/common/ProfitTimeMetal';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { HQAndProfitTimeContext } from 'utils/contexts';

export const TaskDetailsHeader = withLDConsumer()(({ task, flags }) => {
  const { vehicle } = task;
  const vehicleTitle = formatVehicleTitle(vehicle);
  const colors = [...new Set([vehicle.baseColor, vehicle.color].filter((c) => c))];
  const dealer = useSelector((state) => state.dealers?.current?.data);
  const { isVendor } = useVendorRole(flags?.reconVendorManagement);
  const { vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const hasReconProfitTime = useContext(HQAndProfitTimeContext);
  const isEnterpriseOrContainerDealer =
    dealer.entityType === ewsEntityTypes.ENTERPRISE || dealer.entityType === ewsEntityTypes.CONTAINER;
  const showDealerName = isVendor || (isEnterpriseOrContainerDealer && flags?.ireconHqView);
  const vehicleProfitTime = useSelector((state) => state.vehicles.vehicleProfitTime);
  const vehicleProfitTimeFetchStatus = useSelector((state) => state.vehicles.vehicleProfitTimeFetchStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!(isVendor && !vendorShipSelected) && hasReconProfitTime && vehicle?.id && vehicle?.dealerId) {
      dispatch(vehiclesActions.getVehicleProfitTime(vehicle.id, vehicle.dealerId));
    }
  }, [isVendor, hasReconProfitTime, vehicle?.id, vehicle?.dealerId]);

  useEffect(() => {
    return () => {
      if (!isVendor && vehicleProfitTime && !!Object.keys(vehicleProfitTime).length) {
        //clear profit time when dismounting
        dispatch(vehiclesActions.setVehicleProfitTime({}));
        dispatch(vehiclesActions.setVehicleProfitTimeFetchStatus(apiStatusConstants.PENDING));
      }
    };
  }, []);

  return (
    <StyledTaskDetailsHeaderContainer>
      <StyledImageContainer>
        <VehicleImage
          src={vehicle?.heroImageUrl}
          nonMarketingSrc={vehicle?.nonMarketingHeroImageUrl}
          fallbackSrc={vehicle?.appraisalHeroImageUrl}
          isHeroOrAppraisal
          imageQualityWidth={640} // This determines the image quality we pull back from the image api, setting it to twice the size of the box
          imageQualityHeight={356}
          imageStyle={{ minWidth: '320px', height: '178px' }}
          noImageStyle={{
            width: '320px',
            height: '178px',
            borderWidth: '0 0.5px 0.5px 0',
            borderStyle: 'solid',
            borderColor: themeStyles.colors.borderGray
          }}
        />
      </StyledImageContainer>
      <StyledDescriptionContainer reconProfitTime={hasReconProfitTime}>
        {hasReconProfitTime ? (
          <StyledNameTagContainer>
            <StyledVehicleName reconProfitTime={hasReconProfitTime}>{vehicleTitle}</StyledVehicleName>
            {vehicleProfitTimeFetchStatus === apiStatusConstants.IS_FETCHING ? (
              <CircularSpinner />
            ) : (
              <ProfitTimeMetal profitTimeScore={vehicleProfitTime} />
            )}
          </StyledNameTagContainer>
        ) : (
          <StyledVehicleName reconProfitTime={hasReconProfitTime}>{vehicleTitle}</StyledVehicleName>
        )}
        {!hasReconProfitTime && <DetailPageVehicleInfo vehicle={vehicle} colors={colors} isForTDP={true} />}
        <Divider style={{ margin: hasReconProfitTime ? '13px 0px 5px 0px' : '4px 0' }} />
        {!hasReconProfitTime && <Text style={{ lineHeight: 'normal', display: 'block' }}>{vehicle.dealerName}</Text>}
        {!hasReconProfitTime && vehicle?.excludedOn && (
          <StyledExcludedReconTag type={reconTagTypes.EXCLUDED} vehicle={vehicle} />
        )}
        {/* vehicle can only be excluded if they have no plan - probably will never show*/}
        {hasReconProfitTime && (
          <DetailPageVehicleInfo
            vehicle={vehicle}
            colors={colors}
            reconProfitTime={hasReconProfitTime}
            showDealerName={showDealerName}
            isForTDP={true}
          />
        )}
      </StyledDescriptionContainer>
      <StyledMapPlaceHolder reconProfitTime={hasReconProfitTime}>
        <MapContainer vehicleLocations={vehicle.locations} />
      </StyledMapPlaceHolder>
    </StyledTaskDetailsHeaderContainer>
  );
});

const StyledTaskDetailsHeaderContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  min-width: 0;
`;
const StyledImageContainer = styled.div`
  position: relative;
`;
const StyledDescriptionContainer = styled.div`
  margin-left: ${({ reconProfitTime }) => (reconProfitTime ? '12px' : '24px')};
  min-width: 0;
  flex: 1 1 0px;
  ${({ reconProfitTime }) => (reconProfitTime ? 'display: flex; flex-direction: column;' : '')}
`;
const StyledVehicleName = styled(CommonStrongText).attrs({
  ellipsis: true
})`
  margin-top: ${({ reconProfitTime }) => (reconProfitTime ? '0px' : '16px')};
  font-size: ${({ theme, reconProfitTime }) => (reconProfitTime ? theme.fontSizes.lg : theme.fontSizes.xlg)};
  line-height: 25px;
`;
const StyledExcludedReconTag = styled(ReconTag)`
  .ant-tag& {
    width: 80px;
    margin-top: 4px;
    margin-left: 0;
  }
`;
const StyledMapPlaceHolder = styled.div.attrs({
  className: 'center-content'
})`
  width: 360px;
  margin: 0px 5px 0px ${({ reconProfitTime }) => (reconProfitTime ? '12px' : '15px')};
  border: none;
  position: relative;
`;
const StyledNameTagContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
`;
