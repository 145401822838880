//using devLogger will ensure that your coded logs only happen in dev environment

//acts as a gatekeeper, ensuring that the environment is dev
const inDevEnv = process.env.NODE_ENV === 'development';

//takes a method string and invokes the appropriate console method with the args that were passed into devLogger[consoleMethod] by dev user
const loggingFunction = (consoleMethod, ...args) => {
  console[consoleMethod](...args);
};

export const devLogger = {
  assert: (...args) => inDevEnv && loggingFunction('assert', ...args),
  count: (...args) => inDevEnv && loggingFunction('count', ...args),
  debug: (...args) => inDevEnv && loggingFunction('debug', ...args),
  error: (...args) => inDevEnv && loggingFunction('error', ...args),
  info: (...args) => inDevEnv && loggingFunction('info', ...args),
  log: (...args) => inDevEnv && loggingFunction('log', ...args),
  table: (...args) => inDevEnv && loggingFunction('table', ...args),
  timeStamp: (...args) => inDevEnv && loggingFunction('timeStamp', ...args),
  trace: (...args) => inDevEnv && loggingFunction('trace', ...args),
  warn: (...args) => inDevEnv && loggingFunction('warn', ...args)
};
