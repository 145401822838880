import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { Typography } from 'antd';
import { HQAndProfitTimeContext, useDragDropContext } from 'utils/contexts';
//our components, contexts, functions, types, etc
import {
  addEventListenerForScrollContainer,
  removeEventListenerForScrollContainer
} from './helpers/scrollFunctionality';
import { COMPLETED } from 'app-constants/taskStatusTypes';
import { RECON_PLAN } from 'app-constants/planTypes';
import { DRAGGABLE_ITEM, DROP_PLACEHOLDER, MAIN, TASK_GROUP, TASK_GROUP_TASK, TASK } from './helpers/types';
import { modelType, draggableItemType } from './helpers/propTypes';
import { isStepCompleted } from './helpers/functions';
import { getPerformDropOperationsToggle, setPerformDropOperationsToggle } from './helpers/dndFunctions';
import Task from './Task/Task';
import DropPlaceholder from './DropPlaceholder';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { features } from 'app-constants';
import { useFeatures } from 'hooks';

const { Text } = Typography;

/**
 * DraggableItem - container for draggable tasks and task groups - sets up logic for the rendered UI containers so that they can be draggable
 * component wrapper for either task groups, task group tasks or tasks
 * id, sequence, parentSequence, reconTaskTypeName, parentGroupId are step data
 * tasks is an array of tasks for each step - if it is a single task, then there will only be one in the array
 * modelType is for UI structure determination
 * isRenderedForDragging - set in CustomDragLayer component to determine that the DraggableItem is rendered for the dragging animation tracking
 * index is just the rendered order index from the map function in DragList
 * isInProgressStep is determined in DragList - denotes the step that is in progress
 * expand will only ever have a value if the DraggableItem is rendered in a task group draggable item because its value is determined from the useDrop within DraggableItem. see the code below for more information on it
 * draggingOverTaskGroupPropFromTaskGroup - need to have this prop to differentiate from the observations that the useDrop function makes in every DraggableItem instance - only passed down form a task group component instance
 * draggedItemId and setDraggedItemId - used for knowing if a drop placeholders associated task item is being dragged - if the useDrag function returns true for isDragging, then the draggedItemId is set to this particular step/task's id
 * restOfProps includes other task properties
 */
const DraggableItem = ({
  id,
  index,
  sequence,
  parentSequence,
  tasks,
  reconTaskTypeName,
  passthrough,
  modelType,
  isRenderedForDragging,
  parentGroupId,
  moveItem,
  isInProgressTaskGroup,
  expand,
  draggingOverTaskGroupPropFromTaskGroup, //need to have this prop to differentiate from the observations that the useDrop function makes in every DraggableItem instance - only passed down form a task group component instance
  draggedItemId,
  setDraggedItemId,
  isFirstInList,
  ...restOfProps
}) => {
  const { scrollContainer, isSaving, planSource, taskGroups, vehicle } = useDragDropContext();

  const taskElementRef = useRef();
  const { isHQ } = useContext(HQAndProfitTimeContext);
  //needed for determining whether a drag can happen and whether a drop can happen
  const reconStepIsCompleted = planSource === RECON_PLAN && isStepCompleted({ ...restOfProps, tasks, modelType });
  const [hasInventoryEdit] = useFeatures(features.INVENTORY_EDIT);
  const canDrag = hasInventoryEdit;
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DRAGGABLE_ITEM,
      index,
      id,
      sequence,
      parentSequence,
      modelType,
      parentGroupId,
      tasks,
      reconTaskTypeName,
      passthrough,
      ...restOfProps
    },

    begin: (_e) => {
      //on the beginning of any drag, set performDropOperationsToggle to be true so that dropping can occur
      setPerformDropOperationsToggle(true);
      addEventListenerForScrollContainer(scrollContainer);
    },
    end: (_e) => {
      setDraggedItemId(null);
      removeEventListenerForScrollContainer();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !isSaving && !reconStepIsCompleted && !isHQ && canDrag //once a standalone task or entire group has been completed, you can no longer drag it
  });

  // for determining whether a drop can occur on a task outside of a task group
  const determineDropOnATask = (draggedItemData) => {
    //if there is no draggedItemData or the plan is saving, disable drop
    if (!draggedItemData || isSaving) return false;
    //we can't drop a task group on top of a task, but we can drop either a task group task or a task onto another task
    //only types of modelTypes for draggable items are TASK_GROUP, TASK and TASK_GROUP_TASK
    //can't drop on itself (draggedItemData.id !== id), and can't drop a task onto a task group task (parentGroupId === MAIN)
    return (
      !reconStepIsCompleted &&
      draggedItemData.id !== id &&
      draggedItemData.modelType !== TASK_GROUP &&
      modelType === TASK &&
      parentGroupId === MAIN
    );
  };

  // for determining whether a drop can occur on a task group when not onto a drop placeholder
  // drops of this kind can occur on either a task group container or a task group task
  // when it is on a task group, it is most likely that the user dropped a task on the header for the task group
  // when it is on a task group task, it's on a task group task
  const determineDropOnATaskGroup = (draggedItemData) => {
    //if there is no draggedItemData or the plan is saving, disable drop
    if (!draggedItemData || isSaving) return false;
    //alternatively, we can drag a TASK type DraggableItem on top of a TASK_GROUP or a TASK_GROUP_TASK and drop it then
    if (
      !reconStepIsCompleted &&
      [TASK_GROUP_TASK, TASK_GROUP].includes(modelType) &&
      draggedItemData.modelType === TASK
    )
      return true;
    // or a TASK_GROUP_TASK from one task group to another task group (draggedItemData.parentGroupId !== id && draggedItemData.parentGroupId !== parentGroupId)
    return (
      !reconStepIsCompleted &&
      [TASK_GROUP_TASK, TASK_GROUP].includes(modelType) &&
      draggedItemData.modelType === TASK_GROUP_TASK &&
      draggedItemData.parentGroupId !== id &&
      draggedItemData.parentGroupId !== parentGroupId
    );
  };

  //for adding a different CSS state when a task is dragged over a task group
  const determineIfDraggingTaskOverTaskGroup = (draggedItemData) => {
    if (!draggedItemData || isSaving) return false;
    //want to add the animation tied to this state when the dragged item is a single task and it is being dragged over a task group
    if (
      !reconStepIsCompleted &&
      draggedItemData.id !== id &&
      draggedItemData.modelType === TASK &&
      modelType === TASK_GROUP &&
      parentGroupId === MAIN
    )
      return true;
    //or when the dragged item is a task from another task group and it is being dragged over a different task group than the one it currently belongs to
    return (
      !reconStepIsCompleted &&
      draggedItemData.parentGroupId !== MAIN &&
      draggedItemData.parentGroupId !== parentGroupId &&
      draggedItemData.parentGroupId !== id &&
      draggedItemData.modelType === TASK_GROUP_TASK &&
      modelType === TASK_GROUP
    );
  };

  const [{ draggingTaskOverAnotherTask, draggingTaskOverTaskGroup }, drop] = useDrop({
    accept: DRAGGABLE_ITEM,
    //performDropOperationsToggle is checked here to make sure that for any actual drop only one drop event is allowed
    canDrop: (draggedItemEventData, _monitor) =>
      getPerformDropOperationsToggle() &&
      (determineDropOnATask(draggedItemEventData) || determineDropOnATaskGroup(draggedItemEventData)),
    drop: (e, _monitor) => {
      const droppedOnItemData = {
        index,
        id,
        sequence,
        parentSequence,
        modelType,
        parentGroupId,
        tasks,
        reconTaskTypeName,
        passthrough
      };
      const { type, ...draggedItemData } = e; // striping out type bc we don't need it
      moveItem(draggedItemData, droppedOnItemData);
      // the drop placeholder or task group task's drop event runs prior to the task group task's drop event
      // to prevent propagation of the drop event to a task group, once the task group task's drop event has ran and the taskGroups have been updated to the correct order of tasks, set the toggle for allowing drop operations to be false
      setPerformDropOperationsToggle(false);
    },
    collect: (monitor) => ({
      draggingTaskOverAnotherTask: determineDropOnATask(monitor.getItem()) && !!monitor.isOver(),
      draggingTaskOverTaskGroup: determineIfDraggingTaskOverTaskGroup(monitor.getItem()) && !!monitor.isOver()
    })
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true }); // this removes the default preview
  }, [preview, getEmptyImage]);

  useEffect(() => {
    isDragging && setDraggedItemId(id);
  }, [isDragging, id]);

  return (
    <StyledDraggableContainer
      className="draggable-contianer"
      ref={(node) => drag(drop(node))}
      isDragging={isDragging}
      expand={draggingTaskOverAnotherTask}
      draggingOverTaskGroupInternal={draggingTaskOverTaskGroup}
      draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupPropFromTaskGroup} // this will only be passed down from a task group container
      taskElementRef={taskElementRef}
      isFirstInList={isFirstInList}
      reconStepIsCompleted={reconStepIsCompleted}
      isTaskGroupTask={modelType === TASK_GROUP_TASK} //is a single task that is part of a task group - is not a task group
      isRenderedForDragging={isRenderedForDragging}
      isTaskGroupContainer={modelType === TASK_GROUP}
      scrollContainer={scrollContainer}
      parentGroupId={parentGroupId}
      isTask={modelType === TASK}
    >
      {/* is not part of a task group - is a single task */}
      {modelType === TASK_GROUP ? (
        <TaskGroup
          id={id}
          sequence={sequence}
          tasks={tasks}
          moveItem={moveItem}
          isInProgressTaskGroup={isInProgressTaskGroup}
          expand={draggingTaskOverAnotherTask}
          draggingOverTaskGroupInternal={draggingTaskOverTaskGroup}
          draggedItemId={draggedItemId}
          setDraggedItemId={setDraggedItemId}
          reconStepIsCompleted={reconStepIsCompleted}
          {...restOfProps}
        />
      ) : (
        <Task
          taskElementRef={taskElementRef}
          id={id}
          isTask={modelType === TASK} // draggable item is not part of a task group - it is a single task
          isInProgressTaskGroup={isInProgressTaskGroup}
          task={{
            ...restOfProps,
            id,
            reconTaskTypeName,
            sequence,
            parentGroupId,
            parentSequence,
            modelType,
            passthrough
          }} // task information
          isDragging={isDragging}
          expand={draggingTaskOverAnotherTask || expand}
          draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupPropFromTaskGroup}
          isRenderedForDragging={isRenderedForDragging}
          taskGroups={taskGroups}
          vehicle={vehicle}
        />
      )}
    </StyledDraggableContainer>
  );
};

DraggableItem.propTypes = {
  id: PropTypes.string, // from task item information
  index: PropTypes.number, // from map function that is rendering a list of task items
  sequence: PropTypes.number, // from task item information
  parentSequence: PropTypes.number, // from task item information
  tasks: PropTypes.arrayOf(draggableItemType), // full list of tasks - passed so that we can properly calculate count
  reconTaskTypeName: PropTypes.string, // from task item information
  passthrough: PropTypes.bool, // from task item information
  modelType, // from DndContainer function setStructureForRemainingSourceTasks
  isRenderedForDragging: PropTypes.bool, // from CustomDragLayer component - when PreviewDraggableItem is rendered it renders a DraggableItem component instance and this property is set to true on that instance
  parentGroupId: PropTypes.string, // from DndContainer function setStructureForRemainingSourceTasks
  moveItem: PropTypes.func // DndContainer moveItem function
};

//presentation UI container for draggable task groups
const TaskGroup = withLDConsumer()(
  ({
    tasks,
    sequence,
    moveItem,
    expand,
    draggingOverTaskGroupInternal,
    isInProgressTaskGroup,
    draggedItemId,
    setDraggedItemId,
    reconStepIsCompleted,
    flags
  }) => {
    const completedTasks = tasks.filter((task) => task.status === COMPLETED);
    const incompleteTasks = tasks.filter((task) => task.status !== COMPLETED);
    return (
      <StyledTaskGroup reconStepIsCompleted={reconStepIsCompleted} reconTaskCollapseFlag={flags.reconTaskCollapse}>
        <GroupHeader draggingOverTaskGroupInternal={draggingOverTaskGroupInternal} headerText="Task Group" />
        {completedTasks.map((task, index) => (
          <DraggableItem
            key={task.id}
            index={index}
            parentSequence={sequence}
            {...task}
            moveItem={moveItem}
            draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupInternal}
            draggedItemId={draggedItemId}
            setDraggedItemId={setDraggedItemId}
            isInProgressTaskGroup={isInProgressTaskGroup}
          />
        ))}
        {incompleteTasks.map((task, index) =>
          task.modelType === DROP_PLACEHOLDER ? (
            <DropPlaceholder
              key={task.id}
              index={index}
              draggedItemId={draggedItemId}
              draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupInternal}
              {...task}
              moveItem={moveItem}
            />
          ) : (
            <DraggableItem
              key={task.id}
              index={index}
              parentSequence={sequence}
              {...task}
              draggedItemId={draggedItemId}
              setDraggedItemId={setDraggedItemId}
              isInProgressTaskGroup={isInProgressTaskGroup}
              draggingOverTaskGroupPropFromTaskGroup={draggingOverTaskGroupInternal}
              expand={expand}
              moveItem={moveItem}
            />
          )
        )}
      </StyledTaskGroup>
    );
  }
);

TaskGroup.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      modelType,
      parentGroupId: PropTypes.string
    })
  ),
  sequence: PropTypes.number,
  moveItem: PropTypes.func
};

const GroupHeader = ({ headerText, draggingOverTaskGroupInternal }) => (
  <StyledGroupHeader draggingOverTaskGroupInternal={draggingOverTaskGroupInternal}>
    <Text>{headerText}</Text>
  </StyledGroupHeader>
);

GroupHeader.propTypes = {
  headerText: PropTypes.string
};

const StyledDraggableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ isRenderedForDragging, isTaskGroupTask, expand, draggingOverTaskGroupInternal, isTask, theme }) => {
    if (isRenderedForDragging || expand || draggingOverTaskGroupInternal) {
      return `1px solid ${theme.colors.lightBlueLinkColor}`;
    } else if (isTaskGroupTask || isTask) {
      return 'none';
    } else {
      return '1px solid #dddedf';
    }
  }};

  border-width: ${({ isTaskGroupContainer, draggingOverTaskGroupInternal }) =>
    !isTaskGroupContainer || draggingOverTaskGroupInternal ? '2px' : '1px'};
  border-radius: ${({ isTaskGroupTask }) => (!isTaskGroupTask ? 4 : 0)}px;
  background-color: ${({ expand, draggingOverTaskGroupInternal, draggingOverTaskGroupPropFromTaskGroup, theme }) => {
    if (expand || draggingOverTaskGroupInternal) {
      return 'rgba(43,107,221,0.1)';
    } else if (draggingOverTaskGroupPropFromTaskGroup) {
      return theme.colors.transparent;
    }
    return theme.colors.white;
  }};
  max-width: ${({ isRenderedForDragging, scrollContainer }) => {
    if (isRenderedForDragging) {
      //when a draggable item's prop isRenderedForDragging is true, this is when the component is rendered for dragging
      const { width } = scrollContainer?.current?.getBoundingClientRect();
      return `${width - 32}px`; //the - 32 is because of padding
    }
    return 'unset';
  }};
  min-height: ${({ expand, taskElementRef }) => {
    if (expand && taskElementRef?.current) {
      return taskElementRef.current.clientHeight + 50;
    } else {
      return 0;
    }
  }}px;
  max-height: ${({ isDragging, isRenderedForDragging }) => {
    const maxHeightNotForDragging = isDragging ? 50 : 1000;
    return isRenderedForDragging ? 1000 : maxHeightNotForDragging;
  }};
  transition: border 0.25s;
  transition: background-color 0.25s;
  transition: min-height 0.25s, max-height 0.25s;
  transition-delay: ${({ expand }) => (expand ? '0.5s' : '0s')};
  opacity: ${({ isDragging, isRenderedForDragging }) => {
    const opacityNotForDragging = isDragging ? 0 : 1;
    return isRenderedForDragging ? 1.0 : opacityNotForDragging;
  }};
  margin-top: ${({ reconStepIsCompleted, isRenderedForDragging }) =>
    !isRenderedForDragging && reconStepIsCompleted && '8px'};
  position: ${({ parentGroupId, isRenderedForDragging }) =>
    parentGroupId === MAIN && !isRenderedForDragging && 'relative'};
  cursor: ${({ reconStepIsCompleted }) => !reconStepIsCompleted && 'grab'};
`;

const StyledGroupHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ draggingOverTaskGroupInternal, theme }) =>
    !draggingOverTaskGroupInternal ? '#F5F6F7' : theme.colors.transparent};
  height: 44px;
  color: #002950;
  padding-left: 16px;
  border-bottom: ${({ draggingOverTaskGroupInternal }) =>
    !draggingOverTaskGroupInternal ? '1px solid #CFCFCF' : 'none'};
  font-size: 14px;
  font-weight: 500;
`;

const StyledTaskGroup = styled.div`
  .draggable-contianer:last-child {
    margin-bottom: ${({ reconStepIsCompleted, reconTaskCollapseFlag }) =>
      reconStepIsCompleted && reconTaskCollapseFlag && '16px'};
  }
`;
export default withLDConsumer()(DraggableItem);
