import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useVendorRole from './useVendorRole';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

export const useTaskReadOnlyStatus = (taskId, reconVendorManagementFlag = false) => {
  const { accessibleDealers } = useSelector((state) => state.userSettings);
  const items = useSelector((state) => state.tasks.data?.items);
  const { isVendor } = useVendorRole(reconVendorManagementFlag);
  const { isRootUser } = useSelector(rootEntitySwitcherSelector);
  const [isReadonlyTask, setIsReadonlyTask] = useState(false);

  // Task will be readonly if it is not belong to accessible dealers (in vendor view)
  useEffect(() => {
    if (taskId && isVendor && !isRootUser) {
      const task = items?.find((t) => t.id === taskId);
      if (!accessibleDealers?.length) {
        setIsReadonlyTask(true);
      } else if (accessibleDealers?.length && task?.vehicle) {
        const isTaskNotBelongToAccessibleDealers = !accessibleDealers.some((d) => d.id === task.vehicle.dealerId);
        setIsReadonlyTask(isTaskNotBelongToAccessibleDealers);
      } else if (isReadonlyTask) {
        setIsReadonlyTask(false);
      }
    }
  }, [taskId, items, accessibleDealers, isVendor, reconVendorManagementFlag]);

  return isReadonlyTask;
};
