import React, { useContext } from 'react';
import { Typography } from 'antd';
import { BodySmall } from 'components/styledComponents';
import styled from 'styled-components';

import { TaskContext } from '../helpers/context';
import { formatPrice } from 'utils/numberUtils';
import { RECON_PLAN } from 'app-constants/planTypes';

const { Text } = Typography;

/**
 * CostContent
 * HOC component for rendering cost information component for either recon task or template task
 * branching logic determines which - based on planSource which is set in DndData and passed into TaskContext
 */
const CostContent = ({ reconTaskCollapseFlag = false }) => {
  /**
   * planSource is either RECON_PLAN or TEMPLATE_PLAN and is set in ReconPlan and TemplatePlan component files
   */
  const { planSource } = useContext(TaskContext);
  return planSource === RECON_PLAN ? (
    <CostContentForReconTask reconTaskCollapseFlag={reconTaskCollapseFlag} />
  ) : (
    <CostContentForTemplateTask reconTaskCollapseFlag={reconTaskCollapseFlag} />
  );
};

/**
 * CostContentForReconTask
 * cost content component for recon task
 * either shows final cost if the task is completed (determined by checking task.completedOn)
 * or estimated cost if the task is not completed
 */
const CostContentForReconTask = ({ reconTaskCollapseFlag }) => {
  // task is the task dto that is passed in as a prop in a Task component instance
  const { task } = useContext(TaskContext);

  return reconTaskCollapseFlag ? (
    <BodySmall>
      {task.completedOn ? (
        <CostText ellipsis completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
          Final Cost: {formatPrice(task.completedCost || null)}
        </CostText>
      ) : (
        <CostText ellipsis completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
          Est. Cost: {formatPrice(task.estimatedCost || null)}
        </CostText>
      )}
    </BodySmall>
  ) : (
    <FinalOrEstCostContent task={task} reconTaskCollapseFla={reconTaskCollapseFlag} />
  );
};

const FinalOrEstCostContent = ({ task, reconTaskCollapseFlag }) =>
  task.completedOn ? (
    <CostText ellipsis completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
      Final Cost: {formatPrice(task.completedCost || null)}
    </CostText>
  ) : (
    <CostText ellipsis completed={task.completedOn} recon-task-collapse-flag={reconTaskCollapseFlag ? 1 : 0}>
      Est. Cost: {formatPrice(task.estimatedCost || null)}
    </CostText>
  );

/**
 * CostContentForTemplateTask
 * cost content component for template task
 * shows estimated cost for template task in a template plan
 */
const CostContentForTemplateTask = ({ reconTaskCollapseFlag }) => {
  // task - task dto passed as a prop to Task component instance
  const { task } = useContext(TaskContext);

  return reconTaskCollapseFlag ? (
    <BodySmall>
      <CostText recon-task-collapse-flag={1} ellipsis>
        Est. Cost: {formatPrice(task.estimatedCost)}
      </CostText>
    </BodySmall>
  ) : (
    <CostText recon-task-collapse-flag={0} ellipsis>
      Est. Cost: {formatPrice(task.estimatedCost)}
    </CostText>
  );
};
const CostText = styled(Text).attrs((props) => ({
  ellipsis: !props['recon-task-collapse-flag']
}))`
  line-height: ${(props) => !props['recon-task-collapse-flag'] && '1.9'};
  .ant-typography& {
    color: ${(props) => props['recon-task-collapse-flag'] && props.theme.colors.darkGray};
  }
`;

export default CostContent;
