import React, { useState } from 'react';
import moment from 'moment';
import { Card, Typography, Menu, Icon, Dropdown } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { maxBy } from 'lodash';
import { Stack } from 'components';
import { formatPrice } from 'utils/numberUtils';
import { displayDurationSummary, getMomentDurationFromString } from 'utils/dateTimeUtils';
import { StyledCircleIcon, StyledIcon, StyledMoreIcon, StyledSettingsCard } from '../common/Styles.js';
import { faEllipsisH, faClipboardList } from '@fortawesome/free-solid-svg-icons';
const { Text } = Typography;

const PlanTemplateCard = ({ planTemplate, onDelete, onEdit, flags }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const moreMenu = (
    <Dropdown display='flex'
      onVisibleChange={() => setMenuOpen(!menuOpen)}
      overlay={
        <Menu onClick={({ key }) => {
          setMenuOpen(false);
          switch (key) {
            case 'edit':
              onEdit(planTemplate);
              break;
            case 'delete':
              onDelete(planTemplate);
              break;
            default:
              devLogger.log(`Unexpected response: ${key}. Canceled Task action`);
              break;
          }
        }}>
          <Menu.Item key='edit'>
            <Icon type='edit' style={{ marginRight: '5px' }} />
              Edit
            </Menu.Item>
          <Menu.Item key='delete'>
            <Icon type='delete' style={{ marginRight: '5px' }} />
              Delete
            </Menu.Item>
        </Menu>
      }>
      <StyledMoreIcon icon={faEllipsisH} data-selected={menuOpen} />
    </Dropdown>
  );

  const planTemplateIcon = (
    <StyledCircleIcon>
      <StyledIcon icon={faClipboardList} />
    </StyledCircleIcon>
  );

  const numberOfSteps = planTemplate?.templateTaskGroups?.length;
  const numberOfStepsText = `${numberOfSteps} Step${numberOfSteps > 1 ? 's' : ''}`;

  const emDash = String.fromCharCode(8212);
  const totalCost = planTemplate.templateTasks.every(t => t.estimatedCost == null)
    ? emDash
    : formatPrice(planTemplate.templateTasks.reduce((total, t) => total + (t.estimatedCost || 0), 0));

  let totalTime = displayDurationSummary(planTemplate?.templateTaskGroups?.reduce((acc, tg) => {
    let maxTimeTaskInGroup = maxBy(tg.templateTasks, t => getMomentDurationFromString(t.goalThreshold));
    return acc.add(getMomentDurationFromString(maxTimeTaskInGroup.goalThreshold));
  }, moment.duration(0)), undefined, true, undefined, emDash);

  return (
    <StyledSettingsCard>
      <Card.Meta avatar={planTemplateIcon} />
      <Stack grow horizontal divStyle={{ minWidth: '1px' }}>
        <Stack grow divStyle={{ minWidth: '1px' }}>
          <Text ellipsis className='strong medium-font' style={{ marginBottom: '3px' }}>{planTemplate.name}</Text>
          <Text>{numberOfStepsText}</Text>
          <Text>Est. Cost: {totalCost}</Text>
          <Text>Est. Time: {totalTime}</Text>
        </Stack>
        <Stack divStyle={{ marginLeft: '5px' }}>
          {moreMenu}
        </Stack>
      </Stack>
    </StyledSettingsCard>
  );
};

export default withLDConsumer()(PlanTemplateCard);