//type for permitted dropping in the DndContainer component
export const DRAGGABLE_ITEM = 'draggableItem';

// task item modelType types
export const DROP_PLACEHOLDER = 'dropPlaceholder';
export const TASK_GROUP = 'taskGroup';
export const TASK_GROUP_TASK = 'taskGroupTask';
export const TASK = 'task';

//parent group id types
// MAIN is the top level where both task groups and tasks can be dropped
export const MAIN = 'main';
// nothing else is a constant for parent group ids because each are randomly created with uuid library
