import React from 'react';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BasicLayeredTaskIcon, BaseHoverContent } from 'components/layout/tasks/icons';
import { taskProgressLabels } from 'app-constants';
import { PENDING, DECLINED, IN_PROGRESS } from 'app-constants/taskStatusTypes';
import { TaskDeclinedIcon } from '.';

const TaskSequenceIcon = ({ task, isTask, progress, expand, flags, customContent, ...other }) => {
  // when new data has task status is IN_PROGRESS but task declinedOn
  if (task.declined || task.status === DECLINED) {
    return <TaskDeclinedIcon customContent={customContent} task={task} {...other} />;
  }
  // If this task is pending and Overdue or Past Goal, we want to show the regular
  // clipboard icon, but still show the progress warning in the popover content
  const hoverContent =
    !!progress &&
    task.status === PENDING &&
    [taskProgressLabels.PastGoal, taskProgressLabels.Overdue].indexOf(progress?.status) > -1 ? (
      <>
        <StyledHoverContentHeader>{progress.info}</StyledHoverContentHeader>
        <BaseHoverContent task={task} showLeftBorder={true} />
      </>
    ) : undefined; // Setting to undefined causes it to use default hoverContent defined on BasicLayeredTaskIcon

  const CustomContent = (props) => {
    const sequenceNumber = isTask ? task?.group?.sequence || task?.sequence : task?.parentSequence;
    return (
      <>
        {task && (
          <StyledSequence
            expand={expand}
            {...props}
            status={task.status}
            recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0}
          >
            {sequenceNumber}
            {flags.reconTaskCollapse && '.'}
          </StyledSequence>
        )}
      </>
    );
  };

  return (
    <BasicLayeredTaskIcon
      task={task}
      customContent={customContent || <CustomContent />}
      customHoverContent={hoverContent}
      {...other}
    />
  );
};

const StyledSequence = styled.div`
  height: 25px;
  width: ${(props) => !props['recon-task-collapse-flag'] && '25px'};
  padding-right: ${(props) => props['recon-task-collapse-flag'] && '3px'};
  border-radius: 50%;
  border: ${(props) => !props['recon-task-collapse-flag'] && `1px solid ${props.theme.colors.navy}`};
  background-color: ${(props) => {
    if (props.expand) {
      return props.theme.colors.transparent;
    } else {
      if (props['status'] === IN_PROGRESS) {
        return !props['recon-task-collapse-flag'] && props.theme.colors.navy;
      } else {
        return !props['recon-task-collapse-flag'] && props.theme.colors.white;
      }
    }
  }};
  text-align: ${(props) => !props['recon-task-collapse-flag'] && 'center'};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${(props) =>
    props['recon-task-collapse-flag'] ? props.theme.fontWeights.medium : props.theme.fontWeights.bold};
  color: ${(props) => {
    if (props['status'] === IN_PROGRESS) {
      return !props['recon-task-collapse-flag'] && props.theme.colors.white;
    } else {
      return props.theme.colors.navy;
    }
  }};
`;
const StyledHoverContentHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.red};
`;

export default withLDConsumer()(TaskSequenceIcon);
