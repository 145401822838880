import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Typography, Icon, Menu, Dropdown } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { formatPrice } from 'utils/numberUtils';
import { TaskSequenceIcon } from 'components/layout/tasks/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { GridTemplate, GridArea } from 'components/styledComponents';
const { Text } = Typography;

const TemplateTask = ({ index, planTemplateId, planTemplateTask, count, onUpdate, onDelete, setFormProps, scrollToTask = false, isSaving = false }) => {
  const taskRef = useRef(null);

  useEffect(() => {
    if (scrollToTask && !isSaving && taskRef.current) {
      scrollToTaskAndHighlight();
    }
  }, []);

  const scrollToTaskAndHighlight = () => {
    taskRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    setTimeout(() => {
      if (taskRef.current) {                                                // this does some box shadow animation
        taskRef.current.classList.add('selected-background-color');
        setTimeout(() => {
          if (taskRef.current) {
            taskRef.current.classList.remove('selected-background-color');
          }
        }, 500);
      }
    }, 1000);
  }

  const moreMenu = (
    <Menu onClick={({ key }) => {
      switch (key) {
        case 'edit':
          setFormProps({
            parentId: planTemplateId,
            taskToUpdate: planTemplateTask,
            count: count,
            onUpdate
          });
          break;
        case 'delete':
          onDelete(planTemplateTask);
          break;
        default:
          devLogger.error(new Error(`Unexpected menu key ${key}`));
      }
    }}>
      <Menu.Item key='edit'>
        <Icon type='edit' style={{ marginRight: '5px' }} />
        Edit
      </Menu.Item>
      <Menu.Item key='delete'>
        <Icon type='delete' style={{ marginRight: '5px' }} />
        Delete
      </Menu.Item>
    </Menu >
  );

  return (
    <Draggable draggableId={planTemplateTask.id} index={index} isDragDisabled={isSaving}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          snapshot={snapshot}>
          <StyledCSSGrid
            candrag={!(isSaving)}>
            <StyledTaskContainer
              ref={taskRef}>
              <StyledStepContainer>
                <StyledIconContainer>
                  <TaskSequenceIcon task={planTemplateTask} />
                </StyledIconContainer>
              </StyledStepContainer>
              <StyledContentContainer>
                <StyledRow>
                  <StyledTaskName>
                    {planTemplateTask.reconTaskTypeName}
                  </StyledTaskName>
                  <StyledOverflowMenu>
                    <Dropdown
                      disabled={isSaving}
                      overlay={moreMenu}>
                      <StyledMoreIcon
                        data-disabled={isSaving}
                        icon={faEllipsisH} />
                    </Dropdown>
                  </StyledOverflowMenu>
                </StyledRow>
                <StyledRow>
                  <StyledAssignTo>
                    {planTemplateTask.assignedToName}
                  </StyledAssignTo>
                </StyledRow>
                  <Text ellipsis style={{ lineHeight: 1.9 }}>
                    Est. Cost: {formatPrice(planTemplateTask.estimatedCost)}
                  </Text>
              </StyledContentContainer>
            </StyledTaskContainer>
          </StyledCSSGrid>
        </div>
      )}
    </Draggable >
  )
}

//#region StyledComponents
const StyledCSSGrid = styled(GridTemplate(
  `'bar content'`,
  'auto',
  'auto minmax(0px, 1fr)'
))`
  // fallback if grab cursor is unsupported
  cursor: ${props => !props['candrag'] ? 'default' : 'move'}; 
  cursor: ${props => !props['candrag'] ? 'default' : 'grab'};
  
  margin: 0px 8px 8px 8px;  
  border-radius: 4px;
  border: ${({theme}) => theme.borders.mediumSolidGray};
  transition: all 0.3s;
  :hover {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15);
  }
`;
const StyledTaskContainer = styled(GridArea(
  'content', 1, 2
))`
  display: flex;  
  min-height: 75px;
  align-items: center;  
  padding: 10px 10px 10px 0;
  background-color: ${({theme}) => theme.colors.white};
`;
const StyledStepContainer = styled.div.attrs({
  className: 'center-content'
})`
  margin: 0 16px;
`;
const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 36px;
`;
const StyledContentContainer = styled.div`  
  display: flex;
  flex-direction: column;  
  width: 100%;
  min-width: 0px;
`;
const StyledRow = styled.div`
  flex: 1 1 auto;
  display: flex;
`;
const StyledTaskName = styled(Text).attrs({
  ellipsis: true,
})`
  flex: 1 1 0px;
  font-size: ${({theme}) => theme.fontSizes.md};
  font-weight: ${({theme}) => theme.fontWeights.medium};
`;
const StyledOverflowMenu = styled.div.attrs({
  className: 'center-content'
})`
  width: 40px;
  height: 100%;
`;
const StyledMoreIcon = styled(FontAwesomeIcon)`
  cursor: ${props => props['data-disabled'] ? 'inherit' : 'pointer'};
  color: ${props => props['data-disabled'] ? props.theme.colors.gray : props.theme.colors.darkGray};
  height: 20px;
  padding: 0 8px 0 18px;
  .svg-inline--fa.fa-w-16& {
    width: 100%;
  }
`;
const StyledAssignTo = styled(Text).attrs({
  ellipsis: true,
})`
  flex: 1 1 0px;
`;
//#endregion

export default TemplateTask