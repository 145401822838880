import { createSelector } from 'reselect';
import { rootEntitySwitcherSelector } from '../store/dealersStore';
import { apiStatusConstants, VENDOR_USER_INFO } from '../app-constants';

export const analyticsEntityContextSelector = createSelector(
  rootEntitySwitcherSelector,
  (state) => state.dealers.current,
  (state) => state.vendors.vendor,
  (entitySwitcherState, currentDealerState, currentVendorState) => {
    const { isRootUser, vendorShipSelected: vendorshipSelected } = entitySwitcherState;
    const { data: currentEntity, fetchStatus: currentDealerFetchStatus } = currentDealerState;
    const currentVendor = currentVendorState;

    const isRootUserActingAsVendor = isRootUser === true && vendorshipSelected === true;
    /*
     * If the current user is an actual vendor user signing in to iRecon, we set vendor information on the `vendorStore.vendor`
     * If the current user is a "root user" switching to a vendor entity, we set vendor information on `dealerStore.current` 😕
     * `fetchStatus` for current dealer information works as expected, but `fetchStatus` on the vendor store is not used to track API state for the "get current vendor" API call
     * To get around this we check for a combination of dealer info fetch status and the presence of `vendor.name` from the vendors store
     */
    const isVendorInformationAvailable = isRootUserActingAsVendor
      ? currentDealerFetchStatus === apiStatusConstants.SUCCEEDED
      : currentVendor?.name !== undefined;
    const isDealerInformationAvailable = currentDealerFetchStatus === apiStatusConstants.SUCCEEDED;

    // This way of determining "vendor context" is less than optimal, but this is all we have for now
    const isVendorContext = isRootUserActingAsVendor || currentVendor?.name !== undefined;
    const isRequiredEntityInformationAvailable = isVendorContext
      ? isVendorInformationAvailable
      : isDealerInformationAvailable;

    let _entityContext;
    if (!isRequiredEntityInformationAvailable) {
      _entityContext = undefined;
    } else {
      if (isVendorContext) {
        if (isRootUserActingAsVendor) {
          _entityContext = {
            isVendor: true,
            name: currentEntity.name
          };
        } else {
          _entityContext = {
            isVendor: true,
            name: currentVendor.name
          };
        }
      } else {
        // is dealer
        _entityContext = {
          isVendor: false,
          logicalId: currentEntity.logicalId,
          name: currentEntity.name,
          entityType: currentEntity.entityType
        };
      }
    }

    return {
      isAnalyticsEntityContextAvailable: isRequiredEntityInformationAvailable,
      analyticsEntity: _entityContext
    };
  }
);

export const analyticsUserContextSelector = createSelector(
  rootEntitySwitcherSelector,
  (state) => state.oidc,
  (state) => state.dealers.current.data,
  (state) => state.dealers.current.fetchStatus,
  (state) => state.vendors.vendor,
  (entitySwitcherState, oidcState, currentDealer, currentDealerFetchStatus, currentVendor) => {
    const { isRootUser, vendorShipSelected: vendorshipSelected } = entitySwitcherState;

    const isRootUserActingAsVendor = isRootUser === true && vendorshipSelected === true;
    const isVendorContext = isRootUserActingAsVendor || currentVendor?.name !== undefined;

    let isVendorInformationAvailable;
    const isDealerInformationAvailable = currentDealerFetchStatus === apiStatusConstants.SUCCEEDED;

    let _userContext = undefined;

    if (isVendorContext) {
      const vendorUserInfoData = localStorage.getItem(VENDOR_USER_INFO);
      let vendorUserInfo;
      try {
        vendorUserInfo = vendorUserInfoData ? JSON.parse(vendorUserInfoData) : {};
      } catch {
        /* Gobble. Don't let an error in analytics code take down the entire application */
      }

      isVendorInformationAvailable = vendorUserInfo !== undefined && vendorUserInfo.bridgeId !== undefined;

      if (isVendorInformationAvailable) {
        _userContext = {
          bridgePlatformId: vendorUserInfo.bridgeId,
          bridgeUsername: vendorUserInfo.username,
          solutionPrincipalId: vendorUserInfo.bridgeId,
          userType: vendorUserInfo.analyticsUserType,
          isInternalUser: false
        };
      }
    } else {
      if (currentDealerFetchStatus === apiStatusConstants.SUCCEEDED && oidcState?.user?.profile) {
        _userContext = {
          bridgePlatformId: oidcState.user.profile.recon_user_id,
          bridgeUsername: oidcState.user.profile.sub,
          solutionPrincipalId: oidcState.user.profile.recon_user_id,
          userType: currentDealer.analyticsUserType,
          isInternalUser: currentDealer.analyticsUserType === 'EMPLOYEE'
        };
      }
    }

    return {
      isAnalyticsUserContextAvailable: isVendorContext ? isVendorInformationAvailable : isDealerInformationAvailable,
      analyticsUser: _userContext
    };
  }
);
