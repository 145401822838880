// This is used to give communication-related components awareness of the context in which they are being used.
// For example, we use different nomenclature (Messages vs Communications vs Notes) depending on if we are on
// the Inventory or the Tasks page

const communicationContexts = {
  TASK_DETAILS_PAGE: 'task-details-page', // The full taks details drawer opened from the Tasks page
  VEHICLE_DETAILS_PAGE: 'vehicle-details-page', // The full vehicle details drawer opened from the Inventory page
  VEHICLE_DETAILS_PAGE_MESSAGE: 'vehicle-details-page-message', // The task message drawer opened from the VDP
  VEHICLE_CARD: 'vehicle-card', // The Vehicle Card shown on the Inventory page
  MESSAGE_THREAD_REPLY: 'message-thread-reply', // The CommunicationInput rendered to reply to a specific existing task message
  TASKS_PAGE_MESSAGE_DRAWER: 'task-page-message-drawer', // The messages drawer accessed from the Tasks page
  INVENTORY_PAGE_COMMUNICATION_DRAWER: 'inventory-page-communication-drawer', // The communications drawer accessed from the Inventory page,
  NUMBERS_IMAGE_UPLOADED: 10, // the numbers image uploaded a time
  IMAGES_MESSAGE_NUMBERS_UPLOADED:
    'Maximum Photos for a Note. The maximum number of photos you can post with one note is 10.', // the message to error uploaded over 10 files
  MAXIMUM_FILE_IMAGE: 10485760,
  MESSAGE_MAXIMUM_FILE_IMAGE: 'Photo size cannot exceed 10MB.',
  MESSAGE_INCORRECT_FILE_TYPE: 'The uploaded file type is invalid.',
  MESSAGE_INVALID_VALUE_INPUTING: 'You have inputed an invalid message. Please try again.',
  TOOLTIP_ATTACH_PHOTOS: 'Attach up to 10 photos with a 10MB maximum file size',
  COMMUNICATION_BUTTON_NOTE: 'COMMUNICATION_BUTTON_NOTE', // button create note
  DOCUMENT_SUCCESS: 'DOCUMENT_SUCCESS',
  VEHICLE_COMMUNICATION_REPLY_PAGE: 'vehicle-communication-reply-page',
  MESSAGE_SUCCESS_DELETE_NOTE: 'Deleted a note successfully!',
  MESSAGE_SUCCESS_DELETE_MESSAGE: 'Deleted a message successfully!',
  MESSAGE_LOADING_DELETE_NOTE: 'Deleting a note...',
  MESSAGE_LOADING_DELETE_MESSAGE: 'Deleting a message...',
  NO_SCROLL_ID: 'randomIdToPreventScrolling',
  MESSAGE_EXCEED_10_PHOTOS_LIMIT: 'Message exceeds 10 photo limit. Remove additional photos to continue.',
  VEHICLE_NOTE_EXCEED_10_PHOTOS_LIMIT: 'Vehicle note exceeds 10 photo limit. Remove additional photos to continue.',
  DEFAULT_CREATE_NOTE_HEIGHT: 225,
  DEFAULT_MESSAGE_INPUT_HEIGHT: 73
};

export default communicationContexts;
