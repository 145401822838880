import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { enterpriseMetricsActions } from '../../../../../store/enterpriseMetricsStore';
import { createSelector } from 'reselect';
import { SummaryHeader, SummaryTable } from '.';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';
import { useDebouncedEffect, useLocalStorage } from '../../../../../hooks';
import { dashboardPreferencesTypes } from '../../../../../app-constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { apiStatusConstants, statisticTagTypes } from 'app-constants';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';

const changePresentationData = (isActiveMetrics, data, reconExcludeTaskCategories) => {
  if (reconExcludeTaskCategories) {
    if (isActiveMetrics) {
      return {
        presentationData: data.activeInventoryPerformanceMetrics,
        unfilteredData: data.unfilteredActiveMetrics,
        fetchStatus: data.activeInventoryPerformanceMetricsFetchStatus
      };
    } else {
      return {
        presentationData: data.historicalPerformanceMetrics,
        unfilteredData: data.unfilteredHistoricalMetrics,
        fetchStatus: data.historicalPerformanceMetricsFetchStatus
      };
    }
  } else {
    if (isActiveMetrics) {
      return {
        presentationData: data.snapshotHistoricalMetrics || [],
        fetchStatus: data.snapshotHistoricalMetricsFetchStatus
          ? apiStatusConstants.SUCCEEDED
          : data.historicalMetricsFetchStatus
      };
    } else {
      return {
        presentationData: data.historicalMetrics.overviewData,
        fetchStatus: data.historicalMetricsFetchStatus
      };
    }
  }
};

const summaryTablePropsSelector = createSelector(
  (state) => state?.enterpriseMetrics?.historical,
  (state) => state.dealers.current.data,
  (enterpriseMetrics, currentEntity) => {
    return {
      data: enterpriseMetrics.data,
      fetchStatus: enterpriseMetrics.fetchStatus,
      currentEntity: currentEntity
    };
  }
);

const propsSelector = createSelector(
  (state) => state.metrics,
  (metrics) => ({
    metricsData: metrics
  })
);

const SummaryBody = ({ flags, metricsType = statisticTagTypes.HISTORICAL_METRICS, filters }) => {
  const useLocalStorageObject = useLocalStorage('dashboardPreferences', {});
  const [dashboardPreferences, setSavedDashboardPreferences] = useLocalStorageObject;
  const { metricsData } = useSelector(propsSelector);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const isActiveMetrics = metricsType === statisticTagTypes.ACTIVE_METRICS;
  const excludedTaskCategories =
    dashboardPreferences?.[dashboardPreferencesTypes.HISTORICAL_PERFORMANCE_FILTERS]?.excludedTaskCategories;
  const [drawerFilters, setDrawerFilters] = useState({ ...filters, excludedTaskCategories });
  const { items } = useCommonDropdownContext('dealerships');

  const updateDashboardPreferences = (newFilters) => {
    setSavedDashboardPreferences({ ...dashboardPreferences, historicalPerformanceFilters: newFilters });
    setDrawerFilters(newFilters);
  };
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.ENTERPRISE_SUMMARY_HISTORICAL_PERFORMANCE_VIEWED);

  const [sort, setSort] = useState({ order: 'ascend', field: 'dealerName' });

  const onChange = (...params) => {
    const sortHeader = params[2];
    if (sortHeader && sortHeader.field) {
      setSort({ order: sortHeader.order, field: sortHeader.field });
    }
  };

  const dispatch = useDispatch();
  const { data, fetchStatus, currentEntity } = useSelector(summaryTablePropsSelector);
  const fetchData = (currentFilters, dealerships) => {
    const dealershipIds = flags?.reconDealerFilter
      ? (dealerships ?? [])
          .filter((item) => item.checked)
          .map((item) => item.key)
          .join(',')
      : undefined;
    dispatch(
      enterpriseMetricsActions.getEnterpriseHistoricalMetrics(
        currentEntity.id,
        {
          dispositions: currentFilters.dispositions,
          startTime: currentFilters.startTime,
          endTime: currentFilters.endTime,
          excludedTaskCategories: flags.reconExcludeTaskCategories ? currentFilters.excludedTaskCategories : undefined,
          dealershipIds
        },
        flags.reconTimestampFilter
      )
    );
  };

  useEffect(() => {
    setDrawerFilters(
      {
        ...drawerFilters,
        excludedTaskCategories
      },
      items
    );
  }, [JSON.stringify(excludedTaskCategories)]);

  useEffect(() => {
    fetchData({ ...drawerFilters }, items);
  }, [JSON.stringify(drawerFilters)]);

  useDebouncedEffect(
    () => {
      fetchData(drawerFilters, items);
    },
    600,
    [items]
  );

  useEffect(() => {
    const data = changePresentationData(isActiveMetrics, metricsData, flags?.reconExcludeTaskCategories);
    setUnfilteredData(data.unfilteredData);
  }, [JSON.stringify(metricsData), metricsType, flags?.reconExcludeTaskCategories]);

  const tableData = data.dealershipLevelMetrics;

  return (
    <>
      <SummaryHeader
        data={data}
        filters={drawerFilters}
        setFilters={updateDashboardPreferences}
        fetchStatus={fetchStatus}
        createdOn={currentEntity.createdOn}
        currentEntity={currentEntity}
        useLocalStorageObject={useLocalStorageObject}
        sort={sort}
        unfilteredData={unfilteredData}
      />
      <SummaryTable
        data={tableData}
        fetchStatus={fetchStatus}
        refreshAction={() => fetchData(drawerFilters)}
        filters={drawerFilters}
        onChange={onChange}
        sort={sort}
        useLocalStorageObject={useLocalStorageObject}
      />
    </>
  );
};

export default withLDConsumer()(SummaryBody);
