import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { formatDateTime, formatDateTimeMMDDYYYY } from 'utils/dateTimeUtils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { VENDOR_USER } from 'app-constants/groupTypes';
import { HQAndProfitTimeContext } from 'utils/contexts';

const { Text } = Typography;

const MapContainer = ({ vehicleLocations, flags }) => {
  const [currentLocation, setCurrentLocation] = useState({});
  const [googleUrl, setGoogleUrl] = useState(null);
  const { hasReconProfitTime } = useContext(HQAndProfitTimeContext);
  useEffect(() => {
    if (vehicleLocations.length > 0) {
      const location = vehicleLocations.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))[0];
      setGoogleUrl(
        `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${location.location.latitude},${location.location.longitude}&zoom=18&maptype=satellite`
      );
      setCurrentLocation(location);
    } else {
      setGoogleUrl(null); // sometimes seeing the previous location stay shown, so making sure we clear out and reset for react to detect the change better
    }
  }, [vehicleLocations]);

  const getScannedByAssigneeName = () => {
    const formattedGroupName = ` (${currentLocation.groupName})`;
    if (
      flags?.reconVendorManagement &&
      currentLocation.groupName &&
      currentLocation.createdByUserType === VENDOR_USER
    ) {
      return (
        <b>{`${currentLocation.groupName}${
          currentLocation.createdByName ? ` (${currentLocation.createdByName})` : ''
        }`}</b>
      );
    }
    return <b>{`${currentLocation.createdByName}${currentLocation.groupName ? formattedGroupName : ''}`}</b>;
  };

  const getFormattedDateTime = () => {
    const formattedDateTimeObj = formatDateTimeMMDDYYYY(currentLocation.createdOn);
    return (
      <>
        <b>&nbsp;{formattedDateTimeObj.date}</b>&nbsp;at&nbsp;
        <b>{formattedDateTimeObj.time}</b>
      </>
    );
  };

  return Object.keys(currentLocation).length > 0 ? (
    <>
      {googleUrl && <StyledMapContainer src={googleUrl} />}
      <StyledLocationDateTimeContainer>
        {hasReconProfitTime ? (
          <>
            Location scanned on {getFormattedDateTime()}&nbsp;by&nbsp;{getScannedByAssigneeName()}
            {currentLocation.reconTaskTypeName && (
              <>
                &nbsp;for&nbsp;<b>{currentLocation.reconTaskTypeName}</b>
              </>
            )}
          </>
        ) : (
          <>
            <StyledLocationNearHereLabel>Last location near here</StyledLocationNearHereLabel>
            <Text>{formatDateTime(currentLocation.createdOn)}</Text>
            <StyledTaskInfo>
              by {getScannedByAssigneeName()}
              {currentLocation.reconTaskTypeName && (
                <>
                  &nbsp;for <b>{currentLocation.reconTaskTypeName}</b>
                </>
              )}
            </StyledTaskInfo>
          </>
        )}
      </StyledLocationDateTimeContainer>
    </>
  ) : (
    <StyledWatermark>
      <IconContainer>
        <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
      </IconContainer>
      <StyledNoLocationLabel>No location data has been recorded</StyledNoLocationLabel>
    </StyledWatermark>
  );
};

const StyledWatermark = styled.div`
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  z-index: 1;
  border: none;
  background-color: ${({ theme }) => theme.colors.backgroundGray};
  height: 100%;
  span {
    position: absolute;
    color: ${({ theme }) => theme.colors.darkGray};
    left: 50%;
    transform: translateX(-50%);
  }
`;
const IconContainer = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  top: 25%;
`;
const StyledNoLocationLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: 0;
  top: 50%;
  width: 100%;
`;
const StyledMapContainer = styled.iframe.attrs({
  frameBorder: '0'
})`
  width: 360px;
  height: 100%;
  border: 0;
`;
const StyledLocationDateTimeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  width: 360px;
  z-index: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.9);
`;
const StyledTaskInfo = styled(Text)`
  flex-basis: 100%;
  b {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;
const StyledLocationNearHereLabel = styled(Text)`
  flex: 1 1 0px;
`;

export default withLDConsumer()(MapContainer);
