export const theme = {
  colors: {
    lightRedButton: 'rgba(235,0,46,0.5)',
    transparent: 'transparent',
    navy: '#002950',
    red: '#EB002E',
    orange: '#FE7520',
    blue: '#1CC9FD',
    green: '#00F2AB',
    lime: '#00BE00',
    purple: '#A900F2',
    white: '#FFFFFF',
    gray: '#959FA8',
    black: '#000000',
    lightNavy: '#8094a7',
    lightGray: '#C9CFD8',
    iconGray: '#C7C7C7',
    darkGray: '#6D727A',
    secondaryGrey: '#90939d',
    activeNavBorderRed: '#EB002E',
    borderGray: '#E5E5E5',
    backgroundGray: '#F4F5F7',
    backgroundSelectedLightGray: '#fafafa',
    blackErrorColor: '#16171A',
    primaryButtonBackgroundColor: '#EB002E', //these are the background colors of the antd 'danger' button
    primaryButtonHoverBackgroundColor: '#ff737a',
    primaryButtonDisabledBorderColor: '#d9d9d9',
    criticalPrimary: '#C4001B',
    criticalSecondary: '#EB002E',
    warningPrimary: '#9E671F',
    warningSecondary: '#F59F45',
    successPrimary: '#008629',
    successSecondary: '#00BE00',
    infoPrimary: '#2B6BDD',
    infoSecondary: '#639CFF',
    tableHeaderFontColor: '#042C52',
    lightBlueLinkColor: '#2B6BDD',
    lightBlue: '#0576B3',
    lightOrange: '#FFE4D4',
    lightOrangeFaded: 'rgba(255, 228, 212, 0.5)',
    lightRed: '#FFCCD2',
    lightRedFaded: 'rgba(255, 204, 210, 0.5)',
    darkRed: '#B70024',
    vendorCardBorder: '#CFCFCF',
    titanium: '#CACFD9',
    titanium300: '#F2F4F5',
    titanium500: '#CACFD9',
    titanium700: '#6D727A',
    inputGray: '#6E7279',
    lightSystemBlue: '#007aff',
    disabledGray: '#F5F6F7',
    transparentNavy: 'rgba(0, 41, 80, 0.5)',
    backgroundGrayOut: '#F9FAFA',
    krypton: '#1A5915',
    ferrariRed: '#DD2B2B',
    iconYellow: '#FFBB33',
    iconBlue: '#639CFF',
    cobalt700: '#173A78',
    appBackgroundColor: '#f2f4f5'
  },

  toggle: {
    red: 'rgba(255, 77, 79, 0.85)'
  },

  backgroundColors: {
    gray: '#F4F5F7',
    red: '#DD2B2B',
    green: '#298C20',
    transparentGray: '#8080808A',
    navy: '#072244',
    lightGray: '#F1F4F5',
    lightBlue: '#639CFF',
    krypton: '#38BF2C',
    ironGray: '#E4E9EF'
  },

  borders: {
    activeNavBorder: '2px solid #EB002E',
    inactiveNavBorder: 'none',
    thinSolidGray: 'thin solid #E5E5E5',
    mediumSolidGray: '1px solid #E5E5E5',
    mediumSecondaryGrey: '1px solid #90939d',
    tableRowBorder: '1px solid #E8E8E8',
    thickSolidLightNavy: '2px solid #8094a7',
    thinSolidNoteBox: '1px solid #8094A7',
    thickSolidRedBorder: '3px solid red',
    grayLineItemBorder: '1px solid #E4E9EF',
    grayLineItemDropdownBorder: '1px solid #CFCFCF',
    disabledButtonBorder: '1px solid #2b6bdd',
    inputBorderNormal: '1px solid #CBCFD8',
    inputBorderError: '2px solid #EB002E',
    thinSolidLightGray: '1px solid #C9CFD8',
    thickSolidRed: '3px solid #EB002E',
    mediumSolidNavy: '1px solid #002950',
    invalidRedBorder: '1px solid #EB002E'
  },

  borderColors: {
    gray: '#d9d9d9',
    lightGray: '#E5E5E5',
    white: '#FFFFFF',
    error: '#f7284a',
    blue: '#1890ff'
  },

  fontSizes: {
    ss: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xlg: '20px',
    xxlg: '24px',
    jumbo: '28px',
    title: '32px',
    largeStat: '40px'
  },

  lineHeights: {
    ss: '16px',
    xs: '18px',
    sm: '20px',
    smd: '21px',
    md: '22px',
    lg: '24px',
    subTitle: '28px',
    smalltitle: '36px',
    dropdownText: '38px',
    title: '44px',
    header: '48px'
  },

  letterSpacings: {
    sm: '0.1px',
    md: '0.25px'
  },

  fontWeights: {
    lightest: '100',
    lighter: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    bolder: '800',
    boldest: '900'
  },

  icons: {
    fontSizes: {
      notifIcon: '21px',
      layeredIcon: '25px'
    }
  }
};
