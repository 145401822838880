import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { StyledButton } from '../GeneralSetting/style';
import { forms, validationSchema } from './data';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from 'utils/stringUtils';
import { StyledHeader } from './style';
import { vendorActions } from 'store/vendorStore';
import { apiStatusConstants } from 'app-constants';
import CommonFormRender from '../../common/CommonFormRender';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const toResetWhenSwitchingcountry = ['address', 'address2', 'city', 'state', 'postalCode'];

const BusinessProfile = () => {
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    clearErrors
  } = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema)
  });

  const watchCountry = watch('country');
  const [vendor, setVendor] = useState({});

  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.vendors.fetchStatus);
  const vendorFromStore = useSelector((state) => state.vendors.vendor);
  const currentVendor = useSelector((state) => state.dealers.current.data);
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);

  useEffect(() => {
    return () => dispatch(vendorActions.setFetchStatus(apiStatusConstants.PENDING));
  }, []);

  useEffect(() => {
    setVendor(isRootUser && vendorShipSelected ? currentVendor : vendorFromStore);
  }, [vendorShipSelected, vendorFromStore, currentVendor]);

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(vendor)]);

  useEffect(() => {
    if (isDirty) {
      for (const toCheck of toResetWhenSwitchingcountry) {
        setValue(toCheck, '');
      }
      clearErrors();
    }
  }, [watchCountry]);

  const onSubmit = (data) => {
    const toUpdate = { ...data };
    toUpdate.id = vendor.id;
    toUpdate.contactFirstName = vendor.contactFirstName;
    toUpdate.contactLastName = vendor.contactLastName;
    dispatch(vendorActions.updateVendor(toUpdate));
  };

  const onReset = () => {
    resetForm();
  };

  const resetForm = () => {
    if (vendor?.id) {
      reset({
        phone: formatPhoneNumber(vendor.phone ?? ''),
        name: vendor.name,
        email: vendor.email,
        address: vendor.address,
        address2: vendor.address2,
        postalCode: vendor.postalCode,
        city: vendor.city ?? '',
        state: vendor.state ?? '',
        country: vendor.country ?? 'United States'
      });
    } else {
      reset();
    }
  };

  const formRef = useRef();

  return (
    <Row style={{ minHeight: 'calc(100vh - 192px - 24px)', width: '100vw', margin: 0 }} gutter={[16, 18]}>
      <Col xs={24} style={{ paddingTop: '24px' }}>
        <Row type="flex" align="middle" justify="center">
          <Col sm={23} xs={22}>
            <StyledHeader>Business Profile</StyledHeader>
          </Col>
        </Row>
      </Col>

      <Col xs={24}>
        <Row type="flex" align="middle" justify="center">
          <Col xl={10} lg={14} md={18} xs={22}>
            <form ref={formRef}>
              <Row type="flex" gutter={[16, 25]}>
                <CommonFormRender
                  forms={forms}
                  formRef={formRef}
                  errors={errors}
                  watchCountry={watchCountry}
                  control={control}
                />
                <Col xs={24} style={{ marginTop: '4px' }}>
                  <Row type="flex" justify="end" align="middle" gutter={8}>
                    <Col>
                      <StyledButton
                        ghost
                        disabled={fetchStatus === apiStatusConstants.IS_FETCHING || !isDirty}
                        onClick={onReset}
                      >
                        Reset Changes
                      </StyledButton>
                    </Col>

                    <Col>
                      <StyledButton
                        disabled={!isDirty}
                        onClick={handleSubmit(onSubmit)}
                        loading={fetchStatus === apiStatusConstants.IS_FETCHING}
                        style={{ border: 'none' }}
                      >
                        Save Changes
                      </StyledButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BusinessProfile;
