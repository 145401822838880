import { takeLatest, put, all } from 'redux-saga/effects';
import { createRequestTypes, makeActionCreator } from 'utils';
import { getWithToken } from 'api';
import { CURRENT_DEALER } from 'store/dealersStore';
import { apiStatusConstants } from 'app-constants';
import { createSelector } from 'reselect';

//#region Actions
export const APPRAISAL_INFO = createRequestTypes('APPRAISAL_INFO', [
  'SET_FETCH_STATUS',
  'GET_DATA',
  'SET_DATA',
  'SET_APPRAISAL_POPOVER_STATE'
]);
export const appraisalInfoActions = {
  setFetchStatus: makeActionCreator(APPRAISAL_INFO.SET_FETCH_STATUS, 'fetchStatus'),
  getData: makeActionCreator(APPRAISAL_INFO.GET_DATA, 'vehicleId'),
  setData: makeActionCreator(APPRAISAL_INFO.SET_DATA, 'data'),
  setAppraisalPopoverState: makeActionCreator(APPRAISAL_INFO.SET_APPRAISAL_POPOVER_STATE, 'isOpen')
};
//#endregion

//#region Reducer
const initialState = {
  data: {},
  fetchStatus: apiStatusConstants.IS_FETCHING,
  isAppraisalPopoverOpen: false
};

export const appraisalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPRAISAL_INFO.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case APPRAISAL_INFO.SET_DATA:
      return {
        ...state,
        data: action.data ? { ...action.data } : {}
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    case APPRAISAL_INFO.SET_APPRAISAL_POPOVER_STATE:
      return {
        ...state,
        isAppraisalPopoverOpen: action.isOpen
      };
    default:
      return state;
  }
};
//#endregion

//#region selectors
export const appraisalInfoPropsSelector = createSelector(
  (state) => state.appraisalInfo,
  (appraisalInfo) => ({
    fetchStatus: appraisalInfo.fetchStatus,
    data: appraisalInfo.data,
    isAppraisalPopoverOpen: appraisalInfo.isAppraisalPopoverOpen
  })
);
//#endregion

//#region Sagas
export function* getAppraisalInfoSaga() {
  yield takeLatest(APPRAISAL_INFO.GET_DATA, function* ({ vehicleId }) {
    try {
      yield put(appraisalInfoActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const result = yield getWithToken(`/api/vehicles/id/${vehicleId}/appraisal`);
      const appraisal = result.hasOwnProperty('appraisal')
        ? { ...result.appraisal, appraisalImageUrls: result.appraisalImageUrls }
        : {};

      yield all([
        put(appraisalInfoActions.setData(appraisal)), //change from initialState to whatever is fetched from api
        put(appraisalInfoActions.setFetchStatus(apiStatusConstants.SUCCEEDED))
      ]);
    } catch (error) {
      yield all([
        put(appraisalInfoActions.setData({})), //clear out appraisal data on failure, so we don't show previously fetched data for a different vehicle
        put(appraisalInfoActions.setFetchStatus(apiStatusConstants.FAILED))
      ]);
      devLogger.error('error in getAppraisalInfoSaga', error);
    }
  });
}
//#endregion
