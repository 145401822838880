import React from 'react';
import { Row, Select, Typography, Input } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const StyledRow = styled(Row)`
  .ant-select-dropdown-menu-item {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }

  .ant-select-dropdown-menu {
    overflow: inherit !important;
  }
`;

export const StyledSectionTitle = styled(Text)`
  &.ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: ${({ theme }) => theme.lineHeights.lg};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
  }
`;

export const StyledSelect = styled(({ error, ...rest }) => <Select {...rest} />)`
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.secondaryGrey};
    pointer-events: none;
  }

  .ant-select-selection__rendered {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.dropdownText};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }

  .ant-select-selection,
  .ant-select-selection:focus {
    border: ${({ error, theme }) => (error ? theme.borders.invalidRedBorder : theme.borders.mediumSecondaryGrey)};
  }
`;

export const StyledFormLabel = styled(Text)`
  .ant-typography& {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.ss};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledTextArea = styled(({ error, ...rest }) => <Input.TextArea {...rest} />)`
  &.ant-input {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
    font-size: 14px !important;
    border: ${({ error, theme }) => (error ? theme.borders.invalidRedBorder : theme.borders.mediumSecondaryGrey)};
  }

  &.ant-input[disabled] {
    color: ${({ theme }) => theme.colors.darkGray} !important;
    background-color: ${({ theme }) => theme.colors.disabledGray};
  }

  &.ant-input::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }

  &.ant-input:hover {
    border: ${({ error, theme }) => error && theme.borders.invalidRedBorder};
  }
`;

export const StyledInput = styled(({ error, ...rest }) => <Input {...rest} />)`
  &.ant-input {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.smd};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
    font-size: 14px !important;
    border: ${({ error, theme }) => (error ? theme.borders.invalidRedBorder : theme.borders.mediumSecondaryGrey)};
  }

  &.ant-input[disabled] {
    color: ${({ theme }) => theme.colors.darkGray} !important;
    background-color: ${({ theme }) => theme.colors.disabledGray};
  }

  &.ant-input::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }

  &.ant-input:hover {
    border: ${({ error, theme }) => error && theme.borders.invalidRedBorder};
  }
`;
