import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faWrench } from '@fortawesome/free-solid-svg-icons';
import { theme as themeStyles } from 'styles/appTheme';
import { CommonRedButton } from 'components/styledComponents';

export const StyledWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundColors.lightGray};
  height: 100%;
`;

export const StyledImgHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 72px;
  padding-top:
  text-align: center;
  background-color: ${({ theme }) => theme.backgroundColors.navy};
  padding-top: 21px;
`;

export const StyledContainer = styled.div`
  align-items: center;
  text-align: center;
  min-height: 323px;
  width: 470px;
  margin: 142px auto;
  border: ${({ theme }) => theme.borders.thinSolidLightGray};
  background-color: white;

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const StyledLogoRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  height: 68px;
  padding-top: 16px;
  border-bottom: ${({ theme }) => theme.borders.thinSolidLightGray};
`;

export const StyledInstructionRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  padding: 16px 16px 0px 16px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.md};
`;

export const StyledOptionRow = styled.div`
  display: flex;
`;

export const StyledFaCarIcon = styled(FontAwesomeIcon).attrs({
  icon: faCar,
  color: themeStyles.colors.navy,
  style: {
    fontSize: themeStyles.fontSizes.xxlg,
    margin: '25px 16px'
  }
})``;

export const StyledFaWrechIcon = styled(FontAwesomeIcon).attrs({
  icon: faWrench,
  color: themeStyles.colors.navy,
  style: {
    fontSize: themeStyles.fontSizes.xxlg,
    margin: '34px 16px'
  }
})``;

export const StyledDealerOptionContainer = styled.div`
  flex: 1
  padding-top: 13px;
  padding-bottom: 16px;
  text-align: left;
  margin-right: 16px;
  border-bottom: ${({ theme }) => theme.borders.thinSolidLightGray};
`;

export const StyledVendorOptionContainer = styled.div`
  padding: 16px 16px 16px 0px;
  text-align: left;
`;

export const StyledOptionDesc = styled.div`
  padding-bottom: 8px;
`;

export const StyledRedButton = styled(CommonRedButton)`
  .ant-btn& {
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: 4.5px 8px;
  }
`;
