import { faSearch, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, DatePicker, Menu, Radio, Typography } from 'antd';
import { FilterTags, MultiSelect } from 'components';
import {
  CommonCheckboxMenu,
  CommonControlsContainer,
  CommonFiltersRow,
  CommonGroupCheckboxMenu,
  CommonSearch,
  CommonSelectorContainer
} from 'components/styledComponents';
import { useDebouncedEffect, useSetAvailableDateRange, useUpdateEffect } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled, { css } from 'styled-components';
import { stripReferences } from 'utils/arrayUtils';
import {
  allUnassignedTasksLabel,
  CATEGORY,
  DEALERSHIP,
  ewsEntityTypes,
  sortOptionDefault,
  VENDOR
} from 'app-constants';
import { assignedToAndGroupTagConverter, groupTagRemover, assignedToTagRemover } from 'utils/filterUtils';
import { options } from 'less';
import { HQAndProfitTimeContext } from 'utils/contexts';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const MOMENT_FORMATTING = 'MM-DD-YYYY';

const vehicleFiltersPropsSelector = createSelector(
  (state) => state.vehicles.data,
  (state) => state.vehicles.fetchStatus,
  (state) => state.dealers.current.data,
  (state) => state.dealers.current.data.createdOn,
  (vehiclesData, vehiclesFetchStatus, currentDealer, dealerCreatedOn) => ({
    vehiclesData: vehiclesData,
    vehiclesFetchStatus: vehiclesFetchStatus,
    currentDealer,
    dealerCreatedOn: dealerCreatedOn
  })
);

const VehicleFilters = React.memo(
  ({
    isLoading = false,
    menuState = {
      'Plan Status': null,
      'Task Type': null,
      Category: null,
      'Task Progress': null,
      Dealership: null,
      Vendor: null,
      Group: null,
      'Assigned To': null,
      ProfitTime: null,
      More: null
    },
    setMenuState = () => {},
    query = {},
    setQuery = () => {},
    setHasNoFilters = () => {},
    setAssignedToTechnician = () => {},
    setAssignedToGroup = () => {},
    userId,
    flags = {}
  }) => {
    const { vehiclesData, vehiclesFetchStatus, currentDealer, dealerCreatedOn } = useSelector(
      vehicleFiltersPropsSelector
    );
    const { search, showDateFilter } = query;
    const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);
    const [removedTag, setRemovedTag] = useState([]);
    const availableDateRange = useSetAvailableDateRange(dealerCreatedOn, currentDealer);
    const [filterTags, setFilterTags] = useState([]);
    const [valueSearch, setValueSearch] = useState('');
    const [inProgressFilterState, setInProgressFilterState] = useState(
      menuState?.['Plan Status']?.['Plan Status']?.values?.['In Progress']
    );
    const [atRiskFilterState, setAtRiskFilterState] = useState(
      menuState?.['Plan Status']?.['Plan Status']?.values?.['At Risk']
    );
    const [onTargetFilterState, setOnTargetFilterState] = useState(
      menuState?.['Plan Status']?.['Plan Status']?.values?.['On Target']
    );
    const [filterTagsPresentation, setFilterTagsPresentation] = useState([]); //separating updates to the filter tags from updates to the UI due to the changes in the filter tags. want to only have the updates show up once other stuff has completed
    const [checkedCount, setCheckedCount] = useState({
      'Plan Status': 0,
      'Task Type': 0,
      Category: 0,
      'Task Progress': 0,
      Dealership: 0,
      Vendor: 0,
      'Assigned To': 0,
      Group: 0,
      ProfitTime: 0,
      More: 0
    });
    const menuToQueryMap = {
      'Plan Status': 'planStatus',
      'Completion Date': 'completionDate',
      'Task Type': 'taskType',
      Category: 'taskCategories',
      'Task Progress': 'taskProgress',
      'Assigned To': 'assignedTo',
      Dealership: 'entitiesFilter',
      Vendor: 'assignedToVendor',
      'Recall Status': 'recallStatus',
      'Sold Status': 'soldStatus',
      'Vehicle Status': 'vehicleStatus',
      Disposition: 'disposition',
      assignedToTechnicianFilter: 'assignedToTechnicianFilter',
      assignedToGroupFilter: 'assignedToGroupFilter',
      ProfitTime: 'profitTime'
    };
    const labelAssigneeToThemself = ' (you)';
    const labelGroupToThemself = ' (your group)';
    const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);

    useEffect(() => {
      delete menuToQueryMap['Assigned To'];

      if (currentDealer) {
        if (!isHQ) {
          delete menuState.Dealership;
          delete menuToQueryMap.Dealership;
          setMenuState(menuState);
        }

        if (!hasReconProfitTime) {
          delete menuState.ProfitTime;
          delete menuToQueryMap.ProfitTime;
          setMenuState(menuState);
        }
      }

      if (!flags.reconVendorManagement) {
        delete menuState.Vendor;
        delete menuToQueryMap.assignedToVendor;
        setMenuState(menuState);
      }
    }, []);

    useEffect(() => {
      // sync up query search with valueSearch in VLP
      if (window.location.pathname === '/inventory' && query.search && query.search !== valueSearch) {
        setValueSearch(query.search);
      }
    }, [query.search]);

    useUpdateEffect(() => {
      if (!isLoading) {
        onMenuChange();
      }
    }, [currentDealer?.id, JSON.stringify(menuState)]);

    useDebouncedEffect(
      () => {
        if (vehiclesFetchStatus === 'succeeded') {
          //because there's a lot that goes on when changing filters on the page, there is a potential for filter tag controls to show up misplaced so only showing the tag controls until after page changes have returned data
          setFilterTagsPresentation(filterTags); //filterTagsPresentation is what is actually rendered, but tracking of the current filters is done in filterTags
          setHasNoFilters(!filterTags?.length);
        }
      },
      500,
      [vehiclesData, vehiclesFetchStatus, filterTags]
    );

    useEffect(() => {
      if (removedTag.length) {
        var tag = removedTag.shift();
        let newMenu = stripReferences(menuState);

        const menuComponentToChange = tag.subMenu ? newMenu[tag.menu][tag.subMenu] : newMenu[tag.menu];

        if (tag.type === 'checkbox') {
          switch (tag.key) {
            case 'At Risk':
              setAtRiskFilterState(false);
              setInProgressFilterState(onTargetFilterState);
              menuComponentToChange.values[tag.key] = false;
              menuComponentToChange.values['In Progress'] = onTargetFilterState;
              break;

            case 'On Target':
              setOnTargetFilterState(false);
              setInProgressFilterState(atRiskFilterState);
              menuComponentToChange.values[tag.key] = false;
              menuComponentToChange.values['In Progress'] = onTargetFilterState;
              break;

            case 'In Progress':
              setInProgressFilterState(false);
              setOnTargetFilterState(false);
              setAtRiskFilterState(false);
              menuComponentToChange.values['At Risk'] = false;
              menuComponentToChange.values['On Target'] = false;
              menuComponentToChange.values[tag.key] = false;
              break;

            default:
              if (tag.menu === 'Assigned To' || tag.menu === 'Group') {
                if (tag.menu === 'Group') {
                  groupTagRemover(tag, menuComponentToChange);
                } else {
                  assignedToTagRemover(tag, menuComponentToChange);
                }
              } else {
                menuComponentToChange.values[tag.key] = false;
              }
              if (tag.key === allUnassignedTasksLabel) {
                // Update all Unassigned member to unchecked when filter chip ALL_UNASSIGNED_TASKS removed
                if (menuComponentToChange.values?.length > 0) {
                  menuComponentToChange.values.forEach((menuToChange) => {
                    const hasMemberUnassignedIsChecked = menuToChange?.internalGroupUsers?.some(
                      (groupMember) => groupMember.fullName === 'Unassigned' && groupMember.isChecked
                    );
                    if (hasMemberUnassignedIsChecked) {
                      const groupMembers = menuToChange?.internalGroupUsers?.map((groupMember) => {
                        if (groupMember.fullName === 'Unassigned') {
                          groupMember.isChecked = false;
                        }
                        return groupMember;
                      });
                      menuToChange.internalGroupUsers = groupMembers;
                    }

                    const isAllMemberUnchecked = menuToChange.internalGroupUsers.every(
                      (groupMember) => !groupMember.isChecked
                    );
                    if (isAllMemberUnchecked) {
                      menuToChange.isChecked = false;
                      menuToChange.isShowMinusOutlinedIcon = false;
                    }
                  });
                }
              }
          }
        } else if (tag.type === 'radio') menuComponentToChange.values = null;
        else if (tag.type === 'datepicker') {
          const menuCompletionDate = newMenu[tag.menu]['Completion Date'];
          menuCompletionDate.values = null;
          menuComponentToChange.values = {};
        } else if (tag.type === 'search') {
          let newFilterTags = stripReferences(filterTags);
          newFilterTags = newFilterTags.filter((tag) => tag.type !== 'search');
          setFilterTags(newFilterTags);
          setValueSearch('');
          setQuery({ search: undefined }, 'pushIn');
        }

        setMenuState(newMenu);
      }
    }, [removedTag]);

    const onMenuChange = (e) => {
      if (menuState['Plan Status'] !== null) {
        //ToDo: ZL
        let newMenu = stripReferences(menuState);

        setInProgressFilterState(menuState['Plan Status']['Plan Status'].values['In Progress']);
        setAtRiskFilterState(menuState['Plan Status']['Plan Status'].values['At Risk']);
        setOnTargetFilterState(menuState['Plan Status']['Plan Status'].values['On Target']);

        if (showDateFilter) {
          newMenu['Plan Status']['Completion Date'].visible = true;
          const selectedPlanStatuses = Object.entries(newMenu['Plan Status']['Plan Status'].values)
            .filter((x) => x[1])
            .map((x) => x[0]);
          const disableCompletionDateOptions =
            selectedPlanStatuses.length !== 1 || selectedPlanStatuses[0] !== 'Plan Complete';
          newMenu['Plan Status']['Completion Date'].disabled = newMenu[
            'Plan Status'
          ].datepicker.disabled = disableCompletionDateOptions;
          disableCompletionDateOptions && (newMenu['Plan Status']['Completion Date'].values = null);
          newMenu['Plan Status'].datepicker.visible =
            !disableCompletionDateOptions && newMenu['Plan Status']['Completion Date'].values === 'Custom Range';
        }

        setMenuState(newMenu);
        Object.keys(menuState).forEach((key) => {
          updateCountsAndQueryStrings(key, newMenu);
        });
        //This will only evaluate to true the first time this component is rendered, when filterTags will have a its length of 0
        let newTags = Object.keys(menuState)
          .map((key) => getFilterTags(key, newMenu))
          .flat();
        if (query.search && valueSearch !== '') {
          //This will set a tag for searching when reload page.
          let tagSearch = newTags.find((tag) => tag.type === 'search');
          if (tagSearch) {
            tagSearch.label = `Search: ${query.search}`;
          } else {
            tagSearch = {
              key: 'search',
              label: `Search: ${query.search}`,
              menu: null,
              subMenu: null,
              type: 'search',
              id: null
            };
            newTags.push(tagSearch);
          }
        }
        setFilterTags(newTags);
      }
    };

    const getCount = (menu, menuState) => {
      if (!menuState[menu]) return 0;

      if (menu === 'Assigned To' || menu === 'Group') {
        let assignedToTechnicians = menuState[menu].values?.filter((f) => f.isChecked);
        return assignedToTechnicians.length ?? 0;
      }

      return Object.entries(menuState[menu]).reduce((count, menuItem) => {
        if (typeof menuItem[1] === 'object' && !menuItem[1].disabled && menuItem[0] !== 'datepicker') {
          let obj = menuItem[1].values || (menuItem[0] === 'values' ? menuItem[1] : {});
          count += typeof obj === 'object' ? Object.values(obj).filter((x) => x).length : 1;
        }
        return count;
      }, 0);
    };

    const getFilterTags = (menu, menuState) => {
      let tags = [];
      if (!menuState[menu]) return tags;
      let isCustomRangeCompletionDate = false;
      Object.entries(menuState[menu]).forEach((menuItem) => {
        if (typeof menuItem[1] !== 'object' || menuItem[1].disabled) return;

        let obj = menuItem[1].values || (menuItem[0] === 'values' ? menuItem[1] : null);
        if (!obj) return;
        let filterType = menuItem[1].type || menuState[menu].type;
        if (filterType === 'checkbox') {
          if (menu === 'Assigned To' || menu === 'Group') {
            let values = menuItem[1];
            assignedToAndGroupTagConverter(tags, menu, values);
          } else {
            let filters = Object.entries(obj).filter((x) => x[1]);
            tags.push(
              filters.map((x) => {
                return {
                  menu,
                  subMenu: menuItem[0] === 'values' ? null : menuItem[0],
                  key: x[0],
                  type: 'checkbox',
                  label: `${menuItem[0] === 'values' ? menu : menuItem[0]}: ${x[0]}`,
                  id: null
                };
              })
            );
          }
        } else if (filterType === 'radio') {
          if (menuItem[0] === 'Completion Date' && obj === 'Custom Range') isCustomRangeCompletionDate = true;
          if (!(menuItem[0] === 'Completion Date' && obj === 'Custom Range')) {
            tags.push({
              menu,
              subMenu: menuItem[0],
              key: 'values',
              type: 'radio',
              label: `${menuItem[0] === 'values' ? menu : menuItem[0]}: ${obj}`,
              id: null
            });
          }
        } else if (filterType === 'datepicker') {
          if (!obj.startDate || !obj.endDate || !isCustomRangeCompletionDate) return;
          tags.push({
            menu,
            subMenu: menuItem[0],
            key: 'datepicker',
            type: 'datepicker',
            label: `Completion Date: ${obj.startDate} - ${obj.endDate}`,
            id: null
          });
        }
      });
      return tags.flat();
    };

    const removeFilterTag = (tag) => {
      setRemovedTag((removedTag) => [...removedTag, tag]);
    };

    const updateCountsAndQueryStrings = (updatedMenuKey, updatedMenuVal) => {
      setCheckedCount((prev) => ({
        ...prev,
        [updatedMenuKey]: getCount(updatedMenuKey, updatedMenuVal)
      }));

      let queryValues;
      switch (updatedMenuKey) {
        case 'Plan Status':
        case 'More':
          const newQuery = {};
          Object.entries(updatedMenuVal[updatedMenuKey])
            .filter((x) => !['type', 'datepicker'].includes(x[0]))
            .forEach((subMenu) => {
              if (subMenu[1].disabled || subMenu[1].values === null) {
                const queryKey = menuToQueryMap[subMenu[0]];
                queryKey && (newQuery[queryKey] = []);

                if (subMenu[0] === 'Completion Date') {
                  queryKey && (newQuery[queryKey] = undefined);
                  newQuery.completedOnStart = undefined;
                  newQuery.completedOnEnd = undefined;
                }
                return;
              }
              // Handle completion date range
              if (subMenu[0] === 'Completion Date') {
                let startDate = moment().format(MOMENT_FORMATTING);
                let endDate = moment().format(MOMENT_FORMATTING);
                if (subMenu[1].values !== 'Custom Range') {
                  const daysAgo = Number(subMenu[1].values.match(/\d+/g));
                  // Subtract 1 from daysAgo for startDate because we are counting each day in the range as a whole day (inclusive)
                  startDate = moment(endDate, MOMENT_FORMATTING)
                    .subtract(daysAgo - 1, 'days')
                    .format(MOMENT_FORMATTING);
                } else {
                  startDate = updatedMenuVal[updatedMenuKey].datepicker.values.startDate;
                  endDate = updatedMenuVal[updatedMenuKey].datepicker.values.endDate;
                }
                startDate && (newQuery.completedOnStart = startDate);
                endDate && (newQuery.completedOnEnd = endDate);
                const queryKey = menuToQueryMap[subMenu[0]];
                queryKey && (newQuery[queryKey] = subMenu[1].values);
              } else {
                queryValues = Object.entries(subMenu[1].values || {})
                  .filter((x) => x[1])
                  .map((x) => x[0]);
                const queryKey = menuToQueryMap[subMenu[0]];
                queryKey && (newQuery[queryKey] = queryValues);
              }
            });
          setQuery(newQuery, 'pushIn');
          break;
        case CATEGORY:
        case 'Task Type':
        case 'Task Progress':
        case 'Assigned To':
        case 'ProfitTime':
        case DEALERSHIP:
        case VENDOR:
          if (updatedMenuKey === 'Assigned To') {
            queryValues = updatedMenuVal[updatedMenuKey].values?.filter((f) => f.isChecked);
            let assignedToTechnicians = queryValues.map((member) => {
              return {
                assignedTo: member.id,
                assignedDirectly: member.isAssignedDirectly,
                assignedThruGroup: member.isAssignedThruGroup
              };
            });
            setAssignedToTechnician(queryValues);
            setQuery(
              { [menuToQueryMap['assignedToTechnicianFilter']]: JSON.stringify(assignedToTechnicians) },
              'pushIn'
            );
          } else {
            queryValues = Object.entries(updatedMenuVal[updatedMenuKey].values)
              .filter((x) => x[1])
              .map((x) => x[0]);
            setQuery({ [menuToQueryMap[updatedMenuKey]]: queryValues }, 'pushIn');
          }
          break;
        case 'Group':
          queryValues = updatedMenuVal[updatedMenuKey].values?.filter((f) => f.isChecked);
          let assignedToGroups = queryValues.map((group) => {
            let indexUnassigned = group.internalGroupUsers?.findIndex((member) => member.userId === null);
            return {
              assignedToGroup: group.id,
              assignedToMembers: group.internalGroupUsers
                ?.filter((member) => member.isChecked && member.userId !== null)
                .map((member) => member.userId),
              unassigned: indexUnassigned !== -1 ? group.internalGroupUsers[indexUnassigned].isChecked : false
            };
          });
          setAssignedToGroup(queryValues);
          setQuery({ [menuToQueryMap['assignedToGroupFilter']]: JSON.stringify(assignedToGroups) }, 'pushIn');
          break;
        default:
          break;
      }
    };

    const onCheckboxClick = (menu, subMenu) => (e) => {
      let newMenu = stripReferences(menuState);

      switch (e.target.id) {
        case 'At Risk':
          setAtRiskFilterState(e.target.checked);
          setInProgressFilterState(e.target.checked || onTargetFilterState);
          newMenu[menu][subMenu].values[e.target.id] = e.target.checked;
          newMenu[menu][subMenu].values['In Progress'] = e.target.checked || onTargetFilterState;
          break;

        case 'On Target':
          setOnTargetFilterState(e.target.checked);
          setInProgressFilterState(atRiskFilterState || e.target.checked);
          newMenu[menu][subMenu].values[e.target.id] = e.target.checked;
          newMenu[menu][subMenu].values['In Progress'] = e.target.checked || onTargetFilterState;
          break;

        case 'In Progress':
          setInProgressFilterState(e.target.checked);
          setOnTargetFilterState(e.target.checked);
          setAtRiskFilterState(e.target.checked);
          newMenu[menu][subMenu].values['At Risk'] = e.target.checked;
          newMenu[menu][subMenu].values['On Target'] = e.target.checked;
          newMenu[menu][subMenu].values[e.target.id] = e.target.checked;
          break;

        default:
          if (menu === 'Assigned To' || menu === 'Group') {
            let newDataWithMenuTypes = stripReferences(newMenu[menu]?.values);
            let indexnMenu = newDataWithMenuTypes.findIndex(
              (f) => f.fullName === e.target.fullName && f.id === e.target.id
            );
            if (indexnMenu !== -1) {
              newDataWithMenuTypes[indexnMenu].isChecked = e.target.checked;
              if (menu === 'Assigned To') {
                newDataWithMenuTypes[indexnMenu].isAssignedDirectly = e.target.checked;
                if (newDataWithMenuTypes[indexnMenu].isMemberOfAnyGroup) {
                  newDataWithMenuTypes[indexnMenu].isAssignedThruGroup = e.target.checked;
                }
                if (
                  !newDataWithMenuTypes[indexnMenu].isAssignedThruGroup ||
                  !newDataWithMenuTypes[indexnMenu].isAssignedDirectly
                ) {
                  newDataWithMenuTypes[indexnMenu].isShowMinusOutlinedIcon = true;
                } else {
                  newDataWithMenuTypes[indexnMenu].isShowMinusOutlinedIcon = false;
                }
                newMenu[menu].values = newDataWithMenuTypes;
              }
              if (menu === 'Group') {
                // set isChecked for all user in internal group
                if (newDataWithMenuTypes[indexnMenu].internalGroupUsers?.length > 0) {
                  newDataWithMenuTypes[indexnMenu].internalGroupUsers.forEach((member) => {
                    member.isChecked = e.target.checked;
                  });

                  /*
                   * when a any group member change status to unchecked. we will update status for group name to new icon.
                   */
                  let listGroupMemberNotSelected = newDataWithMenuTypes[indexnMenu].internalGroupUsers.filter(
                    (i) => !i.isChecked
                  );
                  if (listGroupMemberNotSelected?.length > 0) {
                    newDataWithMenuTypes[indexnMenu].isShowMinusOutlinedIcon = true;
                  } else {
                    newDataWithMenuTypes[indexnMenu].isShowMinusOutlinedIcon = false;
                  }
                }
                // Select option 'All Unassigned Tasks'
                if (
                  newDataWithMenuTypes[indexnMenu].fullName === allUnassignedTasksLabel &&
                  newDataWithMenuTypes.length > 0
                ) {
                  newDataWithMenuTypes.forEach((newMenuWithType) => {
                    // Checked/Unchecked all member Unassigned of groups
                    if (newMenuWithType.internalGroupUsers?.length > 0) {
                      let indexMemberUnassigned = newMenuWithType.internalGroupUsers.findIndex(
                        (f) => f.fullName === 'Unassigned'
                      );
                      if (indexMemberUnassigned !== -1) {
                        newMenuWithType.internalGroupUsers[indexMemberUnassigned].isChecked = e.target.checked;
                        /*
                         * when a any group member change status to unchecked. we will update status for group name to new icon.
                         */
                        let listGroupMemberSelected = newMenuWithType.internalGroupUsers.filter((i) => i.isChecked);
                        if (listGroupMemberSelected?.length > 0) {
                          newMenuWithType.isChecked = true;
                          newMenuWithType.isShowMinusOutlinedIcon =
                            listGroupMemberSelected.length !== newMenuWithType.internalGroupUsers.length;
                        } else {
                          newMenuWithType.isChecked = false;
                          newMenuWithType.isShowMinusOutlinedIcon = false;
                        }
                      }
                    }
                  });
                } else {
                  /*
                   * If any group has member Unassigned is uncheck, the All Unassigned Tasks should be update to Minus Icon
                   */
                  updateManualGroupCheckboxState(newDataWithMenuTypes);
                }

                newMenu[menu].values = newDataWithMenuTypes;
              }
            }
          } else {
            if (subMenu) {
              newMenu[menu][subMenu].values[e.target.id] = e.target.checked;
            } else {
              newMenu[menu].values[e.target.id] = e.target.checked;
            }
          }
          break;
      }
      setMenuState(newMenu);
    };
    const onRadioButtonClick = (menu, subMenu) => (e) => {
      let newMenu = stripReferences(menuState);
      if (subMenu) {
        newMenu[menu][subMenu].values = e.target.value;
      } else {
        newMenu[menu].values = e.target.value;
      }
      setMenuState(newMenu);
    };
    const onChangeRangePicker = (momentsArray) => {
      let newMenu = stripReferences(menuState);

      if (momentsArray.length === 0) {
        newMenu['Plan Status']['Completion Date'].values = null;
        newMenu['Plan Status'].datepicker.values = null;
      } else {
        newMenu['Plan Status'].datepicker.values = {
          startDate: momentsArray[0].format(MOMENT_FORMATTING),
          endDate: momentsArray[1].format(MOMENT_FORMATTING)
        };
      }
      setMenuState(newMenu);
    };

    const onSearch = (value) => {
      setQuery({ search: value }, 'pushIn'); // value in URL
      setValueSearch(value); // value in search input
      let newFilterTags = stripReferences(filterTags);
      let tagSearch = newFilterTags.find((tag) => tag.type === 'search');
      if (value.trim() !== '') {
        if (tagSearch) {
          tagSearch.label = `Search: ${value}`;
        } else {
          tagSearch = {
            key: 'search',
            label: `Search: ${value}`,
            menu: null,
            subMenu: null,
            type: 'search',
            id: null
          };
          newFilterTags.push(tagSearch);
        }
      } else {
        if (tagSearch) {
          newFilterTags = newFilterTags.filter((ele) => ele.type !== 'search');
        }
      }
      // set new Tags
      setFilterTags(newFilterTags);
    };

    const onCheckboxSubMenuClick = (menu) => (e) => {
      let newMenu = stripReferences(menuState);
      let newMenuWithType = newMenu[menu].values;
      let indexMenuWithType = newMenuWithType.findIndex((f) => f.fullName === e.target.id);
      if (menu === 'Assigned To') {
        if (newMenuWithType[indexMenuWithType].isMemberOfAnyGroup) {
          if (e.target.subMenu === 'Assigned Directly') {
            newMenuWithType[indexMenuWithType].isAssignedDirectly = e.target.checked;
          }
          if (e.target.subMenu === 'Assigned Thru Group') {
            newMenuWithType[indexMenuWithType].isAssignedThruGroup = e.target.checked;
          }
          /*
           * when Assgined Directly, Assigned Thru Group are unchecked
           */
          if (
            !newMenuWithType[indexMenuWithType].isAssignedDirectly &&
            !newMenuWithType[indexMenuWithType].isAssignedThruGroup
          ) {
            newMenuWithType[indexMenuWithType].isChecked = e.target.checked;
          }
          /*
           * when Assgined Directly is checked or Assigned Thru Group is checked
           */
          if (
            (newMenuWithType[indexMenuWithType].isAssignedDirectly ||
              newMenuWithType[indexMenuWithType].isAssignedThruGroup) &&
            !newMenuWithType[indexMenuWithType].isChecked
          ) {
            newMenuWithType[indexMenuWithType].isChecked = !newMenuWithType[indexMenuWithType].isChecked;
          }
          /*
           * when Assgined Directly is unchecked and Assigned Thru Group is checked.
           * when Assgined Directly is checked and Assigned Thru Group is unchecked.
           */
          if (
            (!newMenuWithType[indexMenuWithType].isAssignedDirectly &&
              newMenuWithType[indexMenuWithType].isAssignedThruGroup) ||
            (newMenuWithType[indexMenuWithType].isAssignedDirectly &&
              !newMenuWithType[indexMenuWithType].isAssignedThruGroup)
          ) {
            newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = true;
          }
          /*
           * when Assgined Directly is checked or Assigned Thru Group is checked
           */
          if (
            newMenuWithType[indexMenuWithType].isAssignedDirectly &&
            newMenuWithType[indexMenuWithType].isAssignedThruGroup
          ) {
            newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = false;
          }
        }
      }
      if (menu === 'Group') {
        let indexInternalGroupUser = newMenuWithType[indexMenuWithType].internalGroupUsers.findIndex(
          (f) => f.fullName === e.target.subMenu
        );
        newMenuWithType[indexMenuWithType].internalGroupUsers[indexInternalGroupUser].isChecked = e.target.checked;
        /*
         * when Group member are unchecked or checker. we will update status for group name.
         */
        if (
          (newMenuWithType[indexMenuWithType].internalGroupUsers.some((member) => member.isChecked) &&
            !newMenuWithType[indexMenuWithType].isChecked) ||
          !newMenuWithType[indexMenuWithType].internalGroupUsers.some((member) => member.isChecked)
        ) {
          newMenuWithType[indexMenuWithType].isChecked = !newMenuWithType[indexMenuWithType].isChecked;
        }
        /*
         * when a any group member change status to unchecked. we will update status for group name to new icon.
         */
        let listGroupMemberNotSelected = newMenuWithType[indexMenuWithType].internalGroupUsers.filter(
          (i) => !i.isChecked
        );
        if (listGroupMemberNotSelected?.length > 0) {
          newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = true;
        } else {
          newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = false;
        }
        /*
         * If any group has member Unassigned is uncheck, the All Unassigned Tasks should be update to Minus Icon
         */
        updateManualGroupCheckboxState(newMenuWithType);
      }
      setMenuState(newMenu);
    };

    const updateManualGroupCheckboxState = (newMenuWithType) => {
      let groupHasMemberUnassignedIsUnChecked = newMenuWithType.find((menuWithType) =>
        menuWithType.internalGroupUsers.some(
          (groupMember) => groupMember.fullName === 'Unassigned' && !groupMember.isChecked
        )
      );

      let groupHasMemberUnassignedIsChecked = newMenuWithType.find((menuWithType) =>
        menuWithType.internalGroupUsers.some(
          (groupMember) => groupMember.fullName === 'Unassigned' && groupMember.isChecked
        )
      );
      let indexMenuAllUnassignedTasks = newMenuWithType.findIndex((f) => f.fullName === allUnassignedTasksLabel);
      if (indexMenuAllUnassignedTasks !== -1) {
        if (groupHasMemberUnassignedIsUnChecked !== undefined) {
          if (groupHasMemberUnassignedIsChecked !== undefined) {
            newMenuWithType[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = true;
            newMenuWithType[indexMenuAllUnassignedTasks].isChecked = true;
          } else {
            newMenuWithType[indexMenuAllUnassignedTasks].isChecked = false;
            newMenuWithType[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = false;
          }
        } else {
          newMenuWithType[indexMenuAllUnassignedTasks].isChecked = true;
        }
      }
    };
    const onIconShowSubMenuClick = (menu, fullName, id = '') => (_e) => {
      let newMenu = stripReferences(menuState);
      let newMenuWithType = newMenu[menu].values;
      let indexMenuWithType = newMenuWithType.findIndex((f) => {
        if (!!id) {
          return f.id === id;
        } else {
          return f.fullName === fullName;
        }
      });
      if (menu === 'Assigned To') {
        newMenuWithType[indexMenuWithType].isShowSubMenu = !newMenuWithType[indexMenuWithType].isShowSubMenu;
      }
      if (menu === 'Group') {
        newMenuWithType[indexMenuWithType].isShowSubMenu = !newMenuWithType[indexMenuWithType].isShowSubMenu;
      }
      setMenuState(newMenu);
    };

    const createMenu = (menuKey, parentMenuKey, maxHeight = null) => {
      const menu = parentMenuKey ? menuState[parentMenuKey][menuKey] : menuState[menuKey];

      if (menu.type === 'group') {
        return (
          <CommonGroupCheckboxMenu data-max-height={maxHeight}>
            {Object.entries(menu)
              .filter((m) => !m[1].hasOwnProperty('visible') || m[1].visible)
              .filter((m) => !['type'].includes(m[0]))
              .map((m) => createMenu(m[0], menuKey))}
          </CommonGroupCheckboxMenu>
        );
      }

      const disabled = menu.disabled || false;

      if (menu.type === 'checkbox') {
        const onChange = onCheckboxClick(parentMenuKey || menuKey, parentMenuKey ? menuKey : null);
        if (menuKey === 'Assigned To' || menuKey === 'Group') {
          const onChangeSubMenu = onCheckboxSubMenuClick(menuKey);
          if (menuKey === 'Assigned To') {
            return (
              <CommonCheckboxSubMenu>
                {menu?.values.length > 0 ? (
                  menu?.values.map((value, index) =>
                    value.isMemberOfAnyGroup ? (
                      <Menu.ItemGroup key={`group-${menuKey}-${value.id}`} className="submenu-technician">
                        <ParentCheckboxMenuOverride dropdownIsOpen={value.isShowSubMenu}>
                          <Checkbox
                            className={value.isShowMinusOutlinedIcon ? 'group-submenu-main' : ''}
                            id={value.id}
                            fullName={value.fullName}
                            onChange={onChange}
                            disabled={disabled}
                            checked={value.isChecked}
                            style={OptionsStyle}
                          >
                            {userId === value.id ? value.fullName + labelAssigneeToThemself : value.fullName}
                          </Checkbox>
                          <FontAwesomeIcon
                            id={value.fullName}
                            icon={value.isShowSubMenu ? faChevronUp : faChevronDown}
                            onClick={onIconShowSubMenuClick(menuKey, value.fullName, value.id)}
                          />
                        </ParentCheckboxMenuOverride>
                        <Menu.Item
                          className={value.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                          key={menuKey + value.fullName + value.id + 'Assigned Directly'}
                          style={NestedCheckboxOverride}
                        >
                          <Checkbox
                            id={value.fullName}
                            subMenu="Assigned Directly"
                            checked={value.isAssignedDirectly}
                            onChange={onChangeSubMenu}
                          >
                            Assigned Directly
                          </Checkbox>
                        </Menu.Item>
                        <Menu.Item
                          className={value.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                          key={menuKey + value.fullName + value.id + 'Assigned Thru Group'}
                          style={NestedCheckboxOverride}
                        >
                          <Checkbox
                            id={value.fullName}
                            subMenu="Assigned Thru Group"
                            checked={value.isAssignedThruGroup}
                            onChange={onChangeSubMenu}
                          >
                            Assigned Thru Group
                          </Checkbox>
                        </Menu.Item>
                      </Menu.ItemGroup>
                    ) : (
                      <Menu.Item key={value.id}>
                        <Checkbox
                          id={value.id}
                          fullName={value.fullName}
                          disabled={disabled}
                          checked={value.isChecked}
                          onChange={onChange}
                          style={OptionsStyle}
                        >
                          {value.fullName}
                        </Checkbox>
                      </Menu.Item>
                    )
                  )
                ) : (
                  <div style={CommonCheckBoxSubMenuText} className="ant-list ant-list-empty-text">
                    No Technician
                  </div>
                )}
              </CommonCheckboxSubMenu>
            );
          }
          if (menuKey === 'Group') {
            return (
              <CommonCheckboxSubMenu>
                {menu?.values?.length > 0 ? (
                  menu?.values?.map((group, index) => (
                    <Menu.ItemGroup key={`list-${menuKey}-${group.id}`} className="submenu-technician">
                      <ParentCheckboxMenuOverride>
                        <Checkbox
                          id={group.id}
                          fullName={group.fullName}
                          onChange={onChange}
                          disabled={disabled}
                          checked={group.isChecked}
                          className={group.isShowMinusOutlinedIcon ? 'group-submenu-main' : ''}
                        >
                          {group.isYourGroup ? group.fullName + labelGroupToThemself : group.fullName}
                        </Checkbox>
                        {group?.internalGroupUsers?.length > 0 && (
                          <FontAwesomeIcon
                            id={group.fullName}
                            icon={group.isShowSubMenu ? faChevronUp : faChevronDown}
                            onClick={onIconShowSubMenuClick(menuKey, group.fullName)}
                          />
                        )}
                      </ParentCheckboxMenuOverride>
                      {group?.internalGroupUsers?.length > 0 &&
                        group?.internalGroupUsers?.map((member, idx) => (
                          <Menu.Item
                            className={group.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                            key={group.id + member.userId + 'Group'}
                            style={NestedCheckboxOverride}
                          >
                            <Checkbox
                              id={group.fullName}
                              subMenu={member.fullName}
                              checked={member.isChecked}
                              onChange={onChangeSubMenu}
                            >
                              {member.fullName}
                            </Checkbox>
                          </Menu.Item>
                        ))}
                    </Menu.ItemGroup>
                  ))
                ) : (
                  <div style={CommonCheckBoxSubMenuText} className="ant-list ant-list-empty-text">
                    No Internal Groups
                  </div>
                )}
              </CommonCheckboxSubMenu>
            );
          }
        } else {
          const checkboxes = Object.entries(menu.values).map((i) => {
            switch (i[0]) {
              case 'In Progress':
                /**
                 * If it is In Progress, the checkbox will be checked according to i[1] if the request is from another page,
                 * otherwise inProgressFilterState keeps track of the checkbox from the interactions on the current page
                 */
                return (
                  <ParentCheckboxMenuOverride key={i[0]} disabled={disabled}>
                    <Checkbox
                      id={i[0]}
                      checked={inProgressFilterState || i[1]}
                      onChange={onChange}
                      disabled={disabled}
                      indeterminate={atRiskFilterState ^ onTargetFilterState}
                      style={OptionsStyle}
                    >
                      {i[0]}
                    </Checkbox>
                  </ParentCheckboxMenuOverride>
                );
              case 'At Risk':
              case 'On Target':
                /**
                 * If it is At Risk/On Target, the checkbox will be checked according to i[1] if the request is from another page,
                 * otherwise atRiskFilterState/onTargetFilterState keeps track of the checkbox from the interactions on the current page
                 */
                return (
                  <Menu.Item key={i[0]} disabled={disabled}>
                    <Checkbox
                      id={i[0]}
                      checked={i[0] === 'At Risk' ? atRiskFilterState || i[1] : onTargetFilterState || i[1]}
                      onChange={onChange}
                      disabled={disabled}
                      style={NestedCheckboxOverride}
                    >
                      {i[0]}
                    </Checkbox>
                  </Menu.Item>
                );
              case 'Needs Approval':
                let text = flags.reconApproveButton ? 'Pending Approval' : 'Needs Approval';

                return (
                  <Menu.Item key={i[0]} disabled={disabled}>
                    <Checkbox id={i[0]} checked={i[1]} onChange={onChange} disabled={disabled} style={OptionsStyle}>
                      {text}
                    </Checkbox>
                  </Menu.Item>
                );
              default:
                return (
                  <Menu.Item key={i[0]} disabled={disabled}>
                    <Checkbox id={i[0]} checked={i[1]} onChange={onChange} disabled={disabled} style={OptionsStyle}>
                      {i[0]}
                    </Checkbox>
                  </Menu.Item>
                );
            }
          });

          return parentMenuKey ? (
            <StyledMenuItemGroupOverride key={`group-${menuKey}`} title={menuKey} className="filter-title">
              {checkboxes}
            </StyledMenuItemGroupOverride>
          ) : (
            <CommonCheckboxMenu data-max-height={maxHeight} style={options}>
              {checkboxes}
            </CommonCheckboxMenu>
          );
        }
      }

      if (menu.type === 'radio') {
        return (
          <Menu.ItemGroup key={`group-${menuKey}`} title={menuKey}>
            <Radio.Group
              key={`radiogroup-${menuKey}`}
              onChange={onRadioButtonClick(parentMenuKey, menuKey)}
              value={menu.values}
              disabled={disabled}
              style={{ width: '100%' }}
            >
              {menu.options.map((o) => (
                <Radio key={o} value={o}>
                  {o}
                </Radio>
              ))}
            </Radio.Group>
          </Menu.ItemGroup>
        );
      }

      if (menu.type === 'datepicker') {
        let value = [null, null];
        if (menu.values?.startDate && menu.values?.endDate) {
          value = [moment(menu.values.startDate, MOMENT_FORMATTING), moment(menu.values.endDate, MOMENT_FORMATTING)];
        }
        return (
          <StyledRangePicker
            key={`group-${menuKey}`}
            placeholder="Select start date"
            disabled={disabled}
            // Why this `>= availableDateRange[1].startOf('day')` when we don't have to do that in Filter.js? It appears that antd has a bug with checking for disabled dates when you provide the `value` prop
            disabledDate={(current) =>
              current && (current < availableDateRange[0] || current >= availableDateRange[1].startOf('day'))
            }
            onChange={onChangeRangePicker}
            dropdownClassName="dashboardRangePicker"
            bordered={false}
            separator="-"
            format="MM-DD-YYYY"
            mode={['date']}
            value={value}
          />
        );
      }
    };

    const updateManualMenuState = (key, tag, menuState) => {
      if (key && tag && key === tag.menu) {
        if (menuState[key][tag.menu]?.values) {
          if (menuState[key][tag.menu].values[tag.key]) {
            menuState[key][tag.menu].values[tag.key] = false;
          }
        } else if (menuState[tag.menu]?.values) {
          menuState[key].values[tag.key] = false;
        } else if (menuState[tag.menu][tag.subMenu].values) {
          menuState[tag.menu][tag.subMenu].values[tag.key] = false;
        }
      }
    };

    const removedAllTag = (value) => {
      let newMenuState = stripReferences(menuState);

      if (newMenuState['Assigned To']?.values?.length > 0) {
        newMenuState['Assigned To'].values.forEach((user) => {
          user.isAssignedDirectly = false;
          user.isAssignedThruGroup = false;
          user.isChecked = false;
        });
      }
      if (newMenuState['Group']?.values?.length > 0) {
        newMenuState['Group'].values.forEach((group) => {
          group.isChecked = false;
          group.isShowMinusOutlinedIcon = false;
          group.isShowSubMenu = false;
          group.internalGroupUsers.map((member) => {
            member.isChecked = false;
            return member;
          });
          return group;
        });
      }
      let oldFilterTags = filterTags.filter((tag) => tag.menu !== 'Group' && tag.menu !== 'Assigned To');
      if (oldFilterTags && oldFilterTags.length > 0) {
        let indexVehicleActive = oldFilterTags.findIndex((f) => f.menu === 'More' && f.key === 'Active');
        if (indexVehicleActive !== -1) {
          oldFilterTags.splice(indexVehicleActive, 1);
        }
        oldFilterTags.forEach((tag) => {
          Object.keys(newMenuState).forEach((key) => updateManualMenuState(key, tag, newMenuState));
        });
      }

      // add default menu
      newMenuState['More']['Vehicle Status'].values['Active'] = true;
      setMenuState(newMenuState);
    };

    const getLabelName = (menuName) => {
      return menuName === 'Assigned To' && flags.reconVendorManagement ? 'Technician' : menuName;
    };

    const checkHQView = (menuName) => {
      return (
        isHQ &&
        (menuName === 'Task Type' || menuName === 'Vendor' || menuName === 'Group' || menuName === 'Assigned To')
      );
    };

    const showLoadingFilter = (menuName) => {
      // I need check off toogle.
      // Because Button Group will displayed.
      if (
        (!flags.reconVendorManagement && menuName === 'Vendor') ||
        (menuName === 'Dealership' && (currentDealer?.entityType === ewsEntityTypes.DEALER || !flags.ireconHqView)) ||
        (menuName === 'ProfitTime' && !hasReconProfitTime) ||
        checkHQView(menuName)
      ) {
        return <></>;
      }
      return <MultiSelect label={getLabelName(menuName)} menu={<CommonCheckboxMenu />} loading marginLeft={8} />;
    };

    const showCheckedCount = (menuName) => {
      if (checkedCount[menuName] > 0) {
        var num = checkedCount[menuName];
        return ` (${num})`;
      }

      return '';
    };

    return (
      <StyledCommonControlsContainer>
        <CommonFiltersRow>
          <Text className="label">Filters</Text>
          {Object.keys(menuState).map((menuName) => (
            <CommonSelectorContainer key={`menu-${menuName}`}>
              {isLoading ? (
                showLoadingFilter(menuName)
              ) : checkHQView(menuName) ? (
                <></>
              ) : (
                <MultiSelect
                  label={`${getLabelName(menuName)}${showCheckedCount(menuName)}`}
                  menu={createMenu(menuName)}
                  marginLeft={8}
                />
              )}
            </CommonSelectorContainer>
          ))}
          <StyledSearchContainer>
            {isLoading ? (
              <CommonSearch placeholder="Search" prefix={<FontAwesomeIcon icon={faSearch} />} disabled />
            ) : (
              <CommonSearch
                data-is-focused={searchBoxIsFocused}
                placeholder={searchBoxIsFocused ? 'Type to begin searching...' : 'Search'}
                prefix={<FontAwesomeIcon icon={faSearch} />}
                onFocus={(e) => setSearchBoxIsFocused(true)}
                onBlur={(e) => setSearchBoxIsFocused(false)}
                value={valueSearch}
                onChange={(e) => {
                  setValueSearch(e.target?.value);
                }}
                onSearch={(e) => {
                  onSearch(e);
                }}
                defaultValue={search}
              />
            )}
          </StyledSearchContainer>
        </CommonFiltersRow>

        <FilterTags
          filterTags={filterTagsPresentation}
          removeFilterTag={removeFilterTag}
          vehiclesFetchStatus={vehiclesFetchStatus}
          resetLink={`/inventory?vehicleStatus=Active&sort=${sortOptionDefault.VEHICLE}`}
          setValueSearch={onSearch}
          removedAllTag={removedAllTag}
          flags={flags}
        />
      </StyledCommonControlsContainer>
    );
  }
);

//#region Styled Components
const StyledCommonControlsContainer = styled(CommonControlsContainer)`
  .ant-affix & {
    border-bottom: ${({ theme }) => `1px solid ${theme.borderColors.gray}`};
  }
`;
const StyledSearchContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const StyledRangePicker = styled(RangePicker)`
  &.ant-calendar-picker {
    color: ${({ theme }) => theme.colors.navy};
    width: 220px;
  }

  .ant-calendar-range-picker-input {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.gray : theme.colors.red)};
    font-weight: ${({ disabled, theme }) => (disabled ? 'initial' : theme.fontWeights.bold)};
  }

  .ant-calendar-picker-input {
    border: none;
    &.ant-input.ant-input-disabled {
      background-color: initial;
    }
  }

  .anticon-close-circle,
  .ant-calendar-range-picker-input::placeholder,
  .ant-calendar-range-picker-separator {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.gray : theme.colors.red)};
  }
`;

const StyledDropdownSubMenuItem = css`
  .submenu-technician {
    .ant-dropdown-menu-item-group-list {
      margin: 0px;
      .ant-menu-vertical {
        border-right: none;
        i {
          font-size: 12px;
          margin-right: 8px;
        }
      }
      .submenu-technician-hidden {
        display: none;
      }
      .group-submenu-main {
        .ant-checkbox-checked {
          .ant-checkbox-inner::after {
            border-left: 2px solid #fff;
            border-right: 0;
            border-bottom: 0;
            transform: rotate(90deg) scale(1) translate(-50%, -10%);
          }
        }
      }
      .fa-chevron-down {
        margin-right: 8px;
        height: 12px;
        width: 12px;
        :hover {
          cursor: pointer;
        }
      }
      .fa-chevron-up {
        margin-right: 8px;
        height: 12px;
        width: 12px;
        :hover {
          cursor: pointer;
        }
      }
      .ant-dropdown-menu-item {
        label {
          padding: 0px;
          font-size: ${({ theme }) => theme.fontSizes.sm};
        }
      }
    }
    .ant-dropdown-menu-item-group-title {
      display: none;
    }
  }
`;

const CommonCheckboxSubMenu = styled(CommonCheckboxMenu)`
  ${StyledDropdownSubMenuItem}
`;

const ParentCheckboxMenuOverride = styled(Menu)`
  display: flex;
  align-items: center;
  padding: 0;
  height: 32px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: none;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.white};
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: ' ';
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.red};
      border-radius: 4px;
      border: unset;
    }
    .ant-checkbox-inner {
      border-radius: 4px;
    }
    .ant-checkbox-checked::after {
      border: unset;
    }
  }

  .ant-menu& .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-menu& .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
  }
`;

const StyledMenuItemGroupOverride = styled(Menu.ItemGroup)`
  div.ant-dropdown-menu-item-group-title {
    font-size: ${({ theme }) => theme.fontSizes.sm} !important;
  }
`;

const NestedCheckboxOverride = {
  padding: '0px 0px 0px 38px',
  fontSize: '14px'
};

const OptionsStyle = {
  fontSize: '14px'
};

const CommonCheckBoxSubMenuText = {
  padding: '8px',
  fontSize: '14px'
};

//#endregion
export default withLDConsumer()(VehicleFilters);
