import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tasksActions } from 'store/tasksStore';
import { SavingTaskOutline } from '../.';
import { TaskForm } from 'components/layout/inventory/reconPlan';
import StandaloneTask from 'components/TaskGroupsDnd/Task/StandaloneTask';
import { DrawerContainer } from 'components';
import { CommonHeaderLabel } from 'components/styledComponents';
import { features } from 'app-constants';
import { useFeatures } from 'hooks';

const TaskFormDrawer = ({
  task,
  closeDrawer,
  onForm,
  setOnForm,
  isDecliningTask = false,
  showDiscardButton,
  setShowDiscardButton,
  handleCanOpenTDP,
  user = {},
  contentType
}) => {
  const dispatch = useDispatch();

  // StandaloneTask relies on the prop formProps to for its overflow menu (which in this instance, will only
  // Display the "Edit" option, if the user has access to edit a completed task (TASKS_WORK_ALL or TASKS_WORK_OWN and its their own)
  // Since The TDP and Tasks page don't use formProps like the VDP/RPB/Inventory pages do,
  // we instead just want to watch for changes in the formProps prop. For that reason, when we pass it into TaskForm,
  // we're calling it fakeFormProps. This allows the TDP and Tasks page to recognize that the user wants to edit the
  // given completed task, and can update the UI to show the TaskForm component accordingly.
  const [fakeFormProps, setFakeFormProps] = useState(null);
  const [hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask] = useFeatures(
    features.TASKS_EDIT,
    features.TASKS_WORK_OWN,
    features.TASKS_WORK_ANY
  );

  const headerLabel = () => {
    if (!task.completedOn && !isDecliningTask) return 'Complete Task';
    if (isDecliningTask) return 'Decline Task';
    if (onForm) return 'Edit Completed Task';
    return 'Task Completed';
  };

  const onUpdateCompletedTask = (task) => {
    dispatch(tasksActions.updateTask(task.id, task));
  };

  const onComplete = ({ id, completedCost, completionNote, assignedToGroupId, assignedToGroupType }) => {
    dispatch(tasksActions.completeTask(id, completedCost, completionNote, assignedToGroupId, assignedToGroupType));
  };

  const onDecline = ({ declinedReason, assignedToGroupId, assignedToGroupType }) => {
    devLogger.log(`dispatching - reason: ${declinedReason}`);
    dispatch(tasksActions.declineTask(user.id, task.id, declinedReason, assignedToGroupId, assignedToGroupType));
  };

  useEffect(() => {
    if (onForm && task.saving) {
      setOnForm(false);
      setFakeFormProps(null);
    }
  }, [task.saving]);

  useEffect(() => {
    if (!onForm && fakeFormProps !== null) {
      setOnForm(true);
    }
  }, [fakeFormProps]);

  return (
    <DrawerContainer
      headerLabel={<CommonHeaderLabel>{headerLabel()}</CommonHeaderLabel>}
      vehicle={task.vehicle}
      showPhoto={!onForm}
      showBackButton={false}
      handleBackButtonClick={() => setOnForm(false)}
      isSaving={task.saving}
      onClose={(e) => closeDrawer(e)}
      onHeaderClick={handleCanOpenTDP}
    >
      {task.saving ? (
        <div style={{ margin: '24px' }}>
          <SavingTaskOutline />
        </div>
      ) : onForm ? (
        <div style={{ paddingTop: '24px', height: '100%' }}>
          <TaskForm
            parentId={task.vehicle.id}
            taskToUpdate={task}
            count={1}
            onUpdate={task.completedOn ? onUpdateCompletedTask : null}
            onComplete={!(task.completedOn || isDecliningTask) && onComplete}
            onDecline={isDecliningTask ? onDecline : null}
            showDiscardButton={showDiscardButton}
            setShowDiscardButton={setShowDiscardButton}
            hidePassthroughIndicator={true}
            closeForm={closeDrawer}
            formHeight={'calc(100vh - 236px)'}
            contentType={contentType}
            onTLP
          />
        </div>
      ) : (
        <div style={{ margin: '24px' }}>
          <StandaloneTask
            key={task.id}
            editable={true}
            setFormProps={setFakeFormProps}
            vehicleId={task.vehicle.id}
            task={task}
            hidePassthroughIndicator={true}
            hideAssigneeWorkload={true}
            showCompletedTag={true}
            reconPlanFeatures={[hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask]}
            hideMessageOverflowMenuItem={true}
            hidePassthroughOverflowMenuItem={true}
            contentType={contentType}
          />
        </div>
      )}
    </DrawerContainer>
  );
};

export default TaskFormDrawer;
