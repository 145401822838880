import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { journalActions } from 'store/journalStore';
import VehicleHistoryDetail from './VehicleHistoryDetail';
import { apiStatusConstants } from 'app-constants';
import { LoadingCenter } from 'components';

const VehicleHistory = ({ vehicle, vehicleHistory, documents, flags }) => {
  const dispatch = useDispatch();
  const vehicleHistoryData = useSelector((state) => state.journal.vehicleHistoryData);
  const vehicleHistoryFetchStatus = useSelector((state) => state.journal.vehicleHistoryFetchStatus);
  useEffect(() => {
    dispatch(journalActions.getVehicleHistoryData(vehicle.id, 1, 1000));
  }, [vehicleHistory, vehicle, documents?.length]);
  const originHistory = vehicleHistoryData?.items ?? [];
  const reversedHistory = [...originHistory].sort((leftDate, rightDate) => new Date(rightDate.journalCreatedOn) - new Date(leftDate.journalCreatedOn));
  let history = flags.reconHistoryUpdate ? reversedHistory : originHistory;
  history = history.filter(h => h.reconJournalEventType !== 'LINE_ITEM_REMOVED');

  return vehicleHistoryFetchStatus === apiStatusConstants.IS_FETCHING ? (
    <LoadingCenter />
  ) : (
    <StyledList
      locale={{ emptyText: 'No history to display' }}
      dataSource={history}
      renderItem={(vehicleHistoryEvent) => (
        <VehicleHistoryDetail vehicleHistoryEvent={vehicleHistoryEvent} vehicle={vehicle} />
      )}
    />
  );
};
const StyledList = styled(List).attrs({
  size: 'small'
})`
  margin-right: 15px;
  li:first-child {
    margin-top: 0;
  }
  li.ant-list-item {
    padding-top: 0;
    padding-bottom: 12px;
  }
`;

export default withLDConsumer()(VehicleHistory);
