import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Row, Col, Typography } from 'antd';
import React, { isValidElement } from 'react';
import {
  StyledLayout,
  StyledLayoutContent,
  StyledLayoutHeader,
  StyledLayoutFooter,
  StyledButton
} from 'components/layout/GeneralSetting/style';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const { Text } = Typography;

const CommonModal = ({
  open,
  onClose,
  onAccept,
  isLoading = false,
  title,
  buttonText,
  bodyTexts,
  cancelText,
  height = 380,
  width = 550,
  icon = faInfoCircle,
  iconStyle = {}
}) => {
  return (
    <StyledModal
      visible={open}
      footer={null}
      closable={null}
      _modalHeight={height}
      _modalWidth={width}
      centered
      maskClosable={false}
      onCancel={onClose}
    >
      <StyledLayout>
        <StyledLayoutHeader>
          <Row type="flex" align="middle" justify="start">
            <Col xs={2}>
              <Row type="flex" align="middle">
                <FontAwesomeIcon icon={icon} size="lg" style={iconStyle} />
              </Row>
            </Col>

            <Col xs={22}>
              <StyledHeaderText>{title}</StyledHeaderText>
            </Col>
          </Row>
        </StyledLayoutHeader>

        <StyledLayoutContent style={{ paddingTop: '8px' }}>
          <Row type="flex" align="middle" justify="end">
            {bodyTexts.map((bodyText, index) => (
              <Col xs={22} style={{ marginTop: index !== 0 ? '14px' : '' }} key={bodyText + uuidv4()}>
                {isValidElement(bodyText) ? bodyText : <StyledModalBodyText>{bodyText}</StyledModalBodyText>}
              </Col>
            ))}
          </Row>
        </StyledLayoutContent>

        <StyledLayoutFooter style={{ marginTop: '24px' }}>
          <Row type="flex" justify="end" align="middle" gutter={8}>
            <Col>
              <StyledButton onClick={onClose} ghost>
                {cancelText}
              </StyledButton>
            </Col>

            <Col>
              <StyledButton loading={isLoading} onClick={onAccept}>
                {buttonText}
              </StyledButton>
            </Col>
          </Row>
        </StyledLayoutFooter>
      </StyledLayout>
    </StyledModal>
  );
};

const StyledHeaderText = styled(Text)`
  &.ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: ${({ theme }) => theme.lineHeights.md};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

export const StyledModalBodyText = styled(Text)`
  &.ant-typography {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    letter-spacing: ${({ theme }) => theme.letterSpacings.md};
    color: ${({ theme }) => theme.colors.navy};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  &.ant-typography strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

const StyledModal = styled(({ _modalHeight, _modalWidth, ...rest }) => <Modal {...rest} />)`
  &.ant-modal {
    width: ${({ _modalWidth }) => _modalWidth}px !important;
    margin-top: 0;
    margin-bottom: 0;

    ${({ _modalHeight }) =>
      _modalHeight &&
      _modalHeight > 0 &&
      `
    @media (max-height: ${_modalHeight + 1}px) {
      margin-top: 2.5vh;
      height: 95vh;
    }
    `}
  }

  ${({ _modalHeight }) =>
    _modalHeight &&
    _modalHeight > 0 &&
    `
  .ant-modal-content {
    @media (min-height: ${_modalHeight + 1}px) {
      height: ${_modalHeight}px;
    }
    @media (max-height: ${_modalHeight + 1}px) {
      height: 95vh;
    }
  }
  `}

  .ant-modal-body {
    height: 100%;
  }
`;

export default CommonModal;
