import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Radio, Switch } from 'antd';
import { vendorUserRoles } from 'app-constants/vendorUserConstants';
import { CommonLinkButton, CommonRedButton } from 'components/styledComponents';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StyledForm, StyledFormItem } from '../../../../components/layout/settings/common/Styles';
import { vendorActions } from '../../../../store/vendorStore';
import { usersActions } from '../../../../store/usersStore';
import FormModal from '../../../modals/FormModal';
import { theme } from 'styles/appTheme';
import { apiStatusConstants } from '../../../../app-constants';
import moment from 'moment';
import { elapsedHours } from '../../../../utils/dateTimeUtils';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const MIN_HOURS_TO_RESEND_INVITE = 24;
const MAX_LENGTH = 120;

const VendorUserDetails = ({ form, closeDrawer, vendorUser, vendorUsers, vendor, putStatus }) => {
  const vendorTech = vendorUserRoles[0].name;
  const vendorAdmin = vendorUserRoles[1].name;

  const { getFieldDecorator, setFieldsValue, validateFields } = form;
  const [toggleValue, setToggleValue] = useState(vendorUser.isPrimaryContact);
  const [roleValue, setRoleValue] = useState(vendorUser.role.name);
  const [modalType, setModalType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [isResendDone, setIsResendDone] = useState(false);
  const [isAdmin, setIsAdmin] = useState(vendorUser.role.name === vendorAdmin);

  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const shouldBeDisabledDueToRootUSer = useMemo(() => {
    //for Root Users, changing role is not available to users who are
    //1.registered admin
    //2.only 1 registered admin user in vendor
    return (
      isRootUser &&
      vendorShipSelected &&
      vendorUser?.onboardingStatus === 'REGISTERED' &&
      vendorUser?.role?.id === 1 &&
      vendorUsers?.filter((vu) => vu.onboardingStatus === 'REGISTERED' && vu.role?.id === 1)?.length === 1
    );
  }, [isRootUser, vendorShipSelected, vendorUsers, vendorUser]);

  let formAction;
  let newPrimaryContact;
  const isLoading = putStatus === apiStatusConstants.PENDING;

  const dispatch = useDispatch();

  const filteredUsers = vendorUsers.filter(
    (user) =>
      user.id !== vendorUser.id && user.role.name === vendorUserRoles[1].name && user.onboardingStatus === 'REGISTERED'
  );
  const sortedUsers = filteredUsers.sort((a, b) => a.fullName.localeCompare(b.fullName));

  // checks if call succeeded or failed and modifies state, closes modal, and sets putStatus to an empty string
  useEffect(() => {
    if (putStatus === apiStatusConstants.SUCCEEDED) {
      setIsModalOpen(false);
      if (modalType === 'change tech' || modalType === 'simple role change') {
        setIsAdmin(!isAdmin);
        if (roleValue === vendorTech) {
          setRoleValue(vendorAdmin);
        } else {
          setRoleValue(vendorTech);
        }
      }
      if (
        modalType === 'change tech' ||
        modalType === 'confirm primary contact' ||
        modalType === 'switch primary contact'
      ) {
        setToggleValue(!toggleValue);
      }
      dispatch(vendorActions.setPutStatus(''));
      if (isResendDone) {
        closeDrawer();
      }
      setIsResendModalOpen(false);
    } else if (putStatus === apiStatusConstants.FAILED) {
      setIsModalOpen(false);
      if (modalType === 'change tech' || modalType === 'simple role change') {
        handleCancel();
      }
      dispatch(vendorActions.setPutStatus(''));
      setIsResendModalOpen(false);
      setIsResendDone(false);
    }
  }, [putStatus]);

  // Set modal information based off of modalType
  const getModalTitle = () => {
    switch (modalType) {
      case 'simple role change':
        return isAdmin ? 'Remove Admin Role' : 'Confirm Admin';
      case 'change tech':
        return 'This User is the Primary Contact';
      case 'confirm primary contact':
        return 'Confirm Primary Contact';
      case 'switch primary contact':
        return 'This User is the Primary Contact';
      default:
        return '';
    }
  };

  const getButtonText = () => {
    if (isLoading) {
      switch (modalType) {
        case 'simple role change':
          return isAdmin ? 'Removing' : 'Confirming';
        case 'change tech':
          return 'Switching';
        case 'confirm primary contact':
          return 'Confirming';
        case 'switch primary contact':
          return 'Switching';
        default:
          return '';
      }
    } else {
      switch (modalType) {
        case 'simple role change':
          return isAdmin ? 'Remove Admin Role' : 'Confirm';
        case 'change tech':
          return 'Switch Primary Contact';
        case 'confirm primary contact':
          return 'Confirm Primary Contact';
        case 'switch primary contact':
          return 'Switch Primary Contact';
        default:
          return '';
      }
    }
  };

  const getModalContent = () => {
    switch (modalType) {
      case 'simple role change':
        return isAdmin ? <RemoveAdmin /> : <ConfirmAdmin />;
      case 'change tech':
        return <TechSwitchPrimary />;
      case 'confirm primary contact':
        return <ConfirmPrimary />;
      case 'switch primary contact':
        return <SwitchPrimary />;
      default:
        return <NoText />;
    }
  };

  // Different styled modal Content, these are the choices within getModalContent
  const ConfirmAdmin = () => {
    return (
      <p>
        You have selected to make <span style={{ fontWeight: theme.fontWeights.medium }}>{vendorUser.fullName}</span> an
        admin. This will enable them to add new techs, assign and unassign tasks to any tech, and promote techs to
        admins.
      </p>
    );
  };

  const RemoveAdmin = () => {
    return (
      <p>
        Are you sure you want to remove the role of admin from{' '}
        <span style={{ fontWeight: theme.fontWeights.medium }}>{vendorUser.fullName}</span>? This will remove their
        ability to assign tasks to other techs, add new techs, and promote other techs to admins.
      </p>
    );
  };

  const ConfirmPrimary = () => {
    return (
      <p>
        By selecting <span style={{ fontWeight: theme.fontWeights.medium }}>{vendorUser.fullName}</span> to be your
        primary contact, their name will appear in Dealership searches for your business. They will replace the existing
        Primary Contact.
      </p>
    );
  };

  const SwitchPrimary = () => {
    return (
      <p>
        In order to remove this person as a Primary Contact, please select another admin to be the new Primary Contact
        first.
      </p>
    );
  };

  const TechSwitchPrimary = () => {
    return (
      <p>In order to change this person to a Tech, you must first select another admin to be the Primary Contact.</p>
    );
  };

  const NoText = () => {
    return <p>There was an issue loading this text. Please close the modal and try again.</p>;
  };

  // Switch to choose toast message depending on form action
  const getToastMessage = () => {
    switch (formAction) {
      case 'Change role to Vendor Technician':
        return {
          successMessageReason: 'made a tech',
          errorMessageReason: 'the role'
        };
      case 'Change role to Vendor Admin':
        return {
          successMessageReason: 'made an admin',
          errorMessageReason: 'the role'
        };
      case 'Assign new Primary Contact':
        return {
          successMessageReason: 'made the Primary Contact',
          errorMessageReason: 'the Primary Contact'
        };
      default:
        return {
          successMessageReason: 'updated',
          errorMessageReason: 'the user'
        };
    }
  };

  const resendInviteOption = () => {
    if (vendorUser.onboardingStatus !== 'ADDED') {
      return;
    }

    // Incoming time is in UTC, so it's converted to local time.
    const formattedLastInviteDatetime = moment(vendorUser.onboardingStatusDate).format();
    const timeSinceLastInviteSent = elapsedHours(formattedLastInviteDatetime, Date.now());

    // Only show resend button if 24 hrs have elapsed since most invite was sent or when the last invite sent failed
    // FirstTimeInviteSent present for one time exception after first time sent invite
    if (
      timeSinceLastInviteSent >= MIN_HOURS_TO_RESEND_INVITE ||
      !vendorUser.inviteSent ||
      vendorUser.firstTimeInviteSent
    ) {
      return (
        <CommonLinkButton
          style={{
            color: theme.colors.red,
            fontSize: theme.fontSizes.md
          }}
          onClick={() => setIsResendModalOpen(true)}
        >
          Resend Invite
        </CommonLinkButton>
      );
    } else {
      const timeUntilResend = 24 - timeSinceLastInviteSent;
      if (timeUntilResend >= 1) {
        return <StyledGrayText>You may send another invite in {Math.round(timeUntilResend)} hours.</StyledGrayText>;
      } else {
        return <StyledGrayText>You may send another invite in less than 1 hour.</StyledGrayText>;
      }
    }
  };

  const getAddOnToastMessage = () => {
    return `, and ${newPrimaryContact?.firstName} ${newPrimaryContact?.lastName} has been made the Primary Contact`;
  };

  // Checks if user is trying to switch primary contact to tech
  const handleRoleClick = () => {
    if (!toggleValue) {
      setModalType('simple role change');
    } else {
      setModalType('change tech');
    }
    setIsModalOpen(true);
  };

  // If user exits modal, the user roles are reverted to their initial values
  const handleCancel = () => {
    setFieldsValue({
      role: roleValue
    });
  };

  // handles most updates to current vendor user (change role or make primary contact)
  const handleUpdate = () => {
    validateFields((err, values) => {
      if (!err) {
        if (modalType === 'confirm primary contact') {
          formAction = 'Assign new Primary Contact';
        } else if (modalType === 'simple role change') {
          if (values.role === 'Vendor Admin') {
            formAction = 'Change role to Vendor Admin';
          } else {
            formAction = 'Change role to Vendor Technician';
          }
        }
        handleSave(values, vendorUser);
      }
    });
  };

  // handles the primary contact switch from current vendor to other user from dropdown selection
  const handleSwitch = (values) => {
    formAction = 'Assign new Primary Contact';
    const switchedPrimaryUser = sortedUsers.find((user) => user.id === values.primaryContact);
    let formValues;
    formValues = {
      role: switchedPrimaryUser.role.name,
      isPrimaryContact: true
    };
    handleSave(formValues, switchedPrimaryUser);
    // if primary contact was switched through change tech modal, handles update to current vendor user from admin to tech
    if (modalType === 'change tech') {
      formAction = 'Change role to Vendor Technician';
      formValues = {
        role: vendorTech,
        isPrimaryContact: false
      };
      newPrimaryContact = {
        firstName: switchedPrimaryUser.firstName,
        lastName: switchedPrimaryUser.lastName
      };
      handleSave(formValues, vendorUser);
    }
  };

  // handles save to store and BE
  const handleSave = (formValues, user) => {
    const role = vendorUserRoles.find((r) => r.name === formValues.role);
    const updatedVendorUser = {
      vendorId: user.vendorId,
      username: user.email,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      role: { id: role.id, name: role.label },
      preferredContactMethodType: 'EMAIL',
      isPrimaryContact: formValues.isPrimaryContact
    };
    const { successMessageReason, errorMessageReason } = getToastMessage();
    const successMessage = `${updatedVendorUser.firstName} ${
      updatedVendorUser.lastName
    } has been ${successMessageReason}${newPrimaryContact ? getAddOnToastMessage() : ''}`;
    const errorMessage = `​​​An error occurred while updating ${errorMessageReason}`;
    dispatch(vendorActions.updateVendorUser(user.id, updatedVendorUser, successMessage, errorMessage));
  };

  const handleResendInvite = (values) => {
    dispatch(usersActions.resendVendorUserInvite(vendorUser.id, vendorUser.vendorId, values.message));

    setIsResendDone(true);
  };

  return (
    <>
      <StyledForm>
        <StyledVendorDetailWrapper>
          <StyledSectionWrapper style={{ paddingTop: 24 }}>
            <StyledSpacedRow>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="iconDiv">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <StyledCol>
                  <span>{vendorUser.fullName}</span>
                  <span>{vendorUser.phone}</span>
                  <span>{vendorUser.email}</span>
                </StyledCol>
              </div>
              <StyledText style={{ fontSize: theme.fontSizes.sm }}>{vendorUser.status.replace(/,/g, ':')}</StyledText>
            </StyledSpacedRow>
          </StyledSectionWrapper>
          <StyledSectionWrapper>
            <StyledFormItem
              label="User Role"
              className={`roleDiv ${
                (vendorUser.onboardingStatus === 'DEACTIVATED' ||
                  vendorUser.id === vendor.id ||
                  shouldBeDisabledDueToRootUSer) &&
                'disabled'
              }`}
              style={{ paddingBottom: 16 }}
            >
              {getFieldDecorator('role', {
                initialValue: roleValue
              })(
                <Radio.Group>
                  <StyledRow>
                    {vendorUserRoles.map((role) => (
                      <Radio
                        value={role.name}
                        key={role.id}
                        style={{ fontSize: theme.fontSizes.md }}
                        onClick={() => {
                          if (role.name !== roleValue) {
                            handleRoleClick();
                          }
                        }}
                        disabled={
                          vendorUser.onboardingStatus === 'DEACTIVATED' ||
                          vendorUser.id === vendor.id ||
                          shouldBeDisabledDueToRootUSer
                        }
                      >
                        {role.label}
                      </Radio>
                    ))}
                  </StyledRow>
                </Radio.Group>
              )}
            </StyledFormItem>
            <StyledFormItemRow
              label="Primary Contact"
              className={
                (roleValue === vendorTech ||
                  vendorUser.onboardingStatus !== 'REGISTERED' ||
                  sortedUsers.length === 0) &&
                'disabled'
              }
              style={{ paddingTop: 16 }}
            >
              {getFieldDecorator('isPrimaryContact', { initialValue: toggleValue })(
                <Switch
                  checked={toggleValue}
                  disabled={
                    roleValue === vendorTech || vendorUser.onboardingStatus !== 'REGISTERED' || sortedUsers.length === 0
                  }
                  onClick={(e) => {
                    if (e) {
                      setModalType('confirm primary contact');
                    } else {
                      setModalType('switch primary contact');
                    }
                    setIsModalOpen(true);
                  }}
                />
              )}
            </StyledFormItemRow>
            <StyledSpan>*User must be an Administrator to be the Primary Contact</StyledSpan>
          </StyledSectionWrapper>
        </StyledVendorDetailWrapper>

        <StyledButtonContainer>
          {resendInviteOption()}
          {/* Commenting out deactivated user as this has no functionality at the moment but may be used in the future */}
          {/* <CommonRedButton className="ant-btn-background-ghost">Deactivate User</CommonRedButton> */}
          <CommonRedButton onClick={closeDrawer}>Close</CommonRedButton>
        </StyledButtonContainer>
      </StyledForm>
      <FormModal
        visible={isModalOpen}
        handleSave={(values) => {
          if (modalType === 'confirm primary contact' || modalType === 'simple role change') {
            handleUpdate();
          } else {
            handleSwitch(values);
          }
        }}
        handleCancel={handleCancel}
        closeModal={() => {
          setIsModalOpen(false);
          if (modalType === 'change tech' || modalType === 'simple role change') {
            handleCancel();
          }
        }}
        title={getModalTitle()}
        icon={faQuestionCircle}
        buttonText={getButtonText()}
        dropdown={
          modalType === 'confirm primary contact' || modalType === 'simple role change'
            ? false
            : {
                label: 'New Primary Contact',
                isRequired: true,
                values: sortedUsers
              }
        }
        isSaving={isLoading}
      >
        {getModalContent()}
      </FormModal>
      <FormModal
        visible={isResendModalOpen}
        handleSave={handleResendInvite}
        closeModal={() => setIsResendModalOpen(false)}
        title="Resend Invite"
        icon={faQuestionCircle}
        buttonText={isLoading ? 'Resending' : 'Resend Invite'}
        isSaving={isLoading}
        message={{
          label: 'Message to User',
          maxLength: MAX_LENGTH,
          placeholder: 'Include a message to the user'
        }}
      >
        Are you sure you want to add <StyledVendorUserName>{vendorUser.fullName}</StyledVendorUserName> as a user?
      </FormModal>
    </>
  );
};

const StyledVendorDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  div.ant-form-explain {
    margin-top: 8px;
  }
  div.iconDiv {
    margin-right: 12px;
    width: 17.5px;
  }
  div.ant-row.ant-form-item {
    padding-bottom: 0;
  }
  label.ant-radio-wrapper {
    padding: 0px;
  }
  .ant-radio-wrapper:hover:not(.ant-radio-wrapper-disabled) {
    background-color: transparent;
  }
  label.ant-radio-wrapper + label.ant-radio-wrapper {
    margin-left: 24px;
  }
  label.ant-radio-wrapper span {
    padding-right: 0px;
  }
  div.disabled div.ant-form-item-label label {
    color: ${theme.colors.lightNavy};
  }
  .roleDiv {
    border-bottom: ${theme.borders.grayLineItemBorder};
  }
`;

const StyledSectionWrapper = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #cacfd9;
  span + span {
    padding-top: 4px;
  }
  .inviteMessage {
    padding-bottom: 16px;
  }
  .inviteMessage + .inviteMessage {
    padding-top: 16px;
    border-top: ${theme.borders.grayLineItemBorder};
  }
  .inviteMessage:last-child {
    padding-bottom: 0px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledSpacedRow = styled(StyledRow)`
  justify-content: space-between;
`;

const StyledFormItemRow = styled(StyledFormItem)`
  flex-direction: row !important;
  align-items: center !important;
  div.ant-col.ant-form-item-label {
    margin-bottom: 0 !important;
    margin-right: 10px;
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.div`
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSizes.xs};
`;

const StyledSpan = styled.span`
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSizes.xs};
  font-weight: ${theme.fontWeights.medium};
`;

const StyledButtonContainer = styled(StyledRow)`
  padding: 20px 24px;
  /* use this if bringing back in deactivated user button */
  /* justify-content: space-between; */
  justify-content: space-between;
  * + * {
    margin-left: 8px;
  }
  border-top: 1px solid #cacfd9;
  position: absolute;
  bottom: 0;
  background-color: white;
`;

const StyledGrayText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

const StyledVendorUserName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export default Form.create()(VendorUserDetails);
