// Util Libraries
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';
import styled from 'styled-components';
// Components (npm then local)
import { Typography } from 'antd';
import { CustomizedTaskIcon } from 'components/layout/tasks/icons';
import { CircularSpinner, ResponsiveStack, Stack } from 'components';
import { ReconTag, ReconTaskWarningTag, reconTagTypes } from 'components/tags';
// Components (npm then local)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { getTaskProgress, getNeedsApprovalEstimateRequest } from 'components/layout/tasks/common/taskUtils';
// Utils (local)
import { taskProgressLabels } from 'app-constants';
import { formatDateTime, displayDurationSummary, toMomentDuration } from 'utils/dateTimeUtils';
import { formatPrice } from 'utils/numberUtils';
import { COMPLETED, PENDING, DECLINED } from 'app-constants/taskStatusTypes';
import { DEALER_INTERNAL_GROUP, VENDOR } from 'app-constants/groupTypes';
import useVendorRole from '../../../../hooks/useVendorRole';
import { useSelector } from 'react-redux';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { Text } = Typography;

export const TaskInfo = withLDConsumer()(({ task, screenWidthIsMax1200px, flags }) => {
  const pendingApprovalLineItemCount = getNeedsApprovalEstimateRequest(task?.lineItems);
  return (
    <Stack horizontal>
      {task.saving ? (
        <CenteredContainer screenSize={screenWidthIsMax1200px}>
          <CircularSpinner
            size={48}
            additionalStyles={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block'
            }}
          />
        </CenteredContainer>
      ) : (
        <>
          <StyledTaskIconContainer reconTaskCollapseFlag={flags.reconTaskCollapse}>
            <CustomizedTaskIcon task={task} isShowCompletedIcon={true} isShowTaskClipboardIcon={true} />
          </StyledTaskIconContainer>
          <Stack>
            <ResponsiveStack
              horizontal
              mediaQueryBoolean={screenWidthIsMax1200px}
              stylesWhenMediaQueryThresholdIsMet={{ width: '260px' }}
              stylesWhenMediaQueryThresholdIsNotMet={{ width: '300px' }}
            >
              <StyledTitle className={'medium-font strong'}>{task.reconTaskTypeName}</StyledTitle>
              {task.declined || task.status === DECLINED ? (
                <ReconTag type={reconTagTypes.DECLINED} />
              ) : pendingApprovalLineItemCount > 0 ? (
                <ReconTag
                  type={flags.reconApproveButton ? reconTagTypes.PENDING_APPROVAL : reconTagTypes.NEEDS_APPROVAL}
                />
              ) : task.status === COMPLETED ? (
                <ReconTag type={reconTagTypes.COMPLETED} />
              ) : task.status === PENDING ? (
                <ReconTag type={reconTagTypes.PENDING} />
              ) : (
                <ReconTaskWarningTag task={task} />
              )}
            </ResponsiveStack>
            <Text>
              <AssigneeNameDisplayingTLP task={task} flags={flags} />
            </Text>
            {task.completedOn && <Text>{formatDateTime(task.completedOn)}</Text>}
          </Stack>
        </>
      )}
    </Stack>
  );
});

/**
 * Vendors Technician Name Displaying on TLP
 * @param {*} task
 * @param {*} flags
 * @returns
 */
const AssigneeNameDisplayingTLP = ({ task, flags }) => {
  const { isVendor } = useVendorRole();
  const { isRootUser } = useSelector(rootEntitySwitcherSelector);
  if (flags?.reconVendorManagement && task?.assignedToGroupName) {
    return (
      (task?.assignedToGroupType === DEALER_INTERNAL_GROUP && (
        <>
          {task?.assignedToName || <span className="unassigned-text-style">Unassigned</span>}
          <>&nbsp;({task?.assignedToGroupName})</>
        </>
      )) ||
      (task?.assignedToGroupType === VENDOR && (
        <GetAssignedToNameOfVendor isVendor={isVendor && !isRootUser} task={task} flags={flags} />
      ))
    );
  }
  return task?.assignedToGroupName ? (
    <>
      {task?.assignedToName || <span className="unassigned-text-style">Unassigned</span>}
      {task?.assignedToGroupType && task?.assignedToGroupType === DEALER_INTERNAL_GROUP && (
        <>&nbsp;({task?.assignedToGroupName})</>
      )}
    </>
  ) : (
    <>{task?.assignedToName}</>
  );
};

/**
 * Returns a component representing any alert level info
 *  related to the task time.
 *
 * @param {*} progress The object containing the task progress info. This
 *  can be gotten from the `getTaskProgress()` function
 */
const TaskTimeAlertInfo = ({ progress }) => {
  if (progress.status === taskProgressLabels.PastGoal) {
    return <Text className="orange strong">{progress.info}</Text>;
  }
  if (progress.status === taskProgressLabels.Overdue) {
    return <Text className="red strong">{progress.info}</Text>;
  }
  return null;
};

export const TaskTime = ({ task }) => {
  const timeInTask = displayDurationSummary(toMomentDuration({ seconds: task.secondsInTask }), true, true);

  return (
    <Stack horizontal>
      <StyledHourglassIcon icon={faHourglassHalf} />
      <Stack>
        <TimeText>
          {!!timeInTask ? <TimeText className={'medium-font strong'}>{timeInTask}</TimeText> : null}
          {task.completedOn ? <TaskTimeAlertInfo progress={getTaskProgress(task)} /> : null}
        </TimeText>

        {task.completedOn ? (
          <Text>Final Cost: {formatPrice(task.completedCost || null)}</Text>
        ) : (
          <Text>Est Cost: {formatPrice(task.estimatedCost || null)}</Text>
        )}
      </Stack>
    </Stack>
  );
};

const GetAssignedToNameOfVendor = ({ isVendor, task, flags }) => {
  if (flags?.reconVendorManagement && isVendor) {
    return <>{task?.assignedToName || <span className="unassigned-text-style">Unassigned</span>}</>;
  }
  return (
    <>
      {task?.assignedToGroupName}
      {task?.assignedToName && <>&nbsp;({task?.assignedToName})</>}
    </>
  );
};
//#region Styled Components
const TimeText = styled(Text)`
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;
const CenteredContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: ${({ screenWidthIsMax1200px }) => (screenWidthIsMax1200px ? '310px' : '350px')};
`;
const StyledTaskIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
  height: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '28px'};
  align-items: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && 'center'};
  padding-left: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '10px'};
`;
const StyledTitle = styled(Text).attrs({
  className: 'medium-font'
})`
  font-weight: ${({ theme }) => theme.fontWeights.md};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  .ant-typography& {
    color: ${(props) => (props['data-color'] ? props['data-color'] : 'inherit')};
  }
`;
const StyledHourglassIcon = styled(FontAwesomeIcon)`
  margin: 4px 12px 0 5px;
`;
//#endregion
