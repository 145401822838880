import styled, { css } from 'styled-components';
import { Card, Table } from 'antd4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { range } from 'lodash/util';
import React from 'react';
import { StatisticsSkeletonColumn } from '../../../sharedComponents/Statistics';
import { Drawer } from 'antd';

const StyledEnterpriseSummaryTitle = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-top: 3px;
`;

const StyledReportControls = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSummaryContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;

  margin-top: 16px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.titanium300};

  > * {
    width: fit-content;
  }
`;
const StyledSummarySkeletonHeader = styled(StyledSummaryContainer)`
  padding: unset;
  width: 33%;
`;

const setSummaryItemCardStyles = {
  needAttention: css`
    background: ${({ theme }) => theme.colors.titanium300} !important;
    border-right: ${({ theme }) => theme.borders.tableRowBorder} !important;
    ${({ noDealershipFiltersSelectedHQ }) => (noDealershipFiltersSelectedHQ ? '' : 'cursor: pointer;')}
    &:hover {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      ${({ noDealershipFiltersSelectedHQ }) => (noDealershipFiltersSelectedHQ ? '' : 'text-decoration: underline;')}
      border-radius: 5px;
    }
    .ant-card-head-title {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.titanium700};
      font-size: ${({ theme }) => theme.fontSizes.md};
    }
    .ant-card-head-body-title {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: ${({ theme }) => theme.fontSizes.xxlg};
      color: ${({ theme }) => theme.colors.cobalt700};
    }
    .ant-col,
    .ant-card-body {
      padding: 1px 12px !important;
    }
  `,
  default: css`
    .ant-col,
    .ant-card-body {
      padding: 1px !important;
    }
  `
};

const SummaryItemCard = styled(Card)`
  height: 100%;

  &.ant-card {
    border-radius: 5px;
  }

  .ant-card-head {
    border-bottom: none;
  }
  ${({ summaryItemCard }) => setSummaryItemCardStyles[summaryItemCard]}
`;

const EnterpriseStatisticsSkeleton = ({ statisticsCount }) => {
  return range(statisticsCount).map((_) => (
    <StyledEnterpriseStatisticsSkeletonContainer>
      <SummaryItemCard summaryItemCard="default">
        <StatisticsSkeletonColumn />
      </SummaryItemCard>
    </StyledEnterpriseStatisticsSkeletonContainer>
  ));
};

const StyledEnterpriseStatisticsSkeletonContainer = styled.div`
  width: 100%;
`;

const EnterpriseSummaryDrawer = styled(Drawer).attrs({
  closable: false, // The close button is added by `DrawerContainer`
  // Ensure drawer is recreated every time it is opened
  // This makes it easy to ensure that fresh data is fetched from the API every time the drawer is opened
  destroyOnClose: true,
  placement: 'right',
  width: '95vw'
})`
  .ant-drawer-body {
    // Overrides default padding from antd so that child elements can manage their own spacing
    padding: 0;
  }
`;

const EnterpriseSummaryDrawerContent = styled.div`
  margin: 16px;
`;

const StyledEnterpriseTable = styled(Table).attrs({
  scroll: { x: 'max-content' }
})`
  /* 
    Table styles?
    Table header colors are different from other tables on the dashboard
  */

  margin-top: 16px;

  .ant-table-content > table {
    line-height: unset;
    border: ${({ theme }) => theme.borders.tableRowBorder};
    white-space: normal;
    table-layout: fixed !important;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 4px 8px;
    color: ${({ theme }) => theme.colors.navy};
  }

  .border-left {
    border-left: ${({ theme }) => theme.borders.tableRowBorder};
  }

  .ant-table-tbody .small-left-padding {
    padding-left: 4px !important;
  }

  .ant-table-tbody > tr > td {
    border-top: ${({ theme }) => theme.borders.tableRowBorder};
  }

  .ant-table tfoot > tr > th {
    padding: 8px 16px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  th.ant-table-cell {
    padding: 8px 16px 0 8px;
    background-color: ${({ theme }) => theme.borders.disabledGray};
    color: ${({ theme }) => theme.colors.navy};
  }

  th.ant-table-column-has-sorters {
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.borders.disabledGray};
    color: ${({ theme }) => theme.colors.navy};
  }
`;

const SkeletonRowContainer = styled.div`
  margin-bottom: 8px;
`;

const IncreasedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
  margin-top: 2px;
  height: 16px;
  width: 16px;
`;

const DecreasedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.lime};
  margin-right: 4px;
  margin-top: 2px;
  height: 16px;
  width: 16px;
`;

const StyledDealerName = styled.div`
  min-width: 120px;
  max-width: 30vw;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;

  // technically the props below only work for webkit-based browsers
  // however, this list includes Chrome, Chromium, Safari, FF and the new Edge browser which covers all the browsers officially supported by iRecon
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledCell = styled.div`
  display: inline-flex;
  text-align: center;
  color: ${({ theme }) => theme.colors.navy};
  padding: 4px 8px;
  border-radius: 4px;
  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-decoration: underline;
    background-color: ${({ theme }) => theme.backgroundColors.gray};
    color: ${({ theme }) => theme.colors.navy}
    cursor: pointer
  }
`;

const StyledCellPrev = styled(StyledCell)`
  color: ${({ theme }) => theme.colors.darkGray};
  &:hover {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const StyledDetailsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  padding-top: 10px;
  justify-content: center;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledEnterpriseSummaryContainer = styled.div`
  display: flex;
  height: 30px;
  .center-content {
    margin-top: 5px;
  }
`;

export {
  StyledEnterpriseSummaryTitle,
  StyledSummaryContainer,
  SummaryItemCard,
  StyledEnterpriseTable,
  SkeletonRowContainer,
  IncreasedIcon,
  DecreasedIcon,
  StyledDealerName,
  StyledCell,
  StyledCellPrev,
  StyledDetailsContainer,
  StyledContainer,
  StyledSummarySkeletonHeader,
  EnterpriseStatisticsSkeleton,
  EnterpriseSummaryDrawer,
  EnterpriseSummaryDrawerContent,
  StyledEnterpriseSummaryContainer,
  StyledReportControls
};
