import { faEdit, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Dropdown, Menu } from 'antd';
import { apiStatusConstants, vendorDealerStatusTypes, VENDOR_USER_INFO } from 'app-constants';
import { DrawerContainer } from 'components';
import React, { useEffect, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { currentVendorActions, rootEntitySwitcherSelector } from 'store/dealersStore';
import { vendorDealerActions } from '../../../../store/vendorStore';
import { formatMMDDYY } from '../../../../utils/dateTimeUtils';
import { compareElementWidthByClassName } from '../../../../utils/domUtils';
import { formatPhoneNumber } from '../../../../utils/numberUtils';
import { statusSort } from '../../../../utils/sortUtils';
import SearchInput from '../../../inputs/SearchInput';
import { CommonHeaderLabel, CommonRedButton } from '../../../styledComponents';
import Table from '../../../tables/Table';
import DealershipDetails from './DealershipDetails';
import NoDealerships from './NoDealerships';
import { StringParam, useQueryParams } from 'use-query-params';
import { GlobalAlertContext } from '../../../App';

const dealersPropsSelector = createSelector(
  (state) => state.dealers.currentVendor.dealers,
  (state) => state.dealers.currentVendor.fetchStatus,
  (dealers, dealersFetchStatus) => ({
    dealers,
    dealersFetchStatus
  })
);

const Dealerships = ({ flags }) => {
  const rawVendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
  const vendorUserInfo = rawVendorUserInfo ? JSON.parse(rawVendorUserInfo) : {};
  const dispatch = useDispatch();
  // shallowEqual checks if returned object is equal to prevent renders
  const { dealers, dealersFetchStatus } = useSelector(dealersPropsSelector, shallowEqual);
  const { isRootUser, vendorShipSelected, currentId } = useSelector(rootEntitySwitcherSelector);
  // states
  const [menuOpen, setMenuOpen] = useState({});
  const [showInnerBorders, setShowInnerBorders] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useQueryParams({
    dealerId: StringParam,
    isNotification: StringParam
  });
  const hasGlobalAlert = !!useContext(GlobalAlertContext);

  useEffect(() => {
    if (query.dealerId && query.isNotification === 'true') {
      if (isRootUser && vendorShipSelected && currentId) {
        dispatch(vendorDealerActions.getVendorDealerDetails(currentId, query.dealerId));
      } else {
        dispatch(vendorDealerActions.getVendorDealerDetails(vendorUserInfo.vendorId, query.dealerId));
      }
      openDrawer();
    }
  }, [query.dealerId, isRootUser, vendorShipSelected, currentId]);

  useEffect(() => {
    if (isRootUser && vendorShipSelected && currentId) {
      dispatch(currentVendorActions.getDealers(currentId));
    } else if (vendorUserInfo && vendorUserInfo.vendorId) {
      dispatch(currentVendorActions.getDealers(vendorUserInfo.vendorId));
    }
  }, [vendorUserInfo?.vendorId, isRootUser, vendorShipSelected, currentId]);

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
    dispatch(vendorDealerActions.resetVendorDealerDetails());
    setQuery({ dealerId: undefined, isNotification: undefined });
  };

  const changeBorders = () => {
    if (filteredTableData.length > 0) {
      setShowInnerBorders(compareElementWidthByClassName('.ant-table-measure-row', '.ant-table-wrapper'));
    }
  };

  useEffect(() => {
    if (filteredTableData.length === 0) {
      setShowInnerBorders(false);
    } else {
      changeBorders();
    }
    window.addEventListener('resize', changeBorders);
    // cleanup func
    return () => window.removeEventListener('resize', changeBorders);
  }, [filteredTableData]);

  useEffect(() => {
    const dealerList = [
      ...dealers.map((dealer) => {
        const statusDisplay = dealer.invitationStatus ? vendorDealerStatusTypes[dealer.invitationStatus]?.Name : '-';
        const formattedDate = dealer.invitationStatusUpdatedOn ? formatMMDDYY(dealer.invitationStatusUpdatedOn) : '-';
        const addedBy = `${dealer.vendorCreatedByFirstName} ${dealer.vendorCreatedByLastName}`;
        const address = dealer.address1 ?? '';
        return {
          // Include all existing fields
          ...dealer,
          addedBy: addedBy,
          address: address,
          city: dealer.city ?? '',
          addressLine: dealer.city ? `${address}, ${dealer.city}` : address,
          // Overwrite phone number with correct formatting
          phone: formatPhoneNumber(dealer.phone),
          status: `${statusDisplay}, ${formattedDate}`,
          statusRank: vendorDealerStatusTypes[dealer.invitationStatus]?.Rank,
          statusDate: dealer.vendorDealerLastUpdatedOn
        };
      })
    ];

    setTableData(dealerList);
    if (searchValue) {
      const filteredArray = dealerList.filter(
        (dealer) =>
          dealer.name?.toLowerCase().includes(searchValue) ||
          dealer.status?.toLowerCase().includes(searchValue) ||
          dealer.status?.toLowerCase().replace(/-/g, '/').includes(searchValue) ||
          dealer.status?.replace(/-/g, ' ').includes(searchValue) ||
          dealer.addedBy?.toLowerCase().includes(searchValue) ||
          dealer.address?.toLowerCase().includes(searchValue) ||
          dealer.city?.toLowerCase().includes(searchValue) ||
          dealer.phone?.includes(searchValue) ||
          dealer.phone?.replace(/-/g, '').includes(searchValue) ||
          dealer.phone?.replace(/-/g, ' ').includes(searchValue)
      );
      setFilteredTableData(filteredArray);
    } else {
      setFilteredTableData(dealerList);
    }
  }, [dealers]);

  const filterTableData = (value) => {
    const trimmedValue = value.toLowerCase().trim();
    setSearchValue(trimmedValue);
    if (trimmedValue) {
      const filteredArray = tableData.filter(
        (dealer) =>
          dealer.name?.toLowerCase().includes(trimmedValue) ||
          dealer.status?.toLowerCase().includes(trimmedValue) ||
          dealer.status?.toLowerCase().replace(/-/g, '/').includes(trimmedValue) ||
          dealer.status?.replace(/-/g, ' ').includes(trimmedValue) ||
          dealer.addedBy?.toLowerCase().includes(trimmedValue) ||
          dealer.address?.toLowerCase().includes(trimmedValue) ||
          dealer.city?.toLowerCase().includes(trimmedValue) ||
          dealer.phone?.includes(trimmedValue) ||
          dealer.phone?.replace(/-/g, '').includes(trimmedValue) ||
          dealer.phone?.replace(/-/g, ' ').includes(trimmedValue)
      );
      setFilteredTableData(filteredArray);
    }
  };

  // Handlers
  const onViewDealer = (dealer) => {
    if (dealer) {
      if (isRootUser && vendorShipSelected && currentId) {
        dispatch(vendorDealerActions.getVendorDealerDetails(currentId, dealer.id));
      } else {
        dispatch(vendorDealerActions.getVendorDealerDetails(vendorUserInfo.vendorId, dealer.id));
      }
      openDrawer();
    }
  };

  // action column ellipsis
  const moreMenu = (dealer) => (
    <Dropdown
      display="flex"
      onVisibleChange={() => setMenuOpen({ id: dealer.id, open: !menuOpen.open })}
      overlay={
        <Menu
          onClick={() => {
            setMenuOpen({ id: dealer.id, open: false });
            onViewDealer(dealer);
          }}
        >
          <Menu.Item key="view">
            <StyledMenuIcon icon={faEdit} />
            View Details
          </Menu.Item>
        </Menu>
      }
    >
      <StyledOverflowIcon id={dealer.id} icon={faEllipsisH} data-selected={menuOpen} />
    </Dropdown>
  );

  const columnsDef = [
    {
      title: 'Dealership',
      dataIndex: 'name',
      width: '125px',
      fixed: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '100px',
      sorter: statusSort,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, _record, _index) =>
        text.includes('Pending') ? <p className={'table-cell-pending'}>{text}</p> : text
    },
    {
      title: 'Added By',
      width: '120px',
      dataIndex: 'addedBy',
      sorter: (a, b) => a.addedBy.localeCompare(b.addedBy)
    },
    {
      title: 'Address',
      width: '110px',
      dataIndex: 'addressLine',
      sorter: (a, b) => a.addressLine.localeCompare(b.addressLine)
    },
    {
      title: 'Phone',
      width: '100px',
      dataIndex: 'phone',
      sorter: (a, b) => {
        if (!a.phone) {
          return -1;
        }

        if (!b.phone) {
          return 1;
        } else {
          return a.phone.localeCompare(b.phone, { numeric: true });
        }
      }
    },
    // remove lines below if phone is never null and remove width on line 199
    {
      title: ''
    },
    {
      title: '',
      dataIndex: 'operation',
      fixed: 'right',
      className: 'vendor-operation-col-header',
      width: flags?.reconVendorWorkflow2023 ? '150px' : '50px',
      render: (_text, record) => (
        <StyledBtnContainer
          reconVendorWorkflow2023={flags?.reconVendorWorkflow2023}
          isInvitationPending={record.invitationStatus === 'PENDING'}
        >
          {flags?.reconVendorWorkflow2023 && record.invitationStatus === 'PENDING' ? (
            <StyledRedButton
              onClick={() => {
                setMenuOpen({ id: record.id, open: false });
                onViewDealer(record);
              }}
            >
              Accept/Decline
            </StyledRedButton>
          ) : (
            moreMenu(record)
          )}
        </StyledBtnContainer>
      )
    }
  ];

  const columns = columnsDef.map((col) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width
    })
  }));

  const dealerRowClass = (record, _index) => {
    if (record.status.includes('Declined')) {
      return 'table-row-declined';
    }
    return 'table-row-normal';
  };

  const scroll = filteredTableData.length !== 0 && {
    y: `calc(100vh - 400px${hasGlobalAlert ? ' - 42px' : ''})`,
    x: 'max-content'
  };

  const noRecordsView = <NoDealerships />;
  // Prevents the 'no results found' message from displaying before data is fetched.
  const isFetching = dealersFetchStatus === apiStatusConstants.IS_FETCHING;
  const fetchFailed = dealersFetchStatus === apiStatusConstants.FAILED;
  const dealersTable = (
    <StyledContainer>
      <StyledDealerTable
        columnsDef={columns}
        dataSource={filteredTableData}
        scroll={scroll}
        rowClassName={dealerRowClass}
        showInnerBorders={showInnerBorders}
        isFetching={isFetching}
        fetchFailed={fetchFailed}
      />
    </StyledContainer>
  );

  return (
    <>
      <StyledReversedHeader>
        <SearchInput
          searchFunction={filterTableData}
          data={tableData}
          resetData={setFilteredTableData}
          customHeight={32}
          setSearchValue={setSearchValue}
        />
      </StyledReversedHeader>
      {!isFetching && dealers.length === 0 && !fetchFailed ? noRecordsView : dealersTable}
      <Drawer
        closable={false}
        destroyOnClose={true}
        placement="right"
        width={580}
        visible={showDrawer}
        onClose={closeDrawer}
        bodyStyle={{ padding: '0' }}
      >
        <DrawerContainer headerLabel={<CommonHeaderLabel>Dealership Details</CommonHeaderLabel>} onClose={closeDrawer}>
          <DealershipDetails
            vendorId={isRootUser && vendorShipSelected && currentId ? currentId : vendorUserInfo.vendorId}
            closeDrawer={closeDrawer}
          />
        </DrawerContainer>
      </Drawer>
    </>
  );
};

//#region Styled Components
const StyledReversedHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  & > .ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
  margin: 0 24px 16px 0;
  line-height: normal;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  white-space: nowrap;
  margin-right: 24px;
  min-width: 600px;
`;

const StyledDealerTable = styled(Table)``;

const StyledMenuIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  width: 14px;
  height: 14px;
`;

const StyledOverflowIcon = styled(FontAwesomeIcon)`
  height: 20px;
  cursor: pointer;
  color: ${(props) =>
    props['data-selected']['open'] && props['data-selected']['id'] === props['id']
      ? 'inherit'
      : props.theme.colors.darkGray};
  vertical-align: -0.45em;
`;

const StyledRedButton = styled(CommonRedButton)`
  line-height: ${({ theme }) => theme.lineHeights.smd} !important;
  height: 24px !important;
  padding: 2px 8px !important;
`;

const StyledBtnContainer = styled.div`
  text-align: ${({ reconVendorWorkflow2023, isInvitationPending }) =>
    !reconVendorWorkflow2023 || isInvitationPending ? 'center' : 'right'};
`;
//#endregion

export default withLDConsumer()(Dealerships);
