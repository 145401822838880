import lineItemStatusTypes from 'app-constants/lineItemStatusTypes';

export const updateStatusForLineItemTemplate = (lineItemTemplate) => {
  let status = lineItemTemplate.preApprove
    ? lineItemStatusTypes.APPROVED
    : !lineItemTemplate.laborCost?.toString() && !lineItemTemplate.partsCost?.toString() && !lineItemTemplate.totalCost?.toString() 
    ? lineItemStatusTypes.ESTIMATE_REQUESTED
    : lineItemStatusTypes.PENDING;

    return {
        ...lineItemTemplate,
        status
    };
};
