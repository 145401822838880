import React, { useEffect } from 'react';
import { IS_NEW_SIGNIN } from 'app-constants';
import { userManagerNewDomain, userManagerNewSignin } from 'utils/userManager';
import {
  StyledWrapper,
  StyledImgHeader,
  StyledContainer,
  StyledLogoRow,
  StyledInstructionRow,
  StyledOptionRow,
  StyledFaCarIcon,
  StyledFaWrechIcon,
  StyledDealerOptionContainer,
  StyledVendorOptionContainer,
  StyledOptionDesc,
  StyledRedButton
} from './styles';
import ReconSignInLogo from 'assets/images/iRecon-Sign-In-Logo.svg';
import CoxAutomotiveLogo from 'assets/images/cox-automotive.svg';
import { notification } from 'antd4';

const LoginSelection = () => {
  useEffect(() => {
    notification.destroy();
  }, []);

  const oldSignin = () => {
    localStorage.setItem(IS_NEW_SIGNIN, false);
    const urlParameters = new URLSearchParams(window.location.search);
    const redirectUrl = urlParameters.get('redirect_url') || undefined;
    userManagerNewDomain.signinRedirect({ state: { redirect_url: redirectUrl } });
  };

  const newSignin = () => {
    localStorage.setItem(IS_NEW_SIGNIN, true);
    const urlParameters = new URLSearchParams(window.location.search);
    const redirectUrl = urlParameters.get('redirect_url') || undefined;
    userManagerNewSignin.signinRedirect({ state: { redirect_url: redirectUrl } });
  };

  return (
    <StyledWrapper>
      <StyledImgHeader>
        <img alt="Cox Automotive" src={CoxAutomotiveLogo} height="30px" width="100%" />
      </StyledImgHeader>
      <StyledContainer>
        <StyledLogoRow>
          <img alt="iRecon" src={ReconSignInLogo} height="32px" width="100%" />
        </StyledLogoRow>
        <StyledInstructionRow>Choose a Sign-in Option:</StyledInstructionRow>
        <StyledOptionRow>
          <StyledFaCarIcon />
          <StyledDealerOptionContainer>
            <StyledOptionDesc>I use an account at a dealership.</StyledOptionDesc>
            <StyledRedButton onClick={oldSignin}>Dealership Account</StyledRedButton>
          </StyledDealerOptionContainer>
        </StyledOptionRow>
        <StyledOptionRow>
          <StyledFaWrechIcon />
          <StyledVendorOptionContainer>
            <StyledOptionDesc>
              I use one vendor account to provide reconditioning services to multiple dealerships.
            </StyledOptionDesc>
            <StyledRedButton onClick={newSignin}>Vendor Account</StyledRedButton>
          </StyledVendorOptionContainer>
        </StyledOptionRow>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default LoginSelection;
