import React from 'react';
import { useDispatch } from 'react-redux';
import { documentsActions } from 'store/documentsStore';
import {documentationContexts} from '../../app-constants';

// called from vehicle | task
const withDocuments = (calledFrom) => (WrappedComponent) => {
  const HOC = (props) => {
    const dispatch = useDispatch();

    const handleGetDocuments = (containerId) => {
      if (calledFrom === documentationContexts.INVENTORY_PAGE) {
        // The containerId is passed as vehicleId 
        dispatch(documentsActions.getAllDocuments(containerId));
      } else {
        // The containerId is passed as taskId 
        dispatch(documentsActions.getTaskDocuments(containerId));
      }
    };

    const handleDeleteDocument = (documentId) => {
      dispatch(documentsActions.deleteDocument(documentId));
    };

    // documentName is Tempotary solution to show document name when download since the document name is missing form response.
    const handleDownloadDocument = (documentId, documentName) => {
      if (calledFrom === documentationContexts.INVENTORY_PAGE) {
        dispatch(documentsActions.downloadVehicleDocument(documentId, documentName));
      }else{
        dispatch(documentsActions.downloadTaskDocument(documentId, documentName));
      }
    };

    const handleUploadDocument = (containerId, documents, stockNumber, message, hasLoadingToast = true) => {
      if (calledFrom === documentationContexts.INVENTORY_PAGE) {
          // The containerId is passed as vehicleId 
        dispatch(documentsActions.uploadVehicleDocument(containerId, documents, stockNumber, message, hasLoadingToast));
      }else{
          // The containerId is passed as taskId 
        dispatch(documentsActions.uploadTaskDocument(containerId, documents, stockNumber, message, hasLoadingToast));
      }
    };

    return (
      <WrappedComponent
        {...props}
        deleteDocument={handleDeleteDocument}
        getDocuments={handleGetDocuments}
        downloadDocument={handleDownloadDocument}
        uploadDocument={handleUploadDocument}
      />
    );
  };
  return HOC;
};

export default withDocuments;
