import React from 'react';

import spin from 'assets/images/vendor-welcome/spin.svg';
import { Overlay, StyledImageSpinner } from './style';

const Spinner = ({ show = false }) => {
  return (
    <Overlay show={show}>
      <StyledImageSpinner src={spin} alt="spinner" />
    </Overlay>
  );
};

export default Spinner;
