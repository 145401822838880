import { Modal } from 'antd';

import { RECON_PLAN } from 'app-constants/planTypes';
import { LINE_ITEMS_CHANGE_STATUS } from 'app-constants/lineItemConstants';
import { vdpActions } from 'store/vdpStore';

// needed for Delete Task Modal that shows up on ReconPlan when deleting a task
const { confirm } = Modal;

/**
 * getOnClickHandler
 * function which determines what onClick handler should be applied to each menu option
 * @param {string} type - the menu option type: edit, delete or complete (complete is only for recon plan tasks)
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {string} planSource - either RECON_PLAN or TEMPLATE_PLAN (set in ReconPlan and TemplatePlan components and passed into DragDropContext in those components)
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
export const getOnClickHandler = (type, task, planSource, taskAccessoryInfo) => {
  if (type === 'edit') {
    return getOnEditClick(planSource, task, taskAccessoryInfo);
  } else if (type === 'message') {
    return getMessagesClick(task, taskAccessoryInfo);
  } else if (type === 'pass through') {
    return getOnPassThroughClick(task, taskAccessoryInfo);
  } else if (type === 'delete') {
    return getOnDeleteClick(planSource, task, taskAccessoryInfo);
  } else if (type === 'Line Items') {
    return getLineItemsClick.bind(null, task, taskAccessoryInfo);
  } else if (planSource === RECON_PLAN && type === 'complete') {
    //it would make no sense to be able to complete a task on a template plan
    return getOnCompleteClick.bind(null, task, taskAccessoryInfo);
  }
};

/**
 * getOnEditClick
 * onClickHandler function for menu option type edit
 * determines based on planSource whether to use the recon plan or the template plan onClick handler
 * @param {string} planSource - either RECON_PLAN or TEMPLATE_PLAN (set in ReconPlan and TemplatePlan components and passed into DragDropContext in those components)
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
const getOnEditClick = (planSource, task, taskAccessoryInfo) => {
  // recon plan click handler for when edit option is clicked
  const onEditClickReconPlan = () => {
    // get data from taskAccessoryInfo to set form props for TaskForm component
    const { setFormProps, vehicleId, count, onUpdate } = taskAccessoryInfo;
    //ReconPlan or TaskForm are shown conditionally based on if formProps is set, so setting formProps renders the TaskForm component in place of the ReconPlan
    setFormProps({
      vehicleId: vehicleId,
      taskToUpdate: task,
      count: count,
      onUpdate: onUpdate
    });
  };

  // template plan click handler for when edit option is clicked
  const onEditClickTemplatePlan = () => {
    // get data from taskAccessoryInfo to set form props for TaskForm component
    const { setFormProps, planTemplateId, count, onUpdate } = taskAccessoryInfo;

    //TemplatePlan or TaskForm are shown conditionally based on if formProps is set, so setting formProps renders the TaskForm component in place of the TemplatePlan
    setFormProps({
      parentId: planTemplateId,
      taskToUpdate: task,
      count: count,
      onUpdate: onUpdate
    });
  };

  return planSource === RECON_PLAN ? onEditClickReconPlan : onEditClickTemplatePlan;
};

/**
 * getMessageClick
 * onClickHandler function for menu option type message
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
const getMessagesClick = (task, taskAccessoryInfo) => {
  const onMessagesClick = () => {
    // get data from taskAccessoryInfo to set form props for TaskForm component
    const { setFormProps, vehicleId, count, onUpdate, onComplete, onDelete } = taskAccessoryInfo;
    //ReconPlan or TaskForm are shown conditionally based on if formProps is set, so setting formProps renders the TaskForm component in place of the ReconPlan
    setFormProps({
      vehicleId: vehicleId,
      isMessageTask: true,
      taskToUpdate: task,
      count: count,
      onUpdate: onUpdate,
      onCompleteTaskMessage: onComplete,
      onDelete: onDelete
    });
  };

  return onMessagesClick;
};

/**
 * getOnPassThroughClick
 * onClickHandler function for menu option type pass through
 * behaves the same for both recon plan and template plan planSources
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
const getOnPassThroughClick = (task, taskAccessoryInfo) => {
  const onPassThroughClick = () => {
    const { onUpdate, closeForm, dispatch } = taskAccessoryInfo;
    dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
    onUpdate({
      ...task,
      passthrough: !task.passthrough,
      // tacking on this adhoc field to track the original value of pass through, so the user can remove it from a in-progress task if they just added it, before saving
      prevPassThroughState: task.hasOwnProperty('prevPassThroughState') ? task.prevPassThroughState : task.passthrough
    });
    closeForm && closeForm();
  };

  return onPassThroughClick;
};

/**
 * getOnDeleteClick
 * onClickHandler function for menu option type delete
 * determines based on planSource whether to use the recon plan or the template plan onClick handler
 * @param {string} planSource - either RECON_PLAN or TEMPLATE_PLAN (set in ReconPlan and TemplatePlan components and passed into DragDropContext in those components)
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
const getOnDeleteClick = (planSource, task, taskAccessoryInfo) => {
  const { onDelete, closeForm, dispatch } = taskAccessoryInfo;
  // template plan click handler for when delete option is clicked
  const onDeleteClickTemplatePlan = () => {
    const { onDelete } = taskAccessoryInfo;

    onDelete(task);
    if (closeForm) {
      closeForm();
    }
  };

  // recon plan click handler for when delete option is clicked
  const onDeleteClickReconPlan = () => {
    /**
     * isTransient is set to true for tasks that have just been added during a session of editing a plan for new tasks (see TaskForm handleSubmit function).
     * once the plan is saved with those new tasks, the task isTransient property is removed
     **/
    const { isTransient, sequence, reconTaskTypeName, assignedToName, group, parentSequence } = task;
    // onDelete function is set in Task component file in the useSetTaskFunctionalityAndData custom hook
    if (!isTransient) {
      // only prompt on Delete if this task has already been persisted before (ie: the plan has already started and vendors have already been notified of upcoming tasks)
      confirm({
        title: `Are you sure you want to delete Step ${
          group?.sequence || parentSequence || sequence
        } ${reconTaskTypeName}?`,
        content: `Deleting this task will remove this work from ${assignedToName}'s task list`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          onDelete(task);
          dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
          closeForm && closeForm();
        },
        onCancel() {
          devLogger.log('Canceled Delete Task');
        }
      });
    } else {
      onDelete(task);
      dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.NOT_ONLY_CHANGE_LINE_ITEM));
      closeForm && closeForm();
    }
  };

  return planSource === RECON_PLAN ? onDeleteClickReconPlan : onDeleteClickTemplatePlan;
};

/**
 * getOnCompleteClick
 * onClickHandler function for menu option type complete
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
const getOnCompleteClick = (task, taskAccessoryInfo) => {
  //only for recon plan - can't complete tasks on a template plan
  // onComplete function is set in Task component file in the useSetTaskFunctionalityAndData custom hook
  const { setFormProps, vehicleId, count, onComplete } = taskAccessoryInfo;
  setFormProps({
    vehicleId: vehicleId,
    taskToUpdate: task,
    count: count,
    onComplete: onComplete
  });
};

/**
 * getLineItemsClick
 * onClickHandler function for menu option type line items
 * @param {object} task - task dto passed into Task component instance and is provided via TaskContext provider to all children components
 * @param {object} taskAccessoryInfo - a mix of both data from DragDropContext and data computed in Task component getReconPlanData and passed into TaskContext
 */
export const getLineItemsClick = (task, taskAccessoryInfo) => {
  // get data from taskAccessoryInfo to set form props for TaskForm component
  const { setFormProps, vehicleId, onUpdate, onComplete, onDelete } = taskAccessoryInfo;
  //ReconPlan or TaskForm are shown conditionally based on if formProps is set, so setting formProps renders the TaskForm component in place of the ReconPlan
  setFormProps({
    vehicleId: vehicleId,
    isLineItemsTask: true,
    taskToUpdate: task,
    onUpdate: onUpdate,
    onCompleteTaskMessage: onComplete,
    onDelete: onDelete
  });
};
