import { Card, Checkbox, Col, Icon, Input, List, Row, Spin, Switch, Tooltip, Typography } from 'antd';
import { apiStatusConstants, features } from 'app-constants';
import { EMAIL, IN_APP, PUSH, SMS } from 'app-constants/notificationChannelsTypes.js';
import { TOGGLE_CHANGE } from 'app-constants/stateChangeToggleSettings.js';
import { CircularSpinner } from 'components';
import { useFeaturesFilter, useWindowSize } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { memo, useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual, FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import { userAppStateActions } from 'store/userAppStateStore.js';
import {
  userNotificationChannelsSelector,
  userNotificationEventsSelector,
  userSettingsActions
} from 'store/userSettingsStore';
import { usersActions } from 'store/usersStore';
import styled from 'styled-components';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { GlobalAlertContext } from '../../App';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';

const { Text } = Typography;

let notificationChannels = [
  {
    id: IN_APP,
    title: 'In-app Notification',
    description: 'Display in-app notifications in the notification drawer in iRecon.'
  },
  {
    id: PUSH,
    title: 'Mobile Push Notification',
    description: 'Send a push notification to the iRecon app on my mobile device'
  },
  { id: EMAIL, title: 'Email', description: 'Send an email to my registered email address' },
  { id: SMS, title: 'SMS', description: 'Send a text (SMS) to my registered phone number' }
];

// Note on using launch toggles in notificationEvents:
// notificationEvents is used by the useFeatures hook below, which uses this array as a useEffect dependency.
// Because of this, we can't turn notificationEvents into a function that returns an array, as that would
// return a new object every time this function is called, triggering the useEffect in useFeatures on every render.

const notificationEvents = [
  //hiding this one until we figure out a way to implement it that doesn't result in obnoxious notification spam
  // {
  //   id: 'VEHICLE_INVENTORY_ADDED', title: 'New Vehicle', requires: features.TASKS_EDIT,
  //   description: 'A new vehicle has been added to my inventory, and needs a reconditioning plan'
  // },
  {
    id: 'VEHICLE_FRONT_LINE_READY',
    title: 'Vehicle Plan Complete',
    requires: features.NOTIFICATIONS_FLR,
    description: 'A vehicle has completed reconditioning and is ready for retail'
  },
  {
    id: 'TASK_COMPLETED',
    title: 'Task Completed',
    requires: [features.TASKS_WORK_OWN, features.VENDOR_ADMIN],
    excludes: features.EXTERNAL_TECH_ONLY,
    description: 'A task I assigned has been completed'
  },
  {
    id: 'TASK_DECLINED',
    title: 'Task Declined',
    requires: [features.TASKS_WORK_OWN, features.VENDOR_ADMIN],
    excludes: features.EXTERNAL_TECH_ONLY,
    description: 'A task I assigned has been declined'
  },
  {
    id: 'TASK_DEFERRED',
    title: 'Task Deferred',
    requires: [features.TASKS_WORK_OWN, features.BASELINE],
    description: 'A task I was assigned to has been deferred'
  },
  {
    id: 'TASK_ASSIGNED',
    title: 'New Task Assigned',
    requires: [features.TASKS_WORK_OWN, features.BASELINE],
    description: 'A new task has been assigned to me'
  },
  {
    id: 'TASK_COMPLETED_BY_DEALER',
    title: 'Task Completed By Another',
    requires: [features.TASKS_WORK_OWN, features.BASELINE],
    description: 'A task assigned to me has been completed by someone else'
  },
  {
    id: 'TASK_CANCELED',
    title: 'Task Canceled',
    requires: [features.TASKS_WORK_OWN, features.BASELINE],
    description: 'A task I was assigned has been canceled'
  },
  {
    id: 'TASK_COMMENT_ADDED',
    title: 'Task Messages',
    requires: [features.TASKS_WORK_OWN, features.TASKS_EDIT, features.BASELINE],
    description: 'A task I am associated with has new messages'
  },
  {
    id: 'TASK_COMMENT_USER_TAGGED',
    title: 'Tagged Communications',
    requires: [features.TASKS_VIEW_OWN, features.BASELINE],
    description: 'I have been tagged in a task message or vehicle note'
  },
  {
    id: 'VEHICLE_COMMENT_USER_TAGGED',
    title: 'Tagged Communications',
    requires: [features.INVENTORY_VIEW, features.INVENTORY_COMMENT, features.BASELINE],
    description: 'I have been tagged in a task message or vehicle note'
  },
  {
    id: 'LINE_ITEM_APPROVAL_REQUESTED',
    title: 'Line Item Pending Approval',
    requires: features.TASKS_APPROVE_LINE_ITEMS,
    description: 'A new line item needs approval'
  },
  {
    id: 'EXCEEDED_MAX_APPROVAL_TIME',
    title: 'Exceeded Max. Approval Time',
    requires: [features.LINE_ITEMS_EDIT, features.TASKS_EDIT],
    description: 'A new line item was not approved within the maximum time limit'
  },
  {
    id: 'LINE_ITEM_APPROVED_DECLINED',
    title: 'Line Item Approved or Declined',
    requires: [features.TASKS_VIEW_OWN, features.LINE_ITEMS_EDIT, features.BASELINE],
    description: 'A line item assigned to me has been approved or declined'
  },
  {
    id: 'VENDOR_CONFIRMATION',
    title: 'Vendor Invitation Accepted or Declined',
    requires: [features.TASKS_EDIT, features.DEALER_SETTINGS],
    description: 'A Vendor I invited to work with my Dealership has accepted or declined the invitation'
  }
];

const getNotificationConfigState = createSelector(
  [userNotificationChannelsSelector, userNotificationEventsSelector, (state) => state.userSettings.fetchStatus],
  (selectedChannels, selectedEvents, fetchStatus) => {
    //build map of status by channel
    const channelStatus = notificationChannels.reduce((acc, val) => ({ ...acc, [val.id]: false }), {});
    for (const c of selectedChannels) {
      channelStatus[c] = true;
    }

    return {
      selectedChannels,
      selectedEvents,
      channelStatus,
      fetchStatus
    };
  }
);

const notificationsPropsSelector = createSelector(
  authSelector,
  (state) => state.users.currentUserInfo,
  (authState, currentUserInfo) => ({
    authState,
    currentUserInfo
  })
);
const Notifications = ({ flags }) => {
  const dispatch = useDispatch();
  const { authState, currentUserInfo } = useSelector(notificationsPropsSelector);
  const { selectedChannels, selectedEvents, channelStatus, fetchStatus } = useSelector(getNotificationConfigState);
  const [isInternalTech, setisInternalTech] = useState(false);
  const [isDealerAdmin, setIsDealerAdmin] = useState(false);
  const [isSalesperson, setIsSalesperson] = useState(false);
  const { isRootUser } = useSelector(rootEntitySwitcherSelector);

  const availableNotificationEvents = useFeaturesFilter(notificationEvents, (e, featuresSet) => {
    const isInternalTechByFeature =
      featuresSet.has(features.INTERNAL_TECH_ONLY) && !featuresSet.has(features.INVENTORY_EDIT);
    const isDealerAdminByFeature =
      (featuresSet.has(features.TASKS_EDIT) && featuresSet.has(features.DEALER_SETTINGS)) ||
      isRootUser ||
      featuresSet.has(features.ADMINGENERAL);
    const isSalespersonByFeature =
      !isDealerAdminByFeature &&
      !featuresSet.has(features.LINE_ITEMS_EDIT) &&
      featuresSet.has(features.INVENTORY_VIEW) &&
      featuresSet.has(features.INVENTORY_COMMENT);

    if (isInternalTechByFeature) {
      setisInternalTech(true);
    }

    if (isDealerAdminByFeature) {
      setIsDealerAdmin(true);
    }

    if (isSalespersonByFeature) {
      setIsSalesperson(true);
    }

    if (e.excludes && !Array.isArray(e.excludes) && featuresSet.has(e.excludes) && !isDealerAdminByFeature) {
      return null;
    }
    return e.requires;
  });
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  const { isBridgeUser } = useSelector((state) => state.auth);
  let availableNotificationEventsWithFlags = [...availableNotificationEvents];
  if (flags.reconApproveButton) {
    let index = availableNotificationEventsWithFlags.findIndex((item) => item.id === 'LINE_ITEM_APPROVAL_REQUESTED');
    if (index !== -1) {
      availableNotificationEventsWithFlags[index] = {
        ...availableNotificationEventsWithFlags[index],
        title: 'New line item is pending approval',
        description: 'A new line item has been added and is pending approval'
      };
    }
  }

  if (isInternalTech) {
    availableNotificationEventsWithFlags = [
      ...availableNotificationEventsWithFlags.filter((i) => !['TASK_DECLINED', 'TASK_COMPLETED'].includes(i.id))
    ];
  }

  if (!isDealerAdmin || !flags?.reconVendorManagement) {
    availableNotificationEventsWithFlags = [
      ...availableNotificationEventsWithFlags.filter((i) => !['VENDOR_CONFIRMATION'].includes(i.id))
    ];
  }

  if (isSalesperson) {
    availableNotificationEventsWithFlags = [
      ...availableNotificationEventsWithFlags.filter((i) => !['TASK_COMMENT_USER_TAGGED'].includes(i.id))
    ];
  } else {
    availableNotificationEventsWithFlags = [
      ...availableNotificationEventsWithFlags.filter((i) => !['VEHICLE_COMMENT_USER_TAGGED'].includes(i.id))
    ];
  }

  const [fetchStarted, setFetchStarted] = useState(null); //  used for capturing page load time for google analytics, considering the page as done loading when the usersActions.getCurrentUserInfo call has completed

  const hasGlobalAlert = useContext(GlobalAlertContext);

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.NOTIFICATION_SETTINGS_VIEWED);
  useEffect(() => {
    dispatch(userSettingsActions.get(authState.user.id, dealerId));
  }, []);

  useEffect(() => {
    if (currentUserInfo && fetchStarted) {
      setFetchStarted(false);
    }
  }, [currentUserInfo]);

  //start a fetch when component mounts
  useEffect(() => {
    setFetchStarted(true);
    dispatch(usersActions.getCurrentUserInfo());
  }, [authState.user.id]); // when user changes (impersonating), refetch data

  /**
   * Dispatch update notificationMessageType
   * @param {*} eventId notificationMessageType
   * @param {*} isChangedDealers List of dealers for each notification message
   * @returns
   */
  const changeEventSelected = (eventId, isChangedDealers) => (selected) => {
    dispatch(
      userSettingsActions.debounceUpdateNotifications(
        authState.user.id,
        dealerId,
        {
          subscribedNotificationMessageTypes: selectedEvents,
          displayNotificationMessageTypes: selectedEvents,
          channels: [...selectedChannels]
        },
        TOGGLE_CHANGE.event,
        selected,
        isSalesperson,
        eventId,
        isChangedDealers
      )
    );
    if (!selected && selectedEvents.length === 0) {
      dispatch(userAppStateActions.setJournalHistoryChecked(authState.user.id, dealerId));
    }
  };

  const changeChannelSelected = (channelId) => (selected) => {
    let updatedChannels;
    if (selected) {
      updatedChannels = [...new Set([...selectedChannels, channelId])];
    } else {
      updatedChannels = [...new Set(selectedChannels.filter((e) => e !== channelId))];
    }
    dispatch(
      userSettingsActions.debounceUpdateNotifications(
        authState.user.id,
        dealerId,
        {
          channels: updatedChannels,
          subscribedNotificationMessageTypes: selectedEvents,
          displayNotificationMessageTypes: selectedEvents
        },
        TOGGLE_CHANGE.channel,
        selected,
        isSalesperson
      )
    );
    if (!selected && !updatedChannels.includes(IN_APP)) {
      dispatch(userAppStateActions.setJournalHistoryChecked(authState.user.id, dealerId));
    }
  };

  const loading = fetchStatus === apiStatusConstants.PENDING ? { indicator: <CircularSpinner /> } : false;

  return (
    <>
      <StyledHeader>
        <Text>Notifications</Text>
      </StyledHeader>
      <StyledContent is-bridge-user={isBridgeUser ? 1 : 0} has-global-alert={hasGlobalAlert ? 1 : 0}>
        <Row type="flex" justify="center" gutter={[24, 8]}>
          <Col xl={8} md={12} xs={24}>
            <StyledListCard loading={loading} title="Notify Me By:">
              {notificationChannels.map((channel) => {
                if (channel.id !== SMS) {
                  return (
                    <ChannelSwitchCard
                      key={channel.id}
                      title={channel.title}
                      text={
                        channel.id === EMAIL && currentUserInfo
                          ? `${channel.description} (${currentUserInfo.email})`
                          : channel.description
                      }
                      status={channelStatus[channel.id]}
                      onChange={changeChannelSelected(channel.id)}
                    />
                  );
                }

                if (flags.reconSms) {
                  return (
                    <ChannelSwitchCard
                      key={channel.id}
                      title={channel.title}
                      text={
                        channel.id === EMAIL && currentUserInfo
                          ? `${channel.description} (${currentUserInfo.email})`
                          : channel.description
                      }
                      status={channelStatus[channel.id]}
                      onChange={changeChannelSelected(channel.id)}
                    />
                  );
                }

                return null;
              })}
            </StyledListCard>
          </Col>
          <Col xl={8} md={12} xs={24}>
            <StyledListCard loading={loading} title="Notify Me When:">
              {availableNotificationEventsWithFlags.map((event) => (
                <EventSwitchCard
                  key={event.id}
                  event={event}
                  status={selectedEvents.find((e) => event.id === e.notificationMessageType)}
                  disabled={!selectedChannels.length}
                  onChangeMessageTypes={changeEventSelected(event.id, false)}
                  onChangeDealers={changeEventSelected(event.id, true)}
                />
              ))}
            </StyledListCard>
          </Col>
        </Row>
      </StyledContent>
    </>
  );
};

const ChannelSwitchCard = (props) => <StyledChannelCard {...props} />;

const EventSwitchCard = ({ event, status, disabled, onChangeMessageTypes, onChangeDealers }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const { isLoading, accessibleDealers } = useSelector((state) => state.userSettings);

  useEffect(() => {
    if (
      status &&
      status.isActive === false &&
      status.dealerIds.filter((x) => x.checked).length === 0 &&
      status.unuseDealerIds.length === 0
    ) {
      setSearchValue('');
    }
  }, [status && status.isActive]);

  const showAll = () => {
    searchValue
      ? dispatch(userSettingsActions.searchingAccessibleDealers(searchValue, event.id))
      : dispatch(userSettingsActions.getAccessibleDealersByType(event.id, searchValue));
  };

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount =
    status && status.hasNextPage ? status && status.dealerIds.length + 1 : status && status.dealerIds.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isLoading ? () => {} : showAll;
  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => {
    return (status && !status.hasNextPage) || index < status.dealerIds.length;
  };

  const searchDealers = (e) => {
    setSearchValue(e.target.value);
    dispatch(userSettingsActions.searchingAccessibleDealers(e.target.value, event.id));
  };

  return (
    <StyledSwitchCard
      title={event.title}
      status={status && status.isActive}
      disabled={disabled}
      disabledText="Select at least one notification method"
      onChangeSwitch={(e) => onChangeMessageTypes(e)}
    >
      {event.description}

      {status && status.isActive && accessibleDealers.length > 1 && (
        <>
          <div style={{ margin: '12px 0', fontWeight: '500' }}>Choose by Dealership</div>
          {accessibleDealers.length > 10 ? (
            <Input
              style={{ marginBottom: '12px' }}
              placeholder="Type to search"
              defaultValue={''}
              value={searchValue}
              onChange={searchDealers}
              disabled={disabled}
            />
          ) : null}
          {status.dealerIds.length > 0 && accessibleDealers.length > 0 ? (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              threshold={1}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  className="List"
                  height={status.dealerIds.length > 10 ? 280 : status.dealerIds.length * 28}
                  itemCount={itemCount}
                  itemSize={28}
                  width={'100%'}
                  itemData={{ items: status.dealerIds || [], onChangeDealers, disabled, isItemLoaded }}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {memoizedCheckbox}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          ) : (
            <NoResult>No results found</NoResult>
          )}
        </>
      )}
    </StyledSwitchCard>
  );
};

const memoizedCheckbox = memo(({ data, index, style }) => {
  // Data passed to List as "itemData" is available as props.data
  const { items, onChangeDealers, disabled, isItemLoaded } = data;
  const item = items[index];
  const textComponentRef = useRef(null);
  const [width, height] = useWindowSize();
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (
      (width > 1200 && textComponentRef.current && textComponentRef.current.clientWidth >= width * 0.33 - 180) ||
      (width > 768 &&
        width <= 1200 &&
        textComponentRef.current &&
        textComponentRef.current.clientWidth >= width * 0.5 - 156) ||
      (width <= 768 && textComponentRef.current && textComponentRef.current.clientWidth >= width - 156)
    ) {
      setTitle(item.name);
    } else {
      setTitle('');
    }
  }, [width, height, textComponentRef.current]);

  const onChangeDealersTest = (e) => {
    item.checked = e.target.checked;
    onChangeDealers(items.filter((x) => x.checked).map((dealer) => dealer.id));
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 20 }} spin />;

  const calculatedWidth = () => {
    if (width > 1200) {
      return '33vw - 156px';
    } else if (width > 768 && width <= 1200) {
      return '50vw - 156px';
    } else {
      return '100vw - 156px';
    }
  };
  return !isItemLoaded(index) ? (
    <div style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin indicator={antIcon} />
    </div>
  ) : (
    <Tooltip title={title} overlayClassName="communication-tooltip">
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <StyledDealerBoxes onChange={onChangeDealersTest} disabled={disabled} checked={item?.checked}>
          <span
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: `calc(${calculatedWidth()})`,
              lineHeight: 1
            }}
            ref={textComponentRef}
          >
            {item?.name}
          </span>
        </StyledDealerBoxes>
      </div>
    </Tooltip>
  );
}, areEqual);

const NoResult = styled(Text)`
  .ant-typography& {
    color: ${({ theme }) => theme.colors.titanium700};
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: 36px;
  }
`;

const StyledDealerBoxes = styled(Checkbox)`
  display: flex !important;
  align-items: center !important;
  line-height: 1 !important;
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.titanium};
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.infoPrimary};
  }
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.borderColors.gray};
  }
  .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.borderColors.blue};
  }
  .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
  .ant-checkbox-wrapper:hover:not(.ant-checkbox-wrapper-disabled),
  .ant-radio-wrapper:hover:not(.ant-radio-wrapper-disabled) {
    background-color: initial !important;
  }
`;

const StyledHeader = styled.div`
  & > .ant-typography {
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
  margin: 24px;
  line-height: normal;
`;

const StyledChannelCard = styled(({ title, text, status, disabled, disabledText, onChange, className }) => (
  <Card
    title={title}
    type="inner"
    size="small"
    className={className}
    extra={<Switch checked={status} disabled={disabled} title={disabled ? disabledText : null} onChange={onChange} />}
  >
    <Text>{text}</Text>
  </Card>
))`
  .ant-card& {
    margin-bottom: 10px;
  }
`;

const StyledSwitchCard = styled(Card).attrs(({ title, status, disabled, disabledText, onChangeSwitch, className }) => ({
  title: title,
  type: 'inner',
  size: 'small',
  className: className,
  extra: <Switch checked={status} disabled={disabled} title={disabled ? disabledText : null} onClick={onChangeSwitch} />
}))`
  .ant-card& {
    margin-bottom: 10px;
  }
`;

//#region Styled Components
const StyledListCard = styled(({ children, title, className, loading }) => (
  <div className={className}>
    <Card title={title}>
      <List split={false} loading={loading}>
        {children}
      </List>
    </Card>
  </div>
))`
  flex: 1;
`;

const calculatedContentHeight = (isBridgeUser, hasGlobalAlert) => {
  let height = 244;
  if (hasGlobalAlert) {
    height += 42;
  }
  if (isBridgeUser) {
    height += 35;
  }
  return height;
};

const StyledContent = styled.div`
  min-height: calc(100vh - ${(props) => calculatedContentHeight(props['is-bridge-user'], props['has-global-alert'])}px);
  padding: 0 24px;
`;
//#endregion

export default withLDConsumer()(Notifications);
