import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { messageSelector } from 'store/messagesStore';
import { message as antdMessage } from 'antd';
import { notification } from 'antd4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { InfoCircleOutlined } from '@ant-design/icons';

export const AppMessages = ({ isBridgeUser, flags }) => {
  const message = useSelector(messageSelector);
  const validMessageTypes = ['info', 'success', 'warning', 'error', 'loading'];
  const newValidMessageTypes = ['info', 'success', 'warning', 'error'];
  useEffect(() => {
    if (message) {
      const { messageType, content, options } = message;
      const { duration, key, closable = false, isVendorToastMessage = false, onClose } = options;
      if (isVendorToastMessage || flags.reconToastMessageUpdate) {
        const validatedMessageType = newValidMessageTypes.includes(messageType) ? messageType : 'info'; // default to info
        notification.destroy();

        let customClassName = 'custom-ant-notification-notice';
        let icon;
        if (validatedMessageType === 'success') {
          customClassName += ' custom-ant-notification-notice-success';
          icon = <FontAwesomeIcon icon={faCheck} />;
        }
        if (validatedMessageType === 'error') {
          customClassName += ' custom-ant-notification-notice-error custom-ant-notification-notice-with-close-icon';
          icon = <FontAwesomeIcon icon={faExclamationCircle} />;
        }
        if (validatedMessageType === 'info') {
          customClassName += ' custom-ant-notification-notice-info';
          icon = <InfoCircleOutlined />;
        }

        if (closable) {
          customClassName += ' custom-ant-notification-notice-with-close-icon';
        }
        const closeIcon = closable || validatedMessageType === 'error' ? undefined : <></>;
        const durationValue = duration && duration > 5 ? duration : 5;
        const newToastDuration = validatedMessageType === 'error' ? 0 : durationValue;
        notification.open({
          duration: newToastDuration,
          className: customClassName,
          placement: 'top',
          closeIcon,
          top: isBridgeUser ? 123 : 88,
          message: content,
          icon,
          type: validatedMessageType,
          key,
          onClose
        });
      } else {
        const validatedMessageType = validMessageTypes.includes(messageType) ? messageType : 'info'; // default to info
        antdMessage[validatedMessageType]({ content, duration: duration && duration > 0 ? duration : 10, key });
      }
    }
  }, [message]);

  return null;
};

export default withLDConsumer()(AppMessages);
