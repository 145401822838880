import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useContext } from 'react';
import { BodySmall } from 'components/styledComponents';
import styled from 'styled-components';
import { Divider, Typography } from 'antd';
import { TaskContext } from '../helpers/context';
import { RECON_PLAN } from 'app-constants/planTypes';
import { COMPLETED, PENDING } from 'app-constants/taskStatusTypes';
import { ReconTaskWarningTag, ReconTag, reconTagTypes } from 'components/tags';
import { useLocation } from '@reach/router';
import { oldPathNamePlanTemplate, pathNamePlanTemplate } from 'app-constants/planTemplatePath';

const { Text } = Typography;

// workload is a property in the users data. if the assigned user to a task is found in the users data then it gets the workload. if workload is missing or the user isn't found, then returns 0 active and pending tasks
const getWorkload = (task, users) =>
  users?.find((u) => u.id === task.assignedTo)?.workload || { active: 0, pending: 0 };
const userHasNoPendingOrActiveTasks = (workload) => workload.active + workload.pending === 0;

/**
 * getAssigneeWorkloadText
 * function which determines what to show for workload portion of task card
 *
 * @param {object array} users - users data from the redux store - reconTaskSelector in Task file
 * @param {boolean} isNotInProgressStep - determined in getReconPlanData in Task component file - boolean - opposite of isInProgressStep which is determined in DragList component file. isInProgressStep is determined to be the currently in progress step
 * @param {object} task - task object
 */
export const getAssigneeWorkloadText = (users, isNotInProgressStep, task, reconTaskCollapse) => {
  // Show assignee workload if plan is new and no tasks have yet been completed, or if this task is currently in progress
  if ((!reconTaskCollapse && isNotInProgressStep) || task.status === COMPLETED) return '';
  const workload = getWorkload(task, users);
  if (!workload) return '';
  if (userHasNoPendingOrActiveTasks(workload)) return 'No tasks';

  return (
    <>
      {!reconTaskCollapse && <Divider type="vertical" style={{ marginLeft: '0px' }} />}
      {reconTaskCollapse ? (
        <BodySmall>
          {'Workload: '}
          {workload.active} active, {workload.pending} pending
        </BodySmall>
      ) : (
        <span>
          {workload.active} active, {workload.pending} pending
        </span>
      )}
    </>
  );
};

/**
 * AssigneeAndTagContent component is the HOC component which uses branching logic to determine what AssigneeAndTagContent to show
 * if planSource === RECON_PLAN, show the recon task AssigneeAndTagContent. If planSource !== RECON_PLAN show the template task assignee content
 */
const AssigneeAndTagContent = ({ flags }) => {
  /**
   * planSource comes from the DragDropContext but is passed into TaskContext in the Task component
   * planSource is set in ReconPlan and TemplatePlan
   **/
  const { planSource } = useContext(TaskContext);
  return planSource === RECON_PLAN ? (
    <AssigneeAndTagContentForReconTask flags={flags} />
  ) : (
    <AssigneeContentForTemplateTask />
  );
};

/**
 * AssigneeAndTagContentForReconTask
 * ReconTask component that shows assigned worker's name and conditionally their workload
 * also conditionally shows a warning tag (for declined status, etc)
 */
export const AssigneeAndTagContentForReconTask = ({ flags }) => {
  /**
   * context data:
   * isNotInProgressStep - determined in getReconPlanData in Task component file - boolean - opposite of isInProgressStep which is determined in DragList component file. isInProgressStep is determined to be the currently in progress step
   * task - task dto passed into Task component file and then passed into TaskContext value
   * users - users data from the redux store - reconTaskSelector in Task file
   * isSaving - whether or not the plan is currently being saved
   * reconPlanFeatures - set in Vehicles - based on user features (whether they can edit tasks)
   * hideAssigneeWorkload- boolean passed into StandaloneTask component file and then passed into TaskContext value
   * showCompletedTag- boolean passed into StandaloneTask component file and then passed into TaskContext value
   * showPendingTag- boolean passed into StandaloneTask component file and then passed into TaskContext value
   */
  const {
    task,
    users,
    isSaving,
    isNotInProgressStep,
    reconPlanFeatures,
    hideAssigneeWorkload,
    hideOverflowMenu,
    showCompletedTag,
    showPendingTag
  } = useContext(TaskContext);
  const hasEditTasks = reconPlanFeatures?.[0];
  const showingOverflowMenu = !hideOverflowMenu && hasEditTasks;

  return (
    <>
      <StyledRow>
        <StyledAssignToName data-assigned-to={task?.assignedToName}>
          <span>{task?.assignedToName || 'Unassigned'}</span>
          <>&nbsp;({task?.assignedToGroupName})</>
        </StyledAssignToName>
        <WarningTagForReconTask
          showPendingTag={showPendingTag}
          showCompletedTag={showCompletedTag}
          showingOverflowMenu={showingOverflowMenu}
          flags={flags}
        />
      </StyledRow>
      {!(!hasEditTasks || isSaving) && task?.assignedTo && !hideAssigneeWorkload ? (
        <StyledAssigneeWorkload>{getAssigneeWorkloadText(users, isNotInProgressStep, task)}</StyledAssigneeWorkload>
      ) : null}
    </>
  );
};

/**
 * AssigneeContentForTemplateTask
 * TemplateTask component that shows assigned worker's name
 */
export const AssigneeContentForTemplateTask = ({ reconTaskCollapseFlag = false }) => {
  // task is the task dto that is passed into the Task component instance
  const { task } = useContext(TaskContext);

  return (
    <StyledRow>
      <StyledAssignTo data-assigned-to={task?.assignedToName}>
        {reconTaskCollapseFlag ? <BodySmall>{task.assignedToName}</BodySmall> : <span>{task.assignedToName}</span>}
      </StyledAssignTo>
    </StyledRow>
  );
};

/**
 * WarningTagForReconTask
 */
export const WarningTagForReconTask = ({ showPendingTag, showCompletedTag, showingOverflowMenu, flags }) => {
  // task is the task dto that is passed into the Task component instance
  const { task, isInProgressTaskGroup } = useContext(TaskContext);
  const location = useLocation();

  return (
    <>
      {!task.completedOn && (
        <StyledWarningTag
          task={task}
          insideTaskCard={true}
          isInProgressTaskGroup={isInProgressTaskGroup}
          isHideNeedsApprovalTag={
            location.pathname === oldPathNamePlanTemplate || location.pathname === pathNamePlanTemplate
          }
          recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0}
          showing-overflow-menu={showingOverflowMenu?.toString()}
        />
      )}
      {/* .toString() required by styled-components lib, otherwise throws warning */}
      {task.completedOn && showCompletedTag && (
        <StyledTag
          type={reconTagTypes.COMPLETED}
          showing-overflow-menu={showingOverflowMenu?.toString()}
          recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0}
        />
      )}
      {/* .toString() required by styled-components lib, otherwise throws warning */}
      {getPenddingTag(
        task,
        showPendingTag,
        reconTagTypes.PENDING,
        showingOverflowMenu?.toString(),
        flags.reconTaskCollapse ? 1 : 0
      )}
      {/* .toString() required by styled-components lib, otherwise throws warning */}
    </>
  );
};

const getPenddingTag = (task, showPendingTag, type, showingOverflow, reconTaskCollapse) => {
  if (task.status === PENDING) {
    if (task.declinedOn || task.declined) return <></>;
    if (showPendingTag) {
      return (
        <StyledTag type={type} showing-overflow-menu={showingOverflow} recon-task-collapse-flag={reconTaskCollapse} />
      );
    }
  }
  return <></>;
};

//#region Styled Components
const StyledRow = styled.div`
  flex: 1 1 auto;
  display: flex;
`;
const StyledAssignTo = styled(Text).attrs(() => ({
  ellipsis: true
}))`
  flex: 1 1 0;
  .ant-typography& {
    color: ${(props) => !props['data-assigned-to'] && props.theme.colors.red};
    font-style: ${(props) => !props['data-assigned-to'] && 'italic'};
  }
`;

const StyledAssignToName = styled(Text).attrs(() => ({
  ellipsis: true
}))`
  flex: 1 1 0;
  span {
    color: ${(props) => !props['data-assigned-to'] && props.theme.colors.red};
  }
`;

const StyledAssigneeWorkload = styled(Text)`
  .ant-typography& {
    color: ${(props) => props.theme.colors.gray};
  }
`;
const StyledWarningTag = styled(ReconTaskWarningTag)`
  .ant-tag& {
    margin-right: ${(props) => (props['showing-overflow-menu'] ? '8px' : '')};
    height: ${(props) => (props['recon-task-collapse-flag'] ? '20px' : '24px')};
    display: ${(props) => (props['recon-task-collapse-flag'] ? 'flex' : 'block')};
    align-items: center;
  }
`;
const StyledTag = styled(ReconTag)`
  .ant-tag& {
    margin-right: ${(props) => (props['showing-overflow-menu'] ? '8px' : '')};
  }
`;
//#endregion

export default withLDConsumer()(AssigneeAndTagContent);
