import { faStoreAltSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { VendorCard } from './';

const { Text } = Typography;

const VendorListContent = ({ vendors, showInvNewVendor, isSaving, dealerId }) =>
  showInvNewVendor ? <CardListWithEmptyState vendors={vendors} isSaving={isSaving} dealerId={dealerId} /> : <></>;

const CardListWithEmptyState = ({ vendors, isSaving, dealerId }) =>
  vendors?.length === 0 ? (
    <NoVendorsContainer>
      <NoVendorsText style={{ fontSize: 24, marginBottom: 16 }}>
        <FontAwesomeIcon icon={faStoreAltSlash} />
      </NoVendorsText>
      <NoVendorsText>No Vendors Found</NoVendorsText>
      <NoVendorsDescriptionText>Not finding who you’re looking for?</NoVendorsDescriptionText>
      <NoVendorsDescriptionText>Invite them to join iRecon.</NoVendorsDescriptionText>
    </NoVendorsContainer>
  ) : (
    vendors.map((vendor, index) => (
      <VendorCard vendor={vendor} key={vendor.id + '_VendorCard'} isSaving={isSaving} dealerId={dealerId} />
    ))
  );

//#region Styled Components
const NoVendorsContainer = styled.div`
  margin-top: 94px;
  margin-bottom: 12px;
`;

const NoVendorsText = styled(Text)`
  .ant-typography& {
    margin: 0 auto 8px auto;
    color: ${({ theme }) => theme.colors.navy};
    font-weight: 500;
    display: block;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: 24px;
    letter-spacing: 0;
  }
`;

const NoVendorsDescriptionText = styled(Text)`
  .ant-typography& {
    text-align: center;
    display: block;
    max-width: 580px;
    margin: auto;
    color: ${({ theme }) => theme.colors.navy};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: 24px;
    letter-spacing: 0;
  }
`;
//#endregion

export default VendorListContent;
