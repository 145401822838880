import React from 'react';
import styled from 'styled-components';
import { CircularSpinner } from 'components';

const SavingTaskOutline = () => {
  return (
    <TaskOutline>
      <SpinnerContainer>
        <CircularSpinner size={48} />
      </SpinnerContainer>
    </TaskOutline>
  );
};

const TaskOutline = styled.div`
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  height: 75px;
  padding: 12px 12px 12px 0;
  display: flex;
  position: relative;
`;
const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default SavingTaskOutline;
