import React, { useContext } from 'react';
import styled from 'styled-components';
import { Filter } from './historicalPerformance';
import ExportFunctionality from '../../common/ExportFunctionality';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useFeatures } from 'hooks';
import { features } from 'app-constants';
import { SortContext } from './Dashboard';
import { HQAndProfitTimeContext } from 'utils/contexts';
import CommonDropdown from 'components/common/CommonDropdown';
import { Row } from 'antd';

const NavigationLinkButton = ({ onClick, label, state }) => (
  <StyledNavButton onClick={onClick} state={state}>
    {label}
  </StyledNavButton>
);

const DashboardControls = ({
  showSnapshot = true,
  toggleShowSnapshot = () => {},
  filters = {},
  setFilters = () => {},
  currentDealer = {},
  createdOn = '',
  flags
}) => {
  const { taskBreakdownSort } = useContext(SortContext);
  const { isHQ } = useContext(HQAndProfitTimeContext);

  const onTabClick = (tab) => {
    //only want the setter to hit when historical performance state is active (showSnapshot is false), or vice-versa
    if (tab === 'Inventory Snapshot' && !showSnapshot) {
      toggleShowSnapshot(true);
      return;
    }

    if (tab === 'Historical Performance' && showSnapshot) {
      toggleShowSnapshot(false);
    }
  };
  const [hasDashboardViewing] = useFeatures(features.INVENTORY_EDIT);

  return (
    <StyledContainer>
      <DashboardNavContainer>
        {/* nav button container*/}
        <NavigationLinkButton //inventory snapshot button
          onClick={() => onTabClick('Inventory Snapshot')}
          label="Inventory Snapshot"
          state={showSnapshot ? 'active' : 'inactive'} //for styling - active state shows red underline, navy color
        />
        <NavigationLinkButton //historical performance button
          onClick={() => onTabClick('Historical Performance')}
          label="Historical Performance"
          state={!showSnapshot ? 'active' : 'inactive'}
        />
      </DashboardNavContainer>
      <Row type="flex" align="middle" justify="center">
        {!showSnapshot && (
          <Filter
            vehicleDispositionFilter={currentDealer?.vehicleDispositionFilter}
            setFilters={setFilters}
            filters={filters}
            createdOn={createdOn}
            currentDealer={currentDealer}
          />
        )}
        {isHQ && flags.reconDealerFilter && (
          <CommonDropdown
            title="Dealerships: "
            identifier="dealerships"
            tooltipMessage="Use the “Dealerships” menu to select one or more dealerships."
            additionalStyle={{ marginLeft: '12px' }}
          />
        )}
        {flags.reconExportDashboard && hasDashboardViewing && (
          <ExportFunctionality
            dividerUndisplay={true}
            exportType={showSnapshot ? 'Inventory' : 'Historical'}
            currentEntityId={currentDealer.id}
            filters={filters}
            taskBreakdownSort={taskBreakdownSort}
          />
        )}
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DashboardNavContainer = styled.div``;

const StyledNavButton = styled.button`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme, state }) => (state === 'active' ? theme.colors.navy : theme.colors.darkGray)};
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: 30px;
  border-bottom: ${({ theme, state }) =>
    (state === 'active' && theme.borders.activeNavBorder) || theme.borders.inactiveNavBorder};
  font-weight: ${({ theme, state }) => (state === 'active' ? theme.fontWeights.semibold : theme.fontWeights.medium)};
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${({ theme, state }) => state === 'inactive' && theme.colors.navy};
  }
`;

export default withLDConsumer()(DashboardControls);
