import React, { useEffect, useState } from 'react';
import { CreateStringWithURLClickable } from 'utils/createStringWithURLClickable.js';
import styled from 'styled-components';
import { CommunicationBase } from '.';
import { CommunicationImages, CommunicationOverflowMenu, EditCommunicationInput } from '../common';
import { dateTimeFromNow } from 'utils/dateTimeUtils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { communicationContexts, locationActions } from 'app-constants';
import { useTaskReadOnlyStatus } from '../../../hooks/useTaskReadOnlyStatus';

const TaskMessage = ({
  id,
  user,
  userId,
  firstName,
  lastName,
  fullName,
  content,
  comment,
  createdOn,
  updatedOn,
  reconTaskTypeName,
  reconTaskId,
  images,
  scrollToMessage = false,
  updateCommunication,
  deleteCommunication,
  taggableUsers,
  indent = false,
  style,
  flags,
  index,
  location,
  taskIsDeleted
}) => {
  const initials = `${firstName[0]}${lastName[0]}`.toUpperCase();
  const {
    isShowLoadingSendCommunication,
    indexCommunicationWhenUpdate,
    focusToCommunicationId,
    locationAction
  } = useSelector((state) => state.vehicles);
  const [transientCommunication, setTransientCommunication] = useState('');
  const [showEditor, setShowEditor] = useState(isShowLoadingSendCommunication || false);
  const [indexCommunication, setIndexCommunication] = useState(indexCommunicationWhenUpdate || 0);
  const isReadonlyTask = useTaskReadOnlyStatus(reconTaskId, flags.reconVendorManagement);

  useEffect(() => {
    if (scrollToMessage) {
      const node = document.getElementById(id);
      if (node) {
        node.scrollIntoView();
      }
    }
  }, [id, scrollToMessage]);

  const getLocationActionSubmit = (location) => {
    if (
      location === communicationContexts.TASK_DETAILS_PAGE ||
      location === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER
    ) {
      return locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_TASK_DETAIL_PAGE;
    } else if (location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE) {
      return locationActions.BUTTON_SAVE_VEHICLE_EDIT_COMMUNICATION_PAGE_MESSAGE;
    } else if (location === communicationContexts.VEHICLE_DETAILS_PAGE) {
      return locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DETAIL_PAGE;
    } else if (location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER) {
      return locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DRAWER_COMMUNICATION_PAGE;
    } else {
      return null;
    }
  };

  const handleShowEditor = (state) => {
    setShowEditor(state);
  };

  const checkShowEditorTaskPage = () => {
    return (
      showEditor &&
      index === indexCommunication &&
      (location === communicationContexts.TASKS_PAGE_MESSAGE_DRAWER ||
        location === communicationContexts.TASK_DETAILS_PAGE)
    );
  };

  const hasShowEditorTaskPage = checkShowEditorTaskPage();

  const checkShowEditorInventoryPage = () => {
    return (
      showEditor &&
      ((index === indexCommunication && focusToCommunicationId === null) || focusToCommunicationId === id) &&
      ((locationAction === locationActions.BUTTON_SAVE_VEHICLE_EDIT_COMMUNICATION_PAGE_MESSAGE &&
        location === communicationContexts.VEHICLE_DETAILS_PAGE_MESSAGE) ||
        (locationAction === locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DETAIL_PAGE &&
          location === communicationContexts.VEHICLE_DETAILS_PAGE) ||
        (locationAction === locationActions.BUTTON_SAVE_EDIT_COMMUNICATION_VEHICLE_DRAWER_COMMUNICATION_PAGE &&
          location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER) ||
        locationAction === null)
    );
  };

  const hasShowEditorInventoryPage = checkShowEditorInventoryPage();

  return (
    <CommunicationBase
      id={id}
      indent={indent}
      style={style}
      authorId={userId}
      authorName={fullName}
      authorInitials={initials}
      datetime={dateTimeFromNow(createdOn)}
      hoverDateTime={createdOn}
      headerExtra={
        user.id === userId &&
        !isReadonlyTask && (
          <CommunicationOverflowMenu
            id={id}
            content={content}
            comment={comment}
            images={images}
            setShowEditor={handleShowEditor}
            setTransientCommunication={setTransientCommunication}
            deleteCommunication={deleteCommunication}
            flags={flags}
            loading={isShowLoadingSendCommunication}
            setIndexCommunication={setIndexCommunication}
            index={index}
            successMessage={communicationContexts.MESSAGE_SUCCESS_DELETE_MESSAGE}
            loadingMessage={communicationContexts.MESSAGE_LOADING_DELETE_MESSAGE}
            taskIsDeleted={taskIsDeleted}
          />
        )
      }
      content={
        <>
          {hasShowEditorTaskPage || hasShowEditorInventoryPage ? (
            <EditCommunicationInput
              id={id}
              transientCommunication={transientCommunication}
              setTransientCommunication={setTransientCommunication}
              setShowEditor={handleShowEditor}
              updateCommunication={updateCommunication}
              flags={flags}
              images={images}
              setIndexCommunication={setIndexCommunication}
              index={index}
              indexCommunication={indexCommunication}
              location={location}
              getLocationActionSubmit={getLocationActionSubmit}
              taggableUsers={taggableUsers}
              taskId={reconTaskId}
            />
          ) : (
            <>
              <StyledContent>
                {<CreateStringWithURLClickable content={content} comment={comment} />}
                {!!updatedOn ? <StyledEditedStamp>(Edited)</StyledEditedStamp> : null}
              </StyledContent>
              <CommunicationImages images={images} createdOn={createdOn} reconTaskTypeName={reconTaskTypeName} />
            </>
          )}
        </>
      }
    />
  );
};

//#region Styled Components
const StyledContent = styled.p`
  margin-bottom: 0px;
  overflow-wrap: break-word;
`;

const StyledEditedStamp = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
//#endregion

export default withLDConsumer()(TaskMessage);
