import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { Select } from 'antd';
import { formatterNumberUSD } from 'utils/numberUtils';
import { CommonSelect } from 'components/styledComponents';
import { apiStatusConstants, IS_NEW_SIGNIN, VENDOR_USER_INFO } from 'app-constants';
import { DEALER_INTERNAL_GROUP } from 'app-constants/groupTypes';
import { AssignedToSelect } from 'components';
import { StyledFormItem } from './AddNewOrEditTaskForm';
import { rootEntitySwitcherSelector } from 'store/dealersStore';

const { Option } = Select;

const assignGroupTaskFormSelector = createSelector(
  (state) => state.users,
  (usersContainer) => {
    return {
      users: usersContainer.assigneeData?.internalUsers,
      internalGroups: usersContainer.assigneeData?.internalGroups,
      vendors: usersContainer.assigneeData?.vendors,
      usersFetchStatus: usersContainer.fetchStatus,
      vendorUsers: usersContainer.assigneeData?.vendors?.length
        ? usersContainer.assigneeData?.vendors[0]?.vendorUsers
        : []
    };
  }
);

const AssignGroupTaskForm = ({
  taskToUpdate,
  getFieldDecorator,
  onChange,
  assignedToOptional,
  assignedToMember,
  userIdGetAssignToByTypeName,
  flags,
  isAssignTask
}) => {
  const { users, internalGroups, vendors, usersFetchStatus, vendorUsers } = useSelector(assignGroupTaskFormSelector);
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const [members, setMembers] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);
  const isNewSignin = localStorage.getItem(IS_NEW_SIGNIN) === 'true';
  const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
  const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
  const isVendorAdmin = (vendorInfo.role?.id === 1 && isNewSignin) || (isRootUser && vendorShipSelected);
  const groupSuffix = currentGroup ? ` (${currentGroup.name})` : '';

  useEffect(() => {
    if (
      internalGroups &&
      users &&
      taskToUpdate &&
      taskToUpdate.assignedToGroupId &&
      taskToUpdate.assignedToGroupType === DEALER_INTERNAL_GROUP
    ) {
      const currentGroup = internalGroups.find((group) => group.id === taskToUpdate.assignedToGroupId);
      if (currentGroup) {
        // default value
        const memberDetails = [];
        let memberUnassigned = currentGroup.internalGroupUsers?.find(
          (groupMember) => groupMember.fullName === 'Unassigned'
        );
        if (memberUnassigned) {
          memberUnassigned.id = null;
          memberDetails.push(memberUnassigned);
        }
        users.forEach((u) => {
          const member = currentGroup.internalGroupUsers?.find((groupMember) => groupMember.userId === u.id);
          if (member)
            memberDetails.push({
              ...u,
              workload: member.workload
            });
        });
        setMembers(memberDetails);
        setCurrentGroup(currentGroup);
      }
    }
  }, [users, internalGroups, taskToUpdate]);

  useEffect(() => {
    if (flags.reconVendorManagement && isVendorAdmin && vendorUsers.length > 0) {
      const vendorUserDetails = vendorUsers.map((user) => ({
        ...user,
        id: user.fullName === 'Unassigned' ? null : user.id
      }));
      setMembers(vendorUserDetails);
    }
  }, [vendorUsers]);
  const emDash = String.fromCharCode(8212);

  return (
    <AssignGroupTaskFormWrapper>
      <StyledFormItem label="Type">
        <DisableValueWrapper>{taskToUpdate.reconTaskTypeName}</DisableValueWrapper>
      </StyledFormItem>

      {isAssignTask ? (
        <AssignedToSelect
          getFieldDecorator={getFieldDecorator}
          assignedToOptional={assignedToOptional}
          users={users}
          vendors={vendors}
          internalGroups={internalGroups}
          usersFetchStatus={usersFetchStatus}
          onChange={onChange}
          assignedToMember={assignedToMember}
          userIdGetAssignToByTypeName={userIdGetAssignToByTypeName}
          taskToUpdate={taskToUpdate}
        />
      ) : (
        getFieldDecorator('assignedTo')(
          <StyledFormItem label="Assigned To">
            <AssigneeSelect
              showSearch
              placeholder="Select a member"
              loading={!usersFetchStatus || usersFetchStatus === apiStatusConstants.IS_FETCHING || !members}
              onChange={onChange('assignedTo')}
              optionFilterProp="label"
              optionLabelProp="selectedLabel"
              value={
                userIdGetAssignToByTypeName === undefined
                  ? taskToUpdate?.assignedTo || null
                  : userIdGetAssignToByTypeName
              }
              key={JSON.stringify(taskToUpdate)}
            >
              {members?.map((u, index) => (
                <Option
                  key={u.id + '_AssigneeSelectOption'}
                  value={u.id}
                  label={u.fullName}
                  selectedLabel={SelectedAssigneeLabel(u, currentGroup)}
                >
                  <AssigneeOptionContent>
                    <AssigneeOptionContentName>{`${u.fullName}${groupSuffix}`}</AssigneeOptionContentName>
                    <AssigneeOptionContentWorkload className="workload">
                      {getUserWorkloadText(u)}
                    </AssigneeOptionContentWorkload>
                  </AssigneeOptionContent>
                </Option>
              ))}
            </AssigneeSelect>
          </StyledFormItem>
        )
      )}

      <StyledFormItem label="Estimated Cost">
        <DisableValueWrapper>
          {isNaN(taskToUpdate.estimatedCost) || !taskToUpdate.estimatedCost
            ? emDash
            : `$${formatterNumberUSD(taskToUpdate.estimatedCost, 2)}`}
        </DisableValueWrapper>
      </StyledFormItem>
      <StyledFormItem label="Description">
        <DisableValueWrapper>{taskToUpdate.description ?? emDash}</DisableValueWrapper>
      </StyledFormItem>
    </AssignGroupTaskFormWrapper>
  );
};

export const getUserWorkloadText = (user) => {
  if (!user.workload || user.workload.active + user.workload.pending === 0) return 'No tasks';
  return (
    <span>
      {user.workload.active} active, {user.workload.pending} pending
    </span>
  );
};

export const SelectedAssigneeLabel = (assignee, group) => {
  const groupSuffix = group ? ` (${group.name})` : '';
  return (
    <StyledAssigneeLabel>
      <StyledAssigneeLabelName>{`${assignee.fullName}${groupSuffix}`}</StyledAssigneeLabelName>
      <StyledAssigneeLabelWorkload>{getUserWorkloadText(assignee)}</StyledAssigneeLabelWorkload>
    </StyledAssigneeLabel>
  );
};

const AssignGroupTaskFormWrapper = styled.div``;

const AssigneeOptionContentName = styled.div``;

const AssigneeOptionContentWorkload = styled.div``;

const DisableValueWrapper = styled.div`
  padding-left: 16px;
`;

const AssigneeOptionContent = styled.div`
  .workload {
    color: ${({ theme }) => theme.colors.gray};
  }
  &:hover > .workload {
    color: ${({ theme }) => theme.colors.navy};
  }
`;

const AssigneeSelect = styled(CommonSelect)`
  .ant-select-selection-selected-value {
    max-width: none;
    width: 100%;
  }
`;

const StyledAssigneeLabel = styled.div`
  display: flex;
  width: 100%;
`;

const StyledAssigneeLabelName = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledAssigneeLabelWorkload = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  padding: 0px 12px;
  text-align: right;
  flex: 1 1 0px;
`;

export default withLDConsumer()(AssignGroupTaskForm);
