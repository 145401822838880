import React, { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { communicationContexts } from 'app-constants';
import { LoadingRedButton } from 'components/common';
import { theme as themeStyles } from 'styles/appTheme';
import { MentionsSuggestion } from './MentionsSuggestion';

const EditCommunicationInput = ({
  id,
  transientCommunication,
  setTransientCommunication,
  setShowEditor,
  updateCommunication,
  images,
  index,
  setIndexCommunication,
  indexCommunication,
  location,
  getLocationActionSubmit,
  taggableUsers = [],
  taskId,
  flags
}) => {
  const { isShowLoadingSendCommunication } = useSelector((state) => state.vehicles);
  const [contentData, setContentData] = useState('');

  return (
    <StyledEditInputContainer>
      <MentionsSuggestion
        disabled={isShowLoadingSendCommunication}
        transientCommunication={transientCommunication}
        setTransientCommunication={setTransientCommunication}
        setContentData={setContentData}
        taggableUsers={taggableUsers}
        taskId={taskId}
        isEdit={true}
        flags={flags}
      />
      <CommunicationEditor
        transientCommunication={transientCommunication}
        contentData={contentData}
        updateCommunication={updateCommunication}
        setShowEditor={setShowEditor}
        images={images}
        isLoading={isShowLoadingSendCommunication}
        index={index}
        setIndexCommunication={setIndexCommunication}
        indexCommunication={indexCommunication}
        location={location}
        getLocationActionSubmit={getLocationActionSubmit}
        flags={flags}
      />
    </StyledEditInputContainer>
  );
};

const CommunicationEditor = ({
  transientCommunication,
  updateCommunication,
  contentData,
  setShowEditor,
  isLoading,
  setIndexCommunication,
  index,
  location,
  getLocationActionSubmit,
  flags,
  images
}) => {
  const handleSaveEditCommunication = () => {
    let locationAction = getLocationActionSubmit(location);
    setIndexCommunication(index);
    setShowEditor(true);
    let successMessage = 'Updated a message successfully!';
    if (
      location === communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER ||
      location === communicationContexts.VEHICLE_DETAILS_PAGE
    ) {
      successMessage = 'Updated a note successfully!';
    }
    updateCommunication(transientCommunication, contentData, locationAction, successMessage);
  };

  const isDisableButton = (!transientCommunication || transientCommunication.length === 0) && images?.length === 0;

  return (
    <StyledButtonContainer>
      <StyledCancelButtonCommunication disabled={isLoading} onClick={(e) => setShowEditor(false)}>
        Cancel
      </StyledCancelButtonCommunication>
      <LoadingRedButton
        theme={themeStyles}
        loadingIcon={isLoading}
        onClick={handleSaveEditCommunication}
        disabled={isLoading || isDisableButton}
      >
        Save
      </LoadingRedButton>
    </StyledButtonContainer>
  );
};

//#region Styled Components
const StyledEditInputContainer = styled.div`
  display: block;
  width: 100%;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;

  .mention-editor {
    :active,
    :focus,
    :hover {
      border: ${({ theme }) => `${theme.borders.thickSolidLightNavy}`};
      border-right-width: 2px !important;
    }

    border: 2px solid #d9d9d9;
    border-radius: 4px;
  }
`;

export const StyledCancelButton = styled(Button).attrs({
  type: 'link'
})`
  .ant-btn-link& {
    color: ${({ theme }) => theme.colors.red};
    margin-right: 8px;
    height: 32px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: 500;
    padding: 0;
    :hover,
    :active,
    :visited,
    :focus {
      color: ${({ theme }) => theme.toggle.red};
    }
  }
`;

export const StyledCancelButtonCommunication = styled(Button).attrs({
  type: 'link'
})`
  .ant-btn-link& {
    color: ${({ theme }) => theme.colors.red};
    margin-right: 8px;
    height: 32px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: 500;
    padding: 0;
    :hover,
    :active,
    :visited,
    :focus {
      color: ${({ theme }) => theme.toggle.red};
    }
  }
`;

const StyledButtonContainer = styled.div`
  float: right;
  margin-top: 5px;
`;
//#endregion

export default withLDConsumer()(EditCommunicationInput);
