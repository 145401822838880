import { useFeatures } from 'hooks';
import { features } from 'app-constants';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from 'antd';
import {
  CommonStrongText,
  GridArea,
  CommonBreadcrumb,
  CommonBreadcrumbButton,
  CommonBreadcrumbLink,
  HeaderSmall,
  BodyXSmallBold
} from 'components/styledComponents';
import { ReconPlan, TaskForm } from '../reconPlan';
import { getInProgressStep, isInProgressTaskGroup } from 'components/TaskGroupsDnd/helpers/functions';
import { COMPLETED, IN_PROGRESS, DECLINED } from '../../../../app-constants/taskStatusTypes';
import { tasksActions } from 'store/tasksStore';
import { vdpActions } from '../../../../store/vdpStore';
import { stripReferences } from '../../../../utils/arrayUtils';
import {
  isTaskComplete,
  isTaskGroupCompleted,
  isTaskGroupIncomplete,
  isTaskIncomplete
} from '../../../TaskGroupsDnd/helpers/functions';
import { prepareTaskGroupsAfterTaskDeletion } from '../vehicles/functions';

export const ReconPlanContainer = ({
  drawerReady,
  vehicle,
  closeDrawer,
  formProps,
  setFormProps,
  closeForm,
  showDiscardButton,
  setShowDiscardButton,
  scrollToId,
  setScrollToId,
  isSaving,
  taskGroups,
  setTaskGroups,
  originalTaskGroups,
  planStarted,
  onTaskChange,
  templateDropdownMenu,
  planTemplates,
  showCreateReconPlanFromTemplateButton,
  contentType,
  setTemplatePlanCreatedFrom,
  templatePlanCreatedFrom,
  reconPlanFeatures,
  flags
}) => {
  const { tasks } = vehicle;
  const [hasEditTasks, hasWorkOwnTasks, hasDealerSettings, hasLineItemsEditRole] = useFeatures(
    features.TASKS_EDIT,
    features.TASKS_WORK_OWN,
    features.DEALER_SETTINGS,
    features.LINE_ITEMS_EDIT
  );
  const isInternalTech = !hasEditTasks && !hasDealerSettings && hasLineItemsEditRole;
  const enableCompleteForInternalTech = isInternalTech && hasWorkOwnTasks;
  const showTaskForm = (hasEditTasks || !hasLineItemsEditRole || enableCompleteForInternalTech) && !!formProps;
  const overflowContainerUS474065 = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(tasksActions.resetTasksLineItemByVehicleId());
    };
  }, []);

  const getBreadcrumbLabel = () => {
    if (formProps?.isMessageTask) return 'Messages';
    if (formProps?.isLineItemsTask) return 'Line Items';
    if (!formProps?.taskToUpdate) return 'Add Task';
    if (formProps?.onComplete) return 'Complete Task';
    if (formProps?.taskToUpdate?.status !== COMPLETED) return flags.reconBreadcrumbUpdate ? 'Edit Task' : 'Update Task';
    if (formProps?.taskToUpdate?.status === COMPLETED) return 'Edit Completed Task';
  };

  const onTaskChangeWithTaskGroups = (type) => (task) => {
    const reconTaskGroupId = vehicle.reconPlan?.reconTaskGroups?.items?.find((taskGroup) => {
      const reconTasks = taskGroup?.reconTasks?.items;
      const selectedTask = reconTasks.find((reconTask) => reconTask.id === task.id);
      return selectedTask;
    })?.id;
    switch (type) {
      case 'update':
      case 'complete': {
        // Sequence won't have changed - this was triggered from the task's overflow menu

        // we are modifying a task group's tasks, so we will be pulling that task group out to modify it
        let indexOfGroup = taskGroups.findIndex((tg) => tg.id === reconTaskGroupId);
        // we will be overriding the current task data with the new task data, so get the index of the task in the task group so that we can replace it
        let indexOfTask = indexOfGroup === -1 ? -1 : taskGroups[indexOfGroup].tasks.findIndex((t) => t.id === task.id);
        if (indexOfTask > -1) {
          // create mutable task
          const modifiableTask = stripReferences(task);
          // if the type of action performed on the task was to complete the task, update its status so that it looks complete immediately
          if (type === 'complete') {
            modifiableTask.status = COMPLETED;
          }

          /*
           * Strip references so children components will actually detect a change.
           * Otherwise, the references doesn't change and useEffects won't pick up any changes,
           * and children components won't re-render!
           */
          const newGroups = stripReferences(taskGroups);
          const updatedGroup = taskGroups[indexOfGroup] && stripReferences(taskGroups[indexOfGroup]);
          updatedGroup.tasks.splice(indexOfTask, 1, modifiableTask);
          newGroups[indexOfGroup] = updatedGroup;

          // additionally, since complete action can only happen on the in progress task/task group, then we need to check to see if there are any updates needed for the in progress task
          if (type === 'complete') {
            /**
             * if the completed task is in a task group, then there's a potential that there are other tasks in the task group that still need to be updated
             * in this case, no updates are needed to the in progress task
             * however, if the completed task was the only task left in the group that wasn't completed, then need to switch to the next task group to be in progress
             **/
            const isTaskGroupComplete = isTaskGroupCompleted(newGroups[indexOfGroup]);

            if (isTaskGroupComplete) {
              // if the task group is complete, then check if it is the last task group in the plan. if it isn't, then update the next one to be in progress
              if (indexOfGroup + 1 !== newGroups.length) {
                // checking if the next index after the modified group isn't equal to the length of newGroups checks if there are any task groups after the one that was just modified
                newGroups[indexOfGroup + 1].tasks = newGroups[indexOfGroup + 1].tasks.map((task) => {
                  return {
                    ...task,
                    status: task.declined
                      ? DECLINED
                      : task.status !== COMPLETED && task.status !== DECLINED
                      ? IN_PROGRESS
                      : task.status
                  };
                });
              }
            }

            //finally, sort the tasks again, so that completed tasks come before incomplete tasks
            //sort at step level
            const completedTaskGroups = stripReferences(newGroups?.filter(isTaskGroupCompleted));
            const incompleteTaskGroups = stripReferences(newGroups?.filter(isTaskGroupIncomplete));
            const taskGroupsSortedByCompleteStatus = [...completedTaskGroups, ...incompleteTaskGroups];
            //sort at task group level
            const taskGroupsSortedWithGroupsSortedByCompleteStatus = taskGroupsSortedByCompleteStatus.map(
              (taskGroup) => {
                const completedTasks = taskGroup?.tasks?.filter(isTaskComplete);
                const incompleteTasks = taskGroup?.tasks?.filter(isTaskIncomplete);
                return {
                  ...taskGroup,
                  tasks: [...completedTasks, ...incompleteTasks]
                };
              }
            );
            setTaskGroups(taskGroupsSortedWithGroupsSortedByCompleteStatus);
          } else {
            setTaskGroups(newGroups);
          }
        }
        break;
      }
      case 'delete': {
        let indexOfGroup = taskGroups.findIndex((taskGroup) => taskGroup.id === reconTaskGroupId);
        if (indexOfGroup !== -1) {
          const newGroups = stripReferences(taskGroups);
          const groupToUpdate = taskGroups[indexOfGroup] && stripReferences(taskGroups[indexOfGroup]);
          const updatedGroup = {
            ...groupToUpdate,
            tasks: groupToUpdate.tasks.filter((t) => t.id !== task.id)
          };
          if (updatedGroup.tasks.length === 0) {
            // if after removing this task from the group, there are no more tasks in the group, then delete the task group from the plan
            newGroups.splice(indexOfGroup, 1);
          } else {
            // if there are remaining tasks, then replace the existing group in taskGroups with the updated one
            newGroups[indexOfGroup] = updatedGroup;
          }

          // set status Task Deleted for Comments
          dispatch(vdpActions.setCommentsTaskIsDeleted(task.id));

          /**
           * was deleted task in progress?
           * not enough to check if the task had in progress status. a task can be in the in progress position, but have a status of DECLINED
           * need to look at where the last completed task was and what came after it
           * the deleted task was not a completed task because you cannot delete completed tasks
           * we have the index of this task, and we can compare where the in progress position would be against the index of this task
           **/

          const indexOfFirstIncompleteTaskGroupInPreviousTaskGroups = taskGroups.findIndex(isTaskGroupIncomplete); // will return first instance of where a task in a task group is incomplete
          const deletedTaskWasAtInProgressPosition =
            indexOfFirstIncompleteTaskGroupInPreviousTaskGroups === indexOfGroup;

          prepareTaskGroupsAfterTaskDeletion(
            setTaskGroups,
            newGroups,
            indexOfGroup,
            updatedGroup,
            deletedTaskWasAtInProgressPosition,
            flags
          );
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <StyledReconPlanGridArea>
      <StyledReconPlanHeaderLabel
        show-task-form={showTaskForm ? 1 : 0}
        recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}
      >
        {flags.reconBreadcrumbUpdate ? (
          <>
            {showTaskForm && (
              <CommonBreadcrumbLink
                style={{ alignSelf: 'flex-start', marginBottom: '-3px' }}
                onClick={() => {
                  if (showTaskForm) {
                    closeForm();
                  }
                }}
              >
                <BodyXSmallBold>
                  <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '4px', height: '13px' }} />
                  Back to Recon Plan
                </BodyXSmallBold>
              </CommonBreadcrumbLink>
            )}
            <StyledLabelItem>
              {showTaskForm ? <HeaderSmall>{getBreadcrumbLabel()}</HeaderSmall> : 'Recon Plan'}
            </StyledLabelItem>
          </>
        ) : (
          <CommonBreadcrumb style={{ fontSize: '18px' }} separator=">">
            <Breadcrumb.Item>
              <CommonBreadcrumbButton
                disabled={!showTaskForm}
                onClick={() => {
                  if (showTaskForm) {
                    closeForm();
                  }
                }}
              >
                Recon Plan
              </CommonBreadcrumbButton>
            </Breadcrumb.Item>
            {showTaskForm && <Breadcrumb.Item>{getBreadcrumbLabel()}</Breadcrumb.Item>}
          </CommonBreadcrumb>
        )}
      </StyledReconPlanHeaderLabel>
      <StyledReconPlanContainer
        ref={overflowContainerUS474065}
        show-task-form={showTaskForm ? 1 : 0}
        recon-breadcrumb-update-flag={flags.reconBreadcrumbUpdate ? 1 : 0}
        is-message={formProps?.isMessageTask}
      >
        {!showTaskForm ? (
          <ReconPlan
            drawerReady={drawerReady}
            vehicle={vehicle}
            tasks={tasks}
            closeDrawer={closeDrawer}
            setFormProps={setFormProps}
            showDiscardButton={showDiscardButton}
            scrollToId={scrollToId}
            isSaving={isSaving}
            taskGroups={taskGroups}
            setTaskGroups={setTaskGroups}
            originalTaskGroups={originalTaskGroups}
            planStarted={planStarted}
            onTaskChange={onTaskChange}
            showCancelButton={false}
            templateDropdownMenu={templateDropdownMenu}
            planTemplates={planTemplates}
            showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
            scrollcontainer={overflowContainerUS474065} // this is for tracking when scrolling should occur in the vdp rpb
            contentType={contentType}
            setTemplatePlanCreatedFrom={setTemplatePlanCreatedFrom}
            reconPlanFeatures={reconPlanFeatures}
            templatePlanCreatedFrom={templatePlanCreatedFrom}
          />
        ) : (
          <TaskForm
            contentType={contentType}
            isShowContentSegmentTDP={true}
            vehicle={vehicle}
            reconPlanFeatures={reconPlanFeatures}
            planStarted={planStarted}
            scrollToId={scrollToId}
            showDiscardButton={showDiscardButton}
            setShowDiscardButton={setShowDiscardButton}
            setFormProps={setFormProps}
            taskGroups={taskGroups}
            onTaskChange={onTaskChange}
            isSaving={isSaving}
            setTaskGroups={setTaskGroups}
            isMessageTask={formProps.isMessageTask}
            isLineItemsTask={formProps.isLineItemsTask}
            parentId={formProps.vehicleId}
            drawerReady={drawerReady}
            vin={vehicle.vin}
            taskToUpdate={formProps.taskToUpdate}
            initialFocus={vehicle.id}
            closeForm={closeForm}
            setScrollToId={setScrollToId}
            onCreate={formProps.onCreate}
            onComplete={formProps.onComplete}
            onUpdate={
              flags.reconApproveButton && formProps?.isLineItemsTask && !formProps.onUpdate
                ? onTaskChangeWithTaskGroups('update')
                : formProps.onUpdate
            }
            onCompleteTaskMessage={
              flags.reconApproveButton && formProps?.isLineItemsTask && !formProps.onCompleteTaskMessage
                ? onTaskChangeWithTaskGroups('complete')
                : formProps.onCompleteTaskMessage
            }
            onDelete={
              flags.reconApproveButton && formProps?.isLineItemsTask && !formProps.onDelete
                ? onTaskChangeWithTaskGroups('delete')
                : formProps.onDelete
            }
            affixTarget={overflowContainerUS474065}
            templateDropdownMenu={templateDropdownMenu}
            planTemplates={planTemplates}
            showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
            formHeight={'calc(100vh - 270px)'}
            isInProgressTaskGroup={isInProgressTaskGroup(formProps.taskToUpdate, getInProgressStep(taskGroups), flags)}
          />
        )}
      </StyledReconPlanContainer>
    </StyledReconPlanGridArea>
  );
};

//#region Styled Components for ReconPlan

const StyledReconPlanGridArea = GridArea('reconPlan', 2, 1);
const StyledHeaderLabel = styled(CommonStrongText)`
  padding: ${(props) =>
    props['recon-breadcrumb-update-flag']
      ? props['show-task-form']
        ? '10px 24px'
        : '34px 24px 20px 24px'
      : '24px 24px 20px 24px'};
  line-height: 10px;
`;
const StyledLabelItem = styled.div`
  color: ${({ theme }) => theme.colors.navy};
`;
export const StyledReconPlanHeaderLabel = styled(StyledHeaderLabel)`
  display: flex;
  flex-direction: column;
  border-right: ${({ theme }) => theme.borders.thinSolidGray};
`;
export const StyledReconPlanContainer = styled.div`
  border-right: ${({ theme }) => theme.borders.thinSolidGray};
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: ${(props) => (props['is-message'] ? 'unset' : 'auto')};
  height: calc(100vh - ${(props) => (props['recon-breadcrumb-update-flag'] ? '243px' : '235px')});
`;
//#endregion
