import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

const FullWidthDivider = () => (
  <StyledDividerContainer>
    <StyledDivider />
  </StyledDividerContainer>
);

const StyledDividerContainer = styled.div`
  margin: 0px -24px;
`;
const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 16px 0;
  }
`;

export default FullWidthDivider;
