import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Typography, Input, Form } from 'antd';
import { maxBy, clone, isEqual } from 'lodash';
import { Stack } from 'components';
import { CommonLinkButton, CommonRedButton, CommonFooterContainer } from 'components/styledComponents';
import ExpandCollapseLinkButton from 'components/common/ExpandCollapseLinkButton';
import { displayDurationSummary, getMomentDurationFromString } from 'utils/dateTimeUtils';
import { formatPrice } from 'utils/numberUtils';
import { DragDropContext as OurDragDropContext } from 'utils/contexts'; //TODO: change this to be just DragDropContext when flag is removed.
import { StyledFormItem } from '../common/Styles';
import { TaskGroupsDnd } from 'components/TaskGroupsDnd';
import { TEMPLATE_PLAN } from 'app-constants/planTypes';

const { Text } = Typography;

const TemplatePlan = ({
  form: { getFieldDecorator, setFieldsValue, validateFields },
  planTemplate,
  templateFormValues,
  closeDrawer,
  setFormProps,
  showDiscardButton,
  handleCanClose,
  scrollToId,
  isSaving,
  taskGroups,
  setTaskGroups,
  onSave,
  onTaskChange,
  onChangeTemplateMetadata,
  templateFormIsDirty,
  isNameTaken,
  onDelete,
  flags
}) => {
  const scrollContainer = useRef();
  const dispatch = useDispatch();
  const [dndData, setDndData] = useState({
    scrollToId,
    scrollContainer,
    setFormProps,
    onTaskChange,
    isSaving,
    planSource: TEMPLATE_PLAN,
    taskGroups,
    planTemplate,
    setTaskGroups,
    dispatch
  });

  useEffect(() => {
    const newDndData = {
      scrollToId,
      scrollContainer,
      setFormProps,
      onTaskChange,
      isSaving,
      planSource: TEMPLATE_PLAN,
      taskGroups,
      setTaskGroups,
      planTemplate,
      dispatch
    };
    const copyOfDndData = clone({ ...dndData });
    const changeInDndData = !isEqual(copyOfDndData, newDndData);
    changeInDndData && setDndData(newDndData);
  }, [
    dndData,
    scrollToId,
    scrollContainer,
    setFormProps,
    onTaskChange,
    isSaving,
    taskGroups,
    setTaskGroups,
    planTemplate
  ]);

  useEffect(() => {
    if (planTemplate) {
      setFieldsValue({
        name: templateFormValues.name,
        description: templateFormValues.description
      });
      setDisabled(getSaveButtonStatus(templateFormValues.name ?? ''));
    }
  }, [templateFormValues]);

  const [isDisabled, setDisabled] = useState(true);
  const [isDuplicated, setDuplicated] = useState(false);

  const PlanStatistics = () => {
    const emDash = String.fromCharCode(8212);
    const totalTime = displayDurationSummary(
      taskGroups?.reduce((acc, tg) => {
        let maxTimeTaskInGroup = maxBy(tg.tasks, (t) => getMomentDurationFromString(t.goalThreshold));
        return acc.add(getMomentDurationFromString(maxTimeTaskInGroup.goalThreshold));
      }, moment.duration(0)),
      true,
      true,
      false,
      emDash
    );
    const localTasks = taskGroups.reduce((acc, tg) => acc.concat(tg.tasks), []);
    const totalCost = formatPrice(
      localTasks.every((t) => t?.estimatedCost == null)
        ? null
        : localTasks.reduce((total, t) => (total += t?.estimatedCost || 0), 0)
    );

    return (
      <StyledStatisticsContainer>
        <Stack horizontal>
          <Text className="strong">Est. Time:</Text>
          <span style={{ width: '5px' }} />
          <Text>{totalTime}</Text>
        </Stack>
        <Stack horizontal>
          <Text className="strong">Est. Cost:</Text>
          <span style={{ width: '5px' }} />
          <Text>{totalCost}</Text>
        </Stack>
      </StyledStatisticsContainer>
    );
  };

  const getSaveButtonLabel = () => {
    return isSaving ? 'Saving' : 'Save';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onSave();
      }
    });
  };

  const getSaveButtonStatus = (value) => {
    return !value.trim() || isDuplicated;
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit}>
        <StyledScrollableContainer ref={scrollContainer}>
          <StyledFormItemsContainer>
            <StyledFormItem label="Plan Template Name" style={{ marginTop: 24 }}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    validator: (rule, value) => {
                      setDisabled(getSaveButtonStatus(value));
                      if (!value || !value.trim()) {
                        rule.message = 'Name is required';
                      } else if (isNameTaken(value.trim(), planTemplate.name) && value.trim() !== planTemplate.name) {
                        setDuplicated(true);
                        rule.message = 'There is already a Plan Template with that name';
                      } else {
                        setDuplicated(false);
                      }
                      if (rule.message) {
                        return Promise.reject(rule.message);
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              })(
                <Input
                  size="large"
                  style={{ fontSize: '14px' }}
                  disabled={isSaving}
                  placeholder="Enter a name"
                  maxLength={30}
                  onChange={onChangeTemplateMetadata('name')}
                />
              )}
            </StyledFormItem>
            <StyledFormItem label="Description">
              {getFieldDecorator('description')(
                <FixedHeightInputTextArea
                  disabled={isSaving}
                  placeholder="Type to enter a description"
                  onChange={onChangeTemplateMetadata('description')}
                />
              )}
            </StyledFormItem>
          </StyledFormItemsContainer>
          <StyledContentContainer>
            <PlanStatistics />
            <StyledTaskCollapse>
              <ExpandCollapseLinkButton taskGroups={taskGroups} reconTaskCollapseFlag={flags.reconTaskCollapse} />
            </StyledTaskCollapse>
            <OurDragDropContext.Provider value={dndData}>
              <DndProvider backend={HTML5Backend}>
                <TaskGroupsDnd />
              </DndProvider>
            </OurDragDropContext.Provider>

            <div className="center-content">
              <CommonLinkButton
                disabled={isSaving}
                style={{ marginTop: '10px' }}
                onClick={(e) =>
                  setFormProps({
                    parentId: planTemplate.id,
                    count: taskGroups.length,
                    onCreate: onTaskChange('add')
                  })
                }
              >
                Add Task
              </CommonLinkButton>
            </div>
          </StyledContentContainer>
        </StyledScrollableContainer>
        <StyledFooterContainer>
          {planTemplate && planTemplate.id && (
            <CommonLinkButton
              disabled={isSaving}
              onClick={(e) => onDelete(planTemplate)}
              style={{ marginLeft: '24px' }}
            >
              Delete
            </CommonLinkButton>
          )}
          {showDiscardButton ? (
            <CommonRedButton
              disabled={isSaving}
              onClick={(e) => closeDrawer()}
              style={{ marginLeft: 'auto', marginRight: '24px' }}
            >
              Discard Changes
            </CommonRedButton>
          ) : (
            <CommonLinkButton
              disabled={isSaving}
              onClick={handleCanClose}
              style={{ marginLeft: 'auto', marginRight: '24px' }}
            >
              Cancel
            </CommonLinkButton>
          )}

          <StyledSubmitButton
            loading={isSaving}
            disabled={!templateFormIsDirty || isDisabled}
            style={{ marginRight: '24px' }}
          >
            {getSaveButtonLabel()}
          </StyledSubmitButton>
        </StyledFooterContainer>
      </StyledForm>
    </StyledContainer>
  );
};

//#region Styled Components
const StyledContainer = styled.div`
  height: 100%;
`;
const StyledForm = styled(Form).attrs({
  layout: 'vertical'
})`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;
const StyledScrollableContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 137px);
`;
export const StyledFormItemsContainer = styled.div`
  padding: 0 24px;
  width: 100%;
`;
const StyledStatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px 25px 8px;
`;
const StyledContentContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  margin: 0px 16px 24px 16px;
`;
const StyledFooterContainer = styled(CommonFooterContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})``;
const FixedHeightInputTextArea = styled(Input.TextArea)`
  form textarea.ant-input& {
    height: 72px;
  }
`;
const StyledTaskCollapse = styled.div`
  margin-bottom: 14px;
  padding: 0px 6px;
  .ant-btn-link {
    float: right;
  }
`;
//#endregion

export default withLDConsumer()(Form.create()(TemplatePlan));
