import React from 'react';
import styled from 'styled-components';
import { CircularSpinner } from './';

export default ({ tip = 'Loading...', size = '2.5rem' }) => (
  <StyledCenter>
    <CircularSpinner tip={tip} size={size} />
  </StyledCenter>
);

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: calc(50vh);
`;
