import { navigate } from '@reach/router';
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Input, Form, Typography, Collapse, Icon, Select, Switch, Modal, Button, Tooltip } from 'antd';
import { faChevronRight, faExclamationCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';
import { apiStatusConstants } from 'app-constants';
import { theme as themeStyles } from 'styles/appTheme';
import {
  CommonSelect,
  BodyBaseBold,
  CommonRedButton,
  CommonLinkButton,
  CommonFooterContainer,
  StyledConfirmationModalIcon
} from 'components/styledComponents';
import { CircularSpinner } from 'components';
import { deepClone } from 'utils/objectUtils';
import { StyledForm, StyledFormItem } from 'components/layout/settings/common/Styles';
import { compact } from 'lodash';

const { Text } = Typography;
const { Panel } = Collapse;

const InternalGroupForm = ({
  form,
  internalGroup,
  users,
  usersFetchStatus,
  isNameTaken,
  closeDrawer,
  isSaving,
  onSave,
  isFetching,
  handleCanClose,
  initialValues
}) => {
  //#region Form
  const { getFieldDecorator } = form;
  const { Option } = Select;
  const [members, setMembers] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  // cached data for comparing if member changed
  const [initialMemberValues, setInitialMemberValues] = useState([]);
  // cached list filterd user, for not to filter every time rendering
  const [filteredUsers, setFilteredUsers] = useState([]);
  const syncState = useRef({ isCanceling: false });

  useEffect(() => {
    setMembers(internalGroup?.members ? internalGroup.members : []);
  }, [internalGroup]);

  useEffect(() => {
    form.setFieldsValue({
      name: initialValues.name
    });
    getMemberDetailsData();
  }, [initialValues]);

  // update all members data when user data change
  useEffect(() => {
    getMemberDetailsData();
  }, [users]);

  useEffect(() => {
    filterUser();
  }, [members]);

  // add detail data of member from list user data
  const getMemberDetailsData = () => {
    const rawMember = deepClone(initialValues.members || []);
    const memberDetails = [];
    rawMember.forEach((member) => {
      const user = users?.find((u) => u.id === member.userId);
      if (user) {
        memberDetails.push({
          ...user,
          workload: member.workload,
          isGroupLeader: member.isGroupLeader
        });
      }
    });
    setInitialMemberValues(deepClone(memberDetails));
    setMembers(memberDetails);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!members.some((m) => m.isGroupLeader)) {
      Modal.warning({
        title: `Group has no leader`,
        content: `Please assign the leader role to at least one of the group members. To learn more about the leader role, see "How this group works".`,
        okText: 'Close',
        okType: 'danger',
        className: 'modal-with-thin-button',
        onOk() {
          form.validateFields();
        },
        icon: <StyledExclamationCircleIcon icon={faExclamationCircle} className="anticon" />
      });
    } else {
      form.validateFields((err) => {
        if (!err) {
          onSave({
            name: form.getFieldValue('name'),
            internalGroupUsers: members.map((m) => ({
              userId: m.id,
              isGroupLeader: m.isGroupLeader
            }))
          });
        }
      });
    }
  };

  const filterUser = () => {
    setFilteredUsers(users?.filter((u) => !members.some((m) => m.id === u.id) && !u.isExternalTech) ?? []);
  };

  const isFormError = () => {
    const formErrors = form.getFieldsError(['name']);
    return Object.keys(formErrors).some((key) => !!formErrors[key]) || !members.length;
  };

  const getSaveButtonLabel = () => {
    if (internalGroup && internalGroup.id) {
      return isSaving ? 'Saving' : 'Save';
    } else {
      return isSaving ? 'Creating Group' : 'Create Group';
    }
  };

  const onCancel = () => {
    setIsAdding(false);
    syncState.current.isCanceling = true;
  };

  const onChange = (fieldName) => (value) => {
    if (fieldName === 'member' && value) {
      // due to the focus out event will be emit first then on click if these 2 events processed in the same time
      // this function will be put in async to wait then if user has any click event emitted in time (click on cancel and blur in same time)
      setTimeout(() => {
        if (syncState.current.isCanceling) {
          syncState.current.isCanceling = false;
        } else {
          const newMember = users?.find((u) => u.id === value);
          if (newMember) {
            newMember.isGroupLeader = false;
            newMember.workload = null;
            setMembers([...members, newMember]);
          }
          form.setFieldsValue({
            [fieldName]: null
          });
          setIsAdding(false);
        }
      }, 0);
    }
  };

  const onBlur = (fieldName) => (e) => {
    const value = e.target.value;
    if (fieldName === 'name' && value) {
      if (typeof value === 'string' && value.trim() !== value) {
        form.setFieldsValue({
          [fieldName]: value.trim()
        });
        e.target.value = value.trim();
        form.validateFields();
      }
    }
  };

  const onSetLeader = (member) => {
    if (member && member.id) {
      let currentMember = members.find((m) => m.id === member.id);
      currentMember.isGroupLeader = !member.isGroupLeader;
      setMembers([...members]);
    }
  };

  const deleteMember = (member) => {
    // can't delete when user have tasks
    const { workload } = member;
    if (workload && workload.pending + workload.active > 0) {
      Modal.confirm({
        title: `${member.fullName} has Group tasks?`,
        content: `The Group member cannot be removed until all of their tasks for this group are canceled, declined or completed.`,
        okText: 'Close',
        okType: 'danger',
        cancelText: 'View Tasks',
        cancelButtonProps: { className: 'btn-dangerous' },
        className: 'modal-with-thin-button',
        icon: <StyledExclamationCircleIcon icon={faExclamationCircle} className="anticon" />,
        onOk() {
          devLogger.log(`Close warning info ${member.fullName}`);
        },
        onCancel() {
          devLogger.log(`Navigate to TLP filtered with ${member.fullName}`);
          closeDrawer();
          let membersInGroup = internalGroup.internalGroupUsers
            ?.filter((m) => m.userId === member.id)
            .map((memberInGroup) => memberInGroup.userId);
          const groupFilter = JSON.stringify([
            {
              assignedToGroup: internalGroup.id,
              assignedToMembers: compact(membersInGroup),
              unassigned: false
            }
          ]);
          let linkNavigate = `/tasks?taskStatus=Pending&taskStatus=In Progress&assignedToGroupFilter=${groupFilter}&assignedToTechnicianFilter=${JSON.stringify(
            []
          )}&fromDashboard=1`;
          navigate(linkNavigate);
        }
      });
    } else {
      Modal.confirm({
        title: `Remove ${member.fullName} from this group?`,
        content: `The group member will not be removed from iRecon, however tasks can no longer be assigned to them through this group.`,
        okText: 'Remove',
        okType: 'danger',
        cancelText: 'Cancel',
        cancelButtonProps: { className: 'btn-dangerous' },
        className: 'modal-with-thin-button',
        onOk() {
          let newMembers = members.filter((m) => m.id !== member.id);
          if (newMembers) {
            setMembers(newMembers);
            // the focused trash icon will be deleted along with the member
            // the focus index will be lost and return to url bar so we need to focus the first element
            focusFirstElement();
          }
        },
        onCancel() {
          devLogger.log(`Canceled Delete ${member.fullName}`);
        },
        icon: <StyledConfirmationModalIcon icon={faQuestionCircle} className="anticon" />
      });
    }
  };

  const focusFirstElement = () => {
    let first = document.getElementById('name');
    if (first) first.focus();
  };

  // make tab key-broad function able to cycle when we tab from last input element of form
  const cycleTab = (evt, isSubmitButton) => {
    if (evt.keyCode === 9) {
      // Tab pressed
      const submitBtn = document.getElementById('group-submit');
      // submit button can be disabled and disabled element can be focused
      // if submit button is disabled then cancel button will be the last element
      if (!isSubmitButton && submitBtn && !submitBtn.disabled) {
        return;
      }
      evt.preventDefault();
      focusFirstElement();
    }
  };

  // disable when: adding member, editing but no data change, creating but not have enough data
  const isNotValidToSave = () => {
    return (
      isAdding ||
      (internalGroup &&
        internalGroup.id &&
        initialValues.name === form.getFieldValue('name') &&
        JSON.stringify(initialMemberValues.map((u) => u.id + u.isGroupLeader).sort()) ===
          JSON.stringify(members.map((u) => u.id + u.isGroupLeader).sort()))
    );
  };

  // can move to common if needed
  const scrollToFocusedNodeWhenTab = (event) => {
    if (event.keyCode === 9) {
      // Tab pressed
      const node = document.activeElement;
      if (node) node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  //#endregion

  const memberSection = isAdding ? (
    <MemberRowCommon style={{ marginTop: '12px' }}>
      <StyledFormItem style={{ width: 'calc(100% - 120px)' }}>
        {getFieldDecorator('member')(
          <MemberSelect
            showSearch
            placeholder="Select a member"
            loading={!usersFetchStatus || usersFetchStatus === apiStatusConstants.IS_FETCHING}
            optionFilterProp="label"
            optionLabelProp="selectedLabel"
            onChange={onChange('member')}
          >
            {filteredUsers.map((u, index) => (
              <Option key={u.id} value={u.id} label={u.fullName}>
                {u.fullName}
              </Option>
            ))}
          </MemberSelect>
        )}
      </StyledFormItem>
      <CommonLinkButton onClick={onCancel}>Cancel</CommonLinkButton>
    </MemberRowCommon>
  ) : (
    <CommonLinkButton onClick={() => setIsAdding(true)} style={{ marginTop: members?.length ? '10px' : '0' }}>
      + Add Group Member
    </CommonLinkButton>
  );

  const CustomIcon = ({ isActive }) => (
    <Icon rotate={isActive ? 90 : 0} viewBox="0 0 1024 1024">
      <FontAwesomeIcon icon={faChevronRight} />
    </Icon>
  );

  return (
    <StyledContainer onKeyDown={scrollToFocusedNodeWhenTab}>
      {!isFetching ? (
        <StyledForm disabled={true}>
          <StyledFormItemsContainer>
            <StyledFormItem label="Group Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        rule.message = 'Name is required';
                      }
                      const isGroupNameTaken = isNameTaken(value, internalGroup?.id);
                      if (isGroupNameTaken) {
                        // this validator defined in VendorAndGroups.js file
                        // checking if name is duplicated with other groups/vendors/internal user
                        rule.message = `There is already ${isGroupNameTaken.entityName} with that name`;
                      }
                      if (rule.message) {
                        return Promise.reject(rule.message);
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              })(
                <Input
                  size="large"
                  style={{ fontSize: '14px' }}
                  placeholder="Type to enter name"
                  maxLength={30}
                  autoComplete="off"
                  onBlur={onBlur('name')}
                />
              )}
            </StyledFormItem>

            <Collapse
              accordion={true}
              bordered={false}
              defaultActiveKey={['member']}
              expandIcon={({ isActive }) => (
                <Icon rotate={isActive ? 90 : 0} viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Icon>
              )}
            >
              <Panel
                header={
                  <HeaderPanel>
                    <BodyBaseBold>
                      <span className="ant-form-item-required">Group members ({members?.length || 0})</span>
                    </BodyBaseBold>
                  </HeaderPanel>
                }
                key={'member'}
              >
                <ListMemberWapper>
                  <MemberRowHeader>
                    {members?.length || isAdding ? (
                      <Text className={isAdding && members?.length === 0 ? 'ant-form-item-required' : ''}>Name</Text>
                    ) : (
                      ''
                    )}
                    {members?.length ? (
                      <Text>
                        Leader
                        <Tooltip
                          placement="top"
                          overlayClassName="leader-info-tooltip"
                          title={<p>Leaders can assign, reassign or complete any group task</p>}
                        >
                          <StyledQuestionIcon icon={faQuestionCircle} />
                        </Tooltip>
                      </Text>
                    ) : (
                      ''
                    )}
                  </MemberRowHeader>
                  {members?.map((m) => (
                    <MemberRow key={m.id}>
                      <Text>{m.fullName}</Text>
                      <MemberRowCommon>
                        <Switch checked={m.isGroupLeader} onChange={() => onSetLeader(m)} />
                        <WrapperIcon onClick={() => deleteMember(m)}>
                          <StyledDeleteIcon icon={faTrashAlt} />
                        </WrapperIcon>
                      </MemberRowCommon>
                    </MemberRow>
                  ))}
                  {memberSection}
                </ListMemberWapper>
              </Panel>
            </Collapse>

            <Collapse
              accordion={true}
              bordered={false}
              defaultActiveKey={['guide']}
              expandIcon={CustomIcon}
            >
              <Panel
                header={
                  <HeaderPanel>
                    <BodyBaseBold id="ig-header-guide">How This Group Works</BodyBaseBold>
                  </HeaderPanel>
                }
                key={'guide'}
              >
                <StyledListGuide>
                  <li>
                    <Text>Technicians can assign tasks to themselves.</Text>
                  </li>
                  <li>
                    <Text>Technicians can complete their own tasks without a leader review.</Text>
                  </li>
                  <li>
                    <Text>Leaders are technicians with additional capabilities.</Text>
                  </li>
                  <li>
                    <Text>Leaders can assign or reassign task for any group member.</Text>
                  </li>
                  <li>
                    <Text>Leaders can decline any task.</Text>
                  </li>
                  <li>
                    <Text>Leaders can complete any task.</Text>
                  </li>
                </StyledListGuide>
              </Panel>
            </Collapse>
          </StyledFormItemsContainer>
        </StyledForm>
      ) : (
        <StyledCenter>
          <CircularSpinner tip="Loading..." size="2.5rem" />
        </StyledCenter>
      )}
      <StyledFooterContainer>
        <Button
          className="btn-dangerous"
          onKeyDown={(e) => cycleTab(e, false)}
          disabled={isSaving}
          onClick={handleCanClose}
          style={{ marginLeft: 'auto', marginRight: '8px' }}
        >
          Cancel
        </Button>
        <StyledSubmitButton
          id="group-submit"
          onClick={handleSubmit}
          style={{ marginRight: '24px' }}
          onKeyDown={(e) => cycleTab(e, true)}
          loading={isSaving}
          disabled={isNotValidToSave() || isFormError()}
        >
          {getSaveButtonLabel()}
        </StyledSubmitButton>
      </StyledFooterContainer>
    </StyledContainer>
  );
};

// #region Styled Components
const ListMemberWapper = styled.div``;

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  margin-top: calc(50% - 2.5rem);
  display: flex;
  align-items: center;
`;

const StyledListGuide = styled.ul`
  list-style: none;
  padding-left: 25px;
  li {
    margin-bottom: 5px;
    position: relative;
  }
  li::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 5px);
    background-color: black;
    left: -20px;
    border-radius: 50%;
  }
`;

const StyledDeleteIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  cursor: pointer;
`;

const StyledQuestionIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.navy};
  margin-left: 5px;
  cursor: pointer;
`;

const WrapperIcon = styled.button.attrs({
  type: 'button'
})`
  width: 20px;
  margin-left: 20px;
  padding: 0 !important;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  border: none;
  background-color: inherit;
  :focus > svg {
    outline: 1px solid black;
    outline-offset: 3px;
  }
  :focus {
    outline: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const MemberSelect = styled(CommonSelect)`
  .ant-select-selection-selected-value {
    max-width: none;
    width: 100%;
  }
`;

const MemberRowCommon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 3px;
`;

const MemberRow = styled(MemberRowCommon)`
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  .ant-typography {
    color: ${({ theme }) => theme.colors.navy};
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

const MemberRowHeader = styled(MemberRowCommon)`
  margin-right: 40px;
  text-transform: uppercase;
  .ant-typography {
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

// contain some focus/focus-visible style, can implement global if needed
// button in this drawer will have padding 8px as design
const StyledContainer = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus-visible > .ant-collapse-arrow > svg {
    outline: 1px solid ${({ theme }) => theme.colors.navy};
    outline-offset: 3px;
  }

  .ant-btn-link:focus-visible > span {
    color: ${({ theme }) => theme.colors.red};
    outline: 1px solid ${({ theme }) => theme.colors.navy};
    outline-offset: 3px;
  }

  .ant-select:not(.ant-select-open) .ant-select-selection:focus {
    border-color: ${({ theme }) => theme.colors.navy} !important;
  }

  .btn-dangerous,
  .ant-btn-danger {
    padding: 0 8px !important;
    :focus-visible {
      outline: 1px solid ${({ theme }) => theme.colors.navy};
      outline-offset: 2px;
      transition: none;
    }
  }

  .ant-btn-danger:focus-visible {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.red};
    border-color: ${({ theme }) => theme.colors.red};
  }

  .ant-switch:focus-visible {
    outline: 1px solid black;
    outline-offset: 2px;
    box-shadow: none;
    transition: none;
  }

  .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    float: left;

    &::before {
      margin-left: 4px;
    }
  }

  .ant-collapse {
    background-color: initial;

    .ant-collapse-item {
      border-bottom: initial;

      .ant-collapse-content > .ant-collapse-content-box {
        padding-bottom: 0;
        padding-left: 24px;
        padding-right: 0;
        margin-bottom: 4px;
      }

      .ant-collapse-header {
        padding-left: 24px;
        padding-right: 0px;

        .ant-collapse-arrow {
          left: 0;
          font-size: ${({ theme }) => theme.fontSizes.md};
          padding-bottom: 5px;
        }
      }
    }
  }
`;
const StyledFormItemsContainer = styled.div`
  padding: 24px 24px 0 24px;
  width: 100%;
  margin-bottom: 73px;
`;
const StyledFooterContainer = styled(CommonFooterContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})``;

const HeaderPanel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 5px;
`;

const StyledExclamationCircleIcon = styled(FontAwesomeIcon)`
  color: ${themeStyles.colors.red} !important;
`;

// #endregion

export default Form.create()(InternalGroupForm);
