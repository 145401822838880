import React from 'react';
import uuidv4 from 'uuid/v4';
import {
  EnterpriseStatisticsSkeleton,
  StyledContainer,
  StyledSummaryContainer,
  StyledSummarySkeletonHeader,
  SummaryItemCard,
  StyledEnterpriseSummaryTitle
} from '../common/styles';
import { apiStatusConstants } from '../../../../../app-constants';
import ExportFunctionality from '../../../../common/ExportFunctionality';
import { Row } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import CommonDropdown from 'components/common/CommonDropdown';

const SummaryHeader = ({
  fetchStatus,
  data,
  onMetricClick,
  currentEntity,
  sort,
  flags,
  noDealershipFiltersSelectedHQ = false
}) => {
  const headerSections = [
    {
      title: 'No Plan',
      value: data?.noPlan
    },
    {
      title: 'Pending Approval',
      value: data?.needsApproval
    },
    {
      title: 'Declined Tasks',
      value: data?.declined
    },
    {
      title: 'Overdue',
      value: data?.overdue
    },
    {
      title: 'Recall',
      value: data?.recall
    },
    {
      title: 'Sold',
      value: data?.sold
    }
  ];

  return (
    <>
      <StyledContainer>
        <StyledEnterpriseSummaryTitle>Needs Attention</StyledEnterpriseSummaryTitle>
        {fetchStatus === apiStatusConstants.SUCCEEDED && (
          <Row type="flex" justify="center" align="middle">
            {flags.reconDealerFilter && (
              <CommonDropdown
                title="Dealerships: "
                identifier="dealerships"
                tooltipMessage="Use the “Dealerships” menu to select one or more dealerships."
                additionalStyle={{ marginLeft: '12px' }}
              />
            )}

            <ExportFunctionality
              dividerUndisplay={false}
              exportType={'EnterpriseNeedAttention'}
              currentEntityId={currentEntity.id}
              enterpriseView={true}
              sort={sort}
            />
          </Row>
        )}
      </StyledContainer>
      <StyledSummaryContainer statisticsCount={6}>
        {fetchStatus !== apiStatusConstants.SUCCEEDED ? (
          <StyledSummarySkeletonHeader>
            <EnterpriseStatisticsSkeleton statisticsCount={6} />
          </StyledSummarySkeletonHeader>
        ) : (
          <>
            {headerSections.map((item, index) => (
              <SummaryItemCard
                summaryItemCard="needAttention"
                size="small"
                bordered={false}
                title={item.title}
                key={uuidv4()}
                onClick={noDealershipFiltersSelectedHQ ? () => {} : () => onMetricClick(item.title)}
                noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
              >
                <div className="ant-card-head-body-title">
                  <span>{item.value} </span>
                </div>
              </SummaryItemCard>
            ))}
          </>
        )}
      </StyledSummaryContainer>
    </>
  );
};

export default withLDConsumer()(SummaryHeader);
