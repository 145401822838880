import { deleteWithToken, getWithToken, postWithToken, putWithToken } from 'api';
import { apiStatusConstants, onboardingStatusTypes, VENDOR_USER_INFO, IS_NEW_SIGNIN } from 'app-constants';
import { vendorUserRoleDisplayLong } from 'app-constants/vendorUserConstants';
import { delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { CURRENT_DEALER } from 'store/dealersStore';
import { messagesActions } from 'store/messagesStore';
import { createRequestTypes, makeActionCreator } from 'utils';
import { postWithoutToken } from '../api';
import { createVendorErrorTypes } from '../app-constants/createVendorErrorTypes';
import { currentDealerActions, currentVendorActions, rootEntitySwitcherSelector } from './dealersStore';
import { userSettingsActions } from './userSettingsStore';

//#region Actions
export const VENDOR_TYPES = createRequestTypes('VENDOR_TYPES', [
  'GET_DATA',
  'GET_SEARCH_DATA',
  'SET_DATA',
  'SET_SEARCH_DATA',
  'SET_FETCH_STATUS',
  'SET_SEARCH_STATUS',
  'ADD',
  'UPDATE',
  'DELETE',
  'SEARCH',
  'GET_TASKTYPE',
  'SET_TASKTYPE',
  'GET_VENDOR',
  'SET_VENDOR',
  'GET_ALL_VENDORS',
  'SET_ALL_VENDORS',
  'VALIDATE_ACTIVATION_LINK',
  'SET_LINK_VALIDATION_STATUS',
  'SET_VALID_ACTIVATION_LINK',
  'SET_PASSWORD_STATUS',
  'SET_PASSWORD',
  'RESET_VENDOR_WELCOME',
  'SET_ERROR_MSG_EMAIL_VALIDATE',
  'GET_ERROR_MSG_EMAIL_VALIDATE',
  'CONFIRM_BUSINESS_PROFILE',
  'SET_CONFIRM_STATUS',
  'GET_VENDOR_USERS',
  'SET_VENDOR_USERS',
  'ADD_VENDOR_USER',
  'UPDATE_VENDOR_USER',
  'SET_VENDOR_USER_PUT_STATUS',
  'SET_VENDOR_USER_POST_STATUS',
  'SET_VENDOR_USERS_FETCH_STATUS',
  'SET_IS_LOADING',
  'UPDATE_VENDOR',
  'SET_PASSWORD_ERROR_MESSAGE',
  'RESEND_INVITE',
  'SET_FORM_ERROR_TYPE'
]);

export const vendorActions = {
  getData: makeActionCreator(VENDOR_TYPES.GET_DATA, 'dealerId'),
  getSearchData: makeActionCreator(VENDOR_TYPES.GET_SEARCH_DATA, 'vendorName'),
  setData: makeActionCreator(VENDOR_TYPES.SET_DATA, 'vendors'),
  setSearchData: makeActionCreator(VENDOR_TYPES.SET_SEARCH_DATA, 'vendors'),
  setFetchStatus: makeActionCreator(VENDOR_TYPES.SET_FETCH_STATUS, 'fetchStatus'),
  setSearchStatus: makeActionCreator(VENDOR_TYPES.SET_SEARCH_STATUS, 'searchStatus'),
  add: makeActionCreator(VENDOR_TYPES.ADD, 'vendor', 'successMessage', 'errorMessage', 'fromForm'),
  update: makeActionCreator(VENDOR_TYPES.UPDATE, 'vendor', 'successMessage', 'errorMessage', 'fromForm'),
  delete: makeActionCreator(VENDOR_TYPES.DELETE, 'vendor', 'successMessage', 'errorMessage'),
  search: makeActionCreator(VENDOR_TYPES.SEARCH, 'vendorName'),
  getTaskTypes: makeActionCreator(VENDOR_TYPES.GET_TASKTYPE, 'vendorId'),
  setTaskTypes: makeActionCreator(VENDOR_TYPES.SET_TASKTYPE, 'taskTypes'),
  getVendor: makeActionCreator(VENDOR_TYPES.GET_VENDOR, 'vendorId', 'dealerId'),
  setVendor: makeActionCreator(VENDOR_TYPES.SET_VENDOR, 'vendor'),
  getAllVendors: makeActionCreator(VENDOR_TYPES.GET_ALL_VENDORS),
  setAllVendors: makeActionCreator(VENDOR_TYPES.SET_ALL_VENDORS, 'allVendorsInSystem'),
  validateActivationLink: makeActionCreator(VENDOR_TYPES.VALIDATE_ACTIVATION_LINK, 'vendorId', 'code', 'dealerId'),
  setLinkValidationStatus: makeActionCreator(VENDOR_TYPES.SET_LINK_VALIDATION_STATUS, 'fetchStatus'),
  setValidActivationLink: makeActionCreator(VENDOR_TYPES.SET_VALID_ACTIVATION_LINK, 'status'),
  setPassword: makeActionCreator(VENDOR_TYPES.SET_PASSWORD, 'vendorUserId', 'code', 'password', 'dealerId'),
  setPasswordErrorMessage: makeActionCreator(VENDOR_TYPES.SET_PASSWORD_ERROR_MESSAGE, 'errorMessage'),
  setPasswordStatus: makeActionCreator(VENDOR_TYPES.SET_PASSWORD_STATUS, 'fetchStatus'),
  resetVendorWelcome: makeActionCreator(VENDOR_TYPES.RESET_VENDOR_WELCOME),
  setErrorMsgEmailValidate: makeActionCreator(VENDOR_TYPES.SET_ERROR_MSG_EMAIL_VALIDATE, 'errorMessage'),
  setFormErrorType: makeActionCreator(VENDOR_TYPES.SET_FORM_ERROR_TYPE, 'errorType'),
  getErrorMsgEmailValidate: makeActionCreator(VENDOR_TYPES.GET_ERROR_MSG_EMAIL_VALIDATE),
  setConfirmStatus: makeActionCreator(VENDOR_TYPES.SET_CONFIRM_STATUS, 'status'),
  confirmBusinessProfile: makeActionCreator(VENDOR_TYPES.CONFIRM_BUSINESS_PROFILE, 'vendorId', 'newProfile'),
  getVendorUsers: makeActionCreator(VENDOR_TYPES.GET_VENDOR_USERS, 'vendorId'),
  setVendorUsers: makeActionCreator(VENDOR_TYPES.SET_VENDOR_USERS, 'vendorUsers'),
  addVendorUser: makeActionCreator(
    VENDOR_TYPES.ADD_VENDOR_USER,
    'vendorId',
    'inviteMessage',
    'vendorUser',
    'successMessage',
    'errorMessage'
  ),
  updateVendorUser: makeActionCreator(
    VENDOR_TYPES.UPDATE_VENDOR_USER,
    'vendorUserId',
    'vendorUser',
    'successMessage',
    'errorMessage'
  ),
  resendInvite: makeActionCreator(
    VENDOR_TYPES.RESEND_INVITE,
    'vendorId',
    'dealerId',
    'invite',
    'successMessage',
    'errorMessage',
    'inviteMessage'
  ),
  setPutStatus: makeActionCreator(VENDOR_TYPES.SET_VENDOR_USER_PUT_STATUS, 'putStatus'),
  setPostStatus: makeActionCreator(VENDOR_TYPES.SET_VENDOR_USER_POST_STATUS, 'postStatus'),
  setVendorUsersFetchStatus: makeActionCreator(VENDOR_TYPES.SET_VENDOR_USERS_FETCH_STATUS, 'fetchStatus'),
  setIsLoading: makeActionCreator(VENDOR_TYPES.SET_IS_LOADING, 'isLoading'),
  updateVendor: makeActionCreator(VENDOR_TYPES.UPDATE_VENDOR, 'vendor')
};

export const VENDOR_DEALER_TYPES = createRequestTypes('VENDOR_DEALER_TYPES', [
  'GET_DATA',
  'SET_DATA',
  'RESET_DATA',
  'UPDATE_VENDOR_DEALER_INVITE',
  'RESEND_INVITE',
  'ADD_TO_DEALERSHIP',
  'SET_FETCH_STATUS'
]);
export const vendorDealerActions = {
  getVendorDealerDetails: makeActionCreator(VENDOR_DEALER_TYPES.GET_DATA, 'vendorId', 'dealerId'),
  setVendorDealerDetails: makeActionCreator(VENDOR_DEALER_TYPES.SET_DATA, 'vendorDealer'),
  resetVendorDealerDetails: makeActionCreator(VENDOR_DEALER_TYPES.RESET_DATA),
  updateVendorDealerInvite: makeActionCreator(
    VENDOR_DEALER_TYPES.UPDATE_VENDOR_DEALER_INVITE,
    'vendorId',
    'dealerId',
    'invite',
    'successMessage',
    'errorMessage',
    'fromForm'
  ),
  resendInvite: makeActionCreator(
    VENDOR_DEALER_TYPES.RESEND_INVITE,
    'vendorId',
    'dealerId',
    'invite',
    'successMessage',
    'errorMessage',
    'inviteMessage'
  ),
  addToDealerShip: makeActionCreator(
    VENDOR_DEALER_TYPES.ADD_TO_DEALERSHIP,
    'vendorId',
    'dealerId',
    'invite',
    'successMessage',
    'errorMessage'
  ),
  setVendorDealerFetchStatus: makeActionCreator(VENDOR_DEALER_TYPES.SET_FETCH_STATUS, 'fetchStatus')
};
//#endregion

//#region Reducer
const initialState = {
  data: [],
  searchData: [],
  vendorTaskTypes: [],
  vendor: {},
  fetchStatus: apiStatusConstants.PENDING,
  searchStatus: apiStatusConstants.SUCCEEDED,
  linkValidationStatus: apiStatusConstants.PENDING,
  setPasswordStatus: apiStatusConstants.PENDING,
  validateActivationLink: {},
  passwordErrorMessage: '',
  errorMessageEmail: '',
  formErrorType: null,
  confirmStatus: apiStatusConstants.PENDING,
  isLoading: false,
  currentVendor: {
    fetchStatus: apiStatusConstants.IS_FETCHING,
    vendorUsers: [],
    postStatus: '',
    putStatus: ''
  },
  vendorDealer: {
    fetchStatus: apiStatusConstants.IS_FETCHING,
    data: {}
  }
};

export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_TYPES.SET_DATA:
      return {
        ...state,
        data: action.vendors
      };
    case VENDOR_TYPES.SET_VENDOR:
      // TODO: Check with API team: include contactFirstName and contactLastName in the response of GET by id API
      // Getting information from the complete vendor list to fill in for now
      const currVendorInList = { ...state.data?.find((vendor) => vendor.id === action.vendor?.id) } ?? {};
      const { contactFirstName, contactLastName } = currVendorInList;
      return {
        ...state,
        vendor:
          action.vendor?.contactFirstName && action.vendor?.contactLastName
            ? action.vendor
            : {
                ...action.vendor,
                contactFirstName,
                contactLastName
              }
      };
    case VENDOR_TYPES.SET_ALL_VENDORS:
      return {
        ...state,
        allVendorsInSystem: action.allVendorsInSystem
      };
    case VENDOR_TYPES.SET_TASKTYPE:
      return {
        ...state,
        vendorTaskTypes: action.taskTypes
      };
    case VENDOR_TYPES.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case VENDOR_TYPES.SET_SEARCH_STATUS:
      return {
        ...state,
        searchStatus: action.searchStatus
      };
    case VENDOR_TYPES.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.vendors || []
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    case VENDOR_TYPES.SET_LINK_VALIDATION_STATUS:
      return {
        ...state,
        linkValidationStatus: action.fetchStatus
      };
    case VENDOR_TYPES.SET_PASSWORD_STATUS:
      return {
        ...state,
        setPasswordStatus: action.fetchStatus
      };
    case VENDOR_TYPES.SET_VALID_ACTIVATION_LINK:
      return {
        ...state,
        validateActivationLink: action.status
      };
    case VENDOR_TYPES.SET_PASSWORD_ERROR_MESSAGE:
      return {
        ...state,
        passwordErrorMessage: action.errorMessage
      };
    case VENDOR_TYPES.SET_ERROR_MSG_EMAIL_VALIDATE:
      return {
        ...state,
        errorMessageEmail: action.errorMessage
      };
    case VENDOR_TYPES.SET_CONFIRM_STATUS:
      return {
        ...state,
        confirmStatus: action.status
      };
    case VENDOR_TYPES.SET_VENDOR_USERS:
      return {
        ...state,
        currentVendor: {
          ...state.currentVendor,
          vendorUsers: action.vendorUsers
        }
      };
    case VENDOR_TYPES.SET_VENDOR_USERS_FETCH_STATUS:
      return {
        ...state,
        currentVendor: {
          ...state.currentVendor,
          fetchStatus: action.fetchStatus
        }
      };
    case VENDOR_TYPES.SET_VENDOR_USER_PUT_STATUS:
      return {
        ...state,
        currentVendor: {
          ...state.currentVendor,
          putStatus: action.putStatus
        }
      };
    case VENDOR_TYPES.SET_FORM_ERROR_TYPE:
      return {
        ...state,
        formErrorType: action.errorType
      };
    case VENDOR_TYPES.SET_VENDOR_USER_POST_STATUS:
      return {
        ...state,
        currentVendor: {
          ...state.currentVendor,
          postStatus: action.postStatus
        }
      };
    case VENDOR_TYPES.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case VENDOR_DEALER_TYPES.SET_DATA:
      return {
        ...state,
        vendorDealer: {
          ...state.vendorDealer,
          data: action.vendorDealer
        }
      };
    case VENDOR_DEALER_TYPES.SET_FETCH_STATUS:
      return {
        ...state,
        vendorDealer: {
          ...state.vendorDealer,
          fetchStatus: action.fetchStatus
        }
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const vendorSelector = createSelector(
  (state) => state.vendors,
  (vendors) => vendors.data
);

export const vendorSearchDataSelector = createSelector(
  (state) => state.vendors,
  (vendors) => vendors.searchData
);

export const vendorTaskTypesSelector = createSelector(
  (state) => state.vendors,
  (vendors) => vendors.vendorTaskTypes
);

export const vendorUsersSelector = createSelector(
  (state) => state.vendors.currentVendor,
  (currentVendor) => currentVendor.vendorUsers
);

export const vendorDealerSelector = createSelector(
  (state) => state.vendors.vendorDealer,
  (vendorDealer) => vendorDealer.data
);
//#endregion

//#region Sagas
// this will trigger on either VENDOR_TYPES.GET_DATA

export function* getVendorsSaga() {
  yield takeLatest(VENDOR_TYPES.GET_DATA, function* ({ dealerId }) {
    try {
      yield put(vendorActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const vendors = yield getWithToken(`/api/Dealers/id/${dealerId}/vendors`);

      yield put(vendorActions.setData(vendors));
      yield put(vendorActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(vendorActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getVendorsSaga', error);
    }
  });
}

export function* setConfirmBusinessProfileSaga() {
  yield takeLatest(VENDOR_TYPES.CONFIRM_BUSINESS_PROFILE, function* ({ vendorId, newProfile }) {
    try {
      yield put(vendorActions.setConfirmStatus(apiStatusConstants.IS_FETCHING));
      yield postWithToken(`/api/Vendors/id/${vendorId}/confirm-business-profile`, newProfile);
      yield put(vendorActions.setConfirmStatus(apiStatusConstants.SUCCEEDED));
      //Update vendor user info in localstorage as mine api is only called once
      const vendorUserInfoData = localStorage.getItem(VENDOR_USER_INFO);
      const vendorUserInfo = vendorUserInfoData ? JSON.parse(vendorUserInfoData) : {};
      vendorUserInfo.vendorOnboardingStatus = onboardingStatusTypes.VERIFIED;
      localStorage.setItem(VENDOR_USER_INFO, JSON.stringify(vendorUserInfo));

      const vendor = yield getWithToken(`/api/Vendors/id/${vendorId}`);
      yield put(vendorActions.setVendor(vendor));
      yield put(userSettingsActions.get(vendorUserInfo.id));
    } catch (error) {
      yield put(vendorActions.setConfirmStatus(apiStatusConstants.FAILED));
      let errorMessage = 'Failed to confirm the business profile!';
      if (error.response?.data?.type === createVendorErrorTypes.DUPLICATE_VENDOR_NAME) {
        errorMessage = 'Vendor name already exists';
      }
      yield put(messagesActions.notify('error', errorMessage, { isVendorToastMessage: true }));
      devLogger.log('error in setConfirmBusinessProfile', error);
    }
  });
}

export function* getVendorTaskTypesSaga() {
  yield takeLatest(VENDOR_TYPES.GET_TASKTYPE, function* ({ vendorId }) {
    try {
      const taskTypes = yield getWithToken(`/api/Vendors/id/${vendorId}/taskTypes`);
      yield put(vendorActions.setTaskTypes(taskTypes));
    } catch (error) {
      devLogger.log('error in getVendorTaskTypesSaga', error);
    }
  });
}
export function* getVendorSaga() {
  yield takeLatest(VENDOR_TYPES.GET_VENDOR, function* ({ vendorId, dealerId }) {
    try {
      yield put(vendorActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const vendor = yield getWithToken(`/api/Vendors/id/${vendorId}${!!dealerId ? `?dealerId=${dealerId}` : ''}`);
      yield put(vendorActions.setVendor(vendor));
      yield put(vendorActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      devLogger.log('error in getVendorTaskTypesSaga', error);
      yield put(vendorActions.setFetchStatus(apiStatusConstants.FAILED));
    }
  });
}

export function* updateVendorByVendorSaga() {
  yield takeLatest(VENDOR_TYPES.UPDATE_VENDOR, function* ({ vendor }) {
    try {
      yield put(vendorActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
      const response = yield putWithToken(`/api/vendors/id/${vendor.id}`, vendor);
      if (isRootUser && vendorShipSelected) {
        yield put(currentDealerActions.setData(response));
      } else {
        yield put(vendorActions.setVendor(response));
      }
      yield put(vendorActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      yield put(
        messagesActions.notify('success', 'Business profile changes were saved', { isVendorToastMessage: true })
      );
    } catch (error) {
      devLogger.log('error in updateVendorByVendorSaga', error);
      yield put(vendorActions.setFetchStatus(apiStatusConstants.FAILED));
      let errorMessage = 'Failed to update the business profile!';
      if (error.response?.data?.type === createVendorErrorTypes.DUPLICATE_EMAIL) {
        errorMessage = `${error.response?.data?.detail ? error.response?.data?.detail : 'Email already exists'}.`;
      }

      yield put(messagesActions.notify('error', errorMessage, { isVendorToastMessage: true }));
    }
  });
}

export function* updateVendorsSaga() {
  yield takeEvery(
    [VENDOR_TYPES.ADD, VENDOR_TYPES.UPDATE, VENDOR_TYPES.DELETE],
    function* ({ type: action, vendor, successMessage, errorMessage, fromForm = false }) {
      try {
        yield put(vendorActions.setIsLoading(true));
        const dealer = yield select((state) => state.dealers.current.data);
        const currentVendor = yield select(vendorSelector);
        let newVendor;
        const { contactFirstName, contactLastName } = vendor;
        switch (action) {
          case VENDOR_TYPES.ADD:
            let added = yield postWithToken('/api/vendors', { ...vendor, dealerId: dealer.id });
            if (vendor.taskTypes) {
              let addedTaskTypes = [];
              Object.entries(vendor.taskTypes).forEach((i) => {
                if (i[1].checked) addedTaskTypes.push({ taskTypeId: i[1].id, name: i[0] });
              });
              if (addedTaskTypes && addedTaskTypes.length > 0) {
                yield postWithToken(
                  `/api/Vendors/id/${added.id}/taskTypes`,
                  addedTaskTypes.map((i) => i.taskTypeId)
                );
                added.vendorTaskTypes = addedTaskTypes;
              }
            }
            // TODO: Check with API team: include contactFirstName and contactLastName in the response of POST API
            // Getting information from payload to fill in for now
            yield put(vendorActions.getData(dealer.id));
            yield put(vendorActions.getAllVendors());
            break;
          case VENDOR_TYPES.UPDATE:
            const updated = yield putWithToken(`/api/vendors/id/${vendor.id}`, vendor);
            let addedTaskTypes = [];
            if (vendor.taskTypes) {
              Object.entries(vendor.taskTypes).forEach((i) => {
                if (i[1].checked) addedTaskTypes.push({ taskTypeId: i[1].id, name: i[0] });
              });
            }
            yield putWithToken(
              `/api/Vendors/id/${vendor.id}/taskTypes`,
              addedTaskTypes.length > 0 ? addedTaskTypes.map((i) => i.taskTypeId) : addedTaskTypes
            );
            updated.vendorTaskTypes = addedTaskTypes;
            const updatedIndex = currentVendor.findIndex((tt) => tt.id === updated.id);
            newVendor = [...currentVendor];
            // TODO: Check with API team: include contactFirstName and contactLastName in the response of PUT API
            // Getting information from payload to fill in for now
            newVendor[updatedIndex] = { ...updated, contactFirstName, contactLastName };
            yield put(vendorActions.setData(newVendor));
            yield put(vendorActions.getAllVendors());
            break;
          case VENDOR_TYPES.DELETE:
            yield deleteWithToken(`/api/Vendors/id/${vendor.id}/dealers/${dealer.id}`);
            newVendor = [...currentVendor.filter((tt) => tt.id !== vendor.id)];
            yield put(vendorActions.setData(newVendor));
            yield put(vendorActions.getAllVendors());
            break;
          default:
            devLogger.log(`Unrecognized action for updateVendorsSaga: ${action}`);
            break;
        }
        yield put(vendorActions.setIsLoading(false));
        yield put(messagesActions.notify('success', successMessage, { duration: 2, isVendorToastMessage: true }));
      } catch (error) {
        yield put(vendorActions.setIsLoading(false));
        if (action === VENDOR_TYPES.ADD) {
          yield put(messagesActions.clear());
          if (error.response?.data?.type === createVendorErrorTypes.INVITATION_NOT_SENT) {
            yield put(vendorActions.setFormErrorType(createVendorErrorTypes.DUPLICATE_EMAIL));
            yield put(vendorActions.setErrorMsgEmailValidate(error.response.data.detail));
          } else if (error.response?.data?.type === createVendorErrorTypes.DUPLICATE_EMAIL) {
            yield put(vendorActions.setFormErrorType(createVendorErrorTypes.DUPLICATE_EMAIL));
            yield put(
              vendorActions.setErrorMsgEmailValidate(
                `${
                  error.response?.data?.detail ? error.response?.data?.detail : 'Email already exists'
                }. Press Cancel to search for this vendor.`
              )
            );
          } else if (error.response?.data?.type === createVendorErrorTypes.DUPLICATE_VENDOR_NAME && fromForm) {
            yield put(vendorActions.setFormErrorType(createVendorErrorTypes.DUPLICATE_VENDOR_NAME));
            yield put(vendorActions.setErrorMsgEmailValidate('Vendor name already exists.'));
          } else {
            yield put(vendorActions.setFormErrorType(createVendorErrorTypes.DUPLICATE_EMAIL));
            yield put(vendorActions.setErrorMsgEmailValidate('Bridge user ID already exists'));
          }
        } else if (
          action === VENDOR_TYPES.UPDATE &&
          error.response?.data?.type === createVendorErrorTypes.DUPLICATE_EMAIL &&
          fromForm
        ) {
          yield put(messagesActions.clear());
          yield put(vendorActions.setFormErrorType(createVendorErrorTypes.DUPLICATE_EMAIL));
          yield put(
            vendorActions.setErrorMsgEmailValidate(
              `${
                error.response?.data?.detail ? error.response?.data?.detail : 'Email already exists'
              }. Press Cancel to search for this vendor.`
            )
          );
        } else {
          yield put(messagesActions.notify('error', errorMessage, { duration: 3.5, isVendorToastMessage: true }));
          devLogger.log('failed updateVendorsSaga', error);
        }
      }
    }
  );
}

export function* validateActivationLinkSaga() {
  yield takeLatest(VENDOR_TYPES.VALIDATE_ACTIVATION_LINK, function* ({ vendorId, code, dealerId }) {
    try {
      yield put(vendorActions.setLinkValidationStatus(apiStatusConstants.IS_FETCHING));
      const result = yield postWithoutToken(`/api/Vendors/users/${vendorId}/validate-activation-link`, {
        code,
        dealerId
      });
      yield put(vendorActions.setValidActivationLink(result));
      yield put(vendorActions.setLinkValidationStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(vendorActions.setValidActivationLink(false));
      yield put(vendorActions.setLinkValidationStatus(apiStatusConstants.FAILED));
      devLogger.log('error in validateActivationLinkSaga', error);
    }
  });
}

export function* setPasswordSaga() {
  yield takeLatest(VENDOR_TYPES.SET_PASSWORD, function* ({ vendorUserId, code, password, dealerId }) {
    try {
      yield put(vendorActions.setPasswordStatus(apiStatusConstants.IS_FETCHING));
      yield postWithoutToken(`/api/Vendors/users/${vendorUserId}/register`, { code, password, dealerId });
      yield put(vendorActions.setPasswordStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      let errorMessage = '';
      if (typeof error === 'string') {
        try {
          const parsedError = JSON.parse(error.split('Response: \n')[1] ?? {});
          if (parsedError.errors && !!parsedError.errors.length) {
            errorMessage = parsedError.errors[0].message;
          } else {
            errorMessage = 'Password request failed.';
          }
        } catch {
          errorMessage = 'Password request failed.';
        }
      }
      yield put(vendorActions.setPasswordStatus(apiStatusConstants.FAILED));
      yield put(vendorActions.setPasswordErrorMessage(errorMessage));
      devLogger.log('error in setPasswordSaga', error);
    }
  });
}

export function* resetVendorSaga() {
  yield takeLatest(VENDOR_TYPES.RESET_VENDOR_WELCOME, function* () {
    try {
      yield put(vendorActions.setPasswordStatus(apiStatusConstants.PENDING));
      yield put(vendorActions.setLinkValidationStatus(apiStatusConstants.PENDING));
      yield put(vendorActions.setValidActivationLink(false));
      yield put(vendorActions.setPasswordErrorMessage(''));
    } catch (error) {
      devLogger.log('error in resetVendorSaga', error);
    }
  });
}

export function* searchVendorsSaga() {
  yield takeLatest(VENDOR_TYPES.SEARCH, function* ({ vendorName }) {
    try {
      yield put(vendorActions.setSearchStatus(apiStatusConstants.IS_FETCHING));
      yield delay(500);
      const dealer = yield select((state) => state.dealers.current.data);
      const result = yield getWithToken(`/api/Vendors/search/matchany`, {
        dealerId: dealer.id,
        search: vendorName,
        limit: 99999
      });
      if (result) {
        yield put(vendorActions.setSearchData(result.items));
      } else {
        yield put(vendorActions.setSearchData([]));
      }
      yield put(vendorActions.setSearchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(vendorActions.setSearchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getVendorsSaga', error);
    }
  });
}

export function* getAllVendorsSaga() {
  yield takeLatest(VENDOR_TYPES.GET_ALL_VENDORS, function* () {
    try {
      const dealer = yield select((state) => state.dealers.current.data);
      yield put(vendorActions.setSearchStatus(apiStatusConstants.IS_FETCHING));
      yield delay(500);
      const result = yield getWithToken(`/api/Vendors/search`, { limit: 99999, dealerId: dealer.id });
      if (result) {
        yield put(vendorActions.setAllVendors(result.items));
      } else {
        yield put(vendorActions.setAllVendors([]));
      }
      yield put(vendorActions.setSearchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(vendorActions.setSearchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getVendorsSaga', error);
    }
  });
}

export function* getVendorUsersSaga() {
  yield takeLatest(VENDOR_TYPES.GET_VENDOR_USERS, function* ({ vendorId }) {
    try {
      yield put(vendorActions.setVendorUsersFetchStatus(apiStatusConstants.IS_FETCHING));
      const vendorUsers = yield getWithToken(`/api/Vendors/id/${vendorId}/users`);
      yield put(vendorActions.setVendorUsers(vendorUsers));
      yield put(vendorActions.setVendorUsersFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(vendorActions.setVendorUsersFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getVendorUsersSaga', error);
    }
  });
}

export function* addVendorUserSaga() {
  yield takeLatest(
    VENDOR_TYPES.ADD_VENDOR_USER,
    function* ({ vendorId, inviteMessage, vendorUser, successMessage, errorMessage }) {
      try {
        // add vendor user
        yield put(vendorActions.setPostStatus(apiStatusConstants.PENDING));
        const addedVendorUser = yield postWithToken(
          `/api/Vendors/id/${vendorId}/users?message=${inviteMessage}`,
          vendorUser
        );

        // create new vendor users state array with created vendor user
        const currentVendorUsers = yield select(vendorUsersSelector);
        const newVendorUsers = [
          ...currentVendorUsers,
          {
            ...addedVendorUser,
            fullName: `${addedVendorUser.firstName} ${addedVendorUser.lastName}`,
            onboardingStatusDate: addedVendorUser.createdOn,
            role: {
              ...addedVendorUser.role,
              name: vendorUserRoleDisplayLong[addedVendorUser.role.name]
            }
          }
        ];
        yield put(vendorActions.setVendorUsers(newVendorUsers));

        // show successful toast message
        yield put(messagesActions.notify('success', successMessage, { duration: 2, isVendorToastMessage: true }));
        yield put(vendorActions.setPostStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        // set postStatus and show failed toast message
        yield put(vendorActions.setPostStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', errorMessage, { duration: 3.5, isVendorToastMessage: true }));
        devLogger.log('error in getVendorUsersSaga', error);
      }
    }
  );
}

export function* updateVendorUserSaga() {
  yield takeLatest(
    VENDOR_TYPES.UPDATE_VENDOR_USER,
    function* ({ vendorUserId, vendorUser, successMessage, errorMessage }) {
      try {
        // update vendor user
        yield put(vendorActions.setPutStatus(apiStatusConstants.PENDING));
        yield putWithToken(`/api/Vendors/id/${vendorUser.vendorId}/users/${vendorUserId} `, vendorUser);
        const vendorUsers = yield getWithToken(`/api/Vendors/id/${vendorUser.vendorId}/users`);
        yield put(vendorActions.setVendorUsers(vendorUsers));
        // show successful toast message
        yield put(messagesActions.notify('success', successMessage, { duration: 2, isVendorToastMessage: true }));
        yield put(vendorActions.setPutStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        // set putStatus
        yield put(vendorActions.setPutStatus(apiStatusConstants.FAILED));
        yield put(messagesActions.notify('error', errorMessage, { duration: 3.5, isVendorToastMessage: true }));
        devLogger.log('error in updateVendorUsersSaga', error);
      }
    }
  );
}

export function* getVendorDealerSaga() {
  yield takeLatest(VENDOR_DEALER_TYPES.GET_DATA, function* ({ vendorId, dealerId }) {
    try {
      // get vendordetails
      yield put(vendorDealerActions.setVendorDealerFetchStatus(apiStatusConstants.IS_FETCHING));
      const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
      const vendorDealerData = yield getWithToken(
        `/api/Vendors/id/${vendorId}/vendor-dealer/${dealerId}?rootUserWorkAsVendor=${isRootUser && vendorShipSelected}`
      );
      yield put(vendorDealerActions.setVendorDealerDetails(vendorDealerData));

      yield put(vendorDealerActions.setVendorDealerFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(vendorDealerActions.setVendorDealerFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getVendorDealerSaga', error);
    }
  });
}

export function* resetVendorDealerSaga() {
  yield takeLatest(VENDOR_DEALER_TYPES.RESET_DATA, function* () {
    try {
      // reset vendordetails to empty
      const emptyVendorDealer = { data: {}, fetchStatus: apiStatusConstants.IS_FETCHING };
      yield put(vendorDealerActions.setVendorDealerDetails(emptyVendorDealer));
    } catch (error) {
      devLogger.log('error in getVendorDealerSaga', error);
    }
  });
}

export function* updateVendorDealerInviteSaga() {
  yield takeEvery(
    [
      VENDOR_DEALER_TYPES.UPDATE_VENDOR_DEALER_INVITE,
      VENDOR_DEALER_TYPES.ADD_TO_DEALERSHIP,
      VENDOR_DEALER_TYPES.RESEND_INVITE
    ],
    function* ({
      type: action,
      vendorId,
      dealerId,
      invite,
      successMessage,
      errorMessage,
      inviteMessage,
      fromForm = false
    }) {
      try {
        // Send vendor invite update
        yield put(vendorActions.setIsLoading(true));
        const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
        let vendorDealerData;
        switch (action) {
          case VENDOR_DEALER_TYPES.UPDATE_VENDOR_DEALER_INVITE:
            vendorDealerData = yield putWithToken(
              `/api/Vendors/id/${vendorId}/vendor-dealer/${dealerId}?rootUserWorkAsVendor=${
                isRootUser && vendorShipSelected
              }`,
              invite
            );
            break;
          case VENDOR_DEALER_TYPES.ADD_TO_DEALERSHIP:
            vendorDealerData = yield postWithToken(
              `/api/Vendors/id/${vendorId}/vendor-dealer/${dealerId}?rootUserWorkAsVendor=${
                isRootUser && vendorShipSelected
              }`,
              invite
            );
            break;
          case VENDOR_DEALER_TYPES.RESEND_INVITE:
            vendorDealerData = yield postWithToken(`/api/Vendors/resend-invite`, {
              sourceId: dealerId,
              sourceType: 'dealer',
              destinationId: vendorId,
              destinationType: 'vendor',
              message: inviteMessage,
              dealerNote: invite?.dealerNote
            });
            break;
          default:
            devLogger.log(`Unrecognized action for updateVendorDealerInviteSaga: ${action}`);
            break;
        }
        const isNewSignin = JSON.parse(localStorage.getItem(IS_NEW_SIGNIN));
        const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
        const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
        const isVendor = isNewSignin && (vendorInfo.role?.id === 1 || vendorInfo.role?.id === 2);
        if ((isRootUser && vendorShipSelected) || isVendor) {
          // Only Refresh vendorDealer to show new invite and status for vendors
          yield put(vendorDealerActions.getVendorDealerDetails(vendorDealerData?.id ?? vendorId, dealerId));
        }
        // Create new vendor state array with updated vendor
        if (action === VENDOR_DEALER_TYPES.RESEND_INVITE) {
          yield put(vendorActions.getData(dealerId));
          yield put(vendorActions.getAllVendors());
        } else {
          const vendors = yield select(vendorSelector);
          const newVendors = [
            ...vendors.filter((v) => v.id !== vendorId),
            {
              ...vendorDealerData,
              // Add any missing fields not included in PUT response body
              invitationStatus: vendorDealerData.invitationStatus,
              statusUpdatedOn: vendorDealerData.statusUpdatedOn,
              dealerNotes: vendorDealerData.dealerNotes
            }
          ];
          yield put(vendorActions.setData(newVendors));
        }
        yield put(vendorActions.setIsLoading(false));
        if (action === VENDOR_DEALER_TYPES.UPDATE_VENDOR_DEALER_INVITE) {
          const isNewSignin = JSON.parse(localStorage.getItem(IS_NEW_SIGNIN));
          const vendorUserInfo = localStorage.getItem(VENDOR_USER_INFO);
          const vendorInfo = vendorUserInfo ? JSON.parse(vendorUserInfo) : {};
          const isVendorAdmin = vendorInfo.role?.id === 1 && isNewSignin;

          if (isVendorAdmin || (isRootUser && vendorShipSelected)) {
            yield put(currentVendorActions.getDealers(vendorId));
            //only update dealerships when logged in as a vendor admin - as a fix for DE277224
          }
        }
        // show successful toast message
        yield put(messagesActions.notify('success', successMessage, { duration: 2, isVendorToastMessage: true }));
      } catch (error) {
        yield put(vendorActions.setIsLoading(false));
        devLogger.log('error in updateVendorDealerInviteSaga', error);
        if (
          action === VENDOR_DEALER_TYPES.UPDATE_VENDOR_DEALER_INVITE &&
          error.response?.data?.type === createVendorErrorTypes.DUPLICATE_EMAIL &&
          fromForm
        ) {
          yield put(vendorActions.setFormErrorType(createVendorErrorTypes.DUPLICATE_EMAIL));
          yield put(
            vendorActions.setErrorMsgEmailValidate(
              `${
                error.response?.data?.detail && error.response?.data?.detail !== 'Email already exists for '
                  ? error.response?.data?.detail
                  : 'Email already exists'
              }. Press Cancel to search for this vendor.`
            )
          );
        } else {
          // show failed toast message
          yield put(messagesActions.notify('error', errorMessage, { duration: 3.5, isVendorToastMessage: true }));
        }
      }
    }
  );
}
//#endregion
