import { createRequestTypes, makeActionCreator } from 'utils';
import { takeLatest, put, select } from 'redux-saga/effects';
import { getWithToken, putWithToken } from 'api';
import { apiStatusConstants } from 'app-constants';
import { createSelector } from 'reselect';
import { rootEntitySwitcherSelector } from './dealersStore';

//#region Actions
export const USER_APP_STATE = createRequestTypes('USER_APP_STATE', [
  'GET_DATA',
  'SET_DATA',
  'SET_FETCH_STATUS',
  'SET_JOURNAL_HISTORY_CHECKED',
  'SET_DISPLAY_EMPTY_LINE_ITEM_PAGE',
  'UPDATE_DISPLAY_EMPTY_LINE_ITEM_PAGE',
  'SET_DISPLAY_MESSAGE_UPDATE_MAX_APPROVAL_TIME',
  'UPDATE_DISPLAY_MESSAGE_UPDATE_MAX_APPROVAL_TIME',
  'UPDATE_DISPLAY_VENDOR_TERMS_AND_CONDITIONS',
  'SET_DISPLAY_VENDOR_TERMS_AND_CONDITIONS',
  'SET_DISPLAY_VENDOR_TERMS_AND_CONDITIONS_FETCH_STATUS'
]);

export const userAppStateActions = {
  getData: makeActionCreator(USER_APP_STATE.GET_DATA, 'userId', 'dealerId'),
  setData: makeActionCreator(USER_APP_STATE.SET_DATA, 'data'),
  setFetchStatus: makeActionCreator(USER_APP_STATE.SET_FETCH_STATUS, 'fetchStatus'),
  setJournalHistoryChecked: makeActionCreator(USER_APP_STATE.SET_JOURNAL_HISTORY_CHECKED, 'userId', 'dealerId'),
  setDisplayEmptyLineItemPage: makeActionCreator(USER_APP_STATE.SET_DISPLAY_EMPTY_LINE_ITEM_PAGE, 'data'),
  updateDisplayEmptyLineItemPage: makeActionCreator(USER_APP_STATE.UPDATE_DISPLAY_EMPTY_LINE_ITEM_PAGE, 'userId'),
  setDisplayMessageUpdateMaxApprovalTime: makeActionCreator(
    USER_APP_STATE.SET_DISPLAY_MESSAGE_UPDATE_MAX_APPROVAL_TIME,
    'data'
  ),
  setDisplayVendorTermsAndConditions: makeActionCreator(USER_APP_STATE.SET_DISPLAY_VENDOR_TERMS_AND_CONDITIONS, 'data'),
  updateDisplayMessageUpdateMaxApprovalTime: makeActionCreator(
    USER_APP_STATE.UPDATE_DISPLAY_MESSAGE_UPDATE_MAX_APPROVAL_TIME,
    'userId'
  ),
  updateDisplayVendorTermsAndConditions: makeActionCreator(
    USER_APP_STATE.UPDATE_DISPLAY_VENDOR_TERMS_AND_CONDITIONS,
    'userId'
  ),
  setDisplayVendorTermsAndConditionsFetchStatus: makeActionCreator(
    USER_APP_STATE.SET_DISPLAY_VENDOR_TERMS_AND_CONDITIONS_FETCH_STATUS,
    'fetchStatus'
  )
};
//#endregion

//#region Reducer
const initialState = {
  data: null,
  fetchStatus: apiStatusConstants.IS_FETCHING,
  vendorTermsAndConditionsFetchStatus: apiStatusConstants.PENDING
};

export const userAppStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_APP_STATE.SET_DATA:
      return {
        ...state,
        data: action.data
      };
    case USER_APP_STATE.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case USER_APP_STATE.SET_DISPLAY_EMPTY_LINE_ITEM_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          displayEmptyLineItemPage: action.data?.displayEmptyLineItemPage
        }
      };
    case USER_APP_STATE.SET_DISPLAY_MESSAGE_UPDATE_MAX_APPROVAL_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          displayMessageUpdateMaxApprovalTime: action.data?.displayMessageUpdateMaxApprovalTime
        }
      };
    case USER_APP_STATE.SET_DISPLAY_VENDOR_TERMS_AND_CONDITIONS:
      return {
        ...state,
        data: {
          ...state.data,
          displayVendorTermsAndConditions: action.data?.displayVendorTermsAndConditions
        }
      };
    case USER_APP_STATE.SET_DISPLAY_VENDOR_TERMS_AND_CONDITIONS_FETCH_STATUS:
      return {
        ...state,
        vendorTermsAndConditionsFetchStatus: action.fetchStatus
      };
    default:
      return state;
  }
};
//#endregion

//#region Selectors
export const userAppStateSelector = createSelector(
  (state) => state.userAppState,
  (userAppState) => userAppState.data
);
//#endregion

//#region Sagas
export function* getUserAppStateSaga() {
  yield takeLatest(USER_APP_STATE.GET_DATA, function* ({ userId, dealerId }) {
    try {
      let params = {};
      const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
      if (!!dealerId && !(isRootUser && vendorShipSelected)) params.dealerId = dealerId;
      yield put(userAppStateActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const appStateData = yield getWithToken(`/api/UserAppState/userId/${userId}`, params);
      yield put(userAppStateActions.setData(appStateData));
      yield put(userAppStateActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(userAppStateActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getUserAppStateSaga', error);
    }
  });
}

export function* setUserAppStateJournalLastCheckedSaga() {
  yield takeLatest(USER_APP_STATE.SET_JOURNAL_HISTORY_CHECKED, function* ({ userId, dealerId }) {
    try {
      devLogger.log(`Updating ReconJournalLastChecked ${userId} seen`);
      const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);

      // set notification complete
      let params = {};
      if (!!dealerId && !(isRootUser && vendorShipSelected)) params.dealerId = dealerId;
      const appState = yield putWithToken(`/api/UserAppState/userId/${userId}/ReconEventChecked`, {}, params);

      yield put(userAppStateActions.setData(appState));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in setUserAppStateJournalLastCheckedSaga', error);
    }
  });
}

export function* setDisplayEmptyLineItemPageSaga() {
  yield takeLatest(USER_APP_STATE.UPDATE_DISPLAY_EMPTY_LINE_ITEM_PAGE, function* ({ userId }) {
    try {
      const appState = yield putWithToken(`/api/UserAppState/userId/${userId}/DisplayEmptyLineItemPage`, {
        displayEmptyLineItemPage: true
      });
      yield put(userAppStateActions.setDisplayEmptyLineItemPage(appState));
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in setDisplayEmptyLineItemPageSaga', error);
    }
  });
}

export function* setDisplayMessageUpdateMaxApprovalTimeSaga() {
  yield takeLatest(USER_APP_STATE.UPDATE_DISPLAY_MESSAGE_UPDATE_MAX_APPROVAL_TIME, function* ({ userId }) {
    try {
      const appState = yield putWithToken(`/api/UserAppState/userId/${userId}/DisplayMessageUpdateMaxApprovalTime`, {
        displayMessageUpdateMaxApprovalTime: false
      });
      yield put(userAppStateActions.setDisplayMessageUpdateMaxApprovalTime(appState));
    } catch (error) {
      devLogger.log('error in setDisplayMessageUpdateMaxApprovalTimeSaga', error);
    }
  });
}

export function* setDisplayVendorTermsAndConditionsSaga() {
  yield takeLatest(USER_APP_STATE.UPDATE_DISPLAY_VENDOR_TERMS_AND_CONDITIONS, function* ({ userId }) {
    try {
      yield put(userAppStateActions.setDisplayVendorTermsAndConditionsFetchStatus(apiStatusConstants.IS_FETCHING));
      const appState = yield putWithToken(`/api/UserAppState/userId/${userId}/DisplayVendorTermsAndConditions`, {
        displayVendorTermsAndConditions: false
      });
      yield put(userAppStateActions.setDisplayVendorTermsAndConditions(appState));
      yield put(userAppStateActions.setDisplayVendorTermsAndConditionsFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      devLogger.log('error in setDisplayVendorTermsAndConditionsSaga', error);
      yield put(userAppStateActions.setDisplayVendorTermsAndConditionsFetchStatus(apiStatusConstants.FAILED));
    }
  });
}
//#endregion
