export const notificationEventTypes = {
    VEHICLE_INVENTORY_ADDED: 'VEHICLE_INVENTORY_ADDED',
    VEHICLE_FRONT_LINE_READY: 'VEHICLE_FRONT_LINE_READY',
    TASK_COMPLETED: 'TASK_COMPLETED',
    TASK_DECLINED: 'TASK_DECLINED',
    TASK_DEFERRED: 'TASK_DEFERRED',
    TASK_ASSIGNED: 'TASK_ASSIGNED',
    TASK_COMPLETED_BY_DEALER: 'TASK_COMPLETED_BY_DEALER',
    TASK_CANCELED: 'TASK_CANCELED',
    TASK_COMMENT_ADDED: 'TASK_COMMENT_ADDED',
    TASK_COMMENT_USER_TAGGED: 'TASK_COMMENT_USER_TAGGED',
    LINE_ITEM_APPROVAL_REQUESTED: 'LINE_ITEM_APPROVAL_REQUESTED',
    EXCEEDED_MAX_APPROVAL_TIME: 'EXCEEDED_MAX_APPROVAL_TIME',
    LINE_ITEM_APPROVED_DECLINED: 'LINE_ITEM_APPROVED_DECLINED'
};