import React from 'react';
import { Overview, TaskBreakdown } from '../sharedComponents';
import { analyticsTagConstants, useComponentViewedGoogleTag } from '../../../../google-analytics';

const HistoricalPerformance = ({ filters, useLocalStorageObject }) => {
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.DASHBOARD_HISTORICAL_PERFORMANCE_VIEWED);

  return (
    <div>
      <Overview metricsType="historicalMetrics" filters={filters} useLocalStorageObject={useLocalStorageObject} />
      <TaskBreakdown metricsType="historicalMetrics" filters={filters} />
    </div>
  );
};
export default HistoricalPerformance;
