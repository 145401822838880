import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

const DashboardGreetings = () => {
  const firstName = useSelector(state => state.auth.user.firstName);
  return <Header>Welcome, {firstName} </Header>;
}

const Header = styled.h1`
  font-weight: ${({theme}) => theme.fontWeights.light};
  font-size: ${({theme}) => theme.fontSizes.title};
`;

export default DashboardGreetings;