import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faStoreAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form, Input } from 'antd';
import { CommonLinkButton, CommonRedButton } from 'components/styledComponents';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { vendorDealerActions } from 'store/vendorStore';
import styled from 'styled-components';
import { theme as themeStyles } from 'styles/appTheme';
import {
  apiStatusConstants,
  vendorDealerStatusTypes,
  detailContents,
  emDash,
  onboardingStatusTypes
} from 'app-constants';
import { StyledForm, StyledFormItem } from '../../../../components/layout/settings/common/Styles';
import { elapsedHours, formatMMDDYY } from '../../../../utils/dateTimeUtils';
import { sortInvitationHistory } from '../../../../utils/sortUtils';
import { CircularSpinner } from '../../../common';
import InputErrorMessage from '../../../common/InputErrorMessage';
import FormModal from '../../../modals/FormModal';
import { formatPhoneNumber } from 'utils/stringUtils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const MIN_HOURS_TO_RESEND_INVITE = 24;
const MAX_LENGTH = 120;

const viewVendorSelector = createSelector(
  (state) => state.vendors.isLoading,
  (state) => state.vendors.vendorDealer,
  (isLoading, vendorDealer) => ({
    isLoading: isLoading,
    vendorDealer: vendorDealer.data,
    fetchStatus: vendorDealer.fetchStatus
  })
);

const VendorDetails = ({ form, closeDrawer, dealerId, setSelectedCard, setIsReinvite, flags }) => {
  const { getFieldDecorator, getFieldsError, validateFields, resetFields } = form;
  const { isLoading, vendorDealer, fetchStatus } = useSelector(viewVendorSelector);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialDealerNotes, setInitialDealerNotes] = useState('');
  const [isNoteUpdated, setIsNoteUpdated] = useState(false);
  const [sortedInvitationHistory, setSortedInvitationHistory] = useState([]);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [businessName, setBusinessName] = useState();
  const [lastInviteSendFailed, setLastInviteSendFailed] = useState(false);
  const messageMaxLength = 30;

  useEffect(() => {
    if (isLoading === false) {
      setIsModalOpen(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (vendorDealer.invitationHistory) {
      const sortedArray = sortInvitationHistory(vendorDealer.invitationHistory);
      let latestInvitation;
      if (sortedArray && sortedArray.length > 0) {
        latestInvitation = sortedArray[sortedArray.length - 1];
      }
      setFirstName(
        flags.reconVendorWorkflow2023 && vendorDealer?.vendor?.onboardingStatus !== onboardingStatusTypes.VERIFIED
          ? !!latestInvitation?.firstName && `${latestInvitation.firstName} `
          : !!vendorDealer?.vendor?.primaryContact?.firstName && `${vendorDealer.vendor.primaryContact.firstName} `
      );
      setLastName(
        flags.reconVendorWorkflow2023 && vendorDealer?.vendor?.onboardingStatus !== onboardingStatusTypes.VERIFIED
          ? !!latestInvitation?.lastName && `${latestInvitation.lastName} `
          : !!vendorDealer?.vendor?.primaryContact?.lastName && `${vendorDealer.vendor.primaryContact.lastName} `
      );
      setPhoneNumber(
        flags.reconVendorWorkflow2023 && vendorDealer?.vendor?.onboardingStatus !== onboardingStatusTypes.VERIFIED
          ? latestInvitation?.phoneNumber
          : vendorDealer?.vendor?.primaryContact?.phone
      );
      setBusinessName(
        flags.reconVendorWorkflow2023 && vendorDealer?.vendor?.onboardingStatus !== onboardingStatusTypes.VERIFIED
          ? latestInvitation?.businessName
          : vendorDealer?.vendor?.name
      );
      setSortedInvitationHistory(sortedArray ?? []);
      setLastInviteSendFailed(sortedArray.length > 0 && sortedArray[0].invitationSent === false);
    }

    const dealerNotes = !vendorDealer.dealerNotes ? '' : vendorDealer.dealerNotes;
    setInitialDealerNotes(dealerNotes);
  }, [vendorDealer, flags.reconVendorWorkflow2023]);

  useEffect(() => {
    if (fetchStatus === apiStatusConstants.FAILED) {
      //close drawer if fetchStatus failed
      closeDrawer();
    }
  }, [fetchStatus]);

  const hasFormErrors = () => {
    const formErrors = getFieldsError();
    return Object.keys(formErrors).some((field) => formErrors[field]);
  };

  const handleChange = (value) => {
    const newNotes = value.trim();
    if (newNotes !== initialDealerNotes) {
      setIsNoteUpdated(true);
    } else {
      setIsNoteUpdated(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      const successMessage = `${businessName} updates saved`;
      const errorMessage = 'An error occurred while updating the vendor';

      if (lastInviteSendFailed) {
        dispatch(
          vendorDealerActions.resendInvite(
            vendorDealer.vendor.id,
            dealerId,
            {
              dealerNote: !values.dealerNotes ? '' : values.dealerNotes
            },
            successMessage,
            errorMessage,
            ''
          )
        );
      } else {
        dispatch(
          vendorDealerActions.updateVendorDealerInvite(
            vendorDealer.vendor.id,
            dealerId,
            {
              vendorId: vendorDealer.vendor.id,
              dealerId: dealerId,
              status: vendorDealer.status,
              message: '',
              dealerNote: !values.dealerNotes ? '' : values.dealerNotes
            },
            successMessage,
            errorMessage
          )
        );
      }

      if (!err) {
        resetFields();
        closeDrawer();
      }
    });
  };

  const handleResendInvite = (values) => {
    const successMessage = `${vendorDealer.vendor.name} was invited to be one of your vendors`;
    const errorMessage = 'An error occurred while inviting the vendor';
    if (lastInviteSendFailed) {
      dispatch(
        vendorDealerActions.resendInvite(
          vendorDealer.vendor.id,
          dealerId,
          {
            dealerNote: vendorDealer.dealerNotes ? vendorDealer.dealerNotes : ''
          },
          successMessage,
          errorMessage,
          values.message
        )
      );
    } else {
      dispatch(
        vendorDealerActions.updateVendorDealerInvite(
          vendorDealer.vendor.id,
          dealerId,
          {
            vendorId: vendorDealer.vendor.id,
            dealerId: dealerId,
            status: 'PENDING',
            message: values.message,
            dealerNote: vendorDealer.dealerNotes ? vendorDealer.dealerNotes : ''
          },
          successMessage,
          errorMessage
        )
      );
    }
  };

  const openModalResendInvitation = () => {
    if (vendorDealer.status === 'PENDING' && vendorDealer.vendor.onboardingStatus !== 'VERIFIED') {
      setIsReinvite(true);
      setSelectedCard({ contentType: detailContents.CREATE_EDIT_VENDOR, vendor: vendorDealer.vendor });
    } else {
      setIsModalOpen(true);
    }
  };

  const resendInviteOption = () => {
    if (sortedInvitationHistory.length === 0) {
      return;
    }

    // filter out only the pending invites sent
    const lastInviteSent = sortedInvitationHistory.filter((item) => item.status === 'PENDING')[0];
    // Incoming time is in UTC, so it's converted to local time.
    const formattedLastInviteDatetime = moment(lastInviteSent?.createdOn).format();
    const timeSinceLastInviteSent = elapsedHours(formattedLastInviteDatetime, Date.now());

    // Only show resend button if 24 hrs have elapsed since most invite was sent
    if (
      timeSinceLastInviteSent >= MIN_HOURS_TO_RESEND_INVITE ||
      sortedInvitationHistory.length === 1 ||
      lastInviteSendFailed
    ) {
      return (
        <CommonLinkButton
          style={{
            color: themeStyles.colors.red,
            fontSize: themeStyles.fontSizes.md
          }}
          className="ant-btn-background-ghost"
          onClick={openModalResendInvitation}
        >
          {vendorDealer.status === vendorDealerStatusTypes.PENDING.Name.toUpperCase()
            ? 'Edit & Resend Invite'
            : 'Resend Invite'}
        </CommonLinkButton>
      );
    } else {
      const timeUntilResend = 24 - timeSinceLastInviteSent;
      if (timeUntilResend >= 1) {
        return <StyledGrayText>You may send another invite in {Math.round(timeUntilResend)} hours.</StyledGrayText>;
      } else {
        return <StyledGrayText>You may send another invite in less than 1 hour.</StyledGrayText>;
      }
    }
  };

  const isFetching = fetchStatus === apiStatusConstants.IS_FETCHING;

  const addressLine =
    [vendorDealer.vendor?.address, vendorDealer.vendor?.address2, vendorDealer.vendor?.city, vendorDealer.vendor?.state]
      .filter((val) => val)
      .join(', ') + (vendorDealer.vendor?.postalCode ? ' ' + vendorDealer.vendor?.postalCode : '');

  return (
    <StyledForm onSubmit={handleSubmit}>
      {!isFetching && Object.keys(vendorDealer).length > 0 ? (
        <>
          <StyledVendorDetailWrapper>
            <StyledSectionWrapper style={{ paddingTop: 24 }}>
              <StyledSpacedRow>
                <StyledHeading>Primary Contact</StyledHeading>
                <StyledText style={{ fontSize: 14 }}>
                  {vendorDealerStatusTypes[vendorDealer?.status]?.Name ?? emDash}:{' '}
                  {formatMMDDYY(vendorDealer.statusUpdatedOn)}
                </StyledText>
              </StyledSpacedRow>
              <StyledRow>
                <div className="iconDiv">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <StyledCol>
                  {(firstName || lastName) && (
                    <span>
                      {firstName}
                      {lastName}
                    </span>
                  )}
                  {phoneNumber && <span>{formatPhoneNumber(phoneNumber)}</span>}
                  <span>{vendorDealer?.vendor?.primaryContact?.email}</span>
                </StyledCol>
              </StyledRow>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
              <StyledRow>
                <StyledHeading>Business Information</StyledHeading>
              </StyledRow>
              <StyledRow>
                <div className="iconDiv">
                  <FontAwesomeIcon icon={faStoreAlt} />
                </div>
                <StyledCol>
                  {businessName && <span>{businessName}</span>}
                  {vendorDealer?.vendor?.address && <span>{addressLine}</span>}
                  {((flags.reconVendorWorkflow2023 &&
                    vendorDealer?.vendor?.onboardingStatus === onboardingStatusTypes.VERIFIED &&
                    !!vendorDealer?.vendor?.phone) ||
                    (!flags.reconVendorWorkflow2023 && !!vendorDealer?.vendor?.phone)) && (
                    <span>{formatPhoneNumber(vendorDealer?.vendor?.phone)}</span>
                  )}
                  {vendorDealer?.vendor?.email && <span>{vendorDealer?.vendor?.email}</span>}
                </StyledCol>
              </StyledRow>
              <StyledHeading style={{ paddingTop: 16, fontSize: 14 }}>Notes</StyledHeading>
              <StyledFormItem>
                {getFieldDecorator('dealerNotes', {
                  initialValue: initialDealerNotes,
                  rules: [
                    {
                      required: false,
                      validator: (rule, value) => {
                        if (value.trim().length > messageMaxLength) {
                          rule.message = (
                            <InputErrorMessage>Message cannot exceed {messageMaxLength} characters</InputErrorMessage>
                          );
                        }
                        if (rule.message) {
                          return Promise.reject(rule.message);
                        }
                        return Promise.resolve();
                      }
                    }
                  ]
                })(
                  <Input
                    size="large"
                    style={{ fontSize: '14px' }}
                    maxLength={messageMaxLength + 1}
                    placeholder="Example: Dent Repair, Detail, etc."
                    onChange={(e) => handleChange(e.target.value)}
                  />
                )}
              </StyledFormItem>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
              <StyledHeading>Invitation Messages</StyledHeading>
              {sortedInvitationHistory
                .filter((item) => item?.message)
                .map((item) => {
                  return (
                    <div className="inviteMessage" key={item.createdOn}>
                      <StyledText>
                        {item.status === 'DECLINED' && 'Decline '}Message from{' '}
                        <StyledSpan>
                          {item.createdByFirstName} {item.createdByLastName}
                        </StyledSpan>{' '}
                        on <StyledSpan>{formatMMDDYY(item.createdOn)}</StyledSpan>.
                      </StyledText>
                      <StyledText>You cannot respond to this message.</StyledText>
                      <StyledMessage>{item.message}</StyledMessage>
                    </div>
                  );
                })}
            </StyledSectionWrapper>
          </StyledVendorDetailWrapper>
          <StyledButtonRow>
            <StyledButtonContainer>
              {vendorDealer.status === vendorDealerStatusTypes.PENDING.Name.toUpperCase() &&
                vendorDealer.invitationHistory &&
                (vendorDealer.invitationHistory?.length === 1 ||
                  elapsedHours(
                    moment(
                      vendorDealer.invitationHistory.filter((item) => item.status === 'PENDING')[0]?.createdOn
                    ).format(),
                    Date.now()
                  )) &&
                resendInviteOption()}
            </StyledButtonContainer>
            <CommonRedButton
              style={{ fontSize: themeStyles.fontSizes.md }}
              onClick={closeDrawer}
              className="ant-btn-background-ghost"
            >
              Cancel
            </CommonRedButton>
            <StyledSubmitButton
              style={{ fontSize: themeStyles.fontSizes.md }}
              disabled={isFetching || hasFormErrors() || !isNoteUpdated}
            >
              Save
            </StyledSubmitButton>
          </StyledButtonRow>
          <FormModal
            visible={isModalOpen}
            handleSave={handleResendInvite}
            closeModal={() => setIsModalOpen(false)}
            title="Resend Invite"
            icon={faQuestionCircle}
            buttonText={isLoading ? 'Resending' : 'Resend Invite'}
            isSaving={isLoading}
            message={{
              label: 'Message to Vendor',
              maxLength: MAX_LENGTH,
              placeholder: 'Include a message to the vendor'
            }}
          >
            Are you sure you want to add <StyledVendorName>{vendorDealer.vendor?.name}</StyledVendorName> as a vendor?
          </FormModal>
        </>
      ) : (
        <StyledCenter>
          <CircularSpinner tip="Loading..." size="2.5rem" />
        </StyledCenter>
      )}
    </StyledForm>
  );
};

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  margin-top: calc(50% - 2.5rem);
  display: flex;
  align-items: center;
`;

const StyledVendorDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  div.ant-form-explain {
    margin-top: 8px;
  }
  div.iconDiv {
    margin-right: 12px;
    width: 17.5px;
  }
`;

const StyledSectionWrapper = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #cacfd9;
  span + span {
    padding-top: 4px;
  }
  .inviteMessage {
    padding-bottom: 16px;
  }
  .inviteMessage + .inviteMessage {
    padding-top: 16px;
    border-top: ${({ theme }) => theme.borders.grayLineItemBorder};
  }
  .inviteMessage:last-child {
    padding-bottom: 0px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledSpacedRow = styled(StyledRow)`
  justify-content: space-between;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.div`
  color: ${({ theme }) => theme.colors.navy};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.lg};
  margin-bottom: 8px;
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledMessage = styled(StyledText)`
  margin-top: 8px;
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

const StyledButtonRow = styled(StyledRow)`
  padding: 20px 24px;
  justify-content: end;
  * + * {
    margin-left: 8px;
  }
  border-top: 1px solid #cacfd9;
  position: absolute;
  bottom: 0;
  background-color: white;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  margin-right: auto;
`;

const StyledSubmitButton = styled(CommonRedButton).attrs({
  htmlType: 'submit'
})``;

const StyledVendorName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledGrayText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export default withLDConsumer()(Form.create()(VendorDetails));
