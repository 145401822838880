import { useEffect, useState } from 'react';
import moment from 'moment';
import { ewsEntityTypes } from '../app-constants';

export const useSetAvailableDateRange = (createdOn = '', currentDealer = {}) => {
  const [availableDateRange, setAvailableDateRange] = useState([moment(createdOn), moment().add(1, 'days')]); //createdOn is the date that the dealer was first entered into database, they will have no data prior to this point; want them to only be able to choose up to yesterday's date

  useEffect(() => {
    if (currentDealer.entityType === ewsEntityTypes.DEALER || currentDealer.entityType === ewsEntityTypes.WHOLESALER) {
      if (createdOn.length > 0) {
        setAvailableDateRange([moment(createdOn), moment().add(1, 'days')]);
      }
    } else {
      // Subtract 1 year because we only support reports for the last 1 year
      // Subtract another 2 days so that when you "go back" one year using the UI component there is at least one selectable date being displayed.
      // Subtracting an additional 2 days is only needed when going back one year on the first day of a month (eg, 1st October 2022 -> 1st October 2021)
      setAvailableDateRange([moment().subtract(1, 'year').subtract(2, 'day'), moment().add(1, 'days')]);
    }
  }, [createdOn, currentDealer?.entityType]);

  return availableDateRange;
};
