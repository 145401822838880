import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledPageLineItemCatalog } from '../common/Styles.js';
import TableCatalog from './TableCatalog';
import { lineItemCatalogActions } from 'store/lineItemCatalogStore';
import uuidv4 from 'uuid/v4';
import { CommonSearchLineItemCatalog, CommonRedButton, CommonLinkButton } from 'components/styledComponents';
import { Row, Col } from 'antd';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import ApprovalTimeModal from './ApprovalTimeModal.js';
import { theme } from '../../../../styles/appTheme';
import { toMomentDuration, displayDurationSummary } from 'utils/dateTimeUtils';
import { userAppStateActions } from 'store/userAppStateStore';
import { apiStatusConstants } from 'app-constants';
import { GlobalAlertContext } from '../../../App';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';

const Catalog = ({ flags }) => {
  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.LINE_ITEM_CATALOG_VIEWED);

  const dispatch = useDispatch();
  const { isBridgeUser } = useSelector((state) => state.auth);
  const dealerId = useSelector((state) => state.dealers?.current?.data?.id) || '';
  const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);
  const [valueSearch, setValueSearch] = useState('');

  const isEditingOrAdding = useSelector((state) => state.lineItemCatalog.isEditingOrAdding);
  const fetchLineItemCatalogStatus = useSelector((state) => state.lineItemCatalog.fetchLineItemCatalogStatus);
  const needApprovalReminderTime = useSelector((state) => state.dealers?.current?.data?.needApprovalReminderTime);
  const displayMessageUpdateMaxApprovalTime = useSelector(
    (state) => state.userAppState?.data?.displayMessageUpdateMaxApprovalTime
  );
  const userId = useSelector((state) => state.oidc?.user?.profile?.recon_user_id);
  const [isShowBanner, setShowBanner] = useState(displayMessageUpdateMaxApprovalTime);
  const buttonAndSearchDisabled = isEditingOrAdding || fetchLineItemCatalogStatus !== apiStatusConstants.SUCCEEDED;
  const hasGlobalAlert = !!useContext(GlobalAlertContext);

  const onSearch = (value) => {
    setValueSearch(value);
    dispatch(lineItemCatalogActions.setSearchString(value.trim()));
  };

  useEffect(() => {
    if (flags.reconLineItemCatalog) {
      dispatch(lineItemCatalogActions.getLineItemCatalog(dealerId));
    }

    return () => {
      dispatch(lineItemCatalogActions.setIsEditingOrAdding(false));
      dispatch(lineItemCatalogActions.setSearchString(''));
      dispatch(
        lineItemCatalogActions.setSortedInfo({
          sortedInfo: {
            columnKey: 'description',
            order: 'ascend'
          }
        })
      );
    };
  }, [dealerId]);

  useEffect(() => {
    setShowBanner(displayMessageUpdateMaxApprovalTime);
  }, [displayMessageUpdateMaxApprovalTime]);

  const addNewLineItem = () => {
    const newLineItem = {
      id: uuidv4(),
      dealerId: dealerId,
      editable: true,
      description: '',
      laborCost: '',
      partsCost: '',
      totalCost: '',
      isAdding: true
    };
    dispatch(lineItemCatalogActions.addNewLocalLineItemCatalog(newLineItem));
    dispatch(lineItemCatalogActions.setIsEditingOrAdding(true));
  };

  const [modalOpen, setModalOpen] = useState(false);

  const onCloseBanner = () => {
    dispatch(userAppStateActions.updateDisplayMessageUpdateMaxApprovalTime(userId));
    setShowBanner(false);
  };

  return (
    <StyledPageLineItemCatalog is-bridge-user={isBridgeUser ? 1 : 0}>
      <StyledCatalogHeader>
        <Row type="flex" justify="space-between" style={{ width: '100%' }} align="middle">
          <Col xs={24}>
            <StyledText>Use this catalog to create preset line items for tasks.</StyledText>
            {isShowBanner && (
              <StyledAlertContainer>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <StyledIcon>
                    <FontAwesomeIcon icon={faInfoCircle} inverse size="lg" />
                  </StyledIcon>
                  <StyledTextSmall>
                    You can now set a Max. Approval Time to receive notifications when approvals exceed the max time.
                  </StyledTextSmall>
                </div>

                <FontAwesomeIcon
                  color={theme.colors.secondaryGrey}
                  icon={faTimes}
                  size="lg"
                  style={{ marginRight: '13.5px', cursor: 'pointer' }}
                  onClick={onCloseBanner}
                />
              </StyledAlertContainer>
            )}
          </Col>

          <Col xs={12}>
            <StyledMaxContainer>
              <FontAwesomeIcon icon={faClock} size="lg" />
              <StyledTextSmall> Max. Approval Time: </StyledTextSmall>
              <StyledTextSmallBold>
                {displayDurationSummary(toMomentDuration({ minutes: needApprovalReminderTime }), true, true, true)}
              </StyledTextSmallBold>
              <CommonLinkButton
                onClick={(event) => {
                  setModalOpen(true);
                  event.currentTarget.blur();
                }}
                style={{ lineHeight: '20px' }}
              >
                Edit
              </CommonLinkButton>
            </StyledMaxContainer>
          </Col>

          <Col xs={12}>
            <StyledCatalogActions>
              <>
                {buttonAndSearchDisabled ? (
                  <CommonSearchLineItemCatalog
                    placeholder="Search"
                    prefix={<FontAwesomeIcon icon={faSearch} />}
                    disabled
                    custom-height={34}
                  />
                ) : (
                  <CommonSearchLineItemCatalog
                    data-is-focused={searchBoxIsFocused}
                    placeholder={searchBoxIsFocused ? 'Type to begin searching...' : 'Search'}
                    prefix={<FontAwesomeIcon icon={faSearch} />}
                    onFocus={(e) => setSearchBoxIsFocused(true)}
                    onBlur={(e) => setSearchBoxIsFocused(false)}
                    value={valueSearch}
                    onChange={(e) => {
                      setValueSearch(e.target?.value);
                    }}
                    onSearch={(e) => {
                      onSearch(e);
                    }}
                    custom-height={34}
                  />
                )}
              </>
              <StyledRedButton
                onClick={addNewLineItem}
                disabled={buttonAndSearchDisabled}
                loading={fetchLineItemCatalogStatus !== apiStatusConstants.SUCCEEDED}
              >
                Add Line Item
              </StyledRedButton>
            </StyledCatalogActions>
          </Col>

          <ApprovalTimeModal visible={modalOpen} setModalOpen={setModalOpen} defaultValue={needApprovalReminderTime} />
        </Row>
      </StyledCatalogHeader>
      <StyledTableContainer
        is-bridge-user={isBridgeUser ? 1 : 0}
        isShowBanner={isShowBanner}
        has-global-alert={hasGlobalAlert}
      >
        <TableCatalog isShowBanner={isShowBanner} />
      </StyledTableContainer>
    </StyledPageLineItemCatalog>
  );
};

const StyledAlertContainer = styled.div`
  border: 1px solid ${theme.colors.secondaryGrey};
  border-radius: 8px;
  margin: 16px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIcon = styled.div`
  background-color: ${theme.colors.infoSecondary};
  width: 42px;
  height: 42px;
  border-left: ${theme.colors.infoSecondary};
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCatalogHeader = styled.div`
  padding-bottom: 16px;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  width: 100%;
`;

const StyledText = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const StyledTextSmall = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding-left: 5px;
`;

const StyledTextSmallBold = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledMaxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledCatalogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledRedButton = styled(CommonRedButton)`
  margin-left: 8px;
`;

// 58px is height of StyledCatalogHeader
// 76px is height of warning banner
// if banner is not shown, subtract 76 from 150 and get 74
const StyledTableContainer = styled.div`
  height: calc(
    100vh - ${(props) => (props['is-bridge-user'] ? 280 : 244)}px - ${(props) => (props['isShowBanner'] ? 150 : 74)}px -
      ${(props) => (props['has-global-alert'] ? 42 : 0)}px
  );
  overflow: auto;
`;

export default withLDConsumer()(Catalog);
