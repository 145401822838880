import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NoImageAvailable from './NoImageAvailable';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Gets a standard image which will default to a fallback image if it can't be found
const VehicleImage = ({
  isLoading = false,
  src,
  nonMarketingSrc,
  fallbackSrc,
  imageQualityWidth = 490,
  imageQualityHeight = 320,
  imageStyle = {},
  noImageStyle = {},
  isHeroOrAppraisal = false,
  flags
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [showNoImage, setShowNoImage] = useState(true);
  const reconCvmIntegrationFlag = flags?.reconCvmIntegration;
  //Keeps track of which URLs we checked
  const [images, setImages] = useState([]);
  useEffect(() => {
    const uniqueImages = Array.from(new Set([src, nonMarketingSrc, fallbackSrc])).filter((url) => url !== undefined);
    setImages(uniqueImages); //New state won't be available until next render, so using variable uniqueImages for logic below
    setShowNoImage(uniqueImages.length === 0);
    setImageUrl(uniqueImages.length > 0 ? uniqueImages[0] : null);
  }, [src, nonMarketingSrc, fallbackSrc, imageQualityHeight, imageQualityWidth]);

  const onImgLoadError = () => {
    const checkedImageUrlIndex = images.findIndex((url) => url === imageUrl);
    if (checkedImageUrlIndex !== -1 && checkedImageUrlIndex + 1 < images.length) {
      setImageUrl(images[checkedImageUrlIndex + 1]);
    } else {
      setShowNoImage(true); // None of the 3 images (src/nonMarketing/fallback) are available/work (or they are all the same URL)
    }
  };
  const includeImageDownloader = imageUrl?.includes('image-downloader');
  return (
    <>
      {showNoImage ? (
        <NoImageAvailable isLoading={isLoading} style={{ ...noImageStyle }} />
      ) : (
        <StyledImage
          // Try to set the height and width higher than the box dimensions because
          // the browser is better at scaling than the image api is
          // 'ph' and 'pw' are the "process" height and width. These affect the overlay dimensions.
          src={`${imageUrl}${
            reconCvmIntegrationFlag && isHeroOrAppraisal && !includeImageDownloader ? `?` : `&`
          }h=${imageQualityHeight}&w=${imageQualityWidth}&ph=${imageQualityHeight}&pw=${imageQualityWidth}`}
          onError={onImgLoadError}
          style={imageStyle}
        />
      )}
    </>
  );
};

//#region Styled Components
const StyledImage = styled.img.attrs({
  alt: 'Vehicle photo'
})`
  object-fit: cover;
`;
//#endregion

export default withLDConsumer()(VehicleImage);
